import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyTest, emptyTestSearch } from "../viewmodels/testsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadTests:
      return {
        ...state,
        tests: action.payload.tests,
        count: action.payload.count,
      };

    case ContextProviderActions.saveTestSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadTest:
      return {
        ...state,
        test: action.payload,
      };

    case ContextProviderActions.loadTestRunnerUpdateState:
      return {
        ...state,
        testRunnerUpdateState: action.payload,
      };

    case ContextProviderActions.loadDataGenerationUpdateState:
      return {
        ...state,
        dataGenerationUpdateState: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in TestsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  tests: [],
  test: emptyTest,
  search: emptyTestSearch,
  count: 0,
  dataGenerationUpdateState: {},
  testRunnerUpdateState: {},
};

const Tests = createContext({
  testsData: initialState,
  setTestsData: () => null,
});

// eslint-disable-next-line react/prop-types
const TestsProvider = ({ children }) => {
  const [testsData, setTestsData] = useReducer(reducer, initialState);

  return (
    <Tests.Provider value={{ testsData, setTestsData }}>
      {children}
    </Tests.Provider>
  );
};

export default TestsProvider;
export const useTests = () => useContext(Tests);
