import React from "react";
import { Modal } from "react-bootstrap";
import { disableAnimations } from "../../services/General";
import HelpLink from "../common/ui/HelpLink";

function ConfirmDialog({ title, question, showModal, onYes, onNo }) {
  return (
    <>
      <Modal show={showModal} onHide={onNo} animation={!disableAnimations()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
            <HelpLink path="/General/Dialogs&anchor=confirm-dialog" label="" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ lineHeight: "24px" }}>{question}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            autoFocus={true}
            className="btn btn-primary"
            onClick={onYes}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Yes
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onNo}
            style={{ marginLeft: "12px" }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmDialog;
