import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyLegacyDbSync } from "../viewmodels/dbLegacyDbSyncVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadDbLegacyDbSync:
      return {
        ...state,
        dbLegacyDbSync: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in DbLegacyDbSyncContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  dbLegacyDbSync: emptyLegacyDbSync,
};

const LegacyDbSync = createContext({
  dbLegacyDbSyncData: initialState,
  setDbLegacyDbSyncData: () => null,
});

// eslint-disable-next-line react/prop-types
const DbLegacyDbSyncProvider = ({ children }) => {
  const [dbLegacyDbSyncData, setDbLegacyDbSyncData] = useReducer(
    reducer,
    initialState
  );

  return (
    <LegacyDbSync.Provider
      value={{ dbLegacyDbSyncData, setDbLegacyDbSyncData }}
    >
      {children}
    </LegacyDbSync.Provider>
  );
};

export default DbLegacyDbSyncProvider;
export const useDbLegacyDbSync = () => useContext(LegacyDbSync);
