import React from "react";
import { useMobile } from "../../../hooks/useMobile";

function ToggleButtonGroupInput({
  name,
  id,
  value,
  options,
  label,
  error,
  onChange,
  labelStyle,
  showLabelInline = false,
  disabled = false,
}) {
  const { isMobileSize } = useMobile();

  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  let labelStyles = { ...(labelStyle || {}) };
  if (showLabelInline) {
    labelStyles = { ...labelStyles, marginRight: "10px" };
  }
  if (disabled === true) {
    labelStyles = { ...labelStyles, color: "var(--text-disabled)" };
  }

  return (
    <div className={wrapperClass}>
      {!showLabelInline && (
        <label htmlFor={id} style={labelStyles} disabled={disabled}>
          {label}
        </label>
      )}
      <div className="field">
        {showLabelInline && (
          <label htmlFor={id} style={labelStyles}>
            {label}
          </label>
        )}
        <div id={id} name={name} role="group" className="btn-group">
          {options.map((option) => (
            <button
              key={option.value}
              type="button"
              value={option.value}
              disabled={disabled}
              className={
                option.value === value ? "btn-primary" : "btn-secondary"
              }
              style={{
                maxWidth: isMobileSize ? "80px" : "136px",
                minWidth: isMobileSize ? "80px" : "136px",
              }}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

export default ToggleButtonGroupInput;
