import React, { useState, useEffect } from "react";
import ReportsList from "./ReportsList";
import ReportSearchForm from "./ReportSearchForm";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import {
  createViewModel,
  emptyReportSearch,
} from "../../../viewmodels/reportsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useReports } from "../../../contexts/ReportsContext";
import {
  apiLoadReports,
  apiDeleteReport,
  apiCopyReport,
} from "../../../api/ReportsApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import { useNavigate } from "react-router-dom";
import DuplicateRecordDialog from "../../dialogs/DuplicateRecordDialog";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import {
  apiLoadDashboardData,
  apiSaveDashboardProperties,
} from "../../../api/HomeApi";
import useApi from "../../../hooks/useApi";
import { useReportTemplates } from "../../../contexts/ReportTemplatesContext";
import { apiLoadReportTemplatesAll } from "../../../api/ReportTemplatesApi";
import HelpLink from "../../common/ui/HelpLink";

function Reports() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { reportsData, setReportsData } = useReports();
  const { reportTemplatesData, setReportTemplatesData } = useReportTemplates();
  const { loading, api: apiLoad } = useApi(apiLoadReports);
  const { loading: loadingTemplates, api: apiLoadTemplates } = useApi(
    apiLoadReportTemplatesAll
  );
  const { loading: loadingDashboard, api: apiLoadDashboard } =
    useApi(apiLoadDashboardData);
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteReport);
  const { loading: copying, api: apiCopy } = useApi(apiCopyReport);
  const { loading: savingDashboard, api: apiSaveDashboard } = useApi(
    apiSaveDashboardProperties
  );
  const navigate = useNavigate();
  const [panelData, setPanelData] = useState(null);
  const [loadData, setLoadData] = useState(true);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const { isMobileSize } = useMobile();

  let reports = [];
  if (reportsData && reportsData.reports) {
    reports = reportsData.reports;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setReportsData must usually
  //  happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //  useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      // Get dashboard data first (without refreshing the actual data) so we can see which reports have been subscribed to.
      loadDashboardData();
    }
  }, [auth.authenticated, loadData]);

  async function loadDashboardData() {
    apiLoadDashboard.call(
      true,
      (result) => {
        setPanelData(result);
        loadReports();
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  async function loadTemplateList() {
    const templates = await apiLoadTemplates.call(null, async (result) => {
      let dsResult = ([...result.resources] || []).sort((a, b) =>
        a.name > b.name ? 1 : -1
      );

      let dsList = [{ value: "-1", label: "All" }];

      for (let i = 0; i < dsResult.length; i++) {
        dsList.push({
          value: dsResult[i].id,
          label: dsResult[i].name,
          entityType: dsResult[i].entityType,
          externalDataRuleTableDefinitionId:
            dsResult[i].externalDataRuleTableDefinitionId,
          runValuesToSave: dsResult[i].runValuesToSave,
        });
      }

      return dsList;
    });

    return templates || [];
  }

  async function loadReports() {
    apiLoad.call(
      reportsData.search,
      async (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setReportsData({
          type: ContextProviderActions.loadReports,
          payload: {
            reports: vms,
            count,
          },
        });

        let templateList = reportTemplatesData.templateListCache || [];
        if ((templateList || []).length === 0) {
          templateList = await loadTemplateList();

          // Save the list of data sources in the cache
          setReportTemplatesData({
            type: ContextProviderActions.setReportTemplateCache,
            payload: templateList,
          });
        }
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setReportsData({
      type: ContextProviderActions.saveReportSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyReportSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : reportsData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = reportsData.search.orderBy.indexOf(`${event.target.id}+`);
    var indexDesc = reportsData.search.orderBy.indexOf(`${event.target.id}-`);

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...reportsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...reportsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...reportsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = { ...reportsData.search, ...newSearch, pageNumber: 1 };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({ ...reportsData.search, [target.name]: target.value });
  }

  function handleSearchCheckboxChange({ target }) {
    setSearchChanges({ ...reportsData.search, [target.name]: target.checked });
  }

  function handleShareModeChanged(sectypes) {
    setSearchChanges({ ...reportsData.search, shareMode: sectypes });
  }

  function handleReportTemplateChange(option) {
    setSearchChanges({ ...reportsData.search, reportTemplateId: option });
  }

  async function deleteReport(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Report deleted successfully");
      setLoadData(true);
    });
  }

  async function handleDelete(id, reportName) {
    setDeleteId(id);
    setAltDeleteId(reportName);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteReport(deleteId);
  }

  const handleCancelModal = () => {
    setShowCopyModal(false);
  };

  function startCopy(name, id) {
    let updated = { ...reportsData.search, oldReportName: { name, id } };
    setSearchChanges(updated);
    setShowCopyModal(true);
  }

  async function copyReport(oldName, newName) {
    apiCopy.call({ id: oldName.id, newId: newName }, (result) => {
      notifySuccess("Report copied successfully to " + newName);
      setShowCopyModal(false);
      setLoadData(true);
    });
  }

  async function handleCopy(newReportName) {
    await copyReport(reportsData.search.oldReportName, newReportName);
  }

  async function handleReportSubscribe(reportId, name, subscribe) {
    const model = { ...panelData };
    const subscribedReportIds = [...model.subscribedReportIds];

    if (subscribe) {
      subscribedReportIds.push(reportId);
    } else {
      const pos = subscribedReportIds.indexOf(reportId);
      if (pos >= 0) {
        subscribedReportIds.splice(pos, 1);
      }
    }
    model.subscribedReportIds = subscribedReportIds;

    apiSaveDashboard.call({ id: panelData.id, model }, (result) => {
      notifySuccess(
        `You are now ${
          subscribe ? "subscribed to" : "unsubscribed from"
        } '${name}'.`
      );
      setLoadData(true); // refresh the report list
    });
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (reportsData.search.description !== "") numFilters++;
    if (reportsData.search.name !== "") numFilters++;
    if ((reportsData.search.shareMode || []).length > 0) numFilters++;
    if ((reportsData.search.reportTemplateId || []).length > 0) numFilters++;
    if (reportsData.search.showOnHomePage === true) numFilters++;
    if (reportsData.search.showAsDashboardPanel === true) numFilters++;
    if (reportsData.search.dashboardPanelIsAlertPanel === true) numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        {((reportsData.search.showAdvancedFilter && !isMobileSize) ||
          !reportsData.search.showAdvancedFilter) && (
          <HelpLink path="/Reports/Reports-List-Screen" label="Help" />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Reports</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/report")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Report
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search Name or Description"
        search={reportsData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <DuplicateRecordDialog
        title="Duplicate Report"
        instructions={`Enter a new name for the report to be copied from '${
          reportsData.search.oldReportName?.name || ""
        }'.`}
        value={reportsData.search.newReportName || ""}
        showModal={showCopyModal}
        onCancel={handleCancelModal}
        onCopy={handleCopy}
        placeholder="New Report Name"
      />
      <ConfirmDialog
        title="Remove Report"
        question={`Are you sure you wish to delete the report '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              reportsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={reportsData.search}
            setSearch={setSearchChanges}
            helpLink="/Reports/Reports-List-Screen&anchor=filters"
          >
            <ReportSearchForm
              errors={errors}
              templates={reportTemplatesData.templateListCache}
              search={reportsData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onCheckboxChange={handleSearchCheckboxChange}
              onShareModeChanged={handleShareModeChanged}
              onReportTemplateChange={handleReportTemplateChange}
            />
          </GridAdvancedFilter>
        </div>

        {reportsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }} data-testid="reports-list">
            {loading ||
            loadingDashboard ||
            loadingTemplates ||
            loadData ||
            savingDashboard ||
            deleting ||
            copying ? (
              <Spinner />
            ) : (
              <ReportsList
                reports={reports}
                subscribedReportIds={panelData?.subscribedReportIds || []}
                onDelete={handleDelete}
                search={reportsData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={reportsData.count}
                onCopy={startCopy}
                onSubscribe={handleReportSubscribe}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default Reports;
