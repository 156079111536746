import React from "react";
import { Modal } from "react-bootstrap";
import { disableAnimations } from "../../services/General";
import { Link } from "react-router-dom";
import HelpLink from "../common/ui/HelpLink";

function InfoDialog({ title, text, showModal, onClose }) {
  const getMessageWithLink = (beforeText, linkText, link, afterText) => (
    <p>
      {beforeText}
      <Link to={link}>{linkText}</Link>
      {afterText}
    </p>
  );

  function getLinkInfo(message) {
    let linkBegin = message.indexOf("<Link>");
    let linkInfo = [];
    if (linkBegin > -1) {
      let beforeText = message.substring(0, linkBegin);
      let linkEnd = message.indexOf("</Link>");
      if (linkEnd > -1) {
        let linkInterior = message.substring(linkBegin + 6, linkEnd - 1);
        let afterText = message.substring(linkEnd + 7);
        if (message.indexOf("||") > -1) {
          let linkUrl = linkInterior.split("||")[0];
          let linkText = linkInterior.split("||")[1];
          linkInfo = [beforeText, linkText, linkUrl, afterText];
        }
      }
    }
    return linkInfo;
  }

  function prepareMessage(message) {
    const msg = message || "";

    // Replace any links in message
    var li = getLinkInfo(msg);
    if (li.length === 4) {
      return getMessageWithLink(li[0], li[1], li[2], li[3]);
    }

    return <p>{msg}</p>;
  }

  return (
    <>
      <Modal show={showModal} onHide={onClose} animation={!disableAnimations()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
            <HelpLink path="/General/Dialogs&anchor=info-dialog" label="" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ lineHeight: "24px" }}>{prepareMessage(text)}</div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onClose}
            style={{ marginLeft: "12px" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InfoDialog;
