import React, { useState, useEffect } from "react";
import TabControl from "../../common/layout/TabControl";
import ReportResultsDefault from "./ReportResultsDefault";
import ReportResultsTemplate from "./ReportResultsTemplate";
import { useReports } from "../../../contexts/ReportsContext";
import { useTimeout } from "../../../hooks/useTimeout";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import useApi from "../../../hooks/useApi";
import { apiRunReport, apiRunReportForDownload } from "../../../api/ReportsApi";
import { download, getExtensionFromFilename } from "../../../services/General";

function ReportResults({
  report,
  reportParameters,
  runNumber,
  runDate,
  externalDataRuleTableId,
  lastRunValues,
  onRunReport,
  hasUnsavedChanges = false,
  runReportOnLoad = false,
}) {
  const { reportsData, setReportsData } = useReports();
  const { loading, api: apiRun } = useApi(apiRunReport);
  const { loading: downloading, api: apiDownload } = useApi(
    apiRunReportForDownload
  );
  const [hasReportRun, setHasReportRun] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [parameters, setParameters] = useState([]);

  const [resultsViewType, setResultsViewType] = useState(
    (report?.reportTemplateId ?? "") === ""
      ? "Default Format"
      : "Template Format"
  );

  const hasTemplate =
    (report?.reportTemplateId || { value: "-1" }).value !== "-1" ?? false;

  useEffect(() => {
    // Rebuild the parameters array
    const _parameters = [];

    for (let i = 0; i < reportParameters.length; i++) {
      _parameters.push({ ...reportParameters[i], reportParameterType: "Data" });
    }

    _parameters.push({
      id: "runNumber",
      value: runNumber,
      reportParameterType: "Template",
    });
    _parameters.push({
      id: "lastRunDate",
      value: runDate,
      reportParameterType: "Template",
    });
    _parameters.push({
      id: "ruleTableId",
      value: externalDataRuleTableId,
      reportParameterType: "Template",
    });

    for (let i = 0; i < lastRunValues.length; i++) {
      _parameters.push({
        id: lastRunValues[i].name,
        value: lastRunValues[i].value,
        reportParameterType: "LastRunValue",
      });
    }

    setParameters(_parameters);
  }, [reportParameters, externalDataRuleTableId, lastRunValues]);

  useEffect(() => {
    if (loadData) {
      runReport(reportsData.resultsSearch);
    }
  }, [loadData]);

  // Use the useTimeout hook so react parameter changes can be seen after they get set. The normal setTimeout freezes
  //   state when initially called so the auto-set parameters are not seen and a required parameter error is displayed.
  useTimeout(() => {
    if (runReportOnLoad) {
      setLoadData(true);
    }
  }, 500);

  async function runReport(search) {
    if (!onRunReport()) {
      notifyWarn("Please correct the errors before running the report.");
      return;
    }
    setHasReportRun(true);

    apiRun.call(
      { id: report.id, search: search, parameters },
      (result) => {
        if (hasTemplate) {
          setReportsData({
            type: ContextProviderActions.loadReportTemplateContentResults,
            payload: {
              content:
                (result.templateData || []).length > 0
                  ? result.templateData[0].templateContents
                  : "", // Templates are now in an array for processes
            },
          });
        }

        // Even if we are formatting with template, user can still view default
        setReportsData({
          type: ContextProviderActions.loadReportResults,
          payload: {
            results: result.resources,
            count: result.count,
            columns: result.columns,
          },
        });
      },
      () => {
        return true;
      }
    );
  }

  async function handleDownloadReport(isTemplate) {
    if (!onRunReport()) {
      notifyWarn("Please correct the errors before downloading the report.");
      return;
    }

    apiDownload.call(
      {
        id: report.id,
        parameters,
        isTemplate: isTemplate,
        importReadyMode: isTemplate
          ? false
          : reportsData.resultsSearch.importReadyMode,
      },
      (result) => {
        const filename = result.FileName;
        const ext = getExtensionFromFilename(filename);
        const contents = result.FileContents;
        download(contents, filename, ext);
        notifySuccess("Report downloaded successfully");
      }
    );
  }

  async function handleRunReport(search) {
    if (!loading) {
      runReport(search);
    }
  }

  return (
    <>
      {hasTemplate === true ? (
        <TabControl
          activeTab={resultsViewType}
          setActiveTab={setResultsViewType}
          showTabHeader={true}
          sticky={false}
          tab1Title={"Default Format"}
          tab2Title={"Template Format"}
          tab1Children={
            <div style={{ marginTop: "20px" }}>
              <ReportResultsDefault
                report={report}
                hasReportRun={hasReportRun}
                downloadingReport={downloading}
                loadingReport={loading}
                hasUnsavedChanges={hasUnsavedChanges}
                onRunReport={handleRunReport}
                onDownloadReport={handleDownloadReport}
              />
            </div>
          }
          tab2Children={
            <div style={{ marginTop: "20px" }}>
              <ReportResultsTemplate
                hasReportRun={hasReportRun}
                downloadingReport={downloading}
                loadingReport={loading}
                hasUnsavedChanges={hasUnsavedChanges}
                onRunReport={handleRunReport}
                onDownloadReport={handleDownloadReport}
              />
            </div>
          }
        />
      ) : (
        <ReportResultsDefault
          report={report}
          hasReportRun={hasReportRun}
          downloadingReport={downloading}
          loadingReport={loading}
          hasUnsavedChanges={hasUnsavedChanges}
          onRunReport={handleRunReport}
          onDownloadReport={handleDownloadReport}
        />
      )}
    </>
  );
}

export default ReportResults;
