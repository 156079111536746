import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

function DatePickerInput({
  name,
  id,
  value,
  label,
  disabled,
  error,
  onChange,
  onBlur,
  onSelect,
  placeholder,
  labelStyle,
  showTimeInput,
  autoFocus = false,
  showPopupCalendar = true,
}) {
  function onLocalChange(date) {
    // jon, 6/28/24: Remove time portion of date before being returned if only showing date
    if (showTimeInput) {
      onChange(date);
    } else {
      const dateNoTime =
        date === null
          ? null
          : new Date(date.getFullYear(), date.getMonth(), date.getDate());
      onChange(dateNoTime);
    }
  }

  let allowOpenPopup = undefined;
  if (showPopupCalendar === false) {
    allowOpenPopup = false;
  }

  let wrapperClass = "form-group";
  if (error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <div className={wrapperClass} style={{ maxWidth: "170px" }}>
      <label htmlFor={id} style={labelStyle || {}}>
        {label}
      </label>
      <div className="field">
        <DatePicker
          id={id}
          name={name}
          className="form-control"
          placeholder={placeholder}
          onChange={onLocalChange}
          onBlur={onBlur}
          onSelect={onSelect}
          selected={value}
          showTimeInput={
            typeof showTimeInput === "undefined" ? true : showTimeInput
          }
          todayButton="Today"
          dateFormat={
            typeof showTimeInput === "undefined" || showTimeInput === true
              ? "Pp"
              : "MM/dd/yyyy"
          }
          disabled={disabled ? "disabled" : ""}
          autoFocus={autoFocus || false}
          open={allowOpenPopup}
        />
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

DatePickerInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};

DatePickerInput.defaultProps = {
  error: "",
};

export default DatePickerInput;
