import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { usePacketParser } from "../../../contexts/PacketParserContext";
import { useMobile } from "../../../hooks/useMobile";
import { useHref } from "react-router-dom";

function ParsedPacketField({
  field,
  isReadOnly,
  fields,
  displayType = "condensed",
  setDisplayType,
}) {
  const { isMobileSize } = useMobile();
  const hrefConfigureDrugsScreen = useHref("/configuredrugs");
  const hrefConfigureServiceProvidersScreen = useHref(
    "/configureserviceproviders"
  );
  const hrefConfigurePrescribersScreen = useHref("/configureprescribers");
  const hrefConfigureMembersScreen = useHref("/configuremembers");
  const hrefConfigureGroupsScreen = useHref("/configuregroups");
  const hrefClaimsScreen = useHref("/claims");
  const hrefDiscountScreen = useHref("/discount");
  const { packetParserData, setPacketParserData } = usePacketParser();

  function onFieldClick() {
    setPacketParserData({
      type: ContextProviderActions.setSelectedFieldId,
      payload: { isRequest: field.isRequest, fieldId: field.id },
    });
    if (displayType === "condensed" && setDisplayType) {
      setDisplayType("expanded");
    }
  }

  function doesFieldMatch(f) {
    if (
      f.transactionIndex === field.transactionIndex &&
      f.fieldGroupOrdinal === field.fieldGroupOrdinal &&
      f.fieldId === field.fieldId
    ) {
      return true;
    }

    return false;
  }

  function getFieldData() {
    // Locate data about this field in the fields array, checking for subFields too.
    let f, s1, s2;
    for (let i = 0; i < fields.length; i++) {
      f = fields[i];

      // Set subfield level on returned field so the UI can indicate the level somehow.
      if (field.segmentCode === f.segmentCode && doesFieldMatch(f)) {
        f.subFieldLevel = 0;
        return f;
      }

      // Check for subfields
      if ((f.subFields || []).length > 0) {
        for (let j = 0; j < f.subFields.length; j++) {
          s1 = f.subFields[j];

          if (field.segmentCode === f.segmentCode && doesFieldMatch(s1)) {
            s1.subFieldLevel = 1;
            return s1;
          }

          // Check for one last layer of subfields
          if ((s1.subFields || []).length > 0) {
            for (let j = 0; j < s1.subFields.length; j++) {
              s2 = s1.subFields[j];

              if (field.segmentCode === f.segmentCode && doesFieldMatch(s2)) {
                s2.subFieldLevel = 2;
                return s2;
              }
            }
          }
        }
      }
    }

    //If we made it here, we did not find the field
    return null;
  }

  function transformVal(fld) {
    var val = fld.value ? "" + fld.value.data : "";
    //Check numeric
    if (fld.fieldDecimalCount > 0) {
      return Number(val).toFixed(fld.fieldDecimalCount);
    }
    return val;
  }

  function handleClickConfigureScreenLink(url, searchTerm, tabName) {
    // Save search term in local storage so we can access it in the other tab
    localStorage.setItem("TEST_PROCESSOR_SEARCH_TERM", _.trim(searchTerm));

    window.open(url, tabName);
  }

  function getSearchLink(linkTitle, linkText, searchTerm, url) {
    const link = (
      <button
        type="button"
        title={linkTitle}
        className="btn btn-link"
        style={{ textDecoration: "underline" }}
        onClick={() =>
          handleClickConfigureScreenLink(url, searchTerm, linkTitle)
        }
      >
        {linkText}
      </button>
    );

    return link;
  }

  function getDirectLink(linkTitle, linkText, url) {
    const link = (
      <button
        type="button"
        title={linkTitle}
        className="btn btn-link"
        style={{ textDecoration: "underline" }}
        onClick={() => window.open(url, linkTitle)}
      >
        {linkText}
      </button>
    );

    return link;
  }

  function buildMemberAndDiscountLinks(value) {
    if (_.isEmpty(value)) return <></>;

    // Do we have a member and/or a discount?
    let linkMember = <></>;
    let linkDiscount = <></>;
    const posMember = value.indexOf("Member:");
    const posDiscount = value.indexOf("Discount:");

    // Extract member info in the form Member:searchterm
    //  Member always comes first if there is one.
    if (posMember >= 0) {
      const memberData =
        posDiscount < 0
          ? _.trim(value.substring(posMember))
          : _.trim(value.substring(posMember, posDiscount));
      const searchTerm = memberData.split(":")[1];
      linkMember = getSearchLink(
        "View member details",
        searchTerm,
        searchTerm,
        hrefConfigureMembersScreen
      );
      linkMember = (
        <StyledActualValue>
          Member:&nbsp;
          {linkMember}
        </StyledActualValue>
      );
    }

    // Extract discount info in the form Discount:SelectedDiscountId/DiscountPlan
    if (posDiscount >= 0) {
      const discountData = _.trim(value.substring(posDiscount + 9));
      const discountDataParts = discountData.split("/");
      linkDiscount = getDirectLink(
        "View selected discount",
        discountDataParts[1],
        hrefDiscountScreen + "/" + discountDataParts[0]
      );
      linkDiscount = (
        <StyledActualValue>
          Discount:&nbsp;
          {linkDiscount}
        </StyledActualValue>
      );
    }

    const finalLinks = (
      <>
        {linkMember}&nbsp;{linkDiscount}
      </>
    );
    return finalLinks;
  }

  function getFieldValueDescription(fn, value, data) {
    if (displayType === "condensed" || data === null) return <></>;

    // If the field has a metadata description, use that. Otherwise, transform it.
    let transformedValue = "";
    const hasMetadata = data.value && data.value.metadata ? true : false;

    if (hasMetadata && !_.isEmpty(data.value.metadata.description)) {
      transformedValue = data.value.metadata.description;
    } else if (hasMetadata && !_.isEmpty(data.value.metadata.summary)) {
      transformedValue = data.value.metadata.summary;
    } else {
      transformedValue = _.trim(transformVal(data));
    }

    // For certain fields, provide a link to the config page
    let link = <>{transformedValue}</>;
    let linkTitle = "";
    let linkUrl = "";
    let searchTerm = value;

    if (fn === "Product/Service Id") {
      linkTitle = "View drug details";
      linkUrl = hrefConfigureDrugsScreen;
    } else if (fn === "Service Provider Id") {
      linkTitle = "View service provider details";
      linkUrl = hrefConfigureServiceProvidersScreen;
    } else if (fn === "Prescriber Id") {
      linkTitle = "View prescriber details";
      linkUrl = hrefConfigurePrescribersScreen;
    } else if (fn === "Cardholder Id") {
      const memberLink = buildMemberAndDiscountLinks(transformedValue);
      return (
        <StyledActualValue>
          &nbsp;
          {memberLink}
        </StyledActualValue>
      );
    } else if (fn === "Group Id") {
      linkTitle = "View group details";
      linkUrl = hrefConfigureGroupsScreen;
    } else if (fn === "Prescription/Service Reference Number") {
      linkTitle = "View all claims for this prescription";
      linkUrl = hrefClaimsScreen;
    }

    if (!_.isEmpty(linkTitle)) {
      link = getSearchLink(linkTitle, transformedValue, searchTerm, linkUrl);
    } else if (_.trim(value) === transformedValue) {
      // Do not show transformed data if it is the same as we are already showing
      return <></>;
    }

    return (
      <StyledActualValue>
        &nbsp;
        {link}
      </StyledActualValue>
    );
  }

  const isFieldSelected =
    packetParserData.selectedFieldId.isRequest === field.isRequest &&
    packetParserData.selectedFieldId.fieldId === field.id;

  const fieldData = getFieldData();
  const fieldName = fieldData ? fieldData.fieldName : "";

  return (
    <StyledFieldSpan
      id={`FLD-${field.id}`}
      className={`${displayType === "condensed" ? "parser-field-hover" : ""} ${
        isFieldSelected ? "selected-field" : ""
      }`}
      onClick={onFieldClick}
      displayType={displayType}
    >
      {field.isHeaderField ? (
        <>
          {displayType !== "condensed" && (
            <StyledFieldName>
              {!_.isEmpty(fieldName) ? fieldName : field.fieldId}
            </StyledFieldName>
          )}
          <StyledValueSpan key={`VL${field.id}`}>
            {field.value}
            {getFieldValueDescription(fieldName, field.value, fieldData)}
          </StyledValueSpan>
        </>
      ) : (
        <LevelIndentBlockDiv
          displayType={displayType}
          subFieldLevel={fieldData?.subFieldLevel || 0}
        >
          <StyledFSSpan
            key={`FS${field.id}`}
            displayType={displayType}
            className={
              isFieldSelected
                ? "selected-field parser-control-char"
                : "parser-control-char"
            }
          >
            FLD
          </StyledFSSpan>
          <StyledCharCodeSpan key={`CC${field.id}`}>
            {field.fieldId}
          </StyledCharCodeSpan>{" "}
          {displayType !== "condensed" && (
            <StyledFieldName>
              {!_.isEmpty(fieldName) ? fieldName : field.fieldId}
            </StyledFieldName>
          )}
          <StyledValueSpan
            key={`VL${field.id}`}
            displayType={displayType}
            isMobileSize={isMobileSize}
          >
            {field.value}
            {getFieldValueDescription(fieldName, field.value, fieldData)}
          </StyledValueSpan>
          {displayType === "detail" &&
            fieldData &&
            fieldData.value &&
            fieldData.value.metadata &&
            fieldData.value.metadata.summary && (
              <StyledFieldDescription>
                {fieldData.value.metadata.summary}
              </StyledFieldDescription>
            )}
        </LevelIndentBlockDiv>
      )}
    </StyledFieldSpan>
  );
}

const LevelIndentBlockDiv = styled.div`
  position: relative;
  margin-left: ${(props) =>
    props.displayType === "condensed" || props.subFieldLevel === 0
      ? "0"
      : `${props.subFieldLevel * 20}px`};
  border-left: ${(props) =>
    props.displayType === "condensed" || props.subFieldLevel === 0
      ? "none"
      : "1px solid var(--text-light)"};
  padding-left: ${(props) =>
    props.displayType === "condensed" || props.subFieldLevel === 0
      ? "0"
      : `${props.subFieldLevel * 4}px`};

  &:after {
    content: "";
    width: ${(props) =>
      props.displayType === "condensed" || props.subFieldLevel === 0
        ? "0"
        : `20px`};
    height: ${(props) =>
      props.displayType === "condensed" || props.subFieldLevel === 0
        ? "0"
        : `1px`};
    background: var(--text-light);
    position: absolute;
    left: 0;
    bottom: -1px;
  }
`;

const StyledFieldDescription = styled.div`
  color: var(--notify-info);
  font-size: 14px;
  font-family: "Source Sans Pro";
  font-style: italic;
  margin-left: 85px;
  margin-bottom: 10px;
`;

const StyledFieldName = styled.span`
  position: relative;
  top: -2px;
  font-size: 14px;
  font-family: "Source Sans Pro";
  display: inline;
  margin-right: 10px;
  color: var(--text-medium);
`;

const StyledFieldSpan = styled.span`
  display: ${(props) =>
    props.displayType === "condensed" ? "inline-block" : "block"};
  margin-left: ${(props) => (props.displayType === "condensed" ? "0" : "20px")};

  border: 2px solid transparent;

  &.selected-field,
  &:active {
    background-color: var(--table-hover-row-bg) !important;
    border-color: var(--table-hover-row-border);
    font-weight: bold;
  }
`;

const StyledFSSpan = styled.span`
  display: ${(props) =>
    props.displayType === "condensed" ? "inline-block" : "block"};
  margin-left: ${(props) => (props.displayType === "condensed" ? "0" : "20px")};

  color: var(--text-normal);
  background-color: var(--text-light);
  font-weight: bold;
  border: 1px solid transparent;
  line-height: 15px;

  &.selected-field {
    border-color: var(--table-hover-row-border);
  }
`;

const StyledCharCodeSpan = styled.span`
  color: var(--text-normal);
  background-color: transparent;
  position: relative;
  top: -2px;
  font-family: courier;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 4px;
  border-radius: 4px;
  margin-left: 2px;
  margin-right: 2px;
`;

const StyledValueSpan = styled.span`
  display: ${(props) =>
    props.displayType !== "condensed" && props.isMobileSize
      ? "block"
      : "inline-block"};

  margin-left: ${(props) =>
    props.displayType !== "condensed" && props.isMobileSize ? "85px" : "0"};

  position: relative;
  top: -2px;
  color: var(--text-normal);
  background-color: transparent;
  font-family: courier;
  font-size: 16px;
  padding: 0 2px;
`;

const StyledActualValue = styled.span`
  color: var(--notify-info);
`;

export default ParsedPacketField;
