import React, { useState, useEffect } from "react";
import GroupRuleMapsList from "./GroupRuleMapsList";
import GroupRuleMapSearchForm from "./GroupRuleMapSearchForm";
import Spinner from "../../common/ui/Spinner";
import {
  createViewModel,
  emptyGroupRuleMapSearch,
} from "../../../viewmodels/groupRuleMapsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useGroupRuleMaps } from "../../../contexts/GroupRuleMapsContext";
import { useClients } from "../../../contexts/ClientsContext";
import { apiLoadGroupRuleMaps } from "../../../api/GroupRuleMapApi";
import { apiLoadClientsAll } from "../../../api/ClientApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function GroupRuleMaps() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { groupRuleMapsData, setGroupRuleMapsData } = useGroupRuleMaps();
  const { clientsData, setClientsData } = useClients();
  const { loading, api: apiLoad } = useApi(apiLoadGroupRuleMaps);
  const { loading: loadingClients, api: apiLoadClients } =
    useApi(apiLoadClientsAll);
  const [loadData, setLoadData] = useState(true);
  const { isMobileSize } = useMobile();

  let groupRuleMaps = [];
  if (groupRuleMapsData && groupRuleMapsData.groupRuleMaps) {
    groupRuleMaps = groupRuleMapsData.groupRuleMaps;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setGroupRuleMapsData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //   useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadGroupRuleMapsAndClients();
    }
  }, [auth.authenticated, loadData]);

  async function loadGroupRuleMapsAndClients() {
    const clientList = await loadClientListIfNeeded();

    apiLoad.call(
      groupRuleMapsData.search,
      (result) => {
        setLoadData(false);
        const count = result.count || 0;

        // Save the list of clients in the cache
        setClientsData({
          type: ContextProviderActions.setClientListCache,
          payload: clientList,
        });

        // GroupRuleMap viewmodel needs the client list
        const vms = result.resources.map((r) => createViewModel(r, clientList));

        setGroupRuleMapsData({
          type: ContextProviderActions.loadGroupRuleMaps,
          payload: {
            groupRuleMaps: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  async function loadClientListIfNeeded() {
    if ((clientsData.clientListCache || []).length > 0) {
      return clientsData.clientListCache;
    }

    return await apiLoadClients.call(null, async (result) => {
      return result.resources.map((c) => {
        return { value: c.id, label: c.name };
      });
    });
  }

  function setSearchChanges(search) {
    setGroupRuleMapsData({
      type: ContextProviderActions.saveGroupRuleMapSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyGroupRuleMapSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : groupRuleMapsData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = groupRuleMapsData.search.orderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = groupRuleMapsData.search.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...groupRuleMapsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...groupRuleMapsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...groupRuleMapsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...groupRuleMapsData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...groupRuleMapsData.search,
      [target.name]: target.value,
    });
  }

  function handleActiveChange({ target }) {
    setSearchChanges({ ...groupRuleMapsData.search, active: target.checked });
  }

  function handleClientChange(value) {
    setSearchChanges({ ...groupRuleMapsData.search, clientId: value });
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (groupRuleMapsData.search.clientId !== "") numFilters++;
    if (groupRuleMapsData.search.groupId !== "") numFilters++;
    if (groupRuleMapsData.search.name !== "") numFilters++;
    if (groupRuleMapsData.search.active !== true) numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        {((groupRuleMapsData.search.showAdvancedFilter && !isMobileSize) ||
          !groupRuleMapsData.search.showAdvancedFilter) && (
          <HelpLink path="/Configure/GroupRuleMaps-List-Screen" label="Help" />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Group Rule Maps</h1>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search Group Id or Name"
        search={groupRuleMapsData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />

      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              groupRuleMapsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={groupRuleMapsData.search}
            setSearch={setSearchChanges}
            helpLink="/Configure/GroupRuleMaps-List-Screen&anchor=filters"
          >
            <GroupRuleMapSearchForm
              errors={errors}
              search={groupRuleMapsData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onActiveChange={handleActiveChange}
              onClientChange={handleClientChange}
              clientList={clientsData.clientListCache}
            />
          </GridAdvancedFilter>
        </div>

        {groupRuleMapsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || loadingClients ? (
              <Spinner />
            ) : (
              <GroupRuleMapsList
                groupRuleMaps={groupRuleMaps}
                search={groupRuleMapsData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={groupRuleMapsData.count}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default GroupRuleMaps;
