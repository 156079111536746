import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyPacketRequest,
  emptyPacketResponse,
} from "../viewmodels/packetParserVm";

export const PacketParserTabs = {
  Tab1: "Parser Contents",
  Tab2: "Fields",
};

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.savePacketRequest:
      return {
        ...state,
        packetRequest: action.payload,
        navigateToUrl: "",
      };

    case ContextProviderActions.savePacketRequestAndNavigate:
      return {
        ...state,
        packetRequest: action.payload.packetRequest,
        navigateToUrl: action.payload.url,
      };

    case ContextProviderActions.savePacketResponse:
      return {
        ...state,
        packetResponse: action.payload,
        navigateToUrl: "",
      };

    case ContextProviderActions.setSelectedFieldId:
      return {
        ...state,
        selectedFieldId: action.payload,
        activeTab:
          state.activeTab === PacketParserTabs.Tab1
            ? PacketParserTabs.Tab2
            : PacketParserTabs.Tab1,
      };

    case ContextProviderActions.setActivePacketParserTab:
      return {
        ...state,
        activeTab: action.payload,
      };

    case ContextProviderActions.setPacketParserDisplayType:
      return {
        ...state,
        displayType: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in PacketParserContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  packetRequest: emptyPacketRequest,
  packetResponse: emptyPacketResponse,
  navigateToUrl: "",
  selectedFieldId: { isRequest: true, fieldID: "" },
  activeTab: PacketParserTabs.Tab1,
  displayType: { label: "Condensed View", value: "condensed" },
};

const PacketParser = createContext({
  packetParserData: initialState,
  setPacketParserData: () => null,
});

// eslint-disable-next-line react/prop-types
const PacketParserProvider = ({ children }) => {
  const [packetParserData, setPacketParserData] = useReducer(
    reducer,
    initialState
  );

  return (
    <PacketParser.Provider value={{ packetParserData, setPacketParserData }}>
      {children}
    </PacketParser.Provider>
  );
};

export default PacketParserProvider;
export const usePacketParser = () => useContext(PacketParser);
