import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.setWebSocketDbUtilsEventState:
      return {
        ...state,
        trafficDbUtilsStateUpdates: action.payload,
      };
    case ContextProviderActions.setWebSocketTestDataPopulationEventState:
      return {
        ...state,
        trafficTestDataPopulationStateUpdate: action.payload,
      };
    case ContextProviderActions.setWebSocketTestRunnerEventState:
      return {
        ...state,
        trafficTestRunnerStateUpdate: action.payload,
      };
    case ContextProviderActions.setWebSocketClaimsTrafficEventState:
      return {
        ...state,
        trafficClaims: action.payload,
      };
    case ContextProviderActions.setWebSocketDiagnosticsEventState:
      return {
        ...state,
        trafficDiagnostics: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in WebSocketConnectionContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  trafficDbUtilsStateUpdates: null,
  trafficTestDataPopulationStateUpdate: null,
  trafficTestRunnerStateUpdate: null,
  trafficClaims: null,
  trafficDiagnostics: null,
};

const WebSocketConnection = createContext({
  webSocketConnectionData: initialState,
  setWebSocketConnectionData: () => null,
});

// eslint-disable-next-line react/prop-types
const WebSocketConnectionProvider = ({ children }) => {
  const [webSocketConnectionData, setWebSocketConnectionData] = useReducer(
    reducer,
    initialState
  );

  return (
    <WebSocketConnection.Provider
      value={{
        webSocketConnectionData,
        setWebSocketConnectionData,
      }}
    >
      {children}
    </WebSocketConnection.Provider>
  );
};

export default WebSocketConnectionProvider;
export const useWebSocketConnectionEvents = () =>
  useContext(WebSocketConnection);
