import React, { useState, useEffect } from "react";
import PriorAuthorizationList from "./PriorAuthorizationList";
import PriorAuthSearchForm from "./PriorAuthSearchForm";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import {
  createViewModel,
  emptyPriorAuthorizationSearch,
} from "../../../viewmodels/priorAuthorizationsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { usePriorAuthorizations } from "../../../contexts/PriorAuthorizationsContext";
import {
  apiLoadPriorAuthorizations,
  apiDeletePriorAuthorization,
} from "../../../api/PriorAuthApi";
import { useNavigate } from "react-router-dom";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function PriorAuthorizations() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const { priorAuthorizationsData, setPriorAuthorizationsData } =
    usePriorAuthorizations();
  const { loading, api: apiLoad } = useApi(apiLoadPriorAuthorizations);
  const { loading: deleting, api: apiDelete } = useApi(
    apiDeletePriorAuthorization
  );
  const [loadData, setLoadData] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const { isMobileSize } = useMobile();

  let priorAuthorizations = [];
  if (priorAuthorizationsData && priorAuthorizationsData.priorAuthorizations) {
    priorAuthorizations = priorAuthorizationsData.priorAuthorizations;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setChainsData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //   useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadPriorAuthorizations();
    }
  }, [auth.authenticated, loadData]);

  async function loadPriorAuthorizations() {
    apiLoad.call(
      priorAuthorizationsData.search,
      (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setPriorAuthorizationsData({
          type: ContextProviderActions.loadPriorAuthorizations,
          payload: {
            priorAuthorizations: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setPriorAuthorizationsData({
      type: ContextProviderActions.savePriorAuthorizationSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyPriorAuthorizationSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : priorAuthorizationsData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};
    if (
      _.has(priorAuthorizationsData.search, "priorAuthorizationNumber") &&
      priorAuthorizationsData.search.priorAuthorizationNumber.length > 11
    )
      _errors.priorAuthorizationNumber =
        "Prior Authorization Number is too long";
    if (
      _.has(priorAuthorizationsData.search, "precriptionNumber") &&
      !_.isInteger(priorAuthorizationsData.search.prescriptionNumber)
    )
      _errors.precriptionNumber = "Prescription Number must be a valid number";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = priorAuthorizationsData.search.orderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = priorAuthorizationsData.search.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...priorAuthorizationsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...priorAuthorizationsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...priorAuthorizationsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...priorAuthorizationsData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...priorAuthorizationsData.search,
      [target.name]: target.value,
    });
  }

  function handleActiveChange({ target }) {
    setSearchChanges({
      ...priorAuthorizationsData.search,
      active: target.checked,
    });
  }

  async function deletePriorAuthorization(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Prior Authorization disabled successfully");
      setLoadData(true);
    });
  }

  async function handleDelete(id, priorAuthorizationNumber) {
    setDeleteId(id);
    setAltDeleteId(priorAuthorizationNumber);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deletePriorAuthorization(deleteId);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (priorAuthorizationsData.search.groupId !== "") numFilters++;
    if (priorAuthorizationsData.search.pharmacy !== "") numFilters++;
    if (priorAuthorizationsData.search.prescriptionNumber !== "") numFilters++;
    if (priorAuthorizationsData.search.priorAuthorizationNumber !== "")
      numFilters++;
    if (priorAuthorizationsData.search.memberId !== "") numFilters++;
    if (priorAuthorizationsData.search.productId !== "") numFilters++;
    if (priorAuthorizationsData.search.active !== true) numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        {((priorAuthorizationsData.search.showAdvancedFilter &&
          !isMobileSize) ||
          !priorAuthorizationsData.search.showAdvancedFilter) && (
          <HelpLink
            path="/Configure/Prior-Authorizations-List-Screen"
            label="Help"
          />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Prior Authorizations</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/priorauthorization")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Prior Auth
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search"
        search={priorAuthorizationsData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <ConfirmDialog
        title="Remove Prior Authorization"
        question={`Are you sure you wish to deactivate the prior authorization '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              priorAuthorizationsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={priorAuthorizationsData.search}
            setSearch={setSearchChanges}
            helpLink="/Configure/Prior-Authorizations-List-Screen&anchor=filters"
          >
            <PriorAuthSearchForm
              errors={errors}
              search={priorAuthorizationsData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onActiveChange={handleActiveChange}
            />
          </GridAdvancedFilter>
        </div>

        {priorAuthorizationsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || deleting ? (
              <Spinner />
            ) : (
              <PriorAuthorizationList
                priorAuthorizations={priorAuthorizations}
                onDelete={handleDelete}
                search={priorAuthorizationsData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={priorAuthorizationsData.count}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default PriorAuthorizations;
