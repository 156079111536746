import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyLogHeaderSearch, emptyLogSearch } from "../viewmodels/logsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadLogs:
      return {
        ...state,
        logs: action.payload,
      };

    case ContextProviderActions.loadLogHeaders:
      return {
        ...state,
        logHeaders: action.payload.logHeaders,
        logHeadersCount: action.payload.logHeadersCount,
      };

    case ContextProviderActions.saveLogSearch:
      return {
        ...state,
        logSearch: action.payload,
      };

    case ContextProviderActions.saveLogHeaderSearch:
      return {
        ...state,
        logHeaderSearch: action.payload,
      };

    default:
      throw new Error(`Unhandled action type in LogsContext: ${action.type}`);
  }
}

const initialState = {
  logs: [],
  logHeaders: [],
  logSearch: emptyLogSearch,
  logHeaderSearch: emptyLogHeaderSearch,
  logHeadersCount: 0,
};

const Logs = createContext({
  logsData: initialState,
  setLogsData: () => null,
});

// eslint-disable-next-line react/prop-types
const LogsProvider = ({ children }) => {
  const [logsData, setLogsData] = useReducer(reducer, initialState);

  return (
    <Logs.Provider value={{ logsData, setLogsData }}>{children}</Logs.Provider>
  );
};

export default LogsProvider;
export const useLogs = () => useContext(Logs);
