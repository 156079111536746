import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyAuditBulkChangeRecordsSearch,
  emptyAuditDocument,
  emptyAuditDocumentSearch,
  emptyAuditRecordsSearch,
} from "../viewmodels/auditVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadAuditRecords:
      return {
        ...state,
        auditRecords: action.payload.auditRecords,
        count: action.payload.count,
      };

    case ContextProviderActions.saveAuditSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadAuditBulkChangeRecords:
      return {
        ...state,
        bulkUser: action.payload.bulkUser,
        bulkDate: action.payload.bulkDate,
        auditBulkChangeRecords: action.payload.auditBulkChangeRecords,
        bulkChangeCount: action.payload.bulkChangeCount,
      };

    case ContextProviderActions.saveAuditBulkChangeSearch:
      return {
        ...state,
        bulkChangeSearch: action.payload,
      };

    case ContextProviderActions.loadAuditRecord:
      return {
        ...state,
        auditDocRecord: action.payload,
      };

    case ContextProviderActions.saveAuditRecordSearch:
      return {
        ...state,
        auditDocSearch: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in AuditContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  auditRecords: [],
  auditDocRecord: emptyAuditDocument,
  auditDocSearch: emptyAuditDocumentSearch,
  search: emptyAuditRecordsSearch,
  count: 0,
  bulkUser: "",
  bulkDate: "",
  auditBulkChangeRecords: [],
  bulkChangeSearch: emptyAuditBulkChangeRecordsSearch,
  bulkChangeCount: 0,
};

const Audit = createContext({
  auditData: initialState,
  setAuditData: () => null,
});

// eslint-disable-next-line react/prop-types
const AuditProvider = ({ children }) => {
  const [auditData, setAuditData] = useReducer(reducer, initialState);

  return (
    <Audit.Provider value={{ auditData, setAuditData }}>
      {children}
    </Audit.Provider>
  );
};

export default AuditProvider;
export const useAudit = () => useContext(Audit);
