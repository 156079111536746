import React from "react";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";

import TextInput from "../../common/input/TextInput";

function RuleAreaGroupsAndTags({ ruleChanges, handleRuleChange, errors }) {
  return (
    <>
      <StyledRowDiv className="row">
        <div className="col-12 col-lg-6">
          <TextInput
            id="includedGroups"
            label="Included Groups"
            onChange={handleRuleChange}
            placeholder="Included Groups"
            name="includedGroupsVm"
            value={ruleChanges.includedGroupsVm}
            error={errors.includedGroups}
          />
        </div>
        <div className="col-12 col-lg-6">
          <TextInput
            id="excludedGroups"
            label="Excluded Groups"
            onChange={handleRuleChange}
            placeholder="Excluded Groups"
            name="excludedGroupsVm"
            value={ruleChanges.excludedGroupsVm}
            error={errors.excludedGroups}
          />
        </div>
        <div className="col-12 col-lg-6">
          <TextInput
            id="includedTags"
            label="Included Tags"
            onChange={handleRuleChange}
            placeholder="Included Tags"
            name="includedTagsVm"
            value={ruleChanges.includedTagsVm}
            error={errors.includedTags}
          />
        </div>
      </StyledRowDiv>
    </>
  );
}

export default RuleAreaGroupsAndTags;
