import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createViewModel,
  fromViewModel,
  emptyTestGroup,
  emptyTestGroupTestSearch,
} from "../../../viewmodels/testGroupsVm";
import {
  formatDateTimeUtcZoneForDisplay,
  formatDecimal,
  generateUUID,
  getTimeframeFromMilliseconds,
  handleCollapseExpandAll,
} from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import { useTestGroups } from "../../../contexts/TestGroupsContext";
import { useAuth } from "../../../contexts/AuthContext";
import {
  apiGetAvailableTests,
  apiAddTestGroup,
  apiLoadTestGroup,
  apiUpdateTestGroup,
  apiRunTest,
  apiUpdateTestGroupResult,
} from "../../../api/TestApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import ReadOnly from "../../common/input/ReadOnly";
import StatusPill from "../../common/ui/StatusPill";
import TestGroupTestList from "./TestGroupTestList";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import TestGroupTestDialog from "./TestGroupTestDialog";
import { emptyTest } from "../../../viewmodels/testsVm";
import HelpLink from "../../common/ui/HelpLink";
import SimpleCompletionBar from "../../common/ui/SimpleCompletionBar";

function TestGroup() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { testGroupsData, setTestGroupsData } = useTestGroups();
  const { loading, api: apiLoad } = useApi(apiLoadTestGroup);
  const { loading: loadingTests, api: apiLoadAllTests } =
    useApi(apiGetAvailableTests);
  const { loading: adding, api: apiAdd } = useApi(apiAddTestGroup);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateTestGroup);
  const { api: apiRunSingleTest } = useApi(apiRunTest);
  const { api: apiSaveGroupResult } = useApi(apiUpdateTestGroupResult);
  const [editItem, setEditItem] = useState(emptyTest);
  const [showTestDialog, setShowTestDialog] = useState(false);
  const [testsForDialog, setTestsForDialog] = useState([]);
  const [errors, setErrors] = useState({});
  const [lastSavedChanges, setLastSavedChanges] = useState(emptyTestGroup);
  const [changes, setChanges] = useState(emptyTestGroup);
  const [testSearch, setTestSearch] = useState(emptyTestGroupTestSearch);
  const [runMode, setRunMode] = useState(false);
  const [currentRunTestIndex, setCurrentRunTestIndex] = useState(-1);
  const [currentRunTestDescription, setCurrentRunTestDescription] =
    useState("");
  const [batchRunGuid, setBatchRunGuid] = useState(null);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Test Group", collapsed: false },
    { name: "Last Run Info", collapsed: false },
    { name: "Tests", collapsed: false },
  ]);

  const resId = params && params.id;
  const autoRun = params && params.autorun === "true";

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setTestGroupsData({
        type: ContextProviderActions.loadTestGroup,
        payload: emptyTestGroup,
      });

      loadTestGroup();
    }
  }, [auth.authenticated, params?.id]);

  // When current run test index changes, kick off the next test run
  useEffect(() => {
    if (
      runMode &&
      batchRunGuid !== null &&
      currentRunTestIndex >= 0 &&
      currentRunTestIndex < testGroupsData.testGroup.tests.length
    ) {
      const test = testGroupsData.testGroup.tests[currentRunTestIndex];
      setCurrentRunTestDescription(test.description);
      runNextTest();
    } else if (
      runMode &&
      currentRunTestIndex >= testGroupsData.testGroup.tests.length
    ) {
      // We are done - either user cancelled or we end the end of the loaded tests
      setCurrentRunTestDescription("Done! Saving group result...");
      saveTestGroupResult();
    }
  }, [runMode, currentRunTestIndex, batchRunGuid]);

  useEffect(() => {
    if (testGroupsData.testGroup) {
      setChanges(testGroupsData.testGroup);
    } else {
      setChanges(emptyTestGroup);
    }
  }, [testGroupsData.testGroup]);

  async function saveTestGroupResult() {
    // This just forces the api to determine and update the result from the test results.
    await apiSaveGroupResult.call(
      { testGroupId: resId, uniqueId: batchRunGuid },
      (result) => {
        const retModel = createViewModel(result);
        resetTestOrdinals(retModel);
        setChanges(retModel);
        setLastSavedChanges(retModel);
        setRunMode(false);
        setCurrentRunTestIndex(-1);
        setBatchRunGuid(null);
      }
    );
  }

  async function loadTestGroup(startingBulkRun = false) {
    if (!resId) {
      setTestGroupsData({
        type: ContextProviderActions.loadTestGroup,
        payload: emptyTestGroup,
      });
      setChanges(emptyTestGroup);
      await loadTests();
      return;
    }

    apiLoad.call(resId, async (result) => {
      let vm = emptyTestGroup;
      if (!result) {
        notifyError("Test Group does not exist");
      } else {
        vm = createViewModel(result);
      }

      resetTestOrdinals(vm);

      setTestGroupsData({
        type: ContextProviderActions.loadTestGroup,
        payload: vm,
      });
      setChanges(vm);
      setLastSavedChanges(vm);

      await loadTests();

      if (autoRun === true) {
        await handleRunTestGroupAsBatch();
      } // If we are starting a bulk run, go into run mode
      else if (startingBulkRun) {
        setRunMode(true);
        setCurrentRunTestIndex(0);
        setBatchRunGuid(generateUUID());
      }
    });
  }

  function resetTestOrdinals(vm) {
    // Reset all test ordinal values to be sequential since these can get messed up easily. They will get saved on next save, but not now.
    if ((vm.tests || []).length > 0) {
      vm.tests = vm.tests.sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1));
      for (let i = 0; i < vm.tests.length; i++) {
        vm.tests[i].ordinal = i;
      }
    }
  }

  async function runNextTest() {
    // Get the test id and description for the test we want to run.
    const test = testGroupsData.testGroup.tests[currentRunTestIndex];
    const id = test.id;

    apiRunSingleTest.call({ testId: id, uniqueId: batchRunGuid }, (result) => {
      // Set result in grid and update last run info for the row
      const retModel = createViewModel(result);
      const newRows = [...testGroupsData.testGroup.tests];
      const rowTest = newRows.find((t) => t.id === id);
      if (rowTest) {
        rowTest.lastRunDate = retModel.lastRunDate;
        rowTest.lastRunBy = retModel.lastRunBy;
        rowTest.lastRunDurationMilliseconds =
          retModel.lastRunDurationMilliseconds;
        rowTest.lastRunResultStatus = retModel.lastRunResultStatus;
      }

      // Increment to the next test - this kicks off the next test run in the useEffect to allow screen to update
      setCurrentRunTestIndex(currentRunTestIndex + 1);
    });
  }

  async function loadTests() {
    // Load all tests for use in dialog
    await apiLoadAllTests.call(null, (results) => {
      const vms = results.map((r) => ({
        ...emptyTest,
        id: r.Id,
        description: r.Description,
      }));
      setTestsForDialog(vms);
    });
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    if (changes.description.trim() === "")
      _errors.description = "Description is required";

    if ((changes.tests || []).length < 1)
      _errors.tests = "Test Group must have at least one test";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };

    if (!resId) {
      addTestGroup(newVm);
    } else updateTestGroup(newVm.id, newVm);
  }

  async function addTestGroup(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      const retModel = createViewModel(result);
      resetTestOrdinals(retModel);
      setChanges(retModel);
      setLastSavedChanges(retModel);
      notifySuccess("Test Group '" + vm.description + "' saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/testgroup/" + result.id, { replace: true });
    });
  }

  async function updateTestGroup(testGroupId, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id: testGroupId, model }, (result) => {
      const retModel = createViewModel(result);
      resetTestOrdinals(retModel);
      setChanges(retModel);
      setLastSavedChanges(retModel);
      setTestGroupsData({
        type: ContextProviderActions.loadTestGroup,
        payload: retModel,
      });
      notifySuccess("Test Group '" + vm.description + "' saved successfully");
    });
  }

  async function handleRunTestGroupAsBatch() {
    // Check for unsaved changes
    if (
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
      JSON.stringify(_.cloneDeep(changes))
    ) {
      notifyWarn("Please save your changes before running the test group.");
      return;
    }

    setRunMode(true);
    setCurrentRunTestIndex(0);
    setBatchRunGuid(generateUUID());
  }

  function cancelTestRun() {
    setRunMode(false);
    setCurrentRunTestIndex(-1);
    setBatchRunGuid(null);
  }

  function handleInstantSearchChange(value) {
    setTestSearch({ ...testSearch, highlightText: value });
  }

  function handleSaveTest() {
    // Make sure test has not already been added
    if (changes.tests.findIndex((t) => t.id === editItem.id) >= 0) {
      notifyWarn(
        "Test was not added because this test has already been added to the group. Please select a different test."
      );
      return;
    }

    errors.tests = "";
    const newVm = _.cloneDeep(changes);
    const array = newVm.tests;

    // Always in insert mode
    const newItem = { ...emptyTest, ...editItem };

    if (newVm.tests.length > 0) {
      newItem.ordinal = newVm.tests.length + 1;
    }

    array.push(newItem);

    setChanges(newVm);
    setShowTestDialog(false);

    // Remove this test from the array of tests so it does not show in the dialog since tests can only be added to the group once
    let _tests = [...testsForDialog];
    const index = _tests.findIndex((t) => t.id === editItem.id);
    if (index >= 0) {
      _tests.splice(index, 1);
      setTestsForDialog(_tests);
    }
  }

  function handleDeleteTest(id) {
    const newVm = _.cloneDeep(changes);
    const array = newVm.tests;

    const index = changes.tests.findIndex((i) => i.id === id);
    if (index < 0) {
      notifyError(`A test with id ${editItem.id} was not found to remove!`);
      return;
    }

    // Remove item reset ordinals
    const removedTest = array.splice(index, 1);
    for (let i = 0; i < array.length; i++) {
      array[i].ordinal = i + 1;
    }
    setChanges(newVm);

    // Add this removed test back to the array of tests so it can be added again without having to reload page
    let _tests = [...testsForDialog];
    _tests.push({
      ...emptyTest,
      id: removedTest[0].id,
      description: removedTest[0].description,
    });
    _tests = _tests.sort((a, b) => (a.description > b.description ? 1 : -1));
    setTestsForDialog(_tests);
  }

  function handleAddTest() {
    setEditItem({ ...emptyTest });
    setShowTestDialog(true);
  }

  function handleMoveTest(id, isUp) {
    const newVm = _.cloneDeep(changes);
    const array = newVm.tests;

    const index = array.findIndex((i) => i.id === id);
    if (index < 0) {
      notifyError(`A test with id ${id} was not found to move!`);
      return;
    }

    // Check boundaries
    if (isUp && index === 0) return;
    if (!isUp && index >= array.length) return;

    const item = array[index];
    const swapItem = isUp ? array[index - 1] : array[index + 1];

    const tempOrdinal = item.ordinal;
    item.ordinal = swapItem.ordinal;
    swapItem.ordinal = tempOrdinal;

    setChanges(newVm);
  }

  const hasChanges =
    JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
    JSON.stringify(_.cloneDeep(changes));
  const working = loading || loadingTests || adding || updating;

  let runPercentage = formatDecimal(
    (currentRunTestIndex / testGroupsData.testGroup.tests.length) * 100,
    0
  );
  if (runPercentage < 1) runPercentage = 1;

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          {!runMode && (
            <button
              type="button"
              title="Return to previous screen"
              className="btn btn-link btn-with-icon"
              onClick={() => navigate(-1)}
            >
              <i className="fa fa-angle-left"></i> Back
            </button>
          )}
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Testing/Test-Group-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            Test Group {changes.description ? ` | ${changes.description}` : ""}
          </h1>
          <StyledHeaderRowButtonDiv>
            {resId && !working && !runMode && (
              <button
                type="button"
                className="btn btn-secondary btn-with-icon"
                onClick={handleRunTestGroupAsBatch}
                style={{
                  marginRight: "24px",
                }}
                disabled={hasChanges}
              >
                {hasChanges ? (
                  <>Save changes before running</>
                ) : (
                  <>
                    <span className="material-icons">play_circle_outline</span>
                    {"  "}Run Now
                  </>
                )}
              </button>
            )}
            {!working && !runMode && (
              <>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "86px",
                  }}
                >
                  <span className="material-icons">check</span>
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => navigate(-1)}
                  style={{ marginLeft: "12px" }}
                >
                  Cancel
                </button>
              </>
            )}
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {working ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin && typeof resId !== "undefined",
                  url: `/auditrecord/testgroup/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Test Group"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Testing/Test-Group-Screen&anchor=test-group-section"
              >
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6">
                    <TextInput
                      id="description"
                      label="Description"
                      onChange={handleChange}
                      placeholder="Description"
                      name="description"
                      value={changes.description || ""}
                      error={errors.description}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              {resId && (
                <ExpandCollapseDetailSection
                  sectionTitle="Last Run Info"
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Testing/Test-Group-Screen&anchor=last-run-info-section"
                >
                  <StyledRowDiv className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Result</label>
                        <div className="field">
                          <StatusPill status={changes.lastRunResultStatus} />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <ReadOnly
                        id="lastRunDate"
                        label="Run Date"
                        name="lastRunDate"
                        value={formatDateTimeUtcZoneForDisplay(
                          changes.lastRunDate
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <ReadOnly
                        id="lastRunDurationMilliseconds"
                        label="Duration"
                        name="lastRunDurationMilliseconds"
                        value={getTimeframeFromMilliseconds(
                          changes.lastRunDurationMilliseconds
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <ReadOnly
                        id="lastRunBy"
                        label="Run By"
                        name="lastRunBy"
                        value={changes.lastRunBy}
                      />
                    </div>
                  </StyledRowDiv>
                  {!runMode && changes.lastRunResultId !== "" && (
                    <StyledRowDiv style={{ marginTop: "10px" }}>
                      <div className="col-12">
                        <button
                          className="btn btn-link link-underline"
                          onClick={() =>
                            navigate(
                              `/testgroupresults/${resId}/${changes.lastRunResultId}`
                            )
                          }
                        >
                          View full results of last test group run
                        </button>
                      </div>
                    </StyledRowDiv>
                  )}
                </ExpandCollapseDetailSection>
              )}
              <ExpandCollapseDetailSection
                sectionTitle="Tests"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Testing/Test-Group-Screen&anchor=tests-section"
              >
                <StyledRowDiv className="row">
                  <div className="col-12">
                    {runMode ? (
                      <SimpleCompletionBar
                        title={
                          <h4 style={{ fontSize: "20px" }}>
                            {currentRunTestDescription === "Done!" ? (
                              currentRunTestDescription
                            ) : (
                              <>
                                Running test {currentRunTestDescription}
                                ...
                              </>
                            )}
                          </h4>
                        }
                        runPercentage={runPercentage}
                        onCancel={cancelTestRun}
                      />
                    ) : (
                      <>
                        <StyledHeaderRowDiv
                          style={{ marginTop: "5px", marginBottom: "10px" }}
                        >
                          <InstantSearchInput
                            id="screenSearchInput"
                            onChange={handleInstantSearchChange}
                            value={testSearch.highlightText}
                          />
                          <StyledHeaderRowButtonDiv>
                            <button
                              type="button"
                              className="btn btn-secondary btn-with-icon"
                              onClick={handleAddTest}
                            >
                              <span className="material-icons">add</span>
                              {"  "}Add Test
                            </button>
                          </StyledHeaderRowButtonDiv>
                        </StyledHeaderRowDiv>
                        <TestGroupTestDialog
                          tests={testsForDialog || []}
                          editItem={editItem}
                          setEditItem={setEditItem}
                          showDialog={showTestDialog}
                          onCloseDialog={() => setShowTestDialog(false)}
                          onSaveDialog={handleSaveTest}
                        />
                        {errors.tests && (
                          <div className="alert alert-danger">
                            {errors.tests}
                          </div>
                        )}
                      </>
                    )}
                    <TestGroupTestList
                      tests={changes.tests}
                      currentRunTestIndex={currentRunTestIndex}
                      isRunning={runMode}
                      totalRecords={changes.tests?.length || 0}
                      search={testSearch}
                      setSearch={setTestSearch}
                      editItem={editItem}
                      setEditItem={setEditItem}
                      onDelete={handleDeleteTest}
                      onMoveUp={(id) => handleMoveTest(id, true)}
                      onMoveDown={(id) => handleMoveTest(id, false)}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default TestGroup;
