import React, { useState, useEffect } from "react";
import ClaimList from "./ClaimList";
import ClaimSearchForm from "./ClaimSearchForm";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import { emptyPacketRequest } from "../../../viewmodels/packetParserVm";
import { emptyClaimSearch } from "../../../viewmodels/claimsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useClaims } from "../../../contexts/ClaimsContext";
import { apiLoadClaims, apiReverseClaim } from "../../../api/ClaimApi";
import { usePacketParser } from "../../../contexts/PacketParserContext";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import useApi from "../../../hooks/useApi";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";

function Claims() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { claimsData, setClaimsData } = useClaims();
  const { loading, api: apiLoad } = useApi(apiLoadClaims);
  const { loading: reversingClaim, api: apiReverse } = useApi(apiReverseClaim);
  const { setPacketParserData } = usePacketParser();
  const [loadData, setLoadData] = useState(true);
  const { isMobileSize } = useMobile();

  let claims = [];
  if (claimsData && claimsData.claims) {
    claims = claimsData.claims;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setChainsData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //   useEffect ensures that happens first.
  useEffect(() => {
    // jon, 5/9/24: Set the search term coming from the test processor screen in another tab if we have the value in local storage
    let preSearch = null;
    if (!_.isEmpty(localStorage["TEST_PROCESSOR_SEARCH_TERM"])) {
      const searchTerm = localStorage["TEST_PROCESSOR_SEARCH_TERM"];
      localStorage.removeItem("TEST_PROCESSOR_SEARCH_TERM");

      preSearch = {
        ...claimsData.search,
        freeFormSearch: searchTerm,
        pageNumber: 1,
      };
      setSearchChanges(preSearch);
    }

    if (auth.authenticated && loadData) {
      //Clear the packet request so it is clear when the packet parser is selected
      savePacketRequest(emptyPacketRequest);
      loadClaims(preSearch);
    }
  }, [auth.authenticated, loadData]);

  async function loadClaims(preSearch) {
    const actualSearch = preSearch === null ? claimsData.search : preSearch;

    apiLoad.call(
      actualSearch,
      (result) => {
        setLoadData(false);
        postLoadClaims(preSearch, result);
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function postLoadClaims(preSearch, result) {
    const actualSearch = preSearch === null ? claimsData.search : preSearch;

    let retClaims = result.resources;
    let count = result.count || 0;
    const newSearch = actualSearch ? { ...actualSearch } : emptyClaimSearch;

    //Apply client side filter
    if (
      newSearch &&
      newSearch.friendlyStatus &&
      newSearch.friendlyStatus.length > 0
    ) {
      let statuses = newSearch.friendlyStatus.map((s) => s.value);
      if (statuses.includes("Unreversed Paid") && !statuses.includes("Paid")) {
        statuses.push("Paid");
        retClaims = retClaims.filter(
          (c) =>
            statuses.includes(c.friendlyStatus) &&
            (!c.isReversed || !(c.friendlyStatus === "Paid"))
        );
      } else {
        retClaims = retClaims.filter((c) =>
          statuses.includes(c.friendlyStatus)
        );
      }
      //Set new "client-side" count (api call returns as many items as possible in page to accomodate this filter)
      count = retClaims.length;
      retClaims = retClaims.slice(
        newSearch.pageSize * (newSearch.pageNumber - 1),
        newSearch.pageSize * newSearch.pageNumber
      );
    }

    setSearchChanges(newSearch);

    setClaimsData({
      type: ContextProviderActions.loadClaims,
      payload: {
        claims: retClaims,
        count,
      },
    });
  }

  async function reverseClaim(reverseClaimId) {
    const params = { claimId: reverseClaimId };

    const msg = await apiReverse.call(params, (result) => {
      return result?.Message || "";
    });

    return msg;
  }

  function setSearchChanges(search) {
    setClaimsData({
      type: ContextProviderActions.saveClaimSearch,
      payload: search,
    });
  }

  function savePacketRequest(packetRequest) {
    setPacketParserData({
      type: ContextProviderActions.savePacketRequest,
      payload: packetRequest,
    });
  }

  async function handleReset() {
    let clearedSearch = emptyClaimSearch;
    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    clearedSearch.startDate = startDate;
    clearedSearch.endDate = endDate;
    setSearchChanges({
      ...clearedSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : claimsData.search.showAdvancedFilter,
    });

    if (!loading && !reversingClaim) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};
    // if (
    //   _.has(claimsData.search, "pharmacy") &&
    //   isNaN(claimsData.search.pharmacy)
    // )
    //   _errors.pharmacy = "Pharmacy NPI must be numeric";
    if (
      _.has(claimsData.search, "startDate") &&
      !_.isDate(claimsData.search.startDate)
    )
      _errors.startDate = "Effective Date must be a valid date";
    if (
      _.has(claimsData.search, "endDate") &&
      !_.isDate(claimsData.search.endDate)
    )
      _errors.endDate = "Termination Date must be a valid date";
    if (
      _.has(claimsData.search, "precriptionRefNumber") &&
      !_.isInteger(claimsData.search.prescriptionRefNumber)
    )
      _errors.endDate = "Prescription Number must be a valid number";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading && !reversingClaim) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = claimsData.search.orderBy.indexOf(`${event.target.id}+`);
    var indexDesc = claimsData.search.orderBy.indexOf(`${event.target.id}-`);

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...claimsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...claimsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...claimsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = { ...claimsData.search, ...newSearch, pageNumber: 1 };

    // jon, 5/23/24: If this is a quick search, expand date range to all dates
    if (
      updatedSearch &&
      updatedSearch.freeFormSearch &&
      updatedSearch.freeFormSearch.length > 0
    ) {
      updatedSearch.startDate = new Date("1/1/2000");
      updatedSearch.endDate = new Date("1/1/3000");
    }

    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleDateRangeChange(dateRange, startDate, endDate) {
    setSearchChanges({
      ...claimsData.search,
      dateRange: dateRange,
      startDate: startDate,
      endDate: endDate,
    });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...claimsData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...claimsData.search, endDate: date });
  }

  function handleStatusChange(statuses) {
    setSearchChanges({ ...claimsData.search, friendlyStatus: statuses });
  }

  function handleDiffStatusChange(statuses) {
    setSearchChanges({ ...claimsData.search, diffStatus: statuses });
  }

  function handleSearchChange({ target }) {
    setSearchChanges({ ...claimsData.search, [target.name]: target.value });
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (claimsData.search.pharmacy !== "") numFilters++;
    if (claimsData.search.memberId !== "") numFilters++;
    if (claimsData.search.drugId !== "") numFilters++;
    if (claimsData.search.prescriptionRefNumber !== "") numFilters++;
    if (claimsData.search.groupId !== "") numFilters++;
    if (claimsData.search.authNumber !== "") numFilters++;
    if ((claimsData.search.friendlyStatus || []).length > 0) numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledHeaderRowDiv>
        <h1>Claim Requests</h1>
        <StyledHeaderRowButtonDiv>
          <HelpLink path="/Claims/Claim-Requests" label="Help" />
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search by Rx, Auth #, or Member ID"
        search={claimsData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <div style={{ display: "flex" }} data-testid="claims-list">
        <div
          style={{
            width:
              claimsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={claimsData.search}
            setSearch={setSearchChanges}
            helpLink="/Claims/Claim-Requests&anchor=filters"
          >
            <ClaimSearchForm
              isAdmin={auth.isAdmin}
              errors={errors}
              search={claimsData.search}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              onDateRangeChange={handleDateRangeChange}
              onStatusChanged={handleStatusChange}
              onDiffStatusChanged={handleDiffStatusChange}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
            />
          </GridAdvancedFilter>
        </div>
        {claimsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || reversingClaim ? (
              <Spinner />
            ) : (
              <ClaimList
                claims={claims}
                search={claimsData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);

                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={claimsData.count}
                isAdmin={auth.isAdmin}
                onReverseClaim={reverseClaim}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default Claims;
