import styled from "styled-components";

export const StyledStepHeaderDiv = styled.div`
  font-size: 18px;
`;

export const StyledWizardHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;

  h1 {
    margin: 0;
    padding: 0;
  }

  span.task-icon {
    font-size: 36px;
    color: var(--drag-chip-border);
  }
`;

export const StyledWizardPanel = styled.div`
  margin-top: 15px;
  padding: 20px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
`;

export const StyledWizardListItem = styled.li`
  margin-top: ${(props) => ((props?.step || 0) > 0 ? "20px" : "0")};

  &::marker {
    font-size: 22px;
    font-weight: bold;
    color: var(--drag-chip-border);
    padding-right: 10px;
  }

  div.footer {
    margin-top: 8px;
    color: var(--text-dark);
    font-style: italic;
  }
`;
