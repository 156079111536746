import React, { useEffect, useState } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
} from "../../common/layout/CommonStyledControls";
import {
  formatDateTimeUtcZoneForDisplay,
  formatDecimal,
  formatNumberForDisplay,
} from "../../../services/General";
import { parseISO } from "date-fns";
import TextInput from "../../common/input/TextInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import styled from "styled-components";
import ReadOnly from "../../common/input/ReadOnly";
import StatusPill from "../../common/ui/StatusPill";
import DatePickerInput from "../../common/input/DatePickerInput";
import TextAreaInput from "../../common/input/TextAreaInput";
import SimpleCompletionBar from "../../common/ui/SimpleCompletionBar";

function DbDatabaseSyncType({
  name,
  changes,
  setChanges,
  hasChanges,
  updateState,
  isStarting,
  isCancelling,
  isAnyProcessStartingOrCancelling,
  syncIsRunning,
  onRunProcess,
  onCancelProcess,
  onDeleteSyncEnvironment,
  errors,
}) {
  const [typeSyncIsRunning, setTypeSyncIsRunning] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarPercent, setProgressBarPercent] = useState(0);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const model = changes.synchronizations.find((t) => t.name === name);

  useEffect(() => {
    if (syncIsRunning && !typeSyncIsRunning) {
      setTypeSyncIsRunning(true);
    } else if (!syncIsRunning && typeSyncIsRunning) {
      setProgressBarMessage(`${name} sync complete!`);
      setProgressBarPercent(100);

      // Let progress bar stay on the screen for a bit so user can see update is done
      window.setTimeout(() => {
        setTypeSyncIsRunning(false);
      }, 2000);
    } else if (syncIsRunning) {
      setTypeSyncIsRunning(true);
    }
  }, [syncIsRunning, typeSyncIsRunning]);

  useEffect(() => {
    if (updateState && !_.isEmpty(updateState.lastUpdateMessage)) {
      setProgressBarMessage(updateState.lastUpdateMessage);
    } else if (!typeSyncIsRunning) {
      setProgressBarMessage("");
    }
  }, [updateState?.lastUpdateMessage, typeSyncIsRunning]);

  useEffect(() => {
    if (updateState && updateState.totalRecordsToProcess > 0) {
      const numRecords = updateState?.totalRecordsToProcess ?? 0;
      const inProgress = updateState?.inProgressRecordsProcessed ?? 0;

      // Prevent divide by zero in percentage calculation below.
      const totalRecords = numRecords === 0 ? 0.01 : numRecords;
      let runPercentage = formatDecimal((inProgress / totalRecords) * 100, 0);
      if (runPercentage < 1) runPercentage = 1;

      setProgressBarPercent(runPercentage);
    } else if (!typeSyncIsRunning) {
      setProgressBarPercent(0);
    }
  }, [
    updateState?.totalRecordsToProcess,
    updateState?.inProgressRecordsProcessed,
    typeSyncIsRunning,
  ]);

  useEffect(() => {
    // Does this errors array contain any items for this sync? The key names start with the sync's name.
    //  If so, automatically open the advanced settings section if it isn't already open.
    if (Object.keys(errors).filter((e) => e.indexOf(name) === 0).length > 0) {
      setShowAdvancedSettings(true);
    }
  }, [errors]);

  function handleChange({ target }) {
    let changed = _.cloneDeep(changes);
    const changedType = changed.synchronizations.find((t) => t.name === name);
    changedType[target.name] = target.value;
    setChanges(changed);
  }

  function handleCheckboxChange({ target }) {
    let changed = _.cloneDeep(changes);
    const changedType = changed.synchronizations.find((t) => t.name === name);
    changedType[target.name] = target.checked;
    setChanges(changed);
  }

  function handleStartDateChange(date) {
    let changed = _.cloneDeep(changes);
    const changedType = changed.synchronizations.find((t) => t.name === name);
    changedType.startDate = date === null ? null : new Date(date).toISOString();
    setChanges(changed);
  }

  function handleEndDateChange(date) {
    let changed = _.cloneDeep(changes);
    const changedType = changed.synchronizations.find((t) => t.name === name);
    changedType.endDate = date === null ? null : new Date(date).toISOString();
    setChanges(changed);
  }

  function handleClickAdvancedSettings(event) {
    if (event) event.preventDefault();
    setShowAdvancedSettings(!showAdvancedSettings);
  }

  if (!model) return <></>;

  return (
    <StyledTypeContainer className="col-12">
      {isStarting || isCancelling ? (
        <>
          <StyledHeaderRowDiv style={{ marginTop: "5px" }}>
            <h1>{model.name}</h1>
          </StyledHeaderRowDiv>
          <Spinner spinnerStyle={{ lineHeight: "10vh" }} />
          {(isStarting || isCancelling) && (
            <h4
              style={{
                textAlign: "center",
                position: "relative",
                top: "40px",
                margin: "0",
                padding: "0",
              }}
            >
              {isStarting ? `Sync is starting...` : `Cancelling sync...`}
            </h4>
          )}
        </>
      ) : typeSyncIsRunning ? (
        <>
          <StyledHeaderRowDiv style={{ marginTop: "5px" }}>
            <h1>{model.name}</h1>
          </StyledHeaderRowDiv>
          <SimpleCompletionBar
            title={<p>Sync in progress...</p>}
            runPercentage={progressBarPercent}
            onCancel={onCancelProcess}
            disableCancel={isCancelling}
            showCancel={syncIsRunning}
            fullWidth={true}
            subtitle={
              <>
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "4px",
                    marginBottom: "0",
                  }}
                >
                  {_.isEmpty(progressBarMessage) ? (
                    <>&nbsp;</>
                  ) : (
                    <>{progressBarMessage}</>
                  )}
                </p>
                <p style={{ textAlign: "center", marginTop: "4px" }}>
                  {updateState?.inProgressRecordsProcessed === 0 &&
                  updateState?.totalRecordsToProcess === 0 ? (
                    <>&nbsp;</>
                  ) : (
                    <>
                      {`${formatNumberForDisplay(
                        updateState?.inProgressRecordsProcessed || 0
                      )} of ${formatNumberForDisplay(
                        updateState?.totalRecordsToProcess || 0
                      )}`}
                    </>
                  )}
                </p>
              </>
            }
          />
        </>
      ) : (
        <StyledRowDiv className="row">
          <div className="col-12 col-md-9" style={{ padding: "15px" }}>
            <StyledHeaderRowDiv
              style={{ marginTop: "5px", marginBottom: "20px" }}
            >
              <h1>
                {_.isEmpty(model.name) ? "(New Environment)" : model.name}
              </h1>
            </StyledHeaderRowDiv>

            <StyledRowDiv className="row">
              <div className="col-12 col-md-6">
                <TextInput
                  id={`${name}-name`}
                  label="Name"
                  onChange={handleChange}
                  placeholder="Name"
                  name="name"
                  value={model.name}
                  error={errors[`${name}-name`]}
                />
              </div>
              <div className="col-12 col-md-6">
                <TextInput
                  id={`${name}-type`}
                  label="Document Types"
                  onChange={handleChange}
                  placeholder="Document Types"
                  name="type"
                  value={model.type}
                  error={errors[`${name}-type`]}
                />
              </div>
              <div className="col-12 col-lg-6">
                <DatePickerInput
                  id={`${name}-startDate`}
                  name="startDate"
                  label="Start Date"
                  value={
                    model.startDate === null ? null : parseISO(model.startDate)
                  }
                  showTimeInput={false}
                  placeholder="Start Date"
                  onChange={handleStartDateChange}
                  error={errors[`${name}-startDate`]}
                />
              </div>
              <div className="col-12 col-lg-6">
                <DatePickerInput
                  id={`${name}-endDate`}
                  name="endDate"
                  label="End Date"
                  value={
                    model.endDate === null ? null : parseISO(model.endDate)
                  }
                  showTimeInput={false}
                  placeholder="End Date"
                  onChange={handleEndDateChange}
                  error={errors[`${name}-endDate`]}
                />
              </div>
              <div className="col-12 col-lg-6">
                <CheckboxInput
                  id={`${name}-active`}
                  label="Active"
                  onChange={handleCheckboxChange}
                  placeholder=""
                  name="active"
                  showLabelInline={true}
                  checked={model.active}
                  error={errors[`${name}-active`]}
                />
              </div>
              <div className="col-12 col-lg-6">
                <CheckboxInput
                  id={`${name}-continuous`}
                  label="Continuous"
                  onChange={handleCheckboxChange}
                  placeholder=""
                  name="continuous"
                  showLabelInline={true}
                  checked={model.continuous}
                  error={errors[`${name}-continuous`]}
                />
              </div>
            </StyledRowDiv>
            <StyledAdvancedSettingsDiv>
              <button
                className="btn btn-link btn-with-icon"
                onClick={handleClickAdvancedSettings}
              >
                Advanced Settings{"  "}
                <span className="material-symbols-outlined">
                  {showAdvancedSettings ? "expand_more" : "chevron_right"}
                </span>
              </button>
            </StyledAdvancedSettingsDiv>
            {showAdvancedSettings && (
              <>
                <StyledRowDiv className="row">
                  <div className="col-12">
                    <TextAreaInput
                      id={`${name}-sourceConnectionString`}
                      rows="3"
                      label="Source Connection String"
                      onChange={handleChange}
                      placeholder="Source Connection String"
                      name="sourceConnectionString"
                      value={model.sourceConnectionString}
                      error={errors[`${name}-sourceConnectionString`]}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextInput
                      id={`${name}-sourceDatabaseId`}
                      label="Source Database Id"
                      onChange={handleChange}
                      placeholder="Source Database Id"
                      name="sourceDatabaseId"
                      value={model.sourceDatabaseId}
                      error={errors[`${name}-sourceDatabaseId`]}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextInput
                      id={`${name}-sourceContainer`}
                      label="Source Container"
                      onChange={handleChange}
                      placeholder="Source Container"
                      name="sourceContainer"
                      value={model.sourceContainer}
                      error={errors[`${name}-sourceContainer`]}
                    />
                  </div>
                </StyledRowDiv>
                <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                  <div className="col-12">
                    <TextAreaInput
                      id={`${name}-destConnectionString`}
                      rows="3"
                      label="Destination Connection String"
                      onChange={handleChange}
                      placeholder="Destination Connection String"
                      name="destConnectionString"
                      value={model.destConnectionString}
                      error={errors[`${name}-destConnectionString`]}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextInput
                      id={`${name}-destDatabaseId`}
                      label="Destination Database Id"
                      onChange={handleChange}
                      placeholder="Destination Database Id"
                      name="destDatabaseId"
                      value={model.destDatabaseId}
                      error={errors[`${name}-destDatabaseId`]}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextInput
                      id={`${name}-destContainer`}
                      label="Destination Container"
                      onChange={handleChange}
                      placeholder="Destination Container"
                      name="destContainer"
                      value={model.destContainer}
                      error={errors[`${name}-destContainer`]}
                    />
                  </div>
                </StyledRowDiv>
                <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                  <div className="col-12">
                    <TextAreaInput
                      id={`${name}-additionalFiltersVm`}
                      rows="3"
                      label={
                        <p style={{ marginBottom: "0" }}>
                          Additional Filters
                          <br />
                          <span
                            style={{
                              color: "var(--text-medium)",
                              display: "inline-block",
                              marginTop: "4px",
                            }}
                          >
                            Instructions:{" "}
                            <i>
                              Enter{" "}
                              <span className="code-block">key=value(s)</span>{" "}
                              pairs, one per line, with comma-separated values
                            </i>
                          </span>
                        </p>
                      }
                      onChange={handleChange}
                      placeholder="Additional Filters"
                      name="additionalFiltersVm"
                      value={model.additionalFiltersVm}
                      error={errors[`${name}-additionalFiltersVm`]}
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <TextInput
                      id={`${name}-pollFrequencySeconds`}
                      label="Poll Frequency Seconds"
                      onChange={handleChange}
                      placeholder="Poll Frequency Seconds"
                      name="pollFrequencySeconds"
                      value={model.pollFrequencySeconds}
                      error={errors[`${name}-pollFrequencySeconds`]}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <CheckboxInput
                      id={`${name}-reloadAllData`}
                      label="Reload All Data"
                      onChange={handleCheckboxChange}
                      placeholder=""
                      name="reloadAllData"
                      showLabelInline={true}
                      checked={model.reloadAllData}
                      error={errors[`${name}-reloadAllData`]}
                    />
                  </div>
                </StyledRowDiv>
              </>
            )}
          </div>
          <div className="col-12 col-md-3">
            <StyledRowDiv className="sidepanel">
              <StyledHeaderRowButtonDiv
                className="flex-row-with-wrap-and-justify"
                style={{
                  rowGap: "10px",
                  marginBottom: "20px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary btn-with-icon"
                  onClick={onDeleteSyncEnvironment}
                  disabled={typeSyncIsRunning}
                >
                  <>
                    <span className="material-icons">close</span>
                    {"  "}Delete
                  </>
                </button>
                {!typeSyncIsRunning && !isStarting && !isCancelling && (
                  <button
                    type="button"
                    className="btn btn-secondary btn-with-icon"
                    onClick={onRunProcess}
                    style={{
                      borderColor: "var(--notify-success)",
                    }}
                    disabled={
                      hasChanges ||
                      typeSyncIsRunning ||
                      isAnyProcessStartingOrCancelling ||
                      !model.active ||
                      !changes.properties.active
                    }
                  >
                    {!model.active || !changes.properties.active ? (
                      <>Sync not active</>
                    ) : hasChanges ? (
                      <>Save first</>
                    ) : (
                      <>
                        <span className="material-icons">
                          play_circle_outline
                        </span>
                        {"  "}Run
                      </>
                    )}
                  </button>
                )}
              </StyledHeaderRowButtonDiv>
              {!updateState || updateState?.lastStarted === null ? (
                <p>This sync has never been run.</p>
              ) : (
                <>
                  <StyledRowDiv className="row">
                    <div className="col-12" style={{ marginTop: "15px" }}>
                      <ReadOnly
                        id="lastStarted"
                        label="Last Started"
                        name="lastStarted"
                        value={formatDateTimeUtcZoneForDisplay(
                          updateState?.lastStarted
                        )}
                      />
                    </div>
                    <div className="col-12" style={{ marginTop: "5px" }}>
                      <ReadOnly
                        id="lastCompleted"
                        label="Last Completed"
                        name="lastCompleted"
                        value={formatDateTimeUtcZoneForDisplay(
                          updateState?.lastCompleted
                        )}
                      />
                    </div>
                    <div className="col-12" style={{ marginTop: "5px" }}>
                      <div className="form-group">
                        <label>Result</label>
                        <div className="field">
                          <StatusPill
                            status={
                              updateState?.lastError?.errorState ??
                              false === true
                                ? _.toLower(
                                    updateState?.lastError?.errorMessage
                                  )?.indexOf("cancel") >= 0
                                  ? "Cancelled"
                                  : "Fail"
                                : "Success"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </StyledRowDiv>
                  {(updateState?.lastError?.errorState ?? false === true) && (
                    <StyledRowDiv className="row" style={{ marginTop: "5px" }}>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Message</label>
                          <div className="field">
                            {updateState?.lastError?.errorMessage ?? ""}
                          </div>
                        </div>
                      </div>
                    </StyledRowDiv>
                  )}
                </>
              )}
            </StyledRowDiv>
          </div>
        </StyledRowDiv>
      )}
    </StyledTypeContainer>
  );
}

const StyledTypeContainer = styled.div`
  padding: 10px;
  min-height: 300px;
  border: 1px solid var(--elevated-border);

  h3 {
    font-size: 18px;
  }

  .sidepanel {
    height: 100%;
    margin-top: 10px;
    padding: 12px 12px 0 20px;
    background-color: var(--bg-flat);
    border-radius: 6px;
  }
`;

const StyledAdvancedSettingsDiv = styled.div`
  margin: 30px 0 20px 0;

  button {
    font-size: 14px;
  }
`;

export default DbDatabaseSyncType;
