export const pageSize = process.env.PageSize;

export const Environments = [
  { label: "Production (Staging)", value: "prod" },
  { label: "Test (Staging)", value: "test" },
  { label: "Development (Staging)", value: "dev" },
  { label: "Test (Normal)", value: "testn" },
  { label: "Development (Normal)", value: "devn" },
  { label: "Production (Normal)", value: "prodn" },
];

export const accessTokenRequest = {
  scopes: ["api://42b9594f-caa2-4ea7-b2bf-dfde48b99382/user_impersonation"],
};

export const TABLET_WINDOW_SIZE = 992;
export const MOBILE_WINDOW_SIZE = 768;
export const SMALL_MOBILE_WINDOW_SIZE = 576;
