/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadDbLegacyDbSync(params, token, signal) {
  let uri = API_URL + "/dbutilconfig/sqltocosmos/" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateDbLegacyDbSync(params, token, signal) {
  const model = params.model;

  let uri = API_URL + "/dbutilconfig/sqltocosmos/" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRunDbLegacyDbSync(params, token, signal) {
  const type = params.type;

  let uri =
    API_URL +
    "/dbutilconfig/runsqltocosmos/" +
    encodeURIComponent(type) +
    getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCancelDbLegacyDbSync(params, token, signal) {
  const type = params.type;

  let uri =
    API_URL +
    "/dbutilconfig/cancelsqltocosmos/" +
    encodeURIComponent(type) +
    getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadDbLegacyDbSync.funcName = "apiLoadDbLegacyDbSync";
apiUpdateDbLegacyDbSync.funcName = "apiUpdateDbLegacyDbSync";
apiRunDbLegacyDbSync.funcName = "apiRunDbLegacyDbSync";
apiCancelDbLegacyDbSync.funcName = "apiCancelDbLegacyDbSync";
