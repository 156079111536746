import React, { useState, useEffect } from "react";

import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createViewModel,
  fromViewModel,
  emptyGroup,
} from "../../../viewmodels/groupsVm";
import DatePickerInput from "../../common/input/DatePickerInput";
import { handleCollapseExpandAll, states } from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import SelectInput from "../../common/input/SingleSelect";
import CheckboxInput from "../../common/input/CheckboxInput";
import { useGroups } from "../../../contexts/GroupsContext";
import { useClients } from "../../../contexts/ClientsContext";
import { useAuth } from "../../../contexts/AuthContext";
import {
  apiAddGroup,
  apiLoadGroup,
  apiUpdateGroup,
} from "../../../api/GroupApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { apiLoadClientsAll } from "../../../api/ClientApi";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import SettingsSection from "../../common/ui/SettingsSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Group() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { groupsData, setGroupsData } = useGroups();
  const { clientsData, setClientsData } = useClients();
  const { loading, api: apiLoad } = useApi(apiLoadGroup);
  const { loading: loadingClients, api: apiLoadClients } =
    useApi(apiLoadClientsAll);
  const { loading: adding, api: apiAdd } = useApi(apiAddGroup);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateGroup);
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyGroup);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Group", collapsed: false },
    { name: "Effective Range", collapsed: true },
    { name: "TPA Phone Replacement", collapsed: true },
    { name: "Contact", collapsed: true },
    { name: "Address", collapsed: true },
    { name: "Settings", collapsed: true },
  ]);

  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setGroupsData({
        type: ContextProviderActions.loadGroup,
        payload: {
          group: emptyGroup,
        },
      });

      loadGroupAndClientList();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (groupsData.group) {
      setChanges(groupsData.group);
    } else {
      setChanges(emptyGroup);
    }
  }, [groupsData.group]);

  async function loadGroupAndClientList() {
    let clientList = clientsData.clientListCache || [];

    if ((clientList || []).length === 0) {
      clientList = await loadClientList();

      // Save the list of groups in the cache
      setClientsData({
        type: ContextProviderActions.setClientListCache,
        payload: clientList,
      });
    }

    await loadGroup(clientList);
  }

  async function loadClientList() {
    return await apiLoadClients.call(null, async (result) => {
      return result.resources.map((c) => {
        let cid = c.clientId ?? c.id;
        return { value: cid, label: c.name };
      });
    });
  }

  async function loadGroup(clientList) {
    if (!resId) {
      setGroupsData({
        type: ContextProviderActions.loadGroup,
        payload: {
          group: emptyGroup,
        },
      });
      setChanges(emptyGroup);
      return;
    }

    apiLoad.call(resId, (result) => {
      postLoadGroupAndClients(result, clientList);
    });
  }

  function postLoadGroupAndClients(groupResult, clientList) {
    // Group viewmodel needs the client list
    let vm = emptyGroup;
    if (!groupResult) {
      notifyError("Group does not exist");
    } else {
      vm = createViewModel(groupResult, clientList);
    }

    setGroupsData({
      type: ContextProviderActions.loadGroup,
      payload: {
        group: vm,
      },
    });
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    if (!_.has(changes, "groupId") || changes.groupId.trim() === "")
      _errors.groupId = "Group Id is required";
    if (!_.has(changes, "name") || changes.name.trim() === "")
      _errors.name = "Name must be entered";
    if (!_.has(changes, "clientIdVm") || changes.clientIdVm === "")
      _errors.client = "Client must be selected";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleStateChange(value) {
    setChanges({ ...changes, stateVm: value });
  }

  function handleEffectiveDateChange(date) {
    setChanges({ ...changes, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  function handleClientChange(value) {
    setChanges({ ...changes, clientIdVm: value });
  }

  function handleTPAChange({ target }) {
    let changed = { ...changes };
    changed.tpaPhoneReplacement = target.checked;
    setChanges(changed);
  }

  function clearGroupListCache() {
    // Copy, delete, and save operations invalidate the cache.
    setGroupsData({
      type: ContextProviderActions.setGroupListCache,
      payload: [],
    });
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };

    if (!resId) {
      addGroup(newVm);
    } else updateGroup(newVm.id, newVm);
  }

  async function addGroup(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      setChanges(createViewModel(result, clientsData.clientListCache));
      clearGroupListCache();
      notifySuccess("Group " + vm.groupId + " saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/group/" + result.id, { replace: true });
    });
  }

  async function updateGroup(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      setChanges(createViewModel(result, clientsData.clientListCache));
      clearGroupListCache();
      notifySuccess("Group " + vm.groupId + " saved successfully");
    });
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return To Groups"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate("/configuregroups")}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Configure/Group-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            Group Details {changes.groupId ? ` | ${changes.groupId}` : ""}
          </h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/configuregroups")}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {loading || loadingClients || adding || updating ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/group/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Group"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Group-Screen&anchor=group"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-3 col-xl-2">
                    <TextInput
                      id="groupId"
                      label="Group Id"
                      onChange={handleChange}
                      placeholder="Group Id"
                      name="groupId"
                      value={changes.groupId || ""}
                      error={errors.groupId}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-2">
                    <TextInput
                      id="name"
                      label="Name"
                      onChange={handleChange}
                      placeholder="Name"
                      name="name"
                      value={changes.name || ""}
                      error={errors.name}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-2">
                    <TextInput
                      id="tpaCode"
                      label="TPA Code"
                      onChange={handleChange}
                      placeholder="TPA Code"
                      name="tpaCode"
                      value={changes.tpaCode || ""}
                      error={errors.tpaCode}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <SelectInput
                      id="clientId"
                      name="clientId"
                      label="Client Id"
                      options={clientsData.clientListCache}
                      value={changes.clientIdVm}
                      onChange={handleClientChange}
                      placeholder="Select a Client"
                      error={errors.client}
                    />
                  </div>
                  <div className="col-12 col-xl-3">
                    <TextInput
                      id="description"
                      label="Description"
                      onChange={handleChange}
                      placeholder="Description"
                      name="description"
                      value={changes.description || ""}
                      error={errors.description}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Effective Range"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Group-Screen&anchor=effective-range"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="effectiveDate"
                      name="effectiveDate"
                      label="Effective Date"
                      value={changes.effectiveDate}
                      placeholder="Effective Date"
                      onChange={handleEffectiveDateChange}
                      error={errors.effectiveDate}
                      showTimeInput={false}
                    />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="terminationDate"
                      label="Termination Date"
                      name="terminationDate"
                      value={changes.terminationDate}
                      placeholder="Termination Date"
                      onChange={handleTerminationDateChange}
                      error={errors.terminationDate}
                      showTimeInput={false}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="TPA Phone Replacement"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Group-Screen&anchor=tpa-phone-replacement"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-4 col-xl-3">
                    <CheckboxInput
                      id="tpaPhoneReplacement"
                      label="Replacement"
                      onChange={handleTPAChange}
                      placeholder="TPA Phone Replacement"
                      name="tpaPhoneReplacement"
                      checked={changes.tpaPhoneReplacement}
                      error={errors.tpaPhoneReplacement}
                    />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3">
                    <TextInput
                      id="tpaPhoneReplacementMemberId"
                      label="Replacement Member Id"
                      onChange={handleChange}
                      placeholder="TPA Phone Replacement Member Id"
                      name="tpaPhoneReplacementMemberId"
                      value={changes.tpaPhoneReplacementMemberId || ""}
                      error={errors.tpaPhoneReplacementMemberId}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Contact"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Group-Screen&anchor=contact"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="lastName"
                      label="Last Name"
                      onChange={handleChange}
                      placeholder="Last Name"
                      name="lastName"
                      value={changes.lastName || ""}
                      error={errors.lastName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="firstName"
                      label="First Name"
                      onChange={handleChange}
                      placeholder="First Name"
                      name="firstName"
                      value={changes.firstName || ""}
                      error={errors.firstName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="middleName"
                      label="Middle Name"
                      onChange={handleChange}
                      placeholder="Middle Name"
                      name="middleName"
                      value={changes.middleName || ""}
                      error={errors.middleName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="title"
                      label="Title"
                      onChange={handleChange}
                      placeholder="Title"
                      name="title"
                      value={changes.title || ""}
                      error={errors.title}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="subtitle"
                      label="Subtitle"
                      onChange={handleChange}
                      placeholder="Subtitle"
                      name="subtitle"
                      value={changes.subtitle || ""}
                      error={errors.subtitle}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="suffix"
                      label="Suffix"
                      onChange={handleChange}
                      placeholder="Suffix"
                      name="suffix"
                      value={changes.suffix || ""}
                      error={errors.suffix}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="number"
                      label="Phone Number"
                      onChange={handleChange}
                      placeholder="Phone Number"
                      name="number"
                      value={changes.number || ""}
                      error={errors.number}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Address"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Group-Screen&anchor=address"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="emailAddresses"
                      label="Email Addresses"
                      onChange={handleChange}
                      placeholder="Email Addresses"
                      name="emailAddressesVm"
                      value={changes.emailAddressesVm}
                      error={errors.emailAddresses}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="address"
                      label="Address"
                      onChange={handleChange}
                      placeholder="Address"
                      name="address"
                      value={changes.address || ""}
                      error={errors.address}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="city"
                      label="City"
                      onChange={handleChange}
                      placeholder="City"
                      name="city"
                      value={changes.city || ""}
                      error={errors.city}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <SelectInput
                      id="state"
                      name="state"
                      label="State"
                      options={states}
                      value={changes.stateVm || ""}
                      onChange={handleStateChange}
                      placeholder="Select a State"
                      error={errors.state}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="zipCode"
                      label="Zip Code"
                      onChange={handleChange}
                      placeholder="Zip Code"
                      name="zipCode"
                      value={changes.zipCode || ""}
                      error={errors.zipCode}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Settings"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Group-Screen&anchor=settings"
              >
                <SettingsSection
                  settingsArrayName="options.settings"
                  changes={changes}
                  setChanges={setChanges}
                  helpLink="/Configure/Group-Screen&anchor=settings-dialog"
                />
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default Group;
