import React, { useState } from "react";
import _ from "lodash";
import { StyledFeedbackText } from "../layout/CommonStyledControls";

function ReadOnly({ value, label, labelStyle, copyToClipboard }) {
  const [copying, setCopying] = useState(false);

  async function setClipboard(txt) {
    await navigator.clipboard.writeText(txt);
    setCopying(true);
    window.setTimeout(() => setCopying(false), 2000);
  }

  let wrapperClass = "form-group";
  return (
    <div className={wrapperClass}>
      <label style={labelStyle || {}}>{label}</label>
      <div className="field force-wrap">
        <span>{_.isEmpty(value) ? " " : value}</span>
        {copyToClipboard ? (
          <>
            <i
              title="Copy to clipboard"
              className="material-icons pointer"
              onClick={() => setClipboard(value)}
              style={{ paddingLeft: "10px" }}
            >
              content_copy
            </i>
            {copying && <StyledFeedbackText>Copied!</StyledFeedbackText>}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ReadOnly;
