import React from "react";
import {
  StyledStepHeaderDiv,
  StyledWizardListItem,
} from "../../../common/layout/CommonStyledWizardComponents";
import useApi from "../../../../hooks/useApi";
import { apiLoadMembersAllSearch } from "../../../../api/MemberApi";
import { MMDDyyyy } from "../../../../services/General";
import AutoCompleteSelectInput from "../../../common/input/SingleSelectAutocomplete";

function PanelGetMember({
  stepNumber,
  currentStep,
  name,
  title,
  member,
  selectedGroup,
  onMemberChange,
  errors,
  showPanel = true,
}) {
  const { loading: loadingSearchMembers, api: apiLoadSearchMembers } = useApi(
    apiLoadMembersAllSearch
  );

  async function loadSelectOptionData(selectedText) {
    const params = { groupId: selectedGroup.value, searchTerm: selectedText };

    return await apiLoadSearchMembers.call(params, async (result) => {
      const options = result.resources.map((r) => {
        return {
          memberId: r.memberId,
          dateOfBirth: MMDDyyyy(new Date(r.dateOfBirth)),
          firstName: r.memberInfo.firstName,
          lastName: r.memberInfo.lastName,
          selectedMember: r,
        };
      });
      return options;
    });
  }

  function getOptionLabel(option) {
    return (
      <>
        {option.memberId}{" "}
        <span className="option-member-name">
          {option.firstName} {option.lastName}&nbsp;&nbsp;&nbsp;&nbsp;
          {option.dateOfBirth}
        </span>
      </>
    );
  }

  if (!showPanel || currentStep < stepNumber) {
    return <></>;
  }

  return (
    <StyledWizardListItem step={stepNumber}>
      <StyledStepHeaderDiv>{title}</StyledStepHeaderDiv>
      <div className="row">
        <div className="col-12 col-lg-6">
          <AutoCompleteSelectInput
            id={name}
            name={name}
            label=""
            value={member === null ? "" : member}
            labelStyle={{ display: "none" }}
            loadSelectOptionData={loadSelectOptionData}
            loading={loadingSearchMembers}
            getOptionLabel={(option) => getOptionLabel(option)}
            getOptionValue={(option) => option?.memberId || ""}
            onChange={onMemberChange}
            placeholder=""
            autoFocus={currentStep === stepNumber}
            error={errors[name]}
          />
        </div>
      </div>
    </StyledWizardListItem>
  );
}

export default PanelGetMember;
