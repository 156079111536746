import React from "react";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import TextAreaInput from "../../common/input/TextAreaInput";

function RuleAreaArtifacts({ ruleChanges, handleRuleChange, errors }) {
  return (
    <>
      <StyledRowDiv className="row">
        <h5>Artifacts</h5>
        <div className="col-12 col-lg-6">
          <TextAreaInput
            id="includedArtifacts"
            label="Included Artifacts"
            onChange={handleRuleChange}
            placeholder="Included Artifacts"
            name="includedArtifactsVm"
            rows="3"
            value={ruleChanges.includedArtifactsVm}
            error={errors.includedArtifacts}
          />
        </div>
        <div className="col-12 col-lg-6">
          <TextAreaInput
            id="excludedArtifacts"
            label="Excluded Artifacts"
            onChange={handleRuleChange}
            placeholder="Excluded Artifacts"
            name="excludedArtifactsVm"
            rows="3"
            value={ruleChanges.excludedArtifactsVm}
            error={errors.excludedArtifacts}
          />
        </div>
        <div className="col-12 col-lg-6">
          <TextAreaInput
            id="ruleArtifacts"
            label="Rule Artifacts"
            onChange={handleRuleChange}
            placeholder="Rule Artifacts"
            name="ruleArtifactsVm"
            rows="5"
            value={ruleChanges.ruleArtifactsVm}
            error={errors.ruleArtifacts}
          />
        </div>
      </StyledRowDiv>
    </>
  );
}

export default RuleAreaArtifacts;
