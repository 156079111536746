import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useRuleEditParser from "../../../hooks/useRuleEditParser";

function RuleEditorInput({
  name,
  id,
  value,
  label,
  onChange,
  placeholder,
  labelStyle,
  inputStyle,
  defaultHeight = "42px",
  parserFunction = null,
}) {
  const {
    getCurrentCursorPosition,
    handleTabKeyPress,
    handleEnterKeyPress,
    parseTextContentIntoHtml,
  } = useRuleEditParser();

  const divRef = useRef(null);
  const [content, setContent] = useState();
  const [offset, setOffset] = useState(0);

  let wrapperClass = "form-group";

  useEffect(() => {
    parseAndSetContent(value || "");
  });

  useLayoutEffect(() => {
    const selection = document.getSelection();
    if (!selection || !divRef.current) return;

    // Set the caret to the beggining - but only if the control has focus - otherwise, focus gets set when the control loads
    const isFocused = document.activeElement === divRef.current;

    if (isFocused) {
      selection.collapse(divRef.current, 0);

      // console.log("Setting cursor position to: " + offset);
      for (let index = 0; index < offset; index++) {
        selection.modify("move", "forward", "character");
      }
    }
  }, [offset, divRef?.current?.innerText]);

  function parseAndSetContent(text) {
    let newContent = "";

    if (parserFunction !== null) {
      newContent = parserFunction(text);
    } else {
      newContent = parseTextContentIntoHtml(text);
    }

    setContent(newContent);
  }
  function handleKeyDown(e) {
    // Reset field height
    e.target.style.height = "inherit";
    handleFocus(e);

    if (e.keyCode === 9) {
      e.preventDefault(); // this will prevent us from tabbing out of the editor
      handleTabKeyPress(divRef.current);
    } else if (e.keyCode === 13) {
      const position = getCurrentCursorPosition(divRef.current);
      handleEnterKeyPress(position);
    }
  }

  function handleInput(e) {
    let newText = e.target.innerText;
    // Simulate event
    onChange({ target: { name: name, value: newText } });

    const position = getCurrentCursorPosition(divRef.current);
    // console.log(`Computed cursor position: ${position}`);
    setOffset(position);

    parseAndSetContent(newText);
  }

  function handleBlur(e) {
    // Reset field height
    e.target.style.height = defaultHeight;
  }

  function handleFocus(e) {
    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    let height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    if (height > 20) height -= 20;

    e.target.style.height = `${height}px`;
  }

  return (
    <div className={wrapperClass}>
      <label htmlFor={id} style={labelStyle || {}}>
        {label}
      </label>
      <div className="field">
        <div
          id={id}
          name={name}
          className="rule-editor"
          style={inputStyle || {}}
          ref={divRef}
          contentEditable={true}
          role="textbox"
          placeholder={placeholder}
          aria-multiline={true}
          spellCheck={false}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onFocus={handleFocus}
          suppressContentEditableWarning={true}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
}

RuleEditorInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  cols: PropTypes.string,
  rows: PropTypes.string,
  value: PropTypes.string,
};

export default RuleEditorInput;
