import { useEffect, useState } from "react";
import _ from "lodash";
import { useAuth } from "../contexts/AuthContext";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { accessTokenRequest } from "../constants/Environments";
// import { parseJwt } from "../services/General";

export function useAuthProvider() {
  const { instance, inProgress } = useMsal();
  const { auth, setAuth } = useAuth();
  const [token, setToken] = useState("");
  const [userName, setUserName] = useState("");
  const [isAcquiringToken, setIsAcquiringToken] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const [lastTokenRefresh, setLastTokenRefresh] = useState(new Date());
  const _isAuthenticated = useIsAuthenticated();

  const isAuthenticated =
    global.TESTMODE === true && global.LOGGED_IN === true
      ? true
      : _isAuthenticated;
  const ADMIN_TOKEN =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJhcGk6Ly80MmI5NTk0Zi1jYWEyLTRlYTctYjJiZi1kZmRlNDhiOTkzODIiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8xZGE2Y2E4Yi0zZmZjLTRhMjctODlkNy04OTI0YjE4ZGYzOTkvIiwiaWF0IjoxNjc1NzkzNzEzLCJuYmYiOjE2NzU3OTM3MTMsImV4cCI6MTY3NTc5ODg5MywiYWNyIjoiMSIsImFpbyI6IkUyWmdZRGdUMXl0b0YveHB3bXJyU0dGaDE2eC9zcncvSG5rZVh1MFFZeThRSDJUK1ZDTStjSm5Kbm5DeDUrMVM2b1VHYVRlNkFBPT0iLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiNDJiOTU5NGYtY2FhMi00ZWE3LWIyYmYtZGZkZTQ4Yjk5MzgyIiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJIZXN0ZXIiLCJnaXZlbl9uYW1lIjoiSm9uIiwiaXBhZGRyIjoiMzUuMTMxLjE1My4zNiIsIm5hbWUiOiJKb24gSGVzdGVyIiwib2lkIjoiNGE4ZmViNDYtOGYxOC00MGQ1LTkxNjEtNTRjODgwODk2YzU3IiwicmgiOiIwLkFUY0FpOHFtSGZ3X0owcUoxNGtrc1kzem1VOVp1VUtpeXFkT3NyX2Yza2k1azRJM0FLMC4iLCJyb2xlcyI6WyJUYXNrLkFkbWluIl0sInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6InJFNjA3cldpaHZxempZWTdnWVNabXJuZElMVjRlMmQ2ZGJSVTZuOFlkZmciLCJ0aWQiOiIxZGE2Y2E4Yi0zZmZjLTRhMjctODlkNy04OTI0YjE4ZGYzOTkiLCJ1bmlxdWVfbmFtZSI6ImpoZXN0ZXJAYWNsYWltcnguY29tIiwidXBuIjoiamhlc3RlckBhY2xhaW1yeC5jb20iLCJ1dGkiOiJCT3F6ZUo4YTlFT0NKXzU0eGtnREFBIiwidmVyIjoiMS4wIn0.BmGnqOr4G_ZtojzCiM8ELAirHf9NENmSd4YJrI9bP7nk2_YEMSrGN9lodvm8FVQVGf79T2nwxmts2-dtQNHRH1qcvpOXtOI-zvOyt5cSYuY8ATGKfBy8B08mWjG90j40w2Y8LbI6LCXfkbhV-dq4UF5wOz3mHFmNj3S1pQVr2RP2E6-sDhq4-vIKMJAFdkZOsGgd_MdVTXo2bgOs51_ncl7KO5OqN1wMdI2HiG5cTjJsl3CiLcyFIzXwIWQmXV5lKD0T0p42zB8afmYQR7o_qvA-eq7nLhB-9G4buSCVRAoPl22deC1sm5gwc4cxQACfS4ba47HLsXwdwJYx718MMg";
  const BASIC_TOKEN =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJhcGk6Ly80MmI5NTk0Zi1jYWEyLTRlYTctYjJiZi1kZmRlNDhiOTkzODIiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8xZGE2Y2E4Yi0zZmZjLTRhMjctODlkNy04OTI0YjE4ZGYzOTkvIiwiaWF0IjoxNjc3MDc4OTc4LCJuYmYiOjE2NzcwNzg5NzgsImV4cCI6MTY3NzA4MzAyOSwiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhUQUFBQUF0TS9SSmU1Y0FaZ1dQcG9IK2R2YVpWaGQ0Yklpc3cyc2N4b2xPRUpJRjA1ZkoxeTNOdThTcUVHbmhlUGFJUFEiLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiNDJiOTU5NGYtY2FhMi00ZWE3LWIyYmYtZGZkZTQ4Yjk5MzgyIiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJBcGkiLCJnaXZlbl9uYW1lIjoiUmV1bml0ZSIsImlwYWRkciI6IjM1LjEzMS4xNTMuMzYiLCJuYW1lIjoiUmV1bml0ZSBBcGkgVXNlciIsIm9pZCI6IjI1MDUyZjQyLTViNzMtNGMwNC1iYjllLTVlNjZkZmNiNmVlMSIsInJoIjoiMC5BVGNBaThxbUhmd19KMHFKMTRra3NZM3ptVTladVVLaXlxZE9zcl9mM2tpNWs0STNBRk0uIiwicm9sZXMiOlsiVGFzay5Vc2VyIl0sInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6IlQ3UXlldXFuOXN2bURiQUMtWmJtWFdFNGl3NlhhZVljQ2FoNkxXeGhJeHMiLCJ0aWQiOiIxZGE2Y2E4Yi0zZmZjLTRhMjctODlkNy04OTI0YjE4ZGYzOTkiLCJ1bmlxdWVfbmFtZSI6InJldW5pdGVAYWNsYWltcnguY29tIiwidXBuIjoicmV1bml0ZUBhY2xhaW1yeC5jb20iLCJ1dGkiOiJYT1pZcXRNMEIwLWZCbHF6UHdFWkFBIiwidmVyIjoiMS4wIn0.QQ488DrapfCgfeeLq0LYIT3KgAGimLn04-QFQkGa5Nkyb4_KEAtyhloanNCc3cVN2hbZJ33Oh3xch5Iex8RjvU_jvAbWgXVyhD-_gm4b3M7hU2aEBHk4MWKO0KOtn6DNa2fRqlS8RM5grPLX8QHyZaQZvQHozkCUr008u39hJ48ILZWRz6boHHBAIJgSk4GWLiir2jmLMWrE3pFAPte35KQArOCxGi31GFmwWC_JVfjzpcozGkovrqqRPfuCjIiODyDwWQEBof0Av_nKjXw7aR0FafiCsx6immkz2dGwRM_aoEwYiWGEP7sFtLOoq-X4EEjURwcLRV0fQx37_XRIRg";
  const PARSER_TOKEN =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiJhcGk6Ly80MmI5NTk0Zi1jYWEyLTRlYTctYjJiZi1kZmRlNDhiOTkzODIiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8xZGE2Y2E4Yi0zZmZjLTRhMjctODlkNy04OTI0YjE4ZGYzOTkvIiwiaWF0IjoxNjc5OTIzNjczLCJuYmYiOjE2Nzk5MjM2NzMsImV4cCI6MTY3OTkyODYzMiwiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhUQUFBQWFyNytsVVhGLzNqa3hGWEQ0R2NjaXp4MnQ0Vk9SbExpMXJaU29wWTkwNFJzL0JQTmhHUzh0Y1V2UVNoZHdaamoiLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiNDJiOTU5NGYtY2FhMi00ZWE3LWIyYmYtZGZkZTQ4Yjk5MzgyIiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJQYXJzZXIgVXNlciIsImdpdmVuX25hbWUiOiJUZXN0IiwiaXBhZGRyIjoiMjA0LjExNi4yMDMuMjU0IiwibmFtZSI6IlRlc3QgUGFyc2VyIFVzZXIiLCJvaWQiOiJiYjk2MmU3Yy0yMGJkLTRkMjItOTJlNS03ZGU5MzE2NmNhN2MiLCJyaCI6IjAuQVRjQWk4cW1IZndfSjBxSjE0a2tzWTN6bVU5WnVVS2l5cWRPc3JfZjNraTVrNEkzQUhzLiIsInJvbGVzIjpbIlRhc2suUGFyc2VyIl0sInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInN1YiI6IkV5ZldQRXFZbU85bVVneWJicER3cHl2bk9JRTZVMGQ4N0J5Z0hfUThYN2MiLCJ0aWQiOiIxZGE2Y2E4Yi0zZmZjLTRhMjctODlkNy04OTI0YjE4ZGYzOTkiLCJ1bmlxdWVfbmFtZSI6InRlc3RwYXJzZXJAYWNsYWltcnguY29tIiwidXBuIjoidGVzdHBhcnNlckBhY2xhaW1yeC5jb20iLCJ1dGkiOiJydDF1TnpYdnlrZVZhb2dsSmtJa0FBIiwidmVyIjoiMS4wIn0.ZpMnmSqkPYjse7iEP_PD-heyyzhCEC9AiJp4EGBsMGgEUWvfH_-KqWnC2N4pvg0ws8678liaTykRbnUEnPcp5f3YrKQJJKFlKY4EJnca7yrfPvxm_TtOaFyPcFqEQSC4Dt0uNxU6bfwreQO7VU89evvQ4hP-jU8s4_tUaWr4NtBX7wqc3jcMz-oHAEta2lJWXNLyApV46vAMfipXjiDxNmdzDeOJKGoGaRGFF3voMIC9BVT_cnvfLD9Tn9GOh6XoKWO5x8-6gHix_RpkpqOf92na0yg2DiS18jwiaXDXJL39QHxk1Br2GHe7NsYTh8THCGoXQKXRSq6uoPRerLuwuA";

  useEffect(() => {
    if (global.TESTMODE === true && global.LOGGED_IN === true) {
      setupForTestMode();
      return;
    }

    if (isAcquiringToken) return;

    if (_isAuthenticated && _.isEmpty(auth.token)) {
      setIsAcquiringToken(true);
      window.setTimeout(() => {
        doAcquireToken();
      }, 1000);
      return;
    }

    if (auth.token && auth.token !== "") {
      setToken(auth.token);
    }
    if (auth.userName && auth.userName !== "") {
      setUserName(auth.userName);
    }

    setAuthenticating(
      inProgress !== InteractionStatus.None && !_isAuthenticated
    );
  }, [
    auth.token,
    auth.userName,
    inProgress,
    _isAuthenticated,
    isAcquiringToken,
  ]);

  function setupForTestMode() {
    const _username = global.USERNAME;
    const _token =
      global.USER_ROLE === "Basic"
        ? BASIC_TOKEN
        : global.USER_ROLE === "Parser"
        ? PARSER_TOKEN
        : ADMIN_TOKEN;
    setToken(_token);
    setUserName(_username);
    handleSuccessfulAuthentication(_token, _username);
  }

  async function doAcquireToken() {
    // Only want to fetch token here if authenticated AND the token is empty. If we have a token, we
    //  will renew it on the next screen load or api call.

    // User is now authenticated, so get their account and token
    const accounts = instance.getAllAccounts();
    const account = accounts && accounts.length > 0 ? accounts[0] : {};

    if (!_.isEmpty(account)) {
      // console.log(`User is authenticated. Fetching access token...`);
      acquireToken(account)
        .then((resp) => {
          // console.log(`Token fetched successfully.`);
          handleSuccessfulAuthentication(resp.token, resp.userName);
        })
        .catch((error) => {
          // Silent renewal failed, so log user out
          setIsAcquiringToken(false);
          console.log(
            "Silent acquire token failed. Logging user out...",
            error
          );
          logoutSessionTimeout();
        });
    } else {
      console.log(
        `User has no accounts after authentication! Is this even possible?`
      );
    }
  }

  async function acquireToken(account) {
    if (global.TESTMODE === true) return;

    var request = { ...accessTokenRequest, account: account };
    return instance.acquireTokenSilent(request).then((resp) => {
      return {
        token: resp.accessToken,
        userName: (resp.idTokenClaims && resp.idTokenClaims.name) || "",
      };
    });
  }

  function handleSuccessfulAuthentication(token, userName) {
    setLastTokenRefresh(new Date());
    setAuth({
      type: ContextProviderActions.setAuthenticationSuccessResult,
      payload: {
        token,
        userName,
      },
    });

    setIsAcquiringToken(false);
  }

  const login = async () => {
    if (global.TESTMODE === true) return;

    return instance.loginRedirect(accessTokenRequest).then((resp) => {
      return {
        token: resp.accessToken,
        userName: (resp.idTokenClaims && resp.idTokenClaims.name) || "",
      };
    });
  };

  function handleSuccessfulLogout() {
    setAuth({
      type: ContextProviderActions.saveLoggedOut,
      payload: ``,
    });
  }

  function handleFailedFailedLogout(error) {
    console.log(error);
    setAuth({
      type: ContextProviderActions.saveLogOutFailed,
      payload: `Logout failed: ${error}`,
    });
  }

  function logoutInternal() {
    if (global.TESTMODE === true) return;

    return instance.logoutRedirect(accessTokenRequest).then((resp) => {
      return {};
    });
  }

  const logout = async () => {
    handleSuccessfulLogout();

    return logoutInternal().catch((error) => {
      handleFailedFailedLogout(error);
    });
  };

  const logoutSessionTimeout = async () => {
    setAuth({
      type: ContextProviderActions.saveLoggedOut,
      payload: `Your session has expired.`,
    });

    return logoutInternal().catch((error) => {
      console.log("Error logging out", error);
    });
  };

  function handleUserLogoutWithMessage(message) {
    setAuth({
      type: ContextProviderActions.saveLoggedOut,
      payload: message,
    });

    instance.logoutRedirect(accessTokenRequest);
  }

  function renewToken() {
    if (global.TESTMODE === true) return;

    // Algorithm for renewing token:
    // 1. If token has expired, log the user out with a session timeout message.
    // 2. If no Microsoft AD accounts found, log the user out with a message.
    // 3. If token exists and we have renewed the token in the last minute, do nothing.
    // 4. Silently renew or create the token.

    // if (hasTokenExpired()) {
    //   handleUserLogoutWithMessage(
    //     `Your session has expired. Click 'Sign in' to log back into the application.`
    //   );
    //   return;
    // }

    const accounts = instance.getAllAccounts();
    const account = accounts && accounts.length > 0 ? accounts[0] : null;
    if (_.isEmpty(account)) {
      handleUserLogoutWithMessage(
        `No user accounts found. Click 'Sign in' to log back into the application.`
      );
      return;
    }

    const secondsSinceLastTokenRefresh =
      (new Date().getTime() - lastTokenRefresh.getTime()) / 1000;
    if (secondsSinceLastTokenRefresh < 60) {
      // console.log(
      //   `No need to renew token since it was refreshed ${secondsSinceLastTokenRefresh} seconds ago.`
      // );
      return;
    }

    console.log(`Renewing token...`);

    acquireToken(account)
      .then((resp) => {
        console.log(`Token renewed successfully.`);
        handleSuccessfulAuthentication(resp.token, resp.userName);
      })
      .catch((error) => {
        // Silent renewal failed, so log user out
        console.log("Silent token renewal error. Logging user out...", error);
        logoutSessionTimeout();
      });
  }

  // function hasTokenExpired() {
  //   if (_.isEmpty(auth.token)) return false;

  //   const exp = parseJwt(auth.token).exp;
  //   const expDate = new Date(0).setUTCSeconds(exp);
  //   const now = new Date().getTime();
  //   // console.log(
  //   //   `Checking if token expired. Token expire date: ${expDate}, current time: ${now}`
  //   // );
  //   const expired = expDate < now;

  //   return expired;
  // }

  // function handleFailedAuthentication(error) {
  //   console.log(error);
  //   setAuth({
  //     type: ContextProviderActions.setAuthenticationFailResult,
  //     payload: `Login failed: ${error}`,
  //   });
  // }

  const authActions = {
    login,
    logout,
    renewToken,
  };

  return {
    token,
    authenticating,
    isAuthenticated,
    userName,
    authActions,
  };
}
