import React from "react";
import CheckboxInput from "../../common/input/CheckboxInput";
import { useMobile } from "../../../hooks/useMobile";
import TextInput from "../../common/input/TextInput";

function PriorAuthSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onActiveChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="priorAuthorizationNumber"
            label="Prior Auth Number"
            onChange={onChange}
            placeholder="Prior Authorization"
            name="priorAuthorizationNumber"
            value={search.priorAuthorizationNumber}
            error={errors.priorAuthorizationNumber}
          />
          <TextInput
            id="pharmacy"
            label="Pharmacy"
            onChange={onChange}
            placeholder="Pharmacy NPI"
            name="pharmacy"
            value={search.pharmacy}
            error={errors.pharmacy}
          />
          <TextInput
            id="prescriptionNumber"
            label="Prescription Number"
            onChange={onChange}
            placeholder="Prescription Number"
            name="prescriptionNumber"
            value={search.prescriptionNumber}
            error={errors.prescriptionNumber}
          />
          <TextInput
            id="memberId"
            label="Member"
            onChange={onChange}
            placeholder="Cardholder Id"
            name="memberId"
            value={search.memberId}
            error={errors.memberId}
          />
          <TextInput
            id="productId"
            label="Product Id"
            onChange={onChange}
            placeholder="Product Id"
            name="productId"
            value={search.productId}
            error={errors.productId}
          />
          <TextInput
            id="groupId"
            label="Group"
            onChange={onChange}
            placeholder="Group Id"
            name="groupId"
            value={search.groupId}
            error={errors.groupId}
          />
          <CheckboxInput
            id="active"
            label="Active Only"
            onChange={onActiveChange}
            placeholder="Active Only"
            name="active"
            checked={search.active}
            error={errors.active}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default PriorAuthSearchForm;
