import React, { useEffect, useState } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import { handleCollapseExpandAll } from "../../../services/General";
import { useCache } from "../../../contexts/CacheContext";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import ActionMenu from "../../common/ui/ActionMenu";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import CacheStatusDiagnostics from "./CacheStatusDiagnostics";
import { createCacheKeyViewModel } from "../../../viewmodels/cacheVm";
import StatusPill from "../../common/ui/StatusPill";
import { notifySuccess } from "../../../services/NotificationService";
import useApi from "../../../hooks/useApi";
import {
  apiClearEntireCache,
  apiGetCacheKeyContents,
  apiGetCacheStatusAndDiagnostics,
  apiInvalidateCacheKey,
  apiSetCacheState,
} from "../../../api/cacheApi";
import CacheStatusSearchForm from "./CacheStatusSearchForm";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import CacheKeyContentsDialog from "./CacheKeyContentsDialog";
import HelpLink from "../../common/ui/HelpLink";

function CacheStatus() {
  const { auth } = useAuth();
  const { cacheData, setCacheData } = useCache();
  const { loading, api: apiLoadCacheStatus } = useApi(
    apiGetCacheStatusAndDiagnostics
  );
  const { loading: settingCacheState, api: apiEnableDisableCache } =
    useApi(apiSetCacheState);
  const { loading: clearingCache, api: apiClearCache } =
    useApi(apiClearEntireCache);
  const { loading: clearingCacheKeys, api: apiClearCacheKey } = useApi(
    apiInvalidateCacheKey
  );
  const { loading: loadingKeyContents, api: apiGetKeyContents } = useApi(
    apiGetCacheKeyContents
  );
  const [errors, setErrors] = useState({});
  const [loadData, setLoadData] = useState(true);
  const [showCacheStatusModal, setShowCacheStatusModal] = useState(false);
  const [showClearEntireCacheModal, setShowClearEntireCacheModal] =
    useState(false);
  const [showClearCacheKeyModal, setShowClearCacheKeyModal] = useState(false);
  const [actionKeyName, setActionKeyName] = useState("");
  const [isClearSingleKey, setIsClearSingleKey] = useState(false);
  const [showViewKeyContentsModal, setShowViewKeyContentsModal] =
    useState(false);
  const [keyContents, setKeyContents] = useState("");

  const [collapsedState, setCollapsedState] = useState([
    { name: "Search Parameters", collapsed: false },
    { name: "Cache Diagnostics", collapsed: false },
  ]);

  const diagnostics =
    (cacheData.cacheStatus && cacheData.cacheStatus.keys) || [];

  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadCacheStatus();
    }
  }, [auth.authenticated, loadData]);

  async function loadCacheStatus() {
    apiLoadCacheStatus.call(
      cacheData.search,
      (result) => {
        setLoadData(false);
        const vm = _.cloneDeep(result);
        vm.keys = vm.keys.map((r) => createCacheKeyViewModel(r));
        vm.keys = getSortedArray(vm.keys, cacheData.search.orderBy);

        setCacheData({
          type: ContextProviderActions.loadCacheStatus,
          payload: vm,
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setCacheData({
      type: ContextProviderActions.saveCacheSearch,
      payload: search,
    });
  }

  async function handleReset() {
    var oneHourAgo = new Date();
    oneHourAgo.setHours(oneHourAgo.getHours() - 1);
    setSearchChanges({
      ...cacheData.search,
      startDate: oneHourAgo,
      endDate: new Date("1/1/3000"),
      keyMatch: "",
    });
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function onSubmit(event) {
    var updatedSearch = { ...cacheData.search, pageNumber: 1 };
    setSearchChanges(updatedSearch);
    await handleSearch(event);
  }

  async function handleSearch(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;
    setLoadData(true);
  }

  async function toggleCacheStatus() {
    setShowCacheStatusModal(false);
    const cacheState = !cacheData.cacheStatus.cacheEnabled;

    apiEnableDisableCache.call(cacheState, (result) => {
      setLoadData(true);

      notifySuccess(
        `Cache has been ${cacheState === true ? "enabled" : "disabled"}`
      );
    });
  }

  async function clearEntireCache() {
    setShowClearEntireCacheModal(false);

    apiClearCache.call(null, (result) => {
      setLoadData(true);

      notifySuccess(`Cache has been cleared successfully.`);
    });
  }

  async function clearCacheKey() {
    setShowClearCacheKeyModal(false);

    apiClearCacheKey.call(actionKeyName, (result) => {
      setLoadData(true);
      setActionKeyName("");
      notifySuccess(
        isClearSingleKey
          ? `This key has been cleared from the cache.`
          : `The keys have been cleared from the cache.`
      );
    });
  }

  async function handleViewKeyContents(keyName) {
    setShowViewKeyContentsModal(true);
    setActionKeyName(keyName);

    apiGetKeyContents.call(keyName, (result) => {
      setKeyContents(result);
    });
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...cacheData.search,
      [target.name]: target.value,
    });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...cacheData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...cacheData.search, endDate: date });
  }

  function handleSort(event) {
    var indexAsc = cacheData.search.orderBy.indexOf(`+${event.target.id}`);
    var indexDesc = cacheData.search.orderBy.indexOf(`-${event.target.id}`);

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...cacheData.search,
        orderBy: `+${event.target.id}`,
        pageNumber: 1,
      });
    if (indexAsc > -1)
      return updateSort({
        ...cacheData.search,
        orderBy: `-${event.target.id}`,
        pageNumber: 1,
      });
    if (indexDesc > -1)
      return updateSort({ ...cacheData.search, orderBy: "", pageNumber: 1 });

    function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      handleClientSideSort(updatedSearch);
    }
  }

  function handleClientSideSort(search) {
    const vm = _.cloneDeep(cacheData.cacheStatus);
    vm.keys = getSortedArray(vm.keys, search.orderBy);

    setCacheData({
      type: ContextProviderActions.loadCacheStatus,
      payload: vm,
    });
  }

  function getSortedArray(keys, orderBy) {
    const direction = orderBy.substring(0, 1);
    const sortBy = orderBy.substring(1);

    if (direction === "+")
      return keys.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
    else return keys.sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : -1));
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        <HelpLink path="/Tools/Cache-Status-Screen" label="Help" />
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <div className="flex-row-without-wrap">
          <h1 style={{ display: "inline-block", paddingTop: "5px" }}>
            Cache Status
          </h1>
          {!loadData && !settingCacheState && cacheData.cacheStatus && (
            <StatusPill
              pillStyle={{
                display: "inline-block",
                marginLeft: "10px",
                minWidth: "50px",
                textAlign: "center",
              }}
              status={
                cacheData.cacheStatus.cacheEnabled === true ? "On" : "Off"
              }
            />
          )}
        </div>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowCacheStatusModal(true)}
            disabled={settingCacheState}
          >
            {cacheData.cacheStatus.cacheEnabled === true
              ? "Disable caching"
              : "Enable caching"}
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowClearEntireCacheModal(true)}
            disabled={clearingCache}
            style={{ marginLeft: "10px" }}
          >
            Clear entire cache
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <ConfirmDialog
        title="Change Cache Status"
        question={`Are you sure you wish to ${
          cacheData.cacheStatus.cacheEnabled ? "disable" : "enable"
        } caching?`}
        showModal={showCacheStatusModal}
        onNo={() => setShowCacheStatusModal(false)}
        onYes={toggleCacheStatus}
      />
      <ConfirmDialog
        title="Clear Cache"
        question={
          <>
            <strong style={{ color: "var(--notify-danger)" }}>WARNING:</strong>{" "}
            This will remove ALL keys from the cache! Are you sure you wish to
            continue?
          </>
        }
        showModal={showClearEntireCacheModal}
        onNo={() => setShowClearEntireCacheModal(false)}
        onYes={clearEntireCache}
      />
      <ConfirmDialog
        title={isClearSingleKey ? "Clear Cache Key" : "Clear Cache Keys"}
        question={
          isClearSingleKey ? (
            `Are you sure you wish to remove the key '${actionKeyName}' from the cache?`
          ) : (
            <>
              <strong style={{ color: "var(--notify-danger)" }}>
                WARNING:
              </strong>{" "}
              This will remove ALL keys from the cache that match the pattern{" "}
              <code>*{actionKeyName}*</code> regardless of the entered date
              range or the diagnostic records showing below. Are you sure you
              wish to continue?
            </>
          )
        }
        showModal={showClearCacheKeyModal}
        onNo={() => setShowClearCacheKeyModal(false)}
        onYes={clearCacheKey}
      />
      <CacheKeyContentsDialog
        keyName={actionKeyName}
        contents={keyContents}
        showDialog={showViewKeyContentsModal}
        loading={loadingKeyContents}
        onCloseDialog={() => {
          setShowViewKeyContentsModal(false);
          setKeyContents("");
          setActionKeyName("");
        }}
      />

      {loading ||
      loadData ||
      settingCacheState ||
      clearingCacheKeys ||
      clearingCache ? (
        <Spinner />
      ) : (
        <>
          <ActionMenu
            title="Actions"
            items={[
              { value: "ExpandAll", label: "Expand All" },
              { value: "CollapseAll", label: "Collapse All" },
            ]}
            onSelectAction={(value, label) => {
              handleCollapseExpandAll(
                value === "CollapseAll",
                collapsedState,
                setCollapsedState
              );
            }}
          />
          <div className="container-fluid" style={{ marginTop: "5px" }}>
            <ExpandCollapseDetailSection
              sectionTitle="Search Parameters"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Tools/Cache-Status-Screen&anchor=search-parameters"
            >
              <CacheStatusSearchForm
                errors={errors}
                search={cacheData.search}
                onStartDateChange={handleStartDateChange}
                onEndDateChange={handleEndDateChange}
                onSearch={onSubmit}
                onReset={handleReset}
                onChange={handleSearchChange}
                onClearCacheKeys={(match) => {
                  setIsClearSingleKey(false);
                  setActionKeyName(match);
                  setShowClearCacheKeyModal(true);
                }}
              />
            </ExpandCollapseDetailSection>
            <ExpandCollapseDetailSection
              sectionTitle="Cache Diagnostics"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Tools/Cache-Status-Screen&anchor=cache-diagnostics"
            >
              <CacheStatusDiagnostics
                diagnostics={diagnostics}
                search={cacheData.search}
                onSearch={setSearchChanges}
                onSort={handleSort}
                onClearCacheKey={(match) => {
                  setIsClearSingleKey(true);
                  setActionKeyName(match);
                  setShowClearCacheKeyModal(true);
                }}
                onViewKeyContents={handleViewKeyContents}
              />
            </ExpandCollapseDetailSection>
          </div>
        </>
      )}
    </Authorize>
  );
}

export default CacheStatus;
