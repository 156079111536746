import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { notifyError } from "../../../services/NotificationService";
import { FieldParser } from "./FieldParser";
import { RejectionParser } from "./RejectionParser";
import Spinner from "../../common/ui/Spinner";
import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  jsonToArray,
  packetParserDisplayTypes,
} from "../../../services/General";
import {
  PacketParserTabs,
  usePacketParser,
} from "../../../contexts/PacketParserContext";
import { useAuth } from "../../../contexts/AuthContext";
import { apiLoadPacket } from "../../../api/ClaimApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import AdvancedPacketEditor from "../../common/ui/AdvancedPacketEditor";
import TextAreaInput from "../../common/input/TextAreaInput";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import {
  StyledActionBar,
  StyledBackButtonDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import { Nav, NavDropdown } from "react-bootstrap";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";
import SelectInput from "../../common/input/SingleSelect";

const HEADER_SIZE = 270;

function PacketParser() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { packetParserData, setPacketParserData } = usePacketParser();
  const { loading, api: apiLoadPacketData } = useApi(apiLoadPacket);
  const { userPrefs } = useUserPreferences();
  const [editMode, setEditMode] = useState(false);
  const [divHeight, setDivHeight] = useState(400);
  const [parserDivHeight, setParserDivHeight] = useState(600);
  const divRef = useRef(null);
  const [windowSizeW, windowSizeH] = useWindowSize([0, 0]);
  const [showActionsMenu, setShowActionsMenu] = useState(false);

  const [requestChanges, setRequestChanges] = useState(
    packetParserData.packetRequest
  );
  const [packetId] = useState(
    (params && params.id) || "00000000-0000-0000-0000-000000000000"
  );

  const requestRejections = packetParserData.packetResponse.requestRejections;
  const responseRejections = packetParserData.packetResponse.responseRejections;
  const requestFields =
    jsonToArray(packetParserData.packetResponse.requestJson) || [];
  const responseFields =
    jsonToArray(packetParserData.packetResponse.responseJson) || [];

  const displayType = packetParserData
    ? packetParserData.displayType
    : { label: "Condensed View", value: "condensed" };

  let hasId =
    !_.isEmpty(packetId) && packetId !== "00000000-0000-0000-0000-000000000000";

  useEffect(() => {
    if (auth.authenticated) {
      // Clear any previously-selected field
      setSelectedFieldId(true, "");
      loadPacket();
    }
  }, [auth.authenticated]);

  // Set the top div height when window size changes or user expands/collapses top section.
  useEffect(() => {
    setDivHeight(divRef?.current?.clientHeight ?? 400);
    setParserDivHeight(windowSizeH - HEADER_SIZE ?? 600);
  }, [
    windowSizeW,
    windowSizeH,
    userPrefs?.expandCollapseState?.find((o) => o.id === "PacketParserForm")
      ?.value,
    editMode,
  ]);

  function setSelectedFieldId(isRequest, fieldId) {
    setPacketParserData({
      type: ContextProviderActions.setSelectedFieldId,
      payload: { isRequest: isRequest, fieldId: fieldId },
    });
  }

  async function loadPacket() {
    const newRequest = { ...requestChanges };

    apiLoadPacketData.call(
      { id: packetId, claimRequest: newRequest },
      (result) => {
        newRequest.requestContents = result.requestContents;
        newRequest.responseContents = result.responseContents;
        newRequest.processedDate = result.processedDate;
        newRequest.claimId = packetId;
        newRequest.transactionIndex = result.transactionIndex || 0;
        setRequestChanges(newRequest);

        savePacketRequest(newRequest);
        savePacketResponse(result);

        setEditMode(
          (newRequest.requestContents === null &&
            newRequest.responseContents) === null
        );
        setActiveTab(PacketParserTabs.Tab1);
        setDivHeight(divRef?.current?.clientHeight ?? 400);
        setParserDivHeight(windowSizeH - HEADER_SIZE ?? 600);
      }
    );
  }

  function savePacketRequest(packetRequest) {
    setPacketParserData({
      type: ContextProviderActions.savePacketRequest,
      payload: packetRequest,
    });
  }

  function savePacketResponse(packetResponse) {
    setPacketParserData({
      type: ContextProviderActions.savePacketResponse,
      payload: packetResponse,
    });
  }

  async function handleLoadPacket(event) {
    event.preventDefault();
    if (auth.authenticated) {
      loadPacket();
    } else {
      notifyError("Unable to get data. Please refresh the page");
    }
  }

  async function handleClearPacket(event) {
    event.preventDefault();
    // On clear, just reload the screen with the packet id set to all zeroes so refresh works correctly after that
    window.location.href =
      "/packetparser?id=00000000-0000-0000-0000-000000000000";
  }

  function handleRequestChange({ target }) {
    setRequestChanges({
      ...requestChanges,
      [target.name]: target.value,
    });
  }

  let actionsMenuTimer = null;
  function handleMouseEnterActionsMenu() {
    window.clearTimeout(actionsMenuTimer);
    setShowActionsMenu(true);
  }

  function handleMouseLeaveActionsMenu() {
    // Wait a small amount of time before actually closing the menu since a very small gap below the dropdown
    //   and above the menu can cause the menu to close immediately if the user moves the mouse slowly.
    window.clearTimeout(actionsMenuTimer);
    actionsMenuTimer = window.setTimeout(() => setShowActionsMenu(false), 500);
  }

  function getActionButton() {
    return (
      <Nav>
        <NavDropdown
          title="Actions"
          show={showActionsMenu}
          onMouseEnter={handleMouseEnterActionsMenu}
          onMouseLeave={handleMouseLeaveActionsMenu}
          onClick={() => setShowActionsMenu(!showActionsMenu)}
          onSelect={() => setShowActionsMenu(false)}
        >
          {hasId && (
            <NavDropdown.Item as={Link} to={`/claim/${packetId}`}>
              Claim Detail
            </NavDropdown.Item>
          )}
          {auth.isAdmin && (
            <NavDropdown.Item as={Link} to={`/testprocessor/${packetId}`}>
              Test Processor
            </NavDropdown.Item>
          )}
          {!editMode && (
            <NavDropdown.Item
              as={Link}
              onClick={() => {
                // Clear any previously-selected field and switch back to first tab
                setSelectedFieldId(true, "");
                setActiveTab(PacketParserTabs.Tab1);
                setEditMode(true);
              }}
            >
              Edit contents
            </NavDropdown.Item>
          )}
        </NavDropdown>
      </Nav>
    );
  }

  function setActiveTab(activeTab) {
    setPacketParserData({
      type: ContextProviderActions.setActivePacketParserTab,
      payload: activeTab,
    });
  }

  function handleSetDisplayType(newDisplayType) {
    const d = packetParserDisplayTypes.find(
      (dt) => dt.value === newDisplayType
    );
    if (!d) {
      console.log("Packet Parser display type not found: " + newDisplayType);
      return;
    }

    setPacketParserData({
      type: ContextProviderActions.setPacketParserDisplayType,
      payload: { ...d },
    });
  }

  function getParserContent() {
    return (
      <>
        {!editMode ? (
          <div>
            <StyledRequestResponseLabel
              displayType={displayType.value}
              htmlFor="requestContents"
            >
              Request
            </StyledRequestResponseLabel>
          </div>
        ) : (
          <></>
        )}
        {editMode ? (
          <TextAreaInput
            id="requestContents"
            label="Request Contents"
            onChange={handleRequestChange}
            placeholder="Request Contents"
            cols="40"
            rows="5"
            name="requestContents"
            value={requestChanges.requestContents || ""}
          />
        ) : (
          <AdvancedPacketEditor
            id="requestContents"
            fields={requestFields}
            value={requestChanges.requestContents || ""}
            isRequest={true}
            displayType={displayType.value}
            setDisplayType={handleSetDisplayType}
          />
        )}
        {!editMode ? (
          <div style={{ marginTop: "10px" }}>
            <StyledRequestResponseLabel
              displayType={displayType.value}
              htmlFor="requestContents"
            >
              Response
            </StyledRequestResponseLabel>
          </div>
        ) : (
          <></>
        )}
        {editMode ? (
          <TextAreaInput
            id="responseContents"
            label="Response Contents"
            onChange={handleRequestChange}
            placeholder="Response Contents"
            cols="40"
            rows="5"
            name="responseContents"
            value={requestChanges.responseContents || ""}
          />
        ) : (
          <AdvancedPacketEditor
            id="responseContents"
            fields={responseFields}
            value={requestChanges.responseContents || ""}
            isRequest={false}
            displayType={displayType.value}
            setDisplayType={handleSetDisplayType}
          />
        )}
        {editMode && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            {" "}
            <button
              value="Packet Parser"
              type="button"
              onClick={handleClearPacket}
              className="btn btn-secondary"
            >
              Clear
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                marginLeft: "12px",
                minWidth: "86px",
              }}
            >
              Parse
            </button>
          </div>
        )}
      </>
    );
  }

  function getFieldsContent() {
    return (
      <>
        {(requestRejections || []).length > 0 ? (
          <>
            <div>
              <RejectionParser
                typeDesc="Request Format Rejections"
                rejections={requestRejections}
              />
            </div>
            <br />
          </>
        ) : (
          <></>
        )}
        {(responseRejections || []).length > 0 ? (
          <>
            <div>
              <RejectionParser
                typeDesc="Response Format Rejections"
                rejections={responseRejections}
              />
            </div>
            <br />
          </>
        ) : (
          <></>
        )}
        <div>
          <FieldParser
            typeDesc="Request Fields"
            fields={requestFields}
            isRequest={true}
          />
        </div>
        <br />
        <div>
          <FieldParser
            typeDesc="Response Fields"
            fields={responseFields}
            isRequest={false}
          />
        </div>
      </>
    );
  }

  function handleDisplayTypeChanged(option) {
    handleSetDisplayType(option.value);
  }

  return (
    <Authorize>
      <form
        onSubmit={handleLoadPacket}
        style={{ backgroundColor: "var(--bg-flat)" }}
      >
        <StyledBackButtonDiv>
          <button
            title="Return to previous screen"
            className="btn btn-link"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Tools/Packet-Parser-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledFixedHeaderRowDiv>
          <h1>Packet Parser</h1>
          <div
            className="flex-row-with-wrap"
            style={{ marginTop: "-10px", width: "225px" }}
          >
            <SelectInput
              id="displayType"
              name="displayType"
              label=""
              labelStyle={{ display: "none" }}
              options={[...packetParserDisplayTypes]}
              value={displayType}
              onChange={handleDisplayTypeChanged}
              placeholder="Select view"
              controlStyle={{
                width: "180px",
              }}
            />
          </div>
          <StyledActionBar>{getActionButton()}</StyledActionBar>
        </StyledFixedHeaderRowDiv>

        {loading ? (
          <Spinner />
        ) : !editMode ? (
          <>{getParserContent()}</>
        ) : (
          <>
            {editMode ? (
              <>{getParserContent()}</>
            ) : (
              <StyledParserWrapperDiv
                style={{ height: `${parserDivHeight}px` }}
              >
                <StyledParserDiv>
                  <StyledFixedWrapperDiv ref={divRef}>
                    {getParserContent()}
                  </StyledFixedWrapperDiv>
                  {!editMode && (
                    <StyledTableWrapperDiv
                      style={{ top: `${divHeight + 50}px` }}
                    >
                      {getFieldsContent()}
                    </StyledTableWrapperDiv>
                  )}
                </StyledParserDiv>
              </StyledParserWrapperDiv>
            )}
          </>
        )}
      </form>
    </Authorize>
  );
}

const StyledFixedHeaderRowDiv = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 48px;
  z-index: 2;
  background-color: var(--bg-flat);
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  flex-wrap: wrap;

  @media only screen and (max-width: 767px) {
    margin-left: -5%;
    margin-right: -5%;
    padding: 0 10px;
  }
`;

const StyledParserWrapperDiv = styled.div`
  display: block;
`;

const StyledParserDiv = styled.div`
  position: absolute;
  top: 135px;
  left: 5%;
  right 5%;
  bottom: 0;
  overflow-y: hidden;
  z-index: 1;

  @media only screen and (max-width: 767px) {
    left: 0;
    right: 0;
  }
`;

const StyledFixedWrapperDiv = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  max-height: 45%;
  overflow-y: auto;

  @media only screen and (max-width: 767px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const StyledTableWrapperDiv = styled.div`
  position: absolute;
  bottom: 45px;
  width: 100%;
  overflow-y: scroll;
`;

const StyledRequestResponseLabel = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border-radius: 3px;

  display: ${(props) =>
    props.displayType === "condensed" ? "inline-block" : "block"};

  padding: ${(props) => (props.displayType === "condensed" ? "0" : "2px 4px")};

  margin-top: ${(props) => (props.displayType === "condensed" ? "0" : "20px")};

  color: ${(props) =>
    props.displayType === "condensed" ? "var(--text-dark)" : "#FFF"};

  background-color: ${(props) =>
    props.displayType === "condensed"
      ? "transparent"
      : "var(--tab-control-header-active)"};
`;

// const StyledMobileMargin = styled.div`
//   @media only screen and (max-width: 767px) {
//     margin-left: -5%;
//     margin-right: -5%;
//   }
// `;

export default PacketParser;
