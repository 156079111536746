import React from "react";
import AsyncSelect from "react-select/async";
import styled from "styled-components";

function AutoCompleteSelectInput({
  name,
  id,
  value,
  label,
  disabled,
  error,
  onChange,
  loadSelectOptionData,
  loading,
  getOptionLabel,
  getOptionValue,
  placeholder,
  labelStyle,
  selectStyle,
  controlStyle,
  autoFocus,
}) {
  let wrapperClass = "form-group";
  if (error.length > 0) {
    wrapperClass += " has-error";
  }

  const styles = {
    valueContainer: (provided) => ({ ...provided, ...selectStyle }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "var(--input-bg)",
      borderColor: "var(--input-border)",
      color: "var(--input-text)",
      ...controlStyle,
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "var(--submenu-bg)",
      borderColor: "var(--submenu-border)",
      color: "var(--submenu-text)",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isFocused
        ? "var(--submenu-active-bg)"
        : "var(--submenu-bg)",
      color: state.isFocused
        ? "var(--submenu-active-text)"
        : "var(--submenu-text)",
      ":active": {
        backgroundColor: "var(--submenu-active-bg)",
        color: "var(--submenu-active-text)",
      },
    }),
  };

  return (
    <div className={wrapperClass}>
      <label id={`${id}-label`} htmlFor={id} style={labelStyle || {}}>
        {label}
      </label>
      <div className="field" style={{ position: "relative" }}>
        <StyledSelect
          aria-label={`SELECT-${label}`}
          aria-labelledby={`${id}-label`}
          isDisabled={disabled}
          id={id}
          name={name}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          loadOptions={loadSelectOptionData}
          styles={styles}
          value={value}
          isClearable={true}
          placeholder={placeholder}
          onChange={onChange}
          blurInputOnSelect={true}
          isSearchable={true}
          autoFocus={autoFocus || false}
          noOptionsMessage={({ inputValue }) =>
            loading
              ? "Loading..."
              : !inputValue
              ? "Start typing to load options..."
              : "No results found"
          }
        />
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

// const StyledLoadingDiv = styled.div`
//   position: absolute;
//   right: 40px;
//   top: 5px;
// `;

const StyledSelect = styled(AsyncSelect)`
  & > div {
    color: var(--input-text);
    background-color: var(--input-bg);
    border: 1px solid var(--input-border);
    border-radius: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 3px 0 2px 0;
    transition: none;

    &:hover {
      border: 1px solid var(--input-border);
    }

    svg {
      color: var(--input-dark);
    }

    & > div > div {
      color: var(--input-text);
    }
  }
`;

AutoCompleteSelectInput.defaultProps = {
  error: "",
};

export default AutoCompleteSelectInput;
