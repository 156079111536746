import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { disableAnimations } from "../../services/General";
import TextInput from "../common/input/TextInput";
import HelpLink from "../common/ui/HelpLink";

function DuplicateRecordDialog({
  title,
  instructions,
  value,
  showModal,
  onCancel,
  onCopy,
  placeholder = "New Id",
}) {
  const [localVal, setLocalVal] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (localVal !== value) {
      setLocalVal(value);
    }
  }, []);

  function onChange({ target }) {
    setLocalVal(target.value);
  }

  function formIsValid() {
    const _errors = {};

    if ((localVal || "").trim() === "") {
      _errors.newRecordId = `${placeholder} is required`;
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function submitOnEnter(e) {
    if (e && e.key && e.key === "Enter") {
      onDuplicateRecord();
    }
  }

  function onDuplicateRecord() {
    if (!formIsValid()) return;
    onCopy(localVal);
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={onCancel}
        animation={!disableAnimations()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
            <HelpLink
              path="/General/Dialogs&anchor=duplicate-record-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{instructions}</p>
          <TextInput
            id="newRecordId"
            label=""
            labelStyle={{ display: "none" }}
            onChange={onChange}
            onKeyDown={submitOnEnter}
            placeholder={placeholder}
            name="newRecordId"
            value={localVal}
            error={errors.newRecordId}
            autoFocus={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={onDuplicateRecord}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DuplicateRecordDialog;
