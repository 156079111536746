import React from "react";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";
import SelectInput from "../../common/input/SingleSelect";

function ReportTemplateSearchForm({
  search,
  dataSourceList,
  errors,
  onChange,
  onSearch,
  onReset,
  onDataSourceChanged,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="name"
            label="Name"
            onChange={onChange}
            placeholder="Name"
            name="name"
            value={search.name}
            error={errors.name}
          />
          <SelectInput
            id="entityType"
            name="entityType"
            label="Data Source"
            options={dataSourceList}
            value={search.entityType}
            onChange={onDataSourceChanged}
            placeholder="Select a data source"
            error={errors.entityType}
          />
          <TextInput
            id="fileNameTemplate"
            label="File Name Template"
            onChange={onChange}
            placeholder="File Name Template"
            name="fileNameTemplate"
            value={search.fileNameTemplate}
            error={errors.fileNameTemplate}
          />
          <TextInput
            id="externalDataRuleTableDefinitionName"
            label="Rule Table Definition"
            onChange={onChange}
            placeholder="Rule Table Definition"
            name="externalDataRuleTableDefinitionName"
            value={search.externalDataRuleTableDefinitionName}
            error={errors.externalDataRuleTableDefinitionName}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default ReportTemplateSearchForm;
