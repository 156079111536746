import React from "react";
import _ from "lodash";
import DatePickerInput from "../../common/input/DatePickerInput";
import { Modal, Button } from "react-bootstrap";
import { notifyError } from "../../../services/NotificationService";
import { disableAnimations } from "../../../services/General";
import HelpLink from "../../common/ui/HelpLink";

function RuleSetDatesDialog({
  showDateModal,
  ruleChanges,
  setRuleChanges,
  itemEffectiveDate,
  setItemEffectiveDate,
  itemTerminationDate,
  setItemTerminationDate,
  dateSetArray,
  setDateSetArray,
  dateSetId,
  setDateSetId,
  onCloseModal,
}) {
  function handleItemEffectiveDateChange(date) {
    setItemEffectiveDate(date);
  }

  function handleItemTerminationDateChange(date) {
    setItemTerminationDate(date);
  }

  const handleTerminateRecord = () => {
    const termDate = new Date("1/1/2000");
    setItemEffectiveDate(termDate);
    setItemTerminationDate(termDate);
  };

  const handleSaveDates = () => {
    let newRule = _.cloneDeep(ruleChanges);

    if (_.isEmpty(dateSetArray)) return;
    if (dateSetId === "") return;
    let item = newRule[dateSetArray].find((n) => n.id === dateSetId);

    if (!_.isEmpty(itemEffectiveDate) && !_.isDate(itemEffectiveDate)) {
      notifyError("Effective Date is not a valid date");
      return;
    }
    if (!_.isEmpty(itemTerminationDate) && !_.isDate(itemTerminationDate)) {
      notifyError("Termination Date is not a valid date");
      return;
    }
    if (!_.isDate(itemEffectiveDate)) item.effectiveDate = null;
    else {
      item.effectiveDate = new Date(itemEffectiveDate.toISOString());
    }
    if (!_.isDate(itemTerminationDate)) item.terminationDate = null;
    else {
      item.terminationDate = new Date(itemTerminationDate.toISOString());
    }
    setRuleChanges(newRule);
    onCloseModal();
  };

  const handleCancelDates = () => {
    setItemEffectiveDate(null);
    setItemTerminationDate(null);
    setDateSetArray("");
    setDateSetId("");
    onCloseModal();
  };

  return (
    <>
      <Modal
        show={showDateModal}
        onHide={handleCancelDates}
        animation={!disableAnimations()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Set Effective/Termination Dates
            <HelpLink
              path="/Processing-Rules/Rule-Screen&anchor=set-dates-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePickerInput
            id="itemEffectiveDate"
            name="itemEffectiveDate"
            label="Effective Date"
            value={itemEffectiveDate}
            labelStyle={{
              fontWeight: "bold",
              fontSize: "1.2em",
              paddingTop: "5px",
            }}
            placeholder="Effective Date"
            onChange={handleItemEffectiveDateChange}
            showTimeInput={false}
          />
          <span className="input-group-addon" style={{ margin: "10px" }} />
          <DatePickerInput
            id="itemTerminationDate"
            label="Termination Date"
            name="itemTerminationDate"
            value={itemTerminationDate}
            labelStyle={{
              fontWeight: "bold",
              fontSize: "1.2em",
              paddingTop: "5px",
            }}
            placeholder="Termination Date"
            onChange={handleItemTerminationDateChange}
            showTimeInput={false}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            variant="secondary"
            className="mr-auto"
            onClick={handleTerminateRecord}
          >
            Terminate
          </Button>
          <div>
            <Button
              variant="secondary"
              style={{ marginRight: "10px" }}
              onClick={handleCancelDates}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveDates}>
              Save Changes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RuleSetDatesDialog;
