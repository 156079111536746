import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyRuleGroupSummary,
  emptyRule,
  emptyRuleSearch,
  emptySavedRuleEditState,
} from "../viewmodels/rulesVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadRules:
      return {
        ...state,
        rules: action.payload.rules,
        count: action.payload.count,
      };

    case ContextProviderActions.saveRuleSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadRule:
      return {
        ...state,
        rule: action.payload,
      };

    case ContextProviderActions.saveRuleEditState:
      return {
        ...state,
        savedRuleEditState: action.payload,
      };

    case ContextProviderActions.loadRuleGroupSummary:
      return {
        ...state,
        groupSummary: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in RulesContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  rules: [],
  rule: emptyRule,
  search: emptyRuleSearch,
  count: 0,
  savedRuleEditState: emptySavedRuleEditState,
  groupSummary: emptyRuleGroupSummary,
};

const Rules = createContext({
  rulesData: initialState,
  setRulesData: () => null,
});

// eslint-disable-next-line react/prop-types
const RulesProvider = ({ children }) => {
  const [rulesData, setRulesData] = useReducer(reducer, initialState);

  return (
    <Rules.Provider value={{ rulesData, setRulesData }}>
      {children}
    </Rules.Provider>
  );
};

export default RulesProvider;
export const useRules = () => useContext(Rules);
