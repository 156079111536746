import React, { useState, useEffect, Fragment } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import Authorize from "../../common/layout/Authorize";
import { useNavigate } from "react-router-dom";
import {
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import Spinner from "../../common/ui/Spinner";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import { handleCollapseExpandAll } from "../../../services/General";
import ActionMenu from "../../common/ui/ActionMenu";
import { apiLoadDataSourcesAll } from "../../../api/ReportsApi";
import SelectInput from "../../common/input/SingleSelect";
import DatePickerInput from "../../common/input/DatePickerInput";
import FileUploadButton from "../../common/input/FileUploadButton";
import {
  emptyImport,
  emptyImportErrorsSearch,
  getSelectedDataSourceFromValue,
  getSelectedImportModeFromValue,
  importModeTypes,
} from "../../../viewmodels/importVm";
import { apiImportData } from "../../../api/ImportApi";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function ImportData() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { loading: loadingDataSources, api: apiLoadDataSources } = useApi(
    apiLoadDataSourcesAll
  );
  const { loading: uploading, api: apiUpload } = useApi(apiImportData);
  const [validationFailures, setValidationFailures] = useState([]);
  const [transactionId, setTransactionId] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [dataSourceList, setDataSourceList] = useState([]);
  const [searchErrors, setSearchErrors] = useState({
    ...emptyImportErrorsSearch,
    pageSize: 10,
  });
  const [changes, setChanges] = useState(emptyImport);
  const [errors, setErrors] = useState({});

  const [collapsedState, setCollapsedState] = useState([
    { name: "Configure Import", collapsed: false },
    { name: "Import Data", collapsed: false },
  ]);

  useEffect(() => {
    if (auth.authenticated) {
      loadDataSourceList();
    }
  }, [auth.authenticated]);

  async function loadDataSourceList() {
    apiLoadDataSources.call(null, async (result) => {
      let _dataSourceList = [];
      let dsResult = [...result] || [];

      // Filter out admin data sources for non-admins
      if (!auth.isAdmin) {
        dsResult = dsResult.filter(
          (c) => c.reportableItemAllowableRole !== "Admin"
        );
      }

      _dataSourceList = (dsResult || [])
        .map((c) => {
          return { value: c.id, label: c.label };
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1));

      setDataSourceList(_dataSourceList);
    });
  }

  function formIsValid() {
    const _errors = {};

    if (changes.dataSource === "") {
      _errors.dataSource = "Source must be selected";
    }
    if (changes.importMode === "") {
      _errors.importMode = "Import Mode must be selected";
    }
    if (
      changes.importMode === "terminatechanges" &&
      changes.terminationDate === null
    ) {
      _errors.terminationDate = "Termination Date must be entered";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleDataSourceChange(option) {
    setChanges({ ...changes, dataSource: option.value });
  }

  function handleImportModeChange(option) {
    setChanges({ ...changes, importMode: option.value });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  function handleUploadPreClickValidation() {
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before importing.");
      return false;
    }

    return true;
  }

  function getImportResultMessage(result) {
    let msg = "";

    const inserted = result.modifiedIds.filter(
      (r) => r.modificationType === "Insert"
    ).length;
    const updated = result.modifiedIds.filter(
      (r) => r.modificationType === "Update"
    ).length;
    const deleted = result.deletedIds.length;
    const notModified = result.notModifiedIds.length;

    msg += ` ${inserted} ${inserted === 1 ? "record" : "records"} inserted.`;
    msg += ` ${updated} ${updated === 1 ? "record" : "records"} updated.`;
    msg += ` ${deleted} ${deleted === 1 ? "record" : "records"} deleted.`;
    msg += ` ${notModified} ${
      notModified === 1 ? "record" : "records"
    } not modified.`;

    return msg;
  }

  function handleUploadFileContents(content) {
    var model = changes;
    model.fileContents = content;
    setTransactionId("");
    setResultMessage("");

    apiUpload.call(model, async (result) => {
      if ((result.importValidationFailures || []).length > 0) {
        setResultMessage(getImportResultMessage(result));
        setValidationFailures(result.importValidationFailures);
      } else {
        // Success
        setValidationFailures([]);
        setTransactionId(result.id);
        setResultMessage(getImportResultMessage(result));
        notifySuccess("File imported successfully");
      }
    });
  }

  return (
    <Authorize>
      <StyledHeaderRowDiv>
        <h1>Import Data</h1>
        <StyledScreenHelpDiv>
          <HelpLink path="/Tools/Import-Data-Screen" label="Help" />
        </StyledScreenHelpDiv>
      </StyledHeaderRowDiv>

      {loadingDataSources ? (
        <Spinner />
      ) : (
        <>
          <ActionMenu
            title="Actions"
            items={[
              { value: "ExpandAll", label: "Expand All" },
              { value: "CollapseAll", label: "Collapse All" },
            ]}
            onSelectAction={(value, label) =>
              handleCollapseExpandAll(
                value === "CollapseAll",
                collapsedState,
                setCollapsedState
              )
            }
          />
          <div className="container-fluid" style={{ marginTop: "5px" }}>
            <ExpandCollapseDetailSection
              sectionTitle="Configure Import"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Tools/Import-Data-Screen&anchor=configure-import"
            >
              <StyledRowDiv className="row">
                <div className="col-12 col-lg-6">
                  <SelectInput
                    id="dataSource"
                    name="dataSource"
                    label="Source"
                    options={dataSourceList || []}
                    value={getSelectedDataSourceFromValue(
                      dataSourceList,
                      changes.dataSource
                    )}
                    onChange={handleDataSourceChange}
                    placeholder=""
                    error={errors.dataSource}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv className="row">
                <div className="col-12 col-lg-6">
                  <SelectInput
                    id="importMode"
                    name="importMode"
                    label="Import Mode"
                    options={importModeTypes}
                    value={getSelectedImportModeFromValue(changes.importMode)}
                    onChange={(option) => handleImportModeChange(option)}
                    placeholder=""
                    error={errors.importMode}
                  />
                </div>
                {changes.importMode === "terminatechanges" && (
                  <div className="col-12 col-lg-6">
                    <DatePickerInput
                      id="terminationDate"
                      label="Termination Date"
                      name="terminationDate"
                      value={changes.terminationDate}
                      placeholder="Termination Date"
                      onChange={handleTerminationDateChange}
                      error={errors.terminationDate}
                      showTimeInput={false}
                    />
                  </div>
                )}
              </StyledRowDiv>
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Import Data"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Tools/Import-Data-Screen&anchor=import-data"
            >
              <StyledRowDiv className="row">
                <div className="col-12 col-lg-6">
                  <FileUploadButton
                    fileTypes=".csv"
                    uploading={uploading}
                    uploadFileContents={handleUploadFileContents}
                    onPreClick={handleUploadPreClickValidation}
                  />
                </div>
              </StyledRowDiv>
              {uploading ? (
                <Spinner
                  spinnerStyle={{ height: "200px", lineHeight: "200px" }}
                />
              ) : (
                <>
                  {validationFailures.length === 0 && transactionId !== "" && (
                    <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                      <h3 style={{ fontSize: "20px" }}>Import Results</h3>
                      <p>{resultMessage}</p>
                      <div className="row">
                        <div className="flex-row-with-wrap">
                          <span
                            style={{ color: "var(--notify-success)" }}
                            className="material-icons"
                          >
                            done
                          </span>
                          <strong style={{ color: "var(--notify-success)" }}>
                            Success!&nbsp;&nbsp;
                          </strong>
                        </div>
                      </div>
                      <StyledRowDiv className="row">
                        <div className="col-12">
                          <button
                            className="btn btn-link link-underline"
                            onClick={() =>
                              navigate("/auditbulkrecords/" + transactionId)
                            }
                          >
                            View detailed import results
                          </button>
                        </div>
                      </StyledRowDiv>
                    </StyledRowDiv>
                  )}
                  {validationFailures.length > 0 && (
                    <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                      <h3 style={{ fontSize: "20px" }}>Import Results</h3>
                      <p>{resultMessage}</p>
                      <div className="row">
                        <div className="flex-row-with-wrap">
                          <span
                            style={{ color: "var(--notify-danger)" }}
                            className="material-icons"
                          >
                            warning
                          </span>
                          <strong style={{ color: "var(--notify-danger)" }}>
                            Warning:&nbsp;&nbsp;
                          </strong>
                          The following validation errors occurred during
                          import. Correct the problems and try the import again.
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <ResponsiveGrid
                            gridId="ImportUploadDataValidationErrors"
                            totalRecords={validationFailures.length}
                            search={searchErrors}
                            setSearch={setSearchErrors}
                            dataRows={validationFailures}
                            enableClientRowPager={true}
                            columnDefs={[
                              {
                                name: "row",
                                label: "Row",
                                disableSort: true,
                                style: { width: "100px" },
                                getValue: (row) => row.row || 1,
                              },
                              {
                                name: "columnName",
                                label: "Column",
                                disableSort: true,
                                style: { width: "25%" },
                              },
                              {
                                name: "message",
                                label: "Error",
                                disableSort: true,
                                style: { width: "75%" },
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </StyledRowDiv>
                  )}
                </>
              )}
            </ExpandCollapseDetailSection>
          </div>
        </>
      )}
    </Authorize>
  );
}

export default ImportData;
