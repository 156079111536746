/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadPrescribers(search, token, signal) {
  const uri = API_URL + "/prescribers" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "npi", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "npi",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "contacts.lastName",
      operator: "array_contains",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "contacts.firstName",
      operator: "array_contains",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.active) {
    where.push({ name: "TEMPORALITEMMATCH", operator: "=", values: [] });
  }
  if (search && search.npi && search.npi.length > 0)
    where.push({ name: "npi", operator: "=", values: [search.npi] });
  if (search && search.deaNumber && search.deaNumber.length > 0)
    where.push({
      name: "deaNumber",
      operator: "=",
      values: [search.deaNumber],
    });

  if (search && search.firstName && search.firstName.length > 0)
    where.push({
      name: "contacts.firstName",
      operator: "array_contains",
      values: [search.firstName],
    });
  if (search && search.lastName && search.lastName.length > 0)
    where.push({
      name: "contacts.lastName",
      operator: "array_contains",
      values: [search.lastName],
    });
  if (search && search.address && search.address.length > 0)
    where.push({
      name: "addresses.address1",
      operator: "array_contains",
      values: [search.address],
    });
  if (search && search.city && search.city.length > 0)
    where.push({
      name: "addresses.city",
      operator: "array_contains",
      values: [search.city],
    });
  if (search && search.state && search.state.length > 0)
    where.push({
      name: "addresses.state",
      operator: "array_contains",
      values: [search.state],
    });

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadPrescriber(id, token, signal) {
  let uri = API_URL + "/prescriber/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddPrescriber(m, token, signal) {
  let uri = API_URL + "/prescriber" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdatePrescriber(params, token, signal) {
  const id = params.id;
  const m = params.model;

  let uri = API_URL + "/prescriber/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeletePrescriber(id, token, signal) {
  let uri = API_URL + "/prescriber/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadPrescribers.funcName = "apiLoadPrescribers";
apiLoadPrescriber.funcName = "apiLoadPrescriber";
apiAddPrescriber.funcName = "apiAddPrescriber";
apiUpdatePrescriber.funcName = "apiUpdatePrescriber";
apiDeletePrescriber.funcName = "apiDeletePrescriber";
