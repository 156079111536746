import _ from "lodash";
import { pageSize } from "../constants/Environments";

export const emptyProcessHistory = {
  id: "",
  description: "",
  reportDefinitionId: "",
  reportName: "",
  reportTemplateId: "",
  reportTemplateName: "",
  runType: "", // Summary, Detail
  runNumber: "",
  runDate: "",
  runDuration: "",
  reportFileName: "",
  mainProcessId: "",
};

export const emptyProcessHistorySearch = {
  description: "",
  runType: "",
  reportName: "",
  startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  endDate: new Date("1/1/3000"),
  dateRange: "Past24hours",
  runBy: "",
  orderBy: ["runDate-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  return vm;
}
