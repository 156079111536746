import React from "react";
import _ from "lodash";
import styled from "styled-components";
import CreatableSelectInput from "../../common/input/CreatableSelectInput";
import SelectInput from "../../common/input/SingleSelect";
import TextInput from "../../common/input/TextInput";
import DatePickerInput from "../../common/input/DatePickerInput";
import CheckboxInput from "../../common/input/CheckboxInput";

function RuleTableDataValueByType({
  columnSpec,
  error,
  value,
  onChange,
  autoFocus,
}) {
  const id = columnSpec.id;
  const name = columnSpec.id;
  const label = columnSpec.name;
  const isKey = columnSpec.isKey;
  const controlType = columnSpec.controlType;
  const listSource = columnSpec.listSource;
  const datatype = columnSpec.valueType;
  const validValues = columnSpec.validValues || [];

  function handleSingleDropdownChange(option) {
    // Need to mimic a normal onChange event, regardless of control used, so caller can handle it.
    onChange({ target: { name: name, value: option ? option.value : "" } });
  }

  function handleDateChange(date) {
    // Convert the local date to UTC in ISO format before saving since this is the format expected
    //   by this component and by the API.
    // jon, 6/28/24: Removing conversion to ISO here since changing to date only.

    // Need to mimic a normal onChange event, regardless of control used, so caller can handle it.
    const dateVal = date === null ? null : date;
    onChange({ target: { name: name, value: dateVal } });
  }

  function handleCheckboxChange({ target }) {
    onChange({ target: { name: name, value: target.checked } });
  }

  function getStringValueFromNumber(n) {
    const value = `${n}`;
    return value;
  }

  function handleNumberChange({ target }) {
    onChange({
      target: {
        name: target.name,
        value:
          _.trim(target.value) === ""
            ? ""
            : getStringValueFromNumber(target.value),
      },
    });
  }

  // Is field a list selecting its values from another rule table?
  //   If so, show an editable dropdown with the valid values but allow custom values.
  if (controlType === "List" && listSource === "RuleTableDefinition") {
    // Build the list of valid values
    const values = validValues.map((v) => {
      return { value: v, label: v };
    });

    const selectedValue = {
      value: value,
      label: values.find((v) => v.value === value)?.label || value,
    };
    return (
      <StyledFieldWrapper>
        {isKey && (
          <StyledKeyIndicator className="material-icons">
            key
          </StyledKeyIndicator>
        )}
        {validValues.length === 0 && (
          <div className="form-group">
            <label>{label}</label>
            <div className="field">
              <div className="flex-row-with-wrap">
                <span
                  style={{ color: "var(--notify-danger)" }}
                  className="material-icons"
                >
                  warning
                </span>
                <strong style={{ color: "var(--notify-danger)" }}>
                  Warning:
                </strong>
                No values found for this column. If the values come from another
                rule table, you should first enter data there. Although not
                recommended, you may also enter custom values here.
              </div>
            </div>
          </div>
        )}
        <CreatableSelectInput
          id={id}
          name={name}
          label={label}
          options={values}
          value={selectedValue}
          onChange={handleSingleDropdownChange}
          placeholder="Select or enter value..."
          error={error}
          autoFocus={autoFocus}
        />
      </StyledFieldWrapper>
    );
  }

  // Is field a list with a fixed set of values? If so, show a dropdown with the valid values only.
  if (controlType === "List" && listSource === "ValidValues") {
    // Build the list of valid values
    const values = validValues.map((v) => {
      return { value: v, label: v };
    });

    const selectedValue = {
      value: value,
      label: values.find((v) => v.value === value)?.label,
    };
    return (
      <StyledFieldWrapper>
        {isKey && (
          <StyledKeyIndicator className="material-icons">
            key
          </StyledKeyIndicator>
        )}
        {validValues.length > 0 ? (
          <SelectInput
            id={id}
            name={name}
            label={label}
            options={values}
            value={selectedValue}
            onChange={handleSingleDropdownChange}
            placeholder=""
            error={error}
            autoFocus={autoFocus}
          />
        ) : (
          <>
            <div className="form-group">
              <label>{label}</label>
              <div className="field">
                <div className="flex-row-with-wrap">
                  <span
                    style={{ color: "var(--notify-danger)" }}
                    className="material-icons"
                  >
                    warning
                  </span>
                  <strong style={{ color: "var(--notify-danger)" }}>
                    Warning:
                  </strong>
                  No values found for this column. If the values come from
                  another rule table, you must first enter data there.
                </div>
              </div>
            </div>
          </>
        )}
      </StyledFieldWrapper>
    );
  }

  // If field is a number, render a number field
  if (controlType === "Input" && datatype === "Numeric") {
    return (
      <StyledFieldWrapper>
        {isKey && (
          <StyledKeyIndicator className="material-icons">
            key
          </StyledKeyIndicator>
        )}
        <TextInput
          id={id}
          label={label}
          onChange={handleNumberChange}
          placeholder=""
          name={name}
          value={value}
          error={error}
          isNumber={true}
          autoFocus={autoFocus}
        />
      </StyledFieldWrapper>
    );
  }

  // If field is a date, show a date control
  // jon, 6/29/24: Assume no time input for these dates since Effective/Termination dates are the norm here.
  if (controlType === "Input" && datatype === "Date") {
    return (
      <StyledFieldWrapper>
        {isKey && (
          <StyledKeyIndicator className="material-icons">
            key
          </StyledKeyIndicator>
        )}
        <DatePickerInput
          id={id}
          label={label}
          name={name}
          value={value === null ? undefined : new Date(value)}
          placeholder=""
          onChange={handleDateChange}
          error={error}
          showTimeInput={false}
          autoFocus={autoFocus}
        />
      </StyledFieldWrapper>
    );
  }

  // If field is a boolean, show a checkbox control
  if (controlType === "Input" && datatype === "Boolean") {
    return (
      <StyledFieldWrapper>
        {isKey && (
          <StyledKeyIndicator className="material-icons">
            key
          </StyledKeyIndicator>
        )}
        <CheckboxInput
          id={id}
          label={label}
          name={name}
          checked={value === "true"}
          placeholder=""
          onChange={handleCheckboxChange}
          error={error}
          showTimeInput={true}
          autoFocus={autoFocus}
        />
      </StyledFieldWrapper>
    );
  }

  // Use a text field for all other data types
  return (
    <StyledFieldWrapper>
      {isKey && (
        <StyledKeyIndicator className="material-icons">key</StyledKeyIndicator>
      )}
      <TextInput
        id={id}
        label={label}
        onChange={onChange}
        placeholder=""
        name={name}
        value={value}
        error={error}
        autoFocus={autoFocus}
      />
    </StyledFieldWrapper>
  );
}

const StyledFieldWrapper = styled.div`
  position: relative;
  margin: 0 10px;
`;

const StyledKeyIndicator = styled.span`
  position: absolute;
  display: inline-block;
  top: 2px;
  left: -20px;
  font-size: 18px !important;
  color: var(--drag-chip-border);
  transform: rotate(-45deg) scaleX(-1);
`;

export default RuleTableDataValueByType;
