import React from "react";
import _ from "lodash";
import {
  StyledCard,
  StyledDescription,
  StyledReturnButton,
} from "./RuleGroupSummaryCommonStyles";

function RuleGroupSummaryRequestValue({ item, linkedProperty, onClickReturn }) {
  const id = _.lowerCase(`req-${item.key}`);
  const matchLink = !_.isEmpty(linkedProperty) && id === linkedProperty;

  return (
    <StyledCard
      id={id}
      className={matchLink ? "selected pulse-fade-subtle" : ""}
    >
      {matchLink && (
        <StyledReturnButton title="Return" onClick={onClickReturn}>
          <i className="fa fa-angle-left"></i>
        </StyledReturnButton>
      )}
      <StyledDescription>
        <span className="charblock">{item.key}</span>
        <span style={{ paddingLeft: "10px" }}>{item.description}</span>
      </StyledDescription>
    </StyledCard>
  );
}

export default RuleGroupSummaryRequestValue;
