import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyTestProcessorConfig,
  emptyTestProcessorResponse,
} from "../viewmodels/testProcessorVm";

export const TestParserTabs = {
  Tab1: "Field Comparison",
  Tab2: "Response Packets",
};

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.saveTestProcessorResponse:
      return {
        ...state,
        testProcessorResponse: action.payload,
      };

    case ContextProviderActions.saveTestProcessorConfig:
      return {
        ...state,
        testProcessorConfig: action.payload,
      };

    case ContextProviderActions.setActiveTestParserTab:
      return {
        ...state,
        activeTab: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in TestProcessorContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  testProcessorConfig: emptyTestProcessorConfig,
  testProcessorResponse: emptyTestProcessorResponse,
  activeTab: TestParserTabs.Tab1,
};

const TestProcessor = createContext({
  testProcessorData: initialState,
  setTestProcessorData: () => null,
});

// eslint-disable-next-line react/prop-types
const TestProcessorProvider = ({ children }) => {
  const [testProcessorData, setTestProcessorData] = useReducer(
    reducer,
    initialState
  );

  return (
    <TestProcessor.Provider value={{ testProcessorData, setTestProcessorData }}>
      {children}
    </TestProcessor.Provider>
  );
};

export default TestProcessorProvider;
export const useTestProcessor = () => useContext(TestProcessor);
