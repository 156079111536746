/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadDbDrugUpdate(params, token, signal) {
  let uri = API_URL + "/dbutilconfig/drug/" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateDbDrugUpdate(params, token, signal) {
  const model = params.model;

  let uri = API_URL + "/dbutilconfig/drug/" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRunDbDrugUpdate(params, token, signal) {
  let uri = API_URL + "/dbutilconfig/rundrug/" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCancelDbDrugUpdate(params, token, signal) {
  let uri = API_URL + "/dbutilconfig/canceldrug/" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadDbDrugUpdate.funcName = "apiLoadDbDrugUpdate";
apiUpdateDbDrugUpdate.funcName = "apiUpdateDbDrugUpdate";
apiRunDbDrugUpdate.funcName = "apiRunDbDrugUpdate";
apiCancelDbDrugUpdate.funcName = "apiCancelDbDrugUpdate";
