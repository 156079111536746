import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { getHighlightedText } from "../../../services/General";

function WizardTaskPanel({
  taskId,
  taskTitle,
  taskDesc,
  symbolIconName,
  searchText,
  onStartTask,
}) {
  const hl = searchText || "";
  const titleHlResult = getHighlightedText(taskTitle, hl);
  const descHlResult = getHighlightedText(taskDesc, hl);

  if (
    _.trim(hl).length > 0 &&
    titleHlResult.count === 0 &&
    descHlResult.count === 0
  ) {
    return <></>;
  }

  return (
    <div className="col-12 col-lg-6">
      <StyledTaskBlock>
        <div>
          <span className="task-icon material-symbols-outlined">
            {symbolIconName}
          </span>
        </div>
        <div>
          <h3>{titleHlResult.html}</h3>
          <p>{descHlResult.html}</p>
        </div>
        <button
          type="button"
          className="btn btn-secondary btn-with-icon"
          onClick={() => onStartTask(taskId)}
        >
          Start
          <span className="material-icons">chevron_right</span>
        </button>
      </StyledTaskBlock>
    </div>
  );
}

const StyledTaskBlock = styled.div`
  margin-top: 13px;
  padding: 9px 11px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;

  span.task-icon {
    font-size: 56px;
    color: var(--drag-chip-border);
  }

  div {
    padding-right: 10px;

    h3 {
      font-size: 18px;
      line-height: 20px;
    }

    p {
      color: var(--text-medium);
      margin: 0;
      padding: 0;
    }
  }

  button.btn.btn-secondary {
    margin-left: auto;
    min-width: 80px;
  }

  button.btn.btn-secondary:hover,
  button.btn.btn-secondary:active {
    color: var(--button-primary-text);
    background-color: var(--button-primary-active-bg);
  }
`;

export default WizardTaskPanel;
