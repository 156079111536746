import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Badge from "./Badge";
import { useTestCoverageTracking } from "../../../contexts/TestCoverageTrackingContext";

function CoverageTrackingMenuButton() {
  const { userPrefs, setUserPrefs } = useUserPreferences();
  const { testCoverageTrackingData } = useTestCoverageTracking();
  const location = useLocation();

  function toggleCoverageTrackingVisibility(show) {
    setUserPrefs({
      type: ContextProviderActions.setCoverageTrackingVisibility,
      payload: show,
    });

    // If current page is Home, hide the sidebar when showing coverage tracking so it does not overlap
    if (show && location.pathname === "/") {
      setUserPrefs({
        type: ContextProviderActions.setShowSidebarOnHomePage,
        payload: false,
      });
    }
  }

  if (userPrefs.coverageTrackingVisibility) {
    return (
      <StyledExpandedContainer
        onClick={() => toggleCoverageTrackingVisibility(false)}
      >
        <i className="material-icons" style={{ marginRight: "6px" }}>
          troubleshoot
        </i>
        <p>
          Test Coverage Tracking{" "}
          <Badge>{testCoverageTrackingData.coverageResults.length}</Badge>
        </p>
        <i className="material-icons" style={{ marginLeft: "auto" }}>
          expand_more
        </i>
      </StyledExpandedContainer>
    );
  } else {
    return (
      <StyledCollapsedContainer
        onClick={() => toggleCoverageTrackingVisibility(true)}
      >
        <i className="material-icons" style={{ marginRight: "6px" }}>
          troubleshoot
        </i>
        <p>
          Test Coverage Tracking{" "}
          <Badge>{testCoverageTrackingData.coverageResults.length}</Badge>
        </p>
        <i className="material-icons" style={{ marginLeft: "auto" }}>
          chevron_right
        </i>
      </StyledCollapsedContainer>
    );
  }
}

const StyledCollapsedContainer = styled.div`
  position: absolute;
  top: 48px;
  left: calc(50% - 150px);
  height: 34px;
  width: 300px;
  padding: 7px 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  background: var(--pill-blue);
  border: 1px solid var(--pill-blue);
  border-top: none;
  border-radius: 0px 0px 6px 6px;
  cursor: pointer;

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
  }

  i {
    color: #fff;
  }
`;

const StyledExpandedContainer = styled.div`
  position: absolute;
  top: 48px;
  margin-left: 5%;
  margin-right: 5%;
  left: 0;
  right: 0;
  height: 34px;
  padding: 7px 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  background: var(--pill-blue);
  border: 1px solid var(--pill-blue);
  border-top: none;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
  }

  i {
    color: #fff;
  }
`;

export default CoverageTrackingMenuButton;
