import React from "react";
import _ from "lodash";
import { ruleConditionalOperators } from "../../../services/General";

export const NodeTypes = Object.freeze({
  Unknown: Symbol("Unknown"),
  Flag: Symbol("Flag"),
  InternalVariable: Symbol("InternalVariable"),
  ResponseFieldValue: Symbol("ResponseFieldValue"),
  Rejection: Symbol("Rejection"),
  RequestFieldValue: Symbol("RequestFieldValue"),
  GroupSetting: Symbol("GroupSetting"),
  EntityData: Symbol("EntityData"),
  CodeArtifact: Symbol("CodeArtifact"),
  CodeArtifactInput: Symbol("CodeArtifactInput"),
  CodeArtifactOutput: Symbol("CodeArtifactOutput"),
  LookupTable: Symbol("LookupTable"),
  TransmissionMessage: Symbol("TransmissionMessage"),
  TransactionMessage: Symbol("TransactionMessage"),
  RootMessage: Symbol("RootMessage"),
  ConditionalNode: Symbol("ConditionalNode"),
  ModuleNode: Symbol("ModuleNode"),
  ModuleInputNode: Symbol("ModuleInputNode"),
});

export function getFieldFromFieldListById(fieldList, id) {
  const field = fieldList.find((f) => f.id === id);
  if (field) {
    return field;
  }

  return null;
}

export function buildChildField(childId) {
  const field = { id: childId };
  return field;
}

export function locateAllParentsOfField(fieldList, node) {
  const childId = node.id;
  const parents = fieldList.filter(
    (f) => f.children.findIndex((c) => c.id === childId) >= 0
  );
  return parents;
}

export function removeChildFromFieldById(field, childId) {
  if (field === null) return;

  const removedChildren = _.remove(field.children, (c) => c.id === childId);
  return removedChildren;
}

export function addItemToArrayIfNotExist(array, item) {
  if (array.findIndex((c) => c.id === item.id) < 0) {
    array.push(item);
  }
}

export function locateField(ruleMap, fieldList, node, isRejection = false) {
  const nodeType = getItemTypeFromNode(ruleMap, node, isRejection);
  const id = buildUniqueIdForNode(node, nodeType);
  const field = getFieldFromFieldListById(fieldList, id);
  if (field !== null) return field;

  console.log(`Unable to locate field with id ${id}`, node);
  throw new Error(`Unable to locate field with id ${id}`);
}

export function getNodeTypeAbbreviation(
  nodeType,
  normalSize = true,
  noIcon = false
) {
  let abbr = "";
  let type = nodeType;
  if (type && type.description) type = type.description;

  switch (type) {
    case "Flag":
      abbr = normalSize ? "FLAG" : "F";
      break;
    case "InternalVariable":
      abbr = normalSize ? "VAR" : "V";
      break;
    case "ResponseFieldValue":
      abbr = noIcon ? "RES" : <span className="material-icons">check</span>;
      break;
    case "Rejection":
      abbr = noIcon ? "REJ" : <span className="material-icons">close</span>;
      break;
    case "RequestFieldValue":
      abbr = normalSize ? "REQ" : "R";
      break;
    case "GroupSetting":
      abbr = normalSize ? "GRP" : "G";
      break;
    case "EntityData":
      abbr = noIcon ? "ENT" : normalSize ? "ENTITY" : "E";
      break;
    case "CodeArtifact":
      abbr = noIcon ? "ART" : normalSize ? "ARTIFACT" : "A";
      break;
    case "CodeArtifactInput":
      abbr = noIcon ? "IN" : normalSize ? "INPUT" : "I";
      break;
    case "CodeArtifactOutput":
      abbr = noIcon ? "OUT" : normalSize ? "OUTPUT" : "O";
      break;
    case "LookupTable":
      abbr = noIcon ? "LKP" : normalSize ? "LOOKUP" : "L";
      break;
    case "TransmissionMessage":
    case "TransactionMessage":
    case "RootMessage":
      abbr = noIcon ? "MSG" : normalSize ? "MESSAGE" : "M";
      break;
    case "ConditionalNode":
      abbr = noIcon ? "CON" : normalSize ? "COND" : "C";
      break;
    case "ModuleNode":
      abbr = noIcon ? "MOD" : normalSize ? "MODULE" : "M";
      break;
    case "ModuleInputNode":
      abbr = noIcon ? "MIN" : normalSize ? "MOD INPUT" : "I";
      break;
    default:
      abbr = type;
  }

  return abbr;
}

export function getBadgeColorsFromType(
  nodeType,
  isActualNodeLink = true,
  isProcessed = true
) {
  let type = nodeType;
  if (type && type.description) type = type.description;

  let bgColor = "var(--ruleparser-blue)";
  let color = "#fff";
  let bgShadow = "transparent";

  switch (type) {
    case "InternalVariable":
      bgColor = "var(--ruleparser-yellow)";
      color = "#000";
      break;
    case "ResponseFieldValue":
      bgColor = "var(--ruleparser-green)";
      break;
    case "Rejection":
      bgColor = "var(--ruleparser-purered)";
      break;
    case "RequestFieldValue":
      bgColor = "var(--ruleparser-blue)";
      break;
    case "GroupSetting":
      bgColor = "var(--ruleparser-gray)";
      break;
    case "CodeArtifact":
      bgColor = "var(--ruleparser-red)";
      break;
    case "CodeArtifactInput":
    case "CodeArtifactOutput":
      bgColor = "var(--ruleparser-red)";
      break;
    case "EntityData":
      bgColor = "var(--ruleparser-darkpurple)";
      break;
    case "LookupTable":
      bgColor = "var(--ruleparser-green)";
      break;
    case "TransmissionMessage":
    case "TransactionMessage":
    case "RootMessage":
      bgColor = "var(--ruleparser-green)";
      break;
    case "ConditionalNode":
      bgColor = "var(--ruleparser-purple)";
      break;
    case "ModuleNode":
      bgColor = "var(--rulemap-module-border)";
      color = "var(--rulemap-module-text)";
      bgShadow = "2px 2px 15px var(--rulemap-module-border)";
      break;
    case "ModuleInputNode":
      bgColor = "var(--rulemap-moduleinput-border)";
      color = "var(--rulemap-moduleinput-text)";
      bgShadow = "2px 2px 15px var(--rulemap-module-border)";
      break;
    default:
  }

  if (!isActualNodeLink && isProcessed) {
    color = "#000";
    bgColor = "var(--ruleparser-lightblue)";
    bgShadow = "2px 2px 15px var(--ruleparser-blue)";
  } else if (!isActualNodeLink) {
    bgColor = "var(--ruleparser-gray)";
  }

  return { color, bgColor, bgShadow };
}

export function buildUniqueIdForNode(node, nodeType) {
  let key = node.displayKey || node.key || node.identifier;
  const type = nodeType.description;

  if (_.isEmpty(key)) {
    key = node.sourceKey;
  }

  if (_.isEmpty(key)) {
    console.log("***ERROR*** No key for node!", node);
  }

  const id = `${type}-${key}`;
  return id;
}

export function getValueFromNode(node, transactionNum) {
  // If this is a message node, return the message
  if (
    node.type === "TransactionMessage" ||
    node.type === "TransmissionMessage" ||
    node.type === "RootMessage"
  ) {
    return node.message;
  }

  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return "";

  if (status.valueTranslated && !_.isEmpty(status.valueTranslated))
    // Response field values have a valueTranslated property but all other nodes have setValue and isValueType
    return status.valueTranslated;

  if (!status.setValue || status.setValue === null) {
    return "NULL";
  }

  // Handle value types differently
  const val = "" + status.setValue;
  if (status.isValueType) return val;

  // Detect and object or array
  if (_.trim(val).indexOf("{") === 0) {
    return "{OBJECT}";
  }

  if (_.trim(val).indexOf("[{") === 0) {
    return "[ARRAY]";
  }

  // Assume this was not an array or object and return the set value
  return val;
}

export function getIsProcessedFromNode(node, transactionNum, nodeType) {
  // Response Fields are marked as not processed for some reason even though they are.
  // Messages don't have statuses so always marked them as processed
  if (
    nodeType === NodeTypes.ResponseFieldValue ||
    nodeType === NodeTypes.TransactionMessage ||
    nodeType === NodeTypes.TransmissionMessage ||
    nodeType === NodeTypes.RootMessage
  ) {
    return true;
  }

  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return false;

  const isProcessed =
    status.itemProcessed === true ||
    status.actionPassed === true ||
    status.actionTriggered === true;

  return isProcessed;
}

export function getIsConditionNodeFromNode(node, transactionNum) {
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) {
    // Handle group rule conditionals differently since they have no status actiontriggered
    return node.condition === "IsCompared" && transactionNum === -1;
  }

  const isConditionNode =
    node.condition === "IsCompared" && status.actionTriggered === true;

  return isConditionNode;
}

export function getSourceKeyTranslatedFromNode(node, transactionNum) {
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return node.displayKey;

  const key = `${status.sourceKeyTranslated}${
    status.sourceKeyTranslated !== node.sourceKey
      ? " (" + node.sourceKey + ")"
      : ""
  }`;

  return key;
}

export function getItemFromPropertyByDisplayKey(propArray, key) {
  const array = propArray || [];
  if (array.length < 1) return null;
  const item = array.find((i) => i.displayKey === key || i.key === key);
  return item || null;
}

export function getItemTypeFromNode(ruleMap, node, isRejection = false) {
  if (!node) return NodeTypes.Unknown;

  let type =
    node.valueType ||
    node.sourceType ||
    node.nodeType ||
    node.type ||
    (isRejection ? "Rejection" : "Unknown");

  if (!ruleMap || ruleMap === null) return NodeTypes.Unknown;

  if (type === "ResponseFieldValue") {
    const key = node.displayKey || node.sourceKey;
    // If this response field is listed in internal variables, change it to that instead.
    const itemID = getItemFromPropertyByDisplayKey(
      ruleMap.includedDataValues,
      key
    );
    if (itemID !== null) {
      type = "InternalVariable";
    } else {
      // Only keep this as a response field if it is listed explicitly in response fields - otherwise make it a variable
      const itemRS = (ruleMap.responseFields || []).find(
        (i) => i.displayKey === key
      );
      if (!itemRS) {
        type = "InternalVariable";
      }
    }
  }

  // Convert type string to NodeType
  const nodeType = NodeTypes[type];
  return nodeType;
}

export function getStatusFromNodeForTransaction(node, transactionNum) {
  if (!node || (node.statuses || []).length < 1 || transactionNum === -1)
    return null;

  const status = node.statuses.find(
    (s) => s.transactionIndex === transactionNum
  );
  if (!status) {
    return null;
  }

  return status;
}

export function getItemKeyFromNode(node) {
  let key = "UNKNOWN_KEY";
  if (!_.isEmpty(node.displayKey)) {
    key = node.displayKey;
  } else if (
    !_.isEmpty(node.identifier) &&
    !_.isEmpty(node.sourceKey) &&
    node.identifier !== node.sourceKey
  ) {
    key = `${node.identifier}/${node.sourceKey}`;
  } else if (!_.isEmpty(node.sourceKey)) {
    key = node.sourceKey;
  } else if (!_.isEmpty(node.key)) {
    key = node.key;
  }

  return key;
}

export function getItemNameFromNode(node, transactionNum) {
  if (!node) return "";

  // Is this is rejection? Use displaykey.
  var nodeType = getItemTypeFromNode(null, node);
  if (nodeType === "Rejection") return node.displayKey;

  // Do we have an item key?
  if (!_.isEmpty(node.itemKey)) return node.itemKey;

  // Do we have a source key?
  if (!_.isEmpty(node.sourceKey)) return node.sourceKey;

  // Do we have a key?
  if (!_.isEmpty(node.key)) return node.key;

  // Check for a source key translated in statuses
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return "Unknown";

  return status.sourceKeyTranslated;
}

export function getDescriptionFromNode(node) {
  if (!node) return "";

  if (!_.isEmpty(node.description)) return node.description;
  return "";
}

export function getConditionalValuesFromNode(node, transactionNum) {
  const val = {
    initial: "",
    intermediate: "",
    translated: "",
    result: "",
  };
  if (!node) return val;

  // Look up comparison type to get label
  const conditional = ruleConditionalOperators.find(
    (c) => c.value === node.comparison
  );
  if (!conditional) {
    console.log(
      `Conditional for ${node.comparison} not found in ruleConditionalOperators!`
    );
    return val;
  }

  // Check for a value intermediate in statuses
  let status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) {
    status = {
      comparisonValuesIntermediate: [],
      comparisonValuesTranslated: [],
      actionPassed: false,
    };
  }

  const operator = conditional.label;

  const showValue = conditional.requiresValue;
  const srcKeyValue = getValueFromNode(node, transactionNum);
  const comparisonValues = (node.comparisonValues || []).join(", ");
  const comparisonValuesIntermediate = (
    status.comparisonValuesIntermediate || []
  ).join(", ");
  const comparisonValuesTranslated = (
    status.comparisonValuesTranslated || []
  ).join(", ");

  val.result = status.actionPassed ? "True" : "False";

  if (!_.isEmpty(node.sourceKey)) {
    val.initial = `${node.sourceKey} ${operator}${
      showValue && !_.isEmpty(comparisonValues) ? " " + comparisonValues : ""
    }`;
  }

  if (!_.isEmpty(srcKeyValue)) {
    val.intermediate = `${srcKeyValue} ${operator}${
      showValue && !_.isEmpty(comparisonValuesIntermediate)
        ? " " + comparisonValuesIntermediate
        : ""
    }`;

    val.translated = `${srcKeyValue} ${operator}${
      showValue && !_.isEmpty(comparisonValuesTranslated)
        ? " " + comparisonValuesTranslated
        : ""
    }`;
  }

  return val;
}

function getRelevantLogsFromArray(array, transactionNum) {
  let logs = [];
  let relevantLogs;

  for (let i = 0; i < array.length; i++) {
    relevantLogs = (array[i].relevantLogs || []).filter(
      (r) => r.transactionIndex === transactionNum
    );

    // Return a log structure that is compatible with the LogCommon component.
    logs = logs.concat(
      relevantLogs.map((log) => {
        return {
          message: log.message,
          entryDate: log.logDate,
          logType: "Info",
          methodName: "",
        };
      })
    );
  }
  return logs;
}

export function getFullLogListFromRuleMap(ruleMap, transactionNum) {
  let logs = [];

  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.codeArtifacts, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.includedDataValues, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.includedRejections, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.excludedRejections, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.ruleTableRows, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.responseFields, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.messageFields, transactionNum)
  );

  const sortedLogs = logs.sort((a, b) => (a.entryDate > b.entryDate ? 1 : -1));
  return sortedLogs;
}

export function getRuleTableListFromRuleMap(ruleMap) {
  const rts = [];
  let rt;
  const array = ruleMap.ruleTableRows || [];

  for (let i = 0; i < array.length; i++) {
    rt = array[i];

    rts.push({
      ruleTableName: rt.ruleTableName,
      ruleTableId: rt.ruleTableId,
      key: rt.key,
      values: (rt.values || []).join(", "),
      effectiveDate: rt.effectiveDate,
      terminationDate: rt.terminationDate,
    });
  }

  const sortedRuleTables = rts.sort(
    (a, b) =>
      a.ruleTableName.localeCompare(b.ruleTableName) ||
      a.key.localeCompare(b.key)
  );

  return sortedRuleTables;
}

export function getLogColors() {
  let bgColor = "var(--ruleparser-blue)";
  let color = "#fff";
  let bgShadow = "transparent";

  return { color, bgColor, bgShadow };
}
