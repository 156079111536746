/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadRuleTableDefinitions(search, token, signal) {
  const uri = API_URL + "/ruletabledefinitions" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({
      operand: "OR",
      name: "name",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "description",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.name && search.name.length > 0)
    where.push({ name: "name", operator: "=", values: [search.name] });
  if (search && search.description && search.description.length > 0)
    where.push({
      name: "description",
      operator: "=",
      values: [search.description],
    });

  // Search by Security Level
  if (search && search.securityLevel && search.securityLevel.length > 0) {
    where.push({ name: "securityLevel", operator: "notnull" });
    search.securityLevel.forEach((securityLevel, i) => {
      where.push({
        operand: "OR",
        name: "securityLevel",
        operator: "=",
        values: [securityLevel.value],
      });
    });
  } else {
    // If no filter is set on security level, automatically exclude Rule type definitions.
    where.push({
      name: "securityLevel",
      operator: "<>",
      values: ["Rule"],
    });
  }

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy || '["name+"]' };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadRuleTableDefinitionsAll(excludeId, token, signal) {
  const uri =
    API_URL +
    "/ruletabledefinitions" +
    getQueryString() +
    "&disablepaging=true";
  let where = [];
  if (excludeId) {
    where.push({
      name: "id",
      operator: "<>",
      values: [excludeId],
    });
  } else {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: ["name+"] };

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadRuleTableDefinitionsForTemplate(params, token, signal) {
  const uri =
    API_URL +
    "/ruletabledefinitions" +
    getQueryString() +
    "&disablepaging=true";
  let where = [];
  where.push({
    operand: "AND",
    name: "securityLevel",
    operator: "=",
    values: ["ReportTemplate"],
  });

  const data = { where, orderBy: ["name+"] };

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadRuleTableDefinition(id, token, signal) {
  let uri = API_URL + "/ruletabledefinition/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddRuleTableDefinition(m, token, signal) {
  let uri = API_URL + "/ruletabledefinition" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateRuleTableDefinition(params, token, signal) {
  const id = params.id;
  const m = params.model;

  let uri = API_URL + "/ruletabledefinition/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteRuleTableDefinition(id, token, signal) {
  let uri = API_URL + "/ruletabledefinition/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCopyRuleTableDefinition(params, token, signal) {
  const id = params.id;
  const newId = params.newId;

  let uri =
    API_URL +
    "/ruletabledefinitioncopy/" +
    encodeURIComponent(id) +
    "/" +
    encodeURIComponent(newId) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadRuleTableDefinitions.funcName = "apiLoadRuleTableDefinitions";
apiLoadRuleTableDefinitionsAll.funcName = "apiLoadRuleTableDefinitionsAll";
apiLoadRuleTableDefinitionsForTemplate.funcName =
  "apiLoadRuleTableDefinitionsForTemplate";
apiLoadRuleTableDefinition.funcName = "apiLoadRuleTableDefinition";
apiAddRuleTableDefinition.funcName = "apiAddRuleTableDefinition";
apiUpdateRuleTableDefinition.funcName = "apiUpdateRuleTableDefinition";
apiDeleteRuleTableDefinition.funcName = "apiDeleteRuleTableDefinition";
apiCopyRuleTableDefinition.funcName = "apiCopyRuleTableDefinition";
