import React from "react";
import { render } from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";
import "react-bootstrap/dist/react-bootstrap.min.js";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import App from "./components/App";
import "./assets/css/site.css";
import AuthProvider from "./contexts/AuthContext";
import UserPreferencesProvider from "./contexts/UserPreferencesContext";

// MSAL configuration

const configuration = {
  auth: {
    clientId: "42b9594f-caa2-4ea7-b2bf-dfde48b99382",
    authority:
      "https://login.microsoftonline.com/1da6ca8b-3ffc-4a27-89d7-8924b18df399",
    // eslint-disable-next-line no-undef
    redirectUri: REDIRECT_URI,
    // eslint-disable-next-line no-undef
    postLogoutRedirectUri: REDIRECT_URI,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        //console.log(message);
        if (containsPii) {
          return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

// eslint-disable-next-line no-undef
console.log(REDIRECT_URI);

const pca = new PublicClientApplication(configuration);

render(
  <MsalProvider instance={pca}>
    <AuthProvider>
      <UserPreferencesProvider>
        <App />
      </UserPreferencesProvider>
    </AuthProvider>
  </MsalProvider>,
  document.getElementById("app")
);
