import React, { useEffect, useState } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
} from "../../common/layout/CommonStyledControls";
import {
  formatDateTimeUtcZoneForDisplay,
  formatDecimal,
  formatNumberForDisplay,
} from "../../../services/General";
import { parseISO } from "date-fns";
import TextInput from "../../common/input/TextInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import styled from "styled-components";
import ReadOnly from "../../common/input/ReadOnly";
import StatusPill from "../../common/ui/StatusPill";
import DatePickerInput from "../../common/input/DatePickerInput";
import SimpleCompletionBar from "../../common/ui/SimpleCompletionBar";

function DbLegacyDbSyncType({
  type,
  changes,
  setChanges,
  hasChanges,
  updateState,
  isStarting,
  isCancelling,
  isAnyProcessStartingOrCancelling,
  syncIsRunning,
  onRunProcess,
  onCancelProcess,
  errors,
}) {
  const [typeSyncIsRunning, setTypeSyncIsRunning] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarPercent, setProgressBarPercent] = useState(0);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const model = changes.typesToSync.find((t) => t.type === type);

  useEffect(() => {
    if (syncIsRunning && !typeSyncIsRunning) {
      setTypeSyncIsRunning(true);
    } else if (!syncIsRunning && typeSyncIsRunning) {
      setProgressBarMessage(`${type} sync complete!`);
      setProgressBarPercent(100);

      // Let progress bar stay on the screen for a bit so user can see update is done
      window.setTimeout(() => {
        setTypeSyncIsRunning(false);
      }, 2000);
    } else if (syncIsRunning) {
      setTypeSyncIsRunning(true);
    }
  }, [syncIsRunning, typeSyncIsRunning]);

  useEffect(() => {
    if (updateState && !_.isEmpty(updateState.lastUpdateMessage)) {
      setProgressBarMessage(updateState.lastUpdateMessage);
    } else if (!typeSyncIsRunning) {
      setProgressBarMessage("");
    }
  }, [updateState?.lastUpdateMessage, typeSyncIsRunning]);

  useEffect(() => {
    if (updateState && updateState.totalRecordsToProcess > 0) {
      const numRecords = updateState?.totalRecordsToProcess ?? 0;
      const inProgress = updateState?.inProgressRecordsProcessed ?? 0;

      // Prevent divide by zero in percentage calculation below.
      const totalRecords = numRecords === 0 ? 0.01 : numRecords;
      let runPercentage = formatDecimal((inProgress / totalRecords) * 100, 0);
      if (runPercentage < 1) runPercentage = 1;

      setProgressBarPercent(runPercentage);
    } else if (!typeSyncIsRunning) {
      setProgressBarPercent(0);
    }
  }, [
    updateState?.totalRecordsToProcess,
    updateState?.inProgressRecordsProcessed,
    typeSyncIsRunning,
  ]);

  function handleChange({ target }) {
    let changed = _.cloneDeep(changes);
    const changedType = changed.typesToSync.find((t) => t.type === type);
    changedType[target.name] = target.value;
    setChanges(changed);
  }

  function handleCheckboxChange({ target }) {
    let changed = _.cloneDeep(changes);
    const changedType = changed.typesToSync.find((t) => t.type === type);
    changedType[target.name] = target.checked;
    setChanges(changed);
  }

  function handleStartDateChange(date) {
    let changed = _.cloneDeep(changes);
    const changedType = changed.typesToSync.find((t) => t.type === type);
    changedType.startDate = date === null ? null : new Date(date).toISOString();
    setChanges(changed);
  }

  function handleEndDateChange(date) {
    let changed = _.cloneDeep(changes);
    const changedType = changed.typesToSync.find((t) => t.type === type);
    changedType.endDate = date === null ? null : new Date(date).toISOString();
    setChanges(changed);
  }

  function handleClickAdvancedSettings(event) {
    if (event) event.preventDefault();
    setShowAdvancedSettings(!showAdvancedSettings);
  }

  function getTypeName() {
    if (type === "DiscountRedemption") return "Discount Redemption";
    if (type === "PriorAuthorization") return "Prior Authorization";
    if (type === "ServiceProvider") return "Service Provider";
    return type;
  }

  if (!model) return <></>;

  return (
    <StyledTypeContainer className="col-12 col-lg-6">
      {isStarting || isCancelling ? (
        <>
          <StyledHeaderRowDiv style={{ marginTop: "5px" }}>
            <h1>{getTypeName()}</h1>
          </StyledHeaderRowDiv>
          <Spinner spinnerStyle={{ lineHeight: "10vh" }} />
          {(isStarting || isCancelling) && (
            <h4
              style={{
                textAlign: "center",
                position: "relative",
                top: "0px",
                margin: "0",
                padding: "0",
              }}
            >
              {isStarting ? `Sync is starting...` : `Cancelling sync...`}
            </h4>
          )}
        </>
      ) : typeSyncIsRunning ? (
        <>
          <StyledHeaderRowDiv style={{ marginTop: "5px" }}>
            <h1>{getTypeName()}</h1>
          </StyledHeaderRowDiv>
          <SimpleCompletionBar
            title={<p>Sync in progress...</p>}
            runPercentage={progressBarPercent}
            onCancel={onCancelProcess}
            disableCancel={isCancelling}
            showCancel={syncIsRunning}
            fullWidth={true}
            subtitle={
              <>
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "4px",
                    marginBottom: "0",
                  }}
                >
                  {_.isEmpty(progressBarMessage) ? (
                    <>&nbsp;</>
                  ) : (
                    <>{progressBarMessage}</>
                  )}
                </p>
                <p style={{ textAlign: "center", marginTop: "4px" }}>
                  {updateState.inProgressRecordsProcessed === 0 &&
                  updateState.totalRecordsToProcess === 0 ? (
                    <>&nbsp;</>
                  ) : (
                    <>
                      {`${formatNumberForDisplay(
                        updateState.inProgressRecordsProcessed
                      )} of ${formatNumberForDisplay(
                        updateState.totalRecordsToProcess
                      )}`}
                    </>
                  )}
                </p>
              </>
            }
          />
        </>
      ) : (
        <StyledRowDiv className="row">
          <div className="col-12 col-md-8" style={{ padding: "15px" }}>
            <StyledHeaderRowDiv
              style={{ marginTop: "5px", marginBottom: "20px" }}
            >
              <h1>{getTypeName()}</h1>
            </StyledHeaderRowDiv>

            <StyledRowDiv className="row">
              <div className="col-12 col-lg-6">
                <DatePickerInput
                  id={`${type}-startDate`}
                  name="startDate"
                  label="Start Date"
                  value={
                    model.startDate === null ? null : parseISO(model.startDate)
                  }
                  showTimeInput={false}
                  placeholder="Start Date"
                  onChange={handleStartDateChange}
                  error={errors[`${type}-startDate`]}
                />
              </div>
              <div className="col-12 col-lg-6">
                <DatePickerInput
                  id={`${type}-endDate`}
                  name="endDate"
                  label="End Date"
                  value={
                    model.endDate === null ? null : parseISO(model.endDate)
                  }
                  showTimeInput={false}
                  placeholder="End Date"
                  onChange={handleEndDateChange}
                  error={errors[`${type}-endDate`]}
                />
              </div>
              <div className="col-12 col-lg-6">
                <CheckboxInput
                  id={`${type}-active`}
                  label="Active"
                  onChange={handleCheckboxChange}
                  placeholder=""
                  name="active"
                  showLabelInline={true}
                  checked={model.active}
                  error={errors[`${type}-active`]}
                />
              </div>
              <div className="col-12 col-lg-6">
                <CheckboxInput
                  id={`${type}-continuous`}
                  label="Continuous"
                  onChange={handleCheckboxChange}
                  placeholder=""
                  name="continuous"
                  showLabelInline={true}
                  checked={model.continuous}
                  error={errors[`${type}-continuous`]}
                />
              </div>
            </StyledRowDiv>
            <StyledAdvancedSettingsDiv>
              <button
                className="btn btn-link btn-with-icon"
                onClick={handleClickAdvancedSettings}
              >
                Advanced Settings{"  "}
                <span className="material-symbols-outlined">
                  {showAdvancedSettings ? "expand_more" : "chevron_right"}
                </span>
              </button>
            </StyledAdvancedSettingsDiv>
            {showAdvancedSettings && (
              <StyledRowDiv className="row">
                <div className="col-12 col-lg-6">
                  <TextInput
                    id={`${type}-bulkRecordPageSize`}
                    label="Bulk Record Page Size"
                    onChange={handleChange}
                    placeholder="Bulk Record Page Size"
                    name="bulkRecordPageSize"
                    value={model.bulkRecordPageSize}
                    error={errors[`${type}-bulkRecordPageSize`]}
                    isNumber={true}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <TextInput
                    id={`${type}-bulkRecordWaitBetweenPages`}
                    label="Bulk Record Wait Between Pages"
                    onChange={handleChange}
                    placeholder="Bulk Record Wait Between Pages"
                    name="bulkRecordWaitBetweenPages"
                    value={model.bulkRecordWaitBetweenPages}
                    error={errors[`${type}-bulkRecordWaitBetweenPages`]}
                    isNumber={true}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <CheckboxInput
                    id={`${type}-reloadAllData`}
                    label="Reload All Data"
                    onChange={handleCheckboxChange}
                    placeholder=""
                    name="reloadAllData"
                    showLabelInline={true}
                    checked={model.reloadAllData}
                    error={errors[`${type}-reloadAllData`]}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <CheckboxInput
                    id={`${type}-continuousTestMode`}
                    label="Continuous Test Mode"
                    onChange={handleCheckboxChange}
                    placeholder=""
                    name="continuousTestMode"
                    showLabelInline={true}
                    checked={model.continuousTestMode}
                    error={errors[`${type}-continuousTestMode`]}
                  />
                </div>
              </StyledRowDiv>
            )}
          </div>
          <div className="col-12 col-md-4">
            <StyledRowDiv className="sidepanel">
              {!typeSyncIsRunning && !isStarting && !isCancelling && (
                <StyledHeaderRowButtonDiv
                  style={{
                    flexWrap: "wrap",
                    rowGap: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-secondary btn-with-icon"
                    onClick={onRunProcess}
                    style={{
                      marginLeft: "14px",
                      borderColor: "var(--notify-success)",
                    }}
                    disabled={
                      hasChanges ||
                      typeSyncIsRunning ||
                      isAnyProcessStartingOrCancelling ||
                      !model.active ||
                      !changes.active
                    }
                  >
                    {!model.active || !changes.active ? (
                      <>Sync not active</>
                    ) : hasChanges ? (
                      <>Save first</>
                    ) : (
                      <>
                        <span className="material-icons">
                          play_circle_outline
                        </span>
                        {"  "}Run
                      </>
                    )}
                  </button>
                </StyledHeaderRowButtonDiv>
              )}
              {!updateState || updateState?.lastStarted === null ? (
                <p>This sync has never been run.</p>
              ) : (
                <>
                  <StyledRowDiv className="row">
                    <div className="col-12" style={{ marginTop: "15px" }}>
                      <ReadOnly
                        id="lastStarted"
                        label="Last Started"
                        name="lastStarted"
                        value={formatDateTimeUtcZoneForDisplay(
                          updateState?.lastStarted
                        )}
                      />
                    </div>
                    <div className="col-12" style={{ marginTop: "5px" }}>
                      <ReadOnly
                        id="lastCompleted"
                        label="Last Completed"
                        name="lastCompleted"
                        value={formatDateTimeUtcZoneForDisplay(
                          updateState?.lastCompleted
                        )}
                      />
                    </div>
                    <div className="col-12" style={{ marginTop: "5px" }}>
                      <div className="form-group">
                        <label>Result</label>
                        <div className="field">
                          <StatusPill
                            status={
                              updateState?.lastError?.errorState ??
                              false === true
                                ? _.toLower(
                                    updateState?.lastError?.errorMessage
                                  )?.indexOf("cancel") >= 0
                                  ? "Cancelled"
                                  : "Fail"
                                : "Success"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </StyledRowDiv>
                  {(updateState?.lastError?.errorState ?? false === true) && (
                    <StyledRowDiv className="row" style={{ marginTop: "5px" }}>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Message</label>
                          <div className="field">
                            {updateState?.lastError?.errorMessage ?? ""}
                          </div>
                        </div>
                      </div>
                    </StyledRowDiv>
                  )}
                </>
              )}
            </StyledRowDiv>
          </div>
        </StyledRowDiv>
      )}
    </StyledTypeContainer>
  );
}

const StyledTypeContainer = styled.div`
  padding: 10px;
  min-height: 260px;
  border: 1px solid var(--elevated-border);

  h3 {
    font-size: 18px;
  }

  .sidepanel {
    height: 100%;
    margin-top: 10px;
    padding: 12px 12px 0 20px;
    background-color: var(--bg-flat);
    border-radius: 6px;
  }
`;

const StyledAdvancedSettingsDiv = styled.div`
  margin: 30px 0 20px 0;

  button {
    font-size: 14px;
  }
`;

export default DbLegacyDbSyncType;
