import _ from "lodash";
import {
  buildChildField,
  buildFieldListsFromRuleMap,
  buildUniqueIdForNode,
  getConditionalValuesFromNode,
  getDescriptionFromNode,
  getFieldFromFieldListById,
  getIsConditionNodeFromNode,
  getIsProcessedFromNode,
  getItemFromPropertyByDisplayKey,
  getItemKeyFromNode,
  getItemNameFromNode,
  getItemTypeFromNode,
  getSourceKeyTranslatedFromNode,
  getStatusFromNodeForTransaction,
  getValueFromNode,
  locateAllParentsOfField,
  locateField,
  NodeTypes,
  removeChildFromFieldById,
} from "./RuleMapDataCommon";

let fieldList = [];
let arrayList = [];

export function buildArrayCounts(field, isActualNodeLink = true, level = 0) {
  if (field === null) return;

  if (level > 25) {
    console.log("Too many levels in buildArrayCounts");
    return;
  }

  const existingField = arrayList.find((f) => f.id === field.id);
  if (existingField) {
    existingField.count++;
  } else {
    arrayList.push({
      id: field.id,
      count: 1,
      isGhost: !isActualNodeLink,
      isProcessed: field.isProcessed,
    });
  }

  let child;
  for (let i = 0; i < (field.children || []).length; i++) {
    child = getFieldFromFieldListById(fieldList, field.children[i].id);
    buildArrayCounts(child, field.children[i].isActualNodeLink, level + 1);
  }
}

export function renderTreeToConsole(field, isActualNodeLink = true, level = 0) {
  if (field === null) return;

  if (level > 25) {
    console.log("Too many levels in renderTreeToConsole");
    return;
  }

  let s = "";
  for (let i = 0; i < level; i++) s += "-";

  console.log(`${s} ${field.id} ${isActualNodeLink ? "" : "(GHOST)"}`);

  let child;
  for (let i = 0; i < (field.children || []).length; i++) {
    child = getFieldFromFieldListById(fieldList, field.children[i].id);
    renderTreeToConsole(child, field.children[i].isActualNodeLink, level + 1);
  }
}

function getModuleFromNode(node) {
  if ((node.modules || []).length < 1) return null;

  // For now, assume a node will only have a single module in the array since I'm not sure how multiple would be represented in the UI.
  let module = { ...node.modules[0], id: node.modules[0].name, subnodes: [] };

  return module;
}

function getLinkForEntityData(nodeStatus, index) {
  let screen = nodeStatus.sourceKeyTranslated;

  switch (nodeStatus.sourceKeyTranslated) {
    case "primaryDrug":
    case "compoundDrugs":
      screen = "drug";
      break;
    case "pharmacy":
    case "pharmacyNCPDP":
      screen = "serviceprovider";
      break;
    default:
      break;
  }

  const entityId = nodeStatus.entityIds[index];
  const entityName = nodeStatus.entityFriendlyIds[index];

  const linkData = {
    index: index,
    entityType: screen,
    entityId: entityId,
    entityName: entityName,
  };
  return linkData;
}

function getLinkForRuleTableRow(ruleMap, nodeStatus, index) {
  const screen = "ruletable";
  const ruleTableRowId = nodeStatus.ruleTableRowId[index];

  // Find the ruletable with this row id to get the ruleTableId and name
  const ruleTableRow = (ruleMap.ruleTableRows || []).find(
    (r) => r.rowId === ruleTableRowId
  );
  if (!ruleTableRow) {
    console.log(
      `Rule table with row id ${ruleTableRowId} not found in ruleTableRows array while building link!`
    );
    return null;
  }

  const entityId = ruleTableRow.ruleTableId;
  const entityName = `${nodeStatus.valueTranslated}`;

  const linkData = {
    index: index,
    entityType: screen,
    entityId: entityId,
    entityName: entityName,
  };
  return linkData;
}

function getDataForRuleTableRow(ruleMap, nodeStatus, index) {
  const ruleTableRowId = nodeStatus.ruleTableRowId[index];

  // Find the ruletable with this row id to get the ruleTableId and name
  const ruleTableRow = (ruleMap.ruleTableRows || []).find(
    (r) => r.rowId === ruleTableRowId
  );
  if (!ruleTableRow) {
    console.log(
      `Rule table with row id ${ruleTableRowId} not found in ruleTableRows array while fetching data!`
    );
    return null;
  }

  const values = ruleTableRow.values || [];
  const data = values.join(", ");
  return data;
}

function getLinksFromNode(ruleMap, node, transactionNum) {
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return [];
  const links = [];

  // If the node type is Entity Data, create a link to that page.
  if (
    node.sourceType === "EntityData" &&
    status.entityIds.length > 0 &&
    status.entityFriendlyIds.length > 0
  ) {
    for (let i = 0; i < status.entityIds.length; i++) {
      links.push(getLinkForEntityData(status, i));
    }
    return links;
  }

  // Also build links to rule table rows. These will have a ruleTableRowId in their status array.
  if ((status.ruleTableRowId || []).length > 0) {
    let link;
    for (let i = 0; i < status.ruleTableRowId.length; i++) {
      link = getLinkForRuleTableRow(ruleMap, status, i);
      if (link !== null) {
        links.push(link);
      }
    }
    return links;
  }

  return [];
}

function getRuleTableDataForNode(ruleMap, node, transactionNum) {
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return [];
  const ruleTableData = [];

  // If node has a ruleTableRowId in its status array, build an array of rule table data.
  if ((status.ruleTableRowId || []).length > 0) {
    let data;
    for (let i = 0; i < status.ruleTableRowId.length; i++) {
      data = getDataForRuleTableRow(ruleMap, status, i);
      if (data !== null) {
        ruleTableData.push(data);
      }
    }
    return ruleTableData;
  }

  return [];
}

function getRelevantLogsFromNode(node, transactionNum) {
  if (!node || (node.relevantLogs || []).length < 1 || transactionNum === -1)
    return [];
  let logs = node.relevantLogs.filter(
    (l) => l.transactionIndex === transactionNum
  );

  // Sort logs in descending order
  logs = logs.sort((a, b) => (a.logDate > b.logDate ? 1 : -1));
  logs = logs.map((log) => {
    return { message: log.message, logDate: log.logDate };
  });
  return logs;
}

function getTranslatedValuesFromNode(node, transactionNum) {
  const val = { value: "", translated: "", result: "" };
  if (!node) return val;

  if (!_.isEmpty(node.itemValue)) {
    val.value = node.itemValue;
  }

  // Check for a value intermediate in statuses
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return val;

  if (!_.isEmpty(status.valueIntermediate)) {
    val.translated = status.valueIntermediate;

    if (_.isEmpty(val.translated) && !_.isEmpty(val.value)) {
      val.translated = val.value;
    }
  }

  // Check for a final result value
  if (!_.isEmpty(status.valueTranslated)) {
    val.result = status.valueTranslated;
  }

  return val;
}

export function getItemByDisplayKey(ruleMap, key) {
  // Look through each of the following properties in the rule map to find an element with the given display key.
  //   codeArtifacts, includedDataValues, includedRejections, excludedRejections
  // If not found, return null.
  if (!ruleMap) return null;

  let item = null;

  item = getItemFromPropertyByDisplayKey(ruleMap.codeArtifacts, key);
  if (item !== null) {
    item.nodeType = "CodeArtifact";
    return item;
  }

  item = getItemFromPropertyByDisplayKey(ruleMap.includedDataValues, key);
  if (item !== null) {
    item.nodeType = "IncludedDataValue";
    return item;
  }

  item = getItemFromPropertyByDisplayKey(ruleMap.includedRejections, key);
  if (item !== null) {
    item.nodeType = "IncludedRejection";
    return item;
  }

  item = getItemFromPropertyByDisplayKey(ruleMap.excludedRejections, key);
  if (item !== null) {
    item.nodeType = "ExcludedRejection";
    return item;
  }

  return null;
}

export function getRuleTableRowByRowId(ruleMap, rowId) {
  if (!ruleMap) return null;
  const array = ruleMap.ruleTableRows || [];
  if (array.length < 1) return null;

  const row = array.find((i) => i.rowId === rowId);
  return row || null;
}

export function getRuleTableListFromRuleMap(ruleMap) {
  const rts = [];
  let rt;
  const array = ruleMap.ruleTableRows || [];

  for (let i = 0; i < array.length; i++) {
    rt = array[i];

    rts.push({
      ruleTableName: rt.ruleTableName,
      ruleTableId: rt.ruleTableId,
      key: rt.key,
      values: (rt.values || []).join(", "),
      effectiveDate: rt.effectiveDate,
      terminationDate: rt.terminationDate,
    });
  }

  const sortedRuleTables = rts.sort(
    (a, b) =>
      a.ruleTableName.localeCompare(b.ruleTableName) ||
      a.key.localeCompare(b.key)
  );

  return sortedRuleTables;
}

function getUpdatesKeysFromField(ruleMap, field, transactionNum) {
  if ((field.updatesKeys || []).length === 0) return [];

  // Group rules have no statuses
  const updatesKeys = (field.updatesKeys || []).filter(
    (a) =>
      transactionNum === -1 ||
      (a.statuses || []).findIndex(
        (s) => s.transactionIndex === transactionNum
      ) >= 0
  );

  if ((updatesKeys || []).length === 0) return [];

  const uks = [];
  let uk;

  for (let i = 0; i < updatesKeys.length; i++) {
    uk = {
      key: getItemNameFromNode(updatesKeys[i], transactionNum),
      value: getValueFromNode(updatesKeys[i], transactionNum),
      description: getDescriptionFromNode(updatesKeys[i]),
      type: getItemTypeFromNode(ruleMap, updatesKeys[i]),
      links: getLinksFromNode(ruleMap, updatesKeys[i], transactionNum),
      ruleTableData: getRuleTableDataForNode(
        ruleMap,
        updatesKeys[i],
        transactionNum
      ),
    };
    uks.push(uk);
  }

  return uks;
}

function buildRunValueEntry(ruleMap, field, transactionNum) {
  // Convert all values to strings so we don't end up with true/false or a number appearing to be empty.
  //  Note that this runValues array is actually indexed by transactionNum, so no need to find by index.
  const value = `${field.values[transactionNum]}`;

  const leaf = {
    key: field.key,
    value: value,
  };

  return leaf;
}

function getRunValuesFromField(ruleMap, field, transactionNum) {
  const runValues = [];
  if (transactionNum === -1) return runValues;

  const rvs = field.runValues || [];

  for (let i = 0; i < rvs.length; i++) {
    runValues.push(buildRunValueEntry(ruleMap, rvs[i], transactionNum));
  }
  return runValues;
}

function getActionPrerequisitesFromField(field, transactionNum) {
  // Group rules have no statuses
  if (transactionNum === -1) return field.actionPrerequisites || [];

  const actionPrereqs = (field.actionPrerequisites || []).filter(
    (a) =>
      (a.statuses || []).findIndex(
        (s) => s.transactionIndex === transactionNum
      ) >= 0
  );

  return actionPrereqs;
}

function getPossibleSourcesFromField(field, parent) {
  let possibleSources = field.possibleSources || [];

  // Sometimes a field can have itself as a possible source through its action prerequisites, so filter these out.
  //   Also, per Randy, always removed Reversals as possible source.
  possibleSources = possibleSources.filter(
    (s) => s.sourceDisplayKey !== parent.displayKey
  );

  possibleSources = filterPossibleSources(possibleSources);

  return possibleSources;
}

function buildField(ruleMap, transactionNum, field, nodeType, parent) {
  const id = buildUniqueIdForNode(field, nodeType);
  const fieldName = getItemNameFromNode(field, transactionNum);
  const fieldKey = getItemKeyFromNode(field);
  const description = getDescriptionFromNode(field);
  const value = getValueFromNode(field, transactionNum);
  const translatedValues = getTranslatedValuesFromNode(field, transactionNum);
  const isProcessed = getIsProcessedFromNode(field, transactionNum, nodeType);
  const isConditionNode = getIsConditionNodeFromNode(field, transactionNum);
  const links = getLinksFromNode(ruleMap, field, transactionNum);
  const ruleTableData = getRuleTableDataForNode(ruleMap, field, transactionNum);
  const logs = getRelevantLogsFromNode(field, transactionNum);
  const module = getModuleFromNode(field);
  const actionPrereqs = getActionPrerequisitesFromField(field, transactionNum);
  const possibleSources = getPossibleSourcesFromField(field, parent);
  const updatesKeys = getUpdatesKeysFromField(ruleMap, field, transactionNum);
  const runValues = getRunValuesFromField(ruleMap, field, transactionNum);
  const conditionals = isConditionNode
    ? getConditionalValuesFromNode(field, transactionNum)
    : [];
  const sourceKey = getSourceKeyTranslatedFromNode(field, transactionNum);

  // if (isConditionNode) {
  //   console.log(`Field is conditional`);
  // }

  const retField = {
    id: id,
    parentKey: parent?.displayKey || "",
    key: fieldKey,
    sourceKey: sourceKey,
    name: fieldName,
    type: nodeType,
    description: description,
    value: value,
    links: links,
    ruleTableData: ruleTableData,
    logs: logs,
    translatedValues: translatedValues,
    isProcessed: isProcessed,
    isConditionNode: isConditionNode,
    module: module,
    actionPrereqs: actionPrereqs,
    possibleSources: possibleSources,
    children: [],
    leafNodes: [],
    updatesKeys: updatesKeys,
    runValues: runValues,
    conditionals: conditionals,
    isActualNodeLink: false, // Gets set in a later pass
  };

  return retField;
}

function getNextNodeInTree(ruleMap, status) {
  // Ignore nodes with sourceDisplayKey = "Reversals/Reversals" since this module is never being created and we don't want to see this artifact ever, per Randy.
  if (
    _.isEmpty(status?.sourceDisplayKey) ||
    status?.sourceDisplayKey === "Reversals/Reversals"
  )
    return null;

  const nextNode = getItemByDisplayKey(ruleMap, status.sourceDisplayKey);
  if (nextNode === null) return null;

  const nextNodeType = getItemTypeFromNode(ruleMap, nextNode);
  return { node: nextNode, type: nextNodeType };
}

function addFieldIfNotExist(field) {
  if ((fieldList || []).length < 1) {
    fieldList.push(field);
    return;
  }

  const existing = fieldList.find((f) => f.id === field.id);
  if (!existing) {
    fieldList.push(field);
  }
}

function buildFieldsForActionPrerequisite(
  ruleMap,
  transactionNum,
  parent,
  item,
  isActualNodeLink
) {
  // Group rule maps have no statuses.
  const itemStatus = getStatusFromNodeForTransaction(item, transactionNum);

  // Does this prereq have a sourceDisplayKey? If so, the tree continues so traverse it.
  const nextNode = getNextNodeInTree(ruleMap, itemStatus);
  if (nextNode !== null) {
    const nextField = locateField(ruleMap, fieldList, nextNode.node);
    nextField.isActualNodeLink = nextField.isActualNodeLink || isActualNodeLink;
    addChildToField(parent, nextField);
    traverseTreeForField(ruleMap, transactionNum, nextField, isActualNodeLink);
    return;
  }

  // Does this action prereq have possible sources? If so, trace the ones back we didn't already do above with sourceDisplayKey.
  //   Also filter out leaf nodes because we would have already covered those above.
  let possibleSources = item.possibleSources || [];
  possibleSources = filterPossibleSources(possibleSources);
  possibleSources = possibleSources.filter(
    (s) =>
      !(
        s.valueType === NodeTypes.EntityData.description ||
        s.valueType === NodeTypes.GroupSetting.description ||
        s.valueType === NodeTypes.RequestFieldValue.description
      )
  );

  // Any possible sources left to traverse? If not, add a leaf node for this action prereq and continue.
  if (possibleSources.length === 0) {
    // Add this leaf node to the parent's children
    const field = locateField(ruleMap, fieldList, item);
    field.isActualNodeLink = field.isActualNodeLink || isActualNodeLink;
    addChildToField(parent, field);
    return;
  }

  // Traverse the possible sources - the children array will contain a flag indicating the children are ghost links only
  for (let i = 0; i < possibleSources.length; i++) {
    buildFieldForPossibleSource(
      ruleMap,
      transactionNum,
      possibleSources[i],
      parent
    );
  }
}

function buildFieldForPossibleSource(
  ruleMap,
  transactionNum,
  possibleSource,
  parent
) {
  const nextSourceNode = getItemByDisplayKey(
    ruleMap,
    possibleSource.sourceDisplayKey
  );
  if (nextSourceNode !== null) {
    const nodeType = getItemTypeFromNode(ruleMap, nextSourceNode);
    const nodeId = buildUniqueIdForNode(nextSourceNode, nodeType);

    const nextField = getFieldFromFieldListById(fieldList, nodeId);

    if (!nextField || nextField === null) {
      console.log(
        `nextField is null in buildFieldForPossibleSource for id: ${nodeId}`
      );
    }

    if (!nextField.isPossibleSourceVisited) {
      nextField.isPossibleSourceVisited = true;
      nextField.isActualNodeLink =
        nextField.isActualNodeLink || transactionNum === -1;
      addChildToField(parent, nextField);
      traverseTreeForField(
        ruleMap,
        transactionNum,
        nextField,
        transactionNum === -1
      );
    }
  }
}

function resetAllPossibleSourceVisited() {
  for (let i = 0; i < fieldList.length; i++) {
    fieldList[i].isPossibleSourceVisited = false;
  }
}

function filterPossibleSources(sources) {
  let possibleSources = sources;
  possibleSources = possibleSources.filter(
    (s) => s.sourceDisplayKey !== "Reversals/Reversals"
  );

  return possibleSources;
}

function traverseTreeForField(
  ruleMap,
  transactionNum,
  field,
  isActualNodeLink
) {
  const actionPrereqs = field.actionPrereqs;

  // Traverse this field's action prerequisites if it has any
  if ((actionPrereqs || []).length > 0) {
    for (let i = 0; i < actionPrereqs.length; i++) {
      buildFieldsForActionPrerequisite(
        ruleMap,
        transactionNum,
        field,
        actionPrereqs[i],
        isActualNodeLink
      );
    }
  }
}

function addChildToField(field, child) {
  if (field === null || child === null) return;

  const childId = child.id;
  if (!childId) {
    console.log(`Child Id undefined in addChildToField`, childId);
  }

  const existingChild = field.children.find((c) => c.id === childId);
  if (!existingChild) {
    field.children.push(buildChildField(childId));
  }
}

function populateMessageFieldsAndTraverseChildren(
  ruleMap,
  transactionNum,
  node,
  parent,
  isActualNodeLink
) {
  // For Group Rule Map, start at this node since we don't have separate message field array
  let nextNode = parent === null ? node : null;

  if (!_.isEmpty(node.sourceDisplayKey)) {
    nextNode = getItemByDisplayKey(ruleMap, node.sourceDisplayKey);

    // Add the message field as the root
    let rootField = buildField(
      ruleMap,
      transactionNum,
      node,
      NodeTypes.RootMessage,
      null
    );
    rootField.isActualNodeLink = true;
    rootField.isProcessed = true;
    rootField.isRoot = true;
    const childId = buildUniqueIdForNode(
      nextNode,
      NodeTypes[nextNode.valueType]
    );
    rootField.children.push(buildChildField(childId));
    fieldList.push(rootField);
  }

  // Go to the sourcedisplaykey node and start there after the root node (for claim rule maps)
  if (nextNode !== null) {
    let field = locateField(ruleMap, fieldList, nextNode);
    field.isRoot = false;
    field.isActualNodeLink = field.isActualNodeLink || isActualNodeLink;
    addChildToField(parent, field);
    traverseTreeForField(ruleMap, transactionNum, field, isActualNodeLink);
  } else {
    console.log("sourceDisplayKey not found on Message field!", node);
  }
}

function populateRejectionFieldsAndTraverseChildren(
  ruleMap,
  transactionNum,
  node,
  nodeType,
  parent,
  isActualNodeLink,
  showModules
) {
  // Add a new rejection field as the root, except for group rules since this doubles the top level node.
  //  This replaces the actual rejection field and it is never part of a module.
  let rootField = buildField(ruleMap, transactionNum, node, nodeType, null);

  rootField.id = "ROOT-" + rootField.id; // to make it unique
  rootField.isActualNodeLink = true;
  rootField.module = null;
  rootField.isRoot = true;
  rootField.name = rootField.key; // This makes the flagname the node name for the rejection.
  fieldList.push(rootField);

  // Remove the old rejection field from the field list
  const childId = buildUniqueIdForNode(node, nodeType);
  _.remove(fieldList, (f) => f.id === childId);

  traverseTreeForField(ruleMap, transactionNum, rootField, isActualNodeLink);
}

function populateFieldsAndTraverseChildren(
  ruleMap,
  transactionNum,
  node,
  nodeType,
  parent,
  isActualNodeLink,
  showModules
) {
  // If this is a rejection field, add a dummy root node for modules since these can be part of the module and we don't want the root to start in one.
  if (
    nodeType === NodeTypes.Rejection ||
    nodeType === NodeTypes.ExcludedRejection
  ) {
    populateRejectionFieldsAndTraverseChildren(
      ruleMap,
      transactionNum,
      node,
      nodeType,
      parent,
      isActualNodeLink,
      showModules
    );
  }
  // If this is a message field, go to the sourcedisplaykey node and start there
  else if (
    nodeType === NodeTypes.TransactionMessage ||
    nodeType === NodeTypes.TransmissionMessage
  ) {
    populateMessageFieldsAndTraverseChildren(
      ruleMap,
      transactionNum,
      node,
      parent,
      isActualNodeLink
    );
  } else {
    const field = locateField(
      ruleMap,
      fieldList,
      node,
      false // No way for this to be a rejection since they are handled above
    );
    field.isActualNodeLink = field.isActualNodeLink || isActualNodeLink;
    addChildToField(parent, field);
    traverseTreeForField(ruleMap, transactionNum, field, isActualNodeLink);
  }
}

function locateAllFieldsInArrayByPropertyAndValue(propName, propValue) {
  const fields = fieldList.filter((f) => f[propName] === propValue);
  return fields;
}

function getAllChildrenFieldsOfField(field) {
  const children = [];

  let childField;
  for (let i = 0; i < field.children.length; i++) {
    childField = getFieldFromFieldListById(fieldList, field.children[i].id);
    if (childField === null) {
      console.log(`***Missing field: ${field.children[i].id}`);
    } else {
      children.push(childField);
    }
  }

  return children;
}

function buildArtifactInputOutputField(field, type, children, leafNodes) {
  const isInput = type === NodeTypes.CodeArtifactInput;

  const typeName = type.description;
  const key = `${typeName}-${field.name}`;
  const value = `${leafNodes.length} ${isInput ? "input" : "output"}${
    leafNodes.length > 1 ? "s" : ""
  }`;

  const node = {
    id: key,
    key: key,
    name: key,
    type: type,
    description: "",
    value: value,
    links: [],
    ruleTableData: [],
    logs: [],
    translatedValues: [],
    isProcessed: field.isProcessed,
    module: field.module,
    actionPrereqs: [],
    isRoot: false,
    isLeaf: isInput,
    children: children,
    leafNodes: leafNodes,
    isActualNodeLink: field.isActualNodeLink,
  };

  return node;
}

function injectArtifactOutputField(artifact) {
  // Add a new CodeArtifactOutput field if the artifact has updatesKeys.
  // Finally, inject this new output field between the artifact and its parent.
  const leafNodes = artifact.updatesKeys;
  const children = [];
  children.push(buildChildField(artifact.id));

  // Create the artifact output node
  const outputField = buildArtifactInputOutputField(
    artifact,
    NodeTypes.CodeArtifactOutput,
    children,
    leafNodes
  );

  // Exchange the artifact's id with this new output field's id in the children array of all the artifact's parents
  const parents = locateAllParentsOfField(fieldList, artifact);
  for (let i = 0; i < parents.length; i++) {
    removeChildFromFieldById(parents[i], artifact.id);
    parents[i].children.push(buildChildField(outputField.id));
  }

  fieldList.push(outputField);
}

function injectArtifactInputField(artifact) {
  // Add a new CodeArtifactInput field and then take all of the children that are leaf nodes and
  //   add them to the field's leafNodes array so they can be rendered in the input node.
  // Finally, connect this new input field as a child of the artifact.
  let child;
  let leafNodes = [];

  // First collect all of the leaf nodes
  const children = getAllChildrenFieldsOfField(artifact);

  // Do not include internal variables in the input node. Leave these as separate nodes in the graph.
  for (let i = 0; i < children.length; i++) {
    child = children[i];
    leafNodes.push({
      node: _.cloneDeep(child),
      numChildren: child.children.length,
      hideNode:
        child.isConditionNode !== true &&
        _.isEmpty(child.translatedValues.translated) &&
        !(
          child.type === NodeTypes.InternalVariable ||
          child.type === NodeTypes.ConditionalNode
        ),
    });
  }

  // Mark these fields that have no children in the field list to be removed later
  const leafNodesToRemove = fieldList.filter(
    (c) =>
      leafNodes.findIndex(
        (l) => l.node.id === c.id && l.numChildren === 0 && l.hideNode === true
      ) >= 0
  );
  for (let i = 0; i < leafNodesToRemove.length; i++) {
    leafNodesToRemove[i].deleteLeafNode = true;
  }

  // Add the artifact input node and add it to the children.
  const inputField = buildArtifactInputOutputField(
    artifact,
    NodeTypes.CodeArtifactInput,
    [],
    leafNodes.map((l) => l.node)
  );

  fieldList.push(inputField);

  // Remove artifact's children that are leaf nodes
  _.remove(
    artifact.children,
    (c) =>
      leafNodes.findIndex(
        (l) => l.node.id === c.id && l.numChildren === 0 && l.hideNode === true
      ) >= 0
  );
  artifact.children.push(buildChildField(inputField.id));
}

function injectConditionalField(field) {
  // Add a new ConditionalNode field between this field and its parent.
  // jon, 12/2/24: Use parent field's key here so we have a way to tie back to the proper field for editing on the Group Rule screen.
  const conditional = {
    ...field,
    type: NodeTypes.ConditionalNode,
    id: `${NodeTypes.ConditionalNode.description}-${field.parentKey}`,
    key: `${NodeTypes.ConditionalNode.description}-${field.parentKey}`,
    name: field.name,
    isLeaf: false,
    isRoot: false,
    children: [],
    isActualNodeLink: field.isActualNodeLink,
  };

  // Add the new conditional to the fieldlist
  fieldList.push(conditional);

  // Exchange this field's id with this new conditional field's id in the children array of all the field's parents
  const parents = locateAllParentsOfField(fieldList, field);
  for (let i = 0; i < parents.length; i++) {
    removeChildFromFieldById(parents[i], field.id);
    parents[i].children.push(buildChildField(conditional.id));
  }

  // Add this field to the conditional's children
  field.conditionals = [];
  conditional.children.push(buildChildField(field.id));
}

function performFieldListPostProcessing() {
  // Add artifact input/output nodes
  let fields = locateAllFieldsInArrayByPropertyAndValue(
    "type",
    NodeTypes.CodeArtifact
  );
  for (let i = 0; i < fields.length; i++) {
    injectArtifactInputField(fields[i]);
    injectArtifactOutputField(fields[i]);
  }

  // Inject connditional nodes
  fields = locateAllFieldsInArrayByPropertyAndValue("isConditionNode", true);
  for (let i = 0; i < fields.length; i++) {
    injectConditionalField(fields[i]);
  }
}

function buildFieldsForArray(ruleMap, transactionNum, array, type) {
  let field, actionPrereqField, actionPrereqs, nodeType, itemType;

  for (let i = 0; i < array.length; i++) {
    nodeType = type === NodeTypes.Unknown ? NodeTypes[array[i].type] : type;
    if (array[i].valueType === "TransactionMessage")
      nodeType = NodeTypes.TransactionMessage;
    if (array[i].valueType === "TransmissionMessage")
      nodeType = NodeTypes.TransmissionMessage;

    field = buildField(ruleMap, transactionNum, array[i], nodeType, null);
    addFieldIfNotExist(field);

    // Add fields for each action prerequisite, if they don't exist
    actionPrereqs = field.actionPrereqs;

    // Group rule maps have no statuses
    if (transactionNum >= 0) {
      actionPrereqs = actionPrereqs.filter(
        (r) =>
          r.statuses.findIndex((s) => s.transactionIndex === transactionNum) >=
          0
      );
    }

    if ((actionPrereqs || []).length > 0) {
      for (let j = 0; j < actionPrereqs.length; j++) {
        itemType = getItemTypeFromNode(ruleMap, actionPrereqs[j]);
        actionPrereqField = buildField(
          ruleMap,
          transactionNum,
          actionPrereqs[j],
          itemType,
          array[i]
        );
        addFieldIfNotExist(actionPrereqField);
      }
    }
  }
}

function populateChildrenForArray(
  ruleMap,
  transactionNum,
  array,
  type,
  showModules
) {
  let item, nodeType;

  for (let i = 0; i < array.length; i++) {
    // Reset if we have already visited possible sources for each top-level field we are building. We just want to make sure we don't get into a loop on a single traversal.
    resetAllPossibleSourceVisited();

    item = array[i];
    nodeType =
      type === NodeTypes.Unknown
        ? NodeTypes[item.type || item.valueType]
        : type;

    if (type !== NodeTypes.Rejection) {
      item.modules = []; // No modules allowed at top level
    }
    populateFieldsAndTraverseChildren(
      ruleMap,
      transactionNum,
      item,
      nodeType,
      null, // No parent
      true, // Actual node link (not ghost)
      showModules
    );
  }
}

function filterArrayByStatusTransactionIndex(array, transactionNum) {
  // For Group Rule Map screen, do not filter by status since there is none
  if (transactionNum === -1) return array;

  const retArray = array.filter(
    (r) =>
      r.statuses.findIndex((s) => s.transactionIndex === transactionNum) >= 0
  );
  return retArray;
}

function filterArrayByMessageTransactionIndex(array, transactionNum) {
  // For Group Rule Map screen, do not filter by status since there is none
  if (transactionNum === -1) return array;

  const retArray = array.filter(
    (f) =>
      f.transactionIndex === transactionNum || f.type === "TransmissionMessage"
  );
  return retArray;
}

function transformRuleMapPass1(ruleMap, transactionNum = 0) {
  // This pass collects all possible fields in the rule map - basically all the action prequisites for each node type

  // Response Fields
  // On Group Rule screen, for response fields and messages, start with included data values for these types since there are no messages or response field arrays.
  let responseFields = ruleMap.responseFields || [];

  // Remove all modules for response fields since we can't have a module at the top level
  responseFields = responseFields.map((f) => {
    return { ...f, modules: [] };
  });

  let array = responseFields;
  array = filterArrayByStatusTransactionIndex(array, transactionNum);
  buildFieldsForArray(
    ruleMap,
    transactionNum,
    array,
    NodeTypes.ResponseFieldValue
  );

  // Messages - no need to do these because these are not actual fields that display. They are starting points for other nodes that do display and are
  //   processed in pass 2.

  // Rejections
  array = ruleMap.includedRejections || [];
  array = filterArrayByStatusTransactionIndex(array, transactionNum);
  buildFieldsForArray(ruleMap, transactionNum, array, NodeTypes.Rejection);

  // Excluded Rejections
  array = ruleMap.excludedRejections || [];
  array = filterArrayByStatusTransactionIndex(array, transactionNum);
  buildFieldsForArray(
    ruleMap,
    transactionNum,
    array,
    NodeTypes.ExcludedRejection
  );

  // Included Data Values
  array = ruleMap.includedDataValues || [];
  array = filterArrayByStatusTransactionIndex(array, transactionNum);
  buildFieldsForArray(
    ruleMap,
    transactionNum,
    array,
    NodeTypes.InternalVariable
  );

  // Code Artifacts
  array = ruleMap.codeArtifacts || [];
  array = filterArrayByStatusTransactionIndex(array, transactionNum);

  // Filter out Reversals since we never want to show these, per Randy
  array = array.filter((f) => f.displayKey !== "Reversals/Reversals");
  buildFieldsForArray(ruleMap, transactionNum, array, NodeTypes.CodeArtifact);
}

function transformRuleMapPass2(ruleMap, transactionNum = 0, showModules) {
  // This pass traverses the rulemap tree starting at the top level and sets children in the fields array

  // On Group Rule screen, for response fields and messages, start with included data values for these types since there are no messages or response field arrays.
  let responseFields = ruleMap.responseFields || [];

  // Response Fields
  let array = responseFields;
  array = filterArrayByStatusTransactionIndex(array, transactionNum);

  populateChildrenForArray(
    ruleMap,
    transactionNum,
    array,
    NodeTypes.ResponseFieldValue,
    showModules
  );

  // let rs = fieldList.filter((r) => r.type === NodeTypes.ResponseFieldValue);
  // console.log("Response Fields", rs);

  // Messages
  // On Group Rule screen, for response fields and messages, start with included data values for these types since there are no messages or response field arrays.
  let messageFields = ruleMap.messageFields || [];
  array = messageFields;
  array = filterArrayByMessageTransactionIndex(array, transactionNum);
  populateChildrenForArray(
    ruleMap,
    transactionNum,
    array,
    NodeTypes.Unknown,
    showModules
  );

  // Rejections
  array = ruleMap.includedRejections || [];
  array = filterArrayByStatusTransactionIndex(array, transactionNum);
  populateChildrenForArray(
    ruleMap,
    transactionNum,
    array,
    NodeTypes.Rejection,
    showModules
  );

  // Excluded Rejections
  array = ruleMap.excludedRejections || [];
  array = filterArrayByStatusTransactionIndex(array, transactionNum);
  populateChildrenForArray(
    ruleMap,
    transactionNum,
    array,
    NodeTypes.ExcludedRejection,
    showModules
  );

  // Somehow artifacts can end up with themselves as children. Remove all children where their id matches their parent
  for (let i = 0; i < fieldList.length; i++) {
    removeChildFromFieldById(fieldList[i], fieldList[i].id);
  }
}

function updateFieldsRootAndLeafFlags() {
  // Update all root and leaf flags pased on parent and children
  let field, parents;
  for (let i = 0; i < fieldList.length; i++) {
    field = fieldList[i];
    parents = locateAllParentsOfField(fieldList, field);
    field.isRoot = parents.length === 0;
    field.isLeaf = field.children.length === 0;
  }
}

export function transformRuleMap(ruleMap, transactionNum = 0, showModules) {
  // This function builds a tree array with all rejections, response fields, and messages as root elements.
  //   Each root element will contain a tree of children that traces back the entire rule map tree.
  fieldList = [];

  transformRuleMapPass1(ruleMap, transactionNum);
  transformRuleMapPass2(ruleMap, transactionNum, showModules);

  performFieldListPostProcessing();
  updateFieldsRootAndLeafFlags();

  const fieldLists = buildFieldListsFromRuleMap(ruleMap, transactionNum);

  // const temp = fieldList.filter(
  //   (f) => f.name.indexOf("ValidatePrescriber") >= 0
  // );
  // console.log("TRANSFORM: ValidatePrescriber fields:", temp);

  return {
    fieldList: fieldList,
    rejections: fieldLists.rejectionList,
    responseFields: fieldLists.responseFieldList,
  };
}
