import _ from "lodash";

export default function useReportTemplateParser() {
  const ParseState = {
    Default: 0,
    StartHandlebars: 10,
    EndHandlebars: 11,
    StartFunction: 50,
    Function: 51,
    EndFunction: 52,
  };

  // const ConditionTypes = ["iv", "req", "rsp", "ed", "gs"];
  // const OperatorTypes = [
  //   "eq",
  //   "neq",
  //   "in",
  //   "nin",
  //   "gt",
  //   "ge",
  //   "lt",
  //   "le",
  //   "sw",
  //   "ew",
  //   "ltlen",
  //   "gtlen",
  //   "len",
  //   "mult",
  //   "nmult",
  //   "matches",
  //   "nmatches",
  //   "empty",
  //   "nempty",
  //   "false",
  //   "true",
  //   "null",
  //   "notnull",
  //   "zero",
  //   "nzero",
  // ];
  // const Operators = ["and", "or", "then", "else", ...OperatorTypes];
  const Functions = [
    "absvalue",
    "calculate",
    "comparenum",
    "comparestr",
    "numtostring",
    "padleft",
    "padright",
    "tocurrency",
    "todateformat",
    "toprecision",
    "toshortdate",
  ];

  function getTokensFromParseString(text) {
    // Split source into an array of tokens - splitting on whitespace, and all possible symbols, but preserve the symbols
    //  Symbols to split on: <spc> <newlines> <tab> { } ( ) # "" /
    const tokens = [];
    if (_.trim(text) === "") return tokens;

    // jon, 4/20/23: Changed from regular expression to loop since iOS does not support lookbehind (?<=) and causes a blank screen in app.
    const TokenChars = ' \r\n\t{}()#"/';
    let s = "";
    for (let i = 0; i < text.length; i++) {
      // Do we have a token?
      if (TokenChars.indexOf(text[i]) >= 0) {
        // Add previous text token (if any) and reset for next one. Also, add this token to the array.
        if (s !== "") {
          tokens.push(s);
          s = "";
        }
        tokens.push(text[i]);
      } else {
        // Keep building text string
        s += text[i];
      }
    }

    // Add the final string token if there is one
    if (s !== "") {
      tokens.push(s);
    }

    return tokens;
  }

  function getLookaheadToken(tokens, position) {
    let token = "";

    if (position + 1 < tokens.length) {
      token = tokens[position + 1];
    }

    return "" + token;
  }

  function parseTemplateTextContentIntoHtml(srcText) {
    if (_.trim(srcText) === "") return "";

    // Split source into an array of tokens - splitting on whitespace, and all possible symbols, but preserve the symbols
    const tokens = getTokensFromParseString(srcText);
    if ((tokens || []).length === 0) return "";

    let stateStack = []; // Using an array to simulate a stack here of the current state.
    let isError = false;
    let pos = 0;
    let state;
    let s = "";
    let token = "";
    let lookahead = "";

    // Initial state
    stateStack.push(ParseState.Default);

    while (!isError && pos < tokens.length && stateStack.length > 0) {
      // Pop the latest stack from top of the stack
      state = stateStack.pop();
      token = tokens[pos];
      lookahead = getLookaheadToken(tokens, pos).toLowerCase();

      switch (state) {
        case ParseState.Default: {
          // In default state, check for all possible tokens to see if we need to enter a different state.
          if (Functions.indexOf(token.toLowerCase()) >= 0) {
            stateStack.push(ParseState.StartFunction);
          } else if (token === "{" && lookahead === "{") {
            stateStack.push(ParseState.StartHandlebars);
          } else if (token === "}" && lookahead === "}") {
            stateStack.push(ParseState.EndHandlebars);
          } else {
            // Stay in default state and add this token to the output
            pos++;
            s += token;

            // If we already have a state on the stack, just use that. Otherwise, set back to default
            if (stateStack.length === 0) {
              stateStack.push(ParseState.Default);
            }
          }
          break;
        }

        case ParseState.StartFunction: {
          s += `<span class='handlebarfunction'>`;
          stateStack.push(ParseState.Function);
          break;
        }

        case ParseState.Function: {
          s += `${token}`;
          pos++;

          stateStack.push(ParseState.EndFunction);
          break;
        }

        case ParseState.EndFunction: {
          s += "</span>";

          // If we already have a state on the stack, just use that. Otherwise, set back to default
          if (stateStack.length === 0) {
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.StartHandlebars: {
          s += `<span class='handlebarcontent'><span class='handlebar'>{{</span>`;
          pos += 2;
          stateStack.push(ParseState.Default);
          break;
        }

        case ParseState.EndHandlebars: {
          s += `<span class='handlebar'>}}</span></span>`;
          pos += 2;

          // If we already have a state on the stack, just use that. Otherwise, set back to default
          if (stateStack.length === 0) {
            stateStack.push(ParseState.Default);
          }
          break;
        }

        default: {
          console.log(`Parse Error. Unknown parsing state: ${state}`);
          isError = true;
        }
      }
    }

    if (isError) {
      console.log(
        "Stopped parsing due to error - in the future, add red squiggly under token."
      );
    }

    return s;
  }

  return {
    parseTemplateTextContentIntoHtml,
  };
}
