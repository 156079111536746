import React, { useEffect, useState } from "react";
import Spinner from "../../common/ui/Spinner";
import { notifyError } from "../../../services/NotificationService";
import ClaimDataSection from "./ClaimDataSection";
import ClaimSummary from "./ClaimSummary";
import DrugData from "./DrugData";
import DiscountData from "./DiscountData";
import MemberData from "./MemberData";
import GroupData from "./GroupData";
import PharmacyData from "./PharmacyData";
import PrescriberData from "./PrescriberData";
import RelatedClaimData from "./RelatedClaimData";
import { Link, useNavigate, useParams } from "react-router-dom";
import { jsonToArray } from "../../../services/General";
import { useClaims } from "../../../contexts/ClaimsContext";
import { usePacketParser } from "../../../contexts/PacketParserContext";
import { useAuth } from "../../../contexts/AuthContext";
import { emptyClaim } from "../../../viewmodels/claimsVm";
import { apiLoadClaim, apiLoadPacket } from "../../../api/ClaimApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import { Nav, NavDropdown } from "react-bootstrap";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import {
  StyledActionBar,
  StyledBackButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Claim() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { claimsData, setClaimsData } = useClaims();
  const { packetParserData, setPacketParserData } = usePacketParser();
  const { loading: loadingClaim, api: apiLoadClaimData } = useApi(apiLoadClaim);
  const { loading: loadingPacket, api: apiLoadPacketData } =
    useApi(apiLoadPacket);
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Claim Summary", collapsed: false },
    { name: "Drug Data", collapsed: true },
    { name: "Group Data", collapsed: true },
    { name: "Discount Data", collapsed: true },
    { name: "Member Data", collapsed: true },
    { name: "Pharmacy Data", collapsed: true },
    { name: "Prescriber Data", collapsed: true },
    { name: "Related Claims", collapsed: true },
  ]);

  const claim = (claimsData.claim && claimsData.claim.claim) || {};
  const member = (claimsData.claim && claimsData.claim.member) || {};
  const drug = (claimsData.claim && claimsData.claim.drug) || {};
  const pharmacy = (claimsData.claim && claimsData.claim.pharmacy) || {};
  const prescriber = (claimsData.claim && claimsData.claim.prescriber) || {};
  const discountRedemption =
    (claimsData.claim && claimsData.claim.discountRedemption) || {};
  const discount = (claimsData.claim && claimsData.claim.discount) || {};
  const group = (claimsData.claim && claimsData.claim.group) || {};
  const chain = (claimsData.claim && claimsData.claim.chain) || {};
  const client = (claimsData.claim && claimsData.claim.client) || {};
  const relatedClaims =
    (claimsData.claim && claimsData.claim.relatedClaims) || [];
  const requestFields =
    jsonToArray(packetParserData.packetResponse.requestJson) || [];
  const responseFields =
    jsonToArray(packetParserData.packetResponse.responseJson) || [];

  const claimId =
    (params && params.id) || "00000000-0000-0000-0000-000000000000";

  useEffect(() => {
    if (auth.authenticated) {
      // When id changes, clear instant search field and show loading indicator
      setClaimsData({
        type: ContextProviderActions.saveClaimSearch,
        payload: { ...claimsData.search, highlightText: "" },
      });

      // Reset screen entity when id parameter changes
      setClaimsData({
        type: ContextProviderActions.loadClaim,
        payload: { ...emptyClaim },
      });

      loadClaim();
    }
  }, [auth.authenticated, params?.id]);

  async function loadClaim() {
    apiLoadClaimData.call(claimId, (result) => {
      let vm = emptyClaim;
      if (!result) {
        notifyError("Claim does not exist");
      } else {
        // No view model for claims - just set directly
        vm = result;
      }

      loadPacketAfterClaim(vm);

      setClaimsData({
        type: ContextProviderActions.loadClaim,
        payload: vm,
      });
    });
  }

  function loadPacketAfterClaim(claim) {
    const packetRequest = {
      requestContents: claim.requestContents,
      responseContents: claim.responseContents,
    };

    apiLoadPacketData.call(
      { id: claimId, claimRequest: packetRequest },
      (result) => {
        //The response sends in whichever packets were used to parse (either looked up or sent in)
        const request = {
          processedDate: result.processedDate || "",
          requestContents: result.requestContents || "",
          responseContents: result.responseContents || "",
          claimId,
        };
        savePacketRequest(request);
        savePacketResponse(result);
      }
    );
  }

  function savePacketRequest(packetRequest) {
    setPacketParserData({
      type: ContextProviderActions.savePacketRequest,
      payload: packetRequest,
    });
  }

  function savePacketResponse(packetResponse) {
    setPacketParserData({
      type: ContextProviderActions.savePacketResponse,
      payload: packetResponse,
    });
  }

  let actionsMenuTimer = null;
  function handleMouseEnterActionsMenu() {
    window.clearTimeout(actionsMenuTimer);
    setShowActionsMenu(true);
  }

  function handleMouseLeaveActionsMenu() {
    // Wait a small amount of time before actually closing the menu since a very small gap below the dropdown
    //   and above the menu can cause the menu to close immediately if the user moves the mouse slowly.
    window.clearTimeout(actionsMenuTimer);
    actionsMenuTimer = window.setTimeout(() => setShowActionsMenu(false), 500);
  }

  function handleCollapseExpandAll(collapse) {
    const newCollapsedState = [...collapsedState];
    newCollapsedState.forEach((section) => (section.collapsed = collapse));
    setCollapsedState(newCollapsedState);
  }

  function getActionButton(claimId) {
    return (
      <Nav>
        <NavDropdown
          title="Actions"
          show={showActionsMenu}
          onMouseEnter={handleMouseEnterActionsMenu}
          onMouseLeave={handleMouseLeaveActionsMenu}
          onClick={() => setShowActionsMenu(!showActionsMenu)}
          onSelect={() => setShowActionsMenu(false)}
        >
          <NavDropdown.Item
            as={Link}
            onClick={() => handleCollapseExpandAll(false)}
          >
            Expand all
          </NavDropdown.Item>
          <NavDropdown.Item
            as={Link}
            onClick={() => handleCollapseExpandAll(true)}
          >
            Collapse all
          </NavDropdown.Item>
          {auth.isAdmin && (
            <NavDropdown.Item as={Link} to={`/packetparser/${claimId}`}>
              Parse Packet
            </NavDropdown.Item>
          )}
        </NavDropdown>
      </Nav>
    );
  }

  function setScreenSearchInput(text) {
    // Set the search text as the text to highlight in the claims context
    const newSearch = { ...claimsData.search };
    newSearch.highlightText = text;

    setClaimsData({
      type: ContextProviderActions.saveClaimSearch,
      payload: newSearch,
    });

    // When user is searching, expand all by default
    handleCollapseExpandAll(false);
  }

  return (
    <Authorize>
      <StyledBackButtonDiv>
        <button
          title="Return to previous screen"
          type="button"
          className="btn btn-link"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink path="/Claims/Claim-Details" label="Help" />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1>
          Claim Details{" "}
          {claim.prescriptionRefNumber
            ? ` | ${claim.prescriptionRefNumber}`
            : ""}
        </h1>
        <InstantSearchInput
          id="screenSearchInput"
          onChange={setScreenSearchInput}
          value={claimsData.search.highlightText}
        />
      </StyledHeaderRowDiv>

      {loadingClaim || loadingPacket ? (
        <Spinner />
      ) : (
        <>
          <StyledActionBar>{getActionButton(claimId)}</StyledActionBar>
          <div
            className="container-fluid"
            style={{ marginTop: "5px" }}
            data-testid="detail-section"
          >
            <div>
              <ClaimDataSection
                sectionTitle="Claim Summary"
                data={{ claim, requestFields, responseFields }}
                SubSection={ClaimSummary}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Claims/Claim-Details&anchor=claim-summary-section"
              />
              <ClaimDataSection
                sectionTitle="Drug Data"
                data={drug}
                SubSection={DrugData}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Claims/Claim-Details&anchor=drug-data-section"
              />
              <ClaimDataSection
                sectionTitle="Group Data"
                data={{ group, client }}
                SubSection={GroupData}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Claims/Claim-Details&anchor=group-data-section"
              />
              <ClaimDataSection
                sectionTitle="Discount Data"
                data={{ discount, discountRedemption }}
                SubSection={DiscountData}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Claims/Claim-Details&anchor=discount-data-section"
              />
              <ClaimDataSection
                sectionTitle="Member Data"
                data={member}
                SubSection={MemberData}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Claims/Claim-Details&anchor=member-data-section"
              />
              <ClaimDataSection
                sectionTitle="Pharmacy Data"
                data={{ pharmacy, chain }}
                SubSection={PharmacyData}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Claims/Claim-Details&anchor=pharmacy-data-section"
              />
              <ClaimDataSection
                sectionTitle="Prescriber Data"
                data={prescriber}
                SubSection={PrescriberData}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Claims/Claim-Details&anchor=prescriber-data-section"
              />
              <ClaimDataSection
                sectionTitle="Related Claims"
                data={{ relatedClaims, authNumber: claim.authorizationNumber }}
                SubSection={RelatedClaimData}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Claims/Claim-Details&anchor=related-claims-section"
              />
            </div>
          </div>
        </>
      )}
    </Authorize>
  );
}

export default Claim;
