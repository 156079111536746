import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useAuth } from "../../../contexts/AuthContext";
import Authorize from "../../common/layout/Authorize";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import useApi from "../../../hooks/useApi";
import { useDbLegacyDbSync } from "../../../contexts/dbLegacyDbSyncContext";
import {
  apiCancelDbLegacyDbSync,
  apiLoadDbLegacyDbSync,
  apiRunDbLegacyDbSync,
  apiUpdateDbLegacyDbSync,
} from "../../../api/DbLegacyDbSyncApi";
import {
  createViewModel,
  emptyLegacyDbSync,
  emptyLegacyDbSyncStatus,
  fromViewModel,
} from "../../../viewmodels/dbLegacyDbSyncVm";
import Spinner from "../../common/ui/Spinner";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import { handleCollapseExpandAll } from "../../../services/General";
import TextInput from "../../common/input/TextInput";
import TextAreaInput from "../../common/input/TextAreaInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import DbLegacyDbSyncType from "./DbLegacyDbSyncType";
import styled from "styled-components";
import { parseISO } from "date-fns";
import DbLegacyDbSyncContinuousSection from "./DbLegacyDbSyncContinuousSection";
import StatusPill from "../../common/ui/StatusPill";
import DbUtilsStatusSection from "./DbUtilsStatusSection";
import DbSyncWebSocketHubConnections from "./DbSyncWebSocketHubConnections";
import { useDbDatabaseSync } from "../../../contexts/dbDatabaseSyncContext";

function DbLegacyDbSync() {
  const { auth } = useAuth();
  const { dbLegacyDbSyncData, setDbLegacyDbSyncData } = useDbLegacyDbSync();
  const { dbDatabaseSyncData } = useDbDatabaseSync();
  const { loading, api: apiLoad } = useApi(apiLoadDbLegacyDbSync);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateDbLegacyDbSync);
  const { api: apiRun } = useApi(apiRunDbLegacyDbSync);
  const { api: apiCancel } = useApi(apiCancelDbLegacyDbSync);
  const [changes, setChanges] = useState(emptyLegacyDbSync);
  const [lastSavedChanges, setLastSavedChanges] = useState(emptyLegacyDbSync);
  const [hasChanges, setHasChanges] = useState(false);
  const [updateState, setUpdateState] = useState(emptyLegacyDbSyncStatus);
  const [continuousSyncMessages, setContinuousSyncMessages] = useState([]);
  const [syncIsRunning, setSyncIsRunning] = useState([]);
  const [syncIsStarting, setSyncIsStarting] = useState([]);
  const [syncIsCancelling, setSyncIsCancelling] = useState([]);
  const [isSyncAppRunning, setIsSyncAppRunning] = useState(false);
  const [errors, setErrors] = useState({});
  const [collapsedState, setCollapsedState] = useState([
    { name: "General", collapsed: true },
    { name: "Run Status", collapsed: false },
    { name: "Synchronization Types", collapsed: true },
    { name: "Continuous Synchronization Status", collapsed: false },
  ]);

  useEffect(() => {
    const vm = dbDatabaseSyncData?.trafficDbSyncStateUpdates;
    if (vm !== null) {
      const newConfig = vm.currentConfig.sqlToCosmos;
      const newState = vm.currentState.sqlToCosmos;

      setIsSyncAppRunning(vm.currentState.started === true);

      setUpdateState(newState);
      setContinuousSyncMessages(
        vm.continuousSyncMessages.filter((m) => m.syncSource === 1) // 1=SqlToCosmos, 2=CosmosToCosmos
      );

      setIsRunningForTypes(newState, false);
      setIsStartingForTypes(newConfig);
      setIsCancellingForTypes(newConfig);
    }
  }, [dbDatabaseSyncData?.trafficDbSyncStateUpdates]);

  useEffect(() => {
    if (auth.authenticated) {
      loadDbLegacyDbSync();
    }
  }, [auth.authenticated]);

  useEffect(() => {
    if (dbLegacyDbSyncData.dbLegacyDbSync) {
      setChanges(dbLegacyDbSyncData.dbLegacyDbSync);
      setLastSavedChanges(dbLegacyDbSyncData.dbLegacyDbSync);
    } else {
      setChanges(emptyLegacyDbSync);
      setLastSavedChanges(emptyLegacyDbSync);
    }
  }, [dbLegacyDbSyncData.dbLegacyDbSync]);

  useEffect(() => {
    setHasChanges(
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
        JSON.stringify(_.cloneDeep(changes))
    );
  }, [lastSavedChanges, changes]);

  async function loadDbLegacyDbSync() {
    await apiLoad.call(null, async (result) => {
      const vm = createViewModel(result);
      setDbLegacyDbSyncData({
        type: ContextProviderActions.loadDbLegacyDbSync,
        payload: vm,
      });
      setUpdateState(vm.currentState);
      setIsSyncAppRunning(vm.started === true);

      // Set up is running flags for each type in the typesSynced array
      setIsRunningForTypes(vm.currentState, true);
      setIsStartingForTypes(vm);
      setIsCancellingForTypes(vm);
    });
  }

  function setSyncIsStartingForType(type, isStarting) {
    const newArray = setSyncArrayForType(
      type,
      syncIsStarting,
      "isStarting",
      isStarting
    );
    setSyncIsStarting(newArray);
  }

  function setSyncIsCancellingForType(type, isCancelling) {
    const newArray = setSyncArrayForType(
      type,
      syncIsCancelling,
      "isCancelling",
      isCancelling
    );
    setSyncIsCancelling(newArray);
  }

  function setSyncArrayForType(type, array, boolType, boolValue) {
    const typeArray = [...array];
    let typeItem = typeArray.find((t) => t.type === type);

    if (typeItem) {
      typeItem[boolType] = boolValue;
    } else {
      typeItem = { type: type };
      typeItem[boolType] = boolValue;
      typeArray.push(typeItem);
    }

    return typeArray;
  }

  function getSyncArrayValueForType(type, array, boolType) {
    const typeArray = [...array];
    let typeItem = typeArray.find((t) => t.type === type);

    if (typeItem) {
      return typeItem[boolType];
    } else {
      return false;
    }
  }

  function setIsRunningForTypes(state, alwaysUpdate) {
    if (!state || !state.typesSynced) return;

    let typeArray = [...syncIsRunning];

    let type, item, isRunning;
    for (let i = 0; i < state.typesSynced.length; i++) {
      item = state.typesSynced[i];
      type = item.type;
      isRunning = getSyncArrayValueForType(type, syncIsRunning, "isRunning");

      if (alwaysUpdate) {
        typeArray = setSyncArrayForType(
          type,
          typeArray,
          "isRunning",
          item.isRunInProgress
        );
      } else if (!isRunning && item.isRunInProgress) {
        typeArray = setSyncArrayForType(type, typeArray, "isRunning", true);
      }
    }

    setSyncIsRunning(typeArray);
  }

  function setIsStartingForTypes(config) {
    if (!config || !config.typesToSync) return;

    let typeArray = [...syncIsStarting];

    let type, item;
    for (let i = 0; i < config.typesToSync.length; i++) {
      item = config.typesToSync[i];
      type = item.type;

      typeArray = setSyncArrayForType(
        type,
        typeArray,
        "isStarting",
        item.beginManual
      );
    }

    setSyncIsStarting(typeArray);
  }

  function setIsCancellingForTypes(config) {
    if (!config || !config.typesToSync) return;

    let typeArray = [...syncIsCancelling];

    let type, item;
    for (let i = 0; i < config.typesToSync.length; i++) {
      item = config.typesToSync[i];
      type = item.type;

      typeArray = setSyncArrayForType(
        type,
        typeArray,
        "isCancelling",
        item.cancelInProcess
      );
    }

    setSyncIsCancelling(typeArray);
  }

  function formIsValid() {
    const _errors = {};

    if (_.trim(changes.remoteNewProcessorAddress) === "")
      _errors.remoteNewProcessorAddress =
        "Remote New Processor Address must be entered";

    if (_.trim(changes.remoteNewProcessorPort) === "")
      _errors.remoteNewProcessorPort =
        "Remote New Processor Port must be entered";

    if (_.trim(changes.numberOfClientsToEmulate) === "")
      _errors.numberOfClientsToEmulate =
        "Number of Clients to Emulate must be entered";

    if (_.trim(changes.sourceConnectionString) === "")
      _errors.sourceConnectionString =
        "Source Connection String must be entered";

    if (_.trim(changes.sourceCoreConnectionString) === "")
      _errors.sourceCoreConnectionString =
        "Source Core Connection String must be entered";

    if (_.trim(changes.destConnectionString) === "")
      _errors.destConnectionString =
        "Destination Connection String must be entered";

    if (_.trim(changes.destDatabaseId) === "")
      _errors.destDatabaseId = "Destination Database Id must be entered";

    if (_.trim(changes.destEnvironment) === "")
      _errors.destEnvironment = "Destination Environment must be entered";

    if (_.trim(changes.pollFrequencySeconds) === "")
      _errors.pollFrequencySeconds = "Poll Frequency Seconds must be entered";

    if (_.trim(changes.bulkRecordPageSize) === "")
      _errors.bulkRecordPageSize = "Bulk Record Page Size must be entered";

    if (_.trim(changes.bulkRecordWaitBetweenPages) === "")
      _errors.bulkRecordWaitBetweenPages =
        "Bulk Record Wait Between Pages must be entered";

    // Types array validation check
    let typeItem;
    for (let i = 0; i < changes.typesToSync.length; i++) {
      typeItem = changes.typesToSync[i];

      if (
        _.trim(typeItem.startDate) !== "" &&
        _.trim(typeItem.endDate) !== "" &&
        parseISO(typeItem.startDate) > parseISO(typeItem.endDate)
      )
        _errors[`${typeItem.type}-startDate`] =
          "Start Date must be less than End Date";

      if (_.trim(typeItem.bulkRecordPageSize) === "")
        _errors[`${typeItem.type}-bulkRecordPageSize`] =
          "Bulk Record Page Size must be entered";

      if (_.trim(typeItem.bulkRecordWaitBetweenPages) === "")
        _errors[`${typeItem.type}-bulkRecordWaitBetweenPages`] =
          "Bulk Record Wait Between Pages must be entered";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleCheckboxChange({ target }) {
    let changed = {
      ...changes,
      [target.name]: target.checked,
    };
    setChanges(changed);
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    updateDbLegacyDbSync({ ...changes });
  }

  async function updateDbLegacyDbSync(vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ model }, (result) => {
      const newVm = { ...model, ...result };
      const retModel = createViewModel(newVm);
      setChanges(retModel);
      setLastSavedChanges(retModel);

      notifySuccess("Configuration saved successfully");
    });
  }

  async function handleRunProcess(type) {
    // Check for unsaved changes
    if (
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
      JSON.stringify(_.cloneDeep(changes))
    ) {
      notifyWarn("Please save your changes before running the process.");
      return;
    }

    setSyncIsStartingForType(type, true);
    apiRun.call({ type: type }, async (result) => {
      notifySuccess(`${type} sync started. It will begin momentarily...`);
    });
  }

  async function handleCancelProcess(event, type) {
    if (event) event.preventDefault();

    setSyncIsCancellingForType(type, true);
    apiCancel.call({ type: type }, async (result) => {
      notifySuccess(`${type} sync cancelled. It will stop momentarily...`);
    });
  }

  const isAnySyncStarting =
    (syncIsStarting || []).filter((t) => t.isStarting === true).length > 0;
  const isAnySyncCancelling =
    (syncIsCancelling || []).filter((t) => t.isCancelling === true).length > 0;

  return (
    <Authorize>
      <DbSyncWebSocketHubConnections />
      <form onSubmit={handleSubmit}>
        <StyledScreenHelpDiv>
          <HelpLink path="/Database/Legacy-DB-Sync-Screen" label="Help" />
        </StyledScreenHelpDiv>
        <StyledHeaderRowDiv>
          <div className="flex-row-without-wrap">
            <h1 style={{ display: "inline-block", paddingTop: "5px" }}>
              Legacy DB Synchronization
            </h1>

            <StatusPill
              pillStyle={{
                display: "inline-block",
                marginLeft: "10px",
                minWidth: "50px",
                textAlign: "center",
              }}
              status={isSyncAppRunning ? "Started" : "Stopped"}
            />
          </div>
          {!loading && (
            <StyledHeaderRowButtonDiv
              style={{ flexWrap: "wrap", rowGap: "10px" }}
            >
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "86px",
                  marginLeft: "24px",
                }}
                disabled={false}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  document.location.reload();
                }}
                style={{ marginLeft: "12px" }}
                disabled={false}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          )}
        </StyledHeaderRowDiv>
        {loading || updating ? (
          <Spinner spinnerStyle={{ lineHeight: "25vh" }} />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <ExpandCollapseDetailSection
              sectionTitle="General"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Legacy-DB-Sync-Screen&anchor=general-section"
            >
              <StyledRowDiv className="row">
                <div className="col-12">
                  <TextAreaInput
                    id="sourceConnectionString"
                    rows="3"
                    label="Source Connection String"
                    onChange={handleChange}
                    placeholder="Source Connection String"
                    name="sourceConnectionString"
                    value={changes.sourceConnectionString}
                    error={errors.sourceConnectionString}
                    autoFocus={true}
                  />
                </div>
                <div className="col-12">
                  <TextAreaInput
                    id="sourceCoreConnectionString"
                    rows="3"
                    label="Source Core Connection String"
                    onChange={handleChange}
                    placeholder="Source Core Connection String"
                    name="sourceCoreConnectionString"
                    value={changes.sourceCoreConnectionString}
                    error={errors.sourceCoreConnectionString}
                    autoFocus={true}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                <div className="col-12">
                  <TextAreaInput
                    id="destConnectionString"
                    rows="3"
                    label="Destination Connection String"
                    onChange={handleChange}
                    placeholder="Destination Connection String"
                    name="destConnectionString"
                    value={changes.destConnectionString}
                    error={errors.destConnectionString}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="destDatabaseId"
                    label="Destination Database Id"
                    onChange={handleChange}
                    placeholder="Destination Database Id"
                    name="destDatabaseId"
                    value={changes.destDatabaseId || ""}
                    error={errors.destDatabaseId}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="destEnvironment"
                    label="Destination Environment"
                    onChange={handleChange}
                    placeholder="Destination Environment"
                    name="destEnvironment"
                    value={changes.destEnvironment || ""}
                    error={errors.destEnvironment}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="remoteNewProcessorAddress"
                    label="Remote New Processor Address"
                    onChange={handleChange}
                    placeholder="Remote New Processor Address"
                    name="remoteNewProcessorAddress"
                    value={changes.remoteNewProcessorAddress || ""}
                    error={errors.remoteNewProcessorAddress}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="remoteNewProcessorPort"
                    label="Remote New Processor Port"
                    onChange={handleChange}
                    placeholder="Remote New Processor Port"
                    name="remoteNewProcessorPort"
                    value={changes.remoteNewProcessorPort || ""}
                    error={errors.remoteNewProcessorPort}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="numberOfClientsToEmulate"
                    label="Number of Clients to Emulate"
                    onChange={handleChange}
                    placeholder="Number of Clients to Emulate"
                    name="numberOfClientsToEmulate"
                    value={changes.numberOfClientsToEmulate}
                    error={errors.numberOfClientsToEmulate}
                    isNumber={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="pollFrequencySeconds"
                    label="Poll Frequency Seconds"
                    onChange={handleChange}
                    placeholder="Poll Frequency Seconds"
                    name="pollFrequencySeconds"
                    value={changes.pollFrequencySeconds}
                    error={errors.pollFrequencySeconds}
                    isNumber={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="bulkRecordPageSize"
                    label="Bulk Record Page Size"
                    onChange={handleChange}
                    placeholder="Bulk Record Page Size"
                    name="bulkRecordPageSize"
                    value={changes.bulkRecordPageSize}
                    error={errors.bulkRecordPageSize}
                    isNumber={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="bulkRecordWaitBetweenPages"
                    label="Bulk Record Wait Between Pages"
                    onChange={handleChange}
                    placeholder="Bulk Record Wait Between Pages"
                    name="bulkRecordWaitBetweenPages"
                    value={changes.bulkRecordWaitBetweenPages}
                    error={errors.bulkRecordWaitBetweenPages}
                    isNumber={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <CheckboxInput
                    id="active"
                    label="Active"
                    onChange={handleCheckboxChange}
                    placeholder=""
                    name="active"
                    showLabelInline={false}
                    checked={changes.active}
                    error={errors.active}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Run Status"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Legacy-DB-Sync-Screen&anchor=run-status-section"
            >
              {updateState?.lastStarted ? (
                <DbUtilsStatusSection
                  isAppRunning={isSyncAppRunning}
                  lastStarted={updateState.lastStarted}
                  lastCompleted={updateState.lastCompleted}
                  lastError={updateState.lastError}
                />
              ) : (
                <>Loading...</>
              )}
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Synchronization Types"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Legacy-DB-Sync-Screen&anchor=synchronization-types-section"
            >
              <StyledTypesContainer className="row">
                {(changes.typesToSync || [])
                  .sort((a, b) => (a.type > b.type ? 1 : -1))
                  .map((type, idx) => (
                    <DbLegacyDbSyncType
                      key={`type-${idx}`}
                      type={type.type}
                      changes={changes}
                      setChanges={setChanges}
                      hasChanges={hasChanges}
                      updateState={(updateState?.typesSynced || []).find(
                        (t) => t.type === type.type
                      )}
                      isStarting={getSyncArrayValueForType(
                        type.type,
                        syncIsStarting,
                        "isStarting"
                      )}
                      isCancelling={getSyncArrayValueForType(
                        type.type,
                        syncIsCancelling,
                        "isCancelling"
                      )}
                      isAnyProcessStartingOrCancelling={
                        isAnySyncStarting || isAnySyncCancelling
                      }
                      syncIsRunning={getSyncArrayValueForType(
                        type.type,
                        syncIsRunning,
                        "isRunning"
                      )}
                      onRunProcess={() => handleRunProcess(type.type)}
                      onCancelProcess={(e) => handleCancelProcess(e, type.type)}
                      errors={errors}
                    />
                  ))}
              </StyledTypesContainer>
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Continuous Synchronization Status"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Legacy-DB-Sync-Screen&anchor=continuous-synchronization-status-section"
            >
              <DbLegacyDbSyncContinuousSection
                typeConfigs={changes.typesToSync || []}
                typeStates={updateState?.typesSynced || []}
                lastestMessages={continuousSyncMessages}
              />
            </ExpandCollapseDetailSection>
          </>
        )}
      </form>
    </Authorize>
  );
}

const StyledTypesContainer = styled.div`
  padding: 10px;
`;

export default DbLegacyDbSync;
