import React, { useEffect } from "react";
import { useAuthProvider } from "../../../hooks/useAuthProvider";

function Authorize({ children }) {
  const { isAuthenticated, authActions } = useAuthProvider();

  // Run on first load only for calling screen to refresh the token.
  useEffect(() => {
    // console.log(`About to refresh token. isAuthenticated: ${isAuthenticated}`);
    authActions.renewToken();
  }, []);

  return isAuthenticated ? <>{children}</> : <></>;
}

export default Authorize;
