import React from "react";
import PropTypes from "prop-types";

function CheckboxInput({
  name,
  id,
  value,
  label,
  error,
  onChange,
  placeholder,
  labelStyle,
  checked,
  showLabelInline,
  disabled = false,
  icon = null,
  onHover,
}) {
  function handleMouseOver(name) {
    if (onHover) {
      onHover(name);
    }
  }

  function handleMouseOut() {
    if (onHover) {
      onHover("");
    }
  }

  let wrapperClass = "form-group";
  if (error.length > 0) {
    wrapperClass += " has-error";
  }

  let labelStyles = { ...(labelStyle || {}) };
  if (showLabelInline) {
    labelStyles = { ...labelStyles, marginLeft: "10px" };
  }
  if (disabled === true) {
    labelStyles = { ...labelStyles, color: "var(--text-disabled)" };
  }

  return (
    <div
      className={wrapperClass}
      onMouseOver={() => handleMouseOver(label)}
      onMouseOut={handleMouseOut}
    >
      {!showLabelInline && (
        <label htmlFor={id} style={labelStyles} disabled={disabled}>
          {label}
        </label>
      )}
      <div className={`field ${showLabelInline ? "flex-row-with-wrap" : ""}`}>
        <input
          id={id}
          type="checkbox"
          name={name}
          className="form-check-input"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          checked={checked}
          disabled={disabled}
        ></input>
        {showLabelInline && (
          <label htmlFor={id} style={labelStyles}>
            {label}
          </label>
        )}
        {icon !== null && icon}
      </div>

      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

CheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  checked: PropTypes.bool,
};

CheckboxInput.defaultProps = {
  checked: false,
  error: "",
};

export default CheckboxInput;
