import React, { useEffect, useState } from "react";
import { usePacketTraffic } from "../../../contexts/PacketTrafficContext";
import { useInterval } from "../../../hooks/useInterval";
import styled from "styled-components";
import {
  convertLocalDateToUTC,
  convertUTCDateStringToUTCDate,
  formatDateTimeUtcZoneForDisplay,
} from "../../../services/General";

function HeartbeatStatus({ isAdmin }) {
  const { packetsTraffic } = usePacketTraffic();
  const [heartbeatStatus, setHeartbeatStatus] = useState("Pending");
  const [heartbeatTime, setHeartbeatTime] = useState(null);
  const [dbSyncStatus, setDbSyncStatus] = useState("Pending");
  const [dbSyncTime, setDbSyncTime] = useState(null);
  const [mergedStatus, setMergedStatus] = useState("Pending");
  const [traffic, setTraffic] = useState({});

  const NO_HEARTBEAT_THRESHOLD_MINUTES = 3;

  useEffect(() => {
    if (packetsTraffic) {
      setTraffic(packetsTraffic.packetsTraffic);
      setNewHeartbeatStatus(packetsTraffic.packetsTraffic);
    }
  }, [packetsTraffic]);

  useInterval(() => {
    setNewHeartbeatStatus(traffic);
  }, 1000);

  function setNewHeartbeatStatus(recentTraffic) {
    const hbStatus = getHeartbeatStatusFromTraffic(recentTraffic);
    const dbStatus = getDbSyncStatusFromTraffic(recentTraffic);
    const mergedStatus = getMergedStatus(hbStatus, dbStatus);
    setMergedStatus(mergedStatus);
  }

  function getMergedStatus(hbStatus, dbStatus) {
    // If both statuses are the same, return either one.
    if (hbStatus === dbStatus) return hbStatus;

    // If either status is Pending, return Pending since we are still waiting for both.
    if (hbStatus === "Pending" || dbStatus === "Pending") return "Pending";

    // If exactly one status is Dead but the other is not, set status to Warning
    //   Note that they cannot both be Dead here since we checked that above.
    if (hbStatus === "Dead" || dbStatus === "Dead") return "Warning";

    // Just return here since all cases were handled above.
    return hbStatus;
  }

  function getDbSyncStatusFromTraffic(t) {
    let status = "Pending";

    if (!t || !t.lastLegacySyncTime) return status;

    let isHeartbeatElapsed = true;
    if (t.lastLegacySyncTime !== null) {
      const now = new Date(convertLocalDateToUTC(new Date()).toISOString());
      const heartbeatDate = convertUTCDateStringToUTCDate(t.lastLegacySyncTime);

      const diffTime = Math.abs(now - heartbeatDate);
      const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      if (diffMinutes <= NO_HEARTBEAT_THRESHOLD_MINUTES)
        isHeartbeatElapsed = false;
    }

    if (t.lastLegacySyncTime != null) {
      setDbSyncTime(t.lastLegacySyncTime);
    }

    // If we have packets but heartbeat time has not been set, assume we are alive
    if (isHeartbeatElapsed) {
      status = "Dead";
    } else {
      status = "Alive";
    }

    setDbSyncStatus(status);
    return status;
  }

  function getHeartbeatStatusFromTraffic(t) {
    let status = "Pending";

    if (!t || !t.processedPackets) return status;

    // Look for most recent processed packet since this indicates socket server and processor are working
    const lastHeartbeatPacket =
      t.processedPackets.length === 0 ? null : t.processedPackets[0]; // Most recent are at the top of the array

    const processingDate =
      lastHeartbeatPacket !== null ? lastHeartbeatPacket.respondedDate : null;

    let isHeartbeatElapsed = true;
    if (heartbeatTime !== null) {
      const now = new Date(convertLocalDateToUTC(new Date()).toISOString());
      const heartbeatDate = convertUTCDateStringToUTCDate(heartbeatTime);

      const diffTime = Math.abs(now - heartbeatDate);
      const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      if (diffMinutes <= NO_HEARTBEAT_THRESHOLD_MINUTES)
        isHeartbeatElapsed = false;
    }

    if (processingDate != null) {
      setHeartbeatTime(processingDate);
    }

    // If we have no heartbeat packet and it has been over 5 minutes, change heartbeat status to dead.
    // If we have packets but heartbeat time has not been set, assume we are alive
    if (lastHeartbeatPacket !== null && heartbeatTime === null) {
      status = "Alive";
    } else {
      status = !isHeartbeatElapsed ? "Alive" : "Dead";
    }

    setHeartbeatStatus(status);
    return status;
  }

  function getTooltip() {
    const hbTime =
      heartbeatTime === null
        ? "none"
        : formatDateTimeUtcZoneForDisplay(heartbeatTime);

    const dbTime =
      dbSyncTime === null
        ? "none"
        : formatDateTimeUtcZoneForDisplay(dbSyncTime);

    const tip = `Last heartbeat: ${hbTime}\nLast db sync: ${dbTime}`;
    return tip;
  }

  function getWarningMessage() {
    // Only one of the two statuses will be dead and the other alive in this case.
    if (heartbeatStatus === "Dead") return "No packets!";
    if (dbSyncStatus === "Dead") return "No db sync!";
  }

  if (!isAdmin) return <></>;

  return (
    <HeartbeatStatusDiv>
      {mergedStatus === "Pending" && (
        <p className="pending" data-tip="Checking heartbeat/db sync status...">
          <span>
            <i className="material-icons">favorite_border</i>
          </span>
          <span className="hb-text">Checking...</span>
        </p>
      )}
      {mergedStatus === "Warning" && (
        <p className="warning" data-tip={getTooltip()}>
          <span>
            <i className="material-icons pulse-fade">favorite_border</i>
          </span>
          <span className="hb-text">{getWarningMessage()}</span>
        </p>
      )}
      {mergedStatus === "Dead" && (
        <p className="dead" data-tip={getTooltip()}>
          <span>
            <i className="material-icons pulse-fade">heart_broken</i>
          </span>
          <span className="hb-text">No heartbeat!</span>
        </p>
      )}
      {mergedStatus === "Alive" && (
        <p className="alive" data-tip={getTooltip()}>
          <span>
            <i className="heartbeat material-icons">favorite</i>
          </span>
          <span className="hb-text">OK</span>
        </p>
      )}
    </HeartbeatStatusDiv>
  );
}

const HeartbeatStatusDiv = styled.div`
  position: relative;
  top: 2px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;

    &.pending {
      color: var(--notify-neutral);
    }

    &.warning {
      color: var(--notify-warning);
      cursor: pointer;
    }

    &.dead {
      color: var(--notify-danger);
      cursor: pointer;
    }

    &.alive {
      color: var(--notify-success);
      cursor: pointer;
    }

    span.hb-text {
      font-size: 14px;
      padding-left: 4px;
    }
  }
`;

export default HeartbeatStatus;
