import React, { useState } from "react";
import styled from "styled-components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { StyledBlockLetter } from "../layout/CommonStyledControls";
import {
  getIconFromAggregate,
  getNumberFromParameterValue,
  getOperatorDesc,
} from "../../../services/General";
import { MAX_INDENT_LEVEL } from "../../screens/reports/ReportFilterConditionCommon";

function DragDropCondition({
  id,
  index,
  label,
  condition,
  isFirstChild,
  parentOperand,
  onClickRemove,
  onClickEdit,
  onClickIndentLevelChange,
  onChangeGroupBreak,
  disabled,
  selectFields,
}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });
  const [removing, setRemoving] = useState(false);

  const indentLevel = condition.actualLevel
    ? condition.actualLevel
    : condition.indentLevel;
  const operand = condition.operand;

  // Remove scaling because it was warping the conditions in a weird way.
  const _transform = { ...transform, scaleX: 1, scaleY: 1 };
  const style = {
    transform: CSS.Transform.toString(_transform),
    transition,
    opacity: isDragging ? ".5" : 1,
    display: "flex",
    alignItems: "center",
    position: "relative",
  };

  function onRemove() {
    if (disabled) return;
    // Allow user a small amount of time to see the condition disappear and the others slide over before actually removing.
    setRemoving(true);
    window.setTimeout(() => {
      onClickRemove(id);
      setRemoving(false);
    }, 500);
  }

  function onChangeIndentLevel(id, level) {
    if (disabled) return;
    const currentLevel = isNaN(indentLevel) ? 0 : indentLevel;
    onClickIndentLevelChange(id, currentLevel + level);
  }

  function handleChangeGroupBreak(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (disabled) return;

    // Do not allow a group break if this is the first child in a group.
    //   But do allow group break to be unset if already set.
    if (!isFirstChild || condition.groupBreak) {
      onChangeGroupBreak(condition.id, !condition.groupBreak);
    }
  }

  let icon = <></>;
  const selectField = selectFields.find(
    (f) => f.fieldDisplayName === condition.fieldDisplayName
  );
  if (selectField && selectField.aggregateFunction !== "None") {
    icon = getIconFromAggregate(selectField.aggregateFunction);
  }

  return (
    <>
      <div ref={setNodeRef} style={style} name={id}>
        <StyledGroupOperandArrow operand={parentOperand}>
          <span className="material-icons">arrow_right</span>
        </StyledGroupOperandArrow>
        {!disabled && (
          <span
            className={`material-icons icon-with-hover pointer ${
              condition.indentLevel < 1 ? "disabled transparent" : ""
            }`}
            style={{ marginRight: "-15px", fontSize: "28px" }}
            onClick={() => {
              if (!disabled && condition.indentLevel >= 1)
                onChangeIndentLevel(condition.id, -1);
            }}
            title="Remove condition from this group"
          >
            chevron_left
          </span>
        )}
        <StyledDraggableChip
          name={id}
          className={`${
            disabled ? "chip-disabled" : removing ? "removing" : ""
          } ${operand === "Or" ? "join-or" : ""}`}
          style={{
            maxWidth: operand === "And" ? "1020px" : "1020px",
          }}
          onDoubleClick={handleChangeGroupBreak}
        >
          {!disabled && (
            <span className="material-icons" {...attributes} {...listeners}>
              drag_indicator
            </span>
          )}
          <StyledCondition>
            <div className="flex-row-with-wrap">
              <StyledFieldSpan>
                {condition.isParameter && condition.parameterLabel !== label ? (
                  <>
                    {condition.parameterLabel} ({label})
                  </>
                ) : (
                  label
                )}
              </StyledFieldSpan>
              <div>{icon}</div>
              {condition.isParameter && (
                <StyledBlockLetter style={{ marginRight: "5px" }}>
                  P
                </StyledBlockLetter>
              )}
              <StyledOperatorSpan>{` ${getOperatorDesc(
                condition.operator
              )}`}</StyledOperatorSpan>
              <StyledFieldSpan>
                {getNumberFromParameterValue(condition.value)}
              </StyledFieldSpan>
            </div>
          </StyledCondition>
          {!disabled && (
            <div
              className="flex-row-with-wrap"
              style={{
                justifyContent: "flex-end",
                position: "absolute",
                right: "-42px",
                width: "100px",
              }}
            >
              <span
                className="material-icons icon-with-hover pointer"
                onClick={onClickEdit}
                title="Edit condition"
              >
                edit
              </span>
              <span
                className={`material-icons icon-with-hover pointer ${
                  condition.indentLevel < MAX_INDENT_LEVEL
                    ? ""
                    : "disabled transparent"
                }`}
                style={{ marginLeft: "1px", fontSize: "28px" }}
                onClick={() => {
                  if (!disabled && condition.indentLevel < MAX_INDENT_LEVEL)
                    onChangeIndentLevel(condition.id, 1);
                }}
                title="Add condition to next group"
              >
                chevron_right
              </span>
              <span
                className="material-icons icon-with-hover pointer"
                style={{ marginLeft: "0", fontSize: "16px" }}
                onClick={onRemove}
                title="Remove condition"
              >
                close
              </span>
            </div>
          )}
        </StyledDraggableChip>
      </div>
    </>
  );
}

const StyledGroupOperandArrow = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  height: 24px;
  left: -15px;

  span[class="material-icons"] {
    font-size: 24px;
    line-height: 24px;
    color: ${(props) =>
      props.operand === "Or"
        ? "var(--drag-chip-join-or-border)"
        : "var(--drag-chip-border)"};
  }
`;

const StyledCondition = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px !important;
  cursor: default;

  span[class="material-icons icon-subtle"] {
    color: var(--drag-chip-placeholder-text);
  }
`;

const StyledFieldSpan = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  color: var(--text-normal);
  margin: 0 5px;
  cursor: default;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

const StyledOperatorSpan = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  color: var(--text-normal);
  margin: 0 5px;
  cursor: default;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

const StyledDraggableChip = styled.div`
  position: relative;
  min-height: 36px;
  max-width: 1000px;
  padding: 10px 10px 10px 10px;
  background: var(--drag-chip-bg);
  border: 1px solid var(--drag-chip-border);
  border-radius: 18px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  transition: all 0.5s ease-in-out;
  margin-left: 10px;
  margin-right: 20px;

  &.removing {
    opacity: 0;
  }

  &.join-or {
    background: var(--drag-chip-join-or-bg);

    p {
      color: var(--drag-chip-join-or-border);
    }

    span[class="material-icons"] {
      color: var(--drag-chip-join-or-icon);
    }
  }

  &.chip-disabled {
    opacity: 0.5;
    background: var(--elevated-bg);
    border: 1px solid var(--elevated-border);

    &.join-or {
      background: var(--elevated-bg);
      border: 1px solid var(--elevated-border);
    }
  }

  span[class="material-icons"] {
    font-size: 20px;
    line-height: 20px;
    color: var(--drag-chip-border);
    touch-action: none;

    &:hover {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
`;

export default DragDropCondition;
