import React from "react";
import _ from "lodash";

export default function RuleGroupSummaryParser() {
  const ParseState = {
    Default: 0,
    StartOperator: 10,
    Operator: 11,
    EndOperator: 12,
    StartOperand: 20,
    Operand: 21,
    EndOperand: 22,
    StartCode: 30,
    Code: 31,
    EndCode: 32,
    StartPropertyLink: 33,
    PropertyLink: 34,
    EndPropertyLink: 35,
    StartRuleTable: 36,
    RuleTable: 37,
    EndRuleTable: 38,
    StartFieldname: 40,
    Fieldname: 41,
    EndFieldname: 42,
    StartConstant: 50,
    Constant: 51,
    EndConstant: 52,
  };

  function getTokensFromParseString(text) {
    // Split source into an array of tokens - splitting on whitespace, and all possible symbols, but preserve the symbols
    const tokens = [];
    if (_.trim(text) === "") return tokens;

    // If there are no _OR_ operators, remove all parentheses since they are not needed.
    if (text.indexOf("_OR_") < 0) {
      text = text.replaceAll("(", "").replaceAll(")", "");
    }

    // jon, 4/20/23: Changed from regular expression to loop since iOS does not support lookbehind (?<=) and causes a blank screen in app.
    const TokenChars = " \r\n\t_@[]#-|^";
    let s = "";
    for (let i = 0; i < text.length; i++) {
      // Do we have a token?
      if (TokenChars.indexOf(text[i]) >= 0) {
        // Add previous text token (if any) and reset for next one. Also, add this token to the array.
        if (s !== "") {
          tokens.push(s);
          s = "";
        }
        tokens.push(text[i]);
      } else {
        // Keep building text string
        s += text[i];
      }
    }

    // Add the final string token if there is one
    if (s !== "") {
      tokens.push(s);
    }

    return tokens;
  }

  function getLookaheadToken(tokens, position) {
    let token = "";

    if (position + 1 < tokens.length) {
      token = tokens[position + 1];
    }

    return "" + token;
  }

  function getPropLinkElement(
    propType,
    propText,
    className,
    onPropertyLinkClick,
    returnToId,
    isAdminView = false
  ) {
    const prefix =
      propType === "gs"
        ? "GRP."
        : propType === "prop"
        ? "VAR."
        : propType === "req"
        ? "REQ."
        : "";

    const elem = (
      <span
        className={className}
        onClick={(e) => onPropertyLinkClick(e, propType, propText, returnToId)}
      >
        {isAdminView ? prefix : ""}
        {propText}
      </span>
    );
    return elem;
  }

  function doesItemExistInList(list, keyName, key) {
    const index = (list || []).findIndex(
      (p) => _.lowerCase(p[keyName]) === _.lowerCase(key)
    );
    return index >= 0;
  }

  function parseTextContentIntoHtml(
    id,
    srcText,
    isAdmin,
    isAdminView,
    onPropertyLinkClick,
    properties,
    groupSettings,
    requestValues
  ) {
    // Split source into an array of tokens - splitting on whitespace, and all possible symbols, but preserve the symbols
    const tokens = getTokensFromParseString(srcText);

    let stateStack = []; // Using an array to simulate a stack here of the current state.
    let isError = false;
    let pos = 0;
    let state;
    let s = "";
    let token = "";
    let lookahead = "";
    let html = [];

    // Initial state
    stateStack.push(ParseState.Default);

    // I'm checking here if pos is 1 past the token array length since we need to sometimes go one past to finish the last element on double endings like -- or ##.
    while (!isError && stateStack.length > 0 && pos <= tokens.length) {
      // Pop the latest stack from top of the stack
      state = stateStack.pop();

      if (pos < tokens.length) {
        token = tokens[pos];
      } else {
        token = "";
      }
      lookahead = getLookaheadToken(tokens, pos).toLowerCase();

      switch (state) {
        case ParseState.Default: {
          // Any input left? If not, break with no more states on the stack so while will end.
          if (pos >= tokens.length) {
            break;
          }

          // In default state, check for all possible tokens to see if we need to enter a different state.
          if (token === "_") {
            stateStack.push(ParseState.StartOperand);
          } else if (token === "@") {
            stateStack.push(ParseState.StartOperator);
          } else if (token === "[") {
            stateStack.push(ParseState.StartCode);
          } else if (token === "|") {
            stateStack.push(ParseState.StartPropertyLink);
          } else if (token === "^") {
            stateStack.push(ParseState.StartRuleTable);
          } else if (token === "#" && lookahead === "#") {
            stateStack.push(ParseState.StartFieldname);
          } else if (token === "-" && lookahead === "-") {
            stateStack.push(ParseState.StartConstant);
          } else {
            // Stay in default state and add this token to the output as plain text
            pos++;
            html.push(token);

            // If we already have a state on the stack, just use that. Otherwise, set back to default
            if (stateStack.length === 0) {
              stateStack.push(ParseState.Default);
            }
          }
          break;
        }

        case ParseState.StartOperand: {
          pos++;
          stateStack.push(ParseState.Operand);
          break;
        }

        case ParseState.Operand: {
          if (token === "_") {
            stateStack.push(ParseState.EndOperand);
          } else {
            s += token;
            stateStack.push(ParseState.Operand);
          }
          pos++;
          break;
        }

        case ParseState.EndOperand: {
          // Add newline before AND operand
          const elem =
            s === "AND" ? (
              <span className="newline">
                <span className="keyword">{s}</span>
              </span>
            ) : (
              <span className="keyword">{s}</span>
            );
          html.push(elem);
          s = "";
          stateStack.push(ParseState.Default);
          break;
        }

        case ParseState.StartOperator: {
          pos++;
          stateStack.push(ParseState.Operator);
          break;
        }

        case ParseState.Operator: {
          if (token === "@") {
            stateStack.push(ParseState.EndOperator);
          } else {
            s += token;
            stateStack.push(ParseState.Operator);
          }
          pos++;
          break;
        }

        case ParseState.EndOperator: {
          const elem = <span className="operator">{s}</span>;
          html.push(elem);
          s = "";
          stateStack.push(ParseState.Default);
          break;
        }

        case ParseState.StartCode: {
          pos++;
          stateStack.push(ParseState.Code);
          break;
        }

        case ParseState.Code: {
          if (token === "]") {
            stateStack.push(ParseState.EndCode);
          } else {
            s += token;
            stateStack.push(ParseState.Code);
          }
          pos++;
          break;
        }

        case ParseState.EndCode: {
          const t = s;
          let elem = <span className="field">{t}</span>;
          let propType = "";

          if (doesItemExistInList(properties, "name", t)) propType = "prop";
          else if (doesItemExistInList(groupSettings, "name", t))
            propType = "gs";
          else if (doesItemExistInList(requestValues, "key", t))
            propType = "req";

          if (!_.isEmpty(propType)) {
            elem = getPropLinkElement(
              propType,
              t,
              "proplink",
              onPropertyLinkClick,
              id,
              isAdminView
            );
          }

          html.push(elem);
          s = "";
          stateStack.push(ParseState.Default);
          break;
        }

        case ParseState.StartFieldname: {
          if (lookahead === "#") {
            // Skip past both ## start tokens
            pos++;
            stateStack.push(ParseState.StartFieldname);
          } else {
            pos++;
            stateStack.push(ParseState.Fieldname);
          }
          break;
        }

        case ParseState.Fieldname: {
          if (token === "#") {
            pos++;
            stateStack.push(ParseState.EndFieldname);
          } else {
            pos++;
            s += token;
            stateStack.push(ParseState.Fieldname);
          }

          break;
        }

        case ParseState.EndFieldname: {
          if (token === "#") {
            // Skip past both ## end tokens
            pos++;
            stateStack.push(ParseState.EndFieldname);
          } else {
            const elem = <span className="name">{s}</span>;
            html.push(elem);
            s = "";
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.StartConstant: {
          if (lookahead === "-") {
            // Skip past both ## start tokens
            pos++;
            stateStack.push(ParseState.StartConstant);
          } else {
            pos++;
            stateStack.push(ParseState.Constant);
          }
          break;
        }

        case ParseState.Constant: {
          if (token === "-") {
            pos++;
            stateStack.push(ParseState.EndConstant);
          } else {
            pos++;
            s += token;
            stateStack.push(ParseState.Constant);
          }

          break;
        }

        case ParseState.EndConstant: {
          if (token === "-") {
            // Skip past both ## end tokens
            pos++;
            stateStack.push(ParseState.EndConstant);
          } else {
            const elem = <span className="number">{s}</span>;
            html.push(elem);
            s = "";
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.StartRuleTable: {
          pos++;
          stateStack.push(ParseState.RuleTable);
          break;
        }

        case ParseState.RuleTable: {
          if (token === "^") {
            stateStack.push(ParseState.EndRuleTable);
          } else {
            s += token;
            stateStack.push(ParseState.RuleTable);
          }
          pos++;
          break;
        }

        case ParseState.EndRuleTable: {
          let t = s.split("/");
          let elem = !isAdmin ? (
            <>
              Rule Table <span className="link">{t[1]}</span>
            </>
          ) : (
            <>
              Rule Table{" "}
              <a
                target="ruletable"
                className="link"
                href={`/ruletable/${t[0]}`}
              >
                {t[1]}
              </a>
            </>
          );

          html.push(elem);
          s = "";
          stateStack.push(ParseState.Default);
          break;
        }

        case ParseState.StartPropertyLink: {
          pos++;
          stateStack.push(ParseState.PropertyLink);
          break;
        }

        case ParseState.PropertyLink: {
          if (token === "|") {
            stateStack.push(ParseState.EndPropertyLink);
          } else {
            s += token;
            stateStack.push(ParseState.PropertyLink);
          }
          pos++;
          break;
        }

        case ParseState.EndPropertyLink: {
          let t = s.split("/");
          let elem = <span className="field">{t[1]}</span>;
          let propType = "";

          if (t[0] === "prop" && doesItemExistInList(properties, "name", t[1]))
            propType = "prop";
          else if (
            t[0] === "gs" &&
            doesItemExistInList(groupSettings, "name", t[1])
          )
            propType = "gs";
          else if (
            t[0] === "req" &&
            doesItemExistInList(requestValues, "key", t[1])
          )
            propType = "req";

          if (!_.isEmpty(propType)) {
            elem = getPropLinkElement(
              propType,
              t[1],
              "proplink",
              onPropertyLinkClick,
              id,
              isAdminView
            );
          }

          html.push(elem);
          s = "";
          stateStack.push(ParseState.Default);
          break;
        }

        default: {
          console.log(`Parse Error. Unknown parsing state: ${state}`);
          isError = true;
        }
      }
    }

    if (isError) {
      console.log(
        "Stopped parsing due to error - in the future, add red squiggly under token."
      );
    }

    return html;
  }

  return { parseTextContentIntoHtml };
}
