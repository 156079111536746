import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyRuleDataSearch } from "../viewmodels/ruleTableDataVm";
import {
  emptyRuleTable,
  emptyRuleTableSearch,
} from "../viewmodels/ruleTablesVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadRuleTables:
      return {
        ...state,
        ruleTables: action.payload.ruleTables,
        count: action.payload.count,
      };

    case ContextProviderActions.saveRuleTableSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadRuleTable:
      return {
        ...state,
        ruleTable: action.payload,
      };

    case ContextProviderActions.saveRuleDataSearch:
      return {
        ...state,
        dataSearch: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in RuleTablesContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  ruleTables: [],
  ruleTable: emptyRuleTable,
  search: emptyRuleTableSearch,
  dataSearch: emptyRuleDataSearch,
  count: 0,
};

const RuleTables = createContext({
  ruleTablesData: initialState,
  setRuleTablesData: () => null,
});

// eslint-disable-next-line react/prop-types
const RuleTablesProvider = ({ children }) => {
  const [ruleTablesData, setRuleTablesData] = useReducer(reducer, initialState);

  return (
    <RuleTables.Provider value={{ ruleTablesData, setRuleTablesData }}>
      {children}
    </RuleTables.Provider>
  );
};

export default RuleTablesProvider;
export const useRuleTables = () => useContext(RuleTables);
