import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { notifyError } from "../services/NotificationService";
import { handleSpecificFailures } from "../api/apiUtils";
import { useAuth } from "../contexts/AuthContext";
import { useAuthProvider } from "./useAuthProvider";
import { useTestCoverageTracking } from "../contexts/TestCoverageTrackingContext";
import { useUserPreferences } from "../contexts/UserPreferencesContext";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { generateUUID } from "../services/General";
import { getApiCoveragePercent } from "../components/common/ui/CoverageTrackingCommon";

export default function useApi(apiFunction) {
  const { auth } = useAuth();
  const { authActions } = useAuthProvider();
  const { userPrefs } = useUserPreferences();
  const { setTestCoverageTrackingData } = useTestCoverageTracking();
  const [loading, setLoading] = useState(false);

  const controllerRef = useRef();
  const functionName = apiFunction.funcName || "Unknown" || apiFunction.name;

  useEffect(() => {
    return () => {
      setLoading(false);

      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  const call = async (params, resultCallback, errorCallback) => {
    // console.log("Calling API function: " + apiFunction.name);
    setLoading(true);

    // Always renew token before each API call to keep session alive
    authActions.renewToken();

    // Set up abort controller so we can cancel in-flight requests on unload.
    const controller = new AbortController();
    controllerRef.current = controller;

    if (_.isEmpty(auth.token)) {
      setLoading(false);
      notifyError("Unable to get data. Please refresh the page");
    }

    function stripCoverageMetadataFromResult(result) {
      let resultBody = _.cloneDeep(result);

      // Strip tracking metadata from result, add it to the context, and pass original result body to callback.
      if (userPrefs.coverageTrackingMode && result.metadata) {
        resultBody = _.cloneDeep(result.body);

        setTestCoverageTrackingData({
          type: ContextProviderActions.saveTestCoverageTracking,
          payload: {
            id: generateUUID(),
            apiName: functionName,
            apiDate: new Date(),
            numCalls: 1,
            entryCount: result.metadata.entries?.length || 0,
            coveragePercent: getApiCoveragePercent(result, false),
            coveragePercentHideDetails: getApiCoveragePercent(result, true),
            metadata: result.metadata,
          },
        });
      }

      return resultBody;
    }

    function handleErrorResult(error) {
      let errorBody = `(${error.statusCode}) ${error.message}`;

      if (userPrefs.coverageTrackingMode) {
        // Parse json that holds metadata and body from the message
        const json = JSON.parse(error.message);
        errorBody = stripCoverageMetadataFromResult(json);

        // Add status code to error message from body
        errorBody = `(${error.statusCode}) ${errorBody}`;
      }

      return errorBody;
    }

    // ***PROD*** Switch to this for production for proper error handling
    return await apiFunction(params, auth.token, controllerRef.current?.signal)
      .then(async (result) => {
        setLoading(false);

        // Coverage tracking support
        const resultBody = stripCoverageMetadataFromResult(result);

        controllerRef.current = null;
        // Success
        if (resultCallback) {
          return await resultCallback(resultBody);
        } else {
          return;
        }
      })
      .catch((error) => {
        setLoading(false);
        controllerRef.current = null;

        // If request was aborted, suppress this error and return
        if (error.name === "AbortError") {
          // console.log("Aborting API call: " + apiFunction.name);
          return;
        }

        // Coverage tracking support
        const errorBody = handleErrorResult(error);
        // const errorBody = stripCoverageMetadataFromResult(result);

        // Check for custom error handler
        const notify = errorCallback ? errorCallback(errorBody) : true;

        // Error
        if (notify) {
          notifyError(handleSpecificFailures(errorBody));
        }
      });
    // ***END PROD***

    // ***DEV*** Switch to this for development to show better error messages
    // let resultBody = "";
    // try {
    //   const apiResult = await apiFunction(
    //     params,
    //     auth.token,
    //     controllerRef.current?.signal
    //   );
    //   setLoading(false);

    //   // Coverage tracking support
    //   resultBody = stripCoverageMetadataFromResult(apiResult);
    //   controllerRef.current = null;
    // } catch (error) {
    //   setLoading(false);
    //   controllerRef.current = null;

    //   // If request was aborted, suppress this error and return
    //   if (error.name === "AbortError") {
    //     // console.log("Aborting API call: " + apiFunction.name);
    //     return;
    //   }

    //   // Coverage tracking support
    //   const errorBody = handleErrorResult(error);
    //   // const errorBody = stripCoverageMetadataFromResult(result);

    //   // Check for custom error handler
    //   const notify = errorCallback ? errorCallback(errorBody) : true;

    //   // Error
    //   if (notify) {
    //     notifyError(handleSpecificFailures(errorBody));
    //   }
    // }

    // // Success: call the callback function
    // if (resultCallback) {
    //   return await resultCallback(resultBody);
    // }
    // return;
    // ***END DEV***
  };

  const api = {
    call,
  };

  return { loading, api };
}
