import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
} from "../../../services/General";
import StatusPill from "../../common/ui/StatusPill";
import InfoDialog from "../../dialogs/InfoDialog";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function TestGroupResultsList({
  testResultId,
  tests,
  totalRecords,
  search,
  setSearch,
}) {
  const navigate = useNavigate();
  const [messageText, setMessageText] = useState("");
  const [showMessageDialog, setShowMessageDialog] = useState(false);

  function getActions(dataRow) {
    const entityId = dataRow.entityId;

    const actions = [];

    actions.push({
      name: "View test result",
      onClick: () => navigate("/testresults/" + testResultId + "/" + entityId),
    });

    // Passing autorun parameter set to true to test and testgroup screens to indicate to run immediately
    actions.push({
      name: "Run just this test",
      onClick: () => navigate("/test/" + entityId + "/true"),
    });

    actions.push({
      name: "Edit test",
      onClick: () => navigate("/test/" + entityId),
    });

    return actions;
  }

  function handleShowMessage(message) {
    if (message !== "") {
      setMessageText(message);
      setShowMessageDialog(true);
    }
  }

  return (
    <>
      <InfoDialog
        title={"Message"}
        text={messageText}
        showModal={showMessageDialog}
        onClose={() => {
          setMessageText("");
          setShowMessageDialog(false);
        }}
      />
      <ResponsiveGrid
        gridId="TestGroupResultTests"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        allowFreeTextSearch={true}
        dataRows={tests.sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))}
        columnDefs={[
          {
            name: "results",
            label: "Results",
            disableSort: true,
            style: { width: "70px", maxWidth: "15%", textAlign: "left" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                title="View the results of this test run"
                onClick={() =>
                  navigate(`/testresults/${row.id}/${testResultId}`)
                }
              >
                view
              </button>
            ),
          },
          {
            name: "ordinal",
            label: "Order",
            disableSort: true,
            className: "d-none d-md-table-cell",
            style: { width: "15%" },
          },
          {
            name: "description",
            label: "Test Description",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "40%" },
            getValue: (row, index, searchResultHtml) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/test/" + row.id)}
              >
                {searchResultHtml}
              </button>
            ),
          },
          {
            name: "lastRunDate",
            label: "Last Run",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
            getValue: (row) =>
              row.lastRunDate === ""
                ? ""
                : formatDateTimeUtcZoneForDisplay(row.lastRunDate),
          },
          {
            name: "lastRunBy",
            label: "Run By",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
          },
          {
            name: "lastRunDurationMilliseconds",
            label: "Duration",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              getTimeframeFromMilliseconds(row.lastRunDurationMilliseconds),
          },
          {
            name: "lastRunResultStatus",
            label: "Last Result",
            freeTextSearch: true,
            style: { width: "15%" },
            noForceWrap: true,
            getValue: (row, index, searchResultHtml) => (
              <StatusPill
                status={searchResultHtml}
                pillStyle={{
                  cursor:
                    row.lastRunResultStatus === "Error" ? "pointer" : "default",
                }}
                message={(row.message || "").substring(0, 50)}
                onClick={() => handleShowMessage(row.message)}
              />
            ),
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default TestGroupResultsList;
