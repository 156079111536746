import React, { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { useMobile } from "../../../hooks/useMobile";
import HeaderNavLink from "./HeaderNavLink";

function HeaderNavDropdown({ name, isActive, hasPermission, children }) {
  const { userPrefs } = useUserPreferences();
  const { isTabletSize } = useMobile();
  const [showMenu, setShowMenu] = useState(false);

  let menuTimer = null;
  function handleMouseEnterMenu() {
    if (userPrefs.menuToggleExpanded) return;
    window.clearTimeout(menuTimer);
    setShowMenu(true);
  }

  function handleMouseLeaveMenu() {
    if (userPrefs.menuToggleExpanded) return;
    // Wait a small amount of time before actually closing the menu since a very small gap below the dropdown
    //   and above the menu can cause the menu to close immediately if the user moves the mouse slowly.
    window.clearTimeout(menuTimer);
    menuTimer = window.setTimeout(() => setShowMenu(false), 100);
  }

  if (!hasPermission) return <></>;

  return isTabletSize ? (
    <>
      <HeaderNavLink
        name={name}
        path=""
        isActive={false}
        hasPermission={true}
        isInDropdown={false}
        disabled={true}
      />
      {children}
    </>
  ) : (
    <NavDropdown
      title={name}
      show={showMenu}
      onMouseEnter={handleMouseEnterMenu}
      onMouseLeave={handleMouseLeaveMenu}
      onClick={() => setShowMenu(!showMenu)}
      onSelect={() => setShowMenu(false)}
      className={`${isActive ? "active-menu-item" : ""}`}
    >
      {children}
    </NavDropdown>
  );
}

export default HeaderNavDropdown;
