import styled from "styled-components";
import { Panel } from "@xyflow/react";

export const StyledNodeDiv = styled.div`
  min-height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  border-radius: 3px;
`;

export const StyledCard = styled.div`
  width: 100%;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 10px 20px 10px;
  background-color: var(--selected-bg);
  border: 1px solid ${(props) => props.colors.bgColor};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 8px;
  box-shadow: ${(props) => props.colors.bgShadow};

  &:hover {
    background-color: var(--rulemap-selected-bg);
    border: 1px solid var(--selected-border);
  }

  &.rule-map-module {
    padding: 40px;
    border-radius: 40px;
  }
`;

export const StyledCardValue = styled.div`
  width: 100%;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-items: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 8px;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCardLeafNodeList = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
`;

export const StyledCardLogList = styled.ul`
  width: 100%;
  padding: 0 20px 0 2px;
  max-height: 400px;
  overflow-y: auto;
  list-style-type: none;

  li div {
    font-size: 13px;
    line-height: 13px;
    text-indent: -20px;
    margin-left: 20px;
    margin-right: 20px;

    span {
      color: var(--text-dark);
    }
  }
`;

export const StyledLogLink = styled.div`
  position: absolute;
  bottom: 2px;
  right: 8px;

  button.btn-link {
    font-size: 13px;
  }
`;

export const StyledCopyContentsLink = styled.span`
  position: absolute;
  top: -12px;
  left: -8px;

  button.btn-link {
    &:hover {
      color: var(--text-normal);
    }

    span {
      font-size: 16px;
    }
  }
`;

export const StyledDescription = styled.div`
  width: 100%;
  font-weight: normal;
  font-size: 18px;
  font-family: "Source Sans Pro";
  color: var(--text-normal);
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span.charblock {
    font-family: "Courier New", Courier, monospace;
    display: inline-block;
    color: ${(props) => props.colors.color};
    background-color: ${(props) => props.colors.bgColor};
    font-weight: bold;
    padding: 2px 4px 0 4px;
    font-size: 16px;
    border-radius: 3px;
  }

  span.full-width {
    display: inline-block;
    width: 100%;
    padding-left: 20px;
  }

  span.value {
    color: var(--text-normal);
    font-weight: bold;
  }
`;

export const StyledTranslatedValues = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 0 20px 0 35px;
  border-top: 1px dotted var(--ruleparser-darkgray);
  border-bottom: 1px dotted var(--ruleparser-darkgray);
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  overflow: hidden;
`;

export const StyledTranslatedValue = styled.p`
  position: relative;
  width: 100%;
  padding: 4px 0;
  margin-bottom: 0;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 16px;

  span {
    position: absolute;
    top: 3px;
    left: -28px;
    font-size: 18px;
    color: ${(props) => props.colors.bgColor};
  }
`;

export const StyledTranslatedResult = styled.p`
  width: 100%;
  color: var(--text-dark);
  margin: 10px 0 0 0;
  padding: 0 20px 0 0;
  font-size: 18px;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
`;

export const StyledProcessedIndicator = styled.div`
  position: absolute;
  top: -18px;
  right: -18px;

  span.material-symbols-outlined {
    font-size: 32px;
    color: var(--ruleparser-yellow);
  }
`;

export const StyledControlPanel = styled(Panel)`
  z-index: 99;
  margin-right: 60px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background-color: var(--bg-flat);
  box-shadow: 0px 0px 10px var(--pill-blue);
  border-radius: 10px;
  min-height: 54px;

  button {
    display: flex;
    min-width: 40px !important;
    width: 40px;
    min-height: 36px;
    padding: 1px 6px;
    align-items: center;
    text-align: center;
    flex: auto;
    color: var(--text-medium);
    background-color: var(--bg-flat);
    border: 1px solid var(--text-medium);

    &:hover {
      color: var(--text-medium);
      background-color: var(--text-verylight);
    }

    &:active {
      color: var(--text-highlight);
      background-color: var(--text-verylight);
    }

    &.btn-selected {
      color: var(--text-highlight);
      background-color: var(--pill-blue);
    }
  }

  div.form-group {
    margin: 0;

    div.field {
      margin: 0;

      div {
        font-size: 14px !important;
      }
    }
  }
`;
