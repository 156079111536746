import React from "react";
import ClaimSectionRow from "./ClaimSectionRow";
import { formatDateOnly } from "../../../services/General";
import _ from "lodash";

export default function GroupData({ data }) {
  const group = data.group;
  const client = data.client;
  const address =
    !_.isEmpty(client) && client.addresses.length > 0
      ? client.addresses[0]
      : null;
  const phone =
    !_.isEmpty(client) && client.phoneNumbers.length > 0
      ? client.phoneNumbers[0]
      : null;
  const contact =
    !_.isEmpty(client) && client.contacts.length > 0
      ? client.contacts[0]
      : null;
  const emailAddress =
    !_.isEmpty(contact) && contact.emailAddresses.length > 0
      ? contact.emailAddresses[0]
      : null;

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-6 col-xxl-4">
        <ClaimSectionRow label="Group Id" value={group.id} />
        <ClaimSectionRow label="Group Name" value={group.name} />
        <ClaimSectionRow label="Group Description" value={group.description} />
        <ClaimSectionRow label="TPA Code" value={group.tpaCode} />
        <ClaimSectionRow
          label="Effective Date"
          value={formatDateOnly(group.effectiveDate)}
        />
        <ClaimSectionRow
          label="Termination Date"
          value={formatDateOnly(group.terminationDate)}
        />

        {!_.isEmpty(address) ? (
          <>
            <ClaimSectionRow label="Address Type" value={address.addressType} />
            <ClaimSectionRow label="Address 1" value={address.address1} />
            <ClaimSectionRow label="Address 2" value={address.address2} />
            <ClaimSectionRow label="City" value={address.city} />
            <ClaimSectionRow label="State" value={address.state} />
            <ClaimSectionRow label="Zip" value={address.zipCode} />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="col-xs-12 col-lg-6 col-xxl-3">
        {!_.isEmpty(phone) ? (
          <>
            <ClaimSectionRow label="Phone Type" value={phone.phoneType} />
            <ClaimSectionRow label="Area Code" value={phone.areaCode} />
            <ClaimSectionRow label="Number" value={phone.number} />
          </>
        ) : (
          <></>
        )}
        {!_.isEmpty(contact) ? (
          <>
            <ClaimSectionRow label="First Name" value={contact.firstName} />
            <ClaimSectionRow label="Middle Name" value={contact.middleName} />
            <ClaimSectionRow label="Last Name" value={contact.lastName} />
            <ClaimSectionRow
              label="Email Address"
              value={emailAddress && emailAddress.emailAddress}
            />
          </>
        ) : (
          <></>
        )}
        <ClaimSectionRow label="Client Id" value={group.clientId} />
        <ClaimSectionRow label="Client Name" value={client.name} />
        <ClaimSectionRow
          label="Client Description"
          value={client.description}
        />
      </div>
    </div>
  );
}
