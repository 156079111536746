/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders } from "./apiUtils";

// Call the negotiate endpoint to get the url and access token for the Azure SignalR Hub.
export function apiGetSignalRServiceInfo(hub, token, signal) {
  const uri = API_URL + `/${hub}/negotiate`;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiGetSignalRServiceInfo.funcName = "apiGetSignalRServiceInfo";
