import React, { createContext, useContext, useReducer } from "react";
import _ from "lodash";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyTestCoverageTrackingSearch } from "../viewmodels/testCoverageTrackingVm";
import { mergeApiMetadata } from "../components/common/ui/CoverageTrackingCommon";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.saveTestCoverageTracking: {
      // Always compute both grouped and non-grouped results
      const _newTracking = _.cloneDeep(state.coverageResults);
      let _newGroupedTracking = _.cloneDeep(state.coverageGroupedResults);

      _newTracking.push(action.payload);
      addNewTrackingToGroupedResults(_newGroupedTracking, action.payload);

      return {
        ...state,
        coverageResults: _newTracking,
        coverageGroupedResults: _newGroupedTracking,
        records:
          state.search.groupMode === true ? _newGroupedTracking : _newTracking,
      };
    }

    case ContextProviderActions.clearTestCoverageTracking: {
      return {
        ...state,
        coverageResults: [],
        coverageGroupedResults: [],
        records: [],
        selectedRecord: null,
        search: emptyTestCoverageTrackingSearch,
      };
    }

    case ContextProviderActions.saveTestCoverageTrackingSearch: {
      // If group mode has changed, update records to be the correct array
      let _records = state.records;
      if (state.search.groupMode !== action.payload.groupMode) {
        _records =
          action.payload.groupMode === true
            ? state.coverageGroupedResults
            : state.coverageResults;
      }

      return {
        ...state,
        records: _records,
        search: action.payload,
      };
    }

    case ContextProviderActions.setTestCoverageTrackingSelectedRecord:
      return {
        ...state,
        selectedRecord: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in TestCoverageTrackingContext: ${action.type.toString()}`
      );
  }
}

function addNewTrackingToGroupedResults(newGroupedTracking, newResult) {
  // If we don't already have this api call, just add it
  const existing = newGroupedTracking.find(
    (r) => r.apiName === newResult.apiName
  );
  if (!existing) {
    newGroupedTracking.push(newResult);
  } else {
    // Merge this new result with the existing api call
    mergeApiMetadata(existing, newResult);
  }
}

const initialState = {
  records: [],
  coverageResults: [],
  coverageGroupedResults: [],
  search: emptyTestCoverageTrackingSearch,
  selectedRecord: null,
};

const TestCoverageTracking = createContext({
  testCoverageTrackingData: initialState,
  setTestCoverageTrackingData: () => null,
});

// eslint-disable-next-line react/prop-types
const TestCoverageTrackingProvider = ({ children }) => {
  const [testCoverageTrackingData, setTestCoverageTrackingData] = useReducer(
    reducer,
    initialState
  );

  return (
    <TestCoverageTracking.Provider
      value={{ testCoverageTrackingData, setTestCoverageTrackingData }}
    >
      {children}
    </TestCoverageTracking.Provider>
  );
};

export default TestCoverageTrackingProvider;
export const useTestCoverageTracking = () => useContext(TestCoverageTracking);
