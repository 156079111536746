import React from "react";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function PrescribersList({
  prescribers,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const npi = dataRow.npi;

    const actions = [
      {
        name: "View Details",
        onClick: () => navigate("/prescriber/" + id),
      },
      {
        name: "Remove",
        onClick: () => onDelete(id, npi),
      },
    ];

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/prescriber/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="Prescribers"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={prescribers}
        columnDefs={[
          {
            name: "npi",
            label: "Id",
            style: { width: "35%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/prescriber/" + row.id)}
              >
                {row.npi}
              </button>
            ),
          },
          {
            name: "contacts[0].firstName",
            label: "First Name",
            style: { width: "25%" },
            getValue: (row) => row.firstName,
          },
          {
            name: "contacts[0].lastName",
            label: "Last Name",
            style: { width: "25%" },
            getValue: (row) => row.lastName,
          },
          {
            name: "addresses[0].address1",
            label: "Address",
            className: "d-none d-xl-table-cell",
            style: { width: "25%" },
            getValue: (row) => row.address,
          },
          {
            name: "addresses[0].city",
            label: "City",
            className: "d-none d-xl-table-cell",
            style: { width: "25%" },
            getValue: (row) => row.city,
          },
          {
            name: "addresses[0].state",
            label: "State",
            className: "d-none d-xl-table-cell",
            style: { width: "15%" },
            getValue: (row) => row.state,
          },
          {
            name: "lastModifiedDate",
            label: "Created/Modified",
            className: "d-none d-xl-table-cell",
            style: { width: "20%" },
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.lastModifiedDate),
          },
          {
            name: "active",
            label: "Active",
            className: "d-none d-xl-table-cell",
            style: { width: "6%" },
            getValue: (row) =>
              row.active ? <span className="material-icons">check</span> : "",
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default PrescribersList;
