import React from "react";
import styled from "styled-components";
import { getTimeAgoFromIntervalSeconds } from "../../../services/General";
import ReadOnly from "../../common/input/ReadOnly";

function DiagnosticsList({
  averageProcessedTime,
  mostRecentAverage,
  totalCount,
  diagnostics,
}) {
  function getTimeAgoInterval(timeAgo, timeUnit) {
    const m = `${timeAgo} ${timeAgo === 1 ? timeUnit : timeUnit + "s"} ago`;
    return m;
  }

  let startDate;
  let endDate;
  let interval;
  let timeAgoInfo;

  return (
    <div data-testid="home-diagnostics-section-list">
      <hr style={{ margin: "16px -20px 0 -20px" }} />
      <StyledCalculationsSection>
        <ReadOnly
          id="averageProcessedTime"
          label="Total Average Processed Time"
          value={averageProcessedTime}
        />
        <ReadOnly
          id="mostRecentAverage"
          label="Most Recent Average Time"
          value={mostRecentAverage}
        />
        <ReadOnly
          id="totalCount"
          label="Total Claim Count"
          value={totalCount}
        />
      </StyledCalculationsSection>

      <table className="table rule-grid" style={{ marginTop: "14px" }}>
        <thead>
          <tr>
            <th style={{ width: "40%" }}>Time</th>
            <th style={{ width: "25%" }}>Claims</th>
            <th style={{ width: "25%" }} className="d-none d-lg-table-cell">
              Drugs
            </th>
            <th style={{ width: "25%" }} className="d-none d-lg-table-cell">
              Pharmacies
            </th>
            <th style={{ width: "25%" }} className="d-none d-md-table-cell">
              Paid
            </th>
            <th style={{ width: "25%" }} className="d-none d-md-table-cell">
              Reversals
            </th>
            <th style={{ width: "25%" }} className="d-none d-md-table-cell">
              Rejections
            </th>
            <th style={{ width: "25%" }}>Diffs</th>
            <th style={{ width: "25%" }}>Errors</th>
          </tr>
        </thead>
        <tbody>
          {diagnostics.map((p, idx) => {
            startDate = new Date(p.startDateTime);
            endDate = new Date(p.endDateTime);
            interval = Math.abs(endDate - startDate) / 1000;
            timeAgoInfo = getTimeAgoFromIntervalSeconds(interval, idx);
            return (
              <tr key={p.id}>
                <td className="force-wrap">
                  {getTimeAgoInterval(timeAgoInfo.ago, timeAgoInfo.unit)}
                </td>
                <td className="force-wrap">{p.claimCount}</td>
                <td className="force-wrap d-none d-lg-table-cell">
                  {p.drugCount}
                </td>
                <td className="force-wrap d-none d-lg-table-cell">
                  {p.pharmacyCount}
                </td>
                <td className="force-wrap d-none d-md-table-cell">
                  {p.unreversedCount}
                </td>
                <td className="force-wrap d-none d-md-table-cell">
                  {p.reversalCount}
                </td>
                <td className="force-wrap d-none d-md-table-cell">
                  {p.rejectionCount}
                </td>
                <td className="force-wrap">{p.nonMatchCount}</td>
                <td className="force-wrap">{p.errors}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const StyledCalculationsSection = styled.div`
  display: flex;
  column-gap: 44px;
  row-gap: 14px;
  align-items: center;
  flex-wrap: wrap;

  label {
    color: var(--text-medium);
  }
`;

export default DiagnosticsList;
