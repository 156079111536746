import React from "react";
import styled from "styled-components";
import DragDropCondition from "../../common/ui/DragDropCondition";
import { getJoinOperatorDesc } from "../../../services/General";
import { NodeType } from "./ReportFilterConditionCommon";

function ReportFilterConditionContainer({
  operandMode,
  conditionNode,
  parentNode,
  isFirstChild = true,
  disabled,
  selectFields,
  onRemoveCondition,
  onEditCondition,
  onSetConditionIndentLevel,
  onChangeGroupBreak,
  onChangeOperandMode,
}) {
  function handleOperandModeChange(isTopLevel) {
    if (!isTopLevel) return;
    onChangeOperandMode((operandMode || "And") === "And" ? "Or" : "And");
  }

  if (conditionNode === null) return <></>;

  // Is this node a container with children? Containers render the operand groupings.
  if (conditionNode.type === NodeType.Container) {
    const inner = (
      <StyledOperandContainer
        key={`operand-${conditionNode.indentLevel}`}
        operand={conditionNode.operand}
      >
        <StyledGroupOperandConnector operand={conditionNode.operand} />
        {conditionNode.children.map((condition, idx) => {
          return (
            <React.Fragment
              key={`container-${condition.id}-${conditionNode.indentLevel}-${idx}`}
            >
              <StyledJoinOperator
                operand={conditionNode.operand}
                isTopLevel={conditionNode.indentLevel === -1}
                title={
                  conditionNode.indentLevel === -1
                    ? `Change to ${
                        operandMode === "And" ? "Or-And-Or" : "And-Or-And"
                      } pattern`
                    : ""
                }
                onClick={() =>
                  handleOperandModeChange(conditionNode.indentLevel === -1)
                }
              >
                {getJoinOperatorDesc(conditionNode.operand)}
              </StyledJoinOperator>
              <ReportFilterConditionContainer
                operandMode={operandMode}
                conditionNode={condition}
                parentNode={conditionNode}
                isFirstChild={idx === 0}
                disabled={disabled}
                selectFields={selectFields}
                onRemoveCondition={onRemoveCondition}
                onEditCondition={onEditCondition}
                onSetConditionIndentLevel={onSetConditionIndentLevel}
                onChangeGroupBreak={onChangeGroupBreak}
                onChangeOperandMode={onChangeOperandMode}
              />
            </React.Fragment>
          );
        })}
      </StyledOperandContainer>
    );

    return (
      <>
        {parentNode !== null && parentNode.type === NodeType.Container ? (
          <StyledOperandGrouping operand={parentNode.operand}>
            <StyledGroupOperandArrow operand={parentNode.operand}>
              <span className="material-icons">arrow_right</span>
            </StyledGroupOperandArrow>
            {inner}
          </StyledOperandGrouping>
        ) : (
          <>{inner}</>
        )}
      </>
    );
  }

  // Otherwise, this is a normal leaf node so just render the chip.
  return (
    <DragDropCondition
      key={`${conditionNode.id}`}
      id={conditionNode.id}
      label={conditionNode.fieldLabel}
      condition={conditionNode}
      isFirstChild={isFirstChild}
      parentOperand={
        parentNode !== null && parentNode.type === NodeType.Container
          ? parentNode.operand
          : "And"
      }
      disabled={disabled}
      onClickRemove={() => {
        if (!disabled) onRemoveCondition(conditionNode.id);
      }}
      onClickEdit={() => {
        if (!disabled) onEditCondition(conditionNode.id);
      }}
      onClickIndentLevelChange={onSetConditionIndentLevel}
      onChangeGroupBreak={onChangeGroupBreak}
      selectFields={selectFields}
    ></DragDropCondition>
  );
}

const StyledGroupOperandArrow = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  height: 24px;
  left: -15px;

  span[class="material-icons"] {
    font-size: 24px;
    line-height: 24px;
    color: ${(props) =>
      props.operand === "Or"
        ? "var(--drag-chip-join-or-border)"
        : "var(--drag-chip-border)"};
  }
`;

const StyledOperandGrouping = styled.div`
  position: relative;
  padding: 8px 0 8px 5px;
  background-color: transparent;
`;

const StyledGroupOperandConnector = styled.div`
  position: absolute;
  top: 0;
  left: -6px;
  bottom: 0;
  width: 6px;
  border: 1px solid
    ${(props) =>
      props.operand === "Or"
        ? "var(--drag-chip-join-or-border)"
        : "var(--drag-chip-border)"};
  border-right: none;
  border-radius: 8px 0 0 8px;
  background-color: transparent;
`;

const StyledOperandContainer = styled.div`
  position: relative;
  display: flex;
  align-items: left;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 8px;
  padding: 5px 10px 5px 0;
  margin-top: 10px;
  margin-left: 15px;
`;

const StyledJoinOperator = styled.span`
  position: absolute;
  top: -17px;
  left: ${(props) => (props.operand === "Or" ? "-20px" : "-23px")};
  text-align: center;
  width: 40px;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  color: ${(props) =>
    props.operand === "Or"
      ? "var(--drag-chip-join-or-border)"
      : "var(--drag-chip-border)"};
  cursor: ${(props) => (props.isTopLevel ? "pointer" : "default")};
  border: ${(props) =>
    props.isTopLevel
      ? `1px solid ${
          props.operand === "Or"
            ? "var(--drag-chip-join-or-border)"
            : "var(--drag-chip-border)"
        }`
      : "none"};
  padding: "2px";

  &:hover,
  &:active {
    background-color: ${(props) =>
      props.isTopLevel ? "var(--drag-chip-bg)" : "transparent"};
  }
`;

export default ReportFilterConditionContainer;
