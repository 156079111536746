import React, { useState } from "react";
import _ from "lodash";
import {
  MMDDyyyy,
  formatDateTimeUtcZoneForDisplay,
} from "../../../services/General";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import InfoDialog from "../../dialogs/InfoDialog";
import StatusPill from "../../common/ui/StatusPill";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function DiscountsList({
  discounts,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
  isPriceCheck,
  bundleInfo,
  claimId,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [infoDialogTitle, setInfoDialogTitle] = useState("");
  const [infoDialogText, setInfoDialogText] = useState("");
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const isMatchedMode = typeof claimId !== "undefined";

  function getRowStyle(filteredOut) {
    if (filteredOut) {
      return { color: "var(--text-disabled)" };
    } else {
      return { color: "var(--text-normal)" };
    }
  }

  function getActions(dataRow) {
    const id = dataRow.id;
    const discountPlan = dataRow.discountPlan;
    const discountMode = dataRow.discountMode;

    const actions = [
      {
        name: "View Details",
        onClick: () =>
          navigate(
            isMatchedMode
              ? "/discount/" + claimId + "/" + id
              : "/discount/" + id
          ),
      },
      {
        name: "Remove",
        onClick: () => onDelete(id, discountPlan, discountMode),
      },
    ];

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/discount/" + id + "/0"),
      });
    }

    return actions;
  }

  function getCurrentMaxRemainingColumn(
    currentVal,
    maximumVal,
    remainingVal,
    fixed
  ) {
    const current = (currentVal || 0).toFixed(fixed);
    const maximum = maximumVal || 0;
    const remaining = (remainingVal || 0).toFixed(fixed);

    let ret = <>&ndash;</>;
    if (currentVal) {
      ret = current;

      if (maximum > 0 && isPriceCheck) {
        ret += "/" + remaining;
      }
    }

    return ret;
  }

  function openInfoDialog(colType, csv) {
    setInfoDialogTitle(colType);
    setInfoDialogText(csv);
    setShowInfoDialog(true);
  }

  function getCSVColumnWithLinkToDialog(csv, colType) {
    if (_.isEmpty(csv)) return <></>;

    // We should have at least one item in the list now.
    const list = ("" + csv).split(",");
    const numItems = list.length;

    // If only one item, don't show the link
    if (numItems === 1) return <>{csv}</>;

    // More than one item - show the first item and then a link with the remaining number of items
    const firstItem = list[0];
    const link = (
      <button
        className="btn btn-link link-underline"
        onClick={() => openInfoDialog(colType, csv)}
      >
        {`${numItems - 1} more`}
      </button>
    );

    return (
      <div
        className="flex-row-with-wrap"
        style={{ rowGap: "0", color: "var(--text-normal)" }}
      >
        <span style={{ paddingRight: "5px" }}>{firstItem}</span>
        {link}
      </div>
    );
  }

  function getGridFirstColumnValue(row, index) {
    const val = bundleInfo.isBundle ? (
      <>
        {row.tags.map((tag, idx) => (
          <StatusPill
            key={`tag-${idx}`}
            status={tag}
            pillStyle={{
              backgroundColor: "var(--pill-unknown)",
              height: "12px",
              fontSize: "14px",
              marginLeft: "-10px",
            }}
          />
        ))}
      </>
    ) : index === 0 ? (
      <span
        className="material-icons"
        style={{ color: "var(--notify-success)" }}
      >
        check
      </span>
    ) : (row.maxCount > 0 && row.remainingUses === 0) ||
      (row.maximumPaid > 0 && row.remainingBenefit === 0) ||
      (row.maximumQuantity > 0 && row.remainingQty === 0) ? (
      <StatusPill status="Max" />
    ) : (
      <></>
    );

    return val;
  }

  return (
    <>
      {isMatchedMode &&
        (bundleInfo.isBundle ? (
          <div className="flex-row-with-wrap">
            <h4 style={{ marginTop: "20px", fontSize: "18px" }}>
              {bundleInfo.bundleName}
              {bundleInfo.bundleTags.map((tag, idx) => (
                <StatusPill
                  key={`tag-${idx}`}
                  status={tag}
                  pillStyle={{
                    backgroundColor: "var(--pill-unknown)",
                    marginLeft: "10px",
                    height: "16px",
                  }}
                />
              ))}
            </h4>
            <h4
              style={{
                margin: "25px 20px 0 auto",
                fontSize: "15px",
                textAlign: "right",
              }}
            >
              <span style={{ color: "var(--text-medium)" }}>
                Initial Price:
              </span>
              &nbsp;&nbsp;$
              {(search.initialPrice || 0).toFixed(2)}
            </h4>
          </div>
        ) : (
          <h4 style={{ marginTop: "20px", fontSize: "16px" }}>
            Discounts considered for this claim
          </h4>
        ))}

      <ResponsiveGrid
        gridId="Discounts"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        alwaysShowDetailsInMobileView={isMatchedMode}
        dataRows={discounts}
        disablePaging={bundleInfo.isBundle}
        disableRecordCount={bundleInfo.isBundle}
        getRowStyle={(row) => getRowStyle(row.filteredOut)}
        columnDefs={[
          {
            name: "isBundle",
            label: bundleInfo.isBundle ? "Tags" : "Used",
            disableSort: true,
            hidden: !isMatchedMode,
            noForceWrap: true,
            style: {
              width: bundleInfo.isBundle ? "20%" : "70px",
              maxWidth: bundleInfo.isBundle ? "25%" : "10%",
              textAlign: "left",
            },
            getValue: (row, index) => getGridFirstColumnValue(row, index),
          },
          {
            name: "discountPlan",
            label: "Discount Plan (Mode)",
            disableSort: isMatchedMode,
            style: { width: "40%" },
            getValue: (row) => (
              <u
                className="pointer"
                title="Discount Plan (Mode)"
                onClick={() =>
                  navigate(
                    isMatchedMode
                      ? "/discount/" + claimId + "/" + row.id
                      : "/discount/" + row.id
                  )
                }
              >
                {row.discountPlan + " (" + row.discountMode + ")"}
              </u>
            ),
          },
          {
            name: "discountAmount",
            label: "Amount (Type)",
            disableSort: isMatchedMode,
            style: { width: "25%" },
            getValue: (row) =>
              row.discountAmount.toFixed(2) + " (" + row.discountType + ")",
          },
          {
            name: "groupId",
            label: "Group",
            disableSort: isMatchedMode,
            style: { width: "20%" },
            className: "d-none d-lg-table-cell",
            getValue: (row) => (
              <span data-tip={row.groupName}>{row.groupId}</span>
            ),
          },
          {
            name: "memberid",
            label: "Member",
            disableSort: isMatchedMode,
            style: { width: "20%" },
            className: "d-sm-none d-lg-table-cell",
            getValue: (row) => row.memberId || "(None)",
          },
          {
            name: "pharmacies",
            label: "Pharmacies",
            disableSort: isMatchedMode,
            style: { width: "20%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) =>
              getCSVColumnWithLinkToDialog(
                row.pharmaciesVm || "(None)",
                "Pharmacies"
              ),
          },
          {
            name: "ndcs",
            label: "Drugs",
            disableSort: isMatchedMode,
            style: { width: "20%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) =>
              getCSVColumnWithLinkToDialog(row.ndcsVm || "(None)", "Drugs"),
          },
          {
            name: "prescribers",
            label: "Prescribers",
            disableSort: isMatchedMode,
            style: { width: "20%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) =>
              getCSVColumnWithLinkToDialog(
                row.prescribersVm || "(None)",
                "Prescribers"
              ),
          },
          {
            name: "effectiveDate",
            label: "Effective",
            disableSort: isMatchedMode,
            style: { width: "20%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) => MMDDyyyy(row.effectiveDate),
          },
          {
            name: "terminationDate",
            label: "Termination",
            disableSort: isMatchedMode,
            style: { width: "20%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) =>
              MMDDyyyy(row.terminationDate) === "01/01/3000"
                ? "-"
                : MMDDyyyy(row.terminationDate),
          },
          {
            name: "currentCount",
            label: "Counts",
            disableSort: isMatchedMode,
            style: { width: "15%" },
            className: "d-none d-sm-table-cell align-right",
            getValue: (row) =>
              getCurrentMaxRemainingColumn(
                row.currentCount,
                row.maxCount,
                row.remainingUses,
                0
              ),
          },
          {
            name: "currentPaid",
            label: "Paid",
            disableSort: isMatchedMode,
            style: { width: "15%" },
            className: "d-none d-sm-table-cell align-right",
            getValue: (row) =>
              getCurrentMaxRemainingColumn(
                row.currentPaid,
                row.maximumPaid,
                row.remainingBenefit,
                2
              ),
          },
          {
            name: "currentQty",
            label: "Qty",
            disableSort: isMatchedMode,
            style: { width: "15%" },
            className: "d-none d-sm-table-cell align-right",
            getValue: (row) =>
              getCurrentMaxRemainingColumn(
                row.currentQty,
                row.maximumQuantity,
                row.remainingQty,
                3
              ),
          },
          {
            name: "thirdPartyCalculatedPayAmount",
            label: "Paid",
            disableSort: true,
            hidden: !isPriceCheck,
            style: { width: "20%" },
            className: "d-none d-sm-table-cell align-right",
            getValue: (row) => row.thirdPartyCalculatedPayAmount.toFixed(2),
          },
          {
            name: "patientCalculatedPayAmount",
            label: "Copay",
            disableSort: true,
            hidden: !isPriceCheck,
            style: { width: "20%" },
            className: "d-none d-sm-table-cell align-right",
            getValue: (row) => row.patientCalculatedPayAmount.toFixed(2),
          },
          {
            name: "lastModifiedDate",
            label: "Created",
            disableSort: isMatchedMode,
            hidden: isPriceCheck,
            style: { width: "20%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.lastModifiedDate),
          },
          {
            name: "active",
            label: "Active",
            disableSort: true,
            hidden: isMatchedMode,
            style: { width: "10%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) =>
              row.active ? <span className="material-icons">check</span> : "",
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            hidden: isMatchedMode,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
      {bundleInfo.isBundle && (
        <h4
          style={{
            margin: "15px 20px 0 auto",
            fontSize: "15px",
            textAlign: "right",
          }}
        >
          <span style={{ color: "var(--text-medium)" }}>
            Total Paid/Remaining Copay:
          </span>
          &nbsp;&nbsp;$
          {(bundleInfo.totalPaid || 0).toFixed(2)}/ $
          {(bundleInfo.finalCopay || 0).toFixed(2)}
        </h4>
      )}
      <InfoDialog
        title={infoDialogTitle}
        text={infoDialogText}
        showModal={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
      />
    </>
  );
}

export default DiscountsList;
