import React from "react";
import {
  StyledStepHeaderDiv,
  StyledWizardListItem,
} from "../../../common/layout/CommonStyledWizardComponents";

function PanelSubmitForm({
  stepNumber,
  currentStep,
  title,
  label,
  disabled,
  submitting,
  onSubmit,
  showPanel = true,
}) {
  if (!showPanel || currentStep < stepNumber) {
    return <></>;
  }

  return (
    <StyledWizardListItem step={stepNumber}>
      <StyledStepHeaderDiv>{title}</StyledStepHeaderDiv>
      <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-12 col-md-6">
          <button
            type="button"
            className="btn btn-primary"
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
            disabled={disabled}
            onClick={onSubmit}
          >
            {submitting ? (
              <>
                <span className="material-symbols-outlined rotating">sync</span>
                &nbsp;&nbsp;
              </>
            ) : (
              <span className="material-icons">check</span>
            )}
            {label}
          </button>
        </div>
      </div>
    </StyledWizardListItem>
  );
}

export default PanelSubmitForm;
