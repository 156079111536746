import React from "react";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <div>
      <h2>Oops, how did I end up here?</h2>
      <br />
      <p>
        We&apos;re sorry, but the page for <b>{window.location.href}</b> could
        not be found!
      </p>
      <br />
      <p>
        <Link to="/">Return To Home page</Link>
      </p>
    </div>
  );
}

export default NotFoundPage;
