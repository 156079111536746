import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { formatDecimal } from "../../../services/General";

function TestResultsTable({
  transactionIndex,
  originalResponseFields,
  testResponseFields,
  isFromTestScreen = false,
}) {
  function getOriginalFieldById(fieldId) {
    const field = originalResponseFields.find(
      (f) => f.fieldId === fieldId && f.transactionIndex === transactionIndex
    );
    if (!field) {
      return "0.00";
    }

    return field.valueAsString;
  }

  function getOriginalFieldRejections() {
    const fieldRejCount = originalResponseFields.find(
      (f) => f.fieldId === "FA" && f.transactionIndex === transactionIndex
    );
    if (!fieldRejCount) {
      return ""; // No rejections
    }

    // Build comma-separated list of rejections codes from subFields
    let rejections = [];
    for (let i = 0; i < fieldRejCount.subFields.length; i++) {
      if (rejections.indexOf(fieldRejCount.subFields[i].valueAsString) < 0) {
        rejections.push(fieldRejCount.subFields[i].valueAsString);
      }
    }

    rejections = rejections.sort((a, b) => (a > b ? 1 : -1));

    return _.trim(rejections.join(", "));
  }

  function getTestFieldById(fieldId) {
    const field = testResponseFields[fieldId];
    if (!field) {
      return "0.00";
    }

    return formatDecimal(field, 2);
  }

  function getTestStringFieldById(fieldId) {
    const field = testResponseFields[fieldId];
    if (!field) {
      return "";
    }

    return field;
  }

  function getTestFieldRejections() {
    const fieldRejections = testResponseFields.FB;
    if (!fieldRejections) {
      return ""; // No rejections
    }

    // Build comma-separated list of rejections codes from FB array
    let rejections = fieldRejections || [];
    rejections = rejections.sort((a, b) => (a > b ? 1 : -1));

    return _.trim(rejections.join(", "));
  }

  const f5 = getOriginalFieldById("F5");
  const f6 = getOriginalFieldById("F6");
  const f7 = getOriginalFieldById("F7");
  const f9 = getOriginalFieldById("F9");
  const an = getOriginalFieldById("AN");
  const rejections = getOriginalFieldRejections();
  const message = isFromTestScreen
    ? getOriginalFieldById("OldMessage")
    : getTestStringFieldById("OldMessage");

  const testF5Value = getTestFieldById("F5");
  const testF6Value = getTestFieldById("F6");
  const testF7Value = getTestFieldById("F7");
  const testF9Value = getTestFieldById("F9");
  const testANValue = getTestStringFieldById("AN");
  const testRejectionsValue = getTestFieldRejections();
  const testMessage = getTestStringFieldById("Message");

  return (
    <>
      <StyledTable className="table" style={{ marginTop: "10px" }}>
        <thead>
          <tr>
            <th style={{ width: "60px", position: "inherit" }}></th>
            <th style={{ width: "20%", position: "inherit" }}>Field</th>
            <th style={{ width: "40%", position: "inherit" }}>Description</th>
            <th style={{ width: "20%", position: "inherit" }}>
              Original Value
            </th>
            <th style={{ width: "20%", position: "inherit" }}>Test Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {f5 !== testF5Value ? (
                <span className="material-icons bad">warning</span>
              ) : (
                <span className="material-icons good">done</span>
              )}
            </td>
            <td>
              <b>F5</b>
            </td>
            <td>Patient Pay Amount</td>
            <td>{f5}</td>
            <td>{testF5Value}</td>
          </tr>
          <tr>
            <td>
              {f6 !== testF6Value ? (
                <span className="material-icons bad">warning</span>
              ) : (
                <span className="material-icons good">done</span>
              )}
            </td>
            <td>
              <b>F6</b>
            </td>
            <td>Ingredient Cost Paid</td>
            <td>{f6}</td>
            <td>{testF6Value}</td>
          </tr>
          <tr>
            <td>
              {f7 !== testF7Value ? (
                <span className="material-icons bad">warning</span>
              ) : (
                <span className="material-icons good">done</span>
              )}
            </td>
            <td>
              <b>F7</b>
            </td>
            <td>Dispensing Fee Paid</td>
            <td>{f7}</td>
            <td>{testF7Value}</td>
          </tr>
          <tr>
            <td>
              {f9 !== testF9Value ? (
                <span className="material-icons bad">warning</span>
              ) : (
                <span className="material-icons good">done</span>
              )}
            </td>
            <td>
              <b>F9</b>
            </td>
            <td>Total Amount Paid</td>
            <td>{f9}</td>
            <td>{testF9Value}</td>
          </tr>
          <tr>
            <td>
              {an !== testANValue ? (
                <span className="material-icons bad">warning</span>
              ) : (
                <span className="material-icons good">done</span>
              )}
            </td>
            <td>
              <b>AN</b>
            </td>
            <td>Transaction Response Status</td>
            <td>{an}</td>
            <td>{testANValue}</td>
          </tr>
          <tr>
            <td>
              {rejections !== testRejectionsValue ? (
                <span className="material-icons bad">warning</span>
              ) : (
                <span className="material-icons good">done</span>
              )}
            </td>
            <td colSpan="2">
              <b>Rejections</b>
            </td>
            <td>{rejections}</td>
            <td>{testRejectionsValue}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan="2">
              <b>Original Message</b>
            </td>
            <td colSpan="2">{message}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan="2">
              <b>Test Message</b>
            </td>
            <td colSpan="2">{testMessage}</td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
}

const StyledTable = styled.table`
  tbody > tr > td > span.material-icons.bad {
    color: var(--notify-danger);
  }
  tbody > tr > td > span.material-icons.good {
    font-weight: bold;
    color: var(--notify-success);
  }
`;

export default TestResultsTable;
