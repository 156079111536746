/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadDrugs(search, token, signal) {
  const uri = API_URL + "/drugs" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "ndc", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "ndc",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "productName",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.ndc && search.ndc.length > 0)
    where.push({ name: "ndc", operator: "=", values: [search.ndc] });
  if (search && search.productName && search.productName.length > 0)
    where.push({
      name: "productName",
      operator: "=",
      values: [search.productName],
    });
  if (search && search.gpi && search.gpi.length > 0)
    where.push({ name: "gpi.gpi", operator: "=", values: [search.gpi] });
  if (search && search.gppc && search.gppc.length > 0)
    where.push({ name: "gpi.gppc", operator: "=", values: [search.gppc] });
  if (
    search &&
    search.routeOfAdministration &&
    search.routeOfAdministration.length > 0
  )
    where.push({
      name: "routeOfAdministration",
      operator: "=",
      values: [search.routeOfAdministration],
    });
  if (search && search.rxOtcIndicator && search.rxOtcIndicator.length > 0)
    where.push({
      name: "rxOtcIndicator",
      operator: "=",
      values: [search.rxOtcIndicator],
    });
  if (search && search.multiSourceCode && search.multiSourceCode.length > 0)
    where.push({
      name: "multiSourceCode",
      operator: "=",
      values: [search.multiSourceCode],
    });

  if (search && search.active === true) {
    where.push({ name: "ndc", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "inactiveDate",
      operator: "isnull",
      values: [],
    });
    where[where.length - 1].subQueryItems.push({
      name: "inactiveDate",
      operand: "or",
      operator: "exact",
      values: ["0001-01-01T00:00:00"],
    });
  }

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadDrug(id, token, signal) {
  let uri = API_URL + "/drug/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddDrug(m, token, signal) {
  let uri = API_URL + "/drug" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateDrug(params, token, signal) {
  const id = params.id;
  const m = params.model;

  let uri = API_URL + "/drug/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteDrug(id, token, signal) {
  let uri = API_URL + "/drug/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCopyDrug(params, token, signal) {
  const id = params.id;
  const newId = params.newId;

  let uri = API_URL + "/drugcopy/" + id + "/" + newId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadDrugs.funcName = "apiLoadDrugs";
apiLoadDrug.funcName = "apiLoadDrug";
apiAddDrug.funcName = "apiAddDrug";
apiUpdateDrug.funcName = "apiUpdateDrug";
apiDeleteDrug.funcName = "apiDeleteDrug";
apiCopyDrug.funcName = "apiCopyDrug";
