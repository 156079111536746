import _ from "lodash";
import { pageSize } from "../constants/Environments";
import {
  formatDateOnly,
  ruleTableImportModeTypes,
  yyyy_mm_dd,
} from "../services/General";

// Calculate today's date at midnight for effective date
const minDate = new Date("01/01/2000");
minDate.setHours(0, 0, 0, 0);
const maxDate = new Date("01/01/3000");
maxDate.setHours(0, 0, 0, 0);
const today = new Date();
today.setHours(0, 0, 0, 0);

export const emptyRuleDataRow = {
  id: "",
  ruleTableId: "",
  keyHash: "",
  isInherited: false,
  sourceRuleId: "",
  sourceRuleName: "",
  inactiveDate: "",
  lastModifiedUserId: "",
  lastModifiedUserName: "",
  effectiveDate: minDate,
  effectiveDateString: "",
  terminationDate: maxDate,
  terminationDateString: "",
  isScheduleChange: false,
  values: [],
};

export const emptyRuleDataRowValue = {
  ruleTableColumnDefinitionId: "",
  value: "",
};

export const emptyRuleDataImport = {
  importMode: {
    label: "Overwrite existing data when keys match",
    value: "overwritechanges",
  }, // see ruleTableImportModeTypes in General.js
  importModeVm: "",
  terminationDate: today,
  fileContents: "",
};

export const emptyRuleDataSearch = {
  active: true,
  highlightText: "",
  pageSize: pageSize || 25,
  pageNumber: 1,
  colPageCount: 1,
  colPageNumber: 1,
  colPageSize: 5,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  // Convert dates back to the xString property equivalent since the api model expects this now.
  // jon, 6/28/24: These dates are now date-only so no need to convert.
  if (vm.effectiveDate === null) {
    vm.effectiveDate = yyyy_mm_dd(minDate);
    vm.effectiveDateString = yyyy_mm_dd(minDate);
  } else {
    vm.effectiveDateString = yyyy_mm_dd(vm.effectiveDate);
    vm.effectiveDate = yyyy_mm_dd(vm.effectiveDate);
  }

  if (vm.terminationDate === null) {
    vm.terminationDate = yyyy_mm_dd(maxDate);
    vm.terminationDateString = yyyy_mm_dd(maxDate);
  } else {
    vm.terminationDateString = yyyy_mm_dd(vm.terminationDate);
    vm.terminationDate = yyyy_mm_dd(vm.terminationDate);
  }

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  // This model sends dates in string ISO UTC format in the xString property. This is so manual and imported dates both
  //   get handled the same way and sent back to the client in the same format. Need to convert the string to the actual date prop.
  if (vm.effectiveDateString === "") {
    vm.effectiveDate = minDate;
  } else {
    vm.effectiveDate = new Date(formatDateOnly(vm.effectiveDateString));
  }

  if (vm.terminationDateString === "") {
    vm.terminationDate = maxDate;
  } else {
    vm.terminationDate = new Date(formatDateOnly(vm.terminationDateString));
  }

  return vm;
}

export function fromRuleDataImportViewModel(vm) {
  vm = _.cloneDeep(vm);

  // Dropdowns
  vm.importMode = (vm.importModeVm || { value: "" }).value;

  let m = vm;
  return m;
}

export function createRuleDataImportViewModel(m) {
  let vm = _.cloneDeep(m);

  // Dropdown values
  vm.importModeVm =
    (ruleTableImportModeTypes || []).find((c) => c.value === vm.importMode) ||
    emptyRuleDataImport.importMode;

  return vm;
}

export function getDropDownValue(model, name) {
  const value =
    model[`${name}Vm`] === ""
      ? model[`${name}`].value
      : model[`${name}Vm`].value;
  return value;
}
