import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { Modal } from "react-bootstrap";
import {
  disableAnimations,
  generateUUID,
  getIconFromAggregate,
  reportAggregateFunctions,
  reportCustomFieldDataTypes,
  reportDisplayFormats,
} from "../../../services/General";
import DragChip, { ChipMode } from "../../common/ui/DragChip";
import PlaceholderChip from "../../common/ui/PlaceholderChip";
import SelectInput from "../../common/input/SingleSelect";
import {
  createFieldViewModel,
  emptySelectedReportField,
  fromFieldViewModel,
} from "../../../viewmodels/reportsVm";
import TextInput from "../../common/input/TextInput";
import { notifyError, notifyWarn } from "../../../services/NotificationService";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import { StyledBlockLetter } from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";

function ReportSelectFieldChipsSection({
  title,
  fields,
  chips,
  setChips,
  error,
  disabled,
  onRemoveField,
  canAddCustomFields,
  dataSourceType,
}) {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [showModal, setShowModal] = useState(false);
  const [addField, setAddField] = useState(
    createFieldViewModel({ ...emptySelectedReportField }, dataSourceType)
  );
  const [chipMode, setChipMode] = useState(ChipMode.View);
  const [errors, setErrors] = useState({});

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(event, chips, setChips) {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id === over.id) {
      return;
    }

    const _chips = reorder(
      chips,
      chips.findIndex((c) => c.id === active.id),
      chips.findIndex((c) => c.id === over.id)
    );

    setChips(_chips);
  }

  function onAddFieldChanged(option) {
    let field = { ...addField };
    field.fieldId = option;
    field = setDisplayNameFromFieldAndAggregate(field);
    setAddField(field);
  }

  function onFormFieldChange({ target }) {
    let field = { ...addField, [target.name]: target.value };
    setAddField(field);
  }

  function onAggregateChanged(option) {
    let field = { ...addField };
    field.aggregateFunctionVm = option;
    field = setDisplayNameFromFieldAndAggregate(field);
    setAddField(field);
  }

  function onDisplayFormatDropChanged(option) {
    let field = { ...addField };
    field.displayFormatVm = option;
    field.displayFormat = option.value;
    setAddField(field);
  }

  function setDisplayNameFromFieldAndAggregate(field) {
    const fieldLabel = (field.fieldId || { originalLabel: "" }).originalLabel;

    let name = addField.isCustom ? field.fieldId : fieldLabel;
    const aggregate = (field.aggregateFunctionVm || { value: "None" }).value;

    if (aggregate && aggregate !== "None") {
      name += " " + aggregate;
    }

    field.fieldDisplayName = name;
    return field;
  }

  function closeSelectFieldDialog() {
    resetAddField();
    setShowModal(false);
  }

  function formIsValid() {
    const _errors = {};

    if (_.trim(addField.fieldDisplayName) === "")
      _errors.fieldDisplayName = "Display Name must be entered";

    if (addField.fieldId === "") {
      _errors.fieldId = "Field must be selected";
    }

    // Make sure another field with the same fieldDisplayName has not already been added.
    if (
      chips.findIndex(
        (c) =>
          c.fieldDisplayName === addField.fieldDisplayName &&
          c.id !== addField.id
      ) >= 0
    ) {
      _errors.fieldDisplayName =
        "This display name has already been added. Enter another.";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function resetAddField() {
    setErrors({});
    setChipMode(ChipMode.View);
    setAddField(
      createFieldViewModel({ ...emptySelectedReportField }, dataSourceType)
    );
  }

  function addFieldToChips() {
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    closeSelectFieldDialog();
    const _chips = [...chips];

    if (chipMode === ChipMode.Edit) {
      // Update chip and save it back to chips list
      const pos = _chips.findIndex((c) => c.id === addField.id);

      if (pos < 0) {
        notifyError(`A chip with id ${addField.id} was not found to save!`);
      } else {
        _chips.splice(pos, 1, fromFieldViewModel({ ...addField }));
      }
    } else {
      // Add field to the end of the chips list
      const field = fromFieldViewModel(addField);
      field.id = generateUUID();
      _chips.push(field);
    }

    setChips(_chips);
    resetAddField();
  }

  function handleRemoveField(id) {
    const _chips = [...chips];
    const index = _chips.findIndex((c) => c.id === id);
    const removedChip = _chips.splice(index, 1)[0];

    // Call parent to check if order/group by fields contain this field. If so, we will remove it there too.
    onRemoveField(_chips, removedChip.fieldDisplayName);

    // If all chips have been removed, automatically switch back to View mode.
    if (_chips.length === 0) {
      setChipMode(ChipMode.View);
    }
  }

  function handleEditField(id) {
    const _chips = [...chips];
    const chip = _chips.find((c) => c.id === id);
    if (!chip) {
      notifyError(`A chip with id ${id} was not found to edit!`);
      return;
    }

    const field = { ...chip };

    if (!field.isCustom) {
      const label =
        fields.find((f) => f.value === field.fieldId)?.label ||
        field.fieldDisplayName;
      // Non-custom fields need the selection option set into fieldId.
      field.fieldId = {
        value: field.fieldId,
        label: label,
      };
    }

    setChipMode(ChipMode.Edit);
    setAddField(createFieldViewModel(field, dataSourceType));
    setShowModal(true);
  }

  function onIsCustomFieldChange(value) {
    const field = { ...addField };
    field.isCustom = value === "Custom";
    field.customDataType = { value: "string", label: "String" };
    field.customDataTypeVm = "string";
    field.fieldId = "";
    field.fieldDisplayName = "";
    setAddField(field);
  }

  function onCustomFieldNameChange({ target }) {
    let field = { ...addField, [target.name]: target.value };
    field = setDisplayNameFromFieldAndAggregate(field);
    setAddField(field);
  }

  function onCustomDataTypeChanged(option) {
    let field = { ...addField };
    field.customDataTypeVm = option;
    setAddField(field);
  }

  return (
    <>
      <StyledSectionHeader>
        <h3>{title}</h3>
        <div
          style={{ display: "flex", alignItems: "center", columnGap: "10px" }}
        >
          <div className="d-none d-md-block">
            <StyledBlockLetter>C</StyledBlockLetter> = Custom Field
          </div>
        </div>
      </StyledSectionHeader>
      {disabled ? (
        <StyledChipContainer>
          {chips.map((chip, idx) => (
            <DragChip
              key={`${chip.id}-${idx}`}
              index={idx}
              id={chip.id}
              label={chip.fieldDisplayName}
              chipMode={ChipMode.Readonly}
              isCustomField={chip.isCustom}
              icon={getIconFromAggregate(chip.aggregateFunctionVm)}
            ></DragChip>
          ))}
        </StyledChipContainer>
      ) : (
        <DndContext
          autoScroll={false}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => onDragEnd(event, chips, setChips)}
        >
          <SortableContext items={chips} strategy={rectSortingStrategy}>
            <StyledChipContainer>
              {chips.map((chip, idx) => (
                <DragChip
                  key={`${chip.id}-${idx}`}
                  index={idx}
                  id={chip.id}
                  label={chip.fieldDisplayName}
                  chipMode={chipMode}
                  onClickRemove={handleRemoveField}
                  onClickEdit={handleEditField}
                  showSortDirection={false}
                  isCustomField={chip.isCustom}
                  icon={getIconFromAggregate(chip.aggregateFunctionVm)}
                ></DragChip>
              ))}
              {chipMode === ChipMode.View && (
                <PlaceholderChip
                  label="Add Field"
                  onClick={() => setShowModal(true)}
                ></PlaceholderChip>
              )}
            </StyledChipContainer>
          </SortableContext>
        </DndContext>
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <Modal
        show={showModal}
        onHide={closeSelectFieldDialog}
        animation={!disableAnimations()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {chipMode === ChipMode.Edit ? "Edit" : "Add"} Field
            <HelpLink
              path="/Reports/Report-Screen&anchor=field-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {canAddCustomFields && (
            <div style={{ marginBottom: "30px" }}>
              <ToggleButtonGroupInput
                id="isCustom"
                name="isCustom"
                label="Field Type"
                options={[
                  { value: "Source", label: "Source" },
                  { value: "Custom", label: "Custom" },
                ]}
                value={addField.isCustom ? "Custom" : "Source"}
                onChange={onIsCustomFieldChange}
                error={errors.isCustom}
              />
            </div>
          )}
          {addField.isCustom ? (
            <div style={{ marginBottom: "30px" }}>
              <TextInput
                id="fieldId"
                label="Custom Field"
                onChange={onCustomFieldNameChange}
                placeholder="Custom Field Name"
                name="fieldId"
                value={addField.fieldId}
                error={errors.fieldId}
              />
              <SelectInput
                id="customDataType"
                name="customDataType"
                label="Custom Field Data Type"
                options={[...reportCustomFieldDataTypes]}
                value={addField.customDataTypeVm}
                onChange={onCustomDataTypeChanged}
                placeholder="String"
                error={errors.customDataType}
              />
            </div>
          ) : (
            <div style={{ marginBottom: "30px" }}>
              <SelectInput
                id="addField"
                name="addField"
                label="Field"
                options={fields.map((field) => {
                  return {
                    key: field.value,
                    label: field.label,
                    originalLabel: field.propertyLabel,
                    value: field.value,
                    entityType: field.entityType,
                  };
                })}
                value={addField.fieldId}
                onChange={onAddFieldChanged}
                placeholder="(Select Field)"
                error={errors.fieldId}
              />
            </div>
          )}
          <div style={{ marginBottom: "30px" }}>
            <SelectInput
              id="aggregateFunction"
              name="aggregateFunction"
              label="Aggregate"
              options={[...reportAggregateFunctions]}
              value={addField.aggregateFunctionVm}
              onChange={onAggregateChanged}
              placeholder="(None)"
              error={errors.aggregateFunction}
            />
            <TextInput
              id="fieldDisplayName"
              label="Display Name"
              onChange={onFormFieldChange}
              placeholder="Display Name"
              name="fieldDisplayName"
              value={addField.fieldDisplayName}
              error={errors.fieldDisplayName}
            />
          </div>
          <SelectInput
            id="displayFormatDrop"
            name="displayFormatDrop"
            label="Display Format"
            options={[...reportDisplayFormats]}
            value={addField.displayFormatVm}
            onChange={onDisplayFormatDropChanged}
            placeholder="(Default)"
            error={errors.displayFormatDrop}
          />
          <TextInput
            id="displayFormat"
            label=""
            labelStyle={{ display: "none" }}
            onChange={onFormFieldChange}
            placeholder="Display format"
            name="displayFormat"
            value={addField.displayFormat}
            error={errors.displayFormat}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={addFieldToChips}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            {chipMode === ChipMode.Edit ? "Save" : "Add"} Field
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={closeSelectFieldDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const StyledSectionHeader = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  div,
  h3 {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  div {
    margin-left: auto;

    button {
      line-height: 31px;
    }
  }
`;

const StyledChipContainer = styled.div`
  padding: 10px;
  min-height: 58px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 8px;
`;

export default ReportSelectFieldChipsSection;
