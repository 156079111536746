import React, { useState, useEffect, useLayoutEffect } from "react";
import _ from "lodash";
import { useRules } from "../../../contexts/RulesContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { apiLoadGroupSummary } from "../../../api/RuleApi";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Spinner from "../../common/ui/Spinner";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import TextInput from "../../common/input/TextInput";
import {
  handleCollapseExpandAll,
  handleCollapseExpandSection,
  ruleSummaryDisplayTypes,
} from "../../../services/General";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";
import { notifyWarn } from "../../../services/NotificationService";
import styled from "styled-components";
import SelectInput from "../../common/input/SingleSelect";
import ToggleSwitchInput from "../../common/input/ToggleSwitchInput";
import RuleGroupSummaryRejections from "./RuleGroupSummaryRejections";
import RuleGroupSummaryResponseValues from "./RuleGroupSummaryResponseValues";
import RuleGroupSummaryRequestValues from "./RuleGroupSummaryRequestValues";
import RuleGroupSummaryGroupSettings from "./RuleGroupSummaryGroupSettings";
import RuleGroupSummaryProperties from "./RuleGroupSummaryProperties";
import { useMobile } from "../../../hooks/useMobile";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import useParser from "./RuleGroupSummaryParser";
import RuleGroupSummaryExcludedRejections from "./RuleGroupSummaryExcludedRejections";
import RuleGroupSummaryRules from "./RuleGroupSummaryRules";

function ReportGroupSummary() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { isMobileSize } = useMobile();
  const { rulesData, setRulesData } = useRules();
  const { loading, api: apiLoad } = useApi(apiLoadGroupSummary);
  const { parseTextContentIntoHtml } = useParser();
  const [changeGroupId, setChangeGroupId] = useState("");
  const [includeAllClaimsRule, setIncludeAllClaimsRule] = useState(true);
  const [textSearch, setTextSearch] = useState("");
  const [rejectionsSort, setRejectionsSort] = useState("message");
  const [rejections, setRejections] = useState([]);
  const [excludedRejectionsSort, setExcludedRejectionsSort] =
    useState("message");
  const [excludedRejections, setExcludedRejections] = useState([]);
  const [responseValues, setResponseValues] = useState([]);
  const [responseValuesSort, setResponseValuesSort] = useState("description");
  const [requestValues, setRequestValues] = useState([]);
  const [requestValuesSort, setRequestValuesSort] = useState("description");
  const [groupSettings, setGroupSettings] = useState([]);
  const [groupSettingsSort, setGroupSettingsSort] = useState("name");
  const [properties, setProperties] = useState([]);
  const [propertiesSort, setPropertiesSort] = useState("valueTypeDisplay");
  const [showUsedGroupSettings, setShowUsedGroupSettings] = useState(true);
  const [linkedProperty, setLinkedProperty] = useState("");
  const [linkedReturnToId, setLinkedReturnToId] = useState("");

  const [displayType, setDisplayType] = useState({
    label: "Basic View",
    value: "basic",
  });

  const [collapsedState, setCollapsedState] = useState([
    { name: "Group", collapsed: false },
    { name: "Rules", collapsed: false },
    { name: "Request Values", collapsed: true },
    { name: "Response Values", collapsed: true },
    { name: "Rejections", collapsed: true },
    { name: "Group Settings", collapsed: true },
    { name: "Properties", collapsed: true },
    { name: "Excluded Rejections", collapsed: true },
  ]);

  const ALL_CLAIMS_RULE_NAME = "Run processing for all claims";
  const SEARCH_KEYS_REQUESTVALUES = ["key", "description"];
  const SEARCH_KEYS_RESPONSEVALUES = [
    "key",
    "description",
    "friendlyCondition",
    "value",
  ];
  const SEARCH_KEYS_REJECTIONS = ["code", "message", "friendlyCondition"];
  const SEARCH_KEYS_EXCLUDEDREJECTIONS = [
    "code",
    "message",
    "friendlyCondition",
  ];
  const SEARCH_KEYS_GROUPSETTINGS = ["name", "value"];
  const SEARCH_KEYS_PROPERTIES = ["name", "value"];

  const summary = (rulesData && rulesData.groupSummary) || {};
  const groupId =
    (rulesData && rulesData.search.groupId) || (params && params.id) || "";

  const displayTypes = ruleSummaryDisplayTypes.filter(
    (d) => auth.isAdmin || (!auth.isAdmin && d.label.indexOf("Admin") < 0)
  );

  useEffect(() => {
    if (auth.authenticated && params && params.id) {
      setChangeGroupId(groupId);
      loadGroupSummary();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (rulesData && rulesData.groupSummary) {
      sortFilterAllSections(includeAllClaimsRule, textSearch);
    }
  }, [rulesData?.groupSummary]);

  // Scroll to selected group setting or property
  useLayoutEffect(() => {
    if (!_.isEmpty(linkedProperty)) {
      scrollToItem(linkedProperty);

      window.setTimeout(() => {
        setLinkedProperty("");
        setLinkedReturnToId("");
      }, 30000);
    }
  }, [linkedProperty]);

  async function loadGroupSummary() {
    apiLoad.call(groupId, async (result) => {
      setRulesData({
        type: ContextProviderActions.loadRuleGroupSummary,
        payload: result,
      });
    });
  }

  function setSearchChanges(search) {
    setRulesData({
      type: ContextProviderActions.saveRuleSearch,
      payload: search,
    });
  }

  function handleGroupIdChange({ target }) {
    setChangeGroupId(target.value);
  }

  function handleDisplayTypeChanged(option) {
    setDisplayType(option);
  }

  function setSortedFilteredItems(
    array,
    setArray,
    keys,
    includeAllClaims,
    sort,
    secondarySort,
    searchTerm,
    additionalFilterCallback = null
  ) {
    let _array = array || [];

    // Filter the array according to the All Claims toggle
    if (!includeAllClaims) {
      _array = _array.filter((r) => r.ruleName !== ALL_CLAIMS_RULE_NAME);
    }

    if (!_.isEmpty(searchTerm)) {
      _array = _array.filter((d) => {
        return (
          keys.filter((k) => {
            if (_.isEmpty(d[k])) return false;
            return d[k]
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          }).length > 0
        );
      });
    }

    // Call section-specific callback to further filter array if defined
    if (additionalFilterCallback !== null) {
      _array = additionalFilterCallback(_array);
    }

    // Sort the array according to sort selection
    _array = _array.sort((a, b) => {
      const a1 = a[sort] || "";
      const a2 = a[secondarySort] || "";
      const b1 = b[sort] || "";
      const b2 = b[secondarySort] || "";

      if (a1 === b1) {
        return _.lowerCase(a2) > _.lowerCase(b2)
          ? 1
          : _.lowerCase(a2) < _.lowerCase(b2)
          ? -1
          : 0;
      } else {
        return _.lowerCase(a1) > _.lowerCase(b1) ? 1 : -1;
      }
    });

    setArray(_array);
  }

  function setSortedFilteredRejections(includeAllClaims, searchTerm, sort) {
    setSortedFilteredItems(
      summary?.rejections,
      setRejections,
      SEARCH_KEYS_REJECTIONS,
      includeAllClaims,
      sort,
      "message",
      searchTerm
    );
  }

  function setSortedFilteredExcludedRejections(
    includeAllClaims,
    searchTerm,
    sort
  ) {
    setSortedFilteredItems(
      summary?.excludedRejections,
      setExcludedRejections,
      SEARCH_KEYS_EXCLUDEDREJECTIONS,
      includeAllClaims,
      sort,
      "message",
      searchTerm
    );
  }

  function setSortedFilteredResponseValues(includeAllClaims, searchTerm, sort) {
    setSortedFilteredItems(
      summary?.responseFields,
      setResponseValues,
      SEARCH_KEYS_RESPONSEVALUES,
      includeAllClaims,
      sort,
      "description",
      searchTerm
    );
  }

  function setSortedFilteredRequestValues(includeAllClaims, searchTerm, sort) {
    setSortedFilteredItems(
      summary?.requestFields,
      setRequestValues,
      SEARCH_KEYS_REQUESTVALUES,
      includeAllClaims,
      sort,
      "description",
      searchTerm
    );
  }

  function setSortedFilteredGroupSettings(
    includeAllClaims,
    searchTerm,
    sort,
    showUsedSettings = showUsedGroupSettings
  ) {
    setSortedFilteredItems(
      summary?.groupSettings,
      setGroupSettings,
      SEARCH_KEYS_GROUPSETTINGS,
      includeAllClaims,
      sort,
      "name",
      searchTerm,
      (_array) => {
        if (showUsedSettings) {
          return _array.filter((r) => r.isUsed === true);
        } else return _array;
      }
    );
  }

  function setSortedFilteredProperties(includeAllClaims, searchTerm, sort) {
    setSortedFilteredItems(
      auth.isAdmin ? summary?.properties : [],
      setProperties,
      SEARCH_KEYS_PROPERTIES,
      includeAllClaims,
      sort,
      sort === "name" ? "valueType" : "name",
      searchTerm
    );
  }

  function handleIncludeAllClaimsRuleChange() {
    const newValue = !includeAllClaimsRule;
    setIncludeAllClaimsRule(newValue, textSearch);
    sortFilterAllSections(newValue, textSearch);
  }

  function sortFilterAllSections(includeAllClaims, searchTerm) {
    setSortedFilteredRejections(includeAllClaims, searchTerm, rejectionsSort);
    setSortedFilteredExcludedRejections(
      includeAllClaims,
      searchTerm,
      excludedRejectionsSort
    );
    setSortedFilteredResponseValues(
      includeAllClaims,
      searchTerm,
      responseValuesSort
    );
    setSortedFilteredRequestValues(
      includeAllClaims,
      searchTerm,
      requestValuesSort
    );
    setSortedFilteredGroupSettings(
      includeAllClaims,
      searchTerm,
      groupSettingsSort
    );
    setSortedFilteredProperties(includeAllClaims, searchTerm, propertiesSort);
  }

  function handleChangeGroup(event) {
    if (event) event.preventDefault();

    const groupNumber = _.trim(changeGroupId);

    if (_.isEmpty(groupNumber)) {
      notifyWarn("You must enter a Group Id before selecting this option");
    } else {
      setSearchChanges({ ...rulesData.search, groupId: groupNumber });
      navigate("/groupsummary/" + groupNumber);
    }
  }

  function getFormattedCondition(id, description) {
    if (_.isEmpty(description)) return "";
    const isAdminView =
      displayType.value === "admin" || displayType.value === "advanced";

    const tokens = parseTextContentIntoHtml(
      id,
      description,
      auth.isAdmin,
      isAdminView,
      handlePropertyLinkClick,
      properties,
      groupSettings,
      requestValues
    );
    const tokenHtml = tokens.map((token, idx) => {
      return <span key={`t-${idx}`}>{token}</span>;
    });

    return tokenHtml;
  }

  function handlePropertyLinkClick(event, propType, propName, returnToId = "") {
    if (event) event.preventDefault();

    const section =
      propType === "gs"
        ? "Group Settings"
        : propType === "prop"
        ? "Properties"
        : "Request Values";
    handleCollapseExpandSection(
      section,
      false,
      collapsedState,
      setCollapsedState
    );

    const linkProp = _.lowerCase(`${propType}-${propName}`);
    setLinkedReturnToId(returnToId);
    setLinkedProperty(linkProp);
  }

  function handleClickReturn() {
    if (!_.isEmpty(linkedReturnToId)) {
      scrollToItem(linkedReturnToId);
      setLinkedProperty("");
      setLinkedReturnToId("");
    }
  }

  function scrollIntoView(element) {
    window.setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 250);
  }

  function scrollToItem(id) {
    let element = document.getElementById(id);
    if (element) {
      scrollIntoView(element);
      return;
    }

    notifyWarn(`An item with id ${id} was not found!`);
  }

  function handleCollapseExpandAllSectionsExceptGroup(collapse) {
    const newCollapsedState = [...collapsedState];
    newCollapsedState
      .filter((s) => s.name !== "Group")
      .forEach((section) => (section.collapsed = collapse));
    setCollapsedState(newCollapsedState);
  }

  function handleInstantSearch(t) {
    setTextSearch(t);
    sortFilterAllSections(includeAllClaimsRule, t);

    // When user is searching, expand all by default. When search is cleared, collapse all but the Group section.
    handleCollapseExpandAllSectionsExceptGroup(_.isEmpty(t));
  }

  return (
    <Authorize>
      <StyledBackButtonDiv>
        <button
          title="Return to previous screen"
          type="button"
          className="btn btn-link"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink
            path="/Processing-Rules/Rule-Group-Summary-Screen"
            label="Help"
          />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1>{`Rule Summary for Group ${groupId === "" ? "" : groupId}`}</h1>

        <StyledHeaderRowButtonDiv>
          <div className="flex-row-with-wrap" style={{ width: "225px" }}>
            <label style={{ display: isMobileSize ? "none" : "inline" }}>
              View
            </label>
            <SelectInput
              id="displayType"
              name="displayType"
              label=""
              labelStyle={{ display: "none" }}
              options={[...displayTypes]}
              value={displayType}
              onChange={handleDisplayTypeChanged}
              placeholder="Select view"
              controlStyle={{
                width: "180px",
                margin: isMobileSize ? "-17px 0 0 0" : "-17px 0 0 8px",
              }}
            />
          </div>
          {auth.isAdmin && groupId !== "" && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                navigate("/grouprule/" + groupId);
              }}
              style={{ marginLeft: isMobileSize ? "0" : "10px" }}
            >
              Group Settings
            </button>
          )}
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>

      {loading ? (
        <Spinner />
      ) : (
        <>
          <ActionMenu
            title="Actions"
            items={[
              { value: "ExpandAll", label: "Expand All" },
              { value: "CollapseAll", label: "Collapse All" },
            ]}
            onSelectAction={(value, label) =>
              handleCollapseExpandAll(
                value === "CollapseAll",
                collapsedState,
                setCollapsedState
              )
            }
          />
          <div className="container-fluid" style={{ marginTop: "5px" }}>
            <ExpandCollapseDetailSection
              sectionTitle="Group"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Processing-Rules/Rule-Group-Summary-Screen&anchor=group-section"
            >
              <StyledRowDiv
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <StyledSearchBar>
                  <form onSubmit={handleChangeGroup}>
                    <TextInput
                      id="groupId"
                      label="Group Id"
                      labelStyle={{ display: "none" }}
                      onChange={handleGroupIdChange}
                      placeholder="Group Id"
                      name="groupId"
                      value={changeGroupId}
                      isSearch={true}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ width: "80px", maxWidth: "80px" }}
                    >
                      Change
                    </button>
                  </form>
                </StyledSearchBar>
                <div style={{ width: "250px", marginTop: "13px" }}>
                  <ToggleSwitchInput
                    id="includeAllClaimsRule"
                    name="includeAllClaimsRule"
                    label="Include 'All Claims' rule"
                    onChange={handleIncludeAllClaimsRuleChange}
                    checked={includeAllClaimsRule}
                  />
                </div>
                <div style={{ width: "250px", marginTop: "13px" }}>
                  <InstantSearchInput
                    id="textSearch"
                    onChange={handleInstantSearch}
                    value={textSearch}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
            {groupId !== "" && (
              <>
                <ExpandCollapseDetailSection
                  sectionTitle="Rules"
                  subTitle={`(${summary?.rules.length})`}
                  filtered={false}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Group-Summary-Screen&anchor=rules-section"
                >
                  <RuleGroupSummaryRules items={summary?.rules} />
                </ExpandCollapseDetailSection>
                <ExpandCollapseDetailSection
                  sectionTitle="Request Values"
                  subTitle={`(${requestValues.length})`}
                  filtered={!_.isEmpty(textSearch)}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Group-Summary-Screen&anchor=request-values-section"
                >
                  <RuleGroupSummaryRequestValues
                    items={requestValues}
                    artifacts={summary?.artifacts || []}
                    sort={requestValuesSort}
                    setSort={setRequestValuesSort}
                    displayValue={displayType.value}
                    includeAllClaimsRule={includeAllClaimsRule}
                    setSortedFilteredItems={(includeAllClaims, sort) =>
                      setSortedFilteredRequestValues(
                        includeAllClaims,
                        textSearch,
                        sort
                      )
                    }
                    linkedProperty={linkedProperty}
                    onClickReturn={handleClickReturn}
                  />
                </ExpandCollapseDetailSection>
                <ExpandCollapseDetailSection
                  sectionTitle="Response Values"
                  subTitle={`(${responseValues.length})`}
                  filtered={!_.isEmpty(textSearch)}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Group-Summary-Screen&anchor=response-values-section"
                >
                  <RuleGroupSummaryResponseValues
                    items={responseValues}
                    artifacts={summary?.artifacts || []}
                    sort={responseValuesSort}
                    setSort={setResponseValuesSort}
                    displayValue={displayType.value}
                    includeAllClaimsRule={includeAllClaimsRule}
                    setSortedFilteredItems={(includeAllClaims, sort) =>
                      setSortedFilteredResponseValues(
                        includeAllClaims,
                        textSearch,
                        sort
                      )
                    }
                    getFormattedCondition={getFormattedCondition}
                  />
                </ExpandCollapseDetailSection>
                <ExpandCollapseDetailSection
                  sectionTitle="Rejections"
                  subTitle={`(${rejections.length})`}
                  filtered={!_.isEmpty(textSearch)}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Group-Summary-Screen&anchor=rejections-section"
                >
                  <RuleGroupSummaryRejections
                    items={rejections}
                    artifacts={summary?.artifacts || []}
                    sort={rejectionsSort}
                    setSort={setRejectionsSort}
                    displayValue={displayType.value}
                    includeAllClaimsRule={includeAllClaimsRule}
                    setSortedFilteredItems={(includeAllClaims, sort) =>
                      setSortedFilteredRejections(
                        includeAllClaims,
                        textSearch,
                        sort
                      )
                    }
                    getFormattedCondition={getFormattedCondition}
                  />
                </ExpandCollapseDetailSection>
                <ExpandCollapseDetailSection
                  sectionTitle="Group Settings"
                  subTitle={`(${groupSettings.length})`}
                  filtered={!_.isEmpty(textSearch)}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Group-Summary-Screen&anchor=group-settings-section"
                >
                  <RuleGroupSummaryGroupSettings
                    items={groupSettings}
                    artifacts={summary?.artifacts || []}
                    sort={groupSettingsSort}
                    setSort={setGroupSettingsSort}
                    displayValue={displayType.value}
                    includeAllClaimsRule={includeAllClaimsRule}
                    setSortedFilteredItems={(
                      includeAllClaims,
                      sort,
                      showUsedSettings
                    ) =>
                      setSortedFilteredGroupSettings(
                        includeAllClaims,
                        textSearch,
                        sort,
                        showUsedSettings
                      )
                    }
                    showUsedSettings={showUsedGroupSettings}
                    setShowUsedSettings={setShowUsedGroupSettings}
                    linkedProperty={linkedProperty}
                    onClickReturn={handleClickReturn}
                  />
                </ExpandCollapseDetailSection>

                <ExpandCollapseDetailSection
                  sectionTitle="Properties"
                  subTitle={`(${properties.length})`}
                  filtered={!_.isEmpty(textSearch)}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Group-Summary-Screen&anchor=properties-section"
                  adminOnly={true}
                >
                  <RuleGroupSummaryProperties
                    items={properties}
                    artifacts={summary?.artifacts || []}
                    isAdmin={auth.isAdmin}
                    sort={propertiesSort}
                    setSort={setPropertiesSort}
                    displayValue={displayType.value}
                    includeAllClaimsRule={includeAllClaimsRule}
                    setSortedFilteredItems={(includeAllClaims, sort) =>
                      setSortedFilteredProperties(
                        includeAllClaims,
                        textSearch,
                        sort
                      )
                    }
                    linkedProperty={linkedProperty}
                    onClickReturn={handleClickReturn}
                  />
                </ExpandCollapseDetailSection>

                <ExpandCollapseDetailSection
                  sectionTitle="Excluded Rejections"
                  subTitle={`(${excludedRejections.length})`}
                  filtered={!_.isEmpty(textSearch)}
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Group-Summary-Screen&anchor=excluded-rejections-section"
                  adminOnly={true}
                >
                  <RuleGroupSummaryExcludedRejections
                    items={excludedRejections}
                    artifacts={summary?.artifacts || []}
                    sort={excludedRejectionsSort}
                    setSort={setExcludedRejectionsSort}
                    displayValue={displayType.value}
                    includeAllClaimsRule={includeAllClaimsRule}
                    setSortedFilteredItems={(includeAllClaims, sort) =>
                      setSortedFilteredExcludedRejections(
                        includeAllClaims,
                        textSearch,
                        sort
                      )
                    }
                    getFormattedCondition={getFormattedCondition}
                  />
                </ExpandCollapseDetailSection>
              </>
            )}
          </div>
        </>
      )}
    </Authorize>
  );
}

const StyledSearchBar = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    margin-top: 10px;
  }

  div.form-group {
    margin-top: 0;

    div.field {
      margin-top: 0;
    }
  }

  input {
    width: 200px;
    min-width: 200px;
    max-width: 320px;
    height: 36px;
  }

  button {
    width: 100px;
    min-width: 100px;
    margin-left: 10px;
  }
`;

export default ReportGroupSummary;
