import _ from "lodash";

export const emptySocketServerConfigProperties = {
  localServerIpAddress: "0.0.0.0",
  localServerPort: 0,
  stateContainer: "",
  connectionRefreshMilliseconds: 0,
};

export const emptySocketServerConfig = {
  isStarted: false,
  serverMode: "",
  stateManager: "",
  configCacheMaxAgeSeconds: 0,
  properties: { ...emptySocketServerConfigProperties },
  routingPlans: [], // emptySocketServerRoutingPlan
  consumers: [], // emptySocketServerConsumer
};

export const emptySocketServerRoutingPlanConsumer = {
  name: "",
  isPrimary: false,
  timeout: 20000,
};

export const emptySocketServerRoutingPlan = {
  id: "",
  name: "",
  recognizer: "",
  handler: "",
  stateManager: "",
  consumers: [], // emptySocketServerRoutingPlanConsumer
  properties: "", // This will be just raw json for now since properties can be completely different for each routing plan
  errorConsumer: "",
};

export const emptySocketServerConsumer = {
  id: "",
  name: "",
  singleton: false,
  timeout: 10000,
  properties: "", // This will be just raw json for now since properties can be completely different for each consumer
};

function formatJson(jsonStr) {
  try {
    var jsonObj = JSON.parse(jsonStr);
    var prettyJson = JSON.stringify(jsonObj, undefined, 2);
    return prettyJson;
  } catch (e) {
    return "Invalid JSON: " + jsonStr;
  }
}

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;

  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  // Pretty print json properties
  (vm.consumers || []).forEach(
    (c) => (c.properties = formatJson(c.properties))
  );

  (vm.routingPlans || []).forEach(
    (c) => (c.properties = formatJson(c.properties))
  );

  return vm;
}
