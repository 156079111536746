import React, { useEffect, useState } from "react";

import Chain from "./screens/chains/Chain";
import Chains from "./screens/chains/Chains";
import Claim from "./screens/claims/Claim";
import Claims from "./screens/claims/Claims";
import Client from "./screens/clients/Client";
import Clients from "./screens/clients/Clients";
import Diagnostics from "./screens/home/Diagnostics";
import Discount from "./screens/discounts/Discount";
import Discounts from "./screens/discounts/Discounts";
import Drug from "./screens/drugs/Drug";
import Drugs from "./screens/drugs/Drugs";
import Group from "./screens/groups/Group";
import Groups from "./screens/groups/Groups";
import Log from "./screens/logs/Log";
import Logs from "./screens/logs/Logs";
import Member from "./screens/members/Member";
import Members from "./screens/members/Members";
import PacketParser from "./screens/packetparser/PacketParser";
import Prescriber from "./screens/prescribers/Prescriber";
import Prescribers from "./screens/prescribers/Prescribers";
import PriorAuthorization from "./screens/priorauthorizations/PriorAuthorization";
import PriorAuthorizations from "./screens/priorauthorizations/PriorAuthorizations";
import Rule from "./screens/rules/Rule";
import RuleGroupSummary from "./screens/rules/RuleGroupSummary";
import Rules from "./screens/rules/Rules";
import ServiceProvider from "./screens/serviceproviders/ServiceProvider";
import ServiceProviders from "./screens/serviceproviders/ServiceProviders";
import TestProcessor from "./screens/testprocessor/TestProcessor";

import { BrowserRouter, Route } from "react-router-dom";
import NotFoundPage from "./NotFoundPage";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import BlankPage from "./BlankPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import DiagnosticsProvider from "../contexts/DiagnosticsContext";
import ChainsProvider from "../contexts/ChainsContext";
import ClientsProvider from "../contexts/ClientsContext";
import GroupsProvider from "../contexts/GroupsContext";
import DiscountsProvider from "../contexts/DiscountsContext";
import DrugsProvider from "../contexts/DrugsContext";
import MembersProvider from "../contexts/MembersContext";
import PrescribersProvider from "../contexts/PrescribersContext";
import ServiceProvidersProvider from "../contexts/ServiceProvidersContext";
import PriorAuthorizationsProvider from "../contexts/PriorAuthorizationsContext";
import ClaimsProvider from "../contexts/ClaimsContext";
import PacketParserProvider from "../contexts/PacketParserContext";
import TestProcessorProvider from "../contexts/TestProcessorContext";
import LogsProvider from "../contexts/LogsContext";
import RulesProvider from "../contexts/RulesContext";
import LoginPage from "./LoginPage";
import AppContent from "./AppContent";
import Report from "./screens/reports/Report";
import Reports from "./screens/reports/Reports";
import RunReport from "./screens/reports/RunReport";
import ReportsProvider from "../contexts/ReportsContext";
import RuleTables from "./screens/ruletables/RuleTables";
import RuleTable from "./screens/ruletables/RuleTable";
import RuleTableDefinitions from "./screens/ruletabledefs/RuleTableDefinitions";
import RuleTableDefinition from "./screens/ruletabledefs/RuleTableDefinition";
import RuleTableDefinitionsProvider from "../contexts/RuleTableDefinitionsContext";
import RuleTablesProvider from "../contexts/RuleTablesContext";
import AuditRecords from "./screens/audit/AuditRecords";
import AuditRecord from "./screens/audit/AuditRecord";
import AuditBulkChangeRecords from "./screens/audit/AuditBulkChangeRecords";
import AuditProvider from "../contexts/AuditContext";
import ImportData from "./screens/import/ImportData";
import { useAuthProvider } from "../hooks/useAuthProvider";
import { isAdmin, isBasicUser, isParserUser } from "../services/General";
import CacheProvider from "../contexts/CacheContext";
import CacheStatus from "./screens/cache/CacheStatus";
import Test from "./screens/tests/Test";
import TestGroup from "./screens/testgroups/TestGroup";
import Tests from "./screens/tests/Tests";
import TestGroups from "./screens/testgroups/TestGroups";
import TestRunResults from "./screens/testresults/TestRunResults";
import TestGroupResults from "./screens/testresults/TestGroupResults";
import TestResults from "./screens/testresults/TestResults";
import TestsProvider from "../contexts/TestsContext";
import TestGroupsProvider from "../contexts/TestGroupsContext";
import TestResultsProvider from "../contexts/TestResultsContext";
import ClaimTemplateTest from "./screens/claims/ClaimTemplateTest";
import ReportTemplates from "./screens/reporttemplates/ReportTemplates";
import ReportTemplate from "./screens/reporttemplates/ReportTemplate";
import ReportTemplatesProvider from "../contexts/ReportTemplatesContext";
import HomePage from "./screens/home/HomePage";
import ReportProcess from "./screens/reportprocesses/ReportProcess";
import ReportProcesses from "./screens/reportprocesses/ReportProcesses";
import ProcessRunHistory from "./screens/reportprocesses/ProcessRunHistory";
import ReportProcessesProvider from "../contexts/ReportProcessesContext";
import ProcessHistoryProvider from "../contexts/ProcessHistoryContext";
import ProcessRunHistoryRecord from "./screens/reportprocesses/ProcessRunHistoryRecord";
import TestCoverageTrackingProvider from "../contexts/TestCoverageTrackingContext";
import DbDrugUpdateProvider from "../contexts/dbDrugUpdateContext";
import DbDrugUpdate from "./screens/databaseutilities/DbDrugUpdate";
import DbLegacyDbSyncProvider from "../contexts/dbLegacyDbSyncContext";
import DbLegacyDbSync from "./screens/databaseutilities/DbLegacyDbSync";
import DbDatabaseSyncProvider from "../contexts/dbDatabaseSyncContext";
import DbDatabaseSync from "./screens/databaseutilities/DbDatabaseSync";
import SocketServerConfig from "./screens/socketserver/SocketServerConfig";
import SocketServerConfigProvider from "../contexts/SocketServerConfigContext";
import PacketTrafficProvider from "../contexts/PacketTrafficContext";
import WizardHome from "./screens/wizard/WizardHome";
import WizardSetMemberDOB from "./screens/wizard/setmemberdob/WizardSetMemberDOB";
import HbDetectorConfigProvider from "../contexts/HbDetectorConfigContext";
import HbDetectorConfig from "./screens/hbdetector/HbDetectorConfig";
import TestDataPopulatorConfig from "./screens/testdatapopulator/TestDataPopulatorConfig";
import TestDataPopulatorConfigProvider from "../contexts/TestDataPopulatorConfigContext";
import TestRunnerConfig from "./screens/testrunner/TestRunnerConfig";
import TestRunnerConfigProvider from "../contexts/TestRunnerConfigContext";
import WebSocketConnectionProvider from "../contexts/WebSocketEventsContext";
import WizardCreateTest from "./screens/wizard/createtest/WizardCreateTest";
import ClaimRuleMap from "./screens/rulemaps/ClaimRuleMap";
import RuleMapsProvider from "../contexts/RuleMapsContext";
import GroupRuleMapsProvider from "../contexts/GroupRuleMapsContext";
import GroupRuleMaps from "./screens/grouprulemaps/GroupRuleMaps";
import GroupRuleMap from "./screens/grouprulemaps/GroupRuleMap";

export const ConfigContext = React.createContext();

const configValue = {};

function App() {
  const { token, authenticating, isAuthenticated, userName } =
    useAuthProvider();
  const [routes, setRoutes] = useState([
    <Route key="*" path="*" exact element={<BlankPage />} />,
  ]);

  useEffect(() => {
    if (token) {
      // console.log(`Login and token fetch complete. Loading app...`);
      initialLoad();
    }
  }, [token]);

  function initialLoad() {
    const admin = isAdmin(token);
    const basicUser = isBasicUser(token);
    const parserUser = isParserUser(token);

    let addRoutes = [];
    if (admin) {
      addRoutes = addRoutes.concat([
        <Route
          key="/packetparser/:id"
          path="/packetparser/:id"
          element={<PacketParser />}
        />,
        <Route
          key="/priorauthorization/:id"
          path="/priorauthorization/:id"
          element={<PriorAuthorization />}
        />,
        <Route
          key="/priorauthorization"
          path="/priorauthorization"
          element={<PriorAuthorization />}
        />,
        <Route
          key="/discount/:claimid/:id"
          path="/discount/:claimid/:id"
          element={<Discount />}
        />,
        <Route
          key="/discount/:id"
          path="/discount/:id"
          element={<Discount />}
        />,
        <Route key="/discount" path="/discount" element={<Discount />} />,
        <Route
          key="/priorauthorizationclaim/:rx/:sp/:rej"
          path="/priorauthorizationclaim/:rx/:sp/:rej"
          element={<PriorAuthorization />}
        />,
        <Route
          key="/priorauthorizationclaim/:rx/:sp"
          path="/priorauthorizationclaim/:rx/:sp"
          element={<PriorAuthorization />}
        />,
        <Route key="/rule/:id" path="/rule/:id" element={<Rule />} />,
        <Route key="/rule" path="/rule" element={<Rule />} />,
        <Route key="/log/:id" path="/log/:id" element={<Log />} />,
        <Route key="/logs" path="/logs" element={<Logs />} />,
        <Route
          key="/processingrules"
          path="/processingrules"
          element={<Rules />}
        />,
        <Route key="/grouprule/:id" path="/grouprule/:id" element={<Rule />} />,
        <Route
          key="/claimrulemap/:id"
          path="/claimrulemap/:id"
          element={<ClaimRuleMap />}
        />,
        <Route
          key="/grouprulemaps"
          path="/grouprulemaps"
          element={<GroupRuleMaps />}
        />,
        <Route
          key="/grouprulemap/:groupId"
          path="/grouprulemap/:groupId"
          element={<GroupRuleMap />}
        />,
        <Route
          key="/ruletables/"
          path="/ruletables/"
          element={<RuleTables />}
        />,
        <Route
          key="/ruletable/:id"
          path="/ruletable/:id"
          element={<RuleTable />}
        />,
        <Route key="/ruletable" path="/ruletable" element={<RuleTable />} />,
        <Route
          key="/ruletabledefinitions"
          path="/ruletabledefinitions"
          element={<RuleTableDefinitions />}
        />,
        <Route
          key="/ruletabledefinition/:id"
          path="/ruletabledefinition/:id"
          element={<RuleTableDefinition />}
        />,
        <Route
          key="/ruletabledefinition"
          path="/ruletabledefinition"
          element={<RuleTableDefinition />}
        />,
        <Route
          key="/testprocessor"
          path="/testprocessor"
          element={<TestProcessor />}
        />,
        <Route
          key="/testprocessor/:id"
          path="/testprocessor/:id"
          element={<TestProcessor />}
        />,
        <Route
          key="/auditrecords"
          path="/auditrecords"
          element={<AuditRecords />}
        />,
        <Route
          key="/auditrecord/:entity/:id/:transactionId"
          path="/auditrecord/:entity/:id/:transactionId"
          element={<AuditRecord />}
        />,
        <Route key="/importdata" path="/importdata" element={<ImportData />} />,
        <Route
          key="/auditbulkrecords/:id"
          path="/auditbulkrecords/:id"
          element={<AuditBulkChangeRecords />}
        />,
        <Route
          key="/cachestatus"
          path="/cachestatus"
          element={<CacheStatus />}
        />,
        <Route
          key="/configuregroups"
          path="/configuregroups"
          element={<Groups />}
        />,
        <Route
          key="/configurechains"
          path="/configurechains"
          element={<Chains />}
        />,
        <Route
          key="/getdiscounts/:claimid"
          path="/getdiscounts/:claimid"
          element={<Discounts />}
        />,
        <Route
          key="/configurediscounts"
          path="/configurediscounts"
          element={<Discounts />}
        />,
        <Route
          key="/configurepriorauthorizations"
          path="/configurepriorauthorizations"
          element={<PriorAuthorizations />}
        />,
        <Route
          key="/configureprescribers"
          path="/configureprescribers"
          element={<Prescribers />}
        />,
        <Route
          key="/configureserviceproviders"
          path="/configureserviceproviders"
          element={<ServiceProviders />}
        />,
        <Route
          key="/configureclients"
          path="/configureclients"
          element={<Clients />}
        />,
        <Route
          key="/configuredrugs"
          path="/configuredrugs"
          element={<Drugs />}
        />,
        <Route
          key="/configuremembers"
          path="/configuremembers"
          element={<Members />}
        />,
        <Route
          key="/reporttemplates"
          path="/reporttemplates"
          element={<ReportTemplates />}
        />,
        <Route
          key="/reporttemplate/:id"
          path="/reporttemplate/:id"
          element={<ReportTemplate />}
        />,
        <Route
          key="/reporttemplate"
          path="/reporttemplate"
          element={<ReportTemplate />}
        />,
        <Route
          key="/reportprocess/:id"
          path="/reportprocess/:id"
          element={<ReportProcess />}
        />,
        <Route
          key="/reportprocess"
          path="/reportprocess"
          element={<ReportProcess />}
        />,
        <Route
          key="/reportprocesses"
          path="/reportprocesses"
          element={<ReportProcesses />}
        />,
        <Route
          key="/processhistory"
          path="/processhistory"
          element={<ProcessRunHistory />}
        />,
        <Route
          key="/processhistory/:id"
          path="/processhistory/:id"
          element={<ProcessRunHistoryRecord />}
        />,
        <Route
          key="/prescriber/:id"
          path="/prescriber/:id"
          element={<Prescriber />}
        />,
        <Route key="/prescriber" path="/prescriber" element={<Prescriber />} />,
        <Route
          key="/serviceprovider/:id"
          path="/serviceprovider/:id"
          element={<ServiceProvider />}
        />,
        <Route
          key="/serviceprovider"
          path="/serviceprovider"
          element={<ServiceProvider />}
        />,
        <Route key="/drug/:id" path="/drug/:id" element={<Drug />} />,
        <Route key="/drug" path="/drug" element={<Drug />} />,
        <Route key="/chain/:id" path="/chain/:id" element={<Chain />} />,
        <Route key="/chain" path="/chain" element={<Chain />} />,
        <Route key="/client/:id" path="/client/:id" element={<Client />} />,
        <Route key="/client" path="/client" element={<Client />} />,
        <Route key="/group/:id" path="/group/:id" element={<Group />} />,
        <Route key="/group" path="/group" element={<Group />} />,
        <Route key="/member/:id" path="/member/:id" element={<Member />} />,
        <Route key="/member" path="/member" element={<Member />} />,
        <Route key="/tests" path="/tests" element={<Tests />} />,
        <Route
          key="/test/:id/:autorun?"
          path="/test/:id/:autorun?"
          element={<Test />}
        />,
        <Route key="/test" path="/test" element={<Test />} />,
        <Route key="/testgroups" path="/testgroups" element={<TestGroups />} />,
        <Route key="/testgroup" path="/testgroup" element={<TestGroup />} />,
        <Route
          key="/testgroup/:id/:autorun?"
          path="/testgroup/:id/:autorun?"
          element={<TestGroup />}
        />,
        <Route
          key="/testrunresults"
          path="/testrunresults"
          element={<TestRunResults />}
        />,
        <Route
          key="/testgroupresults/:testGroupId/:testResultId"
          path="/testgroupresults/:testGroupId/:testResultId"
          element={<TestGroupResults />}
        />,
        <Route
          key="/testresults/:testid/:testResultId"
          path="/testresults/:testid/:testResultId"
          element={<TestResults />}
        />,
        <Route
          key="/claimtemplate"
          path="/claimtemplate"
          element={<ClaimTemplateTest />}
        />,
        <Route
          key="/dbdrugupdate"
          path="/dbdrugupdate"
          element={<DbDrugUpdate />}
        />,
        <Route
          key="/dblegacydbsync"
          path="/dblegacydbsync"
          element={<DbLegacyDbSync />}
        />,
        <Route
          key="/dbdatabasesync"
          path="/dbdatabasesync"
          element={<DbDatabaseSync />}
        />,
        <Route
          key="/socketserverconfig"
          path="/socketserverconfig"
          element={<SocketServerConfig />}
        />,
        <Route
          key="/hbdetectorconfig"
          path="/hbdetectorconfig"
          element={<HbDetectorConfig />}
        />,
        <Route
          key="/testdatapopulatorconfig"
          path="/testdatapopulatorconfig"
          element={<TestDataPopulatorConfig />}
        />,
        <Route
          key="/testrunnerconfig"
          path="/testrunnerconfig"
          element={<TestRunnerConfig />}
        />,
      ]);
    }
    if (admin || basicUser) {
      addRoutes = addRoutes.concat([
        <Route key="/claims" path="/claims" element={<Claims />} />,
        <Route key="/claim/:id" path="/claim/:id" element={<Claim />} />,
        <Route key="/reports" path="/reports" element={<Reports />} />,
        <Route key="/report/:id" path="/report/:id" element={<Report />} />,
        <Route key="/report" path="/report" element={<Report />} />,
        <Route
          key="/runreport/:id"
          path="/runreport/:id"
          element={<RunReport />}
        />,
        <Route
          key="/diagnostics"
          path="/diagnostics"
          element={<Diagnostics />}
        />,
        <Route
          key="/groupsummary"
          path="/groupsummary"
          element={<RuleGroupSummary />}
        />,
        <Route
          key="/groupsummary/:id"
          path="/groupsummary/:id"
          element={<RuleGroupSummary />}
        />,
        <Route key="/wizard" path="/wizard" element={<WizardHome />} />,
        <Route
          key="/wizard"
          path="/wizard/setmemberdob/SetMemberDOBWizard"
          element={<WizardSetMemberDOB />}
        />,
        <Route
          key="/wizard"
          path="/wizard/createtest/WizardCreateTest"
          element={<WizardCreateTest />}
        />,
      ]);
    }
    if (admin || basicUser || parserUser) {
      addRoutes = addRoutes.concat([
        <Route
          key="/packetparser"
          path="/packetparser"
          element={<PacketParser />}
        />,
        <Route
          key="/privacy"
          path="/privacy"
          element={<PrivacyPolicyPage />}
        />,
      ]);
    }
    addRoutes = addRoutes.concat([
      <Route key="Home" path="/" element={<HomePage />} />,
      <Route key="NotFound" path="/notfound" element={<NotFoundPage />} />,
      <Route key="NotFoundDefault" path="*" element={<NotFoundPage />} />,
    ]);

    setRoutes(addRoutes);
  }

  return !isAuthenticated ? (
    <LoginPage inProgress={authenticating} />
  ) : (
    <BrowserRouter>
      <ConfigContext.Provider value={configValue}>
        <WebSocketConnectionProvider>
          <PacketTrafficProvider>
            <DiagnosticsProvider>
              <ChainsProvider>
                <ClientsProvider>
                  <GroupsProvider>
                    <DiscountsProvider>
                      <DrugsProvider>
                        <MembersProvider>
                          <PrescribersProvider>
                            <ServiceProvidersProvider>
                              <PriorAuthorizationsProvider>
                                <PacketParserProvider>
                                  <ClaimsProvider>
                                    <TestProcessorProvider>
                                      <LogsProvider>
                                        <RulesProvider>
                                          <ReportsProvider>
                                            <ReportTemplatesProvider>
                                              <ReportProcessesProvider>
                                                <ProcessHistoryProvider>
                                                  <RuleTableDefinitionsProvider>
                                                    <RuleTablesProvider>
                                                      <AuditProvider>
                                                        <CacheProvider>
                                                          <TestsProvider>
                                                            <TestGroupsProvider>
                                                              <TestResultsProvider>
                                                                <TestCoverageTrackingProvider>
                                                                  <DbDrugUpdateProvider>
                                                                    <DbLegacyDbSyncProvider>
                                                                      <DbDatabaseSyncProvider>
                                                                        <SocketServerConfigProvider>
                                                                          <HbDetectorConfigProvider>
                                                                            <TestDataPopulatorConfigProvider>
                                                                              <TestRunnerConfigProvider>
                                                                                <RuleMapsProvider>
                                                                                  <GroupRuleMapsProvider>
                                                                                    <AppContent
                                                                                      userName={
                                                                                        userName
                                                                                      }
                                                                                      routes={
                                                                                        routes
                                                                                      }
                                                                                    ></AppContent>
                                                                                  </GroupRuleMapsProvider>
                                                                                </RuleMapsProvider>
                                                                              </TestRunnerConfigProvider>
                                                                            </TestDataPopulatorConfigProvider>
                                                                          </HbDetectorConfigProvider>
                                                                        </SocketServerConfigProvider>
                                                                      </DbDatabaseSyncProvider>
                                                                    </DbLegacyDbSyncProvider>
                                                                  </DbDrugUpdateProvider>
                                                                </TestCoverageTrackingProvider>
                                                              </TestResultsProvider>
                                                            </TestGroupsProvider>
                                                          </TestsProvider>
                                                        </CacheProvider>
                                                      </AuditProvider>
                                                    </RuleTablesProvider>
                                                  </RuleTableDefinitionsProvider>
                                                </ProcessHistoryProvider>
                                              </ReportProcessesProvider>
                                            </ReportTemplatesProvider>
                                          </ReportsProvider>
                                        </RulesProvider>
                                      </LogsProvider>
                                    </TestProcessorProvider>
                                  </ClaimsProvider>
                                </PacketParserProvider>
                              </PriorAuthorizationsProvider>
                            </ServiceProvidersProvider>
                          </PrescribersProvider>
                        </MembersProvider>
                      </DrugsProvider>
                    </DiscountsProvider>
                  </GroupsProvider>
                </ClientsProvider>
              </ChainsProvider>
            </DiagnosticsProvider>
          </PacketTrafficProvider>
        </WebSocketConnectionProvider>
      </ConfigContext.Provider>
    </BrowserRouter>
  );
}

export default App;
