import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createViewModel,
  dispenserClassCodes,
  dispenserTypeCodes,
  emptyServiceProvider,
  fromViewModel,
  serviceProviderTypes,
} from "../../../viewmodels/serviceProvidersVm";
import DatePickerInput from "../../common/input/DatePickerInput";
import {
  handleCollapseExpandAll,
  isPositiveInteger,
  states,
} from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import SelectInput from "../../common/input/SingleSelect";
import { useServiceProviders } from "../../../contexts/ServiceProvidersContext";
import { useChains } from "../../../contexts/ChainsContext";
import { useAuth } from "../../../contexts/AuthContext";
import {
  apiAddServiceProvider,
  apiUpdateServiceProvider,
  apiLoadServiceProvider,
} from "../../../api/ServiceProviderApi";
import { apiLoadChainsAll } from "../../../api/ChainApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function ServiceProvider() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { serviceProvidersData, setServiceProvidersData } =
    useServiceProviders();
  const { chainsData, setChainsData } = useChains();
  const { loading, api: apiLoad } = useApi(apiLoadServiceProvider);
  const { loading: adding, api: apiAdd } = useApi(apiAddServiceProvider);
  const { loading: updating, api: apiUpdate } = useApi(
    apiUpdateServiceProvider
  );
  const { loading: loadingChains, api: apiLoadChains } =
    useApi(apiLoadChainsAll);
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyServiceProvider);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Service Provider", collapsed: false },
    { name: "Effective Range", collapsed: true },
    { name: "Address", collapsed: true },
    { name: "Contact", collapsed: true },
  ]);

  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setServiceProvidersData({
        type: ContextProviderActions.loadServiceProvider,
        payload: {
          serviceProvider: emptyServiceProvider,
        },
      });

      loadProvidersAndChainList();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (serviceProvidersData.serviceProvider) {
      setChanges(serviceProvidersData.serviceProvider);
    } else {
      setChanges(emptyServiceProvider);
    }
  }, [serviceProvidersData.serviceProvider]);

  async function loadProvidersAndChainList() {
    let chainList = chainsData.chainListCache || [];

    if ((chainList || []).length === 0) {
      chainList = await loadChainList();

      // Save the list of chains in the cache
      setChainsData({
        type: ContextProviderActions.setChainListCache,
        payload: chainList,
      });
    }

    await loadServiceProvider(chainList);
  }

  async function loadChainList() {
    return await apiLoadChains.call(null, async (result) => {
      return result.resources.map((c) => {
        return { value: c.id, label: c.name };
      });
    });
  }

  async function loadServiceProvider(chainList) {
    if (!resId) {
      setServiceProvidersData({
        type: ContextProviderActions.loadServiceProvider,
        payload: {
          serviceProvider: emptyServiceProvider,
        },
      });
      setChanges(emptyServiceProvider);
      return;
    }

    apiLoad.call(resId, (result) => {
      postLoadServiceProvidersAndChains(result, chainList);
    });
  }

  function postLoadServiceProvidersAndChains(serviceProviderResult, chainList) {
    // Group viewmodel needs the chain list
    let vm = emptyServiceProvider;
    if (!serviceProviderResult) {
      notifyError("Group does not exist");
    } else {
      vm = createViewModel(serviceProviderResult, chainList);
    }

    setServiceProvidersData({
      type: ContextProviderActions.loadServiceProvider,
      payload: {
        serviceProvider: vm,
      },
    });
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    if (_.has(changes, "name") && changes.name.trim() === "")
      _errors.name = "Name must be entered";
    let isPos = isPositiveInteger(changes.npi);
    if (
      _.has(changes, "npi") &&
      ((changes.npi || "").trim().length !== 10 || !isPos)
    )
      _errors.npi = "NPI must be a valid number of 10 digits";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleDispenserClassCodeChange(value) {
    setChanges({ ...changes, dispenserClassCodeVm: value });
  }

  function handleDispenserTypeCodeChange(value) {
    setChanges({ ...changes, dispenserTypeCodeVm: value });
  }

  function handleServiceProviderTypeChange(value) {
    setChanges({ ...changes, serviceProviderTypeVm: value });
  }

  function handleChainChange(value) {
    setChanges({ ...changes, chainIdVm: value });
  }

  function handleStateChange(value) {
    setChanges({ ...changes, stateVm: value });
  }

  function handleEffectiveDateChange(date) {
    setChanges({ ...changes, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };

    if (!resId) {
      addServiceProvider(newVm);
    } else updateServiceProvider(newVm.id, newVm);
  }

  async function addServiceProvider(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      setChanges(createViewModel(result, chainsData.chainListCache));
      notifySuccess("Service Provider " + vm.npi + " saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/serviceprovider/" + result.id, { replace: true });
    });
  }

  async function updateServiceProvider(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      setChanges(createViewModel(result, chainsData.chainListCache));
      notifySuccess("Service Provider " + vm.npi + " saved successfully");
    });
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return To Service Providers"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate("/configureserviceproviders")}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Configure/Service-Provider-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            Service Provider Details{" "}
            {changes.ncpdp ? ` | ${changes.ncpdp}` : ""}
          </h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/configureserviceproviders")}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {loading || loadingChains || adding || updating ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/serviceprovider/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Service Provider"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Service-Provider-Screen&anchor=service-provider"
              >
                <StyledRowDiv className="row">
                  <h5>Service Provider</h5>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="npi"
                      label="Service Provider NPI"
                      onChange={handleChange}
                      placeholder="NPI"
                      name="npi"
                      value={changes.npi || ""}
                      error={errors.npi}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="ncpdp"
                      label="NCPDP Number"
                      onChange={handleChange}
                      placeholder="NCPDP Number"
                      name="ncpdp"
                      value={changes.ncpdp || ""}
                      error={errors.ncpdp}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="name"
                      label="Name"
                      onChange={handleChange}
                      placeholder="Name"
                      name="name"
                      value={changes.name || ""}
                      error={errors.name}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="description"
                      label="Description"
                      onChange={handleChange}
                      placeholder="Description"
                      name="description"
                      value={changes.description || ""}
                      error={errors.description}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <SelectInput
                      id="chainId"
                      name="chainId"
                      label="Chain Id"
                      options={chainsData.chainListCache}
                      value={changes.chainIdVm}
                      onChange={handleChainChange}
                      placeholder="Select a Chain"
                      error={errors.chain}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <SelectInput
                      id="serviceProviderType"
                      name="serviceProviderTypeVm"
                      label="Service Provider Type"
                      options={serviceProviderTypes}
                      value={changes.serviceProviderTypeVm || ""}
                      onChange={handleServiceProviderTypeChange}
                      placeholder="Select a Service Provider Type"
                      error={errors.serviceProviderTypeVm}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <SelectInput
                      id="dispenserClassCode"
                      name="dispenserClassCodeVm"
                      label="Dispenser Class Code"
                      options={dispenserClassCodes}
                      value={changes.dispenserClassCodeVm || ""}
                      onChange={handleDispenserClassCodeChange}
                      placeholder="Select a Dispenser Class Code"
                      error={errors.dispenserClassCodeVm}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <SelectInput
                      id="dispenserTypeCode"
                      name="dispenserTypeCodeVm"
                      label="Dispenser Type Code"
                      options={dispenserTypeCodes}
                      value={changes.dispenserTypeCodeVm || ""}
                      onChange={handleDispenserTypeCodeChange}
                      placeholder="Select a Dispenser Type Code"
                      error={errors.dispenserTypeCodeVm}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Effective Range"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Service-Provider-Screen&anchor=effective-range"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="effectiveDate"
                      name="effectiveDate"
                      label="Effective Date"
                      value={changes.effectiveDate}
                      placeholder="Effective Date"
                      onChange={handleEffectiveDateChange}
                      error={errors.effectiveDate}
                      showTimeInput={false}
                    />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="terminationDate"
                      label="Termination Date"
                      name="terminationDate"
                      value={changes.terminationDate}
                      placeholder="Termination Date"
                      onChange={handleTerminationDateChange}
                      error={errors.terminationDate}
                      showTimeInput={false}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Address"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Service-Provider-Screen&anchor=address"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="emailAddresses"
                      label="Email Addresses"
                      onChange={handleChange}
                      placeholder="Email Addresses"
                      name="emailAddressesVm"
                      value={changes.emailAddressesVm}
                      error={errors.emailAddresses}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="address"
                      label="Address"
                      onChange={handleChange}
                      placeholder="Address"
                      name="address"
                      value={changes.address || ""}
                      error={errors.address}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="city"
                      label="City"
                      onChange={handleChange}
                      placeholder="City"
                      name="city"
                      value={changes.city || ""}
                      error={errors.city}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <SelectInput
                      id="state"
                      name="state"
                      label="State"
                      options={states}
                      value={changes.stateVm || ""}
                      onChange={handleStateChange}
                      placeholder="Select a State"
                      error={errors.state}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="zipCode"
                      label="Zip Code"
                      onChange={handleChange}
                      placeholder="Zip Code"
                      name="zipCode"
                      value={changes.zipCode || ""}
                      error={errors.zipCode}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Contact"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Service-Provider-Screen&anchor=contact"
              >
                <StyledRowDiv className="row">
                  <h5>Contact</h5>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="lastName"
                      label="Contact Last Name"
                      onChange={handleChange}
                      placeholder="Contact Last Name"
                      name="lastName"
                      value={changes.lastName || ""}
                      error={errors.lastName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="firstName"
                      label="Contact First Name"
                      onChange={handleChange}
                      placeholder="Contact First Name"
                      name="firstName"
                      value={changes.firstName || ""}
                      error={errors.firstName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="middleName"
                      label="Contact Middle Name"
                      onChange={handleChange}
                      placeholder="Contact Middle Name"
                      name="middleName"
                      value={changes.middleName || ""}
                      error={errors.middleName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="title"
                      label="Contact Title"
                      onChange={handleChange}
                      placeholder="Contact Title"
                      name="title"
                      value={changes.title || ""}
                      error={errors.title}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="subtitle"
                      label="Contact Subtitle"
                      onChange={handleChange}
                      placeholder="Contact Subtitle"
                      name="subtitle"
                      value={changes.subtitle || ""}
                      error={errors.subtitle}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="suffix"
                      label="Contact Suffix"
                      onChange={handleChange}
                      placeholder="Contact Suffix"
                      name="suffix"
                      value={changes.suffix || ""}
                      error={errors.suffix}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="number"
                      label="Phone Number"
                      onChange={handleChange}
                      placeholder="Phone Number"
                      name="number"
                      value={changes.number || ""}
                      error={errors.number}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
            </div>
            <br />
          </>
        )}
      </form>
    </Authorize>
  );
}

export default ServiceProvider;
