import React, { createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyTestConfig } from "../viewmodels/trafficVm";

const DARK_MODE_ITEM = "DARKMODE";
const TRAFFIC_PACKET_TEST_DATA_ITEM = "TRAFFICPACKETTESTDATA";
export const COVERAGE_TRACKING_ITEM = "COVERAGETRACKINGMODE";
const GRID_PREFERENCES_ARRAY = "GRIDPREFERENCESARRAY";

function reducer(draft, action) {
  switch (action.type) {
    case ContextProviderActions.setExpandCollapseState: {
      const o = draft.expandCollapseState.find(
        (o) => o.id === action.payload.id
      );

      if (o !== undefined) {
        o.value = action.payload.expanded;
      } else {
        draft.expandCollapseState.push({
          id: action.payload.id,
          value: action.payload.expanded,
        });
      }

      return draft;
    }

    case ContextProviderActions.setEnvironment: {
      draft.environment.environment = action.payload.environment;
      if (action.payload.staging !== undefined) {
        draft.environment.staging = action.payload.staging;
      }
      return draft;
    }

    case ContextProviderActions.setMenuToggleExpanded: {
      draft.menuToggleExpanded = action.payload;
      return draft;
    }

    case ContextProviderActions.setShowGraphsOnHomePage: {
      draft.showGraphsOnHomePage = action.payload;
      return draft;
    }

    case ContextProviderActions.setShowSidebarOnHomePage: {
      draft.showSidebarOnHomePage = action.payload;
      return draft;
    }

    case ContextProviderActions.setDarkMode: {
      draft.darkMode = action.payload;

      if (action.payload === true) {
        localStorage.setItem(DARK_MODE_ITEM, "true");
      } else {
        localStorage.removeItem(DARK_MODE_ITEM);
      }
      return draft;
    }

    case ContextProviderActions.setCoverageTrackingMode: {
      draft.coverageTrackingMode = action.payload;
      draft.coverageTrackingVisibility = false;

      if (action.payload === true) {
        localStorage.setItem(COVERAGE_TRACKING_ITEM, "true");
      } else {
        localStorage.removeItem(COVERAGE_TRACKING_ITEM);
      }
      return draft;
    }

    case ContextProviderActions.setCoverageTrackingVisibility: {
      draft.coverageTrackingVisibility = action.payload;
      return draft;
    }

    case ContextProviderActions.setTrafficPacketTestData: {
      draft.trafficPacketTestData = action.payload;
      localStorage.setItem(
        TRAFFIC_PACKET_TEST_DATA_ITEM,
        JSON.stringify(action.payload)
      );

      return draft;
    }

    case ContextProviderActions.setGridPreferences: {
      draft.gridPreferences = action.payload;
      localStorage.setItem(
        GRID_PREFERENCES_ARRAY,
        JSON.stringify(action.payload)
      );

      return draft;
    }

    default:
      throw new Error(
        `Unhandled action type in UserPreferencesContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  darkMode: localStorage[DARK_MODE_ITEM] === "true" ?? false,
  coverageTrackingMode:
    localStorage[COVERAGE_TRACKING_ITEM] === "true" ?? false,
  coverageTrackingVisibility: false,
  trafficPacketTestData: localStorage[TRAFFIC_PACKET_TEST_DATA_ITEM]
    ? JSON.parse(localStorage[TRAFFIC_PACKET_TEST_DATA_ITEM])
    : {
        ...emptyTestConfig,
      },
  expandCollapseState: [],
  environment: {
    staging: false,
    environment: { label: "Production (Staging)", value: "prod" },
  },
  menuToggleExpanded: false,
  showGraphsOnHomePage: true,
  showSidebarOnHomePage: false,
  gridPreferences: localStorage[GRID_PREFERENCES_ARRAY]
    ? JSON.parse(localStorage[GRID_PREFERENCES_ARRAY])
    : [],
};

const UserPreferences = createContext({
  userPrefs: initialState,
  setUserPrefs: () => null,
});

// eslint-disable-next-line react/prop-types
const UserPreferencesProvider = ({ children }) => {
  const [userPrefs, setUserPrefs] = useImmerReducer(reducer, initialState);

  return (
    <UserPreferences.Provider value={{ userPrefs, setUserPrefs }}>
      {children}
    </UserPreferences.Provider>
  );
};

export default UserPreferencesProvider;
export const useUserPreferences = () => useContext(UserPreferences);
