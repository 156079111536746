import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useAuth } from "../contexts/AuthContext";
import styled from "styled-components";
import logo from "../assets/images/aclaimrx-logo.svg";
import logoDarkMode from "../assets/images/aclaimrx-logo-white.png";
import splash from "../assets/images/login-splash.png";
import { useWindowSize } from "../hooks/useWindowSize";
import { useMobile } from "../hooks/useMobile";
import { notifyWarn } from "../services/NotificationService";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { useAuthProvider } from "../hooks/useAuthProvider";
import { useUserPreferences } from "../contexts/UserPreferencesContext";

export default function LoginPage({ inProgress }) {
  const { authActions } = useAuthProvider();
  const { auth, setAuth } = useAuth();
  const [windowSizeW, windowSizeH] = useWindowSize([0, 0]);
  const { isSmallMobileSize } = useMobile();
  const [divWidth, setDivWidth] = useState(400);
  const [divTop, setDivTop] = useState("25%");
  const [showContactPage, setShowContactPage] = useState(false);
  const { userPrefs } = useUserPreferences();

  const LightMode = React.lazy(() => import("./common/ui/LightModeStyles"));
  const DarkMode = React.lazy(() => import("./common/ui/DarkModeStyles"));

  // Set the login div width when window size changes to always allow mobile to have a margin.
  useEffect(() => {
    setDivWidth(windowSizeW > 0 && windowSizeW < 440 ? windowSizeW - 40 : 440);
    setDivTop(windowSizeH > 0 && windowSizeH < 420 ? "50px" : "25%");
  }, [windowSizeW, windowSizeH]);

  // Show any login message in a notification window
  useEffect(() => {
    let loginMessage = auth.loginMessage;
    if (!loginMessage || loginMessage === "") {
      // Check local storage for a login message
      loginMessage = localStorage.getItem("LOGIN_MESSAGE") ?? "";
    }
    if (loginMessage && loginMessage !== "") {
      notifyWarn(loginMessage);
      setAuth({
        type: ContextProviderActions.setLoginScreenMessage,
        payload: "",
      });
    }
  }, [auth.loginMessage, localStorage.getItem("LOGIN_MESSAGE")]);

  function authenticate() {
    console.log(`User is not authenticated. Redirecting to login screen...`);
    authActions.login().catch((error) => {
      console.log(`Login redirect error: ${error}`);
    });
  }

  function showContactUsPage(show) {
    setShowContactPage(show);
  }

  return (
    <>
      <React.Suspense fallback={<></>}>
        {!userPrefs.darkMode && <LightMode />}
        {userPrefs.darkMode && <DarkMode />}
      </React.Suspense>

      <LoginWrapperDiv>
        <LoginGradientDiv />
        <LoginBox
          height={isSmallMobileSize ? "360px" : "320px"}
          style={{
            width: divWidth,
            left: `calc(50% - ${divWidth / 2}px)`,
            top: divTop,
          }}
          className={showContactPage ? "flipped" : ""}
        >
          <LoginContentWrapper className={"login-inner"}>
            <LoginContentPanel className="login-front">
              <LoginCircleBackground />
              <h1>Claims Processing Portal</h1>
              <p>Custom Claims Processing for Specialty Pharmacies</p>

              {userPrefs.darkMode ? (
                <LoginLogoDarkModeImage>&nbsp;</LoginLogoDarkModeImage>
              ) : (
                <LoginLogoImage>&nbsp;</LoginLogoImage>
              )}

              {inProgress ? (
                <LoginProgressBar
                  striped={true}
                  animated={true}
                  now={100}
                  label="Please wait..."
                  style={{ marginTop: "40px" }}
                ></LoginProgressBar>
              ) : (
                <>
                  <Button
                    variant="primary"
                    style={{ width: "280px", marginTop: "30px" }}
                    onClick={authenticate}
                  >
                    Sign in
                  </Button>
                  <button
                    className="btn btn-link"
                    onClick={() => showContactUsPage(true)}
                  >
                    Contact aClaimRx
                  </button>
                </>
              )}
            </LoginContentPanel>
            <LoginContentPanel className="login-back">
              <LoginCircleBackground />
              <h1>Contact aClaimRx</h1>
              <p>For support, please call 864-250-2099</p>
              {userPrefs.darkMode ? (
                <LoginLogoDarkModeImage>&nbsp;</LoginLogoDarkModeImage>
              ) : (
                <LoginLogoImage>&nbsp;</LoginLogoImage>
              )}
              <button
                className="btn btn-link"
                onClick={() => showContactUsPage(false)}
              >
                Return to login
              </button>
            </LoginContentPanel>
          </LoginContentWrapper>
        </LoginBox>
      </LoginWrapperDiv>
    </>
  );
}

const LoginWrapperDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: var(--login-gradient-2) url(${splash}) no-repeat center;
  background-size: cover;
  z-index: 0;
`;

const LoginGradientDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background: linear-gradient(
    116.82deg,
    var(--login-gradient-1) 0%,
    var(--login-gradient-2) 100%
  );
  mix-blend-mode: multiply;
`;

const LoginCircleBackground = styled.div`
  position: absolute;
  top: -40px;
  width: 124px;
  height: 124px;
  z-index: 1;
  left: calc(50% - 62px);
  background-color: var(--login-content-bg);
  border-radius: 50%;
`;

const LoginBox = styled.div`
  position: absolute;
  z-index: 1;
  top: 25%;
  height: ${(props) => props.height};
  min-height: 320px;
  max-height: 360px;
  max-width: 440px;
  left: calc(50% - 220px);
  perspective: 1000px;
  background-color: transparent;

  &.flipped {
    div.login-inner {
      transform: rotateY(180deg);
    }
  }
`;

const LoginContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 90px 20px 20px 20px;
  background-color: var(--login-content-bg);
  box-shadow: 4px 4px 10px var(--shadow-1);
  border-radius: 3px;
  transition: all 0.5s ease-in-out;
  transform-style: preserve-3d;

  div.login-front > h1,
  div.login-back > h1 {
    margin-top: 68px;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: var(--login-title);
  }

  div.login-front > p,
  div.login-back > p {
    color: var(--text-normal);
    text-align: center;
    padding-top: 8px;
  }
`;

const LoginContentPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: all 1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;

  &.login-back {
    transform: rotateY(180deg);
  }

  .btn.btn-link {
    margin-top: auto;
  }
`;

const LoginLogoImage = styled.div`
  position: absolute;
  z-index: 1;
  top: -12px;
  width: 82px;
  height: 68px;
  background: transparent url(${logo}) no-repeat center;
`;

const LoginLogoDarkModeImage = styled.div`
  position: absolute;
  z-index: 1;
  top: -12px;
  width: 82px;
  min-height: 68px;
  max-height: 68px;
  height: 68px;
  background: transparent url(${logoDarkMode}) no-repeat center;
  background-size: auto 100%;
`;

const LoginProgressBar = styled(ProgressBar)`
  height: 25px;
  width: 90%;
  max-width: 400px;

  .progress-bar {
    color: var(--progressbar-text);
    font-size: 16px;
    background-color: var(--progressbar-bg);
  }
`;
