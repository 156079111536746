import React, { useState, useEffect } from "react";
import RuleTableDefinitionsList from "./RuleTableDefinitionsList";
import RuleTableDefinitionSearchForm from "./RuleTableDefinitionSearchForm";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import {
  createViewModel,
  emptyRuleTableDefinitionSearch,
} from "../../../viewmodels/ruleTableDefinitionsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useRuleTableDefinitions } from "../../../contexts/RuleTableDefinitionsContext";
import {
  apiLoadRuleTableDefinitions,
  apiDeleteRuleTableDefinition,
  apiCopyRuleTableDefinition,
} from "../../../api/RuleTableDefinitionApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import { useNavigate } from "react-router-dom";
import DuplicateRecordDialog from "../../dialogs/DuplicateRecordDialog";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function RuleTableDefinitions() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { ruleTableDefinitionsData, setRuleTableDefinitionsData } =
    useRuleTableDefinitions();
  const { loading, api: apiLoad } = useApi(apiLoadRuleTableDefinitions);
  const { loading: deleting, api: apiDelete } = useApi(
    apiDeleteRuleTableDefinition
  );
  const { loading: copying, api: apiCopy } = useApi(apiCopyRuleTableDefinition);
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState(true);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const { isMobileSize } = useMobile();

  let ruleTableDefinitions = [];
  if (
    ruleTableDefinitionsData &&
    ruleTableDefinitionsData.ruleTableDefinitions
  ) {
    ruleTableDefinitions = ruleTableDefinitionsData.ruleTableDefinitions;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setRuleTableDefinitionsData must usually
  //  happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //  useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadRuleTableDefinitions();
    }
  }, [auth.authenticated, loadData]);

  async function loadRuleTableDefinitions() {
    apiLoad.call(
      ruleTableDefinitionsData.search,
      (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setRuleTableDefinitionsData({
          type: ContextProviderActions.loadRuleTableDefinitions,
          payload: {
            ruleTableDefinitions: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setRuleTableDefinitionsData({
      type: ContextProviderActions.saveRuleTableDefinitionSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyRuleTableDefinitionSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : ruleTableDefinitionsData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = ruleTableDefinitionsData.search.orderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = ruleTableDefinitionsData.search.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...ruleTableDefinitionsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...ruleTableDefinitionsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...ruleTableDefinitionsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...ruleTableDefinitionsData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...ruleTableDefinitionsData.search,
      [target.name]: target.value,
    });
  }

  function handleSecurityLevelChanged(securityLevel) {
    setSearchChanges({
      ...ruleTableDefinitionsData.search,
      securityLevel: securityLevel,
    });
  }

  async function deleteRuleTableDefinition(id) {
    apiDelete.call(
      id,
      (result) => {
        notifySuccess("Rule Table Definition deleted successfully");
        setLoadData(true);
      },
      (error) => {
        // Show warning when 409 error returned due to this definition being used on a rule
        if (_.startsWith(error.message, "(409)")) {
          notifyWarn(
            "This rule table definition cannot be deleted because it is being used by at least one rule table."
          );
          return false;
        } else {
          return true;
        }
      }
    );
  }

  async function handleDelete(id, name) {
    setDeleteId(id);
    setAltDeleteId(name);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteRuleTableDefinition(deleteId);
  }

  const handleCancelModal = () => {
    setShowCopyModal(false);
  };

  function startCopy(name) {
    let updated = {
      ...ruleTableDefinitionsData.search,
      oldRuleTableDefinitionName: name,
    };
    setSearchChanges(updated);
    setShowCopyModal(true);
  }

  async function copyRuleTableDefinition(oldName, newName) {
    apiCopy.call({ id: oldName, newId: newName }, (result) => {
      notifySuccess("Rule Table Definition copied successfully to " + newName);
      setShowCopyModal(false);
      setLoadData(true);
    });
  }

  async function handleCopy(newRuleTableDefinitionName) {
    await copyRuleTableDefinition(
      ruleTableDefinitionsData.search.oldRuleTableDefinitionName,
      newRuleTableDefinitionName
    );
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (ruleTableDefinitionsData.search.description !== "") numFilters++;
    if (ruleTableDefinitionsData.search.name !== "") numFilters++;
    if ((ruleTableDefinitionsData.search.securityLevel || []).length > 0)
      numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        {((ruleTableDefinitionsData.search.showAdvancedFilter &&
          !isMobileSize) ||
          !ruleTableDefinitionsData.search.showAdvancedFilter) && (
          <HelpLink
            path="/Processing-Rules/Rule-Table-Definitions-List-Screen"
            label="Help"
          />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Rule Table Definitions</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/ruletabledefinition")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Definition
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search Name or Description"
        search={ruleTableDefinitionsData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <DuplicateRecordDialog
        title="Duplicate Rule Table Definition"
        instructions={`Enter a new name for the rule table definition to be copied from '${ruleTableDefinitionsData.search.oldRuleTableDefinitionName}'.`}
        value={ruleTableDefinitionsData.search.newRuleTableDefinitionName || ""}
        showModal={showCopyModal}
        onCancel={handleCancelModal}
        onCopy={handleCopy}
        placeholder="New Rule Table Definition Name"
      />
      <ConfirmDialog
        title="Remove Rule Table Definition"
        question={`Are you sure you wish to delete the rule table definition '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              ruleTableDefinitionsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={ruleTableDefinitionsData.search}
            setSearch={setSearchChanges}
            helpLink="/Processing-Rules/Rule-Table-Definitions-List-Screen&anchor=filters"
          >
            <RuleTableDefinitionSearchForm
              errors={errors}
              search={ruleTableDefinitionsData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onSecurityLevelChanged={handleSecurityLevelChanged}
            />
          </GridAdvancedFilter>
        </div>

        {ruleTableDefinitionsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || deleting || copying ? (
              <Spinner />
            ) : (
              <RuleTableDefinitionsList
                isAdmin={auth.isAdmin}
                ruleTableDefinitions={ruleTableDefinitions}
                onDelete={handleDelete}
                search={ruleTableDefinitionsData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={ruleTableDefinitionsData.count}
                onCopy={startCopy}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default RuleTableDefinitions;
