import React from "react";
import styled from "styled-components";
import { apiClearDashboardPanelAlert } from "../../../api/HomeApi";
import Spinner from "../../common/ui/Spinner";
import DashboardPanelFront from "./DashboardPanelFront";
import DashboardPanelBack from "./DashboardPanelBack";
import useApi from "../../../hooks/useApi";

function DashboardPanel({
  panel,
  value,
  lastValue,
  lastTimestamp,
  showGraphs,
  updatePanels,
  selectedTileId,
  setSelectedTileId,
}) {
  const { loading, api: apiClearAlert } = useApi(apiClearDashboardPanelAlert);

  async function ackAlert(reportId) {
    apiClearAlert.call(reportId, (result) => {
      updatePanels(result);
    });
  }

  return (
    <StyledAnimatedBox
      id={panel.reportId}
      key={panel.reportId}
      onClick={() =>
        selectedTileId === panel.reportId
          ? setSelectedTileId("")
          : setSelectedTileId(panel.reportId)
      }
      className={selectedTileId === panel.reportId ? "active-tile" : ""}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div
          className={`flip-card-inner ${
            panel.isAlertPanel === true ? "alertpanel" : ""
          } ${panel.isAlertPanelInAlertStatus === true ? "alerted" : ""}`}
        >
          <DashboardPanelFront
            panel={panel}
            selectedTileId={selectedTileId}
            value={value}
            lastValue={lastValue}
            showGraphs={showGraphs}
          />
          <DashboardPanelBack
            panel={panel}
            value={value}
            lastTimestamp={lastTimestamp}
            ackAlert={ackAlert}
          />
        </div>
      )}
    </StyledAnimatedBox>
  );
}

const StyledAnimatedBox = styled.div`
  width: 196px;
  height: 126px;
  background-color: transparent;
  perspective: 1000px;

  @media only screen and (max-width: 576px) {
    width: 160px;
    height: 126px;
  }

  &:not(.active-tile):hover {
    cursor: pointer;

    div.flip-card-front,
    div.flip-card-back {
      background: var(--selected-bg);
      border: 1px solid var(--selected-border);
    }
  }

  &.active-tile {
    div.flip-card-inner {
      transform: rotateY(180deg);
    }
  }

  h3 {
    font-size: 26px;
    line-height: 50px;
    text-align: center;
    color: var(--text-normal);
    margin-bottom: 5px;
    transition: all 1s ease-in-out;

    &.changing {
      transition: all 1s ease-in-out;
      color: var(--selected-border);
      transform: scale(1.2);
    }
  }

  p {
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: var(--text-dark);
    padding: 0;
    margin: 0;

    @media only screen and (max-width: 576px) {
      font-size: 16px;
    }
  }

  div.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: all 0.5s ease-in-out;
    transform-style: preserve-3d;

    h4 {
      font-size: 18px !important;
      font-weight: bold;

      @media only screen and (max-width: 576px) {
        font-size: 15px !important;
      }
    }

    &.alertpanel div.flip-card-front,
    &.alertpanel div.flip-card-back {
      border: 1px solid var(--notify-success);
      box-shadow: 0 0 8px var(--notify-success);

      span[class="material-icons"] {
        color: var(--pill-green);
      }

      span[class="material-icons"] {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 28px;
      }
    }

    &.alertpanel.alerted div.flip-card-front,
    &.alertpanel.alerted div.flip-card-back {
      border: 1px solid var(--notify-danger);
      box-shadow: 0 0 8px var(--notify-danger);

      h3,
      h4,
      p,
      span[class="material-icons"] {
        color: var(--pill-red);
      }
    }
  }

  div.flip-card-front,
  div.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform-style: preserve-3d;
    background: var(--elevated-bg);
    border: 1px solid var(--elevated-border);
    border-radius: 3px;
    padding: 16px 16px 8px 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all 1s ease-in-out;

    svg {
      opacity: 0.3;
    }

    @media only screen and (max-width: 576px) {
      padding: 16px 5px 4px 5px;
    }

    &.changing {
      transition: all 1s ease-in-out;
      border: 1px solid var(--selected-border);
      box-shadow: 0 0 8px var(--selected-border);
    }
  }

  div.flip-card-back {
    transform: rotateY(180deg);
    border: 1px solid var(--selected-border);
  }
`;

export default DashboardPanel;
