import _ from "lodash";
import { pageSize } from "../constants/Environments";
import { parseISO } from "date-fns";
import { autoPopulateTestDataTypes } from "../services/General";

export const emptyTest = {
  id: "",
  lastRunResultId: "",
  lastRunDate: "",
  lastRunBy: "",
  lastRunDurationMilliseconds: 0,
  lastRunResultStatus: "NotRun", // Pass, Fail
  testGroupId: "",
  testGroupName: "",
  ordinal: 1,
  autoCleanup: true,
  description: "",
  isDataAutoPopulated: false,
  autoPopulateGroupId: "",
  autoPopulateTestDataType: {
    label: "Existing claims for frequently-seen data",
    value: "DataFromHighFrequencyClaims",
  },
  autoPopulateTestDataTypeVm: "",
  autoPopulateRecordCount: 10,
  autoPopulateClaimThreshold: 5,
  autoPopulateIsCobOnly: false,
  autoPopulateDataStartDate: new Date("1/1/2000"),
  autoPopulateDataEndDate: new Date("1/1/3000"),
  autoPopulateDataLastGeneratedBy: "",
  autoPopulateDataLastGeneratedDate: null,
  testVariableCsv: null,
  testVariables: [],
  testSteps: [],
};

export const emptyTestStep = {
  id: "",
  testId: "",
  ordinal: 1,
  description: "",
  testStepType: "API", // API, Processor
  testStepMethod: "Get", // Get, Put, Post, Delete
  requestHeaders: "",
  userImpersonateOID: "",
  roles: "",
  url: "",
  ipAddress: "",
  port: 0,
  processorRequestBody: "",
  expectedResponseJson: "",
  expectedResponseVm: "",
  expectedStatusCode: 0,
  lastRunDate: "",
  lastRunBy: "",
  lastRunDurationMilliseconds: 0,
  runResultStatus: "NotRun", // Pass, Fail, Error
  message: "",
};

export const emptyTestStepSearch = {
  orderBy: ["ordinal+"],
  pageSize: 100000,
  pageNumber: 1,
  highlightText: "",
};

export const emptyTestSearch = {
  description: "",
  lastRunBy: "",
  lastRunResultStatus: [],
  includeTestsInGroup: false,
  startDate: new Date("1/1/2000"),
  endDate: new Date("1/1/3000"),
  orderBy: ["description+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
  newTestDescription: "",
  oldTestDescription: "",
  copyId: "",
  showOnlyAutoPopulatedTests: false,
};

export const emptyTestDataFileErrorsSearch = {
  active: true,
  highlightText: "",
  pageSize: pageSize || 25,
  pageNumber: 1,
  colPageCount: 1,
  colPageNumber: 1,
  colPageSize: 5,
};

export function fromViewModel(vm) {
  let m = _.cloneDeep(vm);

  m.autoPopulateTestDataType = (
    m.autoPopulateTestDataTypeVm || { value: "DataFromHighFrequencyClaims" }
  ).value;

  if (m.autoPopulateDataStartDate > new Date("1/1/2999"))
    m.autoPopulateDataStartDate = null;
  if (m.autoPopulateDataEndDate > new Date("1/1/2999"))
    m.autoPopulateDataEndDate = null;

  (m.testSteps || []).forEach(
    (step) =>
      (step.expectedResponseJson = getExpectedResponseJsonFromString(
        step.ordinal,
        step.expectedResponseVm
      ))
  );

  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  vm.autoPopulateTestDataTypeVm = autoPopulateTestDataTypes.find(
    (d) => d.value === vm.autoPopulateTestDataType
  ) || {
    label: "Existing claims for frequently-seen data",
    value: "DataFromHighFrequencyClaims",
  };

  if (_.isNull(vm.autoPopulateDataStartDate))
    vm.autoPopulateDataStartDate = emptyTest.autoPopulateDataStartDate;
  else {
    vm.autoPopulateDataStartDate = parseISO(vm.autoPopulateDataStartDate);
  }

  if (_.isNull(vm.autoPopulateDataEndDate))
    vm.autoPopulateDataEndDate = emptyTest.autoPopulateDataEndDate;
  else {
    vm.autoPopulateDataEndDate = parseISO(vm.autoPopulateDataEndDate);
  }

  (vm.testSteps || []).forEach(
    (step) =>
      (step.expectedResponseVm = getExpectedResponseFromJson(
        step.ordinal,
        step.expectedResponseJson
      ))
  );

  return vm;
}

function getJsonFromContents(step, content) {
  if (!content || _.trim(content) === "") return "";
  const contentJson = "" + content;
  let json = {};

  try {
    json = JSON.parse(contentJson.replaceAll("'", '"'));
  } catch (ex) {
    console.log(`Cannot parse json on step ${step}: ${ex}`);
    return null;
  }

  return json;
}

function getValueAsString(value) {
  // Always return the value as a string with quotes around it
  return `"${value}"`;
}

function getExpectedResponseFromJson(step, jsonText) {
  if (jsonText === "") return "";
  const array = getJsonFromContents(step, _.trim(jsonText));
  if (array === null) return jsonText;

  let result = "";

  // If empty, just return empty string.
  if ((array || []).length === 0) return "";

  for (let i = 0; i < array.length; i++) {
    result += `${array[i].field ?? array[i].Field}: `;
    result += `${getValueAsString(array[i].value ?? array[i].Value)}\n`;
  }

  return result;
}

function getPropValue(v) {
  let value = "" + _.trim(v);
  if (!value.startsWith('"')) value = '"' + value;
  if (!value.endsWith('"')) value = value + '"';
  return value;
}

export function getExpectedResponseJsonFromString(step, jsonText) {
  if (jsonText === "") return "[]";
  let result = "[";

  const items = ("" + _.trim(jsonText)).split("\n");
  for (let i = 0; i < items.length; i++) {
    if (_.trim(items[i]) === "") continue;

    const pos = items[i].indexOf(":");
    const propField = items[i].substring(0, pos);
    const propValue = items[i].substring(pos + 1);

    if (pos >= 0) {
      // Only accept first 2 props
      result += "{";
      result += `"field": "${_.trim(propField)}"`;
      result += ", ";
      result += `"value": ${getPropValue(_.trim(propValue))}`;
      result += "}";
    } else {
      // Assume this is the prop name only
      result += "{";
      result += `"field": "${_.trim(propField)}"`;
      result += ", ";
      result += `"value": ""`;
      result += "}";
    }

    if (i < items.length - 1) result += ", ";
  }

  result += "]";

  // Try to parse this json. If it cannot be parsed, warn the user since this will cause the reload after save to fail.
  const saveResult = getJsonFromContents(step, result);
  if (saveResult === null) return null;

  return result;
}
