import React, { useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthProvider } from "../../../hooks/useAuthProvider";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import HeaderNavLink from "./HeaderNavLink";
import { useTestCoverageTracking } from "../../../contexts/TestCoverageTrackingContext";

function UserLoginLogout({
  menuToggleExpanded,
  showSignoutAsNavItem,
  isAdmin,
}) {
  const { isAuthenticated, userName, authActions } = useAuthProvider();
  const { userPrefs, setUserPrefs } = useUserPreferences();
  const { setTestCoverageTrackingData } = useTestCoverageTracking();
  const [showUserMenu, setShowUserMenu] = useState(false);

  let userMenuTimer = null;
  function handleMouseEnterUserMenu() {
    if (menuToggleExpanded) return;
    window.clearTimeout(userMenuTimer);
    setShowUserMenu(true);
  }

  function handleMouseLeaveUserMenu() {
    if (menuToggleExpanded) return;
    // Wait a small amount of time before actually closing the menu since a very small gap below the dropdown
    //   and above the menu can cause the menu to close immediately if the user moves the mouse slowly.
    window.clearTimeout(userMenuTimer);
    userMenuTimer = window.setTimeout(() => setShowUserMenu(false), 500);
  }

  function handleLightDarkModeSwitch() {
    setUserPrefs({
      type: ContextProviderActions.setDarkMode,
      payload: !userPrefs.darkMode,
    });
    document.location.reload();
  }

  function handleCoverageTrackingModeSwitch() {
    // If turning tracking off, clear any previous results
    if (userPrefs.coverageTrackingMode) {
      setTestCoverageTrackingData({
        type: ContextProviderActions.clearTestCoverageTracking,
        payload: null,
      });
    }

    setUserPrefs({
      type: ContextProviderActions.setCoverageTrackingMode,
      payload: !userPrefs.coverageTrackingMode,
    });

    // We have to reload here to keep from navigating back to the home page. This will reset coverage tracking too.
    document.location.reload();
  }

  return isAuthenticated ? (
    <>
      {showSignoutAsNavItem ? (
        <>
          <HeaderNavLink
            name="User"
            path=""
            isActive={false}
            hasPermission={true}
            isInDropdown={false}
            disabled={true}
          />
          <Nav.Link
            as={Link}
            to="/"
            onClick={handleLightDarkModeSwitch}
            className="nav-link"
          >
            {userPrefs.darkMode ? "Enable Light mode" : "Enable Dark mode"}
          </Nav.Link>
          {isAdmin && (
            <Nav.Link
              as={Link}
              to="/"
              onClick={handleCoverageTrackingModeSwitch}
              className="nav-link"
            >
              {userPrefs.coverageTrackingMode
                ? "Disable coverage tracking"
                : "Enable coverage tracking"}
            </Nav.Link>
          )}
          <Nav.Link
            as={Link}
            to="/"
            onClick={() => authActions.logout()}
            className="nav-link"
          >
            Sign Out
          </Nav.Link>
        </>
      ) : (
        <NavDropdown
          title={userName}
          show={showUserMenu}
          onMouseEnter={handleMouseEnterUserMenu}
          onMouseLeave={handleMouseLeaveUserMenu}
          onSelect={() => setShowUserMenu(false)}
          onClick={() => setShowUserMenu(!showUserMenu)}
        >
          <NavDropdown.Item onClick={handleLightDarkModeSwitch}>
            {userPrefs.darkMode ? "Enable Light mode" : "Enable Dark mode"}
          </NavDropdown.Item>
          <NavDropdown.Item onClick={handleCoverageTrackingModeSwitch}>
            {userPrefs.coverageTrackingMode
              ? "Disable coverage tracking"
              : "Enable coverage tracking"}
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => {
              authActions.logout();
            }}
          >
            Sign Out
          </NavDropdown.Item>
        </NavDropdown>
      )}
    </>
  ) : (
    <></>
  );
}

export default UserLoginLogout;
