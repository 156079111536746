import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptySocketServerConfig } from "../viewmodels/socketserverconfigVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadSocketServerConfig:
      return {
        ...state,
        socketServerConfig: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in SocketServerConfigContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  socketServerConfig: emptySocketServerConfig,
};

const SocketServerConfig = createContext({
  socketServerConfigData: initialState,
  setSocketServerConfigData: () => null,
});

// eslint-disable-next-line react/prop-types
const SocketServerConfigProvider = ({ children }) => {
  const [socketServerConfigData, setSocketServerConfigData] = useReducer(
    reducer,
    initialState
  );

  return (
    <SocketServerConfig.Provider
      value={{ socketServerConfigData, setSocketServerConfigData }}
    >
      {children}
    </SocketServerConfig.Provider>
  );
};

export default SocketServerConfigProvider;
export const useSocketServerConfig = () => useContext(SocketServerConfig);
