import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useMobile } from "../../../hooks/useMobile";

function SliderInput({
  name,
  id,
  label,
  minValue,
  maxValue,
  isColumnSlider = true,
  value,
  onChange,
}) {
  const { isMobileSize } = useMobile();
  const inputRef = useRef(null);

  useEffect(() => {
    if (!(inputRef && inputRef.current)) return;

    // Set up style properties used by the css for this tracker and an event listener to set the value
    //   This stuff is all used to display the colored progress bar to the left of the thumb in the tracker.
    function setValue(input, value) {
      input.style.setProperty("--value", value);
    }

    const e = inputRef.current;
    e.style.setProperty("--value", e.value);
    e.style.setProperty("--min", e.min === "" ? "0" : e.min);
    e.style.setProperty("--max", e.max === "" ? "100" : e.max);
    e.addEventListener("input", () => setValue(e, e.value));

    return () => e.removeEventListener("input", setValue);
  }, [inputRef?.current, value]);

  const ticks = [];
  const max = maxValue > 20 && isColumnSlider ? 20 : maxValue;
  const step = Math.ceil(max / 10);
  for (let i = minValue; i <= max; i = i + step) {
    ticks.push(i);
  }

  return (
    <StyledInputWrapper className="wrapper-range-input">
      {!isMobileSize && label !== "" && (
        <label htmlFor={name}>{label}:&nbsp;&nbsp;</label>
      )}
      <div style={{ width: "100%", maxWidth: "400px" }}>
        <StyledSlider
          ref={inputRef}
          type="range"
          id={id}
          name={name}
          min={minValue}
          max={max}
          value={value}
          className="tracker-primary"
          onChange={onChange}
          list="tickmarks"
        ></StyledSlider>
        <div>
          <datalist id="tickmarks">
            {ticks.map((tick) => (
              <option key={tick} value={tick} label={tick}></option>
            ))}
          </datalist>
        </div>
      </div>
    </StyledInputWrapper>
  );
}

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;

  &.wrapper-range-input {
    label {
      margin-top: -10px;
      font-size: 16px;
      color: var(--text-medium);
    }
  }

  datalist {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 2px 0 4px;
    padding-top: 9px;
  }

  option {
    width: 16px;
    padding: 0;
    color: var(--text-medium);
    font-size: 13px;
    font-family: courier;
    text-align: center;
  }
`;

const StyledSlider = styled.input`
  width: 100%;

  /* input tracker - cross browser styling */
  /*generated with Input range slider CSS style generator (version 20211225)
  https://toughengineer.github.io/demo/slider-styler*/

  height: 9px;
  max-width: 400px;
  -webkit-appearance: none;
  padding: 0;
  color: var(--text-normal);
  background-color: transparent;
  border: 0;

  /*progress support*/
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 40px + var(--ratio) * (100% - 40px));

  &:focus {
    outline: none;
  }

  /*webkit*/
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 1em;
    background: var(--slider-input-progress);
    border: none;
    box-shadow: 0 0 2px black;
    margin-top: calc(max((8px - 1px - 1px) * 0.5, 0px) - 20px * 0.5);
  }

  &::-webkit-slider-runnable-track {
    height: 8px;
    border: 1px solid var(--slider-input-progress);
    border-radius: 1em;
    background: var(--slider-input-track);
    box-shadow: none;
  }

  &::-webkit-slider-thumb:hover {
    background: var(--slider-input-progress-active);
  }

  &::-webkit-slider-thumb:active {
    background: var(--slider-input-progress-active);
  }

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
          var(--slider-input-progress),
          var(--slider-input-progress)
        )
        0 / var(--sx) 100% no-repeat,
      var(--slider-input-track);
  }

  &:hover::-webkit-slider-runnable-track {
    background: linear-gradient(
          var(--slider-input-progress-active),
          var(--slider-input-progress-active)
        )
        0 / var(--sx) 100% no-repeat,
      var(--slider-input-track);
  }

  &:active::-webkit-slider-runnable-track {
    background: linear-gradient(
          var(--slider-input-progress-active),
          var(--slider-input-progress-active)
        )
        0 / var(--sx) 100% no-repeat,
      var(--slider-input-track);
  }

  /*mozilla*/
  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 1em;
    background: var(--slider-input-progress);
    border: none;
    box-shadow: 0 0 2px black;
  }

  &::-moz-range-track {
    height: max(calc(8px - 1px - 1px), 0px);
    border: 1px solid var(--slider-input-progress);
    border-radius: 1em;
    background: var(--slider-input-track);
    box-shadow: none;
  }

  &::-moz-range-thumb:hover {
    background: var(--slider-input-progress-active);
  }

  &::-moz-range-thumb:active {
    background: var(--slider-input-progress-active);
  }

  &::-moz-range-track {
    background: linear-gradient(
          var(--slider-input-progress),
          var(--slider-input-progress)
        )
        0 / var(--sx) 100% no-repeat,
      var(--slider-input-track);
  }

  &:hover::-moz-range-track {
    background: linear-gradient(
          var(--slider-input-progress-active),
          var(--slider-input-progress-active)
        )
        0 / var(--sx) 100% no-repeat,
      var(--slider-input-track);
  }

  &:active::-moz-range-track {
    background: linear-gradient(
          var(--slider-input-progress-active),
          var(--slider-input-progress-active)
        )
        0 / var(--sx) 100% no-repeat,
      var(--slider-input-track);
  }

  /*ms*/
  &::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
  }

  &::-ms-fill-lower {
    background: transparent;
    border-color: transparent;
  }

  &::-ms-thumb {
    width: 20px;
    height: 20px;
    border-radius: 1em;
    background: var(--slider-input-progress);
    border: none;
    box-shadow: 0 0 2px black;
    margin-top: 0;
    box-sizing: border-box;
  }

  &::-ms-track {
    height: 8px;
    border-radius: 1em;
    background: var(--slider-input-track);
    border: 1px solid var(--slider-input-progress);
    box-shadow: none;
    box-sizing: border-box;
  }

  &::-ms-thumb:hover {
    background: var(--slider-input-progress-active);
  }

  &::-ms-thumb:active {
    background: var(--slider-input-progress-active);
  }

  &::-ms-fill-lower {
    height: max(calc(8px - 1px - 1px), 0px);
    border-radius: 1em 0 0 1em;
    margin: -1px 0 -1px -1px;
    background: var(--slider-input-progress);
    border: 1px solid var(--slider-input-progress);
    border-right-width: 0;
  }

  &:hover::-ms-fill-lower {
    background: var(--slider-input-progress-active);
  }

  &:active::-ms-fill-lower {
    background: var(--slider-input-progress-active);
  }
`;

SliderInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

SliderInput.defaultProps = {
  minValue: 1,
  maxValue: 100,
  label: "",
};

export default SliderInput;
