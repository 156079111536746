import React, { useState, useEffect } from "react";

import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createViewModel,
  fromViewModel,
  emptyClient,
} from "../../../viewmodels/clientsVm";
import DatePickerInput from "../../common/input/DatePickerInput";
import { handleCollapseExpandAll, states } from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import SelectInput from "../../common/input/SingleSelect";
import CheckboxInput from "../../common/input/CheckboxInput";
import { useClients } from "../../../contexts/ClientsContext";
import { useAuth } from "../../../contexts/AuthContext";
import {
  apiAddClient,
  apiLoadClient,
  apiUpdateClient,
} from "../../../api/ClientApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import SettingsSection from "../../common/ui/SettingsSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Client() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { clientsData, setClientsData } = useClients();
  const { loading, api: apiLoad } = useApi(apiLoadClient);
  const { loading: adding, api: apiAdd } = useApi(apiAddClient);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateClient);
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyClient);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Client", collapsed: false },
    { name: "Effective Range", collapsed: true },
    { name: "TPA Phone Replacement", collapsed: true },
    { name: "Contact", collapsed: true },
    { name: "Address", collapsed: true },
    { name: "Settings", collapsed: true },
  ]);

  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setClientsData({
        type: ContextProviderActions.loadClient,
        payload: emptyClient,
      });

      loadClient();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (clientsData.client) {
      setChanges(clientsData.client);
    } else {
      setChanges(emptyClient);
    }
  }, [clientsData.client]);

  async function loadClient() {
    if (!resId) {
      setClientsData({
        type: ContextProviderActions.loadClient,
        payload: emptyClient,
      });
      setChanges(emptyClient);
      return;
    }

    apiLoad.call(resId, (result) => {
      let vm = emptyClient;
      if (!result) {
        notifyError("Client does not exist");
      } else {
        vm = createViewModel(result);
      }

      setClientsData({
        type: ContextProviderActions.loadClient,
        payload: vm,
      });
      setChanges(vm);
    });
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    if (!_.has(changes, "clientId") || changes.clientId.trim() === "")
      _errors.clientId = "Client Id is required";

    if (!_.has(changes, "name") || changes.name.trim() === "")
      _errors.name = "Name must be entered";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleStateChange(value) {
    setChanges({ ...changes, stateVm: value });
  }

  function handleEffectiveDateChange(date) {
    setChanges({ ...changes, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  function handleTPAChange({ target }) {
    let changed = { ...changes };
    changed.tpaPhoneReplacement = target.checked;
    setChanges(changed);
  }

  function clearClientListCache() {
    // Copy, delete, and save operations invalidate the cache.
    setClientsData({
      type: ContextProviderActions.setClientListCache,
      payload: [],
    });
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };

    if (!resId) {
      addClient(newVm);
    } else updateClient(newVm.id, newVm);
  }

  async function addClient(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      setChanges(createViewModel(result));
      clearClientListCache();
      notifySuccess("Client " + vm.clientId + " saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/client/" + result.id, { replace: true });
    });
  }

  async function updateClient(clientId, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id: clientId, model }, (result) => {
      setChanges(createViewModel(result));
      clearClientListCache();
      notifySuccess("Client " + vm.clientId + " saved successfully");
    });
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return To Clients"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate("/configureclients")}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Configure/Client-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            Client Details {changes.clientId ? ` | ${changes.clientId}` : ""}
          </h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/configureclients")}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {loading || adding || updating ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/client/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Client"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Client-Screen&anchor=client"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="clientId"
                      label="Client Id"
                      onChange={handleChange}
                      placeholder="Client Id"
                      name="clientId"
                      value={changes.clientId || ""}
                      error={errors.clientId}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="name"
                      label="Name"
                      onChange={handleChange}
                      placeholder="Name"
                      name="name"
                      value={changes.name || ""}
                      error={errors.name}
                    />
                  </div>
                  <div className="col-12 col-lg-4">
                    <TextInput
                      id="description"
                      label="Description"
                      onChange={handleChange}
                      placeholder="Description"
                      name="description"
                      value={changes.description || ""}
                      error={errors.description}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Effective Range"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Client-Screen&anchor=effective-range"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="effectiveDate"
                      name="effectiveDate"
                      label="Effective Date"
                      value={changes.effectiveDate}
                      placeholder="Effective Date"
                      onChange={handleEffectiveDateChange}
                      error={errors.effectiveDate}
                      showTimeInput={false}
                    />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="terminationDate"
                      label="Termination Date"
                      name="terminationDate"
                      value={changes.terminationDate}
                      placeholder="Termination Date"
                      onChange={handleTerminationDateChange}
                      error={errors.terminationDate}
                      showTimeInput={false}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="TPA Phone Replacement"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Client-Screen&anchor=tpa-phone-replacement"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-4 col-xl-3">
                    <CheckboxInput
                      id="tpaPhoneReplacement"
                      label="Replacement"
                      onChange={handleTPAChange}
                      placeholder="TPA Phone Replacement"
                      name="tpaPhoneReplacement"
                      checked={changes.tpaPhoneReplacement}
                      error={errors.tpaPhoneReplacement}
                    />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3">
                    <TextInput
                      id="tpaPhoneReplacementMemberId"
                      label="Replacement Member Id"
                      onChange={handleChange}
                      placeholder="TPA Phone Replacement Member Id"
                      name="tpaPhoneReplacementMemberId"
                      value={changes.tpaPhoneReplacementMemberId || ""}
                      error={errors.tpaPhoneReplacementMemberId}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Contact"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Client-Screen&anchor=contact"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="lastName"
                      label="Last Name"
                      onChange={handleChange}
                      placeholder="Last Name"
                      name="lastName"
                      value={changes.lastName || ""}
                      error={errors.lastName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="firstName"
                      label="First Name"
                      onChange={handleChange}
                      placeholder="First Name"
                      name="firstName"
                      value={changes.firstName || ""}
                      error={errors.firstName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="middleName"
                      label="Middle Name"
                      onChange={handleChange}
                      placeholder="Middle Name"
                      name="middleName"
                      value={changes.middleName || ""}
                      error={errors.middleName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="title"
                      label="Title"
                      onChange={handleChange}
                      placeholder="Title"
                      name="title"
                      value={changes.title || ""}
                      error={errors.title}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="subtitle"
                      label="Subtitle"
                      onChange={handleChange}
                      placeholder="Subtitle"
                      name="subtitle"
                      value={changes.subtitle || ""}
                      error={errors.subtitle}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="suffix"
                      label="Suffix"
                      onChange={handleChange}
                      placeholder="Suffix"
                      name="suffix"
                      value={changes.suffix || ""}
                      error={errors.suffix}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="number"
                      label="Phone Number"
                      onChange={handleChange}
                      placeholder="Phone Number"
                      name="number"
                      value={changes.number || ""}
                      error={errors.number}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Address"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Client-Screen&anchor=address"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="emailAddresses"
                      label="Email Addresses"
                      onChange={handleChange}
                      placeholder="Email Addresses"
                      name="emailAddressesVm"
                      value={changes.emailAddressesVm}
                      error={errors.emailAddresses}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="address"
                      label="Address"
                      onChange={handleChange}
                      placeholder="Address"
                      name="address"
                      value={changes.address || ""}
                      error={errors.address}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="city"
                      label="City"
                      onChange={handleChange}
                      placeholder="City"
                      name="city"
                      value={changes.city || ""}
                      error={errors.city}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <SelectInput
                      id="state"
                      name="state"
                      label="State"
                      options={states}
                      value={changes.stateVm || ""}
                      onChange={handleStateChange}
                      placeholder="Select a State"
                      error={errors.state}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="zipCode"
                      label="Zip Code"
                      onChange={handleChange}
                      placeholder="Zip Code"
                      name="zipCode"
                      value={changes.zipCode || ""}
                      error={errors.zipCode}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Settings"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Client-Screen&anchor=settings"
              >
                <SettingsSection
                  settingsArrayName="defaultOptions.settings"
                  changes={changes}
                  setChanges={setChanges}
                  helpLink="/Configure/Client-Screen&anchor=settings-dialog"
                />
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default Client;
