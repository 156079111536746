import React from "react";
import {
  StyledNoResultsDiv,
  StyledRowDiv,
} from "../../common/layout/CommonStyledControls";
import { useMobile } from "../../../hooks/useMobile";
import { StyledWrapper } from "./RuleGroupSummaryCommonStyles";
import RuleGroupSummaryRule from "./RuleGroupSummaryRule";

function RuleGroupSummaryRules({ items }) {
  const { isMobileSize, isTabletSize } = useMobile();

  return (
    <>
      {items.length === 0 && (
        <StyledRowDiv className="row" style={{ marginTop: "5px" }}>
          <StyledNoResultsDiv>
            <p>
              <i className="material-icons">search_off</i>
            </p>
            <p>No rules found for this group</p>
          </StyledNoResultsDiv>
        </StyledRowDiv>
      )}
      {items.length > 0 && (
        <StyledRowDiv className="row">
          <p style={{ marginBottom: 0, fontStyle: "italic" }}>
            The following rules are processed for the group in this priority
            order:
          </p>
          <StyledWrapper
            className="col-12"
            isSmallScreen={isMobileSize || isTabletSize}
            style={{
              marginTop: "10px",
              gridTemplateColumns: "repeat(1,1fr)",
            }}
          >
            {items.map((r, idx) => {
              return (
                <RuleGroupSummaryRule
                  key={`${r.name}-${idx}`}
                  priority={idx + 1}
                  item={r}
                />
              );
            })}
          </StyledWrapper>
        </StyledRowDiv>
      )}
    </>
  );
}

export default RuleGroupSummaryRules;
