import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import {
  getHighlightStyle,
  getRowStyle,
  getTextStyle,
  handleIndexedAdd,
  handleIndexedChange,
  handleIndexedRemove,
  handleRowDown,
  handleRowUp,
  handleStagingChange,
} from "./RuleCommonFunctions";
import RuleEditorInput from "../../common/input/RuleEditorInput";

function RuleAreaConditionals({
  isSmallSize,
  isGroupRule,
  conditionals,
  ruleChanges,
  setRuleChanges,
  handleOpenDates,
  handleIndexedCopy,
}) {
  const navigate = useNavigate();
  const isFiltered = (ruleChanges.textSearch || "").length > 0;

  return (
    <>
      <StyledRowDiv className="row">
        <div className="col-12 overflow-x-scroll">
          <table
            className={`table rule-grid ${isSmallSize ? "not-fixed" : ""}`}
          >
            <colgroup>
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "2%" }} />
              {!isGroupRule && (
                <>
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "2%" }} />
                </>
              )}
            </colgroup>
            <thead>
              <tr>
                <th id="attachedTo">Attached To</th>
                <th id="attachedToType">Attached To Type</th>
                <th id="keyType">Key Type</th>
                <th id="key">Key</th>
                <th id="operator">Operator</th>
                <th id="values">Value(s)</th>
                <th id="stagingOnly">Staging</th>
                {!isGroupRule ? (
                  <>
                    <th> </th>
                    <th> </th>
                    <th> </th>
                    <th>
                      <i
                        className="fa fa-plus-circle pointer"
                        onClick={(e) =>
                          handleIndexedAdd(
                            "conditionals",
                            ruleChanges,
                            setRuleChanges
                          )
                        }
                      ></i>
                    </th>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody>
              {conditionals.map((eof, eofIndex) => {
                return (
                  <Fragment key={eofIndex}>
                    <tr key={"eoftr" + eofIndex} style={getRowStyle(eof)}>
                      <td id="attachedTo">
                        <input
                          id={"eofAttachedTo"}
                          type="text"
                          name="attachedTo"
                          className="form-control"
                          style={getHighlightStyle(eof, false)}
                          placeholder="Attached To"
                          onChange={(e) =>
                            handleIndexedChange(
                              "conditionals",
                              e,
                              eof.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value={eof.attachedTo || ""}
                        ></input>
                      </td>
                      <td>
                        <select
                          style={getTextStyle(eof)}
                          className="form-select"
                          id={"eofAttachedToType"}
                          name="attachedToType"
                          value={eof.attachedToType || ""}
                          onChange={(e) =>
                            handleIndexedChange(
                              "conditionals",
                              e,
                              eof.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                        >
                          <option value="Artifact">Artifact</option>
                          <option value="Rejection">Rejection</option>
                          <option value="RejectionOverride">
                            Rejection Override
                          </option>
                          <option value="IncludedData">Included Data</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          style={getTextStyle(eof)}
                          id="eofValueType"
                          key={eofIndex}
                          name="valueType"
                          value={eof.valueType}
                          onChange={(e) =>
                            handleIndexedChange(
                              "conditionals",
                              e,
                              eof.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                        >
                          <option value="RequestFieldValue">
                            Request Field Value
                          </option>
                          <option value="ResponseFieldValue">
                            Response Field Value
                          </option>
                          <option value="InternalVariable">
                            Internal Variable
                          </option>
                          <option value="EntityData">Entity Data</option>
                          <option value="GroupSetting">Group Setting</option>
                        </select>
                      </td>
                      <td id="name">
                        <input
                          id={"eofName"}
                          style={getTextStyle(eof)}
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Key"
                          onChange={(e) =>
                            handleIndexedChange(
                              "conditionals",
                              e,
                              eof.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value={eof.name || ""}
                        ></input>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          style={getTextStyle(eof)}
                          id={"eofOperator"}
                          key={eofIndex}
                          name="operator"
                          value={eof.operator || ""}
                          onChange={(e) =>
                            handleIndexedChange(
                              "conditionals",
                              e,
                              eof.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                        >
                          <option value="=">Equal</option>
                          <option value="<>">Not Equal</option>
                          <option value="isempty">Is Empty</option>
                          <option value="isnotempty">Is Not Empty</option>
                          <option value="in">In</option>
                          <option value="notin">Is Not In</option>
                          <option value=">">Greater Than</option>
                          <option value="<">Less Than</option>
                          <option value=">=">Greater Than or Equal</option>
                          <option value="<=">Less Than or Equal</option>
                          <option value="startsWith">Starts With</option>
                          <option value="endsWith">Ends With</option>
                          <option value="isltlength">
                            Is Less Than Length
                          </option>
                          <option value="isnull">Is Null</option>
                          <option value="notnull">Is Not Null</option>
                          <option value="istrue">Is True</option>
                          <option value="isfalse">Is False</option>
                          <option value="multipleof">Multiple Of</option>
                          <option value="notmultipleof">Not Multiple Of</option>
                          <option value="iszero">Is Zero</option>
                          <option value="isnotzero">Is Not Zero</option>
                          <option value="matches">Matches</option>
                          <option value="nmatches">Not Matches</option>
                        </select>
                      </td>
                      <td>
                        <RuleEditorInput
                          id="eofValues"
                          key={eofIndex}
                          label=""
                          labelStyle={{ display: "none" }}
                          onChange={(e) =>
                            handleIndexedChange(
                              "conditionals",
                              e,
                              eof.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          placeholder="Values"
                          name="valuesVm"
                          value={eof.valuesVm || ""}
                          inputStyle={getTextStyle(eof)}
                        />
                      </td>
                      <td>
                        <input
                          id="stagingOnly"
                          type="checkbox"
                          name="stagingOnly"
                          className="form-check-input"
                          placeholder="Staging Only"
                          onChange={(e) =>
                            handleStagingChange(
                              "conditionals",
                              e,
                              eof.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value="Staging Only"
                          checked={eof.stagingOnly}
                        ></input>
                      </td>
                      {!isGroupRule ? (
                        <>
                          <td>
                            <i
                              className="fa fa-calendar pointer"
                              onClick={() =>
                                handleOpenDates(eof, "conditionals", eof.id)
                              }
                            ></i>
                          </td>
                          <td>
                            <i
                              className="fa fa-clone pointer"
                              onClick={() =>
                                handleIndexedCopy("conditionals", eofIndex)
                              }
                            ></i>
                          </td>
                          <td>
                            {isFiltered ? (
                              <>&nbsp;</>
                            ) : (
                              <>
                                <i
                                  className="fa fa-arrow-up pointer"
                                  onClick={() =>
                                    handleRowUp(
                                      "conditionals",
                                      eof.id,
                                      ruleChanges,
                                      setRuleChanges
                                    )
                                  }
                                ></i>
                                <i
                                  className="fa fa-arrow-down pointer"
                                  onClick={() =>
                                    handleRowDown(
                                      "conditionals",
                                      eof.id,
                                      ruleChanges,
                                      setRuleChanges
                                    )
                                  }
                                ></i>
                              </>
                            )}
                          </td>
                          <td>
                            <i
                              className="fa fa-times pointer"
                              onClick={() =>
                                handleIndexedRemove(
                                  "conditionals",
                                  eof.id,
                                  ruleChanges,
                                  setRuleChanges
                                )
                              }
                            ></i>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                    {isGroupRule ? (
                      <tr key={"eoftrs" + eofIndex} style={getRowStyle(eof)}>
                        <td
                          colSpan="2"
                          style={{
                            fontSize: "0.7em",
                            padding: "0px",
                            textAlign: "left",
                          }}
                        >
                          Artifact: {eof.artifact}
                        </td>
                        <td
                          colSpan="20"
                          style={{
                            fontSize: "0.7em",
                            padding: "0px",
                            textAlign: "right",
                          }}
                        >
                          Rule:{" "}
                          <button
                            className="btn-link"
                            style={{ border: "0px" }}
                            onClick={() =>
                              navigate("/rule/" + eof.sourceRuleId)
                            }
                          >
                            {eof.sourceRule}
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </StyledRowDiv>
    </>
  );
}

export default RuleAreaConditionals;
