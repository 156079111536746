import React from "react";
import styled from "styled-components";
import ParsedPacketField from "./ParsedPacketField";
import ParsedPacketRecord from "./ParsedPacketRecord";

function ParsedPacketGroup({
  group,
  isReadOnly,
  fields,
  transactionNumber = 0,
  displayType = "condensed",
  setDisplayType,
}) {
  function getFieldData() {
    if (
      !group.isHeader ||
      !group.headerContents ||
      (group.headerContents.fields || []).length < 1
    )
      return null;

    // Locate data about this group's second field in the fields array (past the AM field)
    const field = group.headerContents.fields[1];
    const data = fields.find(
      (f) =>
        f.transactionIndex === field.transactionIndex &&
        f.segmentCode === field.segmentCode &&
        f.fieldGroupOrdinal === field.fieldGroupOrdinal &&
        f.fieldId === field.fieldId
    );

    return data ? data : null;
  }

  const groupFieldData = getFieldData();

  const groupHeader =
    groupFieldData === null ? "" : groupFieldData.segmentDescription;

  return (
    <>
      {group.isHeader ? (
        <StyledHeaderSpan displayType={displayType}>
          {displayType !== "condensed" && (
            <StyledGSSpanWrapper
              displayType={displayType}
              style={{ marginBottom: "20px" }}
            >
              <StyledGSSpan
                key={`GS${group.id}`}
                displayType={displayType}
                className="parser-control-char"
                style={{ maxWidth: "60px" }}
              >
                HEADER
              </StyledGSSpan>
              {groupHeader}
            </StyledGSSpanWrapper>
          )}
          {group.headerContents.fields.map((field) => (
            <ParsedPacketField
              key={field.id}
              field={field}
              isReadOnly={isReadOnly}
              displayType={displayType}
              setDisplayType={setDisplayType}
              fields={fields}
            />
          ))}
        </StyledHeaderSpan>
      ) : (
        <>
          <StyledGSSpanWrapper displayType={displayType}>
            <StyledGSSpan
              key={`GS${group.id}`}
              displayType={displayType}
              className="parser-control-char"
            >
              TX
            </StyledGSSpan>
            {displayType === "condensed"
              ? ""
              : " Transaction #" + transactionNumber}
          </StyledGSSpanWrapper>
        </>
      )}
      {group.records.map((record) => (
        <ParsedPacketRecord
          key={record.id}
          record={record}
          isReadOnly={isReadOnly}
          displayType={displayType}
          setDisplayType={setDisplayType}
          fields={fields}
        />
      ))}
    </>
  );
}

const StyledHeaderSpan = styled.span`
  background-color: transparent;
  padding: ${(props) => (props.displayType === "condensed" ? "0 2px" : "0")};
  font-family: courier;
  font-size: 16px;
`;

const StyledGSSpan = styled.span`
  margin-left: ${(props) => (props.displayType === "condensed" ? "6px" : "0")};
  margin-right: ${(props) =>
    props.displayType === "condensed" ? "0" : "10px"};
  color: #fff;
  background-color: green;

  &.parser-control-char {
    max-width: ${(props) =>
      props.displayType === "condensed" ? "100%" : "26px"};
  }
`;

const StyledGSSpanWrapper = styled.span`
  margin-top: ${(props) => (props.displayType === "condensed" ? "0" : "20px")};
  display: ${(props) =>
    props.displayType === "condensed" ? "inline-block" : "block"};
`;

export default ParsedPacketGroup;
