/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadReportProcesses(search, token, signal) {
  const uri = API_URL + "/reportprocesses" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({
      operand: "OR",
      name: "description",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "reportName",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "ruleTableName",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "lastRunBy",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.description && search.description.length > 0)
    where.push({
      name: "description",
      operator: "=",
      values: [search.description],
    });

  if (search && search.reportName && search.reportName.length > 0)
    where.push({
      name: "reportName",
      operator: "=",
      values: [search.reportName],
    });

  if (search && search.ruleTableName && search.ruleTableName.length > 0)
    where.push({
      name: "ruleTableName",
      operator: "=",
      values: [search.ruleTableName],
    });

  if (search && search.lastRunBy && search.lastRunBy.length > 0)
    where.push({
      name: "lastRunBy",
      operator: "=",
      values: [search.lastRunBy],
    });

  if (search && search.startDate && _.isDate(search.startDate))
    where.push({
      name: "lastModifiedDate",
      operator: ">=",
      values: [search.startDate],
    });
  if (search && search.endDate && _.isDate(search.endDate))
    where.push({
      name: "lastModifiedDate",
      operator: "<",
      values: [search.endDate],
    });

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy || '["name+"]' };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadReportProcess(id, token, signal) {
  let uri = API_URL + "/reportprocess/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddReportProcess(m, token, signal) {
  let uri = API_URL + "/reportprocess" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateReportProcess(params, token, signal) {
  const id = params.id;
  const model = params.model;

  let uri = API_URL + "/reportprocess/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteReportProcess(id, token, signal) {
  let uri = API_URL + "/reportprocess/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCopyReportProcess(params, token, signal) {
  const id = params.id;
  const newId = params.newId;

  let uri =
    API_URL +
    "/reportprocesscopy/" +
    encodeURIComponent(id) +
    "/" +
    encodeURIComponent(newId) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRunReportProcess(id, token, signal) {
  let uri = API_URL + "/runreportprocess/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiSaveReportLastValues(params, token, signal) {
  const detailHistoryId = params.detailHistoryId;
  const lastRunValues = [];
  lastRunValues.push(params.row);
  const model = { lastRunValues: lastRunValues };

  let uri =
    API_URL + "/updatelastresults/" + detailHistoryId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiClearProcessHistory(id, token, signal) {
  let uri = API_URL + "/clearprocesshistory/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadReportProcesses.funcName = "apiLoadReportProcesses";
apiLoadReportProcess.funcName = "apiLoadReportProcess";
apiAddReportProcess.funcName = "apiAddReportProcess";
apiUpdateReportProcess.funcName = "apiUpdateReportProcess";
apiDeleteReportProcess.funcName = "apiDeleteReportProcess";
apiCopyReportProcess.funcName = "apiCopyReportProcess";
apiRunReportProcess.funcName = "apiRunReportProcess";
apiSaveReportLastValues.funcName = "apiSaveReportLastValues";
apiClearProcessHistory.funcName = "apiClearProcessHistory";
