import React from "react";
import {
  formatDateOnly,
  formatDateTimeUtcZoneForDisplay,
} from "../../../services/General";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function MembersList({
  members,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const memberId = dataRow.memberId;

    const actions = [
      {
        name: "View Details",
        onClick: () => navigate("/member/" + id),
      },
      {
        name: "Remove",
        onClick: () => onDelete(id, memberId),
      },
    ];

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/member/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="Members"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={members}
        columnDefs={[
          {
            name: "memberId",
            label: "Member Id",
            style: { width: "35%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/member/" + row.id)}
              >
                {row.memberId}
              </button>
            ),
          },
          {
            name: "groupId",
            label: "Group Id",
            className: "d-none d-md-table-cell",
            style: { width: "15%" },
          },
          {
            name: "dateOfBirth",
            label: "DOB",
            style: { width: "20%" },
            getValue: (row) => formatDateOnly(row.dateOfBirth),
          },
          {
            name: "personCode",
            label: "Person",
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
          },
          {
            name: "memberInfo.firstName",
            label: "First Name",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
            getValue: (row) => row.memberInfo.firstName,
          },
          {
            name: "memberInfo.lastName",
            label: "Last Name",
            style: { width: "25%" },
            getValue: (row) => row.memberInfo.lastName,
          },
          {
            name: "addresses[0].address1",
            label: "Address",
            className: "d-none d-xl-table-cell",
            style: { width: "25%" },
            getValue: (row) => row.address,
          },
          {
            name: "addresses[0].city",
            label: "City",
            className: "d-none d-xl-table-cell",
            style: { width: "25%" },
            getValue: (row) => row.city,
          },
          {
            name: "addresses[0].state",
            label: "State",
            className: "d-none d-xl-table-cell",
            style: { width: "15%" },
            getValue: (row) => row.state,
          },
          {
            name: "lastModifiedDate",
            label: "Created/Modified",
            className: "d-none d-xl-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.lastModifiedDate),
          },
          {
            name: "active",
            label: "Active",
            className: "d-none d-xl-table-cell",
            style: { width: "6%" },
            getValue: (row) =>
              row.active ? <span className="material-icons">check</span> : "",
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default MembersList;
