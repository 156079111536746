import React from "react";
import Select from "react-select";
import styled from "styled-components";
import { useMobile } from "../../../hooks/useMobile";

function SelectInput({
  name,
  id,
  value,
  label,
  disabled,
  error,
  onChange,
  options,
  placeholder,
  labelStyle,
  selectStyle,
  controlStyle,
  autoFocus,
  loading,
}) {
  const { isMobileSize } = useMobile();

  let wrapperClass = "form-group";
  if (error.length > 0) {
    wrapperClass += " has-error";
  }

  const styles = {
    valueContainer: (provided) => ({ ...provided, ...selectStyle }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "var(--input-bg)",
      borderColor: "var(--input-border)",
      color: "var(--input-text)",
      ...controlStyle,
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "var(--submenu-bg)",
      borderColor: "var(--submenu-border)",
      color: "var(--submenu-text)",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isFocused
        ? "var(--submenu-active-bg)"
        : "var(--submenu-bg)",
      color: state.isFocused
        ? "var(--submenu-active-text)"
        : "var(--submenu-text)",
      ":active": {
        backgroundColor: "var(--submenu-active-bg)",
        color: "var(--submenu-active-text)",
      },
    }),
  };

  return (
    <div className={wrapperClass}>
      <label id={`${id}-label`} htmlFor={id} style={labelStyle || {}}>
        {label}
      </label>
      <div className="field" style={{ position: "relative" }}>
        {loading && (
          <StyledLoadingDiv>
            <span className="material-symbols-outlined rotating">sync</span>
          </StyledLoadingDiv>
        )}
        <StyledSelect
          aria-label={`SELECT-${label}`}
          aria-labelledby={`${id}-label`}
          isDisabled={disabled || loading}
          id={id}
          name={name}
          options={options}
          styles={styles}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          blurInputOnSelect={true}
          isSearchable={!isMobileSize}
          autoFocus={autoFocus || false}
        />
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

const StyledLoadingDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSelect = styled(Select)`
  & > div {
    color: var(--input-text);
    background-color: var(--input-bg);
    border: 1px solid var(--input-border);
    border-radius: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 3px 0 2px 0;
    transition: none;

    &:hover {
      border: 1px solid var(--input-border);
    }

    svg {
      color: var(--input-dark);
    }

    & > div > div {
      color: var(--input-text);
    }
  }
`;

SelectInput.defaultProps = {
  error: "",
};

export default SelectInput;
