import React from "react";
import {
  convertLocalDateToUTC,
  convertUTCDateStringToUTCDate,
  formatDateTimeUtcZoneForDisplay,
} from "../../../services/General";

function DbUtilsStatusSection({
  isAppRunning,
  lastStarted,
  lastCompleted,
  lastError,
  isDbUpdate = false,
}) {
  const RECENT_DATE_HOURS_AGO = 24;
  const LAST_SYNC_THRESHOLD_HOURS_AGO = isDbUpdate ? 24 * 7 : 24;

  function isDateLessThanXHoursAgo(checkDate, threshold) {
    const now = new Date(convertLocalDateToUTC(new Date()).toISOString());
    const compareDate = convertUTCDateStringToUTCDate(checkDate);

    const diffTime = Math.abs(now - compareDate);
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    if (diffHours <= threshold) {
      return true;
    } else {
      return false;
    }
  }

  function getStatusIcon(isGood) {
    return (
      <span
        className="material-icons"
        style={{
          display: "inline-block",
          marginRight: "10px",
          color: isGood ? "var(--notify-success)" : "var(--notify-danger)",
        }}
      >
        {isGood ? "check" : "warning"}
      </span>
    );
  }

  const hasError =
    lastError &&
    lastError.errorState === true &&
    isDateLessThanXHoursAgo(lastError.errorDate, RECENT_DATE_HOURS_AGO);

  const isSyncOld = !isDateLessThanXHoursAgo(
    lastCompleted,
    LAST_SYNC_THRESHOLD_HOURS_AGO
  );

  return (
    <>
      <div className="flex-row-without-wrap">
        {getStatusIcon(isAppRunning)}
        <span>
          {isAppRunning
            ? "Container app is running. "
            : "Container app is stopped. "}
        </span>
      </div>
      <div className="flex-row-without-wrap">
        {getStatusIcon(!isSyncOld)}
        <span>
          {isSyncOld && (
            <>
              Last {isDbUpdate ? "update" : "sync"} over{" "}
              {isDbUpdate
                ? Math.ceil(LAST_SYNC_THRESHOLD_HOURS_AGO / 24)
                : LAST_SYNC_THRESHOLD_HOURS_AGO}{" "}
              {isDbUpdate ? "days" : "hours"} ago.{" "}
            </>
          )}
          {isDbUpdate ? "Update" : "Sync"} last started{" "}
          {formatDateTimeUtcZoneForDisplay(lastStarted)} and last completed{" "}
          {formatDateTimeUtcZoneForDisplay(lastCompleted)}.
        </span>
      </div>
      <div className="flex-row-without-wrap">
        {getStatusIcon(!hasError)}
        <span>
          {hasError
            ? `Last error occurred at ${formatDateTimeUtcZoneForDisplay(
                lastError.errorDate
              )}. Error: ${lastError.errorMessage}`
            : "No recent errors have occurred. "}
        </span>
      </div>
    </>
  );
}

export default DbUtilsStatusSection;
