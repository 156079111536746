import React, { useEffect, useRef, useState } from "react";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import {
  StyledCard,
  StyledCardLeafNodeList,
  StyledCardValue,
  StyledDescription,
  StyledNodeDiv,
  StyledProcessedIndicator,
} from "./RuleMapCommonStyles";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
  NodeTypes,
} from "./RuleMapDataCommon";
import RuleMapCopyContentsLink from "./RuleMapCopyContentsLink";
import { useGroupRuleMaps } from "../../../contexts/GroupRuleMapsContext";

export const DefaultNodeSize = { width: 450, height: 70 };

function RuleMapModuleNode({ data }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const [detailLevel] = useState(1);
  const { ruleMapsData } = useRuleMaps();
  const { groupRuleMapsData } = useGroupRuleMaps();
  const [nodeWidth] = useState(DefaultNodeSize.width);
  const [nodeHeight] = useState(DefaultNodeSize.height);
  const divRef = useRef(null);

  useEffect(() => {
    let doNodeUpdate = false;
    if (data.layoutDirection !== ruleMapsData?.layoutDirection) {
      doNodeUpdate = true;
    }
    if (detailLevel !== ruleMapsData?.detailLevel) {
      doNodeUpdate = true;
    }

    if (doNodeUpdate) {
      updateNodeInternals(data.id);
    }
  }, [
    ruleMapsData?.detailLevel,
    data.layoutDirection,
    ruleMapsData?.layoutDirection,
  ]);

  const colors = getBadgeColorsFromType(
    data.type,
    data.isActualNodeLink,
    data.isProcessed
  );

  const subnodes = (data.subnodes || []).sort(
    (a, b) =>
      a.type.description.localeCompare(b.type.description) ||
      a.name.localeCompare(b.name)
  );
  const subnodeCount = subnodes.length;

  const showDetails =
    ruleMapsData?.detailLevel === 3 || ruleMapsData?.selectedNodeId === data.id;

  return (
    <StyledNodeDiv width={nodeWidth} height={nodeHeight}>
      {!data.isLeafNode && (
        <Handle
          type="target"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Left
              : Position.Top
          }
        />
      )}
      <StyledCard colors={colors} className="rule-map-module" ref={divRef}>
        {!data.isActualNodeLink && data.isProcessed && (
          <StyledProcessedIndicator>
            <span className="material-symbols-outlined">electric_bolt</span>
          </StyledProcessedIndicator>
        )}
        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={colors}
        >
          {groupRuleMapsData?.editMode === false && (
            <RuleMapCopyContentsLink
              show={showDetails}
              text={divRef.current?.innerText}
            />
          )}
          <span style={{ fontSize: "24px", fontFamily: "Times New Roman" }}>
            {data.itemKey}
          </span>
          <span className="charblock">
            {data.type === NodeTypes.ModuleInputNode.description
              ? "MOD INPUT"
              : "MODULE"}
          </span>
        </StyledDescription>
        <StyledCardValue>
          {`${subnodeCount} ${subnodeCount === 1 ? "subnode" : "subnodes"}`}
        </StyledCardValue>

        {showDetails && subnodeCount > 0 && (
          <StyledCardLeafNodeList>
            {subnodes.map((n, lidx) => (
              <li key={`leaf-${lidx}-${n.name}`}>
                <StyledDescription
                  colors={getBadgeColorsFromType(n.type.description)}
                  style={{ columnGap: "10px", marginBottom: "5px" }}
                >
                  <span className="charblock">
                    {getNodeTypeAbbreviation(n.type, false)}
                  </span>
                  <span>{n.name}</span>
                  <span
                    className={`option-member-name ${
                      (n.value || "").length > 100 ? "full-width" : ""
                    }`}
                  >
                    {n.value}
                  </span>
                </StyledDescription>
              </li>
            ))}
          </StyledCardLeafNodeList>
        )}
      </StyledCard>

      {!data.isRootNode && (
        <Handle
          type="source"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Right
              : Position.Bottom
          }
        />
      )}
    </StyledNodeDiv>
  );
}

export default RuleMapModuleNode;
