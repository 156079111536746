import React from "react";
import { useReports } from "../../../contexts/ReportsContext";
import Spinner from "../../common/ui/Spinner";
import { StyledNoResultsDiv } from "../../common/layout/CommonStyledControls";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";

function ReportResultsTemplate({
  hasReportRun,
  loadingReport,
  downloadingReport,
  hasUnsavedChanges,
  onRunReport,
  onDownloadReport,
}) {
  const { reportsData, setReportsData } = useReports();

  function handleRunReportBtnClick() {
    const _search = { ...reportsData.resultsSearch, pageNumber: 1 };

    setReportsData({
      type: ContextProviderActions.saveReportResultsSearch,
      payload: _search,
    });

    onRunReport(_search);
  }

  return (
    <>
      <div className="display-flex" style={{ alignItems: "center" }}>
        <button
          type="button"
          className="btn btn-primary btn-with-icon"
          onClick={() => handleRunReportBtnClick()}
          disabled={hasUnsavedChanges}
        >
          {hasUnsavedChanges ? (
            <>Save changes before running</>
          ) : (
            <>
              <span className="material-icons">play_circle_outline</span>
              {"  "}Run Report
            </>
          )}
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-with-icon"
          style={{ marginLeft: "auto" }}
          onClick={() => onDownloadReport(true)}
          disabled={downloadingReport || hasUnsavedChanges}
        >
          {downloadingReport ? (
            <>
              {" "}
              <span className="material-icons">hourglass_empty</span>
              Downloading...
            </>
          ) : hasUnsavedChanges ? (
            <>Save changes before downloading</>
          ) : (
            <>
              {" "}
              <span className="material-icons">download</span>
              Download (template)
            </>
          )}
        </button>
      </div>

      {loadingReport ? (
        <Spinner spinnerStyle={{ height: "200px", lineHeight: "200px" }} />
      ) : (
        <>
          <div style={{ marginTop: "10px" }}>
            {hasReportRun !== true ? (
              <StyledNoResultsDiv>
                <p>
                  <i className="material-icons">search</i>
                </p>
                <p>Results will appear here</p>
              </StyledNoResultsDiv>
            ) : (
              <>
                {reportsData.resultsTemplateContents === "" ? (
                  <StyledNoResultsDiv>
                    <p>
                      <i className="material-icons">search_off</i>
                    </p>
                    <p>No results found</p>
                  </StyledNoResultsDiv>
                ) : (
                  <div
                    className="rule-editor"
                    style={{
                      marginTop: "20px",
                      minHeight: "400px",
                      height: "400px",
                    }}
                  >
                    {reportsData.resultsTemplateContents}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ReportResultsTemplate;
