import React from "react";
import ClaimSectionRow from "./ClaimSectionRow";
import { formatDecimal, formatDateOnly } from "../../../services/General";

export default function DiscountData({ data }) {
  const discount = data.discount;
  const discountRedemption = data.discountRedemption;
  const ndcs = (discount.ndcs && discount.ndcs.join(", ")) || "";
  const pharmacies =
    (discount.pharmacies && discount.pharmacies.join(", ")) || "";
  const prescribers =
    (discount.prescribers && discount.prescribers.join(", ")) || "";

  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-lg-6 col-xxl-4">
          <ClaimSectionRow label="Plan" value={discount.discountPlan} />
          <ClaimSectionRow label="Type" value={discount.discountType} />
          <ClaimSectionRow
            label="Amount"
            value={formatDecimal(discount.discountAmount, 2)}
          />

          <ClaimSectionRow label="Max Count" value={discount.maxCount} />
          <ClaimSectionRow
            label="Max Quantity"
            value={formatDecimal(discount.maxQuantity, 3)}
          />
          <ClaimSectionRow
            label="Maximum Paid"
            value={formatDecimal(discount.maximumPaid, 2)}
          />
          <ClaimSectionRow label="Mode" value={discount.discountMode} />
          <ClaimSectionRow
            label="MS/U"
            value={discount.metricStrengthPerUnit}
          />
          <ClaimSectionRow
            label="Rejection"
            value={discountRedemption.rejection}
          />
        </div>
        <div className="col-xs-12 col-lg-6 col-xxl-3">
          <ClaimSectionRow label="Group Id" value={discount.groupId} />
          <ClaimSectionRow label="Member Id" value={discount.memberId} />
          <ClaimSectionRow label="NDCs" value={ndcs} />
          <ClaimSectionRow label="Pharmacies" value={pharmacies} />
          <ClaimSectionRow label="Prescribers" value={prescribers} />
          <ClaimSectionRow
            label="Effective Date"
            value={formatDateOnly(discount.effectiveDate)}
          />
          <ClaimSectionRow
            label="Termination Date"
            value={formatDateOnly(discount.terminationDate)}
          />
          <ClaimSectionRow
            label="Patient First Name"
            value={discountRedemption.claimMemberFirstName}
          />
          <ClaimSectionRow
            label="Patient Last Name"
            value={discountRedemption.claimMemberLastName}
          />
        </div>
        <div className="col-xs-12 col-lg-6 col-xxl-3">
          <ClaimSectionRow
            label="Incoming Price"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(discountRedemption.priceFromProcessor, 2)}
          />
          <ClaimSectionRow
            label="Incoming Fees"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(discountRedemption.feesFromProcessor, 2)}
          />
          <ClaimSectionRow
            label="3rd Party Pay"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(discountRedemption.thirdPartyPayAmount, 2)}
          />
          <ClaimSectionRow
            label="Copay"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(discountRedemption.patientPayAmount, 2)}
          />
          <ClaimSectionRow
            label="Quantity"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(discountRedemption.quantity, 3)}
          />
          <ClaimSectionRow
            label="Remaining Uses"
            valueStyle={{ textAlign: "right" }}
            value={discountRedemption.remainingUses}
          />
          <ClaimSectionRow
            label="Remaining Qty"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(discountRedemption.remainingQty, 3)}
          />
          <ClaimSectionRow
            label="Remaining Benefit"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(discountRedemption.remainingBenefit, 2)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ClaimSectionRow
            label="Message"
            value={discountRedemption.message}
            valueStyle={{ maxWidth: "100%" }}
          />
        </div>
      </div>
    </>
  );
}
