import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import styled from "styled-components";
import { useMobile } from "../../../hooks/useMobile";

function SimpleCompletionBar({
  title,
  runPercentage,
  onCancel,
  disableCancel = false,
  showCancel = true,
  fullWidth = false,
  subtitle = <></>,
}) {
  const { isMobileSize } = useMobile();

  return (
    <div
      className="display-flex"
      style={{
        margin: "20px auto",
        flexDirection: "column",
        alignItems: "center",
        width: isMobileSize || fullWidth ? "100%" : "50%",
      }}
    >
      {title}
      <ProgressBarWrapper>
        <label>{`${runPercentage}%`}</label>
        <RunTestProgressBar now={runPercentage} max={100} label="" />
      </ProgressBarWrapper>
      {subtitle}
      {runPercentage < 100 && showCancel && (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onCancel}
          disabled={disableCancel}
          style={{ marginTop: "20px", maxWidth: "80px" }}
        >
          Cancel
        </button>
      )}
    </div>
  );
}

const ProgressBarWrapper = styled.div`
  position: relative;
  height: 20px;
  width: 100%;

  label {
    position: absolute;
    top: 1px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: var(--progressbar-text);
    font-weight: bold;
    background-color: transparent;
  }
`;

const RunTestProgressBar = styled(ProgressBar)`
  height: 100%;
  width: 100%;

  .progress-bar {
    font-size: 16px;
    background-color: var(--progressbar-bg);
  }
`;
export default SimpleCompletionBar;
