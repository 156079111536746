import React, { useState } from "react";
import { BaseEdge, EdgeText, getBezierPath } from "@xyflow/react";
import RuleMapEdgeDialog from "./RuleMapEdgeDialog";

function RuleMapPossiblePathEdge({ id, data, ...props }) {
  const [edgePath, labelX, labelY] = getBezierPath(props);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [labelBgStyle, setLabelBgStyle] = useState({
    ...props.labelBgStyle,
  });

  function handleEdgeTextClick() {
    setShowInfoDialog(true);
  }

  function handleEdgeMouseEnter() {
    setLabelBgStyle({
      ...labelBgStyle,
      fillOpacity: 0.7,
    });
  }

  function handleEdgeMouseLeave() {
    setLabelBgStyle({
      ...labelBgStyle,
      fillOpacity: props.labelBgStyle.fillOpacity,
    });
  }

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={props.markerEnd}
        style={props.style}
      />
      <EdgeText
        x={labelX}
        y={labelY}
        label={props.label}
        labelStyle={{ ...props.labelStyle }}
        labelShowBg={props.labelShowBg}
        labelBgStyle={{ ...props.labelBgStyle }}
        labelBgPadding={props.labelBgPadding}
        labelBgBorderRadius={props.labelBgBorderRadius}
        onClick={handleEdgeTextClick}
        onMouseEnter={handleEdgeMouseEnter}
        onMouseLeave={handleEdgeMouseLeave}
      />
      <RuleMapEdgeDialog
        showDialog={showInfoDialog}
        title={"Edge Info"}
        onCloseDialog={() => setShowInfoDialog(false)}
        data={data}
      />
    </>
  );
}

export default RuleMapPossiblePathEdge;
