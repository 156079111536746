import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyProcessHistory,
  emptyProcessHistorySearch,
} from "../viewmodels/processHistoryVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadProcessHistory:
      return {
        ...state,
        processHistory: action.payload.processHistory,
        count: action.payload.count,
      };

    case ContextProviderActions.saveProcessHistorySearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadProcessHistoryRecord:
      return {
        ...state,
        processHistoryRecord: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in ProcessHistoryContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  processHistory: [],
  processHistoryRecord: emptyProcessHistory,
  search: emptyProcessHistorySearch,
  count: 0,
};

const ProcessHistory = createContext({
  processHistoryData: initialState,
  setProcessHistoryData: () => null,
});

// eslint-disable-next-line react/prop-types
const ProcessHistoryProvider = ({ children }) => {
  const [processHistoryData, setProcessHistoryData] = useReducer(
    reducer,
    initialState
  );

  return (
    <ProcessHistory.Provider
      value={{ processHistoryData, setProcessHistoryData }}
    >
      {children}
    </ProcessHistory.Provider>
  );
};

export default ProcessHistoryProvider;
export const useProcessHistory = () => useContext(ProcessHistory);
