import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { emptyRuleTableLinkedColumn } from "../../../viewmodels/ruleTablesVm";
import SelectInput from "../../common/input/SingleSelect";
import TextInput from "../../common/input/TextInput";
import Spinner from "../../common/ui/Spinner";
import HelpLink from "../../common/ui/HelpLink";

function RuleTableDialog({
  editRuleTable,
  setEditRuleTable,
  definitionsList,
  ruleTablesList,
  errors,
  showDialog,
  onCloseDialog,
  onSaveDialog,
  onAddRuleTableDefinition,
  loading,
}) {
  // Check if the selected Rule Table Definition requires linked columns to be entered
  useEffect(() => {
    if (!showDialog || !editRuleTable?.ruleTableDefinitionId) return;

    const selectedRtd = definitionsList.find(
      (d) => d.id === editRuleTable.ruleTableDefinitionId
    );
    let requiresLinkedColumns =
      selectedRtd &&
      selectedRtd.columns.some((c) => c.listSource === "RuleTableDefinition");

    if (requiresLinkedColumns) {
      const _linkedColumns = getLinkedColumns(
        selectedRtd.columns.filter(
          (c) => c.listSource === "RuleTableDefinition"
        )
      );
      setEditRuleTable({ ...editRuleTable, linkedColumns: _linkedColumns });
    } else {
      setEditRuleTable({ ...editRuleTable, linkedColumns: [] });
    }
  }, [editRuleTable?.ruleTableDefinitionId, showDialog]);

  function getLinkedColumns(cols) {
    const _linkedColumns = [];

    // Cols will have at least once value from the check we did in the useEffect
    cols.forEach((col) => {
      // Find the first rule table that matches this column's definition (if any)
      const rts = ruleTablesList.filter(
        (r) => r.ruleTableDefinitionId === col.ruleTableDefinitionId
      );
      const firstId = rts && rts.length > 0 ? rts[0].id : "";

      // See if we have a saved linked column for this col
      const savedCol = editRuleTable.linkedColumns.find(
        (lc) => lc.columnDefinitionId === col.id
      );

      _linkedColumns.push({
        ...emptyRuleTableLinkedColumn,
        columnDefinitionId: col.id,
        associatedRuleTableId:
          savedCol && savedCol.associatedRuleTableId !== ""
            ? savedCol.associatedRuleTableId
            : firstId,
        colName: col.name,
        ruleTableList: rts.map((rt) => {
          return { value: rt.id, label: rt.key };
        }),
      });
    });

    return _linkedColumns;
  }

  function handleChange({ target }) {
    setEditRuleTable({ ...editRuleTable, [target.name]: target.value });
  }

  function handleRuleTableDefinitionChange(option) {
    setEditRuleTable({ ...editRuleTable, ruleTableDefinitionId: option.value });
  }

  function handleLinkedColumnRuleTableChange(colId, option) {
    const _linkedColumns = editRuleTable.linkedColumns;
    _linkedColumns.find(
      (lc) => lc.columnDefinitionId === colId
    ).associatedRuleTableId = option.value;
    setEditRuleTable({ ...editRuleTable, linkedColumns: _linkedColumns });
  }

  const isEditMode = editRuleTable && editRuleTable.id;

  const filteredDefinitionsList = definitionsList.map((def) => {
    return { value: def.id, label: def.name };
  });

  return (
    <>
      <Modal size="lg" show={showDialog} onHide={onCloseDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Edit Rule Table" : "Add Rule Table"}
            <HelpLink
              path="/Processing-Rules/Rule-Screen&anchor=rule-table-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner spinnerStyle={{ height: "150px", lineHeight: "150px" }} />
          ) : (
            <>
              <SelectInput
                id="ruleTableDefinition"
                name="ruleTableDefinition"
                label="Rule Table Definition"
                options={filteredDefinitionsList}
                value={{
                  value: editRuleTable.ruleTableDefinitionId,
                  label: filteredDefinitionsList.find(
                    (f) => f.value === editRuleTable.ruleTableDefinitionId
                  )?.label,
                }}
                onChange={handleRuleTableDefinitionChange}
                placeholder="Select..."
                error={errors.ruleTableDefinition}
                disabled={isEditMode}
              />
              <TextInput
                id="key"
                label={`Key${
                  editRuleTable.isSystem === true
                    ? " (System key is read-only)"
                    : ""
                }`}
                onChange={handleChange}
                placeholder=""
                name="key"
                value={editRuleTable.key}
                error={errors.key}
                disabled={editRuleTable.isSystem === true}
              />
              <TextInput
                id="description"
                label="Description"
                onChange={handleChange}
                placeholder=""
                name="description"
                value={editRuleTable.description}
                error={errors.description}
              />

              {(editRuleTable.linkedColumns || []).length > 0 && (
                <>
                  <h3 style={{ marginTop: "20px", fontSize: "18px" }}>
                    Linked Columns
                  </h3>
                  <p>
                    Select the rule tables on this rule to use for validating
                    the following columns from the definition:
                  </p>
                  {editRuleTable.linkedColumns.map((col, idx) => (
                    <div key={`lc-${idx}`} className="form-group">
                      <label htmlFor={`lfld-${idx}`}>{col.colName}</label>
                      {(col.ruleTableList || []).length === 0 ? (
                        <div className="flex-row-with-wrap">
                          <span
                            style={{ color: "var(--notify-danger)" }}
                            className="material-icons"
                          >
                            warning
                          </span>
                          <strong style={{ color: "var(--notify-danger)" }}>
                            Warning:
                          </strong>
                          &nbsp;&nbsp;No matching rule tables found for this
                          column
                        </div>
                      ) : (
                        <SelectInput
                          id={`lfld-${idx}`}
                          name={`lfld-${idx}`}
                          label=""
                          labelStyle={{ display: "none" }}
                          options={col.ruleTableList}
                          value={{
                            value: col.associatedRuleTableId,
                            label: col.ruleTableList.find(
                              (f) => f.value === col.associatedRuleTableId
                            )?.label,
                          }}
                          onChange={(option) =>
                            handleLinkedColumnRuleTableChange(
                              col.columnDefinitionId,
                              option
                            )
                          }
                          placeholder="Select..."
                          error={errors[`lfld-${idx}`]}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {editRuleTable && editRuleTable.id === "" && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => onAddRuleTableDefinition(editRuleTable)}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="material-icons">add</span>
              {"  "}Add Rule Table Definition
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={onSaveDialog}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
              marginLeft: "auto",
            }}
            disabled={
              !editRuleTable ||
              (editRuleTable &&
                (editRuleTable.linkedColumns || []).length > 0 &&
                editRuleTable.linkedColumns.some(
                  (col) => (col.ruleTableList || []).length === 0
                ))
            }
          >
            <span className="material-icons">check</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCloseDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RuleTableDialog;
