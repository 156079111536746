import React, { useState } from "react";
import { StyledCopyContentsLink } from "./RuleMapCommonStyles";
import { StyledFeedbackText } from "../../common/layout/CommonStyledControls";

function RuleMapCopyContentsLink({ show, text = "NONE" }) {
  const [copying, setCopying] = useState(false);

  async function setClipboard(txt) {
    await navigator.clipboard.writeText(txt);
    setCopying(true);
    window.setTimeout(() => setCopying(false), 2000);
  }

  function handleCopyContents(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setClipboard(text);
  }

  if (show !== true) {
    return <></>;
  }

  return (
    <StyledCopyContentsLink>
      <button
        type="button"
        className="btn btn-link"
        style={{ cursor: "copy" }}
        onClick={handleCopyContents}
        title="Copy node contents to clipboard"
      >
        <span className="material-icons">file_copy</span>
      </button>
      {copying && <StyledFeedbackText>Copied!</StyledFeedbackText>}
    </StyledCopyContentsLink>
  );
}

export default RuleMapCopyContentsLink;
