import React, { useEffect } from "react";
import { useReports } from "../../../contexts/ReportsContext";
import Spinner from "../../common/ui/Spinner";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useMobile } from "../../../hooks/useMobile";
import { StyledNoResultsDiv } from "../../common/layout/CommonStyledControls";
import ReportResultsGrid from "./ReportResultsGrid";
import CheckboxInput from "../../common/input/CheckboxInput";

function ReportResultsDefault({
  report,
  hasReportRun,
  loadingReport,
  downloadingReport,
  hasUnsavedChanges,
  onRunReport,
  onDownloadReport,
}) {
  const { reportsData, setReportsData } = useReports();
  const { isMobileSize } = useMobile();

  // When size changes to mobile, set results column page size to 2. Set back to 5 when not mobile.
  useEffect(() => {
    if (reportsData && reportsData.resultsColCount > 0) {
      // Also set the initial number of pages and go back to first page
      const pageSize = isMobileSize ? 2 : 5;
      const numPages = Math.ceil(reportsData.resultsColCount / pageSize);

      setSearchChanges({
        ...reportsData.resultsSearch,
        colPageSize: isMobileSize ? 2 : 5,
        colPageCount: numPages,
        colPageNumber: 1,
      });
    }
  }, [isMobileSize, reportsData.resultsColCount]);

  function setSearchChanges(search) {
    setReportsData({
      type: ContextProviderActions.saveReportResultsSearch,
      payload: search,
    });
  }

  async function handleSearch(event, search) {
    if (event) event.preventDefault();
    onRunReport(search);
  }

  function handleCheckboxChange({ target }) {
    let changed = {
      ...reportsData.resultsSearch,
      [target.name]: target.checked,
    };
    setSearchChanges(changed);
  }

  function handleRunReportBtnClick() {
    const _search = { ...reportsData.resultsSearch, pageNumber: 1 };
    setSearchChanges(_search);
    onRunReport(_search);
  }

  return (
    <>
      <div className="display-flex" style={{ alignItems: "center" }}>
        <button
          type="button"
          className="btn btn-primary btn-with-icon"
          onClick={() => handleRunReportBtnClick()}
          disabled={hasUnsavedChanges}
        >
          {hasUnsavedChanges ? (
            <>Save changes before running</>
          ) : (
            <>
              <span className="material-icons">play_circle_outline</span>
              {"  "}Run Report
            </>
          )}
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-with-icon"
          style={{ marginLeft: "auto" }}
          onClick={() => onDownloadReport(false)}
          disabled={downloadingReport || hasUnsavedChanges}
        >
          {downloadingReport ? (
            <>
              {" "}
              <span className="material-icons">hourglass_empty</span>
              Downloading...
            </>
          ) : hasUnsavedChanges ? (
            <>Save changes before downloading</>
          ) : (
            <>
              {" "}
              <span className="material-icons">download</span>
              Download (csv)
            </>
          )}
        </button>
      </div>
      <div
        className="display-flex"
        style={{ alignItems: "center", marginTop: "-4px" }}
      >
        <span style={{ marginLeft: "auto" }}>
          <CheckboxInput
            id="importReadyMode"
            label="Export data in 'Import Ready' mode"
            onChange={handleCheckboxChange}
            placeholder=""
            name="importReadyMode"
            checked={reportsData.resultsSearch.importReadyMode}
            showLabelInline={true}
          />
        </span>
      </div>

      {loadingReport ? (
        <Spinner spinnerStyle={{ height: "200px", lineHeight: "200px" }} />
      ) : (
        <>
          <div style={{ marginTop: "10px" }}>
            {hasReportRun !== true ? (
              <StyledNoResultsDiv>
                <p>
                  <i className="material-icons">search</i>
                </p>
                <p>Results will appear here</p>
              </StyledNoResultsDiv>
            ) : (
              <>
                {reportsData.resultsCount === 0 ? (
                  <StyledNoResultsDiv>
                    <p>
                      <i className="material-icons">search_off</i>
                    </p>
                    <p>No results found</p>
                  </StyledNoResultsDiv>
                ) : (
                  <div style={{ marginTop: "20px" }}>
                    <ReportResultsGrid
                      totalRecords={reportsData.resultsCount}
                      reportEntity={report.entityType}
                      isGroupBy={
                        report?.selectFields?.some(
                          (f) => f.aggregateFunction !== "None"
                        ) ?? false
                      }
                      columns={reportsData.resultsColumns}
                      records={reportsData.results}
                      colCount={reportsData.resultsColCount}
                      search={reportsData.resultsSearch}
                      setSearch={async (search) => {
                        const clientOp = search.isClientOpOnly;
                        setSearchChanges({
                          ...search,
                          isClientOpOnly: false,
                        });

                        // Only do server-side search if the user didn't just change the number of columns to view
                        //   or the page number
                        if (!clientOp) {
                          await handleSearch(undefined, search);
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ReportResultsDefault;
