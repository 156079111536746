import React, { useEffect, useState } from "react";
import RuleMapLayouter from "../rulemaps/RuleMapLayouter";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { apiLoadGroupRuleMap } from "../../../api/GroupRuleMapApi";
import useApi from "../../../hooks/useApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { notifyError } from "../../../services/NotificationService";
import Spinner from "../../common/ui/Spinner";
import Authorize from "../../common/layout/Authorize";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledBackButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import { transformRuleMapToIncludeModules } from "../rulemaps/RuleMapDataModuleExtractor";
import { transformRuleMap } from "../rulemaps/RuleMapDataTransformer";
import { getReactFlowNodesAndEdgesForGroup } from "../rulemaps/RuleMapDataToFlowConverter";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { emptyGroupRuleMapFilter } from "../../../viewmodels/groupRuleMapsVm";
import GroupRuleMapFilterForm from "./GroupRuleMapFilterForm";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import { NodeTypes } from "../rulemaps/RuleMapDataCommon";

function GroupRuleMap() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { isMobileSize, isTabletSize } = useMobile();
  const [windowSizeW, windowSizeH] = useWindowSize([800, 800]);
  const { ruleMapsData, setRuleMapsData } = useRuleMaps();
  const { loading, api: apiLoad } = useApi(apiLoadGroupRuleMap);
  const [showPossiblePaths, setShowPossiblePaths] = useState(true);
  const [showModules, setShowModules] = useState(false);
  const [isModuleViewMode, setIsModuleViewMode] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const resId = params && params.groupId;

  // Remove the resizeObserver error
  //   jon, 8/23/24: This is a workaround to remove the specific errors below. These errors only display in a dev environment and
  //   are benign according to many stack overflow articles. Here is a discussion about it on the react flow github:
  //   https://github.com/xyflow/xyflow/issues/3076
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  });

  useEffect(() => {
    // When the screen gets unloaded, reset the context state so nothing is remembered between loads of a rule map
    return () => {
      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForClaim,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForClaim,
        payload: null,
      });

      loadRuleMap();
    }
  }, [auth.authenticated, params?.groupId]);

  useEffect(() => {
    if (
      ruleMapsData &&
      ruleMapsData.ruleMap !== null &&
      showPossiblePaths !== ruleMapsData.showPossiblePaths
    ) {
      handlePossiblePathsClick(ruleMapsData.showPossiblePaths);
    }
  }, [ruleMapsData?.showPossiblePaths]);

  useEffect(() => {
    if (
      ruleMapsData &&
      ruleMapsData.ruleMap !== null &&
      showModules !== ruleMapsData.showModules
    ) {
      handleShowModulesClick(ruleMapsData.showModules);
    }
  }, [ruleMapsData?.showModules]);

  useEffect(() => {
    if (
      ruleMapsData &&
      ruleMapsData.ruleMap !== null &&
      isModuleViewMode !== ruleMapsData.isModuleViewMode
    ) {
      handleModuleViewChange(ruleMapsData.isModuleViewMode);
    }
  }, [ruleMapsData?.isModuleViewMode]);

  useEffect(() => {
    if (ruleMapsData && ruleMapsData.ruleMap && ruleMapsData.ruleMap !== null) {
      rebuildNodeAndEdges(
        ruleMapsData.ruleMap,
        -1,
        ruleMapsData.showModules,
        ruleMapsData.selectedField
      );
    }
  }, [ruleMapsData.selectedField]);

  useEffect(() => {
    if (windowSizeH === 0 || windowSizeW === 0) return;

    const direction =
      isMobileSize || isTabletSize || windowSizeH > windowSizeW ? "TB" : "LR";

    if (ruleMapsData && direction !== ruleMapsData.layoutDirection) {
      setRuleMapsData({
        type: ContextProviderActions.setRuleMapLayoutDirectionWithoutRefresh,
        payload: direction,
      });
    }
  }, [windowSizeH, windowSizeW, isMobileSize, isTabletSize]);

  async function loadRuleMap() {
    if (!resId) {
      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForGroup,
        payload: null,
      });
      return;
    }

    apiLoad.call(resId, (result) => {
      let vm = null;
      if (!result) {
        notifyError(`Rule Map for group ${resId} does not exist`);
      } else {
        vm = result;
      }

      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForGroup,
        payload: vm,
      });

      doPostLoadRuleMap(vm);
    });
  }

  function doPostLoadRuleMap(vm) {
    rebuildNodeAndEdges(vm, -1, false, ruleMapsData.selectedField);
  }

  function handlePossiblePathsClick(showPaths) {
    setShowPossiblePaths(showPaths);
    rebuildNodesAndRefresh(showPaths);
  }

  function handleShowModulesClick(showModulesSetting) {
    setShowModules(showModulesSetting);
    rebuildNodesAndRefresh(showPossiblePaths);
  }

  function handleModuleViewChange(moduleViewSetting) {
    setIsModuleViewMode(moduleViewSetting);
    rebuildNodesAndRefresh(showPossiblePaths);
  }

  function getGroupSetting(groupSettings, key) {
    let value = "";

    const gs = groupSettings.find((g) => g.key === key);
    if (gs) {
      value = gs.value;
    }

    return value;
  }

  function injectGroupSettingsToFieldList(ruleMap, fieldList) {
    const groupSettings = ruleMap.groupSettings || [];
    const gsFields = fieldList.filter((f) => f.type === NodeTypes.GroupSetting);

    for (let i = 0; i < gsFields.length; i++) {
      gsFields[i].value = getGroupSetting(groupSettings, gsFields[i].name);
    }

    return fieldList;
  }

  function rebuildNodesAndRefresh(showPaths) {
    rebuildNodeAndEdges(
      ruleMapsData.ruleMap,
      -1,
      ruleMapsData.showModules,
      ruleMapsData.selectedField
    );

    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        setRuleMapsData({
          type: ContextProviderActions.doRuleMapRefreshLayout,
          payload: null,
        });
      });
    }, 100);
  }

  function rebuildNodeAndEdges(
    ruleMap,
    newTransactionNum,
    newShowModules,
    selectedField
  ) {
    const rm = {
      ...ruleMap,
    };

    const selectedModuleId =
      ruleMapsData?.isModuleViewMode === true &&
      ruleMapsData?.selectedModuleId !== "-1"
        ? ruleMapsData.selectedModuleId
        : "-1";

    const shouldShowModules =
      ruleMapsData?.isModuleViewMode === true ? false : newShowModules;

    const transformedFieldLists = transformRuleMap(
      rm,
      newTransactionNum,
      shouldShowModules
    );
    let fieldList = transformedFieldLists.fieldList;

    fieldList = injectGroupSettingsToFieldList(ruleMap, fieldList);

    fieldList = transformRuleMapToIncludeModules(
      fieldList,
      shouldShowModules,
      selectedModuleId,
      selectedField
    );

    const newNodesAndEdges = getReactFlowNodesAndEdgesForGroup(
      fieldList,
      selectedModuleId,
      selectedField
    );

    setNodes(newNodesAndEdges.nodeList);
    setEdges(newNodesAndEdges.edgeList);
    setRejectionsList(transformedFieldLists.rejections);
    setResponseFieldsList(transformedFieldLists.responseFields);

    window.setTimeout(() => {
      setRefreshing(false);
    }, 100);
  }

  function setNodes(nodes) {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapNodes,
      payload: nodes,
    });
  }

  function setEdges(edges) {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapEdges,
      payload: edges,
    });
  }

  function setRejectionsList(array) {
    setRuleMapsData({
      type: ContextProviderActions.loadRuleMapRejectionsList,
      payload: array,
    });
  }

  function setResponseFieldsList(array) {
    setRuleMapsData({
      type: ContextProviderActions.loadRuleMapResponseFieldsList,
      payload: array,
    });
  }

  function refreshNodesAndEdges() {
    setRefreshing(true);

    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        rebuildNodeAndEdges(
          ruleMapsData.ruleMap,
          -1,
          ruleMapsData.showModules,
          ruleMapsData.selectedField
        );
      });
    }, 100);
  }

  function setSearchChanges(search) {
    setRuleMapsData({
      type: ContextProviderActions.saveGroupRuleMapFilter,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyGroupRuleMapFilter,
      showAdvancedFilter: isMobileSize
        ? false
        : ruleMapsData.search.showAdvancedFilter,
    });

    refreshNodesAndEdges();
  }

  async function onSubmit(event, newSearch) {
    if (event) event.preventDefault();

    var updatedSearch = {
      ...ruleMapsData.search,
      ...newSearch,
    };
    setSearchChanges(updatedSearch);

    refreshNodesAndEdges();
  }

  function handleFilterItemClick(value, type) {
    const option = {
      value: value,
      type: type,
    };

    setRuleMapsData({
      type: ContextProviderActions.selectRuleMapField,
      payload: { selectedField: option },
    });
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    // if (ruleMapsData.search.clientId !== "") numFilters++;

    return numFilters;
  }

  const working = loading || (ruleMapsData?.ruleMap || null) === null;

  return (
    <Authorize>
      <StyledBackButtonDiv>
        <button
          title="Return to previous screen"
          type="button"
          className="btn btn-link"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink path="/GroupRuleMap/GroupRule-Map-Screen" label="Help" />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1 className="flex-row-with-wrap">
          <span>Group Rule Map for Group {resId}</span>
        </h1>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search Rule Map"
        search={ruleMapsData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      {working ? (
        <>
          <Spinner />
        </>
      ) : (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width:
                ruleMapsData.search.showAdvancedFilter && isMobileSize
                  ? "100%"
                  : "auto",
            }}
          >
            <GridAdvancedFilter
              search={ruleMapsData.search}
              setSearch={setSearchChanges}
              width={400}
              helpLink="/GroupRuleMap/GroupRule-Map-Screen&anchor=filters"
            >
              <GroupRuleMapFilterForm
                search={ruleMapsData.search}
                onSearch={onSubmit}
                onReset={handleReset}
                responseFields={ruleMapsData?.responseFieldsList || []}
                rejectionFields={ruleMapsData?.rejectionsList || []}
                onFilterItemClick={handleFilterItemClick}
              />
            </GridAdvancedFilter>
          </div>
          <div
            style={{
              flex: "1 1 auto",
              height: `${
                windowSizeH > 800 ? windowSizeH - 240 : windowSizeH
              }px`,
            }}
          >
            {refreshing ? (
              <Spinner />
            ) : (
              <RuleMapLayouter isGroupRuleMap={true} />
            )}
          </div>
        </div>
      )}
    </Authorize>
  );
}

export default GroupRuleMap;
