import React, { useEffect } from "react";
import { useWebSocketConnectionEvents } from "../../../contexts/WebSocketEventsContext";
import { useTests } from "../../../contexts/TestsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";

function TestsWebSocketHubConnections() {
  const { setTestsData } = useTests();
  const { webSocketConnectionData } = useWebSocketConnectionEvents();

  useEffect(() => {
    const vm = webSocketConnectionData.trafficTestDataPopulationStateUpdate;
    if (vm !== null) {
      const newState = vm.currentState;
      setTestsData({
        type: ContextProviderActions.loadDataGenerationUpdateState,
        payload: newState,
      });
    }
  }, [webSocketConnectionData?.trafficTestDataPopulationStateUpdate]);

  useEffect(() => {
    const vm = webSocketConnectionData.trafficTestRunnerStateUpdate;
    if (vm !== null) {
      const newState = vm.currentState;
      setTestsData({
        type: ContextProviderActions.loadTestRunnerUpdateState,
        payload: newState,
      });
    }
  }, [webSocketConnectionData?.trafficTestRunnerStateUpdate]);

  return <></>;
}

export default TestsWebSocketHubConnections;
