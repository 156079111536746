import React from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  formatDateTimeUtcZoneForDisplay,
  formatDateUtcZoneForDisplay,
} from "../../../services/General";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function ReportResultsGrid({
  totalRecords,
  reportEntity,
  isGroupBy = false,
  columns,
  records,
  colCount,
  search,
  setSearch,
}) {
  const navigate = useNavigate();

  const isEntityLinkable =
    reportEntity !== "DiscountRedemption" &&
    reportEntity !== "Diagnosis" &&
    !isGroupBy;

  function buildLinkFromEntity(id) {
    let screen = _.lowerCase(reportEntity);

    if (screen === "claimpriorauthorization") screen = "priorauthorization";
    else if (screen === "ProcessingRule") screen = "rule";

    const link = `/${screen}/` + id;
    // console.log("Report entity link:", link);
    return link;
  }

  function handleClickRow(id) {
    if (isEntityLinkable) {
      navigate(buildLinkFromEntity(id));
    }
  }

  function getLinkColumn(recordId) {
    if (!isEntityLinkable) return null;

    return (
      <td>
        <button
          className="btn btn-link link-underline"
          onClick={() => handleClickRow(recordId)}
          style={{ marginTop: "-4px" }}
        >
          view
        </button>
      </td>
    );
  }

  function getColumnDefs() {
    const cols = [];

    // Always-visible edit column
    cols.push({
      name: "edit",
      label: "",
      disableSort: true,
      isSticky: true,
      hidden: !isEntityLinkable,
      style: { width: "75px" },
      getValue: (row) => getLinkColumn(row.id),
    });

    // Report columns
    let col;
    for (let i = 0; i < columns.length; i++) {
      col = columns[i];

      cols.push({
        name: col.name,
        label: col.label,
        disableSort: true,
        getValue: (row) => {
          const val = row.values[i];
          let value = val ?? "";

          if (columns[i].isTemplate === false) {
            if (columns[i].dataType === "DateTime") {
              value = formatDateTimeUtcZoneForDisplay(value);
            } else if (columns[i].dataType === "DateOnly") {
              value = formatDateUtcZoneForDisplay(value);
            }
          }

          return value;
        },
      });
    }

    return cols;
  }

  return (
    <>
      {/* jon, 6/14/23: Adding colcount to the key here to force re-render when select list changes. Otherwise, shows old column set at first. */}
      <ResponsiveGrid
        key={`grid=${colCount}`}
        gridId="ReportResults"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        dataRows={records}
        enableClientColumnPager={true}
        totalColumnCount={colCount + 1}
        columnDefs={getColumnDefs()}
      />
    </>
  );
}

export default ReportResultsGrid;
