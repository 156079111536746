import _ from "lodash";
import { generateUUID } from "../../../services/General";

const disabledBackground = "var(--rule-disabled-row-bg)";
const disabledText = "var(--text-disabled)";
const warnBackground = "var(--rule-warn-row-bg)";
const warnRowStyle = { backgroundColor: warnBackground };
const disabledRowStyle = { backgroundColor: disabledBackground };
const disabledTextStyle = { color: disabledText };
const withDateBackground = "#e9f0e1";
const withDateRowStyle = { backgroundColor: withDateBackground };

export function getRowStyle(item) {
  if (item.potentialError) return warnRowStyle;
  var isEnabled =
    (!_.isDate(item.effectiveDate) || item.effectiveDate <= new Date()) &&
    (!_.isDate(item.terminationDate) || item.terminationDate > new Date());
  var isTimed = _.isDate(item.effectiveDate) || _.isDate(item.terminationDate);
  if (!isEnabled) return disabledRowStyle;
  if (isTimed) return withDateRowStyle;
  return {};
}

export function getHighlightStyle(item, attachedToArtifact) {
  var ts = getTextStyle(item);
  if (_.isEmpty(item.matchColor)) return ts;
  if (item.matchByArtifact === attachedToArtifact) {
    var hs = {
      outline: "none !important",
      borderColor: item.matchColor,
      boxShadow: "0 0 10px " + item.matchColor,
    };
    ts = { ...ts, ...hs };
  }
  return ts;
}

export function getTextStyle(item) {
  var isEnabled =
    (!_.isDate(item.effectiveDate) || item.effectiveDate <= new Date()) &&
    (!_.isDate(item.terminationDate) || item.terminationDate > new Date());
  if (!isEnabled) return disabledTextStyle;
  return {};
}

function filterArray(array, textSearch) {
  return array.filter((d) => {
    if (d["isNew"] === true) return true;
    return (
      Object.keys(d).filter((k) => {
        if (_.isEmpty(d[k])) return false;
        return d[k].toString().toLowerCase().includes(textSearch.toLowerCase());
      }).length > 0
    );
  });
}

export function filterItems(rule, ruleTablesData) {
  if (_.isEmpty(rule.textSearch)) {
    rule.ruleTableFilterCount = ruleTablesData?.ruleTables?.length || 0;
    return rule;
  }

  rule = _.cloneDeep(rule);
  rule.conditionals = filterArray(rule.conditionals, rule.textSearch);
  rule.includedDataValues = filterArray(
    rule.includedDataValues,
    rule.textSearch
  );
  rule.includedRejections = filterArray(
    rule.includedRejections,
    rule.textSearch
  );
  rule.excludedRejections = filterArray(
    rule.excludedRejections,
    rule.textSearch
  );

  // Adding rule tables here, but to only get the count since rule tables are filtered separately inside of RuleTableList
  let rtCount = 0;
  if (ruleTablesData?.ruleTables) {
    const ruleTables = filterArray(
      ruleTablesData?.ruleTables.map((rt) => {
        return {
          key: rt.key,
          description: rt.description,
          ruleTableDefinitionName: rt.ruleTableDefinitionName,
        };
      }),
      rule.textSearch
    );
    rtCount = ruleTables.length;
  }
  rule.ruleTableFilterCount = rtCount;

  return rule;
}

export function handleIndexedChange(
  array,
  event,
  id,
  ruleChanges,
  setRuleChanges
) {
  let newRule = _.cloneDeep(ruleChanges);
  let item = newRule[array].find((n) => n.id === id);
  if (!_.isNull(item)) {
    if (_.isNull(event.target.value)) item[event.target.name] = "";
    else item[event.target.name] = event.target.value;
    setRuleChanges(newRule);
  }
}

export function handleIndexedRemove(array, id, ruleChanges, setRuleChanges) {
  let newRule = _.cloneDeep(ruleChanges);
  let item = newRule[array].find((n) => n.id === id);
  if (!_.isNull(item)) {
    const index = newRule[array].findIndex((n) => n.id === id);
    newRule[array].splice(index, 1);
    setRuleChanges(newRule);
  }
}

export function handleIndexedAdd(array, ruleChanges, setRuleChanges) {
  let newRule = _.cloneDeep(ruleChanges);
  if (array === "conditionals")
    newRule.conditionals.push({
      id: generateUUID(),
      isNew: true,
      attachedTo: "",
      attachedToType: "artifact",
      name: "",
      valueType: "RequestFieldValue",
      operator: "=",
      valuesVm: "",
      stagingOnly: true,
    });
  if (array === "includedDataValues")
    newRule.includedDataValues.push({
      id: generateUUID(),
      isNew: true,
      attachedArtifact: "",
      idKey: "",
      key: "",
      value: "",
      valueProcessTiming: "BeforeArtifact",
      valueType: "InternalVariable",
      stagingOnly: true,
    });
  if (array === "includedRejections")
    newRule.includedRejections.push({
      id: generateUUID(),
      isNew: true,
      rejectionKey: "",
      attachedArtifact: "",
      code: "",
      level: "Transmission",
      message: "",
      stagingOnly: true,
    });
  if (array === "excludedRejections")
    newRule.excludedRejections.push({
      id: generateUUID(),
      isNew: true,
      rejectionKey: "",
      attachedArtifact: "",
      code: "",
      message: "",
      stagingOnly: true,
    });

  setRuleChanges(newRule);
}

export function handleStagingChange(
  array,
  event,
  id,
  ruleChanges,
  setRuleChanges
) {
  let newRule = _.cloneDeep(ruleChanges);
  let item = newRule[array].find((n) => n.id === id);
  if (!_.isNull(item)) {
    if (event.target.checked) {
      item.stagingOnly = true;
    } else {
      item.stagingOnly = false;
    }
    setRuleChanges(newRule);
  }
}

export function handleRowUp(array, id, ruleChanges, setRuleChanges) {
  let newRule = _.cloneDeep(ruleChanges);
  let item = newRule[array].find((n) => n.id === id);
  if (!_.isNull(item)) {
    const index = newRule[array].findIndex((n) => n.id === id);
    if (index === 0) return;
    newRule[array].splice(index, 1);
    newRule[array].splice(index - 1, 0, item);
    setRuleChanges(newRule);
  }
}

export function handleRowDown(array, id, ruleChanges, setRuleChanges) {
  let newRule = _.cloneDeep(ruleChanges);
  let item = newRule[array].find((n) => n.id === id);
  if (!_.isNull(item)) {
    const index = newRule[array].findIndex((n) => n.id === id);
    if (index >= newRule[array].length - 1) return;
    newRule[array].splice(index, 1);
    newRule[array].splice(index + 1, 0, item);
    setRuleChanges(newRule);
  }
}
