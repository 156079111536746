import _ from "lodash";
import { pageSize } from "../constants/Environments";

export const emptyReportProcess = {
  id: "",
  description: "",
  reportId: { value: "-1", label: "(Select a report)" },
  reportVm: "",
  reportName: "",
  ruleTableId: {
    value: "-1",
    label: "(Select a rule table)",
  },
  ruleTableVm: "",
  ruleTableName: "",
  runNumber: "0",
  runDateInput: "",
  lastRunDate: "",
  lastRunDuration: "",
  lastRunBy: "",
  lastProcessHistoryId: "",
  noRepeatData: false,
  reportParameters: [],
};

export const emptyReportProcessSearch = {
  description: "",
  reportName: "",
  ruleTableName: "",
  startDate: new Date("1/1/2000"),
  endDate: new Date("1/1/3000"),
  dateRange: "All",
  lastRunBy: "",
  orderBy: ["description+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
  oldName: { name: "", id: "" },
  newName: "",
};

export const emptyReportProcessLastRunValuesSearch = {
  pageSize: pageSize || 25,
  pageNumber: 1,
  colPageCount: 1,
  colPageNumber: 1,
  colPageSize: 5,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  vm.reportId = (vm.reportVm || { value: "-1" }).value;
  vm.ruleTableId = (vm.ruleTableVm || { value: "-1" }).value;

  let m = vm;
  return m;
}

export function createViewModel(m, reportList, ruleTableList) {
  let vm = _.cloneDeep(m);

  // Dropdown values
  vm.reportVm =
    (reportList || []).find((c) => c.value === vm.reportId) ||
    emptyReportProcess.reportId;

  vm.ruleTableVm =
    (ruleTableList || []).find((c) => c.value === vm.ruleTableId) ||
    emptyReportProcess.ruleTableId;

  return vm;
}
