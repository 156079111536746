import _ from "lodash";

export const emptyDrugUpdate = {
  id: "",
  databaseConnectionString: "",
  databaseId: "",
  container: "",
  sqlManualDrugRecordsConnectionString: "",
  storageConnectionString: "",
  drugFileLocation: "",
  bulkRecordPageSize: 25,
  bulkRecordWaitBetweenPages: 300,
  active: true,
};

export const emptyDrugUpdateStatus = {
  isRunInProgress: false,
  inProgressRecordsProcessed: 0,
  totalRecordsToProcess: 0,
  totalRecordsProcessed: 0,
  totalManualRecords: 0,
  lastUpdateMessage: "",
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  return vm;
}
