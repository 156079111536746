import _ from "lodash";
import { genders, states } from "../services/General";
import { parseISO } from "date-fns";
import { pageSize } from "../constants/Environments";

export const serviceProviderTypes = [
  { label: "Independent Pharmacy", value: "IndependentPharmacy" },
  { label: "Chain Pharmacy", value: "ChainPharmacy" },
  { label: "Mail Order Pharmacy", value: "MailOrderPharmacy" },
];

export const dispenserClassCodes = [
  { label: "Other", value: "Other" },
  { label: "Independent", value: "Independent" },
  { label: "Chain", value: "Chain" },
  { label: "Institution", value: "Institution" },
  { label: "Clinic", value: "Clinic" },
  { label: "Franchise", value: "Franchise" },
  { label: "Government - Federal", value: "GovernmentFederal" },
  { label: "Alternate", value: "Alternate" },
];

export const dispenserTypeCodes = [
  { label: "Other", value: "Other" },
  { label: "Retail", value: "Retail" },
  { label: "Grocery Store", value: "GroceryStore" },
  { label: "Department Store", value: "DepartmentStore" },
  { label: "Long Term Care", value: "LongTermCare" },
  { label: "Mail Order", value: "MailOrder" },
  { label: "IV Infusion", value: "IvInfusion" },
  { label: "Dispensing Physician", value: "DispensingPhysician" },
  { label: "Indian Health Services", value: "IndianHealthServices" },
  { label: "VA Hospital", value: "VaHospital" },
  { label: "State Hospital", value: "StateHospital" },
  { label: "Institution", value: "Institution" },
  { label: "HMO Pharmacy", value: "HmoPharmacy" },
  { label: "DME", value: "DME" },
  { label: "Clinic Pharmacy", value: "ClinicPharmacy" },
  { label: "Specialty Pharmacy", value: "SpecialtyPharmacy" },
  { label: "Nuclear Pharmacy", value: "NuclearPharmacy" },
  { label: "Military Pharmacy", value: "MilitaryPharmacy" },
];

export const emptyServiceProvider = {
  effectiveDate: new Date("1/1/2000"),
  terminationDate: new Date("1/1/3000"),
  ncpdp: "",
  npi: "",
  chainId: { value: "00000000", label: "Independent" },
  chainIdVm: "",
  name: "",
  description: "",
  chain: "",
  serviceProviderType: "IndependentPharmacy",
  dispenserClassCode: "Other",
  dispenserTypeCode: "Other",
  payCenterCode: "",
  firstName: "",
  middleName: "",
  lastName: "",
  title: "",
  subtitle: "",
  suffix: "",
  gender: "Unspecified",
  emailAddressesVm: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  number: "",
  addresses: [],
  phoneNumbers: [],
  contacts: [],
};

export const emptyServiceProviderSearch = {
  npi: "",
  ncpdp: "",
  chainId: { value: "", label: "Any" },
  name: "",
  address: "",
  city: "",
  state: "",
  active: true,
  orderBy: ["lastModifiedDate-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  serviceProvidersCount: 0,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  if (vm.terminationDate > new Date("1/1/2999")) vm.terminationDate = null;
  vm.state = (vm.stateVm || { value: "" }).value || "";
  vm.serviceProviderType = (
    vm.serviceProviderTypeVm || { value: "IndependentPharmacy" }
  ).value;
  vm.dispenserClassCode = (vm.dispenserClassCodeVm || { value: "Other" }).value;
  vm.dispenserTypeCode = (vm.dispenserTypeCodeVm || { value: "Other" }).value;
  vm.chainId = (vm.chainIdVm || { value: "00000000" }).value;
  vm.contacts = [];
  if (vm.lastName) {
    vm.contacts.push({
      title: (vm.title || "").toUpperCase(),
      gender: "Unspecified",
      subtitle: (vm.subtitle || "").toUpperCase(),
      suffix: (vm.suffix || "").toUpperCase(),
      firstName: (vm.firstName || "").toUpperCase(),
      middleName: (vm.middleName || "").toUpperCase(),
      lastName: (vm.lastName || "").toUpperCase(),
      emailAddresses: vm.emailAddressesVm.split(",").map((m) => {
        return { emailAddress: (m || "").toUpperCase() };
      }),
    });
  }

  vm.addresses = [];
  if (vm.address) {
    vm.addresses.push({
      addressType: "Physical",
      address1: (vm.address || "").toUpperCase(),
      city: (vm.city || "").toUpperCase(),
      state: (vm.state || "None").toUpperCase(),
      zipCode: (vm.zipCode || "").toUpperCase(),
    });
  }

  vm.phoneNumbers = [];
  if (vm.number) {
    vm.phoneNumbers.push({
      phoneType: "Phone",
      number: (vm.number || "").toUpperCase(),
    });
  }

  let m = vm;
  return m;
}

export function createViewModel(m, chainList) {
  let vm = _.cloneDeep(m);
  if (_.isNull(vm.effectiveDate)) {
    vm.effectiveDate = emptyServiceProvider.effectiveDate;
  } else {
    vm.effectiveDate = parseISO(vm.effectiveDate);
  }
  if (_.isNull(vm.terminationDate))
    vm.terminationDate = emptyServiceProvider.terminationDate;
  else {
    vm.terminationDate = parseISO(vm.terminationDate);
  }
  vm.ncpdp = m.ncpdp || "";
  if (vm.contacts.length > 0) {
    vm.title = vm.contacts[0].title || "";
    vm.gender = vm.contacts[0].gender || "";
    vm.suffix = vm.contacts[0].suffix || "";
    vm.subtitle = vm.contacts[0].subtitle || "";
    vm.firstName = vm.contacts[0].firstName || "";
    vm.lastName = vm.contacts[0].lastName || "";
    vm.middleName = vm.contacts[0].middleName || "";
    vm.emailAddressesVm =
      vm.contacts[0].emailAddresses.map((m) => m.emailAddress).join(",") || "";
    vm.genderVm = genders.find((d) => d.value === vm.gender) || {
      label: "Unspecified",
      value: "Unspecified",
    };
  } else {
    vm.firstName = "";
    vm.middleName = "";
    vm.lastName = "";
    vm.title = "";
    vm.gender = "";
    vm.emailAddressesVm = "";
    vm.suffix = "";
    vm.subtitle = "";
  }
  if (vm.addresses.length > 0) {
    vm.address = vm.addresses[0].address1 || "";
    vm.city = vm.addresses[0].city || "";
    vm.state = vm.addresses[0].state || "";
    vm.zipCode = vm.addresses[0].zipCode || "";
    vm.stateVm = states.find((d) => d.value === vm.state) || {
      value: "",
      label: "",
    };
  } else {
    vm.address = "";
    vm.city = "";
    vm.state = "";
    vm.zipCode = "";
  }
  if (vm.phoneNumbers.length > 0) {
    vm.number = vm.phoneNumbers[0].number || "";
  } else {
    vm.number = "";
  }

  vm.chainIdVm =
    (chainList || []).find((c) => c.value === vm.chainId) ||
    emptyServiceProviderSearch.chainId;
  vm.chain = vm.chainIdVm.label;

  vm.serviceProviderTypeVm = serviceProviderTypes.find(
    (d) => d.value === vm.serviceProviderType
  ) || {
    value: "",
    label: "",
  };
  vm.dispenserClassCodeVm = dispenserClassCodes.find(
    (d) => d.value === vm.dispenserClassCode
  ) || {
    value: "",
    label: "",
  };
  vm.dispenserTypeCodeVm = dispenserTypeCodes.find(
    (d) => d.value === vm.dispenserTypeCode
  ) || {
    value: "",
    label: "",
  };

  return vm;
}
