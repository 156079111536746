/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";
import { unZero } from "../services/General";

export function apiLoadDiscounts(search, token, signal) {
  const uri = API_URL + "/discountspost" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "id", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "groupId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "memberId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "discountPlan",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "pharmacies",
      operator: "array_contains",
      values: [(search.freeFormSearch || "").trim()],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "prescribers",
      operator: "array_contains",
      values: [(search.freeFormSearch || "").trim()],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "ndcs",
      operator: "array_contains",
      values: [(search.freeFormSearch || "").trim()],
    });
  }

  if (search && search.startDate && _.isDate(search.startDate))
    where.push({
      name: "lastModifiedDate",
      operator: ">=",
      values: [search.startDate],
    });
  if (search && search.endDate && _.isDate(search.endDate))
    where.push({
      name: "lastModifiedDate",
      operator: "<",
      values: [search.endDate],
    });

  if (search && search.active) {
    where.push({ name: "TEMPORALITEMMATCH", operator: "=", values: [] });
  }
  if (search && search.id && search.id.length > 0)
    where.push({
      name: "id",
      operator: "=",
      values: [(search.id || "").trim()],
    });
  if (search && search.groupId && search.groupId.length > 0)
    where.push({
      name: "groupId",
      operator: "=",
      values: [(search.groupId || "").trim()],
    });
  if (search && search.discountPlan && search.discountPlan.length > 0)
    where.push({
      name: "discountPlan",
      operator: "=",
      values: [(search.discountPlan || "").trim()],
    });
  if (search && search.pharmacy && search.pharmacy.length > 0)
    where.push({
      name: "pharmacies",
      operator: "array_contains",
      values: [(search.pharmacy || "").trim()],
    });
  if (search && search.prescriber && search.prescriber.length > 0)
    where.push({
      name: "prescribers",
      operator: "array_contains",
      values: [(search.prescriber || "").trim()],
    });
  if (search && search.ndc && search.ndc.length > 0)
    where.push({
      name: "ndcs",
      operator: "array_contains",
      values: [(search.ndc || "").trim()],
    });
  if (search && search.memberId && search.memberId.length > 0)
    where.push({
      name: "memberId",
      operator: "=",
      values: [(search.memberId || "").trim()],
    });
  if (
    search &&
    ((search.initialPrice && search.initialPrice !== 0) || search.processedDate)
  )
    where.push({
      name: "initialPrice",
      operator: "=",
      values: [search.initialPrice || 0],
    });
  if (search && search.lookupQty && search.lookupQty > 0)
    where.push({
      name: "lookupQty",
      operator: "=",
      values: [search.lookupQty || 0],
    });
  if (search && search.processedDate)
    where.push({
      name: "processedDate",
      operator: "=",
      values: [search.processedDate || new Date().toISOString()],
    });

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadDiscountRedemption(claimId, token, signal) {
  const uri = API_URL + "/discountredemption/" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + claimId, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadDiscountRedemptions(search, token, signal) {
  const uri = API_URL + "/discountredemptions" + getQueryString();
  let where = [];
  if (search && search.discountBundleId && search.discountBundleId.length > 0) {
    where.push({
      name: "discountBundleId",
      operator: "=",
      values: [(search.discountBundleId || "").trim()],
    });
  } else if (
    search &&
    search.selectedDiscountId &&
    search.selectedDiscountId.length > 0
  ) {
    where.push({
      name: "selectedDiscountId",
      operator: "=",
      values: [(search.selectedDiscountId || "").trim()],
    });
    if (search && search.unreversed) where.push({ name: "unreversed" });
  } else {
    if (
      search &&
      search.prescriptionNumber &&
      search.prescriptionNumber.length > 0
    )
      where.push({
        name: "prescriptionNumber",
        operator: "=",
        values: [(unZero(search.prescriptionNumber) || "").trim()],
      });
    if (search && search.pharmacy && search.pharmacy.length > 0)
      where.push({
        name: "pharmacy",
        operator: "=",
        values: [(search.pharmacy || "").trim()],
      });
  }

  //If none of the where clauses are set (can legitimately happen), do not try to retrieve discount redemptions)
  if (where.length === 0)
    return new Promise((resolve) => {
      resolve({ count: 0, resources: [] });
    });

  const data = { where, orderBy: search.drOrderBy };
  const pageSize = search.redemptionPageSize || 10;
  const page = search.redemptionPageNumber;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadDiscount(id, token, signal) {
  let uri = API_URL + "/discount/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddDiscount(pa, token, signal) {
  let uri = API_URL + "/discount" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(pa),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateDiscount(params, token, signal) {
  const id = params.id;
  const pa = params.model;

  let uri = API_URL + "/discount/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(pa),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteDiscount(id, token, signal) {
  let uri = API_URL + "/discount/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadDiscounts.funcName = "apiLoadDiscounts";
apiLoadDiscountRedemption.funcName = "apiLoadDiscountRedemption";
apiLoadDiscountRedemptions.funcName = "apiLoadDiscountRedemptions";
apiLoadDiscount.funcName = "apiLoadDiscount";
apiAddDiscount.funcName = "apiAddDiscount";
apiUpdateDiscount.funcName = "apiUpdateDiscount";
apiDeleteDiscount.funcName = "apiDeleteDiscount";
