import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import styled from "styled-components";

function MultiSelectInput({
  name,
  id,
  value,
  label,
  error,
  onChange,
  placeholder,
  options,
  labelStyle,
}) {
  let wrapperClass = "form-group";
  if (error.length > 0) {
    wrapperClass += " has-error";
  }
  return (
    <div className={wrapperClass}>
      <label htmlFor={id} style={labelStyle || {}}>
        {label}
      </label>
      <div className="field">
        <StyledSelect
          aria-label={`SELECT-${label}`}
          id={id}
          name={name}
          options={options}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          isMulti={true}
          isSearchable={true}
          isClearable={false}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "var(--input-bg)",
              borderColor: "var(--input-border)",
              color: "var(--input-text)",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "var(--submenu-bg)",
              borderColor: "var(--submenu-border)",
              color: "var(--submenu-text)",
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused
                ? "var(--submenu-active-bg)"
                : "var(--submenu-bg)",
              color: state.isFocused
                ? "var(--submenu-active-text)"
                : "var(--submenu-text)",
              ":active": {
                backgroundColor: "var(--submenu-active-bg)",
                color: "var(--submenu-active-text)",
              },
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "var(--menu-active-bg)",
              borderColor: "var(--menu-active-bg)",
              color: "var(--submenu-bg)",
            }),
            multiValueLabel: (baseStyles) => ({
              ...baseStyles,
              color: "var(--submenu-bg)",
            }),
            multiValueRemove: (baseStyles) => ({
              ...baseStyles,
              color: "var(--submenu-bg)",
              ":hover": {
                cursor: "pointer",
              },
            }),
            multiValueRemoveLabel: (baseStyles) => ({
              ...baseStyles,
              color: "var(--submenu-bg)",
            }),
          }}
        />
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

const StyledSelect = styled(Select)`
  & > div:first-child {
    color: var(--input-text);
    background-color: var(--input-bg);
    border: 1px solid var(--input-border);
    border-radius: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 3px 0 2px 0;
    transition: none;

    &:hover {
      border: 1px solid var(--input-border);
    }
  }
`;

MultiSelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  error: PropTypes.string,
  options: PropTypes.array,
};

MultiSelectInput.defaultProps = {
  error: "",
};

export default MultiSelectInput;
