import React from "react";
import ClaimSectionRow from "./ClaimSectionRow";
import _ from "lodash";

export default function DrugData({ data }) {
  const drug = data;
  const wac =
    !_.isEmpty(drug) && drug.wacPrices.length > 0
      ? drug.wacPrices.filter((p) => p.priority === 1)[0]
      : null;
  const awp =
    !_.isEmpty(drug) && drug.awpPrices.length > 0
      ? drug.awpPrices.filter((p) => p.priority === 1)[0]
      : null;

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-6 col-xxl-4">
        <ClaimSectionRow label="NDC" value={drug.ndc} />
        <ClaimSectionRow label="Product Name" value={drug.productName} />
        <ClaimSectionRow label="GPI" value={drug.productName} />
        <ClaimSectionRow
          label="Multi-Source Code"
          value={drug.multiSourceCode}
        />
        <ClaimSectionRow label="Dispensing Unit" value={drug.dispensingUnit} />
        <ClaimSectionRow label="Dosage Form" value={drug.dosageForm} />
      </div>
      <div className="col-xs-12 col-lg-6 col-xxl-3">
        <ClaimSectionRow
          label="WAC Unit Price"
          value={wac && wac.unitPrice}
          valueStyle={{ textAlign: "right" }}
        />
        <ClaimSectionRow
          label="WAC Package Price"
          value={wac && wac.packagePrice}
          valueStyle={{ textAlign: "right" }}
        />
        <ClaimSectionRow
          label="AWP Unit Price"
          value={awp && awp.unitPrice}
          valueStyle={{ textAlign: "right" }}
        />
        <ClaimSectionRow
          label="AWP Package Price"
          value={awp && awp.packagePrice}
          valueStyle={{ textAlign: "right" }}
        />
      </div>
    </div>
  );
}
