import React, { useState, useEffect } from "react";
import _ from "lodash";
import TestsList from "./TestsList";
import TestSearchForm from "./TestSearchForm";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import { createViewModel, emptyTestSearch } from "../../../viewmodels/testsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useTests } from "../../../contexts/TestsContext";
import {
  apiLoadTests,
  apiDeleteTest,
  apiCopyTest,
  apiStartRunForTestOrGroup,
  apiCancelRunForTestOrGroup,
  apiGenerateTestDataForList,
  apiCancelGenerateTestDataForList,
} from "../../../api/TestApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import { useNavigate } from "react-router-dom";
import DuplicateRecordDialog from "../../dialogs/DuplicateRecordDialog";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import useApi from "../../../hooks/useApi";
import { formatDecimal } from "../../../services/General";
import HelpLink from "../../common/ui/HelpLink";
import SimpleCompletionBar from "../../common/ui/SimpleCompletionBar";
import TestsWebSocketHubConnections from "./TestsWebSocketHubConnections";

function Tests() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { testsData, setTestsData } = useTests();
  const { loading, api: apiLoad } = useApi(apiLoadTests);
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteTest);
  const { loading: copying, api: apiCopy } = useApi(apiCopyTest);
  const { loading: startingDataGeneration, api: apiGenerateDataForTests } =
    useApi(apiGenerateTestDataForList);
  const {
    loading: cancellingGenerateTestData,
    api: apiCancelGenerateTestData,
  } = useApi(apiCancelGenerateTestDataForList);
  const { loading: startingTestRun, api: apiStartRunForTest } = useApi(
    apiStartRunForTestOrGroup
  );
  const { loading: cancellingTestRun, api: apiCancelRunForTest } = useApi(
    apiCancelRunForTestOrGroup
  );
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState(true);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const { isMobileSize } = useMobile();
  const [generationIsWaitingToStart, setGenerationIsWaitingToStart] =
    useState(false);
  const [generationIsWaitingToCancel, setGenerationIsWaitingToCancel] =
    useState(false);
  const [generationInProgress, setGenerationInProgress] = useState(false);
  const [generationIsEnding, setGenerationIsEnding] = useState(false);
  const [testRunIsWaitingToStart, setTestRunIsWaitingToStart] = useState(false);
  const [testRunIsWaitingToCancel, setTestRunIsWaitingToCancel] =
    useState(false);
  const [testRunInProgress, setTestRunInProgress] = useState(false);
  const [testRunIsEnding, setTestRunIsEnding] = useState(false);
  const [showGenerateDataModal, setShowGenerateDataModal] = useState(false);
  const [currentRunTestIndex, setCurrentRunTestIndex] = useState(-1);
  const [currentRunTestDescription, setCurrentRunTestDescription] =
    useState("");

  let tests = [];
  if (testsData && testsData.tests) {
    tests = testsData.tests;
  }

  useEffect(() => {
    if (
      testsData.dataGenerationUpdateState &&
      testsData.dataGenerationUpdateState.testIdQueue
    ) {
      //Set num data rows for finished tests
      const finishedTests =
        testsData.dataGenerationUpdateState.testIdQueue.filter(
          (t) => t.status === "Done" || t.status === "Error"
        );
      setDataGenerationResults(finishedTests);

      // Do we have any pending or in progress tests?
      const hasPendingTests =
        testsData.dataGenerationUpdateState.testIdQueue.filter(
          (t) => t.status === "InProgress" || t.status === "Pending"
        ).length > 0;

      // If none left, indicate generation is complete
      if (generationInProgress && !hasPendingTests) {
        setGenerationIsEnding(true);
        setCurrentRunTestIndex(-1);

        // Wait a few seconds and then hide the progress
        window.setTimeout(() => {
          setGenerationInProgress(false);
          setGenerationIsEnding(false);
        }, 4000);

        return;
      }

      // Check which test is generating data and set its index. If none, wait until the next state update.
      const testGenerating =
        testsData.dataGenerationUpdateState.testIdQueue.find(
          (t) => t.status === "InProgress"
        );
      const testGeneratingId = testGenerating?.testId || "";

      if (generationInProgress && _.isEmpty(testGeneratingId)) {
        setCurrentRunTestIndex(-1);
        return;
      }

      // We have a running test - set the next test to display in completion bar
      setGenerationIsWaitingToStart(false);
      setCurrentRunTestIndex(
        testsData.tests.findIndex((t) => t.id === testGeneratingId)
      );
      setCurrentRunTestDescription(testGenerating?.description || "");
    }
  }, [testsData.dataGenerationUpdateState]);

  useEffect(() => {
    // Do we have anything in process for this user?
    if (
      testsData.testRunnerUpdateState &&
      testsData.testRunnerUpdateState.queuesByUser &&
      testsData.testRunnerUpdateState.queuesByUser[auth.userName]
    ) {
      var queueItem =
        testsData.testRunnerUpdateState.queuesByUser[auth.userName];

      //Set test results for finished tests
      const finishedTests = queueItem.queue.filter(
        (t) =>
          (t.status === "Done" || t.status === "Error") && t.isGroup === false
      );
      setTestResults(finishedTests);

      // Do we have any pending or in progress tests?
      const hasPendingTests =
        queueItem &&
        queueItem.queue.filter(
          (t) =>
            (t.status === "InProgress" || t.status === "Pending") &&
            t.isGroup === false
        ).length > 0;

      // If none left, indicate run is complete
      if (testRunInProgress && !hasPendingTests) {
        setTestRunIsEnding(true);
        setCurrentRunTestIndex(-1);

        // Wait a few seconds and then hide the progress
        window.setTimeout(() => {
          setTestRunInProgress(false);
          setTestRunIsEnding(false);
        }, 4000);

        return;
      }

      if (!queueItem) return;

      // Check which test is running and set its index. If none, wait until the next state update.
      const testRunning = queueItem.queue.find(
        (t) => t.status === "InProgress" && t.isGroup === false
      );
      const testRunningId = testRunning?.id || "";

      if (testRunInProgress && _.isEmpty(testRunningId)) {
        setCurrentRunTestIndex(-1);
        return;
      }

      // We have a running test - set the next test to display in completion bar
      setTestRunIsWaitingToStart(false);
      setCurrentRunTestIndex(
        testsData.tests.findIndex((t) => t.id === testRunningId)
      );
      setCurrentRunTestDescription(testRunning?.description || "");
    }
  }, [testsData.testRunnerUpdateState]);

  // The API call to load data is actually a side effect in most cases since a dispatch to setTestsData must usually
  //  happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //  useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadTests();
    }
  }, [auth.authenticated, loadData]);

  async function loadTests(
    startingBulkRun = false,
    startingBulkGenerate = false,
    overrideSearch = null
  ) {
    apiLoad.call(
      overrideSearch === null ? testsData.search : overrideSearch,
      (result) => {
        setLoadData(false);

        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setTestsData({
          type: ContextProviderActions.loadTests,
          payload: {
            tests: vms,
            count,
          },
        });

        // If we are starting a bulk run, go into run mode
        if (startingBulkRun) {
          kickOffTestRunProcess(vms);
        } else if (startingBulkGenerate) {
          kickOffGenerateDataProcess(vms);
        }
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setTestResults(finishedTests) {
    const newRows = [...testsData.tests];
    if (finishedTests.length === 0) return;

    let rowTest;
    for (let i = 0; i < finishedTests.length; i++) {
      rowTest = newRows.find((t) => t.id === finishedTests[i].id);
      if (rowTest) {
        rowTest.lastRunDate = finishedTests[i].lastRunDate;
        rowTest.lastRunBy = finishedTests[i].lastRunBy;
        rowTest.lastRunDurationMilliseconds =
          finishedTests[i].lastRunDurationMilliseconds;
        rowTest.lastRunResultStatus = finishedTests[i].lastRunResultStatus;
      }
    }

    setTestsData({
      type: ContextProviderActions.loadTests,
      payload: {
        tests: newRows,
        count: newRows.length,
      },
    });
  }

  function setDataGenerationResults(finishedTests) {
    const newRows = [...testsData.tests];
    if (finishedTests.length === 0) return;

    let rowTest;
    for (let i = 0; i < finishedTests.length; i++) {
      rowTest = newRows.find((t) => t.id === finishedTests[i].testId);
      if (rowTest) {
        rowTest.testVariableCsv = finishedTests[i].testVariableCsv;
      }
    }

    setTestsData({
      type: ContextProviderActions.loadTests,
      payload: {
        tests: newRows,
        count: newRows.length,
      },
    });
  }

  function setSearchChanges(search) {
    setTestsData({
      type: ContextProviderActions.saveTestSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyTestSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : testsData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = testsData.search.orderBy.indexOf(`${event.target.id}+`);
    var indexDesc = testsData.search.orderBy.indexOf(`${event.target.id}-`);

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...testsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...testsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1) return updateSort({ ...testsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...testsData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...testsData.search,
      [target.name]: target.value,
    });
  }

  function handleLastRunResultStatusChanged(statuses) {
    setSearchChanges({ ...testsData.search, lastRunResultStatus: statuses });
  }

  function handleIncludeGroupTestsCheckboxChange({ target }) {
    let changed = { ...testsData.search, [target.name]: target.checked };

    if (target.checked) {
      // When checked, add the group name to the sort order
      if (changed.orderBy.indexOf("testGroupName+") < 0) {
        changed.orderBy = ["testGroupName+", "description+"];
      }
    } else {
      // When unchecked, remove the group name from the sort order
      if (changed.orderBy.indexOf("testGroupName+") >= 0) {
        changed.orderBy = ["description+"];
      }
    }

    setSearchChanges(changed);
  }

  function handleShowOnlyAutoPopulatedTestsCheckboxChange({ target }) {
    let changed = { ...testsData.search, [target.name]: target.checked };

    if (target.checked) {
      // When checked, add the group name to the sort order
      if (changed.orderBy.indexOf("testGroupName+") < 0) {
        changed.orderBy = ["testGroupName+", "description+"];
      }

      // Also check the group checkbox so we see all tests, including those in groups
      changed.includeTestsInGroup = true;
    } else {
      // When unchecked, remove the group name from the sort order
      if (changed.orderBy.indexOf("testGroupName+") >= 0) {
        changed.orderBy = ["description+"];
      }
    }

    setSearchChanges(changed);
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...testsData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...testsData.search, endDate: date });
  }

  async function deleteTest(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Test deleted successfully");
      setLoadData(true);
    });
  }

  async function handleDelete(id, name) {
    setDeleteId(id);
    setAltDeleteId(name);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteTest(deleteId);
  }

  const handleCancelModal = () => {
    setShowCopyModal(false);
  };

  function startCopy(id, name) {
    let updated = {
      ...testsData.search,
      oldTestDescription: name,
      copyId: id,
    };
    setSearchChanges(updated);
    setShowCopyModal(true);
  }

  async function copyTest(id, newName) {
    setShowCopyModal(false);
    apiCopy.call({ id: id, newId: newName }, (result) => {
      notifySuccess("Test copied successfully to " + newName);
      setLoadData(true);
    });
  }

  async function handleCopy(newTestDescription) {
    await copyTest(testsData.search.copyId, newTestDescription);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (testsData.search.description !== "") numFilters++;
    if (testsData.search.lastRunBy !== "") numFilters++;
    if ((testsData.search.lastRunResultStatus || []).length > 0) numFilters++;
    if (testsData.search.includeTestsInGroup === true) numFilters++;
    if (testsData.search.showOnlyAutoPopulatedTests === true) numFilters++;

    return numFilters;
  }

  function performRunTests() {
    // Hide advanced filter when running tests and load all tests
    const newSearch = {
      ...testsData.search,
      pageSize: 10000,
      showAdvancedFilter: false,
    };
    setSearchChanges(newSearch);

    // If not all tests in filter are showing, load all the tests.
    if (testsData.tests.length < testsData.count) {
      // This will set run mode once load is complete
      loadTests(true, false, newSearch);
    } else {
      kickOffTestRunProcess(testsData.tests);
    }
  }

  function cancelTestRun() {
    const model = { tests: testsData.tests };
    model.username = auth.userName;

    setTestRunIsWaitingToCancel(true);

    apiCancelRunForTest.call({ model: model }, async (result) => {
      notifySuccess("Test run cancelled. It will stop momentarily...");
    });
  }

  function kickOffTestRunProcess(genTests) {
    const model = { tests: genTests };
    model.username = auth.userName;

    apiStartRunForTest.call({ model: model }, () => {
      setTestRunIsWaitingToStart(true);
      setTestRunInProgress(true);
      notifySuccess(
        "The test run request has been sent. It will begin momentarily..."
      );
    });
  }

  function handleConfirmGenerateTestData() {
    setShowGenerateDataModal(true);
  }

  function performGenerateTestData() {
    setShowGenerateDataModal(false);

    // Hide advanced filter when generating all tests and load all tests
    const newSearch = {
      ...testsData.search,
      pageSize: 10000,
      showAdvancedFilter: false,
    };
    setSearchChanges(newSearch);

    // If not all tests in filter are showing, load all the tests.
    if (testsData.tests.length < testsData.count) {
      // This will set generate mode once load is complete
      loadTests(false, true, newSearch);
    } else {
      kickOffGenerateDataProcess(testsData.tests);
    }
  }

  function cancelTestGenerateData() {
    const model = { tests: testsData.tests };

    setGenerationIsWaitingToCancel(true);

    apiCancelGenerateTestData.call({ model: model }, async (result) => {
      notifySuccess("Test generation cancelled. It will stop momentarily...");
    });
  }

  function kickOffGenerateDataProcess(genTests) {
    const model = { tests: genTests };
    model.username = auth.userName; // Sets lastGeneratedBy on each test

    apiGenerateDataForTests.call({ model: model }, () => {
      setGenerationIsWaitingToStart(true);
      setGenerationInProgress(true);
      notifySuccess(
        "The data population request has been sent. It will begin momentarily..."
      );
    });
  }

  // Run percentage calculation
  let userState = null;
  if (
    testsData.testRunnerUpdateState &&
    testsData.testRunnerUpdateState.queuesByUser &&
    testsData.testRunnerUpdateState.queuesByUser[auth.userName]
  ) {
    userState = testsData.testRunnerUpdateState.queuesByUser[auth.userName];
  }
  let runCompleted = userState === null ? 0 : userState.totalRecordsProcessed;
  let totalToRun = userState === null ? 1 : userState.totalRecordsToProcess;
  let runPercentage = formatDecimal(
    (runCompleted / (totalToRun === 0 ? 1 : totalToRun)) * 100,
    0
  );

  if (testRunIsWaitingToStart) {
    runCompleted = 0;
    totalToRun = 0;
    runPercentage = 0;
  } else if (testRunIsEnding) {
    runPercentage = 100;
  }

  // Data generation percentage calculation
  let generateCompleted =
    testsData.dataGenerationUpdateState?.totalRecordsProcessed || 0;
  let totalToGenerate =
    testsData.dataGenerationUpdateState?.totalRecordsToProcess || 1;
  let generatePercentage = formatDecimal(
    (generateCompleted / totalToGenerate) * 100,
    0
  );

  if (generationIsWaitingToStart) {
    generateCompleted = 0;
    totalToGenerate = 0;
    generatePercentage = 0;
  }

  return (
    <Authorize>
      <TestsWebSocketHubConnections />
      <StyledScreenHelpDiv>
        {((testsData.search.showAdvancedFilter && !isMobileSize) ||
          !testsData.search.showAdvancedFilter) && (
          <HelpLink path="/Testing/Tests-List-Screen" label="Help" />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Tests</h1>
        <StyledHeaderRowButtonDiv>
          {!testRunInProgress && !generationInProgress && (
            <button
              type="button"
              className="btn btn-secondary btn-with-icon"
              onClick={() => navigate("/test")}
            >
              <span className="material-icons">add</span>
              {"  "}Add Test
            </button>
          )}
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      {!testRunInProgress && !generationInProgress && (
        <GridFreeFormSearchBar
          placeholderText="Search Description, Run by, or Result"
          search={testsData.search}
          setSearch={setSearchChanges}
          numSetFilters={getNumberOfSetFilters()}
          onSubmitSearch={onSubmit}
        />
      )}
      <DuplicateRecordDialog
        title="Duplicate Test"
        instructions={`Enter a new description for the test to be copied from '${testsData.search.oldTestDescription}'.`}
        value={testsData.search.newTestDescription || ""}
        showModal={showCopyModal}
        onCancel={handleCancelModal}
        onCopy={handleCopy}
        placeholder="New Test Description"
      />
      <ConfirmDialog
        title="Remove Test"
        question={`Are you sure you wish to delete the test '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <ConfirmDialog
        title="Confirm Test Data Generation"
        question={`Generating new data will delete any existing data on these tests. Do you want to continue?`}
        showModal={showGenerateDataModal}
        onNo={() => setShowGenerateDataModal(false)}
        onYes={performGenerateTestData}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              testsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={testsData.search}
            setSearch={setSearchChanges}
            helpLink="/Testing/Tests-List-Screen&anchor=filters"
          >
            <TestSearchForm
              errors={errors}
              search={testsData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onCheckboxChange={handleIncludeGroupTestsCheckboxChange}
              onShowOnlyAutoPopulatedTestsChecked={
                handleShowOnlyAutoPopulatedTestsCheckboxChange
              }
              onLastRunResultStatusChanged={handleLastRunResultStatusChanged}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
            />
          </GridAdvancedFilter>
        </div>

        {testsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || deleting || copying ? (
              <Spinner />
            ) : (
              <>
                {testRunInProgress ? (
                  <SimpleCompletionBar
                    title={
                      <h4 style={{ fontSize: "20px" }}>
                        {currentRunTestDescription === "Done!" ? (
                          currentRunTestDescription
                        ) : (
                          <>
                            {startingTestRun || testRunIsWaitingToStart
                              ? `Test run is starting...`
                              : testRunIsEnding
                              ? `Test run complete!`
                              : testRunInProgress
                              ? `Running test ${currentRunTestDescription}...`
                              : testRunIsWaitingToCancel
                              ? `Cancelling test run...`
                              : ``}
                          </>
                        )}
                      </h4>
                    }
                    runPercentage={runPercentage}
                    disableCancel={
                      cancellingTestRun || testRunIsWaitingToCancel
                    }
                    onCancel={cancelTestRun}
                  />
                ) : generationInProgress ? (
                  <SimpleCompletionBar
                    title={
                      <h4 style={{ fontSize: "20px" }}>
                        {currentRunTestDescription === "Done!" ? (
                          currentRunTestDescription
                        ) : (
                          <>
                            {startingDataGeneration ||
                            generationIsWaitingToStart
                              ? `Data generation is starting...`
                              : generationIsEnding
                              ? `Data generation complete!`
                              : generationInProgress
                              ? `Generating test data for ${currentRunTestDescription}...`
                              : generationIsWaitingToCancel
                              ? `Cancelling data generation...`
                              : ``}
                          </>
                        )}
                      </h4>
                    }
                    runPercentage={generatePercentage}
                    disableCancel={
                      cancellingGenerateTestData || generationIsWaitingToCancel
                    }
                    onCancel={cancelTestGenerateData}
                  />
                ) : (
                  <StyledHeaderRowDiv>
                    <div>&nbsp;</div>
                    <StyledHeaderRowButtonDiv>
                      {tests.length > 0 && (
                        <button
                          type="button"
                          className="btn btn-secondary btn-with-icon"
                          onClick={performRunTests}
                          style={{ marginLeft: "auto", marginTop: "20px" }}
                        >
                          <span className="material-icons">
                            play_circle_outline
                          </span>
                          {"  "}Run all Tests in Filter
                        </button>
                      )}
                      {testsData.search &&
                        testsData.search.showOnlyAutoPopulatedTests ===
                          true && (
                          <button
                            type="button"
                            className="btn btn-secondary btn-with-icon"
                            onClick={handleConfirmGenerateTestData}
                            style={{ marginLeft: "10px", marginTop: "20px" }}
                          >
                            <span className="material-icons">build</span>
                            {"  "}Generate Data for all Tests in Filter
                          </button>
                        )}
                    </StyledHeaderRowButtonDiv>
                  </StyledHeaderRowDiv>
                )}

                <TestsList
                  tests={tests}
                  currentRunTestIndex={currentRunTestIndex}
                  isRunning={testRunInProgress}
                  isGeneratingData={generationInProgress}
                  onDelete={handleDelete}
                  search={testsData.search}
                  setSearch={async (search) => {
                    setSearchChanges(search);
                    // Only do server-side search if the user didn't just perform a client op only
                    if (!search.isClientOpOnly) {
                      await handleSearch(undefined, search);
                    }
                  }}
                  onSort={handleSort}
                  totalRecords={testsData.count}
                  onCopy={startCopy}
                />
              </>
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default Tests;
