import React, { useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

function ActionMenu({ title, items, onSelectAction }) {
  const [showActionsMenu, setShowActionsMenu] = useState(false);

  let actionsMenuTimer = null;
  function handleMouseEnterActionsMenu() {
    window.clearTimeout(actionsMenuTimer);
    setShowActionsMenu(true);
  }

  function handleMouseLeaveActionsMenu() {
    // Wait a small amount of time before actually closing the menu since a very small gap below the dropdown
    //   and above the menu can cause the menu to close immediately if the user moves the mouse slowly.
    window.clearTimeout(actionsMenuTimer);
    actionsMenuTimer = window.setTimeout(() => setShowActionsMenu(false), 500);
  }

  function handleMouseClickActionsMenu(e, show) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();

      // Click also fires on each item, so check for dropdown item class to prevent keeping the menu open.
      if (e.target.className !== "dropdown-item") {
        setShowActionsMenu(show);
      }
    }
  }

  return (
    <StyledActionBar>
      <Nav>
        <NavDropdown
          title={title}
          show={showActionsMenu}
          onMouseEnter={handleMouseEnterActionsMenu}
          onMouseLeave={handleMouseLeaveActionsMenu}
          onClick={(e) => handleMouseClickActionsMenu(e, true)}
          onSelect={() => setShowActionsMenu(false)}
        >
          {items.map((item, idx) => {
            if (item.show !== false) {
              return item.isLink || false ? (
                <NavDropdown.Item
                  key={item.value}
                  as={Link}
                  to={item.url}
                  onClick={() => {
                    setShowActionsMenu(false);
                  }}
                >
                  {item.label}
                </NavDropdown.Item>
              ) : (
                <NavDropdown.Item
                  key={item.value}
                  as={Link}
                  onClick={() => {
                    setShowActionsMenu(false);
                    onSelectAction(item.value, item.label);
                  }}
                >
                  {item.label}
                </NavDropdown.Item>
              );
            } else return <React.Fragment key={`am-${idx}`}></React.Fragment>;
          })}
        </NavDropdown>
      </Nav>
    </StyledActionBar>
  );
}
const StyledActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 5px;
  padding: 5px 0;

  @media only screen and (max-width: 767px) {
    margin-right: -5px;
  }

  .nav-link {
    padding: 0;
    color: var(--link);

    &:hover {
      color: var(--link-active);
    }
  }
`;

export default ActionMenu;
