import { toast } from "react-toastify";
import React from "react";
import { Link } from "react-router-dom";

const CustomToastWithLink = (
  headerText,
  beforeText,
  linkText,
  link,
  afterText
) => (
  <>
    <h4>{headerText}</h4>
    <p>
      {beforeText}
      <Link to={link}>{linkText}</Link>
      {afterText}
    </p>
  </>
);

function getLinkInfo(message) {
  let linkBegin = message.indexOf("<Link>");
  let linkInfo = [];
  if (linkBegin > -1) {
    let beforeText = message.substring(0, linkBegin);
    let linkEnd = message.indexOf("</Link>");
    if (linkEnd > -1) {
      let linkInterior = message.substring(linkBegin + 6, linkEnd - 1);
      let afterText = message.substring(linkEnd + 7);
      if (message.indexOf("||") > -1) {
        let linkUrl = linkInterior.split("||")[0];
        let linkText = linkInterior.split("||")[1];
        linkInfo = [beforeText, linkText, linkUrl, afterText];
      }
    }
  }
  return linkInfo;
}

// jon, 10/6/22: Make error toasts disapear after 1 minute.
export function notifyError(msg, toastOptionsOverride) {
  var li = getLinkInfo(msg);
  if (li.length === 4) {
    notifyErrorWithLink(
      CustomToastWithLink("Error", li[0], li[1], li[2], li[3]),
      toastOptionsOverride
    );
    return;
  }

  const message = (
    <>
      <h4>Error</h4>
      <p>{msg}</p>
    </>
  );

  // jon, 4/18/24: toastId should prevent duplicate generic errors
  toast.error(message, {
    toastId: msg,
    autoClose: 30000,
    ...toastOptionsOverride,
  });
}

export function notifyErrorWithLink(CustomToastWithLink, toastOptionsOverride) {
  toast.error(CustomToastWithLink, {
    autoClose: 60000,
    ...toastOptionsOverride,
  });
}

export function notifySuccess(msg, toastOptionsOverride) {
  const message = (
    <>
      <h4>Success</h4>
      <p>{msg}</p>
    </>
  );
  toast.success(message, { ...toastOptionsOverride, autoClose: 5000 });
}

export function notifyWarn(msg, toastOptionsOverride) {
  const message = (
    <>
      <h4>Warning</h4>
      <p>{msg}</p>
    </>
  );
  toast.warn(message, { ...toastOptionsOverride, autoClose: 10000 });
}

export function notifyInfo(msg, toastOptionsOverride) {
  const message = (
    <>
      <h4>Information</h4>
      <p>{msg}</p>
    </>
  );
  toast.info(message, { ...toastOptionsOverride, autoClose: 10000 });
}
