import React from "react";
import styled from "styled-components";
import ToggleSwitchInput from "../../common/input/ToggleSwitchInput";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

function DashboardPanelEditMode({ id, panel, setVisibility }) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });

  function onChangeShowPanel() {
    const show = !panel.visible;
    setVisibility(panel.reportId, show);
  }

  // Remove scaling because it was warping the chips in a weird way.
  const _transform = { ...transform, scaleX: 1, scaleY: 1 };
  const style = {
    transform: CSS.Transform.toString(_transform),
    transition,
    opacity: isDragging ? ".5" : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      name={id}
      className="dashboard-edit-panel"
    >
      <StyledPanel>
        {panel.isAlertPanel && <i className="material-icons">error</i>}
        <span className="material-icons" {...attributes} {...listeners}>
          drag_indicator
        </span>
        <p className="panel-label">{panel.panelLabel}</p>
        <label htmlFor="showpanel" style={{ marginBottom: "5px" }}>
          Show panel
        </label>
        <ToggleSwitchInput
          id={`${id}-showpanel`}
          name="showpanel"
          label=""
          onChange={onChangeShowPanel}
          checked={panel.visible}
        />
      </StyledPanel>
    </div>
  );
}

const StyledPanel = styled.div`
  position: relative;
  width: 196px;
  height: 126px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  padding: 16px 16px 8px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  touch-action: none;

  @media only screen and (max-width: 576px) {
    width: 160px;
    height: 126px;
  }

  span[class="material-icons"] {
    position: absolute;
    top: 40%;
    left: 5px;
    font-size: 24px;
    color: var(--text-light);

    &:hover {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

  i[class="material-icons"] {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 28px;
    color: var(--text-light);
  }

  p {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    text-align: center;
    color: var(--text-dark);
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    @media only screen and (max-width: 576px) {
      font-size: 16px;
    }
  }
`;

export default DashboardPanelEditMode;
