import React, { useState, useEffect } from "react";
import ReportTemplatesList from "./ReportTemplatesList";
import ReportTemplateSearchForm from "./ReportTemplateSearchForm";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useReportTemplates } from "../../../contexts/ReportTemplatesContext";
import {
  apiLoadReportTemplates,
  apiDeleteReportTemplate,
  apiCopyReportTemplate,
} from "../../../api/ReportTemplatesApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import { useNavigate } from "react-router-dom";
import DuplicateRecordDialog from "../../dialogs/DuplicateRecordDialog";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import useApi from "../../../hooks/useApi";
import { useReports } from "../../../contexts/ReportsContext";
import { apiLoadDataSourcesAll } from "../../../api/ReportsApi";
import {
  createViewModel,
  emptyReportTemplateSearch,
} from "../../../viewmodels/reportTemplatesVm";
import HelpLink from "../../common/ui/HelpLink";

function ReportTemplates() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { reportTemplatesData, setReportTemplatesData } = useReportTemplates();
  const { reportsData, setReportsData } = useReports();
  const { loading, api: apiLoad } = useApi(apiLoadReportTemplates);
  const { loading: loadingDataSources, api: apiLoadDataSources } = useApi(
    apiLoadDataSourcesAll
  );
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteReportTemplate);
  const { loading: copying, api: apiCopy } = useApi(apiCopyReportTemplate);
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState(true);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const { isMobileSize } = useMobile();

  let templates = [];
  if (reportTemplatesData && reportTemplatesData.templates) {
    templates = reportTemplatesData.templates;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setReportsData must usually
  //  happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //  useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      // Get dashboard data first (without refreshing the actual data) so we can see which reports have been subscribed to.
      loadReportTemplateAndDataSourceList();
    }
  }, [auth.authenticated, loadData]);

  async function loadReportTemplateAndDataSourceList() {
    let dataSourceList = reportsData.sourceListCache || [];

    if ((dataSourceList || []).length === 0) {
      dataSourceList = await loadDataSourceList();

      // Save the list of data sources in the cache
      setReportsData({
        type: ContextProviderActions.setReportDataSourcesCache,
        payload: dataSourceList,
      });
    }

    await loadReportTemplates(dataSourceList);
  }

  async function loadDataSourceList() {
    const dataSourceList = await apiLoadDataSources.call(
      null,
      async (result) => {
        let dsResult = [...result] || [];

        // Filter out admin data sources for non-admins
        if (!auth.isAdmin) {
          dsResult = dsResult.filter(
            (c) => c.reportableItemAllowableRole !== "Admin"
          );
        }

        const dsList = (dsResult || [])
          .map((c) => {
            return { value: c.id, label: c.label };
          })
          .sort((a, b) => (a.label > b.label ? 1 : -1));

        return dsList;
      }
    );

    return dataSourceList || [];
  }

  async function loadReportTemplates(dataSourceList) {
    apiLoad.call(
      reportTemplatesData.search,
      (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) =>
          createViewModel(r, dataSourceList)
        );
        const count = result.count || 0;

        setReportTemplatesData({
          type: ContextProviderActions.loadReportTemplates,
          payload: {
            templates: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setReportTemplatesData({
      type: ContextProviderActions.saveReportTemplateSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyReportTemplateSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : reportTemplatesData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = reportTemplatesData.search.orderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = reportTemplatesData.search.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...reportTemplatesData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...reportTemplatesData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...reportTemplatesData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...reportTemplatesData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...reportTemplatesData.search,
      [target.name]: target.value,
    });
  }

  function handleDataSourceChanged(value) {
    setSearchChanges({ ...reportTemplatesData.search, entityType: value });
  }

  function clearTemplateListCache() {
    // Copy, delete, and save operations invalidate the cache.
    setReportTemplatesData({
      type: ContextProviderActions.setReportTemplateCache,
      payload: [],
    });
  }

  async function deleteReportTemplate(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Template deleted successfully");
      clearTemplateListCache();
      setLoadData(true);
    });
  }

  async function handleDelete(id, name) {
    setDeleteId(id);
    setAltDeleteId(name);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteReportTemplate(deleteId);
  }

  const handleCancelModal = () => {
    setShowCopyModal(false);
  };

  function startCopy(name, id) {
    let updated = { ...reportTemplatesData.search, oldName: { name, id } };
    setSearchChanges(updated);
    setShowCopyModal(true);
  }

  async function copyReportTemplate(oldName, newName) {
    apiCopy.call({ id: oldName.id, newId: newName }, (result) => {
      notifySuccess("Template copied successfully to " + newName);
      clearTemplateListCache();
      setShowCopyModal(false);
      setLoadData(true);
    });
  }

  async function handleCopy(newName) {
    await copyReportTemplate(reportTemplatesData.search.oldName, newName);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (
      reportTemplatesData.search.entityType &&
      reportTemplatesData.search.entityType.value
    )
      numFilters++;
    if (reportTemplatesData.search.name !== "") numFilters++;
    if (reportTemplatesData.search.fileNameTemplate !== "") numFilters++;
    if (reportTemplatesData.search.externalDataRuleTableDefinitionName !== "")
      numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        {((reportTemplatesData.search.showAdvancedFilter && !isMobileSize) ||
          !reportTemplatesData.search.showAdvancedFilter) && (
          <HelpLink path="/Reports/Report-Templates-List-Screen" label="Help" />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Report Templates</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/reporttemplate")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Template
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search Name, Data source, or File Name"
        search={reportTemplatesData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <DuplicateRecordDialog
        title="Duplicate Template"
        instructions={`Enter a new name for the template to be copied from '${
          reportTemplatesData.search.oldName?.name || ""
        }'.`}
        value={reportTemplatesData.search.newName || ""}
        showModal={showCopyModal}
        onCancel={handleCancelModal}
        onCopy={handleCopy}
        placeholder="New Template Name"
      />
      <ConfirmDialog
        title="Remove Template"
        question={`Are you sure you wish to delete the template '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              reportTemplatesData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={reportTemplatesData.search}
            setSearch={setSearchChanges}
            helpLink="/Reports/Report-Templates-List-Screen&anchor=filters"
          >
            <ReportTemplateSearchForm
              errors={errors}
              search={reportTemplatesData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onDataSourceChanged={handleDataSourceChanged}
              dataSourceList={reportsData.sourceListCache}
            />
          </GridAdvancedFilter>
        </div>

        {reportTemplatesData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }} data-testid="reports-list">
            {loading ||
            loadingDataSources ||
            loadData ||
            deleting ||
            copying ? (
              <Spinner />
            ) : (
              <ReportTemplatesList
                templates={templates}
                onDelete={handleDelete}
                search={reportTemplatesData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={reportTemplatesData.count}
                onCopy={startCopy}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default ReportTemplates;
