import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  buildUniqueIdForNode,
  getBadgeColorsFromType,
  NodeTypes,
} from "../rulemaps/RuleMapDataCommon";
import {
  StyledCharblockDiv,
  StyledDescription,
} from "../rulemaps/RuleMapCommonStyles";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import { StyledNoResultsDiv } from "../../common/layout/CommonStyledControls";
import ExpandCollapse from "../../common/layout/ExpandCollapse";
import CheckboxInput from "../../common/input/CheckboxInput";

function GroupRuleMapFilterForm({
  search,
  onSearch,
  onReset,
  ruleMap,
  selectedField,
  onFilterItemClick,
}) {
  const [changedFields, setChangedFields] = useState([]);
  const [responseFields, setResponseFields] = useState([]);
  const [includedRejectionFields, setIncludedRejectionFields] = useState([]);
  const [excludedRejectionFields, setExcludedRejectionFields] = useState([]);
  const [messageFields, setMessageFields] = useState([]);
  const [variableFields, setVariableFields] = useState([]);
  const [filterSelection, setFilterSelection] = useState("NONE");
  const [ruleSelections, setRuleSelections] = useState([]);

  const variableColors = getBadgeColorsFromType(NodeTypes.InternalVariable);
  const responseColors = getBadgeColorsFromType(NodeTypes.ResponseFieldValue);
  const rejectionColors = getBadgeColorsFromType(NodeTypes.Rejection);
  const messageColors = getBadgeColorsFromType(NodeTypes.RootMessage);
  const changedColors = {
    color: "var(--rulemap-default-text)",
    bgColor: "var(--rulemap-default-bg)",
    bgShadow: "var(--rulemap-default-shadow)",
  };
  const allColors = {
    color: "#FFF",
    bgColor: "#000",
    bgShadow: "var(--rulemap-default-shadow)",
  };

  useEffect(() => {
    if (ruleMap && ruleMap.ruleMetadata) {
      // Select all rules by default
      const selections = ruleMap.ruleMetadata.map((r, idx) => {
        return { id: r.id, checked: true };
      });
      setRuleSelections(selections);
    }
  }, [ruleMap?.ruleMetadata]);

  useEffect(() => {
    const fieldLists = buildFieldListsFromRuleMap();

    const searchText = _.trim(search?.freeFormSearch || "");
    if (!_.isEmpty(searchText) && filterSelection === "NONE") {
      setFilterSelection("ALL");
    }

    const filteredFieldLists = filterFieldLists(fieldLists, searchText);

    setIncludedRejectionFields(filteredFieldLists.includedRejectionList);
    setExcludedRejectionFields(filteredFieldLists.excludedRejectionList);
    setResponseFields(filteredFieldLists.responseFieldList);
    setMessageFields(filteredFieldLists.messageList);
    setVariableFields(filteredFieldLists.variableList);
    setChangedFields(filteredFieldLists.changeList);
  }, [filterSelection, search?.freeFormSearch, ruleSelections]);

  function filterListItemBySearchText(array, searchText) {
    let filteredArray = [...array];

    if (!_.isEmpty(searchText)) {
      // Filter by search text
      filteredArray = filteredArray.filter(
        (r) => _.toLower(r.sortLabel).indexOf(_.toLower(searchText)) >= 0
      );
    }

    // Filter by rule
    filteredArray = filteredArray.filter(
      (r) =>
        ruleSelections.findIndex(
          (s) => s.id === r.ruleId && s.checked === true
        ) >= 0
    );

    return filteredArray;
  }

  function filterFieldLists(fieldLists, searchText) {
    if (filterSelection === "NONE")
      return {
        changeList: [],
        includedRejectionList: [],
        excludedRejectionList: [],
        responseFieldList: [],
        variableList: [],
        messageList: [],
      };

    const filteredFieldLists = {
      changeList: [],
      includedRejectionList: filterListItemBySearchText(
        fieldLists.includedRejectionList,
        searchText
      ),
      excludedRejectionList: filterListItemBySearchText(
        fieldLists.excludedRejectionList,
        searchText
      ),
      responseFieldList: filterListItemBySearchText(
        fieldLists.responseFieldList,
        searchText
      ),
      variableList: filterListItemBySearchText(
        fieldLists.variableList,
        searchText
      ),
      messageList: filterListItemBySearchText(
        fieldLists.messageList,
        searchText
      ),
    };

    if (filterSelection === "ALL") return filteredFieldLists;

    if (filterSelection === "CHG") {
      filteredFieldLists.responseFieldList = [];
      filteredFieldLists.includedRejectionList = [];
      filteredFieldLists.excludedRejectionList = [];
      filteredFieldLists.variableList = [];
      filteredFieldLists.messageList = [];
    } else if (filterSelection === "RES") {
      filteredFieldLists.changeList = [];
      filteredFieldLists.includedRejectionList = [];
      filteredFieldLists.excludedRejectionList = [];
      filteredFieldLists.variableList = [];
      filteredFieldLists.messageList = [];
    } else if (filterSelection === "REJ") {
      filteredFieldLists.changeList = [];
      filteredFieldLists.responseFieldList = [];
      filteredFieldLists.variableList = [];
      filteredFieldLists.messageList = [];
    } else if (filterSelection === "VAR") {
      filteredFieldLists.changeList = [];
      filteredFieldLists.responseFieldList = [];
      filteredFieldLists.includedRejectionList = [];
      filteredFieldLists.excludedRejectionList = [];
      filteredFieldLists.messageList = [];
    } else if (filterSelection === "MSG") {
      filteredFieldLists.changeList = [];
      filteredFieldLists.responseFieldList = [];
      filteredFieldLists.includedRejectionList = [];
      filteredFieldLists.excludedRejectionList = [];
      filteredFieldLists.variableList = [];
    }

    return filteredFieldLists;
  }

  function getDropdownLabel(code, description, type) {
    const label = (
      <StyledDescription
        className="flex-row-without-wrap"
        colors={getBadgeColorsFromType(type, true, true)}
      >
        <span className="option-member-name normal-case force-wrap">
          <span className="charblock" style={{ fontSize: "13px" }}>
            {code}
          </span>
          &nbsp;&nbsp;
          <span className="group-rulemap-label">{description}</span>
        </span>
      </StyledDescription>
    );
    return label;
  }

  function buildFilterItem(node, nodeType, key, description) {
    const prefix =
      nodeType === NodeTypes.Rejection ||
      nodeType === NodeTypes.ExcludedRejection ||
      nodeType === NodeTypes.ConditionalNode
        ? "ROOT-"
        : "";
    const item = {
      label: getDropdownLabel(key, description, nodeType),
      sortLabel: `${key} ${description}`,
      value: `${prefix}${buildUniqueIdForNode(node, nodeType)}`,
      type: nodeType,
      ruleId: node.ruleId,
    };

    return item;
  }

  function buildFieldListsFromRuleMap() {
    let desc;
    const includedRejectionList = (ruleMap.includedRejections || [])
      .map((r) => {
        desc = r.itemValue;
        return buildFilterItem(r, NodeTypes.Rejection, r.itemKey, desc);
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    const excludedRejectionList = (ruleMap.excludedRejections || [])
      .map((r) => {
        desc = r.description;
        return buildFilterItem(r, NodeTypes.ExcludedRejection, r.itemKey, desc);
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    const responseFields = ruleMap.responseFields || [];

    const responseFieldList = responseFields
      .map((r) => {
        desc =
          r.description !== null
            ? r.description
            : r.displayKey !== null
            ? r.displayKey
            : "DESC. MISSING IN DATA";
        return buildFilterItem(
          r,
          NodeTypes.ResponseFieldValue,
          r.itemKey,
          desc
        );
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    const messageFields = ruleMap.messageFields || [];

    const messageList = messageFields
      .map((r) => {
        desc = r.sourceDisplayKey;
        return buildFilterItem(r, r.type, "MSG", desc);
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    const variableFields = ruleMap.includedDataValues || [];

    const variableList = variableFields
      .map((r) => {
        desc = r.itemKey;
        return buildFilterItem(r, NodeTypes.InternalVariable, "VAR", desc);
      })
      .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

    return {
      includedRejectionList,
      excludedRejectionList,
      responseFieldList,
      messageList,
      variableList,
    };
  }

  function handleFilterSelectionChange(option) {
    setFilterSelection(option);
  }

  function getRuleCheckboxState(id) {
    let checked = false;
    const checkbox = ruleSelections.find((s) => s.id === id);
    if (!checkbox) {
      return false;
    }
    checked = checkbox.checked;
    return checked;
  }

  function handleRuleCheckboxChange({ target }) {
    if (filterSelection === "NONE") {
      setFilterSelection("ALL");
    }

    const rs = [...ruleSelections];
    const checkbox = rs.find((s) => s.id === target.id);
    if (checkbox) {
      checkbox.checked = target.checked;
    }
    setRuleSelections(rs);
  }

  function getStyledFilterList(array, title, type, selected) {
    const list = (
      <>
        {array.length > 0 && <h4>{title}</h4>}
        <StyledFilterList>
          {array.map((r, idx) => (
            <li
              key={`li-${idx}`}
              className={
                r.value === selected ? "group-rulemap-label-active" : ""
              }
              onClick={() =>
                onFilterItemClick(
                  r.value,
                  type === NodeTypes.Unknown ? r.type : type
                )
              }
            >
              {r.label}
            </li>
          ))}
        </StyledFilterList>
      </>
    );

    return list;
  }

  function getToggleButtonGroupInputButton(value, label, colors) {
    const button = {
      value: value,
      label: (
        <StyledCharblockDiv className="charblock" colors={colors}>
          {label}
        </StyledCharblockDiv>
      ),
    };

    return button;
  }

  const allEmpty =
    responseFields.length === 0 &&
    includedRejectionFields.length === 0 &&
    excludedRejectionFields.length === 0 &&
    messageFields.length === 0 &&
    variableFields.length === 0 &&
    changedFields.length === 0;

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <ExpandCollapse
            id="groupRuleScreenRuleSelection"
            title="Rule Selection"
            defaultState={false}
          >
            <StyledCheckboxList>
              {ruleMap.ruleMetadata.map((r, idx) => (
                <CheckboxInput
                  key={`rulechk-${idx}`}
                  id={r.id}
                  label={r.name}
                  onChange={handleRuleCheckboxChange}
                  placeholder=""
                  name={r.id}
                  showLabelInline={true}
                  checked={getRuleCheckboxState(r.id)}
                />
              ))}
            </StyledCheckboxList>
          </ExpandCollapse>
          <ExpandCollapse
            id="groupRuleScreenDateSelection"
            title="Date Selection"
            defaultState={false}
          >
            <i>Coming soon...</i>
          </ExpandCollapse>
          <ExpandCollapse
            id="groupRuleScreenFieldSelection"
            title="Field Selection"
            defaultState={true}
            style={{ marginTop: "40px" }}
          >
            <ToggleButtonGroupInput
              id="filterSelection"
              name="filterSelection"
              label=""
              customWidth="15%"
              labelStyle={{ display: "none" }}
              controlClass="btn-group-toolbar"
              buttonClass="btn-group-btn"
              options={[
                getToggleButtonGroupInputButton("ALL", "ALL", allColors),
                getToggleButtonGroupInputButton("CHG", "CHG", changedColors),
                getToggleButtonGroupInputButton("RES", "RES", responseColors),
                getToggleButtonGroupInputButton("REJ", "REJ", rejectionColors),
                getToggleButtonGroupInputButton("VAR", "VAR", variableColors),
                getToggleButtonGroupInputButton("MSG", "MSG", messageColors),
              ]}
              value={filterSelection}
              onChange={handleFilterSelectionChange}
            />
            {allEmpty ? (
              <StyledNoResultsDiv style={{ marginTop: "20px" }}>
                <p>
                  <i className="material-icons">search_off</i>
                </p>
                <p>Filter returned no results</p>
              </StyledNoResultsDiv>
            ) : (
              <StyledResultsContent>
                {getStyledFilterList(
                  responseFields,
                  "Response Fields",
                  NodeTypes.ResponseFieldValue,
                  selectedField?.value
                )}
                {getStyledFilterList(
                  includedRejectionFields,
                  "Included Rejections",
                  NodeTypes.Rejection,
                  selectedField?.value
                )}
                {getStyledFilterList(
                  excludedRejectionFields,
                  "Excluded Rejections",
                  NodeTypes.ExcludedRejection,
                  selectedField?.value
                )}
                {getStyledFilterList(
                  messageFields,
                  "Messages",
                  NodeTypes.Unknown,
                  selectedField?.value
                )}
                {getStyledFilterList(
                  variableFields,
                  "Internal Variables",
                  NodeTypes.InternalVariable,
                  selectedField?.value
                )}
              </StyledResultsContent>
            )}
          </ExpandCollapse>
        </div>
      </form>
    </>
  );
}

const StyledResultsContent = styled.div`
  margin-top: 20px;

  h4 {
    margin-top: 10px;
  }
`;

const StyledFilterList = styled.ul`
  margin-top: 10px;
  padding-left: 5px;

  li {
    text-indent: 0;
    list-style-type: none;
    cursor: pointer;
    margin-bottom: 2px;
    border-bottom: 1px solid transparent;

    &:hover span.group-rulemap-label {
      color: var(--text-highlight);
      background-color: var(--table-hover-row-bg);
    }

    &:active span.group-rulemap-label {
      color: var(--text-highlight);
      background-color: transparent;
    }

    &.group-rulemap-label-active {
      color: var(--text-highlight);
      background-color: var(--table-hover-row-bg);
      border-radius: 0 20px 20px 0;

      span.group-rulemap-label {
        color: var(--text-highlight);
        background-color: var(--table-hover-row-bg);
      }
    }
  }
`;

const StyledCheckboxList = styled.div`
  div.form-group {
    margin-top: 0px;

    label {
      font-size: 14px;
    }
  }
`;

export default GroupRuleMapFilterForm;
