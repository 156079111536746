import React, { useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  emptyFieldSegment,
  emptyGroupSegment,
  emptyHeaderSegment,
  emptyRecordSegment,
} from "../../../viewmodels/packetParserVm";
import ParsedPacketGroup from "./ParsedPacketGroup";
import { usePacketParser } from "../../../contexts/PacketParserContext";
import { notifyError } from "../../../services/NotificationService";

const AsciiChars = {
  STX: 2,
  ETX: 3,
  GS: 29,
  RS: 30,
  FS: 28,
};

const ParseState = {
  StartPacket: 0,
  Header: 1,
  GroupSegment: 2,
  RecordSegment: 3,
  FieldSegment: 4,
  FieldCode: 5,
  FieldValue: 6,
  EndPacket: 7,
  Done: 8,
};

function AdvancedPacketEditor({
  id,
  value,
  error,
  fields,
  isRequest,
  isReadOnly = false,
  displayType = "condensed",
  setDisplayType,
}) {
  const { packetParserData } = usePacketParser();

  useEffect(() => {
    if (
      !isReadOnly &&
      packetParserData.selectedFieldId &&
      packetParserData.selectedFieldId !== null
    ) {
      scrollToSelectedField();
    }
  }, [packetParserData.selectedFieldId]);

  function scrollIntoView(element) {
    window.setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 250);
  }

  function scrollToSelectedField() {
    const selectedId =
      packetParserData.selectedFieldId.isRequest === isRequest
        ? packetParserData.selectedFieldId.fieldId ?? ""
        : "";
    if (selectedId === "") return;

    let elementId = `FLD-${selectedId}`;
    let element = document.getElementById(elementId);
    if (element) {
      scrollIntoView(element);
      return;
    }
  }

  function getLookAheadChar(contents, pos) {
    if (pos + 1 >= contents.length) return "";
    else return contents[pos + 1];
  }

  function cloneObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  function getHeaderSegment(s) {
    const header = cloneObject(emptyHeaderSegment);

    // Use the header fields passed into this component to locate and divide the header string into actual fields.
    const headerFields = (fields || []).filter(
      (field) => field.segmentCode === "REQ" && field.transactionIndex === -1
    );

    if (!headerFields || headerFields.length === 0) {
      console.log("No header fields found while parsing header string.", s);
      return header;
    }

    // Since the first field in the header string is not actually included in the fields array, we ned to get the
    //  first field in the array and locate the position of that.  Then everything before that is the actual first
    //  field: the sender id.
    const firstField = headerFields[0];
    let firstFieldPos = s.indexOf(firstField.valueAsString);
    if (firstFieldPos >= 0) {
      // Add this field to the record
      const val = s.substring(0, firstFieldPos);
      header.fields.push({
        ...emptyFieldSegment,
        isRequest,
        id: `${isRequest ? "REQ" : "RES"}-${firstField.transactionIndex}-${
          firstField.segmentCode
        }-SENDERID`,
        fieldId: "SENDERID",
        fieldGroupOrdinal: 0,
        transactionIndex: firstField.transactionIndex,
        segmentCode: firstField.segmentCode,
        length: val.length,
        value: val,
        isHeaderField: true,
      });
    }

    // Now go through each header field, locate their position in the header string and add them to the fields array.
    let pos = -1;
    headerFields.forEach((field) => {
      pos = s.indexOf(field.valueAsString, firstFieldPos);
      if (pos > 0) {
        header.fields.push({
          ...emptyFieldSegment,
          isRequest,
          id: `${isRequest ? "REQ" : "RES"}-${field.transactionIndex}-${
            field.segmentCode
          }-${field.fieldId}`,
          fieldId: field.fieldId,
          fieldGroupOrdinal: 0,
          transactionIndex: field.transactionIndex,
          segmentCode: field.segmentCode,
          length: field.valueAsString.length,
          value: field.valueAsString,
          isHeaderField: true,
        });
      }
    });

    return header;
  }

  function getFieldGroupOrdinal(fieldCode, segmentFieldCodes) {
    const fieldCodes = segmentFieldCodes || [];
    let count = 0;

    // Field codes are added to this array before they can be checked, so expect there to be one in the normal case. If more than that,
    //   then we will return a non-zero ordinal.
    const alreadySeen = fieldCodes.filter((code) => code === fieldCode);
    if (alreadySeen && alreadySeen.length > 1) {
      count = alreadySeen.length - 1;
    }

    return count;
  }

  function parseContentsIntoTokenArray(contents) {
    let tokens = []; // Array of group segments
    let isError = false;
    let pos = 0;
    let state = ParseState.StartPacket;
    let s = "";
    let fieldCode = "";
    let segmentFieldCodes = []; // Keeps track of how many times we have seen each field code in the current segment - used to set id on fields

    // Current group and record
    let group = null;
    let record = null;

    while (!isError && pos < contents.length && state !== ParseState.Done) {
      switch (state) {
        case ParseState.StartPacket: {
          if (contents[pos].charCodeAt(0) !== AsciiChars.STX) {
            console.log(
              `Unexpected start of packet character: ${contents[pos]}. Expected STX (${AsciiChars.STX})`
            );
            isError = true;
          } else {
            pos++;
            state = ParseState.Header;
          }
          break;
        }
        case ParseState.Header: {
          const lchar = getLookAheadChar(contents, pos).charCodeAt(0);
          if (lchar !== AsciiChars.RS && lchar !== AsciiChars.GS) {
            s = s + contents[pos];
          } else {
            s = s + contents[pos];
            group = {
              ...cloneObject(emptyGroupSegment),
              isHeader: true,
              id: -1,
              transactionIndex: -1,
              headerContents: getHeaderSegment(s),
            };
            s = "";

            state =
              lchar === AsciiChars.RS
                ? ParseState.RecordSegment
                : ParseState.GroupSegment;
          }
          pos++;
          break;
        }
        case ParseState.RecordSegment: {
          if (contents[pos].charCodeAt(0) !== AsciiChars.RS) {
            console.log(
              `Unexpected start of record segment character: ${
                contents[pos]
              } (${contents[pos].charCodeAt(0)}). Expected RS (${
                AsciiChars.RS
              })`
            );
            isError = true;
          } else {
            // Add the last record to this group and start a new one
            // We can't set the id and segmentCode until we find the AM field
            if (record !== null) group.records.push(record);
            record = cloneObject(emptyRecordSegment);
            segmentFieldCodes = [];
            pos++;
            state = ParseState.FieldSegment;
          }
          break;
        }
        case ParseState.FieldSegment: {
          if (contents[pos].charCodeAt(0) !== AsciiChars.FS) {
            console.log(
              `Unexpected start of field segment character: ${
                contents[pos]
              } (${contents[pos].charCodeAt(0)}). Expected FS (${
                AsciiChars.FS
              })`
            );
            isError = true;
          } else {
            pos++;
            state = ParseState.FieldCode;
          }
          break;
        }
        case ParseState.FieldCode: {
          // Field code is always two characters
          fieldCode = contents[pos] + getLookAheadChar(contents, pos);
          segmentFieldCodes.push(cloneObject(fieldCode));
          pos = pos + 2;
          state = ParseState.FieldValue;
          break;
        }
        case ParseState.FieldValue: {
          const lchar = getLookAheadChar(contents, pos).charCodeAt(0);
          if (
            lchar !== AsciiChars.RS &&
            lchar !== AsciiChars.FS &&
            lchar !== AsciiChars.GS &&
            lchar !== AsciiChars.ETX
          ) {
            s = s + contents[pos];
          } else {
            s = s + contents[pos];

            // If the fieldCode is AM, then set the current record's keys using this value
            if (fieldCode === "AM") {
              record.id = `${group.transactionIndex}-${s}`;
              record.segmentCode = s;
            }

            // Add this field to the record
            const fieldGroupOrdinal = getFieldGroupOrdinal(
              fieldCode,
              segmentFieldCodes
            );
            const subFieldId =
              fieldGroupOrdinal > 0 ? `-${fieldGroupOrdinal}` : "";
            record.fields.push({
              ...emptyFieldSegment,
              isRequest,
              id: `${group.transactionIndex}-${record.segmentCode}-${fieldCode}${subFieldId}`,
              fieldId: fieldCode,
              fieldGroupOrdinal: fieldGroupOrdinal,
              transactionIndex: group.transactionIndex,
              segmentCode: record.segmentCode,
              length: s.length,
              value: s,
            });
            s = "";
            fieldCode = "";

            state =
              lchar === AsciiChars.FS
                ? ParseState.FieldSegment
                : lchar === AsciiChars.RS
                ? ParseState.RecordSegment
                : lchar === AsciiChars.GS
                ? ParseState.GroupSegment
                : ParseState.EndPacket;
          }
          pos++;
          break;
        }
        case ParseState.GroupSegment: {
          if (contents[pos].charCodeAt(0) !== AsciiChars.GS) {
            console.log(
              `Unexpected start of group segment character: ${
                contents[pos]
              } (${contents[pos].charCodeAt(0)}). Expected GS (${
                AsciiChars.RS
              })`
            );
            isError = true;
          } else {
            // Add the last record to this group
            if (record !== null) group.records.push(record);
            record = null;
            segmentFieldCodes = [];

            // Add this group and then start a new non-header group
            if (group !== null) tokens.push(group);
            group = {
              ...cloneObject(emptyGroupSegment),
              isHeader: false,
              id: group.id + 1,
              transactionIndex: group.transactionIndex + 1,
              headerContents: "",
            };

            pos++;
            state = ParseState.RecordSegment;
          }
          break;
        }
        case ParseState.EndPacket: {
          if (contents[pos].charCodeAt(0) !== AsciiChars.ETX) {
            console.log(
              `Unexpected end of packet character: ${contents[pos]}. Expected ETX (${AsciiChars.ETX})`
            );
            isError = true;
          } else {
            // Add the last record to the last group
            group.records.push(record);

            // Save the last group
            tokens.push(group);

            pos++;
            state = ParseState.Done;
          }
          break;
        }

        default: {
          console.log(`Parse Error. Unknown parsing state: ${state}`);
          isError = true;
        }
      }
    }

    return tokens;
  }

  if (_.isEmpty(value)) {
    return <i>Nothing found to parse</i>;
  }

  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  if (fields && fields.length > 0) {
    let parsedContents = <></>;
    try {
      parsedContents = parseContentsIntoTokenArray(value);
    } catch (ex) {
      notifyError("Error parsing contents for packet viewer: " + ex);
    }

    return (
      <div className={wrapperClass}>
        <div className="field">
          <StyledEditorDiv id={id}>
            {value && value !== "" ? (
              <>
                <StyledSTXSpan
                  displayType={displayType}
                  className="parser-control-char"
                >
                  START
                </StyledSTXSpan>{" "}
              </>
            ) : (
              <></>
            )}
            {parsedContents.map((group, idx) => {
              return (
                <ParsedPacketGroup
                  key={group.id}
                  group={group}
                  isReadOnly={isReadOnly}
                  displayType={displayType}
                  setDisplayType={setDisplayType}
                  fields={fields}
                  transactionNumber={idx}
                />
              );
            })}
            {value && value !== "" ? (
              <>
                {" "}
                <StyledETXSpan
                  displayType={displayType}
                  className="parser-control-char"
                >
                  END
                </StyledETXSpan>
              </>
            ) : (
              <></>
            )}
          </StyledEditorDiv>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  } else {
    return (
      <div className={wrapperClass}>
        <div className="field">&nbsp;</div>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  }
}

const StyledEditorDiv = styled.div`
  background-color: transparent;
  padding: 4px 0 8px 0;
`;

const StyledSTXSpan = styled.span`
  margin-right: 6px;
  color: #fff;
  background-color: #000;

  &.parser-control-char {
    display: ${(props) =>
      props.displayType === "condensed" ? "inline-block" : "block"};
    max-width: ${(props) =>
      props.displayType === "condensed" ? "100%" : "52px"};
  }
`;

const StyledETXSpan = styled.span`
  margin-left: ${(props) => (props.displayType === "condensed" ? "6px" : "0")};
  color: #fff;
  background-color: #000;

  &.parser-control-char {
    display: ${(props) =>
      props.displayType === "condensed" ? "inline-block" : "block"};
    max-width: ${(props) =>
      props.displayType === "condensed" ? "100%" : "34px"};
  }
`;

export default AdvancedPacketEditor;
