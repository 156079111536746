import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { useMobile } from "../../../hooks/useMobile";

function HeaderNavLink({
  name,
  path,
  isActive,
  hasPermission,
  isInDropdown,
  disabled = false,
}) {
  const { setUserPrefs } = useUserPreferences();
  const { isTabletSize } = useMobile();

  function setMenuToggleExpanded(expanded) {
    setUserPrefs({
      type: ContextProviderActions.setMenuToggleExpanded,
      payload: expanded,
    });
  }

  if (!hasPermission) return <></>;

  function doMenuItemClick() {
    if (!disabled) {
      setMenuToggleExpanded(false);
    }
  }

  return isInDropdown && !isTabletSize ? (
    <NavDropdown.Item as={Link} to={path} onClick={doMenuItemClick}>
      {name}
    </NavDropdown.Item>
  ) : (
    <Nav.Link
      as={Link}
      to={path}
      name={name}
      onClick={doMenuItemClick}
      className={`${isActive ? "active-menu-item" : ""} ${
        disabled ? "disabled-menu-item" : ""
      }`}
    >
      {name}
    </Nav.Link>
  );
}

export default HeaderNavLink;
