import React, { useState } from "react";
import _ from "lodash";
import {
  formatDateTimeUtcZoneForDisplay,
  formatDecimal,
  getTimeframeFromMilliseconds,
  unZero,
} from "../../../services/General";
import { useNavigate } from "react-router-dom";
import StatusPill from "../../common/ui/StatusPill";
import styled from "styled-components";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";
import { useMobile } from "../../../hooks/useMobile";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import { notifySuccess } from "../../../services/NotificationService";

function ClaimList({
  claims,
  search,
  setSearch,
  onSort,
  totalRecords,
  isAdmin,
  onReverseClaim,
}) {
  const navigate = useNavigate();
  const { isMobileSize } = useMobile();
  const [showReverseClaimModal, setShowReverseClaimModal] = useState(false);
  const [reverseClaimId, setReverseClaimId] = useState(null);

  function getMessage(returnMessage, rejectionMessages) {
    let msg = returnMessage || "";

    // Remove brackets from message to make it more readable.
    if (_.startsWith(msg, "[")) {
      msg = msg.substring(1);
    }
    if (_.endsWith(msg, "]")) {
      msg = msg.substring(0, msg.length - 1);
    }

    // Replace semicolons with | to make it more readable
    msg = msg.replaceAll(";", " | ");

    return (
      <>
        {_.isEmpty(msg) ? (
          <span>**No Message Returned**</span>
        ) : (
          <span>{msg}</span>
        )}
        {rejectionMessages ? (
          <span
            style={{
              fontStyle: "italic",
              color: "var(--text-medium)",
              paddingLeft: "5px",
            }}
          >{`Rejected | ${rejectionMessages.trim()}`}</span>
        ) : (
          <></>
        )}
      </>
    );
  }

  function getActions(dataRow) {
    const id = dataRow.id;
    const claimId = dataRow.claimId;
    const friendlyStatus = dataRow.friendlyStatus;
    const prescriptionRefNumber = dataRow.prescriptionRefNumber;
    const serviceProviderId = dataRow.serviceProviderId;
    const rejectionCodes = dataRow.rejectionCodes;
    const diffStatus = dataRow.compareStatus;

    const actions = [
      {
        name: "View Details",
        onClick: () => navigate("/claim/" + id),
      },
    ];

    if (isAdmin) {
      actions.push({
        name: "Parse Packet",
        onClick: () => navigate("/packetparser/" + claimId),
      });

      actions.push({
        name: "Test Processsor",
        onClick: () => navigate("/testprocessor/" + claimId),
      });

      actions.push({
        name: "View Rule Map",
        disabled:
          friendlyStatus === "Duplicate of Paid" || diffStatus === "untested",
        onClick: () => navigate("/claimrulemap/" + claimId),
      });

      actions.push({
        name: "Match Discount",
        onClick: () => navigate("/getdiscounts/" + claimId),
      });

      actions.push({
        name: "Prior Authorization",
        disabled: friendlyStatus !== "Rejected",
        onClick: () =>
          navigate(
            "/priorauthorizationclaim/" +
              prescriptionRefNumber +
              "/" +
              serviceProviderId +
              "/" +
              rejectionCodes
          ),
      });

      actions.push({
        name: "Reverse Claim",
        disabled: friendlyStatus !== "Paid",
        onClick: () => handleReverseClaim(id),
      });
    }

    return actions;
  }

  function handleReverseClaim(claimId) {
    setReverseClaimId(claimId);
    setShowReverseClaimModal(true);
  }

  function finishReverseClaim() {
    setReverseClaimId(null);
    setShowReverseClaimModal(false);
  }

  async function reverseClaim() {
    const claimId = reverseClaimId;
    finishReverseClaim();
    const msg = await onReverseClaim(claimId);
    notifySuccess(_.isEmpty(msg) ? "Claim was reversed successfully" : msg);
  }

  function getDiffIconSpan(claim, status) {
    let className = "unknown";
    let icon = "help";

    switch (status) {
      case "pass":
        className = "good";
        icon = "done";
        break;
      case "fail":
        className = "bad";
        icon = "error";
        break;
      case "warn":
        className = "warn";
        icon = "warning";
        break;
      default:
        className = "unknown";
        icon = "help";
    }

    const tooltip = getDiffTooltip(claim);

    const span = (
      <StyledIconSpan
        className={`material-icons ${className}`}
        data-tip={tooltip}
      >
        {icon}
      </StyledIconSpan>
    );

    return span;
  }

  function getDiffStatusIcon(claim) {
    if (!claim.compareStatus || claim.compareStatus === "untested") {
      return getDiffIconSpan(claim, "unknown");
    }

    return getDiffIconSpan(claim, claim.compareStatus);
  }

  function getDiffRejections(compareValues, isOld) {
    const rejs = isOld ? compareValues?.FB?.Item1 : compareValues?.FB?.Item2;

    // Build comma-separated list of rejections codes from FB array
    let rejections = rejs || [];
    rejections = _.trim(rejections.sort((a, b) => (a > b ? 1 : -1)).join(", "));

    return rejections;
  }

  function getDiffTooltip(claim) {
    if (!claim.customDataValues) {
      return "No compare values found.";
    }

    const compareValues = claim.customDataValues.find(
      (c) => c.key === "compareValues"
    )?.value;
    if (!compareValues) return "No compare values found.";

    const oldF5 = compareValues?.F5?.Item1 ?? "0";
    const newF5 = compareValues?.F5?.Item2 ?? "0";
    const oldF6 = compareValues?.F6?.Item1 ?? "0";
    const newF6 = compareValues?.F6?.Item2 ?? "0";
    const oldF7 = compareValues?.F7?.Item1 ?? "0";
    const newF7 = compareValues?.F7?.Item2 ?? "0";
    const oldF9 = compareValues?.F9?.Item1 ?? "0";
    const newF9 = compareValues?.F9?.Item2 ?? "0";
    const oldAN = compareValues?.AN?.Item1 ?? "";
    const newAN = compareValues?.AN?.Item2 ?? "";
    // const oldMessage = compareValues?.Message?.Item1 ?? "";
    const newMessage = compareValues?.Message?.Item2 ?? "";

    const tip = `OrigProc: (F5: ${oldF5}, F6: ${oldF6}, F7: ${oldF7}, F9: ${oldF9}, AN: "${oldAN}", Rej: ${getDiffRejections(
      compareValues,
      true
    )})\nTestProc: (F5: ${newF5}, F6: ${newF6}, F7: ${newF7}, F9: ${newF9}, AN: "${newAN}", Rej: ${getDiffRejections(
      compareValues,
      false
    )})\n\n${newMessage === "" ? "" : "  Message: " + newMessage}`;
    return tip;
  }

  function getDrugOneUnit(drugUnits, quantity) {
    const qty = quantity === 0 ? 1 : quantity;
    const oneUnit = qty / drugUnits;
    const result = formatDecimal(oneUnit, 2);
    return result;
  }

  return (
    <>
      <ConfirmDialog
        title="Reverse Claim"
        question={`WARNING: Reversing a claim will cause it to become out of sync with other systems that will still see it as a Paid claim. This should only be done for testing purposes on non-live data. Are you sure you want to reverse this claim?`}
        showModal={showReverseClaimModal}
        onNo={finishReverseClaim}
        onYes={reverseClaim}
      />
      <ResponsiveGrid
        gridId="Claims"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        showNameLink={true}
        showDetailsLink={true}
        onSort={onSort}
        dataRows={claims}
        columnDefs={[
          {
            name: "prescriptionRefNumber",
            label: "Rx Number",
            className: "d-none d-md-table-cell",
            style: { width: "20%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/claim/" + row.id)}
              >
                {unZero(row.prescriptionRefNumber)}
              </button>
            ),
          },
          {
            name: "group_rx",
            label: "Rx/Group",
            className: "d-md-none",
            style: { width: "20%" },
            desktopOnly: true,
            getValue: (row) => (
              <div className="">
                <button
                  className="btn btn-link link-underline"
                  onClick={() => navigate("/claim/" + row.id)}
                >
                  {unZero(row.prescriptionRefNumber)}
                </button>
                <br />
                <span style={{ color: "var(--text-dark)", fontSize: "14px" }}>
                  {row.groupId}
                </span>
              </div>
            ),
          },
          {
            name: "friendlyStatus",
            label: "Status",
            noForceWrap: true,
            style: { width: "15%" },
            getValue: (row) => <StatusPill status={row.friendlyStatus} />,
          },
          {
            name: "groupId",
            label: "Group",
            style: { width: "15%" },
            className: "d-none d-sm-table-cell",
            hidden: search.showColumnNames,
            desktopOnly: true,
            getDataTip: (row) => row.groupName,
            getValue: (row) => row.groupId,
          },
          {
            name: "groupName",
            label: "Group",
            style: { width: "15%" },
            className: "d-none d-sm-table-cell",
            hidden: !search.showColumnNames,
            desktopOnly: true,
            getDataTip: (row) => row.groupId,
            getValue: (row) => row.groupName,
          },
          {
            name: "group",
            label: "Group",
            mobileOnly: true,
            getValue: (row) => (
              <>
                {row.groupId} ({row.groupName})
              </>
            ),
          },
          {
            name: "memberId",
            label: "Member",
            style: { width: "15%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) => row.memberId,
          },
          {
            name: "serviceProviderId",
            label: "Pharmacy NPI/NCPDP",
            style: { width: "15%" },
            className: "d-none d-xl-table-cell",
            hidden: search.showColumnNames,
            desktopOnly: true,
            getDataTip: (row) => row.serviceProviderName,
            getValue: (row) => (
              <>
                {row.serviceProviderId}/{row.pharmacyNcpdp}
              </>
            ),
          },
          {
            name: "serviceProviderName",
            label: "Pharmacy",
            style: { width: "15%" },
            className: "d-none d-xl-table-cell",
            hidden: !search.showColumnNames,
            desktopOnly: true,
            getDataTip: (row) => row.serviceProviderId,
            getValue: (row) => row.serviceProviderName,
          },
          {
            name: "serviceProviderNPI_NCPDP",
            label: "Pharmacy NPI/NCPDP",
            style: { width: "30%" },
            className: "d-xl-none",
            hidden: search.showColumnNames,
            desktopOnly: true,
            getValue: (row) => (
              <>
                <span style={{ color: "var(--text-dark)", fontSize: "14px" }}>
                  {row.serviceProviderId}/{row.pharmacyNcpdp}
                </span>
              </>
            ),
          },
          {
            name: "serviceProviderName",
            label: "Pharmacy",
            style: { width: "30%" },
            className: "d-xl-none",
            hidden: !search.showColumnNames,
            desktopOnly: true,
            getValue: (row) => (
              <>
                <span style={{ color: "var(--text-dark)", fontSize: "14px" }}>
                  {row.serviceProviderName}
                </span>
              </>
            ),
          },
          {
            name: "serviceProvider",
            label: "Pharmacy NPI/NCPDP",
            mobileOnly: true,
            getValue: (row) => (
              <>
                {row.serviceProviderId}/{row.pharmacyNcpdp} (
                {row.serviceProviderName})
              </>
            ),
          },
          {
            name: "productId",
            label: "Drug ID",
            style: { width: "15%" },
            className: "d-none d-xl-table-cell",
            hidden: search.showColumnNames,
            desktopOnly: true,
            getDataTip: (row) => row.productName,
            getValue: (row) => row.productId,
          },
          {
            name: "productName",
            label: "Drug",
            style: { width: "15%" },
            className: "d-none d-xl-table-cell",
            hidden: !search.showColumnNames,
            desktopOnly: true,
            getDataTip: (row) => row.productId,
            getValue: (row) => row.productName,
          },
          {
            name: "drug",
            label: "Drug",
            mobileOnly: true,
            getValue: (row) => (
              <>
                {row.productId} ({row.productName})
              </>
            ),
          },
          {
            name: "auth",
            label: "Auth No.",
            style: { width: "15%" },
            className: "d-none d-xxl-table-cell",
            getValue: (row) => row.authorizationNumber,
          },
          {
            name: "processedDate",
            label: "Processed",
            style: { width: "20%" },
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.processedDate),
          },
          {
            name: "runMilliseconds",
            label: "Time",
            style: { width: "10%" },
            className: "d-none d-xxl-table-cell",
            getValue: (row) =>
              getTimeframeFromMilliseconds(row.runMilliseconds),
          },
          {
            name: "dateOfService",
            label: "Fill Date",
            mobileOnly: true,
            getValue: (row) => row.dateOfService,
          },
          {
            name: "drugUnits",
            label: "Drug Units",
            mobileOnly: true,
            getValue: (row) =>
              `${formatDecimal(row.drugUnits)} (1 unit = ${getDrugOneUnit(
                row.drugUnits,
                row.quantity
              )})`,
          },
          {
            name: "quantity",
            label: "Metric Quantity",
            mobileOnly: true,
            getValue: (row) => row.quantity,
          },
          {
            name: "copay",
            label: "Copay",
            mobileOnly: true,
            getValue: (row) => formatDecimal(row.copay, 2),
          },
          {
            name: "totalPaid",
            label: "Total Price",
            mobileOnly: true,
            getValue: (row) => formatDecimal(row.totalPaid, 2),
          },
          {
            name: "diff",
            label: "Diff",
            hidden: !isAdmin,
            style: { width: "60px", maxWidth: "15%" },
            className: "d-none d-md-table-cell",
            getValue: (row) => getDiffStatusIcon(row),
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "10%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
          {
            name: "message",
            label: "",
            showInDetailRow: true,
            disableSort: true,
            className: "",
            style: {
              color: "var(--text-dark)",
            },
            getValue: (row) => (
              <>
                {!isMobileSize && (
                  <span
                    className="material-symbols-outlined"
                    style={{
                      paddingRight: "10px",
                    }}
                  >
                    comment
                  </span>
                )}
                <span>
                  {getMessage(row.returnMessage, row.rejectionMessages)}
                </span>
              </>
            ),
          },
        ]}
      />
    </>
  );
}

ClaimList.defaultProps = {
  claims: [],
};

const StyledIconSpan = styled.span`
  cursor: pointer;

  &.unknown {
    color: var(--notify-neutral);
  }

  &.bad {
    color: var(--notify-danger);
  }

  &.good {
    color: var(--notify-success);
  }

  &.warn {
    color: var(--notify-warning);
  }
`;

export default ClaimList;
