import { useState, useLayoutEffect } from "react";
import {
  TABLET_WINDOW_SIZE,
  MOBILE_WINDOW_SIZE,
  SMALL_MOBILE_WINDOW_SIZE,
} from "../constants/Environments";

export function useMobile() {
  const [isTabletSize, setIsTabletSize] = useState(false);
  const [isMobileSize, setIsMobileSize] = useState(false);
  const [isSmallMobileSize, setIsSmallMobileSize] = useState(false);
  useLayoutEffect(() => {
    function updateSize() {
      setIsTabletSize(
        global.TESTMODE === true && global.DEVICE_TYPE === "Tablet"
          ? true
          : window.innerWidth < TABLET_WINDOW_SIZE
      );
      setIsMobileSize(
        global.TESTMODE === true && global.DEVICE_TYPE === "Mobile"
          ? true
          : window.innerWidth < MOBILE_WINDOW_SIZE
      );
      setIsSmallMobileSize(
        global.TESTMODE === true && global.DEVICE_TYPE === "SmallMobile"
          ? true
          : window.innerWidth < SMALL_MOBILE_WINDOW_SIZE
      );
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return { isTabletSize, isMobileSize, isSmallMobileSize };
}
