import React from "react";

function PrivacyPolicyPage() {
  return (
    <>
      <h1>Privacy Policy</h1>
      <p>Placeholder page.</p>
    </>
  );
}

export default PrivacyPolicyPage;
