/* eslint-disable no-undef */
import {
  handleResponse,
  getCommonHeaders,
  getQueryString,
  getTestProcEndpoint,
} from "./apiUtils";

export function apiRunTestProcessor(params, token, signal) {
  const claimId = params.claimId;
  const originalResponse = params.originalResponse;
  const requestContents = params.requestContents;
  const processingDate = params.processingDate;
  const overrideProcessedDate = params.overrideProcessedDate;
  const saveHistory = params.saveHistory;
  const clearRuleCache = params.clearRuleCache;
  const serializeLogItems = params.serializeLogItems;

  let uri =
    PROCESSOR_URL + "/" + getTestProcEndpoint(saveHistory) + getQueryString();

  if (clearRuleCache === true) {
    uri += "&clearRuleCache=1";
  }
  if (serializeLogItems === true) {
    uri += "&serializeLogItems=1";
  }

  const headersToAdd = getCommonHeaders(token);
  headersToAdd["x-processing-date"] = processingDate;
  if (overrideProcessedDate) {
    headersToAdd["x-override-processing-date"] = processingDate;
  }
  headersToAdd["x-claimid"] = claimId;

  if (originalResponse && originalResponse !== "") {
    headersToAdd["x-compare-packet"] = encodeURIComponent(originalResponse);
  }

  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: requestContents,
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiRunTestProcessor.funcName = "apiRunTestProcessor";
