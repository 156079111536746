import React, { Fragment } from "react";
import _ from "lodash";
import StatusPill from "../../common/ui/StatusPill";
import {
  convertUTCDateToLocal,
  formatDateTimeUtcZoneForDisplay,
  getHighlightedText,
  getTimeAgoFromIntervalSeconds,
  getTimeframeFromMilliseconds,
  unZero,
} from "../../../services/General";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { StyledBlockLetter } from "../../common/layout/CommonStyledControls";
import { PACKET_TRAFFIC_DONE_STATUSES } from "./HomePacketsTraffic";

export const PACKET_CONSUMER_ERROR_STATUSES = ["Error", "Timeout"];

function HomePacketsTrafficBlock({
  listName,
  packet,
  isPacketInFilter,
  searchTerm,
  showDetails,
  blockTimeout,
  blockClassNames,
  blockOriginPosition,
  myPosition,
  isHighlighted,
  onBlockRemove,
  onBlockExit,
  showGroupName,
  groupPacket,
  ...cssOtherProps
}) {
  const nodeRef = React.useRef(null);
  const QUEUE_DELAY_WARNING_SECONDS = 30;

  function getTimeAgoInterval(timeAgo, timeUnit) {
    const m = `${timeAgo} ${timeAgo === 1 ? timeUnit : timeUnit + "s"} ago`;
    return m;
  }

  function getExtraStatusText(p) {
    if (
      PACKET_TRAFFIC_DONE_STATUSES.indexOf(p.status) >= 0 &&
      p.finishing === true
    ) {
      return "...";
    }

    if (p.status === "ReadyToRespond" && p.finishing !== true) {
      // If status is completed add the number of consumers completed vs. total to the pill
      const totalConsumers = p.consumers.length;
      const doneConsumers =
        (p.consumers || []).filter(
          (c) =>
            c.dateCompleted &&
            PACKET_CONSUMER_ERROR_STATUSES.indexOf(c.stage) < 0
        )?.length || 0;

      return `${doneConsumers}/${totalConsumers}`;
    }

    return "";
  }

  function getPacketStatus(t) {
    if (t.status === "Error") return "PacketError";
    return t.status;
  }

  const queuePos = blockOriginPosition;
  const myPos = myPosition;

  const blockHeight = showDetails ? 246 : 68;
  let vOffset = Math.abs(queuePos - myPos);

  // If this block is lower on screen than the queue block we are moving from, voffset will be negative. Otherwise, positive.
  if (myPos >= queuePos) {
    vOffset = -vOffset;
  }

  // if (myPos === 0) {
  //   console.log(
  //     `My pos: ${myPos}, Queue pos: ${queuePos}, offset: ${vOffset}, blockHeight: ${blockHeight}, final offset: ${
  //       vOffset * blockHeight
  //     }`
  //   );
  // }
  const blockTopOffset = vOffset * blockHeight;

  const t = packet;
  const hl = searchTerm;
  let isHidden = false;

  let startDate = new Date();
  let endDate = new Date(
    convertUTCDateToLocal(
      PACKET_TRAFFIC_DONE_STATUSES.indexOf(t.status) >= 0
        ? t.respondedDate
        : t.startedDate
    )
  );
  let interval = Math.abs(endDate - startDate) / 1000;
  let timeAgoInfo = getTimeAgoFromIntervalSeconds(interval, 1);

  let packetId = unZero(
    (t.friendlyId || "").substring((t.friendlyId || "").indexOf("/") + 1)
  );
  let idHlResult = getHighlightedText(packetId, hl);
  let statusHlResult = getHighlightedText(t.status, hl);
  let providerHlResult = getHighlightedText(t.serviceProvider, hl);

  let consumerTimeAgo;

  const isQueueDelayWarning =
    interval > QUEUE_DELAY_WARNING_SECONDS && listName === "Queued";

  isHidden =
    _.trim(hl).length > 0 &&
    idHlResult.count === 0 &&
    providerHlResult.count === 0 &&
    statusHlResult.count === 0;

  return isHidden || !isPacketInFilter ? (
    <Fragment key={`trafficblock-${t.id}`}></Fragment>
  ) : (
    <CSSTransition
      {...cssOtherProps}
      key={`traffic-${t.id}`}
      nodeRef={nodeRef}
      timeout={blockTimeout}
      classNames={blockClassNames}
      onEntered={(node) => onBlockRemove(packet.id, listName)}
      onExit={() => {
        onBlockExit(packet.id, listName, blockOriginPosition);
      }}
    >
      <StyledTrafficBlock
        ref={nodeRef}
        className={isHighlighted ? "" : "de-highlight"}
        style={{
          borderColor: t.isTestPacket
            ? "var(--notify-warning)"
            : t.isHeartbeatPacket
            ? "var(--notify-info)"
            : isQueueDelayWarning
            ? "var(--notify-danger)"
            : "var(--elevated-border)",
        }}
        leftOffset={listName === "Processed" ? `-100%` : `0`}
        topOffset={listName === "Processed" ? `${blockTopOffset}px` : `0`}
        groupPacket={groupPacket || false}
      >
        <div className="full-width">
          {t.isTestPacket && (
            <div className="test-packet">
              &ndash;&nbsp;&nbsp;TEST&nbsp;&nbsp;&ndash;
            </div>
          )}
          {t.isHeartbeatPacket && (
            <div className="heartbeat-packet">
              <i className="material-icons">monitor_heart</i>
            </div>
          )}
          {showGroupName && (
            <div className="flex-row-with-wrap-and-justify full-width">
              <h4>Routing Key</h4>
              <h4 className="force-wrap">{packet.groupingKey}</h4>
            </div>
          )}
          <div className="flex-row-with-wrap-and-justify full-width">
            <p>{t.isHeartbeatPacket ? "" : idHlResult.html}</p>
            <p
              style={{
                fontStyle: "italic",
                display: "flex",
                columnGap: "4px",
                alignItems: "center",
                color: isQueueDelayWarning
                  ? "var(--notify-danger)"
                  : "var(--text-medium)",
              }}
            >
              {isQueueDelayWarning && (
                <i
                  className="material-symbols-outlined"
                  style={{
                    color: "var(--notify-danger)",
                  }}
                >
                  warning
                </i>
              )}
              {getTimeAgoInterval(timeAgoInfo.ago, timeAgoInfo.unit)}
            </p>
          </div>
          <div className="flex-row-with-wrap-and-justify full-width">
            <p>{t.isHeartbeatPacket ? "" : providerHlResult.html}</p>
            <p>
              {statusHlResult.count > 0 ? (
                statusHlResult.html
              ) : t.isHeartbeatPacket ? (
                <span
                  className="heartbeat"
                  style={{ color: "var(--notify-info", fontSize: "14px" }}
                >
                  <i className="material-icons">favorite</i>
                </span>
              ) : (
                <StatusPill
                  status={getPacketStatus(t)}
                  extraText={getExtraStatusText(t)}
                  className={
                    PACKET_TRAFFIC_DONE_STATUSES.indexOf(t.status) >= 0 &&
                    t.finishing === true
                      ? "pulse-fade-stay-visible"
                      : ""
                  }
                />
              )}
            </p>
          </div>
          {showDetails && (
            <>
              <div
                className="row"
                style={{ fontSize: "13px", marginTop: "10px" }}
              >
                <div className="col-12 col-lg-6">
                  <p>Routing Key: {t.friendlyId}</p>
                </div>
                <div className="col-12 col-lg-6">
                  <p>
                    Last Change: {formatDateTimeUtcZoneForDisplay(t.sortDate)}
                  </p>
                </div>
                <div className="col-12 col-lg-6">
                  <p>Connection: {t.connectionName}</p>
                </div>
                <div className="col-12 col-lg-6">
                  <p>
                    Type/Recognizer: {t.dataType}/{t.recognizerName}
                  </p>
                </div>
                {!_.isEmpty(t.originalResponse) && (
                  <div className="col-12">
                    <p className="force-wrap">
                      Response:
                      <br />
                      {t.originalResponse}
                    </p>
                  </div>
                )}
              </div>
              <div
                className="flex-row-with-wrap-and-justify full-width"
                style={{ marginTop: "10px" }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  Consumers:
                </p>
                {t.consumers.map((consumer) => {
                  consumerTimeAgo = getTimeframeFromMilliseconds(
                    consumer.consumeMilliseconds
                  );
                  return (
                    <div
                      key={consumer.name}
                      className="flex-row-with-wrap-and-justify full-width"
                      style={{ fontSize: "12px" }}
                    >
                      <div className="flex-row-with-wrap-and-justify">
                        {consumer.dateCompleted &&
                        PACKET_CONSUMER_ERROR_STATUSES.indexOf(consumer.stage) <
                          0 ? (
                          <i
                            className="material-symbols-outlined"
                            style={{
                              color: "var(--notify-success)",
                              fontSize: "12px",
                            }}
                          >
                            done
                          </i>
                        ) : PACKET_CONSUMER_ERROR_STATUSES.indexOf(
                            consumer.stage
                          ) >= 0 ? (
                          <i
                            className="material-symbols-outlined"
                            style={{
                              color: "var(--notify-danger)",
                              fontSize: "12px",
                            }}
                          >
                            close
                          </i>
                        ) : (
                          <i
                            className="material-symbols-outlined rotating"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            rotate_right
                          </i>
                        )}
                        &nbsp;&nbsp;
                        <span
                          style={{
                            fontWeight: consumer.isPrimary ? "bold" : "normal",
                          }}
                        >
                          {consumer.name}
                          {consumer.isPrimary && (
                            <StyledBlockLetter
                              style={{ marginLeft: "5px", fontSize: "11px" }}
                            >
                              P
                            </StyledBlockLetter>
                          )}
                        </span>
                      </div>
                      <div
                        style={{
                          fontStyle: "italic",
                          color:
                            PACKET_CONSUMER_ERROR_STATUSES.indexOf(
                              consumer.stage
                            ) >= 0
                              ? "var(--notify-danger)"
                              : "var(--text-medium)",
                        }}
                      >
                        {consumer.dateCompleted
                          ? consumerTimeAgo
                          : consumer.stage}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </StyledTrafficBlock>
    </CSSTransition>
  );
}

const StyledTrafficBlock = styled.div`
  position: relative;
  margin-top: ${(props) => (props.groupPacket === true ? "-2px" : "7px")};
  padding: 5px 11px 9px 11px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-top: ${(props) =>
    props.groupPacket === true ? "none" : "1px solid var(--elevated-border)"};
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  color: var(--text-medium);

  &.de-highlight {
    opacity: 0.3;
  }

  &.animatequeueitem-appear {
    opacity: 0;
  }

  &.animatequeueitem-appear-active {
    opacity: 1;
    transition: all 500ms ease-in;
    box-shadow: 4px -4px 10px var(--shadow-1);
  }

  &.animatequeueitem-enter {
    opacity: 0;
    transform: translateY(400%);
  }

  &.animatequeueitem-enter-active {
    opacity: 1;
    z-index: 99;
    transform: translateX(0);
    transition: all 500ms ease-in-out;
    box-shadow: 4px -4px 10px var(--shadow-1);
  }

  &.animatecompleteitem-appear {
    opacity: 0;
  }

  &.animatecompleteitem-appear-active {
    opacity: 1;
    z-index: 99;
    transition: all 500ms ease-in;
    box-shadow: 4px -4px 10px var(--shadow-1);
  }

  &.animatecompleteitem-enter {
    background-color: var(--selected-bg);
    border-color: var(--selected-border);
    transform: translate(
      ${(props) => props.leftOffset},
      ${(props) => props.topOffset}
    );
  }

  &.animatecompleteitem-enter-active {
    z-index: 99;
    background-color: var(--elevated-bg);
    border-color: var(--elevated-border);
    transform: translate(0, 0);
    transition: all 500ms ease-in-out;
    box-shadow: 4px -4px 10px var(--shadow-1);
  }

  &.animatecompleteitem-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.animatecompleteitem-exit-active {
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: 0;
    transform: translateY(400%);
    transition: all 500ms ease-in-out;
    box-shadow: 4px -4px 10px var(--shadow-1);
  }

  h4 {
    color: var(--text-dark);
    font-size: 16px;
  }

  div.test-packet {
    position: absolute;
    width: auto;
    top: 1px;
    left: calc(50% - 21px);
    font-weight: bold;
    font-size: 11px;
    line-height: 11px;
    font-style: italic;
    color: var(--notify-warning);
    opacity: 0.85;
    z-index: 1;
  }

  div.heartbeat-packet {
    position: absolute;
    width: auto;
    top: 1px;
    left: 10px;
    font-weight: bold;
    line-height: 18px;
    color: var(--notify-info);
    opacity: 0.85;
    z-index: 1;
    margin-top: 14px;

    i {
      font-size: 36px;
    }
  }

  div.full-width {
    width: 100%;

    h3 {
      margin: 0;
      padding: 4px 0;
    }

    p {
      color: var(--text-medium);
      margin: 0;
      padding: 4px 0;
    }
  }
`;

export default HomePacketsTrafficBlock;
