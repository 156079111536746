/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadAuditRecords(search, token, signal) {
  const uri = API_URL + "/audit" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "id", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "documentId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "userName",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "entityType",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.id && search.id.length > 0)
    where.push({ name: "id", operator: "=", values: [search.id] });
  if (search && search.userName && search.userName.length > 0)
    where.push({ name: "userName", operator: "=", values: [search.userName] });
  if (search && search.documentId && search.documentId.length > 0)
    where.push({
      name: "documentId",
      operator: "=",
      values: [search.documentId],
    });

  if (search && search.entityType && search.entityType.length > 0)
    where.push({
      name: "entityType",
      operator: "=",
      values: [search.entityType],
    });
  if (search && search.entityKey && search.entityKey.length > 0)
    where.push({
      name: "entityKey",
      operator: "=",
      values: [search.entityKey],
    });

  if (search && search.bulkChangeOnly)
    where.push({
      name: "bulkChange",
      operator: "true",
    });

  if (search && search.startDate && _.isDate(search.startDate))
    where.push({
      name: "transactionDate",
      operator: ">=",
      values: [search.startDate],
    });
  if (search && search.endDate && _.isDate(search.endDate))
    where.push({
      name: "transactionDate",
      operator: "<",
      values: [search.endDate],
    });

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadBulkChangeAuditRecords(id, token, signal) {
  const uri = API_URL + "/auditbulk/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadAuditDocument(params, token, signal) {
  const entityType = params.entityType;
  const id = params.id;
  const transactionId = params.transactionId;

  let uri =
    API_URL +
    "/documentstate/" +
    encodeURIComponent(entityType) +
    "/" +
    id +
    getQueryString();

  if (transactionId && transactionId !== "") {
    uri += "&transactionId=" + transactionId;
  }

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRollbackSingleChange(params, token, signal) {
  const entityType = params.entityType;
  const id = params.id;
  const transactionId = params.transactionId;

  let uri =
    API_URL +
    "/documentstate/" +
    encodeURIComponent(entityType) +
    "/" +
    id +
    "/" +
    transactionId +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRollbackBulkChange(id, token, signal) {
  let uri = API_URL + "/bulkimport/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadAuditRecords.funcName = "apiLoadAuditRecords";
apiLoadBulkChangeAuditRecords.funcName = "apiLoadBulkChangeAuditRecords";
apiLoadAuditDocument.funcName = "apiLoadAuditDocument";
apiRollbackSingleChange.funcName = "apiRollbackSingleChange";
apiRollbackBulkChange.funcName = "apiRollbackBulkChange";
