import React, { Fragment, useRef, useState } from "react";
import _ from "lodash";

import {
  StyledHeaderRowDiv,
  StyledNoResultsDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import {
  convertUTCDateToLocal,
  getHighlightedText,
  getTimeAgoFromIntervalSeconds,
  unZero,
} from "../../../services/General";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import SliderInput from "../../common/input/SliderInput";
import Badge from "../../common/ui/Badge";
import HomeClaimsTrafficBlock from "./HomeClaimsTrafficBlock";
import HomeClaimsTrafficBreakdown from "./HomeClaimsTrafficBreakdown";

function HomeClaimsTraffic({ traffic }) {
  const [search, setSearch] = useState({ highlightText: "" });
  const [numClaimsToShow, setNumClaimsToShow] = useState(10);
  const [numBadPackets] = useState(0);
  const parentRef = useRef(null);

  function handleInstantSearchChange(value) {
    setSearch({ ...search, highlightText: value });
  }

  let startDate;
  let endDate;
  let interval;
  let timeAgoInfo;

  const hl = search.highlightText;
  let rxHlResult = {};
  let authHlResult = {};
  let statusHlResult = {};
  let groupHlResult = {};
  let providerHlResult = {};

  let isHidden = false;
  let i = 0;
  let lastGroupingKey = "";

  return (
    <div data-testid="home-claims-traffic-section">
      <StyledHeaderRowDiv style={{ marginTop: "35px", marginBottom: "-4px" }}>
        <h2
          style={{
            fontSize: "18px",
            padding: "0",
            margin: "0",
            display: "flex",
            alignItems: "center",
            columnGap: "5px",
          }}
        >
          <span>
            Real-time Claims Traffic{" "}
            <Badge>{(traffic.claims || []).length}</Badge>{" "}
          </span>
          <HelpLink path="/Home#real-time-claims-traffic-section" />
        </h2>
        {numBadPackets > 0 && (
          <div
            className="flex-row-with-wrap"
            style={{ color: "var(--notify-danger)", fontSize: "18px" }}
          >
            <i
              className="material-symbols-outlined"
              style={{ fontSize: "28px" }}
            >
              warning
            </i>
            <span>
              &nbsp;&nbsp;
              {`${numBadPackets} bad ${
                numBadPackets === 1 ? "packet" : "packets"
              } received!`}
            </span>
          </div>
        )}
      </StyledHeaderRowDiv>
      {(traffic.claims || []).length === 0 ? (
        <>
          <StyledNoResultsDiv
            style={{ marginTop: "10px", height: "60px" }}
            data-testid="home-claims-traffic-no-results"
          >
            <div className="flex-row-with-wrap" style={{ columnGap: "5px" }}>
              <i className="material-symbols-outlined rotating">rotate_right</i>
              <span>Waiting for claims traffic...</span>
            </div>
          </StyledNoResultsDiv>
        </>
      ) : (
        <div className="row" ref={parentRef} style={{ marginTop: "20px" }}>
          <>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-6">
                  <InstantSearchInput
                    id="screenSearchInput"
                    onChange={handleInstantSearchChange}
                    value={search.highlightText}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SliderInput
                    id="numClaims"
                    name="numClaims"
                    label="Show"
                    minValue={10}
                    maxValue={100}
                    isColumnSlider={false}
                    value={numClaimsToShow}
                    onChange={(e) =>
                      setNumClaimsToShow(parseInt(e.target.value, 10))
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <HomeClaimsTrafficBreakdown traffic={traffic} />
            </div>
            <div className="col-12 col-md-6">
              {traffic.claims.map((t) => {
                startDate = new Date();
                endDate = new Date(convertUTCDateToLocal(t.processedDate));
                interval = Math.abs(endDate - startDate) / 1000;
                timeAgoInfo = getTimeAgoFromIntervalSeconds(interval, 1);

                rxHlResult = getHighlightedText(
                  unZero(t.prescriptionRefNumber),
                  hl
                );
                authHlResult = getHighlightedText(t.authorizationNumber, hl);
                statusHlResult = getHighlightedText(t.friendlyStatus, hl);
                groupHlResult = getHighlightedText(t.groupId, hl);
                providerHlResult = getHighlightedText(
                  t.serviceProviderName,
                  hl
                );

                isHidden =
                  i >= numClaimsToShow ||
                  (_.trim(hl).length > 0 &&
                    rxHlResult.count === 0 &&
                    authHlResult.count === 0 &&
                    groupHlResult.count === 0 &&
                    providerHlResult.count === 0 &&
                    statusHlResult.count === 0);

                if (isHidden) {
                  return <Fragment key={`traffic-${t.id}`}></Fragment>;
                }
                i++;

                const claimBlock = (
                  <HomeClaimsTrafficBlock
                    key={`traffic-${t.id}`}
                    claim={t}
                    rxHlResult={rxHlResult}
                    timeAgoInfo={timeAgoInfo}
                    authHlResult={authHlResult}
                    statusHlResult={statusHlResult}
                    groupHlResult={groupHlResult}
                    providerHlResult={providerHlResult}
                    showGroupName={
                      t.groupingKey !== "" && t.groupingKey !== lastGroupingKey
                    }
                    groupPacket={
                      t.groupingKey !== "" && t.groupingKey === lastGroupingKey
                    }
                  />
                );

                lastGroupingKey = t.groupingKey || t.friendlyId;
                return claimBlock;
              })}
            </div>
          </>
        </div>
      )}
    </div>
  );
}

export default HomeClaimsTraffic;
