import React from "react";
import _ from "lodash";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import { useMobile } from "../../../hooks/useMobile";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function DiscountRedemptionsList({
  search,
  setSearch,
  discountRedemptions,
  totalRecords,
  fromDiscountsPage,
  onSort,
}) {
  const { isMobileSize } = useMobile();
  const rxNum = (discountRedemptions[0] || {}).prescriptionNumber || "";

  function getRedemptionRowStyle(isReversal) {
    if (isReversal) {
      return { color: "var(--pill-red)", borderLeftColor: "var(--pill-red)" };
    } else {
      return { color: "var(--text-normal)", borderLeftColor: "transparent" };
    }
  }

  function getMessage(returnMessage) {
    let msg = returnMessage || "";

    // Remove brackets from message to make it more readable.
    if (_.startsWith(msg, "[")) {
      msg = msg.substring(1);
    }
    if (_.endsWith(msg, "]")) {
      msg = msg.substring(0, msg.length - 1);
    }

    // Replace semicolons with | to make it more readable
    msg = msg.replaceAll(";", " | ");

    return (
      <>
        {_.isEmpty(msg) ? <div>**No Message Returned**</div> : <div>{msg}</div>}
      </>
    );
  }

  function getDiscountUnits(row) {
    const discType = row.discountType;
    const maxQty = row.maximumQuantity || 0;
    const drugUnitsApplied = row.drugUnitsApplied || 0;
    const drugUnitQuantity = row.drugUnitQuantity || 0;

    if ((discType === "perunit" || maxQty > 0) && drugUnitsApplied > 0) {
      return (
        drugUnitsApplied.toFixed(2) +
        " (1 unit = " +
        drugUnitQuantity.toFixed(2) +
        ")"
      );
    }

    return <>&ndash;</>;
  }

  return (
    <>
      <h4 style={{ marginTop: "20px", fontSize: "16px" }}>
        Redemptions
        {fromDiscountsPage && rxNum ? <>{" - Rx: " + rxNum}</> : <></>}
      </h4>
      <ResponsiveGrid
        gridId="DiscountRedemptions"
        totalRecords={totalRecords}
        search={search}
        searchOrderByPropName="drOrderBy"
        setSearch={setSearch}
        alwaysShowDetailsInMobileView={true}
        onSort={onSort}
        dataRows={discountRedemptions}
        getRowStyle={(row) => getRedemptionRowStyle(row.reversed || false)}
        columnDefs={[
          {
            name: "redemptionDate",
            label: "Redemption Date",
            style: { paddingLeft: "8px", width: "25%" },
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.redemptionDate),
          },
          {
            name: "discountPlan",
            label: "Discount Plan (Mode)",
            disableSort: true,
            hidden: !fromDiscountsPage,
            style: { width: "40%" },
            getValue: (row) =>
              !row.discountPlan
                ? "Rejection " + row.rejectionCode
                : row.discountPlan + " (" + row.discountMode + ")",
          },
          {
            name: "discountAmount",
            label: "Amount (Type)",
            disableSort: true,
            hidden: !fromDiscountsPage,
            style: { width: "25%" },
            getValue: (row) =>
              !row.discountPlan
                ? ""
                : (row.discountAmount || 0).toFixed(2) +
                  " (" +
                  row.discountType +
                  ")",
          },
          {
            name: "quantity",
            label: "Met. Qty.",
            disableSort: true,
            style: { width: "25%" },
            getValue: (row) => (row.quantity || 0).toFixed(2),
          },
          {
            name: "discountUnits",
            label: "Units",
            disableSort: true,
            style: { width: "25%" },
            getValue: (row) => getDiscountUnits(row),
          },
          {
            name: "groupId",
            label: "Group",
            disableSort: true,
            hidden: !fromDiscountsPage,
            style: { width: "10%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) => row.groupId,
          },
          {
            name: "memberid",
            label: "Member",
            disableSort: true,
            hidden: !fromDiscountsPage,
            style: { width: "20%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) => row.memberid || "(None)",
          },
          {
            name: "prescriptionNumber",
            label: "Prescription #",
            disableSort: true,
            hidden: fromDiscountsPage,
            style: { width: "20%" },
            getValue: (row) => row.prescriptionNumber,
          },
          {
            name: "authorizationNumber",
            label: "Authorization #",
            disableSort: true,
            hidden: fromDiscountsPage,
            style: { width: "20%" },
            getValue: (row) => row.authorizationNumber || "(Not recorded)",
          },
          {
            name: "ndc",
            label: "Drug",
            disableSort: true,
            style: { width: "20%" },
            getValue: (row) => row.ndc || "(None)",
          },
          {
            name: "remainingUses",
            label: "Rem. Count",
            disableSort: true,
            style: { width: "10%", textAlign: "right" },
            className: "d-none d-xl-table-cell align-right",
            getValue: (row) =>
              row.remainingUses ? (
                (row.remainingUses || 0).toFixed(0)
              ) : (
                <>&ndash;</>
              ),
          },
          {
            name: "remainingBenefit",
            label: "Rem. Benefit",
            disableSort: true,
            style: { width: "10%", textAlign: "right" },
            className: "d-none d-xl-table-cell align-right",
            getValue: (row) =>
              row.remainingBenefit ? (
                (row.remainingBenefit || 0).toFixed(2)
              ) : (
                <>&ndash;</>
              ),
          },
          {
            name: "remainingQty",
            label: "Rem. Qty",
            disableSort: true,
            style: { width: "10%", textAlign: "right" },
            className: "d-none d-xl-table-cell align-right",
            getValue: (row) =>
              row.remainingQty ? (
                (row.remainingQty || 0).toFixed(3)
              ) : (
                <>&ndash;</>
              ),
          },
          {
            name: "feesFromProcessor",
            label: "Fees",
            disableSort: true,
            style: { width: "15%", textAlign: "right" },
            className: "d-none d-xl-table-cell align-right",
            getValue: (row) => (row.feesFromProcessor || 0).toFixed(2),
          },
          {
            name: "thirdPartyPayAmount",
            label: "Paid",
            disableSort: true,
            style: { width: "15%", textAlign: "right" },
            className: "align-right",
            getValue: (row) => (
              <span
                style={{
                  color: row.reversed
                    ? "var(--pill-red)"
                    : "var(--pill-normal)",
                }}
              >
                {(row.thirdPartyPayAmount || 0).toFixed(2)}
              </span>
            ),
          },
          {
            name: "patientPayAmount",
            label: "Copay",
            disableSort: true,
            style: { width: "15%", textAlign: "right" },
            className: "align-right",
            getValue: (row) => (
              <span
                style={{
                  color: row.reversed
                    ? "var(--pill-red)"
                    : "var(--pill-normal)",
                }}
              >
                {(row.patientPayAmount || 0).toFixed(2)}
              </span>
            ),
          },
          {
            name: "message",
            label: "",
            showInDetailRow: true,
            disableSort: true,
            style: {
              color: isMobileSize ? "var(--text-normal)" : "var(--text-medium)",
            },
            getValue: (row) => (
              <>
                {!isMobileSize && (
                  <span
                    className="material-symbols-outlined"
                    style={{
                      paddingRight: "10px",
                      color: row.reversed
                        ? "var(--pill-red)"
                        : "var(--pill-normal)",
                    }}
                  >
                    comment
                  </span>
                )}
                <span
                  style={{
                    color: row.reversed
                      ? "var(--pill-red)"
                      : "var(--pill-normal)",
                  }}
                >
                  {getMessage(row.message)}
                </span>
              </>
            ),
          },
        ]}
      />
    </>
  );
}

export default DiscountRedemptionsList;
