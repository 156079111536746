import React from "react";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import DatePickerInput from "../../common/input/DatePickerInput";

function RuleAreaEffectiveRange({
  ruleChanges,
  handleEffectiveDateChange,
  handleTerminationDateChange,
  errors,
}) {
  return (
    <>
      {" "}
      <StyledRowDiv className="row">
        <div className="col-6 col-md-4 col-xl-3">
          <DatePickerInput
            id="effectiveDate"
            name="effectiveDate"
            label="Effective Date"
            value={ruleChanges.effectiveDate}
            placeholder="Effective Date"
            onChange={handleEffectiveDateChange}
            error={errors.effectiveDate}
            showTimeInput={false}
          />
        </div>
        <div className="col-6 col-md-4 col-xl-3">
          <DatePickerInput
            id="terminationDate"
            label="Termination Date"
            name="terminationDate"
            value={ruleChanges.terminationDate}
            placeholder="Termination Date"
            onChange={handleTerminationDateChange}
            error={errors.terminationDate}
            showTimeInput={false}
          />
        </div>
      </StyledRowDiv>
    </>
  );
}

export default RuleAreaEffectiveRange;
