import React from "react";
import {
  StyledStepHeaderDiv,
  StyledWizardListItem,
} from "../../../common/layout/CommonStyledWizardComponents";
import SelectInput from "../../../common/input/SingleSelect";

function PanelGetDropdown({
  stepNumber,
  currentStep,
  name,
  title,
  value,
  options,
  onChange,
  errors,
  showPanel = true,
}) {
  if (!showPanel || currentStep < stepNumber) {
    return <></>;
  }

  return (
    <StyledWizardListItem step={stepNumber}>
      <StyledStepHeaderDiv>{title}</StyledStepHeaderDiv>

      <div className="row">
        <div className="col-12 col-md-6">
          <SelectInput
            id={name}
            name={name}
            label=""
            labelStyle={{ display: "none" }}
            options={options}
            value={value}
            onChange={onChange}
            placeholder=""
            autoFocus={currentStep === stepNumber}
            error={errors[name]}
          />
        </div>
      </div>
    </StyledWizardListItem>
  );
}

export default PanelGetDropdown;
