import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useAuth } from "../../../contexts/AuthContext";
import styled from "styled-components";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import { useClaims } from "../../../contexts/ClaimsContext";
import { useNavigate } from "react-router-dom";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import HomeReports from "./HomeReports";
import { notifySuccess } from "../../../services/NotificationService";
import {
  apiGetClaimsTraffic,
  apiLoadDashboardData,
  apiLoadDashboardReports,
  apiSaveDashboardPanelProperties,
} from "../../../api/HomeApi";
import HomeDashboardPanels from "./HomeDashboardPanels";
import HomeSidebar from "./HomeSidebar";
import { useMobile } from "../../../hooks/useMobile";
import Spinner from "../../common/ui/Spinner";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import {
  StyledHeaderRowButtonDiv,
  StyledNoResultsDiv,
} from "../../common/layout/CommonStyledControls";
import HomeSourceBuildContent from "./HomeSourceBuildContent";
import HomeDiagnostics from "./HomeDiagnostics";
import { apiLoadDiagnostics } from "../../../api/DiagnosticApi";
import { createViewModel } from "../../../viewmodels/diagnosticsVm";
import { useDiagnostics } from "../../../contexts/DiagnosticsContext";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";
import HomeClaimsTraffic from "./HomeClaimsTraffic";
import HomePacketsTraffic from "./HomePacketsTraffic";
import HomeWebSocketHubConnections from "./HomeWebSocketHubConnections";

function HomePage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { loading, api: apiLoadData } = useApi(apiLoadDashboardData);
  const { api: apiLoadDataSilent } = useApi(apiLoadDashboardData);
  const { loading: loadingReports, api: apiLoadReports } = useApi(
    apiLoadDashboardReports
  );
  const { loading: savingPanelProps, api: apiSavePanelProps } = useApi(
    apiSaveDashboardPanelProperties
  );
  const { loading: loadingDiagnosticsData, api: apiLoadDiagnosticsData } =
    useApi(apiLoadDiagnostics);
  const { loading: loadingClaimsTraffic, api: apiLoadClaimsTraffic } =
    useApi(apiGetClaimsTraffic);
  const { claimsData, setClaimsData } = useClaims();
  const { userPrefs, setUserPrefs } = useUserPreferences();
  const { isMobileSize, isTabletSize } = useMobile();
  const { diagnosticData, setDiagnosticData } = useDiagnostics();
  const [panelData, setPanelData] = useState(null);
  const [lastPanelValues, setLastPanelValues] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [panelEditMode, setPanelEditMode] = useState(false);
  const [editPanels, setEditPanels] = useState([]);
  const [packetBlockPositions, setPacketBlockPositions] = useState([]);

  const timerRef = useRef(null);

  const isSmallScreen = isMobileSize || isTabletSize;
  const filteredPanels = ((panelData || {}).panelStates || []).filter(
    (panel) => panelData?.dashboardHiddenReportIds.indexOf(panel.reportId) < 0
  );

  // The API call to load data is actually a side effect in most cases since a dispatch to setReportsData must usually
  //  happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //  useEffect ensures that happens first.
  useEffect(() => {
    // jon, 3/27/23: If this is a packet parser user, redirect to the Packet Parser page since this is their home page.
    if (auth.isParserUser) {
      navigate("/packetparser");
      return;
    }

    if (auth.authenticated && !panelEditMode) {
      loadDashboardAndReports();
    }
  }, [auth.authenticated]);

  // The state timer is used to refresh the dashboard data. It gets set after the first call to loadNewDashboardData
  //   completes. This useEffect also clears the timer if needed on unload.
  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        window.clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  async function loadDashboardAndReports() {
    // Load home screen initial data
    apiLoadData.call(true, (result) => {
      setPanelData(result);
      saveLastPanelValues(result.panelStates || []);

      // Immediately refresh the panels behind the scenes since we did not do it on the first load for speed
      // Don't do this in test mode. The timer messes up the tests because it keeps changing the UI when the
      //   test does not expect it.
      if (global.TESTMODE !== true) {
        loadNewDashboardData();
      }
    });

    apiLoadClaimsTraffic.call(null, (result) => {
      setClaimsData({
        type: ContextProviderActions.loadClaimsTraffic,
        payload: result,
      });
    });

    apiLoadReports.call(null, (result) => {
      setReportsData(result.resources);
    });

    loadDiagnostics();
  }

  async function loadNewDashboardData() {
    // This loads only dashboard data and is called on a set interval.
    apiLoadDataSilent.call(false, (result) => {
      updatePanelsWithNewData(result);
    });
  }

  function clearRefreshTimer() {
    if (timerRef.current !== null) window.clearTimeout(timerRef.current);
    timerRef.current = null;
  }

  function updatePanelsWithNewData(panelsResult) {
    setPanelData(panelsResult);

    // If in test mode, update new values immediately to avoid state updates outsideo Act error
    if (global.TESTMODE === true) {
      saveLastPanelValues(panelsResult.panelStates);
    } else {
      window.setTimeout(() => {
        // Bring last panel values up to date with the current values after a short pause to show the highlight
        saveLastPanelValues(panelsResult.panelStates);
      }, 1000);
    }

    // Start new timer until next data refresh - use refresh frequency given from API from last call.
    // This call will potentially make the new panel values not match what we have saved in lastPanelValues.
    //   That will cause HomeDashboardPanels to briefly highlight the change between the two.
    clearRefreshTimer();

    if (
      panelsResult.stateRefreshFrequencySeconds > 0 &&
      global.TESTMODE !== true
    ) {
      timerRef.current = window.setTimeout(
        () => loadNewDashboardData(),
        panelsResult.stateRefreshFrequencySeconds * 1000
      );
    }
  }

  function updateEditPanels(panelsResult) {
    setEditPanels(panelsResult);
  }

  async function loadDiagnostics() {
    apiLoadDiagnosticsData.call(diagnosticData.search, (result) => {
      let vms = result.map((r) => createViewModel(r));
      const count = vms.length || 0;

      //CRL: Always one record that has nothing but the build number and can be
      //removed one the build number is extracted
      let apiBuildVersion = vms.length > 0 ? vms[0].buildVersion : "";
      let apiEnv = vms.length > 0 ? vms[0].environment : "";

      setDiagnosticData({
        type: ContextProviderActions.loadDiagnostics,
        payload: {
          apiBuildVersion: apiBuildVersion,
          apiEnv: apiEnv,
          diagnostics: vms,
          count,
        },
      });
    });
  }

  function saveLastPanelValues(panelStates) {
    const lastValues = [];

    panelStates.forEach((panel) => {
      let values = panel.dashboardValues.map((v) => v.value);
      lastValues.push({
        id: panel.reportId,
        value: values.length > 0 ? values[values.length - 1] : 0,
      });
    });

    // console.log("Last values", lastValues);
    setLastPanelValues(lastValues);
  }

  function expandHomeSidebar(expand) {
    setUserPrefs({
      type: ContextProviderActions.setShowSidebarOnHomePage,
      payload: expand,
    });
  }

  function handlePanelActionSelection(value, label) {
    if (value === "ToggleGraphs") {
      setUserPrefs({
        type: ContextProviderActions.setShowGraphsOnHomePage,
        payload: !userPrefs.showGraphsOnHomePage,
      });
    } else if (value === "PanelEditMode") {
      // Stop refreshing data while in edit mode - panels are updated on Save and Cancel when done.
      clearRefreshTimer();

      // Build edit model panels
      const _panels = ((panelData || {}).panelStates || []).map((panel) => {
        return {
          id: panel.reportId,
          reportId: panel.reportId,
          panelLabel: panel.panelLabel,
          dashboardPanelOrdinal: panel.dashboardPanelOrdinal,
          isAlertPanel: panel.isAlertPanel,
          visible:
            panelData.dashboardHiddenReportIds.indexOf(panel.reportId) < 0,
        };
      });
      setEditPanels(_panels);
      setPanelEditMode(true);
    }
  }

  function restartRefreshTimer() {
    // Need a full refresh here so Loading indicator stays on screen until new panels are fully rendered.
    //   Clear the refresh timer first and let it get reset normally afterwards.
    clearRefreshTimer();
    loadDashboardAndReports();
  }

  async function savePanelEdits() {
    apiSavePanelProps.call(editPanels, (result) => {
      notifySuccess("Panels saved successfully");

      restartRefreshTimer();
      setPanelEditMode(false);
      setEditPanels([]);
    });
  }

  async function cancelPanelEdits() {
    restartRefreshTimer();
    setPanelEditMode(false);
    setEditPanels([]);
  }

  return (
    <>
      <HomeWebSocketHubConnections />
      {auth.isAdmin && !isSmallScreen && (
        <HomeSidebar
          expanded={userPrefs.showSidebarOnHomePage}
          expandHomeSidebar={expandHomeSidebar}
        >
          <HomeSourceBuildContent
            isSidebar={true}
            loadDiagnostics={loadDiagnostics}
            loading={loadingDiagnosticsData}
          />
        </HomeSidebar>
      )}
      <StyledHomeContainer
        style={{
          width:
            userPrefs.showSidebarOnHomePage && !isSmallScreen
              ? "calc(100% - 460px)"
              : "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "top",
            justifyContent: "center",
          }}
        >
          <div style={{ marginRight: "auto", visibility: "hidden" }}>
            <HelpLink path="/Home" label="Help" />
          </div>
          <h1 style={{ padding: 0, margin: 0 }}>
            xClaim Vision | {auth.isAdmin ? "Home" : "Home"}
          </h1>
          <StyledHeaderRowButtonDiv style={{ marginLeft: "auto" }}>
            <HelpLink path="/Home" label="Help" />
          </StyledHeaderRowButtonDiv>
        </div>

        <GridFreeFormSearchBar
          placeholderText="Search by Rx, Auth #, or Member ID"
          search={claimsData.search}
          setSearch={(search) => {
            setClaimsData({
              type: ContextProviderActions.saveClaimSearch,
              payload: search,
            });
          }}
          numSetFilters={0}
          onSubmitSearch={(event, newSearch) => {
            const updatedSearch = { ...claimsData.search, ...newSearch };
            if (_.trim(updatedSearch.freeFormSearch) !== "")
              navigate("/claims");
          }}
          showShowFiltersLink={false}
          allowEmptySearch={false}
        />

        <StyledHeaderRowDiv data-testid="home-dashboard-panels-section">
          <h2>
            <span>{panelEditMode ? "Edit Panels" : "Recent Activity"}</span>
            {panelEditMode ? (
              <HelpLink path="/Home#edit-panels" />
            ) : (
              <HelpLink path="/Home#recent-activity-section" />
            )}
          </h2>
          {panelEditMode && !loading ? (
            <StyledHeaderRowButtonDiv>
              <button
                type="button"
                className="btn btn-primary btn-with-icon"
                onClick={savePanelEdits}
                style={{ width: "84px", minWidth: "84px" }}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelPanelEdits}
                style={{ marginLeft: "12px" }}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          ) : (
            <ActionMenu
              title="Actions"
              items={[
                {
                  value: "ToggleGraphs",
                  label: userPrefs.showGraphsOnHomePage
                    ? "Hide graphs"
                    : "Show graphs",
                },
                {
                  value: "PanelEditMode",
                  label: "Edit panel order and visibility",
                },
              ]}
              onSelectAction={handlePanelActionSelection}
            />
          )}
        </StyledHeaderRowDiv>

        {panelEditMode && (
          <p>
            Drag and drop panels to change their order. Toggle the Show panel
            button to change visibility.
          </p>
        )}

        {loading ||
        loadingReports ||
        savingPanelProps ||
        loadingClaimsTraffic ? (
          <Spinner spinnerStyle={{ height: "200px", lineHeight: "200px" }} />
        ) : (
          <>
            {!panelEditMode && filteredPanels.length === 0 ? (
              <>
                <StyledNoResultsDiv
                  style={{ marginTop: "10px", height: "50px" }}
                  data-testid="home-dashboard-panels-no-results"
                >
                  <button
                    type="button"
                    className="btn btn-link btn-with-icon"
                    onClick={() => navigate("/report")}
                  >
                    <i className="material-icons">add</i>
                    Add a new dashboard panel
                  </button>
                </StyledNoResultsDiv>
              </>
            ) : (
              <>
                <StyledBoxContainer
                  data-testid={
                    panelEditMode
                      ? "home-dashboard-panels-edit"
                      : "home-dashboard-panels"
                  }
                >
                  <HomeDashboardPanels
                    panels={panelEditMode ? editPanels : filteredPanels}
                    lastValues={lastPanelValues}
                    showGraphs={userPrefs.showGraphsOnHomePage}
                    updateEditPanels={updateEditPanels}
                    updatePanels={updatePanelsWithNewData}
                    editMode={panelEditMode}
                  />
                </StyledBoxContainer>
              </>
            )}
          </>
        )}
        {auth.isAdmin && (
          <HomePacketsTraffic
            isLoading={false}
            packetBlockPositions={packetBlockPositions}
            setPacketBlockPositions={setPacketBlockPositions}
          />
        )}
        <HomeClaimsTraffic traffic={claimsData.claimsTraffic} />
        {auth.isAdmin && isSmallScreen && (
          <div
            style={{ marginTop: "40px" }}
            data-testid="home-sources-connections"
          >
            <StyledHeaderRowDiv style={{ marginBottom: "10px" }}>
              <h2>
                <span>Build Information</span>
                <HelpLink path="/Home#build-information-section" />
              </h2>
            </StyledHeaderRowDiv>
            <HomeSourceBuildContent
              isSidebar={false}
              loadDiagnostics={loadDiagnostics}
              loading={loadingDiagnosticsData}
            />
          </div>
        )}
        {auth.isAdmin && (
          <>
            <StyledHeaderRowDiv style={{ marginBottom: "10px" }}>
              <h2>
                <span>Diagnostics</span>
                <HelpLink path="/Home#diagnostics-section" />
              </h2>
            </StyledHeaderRowDiv>
            <HomeDiagnostics
              loadDiagnostics={loadDiagnostics}
              loading={loadingDiagnosticsData}
            />
          </>
        )}
        <HomeReports
          reportList={reportsData.filter((r) => r.showOnHomePage === true)}
        />
      </StyledHomeContainer>
    </>
  );
}

const StyledHomeContainer = styled.div`
  margin: 15px auto;

  h1 {
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: var(--text-normal);
    margin-bottom: 24px;
  }
`;

const StyledHeaderRowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  margin: 35px 0 6px 0;
  flex-wrap: wrap;

  @media only screen and (max-width: 767px) {
    margin-left: -5%;
    margin-right: -5%;
    padding: 0 10px;
  }

  h2 {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin: 2px 0 0 0;
    padding: 0;
    font-size: 18px;
    line-height: 16px;
    color: var(--text-normal);
  }
`;

const StyledBoxContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  flex-wrap: wrap;
  gap: 24px;

  @media only screen and (max-width: 576px) {
    gap: 15px;
  }
`;

export default HomePage;
