import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  StyledStepHeaderDiv,
  StyledWizardListItem,
} from "../../../common/layout/CommonStyledWizardComponents";
import DatePickerInput from "../../../common/input/DatePickerInput";

function PanelGetDateOnly({
  stepNumber,
  currentStep,
  name,
  title,
  footerText,
  value,
  onDateChange,
  errors,
  showPanel = true,
}) {
  // We have to track the date change locally and only send back the onChange on blur. Otherwise, the auto focus for the next panel kicks in and the current step changes.
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (value !== null) {
      setLocalValue(value);
    }
  }, [value]);

  function handleLocalChange(date) {
    setLocalValue(date);
  }

  function handleDateChange() {
    // Convert to ISO format so API can bind it to the view model
    const dt = localValue === null ? null : new Date(localValue).toISOString();
    onDateChange(dt);
  }

  if (!showPanel || currentStep < stepNumber) {
    return <></>;
  }

  return (
    <StyledWizardListItem step={stepNumber}>
      <StyledStepHeaderDiv>{title}</StyledStepHeaderDiv>

      <div className="row">
        <div className="col-12 col-md-6">
          <DatePickerInput
            id={name}
            name={name}
            label=""
            labelStyle={{ display: "none" }}
            value={localValue === null ? null : new Date(localValue)}
            showTimeInput={false}
            showPopupCalendar={false}
            placeholder=""
            onChange={handleLocalChange}
            onBlur={handleDateChange}
            error={errors[name]}
            autoFocus={currentStep === stepNumber}
          />
          {!_.isEmpty(footerText) && <div className="footer">{footerText}</div>}
        </div>
      </div>
    </StyledWizardListItem>
  );
}

export default PanelGetDateOnly;
