import React, { useState } from "react";
import _ from "lodash";
import { StyledNoResultsDiv } from "../../common/layout/CommonStyledControls";
import RuleTableDataGrid from "./RuleTableDataGrid";
import { download } from "../../../services/General";
import Spinner from "../../common/ui/Spinner";
import RuleTableDataDialog from "./RuleTableDataDialog";
import { useMobile } from "../../../hooks/useMobile";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import {
  apiDeleteRuleDataRow,
  apiGetRuleDataRowsForDownload,
  apiLoadRuleDataRow,
  apiScheduleRuleDataRowChange,
  apiUpdateRuleDataRow,
} from "../../../api/RuleTableDataApi";
import {
  createViewModel,
  emptyRuleDataRow,
  fromViewModel,
} from "../../../viewmodels/ruleTableDataVm";
import CheckboxInput from "../../common/input/CheckboxInput";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import RuleTableDataHistoryDialog from "./RuleTableDataHistoryDialog";
import useApi from "../../../hooks/useApi";

function RuleTableData({
  ruleTable,
  ruleTableSearch,
  search,
  setSearch,
  loadingData,
  onLoadingData,
}) {
  const { isMobileSize } = useMobile();
  const { loading, api: apiLoad } = useApi(apiLoadRuleDataRow);
  const { loading: saving, api: apiSave } = useApi(apiUpdateRuleDataRow);
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteRuleDataRow);
  const { loading: downloading, api: apiLoadDownload } = useApi(
    apiGetRuleDataRowsForDownload
  );
  const { loading: scheduling, api: apiSchedule } = useApi(
    apiScheduleRuleDataRowChange
  );
  const [showModal, setShowModal] = useState(false);
  const [editDataRow, setEditDataRow] = useState(null);
  const [errors, setErrors] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteExtras, setDeleteExtras] = useState("");
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [historyKeyHash, setHistoryKeyHash] = useState(null);

  const today = new Date();

  const filteredDataRows = search.active
    ? ruleTable.rows.filter(
        (r) =>
          (r.effectiveDate === null || new Date(r.effectiveDate) <= today) &&
          (r.terminationDate === null || new Date(r.terminationDate) > today)
      )
    : ruleTable.rows;

  async function downloadData() {
    apiLoadDownload.call(
      { id: ruleTable.id, search: ruleTableSearch },
      (result) => {
        const filename = ruleTable.key.replace(/([^a-z0-9\s]+)/gi, "-");
        download(result, `${filename}.csv`, ".csv");
        notifySuccess("File downloaded successfully");
      }
    );
  }

  function formIsValid() {
    const _errors = {};

    // Most validation is handled on the server, but here we will check to be sure all key columns and the effective date are filled.
    let val;
    ruleTable.columnSpecs
      .filter((c) => c.isKey === true)
      .forEach((c) => {
        val = editDataRow.values.find(
          (v) => v.ruleTableColumnDefinitionId === c.id
        );
        if (!val || !val.value || _.trim(val.value) === "")
          _errors[c.id] = `${c.name} is required`;
      });

    if (editDataRow.effectiveDate === null) {
      _errors.effectiveDate = "Effective Date is required";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleCloseEditDialog() {
    setEditDataRow(null);
    setErrors({});
    setShowModal(false);
  }

  async function deleteRuleDataRow(id, ruleTableId, isInherited) {
    apiDelete.call({ id, ruleTableId, isInherited }, (result) => {
      notifySuccess("Data row deleted successfully");
      onLoadingData();
    });
  }

  async function handleDelete(id, ruleTableId, isInherited) {
    setDeleteId(id);
    setDeleteExtras({ isInherited: isInherited, ruleTableId: ruleTableId });
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteRuleDataRow(
      deleteId,
      deleteExtras.ruleTableId,
      deleteExtras.isInherited
    );
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    setShowModal(false);
    const newVm = { ...editDataRow, ruleTableId: ruleTable.id };

    // Inserts also use the update endpoint.
    updateRuleDataRow(newVm.id, newVm);
  }

  function handleValidationFailure(vm) {
    // Convert validation failures into items in the errors object for display and then re-show the dialog
    const _errors = {};

    vm.validationFailures.forEach((e) => (_errors[e.columnId] = e.message));
    setErrors(_errors);

    notifyWarn("Please correct the errors before saving.");
    setShowModal(true);
  }

  async function updateRuleDataRow(id, vm) {
    var model = fromViewModel(vm);

    if (model.isScheduleChange === true) {
      apiSchedule.call({ id, model }, (result) => {
        postSaveRuleDataRow(result, model);
      });
    } else {
      apiSave.call({ id, model }, (result) => {
        postSaveRuleDataRow(result, model);
      });
    }
  }

  function postSaveRuleDataRow(result, model) {
    // Check for validation errors in result. If any, reshow dialog with errors.
    if ((result.validationFailures || []).length > 0) {
      handleValidationFailure(result);
    } else {
      // Success
      const newVm = { ...model, ...result };
      setEditDataRow(createViewModel(newVm));

      notifySuccess("Data row saved successfully");

      // Reload the grid to show the record updates
      onLoadingData();
    }
  }

  function handleAddRuleDataRow() {
    setEditDataRow({ ...emptyRuleDataRow });
    setShowModal(true);
  }

  function handleEditRuleDataRow(id) {
    apiLoad.call(id, (result) => {
      const vm = createViewModel(result);
      setEditDataRow({ ...emptyRuleDataRow, ...vm });
      setShowModal(true);
    });
  }

  function onActiveChange({ target }) {
    let updatedSearch = { ...search, active: target.checked };
    setSearch(updatedSearch);
  }

  function handleInstantSearchChange(value) {
    // If instant search is being performed, show ALL records.
    const pageSize =
      _.trim(value) !== ""
        ? 100000
        : search.pageSize > 250
        ? 25
        : search.pageSize;
    const pageNumber = _.trim(value) !== "" ? 1 : search.pageNumber;
    setSearch({
      ...search,
      highlightText: value,
      pageSize: pageSize,
      pageNumber: pageNumber,
    });
  }

  function handleScheduleDataRowChange(record) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const newRecord = {
      ...record,
      previousValueRowId: record.id,
      isScheduleChange: true,
      effectiveDate: today.toISOString(),
    };
    setEditDataRow({ ...emptyRuleDataRow, ...newRecord });
    setShowModal(true);
  }

  function handleViewDataRowHistory(keyHash) {
    setHistoryKeyHash(keyHash);
    setShowHistoryDialog(true);
  }

  function closeDataRowHistoryDialog() {
    setHistoryKeyHash(null);
    setShowHistoryDialog(false);
  }

  return (
    <>
      {historyKeyHash && (
        <RuleTableDataHistoryDialog
          records={ruleTable.rows.filter(
            (row) => row.keyHash === historyKeyHash
          )}
          columns={ruleTable.columnSpecs}
          showDialog={showHistoryDialog}
          onCloseDialog={closeDataRowHistoryDialog}
        />
      )}
      <ConfirmDialog
        title="Remove Data Row"
        question={`Are you sure you wish to deactivate this data row?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div
        className="flex-row-with-wrap"
        style={{ alignItems: "flex-start", marginBottom: "10px" }}
      >
        <div style={{ marginBottom: "10px" }}>
          <InstantSearchInput
            id="screenSearchInput"
            onChange={handleInstantSearchChange}
            value={search.highlightText}
          />
          <CheckboxInput
            id="active"
            label="Active Only"
            onChange={onActiveChange}
            placeholder="Active Only"
            name="active"
            showLabelInline={true}
            checked={search.active}
            error={errors.active}
          />
        </div>
        <button
          type="button"
          className="btn btn-secondary btn-with-icon"
          onClick={downloadData}
          style={{
            marginLeft: isMobileSize ? "0" : "auto",
          }}
          disabled={downloading || (ruleTable.rows || []).length === 0}
        >
          {downloading ? (
            <>
              {" "}
              <span className="material-icons">hourglass_empty</span>
              Downloading...
            </>
          ) : (
            <>
              {" "}
              <span className="material-icons">download</span>
              Download Active Rows (CSV)
            </>
          )}
        </button>
      </div>

      {editDataRow !== null && (
        <RuleTableDataDialog
          editDataRow={editDataRow}
          setEditDataRow={setEditDataRow}
          columnSpecs={ruleTable.columnSpecs || []}
          errors={errors}
          showDialog={showModal}
          onCloseDialog={handleCloseEditDialog}
          onSaveDialog={handleSubmit}
        />
      )}
      {loading || loadingData || saving || deleting || scheduling ? (
        <Spinner spinnerStyle={{ height: "200px", lineHeight: "200px" }} />
      ) : (
        <div style={{ marginTop: "20px" }}>
          <hr />
          <button
            type="button"
            className="btn btn-secondary btn-with-icon"
            onClick={handleAddRuleDataRow}
            style={{ marginLeft: "auto" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Data Row
          </button>

          {ruleTable.rows.length === 0 ? (
            <div style={{ marginTop: "10px" }}>
              <StyledNoResultsDiv>
                <p>
                  <i className="material-icons">search_off</i>
                </p>
                <p>No results found</p>
              </StyledNoResultsDiv>
            </div>
          ) : (
            <RuleTableDataGrid
              ruleTableId={ruleTable.id}
              totalRecords={ruleTable.rows.length}
              columns={ruleTable.columnSpecs}
              records={filteredDataRows}
              colCount={ruleTable.columnSpecs.length}
              search={search}
              setSearch={setSearch}
              onDeleteDataRow={handleDelete}
              onEditDataRow={handleEditRuleDataRow}
              onScheduleDataRowChange={handleScheduleDataRowChange}
              onViewDataRowHistory={handleViewDataRowHistory}
            />
          )}
        </div>
      )}
    </>
  );
}

export default RuleTableData;
