import React from "react";
import { useMobile } from "../../../hooks/useMobile";
import CheckboxInput from "../../common/input/CheckboxInput";
import TextInput from "../../common/input/TextInput";

function DrugSearchForm({
  search,
  errors,
  onChange,
  onActiveChange,
  onSearch,
  onReset,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="ndc"
            label="NDC"
            onChange={onChange}
            placeholder="NDC"
            name="ndc"
            value={search.ndc}
            error={errors.ndc}
          />
          <TextInput
            id="productName"
            label="Product Name"
            onChange={onChange}
            placeholder="Product Name"
            name="productName"
            value={search.productName}
            error={errors.productName}
          />
          <TextInput
            id="gpi"
            label="GPI"
            onChange={onChange}
            placeholder="GPI"
            name="gpi"
            value={search.gpi}
            error={errors.gpi}
          />
          <TextInput
            id="gppc"
            label="GPPC"
            onChange={onChange}
            placeholder="GPPC"
            name="gppc"
            value={search.gppc}
            error={errors.gppc}
          />
          <TextInput
            id="routeOfAdministration"
            label="Route Of Administration"
            onChange={onChange}
            placeholder="Route Of Administration"
            name="routeOfAdministration"
            value={search.routeOfAdministration}
            error={errors.routeOfAdministration}
          />
          <TextInput
            id="rxOtcIndicator"
            label="OTC Indicator"
            onChange={onChange}
            placeholder="OTC Indicator"
            name="rxOtcIndicator"
            value={search.rxOtcIndicator}
            error={errors.rxOtcIndicator}
          />
          <TextInput
            id="multiSourceCode"
            label="Multi Source Code"
            onChange={onChange}
            placeholder="Multi Source Code"
            name="multiSourceCode"
            value={search.multiSourceCode}
            error={errors.multiSourceCode}
          />
          <CheckboxInput
            id="active"
            label="Active Only"
            onChange={onActiveChange}
            placeholder="Active Only"
            name="active"
            checked={search.active}
            error={errors.active}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default DrugSearchForm;
