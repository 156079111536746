import React from "react";
import styled from "styled-components";

function Badge({ badgeStyle = {}, children }) {
  return <StyledBadge style={{ ...badgeStyle }}>{children}</StyledBadge>;
}

const StyledBadge = styled.span`
  height: 15px;
  min-width: 28px;
  border-radius: 500px;
  padding: 0 5px 1px 4px;
  margin: 0 4px;
  font-size: 15px;
  font-weight: bold;
  line-height: 15px;
  color: var(--pill-blue);
  background-color: var(--pill-text);
`;

export default Badge;
