import React, { useEffect, useState } from "react";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import styled from "styled-components";
import {
  StyledDescription,
  StyledProcessedIndicator,
} from "./RuleMapCommonStyles";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
} from "./RuleMapDataCommon";

export const DefaultZoomedOutNodeSize = { width: 80, height: 50 };

function RuleMapZoomedOutNode({ data }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const { ruleMapsData } = useRuleMaps();
  const [nodeWidth] = useState(DefaultZoomedOutNodeSize.width);
  const [nodeHeight] = useState(DefaultZoomedOutNodeSize.height);

  useEffect(() => {
    if (data.layoutDirection !== ruleMapsData?.layoutDirection) {
      updateNodeInternals(data.id);
    }
  }, [data.layoutDirection]);

  const colors = getBadgeColorsFromType(
    data.type,
    data.isActualNodeLink,
    data.isProcessed
  );

  return (
    <StyledNodeDiv width={nodeWidth} height={nodeHeight}>
      {!data.isLeafNode && (
        <Handle
          type="target"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Left
              : Position.Top
          }
        />
      )}
      <StyledZoomedOutCard colors={colors} height={nodeHeight}>
        {!data.isActualNodeLink && data.isProcessed && (
          <StyledProcessedIndicator>
            <span className="material-symbols-outlined">electric_bolt</span>
          </StyledProcessedIndicator>
        )}
        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={colors}
        >
          <div className="content">
            <span className="charblock">
              {getNodeTypeAbbreviation(data.type, false)}
            </span>
            <span className="content-text">{data.itemKey}</span>
          </div>
        </StyledDescription>
      </StyledZoomedOutCard>
      {!data.isRootNode && (
        <Handle
          type="source"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Right
              : Position.Bottom
          }
        />
      )}
    </StyledNodeDiv>
  );
}

const StyledNodeDiv = styled.div`
  min-height: ${(props) => props.height}px;
  min-width: ${(props) => props.width}px;
  border-radius: 3px;
`;

const StyledZoomedOutCard = styled.div`
  min-height: ${(props) => props.height}px;
  height: 100%;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: var(--selected-bg);
  border: 1px solid ${(props) => props.colors.bgColor};
  border-radius: 3px;
  box-shadow: ${(props) => props.colors.bgShadow};

  &.hover {
    background-color: var(--selected-bg);
    border: 1px solid var(--selected-border);
  }

  div.content {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    height: ${(props) => props.height}px;
    line-height: ${(props) => props.height}px;
    border: 4px solid ${(props) => props.colors.bgColor};

    span.content-text {
      display: inline-block;
      color: var(--text-normal);
      font-size: 26px;
      padding: 0 20px;
    }

    span.charblock {
      display: inline-block;
      padding: 0 12px 0 8px;
      font-size: 28px;

      span.material-icons {
        font-size: 28px;
        vertical-align: middle;
      }
    }
  }
`;

export default RuleMapZoomedOutNode;
