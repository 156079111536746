import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyHbDetectorConfig } from "../viewmodels/hbDetectorConfigVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadHbDetectorConfig:
      return {
        ...state,
        hbDetectorConfig: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in HbDetectorConfigContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  hbDetectorConfig: emptyHbDetectorConfig,
};

const HbDetectorConfig = createContext({
  hbDetectorConfigData: initialState,
  setHbDetectorConfigData: () => null,
});

// eslint-disable-next-line react/prop-types
const HbDetectorConfigProvider = ({ children }) => {
  const [hbDetectorConfigData, setHbDetectorConfigData] = useReducer(
    reducer,
    initialState
  );

  return (
    <HbDetectorConfig.Provider
      value={{ hbDetectorConfigData, setHbDetectorConfigData }}
    >
      {children}
    </HbDetectorConfig.Provider>
  );
};

export default HbDetectorConfigProvider;
export const useHbDetectorConfig = () => useContext(HbDetectorConfig);
