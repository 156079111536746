import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  getUserIdFromToken,
  isAdmin,
  isBasicUser,
  isParserUser,
} from "../services/General";

function reducer(state, action) {
  const isTestModeAndLoggedIn =
    global.TESTMODE === true && global.LOGGED_IN === true;

  switch (action.type) {
    case ContextProviderActions.setLoginScreenMessage:
      setLoginMessageInLocalStorage(action.payload);

      return {
        ...state,
        loginMessage: action.payload,
      };

    case ContextProviderActions.setAuthenticationSuccessResult:
      setLoginMessageInLocalStorage("");

      return {
        ...state,
        authenticated: true,
        token: action.payload.token,
        userName: action.payload.userName,
        userId: isTestModeAndLoggedIn
          ? global.USERID
          : getUserIdFromToken(action.payload.token),
        isAdmin:
          isTestModeAndLoggedIn && global.USER_ROLE === "Admin"
            ? true
            : isAdmin(action.payload.token),
        isBasicUser:
          isTestModeAndLoggedIn && global.USER_ROLE === "Basic"
            ? true
            : isBasicUser(action.payload.token),
        isParserUser:
          isTestModeAndLoggedIn && global.USER_ROLE === "Parser"
            ? true
            : isParserUser(action.payload.token),
        loginMessage: "",
      };

    case ContextProviderActions.setAuthenticationToken:
      return {
        ...state,
        token: action.payload,
        userId: isTestModeAndLoggedIn
          ? global.USERID
          : getUserIdFromToken(action.payload.token),
        isAdmin:
          isTestModeAndLoggedIn && global.USER_ROLE === "Admin"
            ? true
            : isAdmin(action.payload.token),
        isBasicUser:
          isTestModeAndLoggedIn && global.USER_ROLE === "Basic"
            ? true
            : isBasicUser(action.payload.token),
        isParserUser:
          isTestModeAndLoggedIn && global.USER_ROLE === "Parser"
            ? true
            : isParserUser(action.payload.token),
      };

    case ContextProviderActions.setAuthenticationFailResult:
      setLoginMessageInLocalStorage(action.payload);

      return {
        ...state,
        authenticated: false,
        token: "",
        userId: "",
        userName: "",
        isAdmin: false,
        isBasicUser: false,
        isParserUser: false,
        loginMessage: action.payload,
      };

    case ContextProviderActions.saveLoggedOut:
      setLoginMessageInLocalStorage(action.payload);

      return {
        ...state,
        authenticated: false,
        token: "",
        userId: "",
        userName: "",
        isAdmin: false,
        isBasicUser: false,
        isParserUser: false,
        loginMessage: action.payload,
      };

    case ContextProviderActions.saveLogOutFailed:
      setLoginMessageInLocalStorage(action.payload);

      return {
        ...state,
        authenticated: false,
        token: "",
        userId: "",
        userName: "",
        isAdmin: false,
        isBasicUser: false,
        isParserUser: false,
        loginMessage: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in AuthContext: ${action.type.toString()}`
      );
  }
}

function setLoginMessageInLocalStorage(message) {
  // This is so the login message can survive the round trip to the Microsoft login page and back.
  if (message === "") {
    localStorage.removeItem("LOGIN_MESSAGE");
  } else {
    localStorage.setItem("LOGIN_MESSAGE", message);
  }
}

const initialState = {
  loginMessage: localStorage.getItem("LOGIN_MESSAGE") ?? "",
  authenticated: false,
  token: "",
  userId: "",
  userName: "",
  isAdmin: false,
  isBasicUser: false,
  isParserUser: false,
};

const Auth = createContext({
  auth: initialState,
  setAuth: () => null,
});

// eslint-disable-next-line react/prop-types
const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useReducer(reducer, initialState);

  return <Auth.Provider value={{ auth, setAuth }}>{children}</Auth.Provider>;
};

export default AuthProvider;
export const useAuth = () => useContext(Auth);
