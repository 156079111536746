import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createViewModel,
  emptyPriorAuthorization,
  fromViewModel,
} from "../../../viewmodels/priorAuthorizationsVm";
import DatePickerInput from "../../common/input/DatePickerInput";
import ReadOnly from "../../common/input/ReadOnly";
import {
  disableAnimations,
  formatDateTimeUtcZoneForDisplay,
  handleCollapseExpandAll,
  isPositiveInteger,
  unZero,
} from "../../../services/General";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { usePriorAuthorizations } from "../../../contexts/PriorAuthorizationsContext";
import { useAuth } from "../../../contexts/AuthContext";
import {
  apiAddPriorAuthorization,
  apiLoadPriorAuthorization,
  apiLoadPriorAuthorizationClaims,
  apiLoadRejectionDescriptions,
  apiUpdatePriorAuthorization,
} from "../../../api/PriorAuthApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import PriorAuthorizationConditionalsSection from "./PriorAuthorizationConditionalsSection";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function PriorAuthorization() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { priorAuthorizationsData, setPriorAuthorizationsData } =
    usePriorAuthorizations();
  const { loading, api: apiLoad } = useApi(apiLoadPriorAuthorization);
  const { loading: adding, api: apiAdd } = useApi(apiAddPriorAuthorization);
  const { loading: updating, api: apiUpdate } = useApi(
    apiUpdatePriorAuthorization
  );
  const { loading: loadingClaims, api: apiLoadClaims } = useApi(
    apiLoadPriorAuthorizationClaims
  );
  const { loading: loadingRejDescs, api: apiLoadRejDescs } = useApi(
    apiLoadRejectionDescriptions
  );
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyPriorAuthorization);
  const [timer, setTimer] = useState();
  const [showPaModal, setShowPaModal] = useState();
  const [collapsedState, setCollapsedState] = useState([
    { name: "Prior Authorization", collapsed: false },
    { name: "Effective Range", collapsed: !(params && params.rx && params.sp) },
    { name: "Conditionals", collapsed: !(params && params.rx && params.sp) },
    {
      name: "Authorized Rejection Overrides",
      collapsed: !(params && params.rx && params.sp),
    },
    { name: "History", collapsed: true },
  ]);

  const paId = params && params.id;
  const paRx = params && params.rx;
  const paSp = params && params.sp;
  const paRej = (params && params.rej) || "";

  let paClaims = [];
  if (priorAuthorizationsData && priorAuthorizationsData.claims) {
    paClaims = priorAuthorizationsData.claims;
  }

  let rejectionDescriptions = [];
  if (
    priorAuthorizationsData &&
    priorAuthorizationsData.rejectionDescriptions
  ) {
    rejectionDescriptions = priorAuthorizationsData.rejectionDescriptions;
  }

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setPriorAuthorizationsData({
        type: ContextProviderActions.loadPriorAuthorization,
        payload: {
          priorAuthorization: emptyPriorAuthorization,
          claims: [],
          rejectionDescriptions: [],
        },
      });

      loadPriorAuthorization();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (priorAuthorizationsData.priorAuthorization) {
      setChanges(priorAuthorizationsData.priorAuthorization);
    } else {
      setChanges(emptyPriorAuthorization);
    }
  }, [priorAuthorizationsData.priorAuthorization]);

  async function loadPriorAuthorization() {
    // There are three entry points to this screen:
    //   1. Creating new prior auth from a rejected claim on the Claims screen
    //   2. Clicking an existing prior auth record link from the Prior Authorizations screen
    //   3. Clicking the "Add New" link from the Prior Authorizations screen
    if (paRx && paSp) {
      // If creating a new prior auth from a rejected claim, set up the screen with default data and load rejection codes.
      await loadNewPriorAuthorizationForRejectedClaim();
    } else if (paId) {
      // Load the given prior auth
      await loadExistingPriorAuthorization();
    } else {
      // Show blank prior auth screen for creating new one
      setPriorAuthorizationsData({
        type: ContextProviderActions.loadPriorAuthorization,
        payload: {
          priorAuthorization: emptyPriorAuthorization,
          claims: [],
          rejectionDescriptions: [],
        },
      });
      setChanges(emptyPriorAuthorization);
    }
  }

  async function loadExistingPriorAuthorization() {
    await apiLoad.call(paId, async (result) => {
      let vm = emptyPriorAuthorization;
      if (!result) {
        notifyError("Prior Authorization does not exist");
      } else {
        vm = createViewModel(result);
      }

      // Load claims for this prior auth
      const claims = await loadPriorAuthorizationClaims();

      // Load rejection descriptions for this prior auth
      const rejDescs = await getRejectionDescriptions(
        vm.authorizedRejectionsVm
      );

      setPriorAuthorizationsData({
        type: ContextProviderActions.loadPriorAuthorization,
        payload: {
          priorAuthorization: vm,
          claims,
          rejectionDescriptions: rejDescs,
        },
      });
      setChanges(vm);
    });
  }

  async function loadPriorAuthorizationClaims() {
    return await apiLoadClaims.call(paId, (result) => {
      // Claims are returned as a normal array here, not like the search result which returns an object containing an array.
      return result;
    });
  }

  async function getRejectionDescriptions(rejs) {
    return await apiLoadRejDescs.call(rejs, (result) => {
      return result;
    });
  }

  async function loadNewPriorAuthorizationForRejectedClaim() {
    var newPaData = {
      ...changes,
      prescriptionNumber: unZero(paRx),
      pharmacy: paSp,
      authorizedRejectionsVm: (paRej || "").replace("|", ","),
    };
    setChanges(newPaData);

    await getRejectionDescriptions(newPaData.authorizedRejectionsVm).then(
      (result) => {
        // Success
        if (result) {
          setPriorAuthorizationsData({
            type: ContextProviderActions.loadPriorAuthorization,
            payload: {
              priorAuthorization: newPaData,
              claims: [],
              rejectionDescriptions: result,
            },
          });
        }
      }
    );
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    let isPos = isPositiveInteger(changes.prescriptionNumber);
    if (_.has(changes, "prescriptionNumber") && !isPos)
      _errors.prescriptionNumber = "Prescription Number must be a valid number";
    if (changes.authorizedRejectionsVm.trim() === "")
      _errors.authorizedRejections =
        "There must be at least one overridden rejection";
    if (!_.has(changes, "usages") || !isPositiveInteger(changes.usages))
      _errors.usages = "Usages must be a valid number greater than zero";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let paChanged = { ...changes, [target.name]: target.value };
    setChanges(paChanged);
    if (target.name === "authorizedRejectionsVm") {
      // I think this timer is set up to prevent unecessary calls to the API while user is making changes. It waits until
      //   2 seconds after the last change.  Any additional changes clears the old timer before setting it again.
      clearTimeout(timer);
      setTimer(
        setTimeout(async () => {
          await getRejectionDescriptions(target.value).then((result) => {
            // Success
            if (result) {
              setPriorAuthorizationsData({
                type: ContextProviderActions.savePriorAuthorizationRejectionDescriptions,
                payload: result,
              });
            }
          });
        }, 2000)
      );
    }
  }

  function handleEffectiveDateChange(date) {
    setChanges({ ...changes, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };

    if (!paId) {
      addPriorAuthorization(newVm);
    } else updatePriorAuthorization(newVm.id, newVm);
  }

  async function addPriorAuthorization(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      setChanges(createViewModel(result));
      setShowPaModal(true);
    });
  }

  async function updatePriorAuthorization(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      setChanges(createViewModel(result));
      notifySuccess(
        "Prior Authorization " +
          result.priorAuthorizationNumber +
          " saved successfully"
      );
    });
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return"
            className="btn btn-link btn-with-icon"
            onClick={() =>
              navigate(
                paRx && paSp ? "/claims" : "/configurepriorauthorizations"
              )
            }
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink
              path="/Configure/Prior-Authorization-Screen"
              label="Help"
            />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            Prior Authorization{" "}
            {changes.priorAuthorizationNumber
              ? ` | ${changes.priorAuthorizationNumber}`
              : ""}
          </h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() =>
                navigate(
                  paRx && paSp ? "/claims" : "/configurepriorauthorizations"
                )
              }
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {loading || loadingClaims || adding || updating ? (
          <Spinner />
        ) : (
          <>
            <Modal
              show={showPaModal}
              onHide={() => {}}
              animation={!disableAnimations()}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  New Prior Authorization Number
                  <HelpLink
                    path="/Configure/Prior-Authorization-Screen&anchor=new-prior-authorization-number-dialog"
                    label=""
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <ReadOnly
                    id="priorAuthorizationNumber"
                    label="Prior Authorization Number"
                    value={changes.priorAuthorizationNumber}
                    copyToClipboard={true}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowPaModal(false);
                    navigate("/configurepriorauthorizations");
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/claimpriorauthorization/${paId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Prior Authorization"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Prior-Authorization-Screen&anchor=prior-authorization"
              >
                {paId && (
                  <>
                    <StyledRowDiv
                      className="row"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="col-12">
                        <ReadOnly
                          id="priorAuthorizationNumber"
                          label="Prior Authorization Number"
                          value={changes.priorAuthorizationNumber}
                          copyToClipboard={true}
                        />
                      </div>
                    </StyledRowDiv>
                  </>
                )}
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="pharmacy"
                      label="Pharmacy"
                      onChange={handleChange}
                      placeholder="Pharmacy NPI"
                      name="pharmacy"
                      value={changes.pharmacy || ""}
                      error={errors.pharmacy}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="prescriptionNumber"
                      label="Prescription Number"
                      onChange={handleChange}
                      placeholder="Prescription Number"
                      name="prescriptionNumber"
                      value={changes.prescriptionNumber}
                      error={errors.prescriptionNumber}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="memberId"
                      label="Member"
                      onChange={handleChange}
                      placeholder="Cardholder Id"
                      name="memberId"
                      value={changes.memberId}
                      error={errors.memberId}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="productId"
                      label="Product Id"
                      onChange={handleChange}
                      placeholder="Product Id"
                      name="productId"
                      value={changes.productId}
                      error={errors.productId}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="groupId"
                      label="Group"
                      onChange={handleChange}
                      placeholder="Group Id"
                      name="groupId"
                      value={changes.groupId}
                      error={errors.groupId}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="usages"
                      label="Usages"
                      onChange={handleChange}
                      placeholder="Usages"
                      name="usages"
                      value={changes.usages}
                      error={errors.usages}
                      isNumber={true}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Effective Range"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Prior-Authorization-Screen&anchor=effective-range"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="effectiveDate"
                      name="effectiveDate"
                      label="Effective Date"
                      value={changes.effectiveDate}
                      placeholder="Effective Date"
                      onChange={handleEffectiveDateChange}
                      error={errors.effectiveDate}
                      showTimeInput={false}
                    />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="terminationDate"
                      label="Termination Date"
                      name="terminationDate"
                      value={changes.terminationDate}
                      placeholder="Termination Date"
                      onChange={handleTerminationDateChange}
                      error={errors.terminationDate}
                      showTimeInput={false}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Conditionals"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Prior-Authorization-Screen&anchor=conditionals"
              >
                <PriorAuthorizationConditionalsSection
                  changes={changes}
                  setChanges={setChanges}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Authorized Rejection Overrides"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Prior-Authorization-Screen&anchor=authorized-rejection-overrides"
              >
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6">
                    <p style={{ padding: "0", margin: "0", marginTop: "10px" }}>
                      Enter override codes as a comma-separated list.
                    </p>
                    <TextInput
                      id="authorizedRejections"
                      label=""
                      labelStyle={{
                        display: "none",
                      }}
                      onChange={handleChange}
                      placeholder="Authorized Rejection Overrides"
                      name="authorizedRejectionsVm"
                      value={changes.authorizedRejectionsVm}
                      error={errors.authorizedRejections}
                    />
                    <br />
                    {loadingRejDescs ? (
                      <Spinner
                        spinnerStyle={{ height: "200px", lineHeight: "200px" }}
                      />
                    ) : (
                      <table className="table">
                        <colgroup>
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "75%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th colSpan="2" className="table-header">
                              Code Definitions
                            </th>
                          </tr>
                          <tr>
                            <th>Code</th>
                            <th>Definition</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rejectionDescriptions.map((d, dIndex) => {
                            return (
                              <tr key={"tr" + dIndex}>
                                <td>{(d || ":").split(":")[0].trim()}</td>
                                <td>{(d || ":").split(":")[1].trim()}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
            </div>
            <ExpandCollapseDetailSection
              sectionTitle="History"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Configure/Prior-Authorization-Screen&anchor=history"
            >
              <StyledRowDiv className="row">
                <div className="col-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th id="date">Date</th>
                        <th id="authorizationNumber">Auth #</th>
                        <th id="prescription">Prescription</th>
                        <th id="paidReversed">Paid / Reversed</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paClaims.map((c, idx) => {
                        return (
                          <tr key={"cl" + idx}>
                            <td className="force-wrap">
                              {formatDateTimeUtcZoneForDisplay(c.processedDate)}
                            </td>
                            <td className="force-wrap">
                              {c.authorizationNumber}
                            </td>
                            <td className="force-wrap">
                              {c.prescriptionNumber}
                            </td>
                            <td className="force-wrap">
                              {c.reversed ? "Reversed" : "Paid"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default PriorAuthorization;
