import React from "react";
import {
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
} from "../../../services/General";
import { useNavigate } from "react-router-dom";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function ProcessRunHistoryList({
  processHistory,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDownloadFile,
  onRollbackProcess,
}) {
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const mainProcessId = dataRow.mainProcessId;
    const type = dataRow.type;

    const actions = [];

    actions.push({
      name: "View Process History record",
      onClick: () => navigate("/processhistory/" + id),
    });

    actions.push({
      name: "Download file(s)",
      onClick: () => onDownloadFile(id),
    });

    actions.push({
      name: "Edit Report Process",
      onClick: () => navigate("/reportprocess/" + mainProcessId),
    });

    if (type === "Summary") {
      actions.push({
        name: 'Rollback process to this "Last Known Good" point',
        onClick: () => onRollbackProcess(id),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="ReportProcessHistory"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={processHistory}
        columnDefs={[
          {
            name: "description",
            label: "Description",
            style: { width: "40%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/processhistory/" + row.id)}
              >
                {row.description}
              </button>
            ),
          },
          {
            name: "runType",
            label: "Run Type",
            style: { width: "25%" },
          },
          {
            name: "reportName",
            label: "Report",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/report/" + row.reportDefinitionId)}
              >
                {row.reportName}
              </button>
            ),
          },
          {
            name: "runDate",
            label: "Run Date",
            style: { width: "25%" },
            getValue: (row) => formatDateTimeUtcZoneForDisplay(row.runDate),
          },
          {
            name: "runDuration",
            label: "Duration",
            className: "d-none d-lg-table-cell",
            style: { width: "20%" },
            getValue: (row) => getTimeframeFromMilliseconds(row.runDuration),
          },
          {
            name: "runBy",
            label: "Run By",
            className: "d-none d-lg-table-cell",
            style: { width: "20%" },
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default ProcessRunHistoryList;
