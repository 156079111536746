import _ from "lodash";
import { ticksToDate } from "../services/General";

var oneHourAgo = new Date();
oneHourAgo.setHours(oneHourAgo.getHours() - 1);

export const emptyDiagnosticsSearch = {
  sliceSeconds: 600,
  startDate: oneHourAgo,
  endDate: new Date("1/1/3000"),
};

export const emptyDiagnostic = {
  sliceStartTicks: 0,
  sliceEndTicks: 0,
  drugCount: 0,
  errors: 0,
  pharmacyCount: 0,
  claimCount: 0,
  reversalPrescriptions: "",
  rejectedPrescriptions: "",
  rejections: "",
  rangeGroup: 0,
};

export function createViewModel(m) {
  let vm = _.cloneDeep(m);
  vm.startDateTime = ticksToDate(vm.sliceStartTicks);
  vm.endDateTime = ticksToDate(vm.sliceEndTicks);
  return vm;
}
