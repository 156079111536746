import React from "react";
import CheckboxInput from "../../common/input/CheckboxInput";
import DatePickerInput from "../../common/input/DatePickerInput";
import { useMobile } from "../../../hooks/useMobile";
import TextInput from "../../common/input/TextInput";
import DateRangeInput from "../../common/input/DateRangeInput";

function DiscountSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onActiveChange,
  onDateChange,
  onStartDateChange,
  onEndDateChange,
  onDateRangeChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="id"
            label="Id"
            onChange={onChange}
            placeholder="Id"
            name="id"
            value={search.id}
            error={errors.id}
          />
          <TextInput
            id="groupId"
            label="Group"
            onChange={onChange}
            placeholder="Group Id"
            name="groupId"
            value={search.groupId}
            error={errors.groupId}
          />
          <TextInput
            id="discountPlan"
            label="Discount Plan"
            onChange={onChange}
            placeholder="Discount Plan"
            name="discountPlan"
            value={search.discountPlan}
            error={errors.discountPlan}
          />
          <TextInput
            id="memberId"
            label="Member"
            onChange={onChange}
            placeholder="Cardholder Id"
            name="memberId"
            value={search.memberId}
            error={errors.memberId}
          />
          <TextInput
            id="pharmacy"
            label="Pharmacy"
            onChange={onChange}
            placeholder="Pharmacy NPI"
            name="pharmacy"
            value={search.pharmacy}
            error={errors.pharmacy}
          />
          <TextInput
            id="ndc"
            label="Drug NDC"
            onChange={onChange}
            placeholder="Drug NDC"
            name="ndc"
            value={search.ndc}
            error={errors.ndc}
          />
          <TextInput
            id="prescriber"
            label="Prescriber NPI"
            onChange={onChange}
            placeholder="Prescriber NPI"
            name="prescriber"
            value={search.prescriber}
            error={errors.prescriber}
          />
          <CheckboxInput
            id="active"
            label="Active Only"
            onChange={onActiveChange}
            placeholder="Active Only"
            name="active"
            checked={search.active}
            error={errors.active}
          />
          <DateRangeInput
            id="dateRange"
            name="dateRange"
            label="Date Range"
            value={search.dateRange}
            onChange={onDateRangeChange}
            error={errors.dateRange}
          />
          <DatePickerInput
            id="startDate"
            name="startDate"
            label="Processed Start Date"
            value={search.startDate}
            placeholder=""
            onChange={onStartDateChange}
            error={errors.startDate}
          />
          <DatePickerInput
            id="endDate"
            label="Processed End Date"
            name="endDate"
            value={search.endDate}
            placeholder=""
            onChange={onEndDateChange}
            error={errors.endDate}
          />
          <h4 style={{ marginTop: "20px" }}>View Discount Amounts</h4>
          <p>Set Group Id above to enable:</p>
          <TextInput
            id="initialPrice"
            label="Initial Price"
            onChange={onChange}
            placeholder="Initial Price"
            name="initialPrice"
            disabled={search.groupId.trim() === ""}
            value={search.initialPrice}
            error={errors.initialPrice}
            isNumber={true}
          />
          <DatePickerInput
            id="processedDate"
            name="processedDate"
            label="Processed Date"
            value={search.processedDate}
            disabled={search.groupId.trim() === ""}
            placeholder="Processed Date"
            onChange={onDateChange}
            error={errors.processedDate}
          />
          <TextInput
            id="lookupQty"
            label="Lookup Quantity"
            onChange={onChange}
            placeholder="Metric Decimal Quantity"
            name="lookupQty"
            disabled={search.groupId.trim() === ""}
            value={search.lookupQty}
            error={errors.lookupQty}
            isNumber={true}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default DiscountSearchForm;
