import { pageSize } from "../constants/Environments";
import { logInitial } from "../services/General";

export const emptyLogHeaderSearch = {
  startDate: new Date(new Date().getTime() - 60 * 60 * 1000),
  endDate: new Date("1/1/3000"),
  dateRange: "Past24hours",
  headerSearchText: "",
  logTypes: [],
  orderBy: ["ticks-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  logHeadersCount: 0,
  logHeaderSearch: "",
  traceId: "",
  userSearch: "",
  environment: "",
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export const emptyLogSearch = {
  traceId: "00000000-0000-0000-0000-000000000000",
  logType: [...logInitial],
  highlightText: "",
};

export function getLogLevelRowStyle(level) {
  let border = "4px solid ";

  if (level === "Error" || level === "Fatal") border += "var(--notify-danger)";
  else if (level === "Debug") border += "var(--notify-success)";
  else if (level === "Info") border += "var(--notify-info)";
  else if (level === "Warn") border += "var(--notify-warning)";
  else border += "var(--notify-neutral)";

  return border;
}
