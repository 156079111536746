import _ from "lodash";
import { pageSize } from "../constants/Environments";
import { generateUUID } from "../services/General";

export const emptyRule = {
  id: "",
  description: "",
  priority: "0",
  client: "*",
  effectiveDate: new Date("1/1/2000"),
  terminationDate: new Date("1/1/3000"),
  includedGroups: [],
  excludedGroups: [],
  conditionals: [],
  includedTags: [],
  includedArtifacts: [],
  excludedArtifacts: [],
  ruleArtifacts: [],
  includedDataValues: [],
  includedRejections: [],
  excludedRejections: [],
  includedGroupsVm: "",
  excludedGroupsVm: "",
  includedTagsVm: "",
  includedArtifactsVm: "",
  excludedArtifactsVm: "",
  destinationRules: [],
  textSearch: "",
};

export const emptySavedRuleEditState = {
  continueRuleEdit: false,
  ruleId: "",
  ruleChanges: null,
  ruleTableChanges: null,
  securityLevel: "Rule",
};

export const emptyRuleSearch = {
  groupId: "",
  textSearch: "",
  pageSize: pageSize || 25,
  pageNumber: 1,
  showAdvancedFilter: false,
  highlightText: "",
};

export const emptyRuleGroupSummary = {
  rules: [],
  artifacts: [],
  rejections: [],
  properties: [],
  values: [],
  conditionals: [],
  ruleTables: [],
  responseFields: [],
};

export const emptyRuleChangeHistoryRecord = {
  changeBy: "",
  changeReason: "",
  changeDate: new Date(),
};

// eslint-disable-next-line no-extend-native
Array.prototype.trimArray = function () {
  return this.map((i) => {
    return _.trim(i);
  });
};

export function fromViewModel(rule) {
  let r = _.cloneDeep(rule);
  if (_.isEmpty(r.includedGroupsVm)) r.includedGroups = [];
  else r.includedGroups = r.includedGroupsVm.split(",").trimArray();
  if (_.isEmpty(r.excludedGroupsVm)) r.excludedGroups = [];
  else r.excludedGroups = r.excludedGroupsVm.split(",").trimArray();
  if (_.isEmpty(r.includedTagsVm)) r.includedTags = [];
  else r.includedTags = r.includedTagsVm.split(",").trimArray();
  if (_.isEmpty(r.includedArtifactsVm)) r.includedArtifacts = [];
  else r.includedArtifacts = r.includedArtifactsVm.split(",").trimArray();
  if (_.isEmpty(r.ruleArtifactsVm)) r.ruleArtifacts = [];
  else r.ruleArtifacts = r.ruleArtifactsVm.split(",").trimArray();
  if (_.isEmpty(r.excludedArtifactsVm)) r.excludedArtifacts = [];
  else r.excludedArtifacts = r.excludedArtifactsVm.split(",").trimArray();
  if (_.isEmpty(r.client)) r.client = "*";
  var eof = [...r.conditionals];
  r.runMode = r.stagingOnly ? "StagingOnly" : "All";
  r.conditionals = eof.map((f) => {
    var vals = f.valuesVm.split(",").trimArray();
    f.values = vals;
    f.runMode = f.stagingOnly ? "StagingOnly" : "All";
    return f;
  });
  r.includedDataValues.map((v) => {
    v.runMode = v.stagingOnly ? "StagingOnly" : "All";
    return v;
  });
  r.includedRejections.map((v) => {
    v.runMode = v.stagingOnly ? "StagingOnly" : "All";
    return v;
  });
  r.excludedRejections.map((v) => {
    v.runMode = v.stagingOnly ? "StagingOnly" : "All";
    return v;
  });
  r.priority = _.isEmpty(r.priority) ? 0 : _.toNumber(r.priority);
  return r;
}

// jon, 4/10/23: Give each array that can be filtered on the screen a unique id for use in operations that
//   used to use the index position.  But that does not work when filtered, so using GUID instead.
export function createRuleViewModel(rule) {
  let r = _.cloneDeep(rule);

  if (r.includedGroups.length > 0) r.groups = r.includedGroups.join(",");
  else r.groups = "*";
  if (r.excludedGroups.length > 0) r.notGroups = r.excludedGroups.join(",");
  else r.notGroups = "";

  r.artifactTags = r.includedTags.length === 0 ? "*" : r.includedTags.join(",");
  let artifacts = r.ruleArtifacts.concat(r.includedArtifacts);
  if (artifacts > 0) r.artifacts = artifacts.join(",");
  else r.artifacts = "*";
  if (r.excludedArtifacts.length > 0)
    r.notArtifacts = r.excludedArtifacts.join(",");
  else r.notArtifacts = "";

  r.includedGroupsVm = r.includedGroups.join(",");
  r.excludedGroupsVm = r.excludedGroups.join(",");
  r.includedTagsVm = r.includedTags.join(",");
  r.ruleArtifactsVm = r.ruleArtifacts.join(",");
  r.includedArtifactsVm = r.includedArtifacts.join(",");
  r.excludedArtifactsVm = r.excludedArtifacts.join(",");
  r.stagingOnly = r.runMode === "StagingOnly";

  r.conditionals.map((eof) => {
    eof.id = generateUUID();
    eof.effectiveDate = !_.isNull(eof.effectiveDate)
      ? new Date(eof.effectiveDate)
      : null;
    eof.terminationDate = !_.isNull(eof.terminationDate)
      ? new Date(eof.terminationDate)
      : null;
    eof.valuesVm = eof.values.join(",");
    eof.stagingOnly = eof.runMode === "StagingOnly";
    return eof;
  });

  r.includedDataValues.map((v) => {
    v.id = generateUUID();
    v.effectiveDate = !_.isNull(v.effectiveDate)
      ? new Date(v.effectiveDate)
      : null;
    v.terminationDate = !_.isNull(v.terminationDate)
      ? new Date(v.terminationDate)
      : null;
    v.stagingOnly = v.runMode === "StagingOnly";
    return v;
  });

  r.includedRejections.map((v) => {
    v.id = generateUUID();
    v.effectiveDate = !_.isNull(v.effectiveDate)
      ? new Date(v.effectiveDate)
      : null;
    v.terminationDate = !_.isNull(v.terminationDate)
      ? new Date(v.terminationDate)
      : null;
    v.stagingOnly = v.runMode === "StagingOnly";
    return v;
  });

  r.excludedRejections.map((v) => {
    v.id = generateUUID();
    v.effectiveDate = !_.isNull(v.effectiveDate)
      ? new Date(v.effectiveDate)
      : null;
    v.terminationDate = !_.isNull(v.terminationDate)
      ? new Date(v.terminationDate)
      : null;
    v.stagingOnly = v.runMode === "StagingOnly";
    return v;
  });

  r.priority = r.priority.toString();
  if (_.isNull(r.effectiveDate)) {
    r.effectiveDate = new Date("1/1/2000");
  } else {
    r.effectiveDate = new Date(r.effectiveDate);
  }
  if (_.isNull(r.terminationDate)) {
    r.terminationDate = new Date("1/1/3000");
  } else {
    r.terminationDate = new Date(r.terminationDate);
  }
  r.textSearch = "";
  return r;
}
