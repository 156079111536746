import React, { useEffect } from "react";
import { useWebSocketConnectionEvents } from "../../../contexts/WebSocketEventsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useDbDatabaseSync } from "../../../contexts/dbDatabaseSyncContext";

function DbSyncWebSocketHubConnections() {
  const { setDbDatabaseSyncData } = useDbDatabaseSync();
  const { webSocketConnectionData } = useWebSocketConnectionEvents();

  useEffect(() => {
    const vm = webSocketConnectionData.trafficDbUtilsStateUpdates;
    if (vm !== null) {
      const jsonTraffic = vm;
      setDbDatabaseSyncData({
        type: ContextProviderActions.loadDbDatabaseSyncUpdateState,
        payload: jsonTraffic,
      });
    }
  }, [webSocketConnectionData?.trafficDbUtilsStateUpdates]);

  return <></>;
}

export default DbSyncWebSocketHubConnections;
