/* eslint-disable no-undef */
import { getDropDownValue } from "../viewmodels/ruleTableDataVm";
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadRuleDataRow(id, token, signal) {
  let uri = API_URL + "/ruletablerow/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateRuleDataRow(params, token, signal) {
  const m = params.model;

  let uri = API_URL + "/ruletablerow" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteRuleDataRow(params, token, signal) {
  const id = params.id;
  const ruleTableId = params.ruleTableId;
  const isInherited = params.isInherited;

  let uri =
    API_URL + "/ruletablerow/" + id + "/" + ruleTableId + getQueryString();
  uri += `&isInherited=${isInherited === true}`;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiScheduleRuleDataRowChange(params, token, signal) {
  const m = params.model;

  let uri = API_URL + "/ruletablerowvaluechange" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiGetRuleDataRowsForDownload(params, token, signal) {
  const id = params.id;
  const search = params.search;

  let uri = API_URL + "/ruletableexport/" + id + getQueryString();

  // Handle export by group id - this filters to the group and considers inheritance.
  if (search.isGroupSearch && _.trim(search.groupId) !== "") {
    uri =
      API_URL +
      "/ruletableexportbygroup/" +
      id +
      "/" +
      search.groupId +
      getQueryString();
  }

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiImportRuleDataRows(params, token, signal) {
  const id = params.id;
  const search = params.search;
  const ruleDataImport = params.model;

  let uri = API_URL + "/ruletableimport/" + id + getQueryString();

  // Handle export by group id - this filters to the group and considers inheritance.
  if (search.isGroupSearch && _.trim(search.groupId) !== "") {
    uri =
      API_URL +
      "/ruletableimportbygroup/" +
      id +
      "/" +
      search.groupId +
      getQueryString();
  }

  // Add import mode and termination date (if needed) to querystring
  const importMode = getDropDownValue(ruleDataImport, "importMode");
  uri += "&importMode=" + importMode;

  if (importMode === "terminatechanges" && ruleDataImport.terminationDate) {
    // Remove ms from this date because the server does not see it as a valid date if it contains a .
    const terminationDate = ruleDataImport.terminationDate
      .toISOString()
      .replace(".000Z", "Z");
    uri += "&terminationDate=" + terminationDate;
  }

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: ruleDataImport.fileContents,
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadRuleDataRow.funcName = "apiLoadRuleDataRow";
apiUpdateRuleDataRow.funcName = "apiUpdateRuleDataRow";
apiDeleteRuleDataRow.funcName = "apiDeleteRuleDataRow";
apiScheduleRuleDataRowChange.funcName = "apiScheduleRuleDataRowChange";
apiGetRuleDataRowsForDownload.funcName = "apiGetRuleDataRowsForDownload";
apiImportRuleDataRows.funcName = "apiImportRuleDataRows";
