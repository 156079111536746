import React from "react";
import _ from "lodash";
import {
  StyledCard,
  StyledDescription,
  StyledReturnButton,
  StyledRuleLink,
} from "./RuleGroupSummaryCommonStyles";
import styled from "styled-components";

function RuleGroupSummaryProperty({
  item,
  showValue,
  showRuleLink,
  linkedProperty,
  onClickReturn,
  isAdmin,
}) {
  function convertRuleTableLookupsToLinks(value) {
    if (_.isEmpty(value)) return "";

    // Convert (+\\714e0fbd-0f03-4625-b0cc-6506281cc6c4\116 more values\) to (+<a href="714e0fbd-0f03-4625-b0cc-6506281cc6c4">116 more values</a>).
    //  Only convert to link if user is admin.
    const pos = value.indexOf("\\\\");
    if (pos >= 0) {
      const pos2 = value.indexOf("\\", pos + 2);
      const pos3 = value.indexOf("\\", pos2 + 1);

      if (pos2 >= 0 && pos3 >= 0) {
        return isAdmin ? (
          <>
            {value.substring(0, pos)}
            <a
              target="ruletable"
              className="link"
              href={`/ruletable/${value.substring(pos + 2, pos2)}`}
            >
              {value.substring(pos2 + 1, pos3)}
            </a>
            {value.substring(pos3 + 1)}
          </>
        ) : (
          <>
            {value.substring(0, pos)}
            {value.substring(pos2 + 1, pos3)}
            {value.substring(pos3 + 1)}
          </>
        );
      }
    }

    return <>{value}</>;
  }

  function addSpaceToLookupsToForceWrapping(value) {
    if (_.isEmpty(value)) return "";

    //If lookup, add space after //lookupname::bydate and before next /
    const pos = value.indexOf("//");
    if (pos >= 0 && value.length > pos + 2) {
      const pos2 = value.indexOf("/", pos + 2);

      if (pos2 >= 0) {
        return value.substring(0, pos2) + " " + value.substring(pos2);
      }
    }

    return value;
  }

  function handleSpecialPropertyFormatting(value) {
    let val = value;
    val = addSpaceToLookupsToForceWrapping(val);
    val = convertRuleTableLookupsToLinks(val);
    return val;
  }

  const id = _.lowerCase(`prop-${item.name}`);
  const matchLink = !_.isEmpty(linkedProperty) && id === linkedProperty;

  return (
    <StyledCard
      className={matchLink ? "selected pulse-fade-subtle" : ""}
      id={id}
    >
      {matchLink && (
        <StyledReturnButton title="Return" onClick={onClickReturn}>
          <i className="fa fa-angle-left"></i>
        </StyledReturnButton>
      )}
      <StyledDescription className="flex-row-with-wrap-and-justify">
        <span>{item.name}</span>
        <span
          className="charblock"
          style={{ fontSize: "16px", padding: "2px 4px" }}
        >
          {item.valueTypeDisplay}
        </span>
      </StyledDescription>
      {showRuleLink && (
        <StyledRuleLink>
          <a target="rule" className="link" href={`/rule/${item.ruleId}`}>
            {item.ruleName}
          </a>
        </StyledRuleLink>
      )}
      {showValue && (
        <>
          <StyledPropertyValueDiv>
            {_.isEmpty(item.value) ? (
              <i style={{ color: "var(--text-normal)" }}>No value</i>
            ) : (
              handleSpecialPropertyFormatting(item.value)
            )}
          </StyledPropertyValueDiv>
        </>
      )}
    </StyledCard>
  );
}

const StyledPropertyValueDiv = styled.div`
  padding: 20px 20px 0 0;
  color: var(--text-dark);
  font-family: "Courier New", Courier, monospace;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

export default RuleGroupSummaryProperty;
