import _ from "lodash";
import { pageSize } from "../constants/Environments";

export const emptyAuditRecord = {
  id: "",
  documentId: "",
  auditDocumentSize: 0,
  transactionDate: "",
  userName: "",
  entityType: "",
  entityKey: "",
  bulkChange: false,
  modifiedIds: [],
  deletedIds: [],
  numChanges: 0,
};

export const emptyAuditRecordsSearch = {
  id: "",
  documentId: "",
  userName: "",
  entityType: "",
  entityKey: "",
  bulkChangeOnly: false,
  startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  endDate: new Date("1/1/3000"),
  dateRange: "Past24hours",
  orderBy: ["transactionDate-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  auditRecordsCount: 0,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export const emptyAuditBulkChangeRecord = {
  id: "",
  documentId: "",
  entityType: "",
  entityKey: "",
  modifiedIds: [],
  deletedIds: [],
};

export const emptyAuditBulkChangeRecordsSearch = {
  orderBy: ["id+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  auditRecordsCount: 0,
  highlightText: "",
};

export const emptyAuditDocument = {
  id: "",
  entityType: "",
  entityKey: "",
  transactionHistory: [],
};

export const emptyAuditDocumentSearch = {
  highlightText: "",
};

export const emptyAuditDocumentChange = {
  transactionId: "",
  transactionDate: "",
  transactionUser: "",
  model: "",
  showJson: true,
  propertiesChanged: [],
};

export function createViewModel(m) {
  let vm = _.cloneDeep(m);
  const numModified = (vm.modifiedIds || []).length;
  const numDeleted = (vm.deletedIds || []).length;
  vm.numChanges = numModified + numDeleted;

  return vm;
}

export function createBulkChangeViewModel(m) {
  let vm = _.cloneDeep(m);
  return vm;
}

export function createDocumentChangeViewModel(m) {
  let vm = _.cloneDeep(m);

  const _changes = [];
  vm.transactionHistory.forEach((change) =>
    _changes.push({ ...emptyAuditDocumentChange, ...change })
  );
  vm.transactionHistory = _changes;

  vm.entityKey =
    (vm.transactionHistory || []).length > 0
      ? vm.transactionHistory[0].entityKey
      : "";
  vm.entityType =
    (vm.transactionHistory || []).length > 0
      ? vm.transactionHistory[0].entityType
      : "";

  return vm;
}
