/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";
import _ from "lodash";
import { unZero } from "../services/General";

export function apiLoadClaim(id, token, signal) {
  const uri = API_URL + "/claim/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadClaimSimple(id, token, signal) {
  const uri = API_URL + "/claimsimple/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadPacket(params, token, signal) {
  const id = params.id;
  const claimRequest = params.claimRequest;

  const uri = API_URL + "/packet/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(claimRequest),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadClaims(search, token, signal) {
  const uri = API_URL + "/claims" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "id", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];

    if (!isNaN(search.freeFormSearch)) {
      where[where.length - 1].subQueryItems.push({
        operand: "OR",
        name: "prescriptionRefNumber",
        operator: "=",
        values: [unZero(search.freeFormSearch)],
      });
    }
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "memberId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "productId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "authorizationNumber",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  // Search by Status support
  if (search && search.friendlyStatus && search.friendlyStatus.length > 0) {
    //If unversed paid is included in the filter, then only use this status filter
    let unreversedPaid = false;
    search.friendlyStatus.forEach((status) => {
      if (status.label.toLowerCase() === "unreversed paid") {
        unreversedPaid = true;
      }
    });
    if (unreversedPaid) {
      where.push({ name: "status", operator: "notnull" });
      where[where.length - 1].subQueryItems = [];
      where[where.length - 1].subQueryItems.push({
        name: "status",
        operator: "exact",
        values: ["Paid"],
      });
      where[where.length - 1].subQueryItems.push({
        name: "isReversed",
        operator: "false",
      });
      search.friendlyStatus = search.friendlyStatus.filter(
        (fs) =>
          fs.label.toLowerCase() !== "paid" &&
          fs.label.toLowerCase() !== "reversed"
      );
    }
    search.friendlyStatus.forEach((status, i) => {
      if (status.label.toLowerCase() !== "unreversed paid") {
        where.push({
          operand: "OR",
          name: "status",
          operator: "=",
          values: [status.value],
        });
        if (unreversedPaid) where[where.length - 2].operand = "OR"; //Any other statuses will cause the status added by unreversed paid to be ORed as well
      }
    });
  }

  // Search by Diff Status support
  if (search && search.diffStatus && search.diffStatus.length > 0) {
    search.diffStatus.forEach((status, i) => {
      where.push({
        operand: "OR",
        name: "compareStatus",
        operator: "=",
        values: [status.value],
      });
    });
  }

  if (search && search.startDate && _.isDate(search.startDate))
    where.push({
      name: "processedDate",
      operator: ">=",
      values: [search.startDate],
    });
  if (search && search.endDate && _.isDate(search.endDate))
    where.push({
      name: "processedDate",
      operator: "<",
      values: [search.endDate],
    });

  if (search && search.memberId && search.memberId.length > 0) {
    where.push({
      name: "memberId",
      operator: "=",
      values: [search.memberId],
    });
  }
  if (search && search.pharmacy && search.pharmacy.length > 0) {
    where.push({ name: "serviceProviderId", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "serviceProviderId",
      operator: "=",
      values: [search.pharmacy],
    });
    where[where.length - 1].subQueryItems.push({
      name: "pharmacyNcpdp",
      operator: "=",
      values: [search.pharmacy],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "serviceProviderName",
      operator: "=",
      values: [search.pharmacy],
    });
  }
  if (search && search.authNumber && search.authNumber.length > 0)
    where.push({
      name: "authorizationNumber",
      operator: "=",
      values: [search.authNumber],
    });

  if (
    search &&
    search.prescriptionRefNumber &&
    search.prescriptionRefNumber.length > 0
  )
    where.push({
      name: "prescriptionRefNumber",
      operator: "=",
      values: [search.prescriptionRefNumber],
    });

  if (search && search.groupId && search.groupId.length > 0) {
    where.push({ name: "groupId", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "groupId",
      operator: "=",
      values: [search.groupId],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "groupName",
      operator: "=",
      values: [search.groupId],
    });
  }

  if (search && search.drugId && search.drugId.length > 0) {
    where.push({ name: "drugId", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "productId",
      operator: "=",
      values: [search.drugId],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "productName",
      operator: "=",
      values: [search.drugId],
    });
  }

  if (search && search.message && search.message.length > 0)
    where.push({
      name: "returnMessage",
      operator: "=",
      values: [search.message],
    });

  if (search && search.rejectionCode && search.rejectionCode.length > 0)
    where.push({
      name: "rejectionCodes",
      operator: "=",
      values: [search.rejectionCode],
    });

  const clientFiltering = search.friendlyStatus.length > 0;
  const data = { where, orderBy: search.orderBy };
  const pageSize = clientFiltering ? 100000 : search.pageSize || 10;
  const page = clientFiltering ? 1 : search.pageNumber;

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiTransformClaimsWithTemplate(search, token, signal) {
  const uri =
    API_URL +
    "/transformclaims/" +
    encodeURIComponent(search.template) +
    getQueryString();
  let where = [];

  if (search && search.startDate && _.isDate(search.startDate))
    where.push({
      name: "processedDate",
      operator: ">=",
      values: [search.startDate],
    });
  if (search && search.endDate && _.isDate(search.endDate))
    where.push({
      name: "processedDate",
      operator: "<",
      values: [search.endDate],
    });

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber;

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiReverseClaim(params, token, signal) {
  const claimId = params.claimId;
  let uri = API_URL + "/reversalclaim/" + claimId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadClaim.funcName = "apiLoadClaim";
apiLoadClaimSimple.funcName = "apiLoadClaimSimple";
apiLoadPacket.funcName = "apiLoadPacket";
apiLoadClaims.funcName = "apiLoadClaims";
apiTransformClaimsWithTemplate.funcName = "apiTransformClaimsWithTemplate";
apiReverseClaim.funcName = "apiReverseClaim";
