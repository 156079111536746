import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useDiagnostics } from "../../../contexts/DiagnosticsContext";
import DiagnosticsList from "./DiagnosticsList";
import DiagnosticsSearchForm from "./DiagnosticsSearchForm";
import Spinner from "../../common/ui/Spinner";

function HomeDiagnostics({ loadDiagnostics, loading }) {
  const { diagnosticData, setDiagnosticData } = useDiagnostics();
  const [errors, setErrors] = useState({});
  const [loadingDiagnostics, setLoadingDiagnostics] = useState(false);

  useEffect(() => {
    if (!loading) {
      setLoadingDiagnostics(false);
    }
  }, [loading]);

  function getAverageProcessedTime(diagnostics) {
    let processedMilliseconds = 0;
    _.forEach(diagnostics, function (d) {
      processedMilliseconds += d.processedMilliseconds;
    });
    processedMilliseconds = processedMilliseconds / diagnostics.length;
    return processedMilliseconds.toFixed(2);
  }

  function getClaimCount(diagnostics) {
    let claimCount = 0;
    _.forEach(diagnostics, function (d) {
      claimCount += d.claimCount;
    });
    return claimCount.toFixed(0);
  }

  function getMostRecentAverage(diagnostics) {
    if (diagnostics.length < 2) return 0.0;
    let processedMilliseconds = 0;
    processedMilliseconds = diagnostics[1].processedMilliseconds;
    return processedMilliseconds.toFixed(2);
  }

  let averageProcessedTime = 0;
  let mostRecentAverage = 0;
  let totalCount = 0;

  let diagnostics = [];
  if (diagnosticData && diagnosticData.diagnostics) {
    diagnostics = diagnosticData.diagnostics;
  }

  if (diagnostics.length > 0) {
    averageProcessedTime = getAverageProcessedTime(diagnostics);
    mostRecentAverage = getMostRecentAverage(diagnostics);
    totalCount = getClaimCount(diagnostics);
  }

  function setSearchChanges(search) {
    setDiagnosticData({
      type: ContextProviderActions.saveDiagnosticSearch,
      payload: search,
    });
  }

  async function handleReset() {
    var oneHourAgo = new Date();
    oneHourAgo.setHours(oneHourAgo.getHours() - 1);
    setSearchChanges({
      ...diagnosticData.search,
      startDate: oneHourAgo,
      endDate: new Date("1/1/3000"),
      sliceSeconds: 600,
    });
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;
    setLoadingDiagnostics(true);
    await loadDiagnostics();
  }

  async function onSubmit(event) {
    var updatedSearch = { ...diagnosticData.search, pageNumber: 1 };
    setSearchChanges(updatedSearch);
    await handleSearch(event);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...diagnosticData.search,
      [target.name]: target.value,
    });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...diagnosticData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...diagnosticData.search, endDate: date });
  }

  return (
    <StyledSection data-testid="home-diagnostics-section">
      <DiagnosticsSearchForm
        errors={errors}
        search={diagnosticData.search}
        onStartDateChange={handleStartDateChange}
        onEndDateChange={handleEndDateChange}
        onSearch={onSubmit}
        onReset={handleReset}
        onChange={handleSearchChange}
      />
      {loadingDiagnostics ? (
        <Spinner />
      ) : (
        <DiagnosticsList
          mostRecentAverage={mostRecentAverage}
          averageProcessedTime={averageProcessedTime}
          totalCount={totalCount}
          diagnostics={diagnostics.slice(1)}
        />
      )}
    </StyledSection>
  );
}

const StyledSection = styled.div`
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  margin-top: 14px;
  padding: 16px 20px;
`;

export default HomeDiagnostics;
