import React, { useEffect, useLayoutEffect, useState } from "react";
import { jsonToArray } from "../services/General";
import { usePacketTraffic } from "../contexts/PacketTrafficContext";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { apiGetPacketsTraffic } from "../api/HomeApi";
import { useAuth } from "../contexts/AuthContext";
import useApi from "../hooks/useApi";
import ReactTooltip from "react-tooltip";
import { useWebSocketConnectionEvents } from "../contexts/WebSocketEventsContext";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { apiGetSignalRServiceInfo } from "../api/signalrApi";
import { notifyError } from "../services/NotificationService";

function WebSocketHubConnectionLoader() {
  const { auth } = useAuth();

  const { api: apiLoadPacketsTraffic } = useApi(apiGetPacketsTraffic);
  const { setPacketsTraffic } = usePacketTraffic();
  const { webSocketConnectionData, setWebSocketConnectionData } =
    useWebSocketConnectionEvents();
  const { api: apiLoadSignalRServiceInfo } = useApi(apiGetSignalRServiceInfo);
  const [hubDefaultConnection, setHubDefaultConnection] = useState();
  const [hubClaimsHistoryConnection, setHubClaimsHistoryConnection] =
    useState();
  const [hubDiagnosticsConnection, setHubDiagnosticsConnection] = useState();

  useEffect(() => {
    const vm = webSocketConnectionData.trafficDiagnostics;
    if (vm !== null) {
      setPacketsTraffic({
        type: ContextProviderActions.loadTrafficPackets,
        payload: vm,
      });
    }
  }, [webSocketConnectionData?.trafficDiagnostics]);

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    if (auth.authenticated && auth.isAdmin) {
      apiLoadPacketsTraffic.call(null, (result) => {
        setPacketsTraffic({
          type: ContextProviderActions.loadTrafficPackets,
          payload: result,
        });
      });
    }

    if (auth.authenticated) {
      configureWebSocketHubConnections();
    }
  }, [auth.authenticated]);

  useEffect(() => {
    return () => {
      // Stop hub connections when user leaves the page
      if (hubDefaultConnection) hubDefaultConnection.stop();
      if (hubClaimsHistoryConnection) hubClaimsHistoryConnection.stop();
      if (hubDiagnosticsConnection) hubDiagnosticsConnection.stop();
    };
  }, []);

  async function configureWebSocketHubConnections() {
    await setUpDefaultHubConnectionAndEvents();
    await setUpClaimsHistoryHubConnectionAndEvents();
    await setUpDiagnosticsHubConnectionAndEvents();
  }

  const getAzureSocketConnection = async (hubName, setHubConnection) => {
    // This calls api/negotiate to get the url and access token for the Azure SignalR Service. We then use that to build our hub connection.
    return await apiLoadSignalRServiceInfo.call(hubName, (result) => {
      const options = {
        accessTokenFactory: () => result.accessToken,
      };

      const _connect = new HubConnectionBuilder()
        .withUrl(result.url, options)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build(HttpTransportType.None);

      setHubConnection(_connect);
      return _connect;
    });
  };

  const setUpDiagnosticsHubConnectionAndEvents = async () => {
    const hubName = "trafficdbdiagnosticshub";
    const connection = await getAzureSocketConnection(
      hubName,
      setHubDiagnosticsConnection
    );

    if (!connection) {
      notifyError(
        `Connection not set in setUpDiagnosticsHubConnectionAndEvents for hub ${hubName}.`
      );
      return;
    }

    console.log(
      `Subscribing to event trafficDiagnosticsUpdate on hub ${hubName}`
    );
    connection.on("trafficDiagnosticsUpdate", (message) => {
      const vm = jsonToArray(message);
      setWebSocketConnectionData({
        type: ContextProviderActions.setWebSocketDiagnosticsEventState,
        payload: vm,
      });
    });

    connection
      .start()
      .then(() => {
        console.log(`Connected to web socket hub ${hubName}`);
      })
      .catch((error) => {
        const msg = `An error occurred connecting to the websocket hub ${hubName}: ${error}`;
        console.log(msg);
        notifyError(msg);
      });
  };

  const setUpClaimsHistoryHubConnectionAndEvents = async () => {
    const hubName = "trafficdbclaimshistoryhub";
    const connection = await getAzureSocketConnection(
      hubName,
      setHubClaimsHistoryConnection
    );

    if (!connection) {
      notifyError(
        `Connection not set in setUpClaimsHistoryHubConnectionAndEvents for hub ${hubName}.`
      );
      return;
    }

    console.log(`Subscribing to event trafficClaimsUpdate on hub ${hubName}`);
    connection.on("trafficClaimsUpdate", (message) => {
      const vm = jsonToArray(message);
      setWebSocketConnectionData({
        type: ContextProviderActions.setWebSocketClaimsTrafficEventState,
        payload: vm,
      });
    });

    connection
      .start()
      .then(() => {
        console.log(`Connected to web socket hub ${hubName}`);
      })
      .catch((error) => {
        const msg = `An error occurred connecting to the websocket hub ${hubName}: ${error}`;
        console.log(msg);
        notifyError(msg);
      });
  };

  const setUpDefaultHubConnectionAndEvents = async () => {
    const hubName = "trafficdbdefaulthub";
    const connection = await getAzureSocketConnection(
      hubName,
      setHubDefaultConnection
    );

    if (!connection) {
      notifyError(
        `Connection not set in setUpDefaultHubConnectionAndEvents for hub ${hubName}.`
      );
      return;
    }

    console.log(
      `Subscribing to event trafficDbUtilsStateUpdate on hub ${hubName}`
    );
    connection.on("trafficDbUtilsStateUpdate", (message) => {
      const vm = JSON.parse(message);
      setWebSocketConnectionData({
        type: ContextProviderActions.setWebSocketDbUtilsEventState,
        payload: vm,
      });
    });

    console.log(
      `Subscribing to event trafficTestDataPopulationStateUpdate on hub ${hubName}`
    );
    connection.on("trafficTestDataPopulationStateUpdate", (message) => {
      const vm = JSON.parse(message);
      setWebSocketConnectionData({
        type: ContextProviderActions.setWebSocketTestDataPopulationEventState,
        payload: vm,
      });
    });

    console.log(
      `Subscribing to event trafficTestRunnerStateUpdate on hub ${hubName}`
    );
    connection.on("trafficTestRunnerStateUpdate", (message) => {
      const vm = JSON.parse(message);
      setWebSocketConnectionData({
        type: ContextProviderActions.setWebSocketTestRunnerEventState,
        payload: vm,
      });
    });

    connection
      .start()
      .then(() => {
        console.log(`Connected to web socket hub ${hubName}`);
      })
      .catch((error) => {
        const msg = `An error occurred connecting to the websocket hub ${hubName}: ${error}`;
        console.log(msg);
        notifyError(msg);
      });
  };

  return <></>;
}

export default WebSocketHubConnectionLoader;
