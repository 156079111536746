import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createDrViewModel,
  createViewModel,
  discountModes,
  discountTypes,
  emptyDiscount,
  fromViewModel,
} from "../../../viewmodels/discountsVm";
import DatePickerInput from "../../common/input/DatePickerInput";
import SelectInput from "../../common/input/SingleSelect";
import DiscountRedemptionsList from "./DiscountRedemptionsList";
import { useNavigate, useParams } from "react-router-dom";
import CheckboxInput from "../../common/input/CheckboxInput";
import { useAuth } from "../../../contexts/AuthContext";
import { useDiscounts } from "../../../contexts/DiscountsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import {
  apiAddDiscount,
  apiLoadDiscount,
  apiLoadDiscountRedemptions,
  apiUpdateDiscount,
} from "../../../api/DiscountApi";
import Authorize from "../../common/layout/Authorize";
import {
  disableAnimations,
  formatDecimal,
  handleCollapseExpandAll,
} from "../../../services/General";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import { Button, Modal } from "react-bootstrap";
import ReadOnly from "../../common/input/ReadOnly";
import HelpLink from "../../common/ui/HelpLink";
import styled from "styled-components";

function Discount() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { discountsData, setDiscountsData } = useDiscounts();
  const { loading, api: apiLoad } = useApi(apiLoadDiscount);
  const { loading: loadingRedemptions, api: apiLoadRedemptions } = useApi(
    apiLoadDiscountRedemptions
  );
  const { loading: adding, api: apiAdd } = useApi(apiAddDiscount);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateDiscount);
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyDiscount);

  const [showGeneratedMemberIdsDialog, setShowGeneratedMemberIdsDialog] =
    useState(false);

  const [collapsedState, setCollapsedState] = useState([
    { name: "Discount", collapsed: false },
    { name: "Discount Usage", collapsed: false },
    { name: "Effective Range", collapsed: true },
    { name: "Discount Targets", collapsed: true },
    { name: "Redemptions", collapsed: true },
  ]);

  // const claimId = params && params.claimid;
  const resId = params && params.id;

  let discountRedemptions = [];
  if (discountsData && discountsData.discountRedemptions) {
    discountRedemptions = discountsData.discountRedemptions;
  }

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setDiscountsData({
        type: ContextProviderActions.loadDiscount,
        payload: emptyDiscount,
      });

      loadDiscount();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (discountsData.discount) {
      setChanges(discountsData.discount);
    } else {
      setChanges(emptyDiscount);
    }
  }, [discountsData.discount]);

  async function loadDiscount() {
    if (!resId) {
      setDiscountsData({
        type: ContextProviderActions.loadDiscount,
        payload: emptyDiscount,
      });
      setChanges(emptyDiscount);
      return;
    }

    await apiLoad.call(resId, async (result) => {
      const model = createViewModel(result);
      const vm = { ...emptyDiscount, ...model };

      // Load redemptions for this discount
      const discountRedemptions = await loadRedemptions(discountsData.search);

      setDiscountsData({
        type: ContextProviderActions.loadDiscount,
        payload: {
          discount: vm,
          discountRedemptions: discountRedemptions.resources,
          countDiscountRedemptions: discountRedemptions.count,
        },
      });
      setChanges(vm);
    });
  }

  async function loadRedemptions(search) {
    var newSearch = { ...search, selectedDiscountId: resId };

    return await apiLoadRedemptions.call(newSearch, (result) => {
      let discountRedemptions = result.resources.map((r) =>
        createDrViewModel(r)
      );

      return { resources: discountRedemptions, count: result.count };
    });
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    // if (
    //   _.has(changes, "generateMemberIds") &&
    //   !isPositiveInteger(changes.generateMemberIds)
    // )
    //   _errors.generateMemberIds =
    //     "Generate Member Ids must be a positive integer";
    if (_.has(changes, "maximumPaid") && isNaN(changes.maximumPaid))
      _errors.maximumPaid = "Maximum Paid must be a valid number";
    if (_.has(changes, "discountAmount") && isNaN(changes.discountAmount))
      _errors.discountAmount = "Discount Amount must be a valid number";
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleEffectiveDateChange(date) {
    setChanges({ ...changes, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  function handleTypeChange(val) {
    const discountType = val;
    setChanges({ ...changes, discountTypeVm: discountType });
  }

  function handleModeChange(val) {
    const discountMode = val;
    setChanges({ ...changes, discountModeVm: discountMode });
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;
    const newVm = updated ? updated : { ...changes };

    // See if user is generating member ids. We set this in state because addedMemberIds always comes back with
    //  a member id it appears, even when not generated.
    const generatingMemberIds =
      newVm.generateMemberIds !== 0 &&
      newVm.generateMemberIds !== null &&
      newVm.generateMemberIds !== undefined;

    if (!resId) {
      addDiscount(newVm, generatingMemberIds);
    } else updateDiscount(newVm.id, newVm, generatingMemberIds);
  }

  async function addDiscount(vm, generatingMemberIds) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      checkAndHandleGeneratedMemberIds(result, generatingMemberIds);
      setChanges(createViewModel(result));
      notifySuccess("Discount saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/discount/" + result.id, { replace: true });
    });
  }

  async function updateDiscount(id, vm, generatingMemberIds) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      checkAndHandleGeneratedMemberIds(result, generatingMemberIds);
      setChanges(createViewModel(result));
      notifySuccess("Discount saved successfully");
    });
  }

  function checkAndHandleGeneratedMemberIds(result, generatingMemberIds) {
    // If we generated member ids, show them in a dialog.
    if (generatingMemberIds && (result.addedMemberIds || []).length > 0) {
      setShowGeneratedMemberIdsDialog(true);

      //Reset after save so more don't get generated on subsequent saves
      result.generateMemberIds = 0;
    }
  }

  function setSearchChanges(search) {
    setDiscountsData({
      type: ContextProviderActions.saveDiscountSearch,
      payload: search,
    });
  }

  async function handleRedemptionsLoad(event, updatedSearch) {
    if (event) event.preventDefault();

    const newSearch = updatedSearch
      ? updatedSearch
      : { ...discountsData.search };
    const redemptions = await loadRedemptions(newSearch);

    setDiscountsData({
      type: ContextProviderActions.loadDiscountRedemptions,
      payload: {
        discountRedemptions: redemptions.resources,
        countDiscountRedemptions: redemptions.count,
      },
    });
  }

  async function reloadRedemptions(event) {
    await handleRedemptionsLoad(event);
  }

  async function handleRedemptionSort(event) {
    var indexAsc = discountsData.search.drOrderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = discountsData.search.drOrderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...discountsData.search,
        drOrderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...discountsData.search,
        drOrderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...discountsData.search, drOrderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleRedemptionsLoad(event, updatedSearch);
    }
  }

  function handleUnreversedChange({ target }) {
    setSearchChanges({ ...discountsData.search, unreversed: target.checked });
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return To Discounts"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Configure/Discount-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            Discount Details{" "}
            {changes.discountPlan ? ` | ${changes.discountPlan}` : ""}
          </h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate(-1)}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {loading || adding || updating ? (
          <Spinner />
        ) : (
          <>
            <Modal
              show={showGeneratedMemberIdsDialog}
              onHide={() => {}}
              animation={!disableAnimations()}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Generated Member Ids
                  <HelpLink
                    path="/Configure/Discount-Screen&anchor=generated-member-ids-dialog"
                    label=""
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <ReadOnly
                    id="addedMemberIds"
                    label="The following Member Ids were generated:"
                    value={changes.addedMemberIds.join(",")}
                    copyToClipboard={true}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowGeneratedMemberIdsDialog(false);
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/discount/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Discount"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Discount-Screen&anchor=discount"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="discountPlan"
                      label="Discount Plan"
                      onChange={handleChange}
                      placeholder="Discount Plan"
                      name="discountPlan"
                      value={changes.discountPlan}
                      error={errors.discountPlan}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="discountAmount"
                      label="Discount Amount"
                      onChange={handleChange}
                      placeholder="Discount Amount"
                      name="discountAmount"
                      value={changes.discountAmount}
                      error={errors.discountAmount}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <SelectInput
                      id="discountType"
                      name="discountType"
                      label="Discount Type"
                      options={discountTypes}
                      value={changes.discountTypeVm}
                      onChange={handleTypeChange}
                      placeholder="Select a Type"
                      error={errors.discountType}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <SelectInput
                      id="discountMode"
                      name="discountMode"
                      label="Discount Mode"
                      options={discountModes}
                      value={changes.discountModeVm}
                      onChange={handleModeChange}
                      placeholder="Select a Mode"
                      error={errors.discountMode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="groupId"
                      label="Group"
                      onChange={handleChange}
                      placeholder="Group Id"
                      name="groupId"
                      value={changes.groupId}
                      error={errors.groupId}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="gneerateMemberIds"
                      label="Generate Member Id Count"
                      onChange={handleChange}
                      placeholder="Id Count"
                      name="generateMemberIds"
                      value={changes.generateMemberIds}
                      error={errors.generateMemberIds}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="memberId"
                      label="Member Id"
                      onChange={handleChange}
                      placeholder="Member Id"
                      name="memberId"
                      value={changes.memberId}
                      error={errors.memberId}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="memberFirstName"
                      label="Member First Name"
                      onChange={handleChange}
                      placeholder="Member First Name"
                      name="memberFirstName"
                      value={changes.memberFirstName}
                      error={errors.memberFirstName}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="memberLastName"
                      label="Member Last Name"
                      onChange={handleChange}
                      placeholder="Member Last Name"
                      name="memberLastName"
                      value={changes.memberLastName}
                      error={errors.memberLastName}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="memberDateOfBirth"
                      label="Member DOB"
                      onChange={handleChange}
                      placeholder="Member DOB"
                      name="memberDateOfBirth"
                      value={changes.memberDateOfBirth}
                      error={errors.memberDateOfBirth}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="metricStrengthPerUnit"
                      label="Metric Strength Per Unit"
                      onChange={handleChange}
                      placeholder="Met. Str/Unit"
                      name="metricStrengthPerUnit"
                      value={changes.metricStrengthPerUnit}
                      error={errors.metricStrengthPerUnit}
                      isNumber={true}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Discount Usage"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Discount-Screen&anchor=discount-usage-section"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="maxCount"
                      label="Max Count"
                      onChange={handleChange}
                      placeholder="Max Count"
                      name="maxCount"
                      value={changes.maxCount}
                      error={errors.maxCount}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <StyledReadonlyWrapper>
                      <ReadOnly
                        label="Current Count"
                        value={formatDecimal(changes.currentCount, 0, "-")}
                        copyToClipboard={true}
                      />
                    </StyledReadonlyWrapper>
                  </div>
                </StyledRowDiv>
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="maximumPaid"
                      label="Maximum Paid"
                      onChange={handleChange}
                      placeholder="Maximum Paid"
                      name="maximumPaid"
                      value={changes.maximumPaid}
                      error={errors.maximumPaid}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <StyledReadonlyWrapper>
                      <ReadOnly
                        label="Current Paid"
                        value={formatDecimal(changes.currentPaid, 2, "-")}
                        copyToClipboard={true}
                      />
                    </StyledReadonlyWrapper>
                  </div>
                </StyledRowDiv>
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="maximumQuantity"
                      label="Maximum Quantity"
                      onChange={handleChange}
                      placeholder="Max Qty"
                      name="maximumQuantity"
                      value={changes.maximumQuantity}
                      error={errors.maximumQuantity}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <StyledReadonlyWrapper>
                      <ReadOnly
                        label="Current Quantity"
                        value={formatDecimal(changes.currentQty, 3, "-")}
                        copyToClipboard={true}
                      />
                    </StyledReadonlyWrapper>
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Effective Range"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Discount-Screen&anchor=effective-range"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="effectiveDate"
                      name="effectiveDate"
                      label="Effective Date"
                      value={changes.effectiveDate}
                      placeholder="Effective Date"
                      onChange={handleEffectiveDateChange}
                      error={errors.effectiveDate}
                      showTimeInput={false}
                    />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="terminationDate"
                      label="Termination Date"
                      name="terminationDate"
                      value={changes.terminationDate}
                      placeholder="Termination Date"
                      onChange={handleTerminationDateChange}
                      error={errors.terminationDate}
                      showTimeInput={false}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Discount Targets"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Discount-Screen&anchor=discount-targets"
              >
                <StyledRowDiv className="row">
                  <div className="col-12">
                    <TextInput
                      id="pharmaciesVm"
                      label="Pharmacies"
                      onChange={handleChange}
                      placeholder="Pharmacy NPIs"
                      name="pharmaciesVm"
                      value={changes.pharmaciesVm || ""}
                      error={errors.pharmaciesVm}
                    />
                  </div>
                  <div className="col-12">
                    <TextInput
                      id="ndcsVm"
                      label="Drug NDCs"
                      onChange={handleChange}
                      placeholder="Drug NDCs"
                      name="ndcsVm"
                      value={changes.ndcsVm || ""}
                      error={errors.ndcsVm}
                    />
                  </div>
                  <div className="col-12">
                    <TextInput
                      id="prescribersVm"
                      label="Prescribers"
                      onChange={handleChange}
                      placeholder="Prescribers"
                      name="prescribersVm"
                      value={changes.prescribersVm || ""}
                      error={errors.prescribersVm}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Redemptions"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Discount-Screen&anchor=redemptions"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginTop: "-11px" }}>
                    <CheckboxInput
                      id="unreversed"
                      label="Show Unreversed"
                      onChange={handleUnreversedChange}
                      placeholder="Unreversed"
                      name="unreversed"
                      checked={discountsData.search.unreversed}
                      error={errors.unreversed}
                      showLabelInline={true}
                    />
                  </div>
                  <button
                    onClick={reloadRedemptions}
                    type="button"
                    className="btn btn-secondary"
                    style={{ marginLeft: "auto" }}
                  >
                    Reload Redemptions
                  </button>
                </div>

                <br />
                {loadingRedemptions ? (
                  <Spinner
                    spinnerStyle={{ height: "200px", lineHeight: "200px" }}
                  />
                ) : (
                  <div
                    className="container-fluid"
                    style={{ marginTop: "-10px" }}
                  >
                    {resId && (
                      <>
                        <div className="row">
                          <div className="col-12">
                            <DiscountRedemptionsList
                              discountRedemptions={discountRedemptions}
                              search={discountsData.search}
                              setSearch={async (search) => {
                                setSearchChanges(search);
                                await handleRedemptionsLoad(undefined, search);
                              }}
                              onSort={handleRedemptionSort}
                              totalRecords={
                                discountsData.countDiscountRedemptions
                              }
                              fromDiscountsPage={false}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

const StyledReadonlyWrapper = styled.div`
  div.field {
    padding: 10px;
    line-height: 16px;

    span {
      display: inline-block;
      min-width: 60px;
    }

    i {
      font-size: 16px;
    }
  }
`;
export default Discount;
