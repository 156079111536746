import React, { Fragment } from "react";
import styled from "styled-components";
import {
  formatDateTimeUtcWithMilliseconds,
  getFormattedJson,
  getHighlightedText,
  getHighlightedTextForPropertiesArray,
} from "../../../services/General";
import _ from "lodash";
import { useMobile } from "../../../hooks/useMobile";
import { StyledNoResultsDiv } from "../../common/layout/CommonStyledControls";
import LogLevelPill from "./LogLevelPill";
import { getLogLevelRowStyle } from "../../../viewmodels/logsVm";

export const logTypeDef = [
  { label: "Trace", value: "Trace" },
  { label: "Debug", value: "Debug" },
  { label: "Info", value: "Info" },
  { label: "Warn", value: "Warn" },
  { label: "Error", value: "Error" },
  { label: "Fatal", value: "Fatal" },
];

export function filterLogs(logs, logType, textSearch) {
  //Filter by log type
  function filterByLogType(logs, logType) {
    let copyLogs = [...logs];
    if (logType && _.isArray(logType)) {
      let simpleLogType = logType.map((lt) => lt.value);
      let options = logTypeDef.map((lt) => lt.value);
      let selectedOptions = _.differenceWith(options, simpleLogType, _.isEqual);
      _.remove(copyLogs, (l) => selectedOptions.includes(l.logType));
    }
    return copyLogs;
  }
  //Filter by text
  function filterByText(logs, textSearch) {
    if (!textSearch || textSearch === "") return logs;
    let copyLogs = [...logs];
    var srch = textSearch.toUpperCase();
    _.remove(
      copyLogs,
      (l) =>
        !_.includes(l.methodName.toUpperCase(), srch) &&
        !_.includes(l.message.toUpperCase(), srch)
    );
    return copyLogs;
  }

  let filtered = filterByLogType(logs, logType);
  filtered = filterByText(filtered, textSearch);
  return filtered;
}

export function LogCommon({
  logs,
  highlightText,
  noResultsFoundMsg,
  noResultsFoundIcon,
}) {
  const { isMobileSize } = useMobile();

  const hl = highlightText || "";

  let lastKey = "";

  function BuildArrayFromJsonTreeProperties(level, jsonObj, propsArray) {
    if (jsonObj === null || typeof jsonObj !== "object") {
      if (lastKey === "") {
        // console.log(
        //   `Unexpected empty lastKey for value at level ${level}: `,
        //   jsonObj
        // );
        return;
      }

      propsArray.push({ level: level, name: lastKey, value: jsonObj });
      lastKey = "";
      return;
    }

    Object.entries(jsonObj).forEach(([key, value]) => {
      // Skip the first level keys since this is also an integer.
      if (level === 0) {
        BuildArrayFromJsonTreeProperties(level + 1, value, propsArray);
        return;
      }

      if (key === "key") {
        lastKey = value;
        return;
      } else {
        BuildArrayFromJsonTreeProperties(level + 1, value, propsArray);
        return;
      }
    });
  }

  function getPropertyArrayHtml(index, htmlArray) {
    return (
      <ul
        className="force-wrap"
        key={"props" + index}
        style={{
          marginTop: "5px",
        }}
      >
        {htmlArray.map((property, idx) => {
          return (
            <li key={idx}>
              <i
                style={{
                  color: "var(--text-medium)",
                  display: "inline-block",
                  marginRight: "10px",
                }}
              >
                {property.htmlName}:
              </i>
              {property.htmlValue}
            </li>
          );
        })}
      </ul>
    );
  }

  if (logs && logs.length === 0) {
    return (
      <StyledNoResultsDiv>
        <p>
          <i className="material-icons">{noResultsFoundIcon || "search_off"}</i>
        </p>
        <p>{noResultsFoundMsg || "No logs found for this Trace ID"}</p>
      </StyledNoResultsDiv>
    );
  }

  let logLevelHlResult = {};
  let logDateHlResult = {};
  let methodHlResult = {};
  let messageHlResult = {};
  let entryDateStr = "";
  let propsArray = [];
  let propsArrayHlResult = {};
  let logMessage = "";

  return (
    <>
      <table className="table" cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th id="logDate" style={{ width: "120px", maxWidth: "20%" }}>
              Date
            </th>
            <th id="logLevel" style={{ width: "100px", maxWidth: "20%" }}>
              Level
            </th>
            <th id="message" style={{ width: "80%" }}>
              Message
            </th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, idx) => {
            entryDateStr = formatDateTimeUtcWithMilliseconds(log.entryDate);
            logLevelHlResult = getHighlightedText(log.logType, hl);
            logDateHlResult = getHighlightedText(entryDateStr, hl);
            methodHlResult = getHighlightedText(log.methodName, hl);

            logMessage = getFormattedJson(log.message);
            messageHlResult = getHighlightedText(logMessage, hl);

            propsArray = [];
            BuildArrayFromJsonTreeProperties(0, log.properties, propsArray);
            propsArrayHlResult = getHighlightedTextForPropertiesArray(
              propsArray,
              hl
            );

            return (
              <Fragment key={idx}>
                {_.trim(hl).length > 0 &&
                logLevelHlResult.count === 0 &&
                logDateHlResult.count === 0 &&
                methodHlResult.count === 0 &&
                messageHlResult.count === 0 &&
                propsArrayHlResult.count === 0 ? (
                  <></>
                ) : (
                  <>
                    <tr
                      key={"tr0" + idx}
                      style={{
                        borderLeft: getLogLevelRowStyle(log.logType),
                      }}
                    >
                      <td>{logDateHlResult.html}</td>
                      <td>
                        {logLevelHlResult.count > 0 ? (
                          logLevelHlResult.html
                        ) : (
                          <LogLevelPill level={log.logType}></LogLevelPill>
                        )}
                      </td>
                      <td className="force-wrap">
                        {!_.isEmpty(log.methodName) && (
                          <div
                            style={{ fontWeight: "bold", marginBottom: "10px" }}
                          >
                            {methodHlResult.html}
                          </div>
                        )}
                        {isMobileSize ? <></> : messageHlResult.html}
                        {propsArrayHlResult.htmlArray.length > 0 &&
                        !isMobileSize ? (
                          <>
                            {getPropertyArrayHtml(
                              idx,
                              propsArrayHlResult.htmlArray
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                    {isMobileSize && (
                      <StyledMobileMessageTableRow
                        key={"tr1" + idx}
                        style={{
                          borderLeft: getLogLevelRowStyle(log.logType),
                        }}
                      >
                        <td colSpan="3" className="force-wrap">
                          {messageHlResult.html}
                          <>
                            {getPropertyArrayHtml(
                              idx,
                              propsArrayHlResult.htmlArray
                            )}
                          </>
                        </td>
                      </StyledMobileMessageTableRow>
                    )}
                  </>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const StyledMobileMessageTableRow = styled.tr`
  background-color: transparent;

  td {
    margin-left: 40px;
  }
`;
