import React, { useState } from "react";
import useApi from "../../../hooks/useApi";
import { apiTransformClaimsWithTemplate } from "../../../api/ClaimApi";
import Spinner from "../../common/ui/Spinner";
import Authorize from "../../common/layout/Authorize";
import TextAreaInput from "../../common/input/TextAreaInput";
import { emptyClaimSearch } from "../../../viewmodels/claimsVm";

function ClaimTemplateTest() {
  const { loading, api: apiTransformClaims } = useApi(
    apiTransformClaimsWithTemplate
  );
  const [template, setTemplate] = useState(
    "{{#each resources}}\n| {{ClaimId}} | {{PrescriberId}} | {{AuthorizationNumber}} | {{Quantity}} | {{ProcessedDate}}{{#if FillNumber}} | {{FillNumber}}{{else}} | None{{/if}} |\n{{/each}}"
  );
  const [transformedText, setTransformedText] = useState("");

  async function transformClaimsWithTemplate() {
    apiTransformClaims.call(
      { ...emptyClaimSearch, template: template },
      (result) => {
        setTransformedText(result);
      }
    );
  }

  function handleChange({ target }) {
    setTemplate(target.value);
  }

  return (
    <Authorize>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <form onSubmit={transformClaimsWithTemplate}>
            <TextAreaInput
              id="template"
              rows="5"
              label="Template"
              onChange={handleChange}
              placeholder="Enter text transformation template to transform claims"
              name="template"
              value={template}
              inputStyle={{ fontFamily: "courier" }}
            />
            <button
              type="submit"
              className="btn btn-primary"
              style={{ marginTop: "10px" }}
            >
              Submit
            </button>
          </form>
          <div
            style={{
              display: "block",
              marginTop: "20px",
              border: "1px solid #CCC",
              minHeight: "400px",
              whiteSpace: "pre",
              padding: "10px",
              lineHeight: "20px",
              fontFamily: "courier",
            }}
          >
            {transformedText === ""
              ? "Text transformation not complete"
              : transformedText}
          </div>
        </>
      )}
    </Authorize>
  );
}

export default ClaimTemplateTest;
