import React from "react";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";
import MultiSelectInput from "../../common/input/MultiSelectInput";
import { ruleTableDefinitionSecurityTypes } from "../../../services/General";

function RuleTableDefinitionSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onSecurityLevelChanged,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="name"
            label="Name"
            onChange={onChange}
            placeholder="Name"
            name="name"
            value={search.name}
            error={errors.name}
          />
          <TextInput
            id="description"
            label="Description"
            onChange={onChange}
            placeholder="Description"
            name="description"
            value={search.description}
            error={errors.description}
          />
          <MultiSelectInput
            id="securityLevel"
            name="securityLevel"
            label="Security"
            options={[...ruleTableDefinitionSecurityTypes]}
            value={search.securityLevel}
            onChange={onSecurityLevelChanged}
            placeholder="All"
            error={errors.securityLevel}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default RuleTableDefinitionSearchForm;
