export const emptyPacketRequest = {
  requestContents: "",
  responseContents: "",
  processedDate: "",
  transactionIndex: 0,
};

export const emptyPacketResponse = {
  processedDate: "",
  requestJson: "",
  responseJson: "",
  requestRejections: [],
  responseRejections: [],
  claimId: "",
};

export const emptyGroupSegment = {
  id: "", // Unique id of entire group - same as transaction index
  isHeader: false, // Indicates if group is for the header, which is parsed differently than other groups
  headerContents: {}, // HeaderSegment
  transactionIndex: -1, // Unique transactionid of group
  records: [], // RecordSegment
};

export const emptyHeaderSegment = {
  fields: [], // FieldSegment
};

export const emptyRecordSegment = {
  id: "", // Unique id of entire record - includes group and record ids
  segmentCode: "", // Unique segment code for this record within the group
  fields: [], // FieldSegment
};

export const emptyFieldSegment = {
  isRequest: true, // True for request, false for response
  id: "", // Unique id of entire field - includes group, record, subfield level, fieldId, and fieldGroupOrdinal
  fieldId: "", // Unique field code within the record (not including subfields)
  length: -1, // String length of field value
  value: "", // Field value
  fieldGroupOrdinal: 0, // Position in subfields, if a subfield
  isHeaderField: false, // Header fields render without the FLD indicator and fieldId code
};
