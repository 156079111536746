import React from "react";
import CheckboxInput from "../../common/input/CheckboxInput";
import { useMobile } from "../../../hooks/useMobile";
import SelectInput from "../../common/input/SingleSelect";
import TextInput from "../../common/input/TextInput";

function MemberSearchForm({
  groupList,
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onActiveChange,
  onGroupChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="memberId"
            label="Member Id"
            onChange={onChange}
            placeholder="Member Id"
            name="memberId"
            value={search.memberId}
            error={errors.memberId}
          />
          <TextInput
            id="lastName"
            label="Last Name"
            onChange={onChange}
            placeholder="Last Name"
            name="lastName"
            value={search.lastName}
            error={errors.lastName}
          />
          <TextInput
            id="firstName"
            label="First Name"
            onChange={onChange}
            placeholder="First Name"
            name="firstName"
            value={search.firstName}
            error={errors.firstName}
          />
          <SelectInput
            id="groupId"
            name="groupId"
            label="Group"
            options={groupList}
            value={search.groupId}
            onChange={onGroupChange}
            placeholder="Select a Group"
            error={errors.groupId}
          />
          <TextInput
            id="address"
            label="Address"
            onChange={onChange}
            placeholder="Address"
            name="address"
            value={search.address}
            error={errors.address}
          />
          <TextInput
            id="city"
            label="City"
            onChange={onChange}
            placeholder="City"
            name="city"
            value={search.city}
            error={errors.city}
          />
          <TextInput
            id="state"
            label="State"
            onChange={onChange}
            placeholder="State"
            name="state"
            value={search.state}
            error={errors.state}
          />
          <CheckboxInput
            id="active"
            label="Active Only"
            onChange={onActiveChange}
            placeholder="Active Only"
            name="active"
            checked={search.active}
            error={errors.active}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default MemberSearchForm;
