import React, { useState, useEffect } from "react";

import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createViewModel,
  emptyMember,
  fromViewModel,
} from "../../../viewmodels/membersVm";
import DatePickerInput from "../../common/input/DatePickerInput";
import {
  genders,
  handleCollapseExpandAll,
  isPositiveInteger,
  states,
} from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import SelectInput from "../../common/input/SingleSelect";
import CheckboxInput from "../../common/input/CheckboxInput";
import { useMembers } from "../../../contexts/MembersContext";
import { useGroups } from "../../../contexts/GroupsContext";
import { useAuth } from "../../../contexts/AuthContext";
import {
  apiAddMember,
  apiLoadMember,
  apiUpdateMember,
} from "../../../api/MemberApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { apiLoadGroupsAll } from "../../../api/GroupApi";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Member() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { membersData, setMembersData } = useMembers();
  const { groupsData, setGroupsData } = useGroups();
  const { loading, api: apiLoad } = useApi(apiLoadMember);
  const { loading: adding, api: apiAdd } = useApi(apiAddMember);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateMember);
  const { loading: loadingGroups, api: apiLoadGroups } =
    useApi(apiLoadGroupsAll);
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyMember);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Member", collapsed: false },
    { name: "Effective Range", collapsed: true },
    { name: "Contact", collapsed: true },
  ]);

  const resId = params && params.id;
  const noIdChange = resId;

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setMembersData({
        type: ContextProviderActions.loadMember,
        payload: {
          member: emptyMember,
        },
      });

      loadMemberAndGroupList();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (membersData.member) {
      setChanges(membersData.member);
    } else {
      setChanges(emptyMember);
    }
  }, [membersData.member]);

  async function loadMemberAndGroupList() {
    let groupList = groupsData.groupListCache || [];

    if ((groupList || []).length === 0) {
      groupList = await loadGroupList();

      // Save the list of groups in the cache
      setGroupsData({
        type: ContextProviderActions.setGroupListCache,
        payload: groupList,
      });
    }

    await loadMember(groupList);
  }

  async function loadGroupList() {
    return await apiLoadGroups.call(null, (result) => {
      return _.concat(
        [{ value: "", label: "(None Selected)" }],
        (result.resources || []).map((c) => {
          return { value: c.groupId, label: c.name };
        })
      );
    });
  }

  async function loadMember(groupList) {
    if (!resId) {
      setMembersData({
        type: ContextProviderActions.loadMember,
        payload: {
          member: emptyMember,
        },
      });
      setChanges(emptyMember);
      return;
    }

    apiLoad.call(resId, (result) => {
      postLoadMemberAndGroups(result, groupList);
    });
  }

  function postLoadMemberAndGroups(memberResult, groupList) {
    // Group viewmodel needs the group list
    let vm = emptyMember;
    if (!memberResult) {
      notifyError("Member does not exist");
    } else {
      vm = createViewModel(memberResult, groupList);
    }

    setMembersData({
      type: ContextProviderActions.loadMember,
      payload: {
        member: vm,
      },
    });
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    if (
      !_.has(changes, "groupIdVm") ||
      (changes.groupIdVm || { value: "" }).value === ""
    )
      _errors.group = "Please select a group";

    if (!_.has(changes, "dateOfBirth") || changes.dateOfBirth === "")
      _errors.dateOfBirth = "Date of Birth is required";

    if (!_.has(changes, "personCode") || changes.personCode === "")
      _errors.personCode = "Person Code is required";

    if (changes.eligibilityCode && changes.eligibilityCode !== "")
      if (
        !isPositiveInteger(changes.eligibilityCode) ||
        _.toNumber(changes.eligibilityCode) < 0 ||
        _.toNumber(changes.eligibilityCode) > 255
      )
        _errors.eligibilityCode =
          "Eligibility Code must be an integer between 0 and 255";

    if (changes.relationshipCode && changes.relationshipCode !== "")
      if (
        !isPositiveInteger(changes.relationshipCode) ||
        _.toNumber(changes.relationshipCode) < 0 ||
        _.toNumber(changes.relationshipCode) > 255
      )
        _errors.relationshipCode =
          "Relationship Code must be an integer between 0 and 255";

    if (
      !_.has(changes, "personCode") ||
      !isPositiveInteger(changes.personCode) ||
      _.toNumber(changes.personCode) < 0 ||
      _.toNumber(changes.personCode) > 255
    )
      _errors.personCode = "Person Code must be an integer between 0 and 255";

    if (
      changes.dateOfBirth !== null &&
      new Date(changes.dateOfBirth) > new Date()
    )
      _errors.dateOfBirth = "Date of Birth must be less than current date";

    if (!_.has(changes, "memberId") || changes.memberId.trim() === "")
      _errors.memberId = "Member Id is required";
    if (
      !_.has(changes.memberInfo, "lastName") ||
      changes.memberInfo.lastName.trim() === ""
    )
      _errors.lastName = "Last Name is required";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleGenderChange(value) {
    let changedMemberInfo = { ...changes.memberInfo, genderVm: value };
    let changed = { ...changes, memberInfo: changedMemberInfo };
    setChanges(changed);
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleMemberInfoChange({ target }) {
    let changedMemberInfo = {
      ...changes.memberInfo,
      [target.name]: target.value,
    };
    let changed = { ...changes, memberInfo: changedMemberInfo };
    setChanges(changed);
  }

  function handleStateChange(value) {
    setChanges({ ...changes, stateVm: value });
  }

  function handleEffectiveDateChange(date) {
    setChanges({ ...changes, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  function handleGroupChange(value) {
    setChanges({ ...changes, groupIdVm: value });
  }

  function handleEmailAddressSentChange({ target }) {
    let changed = { ...changes };
    changed.emailAddressSent = target.checked;
    setChanges(changed);
  }

  function handleBillableEmailAddressFeePaidChange({ target }) {
    let changed = { ...changes };
    changed.billableEmailAddressFeePaid = target.checked;
    setChanges(changed);
  }

  function handleOptedIntoPlanChange({ target }) {
    let changed = { ...changes };
    changed.optedIntoPlan = target.checked;
    setChanges(changed);
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };

    if (!resId) {
      addMember(newVm);
    } else updateMember(resId, newVm);
  }

  async function addMember(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      setChanges(createViewModel(result, groupsData.groupListCache));
      notifySuccess("Member " + vm.memberId + " saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/member/" + result.id, { replace: true });
    });
  }

  async function updateMember(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      setChanges(createViewModel(result, groupsData.groupListCache));
      notifySuccess("Member " + vm.memberId + " saved successfully");
    });
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return To Members"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate("/configuremembers")}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Configure/Member-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            Member Details {changes.memberId ? ` | ${changes.memberId}` : ""}
          </h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/configuremembers")}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {loading || loadingGroups || adding || updating ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/member/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Member"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Member-Screen&anchor=member"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-3 col-xl-3">
                    <SelectInput
                      id="groupId"
                      name="groupId"
                      label="Group"
                      options={groupsData.groupListCache}
                      value={changes.groupIdVm}
                      onChange={handleGroupChange}
                      placeholder="Select a Group"
                      error={errors.group}
                      disabled={noIdChange}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="memberId"
                      label="Member Id"
                      onChange={handleChange}
                      placeholder="Member Id"
                      name="memberId"
                      value={changes.memberId || ""}
                      error={errors.memberId}
                      disabled={noIdChange}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <div style={{ width: "180px" }}>
                      <TextInput
                        id="dateOfBirth"
                        label="Date Of Birth"
                        name="dateOfBirth"
                        value={changes.dateOfBirth}
                        placeholder="YYYY-MM-DD"
                        onChange={handleChange}
                        error={errors.dateOfBirth}
                      />
                    </div>
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="personCode"
                      label="Person Code"
                      onChange={handleChange}
                      placeholder="Person Code"
                      name="personCode"
                      value={changes.personCode}
                      error={errors.personCode}
                      disabled={noIdChange}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="memberInfo.lastName"
                      label="Last Name"
                      onChange={handleMemberInfoChange}
                      placeholder="Last Name"
                      name="lastName"
                      value={changes.memberInfo.lastName || ""}
                      error={errors.lastName}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="memberInfo.firstName"
                      label="First Name"
                      onChange={handleMemberInfoChange}
                      placeholder="First Name"
                      name="firstName"
                      value={changes.memberInfo.firstName || ""}
                      error={errors.firstName}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="memberInfo.middleName"
                      label="Middle Name"
                      onChange={handleMemberInfoChange}
                      placeholder="Middle Name"
                      name="middleName"
                      value={changes.memberInfo.middleName || ""}
                      error={errors.middleName}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="relationshipCode"
                      label="Relationship Code"
                      onChange={handleChange}
                      placeholder="Relationship Code"
                      name="relationshipCode"
                      value={changes.relationshipCode || ""}
                      error={errors.relationshipCode}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="eligibilityCode"
                      label="Eligibility Code"
                      onChange={handleChange}
                      placeholder="Eligibility Code"
                      name="eligibilityCode"
                      value={changes.eligibilityCode || ""}
                      error={errors.eligibilityCode}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <CheckboxInput
                      id="emailAddressSent"
                      label="Email Address Sent"
                      onChange={handleEmailAddressSentChange}
                      placeholder="Email Address Sent"
                      name="emailAddressSent"
                      checked={changes.emailAddressSent}
                      error={errors.emailAddressSent}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <CheckboxInput
                      id="billableEmailAddressFeePaid"
                      label="Billable Email Address Fee Paid"
                      onChange={handleBillableEmailAddressFeePaidChange}
                      placeholder="Billable Email Address Fee Paid"
                      name="billableEmailAddressFeePaid"
                      checked={changes.billableEmailAddressFeePaid}
                      error={errors.billableEmailAddressFeePaid}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <CheckboxInput
                      id="optedIntoPlan"
                      label="Opted Into Plan"
                      onChange={handleOptedIntoPlanChange}
                      placeholder="Opted Into Plan"
                      name="optedIntoPlan"
                      checked={changes.optedIntoPlan}
                      error={errors.optedIntoPlan}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Effective Range"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Member-Screen&anchor=effective-range"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="effectiveDate"
                      name="effectiveDate"
                      label="Effective Date"
                      value={changes.effectiveDate}
                      placeholder="Effective Date"
                      onChange={handleEffectiveDateChange}
                      error={errors.effectiveDate}
                      showTimeInput={false}
                    />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="terminationDate"
                      label="Termination Date"
                      name="terminationDate"
                      value={changes.terminationDate}
                      placeholder="Termination Date"
                      onChange={handleTerminationDateChange}
                      error={errors.terminationDate}
                      showTimeInput={false}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Contact"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Member-Screen&anchor=contact"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="memberInfo.title"
                      label="Title"
                      onChange={handleMemberInfoChange}
                      placeholder="Title"
                      name="title"
                      value={changes.memberInfo.title || ""}
                      error={errors.title}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="memberInfo.subtitle"
                      label="Subtitle"
                      onChange={handleMemberInfoChange}
                      placeholder="Subtitle"
                      name="subtitle"
                      value={changes.memberInfo.subtitle || ""}
                      error={errors.subtitle}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="memberInfo.suffix"
                      label="Suffix"
                      onChange={handleMemberInfoChange}
                      placeholder="Suffix"
                      name="suffix"
                      value={changes.memberInfo.suffix || ""}
                      error={errors.suffix}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <SelectInput
                      id="memberInfo.gender"
                      name="gender"
                      label="Gender"
                      options={genders}
                      value={changes.memberInfo.genderVm || ""}
                      onChange={handleGenderChange}
                      placeholder="Select a Gender"
                      error={errors.gender}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="memberInfo.emailAddresses"
                      label="Email Addresses"
                      onChange={handleMemberInfoChange}
                      placeholder="Email Addresses"
                      name="emailAddressesVm"
                      value={changes.memberInfo.emailAddressesVm || ""}
                      error={errors.emailAddresses}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="address"
                      label="Address"
                      onChange={handleChange}
                      placeholder="Address"
                      name="address"
                      value={changes.address || ""}
                      error={errors.address}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="city"
                      label="City"
                      onChange={handleChange}
                      placeholder="City"
                      name="city"
                      value={changes.city || ""}
                      error={errors.city}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <SelectInput
                      id="state"
                      name="state"
                      label="State"
                      options={states}
                      value={changes.stateVm || ""}
                      onChange={handleStateChange}
                      placeholder="Select a State"
                      error={errors.state}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="zipCode"
                      label="Zip Code"
                      onChange={handleChange}
                      placeholder="Zip Code"
                      name="zipCode"
                      value={changes.zipCode || ""}
                      error={errors.zipCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="number"
                      label="Phone Number"
                      onChange={handleChange}
                      placeholder="Phone Number"
                      name="number"
                      value={changes.number || ""}
                      error={errors.number}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
            </div>
            <br />
          </>
        )}
      </form>
    </Authorize>
  );
}

export default Member;
