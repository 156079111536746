import React, { useEffect, useState } from "react";
import _ from "lodash";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import FileUploadNoSaveButton from "../../common/input/FileUploadNoSaveButton";
import Spinner from "../../common/ui/Spinner";
import { emptyTestDataFileErrorsSearch } from "../../../viewmodels/testsVm";
import {
  autoPopulateTestDataTypes,
  download,
  formatDateTimeUtcZoneForDisplay,
  generateUUID,
} from "../../../services/General";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import useApi from "../../../hooks/useApi";
import { apiGetTestDataForDownload } from "../../../api/TestApi";
import { notifySuccess } from "../../../services/NotificationService";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import TextInput from "../../common/input/TextInput";
import SelectInput from "../../common/input/SingleSelect";
import DatePickerInput from "../../common/input/DatePickerInput";
import ConfirmDialog from "../../dialogs/ConfirmDialog";

function TestDataFileSection({
  testId,
  changes,
  setChanges,
  hasChanges,
  collapsedState,
  setCollapsedState,
  searchData,
  setSearchData,
  onGenerateData,
  generationIsWaitingToStart,
  generationInProgress,
  generationIsEnding,
  uploading,
  validationFailures,
  errors,
}) {
  const { loading: downloading, api: apiLoadDownload } = useApi(
    apiGetTestDataForDownload
  );

  const [searchErrors, setSearchErrors] = useState({
    ...emptyTestDataFileErrorsSearch,
    pageSize: 10,
  });
  const [testData, setTestData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showGenerateDataModal, setShowGenerateDataModal] = useState(false);

  const hasData =
    changes && changes.testVariableCsv && changes.testVariableCsv !== "";

  useEffect(() => {
    if (changes.testVariableCsv) {
      buildTestDataArrayFromCSV(changes.testVariableCsv);
    }
  }, [changes?.testVariableCsv]);

  async function downloadData() {
    apiLoadDownload.call({ id: testId }, (result) => {
      const testDesc = changes.description.trim();
      const filename = testDesc.replace(/([^a-z0-9\s]+)/gi, "-");
      download(result, `${filename}-Data.csv`, ".csv");
      notifySuccess("Test data downloaded successfully");
    });
  }

  function buildTestDataArrayFromCSV(csv) {
    if (_.trim(csv) === "") {
      setColumns([]);
      setTestData([]);
      return;
    }

    const array = csv.split("\r\n");
    const records = [];
    let record;

    for (let i = 0; i < array.length; i++) {
      if (i === 0) {
        // header columns
        const cols = array[i].split(",");
        setColumns(cols);

        // Set initial viewable cols
        const vc = [];
        for (let c = 0; c < array.length; c++) {
          vc.push(c);
        }
      } else {
        // data rows
        record = { id: generateUUID(), values: array[i].split(",") };
        records.push(record);
      }
    }

    setTestData(records);
  }

  function handleSaveFileContents(content) {
    var model = { ...changes };
    model.testVariableCsv = content;
    model.importValidationFailures = [];
    setChanges(model);
    buildTestDataArrayFromCSV(content);
  }

  function getColumn(value, idx) {
    return <>{value === "" ? <i>null</i> : value}</>;
  }

  function getSectionSubtitle() {
    if (validationFailures.length > 0)
      return `(${validationFailures.length} validation warning${
        validationFailures.length === 1 ? "" : "s"
      })`;
    else if (testData.length > 0)
      return `(${testData.length} record${testData.length === 1 ? "" : "s"})`;
  }

  function getTestDataColumnDefs() {
    const cols = [];

    // Always-visible row number column
    cols.push({
      name: "rowNum",
      label: "Row",
      disableSort: true,
      isSticky: true,
      style: { width: "60px" },
      getValue: (row, index) => index,
    });

    // Data columns
    let col;
    for (let i = 0; i < columns.length; i++) {
      col = columns[i];

      cols.push({
        name: col,
        label: col,
        disableSort: true,
        getValue: (row) => getColumn(row.values[i], i),
      });
    }

    return cols;
  }

  function onTextChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function onCheckboxChange({ target }) {
    setChanges({
      ...changes,
      [target.name]: target.checked,
    });
  }

  function onDataSourceToggleButtonChange(value) {
    setChanges({
      ...changes,
      isDataAutoPopulated: value === "Generate",
    });
  }

  function onAutoPopulateTestDataTypeChanged(option) {
    setChanges({
      ...changes,
      autoPopulateTestDataTypeVm: option,
    });
  }

  function handleStartDateChange(date) {
    setChanges({ ...changes, autoPopulateDataStartDate: date });
  }

  function handleEndDateChange(date) {
    setChanges({ ...changes, autoPopulateDataEndDate: date });
  }

  function onGenerateTestData() {
    setShowGenerateDataModal(true);
  }

  function performGenerateTestData() {
    setShowGenerateDataModal(false);
    onGenerateData();
  }

  if (
    generationIsWaitingToStart ||
    generationInProgress ||
    generationIsEnding
  ) {
    return (
      <ExpandCollapseDetailSection
        sectionTitle="Test Data"
        subTitle={getSectionSubtitle()}
        collapsedState={collapsedState}
        setCollapsedState={setCollapsedState}
        helpLink="/Testing/Test-Screen&anchor=test-data-section"
      >
        <div style={{ minHeight: "320px" }}>
          <Spinner spinnerStyle={{ lineHeight: "10vh" }} />

          <h4
            style={{
              textAlign: "center",
              position: "relative",
              top: "40px",
              margin: "0",
              padding: "0",
            }}
          >
            {generationIsWaitingToStart
              ? `Data generation is starting...`
              : generationIsEnding
              ? `Data generation complete!`
              : generationInProgress
              ? `Generating test data...`
              : `Cancelling data generation...`}
          </h4>
        </div>
      </ExpandCollapseDetailSection>
    );
  }

  return (
    <>
      <ConfirmDialog
        title="Confirm Test Data Generation"
        question={`Generating new data will delete any existing data on this test. Do you want to continue?`}
        showModal={showGenerateDataModal}
        onNo={() => setShowGenerateDataModal(false)}
        onYes={performGenerateTestData}
      />
      <ExpandCollapseDetailSection
        sectionTitle="Test Data"
        subTitle={getSectionSubtitle()}
        collapsedState={collapsedState}
        setCollapsedState={setCollapsedState}
        helpLink="/Testing/Test-Screen&anchor=test-data-section"
      >
        <div style={{ marginBottom: "40px", marginTop: "-10px" }}>
          <ToggleButtonGroupInput
            id="isDataAutoPopulated"
            name="isDataAutoPopulated"
            label="Data Source"
            options={[
              { value: "File", label: "CSV File" },
              { value: "Generate", label: "Auto Generate" },
            ]}
            value={changes.isDataAutoPopulated ? "Generate" : "File"}
            onChange={onDataSourceToggleButtonChange}
          />
        </div>
        {changes.isDataAutoPopulated ? (
          <>
            <p style={{ marginBottom: "30px" }}>
              Enter the values below to configure test data auto-generation.
            </p>
            <StyledRowDiv className="row">
              <div className="col-12 col-lg-6">
                <SelectInput
                  id="autoPopulateTestDataType"
                  name="autoPopulateTestDataType"
                  label="Generate data from"
                  options={[...autoPopulateTestDataTypes]}
                  value={changes.autoPopulateTestDataTypeVm}
                  onChange={onAutoPopulateTestDataTypeChanged}
                  placeholder=""
                  error={errors.autoPopulateTestDataType}
                />
              </div>
            </StyledRowDiv>
            <StyledRowDiv className="row">
              <div className="col-12 col-lg-6">
                <div style={{ maxWidth: "200px" }}>
                  <TextInput
                    id="autoPopulateGroupId"
                    label="Group Id"
                    onChange={onTextChange}
                    placeholder="Group Id"
                    name="autoPopulateGroupId"
                    value={changes.autoPopulateGroupId || ""}
                    error={errors.autoPopulateGroupId}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <TextInput
                  id="autoPopulateRecordCount"
                  name="autoPopulateRecordCount"
                  label="Number of data records to generate"
                  onChange={onTextChange}
                  placeholder="Record Count"
                  value={changes.autoPopulateRecordCount}
                  error={errors.autoPopulateRecordCount}
                  isNumber={true}
                />
              </div>
            </StyledRowDiv>
            <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
              {(changes.autoPopulateTestDataTypeVm.value ===
                "DataFromHighFrequencyClaims" ||
                changes.autoPopulateTestDataTypeVm.value ===
                  "DataFromLowFrequencyClaims") && (
                <>
                  <div className="col-12 col-lg-6">
                    <TextInput
                      id="autoPopulateClaimThreshold"
                      name="autoPopulateClaimThreshold"
                      label={
                        changes.autoPopulateTestDataTypeVm.value ===
                        "DataFromHighFrequencyClaims"
                          ? "Only include claims with key data seen this # of times or more"
                          : "Only include claims with key data seen fewer than this # of times"
                      }
                      onChange={onTextChange}
                      placeholder="Threshold"
                      value={changes.autoPopulateClaimThreshold}
                      error={errors.autoPopulateClaimThreshold}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <CheckboxInput
                      id="autoPopulateIsCobOnly"
                      name="autoPopulateIsCobOnly"
                      label="Use COB claims only"
                      onChange={onCheckboxChange}
                      checked={changes.autoPopulateIsCobOnly}
                      error={errors.autoPopulateIsCobOnly}
                    />
                  </div>
                </>
              )}
            </StyledRowDiv>
            <StyledRowDiv className="row">
              <div className="col-12 col-lg-6">
                <DatePickerInput
                  id="autoPopulateDataStartDate"
                  name="autoPopulateDataStartDate"
                  label="Data Start Date"
                  value={changes.autoPopulateDataStartDate}
                  placeholder="Start Date"
                  onChange={handleStartDateChange}
                  error={errors.autoPopulateDataStartDate}
                />
              </div>
              <div className="col-12 col-lg-6">
                <DatePickerInput
                  id="autoPopulateDataEndDate"
                  name="autoPopulateDataEndDate"
                  label="Data End Date"
                  value={changes.autoPopulateDataEndDate}
                  placeholder="End Date"
                  onChange={handleEndDateChange}
                  error={errors.autoPopulateDataEndDate}
                />
              </div>
            </StyledRowDiv>

            <p style={{ marginTop: "30px" }}>
              {!_.isEmpty(changes.autoPopulateDataLastGeneratedBy) &&
              changes.autoPopulateDataLastGeneratedDate !== null ? (
                <i>
                  Data last generated successfully by{" "}
                  {changes.autoPopulateDataLastGeneratedBy} on{" "}
                  {formatDateTimeUtcZoneForDisplay(
                    changes.autoPopulateDataLastGeneratedDate
                  )}
                  .
                </i>
              ) : (
                <i>Data has not yet been generated for this test.</i>
              )}
            </p>
            {changes.autoPopulateDataLastGeneratedError.errorState && (
              <div className="row">
                <div className="flex-row-with-wrap">
                  <span
                    style={{ color: "var(--notify-danger)" }}
                    className="material-icons"
                  >
                    warning
                  </span>
                  <strong style={{ color: "var(--notify-danger)" }}>
                    Generation failed:&nbsp;&nbsp;
                  </strong>
                  {formatDateTimeUtcZoneForDisplay(
                    changes.autoPopulateDataLastGeneratedError.errorDate
                  )}
                  &nbsp;&nbsp;
                  {changes.autoPopulateDataLastGeneratedError.errorMessage}
                </div>
              </div>
            )}
            <StyledRowDiv className="row" style={{ marginTop: "10px" }}>
              <div className="col-12 flex-row-with-wrap">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onGenerateTestData}
                  style={{ width: "180px", marginRight: "10px" }}
                  disabled={hasChanges}
                >
                  {hasChanges ? (
                    <>Save changes first</>
                  ) : (
                    <>Generate Test Data</>
                  )}
                </button>
                {hasData && (
                  <button
                    type="button"
                    className="btn btn-secondary btn-with-icon"
                    onClick={() => handleSaveFileContents("")}
                  >
                    <span className="material-icons">clear</span>
                    {"  "}Clear Test Data
                  </button>
                )}
              </div>
            </StyledRowDiv>
          </>
        ) : (
          <>
            <p style={{ marginBottom: "30px" }}>
              Upload a data file to run step(s) once for each data row.
            </p>
            <StyledRowDiv className="row">
              <div className="col-12 flex-row-with-wrap-and-justify">
                <div className="flex-row-with-wrap">
                  <FileUploadNoSaveButton
                    fileTypes=".csv"
                    uploading={uploading}
                    uploadFileContents={handleSaveFileContents}
                    style={{ marginRight: "10px" }}
                  />
                  {hasData && (
                    <button
                      type="button"
                      className="btn btn-secondary btn-with-icon"
                      onClick={() => handleSaveFileContents("")}
                    >
                      <span className="material-icons">clear</span>
                      {"  "}Clear Test Data
                    </button>
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-secondary btn-with-icon"
                  onClick={downloadData}
                  disabled={
                    !testId || downloading || (testData || []).length === 0
                  }
                >
                  {downloading ? (
                    <>
                      {" "}
                      <span className="material-icons">hourglass_empty</span>
                      Downloading...
                    </>
                  ) : (
                    <>
                      {" "}
                      <span className="material-icons">download</span>
                      Download test data (CSV)
                    </>
                  )}
                </button>
              </div>
            </StyledRowDiv>
          </>
        )}
        {uploading ? (
          <Spinner spinnerStyle={{ height: "200px", lineHeight: "200px" }} />
        ) : (
          <>
            {hasData && validationFailures.length === 0 && (
              <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                <h3 style={{ fontSize: "20px" }}>Test Data</h3>
                <ResponsiveGrid
                  gridId="TestUploadData"
                  totalRecords={testData.length}
                  search={searchData}
                  setSearch={setSearchData}
                  dataRows={testData}
                  enableClientColumnPager={true}
                  enableClientRowPager={true}
                  totalColumnCount={columns.length}
                  columnDefs={getTestDataColumnDefs()}
                />
              </StyledRowDiv>
            )}
            {validationFailures.length > 0 && (
              <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                <h3 style={{ fontSize: "20px" }}>Test Data Validation</h3>
                <div className="row">
                  <div className="flex-row-with-wrap">
                    <span
                      style={{ color: "var(--notify-danger)" }}
                      className="material-icons"
                    >
                      warning
                    </span>
                    <strong style={{ color: "var(--notify-danger)" }}>
                      Warning:&nbsp;&nbsp;
                    </strong>
                    The following validation errors were found in the data file.
                    Correct the problems and upload the file again.
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <ResponsiveGrid
                      gridId="TestUploadDataValidationErrors"
                      totalRecords={validationFailures.length}
                      search={searchErrors}
                      setSearch={setSearchErrors}
                      dataRows={validationFailures}
                      enableClientRowPager={true}
                      columnDefs={[
                        {
                          name: "row",
                          label: "Row",
                          disableSort: true,
                          style: { width: "100px" },
                          getValue: (row) => row.row || 1,
                        },
                        {
                          name: "columnName",
                          label: "Column",
                          disableSort: true,
                          style: { width: "25%" },
                        },
                        {
                          name: "message",
                          label: "Error",
                          disableSort: true,
                          style: { width: "75%" },
                        },
                      ]}
                    />
                  </div>
                </div>
              </StyledRowDiv>
            )}
          </>
        )}
      </ExpandCollapseDetailSection>
    </>
  );
}

export default TestDataFileSection;
