import React, { useState, useEffect } from "react";
import { useRuleTables } from "../../../contexts/RuleTablesContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  emptyRuleTable,
  createViewModel,
  fromViewModel,
} from "../../../viewmodels/ruleTablesVm";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import {
  apiLoadRuleTable,
  apiUpdateRuleTable,
} from "../../../api/RuleTableApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Spinner from "../../common/ui/Spinner";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import TextInput from "../../common/input/TextInput";
import {
  notifySuccess,
  notifyWarn,
  notifyError,
} from "../../../services/NotificationService";
import { handleCollapseExpandAll } from "../../../services/General";
import RuleTableData from "./RuleTableData";
import ActionMenu from "../../common/ui/ActionMenu";
import RuleTableDataFilter from "./RuleTableDataFilter";
import RuleTableImportSection from "./RuleTableImportSection";
import {
  createRuleDataImportViewModel,
  emptyRuleDataImport,
} from "../../../viewmodels/ruleTableDataVm";
import useApi from "../../../hooks/useApi";
import { useReportTemplates } from "../../../contexts/ReportTemplatesContext";
import HelpLink from "../../common/ui/HelpLink";

function RuleTable() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { ruleTablesData, setRuleTablesData } = useRuleTables();
  const { setReportTemplatesData } = useReportTemplates();
  const { loading, api: apiLoad } = useApi(apiLoadRuleTable);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateRuleTable);
  const [loadingData, setLoadingData] = useState(false);
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyRuleTable);
  const [ruleTableImport, setRuleTableImport] = useState(
    createRuleDataImportViewModel({ ...emptyRuleDataImport })
  );

  const [collapsedState, setCollapsedState] = useState([
    { name: "Rule Table", collapsed: false },
    { name: "Group Search", collapsed: false },
    { name: "Import", collapsed: true },
    { name: "Data", collapsed: false },
  ]);

  const ruleTable = (ruleTablesData && ruleTablesData.ruleTable) || {};
  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setRuleTablesData({
        type: ContextProviderActions.loadRuleTable,
        payload: emptyRuleTable,
      });

      loadRuleTable();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (ruleTablesData.ruleTable) {
      setChanges(ruleTablesData.ruleTable);
    } else {
      setChanges(emptyRuleTable);
    }
  }, [ruleTablesData.ruleTable]);

  async function loadRuleTableData(updatedSearch) {
    setLoadingData(true);
    await loadRuleTable(updatedSearch);
  }

  async function loadRuleTable(updatedSearch) {
    const _search = { ...ruleTablesData.search, ...(updatedSearch || {}) };

    apiLoad.call({ id: resId, search: _search }, (result) => {
      setLoadingData(false);

      let vm = emptyRuleTable;
      if (!result) {
        notifyError("Rule table does not exist");
      } else {
        vm = createViewModel(result);
      }

      setRuleTablesData({
        type: ContextProviderActions.loadRuleTable,
        payload: vm,
      });
      setChanges(vm);
    });
  }

  function clearTemplateRuleTableCache() {
    // Copy, delete, and save operations invalidate the cache.
    setReportTemplatesData({
      type: ContextProviderActions.setReportTemplateCache,
      payload: [],
    });
  }

  function setSearchChanges(search) {
    setRuleTablesData({
      type: ContextProviderActions.saveRuleTableSearch,
      payload: search,
    });
  }

  function setDataSearchChanges(search) {
    setRuleTablesData({
      type: ContextProviderActions.saveRuleDataSearch,
      payload: search,
    });
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function formIsValid() {
    const _errors = {};

    if (changes.key.trim() === "") _errors.key = "Key must be entered";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleDataFilterApply(event, updatedSearch) {
    if (event) event.preventDefault();
    loadRuleTableData(updatedSearch);
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    const newVm = { ...changes };
    updateRuleTable(newVm.id, newVm);
  }

  async function updateRuleTable(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      const newVm = { ...model, ...result };
      setChanges(createViewModel(newVm));
      clearTemplateRuleTableCache();
      notifySuccess("RuleTable '" + vm.key + "' saved successfully");
    });
  }

  if (!resId) {
    return (
      <StyledHeaderRowDiv>
        <h1>Error</h1>
        <p>A Rule Table ID must be provided.</p>
      </StyledHeaderRowDiv>
    );
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            title="Return to previous screen"
            type="button"
            className="btn btn-link"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Processing-Rules/Rule-Table-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            Edit Rule Table
            {loading ? "" : ` | ${changes.key}`}
          </h1>

          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {(loading || updating) && !loadingData ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/ruletable/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Rule Table"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Table-Screen&anchor=rule-table"
              >
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6">
                    <TextInput
                      id="key"
                      label={`Key${
                        changes.isSystem === true
                          ? " (System key is read-only)"
                          : ""
                      }`}
                      onChange={handleChange}
                      placeholder="Key"
                      name="key"
                      value={changes.key || ""}
                      error={errors.key}
                      autoFocus={true}
                      disabled={changes.isSystem === true}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextInput
                      id="description"
                      label="Description"
                      onChange={handleChange}
                      placeholder="Description"
                      name="description"
                      value={changes.description || ""}
                      error={errors.description}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Rule</label>
                      <div className="field">
                        <button
                          className="btn btn-link link-underline"
                          onClick={() =>
                            navigate("/rule/" + changes.sourceRuleId)
                          }
                        >
                          {changes.sourceRuleName}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Rule Table Definition</label>
                      <div className="field">
                        <button
                          className="btn btn-link link-underline"
                          onClick={() =>
                            navigate(
                              "/ruletabledefinition/" +
                                changes.ruleTableDefinitionId
                            )
                          }
                        >
                          {changes.ruleTableDefinitionName}
                        </button>
                      </div>
                    </div>
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Group Search"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Table-Screen&anchor=group-search"
              >
                <RuleTableDataFilter
                  search={ruleTablesData.search}
                  setSearch={setSearchChanges}
                  onSubmit={handleDataFilterApply}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Import"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Table-Screen&anchor=import"
              >
                <RuleTableImportSection
                  ruleTableId={ruleTable.id}
                  search={ruleTablesData.search}
                  ruleTableImport={ruleTableImport}
                  setRuleTableImport={setRuleTableImport}
                  onLoadData={loadRuleTableData}
                  errors={errors}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Data"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Table-Screen&anchor=data"
              >
                <RuleTableData
                  ruleTable={ruleTable}
                  ruleTableSearch={ruleTablesData.search}
                  search={ruleTablesData.dataSearch}
                  setSearch={setDataSearchChanges}
                  loadingData={loadingData}
                  onLoadingData={loadRuleTableData}
                />
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default RuleTable;
