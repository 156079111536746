import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { getFooterAppVersion } from "../../../services/Version";

function Footer() {
  const navigate = useNavigate();
  const { userPrefs } = useUserPreferences();
  const today = new Date();

  return (
    <StyledFooter
      style={{
        boxShadow: `-4px -4px 10px var(${
          userPrefs.coverageTrackingMode ? "--pill-blue" : "--shadow-1"
        })`,
      }}
    >
      <p>
        &copy; {today.getFullYear()} aClaim, Inc. All Rights Reserved. Version{" "}
        {getFooterAppVersion()} |
        <button className="btn btn-link" onClick={() => navigate("/contact")}>
          Contact Support
        </button>
        |
        <button className="btn btn-link" onClick={() => navigate("/privacy")}>
          Privacy Policy
        </button>
      </p>
    </StyledFooter>
  );
}

const StyledFooter = styled.div`
  width: 100%;
  min-height: 32px;
  background-color: var(--footer-bg);
  border-top: 2px solid var(--bg-gradient-bottom);
  margin-top: 20px;
  padding: 7px 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: var(--text-dark);
    margin-bottom: 0;
    font-size: 15px;
    line-height: 19px;
    text-align: center;

    button.btn-link {
      color: var(--link);
      font-size: 15px;
      line-height: 19px;
      margin-top: -3px;
      padding: 0 4px;
    }
  }
`;

export default Footer;
