import _ from "lodash";
import { parseISO } from "date-fns";
import { pageSize } from "../constants/Environments";
import { generateUUID } from "../services/General";

var today = new Date();
var tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
var oneHourAgo = new Date();
oneHourAgo.setHours(oneHourAgo.getHours() - 1);

export const emptyPriorAuthorization = {
  priorAuthorizationNumber: "",
  groupId: "",
  pharmacy: "",
  prescriptionNumber: "",
  productId: "",
  memberId: "",
  usages: 1,
  effectiveDate: today,
  terminationDate: tomorrow,
  conditionals: [],
  authorizedRejections: [],
  authorizedRejectionsVm: "",
  active: false,
};

export const emptyPriorAuthorizationSearch = {
  groupId: "",
  pharmacy: "",
  prescriptionNumber: "",
  productId: "",
  memberId: "",
  priorAuthorizationNumber: "",
  active: true,
  orderBy: ["lastModifiedDate-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  priorAuthsCount: 0,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export const emptyPriorAuthorizationConditional = {
  id: "",
  attachedTo: "",
  attachedToType: "RejectionOverride",
  name: "",
  valueType: "RequestFieldValue",
  operator: "=",
  valuesVm: "",
  stagingOnly: false,
  runMode: "All",
};

export function fromViewModel(pa) {
  let m = _.cloneDeep(pa);
  m.conditionals = m.conditionals.map((f) => {
    var vals = f.valuesVm.split(",").trimArray();
    f.values = vals;
    f.runMode = "All";
    return f;
  });
  if (_.isEmpty(m.pharmacy)) m.pharmacy = "*";
  if (_.isEmpty(m.prescriptionNumber)) m.prescriptionNumber = "*";
  if (_.isEmpty(m.memberId)) m.memberId = "*";
  if (_.isEmpty(m.productId)) m.productId = "*";
  if (_.isEmpty(m.groupId)) m.groupId = "*";
  if (_.isEmpty(m.authorizedRejectionsVm)) m.authorizedRejections = [];
  else m.authorizedRejections = m.authorizedRejectionsVm.split(",").trimArray();
  m.serviceProviderId = m.pharmacy;
  return m;
}

export function createViewModel(pa) {
  let vm = _.cloneDeep(pa);
  if (_.isNull(vm.effectiveDate)) {
    vm.effectiveDate = emptyPriorAuthorization.effectiveDate;
  } else {
    vm.effectiveDate = parseISO(vm.effectiveDate);
  }
  if (_.isNull(vm.terminationDate))
    vm.terminationDate = emptyPriorAuthorization.terminationDate;
  else {
    vm.terminationDate = parseISO(vm.terminationDate);
  }

  // jon, 12/6/2022: Give each conditional a unique id for use in CRUD operations
  vm.conditionals = vm.conditionals.map((c) => {
    c.id = generateUUID();
    c.effectiveDate = null;
    c.terminationDate = null;
    c.valuesVm = c.values.join(",");
    c.stagingOnly = false;
    return c;
  });
  vm.pharmacy = vm.serviceProviderId;
  vm.authorizedRejectionsVm = vm.authorizedRejections.join(", ");
  return vm;
}
