import React, { useState, useEffect } from "react";
import AuditBulkChangeRecordList from "./AuditBulkChangeRecordList";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import {
  createBulkChangeViewModel,
  emptyAuditBulkChangeRecordsSearch,
} from "../../../viewmodels/auditVm";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useAudit } from "../../../contexts/AuditContext";
import {
  apiLoadBulkChangeAuditRecords,
  apiRollbackSingleChange,
  apiRollbackBulkChange,
} from "../../../api/AuditApi";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function AuditBulkChangeRecords() {
  const { auth } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const { auditData, setAuditData } = useAudit();
  const { loading, api: apiLoad } = useApi(apiLoadBulkChangeAuditRecords);
  const [loadData, setLoadData] = useState(true);
  const { loading: rollingBack, api: apiRollbackSingle } = useApi(
    apiRollbackSingleChange
  );
  const { loading: rollingBackBulk, api: apiRollbackBulk } = useApi(
    apiRollbackBulkChange
  );
  const [showRollbackModal, setShowRollbackModal] = useState(false);
  const [rollbackSingleId, setRollbackSingleId] = useState({});

  const resId = params && params.id;

  let auditBulkChangeRecords = [];
  let bulkChangeCount = 0;
  if (auditData && auditData.auditBulkChangeRecords) {
    auditBulkChangeRecords = auditData.auditBulkChangeRecords;
    bulkChangeCount = auditData.bulkChangeCount;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setChainsData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //   useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadAuditRecords();
    }
  }, [auth.authenticated, loadData]);

  // When id changes, reset search params so old search criteria does not show on new record
  useEffect(() => {
    if (params?.id) {
      setSearchChanges({ ...emptyAuditBulkChangeRecordsSearch });
    }
  }, [params?.id]);

  async function loadAuditRecords() {
    apiLoad.call(
      resId,
      (result) => {
        setLoadData(false);
        // Show all inserts, then updates, then deletes, then not modifieds, each group by entity type then key
        let vms = [
          ...result.modifiedIds
            .map((r) => createBulkChangeViewModel(r))
            .sort((a, b) =>
              a.modificationType > b.modificationType ||
              a.entityType > b.entityType ||
              a.entityKey > b.entityKey
                ? 1
                : -1
            ),
          ...result.deletedIds
            .map((r) => createBulkChangeViewModel(r))
            .sort((a, b) =>
              a.entityType > b.entityType || a.entityKey > b.entityKey ? 1 : -1
            ),
          ...result.notModifiedIds
            .map((r) => createBulkChangeViewModel(r))
            .sort((a, b) =>
              a.entityType > b.entityType || a.entityKey > b.entityKey ? 1 : -1
            ),
        ];

        const count =
          (result.modifiedIds || []).length +
          (result.deletedIds || []).length +
          (result.notModifiedIds || []).length;

        setAuditData({
          type: ContextProviderActions.loadAuditBulkChangeRecords,
          payload: {
            bulkUser: result.userName ?? "UNKNOWN USER",
            bulkDate: result.transactionDate
              ? formatDateTimeUtcZoneForDisplay(result.transactionDate)
              : "UNKNOWN DATE",
            auditBulkChangeRecords: vms,
            bulkChangeCount: count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setAuditData({
      type: ContextProviderActions.saveAuditBulkChangeSearch,
      payload: search,
    });
  }

  function handleInstantSearchChange(value) {
    setSearchChanges({
      ...auditData.bulkChangeSearch,
      highlightText: value,
      pageNumber: 1,
    });
  }

  async function rollbackChanges(id) {
    apiRollbackBulk.call(id, (result) => {
      notifySuccess("Changes for bulk operation rolled back successfully");
      navigate("/auditbulkrecords/" + result.id);
    });
  }

  async function rollbackChangesSingleId(entityType, id) {
    apiRollbackSingle.call(
      { entityType, id: id, transactionId: resId },
      (result) => {
        notifySuccess(`Changes to single document rolled back successfully`);
        setRollbackSingleId({});
        setLoadData(true);
      }
    );
  }

  function handleRollbackChanges(entityType, id) {
    setRollbackSingleId({ entityType, id });
    setShowRollbackModal(true);
  }

  async function performRollbackChanges() {
    setShowRollbackModal(false);

    if (rollbackSingleId.id === "") {
      await rollbackChanges(resId);
    } else {
      await rollbackChangesSingleId(
        rollbackSingleId.entityType,
        rollbackSingleId.id
      );
    }
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        <HelpLink path="/Tools/Audit-Bulk-Operation-Screen" label="Help" />
      </StyledScreenHelpDiv>
      <StyledBackButtonDiv>
        <button
          title="Return To Audit Records"
          className="btn btn-link btn-with-icon"
          onClick={() => navigate("/auditrecords")}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1>Audit Bulk Operation</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary btn-with-icon"
            onClick={() => handleRollbackChanges("", "")}
          >
            <span className="material-icons">history</span>
            Rollback all changes
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <div style={{ marginTop: "10px", marginBottom: "20px" }}>
        Change by {auditData.bulkUser || "..."} on {auditData.bulkDate || "..."}
      </div>
      <StyledHeaderRowDiv>
        <InstantSearchInput
          id="screenSearchInput"
          onChange={handleInstantSearchChange}
          value={auditData.bulkChangeSearch.highlightText}
        />
      </StyledHeaderRowDiv>

      <ConfirmDialog
        title="Rollback Changes"
        question={`${
          rollbackSingleId.id === ""
            ? "Are you sure you want to undo all of the changes from this bulk operation"
            : `Are you sure you want to undo the changes from ${rollbackSingleId.entityType} ${rollbackSingleId.id}`
        }?`}
        showModal={showRollbackModal}
        onNo={() => setShowRollbackModal(false)}
        onYes={performRollbackChanges}
      />
      <div style={{ marginTop: "10px" }}>
        {loading || loadData || rollingBack || rollingBackBulk ? (
          <Spinner />
        ) : (
          <AuditBulkChangeRecordList
            transactionId={resId}
            auditRecords={auditBulkChangeRecords}
            search={auditData.bulkChangeSearch}
            setSearch={setSearchChanges}
            totalRecords={bulkChangeCount}
            onRollbackChange={(entityType, id) =>
              handleRollbackChanges(entityType, id)
            }
          />
        )}
      </div>
    </Authorize>
  );
}

export default AuditBulkChangeRecords;
