import React from "react";
import TextInput from "../../common/input/TextInput";
import PropTypes from "prop-types";
import DatePickerInput from "../../common/input/DatePickerInput";
import MultiSelectInput from "../../common/input/MultiSelectInput";
import DateRangeInput from "../../common/input/DateRangeInput";
import { useMobile } from "../../../hooks/useMobile";

function ClaimSearchForm({
  isAdmin,
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onStartDateChange,
  onEndDateChange,
  onDateRangeChange,
  onStatusChanged,
  onDiffStatusChanged,
}) {
  const { isMobileSize } = useMobile();

  const statusOptions = [
    { label: "Paid", value: "Paid" },
    { label: "Rejected", value: "Rejected" },
    { label: "Reversed", value: "Reversed" },
    { label: "Unreversed Paid", value: "Unreversed Paid" },
  ];

  const diffStatusOptions = [
    { label: "Pass", value: "pass" },
    { label: "Fail", value: "fail" },
    { label: "Warn", value: "warn" },
    { label: "Untested", value: "untested" },
  ];

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="prescriptionRefNumber"
            label="Rx Number"
            onChange={onChange}
            placeholder=""
            name="prescriptionRefNumber"
            value={search.prescriptionRefNumber}
            error={errors.prescriptionRefNumber}
          />
          <TextInput
            id="groupId"
            label="Group ID or Name"
            onChange={onChange}
            placeholder=""
            name="groupId"
            value={search.groupId}
            error={errors.groupId}
          />
          <TextInput
            id="memberId"
            label="Member ID"
            onChange={onChange}
            placeholder=""
            name="memberId"
            value={search.memberId}
            error={errors.memberId}
          />
          <TextInput
            id="pharmacy"
            label="Pharmacy NPI, NCPDP, or Name"
            onChange={onChange}
            placeholder=""
            name="pharmacy"
            value={search.pharmacy}
            error={errors.pharmacy}
          />
          <TextInput
            id="drugId"
            label="Drug ID or Name"
            onChange={onChange}
            placeholder=""
            name="drugId"
            value={search.drugId}
            error={errors.drugId}
          />
          <TextInput
            id="authNumber"
            label="Authorization No."
            onChange={onChange}
            placeholder=""
            name="authNumber"
            value={search.authNumber}
            error={errors.authNumber}
          />
          <TextInput
            id="message"
            label="Message"
            onChange={onChange}
            placeholder=""
            name="message"
            value={search.message}
            error={errors.message}
          />
          <TextInput
            id="rejectionCode"
            label="Rejection Code"
            onChange={onChange}
            placeholder=""
            name="rejectionCode"
            value={search.rejectionCode}
            error={errors.rejectionCode}
          />
          <MultiSelectInput
            id="friendlyStatus"
            name="friendlyStatus"
            label="Status"
            options={statusOptions}
            value={search.friendlyStatus}
            onChange={onStatusChanged}
            placeholder="All"
            error={errors.friendlyStatus}
          />
          {isAdmin && (
            <MultiSelectInput
              id="diffStatus"
              name="diffStatus"
              label="Difference Status"
              options={diffStatusOptions}
              value={search.diffStatus}
              onChange={onDiffStatusChanged}
              placeholder="All"
              error={errors.diffStatus}
            />
          )}
          <DateRangeInput
            id="dateRange"
            name="dateRange"
            label="Date Range"
            value={search.dateRange}
            onChange={onDateRangeChange}
            error={errors.dateRange}
          />
          <DatePickerInput
            id="startDate"
            name="startDate"
            label="Processed Start Date"
            value={search.startDate}
            placeholder=""
            onChange={onStartDateChange}
            error={errors.startDate}
          />
          <DatePickerInput
            id="endDate"
            label="Processed End Date"
            name="endDate"
            value={search.endDate}
            placeholder=""
            onChange={onEndDateChange}
            error={errors.endDate}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

ClaimSearchForm.propTypes = {
  search: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ClaimSearchForm;
