import React from "react";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function ReportTemplatesList({
  templates,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
  onCopy,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const name = dataRow.name;

    const actions = [];

    actions.push({
      name: "Edit",
      onClick: () => navigate("/reporttemplate/" + id),
    });

    actions.push({
      name: "Duplicate",
      onClick: () => onCopy(name, id),
    });

    actions.push({
      name: "Remove",
      onClick: () => onDelete(id, name),
    });

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/reporttemplate/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="ReportTemplates"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={templates}
        columnDefs={[
          {
            name: "name",
            label: "Name",
            style: { width: "40%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/reporttemplate/" + row.id)}
              >
                {row.name}
              </button>
            ),
          },
          {
            name: "entityType",
            label: "Data Source",
            style: { width: "25%" },
          },
          {
            name: "fileNameTemplate",
            label: "File Name",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
          },
          {
            name: "externalDataRuleTableDefinitionName",
            label: "Rule Table Def.",
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() =>
                  navigate(
                    "/ruletabledefinition/" +
                      row.externalDataRuleTableDefinitionId
                  )
                }
              >
                {row.externalDataRuleTableDefinitionName}
              </button>
            ),
          },
          {
            name: "lastModifiedDate",
            label: "Created/Modified",
            className: "d-none d-lg-table-cell",
            style: { width: "20%" },
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.lastModifiedDate),
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default ReportTemplatesList;
