import React from "react";
import styled from "styled-components";
import { useMobile } from "../../../hooks/useMobile";
import HelpLink from "../ui/HelpLink";

function GridAdvancedFilter({
  search,
  setSearch,
  helpLink = null,
  marginTop = "-185px",
  width = 226,
  children,
}) {
  const { isMobileSize } = useMobile();

  if (search.showAdvancedFilter === false) return <></>;

  // Show as full screen when view is mobile size
  if (!isMobileSize) {
    return (
      <StyledFilterBox data-testid="advanced-filter-box" width={width}>
        <StyledFilterBoxHeader>
          <h4>Filters {helpLink !== null && <HelpLink path={helpLink} />}</h4>
          <button
            className="btn btn-link"
            onClick={() =>
              setSearch({
                ...search,
                showAdvancedFilter: false,
              })
            }
          >
            Hide
          </button>
        </StyledFilterBoxHeader>
        <StyledFilterBoxContents>{children}</StyledFilterBoxContents>
      </StyledFilterBox>
    );
  } else {
    // Show as full screen
    return (
      <StyledFilterFullScreen
        data-testid="advanced-filter-box-full-screen"
        marginTop={marginTop}
      >
        <StyledBackButtonDiv>
          <button
            className="btn btn-link"
            onClick={() =>
              setSearch({
                ...search,
                showAdvancedFilter: false,
              })
            }
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
        </StyledBackButtonDiv>
        <StyledFilterBoxHeader>
          <h4>Filters {helpLink !== null && <HelpLink path={helpLink} />}</h4>
        </StyledFilterBoxHeader>
        <StyledFilterBoxContents>{children}</StyledFilterBoxContents>
      </StyledFilterFullScreen>
    );
  }
}

const StyledFilterFullScreen = styled.div`
  margin: ${(props) => props.marginTop} -5% 0 -5%;
  z-index: 3;
  background: var(--elevated-bg);

  h4 {
    color: var(--text-normal);
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

const StyledBackButtonDiv = styled.div`
  padding: 15px 0 3px 15px;
`;

const StyledFilterBox = styled.div`
  margin-right: 14px;
  width: ${(props) => props.width}px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border2);
  border-radius: 3px;

  h4 {
    color: var(--text-dark);
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

const StyledFilterBoxHeader = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledFilterBoxContents = styled.div`
  border-top: 1px solid var(--elevated-border2);
  padding: 1px 20px 20px 20px;
`;

export default GridAdvancedFilter;
