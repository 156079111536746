import React from "react";
import { useHref } from "react-router-dom";

function RuleMapEntityLink({ links }) {
  const hrefChain = useHref("/chain");
  const hrefClient = useHref("/client");
  const hrefDiscount = useHref("/discount");
  const hrefDrug = useHref("/drug");
  const hrefGroup = useHref("/group");
  const hrefMember = useHref("/member");
  const hrefPrescriber = useHref("/prescriber");
  const hrefPriorAuth = useHref("/priorauthorization");
  const hrefServiceProvider = useHref("/serviceprovider");
  const hrefRuleTable = useHref("/ruletable");

  function openEntityLink(entityType, entityId) {
    let href = null;

    switch (entityType) {
      case "chain":
        href = hrefChain;
        break;
      case "client":
        href = hrefClient;
        break;
      case "discount":
        href = hrefDiscount;
        break;
      case "drug":
        href = hrefDrug;
        break;
      case "group":
        href = hrefGroup;
        break;
      case "member":
        href = hrefMember;
        break;
      case "prescriber":
        href = hrefPrescriber;
        break;
      case "priorauthorization":
        href = hrefPriorAuth;
        break;
      case "serviceprovider":
        href = hrefServiceProvider;
        break;
      case "ruletable":
        href = hrefRuleTable;
        break;
      default:
        console.log("entity not yet supported: ", entityType);
        break;
    }

    console.log(`Opening link: ${href + "/" + entityId}`);
    window.open(href + "/" + entityId, "RuleMapEntity");
  }

  if ((links || []).length < 1) {
    return null;
  }

  return (
    <>
      {links.map((l, lidx) => (
        <button
          key={`link-${lidx}`}
          type="button"
          className="btn btn-link btn-with-icon"
          onClick={() => openEntityLink(l.entityType, l.entityId)}
        >
          {l.entityName}
          <span className="material-icons" style={{ paddingLeft: "5px" }}>
            open_in_new
          </span>
        </button>
      ))}
    </>
  );
}

export default RuleMapEntityLink;
