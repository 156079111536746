import React from "react";
import CheckboxInput from "../../common/input/CheckboxInput";
import SelectInput from "../../common/input/SingleSelect";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";

function GroupRuleMapSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onActiveChange,
  onClientChange,
  clientList,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="groupId"
            label="Group Id"
            onChange={onChange}
            placeholder="Group Id"
            name="groupId"
            value={search.groupId}
            error={errors.groupId}
          />
          <TextInput
            id="name"
            label="Name"
            onChange={onChange}
            placeholder="Name"
            name="name"
            value={search.name}
            error={errors.name}
          />
          <SelectInput
            id="clientId"
            name="clientId"
            label="Client"
            options={clientList}
            value={search.clientId}
            onChange={onClientChange}
            placeholder="Select a Client"
            error={errors.clientId}
          />
          <CheckboxInput
            id="active"
            label="Active Only"
            onChange={onActiveChange}
            placeholder="Active Only"
            name="active"
            checked={search.active}
            error={errors.active}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default GroupRuleMapSearchForm;
