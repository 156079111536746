import React, { Fragment, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
} from "../../common/layout/CommonStyledControls";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import GridActionButton from "../../common/grid/GridActionButton";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import {
  generateUUID,
  getHighlightedText,
  ruleConditionalOperators,
} from "../../../services/General";
import { Modal } from "react-bootstrap";
import TextInput from "../../common/input/TextInput";
import SelectInput from "../../common/input/SingleSelect";
import { emptyPriorAuthorizationConditional } from "../../../viewmodels/priorAuthorizationsVm";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import HelpLink from "../../common/ui/HelpLink";

function PriorAuthorizationConditionalsSection({ changes, setChanges }) {
  const [search, setSearch] = useState({ highlightText: "" });
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editItem, setEditItem] = useState({
    ...emptyPriorAuthorizationConditional,
    originalId: "",
  });
  const [errors, setErrors] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const settingsArrayName = "conditionals";

  const valueTypeArray = [
    { value: "RequestFieldValue", label: "Request Field Value" },
    { value: "ResponseFieldValue", label: "Response Field Value" },
    { value: "InternalVariable", label: "Internal Variable" },
    { value: "EntityData", label: "Entity Data" },
    { value: "GroupSetting", label: "Group Setting" },
  ];

  function handleAddSetting() {
    const id = generateUUID();
    setEditItem({
      originalId: id,
      id: id,
      ...emptyPriorAuthorizationConditional,
    });
    setEditMode(false);
    setShowModal(true);
  }

  function handleEditSetting(conditional) {
    setEditItem({
      originalId: conditional.id,
      id: conditional.id,
      ...conditional,
    });
    setEditMode(true);
    setShowModal(true);
  }

  function handleRemoveSetting() {
    const newVm = _.cloneDeep(changes);
    const array = _.get(newVm, settingsArrayName) || [];
    const index = array.findIndex((i) => i.id === deleteId);
    if (index < 0) {
      notifyError(`A conditional with id ${deleteId} was not found to remove!`);
      return;
    }

    array.splice(index, 1);
    setChanges(newVm);
    notifySuccess(`Conditional removed successfully.`);
    setShowDeleteModal(false);
  }

  function handleChange({ target }) {
    let newItem = { ...editItem, [target.name]: target.value };
    setEditItem(newItem);
  }

  function operatorRequiresValue(opValue) {
    const operator = ruleConditionalOperators.find((o) => o.value === opValue);
    if (!operator) return true;

    return operator.requiresValue === true;
  }

  function handleValueTypeChange(option) {
    let changes = { ...editItem, valueType: option.value };
    setEditItem(changes);
  }

  function handleOperatorChange(option) {
    let changes = { ...editItem, operator: option.value, value: "" };

    if (!operatorRequiresValue(changes.operator)) {
      changes.value = "";
    }

    setEditItem(changes);
  }

  function formIsValid() {
    const _errors = {};

    if (editItem.name.trim() === "") _errors.name = "Key is required";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function setItemValues(item, editItem) {
    item.name = _.isNull(editItem.name) ? "" : editItem.name;
    item.valuesVm = _.isNull(editItem.valuesVm) ? "" : editItem.valuesVm;
    item.valueType = _.isNull(editItem.valueType) ? "" : editItem.valueType;
    item.operator = _.isNull(editItem.operator) ? "" : editItem.operator;
  }

  function handleSave() {
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    const newVm = _.cloneDeep(changes);
    const array = _.get(newVm, settingsArrayName) || [];

    if (editMode) {
      // Save in edit mode
      const index = array.findIndex((i) => i.id === editItem.originalId);
      if (index < 0) {
        notifyError(
          `An conditional with id ${editItem.originalId} was not found to edit!`
        );
        return;
      }

      const item = array[index];
      setItemValues(item, editItem);
    } else {
      // Save in insert mode
      const item = { ...emptyPriorAuthorizationConditional };
      setItemValues(item, editItem);
      array.push(item);
    }

    setChanges(newVm);
    handleCloseEditDialog();
    notifySuccess(
      `Conditional ${editMode ? "updated" : "added"} successfully.`
    );
  }

  function submitOnEnter(e) {
    if (e && e.key && e.key === "Enter") {
      handleSave();
    }
  }

  function handleCloseEditDialog() {
    setEditItem({ ...emptyPriorAuthorizationConditional, originalId: "" });
    setErrors({});
    setShowModal(false);
  }

  function getActionButton(conditional) {
    const actions = [
      {
        name: "Edit Conditional",
        onClick: () => handleEditSetting(conditional),
      },
      {
        name: "Remove Conditional",
        onClick: () => {
          setDeleteId(conditional.id);
          setShowDeleteModal(true);
        },
      },
    ];

    return (
      <GridActionButton
        key={`ab-${conditional.id}`}
        actions={actions}
      ></GridActionButton>
    );
  }

  function handleInstantSearchChange(value) {
    setSearch({ ...search, highlightText: value });
  }

  const hl = search.highlightText;
  let conditionalHlNameResult = {};
  let conditionalHlValueTypeResult = {};
  let conditionalHlOperatorResult = {};
  let conditionalHlValueResult = {};
  const settingsArray = _.get(changes, settingsArrayName) || [];

  return (
    <>
      <ConfirmDialog
        title="Remove Conditional"
        question={`Are you sure you wish to remove this conditional?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={handleRemoveSetting}
      />
      <Modal show={showModal} onHide={handleCloseEditDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Conditional" : "Add Conditional"}
            <HelpLink
              path="/Configure/Prior-Authorization-Screen&anchor=conditional-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectInput
            id="valueType"
            name="valueType"
            label="Key Type"
            options={valueTypeArray}
            value={{
              value: editItem.valueType,
              label: valueTypeArray.find((o) => o.value === editItem.valueType)
                ?.label,
            }}
            onChange={handleValueTypeChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            error={errors.valueType}
            autoFocus={true}
          />
          <TextInput
            id="name"
            label="Key"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="name"
            value={editItem.name}
            error={errors.name}
          />
          <SelectInput
            id="operator"
            name="operator"
            label="Operator"
            options={ruleConditionalOperators}
            value={
              editItem?.operator
                ? {
                    value: editItem.operator,
                    label: ruleConditionalOperators.find(
                      (o) => o.value === editItem.operator
                    )?.label,
                  }
                : { value: "=", label: "Equal" }
            }
            onChange={handleOperatorChange}
            placeholder=""
            error={errors.operator}
          />
          <TextInput
            id="valuesVm"
            label="Value(s)"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="valuesVm"
            value={editItem.valuesVm}
            error={errors.valuesVm}
            disabled={!operatorRequiresValue(editItem.operator)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSave}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseEditDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <StyledContainer>
        <StyledHeaderRowDiv>
          <InstantSearchInput
            id="screenSearchInput"
            onChange={handleInstantSearchChange}
            value={search.highlightText}
          />
          <StyledHeaderRowButtonDiv>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleAddSetting}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="material-icons">add</span>
              {"  "}Add Conditional
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        <table className="table table-clickable-rows">
          <thead>
            <tr>
              <th style={{ width: "25%" }}>Key</th>
              <th style={{ width: "25%" }}>Key Type</th>
              <th style={{ width: "25%" }}>Operator</th>
              <th style={{ width: "25%" }}>Value(s)</th>
              <th style={{ width: "60px", textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {settingsArray.map((i, idx) => {
              conditionalHlNameResult = getHighlightedText(i.name, hl);
              conditionalHlValueTypeResult = getHighlightedText(
                valueTypeArray.find((o) => o.value === i.valueType)?.label,
                hl
              );
              conditionalHlOperatorResult = getHighlightedText(
                ruleConditionalOperators.find((o) => o.value === i.operator)
                  ?.label,
                hl
              );
              conditionalHlValueResult = getHighlightedText(i.valuesVm, hl);

              return _.trim(hl).length > 0 &&
                conditionalHlNameResult.count === 0 &&
                conditionalHlValueTypeResult.count === 0 &&
                conditionalHlOperatorResult.count === 0 &&
                conditionalHlValueResult.count === 0 ? (
                <></>
              ) : (
                <Fragment key={idx}>
                  <tr key={"conditional" + idx}>
                    <td className="force-wrap">
                      <button
                        type="button"
                        className="btn btn-link link-underline"
                        onClick={() => handleEditSetting(i)}
                      >
                        {conditionalHlNameResult.html}
                      </button>
                    </td>
                    <td className="force-wrap">
                      {conditionalHlValueTypeResult.html}
                    </td>
                    <td className="force-wrap">
                      {conditionalHlOperatorResult.html}
                    </td>
                    <td className="force-wrap">
                      {conditionalHlValueResult.html}
                    </td>

                    <td className="table-action-btn">{getActionButton(i)}</td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  padding-top: 10px;

  table.table {
    margin-top: 12px;
  }

  button.btn.btn-secondary {
    margin-top: 4px;
  }
`;

export default PriorAuthorizationConditionalsSection;
