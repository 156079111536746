import React, { Fragment, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
} from "../../common/layout/CommonStyledControls";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import GridActionButton from "../../common/grid/GridActionButton";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import {
  getHighlightedText,
  isPositiveInteger,
} from "../../../services/General";
import { Modal } from "react-bootstrap";
import TextInput from "../../common/input/TextInput";
import { emptyDrugIngredient } from "../../../viewmodels/drugsVm";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import HelpLink from "../../common/ui/HelpLink";

function DrugIngredientsSection({ changes, setChanges }) {
  const [search, setSearch] = useState({ highlightText: "" });
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editItem, setEditItem] = useState({
    ...emptyDrugIngredient,
    originalId: "",
  });
  const [errors, setErrors] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const settingsArrayName = "ingredients";

  function handleAddSetting() {
    setEditItem({ originalId: "", id: "", ...emptyDrugIngredient });
    setEditMode(false);
    setShowModal(true);
  }

  function handleEditSetting(ingredient) {
    setEditItem({
      originalId: ingredient.ingredientGenericIdNumber,
      id: ingredient.ingredientGenericIdNumber,
      ...ingredient,
    });
    setEditMode(true);
    setShowModal(true);
  }

  function handleRemoveSetting() {
    const newVm = _.cloneDeep(changes);
    const array = _.get(newVm, settingsArrayName) || [];
    const index = array.findIndex(
      (i) => i.ingredientGenericIdNumber === deleteId
    );
    if (index < 0) {
      notifyError(`An ingredient with id ${deleteId} was not found to remove!`);
      return;
    }

    array.splice(index, 1);
    setChanges(newVm);
    notifySuccess(`Ingredient removed successfully.`);
    setShowDeleteModal(false);
  }

  function handleChange({ target }) {
    let newItem = { ...editItem, [target.name]: target.value };
    setEditItem(newItem);
  }

  function formIsValid() {
    const _errors = {};

    if (
      _.has(editItem, "ingredientGenericIdNumber") &&
      !isPositiveInteger(editItem.ingredientGenericIdNumber)
    )
      _errors.ingredientGenericIdNumber =
        "Generic Id Number must be a valid number";

    if (editItem.ingredientGenericTypeIdCode.trim() === "")
      _errors.ingredientGenericTypeIdCode = "Generic Type Id Code is required";
    if (editItem.genericIngredientName.trim() === "")
      _errors.genericIngredientName = "Generic Ingredient Name is required";
    if (editItem.activeIngredientFlag.trim() === "")
      _errors.activeIngredientFlag = "Active Ingredient Flag is required";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function setItemValues(item, editItem) {
    item.ingredientGenericTypeIdCode = _.isNull(
      editItem.ingredientGenericTypeIdCode
    )
      ? ""
      : editItem.ingredientGenericTypeIdCode;

    item.ingredientGenericIdNumber = _.isNull(
      editItem.ingredientGenericIdNumber
    )
      ? ""
      : editItem.ingredientGenericIdNumber;

    item.ingredientStrength = _.isNull(editItem.ingredientStrength)
      ? ""
      : editItem.ingredientStrength;

    item.ingredientStrengthUnitOfMeasure = _.isNull(
      editItem.ingredientStrengthUnitOfMeasure
    )
      ? ""
      : editItem.ingredientStrengthUnitOfMeasure;

    item.genericIngredientName = _.isNull(editItem.genericIngredientName)
      ? ""
      : editItem.genericIngredientName;

    item.activeIngredientFlag = _.isNull(editItem.activeIngredientFlag)
      ? ""
      : editItem.activeIngredientFlag;
  }

  function handleSave() {
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    const newVm = _.cloneDeep(changes);
    const array = _.get(newVm, settingsArrayName) || [];

    if (editMode) {
      // Save in edit mode
      const index = array.findIndex(
        (i) => i.ingredientGenericIdNumber === editItem.originalId
      );
      if (index < 0) {
        notifyError(
          `An ingredient with id ${editItem.originalId} was not found to edit!`
        );
        return;
      }

      const item = array[index];
      setItemValues(item, editItem);
    } else {
      // Save in insert mode
      const item = { ...emptyDrugIngredient };
      setItemValues(item, editItem);
      array.push(item);
    }

    setChanges(newVm);
    handleCloseEditDialog();
    notifySuccess(`Ingredient ${editMode ? "updated" : "added"} successfully.`);
  }

  function submitOnEnter(e) {
    if (e && e.key && e.key === "Enter") {
      handleSave();
    }
  }

  function handleCloseEditDialog() {
    setEditItem({ ...emptyDrugIngredient, originalId: "" });
    setErrors({});
    setShowModal(false);
  }

  function getActionButton(ingredient) {
    const actions = [
      {
        name: "Edit Ingredient",
        onClick: () => handleEditSetting(ingredient),
      },
      {
        name: "Remove Ingredient",
        onClick: () => {
          setDeleteId(ingredient.ingredientGenericIdNumber);
          setShowDeleteModal(true);
        },
      },
    ];

    return (
      <GridActionButton
        key={`ab-${ingredient.ingredientGenericIdNumber}`}
        actions={actions}
      ></GridActionButton>
    );
  }

  function handleInstantSearchChange(value) {
    setSearch({ ...search, highlightText: value });
  }

  const hl = search.highlightText;
  let ingredientGenericTypeIdCodeHlResult = {};
  let ingredientGenericIdNumberHlResult = {};
  let ingredientStrengthHlResult = {};
  let ingredientStrengthUnitOfMeasureHlResult = {};
  let genericIngredientNameHlResult = {};
  let activeIngredientFlagHlResult = {};
  const settingsArray = _.get(changes, settingsArrayName) || [];

  return (
    <>
      <ConfirmDialog
        title="Remove Ingredient"
        question={`Are you sure you wish to remove the ingredient '${deleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={handleRemoveSetting}
      />
      <Modal show={showModal} onHide={handleCloseEditDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Ingredient" : "Add Ingredient"}
            <HelpLink
              path="/Configure/Drug-Screen&anchor=ingredient-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            id="ingredientGenericIdNumber"
            label="Generic Id Number"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="ingredientGenericIdNumber"
            value={editItem.ingredientGenericIdNumber}
            error={errors.ingredientGenericIdNumber}
            autoFocus={true}
            isNumber={true}
          />
          <TextInput
            id="ingredientGenericTypeIdCode"
            label="Generic Type Id Code"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="ingredientGenericTypeIdCode"
            value={editItem.ingredientGenericTypeIdCode}
            error={errors.ingredientGenericTypeIdCode}
          />
          <TextInput
            id="ingredientStrength"
            label="Strength"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="ingredientStrength"
            value={editItem.ingredientStrength}
            error={errors.ingredientStrength}
            isNumber={true}
          />
          <TextInput
            id="ingredientStrengthUnitOfMeasure"
            label="Strength Unit Of Measure"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="ingredientStrengthUnitOfMeasure"
            value={editItem.ingredientStrengthUnitOfMeasure}
            error={errors.ingredientStrengthUnitOfMeasure}
          />
          <TextInput
            id="genericIngredientName"
            label="Generic Ingredient Name"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="genericIngredientName"
            value={editItem.genericIngredientName}
            error={errors.genericIngredientName}
          />
          <TextInput
            id="activeIngredientFlag"
            label="Active Ingredient Flag"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="activeIngredientFlag"
            value={editItem.activeIngredientFlag}
            error={errors.activeIngredientFlag}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSave}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseEditDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <StyledContainer>
        <StyledHeaderRowDiv>
          <InstantSearchInput
            id="screenSearchInput"
            onChange={handleInstantSearchChange}
            value={search.highlightText}
          />
          <StyledHeaderRowButtonDiv>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleAddSetting}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="material-icons">add</span>
              {"  "}Add Ingredient
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        <table className="table table-clickable-rows">
          <thead>
            <tr>
              <th id="ingredientGenericIdNumber" style={{ width: "40%" }}>
                Generic Id Number
              </th>
              <th id="ingredientGenericTypeIdCode" style={{ width: "25%" }}>
                Id Code
              </th>
              <th
                id="ingredientStrength"
                style={{ width: "25%" }}
                className=" d-none d-md-table-cell"
              >
                Strength
              </th>
              <th
                id="ingredientStrengthUnitOfMeasure"
                style={{ width: "25%" }}
                className=" d-none d-md-table-cell"
              >
                Strength UOM
              </th>
              <th
                id="genericIngredientName"
                style={{ width: "45%" }}
                className=" d-none d-sm-table-cell"
              >
                Generic Ingredient Name
              </th>
              <th
                id="activeIngredientFlag"
                style={{ width: "20%" }}
                className=" d-none d-sm-table-cell"
              >
                Active Flag
              </th>
              <th style={{ width: "60px", textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {settingsArray.map((i, idx) => {
              ingredientGenericIdNumberHlResult = getHighlightedText(
                i.ingredientGenericIdNumber,
                hl
              );
              ingredientGenericTypeIdCodeHlResult = getHighlightedText(
                i.ingredientGenericTypeIdCode,
                hl
              );
              ingredientStrengthHlResult = getHighlightedText(
                i.ingredientStrength,
                hl
              );
              ingredientStrengthUnitOfMeasureHlResult = getHighlightedText(
                i.ingredientStrengthUnitOfMeasure,
                hl
              );
              genericIngredientNameHlResult = getHighlightedText(
                i.genericIngredientName,
                hl
              );
              activeIngredientFlagHlResult = getHighlightedText(
                i.activeIngredientFlag,
                hl
              );

              return _.trim(hl).length > 0 &&
                ingredientGenericTypeIdCodeHlResult.count === 0 &&
                ingredientGenericIdNumberHlResult.count === 0 &&
                ingredientStrengthHlResult.count === 0 &&
                ingredientStrengthUnitOfMeasureHlResult.count === 0 &&
                genericIngredientNameHlResult.count === 0 &&
                activeIngredientFlagHlResult.count === 0 ? (
                <></>
              ) : (
                <Fragment key={idx}>
                  <tr key={"ingredient" + idx}>
                    <td className="force-wrap">
                      <button
                        type="button"
                        className="btn btn-link link-underline"
                        onClick={() => handleEditSetting(i)}
                      >
                        {ingredientGenericIdNumberHlResult.html}
                      </button>
                    </td>
                    <td className="force-wrap">
                      {ingredientGenericTypeIdCodeHlResult.html}
                    </td>
                    <td className="force-wrap d-none d-md-table-cell">
                      {ingredientStrengthHlResult.html}
                    </td>
                    <td className="force-wrap d-none d-md-table-cell">
                      {ingredientStrengthUnitOfMeasureHlResult.html}
                    </td>
                    <td className="force-wrap d-none d-sm-table-cell">
                      {genericIngredientNameHlResult.html}
                    </td>
                    <td className="force-wrap d-none d-sm-table-cell">
                      {activeIngredientFlagHlResult.html}
                    </td>

                    <td className="table-action-btn">{getActionButton(i)}</td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  padding-top: 10px;

  table.table {
    margin-top: 12px;
  }

  button.btn.btn-secondary {
    margin-top: 4px;
  }
`;

export default DrugIngredientsSection;
