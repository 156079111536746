import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import { useReportProcesses } from "../../../contexts/ReportProcessesContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import ReadOnly from "../../common/input/ReadOnly";
import TextInput from "../../common/input/TextInput";
import { apiSaveReportLastValues } from "../../../api/ReportProcessesApi";
import useApi from "../../../hooks/useApi";
import Spinner from "../../common/ui/Spinner";
import HelpLink from "../../common/ui/HelpLink";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function ReportProcessLastRunSavedData({
  title,
  vmLastSavedValues,
  allowEdits = false,
}) {
  const { reportProcessesData, setReportProcessesData } = useReportProcesses();
  const { loading: saving, api: apiSaveValues } = useApi(
    apiSaveReportLastValues
  );
  const [lastRunValues, setLastRunValues] = useState([]);
  const [lastRunValuesForGrid, setLastRunValuesForGrid] = useState([]);
  const [columns, setColumns] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    handleLastRunValues(_.cloneDeep(vmLastSavedValues));
  }, [vmLastSavedValues]);

  function setSearchLastRunValuesChanges(search) {
    setReportProcessesData({
      type: ContextProviderActions.saveReportProcessLastRunValuesSearch,
      payload: search,
    });
  }

  function handleLastRunValues(vmLsv) {
    if ((vmLsv || []).length > 0) {
      buildLastRunValuesArrays(_.cloneDeep(vmLsv));
      setLastRunValues(_.cloneDeep(vmLsv));
    } else {
      setColumns([]);
      setLastRunValuesForGrid([]);
      setLastRunValues([]);
    }
  }

  function buildLastRunValuesArrays(lastValues, colSize = -1) {
    const _columns = [];
    const _viewableColumns = []; // First 4 columns
    const _rowValues = [];
    let _colValues = [];
    let valueArray = [];

    for (let i = 0; i < lastValues.length; i++) {
      valueArray = lastValues[i].value || [];

      // Only get column list from the first row.
      if (i === 0) {
        _columns.push("Row Key");

        for (let j = 0; j < valueArray.length; j++) {
          _columns.push(valueArray[j].key);

          if (j < 4) _viewableColumns.push(j);
        }
      }

      _colValues = [];
      _colValues.push(lastValues[i].key);
      for (let j = 0; j < valueArray.length; j++) {
        _colValues.push(valueArray[j].value);
      }
      _rowValues.push({
        id: lastValues[i].detailHistoryId,
        values: [..._colValues],
      });
    }

    setColumns(_columns);
    setLastRunValuesForGrid(_rowValues);
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleCloseEditDialog() {
    setEditRow(null);
    setErrors({});
    setShowModal(false);
  }

  async function handleCancel(event) {
    if (event) event.preventDefault();

    //Reset last saved values to their last saved values (heh)
    setLastRunValues(_.cloneDeep(vmLastSavedValues));
    handleCloseEditDialog();
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    setShowModal(false);

    const _lastRunValues = _.cloneDeep(lastRunValues);
    const lrv = _lastRunValues.find(
      (v) => v.detailHistoryId === editRow.detailHistoryId
    );
    lrv.value = editRow.value;

    // Inserts also use the update endpoint.
    updateLastRunSaveValuesForRow(_lastRunValues, editRow.detailHistoryId, lrv);
  }

  async function updateLastRunSaveValuesForRow(
    updatedLastRunValues,
    detailHistoryId,
    row
  ) {
    apiSaveValues.call({ detailHistoryId, row }, (result) => {
      setEditRow(null);
      notifySuccess("Values saved successfully");
      // Update row values in the grid to match the saved edits, preserving currently column size so it does not get reset
      buildLastRunValuesArrays(
        updatedLastRunValues,
        reportProcessesData.searchLastRunValues.colSize
      );
    });
  }

  function handleEditDataRow(e, id) {
    if (e) e.preventDefault();
    const values = _.cloneDeep(lastRunValues);

    const lrv = values.find((v) => v.detailHistoryId === id);
    setEditRow(lrv);
    setShowModal(true);
  }

  function handleChangeEditDataRowValue(id, dataKey, value) {
    const lrv = lastRunValues.find((v) => v.detailHistoryId === id);
    if (lrv) {
      const data = lrv.value.find((v) => v.key === dataKey);
      if (data) {
        data.value = value;
        setEditRow({ ...lrv });
      }
    }
  }

  function getColumnDefs() {
    const cols = [];

    // Always-visible edit column
    cols.push({
      name: "edit",
      label: "",
      disableSort: true,
      isSticky: true,
      hidden: !allowEdits,
      style: { width: "65px" },
      getValue: (row) => (
        <button
          type="button"
          className="btn btn-link link-underline"
          onClick={(e) => handleEditDataRow(e, row.id)}
          style={{ marginTop: "-4px" }}
        >
          edit
        </button>
      ),
    });

    // Report columns
    let col;
    for (let i = 0; i < columns.length; i++) {
      col = columns[i];

      cols.push({
        name: col,
        label: col,
        disableSort: true,
        getValue: (row) => {
          const val = row.values[i];
          let value = val ? val : <i>null</i>;
          return value;
        },
      });
    }

    return cols;
  }

  return (
    <>
      {(lastRunValuesForGrid || []).length > 0 && (
        <StyledRowDiv className="row" style={{ marginTop: "40px" }}>
          <h3 style={{ fontSize: "20px" }}>{title}</h3>
          {saving ? (
            <Spinner spinnerStyle={{ height: "200px", lineHeight: "200px" }} />
          ) : (
            <>
              <ResponsiveGrid
                gridId="ReportProcessLastRunSavedData"
                totalRecords={lastRunValuesForGrid.length}
                search={reportProcessesData.searchLastRunValues}
                setSearch={setSearchLastRunValuesChanges}
                dataRows={lastRunValuesForGrid}
                enableClientColumnPager={true}
                enableClientRowPager={true}
                totalColumnCount={columns.length + 1}
                columnDefs={getColumnDefs()}
              />
            </>
          )}
        </StyledRowDiv>
      )}
      <Modal show={showModal} onHide={handleCloseEditDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Last Run Saved Values
            <HelpLink
              path="/Reports/Report-Process-Screen&anchor=edit-last-run-saved-values-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col col-12">
            <ReadOnly label="Row Key" value={editRow?.key} />
          </div>

          {editRow &&
            editRow.value &&
            editRow.value.map((data, idx) => (
              <div key={`col-${idx}`} className="col col-12">
                <TextInput
                  key={data.key}
                  id={data.key}
                  label={data.key}
                  name={data.key}
                  value={data.value}
                  error={errors[data.key]}
                  onChange={({ target }) =>
                    handleChangeEditDataRowValue(
                      editRow.detailHistoryId,
                      target.name,
                      target.value
                    )
                  }
                  autoFocus={idx === 0}
                />
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-with-icon"
            onClick={handleSubmit}
            style={{
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReportProcessLastRunSavedData;
