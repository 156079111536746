import React, { useEffect, useState } from "react";
import Spinner from "../../common/ui/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { useLogs } from "../../../contexts/LogsContext";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import { apiLoadLogs } from "../../../api/LogApi";
import { filterLogs, LogCommon } from "./LogCommon";
import { logInitial } from "../../../services/General";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import ActionMenu from "../../common/ui/ActionMenu";
import {
  StyledBackButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Log() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { logsData, setLogsData } = useLogs();
  const { loading, api: apiLoad } = useApi(apiLoadLogs);
  const [actionMenuSelection, setActionMenuSelection] =
    useState("Summary logs only");

  const traceId =
    (params && params.id) || "00000000-0000-0000-0000-000000000000";

  let filteredLogs = [];
  if (logsData && logsData.logs) {
    filteredLogs = filterLogs(
      logsData.logs,
      logsData.logSearch.logType,
      logsData.logSearch.textSearch
    );
  }

  const logLevelActions = [
    { label: "Summary logs only", value: "Summary" },
    { label: "Summary logs including Debug", value: "Debug" },
    { label: "Summary logs including Trace", value: "Trace" },
  ];

  useEffect(() => {
    if (auth.authenticated) {
      loadLogs();
    }
  }, [auth.authenticated, params?.id]);

  async function loadLogs() {
    apiLoad.call(traceId, async (result) => {
      setLogsData({
        type: ContextProviderActions.loadLogs,
        payload: result,
      });
    });
  }

  function handleLogTypeFilter(value, label) {
    // Default is Summary only - Info, Warn, Error, and Fatal
    let logTypes = [...logInitial];

    if (value === "Debug" || value === "Trace") {
      logTypes.push({ label: "Debug", value: "Debug" });
    }
    if (value === "Trace") {
      logTypes.push({ label: "Trace", value: "Trace" });
    }

    setLogsData({
      type: ContextProviderActions.saveLogSearch,
      payload: { ...logsData.logSearch, logType: logTypes },
    });
    setActionMenuSelection(label);
  }

  function setScreenSearchInput(text) {
    // Set the search text as the text to highlight in the claims context
    const newSearch = { ...logsData.logSearch };
    newSearch.highlightText = text;

    setLogsData({
      type: ContextProviderActions.saveLogSearch,
      payload: newSearch,
    });
  }

  return (
    <Authorize>
      <StyledBackButtonDiv>
        <button
          title="Return To Logs"
          className="btn btn-link"
          onClick={() => navigate("/logs")}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink path="/Tools/Log-Details-Screen" label="Help" />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1>{`Log Details | ${traceId}`}</h1>
        <InstantSearchInput
          id="screenSearchInput"
          onChange={setScreenSearchInput}
          value={logsData.logSearch.highlightText}
        />
      </StyledHeaderRowDiv>

      {loading ? (
        <Spinner />
      ) : (
        <>
          <ActionMenu
            title={actionMenuSelection}
            items={logLevelActions}
            onSelectAction={handleLogTypeFilter}
          />
          <div className="container-fluid" style={{ marginTop: "5px" }}>
            <LogCommon
              logs={filteredLogs}
              highlightText={logsData.logSearch.highlightText}
            />
          </div>
        </>
      )}
    </Authorize>
  );
}

export default Log;
