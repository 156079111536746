import React from "react";
import TextInput from "../../common/input/TextInput";
import PropTypes from "prop-types";
import DatePickerInput from "../../common/input/DatePickerInput";
import DateRangeInput from "../../common/input/DateRangeInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import { useMobile } from "../../../hooks/useMobile";

function AuditRecordsSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onCheckboxChange,
  onStartDateChange,
  onEndDateChange,
  onDateRangeChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="id"
            label="Transaction Id"
            onChange={onChange}
            placeholder=""
            name="id"
            value={search.id}
            error={errors.id}
          />
          <TextInput
            id="userName"
            label="User"
            onChange={onChange}
            placeholder=""
            name="userName"
            value={search.userName}
            error={errors.userName}
          />
          <TextInput
            id="documentId"
            label="Document Id"
            onChange={onChange}
            placeholder=""
            name="documentId"
            value={search.documentId}
            error={errors.documentId}
          />
          <TextInput
            id="entityType"
            label="Entity"
            onChange={onChange}
            placeholder=""
            name="entityType"
            value={search.entityType}
            error={errors.entityType}
          />
          <TextInput
            id="entityKey"
            label="Key"
            onChange={onChange}
            placeholder=""
            name="entityKey"
            value={search.entityKey}
            error={errors.entityKey}
          />
          <CheckboxInput
            id="bulkChangeOnly"
            label="Show bulk operations only"
            onChange={onCheckboxChange}
            placeholder=""
            name="bulkChangeOnly"
            checked={search.bulkChangeOnly}
            error={errors.bulkChangeOnly}
          />
          <DateRangeInput
            id="dateRange"
            name="dateRange"
            label="Date Range"
            value={search.dateRange}
            onChange={onDateRangeChange}
            error={errors.dateRange}
          />
          <DatePickerInput
            id="startDate"
            name="startDate"
            label="Start Date"
            value={search.startDate}
            placeholder=""
            onChange={onStartDateChange}
            error={errors.startDate}
          />
          <DatePickerInput
            id="endDate"
            label="End Date"
            name="endDate"
            value={search.endDate}
            placeholder=""
            onChange={onEndDateChange}
            error={errors.endDate}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

AuditRecordsSearchForm.propTypes = {
  search: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default AuditRecordsSearchForm;
