import React from "react";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function ReportsList({
  reports,
  subscribedReportIds,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
  onCopy,
  onSubscribe,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const reportName = dataRow.reportName;
    const shareMode = dataRow.shareMode;
    const ownerUserId = dataRow.ownerUserId;
    const isSubscribed = subscribedReportIds.indexOf(dataRow.id) >= 0;

    const actions = [];
    const userId = auth.userId;

    const hideEditRemove =
      (shareMode === "Featured" && !auth.isAdmin) ||
      (shareMode === "Shared" && ownerUserId !== userId);

    if (!hideEditRemove)
      actions.push({
        name: "Edit",
        onClick: () => navigate("/report/" + id),
      });

    actions.push({
      name: "Run now",
      onClick: () => navigate("/runreport/" + id),
    });

    actions.push({
      name: "Duplicate",
      onClick: () => onCopy(reportName, id),
    });

    if (!hideEditRemove) {
      actions.push({
        name: "Remove",
        onClick: () => onDelete(id, reportName),
      });
    }

    // Add ability to subscribe to a shared/featured reports if the user is not the owner
    if (shareMode !== "NotShared" && ownerUserId !== userId) {
      if (isSubscribed) {
        actions.push({
          name: "Unsubscribe",
          onClick: () => onSubscribe(id, reportName, false),
        });
      } else {
        actions.push({
          name: "Subscribe",
          onClick: () => onSubscribe(id, reportName, true),
        });
      }
    }

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/reportdefinition/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="Reports"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={reports}
        columnDefs={[
          {
            name: "run",
            label: "Run",
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%" },
            getValue: (row) => (
              <span
                className="material-icons"
                style={{ cursor: "pointer" }}
                title="Run this report"
                onClick={() => navigate("/runreport/" + row.id)}
              >
                play_circle_outline
              </span>
            ),
          },
          {
            name: "name",
            label: "Name",
            style: { width: "25%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/report/" + row.id)}
              >
                {row.name}
              </button>
            ),
          },
          {
            name: "description",
            label: "Description",
            style: { width: "40%" },
          },
          {
            name: "shareMode",
            label: "Share Mode",
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) => row.shareModeVm?.label,
          },
          {
            name: "showOnHomePage",
            label: "Home Link",
            className: "d-none d-xl-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              row.showOnHomePage ? (
                <span className="material-icons">check</span>
              ) : (
                ""
              ),
          },
          {
            name: "showAsDashboardPanel",
            label: "Dashboard",
            style: { width: "15%" },
            className: "d-none d-xl-table-cell",
            getValue: (row) =>
              row.showAsDashboardPanel ? (
                <span className="material-icons">check</span>
              ) : (
                ""
              ),
          },
          {
            name: "dashboardPanelIsAlertPanel",
            label: "Alert",
            className: "d-none d-xl-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              row.dashboardPanelIsAlertPanel ? (
                <span className="material-icons">check</span>
              ) : (
                ""
              ),
          },
          {
            name: "isSubscribed",
            label: "Subscribed",
            disableSort: true,
            className: "d-none d-xl-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              subscribedReportIds.indexOf(row.id) >= 0 ? (
                <span className="material-icons">check</span>
              ) : (
                ""
              ),
          },
          {
            name: "lastModifiedDate",
            label: "Created/Modified",
            className: "d-none d-xl-table-cell",
            style: { width: "20%" },
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.lastModifiedDate),
          },
          {
            name: "ownerUserName",
            label: "Created By",
            className: "d-none d-xl-table-cell",
            style: { width: "20%" },
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default ReportsList;
