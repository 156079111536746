import _ from "lodash";

export const emptyTestDataPopulatorConfig = {
  id: "",
  apiAddress: "",
  clientId: "",
  tenantId: "",
  clientSecret: "",
  sqlConnectionString: "",
  storageConnectionString: "",
  logLocation: "",
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  return vm;
}
