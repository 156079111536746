import { pageSize } from "../constants/Environments";

export const emptyClaimSearch = {
  startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  endDate: new Date("1/1/3000"),
  dateRange: "Past24hours",
  pharmacy: "",
  memberId: "",
  drugId: "",
  prescriptionRefNumber: "",
  groupId: "",
  authNumber: "",
  message: "",
  rejectionCode: "",
  friendlyStatus: [],
  orderBy: ["processedDate-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
  showColumnNames: false,
  showDetails: false,
  highlightText: "",
  diffStatus: [],
};

export const emptyClaim = {
  claim: {},
  member: {},
  pharmacy: {},
  prescriber: {},
  drug: {},
  discount: {},
  discountRedemption: {},
  group: {},
  chain: {},
  client: {},
  relatedClaims: [],
};
