import React from "react";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { StyledLogLink } from "./RuleMapCommonStyles";

function RuleMapLogsLink({ logs, title }) {
  const { setRuleMapsData } = useRuleMaps();

  function openLogDialog(e) {
    if (e) e.preventDefault();

    setRuleMapsData({
      type: ContextProviderActions.openRuleMapLogsDialog,
      payload: { logs: logs, title: title },
    });
  }

  const logLength = (logs || []).length;
  if (logLength === 0) return null;

  return (
    <StyledLogLink>
      <button type="button" className="btn btn-link" onClick={openLogDialog}>
        logs ({logLength})
      </button>
    </StyledLogLink>
  );
}

export default RuleMapLogsLink;
