import React from "react";
import styled from "styled-components";
import { useMobile } from "../../../hooks/useMobile";
import CoverageTrackingRecordPropertiesContent from "./CoverageTrackingRecordPropertiesContent";

function CoverageTrackingRecordProperties({ show, setShow, entry }) {
  const { isMobileSize } = useMobile();

  if (!show) return <></>;

  // Show as full screen when view is mobile size
  if (!isMobileSize) {
    return (
      <StyledPropertiesBox>
        <StyledPropertiesBoxHeader>
          <h4>Metadata</h4>
          <button className="btn btn-link" onClick={() => setShow(false)}>
            Hide
          </button>
        </StyledPropertiesBoxHeader>
        <StyledPropertyBoxContents>
          <CoverageTrackingRecordPropertiesContent entry={entry} />
        </StyledPropertyBoxContents>
      </StyledPropertiesBox>
    );
  } else {
    // Show as full screen
    return (
      <StyledPropertiesFullScreen>
        <StyledBackButtonDiv>
          <button className="btn btn-link" onClick={() => setShow(false)}>
            <i className="fa fa-angle-left"></i> Back
          </button>
        </StyledBackButtonDiv>
        <StyledPropertiesBoxHeader>
          <h4>Metadata</h4>
        </StyledPropertiesBoxHeader>
        <StyledPropertyBoxContents>
          <CoverageTrackingRecordPropertiesContent entry={entry} />
        </StyledPropertyBoxContents>
      </StyledPropertiesFullScreen>
    );
  }
}

const StyledPropertiesFullScreen = styled.div`
  margin: 0 -5% 0 -5%;
  z-index: 3;
  background: var(--elevated-bg);

  h4 {
    color: var(--text-normal);
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

const StyledBackButtonDiv = styled.div`
  padding: 15px 0 3px 15px;
`;

const StyledPropertiesBox = styled.div`
  margin: 16px 14px 4px 4px;
  width: 226px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border2);
  border-radius: 3px;

  h4 {
    color: var(--text-dark);
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

const StyledPropertiesBoxHeader = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledPropertyBoxContents = styled.div`
  border-top: 1px solid var(--elevated-border2);
  padding: 1px 20px 20px 20px;
`;

export default CoverageTrackingRecordProperties;
