import React from "react";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";
import MultiSelectInput from "../../common/input/MultiSelectInput";
import { testRunResultTypes } from "../../../services/General";
import DatePickerInput from "../../common/input/DatePickerInput";
import CheckboxInput from "../../common/input/CheckboxInput";

function TestSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onLastRunResultStatusChanged,
  onCheckboxChange,
  onShowOnlyAutoPopulatedTestsChecked,
  onStartDateChange,
  onEndDateChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="description"
            label="Description"
            onChange={onChange}
            placeholder="Description"
            name="description"
            value={search.description}
            error={errors.description}
          />
          <TextInput
            id="lastRunBy"
            label="Last Run By"
            onChange={onChange}
            placeholder="Last run by"
            name="lastRunBy"
            value={search.lastRunBy}
            error={errors.lastRunBy}
          />
          <MultiSelectInput
            id="lastRunResultStatus"
            name="lastRunResultStatus"
            label="Run Result Status"
            options={[...testRunResultTypes]}
            value={search.lastRunResultStatus}
            onChange={onLastRunResultStatusChanged}
            placeholder="All"
            error={errors.lastRunResultStatus}
          />
          <CheckboxInput
            id="includeTestsInGroup"
            label="Include Group Tests"
            onChange={onCheckboxChange}
            placeholder=""
            name="includeTestsInGroup"
            checked={search.includeTestsInGroup}
            error={errors.includeTestsInGroup}
          />
          <CheckboxInput
            id="showOnlyAutoPopulatedTests"
            label="Show Only Auto-Populated Tests"
            onChange={onShowOnlyAutoPopulatedTestsChecked}
            placeholder=""
            name="showOnlyAutoPopulatedTests"
            checked={search.showOnlyAutoPopulatedTests}
            error={errors.showOnlyAutoPopulatedTests}
          />
          <DatePickerInput
            id="startDate"
            name="startDate"
            label="Last Run Start Date"
            value={search.startDate}
            placeholder=""
            onChange={onStartDateChange}
            error={errors.startDate}
          />
          <DatePickerInput
            id="endDate"
            label="Last Run End Date"
            name="endDate"
            value={search.endDate}
            placeholder=""
            onChange={onEndDateChange}
            error={errors.endDate}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default TestSearchForm;
