import _ from "lodash";
import { pageSize } from "../constants/Environments";

export const emptyDrug = {
  ndc: "",
  npi: "",
  formatCode: "",
  transactionCode: "",
  sequenceCode: "",
  labelerCode: 0,
  genericIdType: "",
  genericId: 0,
  deaClassCode: "",
  ahfsccTherapeuticClass: 0,
  ahfsccTherapeuticClassExtended: 0,
  itemStatus: "",
  local: "",
  therapeuticEquivEval: "",
  formattedId: "",
  rxOtcIndicator: "",
  thirdPartyRestriction: "",
  maintenanceDrug: "",
  dispensingUnit: "",
  unitDose: "",
  routeOfAdministration: "",
  formType: "",
  dollarRank: "",
  rxRank: "",
  numberSystemCharacter: "",
  secondaryIdFormat: "",
  secondaryId: "",
  multiSourceCode: "",
  brandNameCode: "",
  reimbursementIndicator: "",
  internalCode: "",
  singleCombinationCode: "",
  storageConditionCode: "",
  limitedStabilityCode: "",
  productName: "",
  productNameExt: "",
  allergyPatternCode: 0,
  ppgIndicatorCode: "",
  hfpgIndicatorCode: "",
  labelerTypeCode: "",
  pricingSpreadCode: "",
  metricStrength: 0,
  strengthUnitMeasure: "",
  dosageForm: "",
  packageSize: 0,
  packageSizeUnitMeasure: "",
  packageQuantity: 0,
  repackagingCode: "",
  totalPackageQuantity: 0,
  desiCode: "",
  packageDescription: "",
  nextSmallerNdcSuffix: "",
  nextLargerNdcSuffix: "",
  innerPackCode: "",
  clinicPackCode: "",
  limitedDistributionCode: "",
  inactiveDate: "0001-01-01",
  gpi: { gpi: "", genericName: "", gppc: "" },
  manufacturerName: {
    manufacturerName: "",
    manufacturerAbbrevName: "",
    productDescriptionAbbreviation: "",
    drugNameCode: "",
    genericProductPackagingCode: "",
  },
  ingredients: [],
  awpUnitPrice: 0,
  awpPackagePrice: 0,
  wacUnitPrice: 0,
  wacPackagePrice: 0,
  directUnitPrice: 0,
  directPackagePrice: 0,
};

export const emptyDrugIngredient = {
  ingredientGenericTypeIdCode: "",
  ingredientGenericIdNumber: 0,
  ingredientStrength: 0,
  ingredientStrengthUnitOfMeasure: "",
  genericIngredientName: "",
  activeIngredientFlag: "",
};

export const emptyDrugSearch = {
  ndc: "",
  gpi: "",
  gppc: "",
  routeOfAdministration: "",
  rxOtcIndicator: "",
  multiSourceCode: "",
  productName: "",
  active: true,
  orderBy: ["ndc+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  drugsCount: 0,
  oldDrugId: "",
  newDrugId: "",
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  vm.awpPrices = vm.awpPrices || [];
  vm.wacPrices = vm.wacPrices || [];
  vm.directPrices = vm.directPrices || [];

  let awpPrice =
    vm.awpPrices.length > 0
      ? vm.awpPrices[0]
      : {
          packagePrice: 0,
          unitPrice: 0,
          priority: 1,
          effectiveDate: new Date(),
        };
  let wacPrice =
    vm.wacPrices.length > 0
      ? vm.wacPrices[0]
      : {
          packagePrice: 0,
          unitPrice: 0,
          priority: 1,
          effectiveDate: new Date(),
        };
  let directPrice =
    vm.directPrices.length > 0
      ? vm.directPrices[0]
      : {
          packagePrice: 0,
          unitPrice: 0,
          priority: 1,
          effectiveDate: new Date(),
        };

  let m = vm;

  awpPrice.unitPrice = m.awpUnitPrice;
  awpPrice.packagePrice = m.awpPackagePrice;
  wacPrice.unitPrice = m.wacUnitPrice;
  wacPrice.packagePrice = m.wacPackagePrice;
  directPrice.unitPrice = m.directUnitPrice;
  directPrice.packagePrice = m.directPackagePrice;

  m.labelerCode = m.labelerCode || 0;
  m.genericId = m.genericId || 0;
  m.ahfsccTherapeuticClass = m.ahfsccTherapeuticClass || 0;
  m.ahfsccTherapeuticClassExtended = m.ahfsccTherapeuticClassExtended || 0;
  m.allergyPatternCode = m.allergyPatternCode || 0;
  m.metricStrength = m.metricStrength || 0;
  m.packageSize = m.packageSize || 0;
  m.packageQuantity = m.packageQuantity || 0;
  m.totalPackageQuantity = m.totalPackageQuantity || 0;

  if (m.awpPrices.length > 0) m.awpPrices[0] = awpPrice;
  else m.awpPrices.push(awpPrice);
  if (m.wacPrices.length > 0) m.wacPrices[0] = wacPrice;
  else m.wacPrices.push(wacPrice);
  if (m.directPrices.length > 0) m.directPrices[0] = directPrice;
  else m.directPrices.push(directPrice);

  m.productName = (m.productName || "").toUpperCase();
  m.productNameExt = (m.productNameExt || "").toUpperCase();

  m.gpi.gpi = vm.gpiVm;
  m.gpi.genericName = vm.genericNameVm;
  m.gpi.gppc = vm.gppcVm;
  m.manufacturerName.manufacturerName = vm.manufacturerNameVm;
  m.manufacturerName.manufacturerAbbrevName = vm.manufacturerAbbrevNameVm;
  m.manufacturerName.drugNameCode = vm.drugNameCodeVm;
  m.manufacturerName.manufacturerName = (
    vm.manufacturerName.manufacturerName || ""
  ).toUpperCase();
  m.manufacturerName.manufacturerAbbrevName = (
    vm.manufacturerName.manufacturerAbbrevName || ""
  ).toUpperCase();
  m.manufacturerName.drugNameCode = (
    vm.manufacturerName.drugNameCode || ""
  ).toUpperCase();
  m.gpi.genericName = (vm.gpi.genericName || "").toUpperCase();

  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  if (vm.wacPrices.length > 0) {
    vm.wacUnitPrice = vm.wacPrices[0].unitPrice || 0;
    vm.wacPackagePrice = vm.wacPrices[0].packagePrice || 0;
  }
  if (vm.awpPrices.length > 0) {
    vm.awpUnitPrice = vm.awpPrices[0].unitPrice || 0;
    vm.awpPackagePrice = vm.awpPrices[0].packagePrice || 0;
  }
  if (vm.directPrices.length > 0) {
    vm.directUnitPrice = vm.directPrices[0].unitPrice || 0;
    vm.directPackagePrice = vm.directPrices[0].packagePrice || 0;
  }

  vm.gpiVm = (vm.gpi || {}).gpi || "";
  vm.genericNameVm = (vm.gpi || {}).genericName || "";
  vm.gppcVm = (vm.gpi || {}).gppc || "";
  vm.manufacturerNameVm = (vm.manufacturerName || {}).manufacturerName || "";
  vm.manufacturerAbbrevNameVm =
    (vm.manufacturerName || {}).manufacturerAbbrevName || "";
  vm.drugNameCodeVm = (vm.manufacturerName || {}).drugNameCode || "";

  return vm;
}
