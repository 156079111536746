import React from "react";
import styled from "styled-components";
import HelpLink from "../../common/ui/HelpLink";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";

function HomeSidebar({ expanded, expandHomeSidebar, children }) {
  const { userPrefs } = useUserPreferences();

  return (
    <>
      {expanded && (
        <StyledSidebarExpandedContainer data-testid="home-sidebar">
          <StyledFilterBoxHeader>
            <h4>
              <span>Build Information</span>
              <HelpLink path="/Home#build-information-section" />
            </h4>
            <button
              className="btn btn-link"
              onClick={() => expandHomeSidebar(false)}
            >
              Hide
            </button>
          </StyledFilterBoxHeader>
          <StyledFilterBoxContents>{children}</StyledFilterBoxContents>
        </StyledSidebarExpandedContainer>
      )}
      {!expanded && (
        <StyledSidebarCollapsedContainer
          data-testid="home-sidebar-collapsed"
          style={{
            position:
              userPrefs.coverageTrackingMode &&
              userPrefs.coverageTrackingVisibility
                ? "absolute"
                : "fixed",
          }}
          onClick={() => expandHomeSidebar(true)}
        >
          <i className="material-icons">monitor_heart</i>
          <p>Build Information</p>
        </StyledSidebarCollapsedContainer>
      )}
    </>
  );
}

const StyledSidebarCollapsedContainer = styled.div`
  position: fixed;
  left: 34px;
  top: 140px;
  height: 34px;
  padding: 7px 10px 7px 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border2);
  border-radius: 6px 6px 0px 0px;
  transform: rotate(90deg);
  transform-origin: top left;
  cursor: pointer;
  box-shadow: 4px -4px 10px var(--shadow-1);

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 16px;
    color: var(--menu-text);
  }

  i {
    margin-right: 6px;
    color: var(--menu-text);
    transform: rotate(90deg);
  }
`;

const StyledSidebarExpandedContainer = styled.div`
  position: fixed;
  left: 0;
  top: 140px;
  width: 226px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border2);
  border-radius: 6px 6px 0px 0px;
  box-shadow: 4px 4px 10px var(--shadow-1);

  h4 {
    color: var(--text-dark);
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
  }
`;

const StyledFilterBoxHeader = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

const StyledFilterBoxContents = styled.div`
  border-top: 1px solid var(--elevated-border2);
  padding: 10px 20px;
`;

export default HomeSidebar;
