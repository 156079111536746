import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { suppressEnterKey } from "../../../services/General";

function InstantSearchInput({ id, onChange, value, hideBackground = false }) {
  function handleIconClick() {
    if (_.trim(value) !== "") {
      onChange("");
    }
  }

  return (
    <StyledSearchBar>
      <div
        className={hideBackground ? "form-group hide-background" : "form-group"}
      >
        <div className="field">
          <input
            id={id}
            type="text"
            name={id}
            placeholder="Find"
            className="form-control"
            onChange={({ target }) => onChange(target.value)}
            onKeyDown={(e) => suppressEnterKey(e)}
            value={value}
          ></input>
        </div>
        <i
          className="material-icons"
          title={_.trim(value) === "" ? "Find" : "Clear"}
          onClick={handleIconClick}
        >
          {_.trim(value) === "" ? "search" : "close"}
        </i>
      </div>
    </StyledSearchBar>
  );
}

const StyledSearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  form {
    width: 100%;
  }

  div.form-group {
    margin-top: -2px;
    padding: 0 11px;
    height: 44px;
    width: 100%;
    background: var(--elevated-bg);
    border: 1px solid var(--elevated-border);
    border-radius: 3px;
    display: flex;
    align-items: center;

    &.hide-background {
      background: transparent;
      border: 1px solid transparent;
    }

    i {
      cursor: pointer;
      font-size: 24px;
      color: var(--text-light);
    }

    div.field {
      width: 250px;

      @media only screen and (max-width: 767px) {
        flex: 1;
        width: auto;
      }

      input {
        width: 100%;
        margin-bottom: 3px;
        font-size: 16px;
        line-height: 20px;
        background-color: var(--input-bg);
        color: var(--text-light);
        outline: none !important;
        padding: 2px 0;
        border: 0;
        border-bottom: 1px solid var(--text-light);
      }
    }
  }
`;

export default InstantSearchInput;
