import React from "react";
import _ from "lodash";
import MultiSelectInput from "../input/MultiSelectInput";
import SelectInput from "../input/SingleSelect";
import TextInput from "../input/TextInput";
import {
  convertUTCDateToLocal,
  getNumberFromParameterValue,
  getParameterValueFromNumber,
} from "../../../services/General";
import DatePickerInput from "../input/DatePickerInput";
import CheckboxInput from "../input/CheckboxInput";

function FilterValueFieldByDataType({
  name,
  id,
  value,
  label,
  error,
  disabled,
  onChange,
  placeholder,
  field,
  operator,
  displayAsTextControl,
}) {
  function handleSingleDropdownChange(option) {
    // Need to mimic a normal onChange event, regardless of control used, so caller can handle it.
    onChange({ target: { name: name, value: option.value } });
  }

  function handleMultiDropdownChange(fields) {
    // Need to mimic a normal onChange event, regardless of control used, so caller can handle it.
    //   Convert the selected array into a comma-separated string.
    const valStr = (fields || []).map((f) => f.value).join(", ");
    onChange({ target: { name: name, value: valStr } });
  }

  function handleDateChange(date) {
    // Convert the local date to UTC in ISO format before saving since this is the format expected
    //   by this component and by the API.

    // Need to mimic a normal onChange event, regardless of control used, so caller can handle it.
    const dateVal = date === null ? null : new Date(date).toISOString();
    onChange({ target: { name: name, value: dateVal } });
  }

  function handleNumberChange({ target }) {
    // Prepend n: to number values so api knows how to use this in a filter (instead of string)
    onChange({
      target: {
        name: target.name,
        value:
          _.trim(target.value) === ""
            ? ""
            : getParameterValueFromNumber(target.value),
      },
    });
  }

  function handleBooleanChange({ target }) {
    onChange({
      target: {
        name: target.name,
        value: target.checked,
      },
    });
  }

  // Does field have a list of valid values? If so, some operators will show dropdowns
  if ((field?.validValues || []).length > 0) {
    // Build the list of valid values
    const values = field.validValues
      .map((v) => {
        return { value: v.ValueName, label: v.ValueDescription };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));

    // Build a single select for EQUALS and NOTEQUALS operators.
    if (["Equals", "NotEqual"].indexOf(operator) >= 0) {
      const selectedValue = {
        value: value,
        label: values.find((v) => v.value === value)?.label,
      };
      return (
        <SelectInput
          id={id}
          name={name}
          label={label}
          options={values}
          value={selectedValue}
          onChange={handleSingleDropdownChange}
          placeholder={placeholder}
          error={error}
        />
      );
    }
    // Build a multi-select for IN and NOT IN operators
    else if (["In", "NotIn"].indexOf(operator) >= 0) {
      // Convert values from comma-separated to array of options
      let selectedValues = [];
      if (_.trim(value) !== "") {
        const valueArray = (value || "").split(", ");
        selectedValues = valueArray.map((s) => {
          return {
            value: _.trim(s),
            label: values.find((v) => v.value === s)?.label,
          };
        });
      }

      return (
        <MultiSelectInput
          id={id}
          name={name}
          label={label}
          options={values}
          value={selectedValues}
          onChange={handleMultiDropdownChange}
          placeholder={placeholder}
          error={error}
        />
      );
    }
  }

  // If field is a number, render a number field
  if (
    field?.datatype === "Int32" ||
    field?.datatype === "Decimal" ||
    field?.datatype === "Byte"
  ) {
    return (
      <TextInput
        id={id}
        label={label}
        onChange={handleNumberChange}
        placeholder={placeholder}
        name={name}
        value={getNumberFromParameterValue(value)}
        error={error}
        disabled={disabled}
        isNumber={true}
      />
    );
  }

  // If field is a dateonly or datetime value, show a date control and use
  //   the calculated field value in case a calculation was performed (e,g, "today").
  if (
    (field?.datatype === "DateOnly" || field?.datatype === "DateTime") &&
    !displayAsTextControl
  ) {
    return (
      <DatePickerInput
        id={id}
        label={label}
        name={name}
        value={value === null ? undefined : convertUTCDateToLocal(value)}
        placeholder={placeholder}
        onChange={handleDateChange}
        error={error}
        disabled={disabled}
        showTimeInput={field?.datatype === "DateTime"}
      />
    );
  }

  // If field is a boolean, render a checkbox field
  if (field?.datatype === "Boolean") {
    return (
      <CheckboxInput
        id={id}
        label={label}
        onChange={handleBooleanChange}
        placeholder={placeholder}
        name={name}
        checked={value === true || value === "true"}
        error={error}
        disabled={disabled}
      />
    );
  }

  // Use a text field for all other data types
  return (
    <TextInput
      id={id}
      label={label}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      value={value}
      error={error}
      disabled={disabled}
    />
  );
}

export default FilterValueFieldByDataType;
