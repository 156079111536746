import React, { useState, useEffect } from "react";
import ProcessRunHistoryList from "./ProcessRunHistoryList";
import ProcessRunHistorySearchForm from "./ProcessRunHistorySearchForm";
import Spinner from "../../common/ui/Spinner";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useProcessHistory } from "../../../contexts/ProcessHistoryContext";
import {
  apiDownloadProcessFile,
  apiLoadProcessHistory,
  apiRollbackResults,
} from "../../../api/ProcessHistoryApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import { StyledHeaderRowDiv } from "../../common/layout/CommonStyledControls";
import useApi from "../../../hooks/useApi";
import {
  createViewModel,
  emptyProcessHistorySearch,
} from "../../../viewmodels/processHistoryVm";
import {
  decodeBase64Stream,
  download,
  getExtensionFromFilename,
} from "../../../services/General";
import { notifySuccess } from "../../../services/NotificationService";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import HelpLink from "../../common/ui/HelpLink";

function ProcessRunHistory() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { processHistoryData, setProcessHistoryData } = useProcessHistory();
  const { loading, api: apiLoad } = useApi(apiLoadProcessHistory);
  const { loading: downloading, api: apiDownload } = useApi(
    apiDownloadProcessFile
  );
  const { loading: rollingBack, api: apiRollback } = useApi(apiRollbackResults);
  const [loadData, setLoadData] = useState(true);
  const { isMobileSize } = useMobile();
  const [rollbackId, setRollbackId] = useState(null);
  const [showRollbackModal, setShowRollbackModal] = useState(false);

  let processHistory = [];
  if (processHistoryData && processHistoryData.processHistory) {
    processHistory = processHistoryData.processHistory;
  }

  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadProcessHistory();
    }
  }, [auth.authenticated, loadData]);

  async function loadProcessHistory() {
    apiLoad.call(
      processHistoryData.search,
      (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setProcessHistoryData({
          type: ContextProviderActions.loadProcessHistory,
          payload: {
            processHistory: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setProcessHistoryData({
      type: ContextProviderActions.saveProcessHistorySearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyProcessHistorySearch,
      showAdvancedFilter: isMobileSize
        ? false
        : processHistoryData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = processHistoryData.search.orderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = processHistoryData.search.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...processHistoryData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...processHistoryData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...processHistoryData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...processHistoryData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...processHistoryData.search,
      [target.name]: target.value,
    });
  }

  function handleRunTypeChanged(option) {
    setSearchChanges({
      ...processHistoryData.search,
      runType: option.value,
    });
  }

  function handleDateRangeChange(dateRange, startDate, endDate) {
    setSearchChanges({
      ...processHistoryData.search,
      dateRange: dateRange,
      startDate: startDate,
      endDate: endDate,
    });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...processHistoryData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...processHistoryData.search, endDate: date });
  }

  async function handleDownloadProcessFile(id) {
    apiDownload.call(id, async (result) => {
      const filename = result.FileName;
      const ext = getExtensionFromFilename(filename);
      const contents = await decodeBase64Stream(result.FileContents);
      download(contents, filename, ext);
      notifySuccess("File downloaded successfully");
    });
  }

  async function handleRollbackProcess(id) {
    setRollbackId(id);
    setShowRollbackModal(true);
  }

  async function performRollbackProcessFile() {
    setShowRollbackModal(false);
    apiRollback.call(rollbackId, async (result) => {
      setLoadData(true);
      notifySuccess("Rollback completed successfully");
    });
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (processHistoryData.search.description !== "") numFilters++;
    if (processHistoryData.search.runType !== "") numFilters++;
    if (processHistoryData.search.reportName !== "") numFilters++;
    if (processHistoryData.search.runBy !== "") numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <ConfirmDialog
        title="Rollback Process Results"
        question={`This will rollback all history and saved values for the process to this point in history. Are you sure you want to continue?`}
        showModal={showRollbackModal}
        onNo={() => setShowRollbackModal(false)}
        onYes={performRollbackProcessFile}
      />

      <StyledHeaderRowDiv>
        <h1>Process Run History</h1>
        <div>
          {((processHistoryData.search.showAdvancedFilter && !isMobileSize) ||
            !processHistoryData.search.showAdvancedFilter) && (
            <HelpLink path="/Reports/Process-Run-History-Screen" label="Help" />
          )}
        </div>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search process run history"
        search={processHistoryData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              processHistoryData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={processHistoryData.search}
            setSearch={setSearchChanges}
            helpLink="/Reports/Process-Run-History-Screen&anchor=filters"
          >
            <ProcessRunHistorySearchForm
              errors={errors}
              search={processHistoryData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onRunTypeChanged={handleRunTypeChanged}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              onDateRangeChange={handleDateRangeChange}
            />
          </GridAdvancedFilter>
        </div>

        {processHistoryData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }} data-testid="process-history-list">
            {loading || loadData || downloading || rollingBack ? (
              <Spinner />
            ) : (
              <ProcessRunHistoryList
                processHistory={processHistory}
                search={processHistoryData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={processHistoryData.count}
                onDownloadFile={handleDownloadProcessFile}
                onRollbackProcess={handleRollbackProcess}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default ProcessRunHistory;
