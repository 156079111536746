import React from "react";
import styled from "styled-components";
import { useTestCoverageTracking } from "../../../contexts/TestCoverageTrackingContext";
import { formatDecimal } from "../../../services/General";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import CoverageTrackingRecordEntryPager from "./CoverageTrackingRecordEntryPager";
import { StyledNoResultsDiv } from "../layout/CommonStyledControls";

function CoverageTrackingRecord({ num, hideDetails, setHideDetails }) {
  const { testCoverageTrackingData, setTestCoverageTrackingData } =
    useTestCoverageTracking();

  const record = testCoverageTrackingData.selectedRecord || null;

  function handleRowClick(e) {
    if (e) e.preventDefault();

    setTestCoverageTrackingData({
      type: ContextProviderActions.setTestCoverageTrackingSelectedRecord,
      payload: null,
    });
  }

  return (
    <>
      <table className="table table-clickable-rows">
        <thead>
          <tr>
            <th id="num" style={{ width: "10%" }}>
              #
            </th>
            <th id="apiName" style={{ width: "60%" }}>
              API Name
            </th>
            <th id="coveragePercent" style={{ width: "25%" }}>
              Coverage
            </th>
            <th
              style={{
                width: "60px",
                maxWidth: "25%",
                textAlign: "center",
              }}
            >
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              cursor: "pointer",
              borderLeft: "4px solid var(--table-body-text)",
              backgroundColor: "var(--table-hover-row-bg)",
            }}
            onClick={(e) => handleRowClick(e)}
          >
            <td style={{ fontWeight: "bold" }}>{num}</td>
            <td style={{ fontWeight: "bold" }} className="force-wrap">
              {record.apiName}
            </td>
            <td style={{ fontWeight: "bold" }}>
              {hideDetails
                ? `${
                    record.coveragePercentHideDetails === 0
                      ? 0
                      : formatDecimal(record.coveragePercentHideDetails, 1)
                  }%`
                : `${
                    record.coveragePercent === 0
                      ? 0
                      : formatDecimal(record.coveragePercent, 1)
                  }%`}
            </td>
            <td style={{ textAlign: "right" }}>
              <i className="material-icons" style={{ marginLeft: "auto" }}>
                expand_more
              </i>
            </td>
          </tr>
        </tbody>
      </table>
      <StyledContainer>
        {(record.metadata?.entries || []).length === 0 ? (
          <StyledNoResultsDiv>
            <p>
              <i className="material-icons">search_off</i>
            </p>
            <p>No entries found</p>
          </StyledNoResultsDiv>
        ) : (
          <CoverageTrackingRecordEntryPager
            metadata={record.metadata}
            hideDetails={hideDetails}
            setHideDetails={setHideDetails}
          />
        )}
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  margin: 20px 0;
`;

export default CoverageTrackingRecord;
