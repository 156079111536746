import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyReportTemplate,
  emptyReportTemplateSearch,
} from "../viewmodels/reportTemplatesVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadReportTemplates:
      return {
        ...state,
        templates: action.payload.templates,
        count: action.payload.count,
      };

    case ContextProviderActions.saveReportTemplateSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadReportTemplate:
      return {
        ...state,
        template: action.payload,
      };

    case ContextProviderActions.setReportTemplateCache:
      return {
        ...state,
        templateListCache: action.payload,
      };

    case ContextProviderActions.loadRuleTableDefinitionList:
      return {
        ...state,
        ruleTableDefinitionList: action.payload,
      };

    case ContextProviderActions.setReportRuleTableCache:
      return {
        ...state,
        ruleTableListCache: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in ReportTemplatesContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  templates: [],
  template: emptyReportTemplate,
  search: emptyReportTemplateSearch,
  count: 0,
  templateListCache: [],
  ruleTableDefinitionList: [],
  ruleTableListCache: [],
};

const ReportTemplates = createContext({
  reportTemplatesData: initialState,
  setReportTemplatesData: () => null,
});

// eslint-disable-next-line react/prop-types
const ReportTemplatesProvider = ({ children }) => {
  const [reportTemplatesData, setReportTemplatesData] = useReducer(
    reducer,
    initialState
  );

  return (
    <ReportTemplates.Provider
      value={{ reportTemplatesData, setReportTemplatesData }}
    >
      {children}
    </ReportTemplates.Provider>
  );
};

export default ReportTemplatesProvider;
export const useReportTemplates = () => useContext(ReportTemplates);
