import React, { useEffect, useRef, useState } from "react";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import {
  StyledCard,
  StyledCardLeafNodeList,
  StyledCardValue,
  StyledDescription,
  StyledNodeDiv,
  StyledProcessedIndicator,
} from "./RuleMapCommonStyles";
import RuleMapZoomedOutNode from "./RuleMapZoomedOutNode";
import RuleMapEntityLink from "./RuleMapEntityLink";
import RuleMapLogsLink from "./RuleMapLogsLink";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
  NodeTypes,
} from "./RuleMapDataCommon";
import RuleMapCopyContentsLink from "./RuleMapCopyContentsLink";

export const DefaultNodeSize = { width: 450, height: 70 };

function RuleMapArtifactPreReqsNode({ data }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const [detailLevel] = useState(1);
  const { ruleMapsData } = useRuleMaps();
  const [nodeWidth] = useState(DefaultNodeSize.width);
  const [nodeHeight] = useState(DefaultNodeSize.height);
  const divRef = useRef(null);

  useEffect(() => {
    let doNodeUpdate = false;
    if (data.layoutDirection !== ruleMapsData?.layoutDirection) {
      doNodeUpdate = true;
    }
    if (detailLevel !== ruleMapsData?.detailLevel) {
      doNodeUpdate = true;
    }

    if (doNodeUpdate) {
      updateNodeInternals(data.id);
    }
  }, [
    ruleMapsData?.detailLevel,
    data.layoutDirection,
    ruleMapsData?.layoutDirection,
  ]);

  const colors = getBadgeColorsFromType(
    "CodeArtifact",
    data.isActualNodeLink,
    data.isProcessed
  );

  const showDetails =
    ruleMapsData?.detailLevel === 3 || ruleMapsData?.selectedNodeId === data.id;

  if (ruleMapsData?.detailLevel === 1 && !showDetails) {
    return <RuleMapZoomedOutNode data={data} />;
  }

  const leafNodes = (data.leafNodes || []).sort(
    (a, b) => a.type.localeCompare(b.type) || a.itemKey.localeCompare(b.itemKey)
  );
  const leafNodeCount = leafNodes.length;
  const isOutput =
    data.type === "CodeArtifactOutput" ||
    data.type === NodeTypes.CodeArtifactOutput;

  return (
    <StyledNodeDiv width={nodeWidth} height={nodeHeight}>
      {isOutput && !data.isLeafNode && (
        <Handle
          type="target"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Left
              : Position.Top
          }
        />
      )}
      <StyledCard colors={colors} ref={divRef}>
        {!data.isActualNodeLink && data.isProcessed && (
          <StyledProcessedIndicator>
            <span className="material-symbols-outlined">electric_bolt</span>
          </StyledProcessedIndicator>
        )}
        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={colors}
        >
          <span>{showDetails ? data.displayKey : data.itemKey}</span>
          <span className="charblock">{isOutput ? "OUTPUT" : "INPUT"}</span>
        </StyledDescription>
        {showDetails && data.module && data.module !== null && (
          <StyledDescription
            className="flex-row-with-wrap-and-justify"
            colors={colors}
            style={{ fontSize: "13px" }}
          >
            Module: {data.module.name}
          </StyledDescription>
        )}
        <StyledCardValue>
          {`${leafNodeCount} ${isOutput ? "output" : "input"} ${
            leafNodeCount === 1 ? "value" : "values"
          }`}
        </StyledCardValue>

        {showDetails && leafNodeCount > 0 && (
          <StyledCardLeafNodeList>
            {leafNodes.map((n, lidx) => (
              <li key={`leaf-${lidx}-${n.itemKey}`}>
                <StyledDescription
                  colors={getBadgeColorsFromType(n.type)}
                  style={{ columnGap: "10px", marginBottom: "5px" }}
                >
                  <RuleMapCopyContentsLink
                    show={showDetails}
                    text={divRef.current?.innerText}
                  />
                  <span className="charblock">
                    {getNodeTypeAbbreviation(n.type, false)}
                  </span>
                  <span>{n.itemKey}</span>
                  <span
                    className={`option-member-name ${
                      (n.value || "").length > 100 ? "full-width" : ""
                    }`}
                  >
                    {(n.links || []).length > 0 ? (
                      <RuleMapEntityLink links={n.links} />
                    ) : (
                      <>{n.value}</>
                    )}
                  </span>
                </StyledDescription>
              </li>
            ))}
          </StyledCardLeafNodeList>
        )}
        <RuleMapLogsLink logs={data.logs} title={data.itemKey} />
      </StyledCard>

      {!data.isRootNode && (
        <Handle
          type="source"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Right
              : Position.Bottom
          }
        />
      )}
    </StyledNodeDiv>
  );
}

export default RuleMapArtifactPreReqsNode;
