import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useReports } from "../../../contexts/ReportsContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import ReportFilterSection from "./ReportFilterSection";

import {
  emptyReport,
  emptyReportResultsSearch,
  createViewModel,
  fromViewModel,
  dashboardPanelGraphTypes,
  getFieldNameWithEntityType,
} from "../../../viewmodels/reportsVm";

import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import {
  apiLoadReport,
  apiLoadDataSourcesAll,
  apiLoadFieldsForDataSource,
  apiAddReport,
  apiUpdateReport,
} from "../../../api/ReportsApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Spinner from "../../common/ui/Spinner";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import SelectInput from "../../common/input/SingleSelect";
import TextInput from "../../common/input/TextInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import {
  notifySuccess,
  notifyWarn,
  notifyError,
} from "../../../services/NotificationService";
import {
  REPORT_TEMPLATES_RULE_ID,
  handleCollapseExpandAll,
  handleCollapseExpandSection,
  shareModeTypes,
} from "../../../services/General";
import ReportResults from "./ReportResults";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import { apiLoadReportTemplatesAll } from "../../../api/ReportTemplatesApi";
import { useReportTemplates } from "../../../contexts/ReportTemplatesContext";
import ReportSelectFieldChipsSection from "./ReportSelectFieldChipsSection";
import ReportOrderGroupByFieldChipsSection from "./ReportOrderGroupByFieldChipsSection";
import { apiLoadAllRuleTablesForRule } from "../../../api/RuleTableApi";
import HelpLink from "../../common/ui/HelpLink";
import MultiSelectInput from "../../common/input/MultiSelectInput";

function Report() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { reportsData, setReportsData } = useReports();
  const { reportTemplatesData, setReportTemplatesData } = useReportTemplates();
  const { loading, api: apiLoad } = useApi(apiLoadReport);
  const { loading: loadingDataSources, api: apiLoadDataSources } = useApi(
    apiLoadDataSourcesAll
  );
  const { loading: loadingFields, api: apiLoadFields } = useApi(
    apiLoadFieldsForDataSource
  );
  const { loading: loadingTemplates, api: apiLoadTemplates } = useApi(
    apiLoadReportTemplatesAll
  );
  const { loading: loadingRuleTables, api: apiLoadTemplateRuleTables } = useApi(
    apiLoadAllRuleTablesForRule
  );
  const { loading: addingReport, api: apiAdd } = useApi(apiAddReport);
  const { loading: updatingReport, api: apiUpdate } = useApi(apiUpdateReport);
  const [errors, setErrors] = useState({});
  const [lastSavedChanges, setLastSavedChanges] = useState(emptyReport);
  const [changes, setChanges] = useState(emptyReport);
  const [dataSourceSelection, setDataSourceSelection] = useState({});
  const [showConfirmDatasourceModal, setShowConfirmDatasourceModal] =
    useState(false);
  const [joinEntitySelection, setJoinEntitySelection] = useState([]);
  const [showConfirmJoinEntityModal, setShowConfirmJoinEntityModal] =
    useState(false);

  const [fields, setFields] = useState([]);
  const [joinEntityTypes, setJoinEntityTypes] = useState([]);

  // Editable is false unless user is report owner/admin or this is a new report (id does not exist as param)
  const [editable, setEditable] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [collapsedState, setCollapsedState] = useState([
    { name: "Summary", collapsed: false },
    { name: "Data", collapsed: false },
    { name: "Dashboard Panel", collapsed: false },
    { name: "Report Format", collapsed: false },
    { name: "Run Report", collapsed: false },
  ]);

  const report = (reportsData && reportsData.report) || {};
  const resId = params && params.id;

  let filteredShareModeTypes = [...shareModeTypes];
  if (!auth.isAdmin)
    filteredShareModeTypes = filteredShareModeTypes.filter(
      (t) => t.value !== "Featured"
    );

  useEffect(() => {
    if (auth.authenticated) {
      loadReportAndDataSourceList();

      // Reset result search params on load of report
      setReportsData({
        type: ContextProviderActions.saveReportResultsSearch,
        payload: { ...emptyReportResultsSearch },
      });
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (reportsData.report) {
      setChanges(reportsData.report);
    } else {
      setChanges(emptyReport);
    }
  }, [reportsData.report]);

  useEffect(() => {
    setHasChanges(
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
        JSON.stringify(_.cloneDeep(changes))
    );
  }, [lastSavedChanges, changes]);

  async function loadReportAndDataSourceList() {
    let dataSourceList = reportsData.sourceListCache || [];
    let templateList = reportTemplatesData.templateListCache || [];
    let ruleTableList = reportTemplatesData.ruleTableListCache || [];

    if ((dataSourceList || []).length === 0) {
      dataSourceList = await loadDataSourceList();

      // Save the list of data sources in the cache
      setReportsData({
        type: ContextProviderActions.setReportDataSourcesCache,
        payload: dataSourceList,
      });
    }

    if ((templateList || []).length === 0) {
      templateList = await loadTemplateList();

      // Save the list of data sources in the cache
      setReportTemplatesData({
        type: ContextProviderActions.setReportTemplateCache,
        payload: templateList,
      });
    }

    if ((ruleTableList || []).length === 0) {
      ruleTableList = await loadTemplateRuleTableList();

      // Save the list of rule tables in the cache
      setReportTemplatesData({
        type: ContextProviderActions.setReportRuleTableCache,
        payload: ruleTableList,
      });
    }

    await loadReport(dataSourceList, templateList, ruleTableList);
  }

  async function loadDataSourceList() {
    const dataSourceList = await apiLoadDataSources.call(
      null,
      async (result) => {
        let dsResult = [...result] || [];

        // Filter out admin data sources for non-admins
        if (!auth.isAdmin) {
          dsResult = dsResult.filter(
            (c) => c.reportableItemAllowableRole !== "Admin"
          );
        }

        const dsList = (dsResult || [])
          .map((c) => {
            return {
              value: c.id,
              label: c.label,
              entityType: c.entityType,
              joinList: c.joinEntityTypes || "Discount,Group,Member",
            };
          })
          .sort((a, b) => (a.label > b.label ? 1 : -1));

        return dsList;
      }
    );

    return dataSourceList || [];
  }

  async function loadTemplateList() {
    const templates = await apiLoadTemplates.call(null, async (result) => {
      let dsResult = ([...result.resources] || []).sort((a, b) =>
        a.name > b.name ? 1 : -1
      );

      let dsList = [
        { value: "-1", label: "(Select a template)", entityType: "" },
      ];

      for (let i = 0; i < dsResult.length; i++) {
        dsList.push({
          value: dsResult[i].id,
          label: dsResult[i].name,
          entityType: dsResult[i].entityType,
          externalDataRuleTableDefinitionId:
            dsResult[i].externalDataRuleTableDefinitionId,
          runValuesToSave: dsResult[i].runValuesToSave,
        });
      }

      return dsList;
    });

    return templates || [];
  }

  async function loadTemplateRuleTableList() {
    const ruleTables = await apiLoadTemplateRuleTables.call(
      REPORT_TEMPLATES_RULE_ID,
      async (result) => {
        let dsResult = ([...result.resources] || []).sort((a, b) =>
          a.name > b.name ? 1 : -1
        );

        let dsList = [
          { value: "-1", label: "(Select a rule table)", entityType: "" },
        ];

        for (let i = 0; i < dsResult.length; i++) {
          dsList.push({
            value: dsResult[i].id,
            label: dsResult[i].key,
            ruleTableDefinitionId: dsResult[i].ruleTableDefinitionId,
          });
        }

        return dsList;
      }
    );

    return ruleTables || [];
  }

  async function loadFieldsForDataSourceAndCache(dataSourceList, entityType) {
    // Assume that data sources have already been loaded and cached.
    const dataSources = [...dataSourceList];
    const source = dataSources.find((s) => s.entityType === entityType);
    if (!source) {
      // Data source not found so just load the fields from the api
      console.log(
        `Data source ${entityType} not found in cache in loadFieldsForDataSourceAndCache!`
      );
      return await loadFieldsForDataSource(entityType);
    }

    // Do we already have fields for this datasource? If not, load them from the API.
    let fields = source.fields;

    if ((fields || []).length === 0) {
      fields = await loadFieldsForDataSource(entityType);
      source.fields = fields;

      // Save these fields with the data source in the cache
      setReportsData({
        type: ContextProviderActions.setReportDataSourcesCache,
        payload: dataSources,
      });
    }

    const retFields = [...fields];
    return retFields;
  }

  async function loadFieldsForDataSource(entityType) {
    const fieldList = apiLoadFields.call(entityType, async (result) => {
      const list = (result.properties || [])
        .map((c) => {
          return {
            value: c.id,
            label: getFieldNameWithEntityType(entityType, c.propertyLabel),
            propertyLabel: c.propertyLabel,
            entityType: entityType,
            datatype: c.propertyType,
            valueType: c.valueType,
            allowFiltering: c.allowFiltering,
            filterOnly: c.filterOnly,
            validValues: [...c.validValues],
          };
        })
        .sort((a, b) => (a.propertyLabel > b.propertyLabel ? 1 : -1));

      return list;
    });

    return fieldList || [];
  }

  async function loadJoinFieldsForJoinEntitiesAndCache(
    dataSourceList,
    joinEntities
  ) {
    if ((joinEntities || []).length === 0) return [];
    let joinFields = [];
    let newFields = [];

    for (let i = 0; i < joinEntities.length; i++) {
      newFields = await loadFieldsForDataSourceAndCache(
        dataSourceList,
        joinEntities[i]
      );
      joinFields = joinFields.concat(newFields);
    }

    return joinFields;
  }

  async function loadReport(dataSourceList, templateList, ruleTableList) {
    if (!resId) {
      const dsFields = await loadFieldsForDataSourceAndCache(
        dataSourceList,
        "Claim"
      );
      setFields(dsFields);
      const vm = createViewModel(
        emptyReport,
        dataSourceList,
        templateList,
        ruleTableList,
        dsFields
      );
      setReportsData({
        type: ContextProviderActions.loadReport,
        payload: vm,
      });
      setChanges(vm);
      setLastSavedChanges(vm);
      setEditable(true);

      // Set join list for selected data source
      const entities = getJoinEntityTypeOptionsFromArray(
        dataSourceList,
        vm.dataSourceVm.joinList
      );
      setJoinEntityTypes(entities);
      return;
    }

    apiLoad.call(resId, async (result) => {
      await postLoadReportAndDataSources(
        result,
        dataSourceList,
        templateList,
        ruleTableList
      );
    });
  }

  function getJoinEntityTypeOptionsFromArray(dataSourceList, entities) {
    const joinList = (entities || [])
      .map((c) => {
        return {
          value: c,
          label: dataSourceList.find((d) => d.entityType === c)?.label ?? c,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));

    return joinList;
  }

  async function postLoadReportAndDataSources(
    reportResult,
    dataSourceList,
    templateList,
    ruleTableList
  ) {
    // Report viewmodel needs the datasource list
    let vm = { ...emptyReport };
    if (!reportResult) {
      notifyError("Report does not exist");
    } else {
      let dsFields = await loadFieldsForDataSourceAndCache(
        dataSourceList,
        reportResult.entityType
      );

      // Set join list for selected data source
      const dataSourceVm = (dataSourceList || []).find(
        (c) => c.value === reportResult.entityType
      );
      const entities = getJoinEntityTypeOptionsFromArray(
        dataSourceList,
        dataSourceVm.joinList
      );
      setJoinEntityTypes(entities);

      // Load any needed join entity fields
      const joinFields = await loadJoinFieldsForJoinEntitiesAndCache(
        dataSourceList,
        reportResult.joinEntityTypes
      );
      dsFields = dsFields.concat(joinFields);

      setFields(dsFields);
      vm = createViewModel(
        { ...emptyReport, ...reportResult },
        dataSourceList,
        templateList,
        ruleTableList,
        dsFields
      );

      // jon, 1/2/23: Make report editable if user is the owner or an admin
      if (auth.userId === vm.ownerUserId || auth.isAdmin) {
        setEditable(true);
      }

      // If a report template is selected, set the state variable so we can filter rule tables
      if (vm.reportTemplateId !== "") {
        setSelectedTemplate(
          templateList.find((t) => t.value === vm.reportTemplateId)
        );
      } else {
        setSelectedTemplate(null);
      }
    }

    setReportsData({
      type: ContextProviderActions.loadReport,
      payload: vm,
    });
    setChanges(vm);
    setLastSavedChanges(vm);
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleShareModeChange(option) {
    let changed = { ...changes, shareModeVm: option };
    setChanges(changed);
  }

  function handleJoinEntitiesChanged(joinEntities) {
    const oldJoinEntities = [...changes.joinEntities];

    // Have any joins been removed?
    const removedEntities = oldJoinEntities.filter(
      (j) => joinEntities.findIndex((k) => k.value === j.value) < 0
    );
    if (removedEntities.length > 0) {
      // If so and some fields, order/group by, or filter are using the join entity type, ask for confirmation. Note: should be only one removed at a time.
      if (
        changes.selectFields.findIndex(
          (f) => f.entityType === removedEntities[0].value
        ) >= 0 ||
        changes.orderByFields.findIndex(
          (f) => f.entityType === removedEntities[0].value
        ) >= 0 ||
        changes.filters.findIndex(
          (f) => f.entityType === removedEntities[0].value
        ) >= 0
      ) {
        // Remember the user's selection while opening modal so we can set it after confirmation.
        setJoinEntitySelection(joinEntities);
        setShowConfirmJoinEntityModal(true);
      } else {
        performJoinEntityChange(joinEntities);
      }
    } else {
      performJoinEntityChange(joinEntities);
    }
  }

  async function performJoinEntityChange(joinEntities) {
    setShowConfirmJoinEntityModal(false);

    // Remove any entities with these entity types. Add the main data source entity so we don't remove those!
    const joinEntityTypes = (joinEntities || []).map((j) => j.value);
    let allEntities = [];
    allEntities.push(changes.dataSourceVm.value);
    allEntities = allEntities.concat(joinEntityTypes);

    const _changes = { ...changes };

    _changes.selectFields = _changes.selectFields.filter(
      (f) => allEntities.findIndex((j) => j === f.entityType) >= 0
    );
    _changes.orderByFields = _changes.orderByFields.filter(
      (f) => allEntities.findIndex((j) => j === f.entityType) >= 0
    );
    _changes.filters = _changes.filters.filter(
      (f) => allEntities.findIndex((j) => j === f.entityType) >= 0
    );

    _changes.joinEntities = joinEntities;

    setChanges(_changes);

    // Remove all join fields from the field list that are not the main data source because we will add them back in below. Don't want to double add.
    let _fields = [...fields];
    _fields = _fields.filter(
      (f) => f.entityType === changes.dataSourceVm.value
    );

    // Do we have all of the fields we need for the selected join entities? If not, load any missing ones and cache them.
    const dataSourceList = reportsData.sourceListCache;
    const joinFields = await loadJoinFieldsForJoinEntitiesAndCache(
      dataSourceList,
      joinEntityTypes
    );
    _fields = _fields.concat(joinFields);
    setFields(_fields);

    setJoinEntitySelection([]);
  }

  function setFieldChips(value) {
    setChanges({ ...changes, selectFields: value });
  }

  function setOrderChips(value) {
    setChanges({ ...changes, orderByFields: value });
  }

  function setConditions(value) {
    setChanges({ ...changes, filters: value });
  }

  function handleDataSourceChange(option) {
    // Since this will reset all field, order, filter, etc. selections, confirm this action with user.
    // Only do this if any fields, orders, or conditions have values.
    if (
      changes.selectFields.length === 0 &&
      changes.orderByFields.length === 0 &&
      changes.filters.length === 0
    ) {
      performDatasourceChange(undefined, option);
    } else {
      // Remember the user's selection while opening modal so we can set it after confirmation.
      setDataSourceSelection(option);
      setShowConfirmDatasourceModal(true);
    }
  }

  function handleReportFormatChange(option) {
    setChanges({ ...changes, reportFormat: option });
  }

  async function performDatasourceChange(
    event,
    selectedSource,
    dataSourceList
  ) {
    setShowConfirmDatasourceModal(false);

    const selection = selectedSource || dataSourceSelection;
    const sourceList = dataSourceList || reportsData.sourceListCache;

    setChanges({
      ...changes,
      dataSourceVm: selection,
      joinEntities: [],
      selectFields: [],
      orderByFields: [],
      filters: [],
      reportTemplateId: emptyReport.reportTemplateId,
      reportTemplateVm: emptyReport.reportTemplateId,
      dashboardValueSelect: emptyReport.dashboardValueSelect,
      dashboardPanelFieldDisplayName:
        emptyReport.dashboardPanelFieldDisplayName,
    });

    // Set join list for selected data source
    const entities = getJoinEntityTypeOptionsFromArray(
      sourceList,
      selection.joinList
    );
    setJoinEntityTypes(entities);

    const dsFields = await loadFieldsForDataSourceAndCache(
      sourceList,
      selection.value
    );
    setFields(dsFields);
    setDataSourceSelection({});
  }

  function handleDashboardValueFieldChange(option) {
    setChanges({ ...changes, dashboardPanelFieldDisplayName: option.value });
  }

  function handleCheckboxChange({ target }) {
    let changed = { ...changes, [target.name]: target.checked };
    setChanges(changed);
  }

  function handleDashboardPanelGraphTypeChange(value) {
    setChanges({ ...changes, dashboardPanelGraphTypeVm: value });
  }

  function handleReportTemplateChange(option) {
    setChanges({
      ...changes,
      reportTemplateVm: option,
      externalDataRuleTableId: "-1",
      externalDataRuleTableVm: {
        value: "-1",
        label: "(Select a rule table)",
        ruleTableDefinitionId: "",
      },
    });
    setSelectedTemplate(
      reportTemplatesData.templateListCache.find(
        (t) => t.value === option.value
      )
    );
  }

  function handleRuleTableChange(option) {
    setChanges({ ...changes, externalDataRuleTableVm: option });
  }

  function handleDashboardValueSelectChange(value) {
    setChanges({ ...changes, dashboardValueSelect: value });
  }

  function formIsValid() {
    const _errors = {};

    if (changes.name.trim() === "") _errors.name = "Name must be entered";
    if ((changes.selectFields || []).length === 0)
      _errors.selectFields = "At least one field must be selected";

    if (
      (changes.filters || []).filter(
        (f) => f.entityType === changes.dataSourceVm.value
      ).length === 0
    )
      _errors.filters = `At least one filter condition on the main data source (${changes.dataSourceVm.label}) must be added`;

    // If Report Format is set to Template, make sure a template is selected
    if (changes.reportFormat !== "Default") {
      if ((changes.reportTemplateVm || { value: "-1" }).value === "-1") {
        _errors.reportTemplateId = "Report Template must be selected.";
      }

      // If template has an RTD specified, make sure user selected a rule table.
      if (
        selectedTemplate !== null &&
        selectedTemplate.externalDataRuleTableDefinitionId &&
        selectedTemplate.externalDataRuleTableDefinitionId !== "-1"
      ) {
        // Template has an RTD so check if rule table is selected:
        if (
          (changes.externalDataRuleTableVm || { value: "-1" }).value === "-1"
        ) {
          _errors.externalDataRuleTableId =
            "Template Rule Table must be selected.";
        }
      }
    }

    // If report has join sources, uncheck the dashboard panel checkbox
    if ((changes.joinEntities || []).length > 0) {
      changes.showAsDashboardPanel = false;
    }

    // Make sure that if any aggregate fields have been added to the select list, all non-aggregate fields are in the group by list.
    const hasAggregate = changes.selectFields.some(
      (f) => f.aggregateFunction !== "None"
    );
    if (hasAggregate) {
      let selectFieldsNotInGroupBy = "";

      for (let i = 0; i < changes.selectFields.length; i++) {
        if (
          changes.selectFields[i].aggregateFunction === "None" &&
          !changes.orderByFields.some(
            (f) =>
              f.fieldDisplayName === changes.selectFields[i].fieldDisplayName &&
              f.groupBy === true
          )
        ) {
          if (selectFieldsNotInGroupBy !== "") selectFieldsNotInGroupBy += ", ";
          selectFieldsNotInGroupBy += changes.selectFields[i].fieldDisplayName;
        }
      }

      if (selectFieldsNotInGroupBy !== "") {
        _errors.selectFields =
          "The following non-aggregate fields must be added as Group By fields below: " +
          selectFieldsNotInGroupBy;
      }
    }

    // Do not allow save if there is a mix of aggregate and non-aggregate fields used in the same OR block of the filters
    if (changes.filters.some((f) => f.operand === "Or")) {
      let hasFilterAggregateError = false;
      let normalCount = 0;
      let aggregateCount = 0;
      let fieldNameList = "";
      let selectField;

      for (let i = 0; i < changes.filters.length; i++) {
        // See if we have hit the next AND but found ORs before this with a mix of field types
        if (changes.filters[i].operand === "And") {
          if (aggregateCount > 0 && normalCount > 0) {
            hasFilterAggregateError = true;
            break;
          } else {
            // Reset the counts due to AND condition
            normalCount = 0;
            aggregateCount = 0;
            fieldNameList = "";
          }
        } else {
          // We are in an OR condition, so increment count for an aggregate or non-aggregate field
          selectField = changes.selectFields.find(
            (f) => f.fieldDisplayName === changes.filters[i].fieldDisplayName
          );
          normalCount +=
            !selectField || selectField.aggregateFunction === "None" ? 1 : 0;
          aggregateCount +=
            selectField && selectField.aggregateFunction !== "None" ? 1 : 0;

          if (fieldNameList !== "") fieldNameList += ", ";
          fieldNameList += changes.filters[i].fieldDisplayName;
        }
      }

      // One last count check since the block could end in ORs
      if (aggregateCount > 0 && normalCount > 0) {
        hasFilterAggregateError = true;
      }

      if (hasFilterAggregateError) {
        _errors.filters =
          "A mix of aggregate and normal fields in the same OR block is not allowed: " +
          fieldNameList;
      }
    }

    if (changes.showAsDashboardPanel) {
      // Dashboard panel stuff
      if (changes.dashboardPanelLabel.trim() === "") {
        _errors.dashboardPanelLabel = "Panel Label must be entered";
      } else if (changes.dashboardPanelLabel.length >= 20) {
        _errors.dashboardPanelLabel =
          "Panel Label must be less than 20 characters";
      }

      if (_.trim(changes.dashboardPanelRefreshSeconds) === "") {
        _errors.dashboardPanelRefreshSeconds =
          "Refresh Interval must be entered";
      } else {
        const refreshInterval = parseInt(
          changes.dashboardPanelRefreshSeconds,
          10
        );
        if (refreshInterval < 30) {
          _errors.dashboardPanelRefreshSeconds =
            "Refresh Interval must be at least 30 seconds";
        }
      }

      if (changes.dashboardValueSelect === "FIELD") {
        if (changes.dashboardPanelFieldDisplayName === "") {
          _errors.dashboardPanelFieldDisplayName =
            "Dashboard value field must be selected.";
        }
      }
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    const newVm = { ...changes };

    if (!resId) {
      addReport(newVm);
    } else {
      updateReport(newVm.id, newVm);
    }
  }

  async function addReport(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      const retModel = createViewModel(
        { ...emptyReport, result },
        reportsData.sourceListCache,
        reportTemplatesData.templateListCache,
        reportTemplatesData.ruleTableListCache,
        fields
      );

      setChanges(retModel);
      setLastSavedChanges(retModel);
      notifySuccess("Report '" + vm.name + "' saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/report/" + result.id, { replace: true });
    });
  }

  async function updateReport(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      const newVm = { ...model, ...result };
      const retModel = createViewModel(
        newVm,
        reportsData.sourceListCache,
        reportTemplatesData.templateListCache,
        reportTemplatesData.ruleTableListCache,
        fields
      );

      setChanges(retModel);
      setLastSavedChanges(retModel);
      notifySuccess("Report '" + vm.name + "' saved successfully");
    });
  }

  async function onRunReport() {
    // Check for unsaved changes
    if (
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
      JSON.stringify(_.cloneDeep(changes))
    ) {
      notifyWarn("Please save your changes before running the report.");
      return false;
    }

    // Do not run full validation since name and dashboard stuff is not required to run the report here.
    if ((changes.selectFields || []).length === 0) {
      setErrors({
        ...errors,
        selectFields: "At least one field must be selected",
      });
      notifyWarn("Please correct the errors before running the report.");
      return false;
    } else {
      // Remove old errors
      setErrors({});
    }

    // Collapse the summary and dashboard sections to results can be seen easier
    handleCollapseExpandSection(
      "Summary",
      true,
      collapsedState,
      setCollapsedState
    );
    handleCollapseExpandSection(
      "Dashboard Panel",
      true,
      collapsedState,
      setCollapsedState
    );

    return true;
  }

  function handleRemoveSelectField(chips, fieldDisplayName) {
    // If this field is included in the order/group by fields, remove it there too.
    const _changes = { ...changes, selectFields: chips };
    let index = _changes.orderByFields.findIndex(
      (c) => c.fieldDisplayName === fieldDisplayName
    );

    if (index >= 0) {
      _changes.orderByFields.splice(index, 1);
    }

    // Also change any conditions to use underlying fieldId instead of select field
    index = _changes.filters.findIndex(
      (c) => c.fieldDisplayName === fieldDisplayName
    );

    if (index >= 0) {
      _changes.filters[index].fieldDisplayName = "";
      _changes.filters[index].fieldUniqueId =
        _changes.filters[index].fieldId + "#";
      _changes.filters[index].fieldLabel = fields.find(
        (field) => field.value === _changes.filters[index].fieldId
      )?.label;
    }

    // Also also, if this field is used as the Dashboard value, set it back to Count Only
    if (
      _changes.dashboardValueSelect === "FIELD" &&
      _changes.dashboardPanelFieldDisplayName === fieldDisplayName
    ) {
      _changes.dashboardValueSelect = emptyReport.dashboardValueSelect;
      _changes.dashboardPanelFieldDisplayName =
        emptyReport.dashboardPanelFieldDisplayName;
    }

    setChanges(_changes);
  }

  const isLoadingStuff =
    loading ||
    loadingDataSources ||
    loadingTemplates ||
    loadingRuleTables ||
    addingReport ||
    updatingReport;

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            title="Return to previous screen"
            type="button"
            className="btn btn-link"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Reports/Report-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          {!editable && !isLoadingStuff && resId && (
            <h1>{report.name} (view only)</h1>
          )}
          {editable && (
            <h1>
              {resId ? "Edit Report" : "Create Report"}{" "}
              {resId ? ` | ${report.name}` : ""}
            </h1>
          )}

          {editable ? (
            <StyledHeaderRowButtonDiv>
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "86px",
                }}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                style={{ marginLeft: "12px" }}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          ) : (
            !isLoadingStuff &&
            !loadingFields && <>Report owner: {report.ownerUserName}</>
          )}
        </StyledHeaderRowDiv>
        <ConfirmDialog
          title="Change Data Source"
          question={`Changing the report's data source will reset all Data settings you have changed. Are you sure you want to continue?`}
          showModal={showConfirmDatasourceModal}
          onNo={() => setShowConfirmDatasourceModal(false)}
          onYes={performDatasourceChange}
        />
        <ConfirmDialog
          title="Change Join Entities"
          question={`You have removed Join Sources that are being used by Fields, Order/Group By, and/or Filters. This action will remove the affected entities. Are you sure you want to continue?`}
          showModal={showConfirmJoinEntityModal}
          onNo={() => setShowConfirmJoinEntityModal(false)}
          onYes={() => performJoinEntityChange(joinEntitySelection)}
        />

        {isLoadingStuff ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/reportdefinition/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Summary"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Reports/Report-Screen&anchor=summary-section"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-6 col-lg-4">
                    <TextInput
                      id="name"
                      label="Name"
                      onChange={handleChange}
                      placeholder="Name"
                      name="name"
                      value={changes.name || ""}
                      error={errors.name}
                      autoFocus={true}
                      disabled={!editable}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <TextInput
                      id="description"
                      label="Description"
                      onChange={handleChange}
                      placeholder="Description"
                      name="description"
                      value={changes.description || ""}
                      error={errors.description}
                      disabled={!editable}
                    />
                  </div>
                </StyledRowDiv>
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <SelectInput
                      id="shareMode"
                      name="shareMode"
                      label="Share Mode"
                      options={[...filteredShareModeTypes]}
                      value={changes.shareModeVm}
                      onChange={handleShareModeChange}
                      placeholder=""
                      error={errors.shareMode}
                      controlStyle={{ width: "150px" }}
                      disabled={!editable}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <CheckboxInput
                      id="showOnHomePage"
                      label="Show this report on home page"
                      onChange={handleCheckboxChange}
                      placeholder=""
                      name="showOnHomePage"
                      showLabelInline={false}
                      checked={changes.showOnHomePage}
                      error={errors.showOnHomePage}
                      disabled={!editable}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Data"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Reports/Report-Screen&anchor=data-section"
              >
                {editable && (
                  <p>
                    Select the source of data for this report. Add fields to
                    display on the report, select fields to order the results
                    by, and add conditions to filter the resulting data.
                  </p>
                )}
                <div className="row" style={{ marginTop: "-10px" }}>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                    <SelectInput
                      id="dataSource"
                      name="dataSource"
                      label="Source"
                      options={reportsData.sourceListCache}
                      value={changes.dataSourceVm}
                      onChange={handleDataSourceChange}
                      placeholder=""
                      error={errors.dataSource}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "20px" }}>
                  <div className="col-12 col-md-6 col-lg-4">
                    <MultiSelectInput
                      id="joinEntities"
                      name="joinEntities"
                      label="Join Sources"
                      options={[...joinEntityTypes]}
                      value={changes.joinEntities}
                      onChange={handleJoinEntitiesChanged}
                      placeholder="No joins selected"
                      error={errors.joinEntities}
                    />
                  </div>
                </div>

                {loadingFields ? (
                  <>
                    <p>Loading field list...</p>
                    <Spinner
                      spinnerStyle={{ height: "200px", lineHeight: "200px" }}
                    />
                  </>
                ) : (
                  !isLoadingStuff && (
                    <>
                      <ReportSelectFieldChipsSection
                        title="Fields"
                        fields={fields.filter((f) => f.filterOnly !== true)}
                        chips={changes.selectFields}
                        setChips={setFieldChips}
                        error={errors.selectFields}
                        disabled={!editable}
                        onRemoveField={handleRemoveSelectField}
                        canAddCustomFields={
                          changes.dataSourceVm?.value === "Claim" || false
                        }
                        dataSourceType={changes.dataSourceVm?.value || "Claim"}
                      />
                      <br />
                      <ReportOrderGroupByFieldChipsSection
                        title="Order/Group By"
                        selectFields={[...changes.selectFields]}
                        chips={changes.orderByFields}
                        setChips={setOrderChips}
                        error={errors.orderByFields}
                        disabled={!editable}
                      />
                      <br />
                      <ReportFilterSection
                        title="Filter"
                        fields={fields}
                        conditions={changes.filters}
                        setConditions={setConditions}
                        error={errors.filters}
                        disabled={!editable}
                        selectFields={changes.selectFields}
                      />
                    </>
                  )
                )}
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Report Format"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Reports/Report-Screen&anchor=report-format-section"
              >
                <StyledRowDiv className="row">
                  <div className="col-12">
                    <ToggleButtonGroupInput
                      id="reportFormat"
                      name="reportFormat"
                      label="Select the format for this report"
                      options={[
                        { value: "Default", label: "Default (CSV)" },
                        { value: "Template", label: "From template" },
                      ]}
                      value={changes.reportFormat}
                      onChange={handleReportFormatChange}
                      error={errors.reportFormat}
                      disabled={!editable}
                    />
                  </div>
                </StyledRowDiv>
                {changes.reportFormat === "Template" && (
                  <>
                    <StyledRowDiv className="row">
                      <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                        <SelectInput
                          id="reportTemplateId"
                          name="reportTemplateId"
                          label="Report Template"
                          options={reportTemplatesData.templateListCache.filter(
                            (c) =>
                              c.entityType === "" ||
                              c.entityType ===
                                (changes.dataSourceVm?.value || "")
                          )}
                          value={changes.reportTemplateVm}
                          onChange={handleReportTemplateChange}
                          placeholder=""
                          error={errors.reportTemplateId}
                          disabled={!editable}
                        />
                      </div>
                    </StyledRowDiv>
                    {selectedTemplate !== null &&
                      selectedTemplate.externalDataRuleTableDefinitionId &&
                      selectedTemplate.externalDataRuleTableDefinitionId !==
                        "-1" && (
                        <StyledRowDiv className="row">
                          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                            <SelectInput
                              id="externalDataRuleTableId"
                              name="externalDataRuleTableId"
                              label="Template Rule Table (for data)"
                              options={reportTemplatesData.ruleTableListCache.filter(
                                (rt) =>
                                  rt.ruleTableDefinitionId ===
                                    selectedTemplate.externalDataRuleTableDefinitionId ||
                                  rt.value === "-1"
                              )}
                              value={changes.externalDataRuleTableVm}
                              onChange={handleRuleTableChange}
                              placeholder=""
                              error={errors.externalDataRuleTableId}
                            />
                          </div>
                        </StyledRowDiv>
                      )}
                  </>
                )}
              </ExpandCollapseDetailSection>
              {changes.dataSource !== null && (
                <ExpandCollapseDetailSection
                  sectionTitle="Dashboard Panel"
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Reports/Report-Screen&anchor=dashboard-panel-section"
                >
                  {(changes.joinEntities || []).length > 0 ? (
                    <>
                      <p>
                        <i>
                          Note: Dashboard panels cannot be created when Join
                          Sources are used.
                        </i>
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Dashboard panels display on the Home page and show the
                        count of records returned from the data source and
                        filter above.
                      </p>
                      <StyledRowDiv className="row">
                        <div className="col-12 col-lg-6">
                          <CheckboxInput
                            id="showAsDashboardPanel"
                            label="Create a dashboard panel from this report"
                            onChange={handleCheckboxChange}
                            placeholder=""
                            name="showAsDashboardPanel"
                            showLabelInline={true}
                            checked={changes.showAsDashboardPanel}
                            error={errors.showAsDashboardPanel}
                            disabled={!editable}
                          />
                        </div>
                        <div className="col-12 col-lg-6">
                          <CheckboxInput
                            id="dashboardPanelIsAlertPanel"
                            label="Alert when value is greater than zero"
                            onChange={({ target }) => {
                              let changed = {
                                ...changes,
                                [target.name]: target.checked,
                              };
                              setChanges(changed);
                            }}
                            placeholder=""
                            name="dashboardPanelIsAlertPanel"
                            showLabelInline={true}
                            checked={changes.dashboardPanelIsAlertPanel}
                            error={errors.dashboardPanelIsAlertPanel}
                            disabled={!editable}
                          />
                        </div>
                      </StyledRowDiv>
                      {changes.showAsDashboardPanel && (
                        <>
                          <StyledRowDiv
                            className="row"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="col-12 col-md-4">
                              <TextInput
                                id="dashboardPanelLabel"
                                label="Panel Label"
                                onChange={handleChange}
                                placeholder=""
                                name="dashboardPanelLabel"
                                value={changes.dashboardPanelLabel || ""}
                                error={errors.dashboardPanelLabel}
                                disabled={!editable}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <SelectInput
                                id="dashboardPanelGraphType"
                                name="dashboardPanelGraphTypeVm"
                                label="Graph Type (shows last 10 values)"
                                options={dashboardPanelGraphTypes}
                                value={
                                  changes.dashboardPanelGraphTypeVm ||
                                  "ColumnChart"
                                }
                                onChange={handleDashboardPanelGraphTypeChange}
                                placeholder=""
                                error={errors.dashboardPanelGraphTypeVm}
                                disabled={!editable}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <TextInput
                                id="dashboardPanelRefreshSeconds"
                                label="Refresh Interval (seconds)"
                                onChange={handleChange}
                                placeholder=""
                                name="dashboardPanelRefreshSeconds"
                                value={changes.dashboardPanelRefreshSeconds}
                                error={errors.dashboardPanelRefreshSeconds}
                                isNumber={true}
                                disabled={!editable}
                              />
                            </div>
                          </StyledRowDiv>
                          <StyledRowDiv
                            className="row"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="col-12">
                              <ToggleButtonGroupInput
                                id="dashboardValueSelect"
                                name="dashboardValueSelect"
                                label="Dashboard Value"
                                options={[
                                  { value: "COUNT", label: "Count Only" },
                                  { value: "FIELD", label: "Select field" },
                                ]}
                                value={changes.dashboardValueSelect}
                                onChange={handleDashboardValueSelectChange}
                                error={errors.dashboardValueSelect}
                                disabled={!editable}
                              />
                            </div>

                            {changes.dashboardValueSelect === "FIELD" && (
                              <>
                                <div className="col-12 col-md-6 col-lg-4">
                                  <SelectInput
                                    id="dashboardPanelFieldDisplayName"
                                    name="dashboardPanelFieldDisplayName"
                                    label=""
                                    labelStyle={{ display: "none" }}
                                    options={changes.selectFields
                                      ?.filter(
                                        (f) => f.aggregateFunction !== "None"
                                      )
                                      .map((f) => {
                                        return {
                                          value: f.fieldDisplayName,
                                          label: f.fieldDisplayName,
                                        };
                                      })}
                                    value={
                                      changes.dashboardPanelFieldDisplayName ===
                                      ""
                                        ? {
                                            value: "",
                                            label: "(Select field)",
                                          }
                                        : {
                                            value:
                                              changes.dashboardPanelFieldDisplayName,
                                            label:
                                              changes.dashboardPanelFieldDisplayName,
                                          }
                                    }
                                    onChange={handleDashboardValueFieldChange}
                                    placeholder="(Select field)"
                                    error={
                                      errors.dashboardPanelFieldDisplayName
                                    }
                                    disabled={!editable}
                                  />
                                </div>
                                <div
                                  className="col-12"
                                  style={{ marginTop: "10px" }}
                                >
                                  <strong>Note: </strong> All Group By fields
                                  are ignored when computing the dashboard value
                                  using an aggregate field.
                                </div>
                              </>
                            )}
                          </StyledRowDiv>
                        </>
                      )}
                    </>
                  )}
                </ExpandCollapseDetailSection>
              )}
              {resId && (
                <ExpandCollapseDetailSection
                  sectionTitle="Run Report"
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Reports/Report-Screen&anchor=run-report-section"
                >
                  <ReportResults
                    report={changes}
                    reportParameters={(changes.filters || []).filter(
                      (f) => f.isParameter === true
                    )}
                    runNumber={"0"}
                    runDate={new Date().toISOString()}
                    externalDataRuleTableId={
                      (changes.externalDataRuleTableVm || { value: "-1" }).value
                    }
                    lastRunValues={
                      _.trim(selectedTemplate?.runValuesToSave || "") === ""
                        ? []
                        : selectedTemplate.runValuesToSave
                            .split(",")
                            .map((r) => {
                              return {
                                name: _.trim(r),
                                value: "",
                                reportParameterType: "LastRunValue",
                              };
                            })
                    }
                    onRunReport={onRunReport}
                    hasUnsavedChanges={hasChanges}
                  />
                </ExpandCollapseDetailSection>
              )}
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default Report;
