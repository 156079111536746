import React, { useState } from "react";
import DbLegacyDbSyncContinuousType from "./DbLegacyDbSyncContinuousType";

function DbLegacyDbSyncContinuousSection({
  typeConfigs,
  typeStates,
  lastestMessages,
}) {
  const [showAllMessages, setShowAllMessages] = useState(true);

  if (typeConfigs.length === 0 || typeStates.length === 0)
    return <i>No statuses found for continuous synchronizations.</i>;

  let currentState, isRunning, isError, messages;

  return (
    <div className="row">
      <div
        className="col-12"
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <button
          type="button"
          className="btn btn-secondary"
          onClick={(e) => {
            e.preventDefault();
            setShowAllMessages(!showAllMessages);
          }}
        >
          {showAllMessages ? "Hide all messages" : "Show all messages"}
        </button>
      </div>
      {typeConfigs
        .sort((a, b) => (a.type > b.type ? 1 : -1))
        .map((currentType, idx) => {
          currentState = typeStates.find((s) => s.type === currentType.type);
          isRunning = currentState?.isContinuousRunInProgress || false;
          isError = currentState?.lastError?.errorState ?? false === true;
          messages =
            lastestMessages.find((s) => s.syncType === currentType.type)
              ?.messages ?? [];

          return (
            <DbLegacyDbSyncContinuousType
              key={`typecont-${idx}`}
              type={currentType.type}
              isRunning={isRunning}
              isError={isError}
              lastMessage={currentState?.lastUpdateMessage || ""}
              lastDate={currentState?.lastContinuousDate || ""}
              messages={messages}
              showAllMessages={showAllMessages}
            />
          );
        })}
    </div>
  );
}

export default DbLegacyDbSyncContinuousSection;
