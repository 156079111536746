import React from "react";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";
import DateRangeInput from "../../common/input/DateRangeInput";
import DatePickerInput from "../../common/input/DatePickerInput";

function ReportProcessSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onDateRangeChange,
  onStartDateChange,
  onEndDateChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="description"
            label="Description"
            onChange={onChange}
            placeholder="Description"
            name="description"
            value={search.description}
            error={errors.description}
          />
          <TextInput
            id="reportName"
            label="Report"
            onChange={onChange}
            placeholder="Report"
            name="reportName"
            value={search.reportName}
            error={errors.reportName}
          />
          <TextInput
            id="ruleTableName"
            label="Rule Table"
            onChange={onChange}
            placeholder="Rule Table"
            name="ruleTableName"
            value={search.ruleTableName}
            error={errors.ruleTableName}
          />
          <TextInput
            id="lastRunBy"
            label="Last Run By"
            onChange={onChange}
            placeholder="Last Run By"
            name="lastRunBy"
            value={search.lastRunBy}
            error={errors.lastRunBy}
          />
          <DateRangeInput
            id="dateRange"
            name="dateRange"
            label="Date Range"
            value={search.dateRange}
            onChange={onDateRangeChange}
            error={errors.dateRange}
          />
          <DatePickerInput
            id="startDate"
            name="startDate"
            label="Created/Modified Start Date"
            value={search.startDate}
            placeholder=""
            onChange={onStartDateChange}
            error={errors.startDate}
          />
          <DatePickerInput
            id="endDate"
            label="Created/Modified End Date"
            name="endDate"
            value={search.endDate}
            placeholder=""
            onChange={onEndDateChange}
            error={errors.endDate}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default ReportProcessSearchForm;
