import React from "react";
import styled from "styled-components";
import { handleCollapseExpandSection } from "../../../services/General";
import HelpLink from "../ui/HelpLink";
import { useAuth } from "../../../contexts/AuthContext";

function ExpandCollapseDetailSection({
  sectionTitle,
  subTitle = "",
  filtered = false,
  helpLink = null,
  collapsedState,
  setCollapsedState,
  children,
  adminOnly = false,
}) {
  const { auth } = useAuth();

  function handleSetCollapsed(collapse) {
    handleCollapseExpandSection(
      sectionTitle,
      collapse,
      collapsedState,
      setCollapsedState
    );
  }

  const collapsed = collapsedState.find(
    (section) => section.name === sectionTitle
  ).collapsed;

  if (adminOnly && !auth.isAdmin) return <></>;

  return (
    <>
      <StyledDetailSection>
        <StyledDetailHeaderSection
          onClick={() => handleSetCollapsed(!collapsed)}
        >
          <h4>
            {filtered && <i className="material-icons-outlined">filter_alt</i>}
            {sectionTitle} {subTitle}
            {helpLink !== null && <HelpLink path={helpLink} />}
          </h4>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => handleSetCollapsed(!collapsed)}
          >
            <span>{collapsed ? "Show" : "Hide"}</span>
            <i className="material-icons">
              {collapsed ? "expand_more" : "expand_less"}
            </i>
          </button>
        </StyledDetailHeaderSection>
        {collapsed ? (
          <></>
        ) : (
          <StyledDetailBodySection>{children}</StyledDetailBodySection>
        )}
      </StyledDetailSection>
    </>
  );
}

const StyledDetailSection = styled.div`
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  margin-bottom: 10px;

  @media only screen and (max-width: 767px) {
    margin-left: -5%;
    margin-right: -5%;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid var(--elevated-border);
  }
`;

const StyledDetailHeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 6px 12px;
  cursor: pointer;

  h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: var(--text-normal);
    display: flex;
    align-items: center;
    column-gap: 5px;

    i {
      font-size: 16px;
      color: var(--menu-text);
    }
  }

  button {
    font-size: 16px;
    line-height: 16px;
  }
`;

const StyledDetailBodySection = styled.div`
  display: block;
  padding: 20px 30px;
  border-top: 1px solid var(--elevated-border);

  div.row {
    margin-bottom: 10px;
  }
`;

export default ExpandCollapseDetailSection;
