import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import PlaceholderChip from "../../common/ui/PlaceholderChip";
import { generateUUID } from "../../../services/General";

import DragDropRuleTableDefinitionColumn from "./DragDropRuleTableDefinitionColumn";
import {
  emptyRuleTableDefinitionColumn,
  getDropDownValue,
} from "../../../viewmodels/ruleTableDefinitionsVm";
import RuleTableDefinitionColumnDialog from "./RuleTableDefinitionColumnDialog";

function DragDropRuleTableDefinitionColumnSection({
  columns,
  setColumns,
  definitionsList,
  error,
  disabled,
}) {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [showModal, setShowModal] = useState(false);
  const [editColumn, setEditColumn] = useState(null);
  const [errors, setErrors] = useState({});

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(event) {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id === over.id) {
      return;
    }

    const _columns = reorder(
      columns,
      columns.findIndex((c) => c.id === active.id),
      columns.findIndex((c) => c.id === over.id)
    );

    setColumns(_columns);
  }

  function closeAddColumnDialog() {
    setShowModal(false);
    setEditColumn(null);
  }

  function formIsValid() {
    const _errors = {};

    // Name is required
    if (_.trim(editColumn.name) === "") {
      _errors.name = "Name must be entered";
    }

    // If dropdown source is List of values for text or number field, then at least one value must be entered
    if (
      (getDropDownValue(editColumn, "valueType") === "Text" ||
        getDropDownValue(editColumn, "valueType") === "Numeric") &&
      getDropDownValue(editColumn, "controlType") === "List" &&
      getDropDownValue(editColumn, "listSource") === "ValidValues" &&
      _.trim(editColumn.validValuesVm) === ""
    ) {
      _errors.validValues = "Values must be entered";
    }

    // If dropdown source is Rule Table Definition Column for text or number field, then a definition and column must be selected.
    if (
      (getDropDownValue(editColumn, "valueType") === "Text" ||
        getDropDownValue(editColumn, "valueType") === "Numeric") &&
      getDropDownValue(editColumn, "controlType") === "List" &&
      getDropDownValue(editColumn, "listSource") === "RuleTableDefinition" &&
      (editColumn.ruleTableDefinitionId === "" ||
        editColumn.ruleTableDefinitionColumnId === "")
    ) {
      if (editColumn.ruleTableDefinitionId === "")
        _errors.ruleTableDefinition = "Rule Table Definition must be entered";
      if (editColumn.ruleTableDefinitionColumnId === "")
        _errors.ruleTableDefinitionColumn = "Column must be entered";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function saveColumn() {
    if (!formIsValid()) return;

    closeAddColumnDialog();
    const column = { ...editColumn };
    const _columns = [...columns];

    // If column has an id, then we are editing, otherwise adding
    const pos = _columns.findIndex((c) => c.id === column.id);

    if (pos >= 0) {
      _columns.splice(pos, 1, column);
    } else {
      // New column, so give it an id
      column.id = generateUUID();
      _columns.push(column);
    }

    // Save
    setColumns(_columns);
  }

  function handleRemoveColumn(id) {
    const _columns = [...columns];
    const index = _columns.findIndex((c) => c.id === id);
    _columns.splice(index, 1);
    setColumns(_columns);
  }

  function handleEditColumn(id) {
    const column = columns.find((c) => c.id === id);
    setEditColumn(column);
    setShowModal(true);
  }

  return (
    <>
      {disabled ? (
        <StyledChipContainer>
          {columns.map((column, idx) => (
            <DragDropRuleTableDefinitionColumn
              key={`${column.id}-${idx}`}
              id={column.id}
              column={column}
              definitionsList={definitionsList}
              disabled={disabled}
            />
          ))}
        </StyledChipContainer>
      ) : (
        <DndContext
          autoScroll={false}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => onDragEnd(event)}
        >
          <SortableContext items={columns} strategy={rectSortingStrategy}>
            <StyledChipContainer>
              {columns.map((column, idx) => (
                <DragDropRuleTableDefinitionColumn
                  key={`${column.id}-${idx}`}
                  id={column.id}
                  column={column}
                  definitionsList={definitionsList}
                  disabled={disabled}
                  onClickRemove={() => handleRemoveColumn(column.id)}
                  onClickEdit={() => handleEditColumn(column.id)}
                />
              ))}

              <PlaceholderChip
                label="Add column"
                onClick={() => {
                  setShowModal(true);
                  setEditColumn({ ...emptyRuleTableDefinitionColumn });
                }}
              ></PlaceholderChip>
            </StyledChipContainer>
          </SortableContext>
        </DndContext>
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      {editColumn !== null && (
        <RuleTableDefinitionColumnDialog
          editColumn={editColumn}
          setEditColumn={setEditColumn}
          definitionsList={definitionsList}
          errors={errors}
          showDialog={showModal}
          onCloseDialog={closeAddColumnDialog}
          onSaveDialog={saveColumn}
        />
      )}
    </>
  );
}

const StyledChipContainer = styled.div`
  padding: 10px;
  min-height: 58px;
  display: flex;
  align-items: left;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 8px;
`;

export default DragDropRuleTableDefinitionColumnSection;
