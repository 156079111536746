import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyDrug, emptyDrugSearch } from "../viewmodels/drugsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadDrugs:
      return {
        ...state,
        drugs: action.payload.drugs,
        count: action.payload.count,
      };

    case ContextProviderActions.saveDrugSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadDrug:
      return {
        ...state,
        drug: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in DrugsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  drugs: [],
  drug: emptyDrug,
  search: emptyDrugSearch,
  count: 0,
};

const Drugs = createContext({
  drugsData: initialState,
  setDrugsData: () => null,
});

// eslint-disable-next-line react/prop-types
const DrugsProvider = ({ children }) => {
  const [drugsData, setDrugsData] = useReducer(reducer, initialState);

  return (
    <Drugs.Provider value={{ drugsData, setDrugsData }}>
      {children}
    </Drugs.Provider>
  );
};

export default DrugsProvider;
export const useDrugs = () => useContext(Drugs);
