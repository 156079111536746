import React from "react";
import {
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
} from "../../../services/General";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { emptyProcessHistorySearch } from "../../../viewmodels/processHistoryVm";
import { useProcessHistory } from "../../../contexts/ProcessHistoryContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function ReportProcessesList({
  processes,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
  onCopy,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { setProcessHistoryData } = useProcessHistory();

  function getActions(dataRow) {
    const id = dataRow.id;
    const description = dataRow.description;
    const lastProcessHistoryId = dataRow.lastProcessHistoryId;

    const actions = [];

    actions.push({
      name: "Edit",
      onClick: () => navigate("/reportprocess/" + id),
    });

    actions.push({
      name: "Duplicate",
      onClick: () => onCopy(description, id),
    });

    actions.push({
      name: "Remove",
      onClick: () => onDelete(id, description),
    });

    actions.push({
      name: "View Last Run History",
      onClick: () => {
        const newSearch = {
          ...emptyProcessHistorySearch,
          freeFormSearch: lastProcessHistoryId,
        };
        setProcessHistoryData({
          type: ContextProviderActions.saveProcessHistorySearch,
          payload: newSearch,
        });
        navigate("/processhistory");
      },
    });

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/reportprocess/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="ReportProcesses"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={processes}
        columnDefs={[
          {
            name: "description",
            label: "Description",
            style: { width: "40%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/reportprocess/" + row.id)}
              >
                {row.description}
              </button>
            ),
          },
          {
            name: "reportName",
            label: "Report",
            style: { width: "25%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/report/" + row.reportId)}
              >
                {row.reportName}
              </button>
            ),
          },
          {
            name: "ruleTableName",
            label: "Rule Table",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/ruletable/" + row.ruleTableId)}
              >
                {row.ruleTableName}
              </button>
            ),
          },
          {
            name: "lastRunDate",
            label: "Last Run Date",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
            getValue: (row) => formatDateTimeUtcZoneForDisplay(row.lastRunDate),
          },
          {
            name: "lastRunDuration",
            label: "Last Duration",
            className: "d-none d-lg-table-cell",
            style: { width: "20%" },
            getValue: (row) =>
              getTimeframeFromMilliseconds(row.lastRunDuration),
          },
          {
            name: "lastModifiedDate",
            label: "Created/Modified",
            className: "d-none d-lg-table-cell",
            style: { width: "20%" },
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.lastModifiedDate),
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default ReportProcessesList;
