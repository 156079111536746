import _ from "lodash";
import { pageSize } from "../constants/Environments";

export const emptyTestResult = {
  id: "",
  entityId: "",
  entityDescription: "",
  runDate: "",
  runBy: "",
  resultType: "Test", // Group
  runDurationMilliseconds: 0,
  runResultStatus: "NotRun", // Pass, Fail
  testSteps: [], // emptyTestStepResult[]
};

export const emptyTestGroupResult = {
  id: "",
  lastRunDate: "",
  lastRunBy: "",
  lastRunDurationMilliseconds: 0,
  lastRunResultStatus: "NotRun", // Pass, Fail
  testCount: 0,
  description: "",
  tests: [],
};

export const emptyTestStepResult = {
  id: "",
  description: "",
  lastRunDurationMilliseconds: 0,
  runResultStatus: "NotRun", // Pass, Fail
  testStepType: "API", //Processor
  expectedStatusCode: 0,
  lastStatusCode: 0,
  expectedResponseJson: "",
  lastResponse: "",
  showJson: true,
};

export const emptyTestGroupResultSearch = {
  highlightText: "",
};

export const emptyTestResultSearch = {
  description: "", // for searching entityDescription
  resultType: [], // Test or Group
  runBy: "",
  runResultStatus: [],
  startDate: new Date("1/1/2000"),
  endDate: new Date("1/1/3000"),
  orderBy: ["runDate-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export const emptyTestStepResultSearch = {
  highlightText: "",
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  if ((vm.testSteps || []).length > 0) {
    vm.testSteps.forEach((c) => (c.showJson = c.runResultStatus !== "Pass"));
  }

  return vm;
}
