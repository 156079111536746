import React, { useState, useEffect } from "react";
import ReportProcessesList from "./ReportProcessesList";
import ReportProcessSearchForm from "./ReportProcessSearchForm";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useReportProcesses } from "../../../contexts/ReportProcessesContext";
import {
  apiLoadReportProcesses,
  apiDeleteReportProcess,
  apiCopyReportProcess,
} from "../../../api/ReportProcessesApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import { useNavigate } from "react-router-dom";
import DuplicateRecordDialog from "../../dialogs/DuplicateRecordDialog";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import useApi from "../../../hooks/useApi";
import {
  createViewModel,
  emptyReportProcessSearch,
} from "../../../viewmodels/reportProcessesVm";
import HelpLink from "../../common/ui/HelpLink";

function ReportProcesses() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { reportProcessesData, setReportProcessesData } = useReportProcesses();
  const { loading, api: apiLoad } = useApi(apiLoadReportProcesses);
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteReportProcess);
  const { loading: copying, api: apiCopy } = useApi(apiCopyReportProcess);
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState(true);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const { isMobileSize } = useMobile();

  let processes = [];
  if (reportProcessesData && reportProcessesData.processes) {
    processes = reportProcessesData.processes;
  }

  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadReportProcesses();
    }
  }, [auth.authenticated, loadData]);

  async function loadReportProcesses() {
    apiLoad.call(
      reportProcessesData.search,
      (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setReportProcessesData({
          type: ContextProviderActions.loadReportProcesses,
          payload: {
            processes: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setReportProcessesData({
      type: ContextProviderActions.saveReportProcessSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyReportProcessSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : reportProcessesData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = reportProcessesData.search.orderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = reportProcessesData.search.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...reportProcessesData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...reportProcessesData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...reportProcessesData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...reportProcessesData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...reportProcessesData.search,
      [target.name]: target.value,
    });
  }

  function handleDateRangeChange(dateRange, startDate, endDate) {
    setSearchChanges({
      ...reportProcessesData.search,
      dateRange: dateRange,
      startDate: startDate,
      endDate: endDate,
    });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...reportProcessesData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...reportProcessesData.search, endDate: date });
  }

  async function deleteReportProcess(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Process deleted successfully");
      setLoadData(true);
    });
  }

  async function handleDelete(id, name) {
    setDeleteId(id);
    setAltDeleteId(name);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteReportProcess(deleteId);
  }

  const handleCancelModal = () => {
    setShowCopyModal(false);
  };

  function startCopy(name, id) {
    let updated = { ...reportProcessesData.search, oldName: { name, id } };
    setSearchChanges(updated);
    setShowCopyModal(true);
  }

  async function copyReport(oldName, newName) {
    apiCopy.call({ id: oldName.id, newId: newName }, (result) => {
      notifySuccess("Process copied successfully to " + newName);
      setShowCopyModal(false);
      setLoadData(true);
    });
  }

  async function handleCopy(newName) {
    await copyReport(reportProcessesData.search.oldName, newName);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (
      reportProcessesData.search.entityType &&
      reportProcessesData.search.entityType.value
    )
      numFilters++;
    if (reportProcessesData.search.name !== "") numFilters++;
    if (reportProcessesData.search.fileNameProcess !== "") numFilters++;
    if (reportProcessesData.search.externalDataRuleTableDefinitionName !== "")
      numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        {((reportProcessesData.search.showAdvancedFilter && !isMobileSize) ||
          !reportProcessesData.search.showAdvancedFilter) && (
          <HelpLink path="/Reports/Report-Processes-List-Screen" label="Help" />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Report Processes</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/reportprocess")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Process
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search Desc, Report, Rule Table, or Last Run By"
        search={reportProcessesData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <DuplicateRecordDialog
        title="Duplicate Process"
        instructions={`Enter a new name for the process to be copied from '${
          reportProcessesData.search.oldName?.name || ""
        }'.`}
        value={reportProcessesData.search.newName || ""}
        showModal={showCopyModal}
        onCancel={handleCancelModal}
        onCopy={handleCopy}
        placeholder="New Process Name"
      />
      <ConfirmDialog
        title="Remove Process"
        question={`Are you sure you wish to delete the process '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              reportProcessesData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={reportProcessesData.search}
            setSearch={setSearchChanges}
            helpLink="/Reports/Report-Processes-List-Screen&anchor=filters"
          >
            <ReportProcessSearchForm
              errors={errors}
              search={reportProcessesData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              onDateRangeChange={handleDateRangeChange}
            />
          </GridAdvancedFilter>
        </div>

        {reportProcessesData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }} data-testid="reports-list">
            {loading || loadData || deleting || copying ? (
              <Spinner />
            ) : (
              <ReportProcessesList
                processes={processes}
                onDelete={handleDelete}
                search={reportProcessesData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={reportProcessesData.count}
                onCopy={startCopy}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default ReportProcesses;
