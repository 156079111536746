import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { formatNumberForDisplay } from "../../../services/General";

function DashboardPanelBack({ panel, value, lastTimestamp, ackAlert }) {
  const navigate = useNavigate();

  return (
    <StyledCardBack className="flip-card-back">
      <h4>{panel.panelLabel}</h4>
      <p>{formatNumberForDisplay(value)}</p>
      <p style={{ fontSize: "14px" }}>{lastTimestamp}</p>
      <StyledLinkContainer>
        {panel.isAlertPanel === true &&
          panel.isAlertPanelInAlertStatus === true && (
            <button
              title="Clear this alert"
              className="btn btn-link"
              onClick={() => ackAlert(panel.reportId)}
            >
              Clear alert
            </button>
          )}
        <button
          title="Run report"
          className="btn btn-link"
          onClick={() => navigate("/runreport/" + panel.reportId)}
          style={{ marginLeft: "auto" }}
        >
          Run report
        </button>
      </StyledLinkContainer>
    </StyledCardBack>
  );
}

const StyledCardBack = styled.div`
  h4 {
    font-size: 16px !important;
  }

  p {
    font-size: 16px;
  }
`;

const StyledLinkContainer = styled.div`
  width: 196px;

  @media only screen and (max-width: 576px) {
    width: 160px;
  }

  padding: 0 8px;
  margin-top: auto;
  margin-bottom: -8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default DashboardPanelBack;
