import React, { useState, useEffect } from "react";
import DiagnosticsList from "./DiagnosticsList";
import DiagnosticsSearchForm from "./DiagnosticsSearchForm";
import _ from "lodash";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Spinner from "../../common/ui/Spinner";
import { useDiagnostics } from "../../../contexts/DiagnosticsContext";
import { apiLoadDiagnostics } from "../../../api/DiagnosticApi";
import { createViewModel } from "../../../viewmodels/diagnosticsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { useInterval } from "../../../hooks/useInterval";
import {
  getUIVersion,
  getEnvironment,
  getAPIUrl,
  getProcessorUrl,
} from "../../../services/Version";
import useApi from "../../../hooks/useApi";

function Diagnostics() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { diagnosticData, setDiagnosticData } = useDiagnostics();
  const { loading, api: apiLoad } = useApi(apiLoadDiagnostics);

  let averageProcessedTime = 0;
  let mostRecentAverage = 0;
  let totalCount = 0;

  let diagnostics = [];
  if (diagnosticData && diagnosticData.diagnostics) {
    diagnostics = diagnosticData.diagnostics;
  }

  if (diagnostics.length > 0) {
    averageProcessedTime = getAverageProcessedTime(diagnostics);
    mostRecentAverage = getMostRecentAverage(diagnostics);
    totalCount = getClaimCount(diagnostics);
  }

  function setSearchChanges(search) {
    setDiagnosticData({
      type: ContextProviderActions.saveDiagnosticSearch,
      payload: search,
    });
  }

  async function loadDiagnostics() {
    apiLoad.call(diagnosticData.search, (result) => {
      let vms = result.map((r) => createViewModel(r));
      const count = vms.length || 0;

      //CRL: Always one record that has nothing but the build number and can be
      //removed one the build number is extracted
      let apiBuildVersion = vms.length > 0 ? vms[0].buildVersion : "";
      let apiEnv = vms.length > 0 ? vms[0].environment : "";

      setDiagnosticData({
        type: ContextProviderActions.loadDiagnostics,
        payload: {
          apiBuildVersion: apiBuildVersion,
          apiEnv: apiEnv,
          diagnostics: vms,
          count,
        },
      });
    });
  }

  async function initialLoad() {
    await loadDiagnostics();
  }

  useEffect(() => {
    if (auth.authenticated) {
      initialLoad();
    }
  }, [auth.authenticated]);

  async function loadOnInterval() {
    await loadDiagnostics();
  }

  // jon, 8/31/22: useInternal hook replaces setInterval since the latter "fixes" initial parameters so search params
  //  never change. But useInternal ensures the params used by this function are updated each time they change.
  useInterval(() => {
    loadOnInterval();
  }, 30000);

  function getAverageProcessedTime(diagnostics) {
    let processedMilliseconds = 0;
    _.forEach(diagnostics, function (d) {
      processedMilliseconds += d.processedMilliseconds;
    });
    processedMilliseconds = processedMilliseconds / diagnostics.length;
    return processedMilliseconds.toFixed(2);
  }

  function getClaimCount(diagnostics) {
    let claimCount = 0;
    _.forEach(diagnostics, function (d) {
      claimCount += d.claimCount;
    });
    return claimCount.toFixed(0);
  }

  function getMostRecentAverage(diagnostics) {
    if (diagnostics.length < 2) return 0.0;
    let processedMilliseconds = 0;
    processedMilliseconds = diagnostics[1].processedMilliseconds;
    return processedMilliseconds.toFixed(2);
  }

  async function handleReset() {
    var oneHourAgo = new Date();
    oneHourAgo.setHours(oneHourAgo.getHours() - 1);
    setSearchChanges({
      ...diagnosticData.search,
      startDate: oneHourAgo,
      endDate: new Date(),
    });
    await loadDiagnostics();
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      await loadDiagnostics();
    }
  }

  async function onSubmit(event) {
    var updatedSearch = { ...diagnosticData.search, pageNumber: 1 };
    setSearchChanges(updatedSearch);
    await handleSearch(event);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({ ...diagnosticData.search, [target.name]: target.value });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...diagnosticData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...diagnosticData.search, endDate: date });
  }

  function handleActiveChange({ target }) {
    setSearchChanges({ ...diagnosticData.search, active: target.checked });
  }

  // jon, 10/5/22: I moved the Authorize tag to the DiagnosticsList because that renders on first load AND
  //   on the set interval for the auto refresh. Otherwise, the token was not getting refreshed and the user
  //   would eventually get 401 errors.
  return (
    <>
      <DiagnosticsSearchForm
        errors={errors}
        search={diagnosticData.search}
        onStartDateChange={handleStartDateChange}
        onEndDateChange={handleEndDateChange}
        onSearch={onSubmit}
        onReset={handleReset}
        onChange={handleSearchChange}
        onActiveChange={handleActiveChange}
      />
      {loading ? (
        <Spinner />
      ) : (
        <DiagnosticsList
          apiBuildVersion={diagnosticData.apiBuildVersion}
          apiEnv={diagnosticData.apiEnv}
          uiBuildVersion={getUIVersion()}
          environment={getEnvironment()}
          apiUrl={getAPIUrl()}
          processorUrl={getProcessorUrl()}
          mostRecentAverage={mostRecentAverage}
          averageProcessedTime={averageProcessedTime}
          totalCount={totalCount}
          diagnostics={diagnostics.slice(1)}
          connections={(diagnostics[0] || { connections: [] }).connections}
        />
      )}
    </>
  );
}

export default Diagnostics;
