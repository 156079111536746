import React from "react";
import GridPageSizeAndInfo from "./GridPageSizeAndInfo";
import GridPageChanger from "./GridPageChanger";
import { StyledNoResultsDiv } from "../layout/CommonStyledControls";

function GridWrapper({
  count,
  search,
  setSearch,
  showNameLink,
  showDetailsLink,
  showColumnNames,
  setShowColumnNames,
  showDetails,
  setShowDetails,
  colCount = 1,
  showColumnPager = false,
  disablePaging = false,
  disableRecordCount = false,
  children,
}) {
  return count === 0 ? (
    <StyledNoResultsDiv>
      <p>
        <i className="material-icons">search_off</i>
      </p>
      <p>No results found</p>
    </StyledNoResultsDiv>
  ) : (
    <>
      <GridPageSizeAndInfo
        count={count}
        search={search}
        setSearch={setSearch}
        showNameLink={showNameLink}
        showDetailsLink={showDetailsLink}
        showColumnNames={showColumnNames}
        setShowColumnNames={setShowColumnNames}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        colCount={colCount}
        showColumnPager={showColumnPager}
        disablePaging={disablePaging}
        disableRecordCount={disableRecordCount}
      />
      <div style={{ marginTop: "8px" }}>{children}</div>

      {!disablePaging && (
        <GridPageChanger count={count} search={search} setSearch={setSearch} />
      )}
    </>
  );
}

export default GridWrapper;
