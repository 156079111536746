import React from "react";
import _ from "lodash";
import { getTimeAgoFromIntervalSeconds } from "../../../services/General";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function CacheStatusDiagnostics({
  diagnostics,
  search,
  onSearch,
  onSort,
  onClearCacheKey,
  onViewKeyContents,
}) {
  function getTimeAgoInterval(timeAgo, timeUnit) {
    const m = `${timeAgo} ${timeAgo === 1 ? timeUnit : timeUnit + "s"} ago`;
    return m;
  }

  function getTimeUntilInterval(timeUntil, timeUnit) {
    const m = `${timeUntil} ${timeUntil === 1 ? timeUnit : timeUnit + "s"}`;
    return m;
  }

  function getActions(dataRow) {
    const keyName = dataRow.keyName;

    const actions = [
      {
        name: "View key contents in cache",
        onClick: () => onViewKeyContents(keyName),
      },
      {
        name: "Clear cache for this key",
        onClick: () => onClearCacheKey(keyName),
      },
    ];

    return actions;
  }

  let startDate = new Date();

  return (
    <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
      <div className="row">
        <div className="col-12">
          <ResponsiveGrid
            gridId="CacheStatus"
            totalRecords={diagnostics.length}
            search={search}
            setSearch={onSearch}
            onSort={onSort}
            dataRows={diagnostics}
            enableClientRowPager={true}
            columnDefs={[
              {
                name: "keyName",
                label: "Key",
                style: { width: "40%" },
              },
              {
                name: "keyAverageGetTimeMilliseconds",
                label: "Avg Time (ms)",
                style: { width: "15%" },
                getValue: (row) =>
                  row.keyInfoSource !== "Diagnostics"
                    ? "-"
                    : row.keyAverageGetTimeMilliseconds,
              },
              {
                name: "keyHits",
                label: "Hit Count",
                style: { width: "15%" },
                getValue: (row) =>
                  row.keyInfoSource !== "Diagnostics" ? "-" : row.keyHits,
              },
              {
                name: "keyMisses",
                label: "Miss Count",
                className: "d-none d-xl-table-cell",
                style: { width: "15%" },
                getValue: (row) =>
                  row.keyInfoSource !== "Diagnostics" ? "-" : row.keyMisses,
              },
              {
                name: "keyTotal",
                label: "Total Attempts",
                className: "d-none d-xl-table-cell",
                style: { width: "15%" },
                getValue: (row) =>
                  row.keyInfoSource !== "Diagnostics" ? "-" : row.keyTotal,
              },
              {
                name: "keyHitPercentage",
                label: "Hit %",
                className: "d-none d-xl-table-cell",
                style: { width: "15%" },
                getValue: (row) =>
                  row.keyInfoSource !== "Diagnostics"
                    ? "-"
                    : Math.round(row.keyHitPercentage),
              },
              {
                name: "keyLastHit",
                label: "Last Hit",
                style: { width: "15%" },
                getValue: (row) => {
                  const endDate = new Date(row.keyLastHit);
                  let interval = startDate.getTime() - endDate.getTime();
                  const isDateInFuture = interval < 0;
                  interval = Math.abs(interval) / 1000;
                  const timeAgoInfo = getTimeAgoFromIntervalSeconds(
                    interval,
                    1
                  );
                  return row.keyLastHit === null
                    ? "-"
                    : `${isDateInFuture ? "-" : ""}${getTimeAgoInterval(
                        timeAgoInfo.ago,
                        timeAgoInfo.unit
                      )}`;
                },
              },
              {
                name: "keyTimeToLiveMinutes",
                label: "TTL",
                className: "d-none d-xl-table-cell",
                style: { width: "15%" },
                getValue: (row) => {
                  const ttlInfo = getTimeAgoFromIntervalSeconds(
                    row.keyTimeToLiveMinutes * 60,
                    1
                  );
                  return _.trim(search.keyMatch) === "" ||
                    row.keyTimeToLiveMinutes === 0
                    ? "-"
                    : `${getTimeUntilInterval(ttlInfo.ago, ttlInfo.unit)}`;
                },
              },
              {
                name: "action",
                label: "Action",
                isActionBtn: true,
                disableSort: true,
                noForceWrap: true,
                style: { width: "60px", maxWidth: "25%", textAlign: "center" },
                getActions: (row) => getActions(row),
              },
            ]}
          />
        </div>
      </div>
    </StyledRowDiv>
  );
}

export default CacheStatusDiagnostics;
