import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import { createRuleViewModel } from "../../../viewmodels/rulesVm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useRules } from "../../../contexts/RulesContext";
import { apiLoadRules, apiDeleteRule, apiCopyRule } from "../../../api/RuleApi";
import Authorize from "../../common/layout/Authorize";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import RulesList from "./RulesList";
import RulesSearchBar from "./RulesSearchBar";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Rules() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const { rulesData, setRulesData } = useRules();
  const { loading, api: apiLoad } = useApi(apiLoadRules);
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteRule);
  const { loading: copying, api: apiCopy } = useApi(apiCopyRule);
  const [loadData, setLoadData] = useState(true);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const [copyId, setCopyId] = useState("");
  const { isMobileSize } = useMobile();

  let rules = rulesData.rules || [];

  // The API call to load data is actually a side effect in most cases since a dispatch to setRulesData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //   useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadRules();
    }
  }, [auth.authenticated, loadData]);

  async function loadRules() {
    apiLoad.call(
      rulesData.search.groupId,
      (result) => {
        setLoadData(false);
        const vms = result.map((r) => createRuleViewModel(r));
        const count = vms?.length || 0; // TODO: what should this be?

        setRulesData({
          type: ContextProviderActions.loadRules,
          payload: {
            rules: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setRulesData({
      type: ContextProviderActions.saveRuleSearch,
      payload: search,
    });
  }

  async function onSubmit(event) {
    var updatedSearch = { ...rulesData.search, pageNumber: 1 };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleSearchChange({ target }) {
    setSearchChanges({ ...rulesData.search, [target.name]: target.value });
  }

  function showGroupRule() {
    if (_.isEmpty(rulesData.search.groupId)) {
      notifyWarn("You must enter a Group Id before selecting this option");
    } else {
      navigate("/groupsummary/" + rulesData.search.groupId);
    }
  }

  async function deleteRule(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Rule deleted successfully");

      // Re-load rules after deleting
      setLoadData(true);
    });
  }

  async function handleDeleteRule(id, description) {
    setDeleteId(id);
    setAltDeleteId(description);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteRule(deleteId);
  }

  function startCopy(id) {
    setCopyId(id);
    setShowCopyModal(true);
  }

  async function copyRule(id) {
    apiCopy.call(id, (result) => {
      notifySuccess("Rule copied successfully");

      // Re-load rules after copying
      setLoadData(true);
    });
  }

  async function handleCopyRule() {
    setShowCopyModal(false);
    await copyRule(copyId);
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        <HelpLink path="/Processing-Rules/Rules-List-Screen" label="Help" />
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Processing Rules</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-primary btn-with-icon"
            onClick={() => navigate("/rule")}
            style={{ width: "114px", minWidth: "114px" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Rule
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <RulesSearchBar
        onChange={handleSearchChange}
        search={rulesData.search}
        setSearch={setSearchChanges}
        onSubmitSearch={onSubmit}
        errors={errors}
        showGroupRule={showGroupRule}
      />
      <ConfirmDialog
        title="Remove Rule"
        question={`Are you sure you wish to deactivate the rule '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <ConfirmDialog
        title="Copy Rule"
        question={`Are you sure you wish to make a copy of this rule??`}
        showModal={showCopyModal}
        onNo={() => setShowCopyModal(false)}
        onYes={handleCopyRule}
      />

      <div style={{ display: "flex" }}>
        {rulesData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || deleting || copying ? (
              <Spinner />
            ) : (
              <RulesList
                rules={rules}
                onDelete={handleDeleteRule}
                search={rulesData.search}
                setSearch={async (search) => {
                  const clientOp = search.isClientOpOnly;
                  setSearchChanges({ ...search, isClientOpOnly: false });
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!clientOp) {
                    await handleSearch(undefined, search);
                  }
                }}
                totalRecords={rules?.length || 0}
                onCopy={startCopy}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default Rules;
