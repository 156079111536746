import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyDrugUpdate } from "../viewmodels/dbDrugUpdateVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadDbDrugUpdate:
      return {
        ...state,
        dbDrugUpdate: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in DbDrugUpdateContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  dbDrugUpdate: emptyDrugUpdate,
};

const DbDrugUpdate = createContext({
  dbDrugUpdateData: initialState,
  setDbDrugUpdateData: () => null,
});

// eslint-disable-next-line react/prop-types
const DbDrugUpdateProvider = ({ children }) => {
  const [dbDrugUpdateData, setDbDrugUpdateData] = useReducer(
    reducer,
    initialState
  );

  return (
    <DbDrugUpdate.Provider value={{ dbDrugUpdateData, setDbDrugUpdateData }}>
      {children}
    </DbDrugUpdate.Provider>
  );
};

export default DbDrugUpdateProvider;
export const useDbDrugUpdate = () => useContext(DbDrugUpdate);
