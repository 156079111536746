/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadMembers(search, token, signal) {
  const uri = API_URL + "/members" + getQueryString();

  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "memberId", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "memberId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "memberInfo.lastName",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "memberInfo.firstName",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "groupId",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.active) {
    where.push({ name: "TEMPORALITEMMATCH", operator: "=", values: [] });
  }
  if (search && search.memberId && search.memberId.length > 0)
    where.push({ name: "memberId", operator: "=", values: [search.memberId] });
  if (search && search.lastName && search.lastName.length > 0)
    where.push({
      name: "memberInfo.lastName",
      operator: "=",
      values: [search.lastName],
    });
  if (search && search.firstName && search.firstName.length > 0)
    where.push({
      name: "memberInfo.firstName",
      operator: "=",
      values: [search.firstName],
    });
  if (search && search.groupId && search.groupId.value)
    where.push({
      name: "groupId",
      operator: "=",
      values: [search.groupId.value],
    });

  if (search && search.address && search.address.length > 0)
    where.push({
      name: "addresses.address1",
      operator: "array_contains",
      values: [search.address],
    });
  if (search && search.city && search.city.length > 0)
    where.push({
      name: "addresses.city",
      operator: "array_contains",
      values: [search.city],
    });
  if (search && search.state && search.state.length > 0)
    where.push({
      name: "addresses.state",
      operator: "array_contains",
      values: [search.state],
    });

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadMembersAll(params, token, signal) {
  const uri = API_URL + "/membersall" + getQueryString();
  let where = [];
  where.push({ name: "TEMPORALITEMMATCH", operator: "=", values: [] });

  const data = { where, orderBy: ["name+"] };

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadMembersAllSearch(params, token, signal) {
  var groupId = params.groupId;
  var searchTerm = encodeURIComponent(params.searchTerm);

  const uri =
    API_URL + `/membersallsearch/${groupId}/${searchTerm}` + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadMember(id, token, signal) {
  let uri = API_URL + "/member/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddMember(m, token, signal) {
  let uri = API_URL + "/member" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateMember(params, token, signal) {
  const id = params.id;
  const m = params.model;

  let uri = API_URL + "/member/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteMember(id, token, signal) {
  let uri = API_URL + "/member/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadMembers.funcName = "apiLoadMembers";
apiLoadMembersAll.funcName = "apiLoadMembersAll";
apiLoadMember.funcName = "apiLoadMember";
apiAddMember.funcName = "apiAddMember";
apiUpdateMember.funcName = "apiUpdateMember";
apiDeleteMember.funcName = "apiDeleteMember";
