import React, { useState } from "react";
import _ from "lodash";
import TextInput from "../../common/input/TextInput";
import { notifyWarn } from "../../../services/NotificationService";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";

function RuleTableDataFilter({ search, setSearch, onSubmit }) {
  const [errors, setErrors] = useState({});

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleApplyClick(e) {
    if (e) e.preventDefault();

    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    const isGroupSearch = _.trim(search.groupId) !== "";
    const updatedSearch = { ...search, isGroupSearch: isGroupSearch };
    setSearch(updatedSearch);
    await onSubmit(e, updatedSearch);
  }

  async function handleClearClick(e) {
    if (e) e.preventDefault();

    const updatedSearch = {
      ...search,
      groupId: "",
      isGroupSearch: false,
      active: true,
    };
    setSearch(updatedSearch);
    await onSubmit(e, updatedSearch);
  }

  function handleChange({ target }) {
    let updatedSearch = { ...search, [target.name]: target.value };
    setSearch(updatedSearch);
  }

  return (
    <>
      <StyledRowDiv className="row">
        <div className="col-12">
          Enter a Group ID and click Apply to view this rule table&apos;s data
          across all rules.
        </div>
      </StyledRowDiv>
      <StyledRowDiv className="row">
        <div className="col-12 col-sm-6 col-lg-4">
          <div style={{ width: "150px" }}>
            <TextInput
              id="groupId"
              label=""
              labelStyle={{ display: "none" }}
              onChange={handleChange}
              placeholder="Group ID"
              name="groupId"
              value={search.groupId}
              error={errors.groupId}
            />
          </div>
        </div>
      </StyledRowDiv>
      <StyledRowDiv className="row" style={{ marginTop: "12px" }}>
        <div className="col-12 col-sm-6 col-lg-4">
          <input
            type="button"
            value="Apply"
            className="btn btn-primary"
            onClick={handleApplyClick}
            style={{ width: "86px", minWidth: "86px" }}
          />
          <input
            type="button"
            value="Clear"
            className="btn btn-secondary"
            onClick={handleClearClick}
            style={{ marginLeft: "10px" }}
          />
        </div>
      </StyledRowDiv>
    </>
  );
}

export default RuleTableDataFilter;
