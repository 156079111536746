import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import { emptyRuleDataSearch } from "../../../viewmodels/ruleTableDataVm";
import { StyledKeyHeader } from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function RuleTableDataHistoryDialog({
  records,
  columns,
  showDialog,
  onCloseDialog,
}) {
  const [search, setSearch] = useState({
    ...emptyRuleDataSearch,
    pageSize: 10,
  });

  function getKeyHistoryColumnDefs() {
    const cols = [];

    cols.push({
      name: "lastModifiedDate",
      label: "Date Changed",
      disableSort: true,
      style: { width: "15%" },
      getValue: (row) => formatDateTimeUtcZoneForDisplay(row.lastModifiedDate),
    });
    cols.push({
      name: "lastModifiedUserName",
      label: "User",
      disableSort: true,
      style: { width: "15%" },
    });
    cols.push({
      name: "effectiveDate",
      label: "Effective Date",
      disableSort: true,
      style: { width: "15%" },
      getValue: (row) => formatDateTimeUtcZoneForDisplay(row.effectiveDate),
    });
    cols.push({
      name: "terminationDate",
      label: "Termination Date",
      disableSort: true,
      style: { width: "15%" },
      getValue: (row) => formatDateTimeUtcZoneForDisplay(row.terminationDate),
    });

    // Rule table columns
    let col;
    for (let i = 0; i < columns.length; i++) {
      col = columns[i];

      cols.push({
        name: col.name,
        label: col.isKey ? (
          <StyledKeyHeader>
            <span className="material-icons">key</span>
            <div>{col.name}</div>
          </StyledKeyHeader>
        ) : (
          <>{col.name}</>
        ),
        disableSort: true,
        getValue: (row) => {
          const val = row.values.find(
            (r) => r.ruleTableColumnDefinitionId === columns[i].id
          );
          let value = val?.value ?? "";

          if (columns[i]?.valueType === "Date") {
            value = formatDateTimeUtcZoneForDisplay(value);
          }

          return value;
        },
      });
    }

    return cols;
  }

  return (
    <>
      <Modal
        dialogClassName="modal-dialog-full-width"
        show={showDialog}
        onHide={onCloseDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            View Rule Data Row History
            <HelpLink
              path="/Processing-Rules/Rule-Table-Screen&anchor=key-history-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: "10px 20px" }}>
            <ResponsiveGrid
              gridId="RuleTableDataKeyHistory"
              totalRecords={records.length}
              search={search}
              setSearch={setSearch}
              dataRows={records.sort((a, b) =>
                a.lastModifiedDate > b.lastModifiedDate ? 1 : -1
              )}
              enableClientColumnPager={true}
              enableClientRowPager={true}
              totalColumnCount={columns.length + 4}
              columnDefs={getKeyHistoryColumnDefs()}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCloseDialog}
            style={{ marginLeft: "12px" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RuleTableDataHistoryDialog;
