/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadRuleTables(search, token, signal) {
  let uri = API_URL + "/ruletables" + getQueryString();

  // Handle search by group id - this filters to the group and considers inheritance.
  if (search.isGroupSearch && _.trim(search.groupId) !== "") {
    uri = API_URL + "/ruletablesbygroup/" + search.groupId + getQueryString();
  }

  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({
      operand: "OR",
      name: "key",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "description",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.key && search.key.length > 0)
    where.push({ name: "key", operator: "=", values: [search.key] });
  if (search && search.description && search.description.length > 0)
    where.push({
      name: "description",
      operator: "=",
      values: [search.description],
    });
  if (search && search.sourceRuleName && search.sourceRuleName.length > 0)
    where.push({
      name: "sourceRuleName",
      operator: "=",
      values: [search.sourceRuleName],
    });
  // if (
  //   search &&
  //   search.ruleTableDefinitionName &&
  //   search.ruleTableDefinitionName.length > 0
  // )
  //   where.push({
  //     name: "ruleTableDefinitionName",
  //     operator: "=",
  //     values: [search.ruleTableDefinitionName],
  //   });

  if (search && search.includeInactive !== true) {
    where.push({ name: "inactiveDate", operator: "isnull", values: [] });
  }

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy || '["key+"]' };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadAllRuleTablesForRule(ruleId, token, signal) {
  const uri =
    API_URL + "/ruletables" + getQueryString() + "&disablepaging=true";
  let where = [];

  where.push({ name: "ruleId", operator: "=", values: [ruleId] });
  where.push({ name: "inactiveDate", operator: "isnull", values: [] });

  const data = { where, orderBy: ["key+"] };

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadRuleTable(params, token, signal) {
  const id = params.id;
  const search = params.search;

  let uri = API_URL + "/ruletable/" + id + getQueryString();

  // Handle search by group id - this filters to the group and considers inheritance.
  if (search.isGroupSearch && _.trim(search.groupId) !== "") {
    uri =
      API_URL +
      "/ruletablebygroup/" +
      id +
      "/" +
      search.groupId +
      getQueryString();
  }

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddRuleTable(m, token, signal) {
  let uri = API_URL + "/ruletable" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateRuleTable(params, token, signal) {
  const id = params.id;
  const m = params.model;

  let uri = API_URL + "/ruletable/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteRuleTable(id, token, signal) {
  let uri = API_URL + "/ruletable/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadRuleTables.funcName = "apiLoadRuleTables";
apiLoadAllRuleTablesForRule.funcName = "apiLoadAllRuleTablesForRule";
apiLoadRuleTable.funcName = "apiLoadRuleTable";
apiAddRuleTable.funcName = "apiAddRuleTable";
apiUpdateRuleTable.funcName = "apiUpdateRuleTable";
apiDeleteRuleTable.funcName = "apiDeleteRuleTable";
