/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadHbDetectorConfig(params, token, signal) {
  let uri = API_URL + "/hbdetectorconfig" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateHbDetectorConfig(params, token, signal) {
  const model = params.model;

  let uri = API_URL + "/hbdetectorconfig" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadHbDetectorConfig.funcName = "apiLoadHbDetectorConfig";
apiUpdateHbDetectorConfig.funcName = "apiUpdateHbDetectorConfig";
