import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import UserLoginLogout from "./UserLoginLogout";
import styled from "styled-components";
import logo from "../../../assets/images/aclaimrx-logo.svg";
import logoDarkMode from "../../../assets/images/aclaimrx-logo-white.png";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useMobile } from "../../../hooks/useMobile";
import HeaderNavLink from "./HeaderNavLink";
import HeaderNavDropdown from "./HeaderNavDropdown";
import CoverageTrackingMenuButton from "../ui/CoverageTrackingMenuButton";
import HeartbeatStatus from "./HeartbeatStatus";

function Header() {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { userPrefs, setUserPrefs } = useUserPreferences();
  const { isTabletSize } = useMobile();

  useEffect(() => {
    if (!isTabletSize) {
      setMenuToggleExpanded(false);
    }
  }, [isTabletSize]);

  function setMenuToggleExpanded(expanded) {
    setUserPrefs({
      type: ContextProviderActions.setMenuToggleExpanded,
      payload: expanded,
    });
  }

  function handleToggleMenu(expanded) {
    setMenuToggleExpanded(expanded);
  }

  return (
    <>
      <StyledNavbar
        sticky="top"
        expand="lg"
        expanded={userPrefs.menuToggleExpanded}
        onToggle={handleToggleMenu}
        style={{
          boxShadow: `4px 4px 10px var(${
            userPrefs.coverageTrackingMode ? "--pill-blue" : "--shadow-1"
          })`,
        }}
      >
        {userPrefs.darkMode ? (
          <StyledLogoDarkMode title="Home" onClick={() => navigate("/")}>
            &nbsp;
          </StyledLogoDarkMode>
        ) : (
          <StyledLogo title="Home" onClick={() => navigate("/")}>
            &nbsp;
          </StyledLogo>
        )}

        {isTabletSize && (
          <div style={{ marginLeft: "40px" }}>
            <HeartbeatStatus isAdmin={auth.isAdmin} />
          </div>
        )}

        <StyledMenuToggle
          collapseonselect="true"
          aria-controls="basic-navbar-nav"
        >
          {userPrefs.menuToggleExpanded ? (
            <>
              <i title="CLose menu" className="material-icons">
                close
              </i>
              <p>Close</p>
            </>
          ) : (
            <>
              <i title="Menu" className="material-icons">
                menu
              </i>
              <p>Menu</p>
            </>
          )}
        </StyledMenuToggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <HeaderNavLink
              name="Home"
              path="/"
              isActive={location.pathname.endsWith("/")}
              hasPermission={auth.isBasicUser || auth.isAdmin}
            />
            <HeaderNavLink
              name="Claim Requests"
              path="/claims"
              isActive={
                location.pathname.endsWith("/claims") ||
                location.pathname.includes("/claim/")
              }
              hasPermission={auth.isBasicUser || auth.isAdmin}
            />
            {auth.isBasicUser ? (
              <HeaderNavLink
                name="Reports"
                path="/reports"
                isActive={
                  location.pathname.startsWith("/reports") ||
                  location.pathname.startsWith("/report") ||
                  location.pathname.startsWith("/runreport")
                }
                hasPermission={auth.isBasicUser || auth.isAdmin}
              />
            ) : (
              <HeaderNavDropdown
                name="Reports"
                isActive={
                  location.pathname.startsWith("/reports") ||
                  location.pathname.startsWith("/report/") ||
                  location.pathname.startsWith("/runreport") ||
                  location.pathname.startsWith("/reporttemplate") ||
                  location.pathname.startsWith("/reportprocess") ||
                  location.pathname.startsWith("/processhistory")
                }
                hasPermission={auth.isBasicUser || auth.isAdmin}
              >
                <HeaderNavLink
                  name="Reports"
                  path="/reports"
                  isActive={false}
                  hasPermission={auth.isBasicUser || auth.isAdmin}
                  isInDropdown={true}
                />
                <HeaderNavLink
                  name="Report Templates"
                  path="/reporttemplates"
                  isActive={false}
                  hasPermission={auth.isAdmin}
                  isInDropdown={true}
                />
                <HeaderNavLink
                  name="Report Processes"
                  path="/reportprocesses"
                  isActive={false}
                  hasPermission={auth.isAdmin}
                  isInDropdown={true}
                />
                <HeaderNavLink
                  name="Process Run History"
                  path="/processhistory"
                  isActive={false}
                  hasPermission={auth.isAdmin}
                  isInDropdown={true}
                />
              </HeaderNavDropdown>
            )}

            {auth.isBasicUser ? (
              <HeaderNavLink
                name="Group Rules"
                path="/groupsummary"
                isActive={false}
                hasPermission={auth.isBasicUser || auth.isAdmin}
              />
            ) : (
              <HeaderNavDropdown
                name="Processing Rules"
                isActive={
                  location.pathname.startsWith("/processingrules") ||
                  location.pathname.startsWith("/groupsummary") ||
                  location.pathname.startsWith("/rule/") ||
                  location.pathname.startsWith("/ruletable")
                }
                hasPermission={auth.isAdmin}
              >
                <HeaderNavLink
                  name="Rules"
                  path="/processingrules"
                  isActive={false}
                  hasPermission={auth.isAdmin}
                  isInDropdown={true}
                />
                <HeaderNavLink
                  name="Group Rule Maps"
                  path="/grouprulemaps"
                  isActive={false}
                  hasPermission={auth.isAdmin}
                  isInDropdown={true}
                />
                <HeaderNavLink
                  name="Rule Tables"
                  path="/ruletables"
                  isActive={false}
                  hasPermission={auth.isAdmin}
                  isInDropdown={true}
                />
                <HeaderNavLink
                  name="Rule Table Definitions"
                  path="/ruletabledefinitions"
                  isActive={false}
                  hasPermission={auth.isAdmin}
                  isInDropdown={true}
                />
              </HeaderNavDropdown>
            )}

            <HeaderNavDropdown
              name="Tools"
              isActive={
                location.pathname.endsWith("/packetparser") ||
                location.pathname.endsWith("/logs") ||
                location.pathname.includes("/log/") ||
                location.pathname.endsWith("/testprocessor") ||
                location.pathname.includes("/audit")
              }
              hasPermission={
                auth.isBasicUser || auth.isAdmin || auth.isParserUser
              }
            >
              <HeaderNavLink
                name="Audit Records"
                path="/auditrecords"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Cache Status"
                path="/cachestatus"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Import Data"
                path="/importdata"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Log Explorer"
                path="/logs"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Packet Parser"
                path="/packetparser"
                isActive={false}
                hasPermission={
                  auth.isBasicUser || auth.isAdmin || auth.isParserUser
                }
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Test Processor"
                path="/testprocessor"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Wizards (PoC)"
                path="/wizard"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
            </HeaderNavDropdown>

            <HeaderNavDropdown
              name="Configure"
              isActive={location.pathname.includes("/configure")}
              hasPermission={auth.isAdmin}
            >
              <HeaderNavLink
                name="Chains"
                path="/configurechains"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Clients"
                path="/configureclients"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Groups"
                path="/configuregroups"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Discounts"
                path="/configurediscounts"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Drugs"
                path="/configuredrugs"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Members"
                path="/configuremembers"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Prescribers"
                path="/configureprescribers"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Prior Authorizations"
                path="/configurepriorauthorizations"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Service Providers"
                path="/configureserviceproviders"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
            </HeaderNavDropdown>

            <HeaderNavDropdown
              name="Testing"
              isActive={
                location.pathname.endsWith("/tests") ||
                location.pathname.endsWith("/test") ||
                location.pathname.endsWith("/testgroups/") ||
                location.pathname.endsWith("/testgroup") ||
                location.pathname.endsWith("/testrunresults") ||
                location.pathname.endsWith("/testgroupresults") ||
                location.pathname.endsWith("/testresults")
              }
              hasPermission={auth.isAdmin}
            >
              <HeaderNavLink
                name="Test Run Results"
                path="/testrunresults"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Test Groups"
                path="/testgroups"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Tests"
                path="/tests"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
            </HeaderNavDropdown>

            <HeaderNavDropdown
              name="Services"
              isActive={
                location.pathname.includes("/drugupdate") ||
                location.pathname.includes("/legacydatabase") ||
                location.pathname.includes("/databasesync")
              }
              hasPermission={auth.isAdmin}
            >
              <HeaderNavLink
                name="Drug Update"
                path="/dbdrugupdate"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Database Sync"
                path="/dbdatabasesync"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Legacy Database Sync"
                path="/dblegacydbsync"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Heartbeat Detector Config"
                path="/hbdetectorconfig"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Socket Server Config"
                path="/socketserverconfig"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Test Data Populator Config"
                path="/testdatapopulatorconfig"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
              <HeaderNavLink
                name="Test Runner Config"
                path="/testrunnerconfig"
                isActive={false}
                hasPermission={auth.isAdmin}
                isInDropdown={true}
              />
            </HeaderNavDropdown>

            {isTabletSize && (
              <>
                <UserLoginLogout
                  menuToggleExpanded={userPrefs.menuToggleExpanded}
                  showSignoutAsNavItem={true}
                  isAdmin={auth.isAdmin}
                ></UserLoginLogout>
              </>
            )}
          </Nav>
          {!isTabletSize && (
            <Nav className="ms-auto">
              <HeartbeatStatus isAdmin={auth.isAdmin} />
              <UserLoginLogout
                menuToggleExpanded={userPrefs.menuToggleExpanded}
                showSignoutAsNavItem={false}
                isAdmin={auth.isAdmin}
              ></UserLoginLogout>
            </Nav>
          )}
        </Navbar.Collapse>
        {userPrefs.coverageTrackingMode && <CoverageTrackingMenuButton />}
      </StyledNavbar>
    </>
  );
}

const StyledNavbar = styled(Navbar)`
  padding: 0 5%;
  height: 48px;
  color: var(--menu-text);
  background-color: var(--menu-bg);
  margin-bottom: 20px;

  .navbar-nav {
    display: flex;
    align-items: center;
    color: var(--menu-text);

    .nav-item {
      height: 48px;
      max-height: 48px;
    }

    .dropdown.active-menu-item {
      .nav-link {
        color: var(--menu-active-text);
        background-color: var(--menu-active-bg);
      }
    }
  }

  .nav-link,  .nav-link.show {
    padding: 17px 18px !important;
    height: 100%;
    max-height: 48px;
    color: var(--menu-text);
    background-color: var(--menu-bg);
    transition: all 0.3s !important;

    &:hover,
    &.active-menu-item {
      color: var(--menu-active-text);
      background-color: var(--menu-active-bg);
    }

    &.disabled-menu-item {
      color: var(--menu-subheader-text);
    }

    &.nav-link-logo: hover {
      color: var(--menu-text);
      background-color: transparent;
    }
  }

   .navbar-collapse.collapse.show {
      padding-bottom: 100px;
   }

  .navbar-collapse.collapse.show, .navbar-collapse.collapsing {
    margin-left: -6%;
    margin-right: -6%;
  }

  .navbar-collapse.collapse.show div.navbar-nav,
  .navbar-collapse.collapsing div.navbar-nav {
    background-color: var(--menu-active-bg);
    padding: 20px 0;

    &:first-child {
      padding-bottom: 0;
    }

    &:last-child {
      padding-top: 0;
    }

    .nav-link {
      width: 100%;
      font-size: 20px;
      line-height: 50px;
      text-align: left;
      background-color: var(--menu-active-bg);
      color: var(--menu-active-text);
      padding: 0 40px !important;

      &:active {
        background-color: var(--menu-active-touch-bg);
        color: var(--menu-active-touch-text);
      }

      &.disabled-menu-item, &.disabled-menu-item:active {
        margin-left: 40px !important;
        padding-left: 0px !important;
        color: var(--menu-subheader-text);
        background-color: var(--menu-active-bg);
        border-bottom: 2px solid var(--menu-subheader-text);
      }
    }
`;

const StyledMenuToggle = styled(Navbar.Toggle)`
  display: flex;
  align-items: center;
  justify-content: right;
  color: var(--menu-text);
  width: 112px;
  height: 48px;
  max-height: 48px;
  margin: 0;
  padding: 1em 0 !important;
  border: 0 !important;
  box-shadow: none !important;

  i.material-icons {
    font-size: 18px;
  }

  p {
    margin: 0 0 2px 9px;
    padding: 0;
    font-size: 18px;
    line-height: 23px;
  }
`;

const StyledLogo = styled.i`
  width: 44px;
  height: 36px;
  margin-right: 20px;
  cursor: pointer;
  background: transparent url(${logo}) no-repeat center;
`;

const StyledLogoDarkMode = styled.i`
  width: 44px;
  min-height: 36px;
  max-height: 36px;
  height: 36px;
  margin-right: 20px;
  cursor: pointer;
  background: transparent url(${logoDarkMode}) no-repeat center;
  background-size: auto 100%;
`;

export default Header;
