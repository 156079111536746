import React from "react";
import "../../../assets/css/Spinner.css";

export default function Spinner({ spinnerStyle, message = "" }) {
  return (
    <div
      data-testid="loading-spinner"
      className="loader-wrapper"
      style={spinnerStyle || {}}
    >
      <div className="DNA_cont">
        {message !== "" && <h2>{message}</h2>}
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
      </div>
    </div>
  );
}
