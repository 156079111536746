import _ from "lodash";
import { pageSize } from "../constants/Environments";

export const emptyRuleTable = {
  id: "",
  ruleId: "",
  key: "",
  description: "",
  ruleTableDefinitionId: "",
  ruleTableDefinitionName: "",
  inactiveDate: "",
  linkedColumns: [],
  columnSpecs: [],
  rows: [],
  sourceRuleId: "",
  sourceRuleName: "",
  sourceRuleOrdinal: 0,
  ownRowCount: 0,
  inheritedRowCount: 0,
  newRuleTableName: "",
  oldRuleTableName: "",
};

export const emptyRuleTableLinkedColumn = {
  columnDefinitionId: "",
  associatedRuleTableId: "",
  colName: "",
  ruleTableList: [],
};

export const emptyRuleTableSearch = {
  key: "",
  description: "",
  sourceRuleName: "",
  ruleTableDefinitionName: "",
  groupId: "",
  includeInactive: false,
  orderBy: ["key+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
  isGroupSearch: false,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  // TODO: convert column specs using rule table definition vm?

  return vm;
}
