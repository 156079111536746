import React from "react";
import styled from "styled-components";

function GridPageChanger({ count, search, setSearch }) {
  let lastPage = 0;
  let prevPage = 0;
  let nextPage = 0;
  let btnFirstDisabled = true;
  let btnBackDisabled = true;
  let btnNextDisabled = true;
  let btnLastDisabled = true;
  const pages = [];

  if (count > 0) {
    prevPage = search.pageNumber - 1;
    nextPage = search.pageNumber + 1;
    lastPage = Math.ceil(count / search.pageSize);

    btnFirstDisabled = search.pageNumber === 1;
    btnBackDisabled = search.pageNumber === 1;
    btnNextDisabled = search.pageNumber === lastPage;
    btnLastDisabled = search.pageNumber === lastPage;

    // Build page array
    for (let i = 1; i <= lastPage; i++) {
      pages.push(i);
    }
  }

  function getPagerButton(page, labelElement, selected, disabled, onClick) {
    let cName = "";
    if (selected) {
      cName = cName + " selected-value";
    }
    if (disabled) {
      cName = cName + " disabled-look";
    }

    const btn = (
      <td
        key={page}
        className={cName}
        onClick={() => {
          if (!disabled) onClick();
        }}
      >
        {labelElement}
      </td>
    );

    return btn;
  }

  return (
    <StyledWrapper data-testid="grid-page-changer">
      <StyledPagerTable>
        <tbody>
          <tr>
            {getPagerButton(
              "first",
              <i className="material-icons">keyboard_double_arrow_left</i>,
              false,
              btnFirstDisabled,
              () => setSearch({ ...search, pageNumber: 1 })
            )}
            {getPagerButton(
              "back",
              <span>Back</span>,
              false,
              btnBackDisabled,
              () => setSearch({ ...search, pageNumber: prevPage })
            )}
            {pages.map((page) => {
              if (
                pages.length < 7 ||
                (page + 3 >= search.pageNumber && page - 3 <= search.pageNumber)
              ) {
                return getPagerButton(
                  page,
                  <span>{page}</span>,
                  search.pageNumber === page,
                  count === 0,
                  () => setSearch({ ...search, pageNumber: page })
                );
              } else return null;
            })}
            {getPagerButton(
              "next",
              <span>Next</span>,
              false,
              btnNextDisabled,
              () => setSearch({ ...search, pageNumber: nextPage })
            )}
            {getPagerButton(
              "last",
              <i className="material-icons">keyboard_double_arrow_right</i>,
              false,
              btnLastDisabled,
              () => setSearch({ ...search, pageNumber: lastPage })
            )}
          </tr>
        </tbody>
      </StyledPagerTable>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPagerTable = styled.table`
  background: var(--table-bg);
  border: 1px solid var(--table-border);
  border-radius: 3px;
  height: 26px;
  margin-bottom: 8px;

  tbody tr td {
    cursor: pointer;
    height: 19px;
    font-size: 15px;
    line-height: 19px;
    color: var(--text-medium);
    text-align: center;
    vertical-align: middle;
    padding: 3px 9px 4px 9px;
    border: 1px solid var(--table-border);

    &:not(.disabled-look):hover,
    &.selected-value {
      color: var(--button-primary-text);
      background-color: var(--button-primary-active-bg);
    }

    &.disabled-look {
      cursor: pointer;
    }

    i {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;

export default GridPageChanger;
