import React from "react";
import ClaimSectionRow from "./ClaimSectionRow";
import {
  formatDateTimeUtcZoneForDisplay,
  formatDecimal,
  formatDateOnly,
} from "../../../services/General";
import Authorize from "../../common/layout/Authorize";
import { useAuth } from "../../../contexts/AuthContext";

export default function ClaimSummary({ data, history }) {
  const { auth } = useAuth();

  const claim = data.claim;
  const requestFields = data.requestFields;
  //const responseFields = data.responseFields;

  function getRequestFieldValue(fieldCode) {
    var foundFields = requestFields.filter((f) => f.fieldId === fieldCode);
    if (foundFields.length > 0) {
      return foundFields[0].value.data;
    }
  }

  return (
    <Authorize>
      <div className="row">
        <div className="col">
          <ClaimSectionRow
            label="Return Message"
            value={claim.returnMessage}
            valueStyle={{ maxWidth: "100%" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-6 col-xxl-4">
          <ClaimSectionRow
            label="Rx Number"
            value={claim.prescriptionRefNumber}
          />
          <ClaimSectionRow
            label="Authorization #"
            value={claim.authorizationNumber}
          />
          {auth.isAdmin ? (
            <ClaimSectionRow
              label="Claim Id"
              history={history}
              valueLink={claim.claimId}
              valueLinkId={claim.claimId}
              valueLinkPage="log"
              valueStyle={{ maxWidth: "100%" }}
            />
          ) : (
            <ClaimSectionRow label="Claim Id" value={claim.claimId} />
          )}
          <ClaimSectionRow
            label="Status"
            value={claim.friendlyStatus}
            isPill={true}
          />
          <ClaimSectionRow
            label="Reversal Of"
            history={history}
            valueLink={claim.reversalOf && claim.reversalOf.authorizationNumber}
            valueLinkId={claim.reversalOf && claim.reversalOf.id}
            valueLinkPage="claim"
          />
          <ClaimSectionRow
            history={history}
            label="Reversed By"
            valueLink={claim.reversedBy && claim.reversedBy.authorizationNumber}
            valueLinkId={claim.reversedBy && claim.reversedBy.id}
            valueLinkPage="claim"
          />
          <ClaimSectionRow
            label="Processed"
            value={formatDateTimeUtcZoneForDisplay(claim.processedDate)}
          />
          <ClaimSectionRow
            label="Fill Date"
            value={formatDateOnly(claim.dateOfService)}
          />
          <ClaimSectionRow label="Fill Number" value={claim.fillNumber} />
        </div>
        <div className="col-xs-12 col-lg-6 col-xxl-3">
          <ClaimSectionRow
            label="Days Supply"
            value={getRequestFieldValue("D5")}
          />
          <ClaimSectionRow label="BIN" value={claim.bin} />
          <ClaimSectionRow label="PCN" value={claim.pcn} />
          <ClaimSectionRow label="Group" value={claim.groupId} />
          <ClaimSectionRow label="Member" value={claim.memberId} />
          <ClaimSectionRow label="Prescriber" value={claim.prescriberId} />
          <ClaimSectionRow label="Pharmacy" value={claim.serviceProviderId} />
          <ClaimSectionRow label="Quantity" value={claim.quantity} />
          <ClaimSectionRow
            label="Prior Auth"
            value={claim.priorAuthorizationNumber}
          />
        </div>

        <div className="col-xs-12 col-lg-6 col-xxl-3">
          <ClaimSectionRow
            label="Ingred Cost In"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.ingredientCostSubmitted, 2)}
          />
          <ClaimSectionRow
            label="Ingred Cost Out"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.ingredientCost, 2)}
          />
          <ClaimSectionRow
            label="Disp Fee In"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.dispensingFeeSubmitted, 2)}
          />
          <ClaimSectionRow
            label="Disp Fee Out"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.dispensingFee, 2)}
          />
          <ClaimSectionRow
            label="Copay In"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.copaySubmitted, 2)}
          />
          <ClaimSectionRow
            label="Copay Out"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.copay, 2)}
          />
          <ClaimSectionRow
            label="Tax In"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.taxSubmitted, 2)}
          />
        </div>
        <div className="col-xs-12 col-lg-6 col-xxl-2">
          <ClaimSectionRow
            label="Tax Out"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.tax, 2)}
          />
          <ClaimSectionRow
            label="U and C"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.usualAndCustomary, 2)}
          />
          <ClaimSectionRow
            label="Gross Amt Due"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.grossAmountDue, 2)}
          />
          <ClaimSectionRow
            label="Total Paid"
            valueStyle={{ textAlign: "right" }}
            value={formatDecimal(claim.totalPaid, 2)}
          />
        </div>
      </div>
    </Authorize>
  );
}
