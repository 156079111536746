import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyPriorAuthorization,
  emptyPriorAuthorizationSearch,
} from "../viewmodels/priorAuthorizationsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadPriorAuthorizations:
      return {
        ...state,
        priorAuthorizations: action.payload.priorAuthorizations,
        count: action.payload.count,
      };

    case ContextProviderActions.savePriorAuthorizationSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadPriorAuthorization:
      return {
        ...state,
        priorAuthorization: action.payload.priorAuthorization,
        claims: action.payload.paClaims,
        rejectionDescriptions: action.payload.rejectionDescriptions,
      };

    case ContextProviderActions.savePriorAuthorizationRejectionDescriptions:
      return {
        ...state,
        rejectionDescriptions: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in PriorAuthorizationsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  priorAuthorizations: [],
  priorAuthorization: emptyPriorAuthorization,
  claims: [],
  rejectionDescriptions: [],
  search: emptyPriorAuthorizationSearch,
  count: 0,
};

const PriorAuthorizations = createContext({
  priorAuthorizationsData: initialState,
  setPriorAuthorizationsData: () => null,
});

// eslint-disable-next-line react/prop-types
const PriorAuthorizationsProvider = ({ children }) => {
  const [priorAuthorizationsData, setPriorAuthorizationsData] = useReducer(
    reducer,
    initialState
  );

  return (
    <PriorAuthorizations.Provider
      value={{ priorAuthorizationsData, setPriorAuthorizationsData }}
    >
      {children}
    </PriorAuthorizations.Provider>
  );
};

export default PriorAuthorizationsProvider;
export const usePriorAuthorizations = () => useContext(PriorAuthorizations);
