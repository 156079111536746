import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
} from "../../common/layout/CommonStyledControls";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import {
  createViewModel,
  emptyRuleTable,
  fromViewModel,
} from "../../../viewmodels/ruleTablesVm";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import RuleTableDialog from "./RuleTableDialog";
import {
  apiAddRuleTable,
  apiDeleteRuleTable,
  apiLoadAllRuleTablesForRule,
  apiLoadRuleTable,
  apiUpdateRuleTable,
} from "../../../api/RuleTableApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useRuleTables } from "../../../contexts/RuleTablesContext";
import { useNavigate } from "react-router-dom";
import Spinner from "../../common/ui/Spinner";
import { apiLoadRuleTableDefinitionsAll } from "../../../api/RuleTableDefinitionApi";
import useApi from "../../../hooks/useApi";
import { useReportTemplates } from "../../../contexts/ReportTemplatesContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function RuleTablesList({
  ruleId,
  ruleName,
  onAddRuleTableDefinition,
  savedEditRuleTable,
  textSearch,
}) {
  const navigate = useNavigate();
  const { ruleTablesData, setRuleTablesData } = useRuleTables();
  const { setReportTemplatesData } = useReportTemplates();
  const { loading, api: apiLoad } = useApi(apiLoadRuleTable);
  const { loading: loadingAll, api: apiLoadAll } = useApi(
    apiLoadAllRuleTablesForRule
  );
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteRuleTable);
  const { loading: adding, api: apiAdd } = useApi(apiAddRuleTable);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateRuleTable);
  const { loading: loadingDefs, api: apiLoadDefinitions } = useApi(
    apiLoadRuleTableDefinitionsAll
  );
  const [loadData, setLoadData] = useState(false);
  const [search, setSearch] = useState({ highlightText: "" });
  const [showModal, setShowModal] = useState(false);
  const [editRuleTable, setEditRuleTable] = useState(null);
  const [errors, setErrors] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const [definitions, setDefinitions] = useState([]);

  let ruleTables = [];
  if (ruleTablesData && ruleTablesData.ruleTables) {
    ruleTables = ruleTablesData.ruleTables;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setRuleTablesData must usually
  //  happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //  useEffect ensures that happens first.
  useEffect(() => {
    if (loadData) {
      loadRuleTables();
    }
  }, [loadData]);

  // If we have a previously-saved rule table edit, load that and open the dialog for editing.
  useEffect(() => {
    if (savedEditRuleTable !== null) {
      window.setTimeout(async () => {
        setShowModal(true);
        await loadDefinitionsListIfNeeded();
        setEditRuleTable(savedEditRuleTable);
      }, 1);
    } else {
      setEditRuleTable(null);
      setShowModal(false);
    }
  }, [savedEditRuleTable]);

  // Set the instant search here too if the main rule text search is changed
  useEffect(() => {
    if (_.trim(textSearch) !== "") {
      handleInstantSearchChange(textSearch);
    } else {
      handleInstantSearchChange("");
    }
  }, [textSearch]);

  async function loadDefinitionsListIfNeeded() {
    // Load full list of rule table definitions for use as source in col dialog
    // This will load all RTDs except the one being edited - so use empty string here on Rules
    // Only load these if not already loaded.
    if ((definitions || []).length > 0) return;

    await apiLoadDefinitions.call("", async (result) => {
      const vms = result.resources.map((r) => createViewModel(r));

      // jon, Rule "Report Templates" should only show rule table definitions with securityLevel = "ReportTemplate". Other
      //   rules should only show RTDs with any securityLevel besides "ReportTemplate".
      if (ruleName === "Report Templates") {
        setDefinitions(
          vms.filter((def) => def.securityLevel === "ReportTemplate")
        );
      } else {
        setDefinitions(
          vms.filter((def) => def.securityLevel !== "ReportTemplate")
        );
      }
    });
  }

  async function loadRuleTables() {
    await apiLoadAll.call(
      ruleId,
      (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setRuleTablesData({
          type: ContextProviderActions.loadRuleTables,
          payload: {
            ruleTables: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function clearTemplateRuleTableCache() {
    // Copy, delete, and save operations invalidate the cache.
    setReportTemplatesData({
      type: ContextProviderActions.setReportTemplateCache,
      payload: [],
    });
  }

  function formIsValid() {
    const _errors = {};

    if (editRuleTable.ruleTableDefinitionId === "")
      _errors.ruleTableDefinition = "Rule Table Definition is required";
    if (editRuleTable.key.trim() === "") _errors.key = "Key is required";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleCloseEditDialog() {
    setEditRuleTable(null);
    setErrors({});
    setShowModal(false);
  }

  function getActions(dataRow) {
    const id = dataRow.id;
    const key = dataRow.key;
    const ruleTableDefinitionId = dataRow.ruleTableDefinitionId;

    const actions = [
      {
        name: "Open Rule Table Dialog",
        onClick: () => handleEditRuleTable(id, key),
      },
      {
        name: "Edit Rule Table Data",
        onClick: () => navigate("/ruletable/" + id),
      },
      {
        name: "View Rule Table Definition",
        onClick: () =>
          navigate("/ruletabledefinition/" + ruleTableDefinitionId),
      },
      {
        name: "Remove Rule Table",
        onClick: () => {
          handleDelete(id, key);
        },
      },
    ];

    return actions;
  }

  function handleInstantSearchChange(value) {
    setSearch({ ...search, highlightText: value });
  }

  async function deleteRuleTable(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Rule Table deleted successfully");
      clearTemplateRuleTableCache();

      // Re-load rules after deleting
      setLoadData(true);
    });
  }

  async function handleDelete(id, key) {
    setDeleteId(id);
    setAltDeleteId(key);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteRuleTable(deleteId);
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    setShowModal(false);
    const newVm = { ...editRuleTable, ruleId: ruleId };

    if (newVm.id === "") {
      addRuleTable(newVm);
    } else {
      updateRuleTable(newVm.id, newVm);
    }
  }

  async function addRuleTable(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      setEditRuleTable(createViewModel(result));
      notifySuccess("Rule Table '" + vm.key + "' saved successfully");
      clearTemplateRuleTableCache();

      // Re-load rules after deleting
      setLoadData(true);
    });
  }

  async function updateRuleTable(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      const newVm = { ...model, ...result };
      setEditRuleTable(createViewModel(newVm));
      notifySuccess("Rule Table '" + vm.key + "' saved successfully");
      clearTemplateRuleTableCache();

      // Re-load rules after deleting
      setLoadData(true);
    });
  }

  async function handleAddRuleTable() {
    setShowModal(true);
    await loadDefinitionsListIfNeeded();
    setEditRuleTable({ ...emptyRuleTable });
  }

  async function handleEditRuleTable(id, key) {
    setShowModal(true);
    await loadDefinitionsListIfNeeded();

    apiLoad.call({ id, search: {} }, (result) => {
      setEditRuleTable({ ...emptyRuleTable, ...result });
    });
  }

  return (
    <>
      <ConfirmDialog
        title="Remove Rule Table"
        question={`Are you sure you wish to deactivate the rule table '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      {showModal && (
        <RuleTableDialog
          editRuleTable={editRuleTable}
          setEditRuleTable={setEditRuleTable}
          definitionsList={definitions || []}
          ruleTablesList={ruleTables || []}
          errors={errors}
          showDialog={showModal}
          onCloseDialog={handleCloseEditDialog}
          onSaveDialog={handleSubmit}
          onAddRuleTableDefinition={onAddRuleTableDefinition}
          loading={loading || loadingDefs || editRuleTable === null}
        />
      )}
      <StyledContainer>
        <StyledHeaderRowDiv>
          <InstantSearchInput
            id="screenSearchInput"
            onChange={handleInstantSearchChange}
            value={search.highlightText}
          />
          <StyledHeaderRowButtonDiv>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleAddRuleTable}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="material-icons">add</span>
              {"  "}Add Rule Table
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        <div style={{ marginTop: "10px" }}>
          {loadData || loadingAll || deleting || adding || updating ? (
            <Spinner spinnerStyle={{ height: "200px", lineHeight: "200px" }} />
          ) : (
            <>
              <ResponsiveGrid
                gridId="RuleTablesOnRules"
                totalRecords={ruleTablesData.count}
                search={search}
                setSearch={setSearch}
                allowFreeTextSearch={true}
                dataRows={ruleTables}
                disablePaging={true}
                columnDefs={[
                  {
                    name: "edit",
                    label: "Data",
                    disableSort: true,
                    style: {
                      width: "25%",
                    },
                    getValue: (row, index) => (
                      <button
                        className="btn btn-link link-underline"
                        onClick={() => navigate("/ruletable/" + row.id)}
                      >
                        edit
                      </button>
                    ),
                  },
                  {
                    name: "key",
                    label: "Key",
                    disableSort: true,
                    freeTextSearch: true,
                    style: { width: "40%" },
                    getValue: (row, index, searchResultHtml) =>
                      searchResultHtml,
                  },
                  {
                    name: "description",
                    label: "Description",
                    disableSort: true,
                    freeTextSearch: true,
                    className: "d-none d-lg-table-cell",
                    style: { width: "40%" },
                    getValue: (row, index, searchResultHtml) =>
                      searchResultHtml,
                  },
                  {
                    name: "ruleTableDefinitionName",
                    label: "Rule Table Definition",
                    disableSort: true,
                    freeTextSearch: true,
                    className: "d-none d-lg-table-cell",
                    style: { width: "40%" },
                    getValue: (row, index, searchResultHtml) => (
                      <button
                        className="btn btn-link link-underline"
                        onClick={() =>
                          navigate(
                            "/ruletabledefinition/" + row.ruleTableDefinitionId
                          )
                        }
                      >
                        {searchResultHtml}
                      </button>
                    ),
                  },
                  {
                    name: "numCols",
                    label: "# Cols",
                    disableSort: true,
                    className: "d-none d-md-table-cell",
                    style: { width: "15%" },
                    getValue: (row) => (row.columnSpecs || []).length,
                  },
                  {
                    name: "numRows",
                    label: "# Rows",
                    disableSort: true,
                    className: "d-none d-md-table-cell",
                    style: { width: "15%" },
                    getValue: (row) => row.ownRowCount,
                  },
                  {
                    name: "lastRunBy",
                    label: "Run By",
                    disableSort: true,
                    className: "d-none d-lg-table-cell",
                    style: { width: "25%" },
                  },
                  {
                    name: "action",
                    label: "Action",
                    isActionBtn: true,
                    disableSort: true,
                    noForceWrap: true,
                    style: {
                      width: "60px",
                      maxWidth: "25%",
                      textAlign: "center",
                    },
                    getActions: (row) => getActions(row),
                  },
                ]}
              />
            </>
          )}
        </div>
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  padding-top: 10px;

  table.table {
    margin-top: 12px;
  }

  button.btn.btn-secondary {
    margin-top: 4px;
  }
`;

export default RuleTablesList;
