import React, { useEffect } from "react";
import styled from "styled-components";
import { useDiagnostics } from "../../../contexts/DiagnosticsContext";
import { useAuthProvider } from "../../../hooks/useAuthProvider";
import { useInterval } from "../../../hooks/useInterval";
import {
  getUIVersion,
  getEnvironment,
  getAPIUrl,
  getProcessorUrl,
} from "../../../services/Version";

function HomeSourceBuildContent({ isSidebar, loadDiagnostics, loading }) {
  const { diagnosticData } = useDiagnostics();
  const { isAuthenticated } = useAuthProvider();

  async function initialLoad() {
    await loadDiagnostics();
  }

  useEffect(() => {
    if (isAuthenticated) {
      initialLoad();
    }
  }, [isAuthenticated]);

  useInterval(() => {
    loadOnInterval();
  }, 30000);

  async function loadOnInterval() {
    await loadDiagnostics();
  }

  const apiBuildVersion = diagnosticData.apiBuildVersion;
  const apiEnv = diagnosticData.apiEnv;
  const uiBuildVersion = getUIVersion();
  const environment = getEnvironment();
  const apiUrl = getAPIUrl();
  const processorUrl = getProcessorUrl();

  return (
    <StyledContentDiv>
      <div className="row">
        <div className={isSidebar ? "col-12" : "col-12 col-md-6"}>
          <StyledInfoSection>
            <h4>UI Environment / Version</h4>
            <h5>{environment}</h5>
            <p>{uiBuildVersion}</p>
          </StyledInfoSection>
          <StyledInfoSection>
            <h4>API Environment / Version</h4>
            <h5>{apiEnv}</h5>
            <p>{apiBuildVersion}</p>
          </StyledInfoSection>
          <StyledInfoSection>
            <h4>API URL</h4>
            <p>{apiUrl}</p>
          </StyledInfoSection>
          <StyledInfoSection>
            <h4>Processor URL</h4>
            <p>{processorUrl}</p>
          </StyledInfoSection>
        </div>
      </div>
    </StyledContentDiv>
  );
}

const StyledContentDiv = styled.div`
  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-medium);
  }

  hr {
    margin: 10px 0 10px 0;
  }
`;

const StyledInfoSection = styled.div`
  margin-bottom: 10px;

  h5 {
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-normal);
    text-transform: uppercase;
  }

  p {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-normal);
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default HomeSourceBuildContent;
