import _ from "lodash";
import { parseISO } from "date-fns";
import { NodeTypes } from "../components/screens/rulemaps/RuleMapDataCommon";

export const emptyGroupRuleMap = {
  effectiveDate: new Date("1/1/2000"),
  terminationDate: new Date("1/1/3000"),
  groupId: "",
  name: "",
  description: "",
  clientId: { value: "N/A", label: "N/A" },
  clientIdVm: "",
  client: "",
};

export const emptyGroupRuleMapSearch = {
  client: "",
  clientId: "",
  groupId: "",
  name: "",
  active: true,
  orderBy: ["groupId+"],
  pageSize: 10000, // Always load all rule maps for groups
  pageNumber: 1,
  groupsCount: 0,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export const emptyGroupRuleMapFilter = {
  freeFormSearch: "",
  showAdvancedFilter: true,
};

export const emptyRuleMapField = {
  isNew: false,
  type: NodeTypes.Unknown,
  id: "",
  sourceKey: "",
  sourceType: "",
  condition: "IsCompared",
  comparison: "=",
  comparisonValues: [],
  ruleId: "-1",
  staging: false,
  effectiveDate: null,
  terminationDate: null,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  if (vm.terminationDate > new Date("1/1/2999")) vm.terminationDate = null;
  vm.state = (vm.stateVm || { value: "" }).value || "";
  vm.clientId = (vm.clientIdVm || { value: "N/A" }).value;

  let m = vm;
  return m;
}

export function createViewModel(m, clientList) {
  let vm = _.cloneDeep(m);
  if (_.isNull(vm.effectiveDate)) {
    vm.effectiveDate = emptyGroupRuleMap.effectiveDate;
  } else {
    vm.effectiveDate = parseISO(vm.effectiveDate);
  }
  if (_.isNull(vm.terminationDate))
    vm.terminationDate = emptyGroupRuleMap.terminationDate;
  else {
    vm.terminationDate = parseISO(vm.terminationDate);
  }

  vm.clientIdVm =
    (clientList || []).find((c) => c.value === vm.clientId) ||
    emptyGroupRuleMapSearch.clientId;
  vm.client = vm.clientIdVm.label;

  return vm;
}
