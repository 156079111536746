import React from "react";
import styled from "styled-components";
import ReadOnly from "../../common/input/ReadOnly";
import {
  getEntryDescription,
  getEntryName,
  getEntryNamespace,
  getFilterNameFromEntryType,
  getIconForEntry,
} from "./CoverageTrackingCommon";
import { formatNumberForDisplay } from "../../../services/General";
import { useTestCoverageTracking } from "../../../contexts/TestCoverageTrackingContext";

function CoverageTrackingRecordPropertiesContent({ entry }) {
  const { testCoverageTrackingData } = useTestCoverageTracking();

  const labelStyle = { color: "var(--text-dark)" };

  let lastCallNumber = -1;
  const description = getEntryDescription(entry.description);
  const namespace = getEntryNamespace(entry.name);
  const paramTypes =
    (entry.ParamTypes || []).length === 0 ? [] : entry.ParamTypes.split(",");

  return (
    <StyledContainer>
      <form>
        <div className="filter-search-form">
          <div className="form-group">
            <label style={labelStyle}>Type</label>
            <div className="field" style={{ marginTop: "12px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {getFilterNameFromEntryType(entry.entryType)}
                <div style={{ marginLeft: "auto" }}>
                  {getIconForEntry(entry)}
                </div>
              </div>
            </div>
          </div>

          <ReadOnly
            id="name"
            label="Name"
            labelStyle={labelStyle}
            value={getEntryName(entry.name)}
          />
          <ReadOnly
            id="namespace"
            label="Namespace"
            labelStyle={labelStyle}
            value={namespace === "" ? "-" : namespace}
          />
          <ReadOnly
            id="description"
            label="Description"
            labelStyle={labelStyle}
            value={description === "" ? "-" : description}
          />
          <div className="form-group">
            <label style={labelStyle}>Parameter Types</label>
            <div className="field">
              {paramTypes.length === 0 ? (
                <>-</>
              ) : (
                <ul>
                  {paramTypes.map((t, idx) => (
                    <li key={idx}>{t}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <ReadOnly
            id="timesCalled"
            label="Times Called"
            labelStyle={labelStyle}
            value={`${formatNumberForDisplay(entry.count)}`}
          />
          <ReadOnly
            id="covered"
            label="Test Coverage?"
            labelStyle={labelStyle}
            value={`${entry.covered ? "Yes" : "No"}`}
          />
          <ReadOnly
            id="headless"
            label="Headless?"
            labelStyle={labelStyle}
            value={`${entry.orphaned ? "Yes" : "No"}`}
          />
          {/* Hide properties in group mode */}
          {testCoverageTrackingData.search.groupMode !== true && (
            <div className="form-group" style={{ marginTop: "30px" }}>
              <label>Properties</label>
              <div className="field">
                {(entry.associatedValues || []).length === 0 ? (
                  <i>None</i>
                ) : (
                  <>
                    {entry.associatedValues.map((v, idx) => {
                      const output = (
                        <React.Fragment key={idx}>
                          {v.callNumber !== lastCallNumber &&
                            v.callNumber !== 0 && (
                              <StyledCallDiv>
                                Call #{v.callNumber}
                              </StyledCallDiv>
                            )}
                          <ReadOnly
                            id={`val-${v.callNumber}-${idx}`}
                            label={v.key}
                            labelStyle={labelStyle}
                            value={v.value}
                          />
                        </React.Fragment>
                      );

                      if (v.callNumber !== lastCallNumber) {
                        lastCallNumber = v.callNumber;
                      }

                      return output;
                    })}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  padding: 0;
`;

const StyledCallDiv = styled.div`
  background-color: var(--selected-bg);
  margin-top: 10px;
  padding: 4px 2px;
  font-style: italic;
`;

export default CoverageTrackingRecordPropertiesContent;
