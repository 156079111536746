import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createViewModel,
  fromViewModel,
  emptyDrug,
} from "../../../viewmodels/drugsVm";
import {
  handleCollapseExpandAll,
  isNumber,
  isPositiveInteger,
} from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import { useDrugs } from "../../../contexts/DrugsContext";
import { useAuth } from "../../../contexts/AuthContext";
import { apiAddDrug, apiLoadDrug, apiUpdateDrug } from "../../../api/DrugApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import DrugIngredientsSection from "./DrugIngredientsSection";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Drug() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { drugsData, setDrugsData } = useDrugs();
  const { loading, api: apiLoad } = useApi(apiLoadDrug);
  const { loading: adding, api: apiAdd } = useApi(apiAddDrug);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateDrug);
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyDrug);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Drug", collapsed: false },
    { name: "Ingredients", collapsed: true },
    { name: "Drug Details", collapsed: true },
  ]);

  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setDrugsData({
        type: ContextProviderActions.loadDrug,
        payload: emptyDrug,
      });

      loadDrug();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (drugsData.drug) {
      setChanges(drugsData.drug);
    } else {
      setChanges(emptyDrug);
    }
  }, [drugsData.drug]);

  async function loadDrug() {
    if (!resId) {
      setDrugsData({
        type: ContextProviderActions.loadDrug,
        payload: emptyDrug,
      });
      setChanges(emptyDrug);
      return;
    }

    apiLoad.call(resId, (result) => {
      let vm = emptyDrug;
      if (!result) {
        notifyError("Drug does not exist");
      } else {
        vm = createViewModel(result);
      }

      setDrugsData({
        type: ContextProviderActions.loadDrug,
        payload: vm,
      });
      setChanges(vm);
    });
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    if (_.has(changes, "productName") && changes.productName.trim() === "")
      _errors.productName = "Product Name must be entered";
    if (_.has(changes, "ndc") && changes.ndc.trim() === "")
      _errors.ndc = "NDC must be entered";
    if (_.has(changes, "wacUnitPrice") && !isNumber(changes.wacUnitPrice))
      _errors.wacUnitPrice = "WAC Unit Price must be a valid number";
    if (_.has(changes, "wacPackagePrice") && !isNumber(changes.wacPackagePrice))
      _errors.wacPackagePrice = "WAC Package Price must be a valid number";
    if (_.has(changes, "awpUnitPrice") && !isNumber(changes.awpUnitPrice))
      _errors.awpUnitPrice = "AWP Unit Price must be a valid number";
    if (_.has(changes, "awpPackagePrice") && !isNumber(changes.awpPackagePrice))
      _errors.awpPackagePrice = "AWP Package Price must be a valid number";
    if (_.has(changes, "directUnitPrice") && !isNumber(changes.directUnitPrice))
      _errors.directUnitPrice = "Direct Unit Price must be a valid number";
    if (
      _.has(changes, "directPackagePrice") &&
      !isNumber(changes.directPackagePrice)
    )
      _errors.directPackagePrice =
        "Direct Package Price must be a valid number";
    if (_.has(changes, "metricStrength") && !isNumber(changes.metricStrength))
      _errors.metricStrength = "Metric Strength must be a valid number";
    if (_.has(changes, "packageSize") && !isNumber(changes.packageSize))
      _errors.packageSize = "Package Size must be a valid number";
    if (
      _.has(changes, "labelerCode") &&
      !isPositiveInteger(changes.labelerCode)
    )
      _errors.labelerCode = "Labeler Code must be a valid number";
    if (_.has(changes, "genericId") && !isPositiveInteger(changes.genericId))
      _errors.genericId = "Generic Id must be a valid number";
    if (
      _.has(changes, "ahfsccTherapeuticClass") &&
      !isPositiveInteger(changes.ahfsccTherapeuticClass)
    )
      _errors.ahfsccTherapeuticClass =
        "Therapeutic Class must be a valid number";
    if (
      _.has(changes, "ahfsccTherapeuticClassExtended") &&
      !isPositiveInteger(changes.ahfsccTherapeuticClassExtended)
    )
      _errors.ahfsccTherapeuticClassExtended =
        "Therapeutic Class Extended must be a valid number";
    if (
      _.has(changes, "allergyPatternCode") &&
      !isPositiveInteger(changes.allergyPatternCode)
    )
      _errors.allergyPatternCode =
        "Allergy Pattern Code must be a valid number";

    if (_.has(changes, "packageQuantity") && !isNumber(changes.packageQuantity))
      _errors.packageQuantity = "Package Quantity must be a valid number";
    if (
      _.has(changes, "totalPackageQuantity") &&
      !isNumber(changes.totalPackageQuantity)
    )
      _errors.totalPackageQuantity =
        "Total Package Quantity must be a valid number";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };

    if (!resId) {
      addDrug(newVm);
    } else updateDrug(newVm.id, newVm);
  }

  async function addDrug(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      setChanges(createViewModel(result));
      notifySuccess("Drug " + vm.ndc + " saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/drug/" + result.id, { replace: true });
    });
  }

  async function updateDrug(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      setChanges(createViewModel(result));
      notifySuccess("Drug " + vm.ndc + " saved successfully");
    });
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return To Drugs"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate("/configuredrugs")}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Configure/Drug-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>Drug Details {changes.ndc ? ` | ${changes.ndc}` : ""}</h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/configuredrugs")}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {loading || adding || updating ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/drug/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Drug"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Drug-Screen&anchor=drug"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="ndc"
                      label="NDC"
                      onChange={handleChange}
                      placeholder="NDC"
                      name="ndc"
                      value={changes.ndc || ""}
                      error={errors.ndc}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="productName"
                      label="Product Name"
                      onChange={handleChange}
                      placeholder="Product Name"
                      name="productName"
                      value={changes.productName || ""}
                      error={errors.productName}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="productNameExt"
                      label="Product Name Ext"
                      onChange={handleChange}
                      placeholder="Product Name Ext"
                      name="productNameExt"
                      value={changes.productNameExt || ""}
                      error={errors.productNameExt}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="gpiVm"
                      label="GPI"
                      onChange={handleChange}
                      placeholder="GPI"
                      name="gpiVm"
                      value={changes.gpiVm || ""}
                      error={errors.gpi}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="genericNameVm"
                      label="Generic Name"
                      onChange={handleChange}
                      placeholder="Generic Name"
                      name="genericNameVm"
                      value={changes.genericNameVm || ""}
                      error={errors.genericName}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="gppcVm"
                      label="GPPC"
                      onChange={handleChange}
                      placeholder="GPPC"
                      name="gppcVm"
                      value={changes.gppcVm || ""}
                      error={errors.gppc}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="manufacturerNameVm"
                      label="Manufacturer Name"
                      onChange={handleChange}
                      placeholder="Manufacturer Name"
                      name="manufacturerNameVm"
                      value={changes.manufacturerNameVm || ""}
                      error={errors.manufacturerName}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="manufacturerAbbrevNameVm"
                      label="Manufacturer Abbrev Name"
                      onChange={handleChange}
                      placeholder="Manufacturer Abbrev Name"
                      name="manufacturerAbbrevNameVm"
                      value={changes.manufacturerAbbrevNameVm || ""}
                      error={errors.manufacturerAbbrevName}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="drugNameCodeVm"
                      label="Drug Name Code"
                      onChange={handleChange}
                      placeholder="Drug Name Code"
                      name="drugNameCodeVm"
                      value={changes.drugNameCodeVm || ""}
                      error={errors.drugNameCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="wacUnitPrice"
                      label="WAC Unit Price"
                      onChange={handleChange}
                      placeholder="WAC Unit Price"
                      name="wacUnitPrice"
                      value={changes.wacUnitPrice}
                      error={errors.wacUnitPrice}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="wacPackagePrice"
                      label="WAC Package Price"
                      onChange={handleChange}
                      placeholder="WAC Package Price"
                      name="wacPackagePrice"
                      value={changes.wacPackagePrice}
                      error={errors.wacPackagePrice}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="awpUnitPrice"
                      label="AWP Unit Price"
                      onChange={handleChange}
                      placeholder="AWP Unit Price"
                      name="awpUnitPrice"
                      value={changes.awpUnitPrice}
                      error={errors.awpUnitPrice}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="awpPackagePrice"
                      label="AWP Package Price"
                      onChange={handleChange}
                      placeholder="AWP Package Price"
                      name="awpPackagePrice"
                      value={changes.awpPackagePrice}
                      error={errors.awpPackagePrice}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="directUnitPrice"
                      label="Direct Unit Price"
                      onChange={handleChange}
                      placeholder="Direct Unit Price"
                      name="directUnitPrice"
                      value={changes.directUnitPrice}
                      error={errors.directUnitPrice}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="directPackagePrice"
                      label="Direct Package Price"
                      onChange={handleChange}
                      placeholder="Direct Package Price"
                      name="directPackagePrice"
                      value={changes.directPackagePrice}
                      error={errors.directPackagePrice}
                      isNumber={true}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Ingredients"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Drug-Screen&anchor=ingredients"
              >
                <DrugIngredientsSection
                  changes={changes}
                  setChanges={setChanges}
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Drug Details"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Drug-Screen&anchor=drug-details"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="formatCode"
                      label="Format Code"
                      onChange={handleChange}
                      placeholder="Format Code"
                      name="formatCode"
                      value={changes.formatCode || ""}
                      error={errors.formatCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="transactionCode"
                      label="Transaction Code"
                      onChange={handleChange}
                      placeholder="Transaction Code"
                      name="transactionCode"
                      value={changes.transactionCode || ""}
                      error={errors.transactionCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="sequenceCode"
                      label="Sequence Code"
                      onChange={handleChange}
                      placeholder="Sequence Code"
                      name="sequenceCode"
                      value={changes.sequenceCode || ""}
                      error={errors.sequenceCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="labelerCode"
                      label="Labeler Code"
                      onChange={handleChange}
                      placeholder="Labeler Code"
                      name="labelerCode"
                      value={changes.labelerCode}
                      error={errors.labelerCode}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="genericIdType"
                      label="Generic Id Type"
                      onChange={handleChange}
                      placeholder="Generic Id Type"
                      name="genericIdType"
                      value={changes.genericIdType || ""}
                      error={errors.genericIdType}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="genericId"
                      label="Generic Id"
                      onChange={handleChange}
                      placeholder="Generic Id"
                      name="genericId"
                      value={changes.genericId}
                      error={errors.genericId}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="deaClassCode"
                      label="DEA Class Code"
                      onChange={handleChange}
                      placeholder="DEA Class Code"
                      name="deaClassCode"
                      value={changes.deaClassCode || ""}
                      error={errors.deaClassCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="ahfsccTherapeuticClass"
                      label="Therapeutic Class"
                      onChange={handleChange}
                      placeholder="Therapeutic Class"
                      name="ahfsccTherapeuticClass"
                      value={changes.ahfsccTherapeuticClass}
                      error={errors.ahfsccTherapeuticClass}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="ahfsccTherapeuticClassExtended"
                      label="Therapeutic Class Extended"
                      onChange={handleChange}
                      placeholder="Therapeutic Class Extended"
                      name="ahfsccTherapeuticClassExtended"
                      value={changes.ahfsccTherapeuticClassExtended}
                      error={errors.ahfsccTherapeuticClassExtended}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="itemStatus"
                      label="Item Status"
                      onChange={handleChange}
                      placeholder="Item Status"
                      name="itemStatus"
                      value={changes.itemStatus || ""}
                      error={errors.itemStatus}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="local"
                      label="Local"
                      onChange={handleChange}
                      placeholder="Local"
                      name="local"
                      value={changes.local || ""}
                      error={errors.local}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="therapeuticEquivEval"
                      label="Therapeutic Equiv Eval"
                      onChange={handleChange}
                      placeholder="Therapeutic Equiv Eval"
                      name="therapeuticEquivEval"
                      value={changes.therapeuticEquivEval || ""}
                      error={errors.therapeuticEquivEval}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="formattedId"
                      label="Formatted Id"
                      onChange={handleChange}
                      placeholder="Formatted Id"
                      name="formattedId"
                      value={changes.formattedId || ""}
                      error={errors.formattedId}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="rxOtcIndicator"
                      label="OTC Indicator"
                      onChange={handleChange}
                      placeholder="OTC Indicator"
                      name="rxOtcIndicator"
                      value={changes.rxOtcIndicator || ""}
                      error={errors.rxOtcIndicator}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="thirdPartyRestriction"
                      label="Third Party Restriction"
                      onChange={handleChange}
                      placeholder="Third Party Restriction"
                      name="thirdPartyRestriction"
                      value={changes.thirdPartyRestriction || ""}
                      error={errors.thirdPartyRestriction}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="maintenanceDrug"
                      label="Maintenance Drug"
                      onChange={handleChange}
                      placeholder="Maintenance Drug"
                      name="maintenanceDrug"
                      value={changes.maintenanceDrug || ""}
                      error={errors.maintenanceDrug}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="dispensingUnit"
                      label="Dispensing Unit"
                      onChange={handleChange}
                      placeholder="Dispensing Unit"
                      name="dispensingUnit"
                      value={changes.dispensingUnit || ""}
                      error={errors.dispensingUnit}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="unitDose"
                      label="Unit Dose"
                      onChange={handleChange}
                      placeholder="Unit Dose"
                      name="unitDose"
                      value={changes.unitDose || ""}
                      error={errors.unitDose}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="routeOfAdministration"
                      label="Route Of Administration"
                      onChange={handleChange}
                      placeholder="Route Of Administration"
                      name="routeOfAdministration"
                      value={changes.routeOfAdministration || ""}
                      error={errors.routeOfAdministration}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="formType"
                      label="Form Type"
                      onChange={handleChange}
                      placeholder="Form Type"
                      name="formType"
                      value={changes.formType || ""}
                      error={errors.formType}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="dollarRank"
                      label="Dollar Rank"
                      onChange={handleChange}
                      placeholder="Dollar Rank"
                      name="dollarRank"
                      value={changes.dollarRank || ""}
                      error={errors.dollarRank}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="rxRank"
                      label="Rx Rank"
                      onChange={handleChange}
                      placeholder="Rx Rank"
                      name="rxRank"
                      value={changes.rxRank || ""}
                      error={errors.rxRank}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="numberSystemCharacter"
                      label="Number System Character"
                      onChange={handleChange}
                      placeholder="Number System Character"
                      name="numberSystemCharacter"
                      value={changes.numberSystemCharacter || ""}
                      error={errors.numberSystemCharacter}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="secondaryIdFormat"
                      label="Secondary Id Format"
                      onChange={handleChange}
                      placeholder="Secondary Id Format"
                      name="secondaryIdFormat"
                      value={changes.secondaryIdFormat || ""}
                      error={errors.secondaryIdFormat}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="secondaryId"
                      label="Secondary Id"
                      onChange={handleChange}
                      placeholder="Secondary Id"
                      name="secondaryId"
                      value={changes.secondaryId || ""}
                      error={errors.secondaryId}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="multiSourceCode"
                      label="Multi Source Code"
                      onChange={handleChange}
                      placeholder="Multi Source Code"
                      name="multiSourceCode"
                      value={changes.multiSourceCode || ""}
                      error={errors.multiSourceCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="brandNameCode"
                      label="Brand Name Code"
                      onChange={handleChange}
                      placeholder="Brand Name Code"
                      name="brandNameCode"
                      value={changes.brandNameCode || ""}
                      error={errors.brandNameCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="reimbursementIndicator"
                      label="Reimbursement Indicator"
                      onChange={handleChange}
                      placeholder="Reimbursement Indicator"
                      name="reimbursementIndicator"
                      value={changes.reimbursementIndicator || ""}
                      error={errors.reimbursementIndicator}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="internalCode"
                      label="Internal Code"
                      onChange={handleChange}
                      placeholder="Internal Code"
                      name="internalCode"
                      value={changes.internalCode || ""}
                      error={errors.internalCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="singleCombinationCode"
                      label="Single Combination Code"
                      onChange={handleChange}
                      placeholder="Single Combination Code"
                      name="singleCombinationCode"
                      value={changes.singleCombinationCode || ""}
                      error={errors.singleCombinationCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="storageConditionCode"
                      label="Storage Condition Code"
                      onChange={handleChange}
                      placeholder="Storage Condition Code"
                      name="storageConditionCode"
                      value={changes.storageConditionCode || ""}
                      error={errors.storageConditionCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="limitedStabilityCode"
                      label="Limited Stability Code"
                      onChange={handleChange}
                      placeholder="Limited Stability Code"
                      name="limitedStabilityCode"
                      value={changes.limitedStabilityCode || ""}
                      error={errors.limitedStabilityCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="allergyPatternCode"
                      label="Allergy Pattern Code"
                      onChange={handleChange}
                      placeholder="Allergy Pattern Code"
                      name="allergyPatternCode"
                      value={changes.allergyPatternCode}
                      error={errors.allergyPatternCode}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="ppgIndicatorCode"
                      label="PPG Indicator Code"
                      onChange={handleChange}
                      placeholder="PPG Indicator Code"
                      name="ppgIndicatorCode"
                      value={changes.ppgIndicatorCode || ""}
                      error={errors.ppgIndicatorCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="hfpgIndicatorCode"
                      label="HFPG Indicator Code"
                      onChange={handleChange}
                      placeholder="HFPG Indicator Code"
                      name="hfpgIndicatorCode"
                      value={changes.hfpgIndicatorCode || ""}
                      error={errors.hfpgIndicatorCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="labelerTypeCode"
                      label="Labeler Type Code"
                      onChange={handleChange}
                      placeholder="Labeler Type Code"
                      name="labelerTypeCode"
                      value={changes.labelerTypeCode || ""}
                      error={errors.labelerTypeCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="pricingSpreadCode"
                      label="Pricing Spread Code"
                      onChange={handleChange}
                      placeholder="Pricing Spread Code"
                      name="pricingSpreadCode"
                      value={changes.pricingSpreadCode || ""}
                      error={errors.pricingSpreadCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="metricStrength"
                      label="Metric Strength"
                      onChange={handleChange}
                      placeholder="Metric Strength"
                      name="metricStrength"
                      value={changes.metricStrength}
                      error={errors.metricStrength}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="strengthUnitMeasure"
                      label="Strength Unit Measure"
                      onChange={handleChange}
                      placeholder="Strength Unit Measure"
                      name="strengthUnitMeasure"
                      value={changes.strengthUnitMeasure}
                      error={errors.strengthUnitMeasure}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="dosageForm"
                      label="Dosage Form"
                      onChange={handleChange}
                      placeholder="Dosage Form"
                      name="dosageForm"
                      value={changes.dosageForm || ""}
                      error={errors.dosageForm}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="packageSize"
                      label="Package Size"
                      onChange={handleChange}
                      placeholder="Package Size"
                      name="packageSize"
                      value={changes.packageSize}
                      error={errors.packageSize}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="packageSizeUnitMeasure"
                      label="Package Size Unit Measure"
                      onChange={handleChange}
                      placeholder="Package Size Unit Measure"
                      name="packageSizeUnitMeasure"
                      value={changes.packageSizeUnitMeasure || ""}
                      error={errors.packageSizeUnitMeasure}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="packageQuantity"
                      label="Package Quantity"
                      onChange={handleChange}
                      placeholder="Package Quantity"
                      name="packageQuantity"
                      value={changes.packageQuantity}
                      error={errors.packageQuantity}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="repackagingCode"
                      label="Repackaging Code"
                      onChange={handleChange}
                      placeholder="Repackaging Code"
                      name="repackagingCode"
                      value={changes.repackagingCode || ""}
                      error={errors.repackagingCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="totalPackageQuantity"
                      label="Total Package Quantity"
                      onChange={handleChange}
                      placeholder="Total Package Quantity"
                      name="totalPackageQuantity"
                      value={changes.totalPackageQuantity}
                      error={errors.totalPackageQuantity}
                      isNumber={true}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="desiCode"
                      label="DESI Code"
                      onChange={handleChange}
                      placeholder="DESI Code"
                      name="desiCode"
                      value={changes.desiCode || ""}
                      error={errors.desiCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="packageDescription"
                      label="Package Description"
                      onChange={handleChange}
                      placeholder="Package Description"
                      name="packageDescription"
                      value={changes.packageDescription || ""}
                      error={errors.packageDescription}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="nextSmallerNdcSuffix"
                      label="Next Smaller Ndc Suffix"
                      onChange={handleChange}
                      placeholder="Next Smaller Ndc Suffix"
                      name="nextSmallerNdcSuffix"
                      value={changes.nextSmallerNdcSuffix || ""}
                      error={errors.nextSmallerNdcSuffix}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="nextLargerNdcSuffix"
                      label="Next Larger Ndc Suffix"
                      onChange={handleChange}
                      placeholder="Next Larger Ndc Suffix"
                      name="nextLargerNdcSuffix"
                      value={changes.nextLargerNdcSuffix || ""}
                      error={errors.nextLargerNdcSuffix}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="innerPackCode"
                      label="Inner Pack Code"
                      onChange={handleChange}
                      placeholder="Inner Pack Code"
                      name="innerPackCode"
                      value={changes.innerPackCode || ""}
                      error={errors.innerPackCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="clinicPackCode"
                      label="Clinic Pack Code"
                      onChange={handleChange}
                      placeholder="Clinic Pack Code"
                      name="clinicPackCode"
                      value={changes.clinicPackCode || ""}
                      error={errors.clinicPackCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="limitedDistributionCode"
                      label="Limited Distribution Code"
                      onChange={handleChange}
                      placeholder="Limited Distribution Code"
                      name="limitedDistributionCode"
                      value={changes.limitedDistributionCode || ""}
                      error={errors.limitedDistributionCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4">
                    <TextInput
                      id="inactiveDate"
                      label="Inactive Date"
                      onChange={handleChange}
                      placeholder="YYYY-MM-DD"
                      name="inactiveDate"
                      value={changes.inactiveDate || ""}
                      error={errors.inactiveDate}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default Drug;
