import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import {
  StyledCard,
  StyledCardValue,
  StyledDescription,
  StyledNodeDiv,
  StyledProcessedIndicator,
} from "./RuleMapCommonStyles";
import RuleMapZoomedOutNode from "./RuleMapZoomedOutNode";
import RuleMapLogsLink from "./RuleMapLogsLink";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
} from "./RuleMapDataCommon";
import RuleMapCopyContentsLink from "./RuleMapCopyContentsLink";

export const DefaultNodeSize = { width: 200, height: 70 };

function RuleMapSimpleNode({ data }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const { ruleMapsData } = useRuleMaps();
  const [detailLevel] = useState(1);
  const [nodeWidth] = useState(DefaultNodeSize.width);
  const [nodeHeight] = useState(DefaultNodeSize.height);
  const divRef = useRef(null);

  useEffect(() => {
    let doNodeUpdate = false;
    if (data.layoutDirection !== ruleMapsData?.layoutDirection) {
      doNodeUpdate = true;
    }
    if (detailLevel !== ruleMapsData?.detailLevel) {
      doNodeUpdate = true;
    }

    if (doNodeUpdate) {
      updateNodeInternals(data.id);
    }
  }, [
    ruleMapsData?.detailLevel,
    data.layoutDirection,
    ruleMapsData?.layoutDirection,
  ]);

  const colors = getBadgeColorsFromType(
    data.type,
    data.isActualNodeLink,
    data.isProcessed
  );

  const showDetails =
    ruleMapsData?.detailLevel === 3 || ruleMapsData?.selectedNodeId === data.id;

  if (ruleMapsData?.detailLevel === 1 && !showDetails) {
    return <RuleMapZoomedOutNode data={data} />;
  }

  return (
    <StyledNodeDiv width={nodeWidth} height={nodeHeight}>
      {!data.isLeafNode && (
        <Handle
          type="target"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Left
              : Position.Top
          }
        />
      )}
      <StyledCard colors={colors} ref={divRef}>
        {!data.isActualNodeLink && data.isProcessed && (
          <StyledProcessedIndicator>
            <span className="material-symbols-outlined">electric_bolt</span>
          </StyledProcessedIndicator>
        )}

        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={colors}
        >
          <span>
            <RuleMapCopyContentsLink
              show={showDetails}
              text={divRef.current?.innerText}
            />
            {showDetails ? data.displayKey : data.itemKey}
          </span>
          <span className="charblock">
            {getNodeTypeAbbreviation(data.type)}
          </span>
        </StyledDescription>
        {showDetails && !_.isEmpty(data.description) && (
          <StyledDescription
            className="flex-row-with-wrap-and-justify"
            colors={colors}
            style={{ fontSize: "13px" }}
          >
            <span>{data.description}</span>
          </StyledDescription>
        )}
        {showDetails && data.module && data.module !== null && (
          <StyledDescription
            className="flex-row-with-wrap-and-justify"
            colors={colors}
            style={{ fontSize: "13px" }}
          >
            Module: {data.module.name}
          </StyledDescription>
        )}
        {!_.isEmpty(data.value) && (
          <StyledCardValue>{data.value}</StyledCardValue>
        )}

        <RuleMapLogsLink logs={data.logs} title={data.itemKey} />
      </StyledCard>
      {!data.isRootNode && (
        <Handle
          type="source"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Right
              : Position.Bottom
          }
        />
      )}
    </StyledNodeDiv>
  );
}

export default RuleMapSimpleNode;
