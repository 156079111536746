import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyTestGroupResult,
  emptyTestGroupResultSearch,
  emptyTestResult,
  emptyTestResultSearch,
} from "../viewmodels/testResultsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadTestResults:
      return {
        ...state,
        testResults: action.payload.testResults,
        count: action.payload.count,
      };

    case ContextProviderActions.saveTestResultSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadTestGroupResult:
      return {
        ...state,
        testGroupResult: action.payload,
      };

    case ContextProviderActions.saveTestGroupResultSearch:
      return {
        ...state,
        testGroupSearch: action.payload,
      };

    case ContextProviderActions.loadTestResult:
      return {
        ...state,
        testResult: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in TestResultsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  testResults: [],
  testGroupResult: emptyTestGroupResult,
  testResult: emptyTestResult,
  search: emptyTestResultSearch,
  testGroupSearch: emptyTestGroupResultSearch,
  count: 0,
};

const TestResults = createContext({
  testResultsData: initialState,
  setTestResultsData: () => null,
});

// eslint-disable-next-line react/prop-types
const TestResultsProvider = ({ children }) => {
  const [testResultsData, setTestResultsData] = useReducer(
    reducer,
    initialState
  );

  return (
    <TestResults.Provider value={{ testResultsData, setTestResultsData }}>
      {children}
    </TestResults.Provider>
  );
};

export default TestResultsProvider;
export const useTestResults = () => useContext(TestResults);
