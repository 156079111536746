import {
  addItemToArrayIfNotExist,
  buildChildField,
  getFieldFromFieldListById,
  locateAllParentsOfField,
  NodeTypes,
  removeChildFromFieldById,
} from "./RuleMapDataCommon";

function buildModuleSubnode(field) {
  const subnode = {
    id: field.id,
    name: field.name,
    value: field.value,
    type: field.type,
    module: field.module,
    isProcessed: field.isProcessed,
    isActualNodeLink: field.isActualNodeLink,
    children: [...field.children],
  };

  return subnode;
}

function buildModuleInputSubnode(field) {
  const subnode = {
    id: field.id,
    name: field.name,
    value: field.value,
    type: field.type,
    isProcessed: field.isProcessed,
    isActualNodeLink: field.isActualNodeLink,
  };

  return subnode;
}

function populateModulesFromField(field, modules) {
  if (field.module === null) return null;

  // if (!field.module) {
  //   console.log("here");
  // }

  let module = {
    ...field.module,
    id: field.module.name,
    subnodes: [],
  };

  // Add to modules list so we can track unique modules in the graph
  if (modules.findIndex((m) => m.name === module.name) < 0) {
    // Add this field's id to the module's subnodes
    module.subnodes.push(buildModuleSubnode(field));

    // Save the module
    modules.push(module);
  } else {
    // This is a subnode for the module. Save its id with the module for redirecting edges
    module = modules.find((m) => m.name === module.name);
    if (module.subnodes.findIndex((s) => s.id === field.id) < 0) {
      module.subnodes.push(buildModuleSubnode(field));
    }
  }
}

function extractModulesFromRuleMap(fieldList) {
  const modules = [];

  for (let i = 0; i < fieldList.length; i++) {
    populateModulesFromField(fieldList[i], modules);
  }

  return modules;
}

function doesItemAlreadyExistInArray(item, array) {
  const exists = array.findIndex((s) => s.id === item.id) >= 0;
  return exists;
}

function doesItemQualifyAsInputSubnode(item, subnodes) {
  const valid =
    (!item.module || item.module === null) &&
    !doesItemAlreadyExistInArray(item, subnodes) &&
    (item.type === NodeTypes.RequestFieldValue ||
      item.type === NodeTypes.GroupSetting ||
      item.type === NodeTypes.EntityData ||
      item.type === NodeTypes.Flag);
  return valid;
}

function getSubnodesForModuleInput(fieldList, subnode, subnodes) {
  // Each child of this subnode that is not part of a module but is a Request Field, Entity Data, or Group Setting should be added as a subnode
  let child;
  const children = subnode.children || [];
  for (let i = 0; i < children.length; i++) {
    child = getFieldFromFieldListById(fieldList, children[i].id);

    if (!child) {
      console.log(
        "Child not found in getSubnodesForModuleInput",
        children[i].id
      );
    } else {
      if (doesItemQualifyAsInputSubnode(child, subnodes)) {
        subnodes.push(buildModuleInputSubnode(child));
      }

      // If this child is an artifact input, use its leafnodes to build a module input subnode - but only for ones that are input values
      if (child.type === NodeTypes.CodeArtifactInput) {
        for (let j = 0; j < child.leafNodes.length; j++) {
          if (doesItemQualifyAsInputSubnode(child.leafNodes[j], subnodes)) {
            subnodes.push(buildModuleInputSubnode(child.leafNodes[j]));
          }
        }
      }
    }
  }
}

function buildModuleInputField(fieldList, module) {
  const name = `${module.name} Input`;
  const subnodes = [];

  // Go through subnodes of module and find children that are not part of a module and are Request Fields, Entity Data, or Group Settings to include in this input field.
  for (let i = 0; i < module.subnodes.length; i++) {
    getSubnodesForModuleInput(fieldList, module.subnodes[i], subnodes);
  }
  if (subnodes.length === 0) return null;

  // If all subnodes are ghosts, make the module input itself a ghost
  const isGhostLink =
    subnodes.filter((s) => s.isActualNodeLink === false).length ===
    subnodes.length;

  // If any subnodes are processed, make the module itself processed
  const isProcessed =
    module.subnodes.filter((s) => s.isProcessed === true).length > 0;

  const value = `${subnodes.length} input${subnodes.length > 1 ? "s" : ""}`;
  const id = `INPUT-${module.id}`;

  const retField = {
    id: id,
    key: id,
    sourceKey: id,
    name: name,
    type: NodeTypes.ModuleInputNode,
    description: "",
    value: value,
    links: [],
    ruleTableData: [],
    logs: [],
    translatedValues: [],
    isProcessed: isProcessed,
    isActualNodeLink: !isGhostLink,
    children: [],
    leafNodes: [],
    updatesKeys: [],
    runValues: [],
    conditionals: [],
    subnodes: subnodes || [],
  };

  return retField;
}

function buildModuleField(module) {
  // If all subnodes are ghosts, make the module itself a ghost
  const isGhostLink =
    module.subnodes.filter((s) => s.isActualNodeLink === false).length ===
    module.subnodes.length;

  // If any subnodes are processed, make the module itself processed
  const isProcessed =
    module.subnodes.filter((s) => s.isProcessed === true).length > 0;

  const retField = {
    id: module.id,
    key: module.id,
    sourceKey: module.id,
    name: module.name,
    type: NodeTypes.ModuleNode,
    description: "",
    value: "",
    links: [],
    ruleTableData: [],
    logs: [],
    translatedValues: [],
    isProcessed: isProcessed,
    isActualNodeLink: !isGhostLink,
    children: [],
    leafNodes: [],
    updatesKeys: [],
    runValues: [],
    conditionals: [],
    subnodes: module.subnodes || [],
  };

  console.log(`Module ${retField.name}`, retField.subnodes);

  return retField;
}

function getAllParentsForModule(moduleId, fieldList, field, parents) {
  // Continue to check parents until we are out of the module on all paths
  const parentList = locateAllParentsOfField(fieldList, field);
  let parent;

  for (let i = 0; i < parentList.length; i++) {
    parent = parentList[i];

    if (parent.module !== null && parent.module?.id === moduleId) {
      // This field is still in the module so go back another layer and collect any parents of this field.
      getAllParentsForModule(moduleId, fieldList, parent, parents);
    } else {
      addItemToArrayIfNotExist(parents, {
        field: parent,
        parentId: field.id,
        id: parent.id,
      });
    }
  }
}

function getAllChildrenForModule(moduleId, fieldList, field, children) {
  // Continue to check children until we are out of the module on all paths
  const childList = field.children || [];
  let child;

  for (let i = 0; i < childList.length; i++) {
    child = getFieldFromFieldListById(fieldList, childList[i].id);

    if (child.module !== null && child.module?.id === moduleId) {
      // This field is still in the module so go down another layer and collect any children of this field.
      getAllChildrenForModule(moduleId, fieldList, child, children);
    } else {
      addItemToArrayIfNotExist(children, {
        field: child,
        childId: child.id,
        id: child.id,
      });
    }
  }
}

function transformFieldForModule(fieldList, field) {
  // This field is part of a module so we will do the following to add it to the module:
  // 1. Find all parents of this field that are not in this module and point them at the module instead in their children array.
  //    Only do this once for each parent found.
  // 2. Find all children of this field that are not part of a module and add them as children to the module. Do this only one
  //    for each child and only for ones not already in the module input node.
  const moduleId = field.module.id;
  const moduleField = getFieldFromFieldListById(fieldList, moduleId);
  const moduleInputField = getFieldFromFieldListById(
    fieldList,
    `INPUT-${moduleId}`
  );

  // if (moduleId === "ValidatePrescriber") {
  //   console.log("here");
  // }
  // if (field.id === "CodeArtifact-Discounts/Discounts") {
  //   console.log("here");
  // }

  // We need all parents of this field, working up the tree until we get out of the module. The first nodes outside the module should point back to the module.
  let parentField;
  let parentFields = [];
  let parent;

  getAllParentsForModule(moduleId, fieldList, field, parentFields);

  for (let i = 0; i < parentFields.length; i++) {
    parent = parentFields[i];

    // Remove this field from its parent's children
    parentField = getFieldFromFieldListById(fieldList, parent.field.id);
    if (parentField !== null) {
      removeChildFromFieldById(parentField, parent.parentId);

      // Add the module as a child
      addItemToArrayIfNotExist(parentField.children, buildChildField(moduleId));
    }
  }

  // We need all children of this field, working down the tree until we get out of the module. The first nodes outside the module (not in input node) should be children of the module.
  let child, childId, childField;
  let childFields = [];

  getAllChildrenForModule(moduleId, fieldList, field, childFields);

  for (let i = 0; i < childFields.length; i++) {
    child = childFields[i];

    childField = getFieldFromFieldListById(fieldList, child.field.id);
    if (childField === null) {
      console.log(`Unable to locate field with id ${child.field.id}`);
    } else {
      if (
        doesItemAlreadyExistInArray(
          child.field,
          moduleInputField?.subnodes || []
        )
      )
        continue;

      // Add this child as a child of the module if it not already part of one and it does not already appear in the module input node
      childId = child.childId;
      if (childField.module && childField.module !== null) {
        childId = childField.module.id;
      }

      addItemToArrayIfNotExist(moduleField.children, buildChildField(childId));
    }
  }
}

function transformFieldListForModules(fieldList) {
  let field;
  for (let i = 0; i < fieldList.length; i++) {
    field = fieldList[i];
    if (field.module && field.module !== null) {
      transformFieldForModule(fieldList, field);
    }
  }
}

function traverseTreeForFieldToBuildModules(fieldList, field) {
  if (!field || field === null) return;

  // Get modules for this field and then check all of its children
  if (field.module && field.module !== null) {
    transformFieldForModule(fieldList, field);
  }
  const children = [...field.children];
  let child;

  for (let i = 0; i < children.length; i++) {
    child = getFieldFromFieldListById(fieldList, children[i].id);
    if (child === null) {
      console.log(
        `Field with id ${children[i].id} not found in field list in traverseTreeForFieldToBuildModules.`
      );
      continue;
    }

    traverseTreeForFieldToBuildModules(fieldList, child);
  }
}

function transformFieldListForSelectedField(fieldList, fieldId) {
  const field = getFieldFromFieldListById(fieldList, fieldId);
  if (field === null) {
    console.log(
      `Field with id ${fieldId} not found in field list in transformFieldListForSelectedField.`
    );
    return;
  }

  traverseTreeForFieldToBuildModules(fieldList, field);
}

function transformFieldListForSingleModule(
  fieldList,
  moduleList,
  selectedModuleId
) {
  // Get the selected module
  const module = moduleList.find((m) => m.id === selectedModuleId);
  if (!module) {
    console.log(
      "Selected module not found in module list in transformFieldListForSingleModule!",
      selectedModuleId
    );
    return;
  }

  // We only want fields that are the subnodes of this module and their immediate children
  const newFieldList = [];
  const fields = fieldList.filter((f) => f.module?.name === selectedModuleId);

  let field, child;
  for (let i = 0; i < fields.length; i++) {
    field = fields[i];
    addItemToArrayIfNotExist(newFieldList, { ...field });

    // Go through children and add the immediate descendants that are not part of this module
    for (let j = 0; j < field.children.length; j++) {
      child = getFieldFromFieldListById(fieldList, field.children[j].id);

      if (child.module?.name !== selectedModuleId) {
        addItemToArrayIfNotExist(newFieldList, { ...child });
      }
    }
  }

  return newFieldList;
}

function buildModuleAndModuleInputFields(
  fieldList,
  moduleList,
  shouldShowModules
) {
  if (shouldShowModules) {
    let moduleField, inputField;

    // Build nodes for the module that will replace its subnodes - but only if in show modules node
    for (let i = 0; i < moduleList.length; i++) {
      moduleField = buildModuleField(moduleList[i]);
      fieldList.push(moduleField);

      // Build a module input node that contain all REQ, ENT, and GS child fields that are not part of a module
      inputField = buildModuleInputField(fieldList, moduleField);
      if (inputField !== null) {
        moduleField.children.push(inputField);
        fieldList.push(inputField);
      }
    }
  }
}

export function updateFieldsRootAndLeafFlags(fieldList) {
  // Update all root and leaf flags pased on parent and children
  let field, parents;
  for (let i = 0; i < fieldList.length; i++) {
    field = fieldList[i];
    parents = locateAllParentsOfField(fieldList, field);
    field.isRoot = parents.length === 0;
    field.isLeaf = field.children.length === 0;
  }
}

export function transformRuleMapToIncludeModules(
  fieldList,
  shouldShowModules = true,
  selectedModuleId = "-1",
  selectedField
) {
  let newFieldList = [...fieldList];
  const selectedFieldId =
    selectedField && selectedField.value ? selectedField.value : "-1";

  const moduleList = extractModulesFromRuleMap(fieldList);
  // console.log("Modules", moduleList);

  if (selectedModuleId === "-1") {
    buildModuleAndModuleInputFields(
      newFieldList,
      moduleList,
      shouldShowModules
    );
  }

  // If we are showing modules and not in select module mode, go through the fields and move all fields in modules to their respective modules
  //    and remove them from their current parents.
  if (selectedModuleId !== "-1") {
    newFieldList = transformFieldListForSingleModule(
      newFieldList,
      moduleList,
      selectedModuleId
    );
  } else if (shouldShowModules && selectedFieldId !== "-1") {
    transformFieldListForSelectedField(newFieldList, selectedFieldId);
  } else if (shouldShowModules) {
    transformFieldListForModules(newFieldList);
  }

  // Update root and leaf flags for modules and module input field
  updateFieldsRootAndLeafFlags(newFieldList);

  // const temp = newFieldList.filter(
  //   (f) => f.name.indexOf("ValidatePrescriber") >= 0
  // );
  // console.log("MODULES: ValidatePrescriber fields:", temp);

  return newFieldList;
}
