import styled from "styled-components";

export const StyledWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-row-gap: 0;
  grid-template-columns: repeat(
    ${(props) => (props.isSmallScreen === true ? 1 : 2)},
    1fr
  );
`;

export const StyledArtifact = styled.div`
  color: var(--text-dark);
  font-size: 18px;
  margin-top: 10px;
`;

export const StyledCard = styled.div`
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  background-color: var(--selected-bg);
  border: 1px solid transparent;

  &.selected {
    background-color: var(--selected-bg);
    border: 1px solid var(--selected-border);
  }

  &.selected:hover {
    border: 1px solid var(--ruleparser-purple);
  }
`;

export const StyledRuleLink = styled.div`
  margin-top: 5px;

  a {
    font-size: 15px;
    font-style: italic;
    color: var(--text-dark);
    opacity: 0.7;

    &:hover {
      opacity: 1;
      color: var(--link);
    }
  }
`;

export const StyledDescription = styled.div`
  font-weight: normal;
  font-size: 18px;
  font-family: "Source Sans Pro";
  color: var(--text-normal);
  display: flex;
  align-items: center;

  span.charblock {
    font-family: "Courier New", Courier, monospace;
    display: inline-block;
    color: #fff;
    background-color: var(--ruleparser-blue);
    font-weight: normal;
    padding: 2px 4px 0 4px;
    font-size: 15px;
    border-radius: 3px;
  }
`;

export const StyledReturnButton = styled.div`
  display: block;
  position: absolute;
  top: calc(50% - 12px);
  left: -14px;
  zindex: 2;
  color: #fff;
  font-size: 24px;
  background-color: var(--ruleparser-blue);
  font-weight: normal;
  padding: 0 4px 0 2px;
  line-height: 24px;
  border-radius: 3px 0 0 3px;
  cursor: pointer;

  &:hover {
    background-color: var(--ruleparser-purple);
    cursor: pointer;
  }
`;
