import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyReport,
  emptyReportResultsSearch,
  emptyReportSearch,
} from "../viewmodels/reportsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadReports:
      return {
        ...state,
        reports: action.payload.reports,
        count: action.payload.count,
      };

    case ContextProviderActions.saveReportSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadReport:
      return {
        ...state,
        report: action.payload,
      };

    case ContextProviderActions.setReportDataSourcesCache:
      return {
        ...state,
        sourceListCache: action.payload,
      };

    case ContextProviderActions.loadReportResults:
      return {
        ...state,
        results: action.payload.results,
        resultsColumns: action.payload.columns,
        resultsCount: action.payload.count,
        resultsColCount: action.payload.columns.length,
      };

    case ContextProviderActions.saveReportResultsSearch:
      return {
        ...state,
        resultsSearch: action.payload,
      };

    case ContextProviderActions.loadReportTemplateContentResults:
      return {
        ...state,
        resultsTemplateContents: action.payload.content,
      };

    default:
      throw new Error(
        `Unhandled action type in ReportsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  reports: [],
  report: emptyReport,
  search: emptyReportSearch,
  sourceListCache: [],
  count: 0,
  results: [],
  resultsColumns: [],
  resultsCount: 0,
  resultsColCount: 0,
  resultsSearch: emptyReportResultsSearch,
  resultsTemplateContents: "",
};

const Reports = createContext({
  reportsData: initialState,
  setReportsData: () => null,
});

// eslint-disable-next-line react/prop-types
const ReportsProvider = ({ children }) => {
  const [reportsData, setReportsData] = useReducer(reducer, initialState);

  return (
    <Reports.Provider value={{ reportsData, setReportsData }}>
      {children}
    </Reports.Provider>
  );
};

export default ReportsProvider;
export const useReports = () => useContext(Reports);
