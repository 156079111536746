import React, { useState, useEffect } from "react";
import { useRuleTableDefinitions } from "../../../contexts/RuleTableDefinitionsContext";
import { useRules } from "../../../contexts/RulesContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";

import {
  emptyRuleTableDefinition,
  createViewModel,
  fromViewModel,
  getDropDownValue,
  emptyRuleTableDefinitionRuleTableSearch,
} from "../../../viewmodels/ruleTableDefinitionsVm";

import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import {
  apiLoadRuleTableDefinition,
  apiAddRuleTableDefinition,
  apiUpdateRuleTableDefinition,
  apiLoadRuleTableDefinitionsAll,
} from "../../../api/RuleTableDefinitionApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Spinner from "../../common/ui/Spinner";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import SelectInput from "../../common/input/SingleSelect";
import TextInput from "../../common/input/TextInput";

import {
  notifySuccess,
  notifyWarn,
  notifyError,
} from "../../../services/NotificationService";
import {
  handleCollapseExpandAll,
  ruleTableDefinitionSecurityTypes,
} from "../../../services/General";
import ActionMenu from "../../common/ui/ActionMenu";
import DragDropRuleTableDefinitionColumnSection from "./DragDropRuleTableDefinitionColumnSection";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function RuleTableDefinition() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { ruleTableDefinitionsData, setRuleTableDefinitionsData } =
    useRuleTableDefinitions();
  const { loading: loadingSingle, api: apiLoad } = useApi(
    apiLoadRuleTableDefinition
  );
  const { loading: loadingAllDefs, api: apiLoadAll } = useApi(
    apiLoadRuleTableDefinitionsAll
  );
  const { loading: adding, api: apiAdd } = useApi(apiAddRuleTableDefinition);
  const { loading: updating, api: apiUpdate } = useApi(
    apiUpdateRuleTableDefinition
  );
  const { rulesData, setRulesData } = useRules();
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyRuleTableDefinition);
  const [definitions, setDefinitions] = useState([]);

  // System rule table definitions are only editable by admins
  const [editable, setEditable] = useState(false);

  const [search, setSearch] = useState({
    ...emptyRuleTableDefinitionRuleTableSearch,
  });

  const [collapsedState, setCollapsedState] = useState([
    { name: "Summary", collapsed: false },
    { name: "Columns", collapsed: false },
    { name: "Rule Tables using this Definition", collapsed: false },
  ]);

  const ruleTableDefinition =
    (ruleTableDefinitionsData &&
      ruleTableDefinitionsData.ruleTableDefinition) ||
    {};
  const resId = params && params.id;
  const isFromRule =
    rulesData.savedRuleEditState && rulesData.savedRuleEditState.ruleId !== "";

  let filteredSecurityLevels = [...ruleTableDefinitionSecurityTypes];
  if (isFromRule) {
    // If coming from a rule, then filter according to saved security level - either Rule or Report Template
    filteredSecurityLevels = filteredSecurityLevels.filter(
      (t) => t.value === rulesData.savedRuleEditState.securityLevel
    );
  } else {
    // Remove system and template if user is not an admin
    if (!auth.isAdmin) {
      filteredSecurityLevels = filteredSecurityLevels.filter(
        (t) => t.value !== "System" && t.value !== "ReportTemplate"
      );
    }
    // Remove Rule if there is not exactly one rule where this definition is used
    if (getRuleTableCount(changes) !== 1) {
      filteredSecurityLevels = filteredSecurityLevels.filter(
        (t) => t.value !== "Rule"
      );
    }
  }

  useEffect(() => {
    if (auth.authenticated) {
      loadRuleTableDefinitionAndFullList();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (ruleTableDefinitionsData.ruleTableDefinition) {
      setChanges(ruleTableDefinitionsData.ruleTableDefinition);
    } else {
      setChanges(emptyRuleTableDefinition);
    }
  }, [ruleTableDefinitionsData.ruleTableDefinition]);

  async function loadRuleTableDefinitionAndFullList() {
    // Load full list of rule table definitions and their columns for use as source in col dialog
    // This will load all RTDs except the one being edited - provided by resId
    apiLoadAll.call(resId, (result) => {
      const vms = result.resources.map((r) => createViewModel(r));
      setDefinitions(vms);

      loadRuleTableDefinition();
    });
  }

  async function loadRuleTableDefinition() {
    if (!resId) {
      const model = { ...emptyRuleTableDefinition };
      if (isFromRule) {
        model.securityLevel = rulesData.savedRuleEditState.securityLevel;
      }
      const vm = createViewModel(model);

      setRuleTableDefinitionsData({
        type: ContextProviderActions.loadRuleTableDefinition,
        payload: vm,
      });
      setChanges(vm);
      setEditable(true);
      return;
    }

    apiLoad.call(resId, (result) => {
      postLoadRuleTableDefinition(result);
    });
  }

  async function postLoadRuleTableDefinition(ruleTableDefinitionResult) {
    let vm = emptyRuleTableDefinition;
    if (!ruleTableDefinitionResult) {
      notifyError("Rule Table Definition does not exist");
    } else {
      vm = createViewModel(ruleTableDefinitionResult);

      // System security and template definitions are only editable by admins
      //  Definition is also read only if it is used on any rule tables
      const secLevel = getSecurityLevel(vm);

      if (
        ((secLevel === "System" || secLevel === "ReportTemplate") &&
          !auth.isAdmin) ||
        getRuleTableCount(vm) > 0
      ) {
        setEditable(false);
      } else {
        setEditable(true);
      }
    }

    setRuleTableDefinitionsData({
      type: ContextProviderActions.loadRuleTableDefinition,
      payload: vm,
    });
    setChanges(vm);
  }

  function continueRuleEditOnBackToRule() {
    setRulesData({
      type: ContextProviderActions.saveRuleEditState,
      payload: {
        ...rulesData.savedRuleEditState,
        ruleTableChanges: {
          ...rulesData.savedRuleEditState.ruleTableChanges,
          ruleTableDefinitionId: changes.id,
        },
        continueRuleEdit: true,
      },
    });

    navigate("/rule/" + rulesData.savedRuleEditState.ruleId);
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleSecurityLevelChange(option) {
    let changed = { ...changes, securityLevelVm: option };
    setChanges(changed);
  }

  function setColumns(value) {
    setChanges({ ...changes, columns: value });
  }

  function formIsValid() {
    const _errors = {};

    if (changes.name.trim() === "") _errors.name = "Name must be entered";

    if ((changes.columns || []).length === 0)
      _errors.columns = "At least one column must be added";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    const newVm = { ...changes };

    if (!resId) {
      addRuleTableDefinition(newVm);
    } else {
      updateRuleTableDefinition(newVm.id, newVm);
    }
  }

  async function addRuleTableDefinition(vm) {
    var model = fromViewModel(vm);

    // Make sure security gets set to Rule when coming from rule
    if (isFromRule) {
      model.securityLevel = rulesData.savedRuleEditState.securityLevel;
    }

    apiAdd.call(model, (result) => {
      setChanges(createViewModel(result));
      notifySuccess(
        "Rule Table Definition '" + vm.name + "' saved successfully"
      );

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/ruletabledefinition/" + result.id, { replace: true });
    });
  }

  async function updateRuleTableDefinition(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      const newVm = { ...model, ...result };
      setChanges(createViewModel(newVm));
      notifySuccess(
        "Rule Table Definition '" + vm.name + "' saved successfully"
      );
    });
  }

  function getRuleTableCount(model) {
    if (!model) return 0;
    return (model.ruleTables || []).length;
  }

  function getSecurityLevel(model) {
    return getDropDownValue(model, "securityLevel");
  }

  function canMakeSecurityChanges(model) {
    // If security is rule, it can be upgraded to public or system (if admin).
    // If security is public or system and has exactly one rule, it can be downgraded to Rule.
    // If definition is editable, security changes can definitely be made
    if (editable) return true;

    const secLevel = getSecurityLevel(model);
    if (secLevel !== "Rule" && getRuleTableCount(model) === 1) return true;
    if (secLevel === "Rule") return true;

    return false;
  }

  const loading = loadingSingle || loadingAllDefs || adding || updating;

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        {isFromRule === true ? (
          <StyledBackButtonDiv>
            <button
              title="Return to rule"
              type="button"
              className="btn btn-link"
              onClick={continueRuleEditOnBackToRule}
            >
              <i className="fa fa-angle-left"></i> Back to rule
            </button>
            <StyledScreenHelpWithBackDiv>
              <HelpLink
                path="/Processing-Rules/Rule-Table-Definition-Screen"
                label="Help"
              />
            </StyledScreenHelpWithBackDiv>
          </StyledBackButtonDiv>
        ) : (
          <StyledBackButtonDiv>
            <button
              title="Return to previous screen"
              type="button"
              className="btn btn-link"
              onClick={() => navigate(-1)}
            >
              <i className="fa fa-angle-left"></i> Back
            </button>
            <StyledScreenHelpWithBackDiv>
              <HelpLink
                path="/Processing-Rules/Rule-Table-Definition-Screen"
                label="Help"
              />
            </StyledScreenHelpWithBackDiv>
          </StyledBackButtonDiv>
        )}
        <StyledHeaderRowDiv>
          {!editable && !loading && resId && (
            <h1>{ruleTableDefinition.name} (view only)</h1>
          )}
          {editable && (
            <h1>
              {resId
                ? "Edit Rule Table Definition"
                : "Create Rule Table Definition"}{" "}
              {resId ? ` | ${ruleTableDefinition.name}` : ""}
            </h1>
          )}
          {canMakeSecurityChanges(changes) ? (
            <StyledHeaderRowButtonDiv>
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "86px",
                }}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();

                  if (isFromRule) {
                    continueRuleEditOnBackToRule();
                  } else {
                    navigate(-1);
                  }
                }}
                style={{ marginLeft: "12px" }}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          ) : (
            !loading &&
            getRuleTableCount(changes) === 0 &&
            getSecurityLevel(changes) === "System" && (
              <i>System and Report Template definitions are read-only</i>
            )
          )}
        </StyledHeaderRowDiv>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/ruletabledefinition/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Summary"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Table-Definition-Screen&anchor=summary"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-6 col-lg-4">
                    <TextInput
                      id="name"
                      label="Name"
                      onChange={handleChange}
                      placeholder="Name"
                      name="name"
                      value={changes.name || ""}
                      error={errors.name}
                      autoFocus={true}
                      disabled={!editable}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-8">
                    <TextInput
                      id="description"
                      label="Description"
                      onChange={handleChange}
                      placeholder="Description"
                      name="description"
                      value={changes.description || ""}
                      error={errors.description}
                      disabled={!editable}
                    />
                  </div>
                </StyledRowDiv>
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <SelectInput
                      id="securityLevel"
                      name="securityLevel"
                      label="Security"
                      options={[...filteredSecurityLevels]}
                      value={
                        isFromRule
                          ? filteredSecurityLevels.find(
                              (secLevel) =>
                                secLevel.value ===
                                rulesData.savedRuleEditState.securityLevel
                            )
                          : changes.securityLevelVm
                      }
                      onChange={handleSecurityLevelChange}
                      placeholder=""
                      error={errors.securityLevel}
                      controlStyle={{ width: "250px" }}
                      disabled={isFromRule && !canMakeSecurityChanges(changes)}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Columns"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Processing-Rules/Rule-Table-Definition-Screen&anchor=columns"
              >
                {editable && (
                  <p>
                    Drag and drop by the handle to reorder columns. All key
                    columns will be sorted to the top on save.
                  </p>
                )}

                <DragDropRuleTableDefinitionColumnSection
                  columns={changes.columns}
                  setColumns={setColumns}
                  definitionsList={definitions}
                  error={errors.columns}
                  disabled={!editable}
                />
              </ExpandCollapseDetailSection>

              {resId && (
                <ExpandCollapseDetailSection
                  sectionTitle="Rule Tables using this Definition"
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Processing-Rules/Rule-Table-Definition-Screen&anchor=rule-tables-using-this-definition"
                >
                  {getRuleTableCount(changes) === 0 ? (
                    <>
                      <p>
                        This rule table definition is not used by any rule
                        tables.
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        This rule table definition is read-only because it is
                        used by the following rule tables:
                      </p>
                      <ResponsiveGrid
                        gridId="RuleTableDefinitionRuleTables"
                        totalRecords={getRuleTableCount(changes)}
                        search={search}
                        setSearch={setSearch}
                        dataRows={changes.ruleTables}
                        enableClientRowPager={true}
                        columnDefs={[
                          {
                            name: "key",
                            label: "Key",
                            style: { width: "30%" },
                            getValue: (row) => (
                              <button
                                className="btn btn-link link-underline"
                                onClick={() => navigate("/ruletable/" + row.id)}
                              >
                                {row.key}
                              </button>
                            ),
                          },
                          {
                            name: "description",
                            label: "Description",
                            style: { width: "40%" },
                          },
                          {
                            name: "rule",
                            label: "Rule",
                            style: { width: "30%" },
                            getValue: (row) => (
                              <button
                                className="btn btn-link link-underline"
                                onClick={() => navigate("/rule/" + row.ruleId)}
                              >
                                {row.ruleName}
                              </button>
                            ),
                          },
                        ]}
                      />
                    </>
                  )}
                </ExpandCollapseDetailSection>
              )}
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default RuleTableDefinition;
