import _ from "lodash";
import { pageSize } from "../constants/Environments";

export const emptyReportTemplate = {
  id: "",
  name: "",
  entityType: { value: "Claim", label: "Claim" },
  dataSourceVm: "",
  templateContents: "",
  fileNameTemplate: "",
  runValuesToSave: "",
  externalDataRuleTableDefinitionId: {
    value: "-1",
    label: "(None)",
  },
  externalDataRuleTableDefinitionVm: "",
  externalDataRuleTableDefinitionName: "",
};

export const emptyReportTemplateSearch = {
  name: "",
  entityType: "",
  fileNameTemplate: "",
  externalDataRuleTableDefinitionName: "",
  orderBy: ["name+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
  oldName: { name: "", id: "" },
  newName: "",
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  vm.entityType = (vm.dataSourceVm || { value: "" }).value;
  vm.externalDataRuleTableDefinitionId = (
    vm.externalDataRuleTableDefinitionVm || { value: "-1" }
  ).value;

  let m = vm;
  return m;
}

export function createViewModel(m, dataSourceList, ruleTableDefinitionList) {
  let vm = _.cloneDeep(m);

  // Dropdown values
  vm.dataSourceVm =
    (dataSourceList || []).find((c) => c.value === vm.entityType) ||
    emptyReportTemplate.entityType;

  vm.externalDataRuleTableDefinitionVm =
    (ruleTableDefinitionList || []).find(
      (c) => c.value === vm.externalDataRuleTableDefinitionId
    ) || emptyReportTemplate.externalDataRuleTableDefinitionId;

  return vm;
}
