import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { useMobile } from "../../../hooks/useMobile";

function GridActionButton({ actions }) {
  const { isMobileSize } = useMobile();

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <StyledButton
      opacity={isMobileSize || global.TESTMODE === true ? 1 : 0}
      onClick={(event) => {
        if (event) {
          event.preventDefault();
          onClick(event);
        }
      }}
      ref={ref}
    >
      {children}
    </StyledButton>
  ));
  CustomToggle.displayName = "GridActionButton";

  function onActionClick(event, disabled, onClick) {
    if (event) {
      event.preventDefault();

      if (!disabled) onClick();
    }
  }

  return (
    <Dropdown drop="end" role="menu" autoClose={true}>
      <Dropdown.Toggle as={CustomToggle} id="actiontoggle">
        <i className="material-icons">more_horiz</i>
      </Dropdown.Toggle>

      <Dropdown.Menu renderOnMount={true}>
        {actions.map((action) => (
          <Dropdown.Item
            key={action.name}
            className={action.disabled ? "disabled-look" : ""}
            onClick={(event) =>
              onActionClick(event, action.disabled ?? false, action.onClick)
            }
          >
            {action.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const StyledButton = styled.button`
  height: 24px;
  width: 36px;
  margin: 0;
  padding: 0;
  background: var(--table-action-btn-bg);
  border: 1px solid var(--table-action-btn-border);
  border-radius: 3px;
  opacity: ${(props) => props.opacity};

  &:hover {
    opacity: 1;
    background: var(--table-action-btn-active-bg);
  }

  i {
    font-size: 20px;
    color: var(--table-action-btn-text);
    text-align: middle;
  }
`;

export default GridActionButton;
