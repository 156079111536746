import React from "react";
import {
  formatDateTimeUtcZoneForDisplay,
  getUserSizeFromBytes,
} from "../../../services/General";
import { useNavigate } from "react-router-dom";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function AuditRecordList({
  auditRecords,
  search,
  setSearch,
  onSort,
  totalRecords,
  onRollbackSingleChange,
  onRollbackBulkChange,
}) {
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const documentId = dataRow.documentId;
    const entityType = dataRow.entityType;
    const isBulkOperation = dataRow.bulkChange;

    const actions = [];

    if (isBulkOperation) {
      actions.push({
        name: "View changes",
        onClick: () => navigate("/auditbulkrecords/" + id),
      });
      actions.push({
        name: "Rollback this bulk change",
        onClick: () => onRollbackBulkChange(id),
      });
    } else {
      actions.push({
        name: "View changes",
        onClick: () =>
          navigate(
            "/auditrecord/" +
              encodeURIComponent(entityType) +
              "/" +
              documentId +
              "/" +
              id
          ),
      });
      actions.push({
        name: "Rollback this single change",
        onClick: () => onRollbackSingleChange(entityType, documentId, id),
      });
    }

    return actions;
  }

  function getTruncatedId(oid) {
    let id = `${oid}`;
    const len = id.length;
    if (len > 6) {
      id = "..." + id.substring(len - 6);
    }
    return id;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="AuditRecords"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={auditRecords}
        columnDefs={[
          {
            name: "id",
            label: "Id",
            style: { width: "25%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => {
                  row.bulkChange
                    ? navigate("/auditbulkrecords/" + row.id)
                    : navigate(
                        "/auditrecord/" +
                          encodeURIComponent(row.entityType) +
                          "/" +
                          row.documentId +
                          "/" +
                          row.id
                      );
                }}
              >
                <span className="force-wrap d-none d-md-table-cell">
                  {row.id}
                </span>
                <span className="force-wrap d-table-cell d-md-none">
                  {getTruncatedId(row.id)}
                </span>
              </button>
            ),
          },
          {
            name: "transactionDate",
            label: "Date",
            style: { width: "25%" },
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.transactionDate),
          },
          {
            name: "userName",
            label: "User",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
          },
          {
            name: "modificationType",
            label: "Operation",
            className: "d-none d-md-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              row.bulkChange === true ? "Bulk" : row.modificationType,
          },
          {
            name: "documentId",
            label: "Doc. Id",
            className: "d-none d-xl-table-cell",
            style: { width: "25%" },
          },
          {
            name: "auditDocumentSize",
            label: "Doc. Size",
            className: "d-none d-xl-table-cell",
            style: { width: "25%" },
            getValue: (row) => getUserSizeFromBytes(row.auditDocumentSize),
          },
          {
            name: "entityType",
            label: "Entity",
            style: { width: "25%" },
          },
          {
            name: "entityKey",
            label: "Key",
            className: "d-none d-lg-table-cell",
            style: { width: "20%" },
            getValue: (row) =>
              row.bulkChange ? (
                <i>
                  {`${row.numChanges} ${
                    row.numChanges === 1 ? " change" : "changes"
                  }`}
                </i>
              ) : (
                row.entityKey
              ),
          },
          {
            name: "bulkChange",
            label: "Bulk Op.",
            noForceWrap: true,
            className: "d-none d-lg-table-cell",
            style: { width: "150px" },
            getValue: (row) =>
              row.bulkChange ? (
                <span className="material-icons">check</span>
              ) : (
                ""
              ),
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default AuditRecordList;
