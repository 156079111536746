/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadChains(search, token, signal) {
  const uri = API_URL + "/chains" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "id", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "id",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "name",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.active) {
    where.push({ name: "TEMPORALITEMMATCH", operator: "=", values: [] });
  }
  if (search && search.chainId && search.chainId.length > 0)
    where.push({ name: "chainId", operator: "=", values: [search.chainId] });
  if (search && search.name && search.name.length > 0)
    where.push({ name: "name", operator: "=", values: [search.name] });

  if (search && search.address && search.address.length > 0)
    where.push({
      name: "addresses.address1",
      operator: "array_contains",
      values: [search.address],
    });
  if (search && search.city && search.city.length > 0)
    where.push({
      name: "addresses.city",
      operator: "array_contains",
      values: [search.city],
    });
  if (search && search.state && search.state.length > 0)
    where.push({
      name: "addresses.state",
      operator: "array_contains",
      values: [search.state],
    });

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadChainsAll(params, token, signal) {
  const uri = API_URL + "/chainsall" + getQueryString();
  let where = [];
  where.push({ name: "TEMPORALITEMMATCH", operator: "=", values: [] });

  const data = { where, orderBy: ["name+"] };

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadChain(id, token, signal) {
  let uri = API_URL + "/chain/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddChain(m, token, signal) {
  let uri = API_URL + "/chain" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateChain(params, token, signal) {
  const id = params.id;
  const m = params.model;

  let uri = API_URL + "/chain/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteChain(id, token, signal) {
  let uri = API_URL + "/chain/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadChains.funcName = "apiLoadChains";
apiLoadChainsAll.funcName = "apiLoadChainsAll";
apiLoadChain.funcName = "apiLoadChain";
apiAddChain.funcName = "apiAddChain";
apiUpdateChain.funcName = "apiUpdateChain";
apiDeleteChain.funcName = "apiDeleteChain";
