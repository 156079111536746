import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyTestRunnerConfig } from "../viewmodels/testRunnerConfigVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadTestRunnerConfig:
      return {
        ...state,
        testRunnerConfig: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in TestRunnerConfigContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  testRunnerConfig: emptyTestRunnerConfig,
};

const TestRunnerConfig = createContext({
  testRunnerConfigData: initialState,
  setTestRunnerConfigData: () => null,
});

// eslint-disable-next-line react/prop-types
const TestRunnerConfigProvider = ({ children }) => {
  const [testRunnerConfigData, setTestRunnerConfigData] = useReducer(
    reducer,
    initialState
  );

  return (
    <TestRunnerConfig.Provider
      value={{ testRunnerConfigData, setTestRunnerConfigData }}
    >
      {children}
    </TestRunnerConfig.Provider>
  );
};

export default TestRunnerConfigProvider;
export const useTestRunnerConfig = () => useContext(TestRunnerConfig);
