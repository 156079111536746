import React from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { formatDateTime } from "../../../services/General";
import TextAreaInput from "../../common/input/TextAreaInput";
import ReadOnly from "../../common/input/ReadOnly";
import HelpLink from "../../common/ui/HelpLink";
import { useAuthProvider } from "../../../hooks/useAuthProvider";

function RuleChangeHistoryDialog({
  editItem,
  setEditItem,
  showDialog,
  onCloseDialog,
  onSaveDialog,
}) {
  const { userName } = useAuthProvider();

  function handleChange({ target }) {
    setEditItem({ ...editItem, [target.name]: target.value });
  }

  function onSave(event) {
    if (event) event.preventDefault();

    const changed = { ...editItem };

    // If user did not provide a reason, enter a default value
    if (_.isEmpty(changed.changeReason)) {
      changed.changeReason = "Not provided";
    }

    // Convert local datetime to UTC
    //changed.changeDate = convertLocalDateToUTC(changed.changeDate);

    // Set user
    changed.changeBy = userName;

    setEditItem(changed);
    onSaveDialog(changed);
  }

  return (
    <>
      <Modal show={showDialog} onHide={onCloseDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            Change Reason
            <HelpLink
              path="/Processing-Rules/Rule-Screen&anchor=change-history-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter a reason for this rule change.</p>
          <ReadOnly label="User" value={userName} />
          <ReadOnly label="Date" value={formatDateTime(editItem.changeDate)} />
          <TextAreaInput
            id="changeReason"
            rows="4"
            label="Change Reason"
            onChange={handleChange}
            placeholder="Provide a brief description for this rule change"
            name="changeReason"
            value={editItem.changeReason}
            autoFocus={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-with-icon"
            onClick={onSave}
            style={{
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCloseDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RuleChangeHistoryDialog;
