/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadPriorAuthorizationClaims(id, token, signal) {
  const uri = API_URL + "/priorauthorizationclaims/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadPriorAuthorizations(search, token, signal) {
  const uri = API_URL + "/priorauthorizations" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "priorAuthorizationNumber", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "priorAuthorizationNumber",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "serviceProviderId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "prescriptionNumber",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "groupId",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.active) {
    where.push({ name: "TEMPORALITEMMATCH", operator: "=", values: [] });
  }
  if (search && search.groupId && search.groupId.length > 0)
    where.push({ name: "groupId", operator: "=", values: [search.groupId] });
  if (search && search.pharmacy && search.pharmacy.length > 0)
    where.push({
      name: "serviceProviderId",
      operator: "=",
      values: [search.pharmacy],
    });
  if (
    search &&
    search.prescriptionNumber &&
    search.prescriptionNumber.length > 0
  )
    where.push({
      name: "prescriptionNumber",
      operator: "=",
      values: [search.prescriptionNumber],
    });
  if (search && search.productId && search.productId.length > 0)
    where.push({
      name: "productId",
      operator: "=",
      values: [search.productId],
    });
  if (search && search.memberId && search.memberId.length > 0)
    where.push({ name: "memberId", operator: "=", values: [search.memberId] });
  if (
    search &&
    search.priorAuthorizationNumber &&
    search.priorAuthorizationNumber.length > 0
  )
    where.push({
      name: "priorAuthorizationNumber",
      operator: "=",
      values: [search.priorAuthorizationNumber],
    });

  if (where.length === 0) {
    where.push({
      name: "priorAuthorizationNumber",
      operator: "notnull",
      values: [],
    });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadPriorAuthorization(id, token, signal) {
  let uri = API_URL + "/priorauthorization/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddPriorAuthorization(pa, token, signal) {
  let uri = API_URL + "/priorauthorization" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(pa),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdatePriorAuthorization(params, token, signal) {
  const id = params.id;
  const pa = params.model;

  let uri = API_URL + "/priorauthorization/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(pa),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeletePriorAuthorization(id, token, signal) {
  let uri = API_URL + "/priorauthorization/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token) + getQueryString();
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadRejectionDescriptions(rejs, token, signal) {
  let uri = API_URL + "/rejectiondescriptions" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: rejs,
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadPriorAuthorizationClaims.funcName = "apiLoadPriorAuthorizationClaims";
apiLoadPriorAuthorizations.funcName = "apiLoadPriorAuthorizations";
apiLoadPriorAuthorization.funcName = "apiLoadPriorAuthorization";
apiAddPriorAuthorization.funcName = "apiAddPriorAuthorization";
apiUpdatePriorAuthorization.funcName = "apiUpdatePriorAuthorization";
apiDeletePriorAuthorization.funcName = "apiDeletePriorAuthorization";
apiLoadRejectionDescriptions.funcName = "apiLoadRejectionDescriptions";
