import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { useClaims } from "../../../contexts/ClaimsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { emptyClaimSearch } from "../../../viewmodels/claimsVm";
import { useNavigate } from "react-router-dom";
import { useMobile } from "../../../hooks/useMobile";
import { Chart } from "react-google-charts";

function HomeClaimsTrafficBreakdown({ traffic }) {
  const { setClaimsData } = useClaims();
  const navigate = useNavigate();
  const { isMobileSize, isTabletSize } = useMobile();

  function gotoClaimsScreen(type, name) {
    // Set claims search to show claims in the last five minutes for this entity.
    const _search = {
      ...emptyClaimSearch,
      startDate: new Date(new Date().getTime() - 5 * 60 * 1000),
      endDate: new Date("1/1/3000"),
    };

    if (type === "group") {
      _search.groupId = name;
    } else if (type === "serviceprovider") {
      _search.pharmacy = name;
    } else if (type === "status") {
      _search.friendlyStatus = [{ value: name, label: name }];
    }

    setClaimsData({
      type: ContextProviderActions.saveClaimSearch,
      payload: _search,
    });

    navigate("/claims");
  }

  function clickChart(type, chartWrapper) {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();
    if (selection.length === 1) {
      const [selectedItem] = selection;
      const dataTable = chartWrapper.getDataTable();
      const { row } = selectedItem;

      gotoClaimsScreen(type, dataTable?.getValue(row, 0));
    }
  }
  function getName(name) {
    if (_.isEmpty(name)) return "blank";
    return name;
  }

  function getNameForLink(name) {
    if (_.isEmpty(name)) return <i>blank</i>;
    return name;
  }

  function addMissingStatusesToArray(array) {
    const _array = [...(array || [])];

    if (_array.findIndex((a) => a.name === "Paid") < 0) {
      _array.push({ name: "Paid", value: "Paid" });
    }
    if (_array.findIndex((a) => a.name === "Rejected") < 0) {
      _array.push({ name: "Rejected", value: "Rejected" });
    }
    if (_array.findIndex((a) => a.name === "Reversed") < 0) {
      _array.push({ name: "Reversed", value: "Reversed" });
    }
    if (_array.findIndex((a) => a.name === "Unreversed Paid") < 0) {
      _array.push({ name: "Unreversed Paid", value: "Unreversed Paid" });
    }

    return _array.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  function getValuesArrayForChart(type, array) {
    const data = [[type, "Claims"]];

    for (let i = 0; i < (array || []).length; i++) {
      data.push([getName(array[i].name), array[i].count]);
    }

    return data;
  }

  function getChartOptions(type, arrayLength = -1) {
    const style = getComputedStyle(document.body);
    var legendColor = style.getPropertyValue("--text-medium");
    const color1 = style.getPropertyValue("--pill-green");
    const color2 = style.getPropertyValue("--pill-red");
    const color3 = style.getPropertyValue("--pill-blue");
    const color4 = style.getPropertyValue("--pill-black");
    const color5 = style.getPropertyValue("--pill-text");
    const color6 = style.getPropertyValue("--notify-danger");
    const color7 = style.getPropertyValue("--pill-purple");
    const color8 = style.getPropertyValue("--pill-lavender");
    const color9 = style.getPropertyValue("--notify-warning");
    const color10 = style.getPropertyValue("--notify-info");

    const statusColors = [
      color1,
      color2,
      color3,
      color4,
      color5,
      color6,
      color7,
      color8,
      color9,
      color10,
    ];

    const serviceProviderColors = [
      color10,
      color9,
      color8,
      color7,
      color6,
      color5,
      color4,
      color3,
      color2,
      color1,
    ];

    const groupColors = [
      color7,
      color8,
      color9,
      color10,
      color1,
      color2,
      color3,
      color4,
      color5,
      color6,
    ];

    const chartOptions = {
      sliceVisibilityThreshold: 0,
      //type === "serviceprovider" && arrayLength > 5 ? 0.08 : 0,
      is3D: false,
      backgroundColor: "transparent",
      legend: {
        position: type === "serviceprovider" ? "left" : "right",
        textStyle: { color: legendColor },
        top: 20,
      },
      chartArea: { right: "5%", left: 0, top: 10, bottom: 10, width: "100%" },
      colors:
        type === "group"
          ? groupColors
          : type === "status"
          ? statusColors
          : serviceProviderColors,
    };

    return chartOptions;
  }

  const chartWidth = "100%";
  const chartHeight = "250px";

  return (
    <>
      {(traffic.claims || []).length === 0 ? (
        <i>No recent claims</i>
      ) : (
        <>
          <StyledChartContainer>
            <h4>Groups</h4>
            <Chart
              chartType={"PieChart"}
              data={getValuesArrayForChart("Group", traffic.groups)}
              options={getChartOptions("group")}
              width={chartWidth}
              height={chartHeight}
              chartEvents={[
                {
                  eventName: "select",
                  callback({ chartWrapper }) {
                    clickChart("group", chartWrapper);
                  },
                },
              ]}
            />
          </StyledChartContainer>
          <StyledChartContainer>
            <h4>Service Providers</h4>
            <Chart
              chartType={"PieChart"}
              data={getValuesArrayForChart(
                "Service Provider",
                traffic.serviceProviders
              )}
              options={getChartOptions(
                "serviceprovider",
                traffic.serviceProviders.length
              )}
              width={chartWidth}
              height={chartHeight}
              chartEvents={[
                {
                  eventName: "select",
                  callback({ chartWrapper }) {
                    clickChart("serviceprovider", chartWrapper);
                  },
                },
              ]}
            />
          </StyledChartContainer>
          <StyledChartContainer>
            <h4>Statuses</h4>
            <Chart
              chartType={"PieChart"}
              data={getValuesArrayForChart(
                "Status",
                addMissingStatusesToArray(traffic.statuses)
              )}
              options={getChartOptions("status")}
              width={chartWidth}
              height={chartHeight}
              chartEvents={[
                {
                  eventName: "select",
                  callback({ chartWrapper }) {
                    clickChart("status", chartWrapper);
                  },
                },
              ]}
            />
          </StyledChartContainer>
          <StyledChartContainer>
            <h4>Claims</h4>
          </StyledChartContainer>
          <StyledStatsContainer isSmallScreen={isMobileSize || isTabletSize}>
            <StyledStatsSection>
              <h4>Groups ({(traffic.groups || []).length})</h4>
              <ul>
                {(traffic.groups || []).map((g) => (
                  <li key={g.name}>
                    <button
                      title={`View recent claims for group ${getName(g.name)}`}
                      type="button"
                      className="btn btn-link"
                      onClick={() => gotoClaimsScreen("group", g.name)}
                    >
                      {getNameForLink(g.name)}
                    </button>
                    <span>
                      ({g.count} {g.count === 1 ? "claim" : "claims"})
                    </span>
                  </li>
                ))}
              </ul>
            </StyledStatsSection>
            <StyledStatsSection>
              <h4>
                Service Providers ({(traffic.serviceProviders || []).length})
              </h4>
              <ul>
                {(traffic.serviceProviders || []).map((g) => (
                  <li key={g.name}>
                    <button
                      title={`View recent claims for service provider ${getName(
                        g.name
                      )}`}
                      type="button"
                      className="btn btn-link"
                      onClick={() =>
                        gotoClaimsScreen("serviceprovider", g.name)
                      }
                    >
                      {getNameForLink(g.name)}
                    </button>
                    <span>
                      ({g.count} {g.count === 1 ? "claim" : "claims"})
                    </span>
                  </li>
                ))}
              </ul>
            </StyledStatsSection>
            <StyledStatsSection>
              <h4>Statuses ({(traffic.statuses || []).length})</h4>
              <ul>
                {(traffic.statuses || []).map((g) => (
                  <li key={g.name}>
                    <button
                      title={`View recent claims with status ${getName(
                        g.name
                      )}`}
                      type="button"
                      className="btn btn-link"
                      onClick={() => gotoClaimsScreen("status", g.name)}
                    >
                      {getNameForLink(g.name)}
                    </button>
                    <span>
                      ({g.count} {g.count === 1 ? "claim" : "claims"})
                    </span>
                  </li>
                ))}
              </ul>
            </StyledStatsSection>
          </StyledStatsContainer>
        </>
      )}
    </>
  );
}

const StyledStatsContainer = styled.div`
  margin-top: 0px;
  display: ${(props) => (props.isSmallScreen === true ? "block" : "flex")};
  justify-content: space-around;
  flex-grow: 4;
`;

const StyledStatsSection = styled.div`
  margin-top: 10px;

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-normal);
    margin: 20px 0;
    text-align: center;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-dark);
  }

  ul {
    padding-left: 0;

    li {
      padding: 2px 0;
      list-style-type: none;
      display: flex;
      align-items: center;
      column-gap: 10px;

      span {
        font-size: 14px;
      }
    }
  }
`;

const StyledChartContainer = styled.div`
  margin-top: 20px;

  h4 {
    font-size: 18px;
    background-color: var(--elevated-bg);
    color: var(--text-normal);
    margin-top: 10px;
    padding: 4px;
    opacity: 0.5;
  }

  svg > g > g.google-visualization-tooltip {
    pointer-events: none;
  }

  g {
    cursor: pointer;
  }
`;

export default HomeClaimsTrafficBreakdown;
