import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyReportProcess,
  emptyReportProcessLastRunValuesSearch,
  emptyReportProcessSearch,
} from "../viewmodels/reportProcessesVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadReportProcesses:
      return {
        ...state,
        processes: action.payload.processes,
        count: action.payload.count,
      };

    case ContextProviderActions.saveReportProcessSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadReportProcess:
      return {
        ...state,
        process: action.payload,
      };

    case ContextProviderActions.setReportCache:
      return {
        ...state,
        reportListCache: action.payload,
      };

    case ContextProviderActions.saveReportProcessLastRunValuesSearch:
      return {
        ...state,
        searchLastRunValues: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in ReportProcessesContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  processes: [],
  process: emptyReportProcess,
  search: emptyReportProcessSearch,
  count: 0,
  reportListCache: [],
  searchLastRunValues: emptyReportProcessLastRunValuesSearch,
};

const ReportProcesses = createContext({
  reportProcessesData: initialState,
  setReportProcessesData: () => null,
});

// eslint-disable-next-line react/prop-types
const ReportProcessesProvider = ({ children }) => {
  const [reportProcessesData, setReportProcessesData] = useReducer(
    reducer,
    initialState
  );

  return (
    <ReportProcesses.Provider
      value={{ reportProcessesData, setReportProcessesData }}
    >
      {children}
    </ReportProcesses.Provider>
  );
};

export default ReportProcessesProvider;
export const useReportProcesses = () => useContext(ReportProcesses);
