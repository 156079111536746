export const ContextProviderActions = {
  // Auth
  setAuthenticationSuccessResult: "SET_AUTHENTICATION_SUCCESS_RESULT",
  setAuthenticationFailResult: "SET_AUTHENTICATION_FAIL_RESULT",
  setAuthenticationToken: "SET_AUTH_TOKEN",
  saveLoggedOut: "LOGOUT_COMPLETED",
  saveLogOutFailed: "LOGOUT_FAILED",
  setLoginScreenMessage: "SET_LOGIN_SCREEN_MSG",

  // Diagnostics
  loadDiagnostics: "LOAD_DIAGNOSTICS",
  saveDiagnosticSearch: "SAVE_DIAGNOSTICS_SEARCH",

  // Chains
  loadChains: "LOAD_CHAINS",
  loadChain: "LOAD_CHAIN",
  saveChainSearch: "SAVE_CHAINS_SEARCH",
  setChainListCache: "SET_CHAIN_LIST_CACHE",

  // Clients
  loadClients: "LOAD_CLIENTS",
  loadClient: "LOAD_CLIENT",
  saveClientSearch: "SAVE_CLIENTS_SEARCH",
  setClientListCache: "SET_CLIENT_LIST_CACHE",

  // Groups
  loadGroups: "LOAD_GROUPS",
  loadGroup: "LOAD_GROUP",
  saveGroupSearch: "SAVE_GROUPS_SEARCH",
  setGroupListCache: "SET_GROUP_LIST_CACHE",

  // Discounts
  loadDiscounts: "LOAD_DISCOUNTS",
  loadDiscount: "LOAD_DISCOUNT",
  saveDiscountSearch: "SAVE_DISCOUNTS_SEARCH",
  loadDiscountRedemptions: "LOAD_DISCOUNT_REDEMPTIONS",

  // Drugs
  loadDrugs: "LOAD_DRUGS",
  loadDrug: "LOAD_DRUG",
  saveDrugSearch: "SAVE_DRUGS_SEARCH",

  // Members
  loadMembers: "LOAD_MEMBERS",
  loadMember: "LOAD_MEMBER",
  saveMemberSearch: "SAVE_MEMBERS_SEARCH",

  // Prescribers
  loadPrescribers: "LOAD_PRESCRIBERS",
  loadPrescriber: "LOAD_PRESCRIBER",
  savePrescriberSearch: "SAVE_PRESCRIBERS_SEARCH",

  // Service providers
  loadServiceProviders: "LOAD_SERVICEPROVIDERS",
  loadServiceProvider: "LOAD_SERVICEPROVIDER",
  saveServiceProviderSearch: "SAVE_SERVICEPROVIDERS_SEARCH",

  // Prior Authorizations
  loadPriorAuthorizations: "LOAD_PRIORAUTHORIZATIONS",
  loadPriorAuthorization: "LOAD_PRIORAUTHORIZATION",
  savePriorAuthorizationSearch: "SAVE_PRIORAUTHORIZATIONS_SEARCH",
  savePriorAuthorizationRejectionDescriptions:
    "SAVE_PRIORAUTHORIZATIONS_REJECTIONDESCRIPTIONS",

  // Claims
  loadClaims: "LOAD_CLAIMS",
  loadClaim: "LOAD_CLAIM",
  saveClaimSearch: "SAVE_CLAIMS_SEARCH",
  loadClaimsTraffic: "LOAD_CLAIMS_TRAFFIC",

  // Packet Parser
  savePacketRequest: "SAVE_PACKET_REQUEST",
  savePacketRequestAndNavigate: "SAVE_PACKET_REQUEST_AND_NAVIGATE",
  savePacketResponse: "SAVE_PACKET_RESPONSE",
  setSelectedFieldId: "SET_SELECTED_FIELDID",
  setActivePacketParserTab: "SET_ACTIVE_PACKET_PARSER_TAB",
  setPacketParserDisplayType: "SET_PACKET_PARSER_DISPLAY_TYPE",

  // Test Processor
  saveTestProcessorResponse: "SAVE_TEST_PROCESSING_RESPONSE",
  saveTestProcessorConfig: "SAVE_TEST_PROCESSING_CONFIG",
  setActiveTestParserTab: "SET_ACTIVE_TEST_PARSER_TAB",

  // Logs
  loadLogs: "LOAD_LOGS",
  loadLogHeaders: "LOAD_LOG_HEADERS",
  saveLogSearch: "SAVE_LOGS_SEARCH",
  saveLogHeaderSearch: "SAVE_LOG_HEADERS_SEARCH",

  // Rules
  loadRules: "LOAD_RULES",
  loadRule: "LOAD_RULE",
  loadRuleGroupSummary: "LOAD_RULE_GROUP_SUMMARY",
  saveRuleSearch: "SAVE_RULES_SEARCH",
  saveRuleEditState: "SAVE_RULE_EDIT_STATE",

  // Rule Tables
  loadRuleTables: "LOAD_RULE_TABLES",
  saveRuleTableSearch: "SAVE_RULE_TABLES_SEARCH",
  loadRuleTable: "LOAD_RULE_TABLE",
  saveRuleDataSearch: "SAVE_RULE_DATA_SEARCH",

  // Rule Table Definitions
  loadRuleTableDefinitions: "LOAD_RULE_TABLE_DEFINITIONS",
  saveRuleTableDefinitionSearch: "SAVE_RULE_TABLE_DEFINITIONS_SEARCH",
  loadRuleTableDefinition: "LOAD_RULE_TABLE_DEFINITION",

  // User Preferences
  setExpandCollapseState: "SET_EXPAND_COLLAPSE_STATE",
  setEnvironment: "SET_ENVIRONMENT",
  setMenuToggleExpanded: "SET_MENU_TOGGLE_EXPANDED",
  setShowGraphsOnHomePage: "SET_SHOW_GRAPHS_ON_HOME_PAGE",
  setShowSidebarOnHomePage: "SET_SHOW_SIDEBAR_ON_HOME_PAGE",
  setDarkMode: "SET_DARK_MODE",
  setCoverageTrackingMode: "SET_COVERAGE_TRACKING_MODE",
  setCoverageTrackingVisibility: "SET_COVERAGE_TRACKING_VISIBILITY",
  setTrafficPacketTestData: "SET_TRAFFIC_PACKET_TEST_DATA",
  setGridPreferences: "SET_GRID_PREFERENCES",

  // Reports
  loadReports: "LOAD_REPORTS",
  loadReport: "LOAD_REPORT",
  saveReportSearch: "SAVE_REPORTS_SEARCH",
  setReportDataSourcesCache: "SET_DATASOURCE_LIST_CACHE",
  loadReportResults: "LOAD_REPORT_RESULTS",
  saveReportResultsSearch: "SAVE_REPORTS_RESULTS_SEARCH",
  loadReportTemplateContentResults: "LOAD_TEMPLATE_CONTENT_RESULTS",

  // Report Templates
  loadReportTemplates: "LOAD_REPORT_TEMPLATES",
  loadReportTemplate: "LOAD_REPORT_TEMPLATE",
  saveReportTemplateSearch: "SAVE_REPORT_TEMPLATES_SEARCH",
  setReportTemplateCache: "SET_REPORT_TEMPLATE_LIST_CACHE",
  loadRuleTableDefinitionList: "LOAD_RULE_TABLE_DEFINITION_LIST",
  setReportRuleTableCache: "SET_REPORT_RULE_TABLE_LIST_CACHE",

  // Report Processes
  loadReportProcesses: "LOAD_REPORT_PROCESSES",
  loadReportProcess: "LOAD_REPORT_PROCESS",
  saveReportProcessSearch: "SAVE_REPORT_PROCESSES_SEARCH",
  setReportCache: "SET_REPORT_LIST_CACHE",
  saveReportProcessLastRunValuesSearch:
    "SAVE_REPORT_PROCESSES_LAST_RUN_VALUES_SEARCH",

  // Process History
  loadProcessHistory: "LOAD_PROCESS_HISTORY",
  saveProcessHistorySearch: "SAVE_PROCESS_HISTORY_SEARCH",
  loadProcessHistoryRecord: "LOAD_PROCESS_HISTORY_RECORD",

  // Audit
  loadAuditRecords: "LOAD_AUDIT_RECORDS",
  loadAuditRecord: "LOAD_AUDIT_RECORD",
  saveAuditSearch: "SAVE_AUDIT_SEARCH",
  loadAuditBulkChangeRecords: "LOAD_AUDIT_BULK_CHANGE_RECORDS",
  saveAuditBulkChangeSearch: "SAVE_AUDIT_BULK_CHANGE_SEARCH",
  saveAuditRecordSearch: "SAVE_AUDIT_RECORD_SEARCH",

  // Cache
  loadCacheStatus: "LOAD_CACHE_STATUS",
  saveCacheSearch: "SAVE_CACHE_SEARCH",

  // Testing
  loadTests: "LOAD_TESTS",
  saveTestSearch: "SAVE_TESTS_SEARCH",
  loadTest: "LOAD_TEST",
  loadTestGroups: "LOAD_TEST_GROUPS",
  saveTestGroupSearch: "SAVE_TEST_GROUPS_SEARCH",
  loadTestGroup: "LOAD_TEST_GROUP",
  loadTestResults: "LOAD_TEST_RESULTS",
  saveTestResultSearch: "SAVE_TEST_RESULTS_SEARCH",
  loadTestGroupResult: "LOAD_TEST_GROUP_RESULT",
  saveTestGroupResultSearch: "SAVE_TEST_GROUP_RESULT_SEARCH",
  loadTestResult: "LOAD_TEST_RESULT",
  loadDataGenerationUpdateState: "LOAD_DATA_GENERATION_UPDATE_STATE",
  loadTestRunnerUpdateState: "LOAD_TEST_RUNNER_UPDATE_STATE",

  // Test Coverage Tracking
  setTestCoverageTrackingGroupMode: "SET_TEST_COVERAGE_TRACKING_GROUP_MODE",
  saveTestCoverageTracking: "SAVE_TEST_COVERAGE_TRACKING",
  clearTestCoverageTracking: "CLEAR_TEST_COVERAGE_TRACKING",
  saveTestCoverageTrackingSearch: "SAVE_TEST_COVERAGE_TRACKING_SEARCH",
  setTestCoverageTrackingSelectedRecord:
    "SET_TEST_COVERAGE_TRACKING_SELECTED_RECORD",

  // Database Utilities
  loadDbDrugUpdate: "LOAD_DB_DRUG_UPDATE",
  loadDbLegacyDbSync: "LOAD_DB_LEGACY_DB_SYNC",
  loadDbDatabaseSync: "LOAD_DB_DATABASE_SYNC",
  loadDbDatabaseSyncUpdateState: "LOAD_DB_DATABASE_SYNC_UPDATE_STATE",

  // Socket Server Config
  loadSocketServerConfig: "LOAD_SOCKET_SERVER_CONFIG",

  // Packet Traffic
  loadTrafficPackets: "LOAD_TRAFFIC_PACKETS",

  // Heartbeat Detector Config
  loadHbDetectorConfig: "LOAD_HB_DETECTOR_CONFIG",

  // Test Data Populator Config
  loadTestDataPopulatorConfig: "LOAD_TEST_DATA_POPULATOR_CONFIG",

  // Test Runner Config
  loadTestRunnerConfig: "LOAD_TEST_RUNNER_CONFIG",

  // Web Socket Events
  setWebSocketDbUtilsEventState: "SET_WEB_SOCKET_DB_UTILS_EVENT_STATE",
  setWebSocketTestDataPopulationEventState:
    "SET_WEB_SOCKET_TEST_DATA_POPULATION_EVENT_STATE",
  setWebSocketTestRunnerEventState: "SET_WEB_SOCKET_TEST_RUNNER_EVENT_STATE",
  setWebSocketClaimsTrafficEventState:
    "SET_WEB_SOCKET_CLAIMS_TRAFFIC_EVENT_STATE",
  setWebSocketDiagnosticsEventState:
    "SET_WEB_SOCKET_DIAGNOSTICS_TRAFFIC_EVENT_STATE",

  // Rule Maps
  loadRuleMapForClaim: "LOAD_RULE_MAP_FOR_CLAIM",
  loadRuleMapForGroup: "LOAD_RULE_MAP_FOR_GROUP",
  openRuleMapLogsDialog: "OPEN_RULE_MAP_LOGS_DIALOG",
  closeRuleMapLogsDialog: "CLOSE_RULE_MAP_LOGS_DIALOG",
  loadRuleMapRejectionsList: "LOAD_RULE_MAP_REJECTIONS_LIST",
  loadRuleMapResponseFieldsList: "LOAD_RULE_MAP_RESPONSE_FIELDS_LIST",
  selectRuleMapField: "SELECT_RULE_MAP_FIELD",
  setRuleMapShowPossiblePaths: "SET_SHOW_POSSIBLE_PATHS",
  setRuleMapLayoutDirection: "SET_RULE_MAP_LAYOUT_DIRECTION",
  setRuleMapLayoutDirectionWithoutRefresh:
    "SET_RULE_MAP_LAYOUT_DIRECTION_WITHOUT_REFRESH",
  setRuleMapNodes: "SET_RULE_MAP_NODES",
  setRuleMapEdges: "SET_RULE_MAP_EDGES",
  setRuleMapModules: "SET_RULE_MAP_MODULES",
  onRuleMapNodesChange: "ON_RULE_MAP_NODES_CHANGE",
  onRuleMapEdgesChange: "ON_RULE_MAP_EDGES_CHANGE",
  setRuleMapLayoutedNodesAndEdges: "SET_RULE_MAP_LAYOUTED_NODES_AND_EDGES",
  setRuleMapFinalLayoutedNodesAndEdges:
    "SET_RULE_MAP_FINAL_LAYOUTED_NODES_AND_EDGES",
  doRuleMapRefreshLayout: "DO_RULE_MAP_REFRESH_LAYOUT",
  setRuleMapDetailLevel: "SET_RULE_MAP_DETAIL_LEVEL",
  resetRuleMapSelections: "RESET_RULE_MAP_SELECTIONS",
  selectRuleMapNode: "SELECT_RULE_MAP_NODE",
  setRuleMapShowModules: "SET_RULE_MAP_SHOW_MODULES",
  closeModuleViewMode: "CLOSE_MODULE_VIEW_MODE",
  saveGroupRuleMapFilter: "SAVE_GROUP_RULE_MAP_FILTER",

  // Group Rule Maps
  resetGroupRuleMap: "RESET_GROUP_RULE_MAP",
  loadGroupRuleMaps: "LOAD_GROUP_RULE_MAPS",
  saveGroupRuleMapSearch: "SAVE_GROUP_RULE_MAP_SEARCH",
  loadGroupRuleMap: "LOAD_GROUP_RULE_MAP",
  setGroupRuleMapIsFilterSet: "SET_GROUP_RULE_MAP_IS_FILTER_SET",
  setGroupRuleMapIsEditMode: "SET_GROUP_RULE_MAP_IS_EDIT_MODE",
  setGroupRuleMapEditNode: "SET_GROUP_RULE_MAP_EDIT_NODE",
};
