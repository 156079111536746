import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyCacheStatus, emptyCacheSearch } from "../viewmodels/cacheVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadCacheStatus:
      return {
        ...state,
        cacheStatus: action.payload,
      };

    case ContextProviderActions.saveCacheSearch:
      return {
        ...state,
        search: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in CacheContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  cacheStatus: emptyCacheStatus,
  search: emptyCacheSearch,
};

const CacheStatus = createContext({
  cacheData: initialState,
  setCacheData: () => null,
});

// eslint-disable-next-line react/prop-types
const CacheProvider = ({ children }) => {
  const [cacheData, setCacheData] = useReducer(reducer, initialState);

  return (
    <CacheStatus.Provider value={{ cacheData, setCacheData }}>
      {children}
    </CacheStatus.Provider>
  );
};

export default CacheProvider;
export const useCache = () => useContext(CacheStatus);
