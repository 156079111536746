import React, { createRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import StatusPill from "../../common/ui/StatusPill";

function HomeClaimsTrafficBlock({
  claim,
  rxHlResult,
  timeAgoInfo,
  authHlResult,
  statusHlResult,
  groupHlResult,
  providerHlResult,
  showGroupName,
  groupPacket,
}) {
  const navigate = useNavigate();

  function getTimeAgoInterval(timeAgo, timeUnit) {
    const m = `${timeAgo} ${timeAgo === 1 ? timeUnit : timeUnit + "s"} ago`;
    return m;
  }

  const t = claim;

  return (
    <div className="col-12" ref={createRef()}>
      <StyledTrafficBlock
        className={t.isNew ? "new-claim" : ""}
        groupPacket={groupPacket || false}
      >
        <div>
          {showGroupName && (
            <div className="flex-row-with-wrap-and-justify full-width">
              <h4>Rx Group</h4>
              <h4>{t.groupingKey}</h4>
            </div>
          )}
          <div className="flex-row-with-wrap-and-justify">
            <h3>
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/claim/" + t.id)}
              >
                Rx # {rxHlResult.html}
              </button>
            </h3>
            <p>{getTimeAgoInterval(timeAgoInfo.ago, timeAgoInfo.unit)}</p>
          </div>
          <div className="flex-row-with-wrap-and-justify">
            <p>Auth # {authHlResult.html}</p>
            <p>
              {statusHlResult.count > 0 ? (
                statusHlResult.html
              ) : (
                <StatusPill status={t.friendlyStatus} />
              )}
            </p>
          </div>
          <div className="flex-row-with-wrap-and-justify">
            <p>Group {groupHlResult.html}</p>
            <p>{providerHlResult.html}</p>
          </div>
        </div>
      </StyledTrafficBlock>
    </div>
  );
}

const StyledTrafficBlock = styled.div`
  margin-top: ${(props) => (props.groupPacket === true ? "-2px" : "7px")};
  padding: 5px 11px 9px 11px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-top: ${(props) =>
    props.groupPacket === true ? "none" : "1px solid var(--elevated-border)"};
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;

  &.new-claim {
    -webkit-animation-delay: 100ms;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation-delay: 100ms;
    animation: fadein 1s;
  }

  h4 {
    color: var(--text-dark);
    font-size: 16px;
  }

  div {
    width: 100%;

    h3 {
      margin: 0;
      padding: 4px 0;
    }

    p {
      color: var(--text-medium);
      margin: 0;
      padding: 4px 0;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
      background: var(--selected-bg);
      border: 1px solid var(--selected-border);
    }
    to {
      opacity: 1;
      background: var(--elevated-bg);
      border: 1px solid var(--elevated-border);
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
      background: var(--selected-bg);
      border: 1px solid var(--selected-border);
    }
    to {
      opacity: 1;
      background: var(--elevated-bg);
      border: 1px solid var(--elevated-border);
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      -webkit-opacity: 0;
      -webkit-background: var(--selected-bg);
      -webkit-border: 1px solid var(--selected-border);
    }
    to {
      -webkit-opacity: 1;
      -webkit-background: var(--elevated-bg);
      -webkit-border: 1px solid var(--elevated-border);
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
      background: var(--selected-bg);
      border: 1px solid var(--selected-border);
    }
    to {
      opacity: 1;
      background: var(--elevated-bg);
      border: 1px solid var(--elevated-border);
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
      background: var(--selected-bg);
      border: 1px solid var(--selected-border);
    }
    to {
      opacity: 1;
      background: var(--elevated-bg);
      border: 1px solid var(--elevated-border);
    }
  }
`;

export default HomeClaimsTrafficBlock;
