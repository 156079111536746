import React, { useState, useEffect } from "react";
import DiscountsList from "./DiscountsList";
import DiscountSearchForm from "./DiscountSearchForm";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import {
  emptyDiscountSearch,
  emptyDiscountRedemption,
  createViewModel,
  createDrViewModel,
} from "../../../viewmodels/discountsVm";
import { useNavigate, useParams } from "react-router-dom";
import DiscountRedemptionsList from "./DiscountRedemptionsList";
import { formatDateTimeUtcZone } from "../../../services/General";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useDiscounts } from "../../../contexts/DiscountsContext";
import {
  apiLoadDiscounts,
  apiLoadDiscountRedemptions,
  apiDeleteDiscount,
} from "../../../api/DiscountApi";
import { apiLoadClaim, apiLoadClaimSimple } from "../../../api/ClaimApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Discounts() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { isMobileSize } = useMobile();
  const params = useParams();
  const [errors, setErrors] = useState({});
  const { discountsData, setDiscountsData } = useDiscounts();
  const { loading, api: apiLoad } = useApi(apiLoadDiscounts);
  const { loading: loadingRedemptions, api: apiLoadRedemptions } = useApi(
    apiLoadDiscountRedemptions
  );
  const { loading: loadingClaim, api: apiGetClaim } = useApi(apiLoadClaim);
  const { loading: loadingSimple, api: apiGetSimple } =
    useApi(apiLoadClaimSimple);
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteDiscount);
  const [loadData, setLoadData] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const [bundleInfo, setBundleInfo] = useState({ isBundle: false });

  const claimId = params && params.claimid;

  let discounts = [];
  let discountRedemptions = [];

  if (discountsData && discountsData.discounts) {
    discounts = discountsData.discounts;
  }
  if (discountsData && discountsData.discountRedemptions) {
    discountRedemptions = discountsData.discountRedemptions;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setDiscountsData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //   useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadDiscounts();
    }
  }, [auth.authenticated, loadData]);

  useEffect(() => {
    // Reset serach parameters when parameters change
    setDiscountsData({
      type: ContextProviderActions.saveDiscountSearch,
      payload: { ...emptyDiscountSearch },
    });
    setLoadData(true);
  }, [params?.claimid]);

  function loadDiscounts() {
    // If a claimid was passed in, load the claim and show only its discounts here.
    if (claimId) {
      loadDiscountsFromClaim();
    } else {
      loadAllDiscounts();
    }
  }

  function setInitialSearchFromClaim(loadedClaim, setSearchInState) {
    let initialSearch = { ...discountsData.search };
    initialSearch.groupId = loadedClaim.groupId;
    initialSearch.pharmacy = loadedClaim.serviceProviderId;
    initialSearch.memberId = loadedClaim.memberId;
    initialSearch.ndc = loadedClaim.productId;
    initialSearch.prescriber = loadedClaim.prescriberId;
    initialSearch.initialPrice = loadedClaim.ingredientCost || 0;
    initialSearch.lookupQty = loadedClaim.quantity || 1;
    initialSearch.prescriptionNumber = loadedClaim.prescriptionRefNumber;

    // Add a little time to the processed date to allow for discount redemptions to be considered so amounts are calculated correctly.
    var pdate = new Date(formatDateTimeUtcZone(loadedClaim.processedDate));
    let processedDate = pdate;
    processedDate.setSeconds(processedDate.getSeconds() + 5);
    initialSearch.processedDate = processedDate;

    // If looking at discounts considered for a claim, do not specify an order by since we want to show these in their natural order.
    if (claimId) {
      initialSearch.orderBy = [];
    }

    if (setSearchInState) {
      // For reversals, we don't want to set this in state here because we get into a timing issue and redemptions can get set to an empty array.
      //   Instead, reversals set this search state when setting the discounts and redemptions.
      setSearchChanges(initialSearch);
    }

    return initialSearch;
  }

  async function loadDiscountsFromClaim() {
    apiGetSimple.call(claimId, async (result) => {
      // Set the search parameters for this loaded claim
      const newSearch = setInitialSearchFromClaim(result, !result.isReversal);

      if (!result.isReversal) {
        // For normal claims, we just need to load discounts using the search parameters we just set. So simply call loadAllDiscounts here.
        loadAllDiscounts(newSearch);
      } else {
        // If this claim is a reversal, load the full, detailed claim in order to calculate the correct stats
        loadDiscountsForReversal(newSearch);
      }
    });
  }

  async function loadDiscountsForReversal(newSearch) {
    let calcData = null;

    apiGetClaim.call(claimId, async (result) => {
      const loadedClaimDetailed = result;
      if (
        !(
          loadedClaimDetailed.discount && loadedClaimDetailed.discountRedemption
        )
      ) {
        const calcData = postDiscountLoadWithStats({
          discounts: { resources: [] },
        });
        postLoadDiscountsAndRedemptions(calcData.discounts, 0, [], 0);
        return Promise.resolve();
      }

      let finalDiscount = LoadDiscountStats(
        loadedClaimDetailed.discount,
        loadedClaimDetailed.discountRedemption
      );
      finalDiscount.patientCalculatedPayAmount =
        loadedClaimDetailed.discountRedemption.patientPayAmount;
      finalDiscount.thirdPartyCalculatedPayAmount =
        loadedClaimDetailed.discountRedemption.thirdPartyPayAmount;

      calcData = postDiscountLoadWithStats({
        discounts: {
          resources: [finalDiscount],
        },
      });

      loadDiscountRedemptions(newSearch, (redemptions) => {
        postLoadDiscountsAndRedemptions(
          calcData.discounts,
          1,
          redemptions,
          redemptions.count || 0,
          newSearch
        );
      });
    });
  }

  function getBundleTotalPaid(discounts) {
    let sum = 0;

    for (let i = 0; i < discounts.length; i++) {
      sum += discounts[i].thirdPartyCalculatedPayAmount;
    }

    return sum;
  }

  async function loadAllDiscounts(searchTerms) {
    const newSearch = searchTerms ? searchTerms : { ...discountsData.search };
    let discounts = null;

    apiLoad.call(
      newSearch,
      async (result) => {
        discounts = result;

        // jon, 5/24/24: Check the first discount returned to see if this discount was part of a bundle. If so, all discounts will be
        //   part of the bundle and there will be only one bundle.
        newSearch.discountBundleId = "";
        const _bundleInfo = {
          isBundle: false,
          bundleId: "",
          bundleName: "",
          bundleTags: [],
          totalPaid: 0,
          finalCopay: 0,
        };

        if (
          (discounts.resources || []).length > 0 &&
          !_.isEmpty(discounts.resources[0].discountBundleId)
        ) {
          const disc = discounts.resources[0];
          newSearch.discountBundleId = disc.discountBundleId; //for loading redemptions
          _bundleInfo.isBundle = true;
          _bundleInfo.bundleId = disc.discountBundleId;
          _bundleInfo.bundleName = disc.discountBundleName;
          _bundleInfo.bundleTags = disc.discountBundleTags;
          _bundleInfo.totalPaid = getBundleTotalPaid(discounts.resources);
          _bundleInfo.finalCopay =
            discounts.resources[
              discounts.resources.length - 1
            ].patientCalculatedPayAmount;
        }
        setBundleInfo(_bundleInfo);

        // Success: Returns Discounts and any Discount Redemptions for the discount
        loadDiscountRedemptions(newSearch, (redemptions) => {
          postLoadDiscountsAndRedemptions(
            discounts,
            discounts.count || 0,
            redemptions,
            redemptions.count || 0,
            newSearch
          );
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function loadDiscountRedemptions(search, resultCallback) {
    apiLoadRedemptions.call(
      search,
      async (result) => {
        // This is the last step in all load cases for this screen so reset load flag
        setLoadData(false);
        resultCallback(result);
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function postLoadDiscountsAndRedemptions(
    discountResults,
    countDiscounts,
    discountRedemptionResults,
    countDiscountRedemptions,
    newSearch
  ) {
    // Group viewmodel needs the client list
    const vmsDiscount = discountResults.resources
      ? discountResults.resources.map((r) => createViewModel(r))
      : [];
    const vmsDiscountRedemptions = discountRedemptionResults.resources
      ? discountRedemptionResults.resources.map((r) => createDrViewModel(r))
      : [];

    setDiscountsData({
      type: ContextProviderActions.loadDiscounts,
      payload: {
        discounts: vmsDiscount,
        discountRedemptions: vmsDiscountRedemptions,
        countDiscounts,
        countDiscountRedemptions,
        search: newSearch ? newSearch : { ...discountsData.search },
      },
    });
  }

  function LoadDiscountStats(discount, discountRedemption) {
    discount.currentCount =
      discount.maxCount > 0
        ? discount.maxCount - discountRedemption.remainingUses - 1
        : null;
    discount.remainingUses =
      discount.maxCount > 0 ? discountRedemption.remainingUses : null;
    discount.currentQty =
      discount.maximumQuantity > 0
        ? discount.maximumQuantity - discountRedemption.remainingQty
        : null;
    discount.remainingQty =
      discount.maximumQuantity > 0 ? discountRedemption.remainingQty : null;
    discount.currentPaid =
      discount.maximumPaid > 0
        ? discount.maximumPaid - discountRedemption.remainingBenefit
        : null;
    discount.remainingBenefit =
      discount.maximumPaid > 0 ? discountRedemption.remainingBenefit : null;

    return discount;
  }

  function postDiscountLoadWithStats(returnedFromApis) {
    let claimRedemptions = returnedFromApis.discountRedemptions
      ? returnedFromApis.discountRedemptions.resources.filter(
          (dr) => dr.claimId === claimId
        )
      : [];
    let claimRedemption = emptyDiscountRedemption;
    if (claimRedemptions.length > 0) claimRedemption = claimRedemptions[0];
    returnedFromApis.discounts.resources.map((d) => {
      return LoadDiscountStats(d, claimRedemption);
    });
    return returnedFromApis;
  }

  function setSearchChanges(search) {
    setDiscountsData({
      type: ContextProviderActions.saveDiscountSearch,
      payload: search,
    });
  }

  function handleDateChange(date) {
    setSearchChanges({ ...discountsData.search, processedDate: date });
  }

  function handleDateRangeChange(dateRange, startDate, endDate) {
    setSearchChanges({
      ...discountsData.search,
      dateRange: dateRange,
      startDate: startDate,
      endDate: endDate,
    });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...discountsData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...discountsData.search, endDate: date });
  }

  async function handleReset() {
    const newSearch = { ...emptyDiscountSearch };

    setSearchChanges({
      ...newSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : discountsData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};
    if (
      _.has(discountsData.search, "initialPrice") &&
      isNaN(discountsData.search.initialPrice)
    )
      _errors.initialPrice = "Initial Price must be a valid number";
    if (
      _.has(discountsData.search, "lookupQty") &&
      isNaN(discountsData.search.lookupQty)
    )
      _errors.lookupQty = "Lookup Quantity must be a valid number";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = discountsData.search.orderBy.indexOf(`${event.target.id}+`);
    var indexDesc = discountsData.search.orderBy.indexOf(`${event.target.id}-`);

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...discountsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...discountsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...discountsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function handleRedemptionSort(event) {
    var indexAsc = discountsData.search.drOrderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = discountsData.search.drOrderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...discountsData.search,
        drOrderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...discountsData.search,
        drOrderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...discountsData.search, drOrderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...discountsData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({ ...discountsData.search, [target.name]: target.value });
  }

  function handleActiveChange({ target }) {
    setSearchChanges({ ...discountsData.search, active: target.checked });
  }

  async function deleteDiscount(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Discount disabled successfully");
      // This reloads the discounts using the search parameters since the link change above was not always working.
      setLoadData(true);
    });
  }

  async function handleDelete(id, discountPlan, discountMode) {
    setDeleteId(id);
    setAltDeleteId(discountPlan + " (" + discountMode + ")");
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteDiscount(deleteId);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (discountsData && discountsData.search) {
      if (discountsData.search.id !== "") numFilters++;
      if (discountsData.search.groupId !== "") numFilters++;
      if (discountsData.search.discountPlan !== "") numFilters++;
      if (discountsData.search.memberId !== "") numFilters++;
      if (discountsData.search.pharmacy !== "") numFilters++;
      if (discountsData.search.prescriber !== "") numFilters++;
      if (discountsData.search.ndc !== "") numFilters++;
      if (discountsData.search.initialPrice !== 0) numFilters++;
      if (discountsData.search.processedDate !== null) numFilters++;
      if (discountsData.search.lookupQty !== 0) numFilters++;
      if (discountsData.search.active !== true) numFilters++;
    }

    return numFilters;
  }

  return (
    <Authorize>
      {claimId && !(discountsData.search.showAdvancedFilter && isMobileSize) ? (
        <StyledBackButtonDiv>
          <button
            title="Return To Claims"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate("/claims")}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Configure/Discounts-List-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
      ) : (
        <StyledScreenHelpDiv>
          {((discountsData.search.showAdvancedFilter && !isMobileSize) ||
            !discountsData.search.showAdvancedFilter) && (
            <HelpLink path="/Configure/Discounts-List-Screen" label="Help" />
          )}
        </StyledScreenHelpDiv>
      )}
      <StyledHeaderRowDiv>
        <h1>Discounts</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/discount")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Discount
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search"
        search={discountsData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <ConfirmDialog
        title="Remove Discount"
        question={`Are you sure you wish to deactivate the discount '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              discountsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={discountsData.search}
            setSearch={setSearchChanges}
            helpLink="/Configure/Discounts-List-Screen&anchor=filters"
          >
            <DiscountSearchForm
              errors={errors}
              search={discountsData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onActiveChange={handleActiveChange}
              onDateChange={handleDateChange}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              onDateRangeChange={handleDateRangeChange}
            />
          </GridAdvancedFilter>
        </div>

        {discountsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading ||
            loadData ||
            loadingRedemptions ||
            loadingClaim ||
            loadingSimple ||
            deleting ? (
              <Spinner />
            ) : (
              <>
                <DiscountsList
                  discounts={discounts}
                  isPriceCheck={discountsData.search.initialPrice !== 0}
                  onDelete={handleDelete}
                  search={discountsData.search}
                  setSearch={async (search) => {
                    setSearchChanges(search);

                    // Only do server-side search if the user didn't just perform a client op only
                    if (!search.isClientOpOnly) {
                      await handleSearch(undefined, search);
                    }
                  }}
                  onSort={handleSort}
                  totalRecords={discountsData.countDiscounts}
                  bundleInfo={bundleInfo}
                  claimId={claimId}
                />
                {claimId ? (
                  <>
                    {bundleInfo.isBundle && <br />}
                    <DiscountRedemptionsList
                      discountRedemptions={discountRedemptions}
                      search={discountsData.search}
                      setSearch={async (search) => {
                        setSearchChanges(search);
                        // Only do server-side search if the user didn't just perform a client op only
                        if (!search.isClientOpOnly) {
                          await handleSearch(undefined, search);
                        }
                      }}
                      onSort={handleRedemptionSort}
                      totalRecords={discountsData.countDiscountRedemptions}
                      fromDiscountsPage={true}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default Discounts;
