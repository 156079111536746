import React from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import StatusPill from "../../common/ui/StatusPill";
import { useClaims } from "../../../contexts/ClaimsContext";
import { getHighlightedText } from "../../../services/General";

export default function ClaimSectionRow({
  valueStyle,
  label,
  value,
  valueLink,
  valueLinkId,
  valueLinkPage,
  isPill = false,
  key = uuidv4(),
}) {
  const navigate = useNavigate();
  const { claimsData } = useClaims();
  const hasLink = !_.isEmpty(valueLink) && !_.isEmpty(valueLinkId);

  const hl = claimsData.search.highlightText;
  const { count: labelHlCount, html: labelHtml } = getHighlightedText(
    label,
    hl
  );
  const { count: valueHlCount, html: valueHtml } = getHighlightedText(
    value,
    hl
  );
  const { count: linkHlCount, html: linkHtml } = getHighlightedText(
    valueLink,
    hl
  );

  return _.trim(hl).length > 0 &&
    labelHlCount === 0 &&
    valueHlCount === 0 &&
    linkHlCount === 0 ? (
    <></>
  ) : (
    <div style={{ display: "flex", alignItems: "top", flexWrap: "wrap" }}>
      <div key={"K" + key + "-label"} className={`claim-row-label`}>
        {labelHtml}
      </div>
      <div
        key={"K" + key + "-value"}
        className={`claim-row-value`}
        style={valueStyle}
      >
        {hasLink ? (
          <button
            className="btn btn-link link-underline"
            style={{ marginTop: "-4px" }}
            onClick={() => navigate(`/${valueLinkPage}/${valueLinkId}`)}
          >
            {linkHtml}
          </button>
        ) : isPill && valueHlCount === 0 ? (
          <StatusPill status={value} />
        ) : (
          <>{valueHtml}</>
        )}
      </div>
    </div>
  );
}
