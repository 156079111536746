import _ from "lodash";
import { states, genders } from "../services/General";
import { parseISO } from "date-fns";
import { pageSize } from "../constants/Environments";

export const emptyPrescriber = {
  effectiveDate: new Date("1/1/2000"),
  terminationDate: new Date("1/1/3000"),
  deaNumber: "",
  npi: "",
  name: "",
  idQualifierCode: "",
  otherLastNameSubmitted: "",
  firstName: "",
  middleName: "",
  lastName: "",
  title: "",
  subtitle: "",
  suffix: "",
  gender: "Unspecified",
  emailAddressesVm: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  number: "",
  addresses: [],
  phoneNumbers: [],
  contacts: [],
};

export const emptyPrescriberSearch = {
  npi: "",
  deaNumber: "",
  lastName: "",
  firstName: "",
  address: "",
  city: "",
  state: "",
  active: true,
  orderBy: ["lastModifiedDate-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  prescribersCount: 0,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  if (vm.terminationDate > new Date("1/1/2999")) vm.terminationDate = null;
  vm.state = (vm.stateVm || { value: "" }).value || "";
  vm.gender = (vm.genderVm || { value: "Unspecified" }).value || "";
  vm.contacts = [];
  vm.contacts = [];
  if (vm.lastName) {
    vm.contacts.push({
      title: (vm.title || "").toUpperCase(),
      gender: vm.gender,
      subtitle: (vm.subtitle || "").toUpperCase(),
      suffix: (vm.suffix || "").toUpperCase(),
      firstName: (vm.firstName || "").toUpperCase(),
      middleName: (vm.middleName || "").toUpperCase(),
      lastName: (vm.lastName || "").toUpperCase(),
      emailAddresses: vm.emailAddressesVm.split(",").map((m) => {
        return { emailAddress: (m || "").toUpperCase() };
      }),
    });
  }

  vm.addresses = [];
  if (vm.address) {
    vm.addresses.push({
      addressType: "Physical",
      address1: (vm.address || "").toUpperCase(),
      city: (vm.city || "").toUpperCase(),
      state: (vm.state || "None").toUpperCase(),
      zipCode: (vm.zipCode || "").toUpperCase(),
    });
  }

  vm.phoneNumbers = [];
  if (vm.number) {
    vm.phoneNumbers.push({
      phoneType: "Phone",
      number: (vm.number || "").toUpperCase(),
    });
  }

  vm.name =
    vm.lastName +
    (vm.suffix ? " " + vm.suffix : "") +
    ", " +
    vm.firstName +
    (vm.middleName ? " " + vm.middleName : "") +
    (vm.title ? " (" + vm.title + ")" : "");
  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);
  if (_.isNull(vm.effectiveDate)) {
    vm.effectiveDate = emptyPrescriber.effectiveDate;
  } else {
    vm.effectiveDate = parseISO(vm.effectiveDate);
  }
  if (_.isNull(vm.terminationDate))
    vm.terminationDate = emptyPrescriber.terminationDate;
  else {
    vm.terminationDate = parseISO(vm.terminationDate);
  }
  vm.deaNumber = vm.deaNumber || "";
  if (vm.contacts.length > 0) {
    vm.title = vm.contacts[0].title || "";
    vm.gender = vm.contacts[0].gender || "";
    vm.suffix = vm.contacts[0].suffix || "";
    vm.subtitle = vm.contacts[0].subtitle || "";
    vm.firstName = vm.contacts[0].firstName || "";
    vm.lastName = vm.contacts[0].lastName || "";
    vm.middleName = vm.contacts[0].middleName || "";
    vm.emailAddressesVm =
      vm.contacts[0].emailAddresses.map((m) => m.emailAddress).join(",") || "";
    vm.genderVm = genders.find((d) => d.value === vm.gender) || {
      label: "Unspecified",
      value: "Unspecified",
    };
  } else {
    vm.firstName = "";
    vm.middleName = "";
    vm.lastName = "";
    vm.title = "";
    vm.gender = "";
    vm.emailAddressesVm = "";
    vm.suffix = "";
    vm.subtitle = "";
  }
  if (vm.addresses.length > 0) {
    vm.address = vm.addresses[0].address1 || "";
    vm.city = vm.addresses[0].city || "";
    vm.state = vm.addresses[0].state || "";
    vm.zipCode = vm.addresses[0].zipCode || "";
    vm.stateVm = states.find((d) => d.value === vm.state) || {
      value: "",
      label: "",
    };
  } else {
    vm.address = "";
    vm.city = "";
    vm.state = "";
    vm.zipCode = "";
  }
  if (vm.phoneNumbers.length > 0) {
    vm.number = vm.phoneNumbers[0].number || "";
  } else {
    vm.number = "";
  }

  return vm;
}
