/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadLogs(traceId, token, signal) {
  const uri = API_URL + "/logs/" + traceId;

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadLogHeaders(search, token, signal) {
  const uri = API_URL + "/logheaders" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "objectId", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "objectId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "message",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "userName",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "environment",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "logType",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  //Add .0 to tell the API to treat ticks as numeric
  if (search && search.startDate && _.isDate(search.startDate)) {
    let startTime = search.startDate.getTime() * 10000 + 621355968000000000;
    where.push({
      name: "ticks",
      operator: ">=",
      values: ["n:" + startTime],
    });
  }
  if (search && search.endDate && _.isDate(search.endDate)) {
    let endTime = search.endDate.getTime() * 10000 + 621355968000000000;
    where.push({
      name: "ticks",
      operator: "<",
      values: ["n:" + endTime],
    });
  }

  if (search && search.traceId && search.traceId.length > 0)
    where.push({
      name: "objectId",
      operator: "=",
      values: [search.traceId],
    });
  if (search && search.logHeaderSearch && search.logHeaderSearch.length > 0)
    where.push({
      name: "message",
      operator: "=",
      values: [search.logHeaderSearch],
    });
  if (search && search.userSearch && search.userSearch.length > 0)
    where.push({
      name: "userName",
      operator: "=",
      values: [search.userSearch],
    });
  if (search && search.environment && search.environment.length > 0)
    where.push({
      name: "environment",
      operator: "=",
      values: [search.environment],
    });

  // Search by Log Type
  if (search && search.logTypes && search.logTypes.length > 0) {
    search.logTypes.forEach((logType) => {
      where.push({
        operand: "OR",
        name: "logType",
        operator: "=",
        values: [logType.value],
      });
    });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadLogs.funcName = "apiLoadLogs";
apiLoadLogHeaders.funcName = "apiLoadLogHeaders";
