import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  apiLoadFieldsForDataSource,
  apiLoadReport,
} from "../../../api/ReportsApi";
import { apiLoadReportTemplate } from "../../../api/ReportTemplatesApi";
import { apiLoadAllRuleTablesForRule } from "../../../api/RuleTableApi";
import { useReports } from "../../../contexts/ReportsContext";
import { useAuth } from "../../../contexts/AuthContext";
import Authorize from "../../common/layout/Authorize";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import Spinner from "../../common/ui/Spinner";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ReportResults from "./ReportResults";
import FilterValueFieldByDataType from "../../common/ui/FilterValueFieldByDataType";
import {
  emptyReport,
  emptyReportResultsSearch,
} from "../../../viewmodels/reportsVm";
import useApi from "../../../hooks/useApi";
import { REPORT_TEMPLATES_RULE_ID } from "../../../services/General";
import SelectInput from "../../common/input/SingleSelect";
import HelpLink from "../../common/ui/HelpLink";

function RunReport() {
  const { auth } = useAuth();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { reportsData, setReportsData } = useReports();
  const { loading, api: apiLoad } = useApi(apiLoadReport);
  const { loading: loadingTemplate, api: apiLoadTemplate } = useApi(
    apiLoadReportTemplate
  );
  const { loading: loadingFields, api: apiLoadFields } = useApi(
    apiLoadFieldsForDataSource
  );
  const { loading: loadingRuleTables, api: apiLoadTemplateRuleTables } = useApi(
    apiLoadAllRuleTablesForRule
  );
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [changes, setChanges] = useState([]);
  const [templateRunNumber, setTemplateRunNumber] = useState(0);
  const [templateRunDate, setTemplateRunDate] = useState(
    new Date().toISOString()
  );
  const [reportTemplate, setReportTemplate] = useState(null);
  const [externalDataRuleTable, setExternalDataRuleTable] = useState(null);
  const [templateRuleTableList, setTemplateRuleTableList] = useState([]);
  const [lastRunValues, setLastRunValues] = useState([]);

  const [collapsedState, setCollapsedState] = useState([
    { name: "Parameters", collapsed: false },
    { name: "Results", collapsed: false },
  ]);

  const report = (reportsData && reportsData.report) || {};
  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated && params.id) {
      // Reset report when id parameter changes
      setReportsData({
        type: ContextProviderActions.loadReport,
        payload: { ...emptyReport },
      });

      // Reset result search params as well
      setReportsData({
        type: ContextProviderActions.saveReportResultsSearch,
        payload: { ...emptyReportResultsSearch },
      });

      loadReport();
    }
  }, [auth.authenticated, params?.id]);

  async function loadFieldsForDataSource(entityType) {
    const fieldList = apiLoadFields.call(entityType, async (result) => {
      const list = (result.properties || [])
        .map((c) => {
          return {
            value: c.id,
            label: c.propertyLabel,
            datatype: c.propertyType,
            validValues: [...c.validValues],
          };
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1));

      return list;
    });

    return fieldList || [];
  }

  async function loadReportTemplate(templateId, ruleTableId) {
    await apiLoadTemplate.call(templateId, async (result) => {
      setReportTemplate(result);

      // If template has last run values, convert those into an array so we can collect values for each one here
      if (result.runValuesToSave !== "" && result.runValuesToSave !== null) {
        const values = result.runValuesToSave.split(",");
        const runValuesList = [];

        for (let i = 0; i < values.length; i++) {
          runValuesList.push({
            name: _.trim(values[i]),
            value: "",
          });
        }

        setLastRunValues(runValuesList);
      }

      if (
        result.externalDataRuleTableDefinitionId !== "-1" &&
        result.externalDataRuleTableDefinitionId !== null &&
        (templateRuleTableList || []).length === 0
      ) {
        // Only load rule tables if this report has a template with an RTD selected and we don't already have them in the cache.
        const ruleTableList = await loadTemplateRuleTableList(
          result.externalDataRuleTableDefinitionId
        );

        // Save the list of rule tables in the cache
        setTemplateRuleTableList(ruleTableList);

        if (ruleTableId && ruleTableId !== "-1" && ruleTableId !== null) {
          const ruleTable = ruleTableList.find(
            (rt) => rt.value === ruleTableId
          );
          setExternalDataRuleTable(ruleTable || null);
        }
      }
    });
  }

  async function loadTemplateRuleTableList(ruleTableDefinitionId) {
    const ruleTables = await apiLoadTemplateRuleTables.call(
      REPORT_TEMPLATES_RULE_ID,
      async (result) => {
        let dsResult = ([...result.resources] || []).sort((a, b) =>
          a.name > b.name ? 1 : -1
        );

        let dsList = [];

        // Filter rule tables to show only ones with the template's RTD
        for (let i = 0; i < dsResult.length; i++) {
          if (dsResult[i].ruleTableDefinitionId === ruleTableDefinitionId) {
            dsList.push({
              value: dsResult[i].id,
              label: dsResult[i].key,
            });
          }
        }

        return dsList;
      }
    );

    return ruleTables || [];
  }

  async function loadReport() {
    apiLoad.call(resId, async (result) => {
      const dsFields = await loadFieldsForDataSource(result.entityType);
      setFields([...dsFields]);

      // Load template if the report has one
      if (
        result.reportTemplateId !== "" &&
        result.reportTemplateId !== "-1" &&
        result.reportTemplateId !== null
      ) {
        await loadReportTemplate(
          result.reportTemplateId,
          result.externalDataRuleTableId
        );
      }

      setReportsData({
        type: ContextProviderActions.loadReport,
        payload: result,
      });

      const reportParameters = result.filters
        .filter((f) => f.isParameter === true)
        .map((f) => {
          return { ...f, id: f.id };
        });
      setParameters(reportParameters);
      setInitialParameterValues(reportParameters);
    });
  }

  function setInitialParameterValues(filters) {
    const reportParams = [];

    (filters || []).forEach((p) =>
      reportParams.push({
        id: p.id,
        value: getParameterValue(p), // Calculated value is the original value unless a placeholder (e.g., today) was used
        reportParameterType: "Data",
      })
    );
    setChanges(reportParams);
  }

  function getParameterValue(p) {
    const val = searchParams.get(p.parameterLabel);
    if (val) return val;
    return p.calculatedValue;
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before running the report.");
      return;
    }

    notifySuccess("Run report will go here");
  }

  function onRunReport() {
    const _errors = {};

    // Validate parameters - each one must contain a value, but let each control take care of
    //   validating its own format.
    let val;
    parameters
      .filter(
        (p) => fields.find((f) => f.value === p.fieldId)?.datatype !== "Boolean"
      )
      .forEach((p) => {
        val = changes.find((f) => f.id === p.id);
        if (!val || !val.value || _.trim(val.value) === "")
          _errors[p.id] = `${p.parameterLabel} is required`;
      });

    // Validate rule table is selected if one is needed
    const needsRuleTable =
      reportTemplate !== null &&
      reportTemplate.externalDataRuleTableDefinitionId !== "-1" &&
      reportTemplate.externalDataRuleTableDefinitionId !== null;
    if (needsRuleTable && externalDataRuleTable === null) {
      _errors.externalDataRuleTableId = "Template Rule Table is required";
    }

    setErrors(_errors);
    // Return false to stop report from running if there were errors.
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    const _changes = [...changes];
    const filter = _changes.find((f) => f.id === target.name);
    if (!filter) {
      console.log(`A filter with id "${target.name}" was not found.`);
      return;
    }

    filter.value = target.value;
    setChanges(_changes);
  }

  function handleRuleTableChange(option) {
    setExternalDataRuleTable(option);
  }

  function handleLastValueChange({ target }) {
    const _changes = [...lastRunValues];
    const filter = _changes.find((f) => f.name === target.name);
    if (!filter) {
      console.log(`A last run value with name "${target.name}" was not found.`);
      return;
    }

    filter.value = target.value;
    setLastRunValues(_changes);
  }

  let val = undefined;
  const hasTemplate = reportTemplate !== null;
  const needsRuleTable =
    hasTemplate &&
    reportTemplate.externalDataRuleTableDefinitionId !== "-1" &&
    reportTemplate.externalDataRuleTableDefinitionId !== null;

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            title="Return to previous screen"
            type="button"
            className="btn btn-link"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Reports/Run-Report-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            Run Report
            {` | ${report.name}`}
          </h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate(-1)}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {loading || loadingFields || loadingTemplate || loadingRuleTables ? (
          <Spinner />
        ) : (
          <>
            <h2
              style={{
                marginBottom: "15px",
                fontSize: "18px",
                color: "var(--text-medium)",
              }}
            >
              {report.description}
            </h2>
            <ExpandCollapseDetailSection
              sectionTitle="Parameters"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Reports/Run-Report-Screen&anchor=parameters-section"
            >
              {parameters.length === 0 && !hasTemplate ? (
                <i style={{ color: "var(--text-dark)" }}>
                  This report has no parameters
                </i>
              ) : (
                <div className="row">
                  {parameters.map((p) => {
                    val = changes.find((f) => f.id === p.id);
                    if (!val) return null;
                    return (
                      <div key={p.id} className="col col-12 col-lg-6">
                        <FilterValueFieldByDataType
                          key={p.id}
                          id={p.id}
                          label={p.parameterLabel}
                          onChange={handleChange}
                          placeholder=""
                          name={p.id}
                          value={val.value}
                          error={errors[p.id]}
                          field={fields.find((f) => f.value === p.fieldId)}
                          operator={p.operator}
                          displayAsTextControl={false}
                        />
                      </div>
                    );
                  })}
                  {hasTemplate && (
                    <>
                      <div className="col col-12 col-lg-6">
                        <FilterValueFieldByDataType
                          key={"runNumber"}
                          id={"runNumber"}
                          label={"Run Number"}
                          onChange={({ target }) =>
                            setTemplateRunNumber(target.value)
                          }
                          placeholder=""
                          name={"runNumber"}
                          value={templateRunNumber}
                          error={errors["runNumber"]}
                          field={{ datatype: "Int32" }}
                          operator={""}
                          displayAsTextControl={false}
                        />
                      </div>
                      <div className="col col-12 col-lg-6">
                        <FilterValueFieldByDataType
                          key={"runDate"}
                          id={"runDate"}
                          label={"Run Date"}
                          onChange={({ target }) =>
                            setTemplateRunDate(target.value)
                          }
                          placeholder=""
                          name={"runDate"}
                          value={templateRunDate}
                          error={errors["runDate"]}
                          field={{ datatype: "DateTime" }}
                          operator={""}
                          displayAsTextControl={false}
                        />
                      </div>
                      {needsRuleTable && (
                        <div className="col col-12 col-lg-6">
                          <SelectInput
                            id="externalDataRuleTableId"
                            name="externalDataRuleTableId"
                            label="Template Rule Table (for data)"
                            options={templateRuleTableList}
                            value={externalDataRuleTable}
                            onChange={handleRuleTableChange}
                            placeholder=""
                            error={errors.externalDataRuleTableId}
                            controlStyle={{ width: "250px" }}
                          />
                        </div>
                      )}
                      {(lastRunValues || []).length > 0 &&
                        lastRunValues.map((p) => {
                          return (
                            <div
                              key={"LAST." + p.name}
                              className="col col-12 col-lg-6"
                            >
                              <FilterValueFieldByDataType
                                key={"LAST." + p.name}
                                id={p.name}
                                label={p.name}
                                onChange={handleLastValueChange}
                                placeholder=""
                                name={p.name}
                                value={p.value}
                                error={errors["LAST." + p.name]}
                                operator={""}
                                displayAsTextControl={false}
                              />
                            </div>
                          );
                        })}
                    </>
                  )}
                </div>
              )}
            </ExpandCollapseDetailSection>
            <ExpandCollapseDetailSection
              sectionTitle="Results"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Reports/Run-Report-Screen&anchor=results-section"
            >
              {report.id !== "" && !(loading || loadingFields) && (
                <ReportResults
                  report={report}
                  reportParameters={changes}
                  runNumber={templateRunNumber}
                  runDate={templateRunDate}
                  externalDataRuleTableId={externalDataRuleTable?.value || null}
                  lastRunValues={lastRunValues}
                  onRunReport={onRunReport}
                  runReportOnLoad={true}
                />
              )}
            </ExpandCollapseDetailSection>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default RunReport;
