import React from "react";
import PropTypes from "prop-types";
import SelectInput from "./SingleSelect";

function DateRangeInput({ name, id, value, label, error, onChange }) {
  function handleChange(option) {
    const value = option.value;
    const now = new Date().getTime();
    let startDate = now;
    let endDate = new Date("1/1/3000");

    // Set start and end date based on the selected date range
    switch (value) {
      case "All": {
        startDate = new Date("1/1/2000");
        break;
      }
      case "Past24hours": {
        startDate = now - 24 * 60 * 60 * 1000;
        break;
      }
      case "Past1hour": {
        startDate = now - 60 * 60 * 1000;
        break;
      }
      case "Past2days": {
        startDate = now - 2 * 24 * 60 * 60 * 1000;
        break;
      }
      case "Past7days": {
        startDate = now - 7 * 24 * 60 * 60 * 1000;
        break;
      }
      default: {
        console.log("Unknown value in DateRangeInput.handleChange", value);
      }
    }

    onChange(value, new Date(startDate), new Date(endDate));
  }

  const options = [
    { value: "All", label: "All Dates" },
    { value: "Past1hour", label: "Past 1 hour" },
    { value: "Past24hours", label: "Past 24 hours" },
    { value: "Past2days", label: "Past 2 days" },
    { value: "Past7days", label: "Past 7 days" },
  ];

  return (
    <SelectInput
      name={name}
      id={id}
      value={options.find((option) => option.value === value)}
      label={label}
      error={error}
      onChange={(option) => handleChange(option)}
      options={options}
    />
  );
}

DateRangeInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
};

DateRangeInput.defaultProps = {
  error: "",
};

export default DateRangeInput;
