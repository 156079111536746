import React from "react";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../../common/layout/CommonStyledControls";
import { useNavigate } from "react-router-dom";
import HelpLink from "../../../common/ui/HelpLink";
import { StyledWizardHeader } from "../../../common/layout/CommonStyledWizardComponents";

function PanelWizardHeader({ helpLink, symbolIcon, title }) {
  const navigate = useNavigate();

  return (
    <>
      <StyledBackButtonDiv>
        <button
          type="button"
          title="Return To Common Tasks"
          className="btn btn-link btn-with-icon"
          onClick={() => navigate("/wizard")}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink path={`/Wizard/${helpLink}`} label="Help" />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv style={{ marginTop: "20px" }}>
        <StyledWizardHeader>
          <span className="task-icon material-symbols-outlined">
            {symbolIcon}
          </span>
          <h1>{title}</h1>
        </StyledWizardHeader>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/wizard")}
            style={{ marginRight: "12px" }}
          >
            Cancel
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
    </>
  );
}

export default PanelWizardHeader;
