import React from "react";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";
import DateRangeInput from "../../common/input/DateRangeInput";
import DatePickerInput from "../../common/input/DatePickerInput";
import SelectInput from "../../common/input/SingleSelect";
import { processHistoryRunTypes } from "../../../services/General";

function ProcessHistorySearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onRunTypeChanged,
  onDateRangeChange,
  onStartDateChange,
  onEndDateChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="description"
            label="Description"
            onChange={onChange}
            placeholder="Description"
            name="description"
            value={search.description}
            error={errors.description}
          />
          <SelectInput
            id="runType"
            name="runType"
            label="Run Type"
            options={[...processHistoryRunTypes]}
            value={processHistoryRunTypes.find(
              (t) => t.value === search.runType
            )}
            onChange={onRunTypeChanged}
            placeholder="(All Types)"
            error={errors.runType}
          />
          <TextInput
            id="reportName"
            label="Report"
            onChange={onChange}
            placeholder="Report"
            name="reportName"
            value={search.reportName}
            error={errors.reportName}
          />
          <TextInput
            id="runBy"
            label="Run By"
            onChange={onChange}
            placeholder="Run By"
            name="runBy"
            value={search.runBy}
            error={errors.runBy}
          />
          <DateRangeInput
            id="dateRange"
            name="dateRange"
            label="Date Range"
            value={search.dateRange}
            onChange={onDateRangeChange}
            error={errors.dateRange}
          />
          <DatePickerInput
            id="startDate"
            name="startDate"
            label="Run Start Date"
            value={search.startDate}
            placeholder=""
            onChange={onStartDateChange}
            error={errors.startDate}
          />
          <DatePickerInput
            id="endDate"
            label="Run End Date"
            name="endDate"
            value={search.endDate}
            placeholder=""
            onChange={onEndDateChange}
            error={errors.endDate}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default ProcessHistorySearchForm;
