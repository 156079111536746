import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyGroupRuleMapSearch } from "../viewmodels/groupRuleMapsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.resetGroupRuleMap:
      return {
        ...state,
        editField: null,
        editMode: false,
        groupRuleMaps: [],
        search: emptyGroupRuleMapSearch,
        count: 0,
        isFilterSet: false,
      };

    case ContextProviderActions.loadGroupRuleMaps:
      return {
        ...state,
        groupRuleMaps: action.payload.groupRuleMaps,
        count: action.payload.count,
      };

    case ContextProviderActions.saveGroupRuleMapSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.setGroupRuleMapIsFilterSet:
      return {
        ...state,
        isFilterSet: action.payload,
      };

    case ContextProviderActions.setGroupRuleMapIsEditMode:
      return {
        ...state,
        editMode: action.payload,
      };

    case ContextProviderActions.setGroupRuleMapEditNode:
      return {
        ...state,
        editField: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in GroupRuleMapsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  groupRuleMaps: [],
  search: emptyGroupRuleMapSearch,
  count: 0,
  isFilterSet: false,
  editMode: false,
  editField: null,
};

const GroupRuleMaps = createContext({
  groupRuleMapsData: initialState,
  setGroupRuleMapsData: () => null,
});

// eslint-disable-next-line react/prop-types
const GroupRuleMapsProvider = ({ children }) => {
  const [groupRuleMapsData, setGroupRuleMapsData] = useReducer(
    reducer,
    initialState
  );

  return (
    <GroupRuleMaps.Provider value={{ groupRuleMapsData, setGroupRuleMapsData }}>
      {children}
    </GroupRuleMaps.Provider>
  );
};

export default GroupRuleMapsProvider;
export const useGroupRuleMaps = () => useContext(GroupRuleMaps);
