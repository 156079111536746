import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyMember, emptyMemberSearch } from "../viewmodels/membersVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadMembers:
      return {
        ...state,
        members: action.payload.members,
        count: action.payload.count,
      };

    case ContextProviderActions.saveMemberSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadMember:
      return {
        ...state,
        member: action.payload.member,
      };

    default:
      throw new Error(
        `Unhandled action type in MembersContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  members: [],
  member: emptyMember,
  search: emptyMemberSearch,
  count: 0,
};

const Members = createContext({
  membersData: initialState,
  setMembersData: () => null,
});

// eslint-disable-next-line react/prop-types
const MembersProvider = ({ children }) => {
  const [membersData, setMembersData] = useReducer(reducer, initialState);

  return (
    <Members.Provider value={{ membersData, setMembersData }}>
      {children}
    </Members.Provider>
  );
};

export default MembersProvider;
export const useMembers = () => useContext(Members);
