import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { applyNodeChanges, applyEdgeChanges } from "@xyflow/react";
import { NodeTypes } from "../components/screens/rulemaps/RuleMapDataCommon";
import { emptyGroupRuleMapFilter } from "../viewmodels/groupRuleMapsVm";

function reducer(state, action) {
  switch (action.type) {
    // When a rule map is loaded, do a full reset on all state - use initialState instead of state to get defaults.
    case ContextProviderActions.loadRuleMapForClaim:
      return {
        ...initialState,
        ruleMap: action.payload === null ? null : action.payload.ruleMap,
        claim: action.payload === null ? null : action.payload.claim,
        selectedNodeId: "-1",
        isModuleViewMode: false,
        isGroupRuleMode: false,
      };

    case ContextProviderActions.loadRuleMapForGroup:
      return {
        ...initialState,
        ruleMap: action.payload === null ? null : action.payload,
        claim: null,
        selectedNodeId: "-1",
        isModuleViewMode: false,
        showPossiblePaths: true,
        showModules: false,
        isGroupRuleMode: true,
      };

    case ContextProviderActions.openRuleMapLogsDialog:
      return {
        ...state,
        logs: action.payload.logs,
        logsDialogTitle: action.payload.title,
        showLogsDialog: true,
      };

    case ContextProviderActions.closeRuleMapLogsDialog:
      return {
        ...state,
        logs: [],
        logsDialogTitle: "",
        showLogsDialog: false,
      };

    case ContextProviderActions.loadRuleMapRejectionsList:
      return {
        ...state,
        rejectionsList: action.payload,
      };

    case ContextProviderActions.loadRuleMapResponseFieldsList:
      return {
        ...state,
        responseFieldsList: action.payload,
      };

    case ContextProviderActions.selectRuleMapField:
      return {
        ...state,
        selectedField: action.payload.selectedField,
        showPossiblePaths:
          action.payload.selectedField.type === NodeTypes.Rejection,
        selectedNodeId: "-1",
        isModuleViewMode: false,
      };

    case ContextProviderActions.resetRuleMapSelections:
      return {
        ...state,
        selectedField: { value: "-1", type: NodeTypes.Unknown },
        showPossiblePaths: false,
        selectedNodeId: "-1",
        doRefreshLayout: true,
        finalLayoutComplete: false,
        showModules: true,
        isModuleViewMode: false,
        selectedModuleId: "-1",
        selectedModuleName: "",
      };

    case ContextProviderActions.setRuleMapShowPossiblePaths:
      return {
        ...state,
        showPossiblePaths: action.payload,
      };

    case ContextProviderActions.setRuleMapLayoutDirection:
      return {
        ...state,
        layoutDirection: action.payload,
        doRefreshLayout: true,
        finalLayoutComplete: false,
        selectedNodeId: "-1",
      };

    case ContextProviderActions.setRuleMapLayoutDirectionWithoutRefresh:
      return {
        ...state,
        layoutDirection: action.payload,
      };

    case ContextProviderActions.setRuleMapDetailLevel:
      return {
        ...state,
        detailLevel: action.payload,
      };

    case ContextProviderActions.setRuleMapNodes:
      return {
        ...state,
        nodes: action.payload,
      };

    case ContextProviderActions.setRuleMapEdges:
      return {
        ...state,
        edges: action.payload,
      };

    case ContextProviderActions.setRuleMapModules:
      return {
        ...state,
        modules: action.payload,
      };

    case ContextProviderActions.onRuleMapNodesChange:
      return {
        ...state,
        nodes: applyNodeChanges(action.payload, state.nodes),
      };

    case ContextProviderActions.onRuleMapEdgesChange:
      return {
        ...state,
        edges: applyEdgeChanges(action.payload, state.edges),
      };

    case ContextProviderActions.setRuleMapLayoutedNodesAndEdges:
      // This is called after the dagre layout engine runs on the nodes and edges.
      return {
        ...state,
        nodes: action.payload.layoutedNodes,
        edges: action.payload.layoutedEdges,
      };

    case ContextProviderActions.setRuleMapFinalLayoutedNodesAndEdges:
      // This is called after the dagre layout is run the second time after node sizes and positions are set.
      return {
        ...state,
        nodes: action.payload.layoutedNodes,
        edges: action.payload.layoutedEdges,
        finalLayoutComplete: true,
        doRefreshLayout: false,
      };

    case ContextProviderActions.doRuleMapRefreshLayout:
      // This is called whenever we want to refresh the nodes without generating new ones
      return {
        ...state,
        doRefreshLayout: true,
        finalLayoutComplete: false,
      };

    case ContextProviderActions.selectRuleMapNode: {
      const isModule =
        action.payload.selectedNodeType?.description === "ModuleNode";

      // If we are already in view module mode, do not turn it off if another node type is selected. The Close button
      //   will disable it instead. We want users to be able to select a node within a module.
      const newIsModuleViewMode =
        state.isModuleViewMode || (!state.isModuleViewMode && isModule);

      let newModuleName = action.payload.moduleName;
      if (newIsModuleViewMode && state.selectedModuleName !== "") {
        newModuleName = state.selectedModuleName;
      }

      return {
        ...state,
        selectedNodeId: isModule ? "-1" : action.payload.selectedNodeId,
        selectedModuleId: action.payload.selectedNodeId,
        selectedModuleName: newModuleName,
        isModuleViewMode: newIsModuleViewMode,
        finalLayoutComplete: !isModule,
      };
    }

    case ContextProviderActions.closeModuleViewMode: {
      return {
        ...state,
        selectedNodeId: "-1",
        selectedModuleId: "-1",
        selectedModuleName: "",
        isModuleViewMode: false,
        finalLayoutComplete: false,
      };
    }

    case ContextProviderActions.setRuleMapShowModules:
      return {
        ...state,
        showModules: action.payload,
        selectedNodeId: "-1",
        selectedModuleId: "-1",
        selectedModuleName: "",
      };

    case ContextProviderActions.saveGroupRuleMapFilter:
      return {
        ...state,
        search: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in RuleMapsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  ruleMap: null,
  search: emptyGroupRuleMapFilter,
  nodes: [],
  edges: [],
  modules: [],
  finalLayoutComplete: false,
  doRefreshLayout: false,
  claim: null,
  logs: [],
  logsDialogTitle: "",
  showLogsDialog: false,
  rejectionsList: [],
  selectedField: { value: "-1", type: NodeTypes.Unknown },
  responseFieldsList: [],
  showPossiblePaths: false,
  layoutDirection: "TB",
  detailLevel: 1,
  selectedNodeId: "-1",
  showModules: true,
  selectedModuleId: "-1",
  selectedModuleName: "",
  isModuleViewMode: false,
  isGroupRuleMode: false,
};

const RuleMaps = createContext({
  ruleMapsData: initialState,
  setRuleMapsData: () => null,
});

// eslint-disable-next-line react/prop-types
const RuleMapsProvider = ({ children }) => {
  const [ruleMapsData, setRuleMapsData] = useReducer(reducer, initialState);

  return (
    <RuleMaps.Provider value={{ ruleMapsData, setRuleMapsData }}>
      {children}
    </RuleMaps.Provider>
  );
};

export default RuleMapsProvider;
export const useRuleMaps = () => useContext(RuleMaps);
