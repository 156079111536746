import React, { useState, useEffect } from "react";
import MembersList from "./MembersList";
import MemberSearchForm from "./MemberSearchForm";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import {
  createViewModel,
  emptyMemberSearch,
} from "../../../viewmodels/membersVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useMembers } from "../../../contexts/MembersContext";
import { useGroups } from "../../../contexts/GroupsContext";
import { apiLoadMembers, apiDeleteMember } from "../../../api/MemberApi";
import { apiLoadGroupsAll } from "../../../api/GroupApi";
import { useNavigate } from "react-router-dom";
import Authorize from "../../common/layout/Authorize";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Members() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const { membersData, setMembersData } = useMembers();
  const { groupsData, setGroupsData } = useGroups();
  const { loading, api: apiLoad } = useApi(apiLoadMembers);
  const { loading: loadingGroups, api: apiLoadGroups } =
    useApi(apiLoadGroupsAll);
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteMember);
  const [loadData, setLoadData] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const { isMobileSize } = useMobile();

  let members = [];
  if (membersData && membersData.members) {
    members = membersData.members;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setMembersData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //   useEffect ensures that happens first.
  useEffect(() => {
    // jon, 5/9/24: Set the search term coming from the test processor screen in another tab if we have the value in local storage
    let preSearch = null;
    if (!_.isEmpty(localStorage["TEST_PROCESSOR_SEARCH_TERM"])) {
      const searchTerm = localStorage["TEST_PROCESSOR_SEARCH_TERM"];
      localStorage.removeItem("TEST_PROCESSOR_SEARCH_TERM");

      preSearch = {
        ...membersData.search,
        freeFormSearch: searchTerm,
        pageNumber: 1,
      };
      setSearchChanges(preSearch);
    }

    if (auth.authenticated && loadData) {
      loadMembersAndGroups(preSearch);
    }
  }, [auth.authenticated, loadData]);

  async function loadMembersAndGroups(preSearch) {
    const groupList = await loadGroupListIfNeeded();
    loadMembers(preSearch, groupList);
  }

  async function loadMembers(preSearch, groupList) {
    const actualSearch = preSearch === null ? membersData.search : preSearch;

    apiLoad.call(
      actualSearch,
      (result) => {
        setLoadData(false);
        const count = result.count || 0;

        // Save the list of groups in the cache
        setGroupsData({
          type: ContextProviderActions.setGroupListCache,
          payload: groupList,
        });

        // Group viewmodel needs the client list
        const vms = result.resources.map((r) => createViewModel(r, groupList));

        setMembersData({
          type: ContextProviderActions.loadMembers,
          payload: {
            members: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  async function loadGroupListIfNeeded() {
    if ((groupsData.groupListCache || []).length > 0) {
      return groupsData.groupListCache;
    }

    return await apiLoadGroups.call(null, (result) => {
      return _.concat(
        [{ value: "", label: "(None Selected)" }],
        (result.resources || []).map((c) => {
          return { value: c.id, label: c.name };
        })
      );
    });
  }

  function setSearchChanges(search) {
    setMembersData({
      type: ContextProviderActions.saveMemberSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyMemberSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : membersData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = membersData.search.orderBy.indexOf(`${event.target.id}+`);
    var indexDesc = membersData.search.orderBy.indexOf(`${event.target.id}-`);

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...membersData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...membersData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...membersData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = { ...membersData.search, ...newSearch, pageNumber: 1 };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({ ...membersData.search, [target.name]: target.value });
  }

  function handleActiveChange({ target }) {
    setSearchChanges({ ...membersData.search, active: target.checked });
  }

  function handleGroupChange(value) {
    setSearchChanges({ ...membersData.search, groupId: value });
  }

  async function deleteMember(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Member disabled successfully");
      setLoadData(true);
    });
  }

  async function handleDelete(id, memberId) {
    setDeleteId(id);
    setAltDeleteId(memberId);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteMember(deleteId);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (membersData.search.memberId !== "") numFilters++;
    if (membersData.search.groupId !== "") numFilters++;
    if (membersData.search.lastName !== "") numFilters++;
    if (membersData.search.firstName !== "") numFilters++;
    if (membersData.search.address !== "") numFilters++;
    if (membersData.search.city !== "") numFilters++;
    if (membersData.search.state !== "") numFilters++;
    if (membersData.search.active !== true) numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        {((membersData.search.showAdvancedFilter && !isMobileSize) ||
          !membersData.search.showAdvancedFilter) && (
          <HelpLink path="/Configure/Members-List-Screen" label="Help" />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Members</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/member")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Member
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search Member Id, Group, or Name"
        search={membersData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <ConfirmDialog
        title="Remove Member"
        question={`Are you sure you wish to deactivate the member '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              membersData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={membersData.search}
            setSearch={setSearchChanges}
            helpLink="/Configure/Members-List-Screen&anchor=filters"
          >
            <MemberSearchForm
              errors={errors}
              search={membersData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onActiveChange={handleActiveChange}
              onGroupChange={handleGroupChange}
              groupList={groupsData.groupListCache}
            />
          </GridAdvancedFilter>
        </div>

        {membersData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || loadingGroups || deleting ? (
              <Spinner />
            ) : (
              <MembersList
                members={members}
                onDelete={handleDelete}
                search={membersData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={membersData.count}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}
export default Members;
