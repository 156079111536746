import _ from "lodash";
import { pageSize } from "../constants/Environments";

export const emptyTestGroup = {
  id: "",
  lastRunDate: "",
  lastRunBy: "",
  lastRunDurationMilliseconds: 0,
  lastRunResultStatus: "NotRun", // Pass, Fail
  testCount: 0,
  description: "",
  tests: [],
};

export const emptyTestGroupSearch = {
  description: "",
  lastRunBy: "",
  lastRunResultStatus: [],
  startDate: new Date("1/1/2000"),
  endDate: new Date("1/1/3000"),
  orderBy: ["description+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
  newTestGroupDescription: "",
  oldTestGroupDescription: "",
  copyId: "",
};

export const emptyTestGroupTestSearch = {
  orderBy: ["ordinal+"],
  pageSize: 100000,
  pageNumber: 1,
  highlightText: "",
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  return vm;
}
