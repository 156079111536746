import React from "react";
import styled from "styled-components";
import { useMobile } from "../../../hooks/useMobile";

export function PacketParseSegmentHeader({
  id,
  segmentCode,
  segmentDescription,
  newSegment,
  isSelected,
}) {
  const { isMobileSize } = useMobile();

  if (newSegment)
    return (
      <>
        <tr id={id} key={segmentCode}>
          <StyledTD
            colSpan="100%"
            className={isSelected === true ? "selected-row" : ""}
          >
            {segmentDescription}
          </StyledTD>
        </tr>
        {!isMobileSize && (
          <tr style={{ backgroundColor: "#FFFFFF" }}>
            <th>Field Description</th>
            <th>Tx</th>
            <th>Code</th>
            <th>Value</th>
            <th>Value Description</th>
            <th>Value Summary</th>
          </tr>
        )}
      </>
    );
  else return <></>;
}

const StyledTD = styled.td`
  font-weight: bold;
  border-left: 4px solid transparent;
  background-color: var(--table-border) !important;
  padding: 8px !important;

  &.selected-row {
    color: #000;
    background-color: var(--table-hover-row-bg) !important;
    border-left: 4px solid var(--table-hover-row-border);
  }
`;
