/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";
import _ from "lodash";

export function apiGetCacheStatusAndDiagnostics(search, token, signal) {
  // Convert start and end date to UTC
  const startDate = search.startDate.toISOString();
  const endDate = search.endDate.toISOString();

  const match =
    _.trim(search.keyMatch) === "" ? "*" : `*${_.trim(search.keyMatch)}*`;

  const uri =
    API_URL +
    `/cachekeys/${startDate}/${endDate}/${encodeURIComponent(match)}` +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiSetCacheState(enable, token, signal) {
  let uri = API_URL + "/cachemode/" + getQueryString();
  uri += `&state=${enable ? "enabled" : "disabled"}`;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiClearEntireCache(params, token, signal) {
  let uri = API_URL + "/cachekeys/*" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiInvalidateCacheKey(match, token, signal) {
  if (_.trim(match) === "" || _.trim(match) === "*")
    throw new Error("Match cannot be empty in apiInvalidateCacheKey!");

  let uri =
    API_URL +
    "/cachekeys/" +
    encodeURIComponent(_.trim(match)) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiGetCacheKeyContents(match, token, signal) {
  if (_.trim(match) === "" || _.trim(match) === "*")
    throw new Error("Match cannot be empty in apiGetCacheKeyContents!");

  let uri =
    API_URL +
    "/cacheitem/" +
    encodeURIComponent(_.trim(match)) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiGetCacheStatusAndDiagnostics.funcName = "apiGetCacheStatusAndDiagnostics";
apiSetCacheState.funcName = "apiSetCacheState";
apiClearEntireCache.funcName = "apiClearEntireCache";
apiInvalidateCacheKey.funcName = "apiInvalidateCacheKey";
apiGetCacheKeyContents.funcName = "apiGetCacheKeyContents";
