import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import { emptyLogHeaderSearch } from "../../../viewmodels/logsVm";
import LogHeadersList from "./LogHeadersList";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useAuth } from "../../../contexts/AuthContext";
import { useLogs } from "../../../contexts/LogsContext";
import { apiLoadLogHeaders } from "../../../api/LogApi";
import Authorize from "../../common/layout/Authorize";
import { useMobile } from "../../../hooks/useMobile";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import LogSearchForm from "./LogSearchForm";
import useApi from "../../../hooks/useApi";
import {
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";

function Logs() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { logsData, setLogsData } = useLogs();
  const { loading: loadingHeaders, api: apiLoadHeaders } =
    useApi(apiLoadLogHeaders);
  const [loadHeaders, setLoadHeaders] = useState(true);
  const { isMobileSize } = useMobile();

  let logHeaders = [];
  if (logsData && logsData.logHeaders) {
    logHeaders = logsData.logHeaders;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setLogsData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so these
  //   useEffects ensure that happens first.
  useEffect(() => {
    if (auth.authenticated && loadHeaders) {
      loadLogHeaders();
    }
  }, [auth.authenticated, loadHeaders]);

  function setSearchHeaderChanges(search) {
    setLogsData({
      type: ContextProviderActions.saveLogHeaderSearch,
      payload: search,
    });
  }

  async function loadLogHeaders() {
    apiLoadHeaders.call(logsData.logHeaderSearch, async (result) => {
      setLoadHeaders(false);
      setLogsData({
        type: ContextProviderActions.loadLogHeaders,
        payload: {
          logHeaders: result.resources || [],
          logHeadersCount: result.count || 0,
        },
      });
    });
  }

  function headersFormIsValid() {
    const _errors = {};
    if (
      _.has(logsData.logSearch, "startDate") &&
      !_.isDate(logsData.logSearch.startDate)
    )
      _errors.startDate = "Effective Date must be a valid date";
    if (
      _.has(logsData.logSearch, "endDate") &&
      !_.isDate(logsData.logSearch.endDate)
    )
      _errors.endDate = "Termination Date must be a valid date";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSubmitLogHeaders(event, newSearch) {
    var updatedSearch = {
      ...logsData.logHeaderSearch,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchHeaderChanges(updatedSearch);
    await handleSearchLogHeaders(event, updatedSearch);
  }

  async function handleSearchLogHeaders(event, newSearch) {
    if (event) event.preventDefault();
    if (!headersFormIsValid()) return;

    if (!loadingHeaders) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchHeaderChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadHeaders(true);
    }
  }

  async function handleResetLogHeaders() {
    setSearchHeaderChanges({
      ...emptyLogHeaderSearch,
      showAdvancedFilter: logsData.logHeaderSearch.showAdvancedFilter,
    });
    if (!loadingHeaders) {
      setLoadHeaders(true);
    }
  }

  function handleDateRangeChange(dateRange, startDate, endDate) {
    setSearchHeaderChanges({
      ...logsData.logHeaderSearch,
      dateRange: dateRange,
      startDate: startDate,
      endDate: endDate,
    });
  }

  function onStartDateChange(date) {
    let updatedSearch = { ...logsData.logHeaderSearch, startDate: date };
    setSearchHeaderChanges(updatedSearch);
  }

  function onEndDateChange(date) {
    let updatedSearch = { ...logsData.logHeaderSearch, endDate: date };
    setSearchHeaderChanges(updatedSearch);
  }

  function handleLogHeaderSearchChange({ target }) {
    var updatedSearch = {
      ...logsData.logHeaderSearch,
      [target.name]: target.value,
    };
    setSearchHeaderChanges(updatedSearch);
  }

  function handleLogTypesChanged(logTypes) {
    setSearchHeaderChanges({
      ...logsData.logHeaderSearch,
      logTypes: logTypes,
    });
  }

  async function handleSort(event) {
    var indexAsc = logsData.logHeaderSearch.orderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = logsData.logHeaderSearch.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...logsData.logHeaderSearch,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...logsData.logHeaderSearch,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...logsData.logHeaderSearch, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchHeaderChanges(updatedSearch);

      if (!loadingHeaders) {
        setLoadHeaders(true);
      }
    }
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (logsData.logHeaderSearch.traceId !== "") numFilters++;
    if (logsData.logHeaderSearch.environment !== "") numFilters++;
    if (logsData.logHeaderSearch.logHeaderSearch !== "") numFilters++;
    if (logsData.logHeaderSearch.userSearch !== "") numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledHeaderRowDiv>
        <h1>Log Explorer</h1>
        <StyledScreenHelpDiv>
          <HelpLink path="/Tools/Log-Explorer-List-Screen" label="Help" />
        </StyledScreenHelpDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search by Id, Type, Env, Text, or User"
        search={logsData.logHeaderSearch}
        setSearch={setSearchHeaderChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={handleSubmitLogHeaders}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              logsData.logHeaderSearch.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={logsData.logHeaderSearch}
            setSearch={setSearchHeaderChanges}
            helpLink="/Tools/Log-Explorer-List-Screen&anchor=filters"
          >
            <LogSearchForm
              errors={errors}
              search={logsData.logHeaderSearch}
              onStartDateChange={onStartDateChange}
              onEndDateChange={onEndDateChange}
              onDateRangeChange={handleDateRangeChange}
              onSearch={handleSubmitLogHeaders}
              onReset={handleResetLogHeaders}
              onChange={handleLogHeaderSearchChange}
              onLogTypesChanged={handleLogTypesChanged}
            />
          </GridAdvancedFilter>
        </div>
        {logsData.logHeaderSearch.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loadingHeaders ? (
              <Spinner />
            ) : (
              <LogHeadersList
                logHeaders={logHeaders}
                search={logsData.logHeaderSearch}
                setSearch={async (search) => {
                  setSearchHeaderChanges(search);

                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearchLogHeaders(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={logsData.logHeadersCount}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default Logs;
