import React, { useEffect, useState } from "react";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import { useReportProcesses } from "../../../contexts/ReportProcessesContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function ReportProcessReportParametersGrid({ vmFilters }) {
  const { reportProcessesData, setReportProcessesData } = useReportProcesses();
  const [filtersForGrid, setFiltersForGrid] = useState([]);

  useEffect(() => {
    handleFilters(vmFilters);
  }, [vmFilters]);

  function setSearchLastRunValuesChanges(search) {
    setReportProcessesData({
      type: ContextProviderActions.saveReportProcessLastRunValuesSearch,
      payload: search,
    });
  }

  function handleFilters(pFilters) {
    if (Object.keys(pFilters).length > 0) {
      buildLastRunValuesArrays(pFilters);
    } else {
      setFiltersForGrid([]);
    }
  }

  function buildLastRunValuesArrays(pFilters) {
    const _rowValues = [];

    for (let i = 0; i < Object.keys(pFilters).length; i++) {
      _rowValues.push({
        name: Object.keys(pFilters)[i],
        value: Object.values(pFilters)[i],
      });
    }

    setFiltersForGrid(_rowValues);
  }

  return (
    <>
      {(filtersForGrid || []).length > 0 && (
        <StyledRowDiv className="row" style={{ marginTop: "40px" }}>
          <h3 style={{ fontSize: "20px" }}>Report Parameters</h3>
          <ResponsiveGrid
            gridId="ReportProcessParameters"
            totalRecords={filtersForGrid.length}
            search={reportProcessesData.searchLastRunValues}
            setSearch={setSearchLastRunValuesChanges}
            dataRows={filtersForGrid}
            disablePaging={true}
            totalColumnCount={2}
            columnDefs={[
              {
                name: "name",
                label: "Parameter",
                getValue: (row) => (row.name === "" ? <i>null</i> : row.name),
              },
              {
                name: "value",
                label: "Value",
                getValue: (row) => (row.value === "" ? <i>null</i> : row.value),
              },
            ]}
          />
        </StyledRowDiv>
      )}
    </>
  );
}

export default ReportProcessReportParametersGrid;
