import React from "react";
import _ from "lodash";
import { pageSize } from "../constants/Environments";
import {
  reportAggregateFunctions,
  reportCustomFieldDataTypes,
  reportDisplayFormats,
  shareModeTypes,
} from "../services/General";

export const dashboardPanelGraphTypes = [
  { label: "(Do not show a graph)", value: "None" },
  { label: "Area Chart", value: "AreaChart" },
  { label: "Bar Chart", value: "BarChart" },
  { label: "Column Chart", value: "ColumnChart" },
  { label: "Line Chart", value: "LineChart" },
  { label: "Stepped Area Chart", value: "SteppedAreaChart" },
];

export const emptyReport = {
  id: "",
  name: "",
  description: "",
  shareMode: { value: "ZNotShared", label: "Not shared" }, // see shareModeTypes in General.js
  shareModeVm: "",
  showOnHomePage: false,
  showAsDashboardPanel: false,
  dashboardPanelLabel: "",
  dashboardPanelRefreshSeconds: "60",
  dashboardPanelIsAlertPanel: false,
  dashboardValueSelect: "COUNT", // COUNT or FIELD
  dashboardPanelFieldDisplayName: "",
  dashboardPanelGraphType: { label: "Column Chart", value: "ColumnChart" },
  dashboardPanelGraphTypeVm: "",
  dashboardPanelOrdinal: 0, // Future use: allow user to order panels from dashboard
  entityType: { value: "Claim", label: "Claim" },
  dataSourceVm: "",
  joinEntities: [], // this is an array of select options with value and label
  joinEntityTypes: [], // this is an array of strings saved to the backend - converted from joinEntities in VMs below
  selectFields: [], // emptySelectedReportField
  orderByFields: [], // emptyOrderGroupByReportField
  filters: [],
  reportFormat: "Default", // Default, Template
  reportTemplateId: {
    value: "-1",
    label: "(Select a template)",
    entityType: "",
  },
  reportTemplateVm: "",
  externalDataRuleTableId: {
    value: "-1",
    label: "(Select a rule table)",
    ruleTableDefinitionId: "",
  },
  externalDataRuleTableVm: "",
  externalDataRuleTableName: "",
  newReportName: "",
  oldReportName: { name: "", id: "" },
  ownerUserId: "",
  ownerUserName: "",
  operandMode: "And", // Determines filter type: And=AndOrAnd pattern, Or=OrAndOr pattern.
};

export const emptySelectedReportField = {
  id: "",
  fieldId: "",
  fieldDisplayName: "",
  aggregateFunction: { value: "None", label: "(None)" },
  aggregateFunctionVm: "None",
  isCustom: false,
  customDataType: { value: "string", label: "String" },
  customDataTypeVm: "string",
  displayFormat: "",
  displayFormatDrop: { value: "", label: "(Default)" },
  displayFormatVm: "",
};

export const emptyOrderGroupByReportField = {
  id: "",
  fieldId: "",
  fieldDisplayName: "",
  orderDirection: "Ascending",
  groupBy: false,
  isCustom: false,
};

export const emptyReportCondition = {
  id: "",
  operand: "And",
  fieldId: "",
  operator: "0",
  value: "",
  isParameter: false,
  parameterLabel: "",
  fieldLabel: "",
  fieldUniqueId: "",
  fieldDisplayName: "",
  indentLevel: 0,
  groupBreak: false, // Determines when to break conditions at the same level
  groupBreakHandled: false, // Set to true to keep from adding infinite containers
};

export const emptyReportSearch = {
  name: "",
  description: "",
  shareMode: [],
  reportTemplateId: "",
  showOnHomePage: false,
  showAsDashboardPanel: false,
  dashboardPanelIsAlertPanel: false,
  orderBy: ["shareMode-", "name+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export const emptyReportResultsSearch = {
  pageSize: pageSize || 25,
  pageNumber: 1,
  colPageCount: 1,
  colPageNumber: 1,
  colPageSize: 5,
  importReadyMode: false,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  vm.entityType = (vm.dataSourceVm || { value: "" }).value;

  vm.shareMode = (vm.shareModeVm || { value: "" }).value;
  // Convert ZNotShared back to NotShared before saving
  // if (vm.shareMode === "ZNotShared") vm.shareMode = "NotShared";

  vm.dashboardPanelGraphType = (
    vm.dashboardPanelGraphTypeVm || { value: "ColumnChart" }
  ).value;

  vm.reportTemplateId = (vm.reportTemplateVm || { value: "-1" }).value;
  vm.externalDataRuleTableId = (
    vm.externalDataRuleTableVm || { value: "-1" }
  ).value;

  if (vm.reportFormat === "Default") {
    vm.reportTemplateId = "";
  }

  // Handle special stuff in selectFields
  for (let i = 0; i < vm.selectFields.length; i++) {
    vm.selectFields[i] = fromFieldViewModel(vm.selectFields[i]);
  }

  // Handle special stuff in filters
  for (let i = 0; i < vm.filters.length; i++) {
    vm.filters[i] = fromConditionViewModel(vm.filters[i]);
  }

  if (vm.dashboardValueSelect === "COUNT") {
    vm.dashboardPanelFieldDisplayName = "";
  }

  // Convert join entities to join entity types array for saving
  vm.joinEntityTypes = (vm.joinEntities || []).map((j) => j.value);

  // Remove fields array from datasource since this is huge and not saved anyway
  vm.dataSourceVm.fields = [];

  let m = vm;
  return m;
}

export function createViewModel(
  m,
  dataSourceList,
  templateList,
  ruleTableList,
  fields
) {
  let vm = _.cloneDeep(m);

  // Dropdown values
  const dsValue = vm.entityType.value ?? vm.entityType;
  vm.dataSourceVm =
    (dataSourceList || []).find((c) => c.value === dsValue) ||
    emptyReport.entityType;

  vm.shareModeVm =
    (shareModeTypes || []).find((c) => c.value === vm.shareMode) ||
    emptyReport.shareMode;

  // Convert order by fields to add id to the array of objects since the drag/drop library needs it
  vm.orderByFields = vm.orderByFields.map((f, idx) => {
    return { ...f, id: `${idx}-${f.fieldId}` };
  });

  vm.dashboardPanelGraphTypeVm = dashboardPanelGraphTypes.find(
    (d) => d.value === vm.dashboardPanelGraphType
  ) || { label: "Column Chart", value: "ColumnChart" };

  vm.reportTemplateVm =
    (templateList || []).find((c) => c.value === vm.reportTemplateId) ||
    emptyReport.reportTemplateId;

  vm.externalDataRuleTableVm =
    (ruleTableList || []).find((c) => c.value === vm.externalDataRuleTableId) ||
    emptyReport.externalDataRuleTableId;

  if ((vm.reportTemplateVm || { value: "-1" }).value !== "-1") {
    vm.reportFormat = "Template";
  }

  // Convert join entity types from server to join entities options array for multiselect
  vm.joinEntities = (vm.joinEntityTypes || []).map((j) => {
    return { value: j, label: j };
  });

  // Handle special stuff in selectFields
  for (let i = 0; i < vm.selectFields.length; i++) {
    vm.selectFields[i] = createFieldViewModel(
      vm.selectFields[i],
      vm.entityType
    );
  }

  // Handle special stuff in order/group by fields
  for (let i = 0; i < vm.orderByFields.length; i++) {
    vm.orderByFields[i] = createOrderGroupByViewModel(
      vm.orderByFields[i],
      vm.entityType
    );
  }

  // Handle special stuff in filters
  for (let i = 0; i < vm.filters.length; i++) {
    vm.filters[i] = createConditionViewModel(
      vm.filters[i],
      fields,
      vm.entityType
    );
  }

  if (
    vm.dashboardPanelFieldDisplayName &&
    vm.dashboardPanelFieldDisplayName !== null &&
    vm.dashboardPanelFieldDisplayName !== ""
  ) {
    vm.dashboardValueSelect = "FIELD";
  }

  return vm;
}

export function fromFieldViewModel(vm) {
  vm = _.cloneDeep(vm);

  vm.entityType = vm.fieldId?.entityType
    ? vm.fieldId.entityType
    : vm.entityType;
  vm.fieldId = vm.fieldId?.value ? vm.fieldId.value : vm.fieldId;
  vm.aggregateFunction = (vm.aggregateFunctionVm || { value: "None" }).value;
  vm.displayFormatDrop = (vm.displayFormatVm || { value: "" }).value;
  vm.customDataType = (vm.customDataTypeVm || { value: "string" }).value;

  let m = vm;
  return m;
}

export function createFieldViewModel(m, dataSourceEntityType) {
  let vm = _.cloneDeep(m);

  vm.entityType = vm.entityType ?? dataSourceEntityType;
  vm.aggregateFunctionVm =
    [...reportAggregateFunctions].find(
      (c) => c.value === vm.aggregateFunction
    ) || emptySelectedReportField.aggregateFunction;

  vm.displayFormatVm =
    [...reportDisplayFormats].find(
      (c) =>
        c.value ===
        (vm.displayFormatDrop !== "" ? vm.displayFormatDrop : vm.displayFormat)
    ) || emptySelectedReportField.displayFormatDrop;

  vm.customDataTypeVm =
    [...reportCustomFieldDataTypes].find(
      (c) => c.value === vm.customDataType
    ) || emptySelectedReportField.customDataType;

  return vm;
}

function getEntityLabelFromType(entityType) {
  if (entityType === "DiscountRedemption") return "DR";
  else if (entityType === "PriorAuthorization") return "PA";
  else if (entityType === "ProcessingRule") return "Rule";
  else if (entityType === "ServiceProvider") return "Provider";
  else return entityType;
}

export function getFieldNameWithEntityType(entityType, label) {
  const name = (
    <>
      <span className="entity-name">{getEntityLabelFromType(entityType)}</span>{" "}
      {label}
    </>
  );

  return name;
}

export function fromOrderGroupByViewModel(vm) {
  vm = _.cloneDeep(vm);

  vm.fieldId = vm.fieldId?.value ? vm.fieldId.value : vm.fieldId;

  let m = vm;
  return m;
}

export function fromConditionViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;
  return m;
}

export function createConditionViewModel(m, fields, defaultEntityType) {
  let vm = _.cloneDeep(m);

  // If entity type is not set, default it to the report's entity type
  if (!vm.entityType || vm.entityType === null) {
    vm.entityType = defaultEntityType;
  }

  const fieldDisplayName =
    vm.fieldDisplayName && _.trim(vm.fieldDisplayName) !== ""
      ? vm.fieldDisplayName
      : "";

  // Use field display name (alias provided by user on Select Field dialog) for label if we have one.
  if (!fields) vm.fieldLabel = "";
  else {
    const fieldLabel =
      _.trim(fieldDisplayName) !== ""
        ? fieldDisplayName
        : fields.find((field) => field.value === vm.fieldId)?.label;
    vm.originalFieldLabel = fieldLabel;
    vm.fieldLabel = getFieldNameWithEntityType(vm.entityType, fieldLabel);
  }

  // Unique id is used as value in fields dropdown and combines fieldId for normal fields and display name for select fields.
  vm.fieldUniqueId = vm.fieldId + "#" + fieldDisplayName;

  return vm;
}

export function createOrderGroupByViewModel(m, defaultEntityType) {
  let vm = _.cloneDeep(m);

  // If entity type is not set, default it to the report's entity type
  if (!vm.entityType || vm.entityType === null) {
    vm.entityType = defaultEntityType;
  }

  return vm;
}
