import React, { useState } from "react";
import styled from "styled-components";
import { useTestCoverageTracking } from "../../../contexts/TestCoverageTrackingContext";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
} from "../layout/CommonStyledControls";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { formatDecimal } from "../../../services/General";
import CoverageTrackingRecord from "./CoverageTrackingRecord";
import InstantSearchInput from "../input/InstantSearchInput";
import ToggleSwitchInput from "../input/ToggleSwitchInput";
import HelpLink from "./HelpLink";
import ResponsiveGrid from "../layout/ResponsiveGrid";

function CoverageTrackingList() {
  const { setUserPrefs } = useUserPreferences();
  const { testCoverageTrackingData, setTestCoverageTrackingData } =
    useTestCoverageTracking();
  const [hideDetails, setHideDetails] = useState(true);

  const records = testCoverageTrackingData.records || [];

  function toggleCoverageTrackingVisibility(show) {
    setUserPrefs({
      type: ContextProviderActions.setCoverageTrackingVisibility,
      payload: show,
    });
  }

  function clearCoverageTrackingResults() {
    setTestCoverageTrackingData({
      type: ContextProviderActions.clearTestCoverageTracking,
      payload: null,
    });
  }

  function setSearchChanges(search) {
    setTestCoverageTrackingData({
      type: ContextProviderActions.saveTestCoverageTrackingSearch,
      payload: search,
    });
  }

  function handleRowClick(e, id) {
    if (e) e.preventDefault();

    setTestCoverageTrackingData({
      type: ContextProviderActions.setTestCoverageTrackingSelectedRecord,
      payload: records.find((r) => r.id === id),
    });
  }

  function setSearchText(value) {
    setTestCoverageTrackingData({
      type: ContextProviderActions.saveTestCoverageTrackingSearch,
      payload: { ...testCoverageTrackingData.search, highlightText: value },
    });
  }

  function setGroupMode(value) {
    setTestCoverageTrackingData({
      type: ContextProviderActions.saveTestCoverageTrackingSearch,
      payload: { ...testCoverageTrackingData.search, groupMode: value },
    });
  }

  return (
    <StyledContainer>
      <StyledHeaderRowDiv>
        <h1>
          Test Coverage
          <HelpLink path="/General/Coverage-Tracking" />
        </h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-primary btn-with-icon"
            style={{ minWidth: "86px" }}
            onClick={(e) => {
              e.preventDefault();
              clearCoverageTrackingResults();
            }}
          >
            <span className="material-icons">delete</span>
            Clear
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              toggleCoverageTrackingVisibility(false);
            }}
            style={{ marginLeft: "12px" }}
          >
            Close
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <div className="container-fluid" style={{ marginTop: "5px" }}>
        {testCoverageTrackingData.selectedRecord !== null ? (
          <CoverageTrackingRecord
            num={
              records.findIndex(
                (r) => r.id === testCoverageTrackingData.selectedRecord.id
              ) + 1
            }
            hideDetails={hideDetails}
            setHideDetails={setHideDetails}
          />
        ) : (
          <>
            <StyledHeaderRowDiv style={{ marginTop: "12px" }}>
              <InstantSearchInput
                id="searchText"
                onChange={setSearchText}
                value={testCoverageTrackingData.search.highlightText}
              />
              <ToggleSwitchInput
                id="groupMode"
                name="groupMode"
                label="Group API calls"
                onChange={(event) => setGroupMode(event.target.checked)}
                checked={testCoverageTrackingData.search.groupMode}
              />
              <ToggleSwitchInput
                id="hideDetails"
                name="hideDetails"
                label="Hide Extra"
                onChange={() => setHideDetails(!hideDetails)}
                checked={hideDetails}
              />
            </StyledHeaderRowDiv>
            <ResponsiveGrid
              gridId="CoverageTracking"
              totalRecords={(records || []).length}
              search={testCoverageTrackingData.search}
              setSearch={setSearchChanges}
              disablePaging={true}
              allowFreeTextSearch={true}
              dataRows={records || []}
              getRowStyle={(row) => {
                return { cursor: "pointer" };
              }}
              onRowClick={(e, row) => handleRowClick(e, row.id)}
              columnDefs={[
                {
                  name: "num",
                  label: "#",
                  disableSort: true,
                  style: { width: "10%" },
                  getValue: (row, index) => (
                    <span
                      style={{
                        fontWeight: row.entryCount > 0 ? "bold" : "normal",
                      }}
                    >
                      {index + 1}
                    </span>
                  ),
                },
                {
                  name: "apiName",
                  label: "API Name",
                  disableSort: true,
                  freeTextSearch: true,
                  style: { width: "40%" },
                  getValue: (row, index, searchResultHtml) => (
                    <span
                      style={{
                        fontWeight: row.entryCount > 0 ? "bold" : "normal",
                      }}
                    >
                      {searchResultHtml}
                    </span>
                  ),
                },
                {
                  name: "numCalls",
                  label: (
                    <>
                      <span className="d-none d-md-table-cell"># of Calls</span>
                      <span className="d-md-none">xN</span>
                    </>
                  ),
                  style: { width: "15%" },
                  getValue: (row) => (
                    <span
                      style={{
                        fontWeight: row.entryCount > 0 ? "bold" : "normal",
                      }}
                    >
                      {row.numCalls}
                    </span>
                  ),
                },
                {
                  name: "entryCount",
                  label: "Entries",
                  className: "d-none d-md-table-cell",
                  style: { width: "15%" },
                  getValue: (row) => (
                    <span
                      style={{
                        fontWeight: row.entryCount > 0 ? "bold" : "normal",
                      }}
                    >
                      {row.entryCount}
                    </span>
                  ),
                },
                {
                  name: "coveragePercent",
                  label: (
                    <>
                      <span className="d-none d-md-table-cell">Coverage</span>
                      <span className="d-md-none">Cover</span>
                    </>
                  ),
                  style: { width: "15%" },
                  getValue: (row) => (
                    <span
                      style={{
                        fontWeight: row.entryCount > 0 ? "bold" : "normal",
                      }}
                    >
                      {hideDetails
                        ? `${
                            row.coveragePercentHideDetails === 0
                              ? 0
                              : formatDecimal(row.coveragePercentHideDetails, 1)
                          }%`
                        : `${
                            row.coveragePercent === 0
                              ? 0
                              : formatDecimal(row.coveragePercent, 1)
                          }%`}
                    </span>
                  ),
                },
                {
                  name: "chevronright",
                  label: "",
                  desktopOnly: true,
                  disableSort: true,
                  style: {
                    width: "60px",
                    maxWidth: "25%",
                    textAlign: "center",
                  },
                  getValue: (row) => (
                    <i
                      className="material-icons"
                      style={{ marginLeft: "auto" }}
                    >
                      chevron_right
                    </i>
                  ),
                },
              ]}
            />
          </>
        )}
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  margin: 10px 0 30px 0;
  padding: 20px;
  background: var(--elevated-bg);
  border: 1px solid var(--pill-blue);
  border-top: none;
  box-shadow: 0px 15px 10px -15px var(--pill-blue);

  @media only screen and (max-width: 767px) {
    margin-left: -5%;
    margin-right: -5%;
  }

  h1 {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
`;

export default CoverageTrackingList;
