import React from "react";
import _ from "lodash";
import {
  formatDateTimeUtcZoneForDisplay,
  getHighlightedText,
} from "../../../services/General";
import { useNavigate } from "react-router-dom";
import StatusPill from "../../common/ui/StatusPill";
import { useClaims } from "../../../contexts/ClaimsContext";

export default function RelatedClaimData({ data }) {
  const navigate = useNavigate();
  const { claimsData } = useClaims();
  const relatedClaims = !_.isEmpty(data) ? data.relatedClaims : [];
  const authNumber = !_.isEmpty(data) ? data.authNumber : "";

  const hl = claimsData.search.highlightText;
  let authNumHlResult = {};
  let processDateHlResult = {};
  let statusHlResult = {};
  let processDateStr = "";

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ width: "40%" }}>
              Auth No.
            </th>
            <th scope="col" style={{ width: "30%" }}>
              Processed
            </th>
            <th scope="col" style={{ width: "30%" }}>
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {relatedClaims.map((claim, idx) => {
            processDateStr = formatDateTimeUtcZoneForDisplay(
              claim.processedDate
            );
            authNumHlResult = getHighlightedText(claim.authorizationNumber, hl);
            processDateHlResult = getHighlightedText(processDateStr, hl);
            statusHlResult = getHighlightedText(claim.status, hl);

            let style = {};
            if (claim.authorizationNumber === authNumber)
              style = {
                ...style,
                backgroundColor: "var(--table-hover-row-bg)",
                borderLeft: "4px solid var(--table-hover-row-border)",
              };
            return _.trim(hl).length > 0 &&
              authNumHlResult.count === 0 &&
              processDateHlResult.count === 0 &&
              statusHlResult.count === 0 ? (
              <React.Fragment key={claim.id}></React.Fragment>
            ) : (
              <React.Fragment key={claim.id}>
                <tr key={claim.id} style={style}>
                  <td className="force-wrap">
                    {claim.authorizationNumber !== authNumber ? (
                      <button
                        className="btn btn-link link-underline"
                        onClick={() => {
                          navigate("/claim/" + claim.id);
                        }}
                      >
                        {authNumHlResult.html}
                      </button>
                    ) : (
                      <>{authNumHlResult.html}</>
                    )}
                  </td>
                  <td>{processDateHlResult.html}</td>
                  <td>
                    {statusHlResult.count > 0 ? (
                      statusHlResult.html
                    ) : (
                      <StatusPill status={claim.status} />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
