import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyDiscount, emptyDiscountSearch } from "../viewmodels/discountsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadDiscounts:
      return {
        ...state,
        discounts: action.payload.discounts,
        discountRedemptions: action.payload.discountRedemptions,
        countDiscounts: action.payload.countDiscounts,
        countDiscountRedemptions: action.payload.countDiscountRedemptions,
        search: action.payload.search,
      };

    case ContextProviderActions.saveDiscountSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadDiscount:
      return {
        ...state,
        discount: action.payload.discount,
        discountRedemptions: action.payload.discountRedemptions,
        countDiscountRedemptions: action.payload.countDiscountRedemptions,
      };

    case ContextProviderActions.loadDiscountRedemptions:
      return {
        ...state,
        discountRedemptions: action.payload.discountRedemptions,
        countDiscountRedemptions: action.payload.countDiscountRedemptions,
      };

    default:
      throw new Error(
        `Unhandled action type in DiscountsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  discounts: [],
  discountRedemptions: [],
  discount: emptyDiscount,
  search: emptyDiscountSearch,
  countDiscounts: 0,
  countDiscountRedemptions: 0,
};

const Discounts = createContext({
  discountsData: initialState,
  setDiscountsData: () => null,
});

// eslint-disable-next-line react/prop-types
const DiscountsProvider = ({ children }) => {
  const [discountsData, setDiscountsData] = useReducer(reducer, initialState);

  return (
    <Discounts.Provider value={{ discountsData, setDiscountsData }}>
      {children}
    </Discounts.Provider>
  );
};

export default DiscountsProvider;
export const useDiscounts = () => useContext(Discounts);
