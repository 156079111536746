import React from "react";
import { formatDateTimeUtcForDisplay } from "../../../services/General";
import { useNavigate } from "react-router-dom";
import { getLogLevelRowStyle } from "../../../viewmodels/logsVm";
import LogLevelPill from "./LogLevelPill";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function LogHeadersList({
  logHeaders,
  search,
  setSearch,
  onSort,
  totalRecords,
}) {
  const navigate = useNavigate();

  function getActions(dataRow) {
    const objectId = dataRow.objectId;

    const actions = [
      {
        name: "View Details",
        onClick: () => navigate("/log/" + objectId),
      },
    ];

    return actions;
  }

  function getTruncatedId(oid) {
    let id = `${oid}`;
    const len = id.length;
    if (len > 6) {
      id = "..." + id.substring(len - 6);
    }
    return id;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="LogHeaders"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        getRowStyle={(row) => {
          return {
            borderLeft: getLogLevelRowStyle(row.logType),
          };
        }}
        dataRows={logHeaders || []}
        columnDefs={[
          {
            name: "objectId",
            label: "Trace Id",
            style: { width: "35%", maxWidth: "45%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/log/" + row.objectId)}
              >
                <span className="force-wrap d-none d-md-table-cell">
                  {row.objectId}
                </span>
                <span className="force-wrap d-table-cell d-md-none">
                  {getTruncatedId(row.objectId)}
                </span>
              </button>
            ),
          },
          {
            name: "message",
            label: "Header",
            style: { width: "30%", maxWidth: "40%" },
          },
          {
            name: "logType",
            label: "Type",
            className: "d-none d-md-table-cell",
            style: { width: "20%" },
            getValue: (row) => (
              <LogLevelPill level={row.logType}></LogLevelPill>
            ),
          },
          {
            name: "environment",
            label: "Env",
            className: search.showAdvancedFilter
              ? "pointer force-wrap d-none d-xl-table-cell"
              : "pointer force-wrap d-none d-lg-table-cell",
            style: { width: "20%" },
          },
          {
            name: "userName",
            label: "User",
            className: search.showAdvancedFilter
              ? "pointer force-wrap d-none d-xl-table-cell"
              : "pointer force-wrap d-none d-lg-table-cell",
            style: { width: "20%" },
          },
          {
            name: "ticks",
            label: "Date",
            style: { width: "20%" },
            getValue: (row) => formatDateTimeUtcForDisplay(row.entryDate),
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default LogHeadersList;
