import React from "react";
import styled from "styled-components";

function LogLevelPill({ level }) {
  let pill = <StyledLogLevelPill>{level}</StyledLogLevelPill>;
  if (level === "Error" || level === "Fatal")
    pill = (
      <StyledLogLevelPill style={{ backgroundColor: "var(--notify-danger)" }}>
        {level}
      </StyledLogLevelPill>
    );
  else if (level === "Debug")
    pill = (
      <StyledLogLevelPill style={{ backgroundColor: "var(--notify-success)" }}>
        {level}
      </StyledLogLevelPill>
    );
  else if (level === "Info")
    pill = (
      <StyledLogLevelPill style={{ backgroundColor: "var(--notify-info)" }}>
        {level}
      </StyledLogLevelPill>
    );
  else if (level === "Warn")
    pill = (
      <StyledLogLevelPill style={{ backgroundColor: "var(--notify-warning)" }}>
        {level}
      </StyledLogLevelPill>
    );

  return pill;
}

const StyledLogLevelPill = styled.span`
  height: 24px;
  border-radius: 12px;
  padding: 3px 12px;
  font-size: 15px;
  line-height: 20px;
  color: var(--pill-text);
  background-color: var(--notify-neutral);
`;

export default LogLevelPill;
