import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import {
  StyledCard,
  StyledCardLogList,
  StyledCardValue,
  StyledDescription,
  StyledNodeDiv,
  StyledProcessedIndicator,
} from "./RuleMapCommonStyles";
import RuleMapZoomedOutNode from "./RuleMapZoomedOutNode";
import RuleMapLogsLink from "./RuleMapLogsLink";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import { getBadgeColorsFromType, getLogColors } from "./RuleMapDataCommon";
import RuleMapCopyContentsLink from "./RuleMapCopyContentsLink";

export const DefaultCodeArtifactNodeSize = { width: 500, height: 50 };

function RuleMapCodeArtifactNode({ data }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const [detailLevel] = useState(1);
  const { ruleMapsData } = useRuleMaps();
  const [nodeWidth] = useState(DefaultCodeArtifactNodeSize.width);
  const [nodeHeight] = useState(DefaultCodeArtifactNodeSize.height);
  const divRef = useRef(null);

  useEffect(() => {
    let doNodeUpdate = false;
    if (data.layoutDirection !== ruleMapsData?.layoutDirection) {
      doNodeUpdate = true;
    }
    if (detailLevel !== ruleMapsData?.detailLevel) {
      doNodeUpdate = true;
    }

    if (doNodeUpdate) {
      updateNodeInternals(data.id);
    }
  }, [
    ruleMapsData?.detailLevel,
    data.layoutDirection,
    ruleMapsData?.layoutDirection,
  ]);

  const colors = getBadgeColorsFromType(
    data.type,
    data.isActualNodeLink,
    data.isProcessed
  );

  const showDetails =
    ruleMapsData?.detailLevel === 3 || ruleMapsData?.selectedNodeId === data.id;

  if (ruleMapsData?.detailLevel === 1 && !showDetails) {
    return <RuleMapZoomedOutNode data={data} />;
  }

  const runValues = data.runValues || [];
  const logCount = runValues.length;

  return (
    <StyledNodeDiv width={nodeWidth} height={nodeHeight}>
      {!data.isLeafNode && (
        <Handle
          type="target"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Left
              : Position.Top
          }
        />
      )}
      <StyledCard colors={colors} ref={divRef}>
        {!data.isActualNodeLink && data.isProcessed && (
          <StyledProcessedIndicator>
            <span className="material-symbols-outlined">electric_bolt</span>
          </StyledProcessedIndicator>
        )}
        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={colors}
        >
          <span>
            <RuleMapCopyContentsLink
              show={showDetails}
              text={divRef.current?.innerText}
            />
            {showDetails ? data.displayKey : data.itemKey}
          </span>
          <span className="charblock">ARTIFACT</span>
        </StyledDescription>
        {showDetails && data.module && data.module !== null && (
          <StyledDescription
            className="flex-row-with-wrap-and-justify"
            colors={colors}
            style={{ fontSize: "13px" }}
          >
            Module: {data.module.name}
          </StyledDescription>
        )}
        <StyledCardValue>
          {`${logCount} run ${logCount === 1 ? "value" : "values"}`}
        </StyledCardValue>

        {showDetails && logCount > 0 && (
          <>
            <StyledCardLogList>
              {runValues.map((n, idx) => (
                <li
                  key={`log-${idx}`}
                  style={{
                    borderLeft: "2px solid var(--text-dark)",
                    paddingLeft: "8px",
                    marginBottom: "5px",
                  }}
                >
                  <StyledDescription
                    colors={getLogColors()}
                    style={{
                      columnGap: "10px",
                      marginBottom: "5px",
                      display: "block",
                    }}
                  >
                    <span className="full-width">{n.key}</span>

                    {_.isArray(n.value) ? (
                      (n.value || []).length < 1 ? (
                        <span className="full-width value">
                          <i>No value set</i>
                        </span>
                      ) : (
                        <>
                          {(n.value || []).map((v, vidx) => (
                            <span
                              key={`logval-${vidx}`}
                              className="full-width value"
                            >
                              {`${v}`}
                            </span>
                          ))}
                        </>
                      )
                    ) : n.value !== 0 && _.isEmpty(n.value) ? (
                      <span className="full-width value">
                        <i>No value set</i>
                      </span>
                    ) : (
                      <span className="full-width value">{`${n.value}`}</span>
                    )}
                  </StyledDescription>
                </li>
              ))}
            </StyledCardLogList>
          </>
        )}
        <RuleMapLogsLink logs={data.logs} title={data.itemKey} />
      </StyledCard>
      {!data.isRootNode && (
        <Handle
          type="source"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Right
              : Position.Bottom
          }
        />
      )}
    </StyledNodeDiv>
  );
}

export default RuleMapCodeArtifactNode;
