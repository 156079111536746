import React from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function RulesList({
  rules,
  search,
  setSearch,
  totalRecords,
  onDelete,
  onCopy,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const description = dataRow.description;

    const actions = [
      {
        name: "View Details",
        onClick: () => navigate("/rule/" + id),
      },
      {
        name: "Duplicate",
        onClick: () => onCopy(id),
      },
      {
        name: "Remove",
        onClick: () => onDelete(id, description),
      },
    ];

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/processingrule/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="Rules"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        dataRows={rules}
        allowFreeTextSearch={true}
        enableClientRowPager={true}
        columnDefs={[
          {
            name: "description",
            label: "Rule Description",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "60%" },
            getValue: (row, index, searchResultHtml) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/rule/" + row.id)}
              >
                {searchResultHtml}
              </button>
            ),
          },
          {
            name: "client",
            label: "Client",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "25%" },
            getValue: (row, index, searchResultHtml) => searchResultHtml,
          },
          {
            name: "groups",
            label: "Groups",
            className: "d-none d-md-table-cell",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "35%" },
            getValue: (row, index, searchResultHtml) => {
              return (
                <>
                  {searchResultHtml}
                  {!_.isEmpty(row.notGroups) ? "(- " + row.notGroups + ")" : ""}
                </>
              );
            },
          },
          {
            name: "artifactTags",
            label: "Tags",
            className: "d-none d-xl-table-cell",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "25%" },
            getValue: (row, index, searchResultHtml) => searchResultHtml,
          },
          {
            name: "artifacts",
            label: "Artifacts",
            className: "d-none d-xl-table-cell",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "15%" },
            getValue: (row, index, searchResultHtml) => {
              return (
                <>
                  {searchResultHtml}
                  {!_.isEmpty(row.notArtifacts)
                    ? "(- " + row.notArtifacts + ")"
                    : ""}
                </>
              );
            },
          },
          {
            name: "stagingOnly",
            label: "Staging Only",
            className: "d-none d-xl-table-cell",
            disableSort: true,
            style: { width: "15%" },
            getValue: (row) =>
              row.stagingOnly ? (
                <span className="material-icons">check</span>
              ) : (
                ""
              ),
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default RulesList;
