import React from "react";
import { useMobile } from "../../../hooks/useMobile";
import styled from "styled-components";
import { NodeTypes } from "../rulemaps/RuleMapDataCommon";

function GroupRuleMapFilterForm({
  search,
  onSearch,
  onReset,
  responseFields,
  rejectionFields,
  onFilterItemClick,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <StyledFilterList>
            {responseFields.map((r, idx) => (
              <li
                key={`rs-${idx}`}
                onClick={() =>
                  onFilterItemClick(r.value, NodeTypes.ResponseFieldValue)
                }
              >
                {r.label}
              </li>
            ))}
          </StyledFilterList>
          <StyledFilterList>
            {rejectionFields.map((r, idx) => (
              <li
                key={`rj-${idx}`}
                onClick={() => onFilterItemClick(r.value, NodeTypes.Rejection)}
              >
                {r.label}
              </li>
            ))}
          </StyledFilterList>
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

const StyledFilterList = styled.ul`
  margin-top: 20px;

  li {
    list-style-type: none;
    cursor: pointer;
    margin-bottom: 2px;
  }
`;

export default GroupRuleMapFilterForm;
