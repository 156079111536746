import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useReports } from "../../../contexts/ReportsContext";
import { useReportTemplates } from "../../../contexts/ReportTemplatesContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";

import {
  emptyReportTemplate,
  createViewModel,
  fromViewModel,
} from "../../../viewmodels/reportTemplatesVm";

import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import {
  apiLoadReportTemplate,
  apiAddReportTemplate,
  apiUpdateReportTemplate,
} from "../../../api/ReportTemplatesApi";
import { apiLoadDataSourcesAll } from "../../../api/ReportsApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Spinner from "../../common/ui/Spinner";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import SelectInput from "../../common/input/SingleSelect";
import TextInput from "../../common/input/TextInput";
import {
  notifySuccess,
  notifyWarn,
  notifyError,
} from "../../../services/NotificationService";
import { handleCollapseExpandAll } from "../../../services/General";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import { apiLoadRuleTableDefinitionsForTemplate } from "../../../api/RuleTableDefinitionApi";
import HelpLink from "../../common/ui/HelpLink";
import RuleEditorInput from "../../common/input/RuleEditorInput";
import useReportTemplateParser from "../../../hooks/useReportTemplateParser";

function ReportTemplate() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { reportTemplatesData, setReportTemplatesData } = useReportTemplates();
  const { reportsData, setReportsData } = useReports();
  const { loading, api: apiLoad } = useApi(apiLoadReportTemplate);
  const { loading: loadingDataSources, api: apiLoadDataSources } = useApi(
    apiLoadDataSourcesAll
  );
  const { loading: loadingRuleTableDefs, api: apiLoadRuleTableDefinitions } =
    useApi(apiLoadRuleTableDefinitionsForTemplate);
  const { loading: addingTemplate, api: apiAdd } = useApi(apiAddReportTemplate);
  const { loading: updatingTemplate, api: apiUpdate } = useApi(
    apiUpdateReportTemplate
  );
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyReportTemplate);
  const { parseTemplateTextContentIntoHtml } = useReportTemplateParser();

  const [collapsedState, setCollapsedState] = useState([
    { name: "Report Template", collapsed: false },
    { name: "Template Data", collapsed: false },
    { name: "Template Contents", collapsed: false },
  ]);

  const reportTemplate =
    (reportTemplatesData && reportTemplatesData.template) || {};
  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      loadReportTemplateAndDataSourceList();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (reportTemplatesData.template) {
      setChanges(reportTemplatesData.template);
    } else {
      setChanges(emptyReportTemplate);
    }
  }, [reportTemplatesData.template]);

  async function loadReportTemplateAndDataSourceList() {
    let dataSourceList = reportsData.sourceListCache || [];

    if ((dataSourceList || []).length === 0) {
      dataSourceList = await loadDataSourceList();

      // Save the list of data sources in the cache
      setReportsData({
        type: ContextProviderActions.setReportDataSourcesCache,
        payload: dataSourceList,
      });
    }

    // Always load rule table defs
    const ruleTableDefinitionList = await loadRuleTableDefinitionList();

    await loadReportTemplate(dataSourceList, ruleTableDefinitionList);
  }

  async function loadRuleTableDefinitionList() {
    const ruleTableDefinitionList = await apiLoadRuleTableDefinitions.call(
      null,
      async (result) => {
        return result.resources.map((d) => {
          return { value: d.id, label: d.name };
        });
      }
    );

    setReportTemplatesData({
      type: ContextProviderActions.loadRuleTableDefinitionList,
      payload: ruleTableDefinitionList || [],
    });

    return ruleTableDefinitionList || [];
  }

  async function loadDataSourceList() {
    const dataSourceList = await apiLoadDataSources.call(
      null,
      async (result) => {
        let dsResult = [...result] || [];

        // Filter out admin data sources for non-admins
        if (!auth.isAdmin) {
          dsResult = dsResult.filter(
            (c) => c.reportableItemAllowableRole !== "Admin"
          );
        }

        const dsList = (dsResult || [])
          .map((c) => {
            return { value: c.id, label: c.label };
          })
          .sort((a, b) => (a.label > b.label ? 1 : -1));

        return dsList;
      }
    );

    return dataSourceList || [];
  }

  async function loadReportTemplate(dataSourceList, ruleTableDefinitionList) {
    if (!resId) {
      const vm = createViewModel(
        emptyReportTemplate,
        dataSourceList,
        ruleTableDefinitionList
      );
      setReportTemplatesData({
        type: ContextProviderActions.loadReportTemplate,
        payload: vm,
      });
      setChanges(vm);
      return;
    }

    apiLoad.call(resId, async (result) => {
      await postLoadReportTemplateAndDataSources(
        result,
        dataSourceList,
        ruleTableDefinitionList
      );
    });
  }

  async function postLoadReportTemplateAndDataSources(
    reportTemplateResult,
    dataSourceList,
    ruleTableDefinitionList
  ) {
    // Report viewmodel needs the datasource list
    let vm = emptyReportTemplate;
    if (!reportTemplateResult) {
      notifyError("Template does not exist");
    } else {
      vm = createViewModel(
        reportTemplateResult,
        dataSourceList,
        ruleTableDefinitionList
      );
    }

    setReportTemplatesData({
      type: ContextProviderActions.loadReportTemplate,
      payload: vm,
    });
    setChanges(vm);
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleDataSourceChange(option) {
    setChanges({
      ...changes,
      dataSourceVm: option,
    });
  }

  function handleRuleTableDefinitionChange(option) {
    setChanges({
      ...changes,
      externalDataRuleTableDefinitionVm: option,
    });
  }

  function clearTemplateListCache() {
    // Copy, delete, and save operations invalidate the cache.
    setReportTemplatesData({
      type: ContextProviderActions.setReportTemplateCache,
      payload: [],
    });
  }

  function formIsValid() {
    const _errors = {};

    if (_.trim(changes.name) === "") _errors.name = "Name must be entered";
    if (_.trim(changes.fileNameTemplate) === "")
      _errors.fileNameTemplate = "File Name Template must be entered";
    if (_.trim(changes.templateContents) === "")
      _errors.templateContents = "Template Contents must be entered";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    const newVm = { ...changes };

    if (!resId) {
      addReportTemplate(newVm);
    } else {
      updateReportTemplate(newVm.id, newVm);
    }
  }

  async function addReportTemplate(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      setChanges(
        createViewModel(
          result,
          reportsData.sourceListCache,
          reportTemplatesData.ruleTableDefinitionList
        )
      );
      clearTemplateListCache();
      notifySuccess("Template '" + vm.name + "' saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/reporttemplate/" + result.id, { replace: true });
    });
  }

  async function updateReportTemplate(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      const newVm = { ...model, ...result };
      setChanges(
        createViewModel(
          newVm,
          reportsData.sourceListCache,
          reportTemplatesData.ruleTableDefinitionList
        )
      );
      clearTemplateListCache();
      notifySuccess("Template '" + vm.name + "' saved successfully");
    });
  }

  const isLoadingStuff =
    loading ||
    loadingDataSources ||
    loadingRuleTableDefs ||
    addingTemplate ||
    updatingTemplate;

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            title="Return to previous screen"
            type="button"
            className="btn btn-link"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Reports/Report-Template-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            {resId ? "Edit Report Template" : "Create Report Template"}{" "}
            {resId ? ` | ${reportTemplate.name}` : ""}
          </h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {isLoadingStuff ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin && typeof resId !== "undefined",
                  url: `/auditrecord/reporttemplate/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Report Template"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Reports/Report-Template-Screen&anchor=report-template-section"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-6 col-lg-4">
                    <TextInput
                      id="name"
                      label="Name"
                      onChange={handleChange}
                      placeholder="Name"
                      name="name"
                      value={changes.name || ""}
                      error={errors.name}
                      autoFocus={true}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <SelectInput
                      id="entityType"
                      name="entityType"
                      label="Data Source"
                      options={[...reportsData.sourceListCache]}
                      value={changes.dataSourceVm}
                      onChange={handleDataSourceChange}
                      placeholder=""
                      error={errors.entityType}
                      controlStyle={{ width: "250px" }}
                    />
                  </div>
                </StyledRowDiv>
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6">
                    <TextInput
                      id="fileNameTemplate"
                      label="File Name Template"
                      onChange={handleChange}
                      placeholder="File Name Template"
                      name="fileNameTemplate"
                      value={changes.fileNameTemplate || ""}
                      error={errors.fileNameTemplate}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Template Data"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Reports/Report-Template-Screen&anchor=template-data-section"
              >
                <StyledRowDiv className="row">
                  <p>
                    Select a Rule Table Definition to provide data to the
                    template. The report where this template is used will run
                    once for each row in the rule table selected on the report.
                  </p>
                </StyledRowDiv>
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <SelectInput
                      id="externalDataRuleTableDefinitionId"
                      name="externalDataRuleTableDefinitionId"
                      label="Rule Table Definition"
                      options={[...reportTemplatesData.ruleTableDefinitionList]}
                      value={changes.externalDataRuleTableDefinitionVm}
                      onChange={handleRuleTableDefinitionChange}
                      placeholder=""
                      error={errors.externalDataRuleTableDefinitionId}
                    />
                  </div>
                </StyledRowDiv>
                <StyledRowDiv className="row" style={{ marginTop: "30px" }}>
                  <p>
                    To save data between runs of this template so previous
                    values can be referenced by the template, enter a
                    comma-separated list of field names below.
                  </p>
                </StyledRowDiv>
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-6 col-lg-4">
                    <TextInput
                      id="runValuesToSave"
                      label="Last Run Values to Save"
                      onChange={handleChange}
                      placeholder="Last Run Values to Save"
                      name="runValuesToSave"
                      value={changes.runValuesToSave || ""}
                      error={errors.runValuesToSave}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Template Contents"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Reports/Report-Template-Screen&anchor=template-contents-section"
              >
                <div
                  style={{
                    width: "250px",
                  }}
                >
                  <a
                    title="Handlebars help"
                    rel="noreferrer"
                    href="https://handlebarsjs.com/guide/"
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      open_in_new
                    </span>
                    <span style={{ paddingLeft: "5px" }}>
                      Open Handlebars template help
                    </span>
                  </a>
                </div>
                <RuleEditorInput
                  id="templateContents"
                  label=""
                  labelStyle={{ display: "none" }}
                  onChange={handleChange}
                  placeholder="Enter template contents here"
                  name="templateContents"
                  value={changes.templateContents}
                  inputStyle={{ fontFamily: "courier", height: "400px" }}
                  error={errors.templateContents}
                  defaultHeight="400px"
                  parserFunction={parseTemplateTextContentIntoHtml}
                />
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default ReportTemplate;
