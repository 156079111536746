import _ from "lodash";
import { genders, states } from "../services/General";
import { parseISO } from "date-fns";
import { pageSize } from "../constants/Environments";

export const emptyContact = {
  title: "",
  suffix: "",
  subtitle: "",
  priority: 0,
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  genderVm: "",
  emailAddresses: [],
  emailAddressesVm: "",
};

export const emptyMember = {
  effectiveDate: new Date("1/1/2000"),
  terminationDate: new Date("1/1/3000"),
  memberId: "",
  dateOfBirth: "",
  personCode: "1",
  relationshipCode: "",
  eligibilityCode: "",
  groupId: { value: "", label: "" },
  groupIdVm: "",
  group: "",
  options: {
    emailAddressSent: false,
    billableEmailAddressFeePaid: false,
    optedIntoPlan: false,
  },
  emailAddressSent: false,
  billableEmailAddressFeePaid: false,
  optedIntoPlan: false,
  addresses: [],
  phoneNumbers: [],
  memberInfo: emptyContact,
  address: "",
  city: "",
  state: "",
  zipCode: "",
  number: "",
};

export const emptyMemberSearch = {
  id: "",
  memberId: "",
  groupId: "",
  lastName: "",
  firstName: "",
  address: "",
  city: "",
  state: "",
  active: true,
  orderBy: ["lastModifiedDate-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  membersCount: 0,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  if (vm.terminationDate > new Date("1/1/2999")) vm.terminationDate = null;
  vm.state = (vm.stateVm || { value: "" }).value || "";
  vm.groupId = (vm.groupIdVm || { value: "" }).value;
  vm.memberInfo.gender =
    (vm.memberInfo.genderVm || { value: "Unspecified" }).value || "";

  vm.memberInfo.emailAddresses = vm.memberInfo.emailAddressesVm
    .split(",")
    .map((m) => {
      return { emailAddress: (m || "").toUpperCase() };
    });

  vm.addresses = [];
  if (vm.address) {
    vm.addresses.push({
      addressType: "Physical",
      address1: (vm.address || "").toUpperCase(),
      city: (vm.city || "").toUpperCase(),
      state: (vm.state || "None").toUpperCase(),
      zipCode: (vm.zipCode || "").toUpperCase(),
    });
  }

  vm.phoneNumbers = [];
  if (vm.number) {
    vm.phoneNumbers.push({
      phoneType: "Phone",
      number: (vm.number || "").toUpperCase(),
    });
  }

  vm.options.emailAddressSent = vm.emailAddressSent;
  vm.options.billableEmailAddressFeePaid = vm.billableEmailAddressFeePaid;
  vm.options.optedIntoPlan = vm.optedIntoPlan;

  let m = vm;
  return m;
}

export function createViewModel(m, groupList) {
  let vm = _.cloneDeep(m);
  if (_.isNull(vm.effectiveDate)) {
    vm.effectiveDate = emptyMember.effectiveDate;
  } else {
    vm.effectiveDate = parseISO(vm.effectiveDate);
  }
  if (_.isNull(vm.terminationDate))
    vm.terminationDate = emptyMember.terminationDate;
  else {
    vm.terminationDate = parseISO(vm.terminationDate);
  }
  vm.memberInfo.genderVm = genders.find(
    (d) => d.value === vm.memberInfo.gender
  ) || {
    label: "Unspecified",
    value: "Unspecified",
  };

  vm.memberInfo.emailAddressesVm =
    vm.memberInfo.emailAddresses.map((m) => m.emailAddress).join(",") || "";
  if (vm.addresses.length > 0) {
    vm.address = vm.addresses[0].address1 || "";
    vm.city = vm.addresses[0].city || "";
    vm.state = vm.addresses[0].state || "";
    vm.zipCode = vm.addresses[0].zipCode || "";
    vm.stateVm = states.find((d) => d.value === vm.state) || {
      value: "",
      label: "",
    };
  } else {
    vm.address = "";
    vm.city = "";
    vm.state = "";
    vm.zipCode = "";
  }
  if (vm.phoneNumbers.length > 0) {
    vm.number = vm.phoneNumbers[0].number || "";
  } else {
    vm.number = "";
  }

  vm.emailAddressSent = vm.options.emailAddressSent;
  vm.billableEmailAddressFeePaid = vm.options.billableEmailAddressFeePaid;
  vm.optedIntoPlan = vm.options.optedIntoPlan;
  vm.groupIdVm =
    (groupList || []).find((c) => c.value === vm.groupId) ||
    emptyMemberSearch.groupId;
  vm.group = vm.groupIdVm.label;

  if (vm.personCode === 0) vm.personCode = "1";
  return vm;
}
