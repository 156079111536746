import React, { useEffect } from "react";
import { useWebSocketConnectionEvents } from "../../../contexts/WebSocketEventsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useClaims } from "../../../contexts/ClaimsContext";

function HomeWebSocketHubConnections() {
  const { setClaimsData } = useClaims();
  const { webSocketConnectionData } = useWebSocketConnectionEvents();

  useEffect(() => {
    const vm = webSocketConnectionData.trafficClaims;
    if (vm !== null) {
      const jsonTraffic = vm;
      setClaimsData({
        type: ContextProviderActions.loadClaimsTraffic,
        payload: jsonTraffic,
      });
    }
  }, [webSocketConnectionData?.trafficClaims]);

  return <></>;
}

export default HomeWebSocketHubConnections;
