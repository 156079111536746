import React from "react";
import CheckboxInput from "../../common/input/CheckboxInput";

function HomePacketsFilter({
  traffic,
  filters,
  isLoading,
  onFilterCheckboxChange,
  selectAll,
  onSelectAll,
  hoveredFilter,
  setHoveredFilter,
}) {
  const connections = traffic.connections || [];
  const consumers = traffic.consumers || [];
  const recognizers = traffic.recognizers || [];

  let now = new Date();
  let receiveDate;
  let elapsedTime;

  if (isLoading) return <></>;

  return (
    <>
      <form>
        <div className="filter-search-form">
          <div
            className="flex-row-without-wrap"
            style={{ marginTop: "10px", fontWeight: "bold" }}
          >
            <input
              id={"selectAllConnections"}
              type="checkbox"
              name={"selectAllConnections"}
              className="form-check-input"
              placeholder={""}
              onChange={({ target }) => onSelectAll("connections", target)}
              value={selectAll.connections}
              checked={selectAll.connections}
              disabled={false}
            ></input>
            <label
              htmlFor={"selectAllConnections"}
              style={{ marginLeft: "10px" }}
            >
              Connections
            </label>
          </div>
          <hr className="thinhr" />
          {connections.length === 0 ? (
            <div
              className="flex-row-with-wrap"
              style={{
                color: "var(--notify-danger)",
              }}
            >
              <i className="material-symbols-outlined">warning</i>
              <span>&nbsp;&nbsp; No Connections!</span>
            </div>
          ) : (
            connections.map((connection) => {
              receiveDate = new Date(connection.lastTraffic ?? "1/1/2000");
              elapsedTime = Math.abs(now - receiveDate) / 1000;

              return (
                <CheckboxInput
                  key={connection.id}
                  id={`connection-${connection.id}`}
                  label={connection.name}
                  labelStyle={{
                    fontSize: "14px",
                    fontWeight:
                      hoveredFilter === connection.name ? "bold" : "normal",
                    color:
                      hoveredFilter === connection.name
                        ? "var(--button-primary-active-bg)"
                        : "inherit",
                  }}
                  onChange={({ target }) =>
                    onFilterCheckboxChange("connections", target)
                  }
                  onHover={(name) => setHoveredFilter(name)}
                  name={`${connection.id}`}
                  checked={
                    (filters.connections || []).find(
                      (c) => c.id === connection.id
                    )?.checked
                  }
                  showLabelInline={true}
                  icon={
                    elapsedTime < 6 ? (
                      <i
                        className="material-symbols-outlined pulse-fade"
                        style={{ marginLeft: "5px" }}
                      >
                        sms
                      </i>
                    ) : null
                  }
                />
              );
            })
          )}

          <div
            className="flex-row-without-wrap"
            style={{ marginTop: "30px", fontWeight: "bold" }}
          >
            <input
              id={"selectAllRecognizers"}
              type="checkbox"
              name={"selectAllRecognizers"}
              className="form-check-input"
              placeholder={""}
              onChange={({ target }) => onSelectAll("recognizers", target)}
              value={selectAll.recognizers}
              checked={selectAll.recognizers}
              disabled={false}
            ></input>
            <label
              htmlFor={"selectAllRecognizers"}
              style={{ marginLeft: "10px" }}
            >
              Recognizers
            </label>
          </div>
          <hr className="thinhr" />
          {recognizers.length === 0 ? (
            <i>No recognizers found</i>
          ) : (
            recognizers.map((recognizer) => (
              <CheckboxInput
                key={recognizer.id}
                id={`recognizer-${recognizer.id}`}
                label={recognizer.name}
                labelStyle={{
                  fontSize: "14px",
                  fontWeight:
                    hoveredFilter === recognizer.name ? "bold" : "normal",
                  color:
                    hoveredFilter === recognizer.name
                      ? "var(--button-primary-active-bg)"
                      : "inherit",
                }}
                onChange={({ target }) =>
                  onFilterCheckboxChange("recognizers", target)
                }
                onHover={(name) => setHoveredFilter(name)}
                name={`${recognizer.id}`}
                checked={
                  (filters.recognizers || []).find(
                    (c) => c.id === recognizer.id
                  )?.checked
                }
                showLabelInline={true}
              />
            ))
          )}

          <div
            className="flex-row-without-wrap"
            style={{ marginTop: "30px", fontWeight: "bold" }}
          >
            <input
              id={"selectAllConsumers"}
              type="checkbox"
              name={"selectAllConsumers"}
              className="form-check-input"
              placeholder={""}
              onChange={({ target }) => onSelectAll("consumers", target)}
              value={selectAll.consumers}
              checked={selectAll.consumers}
              disabled={false}
            ></input>
            <label
              htmlFor={"selectAllConsumers"}
              style={{ marginLeft: "10px" }}
            >
              Consumers
            </label>
          </div>
          <hr className="thinhr" />
          {consumers.length === 0 ? (
            <i>No consumers found</i>
          ) : (
            consumers.map((consumer) => (
              <CheckboxInput
                key={consumer.id}
                id={`consumer-${consumer.id}`}
                label={consumer.name}
                labelStyle={{
                  fontSize: "14px",
                  fontWeight:
                    hoveredFilter === consumer.name ? "bold" : "normal",
                  color:
                    hoveredFilter === consumer.name
                      ? "var(--button-primary-active-bg)"
                      : "inherit",
                }}
                onChange={({ target }) =>
                  onFilterCheckboxChange("consumers", target)
                }
                onHover={(name) => setHoveredFilter(name)}
                name={`${consumer.id}`}
                checked={
                  (filters.consumers || []).find((c) => c.id === consumer.id)
                    ?.checked
                }
                showLabelInline={true}
              />
            ))
          )}
        </div>
      </form>
    </>
  );
}

export default HomePacketsFilter;
