import React from "react";
import styled from "styled-components";

function HelpLink({ path, label }) {
  const HELP_ROOT_PATH =
    "https://dev.azure.com/aclaiminc/7c56c839-d22b-461c-ac59-2dcecf55da7e/_wiki/wikis/f27d88f8-afeb-4262-95d7-f6bad9f97c8f?pagePath=";

  function onClickLink(event) {
    if (event) event.stopPropagation();
  }

  return (
    <StyledHelpLink className="help">
      <a
        target="helpwindow"
        href={`${HELP_ROOT_PATH}${encodeURI(path)}`}
        onClick={onClickLink}
      >
        <span className="material-symbols-outlined">help</span>
        {label && <span>{label}</span>}
      </a>
    </StyledHelpLink>
  );
}

const StyledHelpLink = styled.span`
  a {
    cursor: help;
    opacity: 0.5;
    display: flex;
    align-items: center;
    column-gap: 4px;
    text-decoration: none;
  }
`;

export default HelpLink;
