import React from "react";
import styled from "styled-components";
import ParsedPacketField from "./ParsedPacketField";

function ParsedPacketRecord({
  record,
  isReadOnly,
  fields,
  displayType = "condensed",
  setDisplayType,
}) {
  function getFieldData() {
    if ((record.fields || []).length < 2 || (fields || []).length === 0)
      return null;

    // Locate data about this record's second field in the fields array (past the AM field)
    const field = record.fields[1];
    const data = fields.find(
      (f) =>
        f.transactionIndex === field.transactionIndex &&
        f.segmentCode === field.segmentCode &&
        f.fieldGroupOrdinal === field.fieldGroupOrdinal &&
        f.fieldId === field.fieldId
    );

    return data ? data : null;
  }

  const segmentFieldData = getFieldData();

  const segmentHeader =
    segmentFieldData === null ? "" : segmentFieldData.segmentDescription;

  return (
    <span>
      <StyledGSSpanWrapper displayType={displayType}>
        <StyledRSSpan
          key={`RS${record.id}`}
          displayType={displayType}
          className="parser-control-char"
        >
          SEG
        </StyledRSSpan>
        {displayType === "condensed" ? "" : segmentHeader}
      </StyledGSSpanWrapper>
      {record.fields.map((field) => (
        <ParsedPacketField
          key={field.id}
          field={field}
          isReadOnly={isReadOnly}
          displayType={displayType}
          setDisplayType={setDisplayType}
          fields={fields}
        />
      ))}
    </span>
  );
}

const StyledRSSpan = styled.span`
  margin-left: ${(props) =>
    props.displayType === "condensed" ? "10px" : "20px"};
  margin-right: ${(props) =>
    props.displayType === "condensed" ? "0" : "10px"};
  color: #fff;
  background-color: blue;

  &.parser-control-char {
    max-width: ${(props) =>
      props.displayType === "condensed" ? "100%" : "34px"};
  }
`;

const StyledGSSpanWrapper = styled.span`
  margin-top: ${(props) => (props.displayType === "condensed" ? "0" : "20px")};
  margin-bottom: ${(props) =>
    props.displayType === "condensed" ? "0" : "2px"};
  display: ${(props) =>
    props.displayType === "condensed" ? "inline-block" : "block"};
`;

export default ParsedPacketRecord;
