import React from "react";
import CheckboxInput from "../../common/input/CheckboxInput";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";

function ChainSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onActiveChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="chainId"
            label="Chain Id"
            onChange={onChange}
            placeholder="Chain Id"
            name="chainId"
            value={search.chainId}
            error={errors.chainId}
          />
          <TextInput
            id="name"
            label="Name"
            onChange={onChange}
            placeholder="Name"
            name="name"
            value={search.name}
            error={errors.name}
          />
          <TextInput
            id="address"
            label="Address"
            onChange={onChange}
            placeholder="Address"
            name="address"
            value={search.address}
            error={errors.address}
          />
          <TextInput
            id="city"
            label="City"
            onChange={onChange}
            placeholder="City"
            name="city"
            value={search.city}
            error={errors.city}
          />
          <TextInput
            id="state"
            label="State"
            onChange={onChange}
            placeholder="State"
            name="state"
            value={search.state}
            error={errors.state}
          />
          <CheckboxInput
            id="active"
            label="Active Only"
            onChange={onActiveChange}
            placeholder="Active Only"
            name="active"
            checked={search.active}
            error={errors.active}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default ChainSearchForm;
