import React from "react";
import styled from "styled-components";
import {
  getBadgeColorsFromType,
  NodeTypes,
} from "../rulemaps/RuleMapDataCommon";
import { StyledCharblockDiv } from "../rulemaps/RuleMapCommonStyles";
import { emptyRuleMapField } from "../../../viewmodels/groupRuleMapsVm";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useGroupRuleMaps } from "../../../contexts/GroupRuleMapsContext";

function GroupRuleMapEditToolbar() {
  const { groupRuleMapsData, setGroupRuleMapsData } = useGroupRuleMaps();

  const variableColors = getBadgeColorsFromType(NodeTypes.InternalVariable);
  const rejectionColors = getBadgeColorsFromType(NodeTypes.Rejection);
  const ruleTableColors = getBadgeColorsFromType(NodeTypes.LookupTable);

  function onAddButtonClick(e, type) {
    if (e) e.preventDefault();
    console.log("Add Type", type);

    const editItem = { ...emptyRuleMapField, isNew: true, type: type };

    setGroupRuleMapsData({
      type: ContextProviderActions.setGroupRuleMapEditNode,
      payload: editItem,
    });
  }

  function getToolbarButton(shortText, longText, type, colors) {
    const btn = (
      <StyledToolbarButton
        colors={colors}
        type="button"
        className="btn btn-secondary"
        onClick={(e) => onAddButtonClick(e, type)}
        title={"Add " + longText}
      >
        <StyledCharblockDiv
          className="charblock"
          colors={colors}
          style={{ marginRight: "8px" }}
        >
          {shortText}
        </StyledCharblockDiv>
        {longText}
      </StyledToolbarButton>
    );

    return btn;
  }

  if (groupRuleMapsData?.editField !== null) {
    return <></>;
  }

  return (
    <>
      <StyledEditToolbar>
        <div style={{ marginBottom: "10px" }}>Add new:</div>
        <StyledEditToolbarContents>
          {getToolbarButton(
            "VAR",
            "Variable",
            NodeTypes.InternalVariable,
            variableColors
          )}
          {getToolbarButton(
            "REJ",
            "Included Rejection",
            NodeTypes.Rejection,
            rejectionColors
          )}
          {getToolbarButton(
            "REJ",
            "Excluded Rejection",
            NodeTypes.ExcludedRejection,
            rejectionColors
          )}
          {getToolbarButton(
            "DATA",
            "Rule Table Data",
            NodeTypes.LookupTable,
            ruleTableColors
          )}
        </StyledEditToolbarContents>
      </StyledEditToolbar>
    </>
  );
}

const StyledEditToolbar = styled.div`
  margin: 0 0 20px 0;
  padding: 10px 10px;
  min-height: 60px;
  background: transparent;
  border: 1px solid var(--button-secondary-border);
  border-radius: 3px;
`;

const StyledEditToolbarContents = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
`;

const StyledToolbarButton = styled.button`
  display: flex;
  align-items: center;
  border-color: ${(props) => props.colors.bgColor};
`;

export default GroupRuleMapEditToolbar;
