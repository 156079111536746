import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useAuth } from "../../../contexts/AuthContext";
import Authorize from "../../common/layout/Authorize";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import useApi from "../../../hooks/useApi";
import { useDbDatabaseSync } from "../../../contexts/dbDatabaseSyncContext";
import {
  apiCancelDbDatabaseSync,
  apiLoadDbDatabaseSync,
  apiRunDbDatabaseSync,
  apiUpdateDbDatabaseSync,
} from "../../../api/DbDatabaseSyncApi";
import {
  createViewModel,
  emptyDatabaseSync,
  emptyDatabaseSyncStatus,
  emptyDatabaseSyncType,
  fromViewModel,
} from "../../../viewmodels/dbDatabaseSyncVm";
import Spinner from "../../common/ui/Spinner";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import {
  generateUUID,
  handleCollapseExpandAll,
} from "../../../services/General";
import CheckboxInput from "../../common/input/CheckboxInput";
import DbDatabaseSyncType from "./DbDatabaseSyncType";
import styled from "styled-components";
import { parseISO } from "date-fns";
import DbDatabaseSyncContinuousSection from "./DbDatabaseSyncContinuousSection";
import StatusPill from "../../common/ui/StatusPill";
import DbUtilsStatusSection from "./DbUtilsStatusSection";
import DbSyncWebSocketHubConnections from "./DbSyncWebSocketHubConnections";

function DbDatabaseSync() {
  const { auth } = useAuth();
  const { dbDatabaseSyncData, setDbDatabaseSyncData } = useDbDatabaseSync();
  const { loading, api: apiLoad } = useApi(apiLoadDbDatabaseSync);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateDbDatabaseSync);
  const { api: apiRun } = useApi(apiRunDbDatabaseSync);
  const { api: apiCancel } = useApi(apiCancelDbDatabaseSync);
  const [changes, setChanges] = useState(emptyDatabaseSync);
  const [lastSavedChanges, setLastSavedChanges] = useState(emptyDatabaseSync);
  const [hasChanges, setHasChanges] = useState(false);
  const [updateState, setUpdateState] = useState(emptyDatabaseSyncStatus);
  const [continuousSyncMessages, setContinuousSyncMessages] = useState([]);
  const [syncIsRunning, setSyncIsRunning] = useState([]);
  const [syncIsStarting, setSyncIsStarting] = useState([]);
  const [syncIsCancelling, setSyncIsCancelling] = useState([]);
  const [isSyncAppRunning, setIsSyncAppRunning] = useState(false);
  const [errors, setErrors] = useState({});
  const [collapsedState, setCollapsedState] = useState([
    { name: "General", collapsed: true },
    { name: "Run Status", collapsed: false },
    { name: "Sync Environments", collapsed: true },
    { name: "Continuous Synchronization Status", collapsed: false },
  ]);

  useEffect(() => {
    const vm = dbDatabaseSyncData?.trafficDbSyncStateUpdates;
    if (vm !== null) {
      const newConfig = vm.currentConfig.cosmosToCosmos;
      const newState = vm.currentState.cosmosToCosmos;

      setIsSyncAppRunning(vm.currentState.started === true);

      setUpdateState(newState);
      setContinuousSyncMessages(
        vm.continuousSyncMessages.filter((m) => m.syncSource === 2) // 1=SqlToCosmos, 2=CosmosToCosmos
      );

      setIsRunningForTypes(newState, false);
      setIsStartingForTypes(newConfig);
      setIsCancellingForTypes(newConfig);
    }
  }, [dbDatabaseSyncData?.trafficDbSyncStateUpdates]);

  useEffect(() => {
    if (auth.authenticated) {
      loadDbDatabaseSync();
    }
  }, [auth.authenticated]);

  useEffect(() => {
    if (dbDatabaseSyncData.dbDatabaseSync) {
      const changed = _.cloneDeep(dbDatabaseSyncData.dbDatabaseSync);
      // Id is used only for delete but we need each sync to have one and api does not assign one
      changed.synchronizations.forEach((s) => (s.id = generateUUID()));
      changed.synchronizations = getSortedSynchronizations(
        changed.synchronizations
      );
      setChanges(changed);
      setLastSavedChanges(changed);
    } else {
      setChanges(emptyDatabaseSync);
      setLastSavedChanges(emptyDatabaseSync);
    }
  }, [dbDatabaseSyncData.dbDatabaseSync]);

  useEffect(() => {
    setHasChanges(
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
        JSON.stringify(_.cloneDeep(changes))
    );
  }, [lastSavedChanges, changes]);

  async function loadDbDatabaseSync() {
    await apiLoad.call(null, async (result) => {
      const vm = createViewModel(result);
      setDbDatabaseSyncData({
        type: ContextProviderActions.loadDbDatabaseSync,
        payload: vm,
      });
      setUpdateState(vm.currentState);
      setIsSyncAppRunning(vm.started === true);

      // Set up is running flags for each type in the synchronizations array
      setIsRunningForTypes(vm.currentState, true);
      setIsStartingForTypes(vm);
      setIsCancellingForTypes(vm);
    });
  }

  function setSyncIsStartingForType(name, isStarting) {
    const newArray = setSyncArrayForType(
      name,
      syncIsStarting,
      "isStarting",
      isStarting
    );
    setSyncIsStarting(newArray);
  }

  function setSyncIsCancellingForType(name, isCancelling) {
    const newArray = setSyncArrayForType(
      name,
      syncIsCancelling,
      "isCancelling",
      isCancelling
    );
    setSyncIsCancelling(newArray);
  }

  function setSyncArrayForType(name, array, boolType, boolValue) {
    const typeArray = [...array];
    let typeItem = typeArray.find((t) => t.name === name);

    if (typeItem) {
      typeItem[boolType] = boolValue;
    } else {
      typeItem = { name: name };
      typeItem[boolType] = boolValue;
      typeArray.push(typeItem);
    }

    return typeArray;
  }

  function getSyncArrayValueForType(name, array, boolType) {
    const typeArray = [...array];
    let typeItem = typeArray.find((t) => t.name === name);

    if (typeItem) {
      return typeItem[boolType];
    } else {
      return false;
    }
  }

  function setIsRunningForTypes(state, alwaysUpdate) {
    if (!state || !state.synchronizations) return;

    let typeArray = [...syncIsRunning];

    let name, item, isRunning;
    for (let i = 0; i < state.synchronizations.length; i++) {
      item = state.synchronizations[i];
      name = item.name;
      isRunning = getSyncArrayValueForType(name, syncIsRunning, "isRunning");

      if (alwaysUpdate) {
        typeArray = setSyncArrayForType(
          name,
          typeArray,
          "isRunning",
          item.isRunInProgress
        );
      } else if (!isRunning && item.isRunInProgress) {
        typeArray = setSyncArrayForType(name, typeArray, "isRunning", true);
      }
    }

    setSyncIsRunning(typeArray);
  }

  function setIsStartingForTypes(config) {
    if (!config || !config.synchronizations) return;

    let typeArray = [...syncIsStarting];

    let name, item;
    for (let i = 0; i < config.synchronizations.length; i++) {
      item = config.synchronizations[i];
      name = item.name;

      typeArray = setSyncArrayForType(
        name,
        typeArray,
        "isStarting",
        item.beginManual
      );
    }

    setSyncIsStarting(typeArray);
  }

  function setIsCancellingForTypes(config) {
    if (!config || !config.synchronizations) return;

    let typeArray = [...syncIsCancelling];

    let name, item;
    for (let i = 0; i < config.synchronizations.length; i++) {
      item = config.synchronizations[i];
      name = item.name;

      typeArray = setSyncArrayForType(
        name,
        typeArray,
        "isCancelling",
        item.cancelInProcess
      );
    }

    setSyncIsCancelling(typeArray);
  }

  function formIsValid() {
    const _errors = {};

    // Synchronization array validation check
    let typeItem, name;
    for (let i = 0; i < changes.synchronizations.length; i++) {
      typeItem = changes.synchronizations[i];
      name = typeItem.name;

      if (_.trim(typeItem.name) === "")
        _errors[`${name}-name`] = "Name must be entered";

      if (_.trim(typeItem.type) === "")
        _errors[`${name}-type`] = "Type must be entered";

      if (_.trim(typeItem.sourceConnectionString) === "")
        _errors[`${name}-sourceConnectionString`] =
          "Source Connection String must be entered";

      if (_.trim(typeItem.sourceDatabaseId) === "")
        _errors[`${name}-sourceDatabaseId`] =
          "Source Database Id must be entered";

      if (_.trim(typeItem.sourceContainer) === "")
        _errors[`${name}-sourceContainer`] = "Source Container must be entered";

      if (_.trim(typeItem.destConnectionString) === "")
        _errors[`${name}-destConnectionString`] =
          "Destination Connection String must be entered";

      if (_.trim(typeItem.destDatabaseId) === "")
        _errors[`${name}-destDatabaseId`] =
          "Destination Database Id must be entered";

      if (_.trim(typeItem.destContainer) === "")
        _errors[`${name}-destContainer`] =
          "Destination Container must be entered";

      if (_.trim(typeItem.pollFrequencySeconds) === "")
        _errors[`${name}-pollFrequencySeconds`] =
          "Poll Frequency Seconds must be entered";

      if (
        _.trim(typeItem.startDate) !== "" &&
        _.trim(typeItem.endDate) !== "" &&
        parseISO(typeItem.startDate) > parseISO(typeItem.endDate)
      )
        _errors[`${typeItem.name}-startDate`] =
          "Start Date must be less than End Date";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleCheckboxChange({ target }) {
    let changed = _.cloneDeep(changes);
    changed.properties[target.name] = target.checked;
    setChanges(changed);
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    updateDbDatabaseSync({ ...changes });
  }

  async function updateDbDatabaseSync(vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ model }, (result) => {
      const newVm = { ...model, ...result };
      const retModel = createViewModel(newVm);
      retModel.synchronizations = getSortedSynchronizations(
        retModel.synchronizations
      );
      setChanges(retModel);
      setLastSavedChanges(retModel);

      notifySuccess("Configuration saved successfully");
    });
  }

  async function handleRunProcess(type) {
    // Check for unsaved changes
    if (
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
      JSON.stringify(_.cloneDeep(changes))
    ) {
      notifyWarn("Please save your changes before running the process.");
      return;
    }

    setSyncIsStartingForType(type, true);
    apiRun.call({ name: type }, async (result) => {
      notifySuccess(`${type} sync started. It will begin momentarily...`);
    });
  }

  async function handleCancelProcess(event, type) {
    if (event) event.preventDefault();

    setSyncIsCancellingForType(type, true);
    apiCancel.call({ name: type }, async (result) => {
      notifySuccess(`${type} sync cancelled. It will stop momentarily...`);
    });
  }

  function handleAddNewSync() {
    let changed = _.cloneDeep(changes);
    // Id is used only for delete
    changed.synchronizations.push({
      ...emptyDatabaseSyncType,
      id: generateUUID(),
    });
    changed.synchronizations = getSortedSynchronizations(
      changed.synchronizations
    );
    setChanges(changed);
  }

  function handleDeleteSyncEnvironment(event, id) {
    if (event) event.preventDefault();

    let changed = _.cloneDeep(changes);
    const index = changed.synchronizations.findIndex((s) => s.id === id);
    if (index >= 0) {
      changed.synchronizations.splice(index, 1);
    }

    changed.synchronizations = getSortedSynchronizations(
      changed.synchronizations
    );
    setChanges(changed);
  }

  function getSortedSynchronizations(array) {
    // Sort all new items (with blank name) to the bottom.
    const newArray = array.sort((a, b) =>
      (_.isEmpty(a.name) ? "zzzzz" : a.name) >
      (_.isEmpty(b.name) ? "zzzzz" : b.name)
        ? 1
        : -1
    );
    return newArray;
  }

  const isAnySyncStarting =
    (syncIsStarting || []).filter((t) => t.isStarting === true).length > 0;
  const isAnySyncCancelling =
    (syncIsCancelling || []).filter((t) => t.isCancelling === true).length > 0;

  return (
    <Authorize>
      <DbSyncWebSocketHubConnections />
      <form onSubmit={handleSubmit}>
        <StyledScreenHelpDiv>
          <HelpLink
            path="/Database/Database-Synchronization-Screen"
            label="Help"
          />
        </StyledScreenHelpDiv>
        <StyledHeaderRowDiv>
          <div className="flex-row-without-wrap">
            <h1 style={{ display: "inline-block", paddingTop: "5px" }}>
              Database Synchronization
            </h1>

            <StatusPill
              pillStyle={{
                display: "inline-block",
                marginLeft: "10px",
                minWidth: "50px",
                textAlign: "center",
              }}
              status={isSyncAppRunning ? "Started" : "Stopped"}
            />
          </div>
          {!loading && (
            <StyledHeaderRowButtonDiv
              style={{ flexWrap: "wrap", rowGap: "10px" }}
            >
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "86px",
                  marginLeft: "24px",
                }}
                disabled={false}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  document.location.reload();
                }}
                style={{ marginLeft: "12px" }}
                disabled={false}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          )}
        </StyledHeaderRowDiv>
        {loading || updating ? (
          <Spinner spinnerStyle={{ lineHeight: "25vh" }} />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <ExpandCollapseDetailSection
              sectionTitle="General"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Database-Synchronization-Screen&anchor=general-section"
            >
              <StyledRowDiv className="row">
                <div className="col-12 col-md-6">
                  <CheckboxInput
                    id="active"
                    label="Active"
                    onChange={handleCheckboxChange}
                    placeholder=""
                    name="active"
                    showLabelInline={false}
                    checked={changes.properties.active}
                    error={errors.active}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Run Status"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Database-Synchronization-Screen&anchor=run-status-section"
            >
              {updateState?.lastStarted ? (
                <DbUtilsStatusSection
                  isAppRunning={isSyncAppRunning}
                  lastStarted={updateState.lastStarted}
                  lastCompleted={updateState.lastCompleted}
                  lastError={updateState.lastError}
                />
              ) : (
                <>Loading...</>
              )}
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Sync Environments"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Database-Synchronization-Screen&anchor=sync-environments-section"
            >
              <StyledTypesContainer className="row">
                {(changes.synchronizations || []).map((type, idx) => (
                  <DbDatabaseSyncType
                    key={`type-${idx}-${type.id}`}
                    name={type.name}
                    changes={changes}
                    setChanges={setChanges}
                    hasChanges={hasChanges}
                    updateState={
                      (updateState?.synchronizations || []).find(
                        (t) => t.name === type.name
                      ) || { ...emptyDatabaseSyncStatus }
                    }
                    isStarting={getSyncArrayValueForType(
                      type.name,
                      syncIsStarting,
                      "isStarting"
                    )}
                    isCancelling={getSyncArrayValueForType(
                      type.name,
                      syncIsCancelling,
                      "isCancelling"
                    )}
                    isAnyProcessStartingOrCancelling={
                      isAnySyncStarting || isAnySyncCancelling
                    }
                    syncIsRunning={getSyncArrayValueForType(
                      type.name,
                      syncIsRunning,
                      "isRunning"
                    )}
                    onRunProcess={() => handleRunProcess(type.name)}
                    onCancelProcess={(e) => handleCancelProcess(e, type.name)}
                    onDeleteSyncEnvironment={(e) =>
                      handleDeleteSyncEnvironment(e, type.id)
                    }
                    errors={errors}
                  />
                ))}
                <StyledNewTypeContainer onClick={handleAddNewSync}>
                  <p>
                    <span className="material-icons">add</span> Add new sync
                    environment
                  </p>
                </StyledNewTypeContainer>
              </StyledTypesContainer>
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Continuous Synchronization Status"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Database-Synchronization-Screen&anchor=continuous-synchronization-status-section"
            >
              <DbDatabaseSyncContinuousSection
                typeConfigs={changes.synchronizations || []}
                typeStates={updateState?.synchronizations || []}
                lastestMessages={continuousSyncMessages}
              />
            </ExpandCollapseDetailSection>
          </>
        )}
      </form>
    </Authorize>
  );
}

const StyledTypesContainer = styled.div`
  padding: 10px;
`;

const StyledNewTypeContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  min-height: 50px;
  background: var(--drag-chip-placeholder-bg);
  border: 1px dashed var(--drag-chip-placeholder-border);
  cursor: pointer;

    &:hover {
      background-color: var(--drag-chip-placeholder-bg-active);
      border: 1px dashed var(--drag-chip-placeholder-border-active);

      p,
      span[class="material-icons"] {
        color: var(--drag-chip-placeholder-text-active);
      }
    }

    &:active {
      background-color: var(--drag-chip-placeholder-border-active);

      p,
      span[class="material-icons"] {
        color: #FFF;
      }
    }

  }

  span[class="material-icons"] {
    font-size: 24px;
    line-height: 24px;
    color: var(--drag-chip-placeholder-border);
  }

  p {
    width: 320px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 0;
    margin: 0 auto;
    font-size: 24px;
    line-height: 28px;
    color: var(--drag-chip-placeholder-text);
  }
`;

export default DbDatabaseSync;
