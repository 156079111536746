import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
} from "../../../services/General";
import StatusPill from "../../common/ui/StatusPill";
import { useMobile } from "../../../hooks/useMobile";
import InfoDialog from "../../dialogs/InfoDialog";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function TestGroupTestList({
  tests,
  currentRunTestIndex,
  isRunning,
  totalRecords,
  search,
  setSearch,
  onDelete,
  onMoveUp,
  onMoveDown,
}) {
  const navigate = useNavigate();
  const { isMobileSize } = useMobile();
  const [messageText, setMessageText] = useState("");
  const [showMessageDialog, setShowMessageDialog] = useState(false);

  function getActions(dataRow) {
    const id = dataRow.id;

    const actions = [];

    actions.push({
      name: "Remove test",
      onClick: () => onDelete(id),
    });

    if (isMobileSize) {
      actions.push({
        name: "Move test up",
        onClick: () => onMoveUp(id),
      });
      actions.push({
        name: "Move test down",
        onClick: () => onMoveDown(id),
      });
    }

    return actions;
  }

  function getRunColumnIcon(index) {
    if (isRunning && currentRunTestIndex === index) {
      return <span className="material-icons">directions_run</span>;
    } else {
      return <></>;
    }
  }

  function handleShowMessage(message) {
    if (message !== "") {
      setMessageText(message);
      setShowMessageDialog(true);
    }
  }

  return (
    <>
      <InfoDialog
        title={"Message"}
        text={messageText}
        showModal={showMessageDialog}
        onClose={() => {
          setMessageText("");
          setShowMessageDialog(false);
        }}
      />
      <ResponsiveGrid
        gridId="TestGroupTests"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        allowFreeTextSearch={true}
        dataRows={tests.sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))}
        columnDefs={[
          {
            name: "run",
            label: "Run",
            hidden: !isRunning,
            disableSort: true,
            style: {
              width: "60px",
              maxWidth: "15%",
              textAlign: "center",
            },
            getValue: (row, index) => getRunColumnIcon(index),
          },
          {
            name: "ordinal",
            label: "Order",
            disableSort: true,
            style: { width: "15%" },
          },
          {
            name: "description",
            label: "Test Description",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "40%" },
            getValue: (row, index, searchResultHtml) =>
              isRunning ? (
                <>{row.description}</>
              ) : (
                <>
                  <button
                    className="btn btn-link link-underline"
                    onClick={() => navigate("/test/" + row.id)}
                  >
                    {searchResultHtml}
                  </button>
                </>
              ),
          },
          {
            name: "lastRunDate",
            label: "Last Run",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
            getValue: (row) =>
              row.lastRunDate === ""
                ? ""
                : formatDateTimeUtcZoneForDisplay(row.lastRunDate),
          },
          {
            name: "lastRunBy",
            label: "Run By",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
          },
          {
            name: "lastRunDurationMilliseconds",
            label: "Duration",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              getTimeframeFromMilliseconds(row.lastRunDurationMilliseconds),
          },
          {
            name: "lastRunResultStatus",
            label: "Last Result",
            freeTextSearch: true,
            className: "d-none d-md-table-cell",
            style: { width: "15%" },
            noForceWrap: true,
            getValue: (row, index, searchResultHtml) => (
              <StatusPill
                status={searchResultHtml}
                pillStyle={{
                  cursor:
                    row.lastRunResultStatus === "Error" ? "pointer" : "default",
                }}
                message={(row.message || "").substring(0, 50)}
                onClick={() => handleShowMessage(row.message)}
              />
            ),
          },
          {
            name: "move",
            label: "Move",
            hidden: isRunning,
            disableSort: true,
            noForceWrap: true,
            className: "d-none d-md-table-cell",
            style: { width: "80px", maxWidth: "15%", textAlign: "left" },
            getValue: (row, index) => {
              const isFirstTest = index === 0;
              const isLastTest = index === tests.length - 1;

              return (
                <>
                  <span
                    className={
                      isLastTest ? "material-icons disabled" : "material-icons"
                    }
                    style={{
                      cursor: isLastTest ? "default" : "pointer",
                    }}
                    title="Move down"
                    onClick={() => {
                      if (index === tests.length - 1) return;
                      onMoveDown(row.id);
                    }}
                  >
                    south
                  </span>
                  <span
                    className={
                      isFirstTest ? "material-icons disabled" : "material-icons"
                    }
                    style={{
                      cursor: isFirstTest ? "default" : "pointer",
                    }}
                    title="Move up"
                    onClick={() => {
                      if (index === 0) return;
                      onMoveUp(row.id);
                    }}
                  >
                    north
                  </span>
                </>
              );
            },
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default TestGroupTestList;
