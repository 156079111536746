import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useAuth } from "../../../contexts/AuthContext";
import Authorize from "../../common/layout/Authorize";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import useApi from "../../../hooks/useApi";
import { useDbDrugUpdate } from "../../../contexts/dbDrugUpdateContext";
import {
  apiCancelDbDrugUpdate,
  apiLoadDbDrugUpdate,
  apiRunDbDrugUpdate,
  apiUpdateDbDrugUpdate,
} from "../../../api/DbDrugUpdateApi";
import {
  createViewModel,
  emptyDrugUpdate,
  emptyDrugUpdateStatus,
  fromViewModel,
} from "../../../viewmodels/dbDrugUpdateVm";
import Spinner from "../../common/ui/Spinner";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import {
  formatDateTimeUtcZoneForDisplay,
  formatDecimal,
  formatNumberForDisplay,
  handleCollapseExpandAll,
} from "../../../services/General";
import TextInput from "../../common/input/TextInput";
import TextAreaInput from "../../common/input/TextAreaInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import ReadOnly from "../../common/input/ReadOnly";
import StatusPill from "../../common/ui/StatusPill";
import SimpleCompletionBar from "../../common/ui/SimpleCompletionBar";
import DbUtilsStatusSection from "./DbUtilsStatusSection";
import DbSyncWebSocketHubConnections from "./DbSyncWebSocketHubConnections";
import { useDbDatabaseSync } from "../../../contexts/dbDatabaseSyncContext";

function DbDrugUpdate() {
  const { auth } = useAuth();
  const { dbDrugUpdateData, setDbDrugUpdateData } = useDbDrugUpdate();
  const { dbDatabaseSyncData } = useDbDatabaseSync();
  const { loading, api: apiLoad } = useApi(apiLoadDbDrugUpdate);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateDbDrugUpdate);
  const { loading: running, api: apiRun } = useApi(apiRunDbDrugUpdate);
  const { loading: cancelling, api: apiCancel } = useApi(apiCancelDbDrugUpdate);
  const [changes, setChanges] = useState(emptyDrugUpdate);
  const [lastSavedChanges, setLastSavedChanges] = useState(emptyDrugUpdate);
  const [hasChanges, setHasChanges] = useState(false);
  const [updateState, setUpdateState] = useState(emptyDrugUpdateStatus);
  const [updateIsRunning, setUpdateIsRunning] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isSyncAppRunning, setIsSyncAppRunning] = useState(false);
  const [errors, setErrors] = useState({});
  const [collapsedState, setCollapsedState] = useState([
    { name: "General", collapsed: true },
    { name: "Run Status", collapsed: false },
    { name: "Last Run Info", collapsed: false },
  ]);

  useEffect(() => {
    const vm = dbDatabaseSyncData?.trafficDbSyncStateUpdates;
    if (vm !== null) {
      setIsSyncAppRunning(vm.currentState.started === true);

      setUpdateState(vm.currentState.drugUpdate);
      if (!updateIsRunning && vm.currentState.drugUpdate.isRunInProgress) {
        setUpdateIsRunning(true);
        setIsStarting(false);
        setIsCancelling(false);
      } else {
        setIsStarting(vm.currentConfig.drugUpdate.beginDrugUpdate);
        setIsCancelling(vm.currentConfig.drugUpdate.cancelInProcess);
      }
    }
  }, [dbDatabaseSyncData?.trafficDbSyncStateUpdates]);

  useEffect(() => {
    if (auth.authenticated) {
      loadDbDrugUpdate();
    }
  }, [auth.authenticated]);

  useEffect(() => {
    if (updateState && !updateState.isRunInProgress && updateIsRunning) {
      // Let progress bar stay on the screen for a bit so user can see update is done
      const finalState = {
        ...updateState,
        lastUpdateMessage: "Update complete!",
      };
      setUpdateState(finalState);
      window.setTimeout(() => {
        setUpdateIsRunning(false);
      }, 2000);
    }
  }, [updateState?.isRunInProgress]);

  useEffect(() => {
    if (dbDrugUpdateData.dbDrugUpdate) {
      setChanges(dbDrugUpdateData.dbDrugUpdate);
      setLastSavedChanges(dbDrugUpdateData.dbDrugUpdate);
    } else {
      setChanges(emptyDrugUpdate);
      setLastSavedChanges(emptyDrugUpdate);
    }
  }, [dbDrugUpdateData.dbDrugUpdate]);

  useEffect(() => {
    setHasChanges(
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
        JSON.stringify(_.cloneDeep(changes))
    );
  }, [lastSavedChanges, changes]);

  async function loadDbDrugUpdate() {
    await apiLoad.call(null, async (result) => {
      const vm = createViewModel(result);
      setDbDrugUpdateData({
        type: ContextProviderActions.loadDbDrugUpdate,
        payload: vm,
      });
      setUpdateState(vm.currentState);
      setIsSyncAppRunning(vm.started === true);

      if (vm.currentState.isRunInProgress) {
        setUpdateIsRunning(true);
        setIsStarting(false);
        setIsCancelling(false);
      } else {
        setUpdateIsRunning(false);
        setIsStarting(vm.beginDrugUpdate);
        setIsCancelling(vm.cancelInProcess);
      }
    });
  }

  function formIsValid() {
    const _errors = {};

    if (_.trim(changes.databaseConnectionString) === "")
      _errors.databaseConnectionString =
        "Target Database Connection String must be entered";

    if (_.trim(changes.databaseId) === "")
      _errors.databaseId = "Target Database Id must be entered";

    if (_.trim(changes.container) === "")
      _errors.container = "Target Container must be entered";

    if (_.trim(changes.sqlManualDrugRecordsConnectionString) === "")
      _errors.sqlManualDrugRecordsConnectionString =
        "Manual Source Database Connection String must be entered";

    if (_.trim(changes.storageConnectionString) === "")
      _errors.storageConnectionString =
        "Source Drug File Storage Connection String must be entered";

    if (_.trim(changes.drugFileLocation) === "")
      _errors.drugFileLocation =
        "Source Drug File Storage Location must be entered";

    if (_.trim(changes.bulkRecordPageSize) === "")
      _errors.bulkRecordPageSize = "Bulk Record Page Size must be entered";

    if (_.trim(changes.bulkRecordWaitBetweenPages) === "")
      _errors.bulkRecordWaitBetweenPages =
        "Bulk Record Wait Between Pages must be entered";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleCheckboxChange({ target }) {
    let changed = {
      ...changes,
      [target.name]: target.checked,
    };
    setChanges(changed);
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    updateDbDrugUpdate({ ...changes });
  }

  async function updateDbDrugUpdate(vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ model }, (result) => {
      const newVm = { ...model, ...result };
      const retModel = createViewModel(newVm);
      setChanges(retModel);
      setLastSavedChanges(retModel);

      notifySuccess("Drug Update configuration saved successfully");
    });
  }

  async function handleRunProcess() {
    // Check for unsaved changes
    if (
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
      JSON.stringify(_.cloneDeep(changes))
    ) {
      notifyWarn("Please save your changes before running the process.");
      return;
    }

    setIsStarting(true);
    apiRun.call(undefined, async (result) => {
      notifySuccess("Drug update started. It will begin momentarily...");
    });
  }

  async function handleCancelProcess(event) {
    if (event) event.preventDefault();

    setIsCancelling(true);
    apiCancel.call(undefined, async (result) => {
      notifySuccess("Drug update cancelled. It will stop momentarily...");
    });
  }

  // Prevent divide by zero in percentage calculation below.
  const totalRecords =
    updateState.totalRecordsToProcess === 0
      ? 0.01
      : updateState.totalRecordsToProcess;
  let runPercentage = formatDecimal(
    (updateState.inProgressRecordsProcessed / totalRecords) * 100,
    0
  );
  if (runPercentage < 1) runPercentage = 1;

  return (
    <Authorize>
      <DbSyncWebSocketHubConnections />
      <form onSubmit={handleSubmit}>
        <StyledScreenHelpDiv>
          <HelpLink path="/Database/Drug-Update-Screen" label="Help" />
        </StyledScreenHelpDiv>
        <StyledHeaderRowDiv>
          <div className="flex-row-without-wrap">
            <h1 style={{ display: "inline-block", paddingTop: "5px" }}>
              Drug Update
            </h1>

            <StatusPill
              pillStyle={{
                display: "inline-block",
                marginLeft: "10px",
                minWidth: "50px",
                textAlign: "center",
              }}
              status={isSyncAppRunning ? "Started" : "Stopped"}
            />
          </div>
          {!loading && !updateIsRunning && !isStarting && !isCancelling && (
            <StyledHeaderRowButtonDiv
              style={{ flexWrap: "wrap", rowGap: "10px" }}
            >
              <button
                type="button"
                className="btn btn-secondary btn-with-icon"
                onClick={handleRunProcess}
                style={{
                  marginLeft: "14px",
                  borderColor: "var(--notify-success)",
                }}
                disabled={hasChanges || updateIsRunning || !changes.active}
              >
                {!changes.active ? (
                  <>Service is not active</>
                ) : hasChanges ? (
                  <>Save changes before running</>
                ) : (
                  <>
                    <span className="material-icons">play_circle_outline</span>
                    {"  "}Run Now
                  </>
                )}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "86px",
                  marginLeft: "24px",
                }}
                disabled={updateIsRunning}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  document.location.reload();
                }}
                style={{ marginLeft: "12px" }}
                disabled={updateIsRunning}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          )}
        </StyledHeaderRowDiv>
        {loading || updating || running || isStarting || isCancelling ? (
          <>
            <Spinner spinnerStyle={{ lineHeight: "25vh" }} />
            {(isStarting || isCancelling) && (
              <h4
                style={{
                  textAlign: "center",
                  position: "relative",
                  top: "-50px",
                  margin: "0",
                  padding: "0",
                }}
              >
                {isStarting
                  ? `Drug update is starting...`
                  : `Cancelling drug update...`}
              </h4>
            )}
          </>
        ) : updateIsRunning ? (
          <SimpleCompletionBar
            title={<p>Drug update in progress...</p>}
            runPercentage={runPercentage}
            onCancel={handleCancelProcess}
            disableCancel={cancelling}
            fullWidth={true}
            subtitle={
              <>
                {" "}
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "4px",
                    marginBottom: "0",
                  }}
                >
                  {_.isEmpty(updateState.lastUpdateMessage) ? (
                    <>&nbsp;</>
                  ) : (
                    <>{updateState.lastUpdateMessage}</>
                  )}
                </p>
                <p style={{ textAlign: "center", marginTop: "4px" }}>
                  {updateState.inProgressRecordsProcessed === 0 &&
                  updateState.totalRecordsToProcess === 0 ? (
                    <>&nbsp;</>
                  ) : (
                    <>
                      {`${formatNumberForDisplay(
                        updateState.inProgressRecordsProcessed
                      )} of ${formatNumberForDisplay(
                        updateState.totalRecordsToProcess
                      )}`}
                    </>
                  )}
                </p>
              </>
            }
          />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <ExpandCollapseDetailSection
              sectionTitle="General"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Drug-Update-Screen&anchor=general-section"
            >
              <StyledRowDiv className="row">
                <div className="col-12">
                  <TextAreaInput
                    id="storageConnectionString"
                    rows="2"
                    label="Source Drug File Storage Connection String"
                    onChange={handleChange}
                    placeholder="Source Drug File Storage Connection String"
                    name="storageConnectionString"
                    value={changes.storageConnectionString}
                    error={errors.storageConnectionString}
                    autoFocus={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="drugFileLocation"
                    label="Source Drug File Storage Location"
                    onChange={handleChange}
                    placeholder="Source Drug File Storage Location"
                    name="drugFileLocation"
                    value={changes.drugFileLocation || ""}
                    error={errors.drugFileLocation}
                  />
                </div>
                <div className="col-12">
                  <TextAreaInput
                    id="sqlManualDrugRecordsConnectionString"
                    rows="2"
                    label="Manual Source Database Connection String"
                    onChange={handleChange}
                    placeholder="Manual Source Database Connection String"
                    name="sqlManualDrugRecordsConnectionString"
                    value={changes.sqlManualDrugRecordsConnectionString}
                    error={errors.sqlManualDrugRecordsConnectionString}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                <div className="col-12">
                  <TextAreaInput
                    id="databaseConnectionString"
                    rows="2"
                    label="Target Database Connection String"
                    onChange={handleChange}
                    placeholder="Target Database Connection String"
                    name="databaseConnectionString"
                    value={changes.databaseConnectionString}
                    error={errors.databaseConnectionString}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="databaseId"
                    label="Target Database Id"
                    onChange={handleChange}
                    placeholder="Target Database Id"
                    name="databaseId"
                    value={changes.databaseId || ""}
                    error={errors.databaseId}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="container"
                    label="Target Container"
                    onChange={handleChange}
                    placeholder="Target Container"
                    name="container"
                    value={changes.container || ""}
                    error={errors.container}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="bulkRecordPageSize"
                    label="Bulk Record Page Size"
                    onChange={handleChange}
                    placeholder="Bulk Record Page Size"
                    name="bulkRecordPageSize"
                    value={changes.bulkRecordPageSize}
                    error={errors.bulkRecordPageSize}
                    isNumber={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="bulkRecordWaitBetweenPages"
                    label="Bulk Record Wait Between Pages"
                    onChange={handleChange}
                    placeholder="Bulk Record Wait Between Pages"
                    name="bulkRecordWaitBetweenPages"
                    value={changes.bulkRecordWaitBetweenPages}
                    error={errors.bulkRecordWaitBetweenPages}
                    isNumber={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <CheckboxInput
                    id="active"
                    label="Active"
                    onChange={handleCheckboxChange}
                    placeholder=""
                    name="active"
                    showLabelInline={false}
                    checked={changes.active}
                    error={errors.active}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Run Status"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Drug-Update-Screen&anchor=run-status-section"
            >
              {updateState?.lastStarted ? (
                <DbUtilsStatusSection
                  isAppRunning={isSyncAppRunning}
                  lastStarted={updateState.lastStarted}
                  lastCompleted={updateState.lastCompleted}
                  lastError={updateState.lastError}
                  isDbUpdate={true}
                />
              ) : (
                <>Loading...</>
              )}
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Last Run Info"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Database/Drug-Update-Screen&anchor=last-run-info-section"
            >
              {updateState.lastStarted === null ? (
                <p>The drug update has never been run.</p>
              ) : (
                <>
                  <StyledRowDiv className="row">
                    <div className="col-6">
                      <ReadOnly
                        id="lastStarted"
                        label="Last Started"
                        name="lastStarted"
                        value={formatDateTimeUtcZoneForDisplay(
                          updateState.lastStarted
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <ReadOnly
                        id="lastCompleted"
                        label="Last Completed"
                        name="lastCompleted"
                        value={formatDateTimeUtcZoneForDisplay(
                          updateState.lastCompleted
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Result</label>
                        <div className="field">
                          <StatusPill
                            status={
                              updateState.lastError?.errorState ??
                              false === true
                                ? _.toLower(
                                    updateState.lastError?.errorMessage
                                  )?.indexOf("cancel") >= 0
                                  ? "Cancelled"
                                  : "Fail"
                                : "Success"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </StyledRowDiv>
                  {(updateState.lastError?.errorState ?? false === true) && (
                    <StyledRowDiv className="row" style={{ marginTop: "5px" }}>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Message</label>
                          <div className="field">
                            {updateState.lastError?.errorMessage ?? ""}
                          </div>
                        </div>
                      </div>
                    </StyledRowDiv>
                  )}
                </>
              )}
            </ExpandCollapseDetailSection>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default DbDrugUpdate;
