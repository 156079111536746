import React, { useState, useEffect } from "react";

import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  emptyTestProcessorConfig,
  emptyTestProcessorResponse,
} from "../../../viewmodels/testProcessorVm";
import TextAreaInput from "../../common/input/TextAreaInput";
import { LogCommon, filterLogs } from "../logs/LogCommon";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertUTCDateToLocal,
  handleCollapseExpandSection,
  jsonToArray,
  logInitial,
  packetParserDisplayTypes,
} from "../../../services/General";
import CheckboxInput from "../../common/input/CheckboxInput";
import {
  TestParserTabs,
  useTestProcessor,
} from "../../../contexts/TestProcessorContext";
import { usePacketParser } from "../../../contexts/PacketParserContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { apiRunTestProcessor } from "../../../api/TestProcessingApi";
import { apiLoadPacket } from "../../../api/ClaimApi";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import ActionMenu from "../../common/ui/ActionMenu";
import styled from "styled-components";
import TextInput from "../../common/input/TextInput";
import useApi from "../../../hooks/useApi";
import AdvancedPacketEditor from "../../common/ui/AdvancedPacketEditor";
import TabControl from "../../common/layout/TabControl";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import TestResultsTable from "./TestResultsTable";
import {
  emptyPacketRequest,
  emptyPacketResponse,
} from "../../../viewmodels/packetParserVm";
import HelpLink from "../../common/ui/HelpLink";
import SelectInput from "../../common/input/SingleSelect";

function TestProcessor() {
  const navigate = useNavigate();
  const params = useParams();
  const { testProcessorData, setTestProcessorData } = useTestProcessor();
  const { packetParserData, setPacketParserData } = usePacketParser();
  const { loading, api: apiRun } = useApi(apiRunTestProcessor);
  const { loading: loadingPacketData, api: apiLoadPacketData } =
    useApi(apiLoadPacket);
  const [editMode, setEditMode] = useState(false);
  const [autoRun, setAutoRun] = useState(false);
  const [errors, setErrors] = useState({});
  const [packetId, setPacketId] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [isProcessedDateChanged, setIsProcessedDateChanged] = useState(false);
  const [isFromTestScreen, setIsFromTestScreen] = useState(false);
  const [loadingTestResponse, setLoadingTestResponse] = useState(false);
  const [testParsePacketRequest, setTestParsePacketRequest] = useState(null);
  const [testResponseFieldsArray, setTestResponseFieldsArray] = useState([]);
  const [actionMenuSelection, setActionMenuSelection] =
    useState("Summary logs only");
  const [collapsedState, setCollapsedState] = useState([
    { name: "Request Parameters", collapsed: false },
    { name: "Test Results", collapsed: false },
    { name: "Log Output", collapsed: false },
  ]);

  const logLevelActions = [
    { label: "Summary logs only", value: "Summary" },
    { label: "Summary logs including Debug", value: "Debug" },
    { label: "Summary logs including Trace", value: "Trace" },
  ];

  const logs =
    (testProcessorData.testProcessorResponse || emptyTestProcessorResponse)
      .logs || [];
  const logType =
    (testProcessorData.testProcessorConfig || emptyTestProcessorConfig)
      .logType || [];
  const textSearch =
    (testProcessorData.testProcessorConfig || emptyTestProcessorConfig)
      .textSearch || "";

  const emptyTestResponseValues = {
    F5: "",
    F6: "",
    F7: "",
    F9: "",
    AN: "",
    FB: [],
  };

  const displayType = packetParserData
    ? packetParserData.displayType
    : { label: "Condensed View", value: "condensed" };

  const filteredLogs = filterLogs(logs, logType, textSearch);

  const requestFields =
    jsonToArray(packetParserData.packetResponse.requestJson) || [];
  const responseFields =
    jsonToArray(packetParserData.packetResponse.responseJson) || [];

  useEffect(() => {
    if (autoRun) {
      handleTest();
      setAutoRun(false);
    }
  }, [autoRun]);

  useEffect(() => {
    // jon, 4/26/24: First check local storage for a packet request coming from the test processor screen in another tab
    if (!_.isEmpty(localStorage["TEST_PROCESSOR_REQUEST"])) {
      setIsFromTestScreen(true);
      const storageRequest = JSON.parse(localStorage["TEST_PROCESSOR_REQUEST"]);
      localStorage.removeItem("TEST_PROCESSOR_REQUEST");

      var storagePacketId = !_.isEmpty(storageRequest.claimId)
        ? storageRequest.claimId
        : "00000000-0000-0000-0000-000000000000";
      setPacketId(storagePacketId);

      loadPacket(storagePacketId, storageRequest, true);
      return;
    }

    const paramPacketId =
      (params && params.id) || "00000000-0000-0000-0000-000000000000";

    setPacketId(paramPacketId);

    const hasPacketRequest =
      packetParserData.packetRequest.requestContents !== "";

    if (hasPacketRequest) {
      // Reset form to be ready to process new request.
      preparePacketData(packetParserData.packetRequest, true);
    } else if (
      !_.isEmpty(paramPacketId) &&
      paramPacketId !== "00000000-0000-0000-0000-000000000000"
    ) {
      // Load the packet parser data from the claim
      loadPacket(paramPacketId);
    } else {
      // Put form in edit mode so a packet request can be manually entered
      setEditMode(true);
    }
  }, [params?.id]);

  // Look for a navigation event to the packet processor. This is needed because hook operations do not support a
  //  callback or using .then like the old code used to do
  useEffect(() => {
    if (!_.isEmpty(packetParserData.navigateToUrl)) {
      navigate(packetParserData.navigateToUrl);
    }
  }, [packetParserData.navigateToUrl]);

  async function loadPacket(paramPacketId, request, doAutoRunOverride = false) {
    const doAutoRun = !request || doAutoRunOverride;

    const newRequest = request
      ? { ...emptyPacketRequest, ...request }
      : { ...emptyPacketRequest };

    apiLoadPacketData.call(
      { id: paramPacketId, claimRequest: newRequest },
      (result) => {
        newRequest.requestContents = result.requestContents;
        newRequest.responseContents = result.responseContents;
        newRequest.processedDate = _.isEmpty(result.processedDate)
          ? newRequest.processingDate
          : result.processedDate;
        newRequest.claimId = paramPacketId;
        newRequest.transactionIndex = result.transactionIndex || 0;
        savePacketRequest(newRequest);
        savePacketResponse(result);
        preparePacketData(newRequest, doAutoRun);
      }
    );
  }

  function preparePacketData(packetRequest, doAutoRun) {
    const updatedRequest = {
      ...emptyTestProcessorConfig,
      requestContents: packetRequest.requestContents,
      processedDate: formatDateTime(
        convertUTCDateToLocal(packetRequest.processedDate),
        true,
        true
      ),
    };

    // Expand parameters in case another test was already run
    handleCollapseExpandSection(
      "Request Parameters",
      false,
      collapsedState,
      setCollapsedState
    );

    if (updatedRequest.processedDate === null) {
      updatedRequest.processedDate = formatDateTime(new Date(), true, true);
    }

    setTestProcessorData({
      type: ContextProviderActions.saveTestProcessorConfig,
      payload: updatedRequest,
    });

    if (doAutoRun) {
      saveTestProcessorResponse({ ...emptyTestProcessorResponse });
      setAutoRun(true);
    }
  }

  function savePacketRequest(packetRequest) {
    setPacketParserData({
      type: ContextProviderActions.savePacketRequest,
      payload: packetRequest,
    });
  }

  function savePacketResponse(packetResponse) {
    let packetResponseToUse = packetResponse;

    // jon, 4/26/24: If we received the packet from local storage, use the field data response created from the test.
    if (!_.isEmpty(localStorage["TEST_PROCESSOR_REQUEST"])) {
      const storageRequest = JSON.parse(localStorage["TEST_PROCESSOR_REQUEST"]);
      localStorage.removeItem("TEST_PROCESSOR_REQUEST");

      packetResponseToUse = {
        ...emptyPacketResponse,
        requestJson: packetResponse.requestJson, // We need original request Json fields for the advanced parser
        responseJson: storageRequest?.responseJson || "",
      };
    }

    setPacketParserData({
      type: ContextProviderActions.savePacketResponse,
      payload: packetResponseToUse,
    });
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    await handleTest(event);
  }

  async function handleTest() {
    if (!formIsValid()) return;
    const newConfig = { ...testProcessorData.testProcessorConfig };

    // Collapse parameters section when running test
    handleCollapseExpandSection(
      "Request Parameters",
      true,
      collapsedState,
      setCollapsedState
    );

    const reqContents = newConfig.requestContents;

    const processDate = newConfig.processedDate
      ? getDateFromString(newConfig.processedDate).toISOString()
      : "";
    const request = {
      claimId: packetId,
      originalResponse: packetParserData.packetRequest.responseContents,
      requestContents: reqContents,
      processingDate: processDate,
      overrideProcessedDate: isProcessedDateChanged,
      saveHistory: newConfig.saveHistory,
      clearRuleCache: newConfig.clearRuleCache,
      serializeLogItems: newConfig.serializeLogItems,
    };

    setTestParsePacketRequest(null);
    setTestResponseFieldsArray([]);

    apiRun.call(request, async (result) => {
      saveTestProcessorResponse(result);
      request.responseContents = result.responseContents;

      if (packetId === "00000000-0000-0000-0000-000000000000") {
        loadPacket(packetId, request);
      } else {
        const newRequest = { ...emptyPacketRequest, ...request };
        setTestParsePacketRequest(newRequest);

        // If the Response Packets tab is already open, load the test response fields
        if (testProcessorData.activeTab === TestParserTabs.Tab2) {
          callParsePacketForTestResponse(newRequest);
        }
      }
    });
  }

  async function callParsePacketForTestResponse(newRequest) {
    setLoadingTestResponse(true);

    await apiLoadPacketData.call(
      { id: packetId, claimRequest: newRequest },
      (result) => {
        setLoadingTestResponse(false);

        // We only need the response fields for this to use on the Response Packets tab
        const testFieldsArray =
          (result.responseJson || []).length > 0
            ? jsonToArray(result.responseJson)
            : [];
        setTestResponseFieldsArray(testFieldsArray);
      }
    );
  }

  function saveTestProcessorConfig(config) {
    setTestProcessorData({
      type: ContextProviderActions.saveTestProcessorConfig,
      payload: config,
    });
  }

  function saveTestProcessorResponse(response) {
    setTestProcessorData({
      type: ContextProviderActions.saveTestProcessorResponse,
      payload: response,
    });
  }

  function getDateFromString(dateStr) {
    let ds = dateStr;
    let savedMs = "";
    let date;

    // Before converting date, take ms off (if any) since this causes iOS to fail. We will add it back afterwards.
    const pos = ds.indexOf(".");
    if (pos >= 0) {
      savedMs = ds.substring(pos + 1);
      ds = ds.substring(0, pos);
    }
    date = new Date(ds);

    if (savedMs !== "") {
      date.setMilliseconds(parseInt(savedMs, 10));
    }

    return date;
  }
  function formatDateTime(
    date,
    includeSeconds = false,
    includeMilliseconds = false
  ) {
    var d;

    if (Object.prototype.toString.call(date) === "[object Date]") {
      d = date;
    } else {
      d = getDateFromString(date);
    }

    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = "" + d.getHours(),
      minute = "" + d.getMinutes(),
      second = "" + d.getSeconds(),
      ms = "" + d.getMilliseconds();

    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;
    if (second.length < 2) second = "0" + second;

    if (
      _.isNaN(month) ||
      _.isNaN(day) ||
      _.isNaN(year) ||
      _.isNaN(hour) ||
      _.isNaN(minute) ||
      _.isNaN(second) ||
      _.isNaN(ms)
    )
      return null;

    var testDate = `${month}/${day}/${year} ${hour}:${minute}${
      includeSeconds ? ":" + second : ""
    }${includeMilliseconds ? "." + ms : ""}`;

    if (testDate.startsWith("12/31/1969")) return null;
    return testDate;
  }

  function formIsValid() {
    const _errors = {};

    // Make sure Request Contents is not empty and contains the expected GUID ID or the API will fail
    // const reGuidCheck =
    //   /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/;
    // if (
    //   testProcessorData.testProcessorConfig.requestContents.trim() === "" ||
    //   testProcessorData.testProcessorConfig.requestContents.match(
    //     reGuidCheck
    //   ) === null
    // )
    //   _errors.requestContents =
    //     "Request Contents must be a valid, non-empty request packet";

    if (
      formatDateTime(
        testProcessorData.testProcessorConfig.processedDate,
        true
      ) === null
    ) {
      _errors.processedDate = "Processed Date must be a valid datetime";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleConfigChange({ target }) {
    saveTestProcessorConfig({
      ...testProcessorData.testProcessorConfig,
      [target.name]: target.value,
    });
  }

  function handleProcessedDateChange({ target }) {
    const oldValue = testProcessorData.testProcessorConfig.processedDate || "";

    if (oldValue !== target.value) {
      setIsProcessedDateChanged(true);
    }

    saveTestProcessorConfig({
      ...testProcessorData.testProcessorConfig,
      processedDate: target.value,
    });
  }

  function handleCheckboxChange({ target }) {
    let changed = {
      ...testProcessorData.testProcessorConfig,
      [target.name]: target.checked,
    };
    saveTestProcessorConfig(changed);
  }

  function handleLogTypeFilter(value, label) {
    // Default is Summary only - Info, Warn, Error, and Fatal
    let logTypes = [...logInitial];

    if (value === "Debug" || value === "Trace") {
      logTypes.push({ label: "Debug", value: "Debug" });
    }
    if (value === "Trace") {
      logTypes.push({ label: "Trace", value: "Trace" });
    }

    setTestProcessorData({
      type: ContextProviderActions.saveTestProcessorConfig,
      payload: {
        ...testProcessorData.testProcessorConfig,
        logType: logTypes,
      },
    });
    setActionMenuSelection(label);
  }

  function setScreenSearchInput(text) {
    const newConfig = { ...testProcessorData.testProcessorConfig };
    newConfig.highlightText = text;

    setTestProcessorData({
      type: ContextProviderActions.saveTestProcessorConfig,
      payload: newConfig,
    });
  }

  async function setActiveTab(activeTab) {
    setTestProcessorData({
      type: ContextProviderActions.setActiveTestParserTab,
      payload: activeTab,
    });

    if (
      activeTab === TestParserTabs.Tab2 &&
      (testResponseFieldsArray || []).length === 0
    ) {
      await callParsePacketForTestResponse(testParsePacketRequest);
    }
  }

  function handleDisplayTypeChanged(option) {
    handleSetDisplayType(option.value);
  }

  function handleSetDisplayType(newDisplayType) {
    const d = packetParserDisplayTypes.find(
      (dt) => dt.value === newDisplayType
    );
    if (!d) {
      console.log("Packet Parser display type not found: " + newDisplayType);
      return;
    }

    setPacketParserData({
      type: ContextProviderActions.setPacketParserDisplayType,
      payload: { ...d },
    });
  }

  if (loadingPacketData && !loadingTestResponse) {
    return <Spinner />;
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return to previous screen"
            className="btn btn-link"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Tools/Test-Processor-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>Test Processor</h1>
          <div
            className="flex-row-with-wrap"
            style={{ marginTop: "-10px", width: "225px" }}
          >
            <SelectInput
              id="displayType"
              name="displayType"
              label=""
              labelStyle={{ display: "none" }}
              options={[...packetParserDisplayTypes]}
              value={displayType}
              onChange={handleDisplayTypeChanged}
              placeholder="Select view"
              controlStyle={{
                width: "180px",
              }}
            />
          </div>
          <StyledHeaderRowButtonDiv>
            <button
              value="Packet Parser"
              type="button"
              onClick={() => {
                const req = {
                  processedDate:
                    testProcessorData.testProcessorConfig.processedDate,
                  requestContents:
                    testProcessorData.testProcessorConfig.requestContents || "",
                  responseContents:
                    testProcessorData.testProcessorResponse.responseContents ||
                    "",
                };
                const url =
                  packetParserData.packetResponse.claimId &&
                  packetParserData.packetResponse.claimId !==
                    "00000000-0000-0000-0000-000000000000"
                    ? "/packetparser/" + packetParserData.packetResponse.claimId
                    : "/packetparser";

                setPacketParserData({
                  type: ContextProviderActions.savePacketRequestAndNavigate,
                  payload: { packetRequest: req, url },
                });
              }}
              className="btn btn-secondary"
            >
              Packet Parser
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                marginLeft: "12px",
                display: "flex",
                alignItems: "center",
                minWidth: "118px",
              }}
            >
              <span className="material-icons">play_arrow</span>
              Run Test
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>
        <br />
        <ExpandCollapseDetailSection
          sectionTitle="Request Parameters"
          collapsedState={collapsedState}
          setCollapsedState={setCollapsedState}
          helpLink="/Tools/Test-Processor-Screen&anchor=request-parameters"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <StyledRequestResponseLabel htmlFor="requestContents">
              Request
            </StyledRequestResponseLabel>

            {!editMode ? (
              <>
                <button
                  value="Edit"
                  type="button"
                  onClick={() => setEditMode(true)}
                  className="btn btn-secondary"
                >
                  Edit
                </button>
              </>
            ) : (
              <>
                {" "}
                <button
                  value="Cancel"
                  type="button"
                  onClick={() => setEditMode(false)}
                  className="btn btn-secondary"
                >
                  Done
                </button>
              </>
            )}
          </div>

          {editMode ? (
            <TextAreaInput
              id="requestContents"
              label=""
              labelStyle={{ display: "none" }}
              onChange={handleConfigChange}
              placeholder="Request Contents"
              name="requestContents"
              value={testProcessorData.testProcessorConfig.requestContents}
              error={errors.requestContents}
            />
          ) : (
            <AdvancedPacketEditor
              id="requestContents"
              fields={requestFields}
              value={
                testProcessorData.testProcessorConfig.requestContents || ""
              }
              isRequest={true}
              isReadOnly={true}
              displayType={displayType.value}
              setDisplayType={handleSetDisplayType}
            />
          )}

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-6 col-md-3">
              <div style={{ maxWidth: "220px" }}>
                <TextInput
                  id="processedDate"
                  name="processedDate"
                  label="Processed Date"
                  value={
                    testProcessorData.testProcessorConfig.processedDate || ""
                  }
                  placeholder="MM/DD/YYYY HH:MM:SS"
                  onChange={handleProcessedDateChange}
                  error={errors.processedDate}
                  showTimeInput={true}
                />
              </div>
            </div>
            <div className="col-6 col-md-3">
              <CheckboxInput
                id="saveHistory"
                label="Save History"
                onChange={handleCheckboxChange}
                placeholder="Save History"
                name="saveHistory"
                checked={testProcessorData.testProcessorConfig.saveHistory}
                error={errors.saveHistory}
              />
            </div>
            <div className="col-6 col-md-3">
              <CheckboxInput
                id="clearRuleCache"
                label="Clear Rule Cache"
                onChange={handleCheckboxChange}
                placeholder="Clear Rule Cache"
                name="clearRuleCache"
                checked={testProcessorData.testProcessorConfig.clearRuleCache}
                error={errors.clearRuleCache}
              />
            </div>
            <div className="col-6 col-md-3">
              <CheckboxInput
                id="serializeLogItems"
                label="Serialize Log Items"
                onChange={handleCheckboxChange}
                placeholder="Serialize Log Items"
                name="serializeLogItems"
                checked={
                  testProcessorData.testProcessorConfig.serializeLogItems
                }
                error={errors.serializeLogItems}
              />
            </div>
          </div>
        </ExpandCollapseDetailSection>
      </form>

      {filteredLogs && filteredLogs.length === 0 && !loading ? (
        <></>
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <ExpandCollapseDetailSection
                sectionTitle="Test Results"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Tools/Test-Processor-Screen&anchor=test-results"
              >
                <TabControl
                  activeTab={testProcessorData.activeTab}
                  setActiveTab={setActiveTab}
                  showTabHeader={true}
                  sticky={false}
                  tab1Title={TestParserTabs.Tab1}
                  tab2Title={TestParserTabs.Tab2}
                  tab1Children={
                    <>
                      <TestResultsTable
                        transactionIndex={
                          packetParserData.packetRequest.transactionIndex
                        }
                        originalResponseFields={responseFields}
                        testResponseFields={
                          testProcessorData.testProcessorResponse
                            .responseValues || { ...emptyTestResponseValues }
                        }
                        isFromTestScreen={isFromTestScreen}
                      />
                    </>
                  }
                  tab2Children={
                    <>
                      <StyledResponseDiv>
                        <div className="field-group">
                          <label htmlFor="responseContents">
                            Original Response Contents
                          </label>
                          {packetParserData.packetRequest.responseContents &&
                          !isFromTestScreen ? (
                            <AdvancedPacketEditor
                              id="originalResponseContents"
                              fields={responseFields}
                              value={
                                packetParserData.packetRequest
                                  .responseContents || ""
                              }
                              isRequest={false}
                              isReadOnly={true}
                              displayType={displayType.value}
                              setDisplayType={handleSetDisplayType}
                            />
                          ) : (
                            <>
                              <br />
                              <i>Original response not found</i>
                            </>
                          )}
                        </div>
                      </StyledResponseDiv>

                      <StyledResponseDiv>
                        {loadingPacketData && loadingTestResponse ? (
                          <Spinner
                            spinnerStyle={{
                              height: "200px",
                              lineHeight: "200px",
                            }}
                          />
                        ) : (
                          <div className="field-group">
                            <label htmlFor="responseContents">
                              Test Response Contents
                            </label>
                            {testProcessorData.testProcessorResponse
                              .responseContents && (
                              <AdvancedPacketEditor
                                id="responseContents"
                                fields={testResponseFieldsArray}
                                value={
                                  testProcessorData.testProcessorResponse
                                    .responseContents || ""
                                }
                                isRequest={false}
                                isReadOnly={true}
                                displayType={displayType.value}
                                setDisplayType={handleSetDisplayType}
                              />
                            )}
                          </div>
                        )}
                      </StyledResponseDiv>
                    </>
                  }
                />
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Log Output"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Tools/Test-Processor-Screen&anchor=log-output"
              >
                <StyledHeaderRowDiv style={{ marginBottom: "8px" }}>
                  <InstantSearchInput
                    id="screenSearchInput"
                    onChange={setScreenSearchInput}
                    value={testProcessorData.testProcessorConfig.highlightText}
                  />
                  <ActionMenu
                    title={actionMenuSelection}
                    items={logLevelActions}
                    onSelectAction={handleLogTypeFilter}
                  />
                </StyledHeaderRowDiv>

                <LogCommon
                  logs={filteredLogs}
                  highlightText={
                    testProcessorData.testProcessorConfig.highlightText
                  }
                  noResultsFoundMsg="Click Run Test to see results"
                  noResultsFoundIcon="directions_run"
                />
              </ExpandCollapseDetailSection>
            </>
          )}
        </>
      )}
    </Authorize>
  );
}

const StyledRequestResponseLabel = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-dark);
`;

const StyledResponseDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 26px;

  div.field-group div.field {
    margin-top: 4px;
    color: var(--text-medium);
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default TestProcessor;
