import React from "react";
import { Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useUserPreferences } from "../contexts/UserPreferencesContext";
import Footer from "./common/layout/Footer";
import Header from "./common/layout/Header";
import CoverageTrackingList from "./common/ui/CoverageTrackingList";
import ReactTooltip from "react-tooltip";
import { useMobile } from "../hooks/useMobile";
import WebSocketHubConnectionLoader from "../services/WebSocketHubConnectionLoader";

function AppContent({ routes }) {
  const { isMobileSize } = useMobile();
  const { userPrefs } = useUserPreferences();

  const LightMode = React.lazy(() => import("./common/ui/LightModeStyles"));
  const DarkMode = React.lazy(() => import("./common/ui/DarkModeStyles"));

  return (
    <>
      <WebSocketHubConnectionLoader />
      <React.Suspense fallback={<></>}>
        {!userPrefs.darkMode && <LightMode />}
        {userPrefs.darkMode && <DarkMode />}
      </React.Suspense>
      <ReactTooltip
        className="app-tooltip"
        delayHide={500}
        effect="solid"
        multiline={true}
        place={isMobileSize ? "top" : "left"}
      />
      <div
        className={
          userPrefs.menuToggleExpanded
            ? "container-fluid"
            : "container-fluid nav-menu-closed"
        }
      >
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          position="bottom-right"
          newestOnTop={true}
          limit={10}
          theme={userPrefs.darkMode ? "dark" : "light"}
        />
        <Header />
        {userPrefs.menuToggleExpanded ? (
          <></>
        ) : (
          <>
            <div
              className="content"
              style={{
                marginTop:
                  userPrefs.coverageTrackingMode &&
                  !userPrefs.coverageTrackingVisibility
                    ? "40px"
                    : "0",
              }}
            >
              {userPrefs.coverageTrackingMode &&
                userPrefs.coverageTrackingVisibility && (
                  <CoverageTrackingList />
                )}
              <Routes>{routes}</Routes>
            </div>
          </>
        )}
        <Footer />
      </div>
    </>
  );
}

export default AppContent;
