import React, { Fragment } from "react";
import { useMobile } from "../../../hooks/useMobile";
import { StyledMobileDenseTable } from "../../common/layout/CommonStyledControls";

export function RejectionParser({ typeDesc, rejections }) {
  const { isMobileSize } = useMobile();

  if (rejections && rejections.length > 0) {
    return (
      <table className="table" style={{ tableLayout: "unset" }}>
        <thead>
          <tr>
            <th colSpan="100" className="table-header">
              {typeDesc}
            </th>
          </tr>
          {!isMobileSize && (
            <tr>
              <th style={{ width: "15%" }}>Code</th>
              <th style={{ width: "10%" }}>Tx</th>
              <th style={{ width: "35%" }}>Desc</th>
              <th style={{ width: "40%" }}>Message</th>
            </tr>
          )}
        </thead>
        <tbody>
          {rejections.map((rej, idx) => {
            return (
              <Fragment key={idx}>
                <tr key={"tr" + idx}>
                  {isMobileSize ? (
                    <>
                      <td
                        className="parser-field"
                        style={{ width: "100%", padding: "8px" }}
                      >
                        <StyledMobileDenseTable>
                          <tbody>
                            <tr>
                              <td className="dense-label">Code</td>
                              <td>{rej.code}</td>
                            </tr>
                            <tr>
                              <td className="dense-label">Tx</td>
                              <td>{rej.transactionIndex + 1}</td>
                            </tr>
                            <tr>
                              <td className="dense-label">Desc</td>
                              <td>{rej.description}</td>
                            </tr>
                            <tr>
                              <td className="dense-label">Message</td>
                              <td>{rej.message}</td>
                            </tr>
                          </tbody>
                        </StyledMobileDenseTable>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="parser-field" style={{ width: "15%" }}>
                        {rej.code}
                      </td>
                      <td className="parser-field" style={{ width: "10%" }}>
                        {rej.transactionIndex + 1}
                      </td>
                      <td className="parser-field" style={{ width: "35%" }}>
                        {rej.description}
                      </td>
                      <td className="parser-field" style={{ width: "40%" }}>
                        {rej.message}
                      </td>
                    </>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    return <></>;
  }
}
