import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyPrescriber,
  emptyPrescriberSearch,
} from "../viewmodels/prescribersVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadPrescribers:
      return {
        ...state,
        prescribers: action.payload.prescribers,
        count: action.payload.count,
      };

    case ContextProviderActions.savePrescriberSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadPrescriber:
      return {
        ...state,
        prescriber: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in PrescribersContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  prescribers: [],
  prescriber: emptyPrescriber,
  search: emptyPrescriberSearch,
  count: 0,
};

const Prescribers = createContext({
  prescribersData: initialState,
  setPrescribersData: () => null,
});

// eslint-disable-next-line react/prop-types
const PrescribersProvider = ({ children }) => {
  const [prescribersData, setPrescribersData] = useReducer(
    reducer,
    initialState
  );

  return (
    <Prescribers.Provider value={{ prescribersData, setPrescribersData }}>
      {children}
    </Prescribers.Provider>
  );
};

export default PrescribersProvider;
export const usePrescribers = () => useContext(Prescribers);
