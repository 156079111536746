import React, { useState, useEffect } from "react";
import _ from "lodash";
import TestGroupsList from "./TestGroupsList";
import TestGroupSearchForm from "./TestGroupSearchForm";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import {
  createViewModel,
  emptyTestGroupSearch,
} from "../../../viewmodels/testGroupsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useTestGroups } from "../../../contexts/TestGroupsContext";
import {
  apiLoadTestGroups,
  apiDeleteTestGroup,
  apiCopyTestGroup,
  apiStartRunForTestOrGroup,
  apiCancelRunForTestOrGroup,
} from "../../../api/TestApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import { useNavigate } from "react-router-dom";
import DuplicateRecordDialog from "../../dialogs/DuplicateRecordDialog";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import useApi from "../../../hooks/useApi";
import { formatDecimal } from "../../../services/General";
import HelpLink from "../../common/ui/HelpLink";
import SimpleCompletionBar from "../../common/ui/SimpleCompletionBar";
import { useTests } from "../../../contexts/TestsContext";
import TestsWebSocketHubConnections from "../tests/TestsWebSocketHubConnections";

function TestGroups() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { testGroupsData, setTestGroupsData } = useTestGroups();
  const { testsData } = useTests();
  const { loading, api: apiLoad } = useApi(apiLoadTestGroups);
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteTestGroup);
  const { loading: copying, api: apiCopy } = useApi(apiCopyTestGroup);
  const { loading: startingTestRun, api: apiStartRunForTest } = useApi(
    apiStartRunForTestOrGroup
  );
  const { loading: cancellingTestRun, api: apiCancelRunForTest } = useApi(
    apiCancelRunForTestOrGroup
  );
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState(true);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const { isMobileSize } = useMobile();
  const [testRunIsWaitingToStart, setTestRunIsWaitingToStart] = useState(false);
  const [testRunIsWaitingToCancel, setTestRunIsWaitingToCancel] =
    useState(false);
  const [testRunInProgress, setTestRunInProgress] = useState(false);
  const [testRunIsEnding, setTestRunIsEnding] = useState(false);
  const [currentRunTestGroupIndex, setCurrentRunTestGroupIndex] = useState(-1);
  const [currentRunTestGroupDescription, setCurrentRunTestGroupDescription] =
    useState("");

  let testGroups = [];
  if (testGroupsData && testGroupsData.testGroups) {
    testGroups = testGroupsData.testGroups;
  }

  useEffect(() => {
    // Do we have anything in process for this user?
    if (
      testsData.testRunnerUpdateState &&
      testsData.testRunnerUpdateState.queuesByUser &&
      testsData.testRunnerUpdateState.queuesByUser[auth.userName]
    ) {
      var queueItem =
        testsData.testRunnerUpdateState.queuesByUser[auth.userName];

      //Set test results for finished tests
      const finishedTests = queueItem.queue.filter(
        (t) =>
          (t.status === "Done" || t.status === "Error") && t.isGroup === true
      );
      setTestResults(finishedTests);

      // Do we have any pending or in progress tests?
      const hasPendingTests =
        queueItem &&
        queueItem.queue.filter(
          (t) =>
            (t.status === "InProgress" || t.status === "Pending") &&
            t.isGroup === true
        ).length > 0;

      // If none left, indicate run is complete
      if (testRunInProgress && !hasPendingTests) {
        setTestRunIsEnding(true);
        setCurrentRunTestGroupIndex(-1);

        // Wait a few seconds and then hide the progress
        window.setTimeout(() => {
          setTestRunInProgress(false);
          setTestRunIsEnding(false);
        }, 4000);

        return;
      }

      if (!queueItem) return;

      // Check which test group is running and set its index. If none, wait until the next state update.
      const testRunning = queueItem.queue.find(
        (t) => t.status === "InProgress" && t.isGroup === true
      );
      const testRunningId = testRunning?.id || "";

      if (testRunInProgress && _.isEmpty(testRunningId)) {
        setCurrentRunTestGroupIndex(-1);
        return;
      }

      // We have a running test group - set the next test to display in completion bar
      setTestRunIsWaitingToStart(false);
      setCurrentRunTestGroupIndex(
        testGroupsData.testGroups.findIndex((t) => t.id === testRunningId)
      );
      setCurrentRunTestGroupDescription(testRunning?.description || "");
    }
  }, [testsData.testRunnerUpdateState]);

  // The API call to load data is actually a side effect in most cases since a dispatch to setTestGroupsData must usually
  //  happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //  useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadTestGroups();
    }
  }, [auth.authenticated, loadData]);

  async function loadTestGroups(
    startingBulkRun = false,
    overrideSearch = null
  ) {
    apiLoad.call(
      overrideSearch === null ? testGroupsData.search : overrideSearch,
      (result) => {
        setLoadData(false);

        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setTestGroupsData({
          type: ContextProviderActions.loadTestGroups,
          payload: {
            testGroups: vms,
            count,
          },
        });

        // If we are starting a bulk run, go into run mode
        if (startingBulkRun) {
          kickOffTestRunProcess(vms);
        }
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setTestResults(finishedTests) {
    const newRows = [...testGroupsData.testGroups];
    if (finishedTests.length === 0) return;

    let rowTest;
    for (let i = 0; i < finishedTests.length; i++) {
      rowTest = newRows.find((t) => t.id === finishedTests[i].id);
      if (rowTest) {
        rowTest.lastRunDate = finishedTests[i].lastRunDate;
        rowTest.lastRunBy = finishedTests[i].lastRunBy;
        rowTest.lastRunDurationMilliseconds =
          finishedTests[i].lastRunDurationMilliseconds;
        rowTest.lastRunResultStatus = finishedTests[i].lastRunResultStatus;
      }
    }

    setTestGroupsData({
      type: ContextProviderActions.loadTestGroups,
      payload: {
        testGroups: newRows,
        count: newRows.length,
      },
    });
  }

  function setSearchChanges(search) {
    setTestGroupsData({
      type: ContextProviderActions.saveTestGroupSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyTestGroupSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : testGroupsData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = testGroupsData.search.orderBy.indexOf(`${event.target.id}+`);
    var indexDesc = testGroupsData.search.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...testGroupsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...testGroupsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...testGroupsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...testGroupsData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...testGroupsData.search,
      [target.name]: target.value,
    });
  }

  function handleLastRunResultStatusChanged(statuses) {
    setSearchChanges({
      ...testGroupsData.search,
      lastRunResultStatus: statuses,
    });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...testGroupsData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...testGroupsData.search, endDate: date });
  }

  async function deleteTestGroup(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Test group deleted successfully");
      setLoadData(true);
    });
  }

  async function handleDelete(id, name) {
    setDeleteId(id);
    setAltDeleteId(name);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteTestGroup(deleteId);
  }

  const handleCancelModal = () => {
    setShowCopyModal(false);
  };

  function startRun(id) {
    navigate(`/testgroup/${id}/true`);
  }

  function startCopy(id, name) {
    let updated = {
      ...testGroupsData.search,
      oldTestGroupDescription: name,
      copyId: id,
    };
    setSearchChanges(updated);
    setShowCopyModal(true);
  }

  async function copyTestGroup(id, newName) {
    setShowCopyModal(false);
    apiCopy.call({ id: id, newId: newName }, (result) => {
      notifySuccess("Test group copied successfully to " + newName);
      setLoadData(true);
    });
  }

  async function handleCopy(newTestGroupDescription) {
    await copyTestGroup(testGroupsData.search.copyId, newTestGroupDescription);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (testGroupsData.search.description !== "") numFilters++;
    if (testGroupsData.search.lastRunBy !== "") numFilters++;
    if ((testGroupsData.search.lastRunResultStatus || []).length > 0)
      numFilters++;

    return numFilters;
  }

  function performRunTests() {
    // Hide advanced filter when running tests and load all tests
    const newSearch = {
      ...testGroupsData.search,
      pageSize: 10000,
      showAdvancedFilter: false,
    };
    setSearchChanges(newSearch);

    // If not all test groups in filter are showing, load all the test groups.
    if (testGroupsData.testGroups.length < testGroupsData.count) {
      // This will set run mode once load is complete
      loadTestGroups(true, newSearch);
    } else {
      kickOffTestRunProcess(testGroupsData.testGroups);
    }
  }

  function cancelTestRun() {
    const model = { isGroup: true, testGroups: testGroupsData.testGroups };
    model.username = auth.userName;

    setTestRunIsWaitingToCancel(true);

    apiCancelRunForTest.call({ model: model }, async (result) => {
      notifySuccess("Test Group run cancelled. It will stop momentarily...");
    });
  }

  function kickOffTestRunProcess(genTests) {
    const model = { isGroup: true, testGroups: genTests };
    model.username = auth.userName;

    apiStartRunForTest.call({ model: model }, () => {
      setTestRunIsWaitingToStart(true);
      setTestRunInProgress(true);
      notifySuccess(
        "The test run request has been sent. It will begin momentarily..."
      );
    });
  }

  // Run percentage calculation
  let userState = null;
  if (
    testsData.testRunnerUpdateState &&
    testsData.testRunnerUpdateState.queuesByUser &&
    testsData.testRunnerUpdateState.queuesByUser[auth.userName]
  ) {
    userState = testsData.testRunnerUpdateState.queuesByUser[auth.userName];
  }
  let runCompleted = userState === null ? 0 : userState.totalRecordsProcessed;
  let totalToRun = userState === null ? 1 : userState.totalRecordsToProcess;
  let runPercentage = formatDecimal(
    (runCompleted / (totalToRun === 0 ? 1 : totalToRun)) * 100,
    0
  );

  if (testRunIsWaitingToStart) {
    runCompleted = 0;
    totalToRun = 0;
    runPercentage = 0;
  } else if (testRunIsEnding) {
    runPercentage = 100;
  }

  return (
    <Authorize>
      <TestsWebSocketHubConnections />
      <StyledScreenHelpDiv>
        {((testGroupsData.search.showAdvancedFilter && !isMobileSize) ||
          !testGroupsData.search.showAdvancedFilter) && (
          <HelpLink path="/Testing/Test-Groups-List-Screen" label="Help" />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Test Groups</h1>
        <StyledHeaderRowButtonDiv>
          {!testRunInProgress && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/testgroup")}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="material-icons">add</span>
              {"  "}Add Test Group
            </button>
          )}
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      {!testRunInProgress && (
        <GridFreeFormSearchBar
          placeholderText="Search Description, Run by, or Result"
          search={testGroupsData.search}
          setSearch={setSearchChanges}
          numSetFilters={getNumberOfSetFilters()}
          onSubmitSearch={onSubmit}
        />
      )}
      <DuplicateRecordDialog
        title="Duplicate Test Group"
        instructions={`Enter a new description for the test group to be copied from '${testGroupsData.search.oldTestGroupDescription}'.`}
        value={testGroupsData.search.newTestGroupDescription || ""}
        showModal={showCopyModal}
        onCancel={handleCancelModal}
        onCopy={handleCopy}
        placeholder="New Test Group Description"
      />
      <ConfirmDialog
        title="Remove Test Group"
        question={`Are you sure you wish to delete the test group '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              testGroupsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={testGroupsData.search}
            setSearch={setSearchChanges}
            helpLink="/Testing/Test-Groups-List-Screen&anchor=filters"
          >
            <TestGroupSearchForm
              errors={errors}
              search={testGroupsData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onLastRunResultStatusChanged={handleLastRunResultStatusChanged}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
            />
          </GridAdvancedFilter>
        </div>

        {testGroupsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || deleting || copying ? (
              <Spinner />
            ) : (
              <>
                {testRunInProgress ? (
                  <SimpleCompletionBar
                    title={
                      <h4 style={{ fontSize: "20px" }}>
                        {currentRunTestGroupDescription === "Done!" ? (
                          currentRunTestGroupDescription
                        ) : (
                          <>
                            {startingTestRun || testRunIsWaitingToStart
                              ? `Test Group run is starting...`
                              : testRunIsEnding
                              ? `Test Group run complete!`
                              : testRunInProgress
                              ? `Running test group ${currentRunTestGroupDescription}...`
                              : testRunIsWaitingToCancel
                              ? `Cancelling test group run...`
                              : ``}
                          </>
                        )}
                      </h4>
                    }
                    runPercentage={runPercentage}
                    disableCancel={
                      cancellingTestRun || testRunIsWaitingToCancel
                    }
                    onCancel={cancelTestRun}
                  />
                ) : (
                  <>
                    {testGroups.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-secondary btn-with-icon"
                        onClick={performRunTests}
                        style={{ marginLeft: "auto", marginTop: "20px" }}
                      >
                        <span className="material-icons">
                          play_circle_outline
                        </span>
                        {"  "}Run all Test Groups in Filter
                      </button>
                    )}
                  </>
                )}
                <TestGroupsList
                  testGroups={testGroups}
                  currentRunTestGroupIndex={currentRunTestGroupIndex}
                  isRunning={testRunInProgress}
                  onDelete={handleDelete}
                  search={testGroupsData.search}
                  setSearch={async (search) => {
                    setSearchChanges(search);
                    // Only do server-side search if the user didn't just perform a client op only
                    if (!search.isClientOpOnly) {
                      await handleSearch(undefined, search);
                    }
                  }}
                  onSort={handleSort}
                  totalRecords={testGroupsData.count}
                  onCopy={startCopy}
                  onRun={startRun}
                />
              </>
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default TestGroups;
