import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import {
  getHighlightStyle,
  getRowStyle,
  getTextStyle,
  handleIndexedAdd,
  handleIndexedChange,
  handleIndexedRemove,
  handleRowDown,
  handleRowUp,
  handleStagingChange,
} from "./RuleCommonFunctions";

function RuleAreaIncludedRejections({
  isSmallSize,
  isGroupRule,
  includedRejections,
  ruleChanges,
  setRuleChanges,
  handleOpenDates,
  handleIndexedCopy,
}) {
  const navigate = useNavigate();
  const isFiltered = (ruleChanges.textSearch || "").length > 0;

  return (
    <>
      <StyledRowDiv className="row">
        <div className="col-12 overflow-x-scroll">
          <table
            className={`table rule-grid ${isSmallSize ? "not-fixed" : ""}`}
          >
            <colgroup>
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "44%" }} />
              <col style={{ width: "3%" }} />
              {!isGroupRule && (
                <>
                  <col style={{ width: "3%" }} />
                  <col style={{ width: "3%" }} />
                  <col style={{ width: "3%" }} />
                  <col style={{ width: "3%" }} />
                </>
              )}
            </colgroup>
            <thead>
              <tr>
                <th id="attachedArtifact">Artifact/Flag</th>
                <th id="key">Key</th>
                <th id="code">Code</th>
                <th id="level">Level</th>
                <th id="message">Message</th>
                <th id="stagingOnly">Staging</th>
                {!isGroupRule ? (
                  <>
                    <th> </th>
                    <th> </th>
                    <th> </th>
                    <th>
                      <i
                        className="fa fa-plus-circle pointer"
                        onClick={(e) =>
                          handleIndexedAdd(
                            "includedRejections",
                            ruleChanges,
                            setRuleChanges
                          )
                        }
                      ></i>
                    </th>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody>
              {includedRejections.map((ir, irIndex) => {
                return (
                  <Fragment key={irIndex}>
                    <tr key={"irtr" + irIndex} style={getRowStyle(ir)}>
                      <td>
                        <input
                          id="irAttachedArtifact"
                          type="text"
                          name="attachedArtifact"
                          className="form-control"
                          style={getHighlightStyle(ir, true)}
                          placeholder="Artifact/Flag"
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedRejections",
                              e,
                              ir.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value={ir.attachedArtifact || ""}
                        ></input>
                      </td>
                      <td>
                        <input
                          id="irKey"
                          type="text"
                          name="rejectionKey"
                          className="form-control"
                          style={getHighlightStyle(ir, false)}
                          placeholder="Key"
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedRejections",
                              e,
                              ir.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value={ir.rejectionKey || ""}
                        ></input>
                      </td>

                      <td>
                        <input
                          id="irCode"
                          type="text"
                          name="code"
                          className="form-control"
                          style={getTextStyle(ir)}
                          placeholder="Code"
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedRejections",
                              e,
                              ir.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value={ir.code || ""}
                        ></input>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          style={getTextStyle(ir)}
                          id="level"
                          key={irIndex}
                          name="level"
                          value={ir.level}
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedRejections",
                              e,
                              ir.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                        >
                          <option value="Transmission">Transmission</option>
                          <option value="Transaction">Transaction</option>
                        </select>
                      </td>
                      <td>
                        <input
                          id="irMessage"
                          type="text"
                          name="message"
                          className="form-control"
                          style={getTextStyle(ir)}
                          placeholder="Message"
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedRejections",
                              e,
                              ir.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value={ir.message || ""}
                        ></input>
                      </td>
                      <td>
                        <input
                          id="stagingOnly"
                          type="checkbox"
                          name="stagingOnly"
                          className="form-check-input"
                          placeholder="Staging Only"
                          onChange={(e) =>
                            handleStagingChange(
                              "includedRejections",
                              e,
                              ir.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value="Staging Only"
                          checked={ir.stagingOnly}
                        ></input>
                      </td>
                      {!isGroupRule ? (
                        <>
                          <td>
                            <i
                              className="fa fa-calendar pointer"
                              onClick={() =>
                                handleOpenDates(ir, "includedRejections", ir.id)
                              }
                            ></i>
                          </td>
                          <td>
                            <i
                              className="fa fa-clone pointer"
                              onClick={() =>
                                handleIndexedCopy("includedRejections", irIndex)
                              }
                            ></i>
                          </td>
                          <td>
                            {isFiltered ? (
                              <>&nbsp;</>
                            ) : (
                              <>
                                <i
                                  className="fa fa-arrow-up pointer"
                                  onClick={() =>
                                    handleRowUp(
                                      "includedRejections",
                                      ir.id,
                                      ruleChanges,
                                      setRuleChanges
                                    )
                                  }
                                ></i>
                                <i
                                  className="fa fa-arrow-down pointer"
                                  onClick={() =>
                                    handleRowDown(
                                      "includedRejections",
                                      ir.id,
                                      ruleChanges,
                                      setRuleChanges
                                    )
                                  }
                                ></i>
                              </>
                            )}
                          </td>
                          <td>
                            <i
                              className="fa fa-times pointer"
                              onClick={() =>
                                handleIndexedRemove(
                                  "includedRejections",
                                  ir.id,
                                  ruleChanges,
                                  setRuleChanges
                                )
                              }
                            ></i>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                    {isGroupRule ? (
                      <tr key={"irtrs" + irIndex} style={getRowStyle(ir)}>
                        <td
                          colSpan="2"
                          style={{
                            fontSize: "0.7em",
                            padding: "0px",
                            textAlign: "left",
                          }}
                        >
                          Artifact: {ir.artifact}
                        </td>
                        <td
                          colSpan="20"
                          style={{
                            fontSize: "0.7em",
                            padding: "0px",
                            textAlign: "right",
                          }}
                        >
                          Rule:{" "}
                          <button
                            className="btn-link"
                            style={{ border: "0px" }}
                            onClick={() => navigate("/rule/" + ir.sourceRuleId)}
                          >
                            {ir.sourceRule}
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </StyledRowDiv>
    </>
  );
}

export default RuleAreaIncludedRejections;
