import React, { useState } from "react";
import _ from "lodash";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function RuleChangeHistory({ changeHistoryRecords }) {
  const [search, setSearch] = useState({}); // Dummy search state for simple grid with no search

  const records = changeHistoryRecords || [];

  return (
    <>
      <ResponsiveGrid
        gridId="RuleChangeHistory"
        totalRecords={records.length}
        search={search}
        setSearch={setSearch}
        dataRows={records}
        disablePaging={true}
        columnDefs={[
          {
            name: "date",
            label: "Date",
            disableSort: true,
            style: { width: "20%" },
            getValue: (row) => formatDateTimeUtcZoneForDisplay(row.changeDate),
          },
          {
            name: "user",
            label: "User",
            disableSort: true,
            style: { width: "20%" },
            getValue: (row) =>
              _.isEmpty(row.changeBy) ? "Unknown" : row.changeBy,
          },
          {
            name: "reason",
            label: "Reason",
            disableSort: true,
            style: { width: "60%" },
            getValue: (row) =>
              _.isEmpty(row.changeReason) ? "Not provided" : row.changeReason,
          },
        ]}
      />
    </>
  );
}

export default RuleChangeHistory;
