import React from "react";
import styled from "styled-components";
import { useMobile } from "../../../hooks/useMobile";

function StatusPill({
  status,
  pillStyle = {},
  message = "",
  extraText = "",
  className = "",
  onClick = null,
}) {
  const { isMobileSize } = useMobile();

  let pill = (
    <StyledStatusPill className={className} style={{ ...pillStyle }}>
      {status}
      {extraText}
    </StyledStatusPill>
  );
  if (status === "Reversed")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-blue)" }}
      >
        {isMobileSize ? "Rev." : status}
        {extraText}
      </StyledStatusPill>
    );
  else if (
    status === "Rejected" ||
    status === "Off" ||
    status === "Fail" ||
    status === "Stopped" ||
    status === "Max"
  )
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-red)" }}
      >
        {isMobileSize && status === "Rejected" ? "Rej." : status}
        {extraText}
      </StyledStatusPill>
    );
  else if (status === "Duplicate of Paid")
    pill = (
      <StyledStatusPill className={className} style={{ ...pillStyle }}>
        {isMobileSize ? "Dup." : "Duplicate"} {extraText}
      </StyledStatusPill>
    );
  else if (status === "NotRun")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-unknown)" }}
      >
        Not&nbsp;Run {extraText}
      </StyledStatusPill>
    );
  else if (status === "Duplicate of Approved")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-blue)" }}
      >
        {isMobileSize ? "Dup." : "Duplicate"} {extraText}
      </StyledStatusPill>
    );
  else if (status === "Error")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-black)" }}
        title={message}
        onClick={
          onClick === null
            ? () =>
                console.log(
                  "You must define an onClick handler to show an error message"
                )
            : onClick
        }
      >
        {isMobileSize ? "Err." : "Error"} {extraText}
      </StyledStatusPill>
    );
  else if (status === "NotStarted")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-unknown)" }}
      >
        Not Started {extraText}
      </StyledStatusPill>
    );
  else if (status === "PendingConsumption")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--notify-warning)" }}
      >
        Queued {extraText}
      </StyledStatusPill>
    );
  else if (status === "Consuming")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-blue)" }}
      >
        Consuming {extraText}
      </StyledStatusPill>
    );
  else if (status === "CompletedNotPrimary")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-lavender)" }}
      >
        Receiving {extraText}
      </StyledStatusPill>
    );
  else if (status === "TimedOut")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-red)" }}
      >
        Timed Out {extraText}
      </StyledStatusPill>
    );
  else if (status === "PacketError")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-red)" }}
      >
        Error {extraText}
      </StyledStatusPill>
    );
  else if (status === "ReadyToRespond")
    pill = (
      <StyledStatusPill className={className} style={{ ...pillStyle }}>
        Completed {extraText}
      </StyledStatusPill>
    );
  else if (status === "Cancelled")
    pill = (
      <StyledStatusPill
        className={className}
        style={{ ...pillStyle, backgroundColor: "var(--pill-unknown)" }}
      >
        Cancelled
      </StyledStatusPill>
    );

  return pill;
}

const StyledStatusPill = styled.span`
  border-radius: 12px;
  padding: 3px 12px;
  font-size: 15px;
  line-height: 16px;
  color: var(--pill-text);
  background-color: var(--pill-green);
`;

export default StatusPill;
