import React from "react";
import styled from "styled-components";
import { Chart } from "react-google-charts";
import {
  formatDateTimeUtcZoneForDisplay,
  formatNumberForDisplay,
  getTimeAgoFromIntervalSeconds,
} from "../../../services/General";

function DashboardPanelFront({
  panel,
  selectedTileId,
  value,
  lastValue,
  showGraphs,
}) {
  function getDataPointTooltip(timeAgo, timeUnit, value) {
    const tip = `${formatNumberForDisplay(value)}<br />${timeAgo}&nbsp;${
      timeAgo === 1 ? timeUnit : timeUnit + "s"
    }&nbsp;ago`;
    return `<div class="home-chart-tooltip">${tip}</div>`;
  }

  function getValuesArrayForChart(label, vals) {
    const valAr = replaceGapsInValueData(vals);

    const data = [
      [
        "Interval",
        "",
        {
          role: "tooltip",
          type: "string",
          p: { html: true },
        },
      ],
    ];

    if (valAr.length === 0) {
      data.push([0, 0]);
      return data;
    }

    const count = valAr.length;
    let timeAgoInfo;

    valAr.forEach((value, idx) => {
      timeAgoInfo = getTimeAgoFromIntervalSeconds(
        panel.dashboardPanelRefreshSeconds ?? 60,
        Math.abs(idx - count)
      );

      data.push([
        idx,
        value,
        getDataPointTooltip(timeAgoInfo.ago, timeAgoInfo.unit, value),
      ]);
    });

    return data;
  }

  function getMinMaxValueFromArray(array, isMin) {
    const ar = [];

    // Remove all -1's from the array before checking the min or max
    for (let i = 0; i < array.length; i++) {
      if (array[i] >= 0) ar.push(array[i]);
    }

    // If the array is now empty, just return zero
    if (ar.length === 0) return 0;

    // Otherwise, return the min or max
    if (isMin) return Math.min(...ar);
    else return Math.max(...ar);
  }

  function replaceGapsInValueData(array) {
    const ar = [];

    // Replace all -1's with 0 to fill in gaps
    for (let i = 0; i < array.length; i++) {
      if (array[i] === -1) ar.push(0);
      else ar.push(array[i]);
    }

    return ar;
  }

  function fillOutValuesArray(array) {
    // If array contains less than 10 numbers, pad with -1 so they aren't counted toward the min, but
    //   they will be replaced with zeroes before going into the chart.
    if (array.length >= 10) return array;

    const _vals = new Array(10);
    _vals.fill(-1, 0, 10 - array.length);
    array.forEach((v) => _vals.push(v));

    return _vals;
  }

  const showChart =
    selectedTileId !== panel.reportId &&
    showGraphs &&
    panel.dashboardPanelGraphType !== "None" &&
    !panel.isAlertPanel &&
    panel.dashboardValues.length > 1;

  const values = fillOutValuesArray(panel.dashboardValues.map((v) => v.value));

  const lastRefreshTime =
    panel.dataLastRefreshedTime && panel.dataLastRefreshedTime !== ""
      ? formatDateTimeUtcZoneForDisplay(panel.dataLastRefreshedTime)
      : "";

  return (
    <div
      className={
        value === lastValue ? "flip-card-front" : "flip-card-front changing"
      }
    >
      {showChart && (
        <StyledChartContainer className="dashboard-chart">
          <Chart
            chartType={panel.dashboardPanelGraphType ?? "ColumnChart"}
            data={getValuesArrayForChart(panel.panelLabel, values)}
            options={{
              colors: ["#CCCCCC"],
              backgroundColor: "transparent",
              hAxis: {
                baselineColor: "none",
                ticks: [],
              },
              vAxis: {
                baselineColor: "none",
                ticks: [],
              },
              legend: "none",
              tooltip: { isHtml: true, trigger: "visible" },
            }}
            width="100%"
            height="100%"
          />
        </StyledChartContainer>
      )}
      <StyledDataContainer>
        {panel.isAlertPanel !== true && (
          <div className="flex-row-without-wrap">
            <p>
              {panel.dashboardValues.length === 0
                ? ""
                : formatNumberForDisplay(getMinMaxValueFromArray(values, true))}
            </p>
            <p style={{ marginLeft: "auto" }}>
              {panel.dashboardValues.length === 0
                ? ""
                : formatNumberForDisplay(
                    getMinMaxValueFromArray(values, false)
                  )}
            </p>
          </div>
        )}
        <h3 className={value === lastValue ? "" : "changing"}>
          {formatNumberForDisplay(value)}
        </h3>
        {panel.isAlertPanel === true && (
          <>
            {panel.isAlertPanelInAlertStatus === true ? (
              <span className="material-icons">error</span>
            ) : (
              <span className="material-icons">done</span>
            )}
          </>
        )}
        <p className="panel-label">
          {panel.panelLabel && panel.panelLabel.length > 0
            ? panel.panelLabel
            : "loading..."}
        </p>
        <p className="last-refresh-time">{`${lastRefreshTime}`}</p>
      </StyledDataContainer>
    </div>
  );
}

const StyledDataContainer = styled.div`
  z-index: 1;

  div.flex-row-without-wrap {
    position: absolute;
    top: -4px;
    left: 4px;
    right: 4px;

    p {
      font-size: 12px;
      padding: 0;
      color: var(--text-dark) !important;
    }
  }

  p.last-refresh-time {
    font-size: 12px;
    font-style: italic;
    margin-top: 10px;
    padding: 0;
    color: var(--text-dark) !important;
  }
`;

const StyledChartContainer = styled.div`
  position: absolute;
  top: -20px;
  left: -40px;
  right: -40px;
  bottom: -15px;
  z-index: 0;

  &:hover {
    z-index: 2;
  }
`;

export default DashboardPanelFront;
