import { useRef, useEffect } from "react";

// Used to replace setTimeout since that function gets "stuck" with initial state. This one avoids that.
export function useTimeout(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setTimeout(tick, delay);
    return () => clearTimeout(id);
  }, [delay]);
}
