import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  StyledStepHeaderDiv,
  StyledWizardListItem,
} from "../../../common/layout/CommonStyledWizardComponents";
import useApi from "../../../../hooks/useApi";
import { apiLoadGroupsAll } from "../../../../api/GroupApi";
import SelectInput from "../../../common/input/SingleSelect";

function PanelGetGroup({
  isAuthenticated,
  stepNumber,
  currentStep,
  name,
  title,
  selectedGroup,
  setSelectedGroup,
  errors,
  showPanel = true,
}) {
  const { loading: loadingGroups, api: apiLoadGroups } =
    useApi(apiLoadGroupsAll);
  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (isAuthenticated && !loaded) {
      loadGroupList();
    }
  }, [loaded, isAuthenticated]);

  async function loadGroupList() {
    const groupList = await apiLoadGroups.call(null, (result) => {
      setLoaded(true);
      return _.concat(
        [{ value: "", label: "(Select a group)" }],
        (result.resources || [])
          .sort((a, b) => (a.groupId > b.groupId ? 1 : -1))
          .map((c) => {
            return {
              value: c.groupId,
              groupName: c.name,
              label: (
                <>
                  {c.groupId}
                  <span className="option-member-name">{c.name}</span>
                </>
              ),
            };
          })
      );
    });
    setGroups(groupList);
  }

  if (!showPanel || currentStep < stepNumber) {
    return <></>;
  }

  return (
    <StyledWizardListItem step={stepNumber}>
      <StyledStepHeaderDiv>{title}</StyledStepHeaderDiv>
      <div className="row">
        <div className="col-12 col-xs-6 col-lg-4 col-xl-4">
          <SelectInput
            id={name}
            name={name}
            label=""
            labelStyle={{ display: "none" }}
            options={groups}
            value={selectedGroup}
            onChange={setSelectedGroup}
            placeholder="Select a Group"
            autoFocus={currentStep === stepNumber}
            error={errors[name]}
            loading={loadingGroups}
          />
        </div>
      </div>
    </StyledWizardListItem>
  );
}

export default PanelGetGroup;
