import React from "react";
import CheckboxInput from "../../common/input/CheckboxInput";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";
import MultiSelectInput from "../../common/input/MultiSelectInput";
import { shareModeSearchTypes } from "../../../services/General";
import SelectInput from "../../common/input/SingleSelect";

function ReportSearchForm({
  search,
  templates,
  errors,
  onChange,
  onSearch,
  onReset,
  onCheckboxChange,
  onShareModeChanged,
  onReportTemplateChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="name"
            label="Name"
            onChange={onChange}
            placeholder="Name"
            name="name"
            value={search.name}
            error={errors.name}
          />
          <TextInput
            id="description"
            label="Description"
            onChange={onChange}
            placeholder="Description"
            name="description"
            value={search.description}
            error={errors.description}
          />
          <MultiSelectInput
            id="shareMode"
            name="shareMode"
            label="Share Mode"
            options={[...shareModeSearchTypes]}
            value={search.shareMode}
            onChange={onShareModeChanged}
            placeholder="All"
            error={errors.shareMode}
          />
          <SelectInput
            id="reportTemplateId"
            name="reportTemplateId"
            label="Report Template"
            options={templates}
            value={search.reportTemplateId}
            onChange={onReportTemplateChange}
            placeholder="All"
            error={errors.reportTemplateId}
          />
          <p style={{ marginTop: "30px" }}>Only show reports that are:</p>
          <CheckboxInput
            id="showOnHomePage"
            label="Shown on Homepage"
            onChange={onCheckboxChange}
            placeholder=""
            name="showOnHomePage"
            checked={search.showOnHomePage}
            error={errors.showOnHomePage}
            showLabelInline={true}
          />
          <CheckboxInput
            id="showAsDashboardPanel"
            label="A dashboard panel"
            onChange={onCheckboxChange}
            placeholder=""
            name="showAsDashboardPanel"
            checked={search.showAsDashboardPanel}
            error={errors.showAsDashboardPanel}
            showLabelInline={true}
          />
          <CheckboxInput
            id="dashboardPanelIsAlertPanel"
            label="An alert panel"
            onChange={onCheckboxChange}
            placeholder=""
            name="dashboardPanelIsAlertPanel"
            checked={search.dashboardPanelIsAlertPanel}
            error={errors.dashboardPanelIsAlertPanel}
            showLabelInline={true}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default ReportSearchForm;
