import React, { useState } from "react";
import {
  StyledStepHeaderDiv,
  StyledWizardListItem,
} from "../../../common/layout/CommonStyledWizardComponents";
import TextInput from "../../../common/input/TextInput";

function PanelGetNumber({
  stepNumber,
  currentStep,
  name,
  title,
  value,
  onChange,
  errors,
  showPanel = true,
}) {
  // We have to track the number change locally and only send back the onChange on blur. Otherwise, the auto focus for the next panel kicks in and the current step changes.
  const [localValue, setLocalValue] = useState(value);

  function handleChange({ target }) {
    setLocalValue(target.value);
  }

  if (!showPanel || currentStep < stepNumber) {
    return <></>;
  }

  return (
    <StyledWizardListItem step={stepNumber}>
      <StyledStepHeaderDiv>{title}</StyledStepHeaderDiv>

      <div className="row">
        <div className="col-12 col-md-6">
          <TextInput
            id={name}
            name={name}
            label=""
            labelStyle={{ display: "none" }}
            onChange={handleChange}
            onBlur={() => onChange(localValue)}
            placeholder=""
            value={localValue}
            error={errors[name]}
            isNumber={true}
            autoFocus={currentStep === stepNumber}
          />
        </div>
      </div>
    </StyledWizardListItem>
  );
}

export default PanelGetNumber;
