import React from "react";
import { Modal } from "react-bootstrap";
import SelectInput from "../../common/input/SingleSelect";
import TextInput from "../../common/input/TextInput";
import TextAreaInput from "../../common/input/TextAreaInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import {
  disableAnimations,
  ruleTableDefinitionColumnControlTypes,
  ruleTableDefinitionColumnListSourceTypes,
  ruleTableDefinitionColumnValueTypes,
} from "../../../services/General";
import { getDropDownValue } from "../../../viewmodels/ruleTableDefinitionsVm";
import HelpLink from "../../common/ui/HelpLink";

function RuleTableDefinitionColumnDialog({
  editColumn,
  setEditColumn,
  definitionsList,
  errors,
  showDialog,
  onCloseDialog,
  onSaveDialog,
}) {
  function handleChange({ target }) {
    setEditColumn({ ...editColumn, [target.name]: target.value });
  }

  function handleDropdownChange(name, option) {
    setEditColumn({ ...editColumn, [name]: option });
  }

  function handleCheckboxChange({ target }) {
    setEditColumn({ ...editColumn, [target.name]: target.checked });
  }

  function handleValidValuesChange({ target }) {
    setEditColumn({ ...editColumn, validValuesVm: target.value });
  }

  function handleRuleTableDefinitionChange(option) {
    setEditColumn({ ...editColumn, ruleTableDefinitionId: option.value });
  }

  function handleRuleTableDefinitionColumnChange(option) {
    setEditColumn({
      ...editColumn,
      ruleTableDefinitionColumnId: option.value,
    });
  }

  const selectedValueType =
    editColumn.valueTypeVm === "" ? "Text" : editColumn.valueTypeVm.value;
  const selectedControlType =
    editColumn.controlTypeVm === "" ? "Input" : editColumn.controlTypeVm.value;
  const selectedListSource =
    editColumn.listSourceVm === ""
      ? "ValidValues"
      : editColumn.listSourceVm.value;

  const filteredDefinitionsList = definitionsList.map((def) => {
    return { value: def.id, label: def.name };
  });
  const columnsList =
    definitionsList.find((def) => def.id === editColumn.ruleTableDefinitionId)
      ?.columns || [];
  const filteredColumnsList = columnsList
    .filter((c) => getDropDownValue(c, "valueType") === selectedValueType)
    .map((c) => {
      return { value: c.id, label: c.name };
    });

  return (
    <>
      <Modal
        show={showDialog}
        onHide={onCloseDialog}
        animation={!disableAnimations()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editColumn && editColumn.id ? "Edit Column" : "Add Column"}
            <HelpLink
              path="/Processing-Rules/Rule-Table-Definition-Screen&anchor=column-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            id="name"
            label="Name"
            onChange={handleChange}
            placeholder=""
            name="name"
            value={editColumn.name}
            error={errors.name}
          />
          <div style={{ maxWidth: "50%" }}>
            <SelectInput
              id="valueType"
              name="valueType"
              label="Data Type"
              options={ruleTableDefinitionColumnValueTypes}
              value={
                editColumn.valueTypeVm === ""
                  ? editColumn.valueType
                  : editColumn.valueTypeVm
              }
              onChange={(option) => handleDropdownChange("valueTypeVm", option)}
              placeholder=""
              error={errors.valueType}
            />
          </div>
          <CheckboxInput
            id="isKey"
            label="Column is a key for the table"
            showLabelInline={true}
            onChange={handleCheckboxChange}
            placeholder=""
            name="isKey"
            checked={editColumn.isKey ?? false}
            error={errors.isKey}
          />
          {(selectedValueType === "Text" ||
            selectedValueType === "Numeric") && (
            <>
              <h4 style={{ marginTop: "30px", fontSize: "18px" }}>
                Validation
              </h4>
              <div style={{ maxWidth: "50%" }}>
                <SelectInput
                  id="controlType"
                  name="controlType"
                  label="Control Type"
                  options={ruleTableDefinitionColumnControlTypes}
                  value={
                    editColumn.controlTypeVm === ""
                      ? editColumn.controlType
                      : editColumn.controlTypeVm
                  }
                  onChange={(option) =>
                    handleDropdownChange("controlTypeVm", option)
                  }
                  placeholder=""
                  error={errors.controlType}
                />
              </div>
              {selectedValueType === "Text" &&
                selectedControlType === "Input" && (
                  <>
                    <TextInput
                      id="maxLength"
                      label="Max Length"
                      onChange={handleChange}
                      placeholder=""
                      name="maxLength"
                      value={editColumn.maxLength}
                      error={errors.maxLength}
                      isNumber={true}
                    />
                    <TextInput
                      id="validationExpression"
                      label="Regular Expression"
                      onChange={handleChange}
                      placeholder=""
                      name="validationExpression"
                      value={editColumn.validationExpression}
                      error={errors.validationExpression}
                    />
                  </>
                )}
              {selectedValueType === "Numeric" &&
                selectedControlType === "Input" && (
                  <>
                    <div className="row">
                      <div className="col">
                        <TextInput
                          id="minValue"
                          label="Min Value"
                          onChange={handleChange}
                          placeholder=""
                          name="minValue"
                          value={editColumn.minValue}
                          error={errors.minValue}
                          isNumber={true}
                        />
                      </div>
                      <div className="col">
                        <TextInput
                          id="maxValue"
                          label="Max Value"
                          onChange={handleChange}
                          placeholder=""
                          name="maxValue"
                          value={editColumn.maxValue}
                          error={errors.maxValue}
                          isNumber={true}
                        />
                      </div>
                    </div>
                    <TextInput
                      id="decimalPlaces"
                      label="# of Decimal Places"
                      onChange={handleChange}
                      placeholder=""
                      name="decimalPlaces"
                      value={editColumn.decimalPlaces}
                      error={errors.decimalPlaces}
                      isNumber={true}
                    />
                  </>
                )}
              {selectedControlType === "List" && (
                <>
                  <SelectInput
                    id="listSource"
                    name="listSource"
                    label="Dropdown Source"
                    options={ruleTableDefinitionColumnListSourceTypes}
                    value={
                      editColumn.listSourceVm === ""
                        ? editColumn.listSource
                        : editColumn.listSourceVm
                    }
                    onChange={(option) =>
                      handleDropdownChange("listSourceVm", option)
                    }
                    placeholder=""
                    error={errors.listSource}
                  />
                  {selectedListSource === "ValidValues" && (
                    <>
                      <TextAreaInput
                        id="validValues"
                        label="Values (comma-separated list)"
                        onChange={handleValidValuesChange}
                        placeholder={
                          selectedValueType === "Text"
                            ? "Example: value 1, value 2, value 3"
                            : "Example: 1, 2, 3"
                        }
                        name="validValues"
                        value={editColumn.validValuesVm}
                        error={errors.validValues}
                      />
                    </>
                  )}
                  {selectedListSource === "RuleTableDefinition" && (
                    <>
                      <SelectInput
                        id="ruleTableDefinition"
                        name="ruleTableDefinition"
                        label="Rule Table Definition"
                        options={filteredDefinitionsList}
                        value={{
                          value: editColumn.ruleTableDefinitionId,
                          label: filteredDefinitionsList.find(
                            (f) => f.value === editColumn.ruleTableDefinitionId
                          )?.label,
                        }}
                        onChange={handleRuleTableDefinitionChange}
                        placeholder="Select..."
                        error={errors.ruleTableDefinition}
                      />
                      <SelectInput
                        id="ruleTableDefinitionColumn"
                        name="ruleTableDefinitionColumn"
                        label="Column"
                        options={filteredColumnsList}
                        value={{
                          value: editColumn.ruleTableDefinitionColumnId,
                          label: filteredColumnsList.find(
                            (f) =>
                              f.value === editColumn.ruleTableDefinitionColumnId
                          )?.label,
                        }}
                        onChange={handleRuleTableDefinitionColumnChange}
                        placeholder="Select..."
                        error={errors.ruleTableDefinitionColumn}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={onSaveDialog}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            <span className="material-icons">done</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCloseDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RuleTableDefinitionColumnDialog;
