import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

function ToggleSwitchInput({ name, id, label, onChange, checked }) {
  return (
    <StyledToggleSwitchDiv>
      <Form.Check
        type="switch"
        id={id}
        label={label}
        reverse={true}
        name={name}
        onChange={onChange}
        checked={checked}
      />
    </StyledToggleSwitchDiv>
  );
}

const StyledToggleSwitchDiv = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
    margin-right: 8px;
    margin-top: 3px;
  }

  input {
    cursor: pointer;
    margin-top: 1px;
    width: 40px !important;
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);

    &.form-check-input:checked {
      background-color: var(--button-primary-bg);
      border-color: var(--button-primary-bg);
    }
  }
`;

ToggleSwitchInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};

ToggleSwitchInput.defaultProps = {
  checked: false,
};

export default ToggleSwitchInput;
