import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useAuth } from "../../../contexts/AuthContext";
import Authorize from "../../common/layout/Authorize";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import useApi from "../../../hooks/useApi";
import { useTestRunnerConfig } from "../../../contexts/TestRunnerConfigContext";
import {
  apiLoadTestRunnerConfig,
  apiUpdateTestRunnerConfig,
} from "../../../api/TestRunnerConfigApi";
import {
  createViewModel,
  emptyTestRunnerConfig,
  fromViewModel,
} from "../../../viewmodels/testRunnerConfigVm";
import Spinner from "../../common/ui/Spinner";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import { handleCollapseExpandAll } from "../../../services/General";
import TextInput from "../../common/input/TextInput";
import TextAreaInput from "../../common/input/TextAreaInput";

function TestRunnerConfig() {
  const { auth } = useAuth();
  const { testRunnerConfigData, setTestRunnerConfigData } =
    useTestRunnerConfig();
  const { loading, api: apiLoad } = useApi(apiLoadTestRunnerConfig);
  const { loading: updating, api: apiUpdate } = useApi(
    apiUpdateTestRunnerConfig
  );
  const [changes, setChanges] = useState(emptyTestRunnerConfig);
  const [errors, setErrors] = useState({});
  const [collapsedState, setCollapsedState] = useState([
    { name: "General Settings", collapsed: false },
  ]);

  useEffect(() => {
    if (auth.authenticated) {
      loadTestRunnerConfig();
    }
  }, [auth.authenticated]);

  useEffect(() => {
    if (testRunnerConfigData.testRunnerConfig) {
      setChanges(testRunnerConfigData.testRunnerConfig);
    } else {
      setChanges(emptyTestRunnerConfig);
    }
  }, [testRunnerConfigData.testRunnerConfig]);

  async function loadTestRunnerConfig() {
    await apiLoad.call(null, async (result) => {
      const vm = createViewModel(result);
      setTestRunnerConfigData({
        type: ContextProviderActions.loadTestRunnerConfig,
        payload: vm,
      });
    });
  }

  function formIsValid() {
    const _errors = {};

    if (_.trim(changes.storageConnectionString) === "")
      _errors.storageConnectionString =
        "Blob Storage Connection String must be entered";

    if (_.trim(changes.logLocation) === "")
      _errors.logLocation = "Blob Storage Log Location must be entered";

    if (_.trim(changes.apiAddress) === "")
      _errors.apiAddress = "API Address must be entered";

    if (_.trim(changes.clientId) === "")
      _errors.clientId = "Client Id must be entered";

    if (_.trim(changes.tenantId) === "")
      _errors.tenantId = "Tenant Id must be entered";

    if (_.trim(changes.clientSecret) === "")
      _errors.clientSecret = "Client Secret must be entered";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    const changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    updateTestRunnerConfig({ ...changes });
  }

  async function updateTestRunnerConfig(vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ model }, (result) => {
      const newVm = { ...model, ...result };
      const retModel = createViewModel(newVm);
      setChanges(retModel);

      notifySuccess("Test Runner configuration saved successfully");
    });
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledScreenHelpDiv>
          <HelpLink path="/Services/Test-Runner-Screen" label="Help" />
        </StyledScreenHelpDiv>
        <StyledHeaderRowDiv>
          <div className="flex-row-without-wrap">
            <h1 style={{ display: "inline-block", paddingTop: "5px" }}>
              Test Runner Configuration
            </h1>
          </div>
          {!loading && (
            <StyledHeaderRowButtonDiv
              style={{ flexWrap: "wrap", rowGap: "10px" }}
            >
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "86px",
                }}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  document.location.reload();
                }}
                style={{ marginLeft: "12px" }}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          )}
        </StyledHeaderRowDiv>
        {loading || updating ? (
          <>
            <Spinner spinnerStyle={{ lineHeight: "25vh" }} />
          </>
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <ExpandCollapseDetailSection
              sectionTitle="General Settings"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Services/Test-Runner-Screen&anchor=general-settings-section"
            >
              <StyledRowDiv className="row">
                <div className="col-12">
                  <TextAreaInput
                    id="storageConnectionString"
                    rows="2"
                    label="Blob Storage Connection String"
                    onChange={handleChange}
                    placeholder="Blob Storage Connection String"
                    name="storageConnectionString"
                    value={changes.storageConnectionString}
                    error={errors.storageConnectionString}
                    autoFocus={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="logLocation"
                    label="Blob Storage Log Location"
                    onChange={handleChange}
                    placeholder="Blob Storage Log Location"
                    name="logLocation"
                    value={changes.logLocation || ""}
                    error={errors.logLocation}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv className="row" style={{ marginTop: "40px" }}>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="apiAddress"
                    label="API Address"
                    onChange={handleChange}
                    placeholder="API Address"
                    name="apiAddress"
                    value={changes.apiAddress || ""}
                    error={errors.apiAddress}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="clientId"
                    label="Client Id"
                    onChange={handleChange}
                    placeholder="Client Id"
                    name="clientId"
                    value={changes.clientId || ""}
                    error={errors.clientId}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="tenantId"
                    label="Tenant Id"
                    onChange={handleChange}
                    placeholder="Tenant Id"
                    name="tenantId"
                    value={changes.tenantId || ""}
                    error={errors.tenantId}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextInput
                    id="clientSecret"
                    label="Client Secret"
                    onChange={handleChange}
                    placeholder="Client Secret"
                    name="clientSecret"
                    value={changes.clientSecret || ""}
                    error={errors.clientSecret}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default TestRunnerConfig;
