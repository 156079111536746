import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function RuleTablesList({
  ruleTables,
  search,
  setSearch,
  onSort,
  totalRecords,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const ruleId = dataRow.ruleId;
    const ruleTableDefinitionId = dataRow.ruleTableDefinitionId;

    const actions = [];

    actions.push({
      name: "Edit rule table data",
      onClick: () => navigate("/ruletable/" + id),
    });

    actions.push({
      name: "Edit rule",
      onClick: () => navigate("/rule/" + ruleId),
    });

    actions.push({
      name: "View rule table definition",
      onClick: () => navigate("/ruletabledefinition/" + ruleTableDefinitionId),
    });

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/ruletable/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="RuleTables"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={ruleTables}
        columnDefs={[
          {
            name: "key",
            label: "Rule Table",
            style: { width: "45%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/ruletable/" + row.id)}
              >
                {row.key}
              </button>
            ),
          },
          {
            name: "description",
            label: "Description",
            className: "d-none d-xl-table-cell",
            style: { width: "40%" },
          },
          {
            name: "rule",
            label: "Rule",
            style: { width: "45%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/rule/" + row.sourceRuleId)}
              >
                {row.sourceRuleName}
              </button>
            ),
          },
          {
            name: "ruleTableDefinitionName",
            label: "Rule Table Definition",
            className: "d-none d-lg-table-cell",
            style: { width: "35%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() =>
                  navigate("/ruletabledefinition/" + row.ruleTableDefinitionId)
                }
              >
                {row.ruleTableDefinitionName}
              </button>
            ),
          },
          {
            name: "numColumns",
            label: "Rows",
            disableSort: true,
            className: "d-none d-md-table-cell",
            style: { width: "15%" },
            getValue: (row) => row.ownRowCount,
          },
          {
            name: "inherited",
            label: "Inherited",
            disableSort: true,
            hidden: !search.isGroupSearch,
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) => row.inheritedRowCount,
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default RuleTablesList;
