import _ from "lodash";

export const emptyDatabaseSyncConfigProperties = {
  active: true,
};

export const emptyDatabaseSync = {
  properties: { ...emptyDatabaseSyncConfigProperties },
  synchronizations: [], // emptyDatabaseSyncType
};

export const emptyDatabaseSyncStatus = {
  type: "",
  isRunInProgress: false,
  inProgressRecordsProcessed: 0,
  totalRecordsToProcess: 0,
  totalRecordsProcessed: 0,
  totalManualRecords: 0,
  lastUpdateMessage: "",
};

export const emptyDatabaseSyncType = {
  id: "",
  name: "",
  type: "*",
  sourceConnectionString: "",
  sourceDatabaseId: "",
  sourceContainer: "",
  destConnectionString: "",
  destDatabaseId: "",
  destContainer: "",
  startDate: null,
  endDate: null,
  pollFrequencySeconds: 5,
  active: true,
  additionalFilters: [], // emptyDatabaseSyncFilter
  currentState: { ...emptyDatabaseSyncStatus },
};

export const emptyDatabaseSyncFilter = {
  key: "",
  values: [], // string
};

export const emptyDatabaseSyncStatusType = {
  type: "",
  isRunInProgress: false,
  isContinuousRunInProgress: false,
  inProgressRecordsProcessed: 0,
  totalRecordsToProcess: 0,
  totalRecordsProcessed: 0,
  totalManualRecords: 0,
  lastUpdateMessage: "",
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;

  (m.synchronizations || []).forEach(
    (sync) =>
      (sync.additionalFilters = getFilterArrayFromString(
        sync.additionalFiltersVm
      ))
  );

  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  (vm.synchronizations || []).forEach(
    (sync) =>
      (sync.additionalFiltersVm = getFilterStringFromArray(
        sync.additionalFilters
      ))
  );

  return vm;
}

function getValueArrayAsString(values) {
  // Convert this array to a comma-separated string
  let result = "";
  if ((values || []).length === 0) return "";

  result = values.join(",");
  return result;
}

function getFilterStringFromArray(filters) {
  const array = filters;
  if (array === null) return "";

  let result = "";

  // If empty, just return empty string.
  if ((array || []).length === 0) return "";

  for (let i = 0; i < array.length; i++) {
    result += `${array[i].key ?? array[i].key}=`;
    result += `${getValueArrayAsString(array[i].values)}\n`;
  }

  return result;
}

export function getFilterArrayFromString(text) {
  if (text === "") return [];
  let result = [];

  const items = ("" + _.trim(text)).split("\n");
  for (let i = 0; i < items.length; i++) {
    if (_.trim(items[i]) === "") continue;

    let propField = items[i];
    let propValue = "";

    const pos = items[i].indexOf("=");
    if (pos >= 0) {
      propField = items[i].substring(0, pos);
      propValue = items[i].substring(pos + 1);
    }

    // Values can be comma-separated list, so convert to array and trim off spaces
    let values = [];
    const valuesStr = _.trim(propValue);
    if (valuesStr !== "") {
      values = valuesStr.split(",");
      values.forEach((value) => (value = _.trim(value)));
    }

    result.push({ key: _.trim(propField), values: values });
  }

  return result;
}
