import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { createViewModel } from "../../../viewmodels/reportsVm";
import { useNavigate } from "react-router-dom";
import {
  StyledHeaderRowDiv,
  StyledNoResultsDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";

function HomeReports({ reportList }) {
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if ((reportList || []).length > 0) {
      const vms = reportList.map((r) => createViewModel(r));
      setReports(vms);
    } else {
      setReports([]);
    }
  }, [reportList]);

  return (
    <div data-testid="home-reports-section">
      <StyledHeaderRowDiv style={{ marginTop: "35px", marginBottom: "-4px" }}>
        <h2
          style={{
            fontSize: "18px",
            padding: "0",
            margin: "0",
            display: "flex",
            alignItems: "center",
            columnGap: "5px",
          }}
        >
          <span>Reports</span>
          <HelpLink path="/Home#reports-section" />
        </h2>
        <button
          title="View all reports"
          className="btn btn-link"
          onClick={() => navigate("/reports")}
        >
          View All
        </button>
      </StyledHeaderRowDiv>
      {reports.length === 0 ? (
        <>
          <StyledNoResultsDiv
            style={{ marginTop: "10px", height: "50px" }}
            data-testid="home-reports-no-results"
          >
            <button
              type="button"
              className="btn btn-link btn-with-icon"
              onClick={() => navigate("/report")}
            >
              <i className="material-icons">add</i>
              Add a new home report
            </button>
          </StyledNoResultsDiv>
        </>
      ) : (
        <>
          <div className="row" data-testid="home-reports">
            {reports.map((report) => (
              <div key={`report-${report.id}`} className="col-12 col-lg-6">
                <StyledReportBlock>
                  <div>
                    <h3>{report.name}</h3>
                    <p>{report.description}</p>
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary btn-with-icon"
                    onClick={() => navigate("/runreport/" + report.id)}
                    style={{ marginLeft: "auto" }}
                  >
                    <span className="material-icons">play_circle_outline</span>
                    Run Report
                  </button>
                </StyledReportBlock>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

const StyledReportBlock = styled.div`
  margin-top: 13px;
  padding: 9px 11px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;

  div {
    padding-right: 10px;

    h3 {
      font-size: 16px;
      line-height: 20px;
    }

    p {
      color: var(--text-medium);
      margin: 0;
      padding: 0;
    }
  }

  button.btn.btn-secondary {
    min-width: 126px;
  }

  button.btn.btn-secondary:hover,
  button.btn.btn-secondary:active {
    color: var(--button-primary-text);
    background-color: var(--button-primary-active-bg);
  }
`;

export default HomeReports;
