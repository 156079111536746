import React, { useState, useEffect } from "react";
import RuleTablesList from "./RuleTablesList";
import RuleTableSearchForm from "./RuleTableSearchForm";
import Spinner from "../../common/ui/Spinner";
import {
  createViewModel,
  emptyRuleTableSearch,
} from "../../../viewmodels/ruleTablesVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useRuleTables } from "../../../contexts/RuleTablesContext";
import { apiLoadRuleTables } from "../../../api/RuleTableApi";
import Authorize from "../../common/layout/Authorize";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
} from "../../common/layout/CommonStyledControls";
import RuleTablesGridSearchBar from "./RuleTablesGridSearchBar";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function RuleTables() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { ruleTablesData, setRuleTablesData } = useRuleTables();
  const { loading, api: apiLoad } = useApi(apiLoadRuleTables);
  const [loadData, setLoadData] = useState(true);
  const { isMobileSize } = useMobile();

  let ruleTables = [];
  if (ruleTablesData && ruleTablesData.ruleTables) {
    ruleTables = ruleTablesData.ruleTables;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setRuleTablesData must usually
  //  happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //  useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadRuleTables();
    }
  }, [auth.authenticated, loadData]);

  async function loadRuleTables() {
    await apiLoad.call(
      ruleTablesData.search,
      (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setRuleTablesData({
          type: ContextProviderActions.loadRuleTables,
          payload: {
            ruleTables: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setRuleTablesData({
      type: ContextProviderActions.saveRuleTableSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyRuleTableSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : ruleTablesData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = ruleTablesData.search.orderBy.indexOf(`${event.target.id}+`);
    var indexDesc = ruleTablesData.search.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...ruleTablesData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...ruleTablesData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...ruleTablesData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...ruleTablesData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...ruleTablesData.search,
      [target.name]: target.value,
    });
  }

  function handleIncludeInactiveChange({ target }) {
    setSearchChanges({
      ...ruleTablesData.search,
      includeInactive: target.checked,
    });
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (ruleTablesData.search.description !== "") numFilters++;
    if (ruleTablesData.search.key !== "") numFilters++;
    if (ruleTablesData.search.sourceRuleName !== "") numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledHeaderRowDiv>
        <h1>Rule Tables</h1>
        <StyledHeaderRowButtonDiv>
          <HelpLink
            path="/Processing-Rules/Rule-Tables-List-Screen"
            label="Help"
          />
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <RuleTablesGridSearchBar
        placeholderText="Search Key or Description"
        search={ruleTablesData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              ruleTablesData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={ruleTablesData.search}
            setSearch={setSearchChanges}
            helpLink="/Processing-Rules/Rule-Tables-List-Screen&anchor=filters"
          >
            <RuleTableSearchForm
              errors={errors}
              search={ruleTablesData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onIncludeInactiveChange={handleIncludeInactiveChange}
            />
          </GridAdvancedFilter>
        </div>

        {ruleTablesData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData ? (
              <Spinner />
            ) : (
              <RuleTablesList
                ruleTables={ruleTables}
                ruleName={""}
                search={ruleTablesData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={ruleTablesData.count}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default RuleTables;
