import React from "react";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";

import TextInput from "../../common/input/TextInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import TextAreaInput from "../../common/input/TextAreaInput";

function RuleAreaSummary({
  ruleChanges,
  handleRuleChange,
  handleStagingRuleChange,
  errors,
}) {
  return (
    <>
      <StyledRowDiv className="row">
        <div className="col-6 col-lg-4">
          <TextInput
            id="client"
            label="Client"
            onChange={handleRuleChange}
            placeholder="Client"
            name="client"
            value={ruleChanges.client}
            error={errors.client}
          />
        </div>
        <div className="col-6 col-lg-4">
          <TextInput
            id="priority"
            label="Priority"
            onChange={handleRuleChange}
            placeholder="Priority"
            name="priority"
            value={ruleChanges.priority}
            error={errors.priority}
          />
        </div>
        <div className="col-6 col-lg-4">
          <CheckboxInput
            id="staging"
            label="Staging Only"
            onChange={handleStagingRuleChange}
            placeholder="Staging Only"
            name="stagingOnly"
            checked={ruleChanges.stagingOnly}
            error={errors.stagingOnly}
          />
        </div>
        <div className="col-12">
          <TextAreaInput
            id="description"
            rows="2"
            label="Description"
            onChange={handleRuleChange}
            placeholder="Description"
            name="description"
            value={ruleChanges.description || ""}
            error={errors.description}
          />
        </div>
      </StyledRowDiv>
    </>
  );
}

export default RuleAreaSummary;
