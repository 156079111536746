import React from "react";
import _ from "lodash";
import styled from "styled-components";

function RuleTablesGridSearchBar({
  placeholderText,
  search,
  setSearch,
  numSetFilters,
  onSubmitSearch,
}) {
  async function handleSearch(event, updatedSearch) {
    if (event) event.preventDefault();
    onSubmitSearch(null, updatedSearch);
  }

  async function onSubmit(event) {
    var updatedSearch = { ...search, pageNumber: 1 };
    setSearch(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  async function handleClearClick() {
    if (_.trim(search.freeFormSearch) !== "") {
      var updatedSearch = { ...search, freeFormSearch: "" };
      setSearch(updatedSearch);
      await handleSearch(null, updatedSearch);
    }
  }

  async function handleGroupApplyClick() {
    const isGroupSearch = _.trim(search.groupId) !== "";
    const updatedSearch = { ...search, isGroupSearch: isGroupSearch };
    setSearch(updatedSearch);
    await handleSearch(null, updatedSearch);
  }

  async function handleGroupClearClick() {
    const isGroupSearch = false;
    const updatedSearch = {
      ...search,
      groupId: "",
      isGroupSearch: isGroupSearch,
    };
    setSearch(updatedSearch);
    await handleSearch(null, updatedSearch);
  }

  return (
    <StyledSearchBarWrapper>
      <div className="flex-row-with-wrap">
        <input
          id="groupId"
          type="text"
          name="groupId"
          placeholder="Group ID"
          onChange={({ target }) =>
            setSearch({ ...search, groupId: target.value })
          }
          value={search.groupId}
          style={{
            width: "150px",
            maxWidth: "150px",
          }}
        ></input>
        <input
          type="button"
          value="Apply"
          className="btn btn-primary"
          onClick={handleGroupApplyClick}
          style={{ marginLeft: "10px", width: "86px", minWidth: "86px" }}
        />
        <input
          type="button"
          value="Clear"
          className="btn btn-secondary"
          onClick={handleGroupClearClick}
          style={{ marginLeft: "10px" }}
        />
      </div>
      <StyledSearchBar>
        <form onSubmit={onSubmit}>
          <i className="material-icons" onClick={onSubmit}>
            search
          </i>

          <input
            id="freeFormSearchInput"
            type="text"
            name="freeFormSearchInput"
            placeholder={placeholderText}
            onChange={({ target }) =>
              setSearch({ ...search, freeFormSearch: target.value })
            }
            value={search.freeFormSearch}
            style={{
              width: "80%",
              maxWidth: "320px",
            }}
          ></input>
          {_.trim(search.freeFormSearch) !== "" && (
            <i
              className="material-icons"
              title="Clear"
              onClick={handleClearClick}
            >
              close
            </i>
          )}
        </form>
        <button
          className="btn btn-link d-none d-md-block"
          onClick={() =>
            setSearch({
              ...search,
              showAdvancedFilter: !search.showAdvancedFilter,
            })
          }
        >
          {search.showAdvancedFilter === true ? "Hide Filters" : "Show Filters"}
        </button>
      </StyledSearchBar>
      <StyledMobileFilterBar className="d-flex d-md-none">
        <button
          className="btn btn-link"
          onClick={() => setSearch({ ...search, showAdvancedFilter: true })}
        >
          <i className="material-icons">filter_list</i> Filters ({numSetFilters}
          )
        </button>
      </StyledMobileFilterBar>
    </StyledSearchBarWrapper>
  );
}

const StyledSearchBarWrapper = styled.div`
  margin: 4px 0 10px 0;
  padding: 10px 20px;
  min-height: 60px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;

  input {
    line-height: 16px;
  }
`;

const StyledSearchBar = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  form {
    width: 100%;
    max-width: 500px;
  }

  i {
    cursor: pointer;
    font-size: 24px;
    color: var(--text-light);
  }

  input {
    width: 80%;
    min-width: 200px;
    max-width: 320px;
    margin-left: 13px;
    font-size: 16px;
    line-height: 20px;
    color: var(-text-light);
    outline: none !important;
    padding: 6px 0;
    border: 0;
    border-bottom: 1px solid var(--text-light);
  }
`;

const StyledMobileFilterBar = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 16px 0;

  i {
    font-size: 30px;
    margin-top: -5px;
  }
`;

export default RuleTablesGridSearchBar;
