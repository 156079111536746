import React, { useState } from "react";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import DashboardPanel from "./DashboardPanel";
import DashboardPanelEditMode from "./DashboardPanelEditMode";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

function HomeDashboardPanels({
  panels,
  lastValues,
  showGraphs,
  updateEditPanels,
  updatePanels,
  editMode,
}) {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [selectedTileId, setSelectedTileId] = useState("");

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(event, items, setItems) {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id === over.id) {
      return;
    }

    const _items = reorder(
      items,
      items.findIndex((c) => c.id === active.id),
      items.findIndex((c) => c.id === over.id)
    );

    setItems(_items);
  }

  function updateDashboardPanels(_panels) {
    if (editMode) {
      updateEditPanels(_panels);
    } else {
      updatePanels(_panels);
    }
  }

  function setPanels(pPanels) {
    // Set the new dashboard ordinals for each panel
    let i = 0;
    const _panels = [...pPanels];
    _panels.forEach((p) => (p.dashboardPanelOrdinal = i++));
    updateDashboardPanels(_panels);
  }

  function setVisibility(reportId, show) {
    const _panels = [...panels];
    _panels.find((p) => p.reportId === reportId).visible = show;
    updateDashboardPanels(_panels);
  }

  let value = 0;
  let lastValue = 0;
  let lastTimestamp = "";

  if (panels.length === 0) {
    return <></>;
  }

  return editMode ? (
    <>
      <DndContext
        autoScroll={false}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event) => onDragEnd(event, panels, setPanels)}
      >
        <SortableContext items={panels} strategy={rectSortingStrategy}>
          {panels.map((panel) => (
            <DashboardPanelEditMode
              key={panel.reportId}
              id={panel.reportId}
              panel={panel}
              setVisibility={setVisibility}
            />
          ))}
        </SortableContext>
      </DndContext>
    </>
  ) : (
    <>
      {panels.map((panel) => {
        let values = panel.dashboardValues.map((v) => v.value);
        value = values.length > 0 ? values[values.length - 1] : 0;
        lastValue = lastValues.find((v) => v.id === panel.reportId)?.value || 0;
        lastTimestamp =
          panel.lastNewDataDateTime && panel.lastNewDataDateTime !== ""
            ? formatDateTimeUtcZoneForDisplay(panel.lastNewDataDateTime)
            : "";

        return (
          <DashboardPanel
            key={panel.reportId}
            panel={panel}
            value={value}
            lastValue={lastValue}
            lastTimestamp={lastTimestamp}
            showGraphs={showGraphs}
            updatePanels={editMode ? updateEditPanels : updatePanels}
            selectedTileId={selectedTileId}
            setSelectedTileId={setSelectedTileId}
          />
        );
      })}
    </>
  );
}

export default HomeDashboardPanels;
