import React from "react";
import styled from "styled-components";

function PlaceholderChip({ label, onClick }) {
  return (
    <StyledPlaceholderChip onClick={onClick}>
      <span className="material-icons">add</span>
      <p>{label}</p>
    </StyledPlaceholderChip>
  );
}

const StyledPlaceholderChip = styled.div`
  height: 36px;
  max-width: 150px;
  padding: 10px;
  background: var(--drag-chip-placeholder-bg);
  border: 1px dashed var(--drag-chip-placeholder-border);
  border-radius: 28px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;

    &:hover {
      background-color: var(--drag-chip-placeholder-bg-active);
      border: 1px solid var(--drag-chip-placeholder-border-active);

      p,
      span[class="material-icons"] {
        color: var(--drag-chip-placeholder-text-active);
      }
    }

    &:active {
      background-color: var(--drag-chip-placeholder-border-active);

      p,
      span[class="material-icons"] {
        color: #FFF;
      }
    }

  }

  span[class="material-icons"] {
    font-size: 20px;
    line-height: 20px;
    color: var(--drag-chip-placeholder-border);
  }

  p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 16px;
    color: var(--drag-chip-placeholder-text);
  }
`;

export default PlaceholderChip;
