import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import GridWrapper from "../grid/GridWrapper";
import { StyledMobileDenseTable } from "./CommonStyledControls";
import { useMobile } from "../../../hooks/useMobile";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import GridActionButton from "../grid/GridActionButton";
import { getHighlightedText } from "../../../services/General";

function ResponsiveGrid({
  gridId,
  totalRecords,
  search,
  searchOrderByPropName = "orderBy",
  setSearch,
  showNameLink = false,
  alwaysShowDetailsInMobileView = false,
  onSort,
  columnDefs,
  dataRows,
  onRowClick,
  disablePaging = false,
  disableRecordCount = false,
  getRowStyle = undefined,
  allowFreeTextSearch = false,
  enableClientRowPager = false,
  enableClientColumnPager = false,
  totalColumnCount = 5,
}) {
  const { isMobileSize, isTabletSize } = useMobile();
  const { userPrefs, setUserPrefs } = useUserPreferences();
  const [viewableRecords, setViewableRecords] = useState([]);
  const [viewableColumns, setViewableColumns] = useState([]);

  const allColumns = columnDefs || [];
  const data = dataRows || [];

  const DIR_LEFT = 0;
  const DIR_RIGHT = 1;

  useEffect(() => {
    if (
      !(
        enableClientRowPager === true &&
        search.colPageSize &&
        search.colPageCount &&
        search.colPageNumber
      )
    )
      return;

    // Also set the initial number of pages and go back to first page
    const pageSize = 5;
    const numPages = Math.ceil(totalColumnCount / pageSize);

    // Only set search params if changing...
    if (
      search.colPageSize !== pageSize ||
      search.colPageCount !== numPages ||
      search.colPageNumber !== 1
    ) {
      setSearch({
        ...search,
        colPageSize: pageSize,
        colPageCount: numPages,
        colPageNumber: 1,
        isClientOpOnly: true,
      });
    }
  }, [totalColumnCount]);

  useEffect(() => {
    if (
      !(enableClientRowPager === true && search.pageSize && search.pageNumber)
    )
      return;

    // When pageSize or pageNumber changes, set viewableRecords to only show the
    //   current page of records. viewableRecords is an array of integers indicating the indexes
    //   of the records to show.
    let firstRecord = 1 + search.pageSize * (search.pageNumber - 1);
    let lastRecord = firstRecord + search.pageSize - 1;
    if (lastRecord > totalRecords) lastRecord = totalRecords;

    const rows = [];
    // Adjust column numbers for zero-based array
    for (let i = firstRecord - 1; i < lastRecord; i++) {
      rows.push(i);
    }
    setViewableRecords(rows);
  }, [
    search?.pageSize,
    search?.pageNumber,
    search?.highlightText,
    totalRecords,
  ]);

  useEffect(() => {
    if (
      !(
        enableClientColumnPager === true &&
        search.colPageSize &&
        search.colPageNumber
      )
    )
      return;

    // When colPageSize or colPageNumber changes, set viewableColumns to only show the
    //   current page of columns. viewableColumns is an array of integers indicating the indexes
    //   of the columns to show.
    const firstColumn = 1 + search.colPageSize * (search.colPageNumber - 1);
    let lastColumn = firstColumn + search.colPageSize - 1;
    if (lastColumn > totalColumnCount) lastColumn = totalColumnCount;

    const cols = [];
    // Adjust column numbers for zero-based array
    for (let i = firstColumn - 1; i < lastColumn; i++) {
      cols.push(i);
    }
    setViewableColumns(cols);
  }, [search?.colPageSize, search?.colPageNumber]);

  function colInView(index, isStickyColumn) {
    if (!enableClientColumnPager) return true;
    return isStickyColumn || viewableColumns.indexOf(index) >= 0;
  }

  function handleClickColumnPager(direction) {
    let pageNum =
      direction === DIR_LEFT
        ? search.colPageNumber - 1
        : search.colPageNumber + 1;
    if (pageNum < 1) pageNum = 1;
    if (pageNum > search.colPageCount) pageNum = search.colPageCount;
    setSearch({ ...search, colPageNumber: pageNum, isClientOpOnly: true });
  }

  function getSortArrowClass(orderBy = [], name) {
    if (orderBy.includes(`${name}+`)) return "fa fa-sort fa-fw fa-sort-asc";
    if (orderBy.includes(`${name}-`)) return "fa fa-sort fa-fw fa-sort-desc";
    return "";
  }

  function getColumnValueOrSearchResult(dataRow, col, index, hlResults) {
    if (allowFreeTextSearch && col.freeTextSearch === true) {
      var arrayCol = hlResults.htmlArray.find(
        (arCol) => col.name === arCol.colName
      );
      if (arrayCol) return arrayCol.html;
      else return "";
    } else return getColumnValue(dataRow, col, index);
  }

  function getColumnValue(dataRow, col, index) {
    let value = "";
    if (col.getValue) {
      value = col.getValue(dataRow, index, dataRow[col.name]);
    } else {
      value = dataRow[col.name];
    }
    return value;
  }

  function getDataTip(dataRow, col, index) {
    let value = "";
    if (col.getDataTip) {
      value = col.getDataTip(dataRow, index);
    }
    return value;
  }

  function setShowColumnNames(value) {
    setSearch({ ...search, showColumnNames: value, isClientOpOnly: true });
  }

  function setShowDetails(value) {
    setSearch({ ...search, showDetails: value, isClientOpOnly: true });
    saveGridPreferences(value);
  }

  function getGridPreferenceForShowDetails() {
    // Only use grid details preference when in small size.
    if (isMobileSize || isTabletSize) {
      const _gridPreferences = userPrefs.gridPreferences || [];

      const gridPref = _gridPreferences.find((p) => p.gridId === gridId);
      if (gridPref) {
        return gridPref.showDetails;
      }
    }
    return search.showDetails;
  }

  function saveGridPreferences(showDetails) {
    const _gridPreferences = userPrefs.gridPreferences
      ? _.cloneDeep(userPrefs.gridPreferences)
      : [];

    let gridPref = _gridPreferences.find((p) => p.gridId === gridId);
    if (gridPref) {
      gridPref.showDetails = showDetails;
    } else {
      gridPref = {
        gridId: gridId,
        showDetails: showDetails,
      };
      _gridPreferences.push(gridPref);
    }

    // Save data in local storage so it remains for this user
    setUserPrefs({
      type: ContextProviderActions.setGridPreferences,
      payload: _gridPreferences,
    });
  }

  function getRowCombinedStyles(dataRow) {
    let style = {};

    if (getRowStyle) {
      style = getRowStyle(dataRow);
    }

    return style;
  }

  function getNormalColCombinedStyles(dataRow, isMobile = false) {
    let style = {
      borderBottomColor: "transparent",
      borderBottomWidth: "0",
      paddingBottom: detailColumns.length > 0 ? "0" : "14px",
    };

    if (isMobile) {
      style = { ...style, textAlign: "left" };
    }

    return style;
  }

  function getDetailColCombinedStyles(dataRow) {
    let style = {
      padding: "5px 5px 10px 20px",
      borderTopColor: "transparent",
      borderTopWidth: "0",
    };

    return style;
  }

  function handleRowClick(e, dataRow) {
    if (onRowClick) {
      onRowClick(e, dataRow);
    }
  }

  function getActionButton(dataRow, col, isMobile) {
    const actions = col.getActions(dataRow);

    const btn = (
      <td
        className="table-action-btn"
        style={getNormalColCombinedStyles(dataRow, isMobile)}
      >
        <GridActionButton key={col.id} actions={actions}></GridActionButton>
      </td>
    );

    return btn;
  }

  function getHighlightedTextForValuesArray(row, searchTerms) {
    const searchColumns = columns.filter((col) => col.freeTextSearch === true);

    if ((searchColumns || []).length === 0 || (row || []).length === 0) {
      return { count: 0, htmlArray: [] };
    }

    const hlArray = [];
    let hlCount = 0;
    let result = {};
    let val = "";

    searchColumns.forEach((col, index) => {
      val = row[col.name];
      if (col.getRawValue) {
        val = col.getRawValue(row, index);
      }

      if (val !== null) {
        result = getHighlightedText(val, searchTerms);

        let html = result.html;
        if (col.getValue) {
          html = col.getValue(row, index, html);
        }

        hlCount += result.count;
        hlArray.push({
          colName: col.name,
          count: result.count,
          html: html,
        });
      } else {
        hlArray.push({
          colName: col.name,
          count: 0,
          html: null,
        });
      }
    });

    return { count: hlCount, htmlArray: hlArray };
  }

  const gridShowDetailsPreference = getGridPreferenceForShowDetails();
  const showDetailsOverride =
    alwaysShowDetailsInMobileView && (isMobileSize || isTabletSize);
  const showDetailView = gridShowDetailsPreference || showDetailsOverride;
  const columns = allColumns.filter((col) => col.showInDetailRow !== true);
  const mobileColumns = columns.filter((col) => col.desktopOnly !== true);
  const desktopColumns = columns.filter((col) => col.mobileOnly !== true);
  const detailColumns = allColumns.filter(
    (col) => col.showInDetailRow === true
  );

  const hl = allowFreeTextSearch ? search.highlightText : "";
  let hlResults = [];

  return (
    <>
      <GridWrapper
        count={totalRecords}
        search={search}
        setSearch={setSearch}
        showNameLink={showNameLink}
        showDetailsLink={!showDetailsOverride}
        disablePaging={disablePaging}
        disableRecordCount={disableRecordCount}
        showColumnNames={search.showColumnNames}
        setShowColumnNames={setShowColumnNames}
        showDetails={showDetailView}
        setShowDetails={setShowDetails}
        colCount={totalColumnCount}
        showColumnPager={!showDetailView && enableClientColumnPager === true}
      >
        <div style={{ position: "relative" }}>
          {enableClientColumnPager === true &&
            !showDetailView &&
            search.colPageNumber > 1 && (
              <StyledColumnPager
                onClick={() => handleClickColumnPager(DIR_LEFT)}
              >
                <span className="material-icons">keyboard_arrow_left</span>
              </StyledColumnPager>
            )}
          <table className="table table-clickable-rows">
            {!showDetailView && (
              <>
                <thead>
                  <tr>
                    {desktopColumns.map((col, idx) => {
                      if (col.hidden === true || !colInView(idx, col.isSticky))
                        return (
                          <React.Fragment key={`htr-${idx}`}></React.Fragment>
                        );
                      return (
                        <React.Fragment key={`htr-${idx}`}>
                          <th
                            id={col.name}
                            className={
                              "force-wrap " +
                              (col.className || "") +
                              (!col.disableSort ? " pointer" : "")
                            }
                            style={
                              { ...col.style, verticalAlign: "bottom" } || {}
                            }
                            colSpan={col.colspan || 1}
                            onClick={!col.disableSort ? onSort : undefined}
                          >
                            {col.label}
                            {!col.disableSort && (
                              <i
                                className={getSortArrowClass(
                                  search[searchOrderByPropName],
                                  col.name
                                )}
                              />
                            )}
                          </th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                </thead>
              </>
            )}

            {data.map((dataRow, rowIndex) => {
              hlResults = getHighlightedTextForValuesArray(dataRow, hl);

              if (
                (_.trim(hl).length > 0 && hlResults.count === 0) ||
                (enableClientRowPager === true &&
                  viewableRecords.indexOf(rowIndex) < 0)
              ) {
                return (
                  <React.Fragment key={`dtr-${rowIndex}`}></React.Fragment>
                );
              }

              return (
                <React.Fragment key={`dtr-${rowIndex}`}>
                  {showDetailView ? (
                    <tbody>
                      <tr
                        style={getRowStyle ? getRowStyle(dataRow) : {}}
                        onClick={(e) => handleRowClick(e, dataRow)}
                      >
                        <td
                          className={`dense-field ${
                            rowIndex % 2 === 0 ? "" : "alt-row"
                          }`}
                        >
                          <StyledMobileDenseTable>
                            <tbody>
                              {mobileColumns.map((col, idx) => {
                                if (col.hidden === true)
                                  return (
                                    <React.Fragment
                                      key={`htr-${idx}`}
                                    ></React.Fragment>
                                  );
                                return (
                                  <React.Fragment key={`ctr-${idx}`}>
                                    <tr>
                                      <td>
                                        <span
                                          id={col.name}
                                          className={
                                            "dense-label force-wrap" +
                                            (!col.disableSort ? " pointer" : "")
                                          }
                                          onClick={
                                            !col.disableSort
                                              ? onSort
                                              : undefined
                                          }
                                        >
                                          {col.label}
                                        </span>
                                        {!col.disableSort && (
                                          <i
                                            className={getSortArrowClass(
                                              search[searchOrderByPropName],
                                              col.name
                                            )}
                                          />
                                        )}
                                      </td>
                                      {col.isActionBtn ? (
                                        <>
                                          {getActionButton(dataRow, col, true)}
                                        </>
                                      ) : (
                                        <td className="force-wrap">
                                          {getColumnValueOrSearchResult(
                                            dataRow,
                                            col,
                                            rowIndex,
                                            hlResults
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                              {detailColumns.length > 0 && (
                                <tr
                                  style={
                                    getRowStyle
                                      ? {
                                          ...getRowStyle(dataRow),
                                          borderLeft: "none",
                                        }
                                      : {}
                                  }
                                >
                                  <td
                                    colSpan="99"
                                    style={{
                                      padding: "5px 5px 10px 20px",
                                      borderTopColor: "transparent",
                                    }}
                                  >
                                    <div
                                      className="flex-row-without-wrap"
                                      style={detailColumns[0].style || {}}
                                    >
                                      {getColumnValueOrSearchResult(
                                        dataRow,
                                        detailColumns[0],
                                        rowIndex,
                                        hlResults
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </StyledMobileDenseTable>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody
                      className="two-row-record"
                      onClick={(e) => handleRowClick(e, dataRow)}
                    >
                      <tr
                        className={
                          rowIndex % 2 === 0 ? "normal-row" : "alt-row"
                        }
                        style={getRowCombinedStyles(dataRow)}
                      >
                        {desktopColumns.map((col, idx) => {
                          if (
                            col.hidden === true ||
                            !colInView(idx, col.isSticky)
                          )
                            return (
                              <React.Fragment
                                key={`htr-${idx}`}
                              ></React.Fragment>
                            );
                          return (
                            <React.Fragment key={`ctr-${idx}`}>
                              {col.isActionBtn ? (
                                <>{getActionButton(dataRow, col, false)}</>
                              ) : (
                                <td
                                  className={
                                    (col.noForceWrap === true
                                      ? ""
                                      : "force-wrap ") + (col.className || "")
                                  }
                                  style={getNormalColCombinedStyles(dataRow)}
                                >
                                  <span
                                    data-tip={getDataTip(
                                      dataRow,
                                      col,
                                      rowIndex
                                    )}
                                  >
                                    {getColumnValueOrSearchResult(
                                      dataRow,
                                      col,
                                      rowIndex,
                                      hlResults
                                    )}
                                  </span>
                                </td>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </tr>
                      {detailColumns.length > 0 && (
                        <tr
                          className={
                            rowIndex % 2 === 0
                              ? "detail-row normal-row "
                              : "detail-row alt-row "
                          }
                          style={getRowCombinedStyles(dataRow)}
                        >
                          <td
                            colSpan="99"
                            style={getDetailColCombinedStyles(dataRow)}
                            className={detailColumns[0].className || ""}
                          >
                            <div
                              className="flex-row-without-wrap"
                              style={detailColumns[0].style || {}}
                            >
                              {getColumnValueOrSearchResult(
                                dataRow,
                                detailColumns[0],
                                rowIndex,
                                hlResults
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </React.Fragment>
              );
            })}
          </table>
          {enableClientColumnPager === true &&
            !showDetailView &&
            search.colPageNumber < search.colPageCount && (
              <StyledColumnPager
                style={{ left: "unset", right: "-28px" }}
                onClick={() => handleClickColumnPager(DIR_RIGHT)}
              >
                <span className="material-icons">keyboard_arrow_right</span>
              </StyledColumnPager>
            )}
        </div>
      </GridWrapper>
    </>
  );
}

const StyledColumnPager = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: -26px;
  border-radius: 2px;
  width: 26px;
  height: 32px;
  opacity: 0.25;
  background-color: #000;
  padding: 0;
  position: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  span {
    font-size: 28px;
    line-height: 30px;
    color: #fff;
  }
`;

export default ResponsiveGrid;
