import React, { useState } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import SelectInput from "../../common/input/SingleSelect";
import { notifyWarn } from "../../../services/NotificationService";
import { emptyTest } from "../../../viewmodels/testsVm";
import HelpLink from "../../common/ui/HelpLink";

function TestGroupTestDialog({
  tests,
  editItem,
  setEditItem,
  showDialog,
  onCloseDialog,
  onSaveDialog,
}) {
  const [errors, setErrors] = useState({});

  function handleTestChange(option) {
    const newItem = {
      ...emptyTest,
      id: option.value,
      description: option.label,
    };
    let changed = { ...editItem, ...newItem };
    setEditItem(changed);
  }

  function formIsValid() {
    const _errors = {};
    if (_.trim(editItem.id) === "")
      _errors.id = "You must select a test to add";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function onSave(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    onSaveDialog();
  }

  const isEditMode = editItem && editItem.id;
  const testOptions = [{ value: "", label: "(Select a test)" }];

  tests.forEach((test) =>
    testOptions.push({
      value: test.id,
      label: test.description,
    })
  );

  return (
    <>
      <Modal show={showDialog} onHide={onCloseDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Edit Test" : "Add Test"}
            <HelpLink
              path="/Testing/Test-Group-Screen&anchor=test-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectInput
            id="id"
            name="id"
            label="Test"
            options={testOptions}
            value={{
              value: editItem.id,
              label: testOptions.find((f) => f.value === editItem.id)?.label,
            }}
            onChange={handleTestChange}
            placeholder="(Select a test)"
            error={errors.id}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-with-icon"
            onClick={onSave}
            style={{
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Add Test
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCloseDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TestGroupTestDialog;
