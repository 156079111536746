import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function DrugsList({
  drugs,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
  onCopy,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const ndc = dataRow.ndc;

    const actions = [
      {
        name: "View Details",
        onClick: () => navigate("/drug/" + id),
      },
      {
        name: "Duplicate",
        onClick: () => onCopy(ndc),
      },
      {
        name: "Remove",
        onClick: () => onDelete(id, ndc),
      },
    ];

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/drug/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="Drugs"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={drugs}
        columnDefs={[
          {
            name: "ndc",
            label: "NDC",
            style: { width: "40%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/drug/" + row.id)}
              >
                {row.ndc}
              </button>
            ),
          },
          {
            name: "productName",
            label: "Name",
            style: { width: "40%" },
          },
          {
            name: "manufacturerName.manufacturerName",
            label: "Manufacturer",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
            getValue: (row) => (row.manufacturerName || {}).manufacturerName,
          },
          {
            name: "metricStrength",
            label: "Strength",
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
            getValue: (row) => (
              <>
                {(row.metricStrength || 0).toFixed(2)} {row.strengthUnitMeasure}
              </>
            ),
          },
          {
            name: "packageSize",
            label: "Package Size",
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
            getValue: (row) => (
              <>
                {(row.packageSize || 0).toFixed(2)} {row.packageSizeUnitMeasure}{" "}
                {row.packageDescription}
              </>
            ),
          },
          {
            name: "active",
            label: "Active",
            className: "d-none d-xl-table-cell",
            style: { width: "6%" },
            getValue: (row) =>
              row.active ? <span className="material-icons">check</span> : "",
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default DrugsList;
