import { formatDateTime, logInitial } from "../services/General";

export const emptyTestProcessorConfig = {
  processedDate: formatDateTime(new Date(new Date().getTime()), true),
  requestContents: "",
  saveHistory: false,
  clearRuleCache: false,
  serializeLogItems: false,
  logType: [...logInitial],
  textSearch: "",
  highlightText: "",
};

export const emptyTestProcessorResponse = {
  responseContents: "",
  logs: [],
};
