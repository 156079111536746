import React, { useState } from "react";
import styled from "styled-components";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { Modal } from "react-bootstrap";
import { disableAnimations, generateUUID } from "../../../services/General";
import DragChip, { ChipMode } from "../../common/ui/DragChip";
import PlaceholderChip from "../../common/ui/PlaceholderChip";
import SelectInput from "../../common/input/SingleSelect";
import {
  emptyOrderGroupByReportField,
  fromOrderGroupByViewModel,
} from "../../../viewmodels/reportsVm";
import { notifyError, notifyWarn } from "../../../services/NotificationService";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import HelpLink from "../../common/ui/HelpLink";

function ReportOrderGroupByFieldChipsSection({
  title,
  selectFields,
  chips,
  setChips,
  error,
  disabled,
}) {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [showModal, setShowModal] = useState(false);
  const [addField, setAddField] = useState({ ...emptyOrderGroupByReportField });
  const [chipMode, setChipMode] = useState(ChipMode.View);
  const [errors, setErrors] = useState({});

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(event, chips, setChips) {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id === over.id) {
      return;
    }

    const _chips = reorder(
      chips,
      chips.findIndex((c) => c.id === active.id),
      chips.findIndex((c) => c.id === over.id)
    );

    setChips(_chips);
  }

  function onAddFieldChanged(option) {
    const field = { ...addField };
    field.fieldId = option;
    field.fieldDisplayName = option.label;
    field.entityType = option.entityType;
    setAddField(field);
  }

  function onOrderGroupByChange(value) {
    if (addField.groupBy === (value === "GROUPBY")) return;

    const field = { ...addField };
    field.groupBy = value === "GROUPBY";
    field.fieldId = "";
    field.fieldDisplayName = "";
    setAddField(field);
  }

  function onSortDirectionChange(value) {
    const field = { ...addField };
    field.orderDirection = value;
    setAddField(field);
  }

  function closeSelectFieldDialog() {
    resetAddField();
    setShowModal(false);
  }

  function formIsValid() {
    const _errors = {};

    if (addField.fieldId === "") {
      _errors.fieldId = "Field must be selected";
    }

    // Make sure another field with the same fieldDisplayName has not already been added.
    if (
      chips.findIndex(
        (c) =>
          c.fieldDisplayName === addField.fieldDisplayName &&
          c.id !== addField.id
      ) >= 0
    ) {
      _errors.fieldId = "This field has already been added. Select another.";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function resetAddField() {
    setErrors({});
    setChipMode(ChipMode.View);
    setAddField({ ...emptyOrderGroupByReportField });
  }

  function addFieldsToChips() {
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    closeSelectFieldDialog();

    // Add fields to the end of the chips list
    const _chips = [...chips];

    if (chipMode === ChipMode.Edit) {
      // Update chip and save it back to chips list
      const pos = _chips.findIndex((c) => c.id === addField.id);

      if (pos < 0) {
        notifyError(`A chip with id ${addField.id} was not found to save!`);
      } else {
        _chips.splice(pos, 1, fromOrderGroupByViewModel({ ...addField }));
      }
    } else {
      const fieldId = addField.fieldId?.value
        ? addField.fieldId.value
        : addField.fieldId;

      const field = fromOrderGroupByViewModel({
        ...addField,
        fieldId: fieldId,
      });
      field.id = generateUUID();
      _chips.push(field);
    }

    setChips(_chips);
    resetAddField();
  }

  function handleRemoveField(id) {
    const _chips = [...chips];
    const index = _chips.findIndex((c) => c.id === id);
    _chips.splice(index, 1);
    setChips(_chips);

    // If all chips have been removed, automatically switch back to View mode.
    if (_chips.length === 0) {
      setChipMode(ChipMode.View);
    }
  }

  function handleEditField(id) {
    const _chips = [...chips];
    const chip = _chips.find((c) => c.id === id);
    if (!chip) {
      notifyError(`A chip with id ${id} was not found to edit!`);
      return;
    }

    const field = { ...chip };

    if (!field.isCustom) {
      const label = field.fieldDisplayName;
      // Non-custom fields need the selection option set into fieldId.
      field.fieldId = {
        value: field.fieldId,
        label: label,
      };
    }

    setChipMode(ChipMode.Edit);
    setAddField(field);
    setShowModal(true);
  }

  function getIconFromOrderGroupBy(groupBy) {
    let icon = <></>;

    if (groupBy) {
      icon = <span className="material-icons icon-subtle">storage</span>;
    } else {
      icon = <span className="material-icons icon-subtle">sort</span>;
    }

    return icon;
  }

  return (
    <>
      <StyledSectionHeader>
        <h3>{title}</h3>
        <div
          className="d-none d-md-flex"
          style={{ display: "flex", alignItems: "center", columnGap: "5px" }}
        >
          <div className="material-icons">storage</div>
          <div>Group by</div>
          <div className="material-icons" style={{ marginLeft: "10px" }}>
            sort
          </div>
          <div>Order by</div>
        </div>
      </StyledSectionHeader>
      {disabled ? (
        <StyledChipContainer>
          {chips.map((chip, idx) => (
            <DragChip
              key={`${chip.id}-${idx}`}
              index={idx}
              id={chip.id}
              label={chip.fieldDisplayName}
              chipMode={ChipMode.Readonly}
              showSortDirection={true}
              sortDirection={chip.orderDirection ?? "Ascending"}
              icon={getIconFromOrderGroupBy(chip.groupBy)}
            ></DragChip>
          ))}
        </StyledChipContainer>
      ) : (
        <DndContext
          autoScroll={false}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => onDragEnd(event, chips, setChips)}
        >
          <SortableContext items={chips} strategy={rectSortingStrategy}>
            <StyledChipContainer>
              {chips.map((chip, idx) => (
                <DragChip
                  key={`${chip.id}-${idx}`}
                  index={idx}
                  id={chip.id}
                  label={chip.fieldDisplayName}
                  chipMode={chipMode}
                  onClickRemove={handleRemoveField}
                  onClickEdit={handleEditField}
                  showSortDirection={true}
                  sortDirection={chip.orderDirection ?? "Ascending"}
                  icon={getIconFromOrderGroupBy(chip.groupBy)}
                ></DragChip>
              ))}
              {chipMode === ChipMode.View && (
                <PlaceholderChip
                  label="Add Field"
                  onClick={() => setShowModal(true)}
                ></PlaceholderChip>
              )}
            </StyledChipContainer>
          </SortableContext>
        </DndContext>
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <Modal
        show={showModal}
        onHide={closeSelectFieldDialog}
        animation={!disableAnimations()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {chipMode === ChipMode.Edit ? "Edit" : "Add"} Field
            <HelpLink
              path="/Reports/Report-Screen&anchor=order-and-group-by-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToggleButtonGroupInput
            id="orderGroupType"
            name="orderGroupType"
            label="Do you want to order or group by this field?"
            options={[
              { value: "ORDERBY", label: "Order by" },
              { value: "GROUPBY", label: "Group by" },
            ]}
            value={addField.groupBy ? "GROUPBY" : "ORDERBY"}
            onChange={onOrderGroupByChange}
            error={errors.orderGroupType}
          />
          <SelectInput
            id="addField"
            name="addField"
            label="Field"
            options={(selectFields || [])
              .filter(
                (field) =>
                  !addField.groupBy ||
                  (addField.groupBy && field.aggregateFunction === "None")
              )
              .map((field) => {
                return {
                  label: field.fieldDisplayName,
                  value: field.id,
                  entityType: field.entityType,
                };
              })}
            value={addField.fieldId}
            onChange={onAddFieldChanged}
            placeholder="(Select Field)"
            error={errors.fieldId}
          />
          <ToggleButtonGroupInput
            id="orderDirection"
            name="orderDirection"
            label="Sort Direction"
            options={[
              { value: "Ascending", label: "Ascending" },
              { value: "Descending", label: "Descending" },
            ]}
            value={addField.orderDirection}
            onChange={onSortDirectionChange}
            error={errors.orderDirection}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={addFieldsToChips}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            {chipMode === ChipMode.Edit ? "Save" : "Add"} Field
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={closeSelectFieldDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const StyledSectionHeader = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  div,
  h3 {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  div {
    margin-left: auto;

    button {
      line-height: 31px;
    }
  }
`;

const StyledChipContainer = styled.div`
  padding: 10px;
  min-height: 58px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 8px;
`;

export default ReportOrderGroupByFieldChipsSection;
