import React, { Fragment, useEffect, useState } from "react";
import { PacketParseSegmentHeader } from "./PacketParseSegmentHeader";
import styled from "styled-components";
import { usePacketParser } from "../../../contexts/PacketParserContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useMobile } from "../../../hooks/useMobile";
import { StyledMobileDenseTable } from "../../common/layout/CommonStyledControls";

export function FieldParser({ typeDesc, fields, isRequest }) {
  const { packetParserData, setPacketParserData } = usePacketParser();
  const { isMobileSize } = useMobile();
  const [selectedSegmentId, setSelectedSegmentId] = useState("");
  let lastSegment = "";
  let newSegment = true;
  let currentId = 0;

  useEffect(() => {
    if (
      packetParserData.selectedFieldId &&
      packetParserData.selectedFieldId !== null
    ) {
      scrollToSelectedRow();
    }
  }, [packetParserData.selectedFieldId]);

  useEffect(() => {
    if (selectedSegmentId && selectedSegmentId !== "") {
      scrollToSelectedRow();
    }
  }, [selectedSegmentId]);

  function transformVal(fld) {
    var val = fld.value ? fld.value.data : "";
    const parser = new DOMParser();
    if (val.toString().includes("@")) {
      return parser.parseFromString(
        val.toString().replace("@", "@&#8203;"),
        "text/html"
      ).body.textContent;
    }

    //Check numeric
    if (fld.fieldDecimalCount > 0) {
      return Number(val).toFixed(fld.fieldDecimalCount);
    }
    return val;
  }

  function onRowClick(fieldId) {
    setPacketParserData({
      type: ContextProviderActions.setSelectedFieldId,
      payload: { isRequest, fieldId },
    });
  }

  function scrollIntoView(element) {
    window.setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 250);
  }

  function scrollToSelectedRow() {
    const selectedId =
      packetParserData.selectedFieldId.isRequest === isRequest
        ? packetParserData.selectedFieldId.fieldId ?? ""
        : "";
    if (selectedId === "") return;

    let element = document.getElementById(selectedId);
    if (element) {
      setSelectedSegmentId("");
      scrollIntoView(element);
      return;
    }

    // Field element was not found, so just try to locate the segment header instead and highlight that.
    //  Segment id is the all but the last part of the field id (up to the last -). And replace FLD with SEG.
    const pos = selectedId.lastIndexOf("-");
    if (pos > 0) {
      const segmentId = selectedId.substring(0, pos);
      element = document.getElementById(segmentId);
      if (element) {
        setSelectedSegmentId(segmentId);
        scrollIntoView(element);
      }
    }
  }

  let idprefix = "";
  let parserId = "";

  if (fields && fields.length > 0) {
    return (
      <table className="table" style={{ tableLayout: "unset" }}>
        <thead>
          <tr>
            <th colSpan="100" className="table-header">
              {typeDesc}
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map((fld, idx) => {
            newSegment = false;
            fld.id = currentId;
            currentId = currentId + 1;
            /* Header segment needs to specify if this is a request or response since they otherwise look the same - only affects first level of fields */
            idprefix =
              fld.transactionIndex === -1 && fld.segmentCode === "REQ"
                ? isRequest
                  ? "REQ-"
                  : "RES-"
                : "";
            parserId = `${idprefix}${fld.transactionIndex}-${fld.segmentCode}-${fld.fieldId}`;
            if (fld.segmentCode !== lastSegment) {
              newSegment = true;
              lastSegment = fld.segmentCode;
            }
            return (
              <Fragment key={idx}>
                <PacketParseSegmentHeader
                  id={`${idprefix}${fld.transactionIndex}-${fld.segmentCode}`}
                  key={"ppsh" + idx}
                  newSegment={newSegment}
                  segmentCode={fld.segmentCode}
                  segmentDescription={fld.segmentDescription}
                  isSelected={
                    selectedSegmentId ===
                    `${idprefix}${fld.transactionIndex}-${fld.segmentCode}`
                  }
                />
                <StyledFieldRow
                  id={parserId}
                  key={fld.id + idx}
                  className={
                    packetParserData.selectedFieldId.isRequest === isRequest &&
                    packetParserData.selectedFieldId.fieldId === parserId
                      ? "selected-row"
                      : ""
                  }
                  onClick={() => {
                    idprefix =
                      fld.transactionIndex === -1 && fld.segmentCode === "REQ"
                        ? isRequest
                          ? "REQ-"
                          : "RES-"
                        : "";
                    parserId = `${idprefix}${fld.transactionIndex}-${fld.segmentCode}-${fld.fieldId}`;
                    onRowClick(parserId);
                  }}
                >
                  {isMobileSize ? (
                    <>
                      <td
                        className="parser-field"
                        style={{ width: "100%", padding: "8px" }}
                      >
                        <StyledMobileDenseTable>
                          <tbody>
                            <tr>
                              <td className="dense-label">Field Description</td>
                              <td>{fld.fieldName}</td>
                            </tr>
                            <tr>
                              <td className="dense-label">Tx</td>
                              <td>{fld.transactionIndex + 1}</td>
                            </tr>
                            <tr>
                              <td className="dense-label">Code</td>
                              <td>{fld.fieldId}</td>
                            </tr>
                            <tr>
                              <td className="dense-label">Value</td>
                              <td>{transformVal(fld)}</td>
                            </tr>
                            {fld.value &&
                              fld.value.metadata &&
                              fld.value.metadata.description && (
                                <tr>
                                  <td className="dense-label">
                                    Value Description
                                  </td>
                                  <td>{fld.value.metadata.description}</td>
                                </tr>
                              )}
                            {fld.value &&
                              fld.value.metadata &&
                              fld.value.metadata.summary && (
                                <tr>
                                  <td className="dense-label">Value Summary</td>
                                  <td>{fld.value.metadata.summary}</td>
                                </tr>
                              )}
                          </tbody>
                        </StyledMobileDenseTable>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="parser-field" style={{ width: "15%" }}>
                        {fld.fieldName}
                      </td>
                      <td className="parser-field" style={{ width: "5%" }}>
                        {fld.transactionIndex + 1}
                      </td>
                      <td className="parser-field" style={{ width: "5%" }}>
                        {fld.fieldId}
                      </td>
                      <td className="parser-field" style={{ width: "25%" }}>
                        {transformVal(fld)}
                      </td>
                      <td style={{ width: "20%" }}>
                        {fld.value &&
                          fld.value.metadata &&
                          fld.value.metadata.description}
                      </td>
                      <td style={{ width: "30%" }}>
                        {fld.value &&
                          fld.value.metadata &&
                          fld.value.metadata.summary}
                      </td>
                    </>
                  )}
                </StyledFieldRow>
                {fld.subFields.map((sf, idx1) => {
                  sf.id = currentId;
                  currentId = currentId + 1;
                  parserId = `${sf.transactionIndex}-${fld.segmentCode}-${
                    sf.fieldId
                  }${
                    sf.fieldGroupOrdinal === 0 ? "" : "-" + sf.fieldGroupOrdinal
                  }`;
                  return (
                    <Fragment key={idx1}>
                      <StyledFieldRow
                        id={parserId}
                        key={sf.id + idx1}
                        className={
                          packetParserData.selectedFieldId.isRequest ===
                            isRequest &&
                          packetParserData.selectedFieldId.fieldId === parserId
                            ? "selected-row"
                            : ""
                        }
                        onClick={() => {
                          parserId = `${sf.transactionIndex}-${
                            fld.segmentCode
                          }-${sf.fieldId}${
                            sf.fieldGroupOrdinal === 0
                              ? ""
                              : "-" + sf.fieldGroupOrdinal
                          }`;
                          onRowClick(parserId);
                        }}
                      >
                        {isMobileSize ? (
                          <>
                            <td
                              className="parser-field"
                              style={{
                                width: "100%",
                                padding: "8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <i className="fa fa-long-arrow-right"></i>
                              <StyledMobileDenseTable
                                style={{ marginLeft: "10px" }}
                              >
                                <tbody>
                                  <tr>
                                    <td className="dense-label">
                                      Field Description
                                    </td>
                                    <td>{sf.fieldName}</td>
                                  </tr>
                                  <tr>
                                    <td className="dense-label">Tx</td>
                                    <td>{sf.transactionIndex + 1}</td>
                                  </tr>
                                  <tr>
                                    <td className="dense-label">Code</td>
                                    <td>{sf.fieldId}</td>
                                  </tr>
                                  <tr>
                                    <td className="dense-label">Value</td>
                                    <td>{transformVal(sf)}</td>
                                  </tr>
                                  {sf.value &&
                                    sf.value.metadata &&
                                    sf.value.metadata.description && (
                                      <tr>
                                        <td className="dense-label">
                                          Value Description
                                        </td>
                                        <td>{sf.value.metadata.description}</td>
                                      </tr>
                                    )}
                                  {sf.value &&
                                    sf.value.metadata &&
                                    sf.value.metadata.summary && (
                                      <tr>
                                        <td className="dense-label">
                                          Value Summary
                                        </td>
                                        <td>{sf.value.metadata.summary}</td>
                                      </tr>
                                    )}
                                </tbody>
                              </StyledMobileDenseTable>
                            </td>
                          </>
                        ) : (
                          <>
                            <td
                              className="parser-field"
                              style={{
                                width: "15%",
                              }}
                            >
                              <i className="fa fa-long-arrow-right"></i>
                              &nbsp;&nbsp;
                              {sf.fieldName}
                            </td>
                            <td
                              className="parser-field"
                              style={{ width: "5%" }}
                            >
                              {sf.transactionIndex + 1}
                            </td>
                            <td
                              className="parser-field"
                              style={{ width: "5%" }}
                            >
                              {sf.fieldId}
                            </td>
                            <td
                              className="parser-field"
                              style={{ width: "25%" }}
                            >
                              {transformVal(sf)}
                            </td>
                            <td
                              className="hide-on-small"
                              style={{ width: "20%" }}
                            >
                              {sf.value &&
                                sf.value.metadata &&
                                sf.value.metadata.description}
                            </td>
                            <td
                              className="hide-on-medium hide-on-medium-small hide-on-small"
                              style={{ width: "30%" }}
                            >
                              {sf.value &&
                                sf.value.metadata &&
                                sf.value.metadata.summary}
                            </td>
                          </>
                        )}
                      </StyledFieldRow>
                      {sf.subFields.map((sf2, idx2) => {
                        sf2.id = currentId;
                        currentId = currentId + 1;
                        parserId = `${sf2.transactionIndex}-${
                          fld.segmentCode
                        }-${sf2.fieldId}${
                          sf2.fieldGroupOrdinal === 0
                            ? ""
                            : "-" + sf2.fieldGroupOrdinal
                        }`;
                        return (
                          <StyledFieldRow
                            id={parserId}
                            key={sf2.id + idx2}
                            className={
                              packetParserData.selectedFieldId.isRequest ===
                                isRequest &&
                              packetParserData.selectedFieldId.fieldId ===
                                parserId
                                ? "selected-row"
                                : ""
                            }
                            onClick={() => {
                              parserId = `${sf2.transactionIndex}-${
                                fld.segmentCode
                              }-${sf2.fieldId}${
                                sf2.fieldGroupOrdinal === 0
                                  ? ""
                                  : "-" + sf2.fieldGroupOrdinal
                              }`;
                              onRowClick(parserId);
                            }}
                          >
                            {isMobileSize ? (
                              <>
                                <td
                                  className="parser-field"
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <i className="fa fa-long-arrow-right"></i>
                                  <i className="fa fa-long-arrow-right"></i>
                                  <StyledMobileDenseTable
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="dense-label">
                                          Field Description
                                        </td>
                                        <td>{sf2.fieldName}</td>
                                      </tr>
                                      <tr>
                                        <td className="dense-label">Tx</td>
                                        <td>{sf2.transactionIndex + 1}</td>
                                      </tr>
                                      <tr>
                                        <td className="dense-label">Code</td>
                                        <td>{sf2.fieldId}</td>
                                      </tr>
                                      <tr>
                                        <td className="dense-label">Value</td>
                                        <td>{transformVal(sf2)}</td>
                                      </tr>
                                      {sf2.value &&
                                        sf2.value.metadata &&
                                        sf2.value.metadata.description && (
                                          <tr>
                                            <td className="dense-label">
                                              Value Description
                                            </td>
                                            <td>
                                              {sf2.value.metadata.description}
                                            </td>
                                          </tr>
                                        )}
                                      {sf2.value &&
                                        sf2.value.metadata &&
                                        sf2.value.metadata.summary && (
                                          <tr>
                                            <td className="dense-label">
                                              Value Summary
                                            </td>
                                            <td>
                                              {sf2.value.metadata.summary}
                                            </td>
                                          </tr>
                                        )}
                                    </tbody>
                                  </StyledMobileDenseTable>
                                </td>
                              </>
                            ) : (
                              <>
                                <td
                                  className="parser-field"
                                  style={{
                                    width: "15%",
                                  }}
                                >
                                  <i className="fa fa-long-arrow-right"></i>
                                  &nbsp;&nbsp;
                                  <i className="fa fa-long-arrow-right"></i>
                                  &nbsp;&nbsp;{sf2.fieldName}
                                </td>
                                <td
                                  className="parser-field"
                                  style={{
                                    width: "5%",
                                  }}
                                >
                                  {sf2.transactionIndex + 1}
                                </td>
                                <td
                                  className="parser-field"
                                  style={{
                                    width: "5%",
                                  }}
                                >
                                  {sf2.fieldId}
                                </td>
                                <td
                                  className="parser-field"
                                  style={{
                                    width: "25%",
                                  }}
                                >
                                  {transformVal(sf2)}
                                </td>
                                <td
                                  className="hide-on-small"
                                  style={{
                                    width: "20%",
                                  }}
                                >
                                  {sf2.value &&
                                    sf2.value.metadata &&
                                    sf2.value.metadata.description}
                                </td>
                                <td
                                  className="hide-on-medium hide-on-medium-small hide-on-small"
                                  style={{
                                    width: "30%",
                                  }}
                                >
                                  {sf2.value &&
                                    sf2.value.metadata &&
                                    sf2.value.metadata.summary}
                                </td>
                              </>
                            )}
                          </StyledFieldRow>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    return <></>;
  }
}

const StyledFieldRow = styled.tr`
  border-left: 4px solid transparent;

  &:hover {
    background-color: var(--table-hover-row-bg) !important;
    border-left: 4px solid var(--table-hover-row-border);
    cursor: pointer;
  }

  &:active,
  &.selected-row {
    color: #000;
    background-color: var(--table-hover-row-bg) !important;
    border-left: 4px solid var(--table-hover-row-border);
    cursor: pointer;
  }
`;
