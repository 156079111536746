import React, { Fragment, useState } from "react";
import { ruleTableImportModeTypes } from "../../../services/General";
import {
  emptyRuleDataSearch,
  fromRuleDataImportViewModel,
  getDropDownValue,
} from "../../../viewmodels/ruleTableDataVm";
import DatePickerInput from "../../common/input/DatePickerInput";
import FileUploadButton from "../../common/input/FileUploadButton";
import SelectInput from "../../common/input/SingleSelect";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import { apiImportRuleDataRows } from "../../../api/RuleTableDataApi";
import { notifySuccess } from "../../../services/NotificationService";
import Spinner from "../../common/ui/Spinner";
import useApi from "../../../hooks/useApi";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function RuleTableImportSection({
  ruleTableId,
  search,
  ruleTableImport,
  setRuleTableImport,
  onLoadData,
  errors,
}) {
  const { loading: uploading, api: apiImport } = useApi(apiImportRuleDataRows);
  const [validationFailures, setValidationFailures] = useState([]);
  const [searchErrors, setSearchErrors] = useState({
    ...emptyRuleDataSearch,
    pageSize: 10,
  });

  function handleImportModeChange(option) {
    setRuleTableImport({ ...ruleTableImport, importModeVm: option });
  }

  function handleTerminationDateChange(date) {
    setRuleTableImport({ ...ruleTableImport, terminationDate: date });
  }

  function handleUploadFileContents(content) {
    var model = fromRuleDataImportViewModel(ruleTableImport);
    model.fileContents = content;

    apiImport.call({ id: ruleTableId, search, model }, (result) => {
      // Check for validation errors in result. If any, show them to the user.
      if ((result.importValidationFailures || []).length > 0) {
        setValidationFailures(result.importValidationFailures);
      } else {
        // Success
        setValidationFailures([]);
        notifySuccess("File imported successfully");
        onLoadData();
      }
    });
  }

  return (
    <>
      <StyledRowDiv className="row">
        <div className="col-12 col-lg-6">
          <FileUploadButton
            fileTypes=".csv"
            uploading={uploading}
            uploadFileContents={handleUploadFileContents}
          />
        </div>
      </StyledRowDiv>
      <StyledRowDiv className="row">
        <div className="col-12 col-lg-6">
          <SelectInput
            id="importMode"
            name="importMode"
            label="Import Mode"
            options={ruleTableImportModeTypes}
            value={
              ruleTableImport.importModeVm === ""
                ? ruleTableImport.importMode
                : ruleTableImport.importModeVm
            }
            onChange={(option) => handleImportModeChange(option)}
            placeholder=""
            error={errors.importMode}
          />
        </div>
        {getDropDownValue(ruleTableImport, "importMode") ===
          "terminatechanges" && (
          <div className="col-12 col-lg-6">
            <DatePickerInput
              id="terminationDate"
              label="Termination Date"
              name="terminationDate"
              value={ruleTableImport.terminationDate}
              placeholder="Termination Date"
              onChange={handleTerminationDateChange}
              error={errors.terminationDate}
              showTimeInput={false}
            />
          </div>
        )}
      </StyledRowDiv>
      {uploading ? (
        <Spinner spinnerStyle={{ height: "200px", lineHeight: "200px" }} />
      ) : (
        <>
          {validationFailures.length > 0 && (
            <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
              <h3 style={{ fontSize: "20px" }}>Import Errors</h3>
              <div className="row">
                <div className="flex-row-with-wrap">
                  <span
                    style={{ color: "var(--notify-danger)" }}
                    className="material-icons"
                  >
                    warning
                  </span>
                  <strong style={{ color: "var(--notify-danger)" }}>
                    Warning:&nbsp;&nbsp;
                  </strong>
                  The following validation errors occurred during import.
                  Correct the problems and try the import again.
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ResponsiveGrid
                    gridId="RuleTableUploadDataValidationErrors"
                    totalRecords={validationFailures.length}
                    search={searchErrors}
                    setSearch={setSearchErrors}
                    dataRows={validationFailures}
                    enableClientRowPager={true}
                    columnDefs={[
                      {
                        name: "row",
                        label: "Row",
                        disableSort: true,
                        style: { width: "100px" },
                        getValue: (row) => row.row || 1,
                      },
                      {
                        name: "columnName",
                        label: "Column",
                        disableSort: true,
                        style: { width: "25%" },
                      },
                      {
                        name: "message",
                        label: "Error",
                        disableSort: true,
                        style: { width: "75%" },
                      },
                    ]}
                  />
                </div>
              </div>
            </StyledRowDiv>
          )}
        </>
      )}
    </>
  );
}

export default RuleTableImportSection;
