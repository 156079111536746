import _ from "lodash";
import { states } from "../services/General";
import { parseISO } from "date-fns";
import { pageSize } from "../constants/Environments";

export const emptyClientSearch = {
  clientId: "",
  name: "",
  address: "",
  city: "",
  state: "",
  active: true,
  orderBy: ["lastModifiedDate-"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  clientsCount: 0,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export const emptyClient = {
  effectiveDate: new Date("1/1/2000"),
  terminationDate: new Date("1/1/3000"),
  clientId: "",
  name: "",
  description: "",
  defaultOptions: {
    settings: [],
    tpaPhoneReplacement: false,
    tpaPhoneReplacementMemberId: "",
  },
  tpaPhoneReplacement: false,
  tpaPhoneReplacementMemberId: "",
  newClientId: "",
  oldClientId: "",
  addresses: [],
  phoneNumbers: [],
  contacts: [],
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  if (vm.terminationDate > new Date("1/1/2999")) vm.terminationDate = null;
  vm.state = (vm.stateVm || { value: "" }).value || "";

  var settings = [...vm.defaultOptions.settings];
  vm.defaultOptions.settings = settings.map((s) => {
    var vals = s.valuesVm.split(",").trimArray();
    s.values = vals;
    return s;
  });

  vm.contacts = [];
  if (vm.lastName) {
    vm.contacts.push({
      title: (vm.title || "").toUpperCase(),
      gender: "Unspecified",
      subtitle: (vm.subtitle || "").toUpperCase(),
      suffix: (vm.suffix || "").toUpperCase(),
      firstName: (vm.firstName || "").toUpperCase(),
      middleName: (vm.middleName || "").toUpperCase(),
      lastName: (vm.lastName || "").toUpperCase(),
      emailAddresses: vm.emailAddressesVm.split(",").map((m) => {
        return { emailAddress: (m || "").toUpperCase() };
      }),
    });
  }
  vm.addresses = [];
  if (vm.address) {
    vm.addresses.push({
      addressType: "Physical",
      address1: (vm.address || "").toUpperCase(),
      city: (vm.city || "").toUpperCase(),
      state: (vm.state || "None").toUpperCase(),
      zipCode: (vm.zipCode || "").toUpperCase(),
    });
  }

  vm.phoneNumbers = [];
  if (vm.number) {
    vm.phoneNumbers.push({
      phoneType: "Phone",
      number: (vm.number || "").toUpperCase(),
    });
  }

  vm.defaultOptions.tpaPhoneReplacement = vm.tpaPhoneReplacement;
  vm.defaultOptions.tpaPhoneReplacementMemberId =
    vm.tpaPhoneReplacementMemberId;

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);
  if (_.isNull(vm.effectiveDate)) {
    vm.effectiveDate = emptyClient.effectiveDate;
  } else {
    vm.effectiveDate = parseISO(vm.effectiveDate);
  }
  if (_.isNull(vm.terminationDate))
    vm.terminationDate = emptyClient.terminationDate;
  else {
    vm.terminationDate = parseISO(vm.terminationDate);
  }
  vm.defaultOptions.settings.map((s) => {
    s.valuesVm = s.values.join(",");
    return s;
  });
  if (vm.contacts.length > 0) {
    vm.title = vm.contacts[0].title || "";
    vm.suffix = vm.contacts[0].suffix || "";
    vm.subtitle = vm.contacts[0].subtitle || "";
    vm.firstName = vm.contacts[0].firstName || "";
    vm.lastName = vm.contacts[0].lastName || "";
    vm.middleName = vm.contacts[0].middleName || "";
    vm.emailAddressesVm =
      vm.contacts[0].emailAddresses.map((m) => m.emailAddress).join(",") || "";
  } else {
    vm.firstName = "";
    vm.middleName = "";
    vm.lastName = "";
    vm.title = "";
    vm.emailAddressesVm = "";
    vm.suffix = "";
    vm.subtitle = "";
  }
  if (vm.addresses.length > 0) {
    vm.address = vm.addresses[0].address1 || "";
    vm.city = vm.addresses[0].city || "";
    vm.state = vm.addresses[0].state || "";
    vm.zipCode = vm.addresses[0].zipCode || "";
    vm.stateVm = states.find((d) => d.value === vm.state) || {
      value: "",
      label: "",
    };
  } else {
    vm.address = "";
    vm.city = "";
    vm.state = "";
    vm.zipCode = "";
  }
  if (vm.phoneNumbers.length > 0) {
    vm.number = vm.phoneNumbers[0].number || "";
  } else {
    vm.number = "";
  }

  vm.tpaPhoneReplacement = vm.defaultOptions.tpaPhoneReplacement;
  vm.tpaPhoneReplacementMemberId =
    vm.defaultOptions.tpaPhoneReplacementMemberId;
  return vm;
}
