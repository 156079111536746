import React from "react";
import { useNavigate } from "react-router-dom";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function AuditBulkChangeRecordList({
  transactionId,
  auditRecords,
  search,
  setSearch,
  totalRecords,
  onRollbackChange,
}) {
  const navigate = useNavigate();

  function getActions(dataRow) {
    const entityType = dataRow.entityType;
    const documentId = dataRow.documentId;

    const actions = [];

    actions.push({
      name: "View changes",
      onClick: () =>
        navigate(
          "/auditrecord/" +
            encodeURIComponent(entityType) +
            "/" +
            documentId +
            "/" +
            transactionId
        ),
    });
    actions.push({
      name: "Rollback this change",
      onClick: () => onRollbackChange(entityType, documentId),
    });

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="AuditBulkChangeRecords"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        allowFreeTextSearch={true}
        dataRows={auditRecords}
        enableClientRowPager={true}
        columnDefs={[
          {
            name: "documentId",
            label: "Document Id",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "35%" },
            getValue: (row, index, searchResultHtml) => (
              <button
                className="btn btn-link link-underline"
                onClick={() =>
                  navigate(
                    "/auditrecord/" +
                      encodeURIComponent(row.entityType) +
                      "/" +
                      row.documentId +
                      "/" +
                      transactionId
                  )
                }
              >
                <span className="force-wrap">{searchResultHtml}</span>
              </button>
            ),
          },
          {
            name: "modificationType",
            label: "Operation",
            disableSort: true,
            freeTextSearch: true,
            className: "d-none d-md-table-cell",
            style: { width: "15%" },
            getValue: (row, index, searchResultHtml) => searchResultHtml,
          },
          {
            name: "entityType",
            label: "Entity",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "35%" },
            getValue: (row, index, searchResultHtml) => searchResultHtml,
          },
          {
            name: "entityKey",
            label: "Key",
            disableSort: true,
            freeTextSearch: true,
            className: "d-none d-lg-table-cell",
            style: { width: "40%" },
            getValue: (row, index, searchResultHtml) =>
              row.bulkChange ? (
                <i>
                  {`${row.numChanges} ${
                    row.numChanges === 1 ? " change" : "changes"
                  }`}
                </i>
              ) : (
                searchResultHtml
              ),
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default AuditBulkChangeRecordList;
