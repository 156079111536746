import React from "react";
import { useNavigate } from "react-router-dom";
import {
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
} from "../../../services/General";
import StatusPill from "../../common/ui/StatusPill";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function TestsList({
  tests,
  currentRunTestIndex,
  isRunning,
  isGeneratingData,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
  onCopy,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function handleRun(id) {
    navigate(`/test/${id}/true`);
  }

  function getActions(dataRow) {
    const id = dataRow.id;
    const description = dataRow.description;

    const actions = [];

    actions.push({
      name: "Run",
      onClick: () => handleRun(id),
    });

    actions.push({
      name: "Edit",
      onClick: () => navigate("/test/" + id),
    });

    actions.push({
      name: "Duplicate",
      onClick: () => onCopy(id, description),
    });

    actions.push({
      name: "Remove",
      onClick: () => onDelete(id, description),
    });

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () => navigate("/auditrecord/test/" + id + "/0"),
      });
    }

    return actions;
  }

  function getRunColumnIcon(id, index) {
    if (isRunning && currentRunTestIndex === index) {
      return <span className="material-icons">directions_run</span>;
    } else if (isGeneratingData && currentRunTestIndex === index) {
      return <span className="material-icons">build</span>;
    }

    if (!isRunning && !isGeneratingData) {
      return (
        <span
          className="material-icons"
          style={{ cursor: "pointer" }}
          title="Run this test"
          onClick={() => handleRun(id)}
        >
          play_circle_outline
        </span>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      <ResponsiveGrid
        gridId="Tests"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={tests}
        columnDefs={[
          {
            name: "run",
            label: "Run",
            disableSort: true,
            style: { width: "60px", maxWidth: "15%", textAlign: "center" },
            getValue: (row, index) => getRunColumnIcon(row.id, index),
          },
          {
            name: "description",
            label: "Test Description",
            style: { width: "40%" },
            getValue: (row) =>
              isRunning || isGeneratingData ? (
                <>{row.description}</>
              ) : (
                <button
                  className="btn btn-link link-underline"
                  onClick={() => navigate("/test/" + row.id)}
                >
                  {row.description}
                </button>
              ),
          },
          {
            name: "testGroupName",
            label: "Group Description",
            hidden: !search.includeTestsInGroup,
            style: { width: "40%" },
            getValue: (row) =>
              isRunning || isGeneratingData ? (
                <>{row.testGroupName}</>
              ) : (
                <button
                  className="btn btn-link link-underline"
                  onClick={() => navigate("/testgroup/" + row.testGroupId)}
                >
                  {row.testGroupName}
                </button>
              ),
          },
          {
            name: "stepCount",
            label: "# of Steps",
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) => (row.testSteps || []).length,
          },
          {
            name: "dataCount",
            label: "# Data Rows",
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) => {
              // Account for and remove header row in count
              const len =
                (row?.testVariableCsv?.split("\r\n") || []).length - 1;
              return len < 0 ? 0 : len;
            },
          },
          {
            name: "lastRunDate",
            label: "Last Run",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
            getValue: (row) => formatDateTimeUtcZoneForDisplay(row.lastRunDate),
          },
          {
            name: "lastRunBy",
            label: "Run By",
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
          },
          {
            name: "lastRunDurationMilliseconds",
            label: "Duration",
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              getTimeframeFromMilliseconds(row.lastRunDurationMilliseconds),
          },
          {
            name: "lastRunResultStatus",
            label: "Last Result",
            style: { width: "30%" },
            noForceWrap: true,
            getValue: (row) => <StatusPill status={row.lastRunResultStatus} />,
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default TestsList;
