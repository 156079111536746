/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadProcessHistory(search, token, signal) {
  const uri = API_URL + "/reportprocesshistory" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({
      operand: "OR",
      name: "id",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "parentHistoryId",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "description",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "runType",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "reportName",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "runBy",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.description && search.description.length > 0)
    where.push({
      name: "description",
      operator: "=",
      values: [search.description],
    });

  if (search && search.runBy && search.runBy.length > 0)
    where.push({
      name: "runBy",
      operator: "=",
      values: [search.runBy],
    });

  if (search && search.runType && search.runType.length > 0)
    where.push({
      name: "runType",
      operator: "=",
      values: [search.runType],
    });

  if (search && search.reportName && search.reportName.length > 0)
    where.push({
      name: "reportName",
      operator: "=",
      values: [search.reportName],
    });

  if (search && search.startDate && _.isDate(search.startDate))
    where.push({
      name: "runDate",
      operator: ">=",
      values: [search.startDate],
    });
  if (search && search.endDate && _.isDate(search.endDate))
    where.push({
      name: "runDate",
      operator: "<",
      values: [search.endDate],
    });

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy || '["runDate-"]' };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadProcessHistoryRecord(id, token, signal) {
  let uri = API_URL + "/reportprocesshistory/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDownloadProcessFile(id, token, signal) {
  let uri = API_URL + "/loadprocessfile/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRollbackResults(summaryHistoryId, token, signal) {
  let uri =
    API_URL + "/revertprocesshistoryto/" + summaryHistoryId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadProcessHistory.funcName = "apiLoadProcessHistory";
apiLoadProcessHistoryRecord.funcName = "apiLoadProcessHistoryRecord";
apiDownloadProcessFile.funcName = "apiDownloadProcessFile";
apiRollbackResults.funcName = "apiRollbackResults";
