import React from "react";
import styled from "styled-components";
import DatePickerInput from "../../common/input/DatePickerInput";
import TextInput from "../../common/input/TextInput";

function DiagnosticsSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onStartDateChange,
  onEndDateChange,
}) {
  return (
    <form onSubmit={onSearch} onReset={onReset}>
      <StyledSearchForm>
        <DatePickerInput
          id="startDate"
          name="startDate"
          label="Start Time"
          value={search.startDate}
          placeholder="Start Time"
          onChange={onStartDateChange}
          error={errors.startDate}
        />
        <DatePickerInput
          id="endDate"
          label="End Time"
          name="endDate"
          value={search.endDate}
          placeholder="End Time"
          onChange={onEndDateChange}
          error={errors.endDate}
        />
        <TextInput
          id="sliceSeconds"
          label="Slice Seconds"
          onChange={onChange}
          placeholder="Slice Seconds"
          name="sliceSeconds"
          value={search.sliceSeconds}
          error={errors.sliceSeconds}
          isNumber={true}
        />
        <div>
          <input type="submit" value={"Update"} className="btn btn-primary" />
          <input
            type="reset"
            value="Clear"
            className="btn btn-secondary"
            style={{ marginLeft: "10px" }}
          />
        </div>
      </StyledSearchForm>
    </form>
  );
}

const StyledSearchForm = styled.div`
  display: flex;
  column-gap: 24px;
  row-gap: 14px;
  align-items: flex-end;
  flex-wrap: wrap;

  label {
    color: var(--text-medium);
  }

  input.btn.btn-primary,
  input.btn.btn-secondary {
    height: 42px;
    width: 106px;
    min-width: 86px;
  }
`;

export default DiagnosticsSearchForm;
