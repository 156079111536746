/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiImportData(model, token, signal) {
  let uri = API_URL + "/bulkimport/" + model.dataSource + getQueryString();

  // http://localhost:7071/api/bulkimport/discount?importmode=terminatechanges&terminationDate=3/1/2023

  // Add import mode and termination date (if needed) to querystring
  uri += "&importmode=" + model.importMode;

  if (model.importMode === "terminatechanges" && model.terminationDate) {
    // Remove ms from this date because the server does not see it as a valid date if it contains a .
    const terminationDate = model.terminationDate
      .toISOString()
      .replace(".000Z", "Z");
    uri += "&terminationDate=" + terminationDate;
  }

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: model.fileContents,
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiImportData.funcName = "apiImportData";
