import React, { useState, useEffect } from "react";
import ClientsList from "./ClientsList";
import ClientSearchForm from "./ClientSearchForm";
import Spinner from "../../common/ui/Spinner";
import { notifySuccess } from "../../../services/NotificationService";
import {
  createViewModel,
  emptyClientSearch,
} from "../../../viewmodels/clientsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useClients } from "../../../contexts/ClientsContext";
import {
  apiLoadClients,
  apiDeleteClient,
  apiCopyClient,
} from "../../../api/ClientApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import { useNavigate } from "react-router-dom";
import DuplicateRecordDialog from "../../dialogs/DuplicateRecordDialog";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Clients() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { clientsData, setClientsData } = useClients();
  const { loading, api: apiLoad } = useApi(apiLoadClients);
  const { loading: deleting, api: apiDelete } = useApi(apiDeleteClient);
  const { loading: copying, api: apiCopy } = useApi(apiCopyClient);
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState(true);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [altDeleteId, setAltDeleteId] = useState("");
  const { isMobileSize } = useMobile();

  let clients = [];
  if (clientsData && clientsData.clients) {
    clients = clientsData.clients;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setClientsData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //   useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadClients();
    }
  }, [auth.authenticated, loadData]);

  async function loadClients() {
    apiLoad.call(
      clientsData.search,
      (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setClientsData({
          type: ContextProviderActions.loadClients,
          payload: {
            clients: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setClientsData({
      type: ContextProviderActions.saveClientSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyClientSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : clientsData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function clearClientListCache() {
    // Copy, delete, and save operations invalidate the cache.
    setClientsData({
      type: ContextProviderActions.setClientListCache,
      payload: [],
    });
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = clientsData.search.orderBy.indexOf(`${event.target.id}+`);
    var indexDesc = clientsData.search.orderBy.indexOf(`${event.target.id}-`);

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...clientsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...clientsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...clientsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = { ...clientsData.search, ...newSearch, pageNumber: 1 };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({ ...clientsData.search, [target.name]: target.value });
  }

  function handleActiveChange({ target }) {
    setSearchChanges({ ...clientsData.search, active: target.checked });
  }

  async function deleteClient(id) {
    apiDelete.call(id, (result) => {
      notifySuccess("Client disabled successfully");
      clearClientListCache();
      setLoadData(true);
    });
  }

  async function handleDelete(id, clientId) {
    setDeleteId(id);
    setAltDeleteId(clientId);
    setShowDeleteModal(true);
  }

  async function performDelete() {
    setShowDeleteModal(false);
    await deleteClient(deleteId);
  }

  const handleCancelModal = () => {
    setShowCopyModal(false);
  };

  function startCopy(id) {
    let updated = { ...clientsData.search, oldClientId: id };
    setSearchChanges(updated);
    setShowCopyModal(true);
  }

  async function copyClient(id, newId) {
    setShowCopyModal(false);

    apiCopy.call({ id, newId }, (result) => {
      notifySuccess("Client copied successfully to " + newId);
      clearClientListCache();
      setLoadData(true);
    });
  }

  async function handleCopy(newClientId) {
    await copyClient(clientsData.search.oldClientId, newClientId);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (clientsData.search.clientId !== "") numFilters++;
    if (clientsData.search.name !== "") numFilters++;
    if (clientsData.search.address !== "") numFilters++;
    if (clientsData.search.city !== "") numFilters++;
    if (clientsData.search.state !== "") numFilters++;
    if (clientsData.search.active !== true) numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledScreenHelpDiv>
        {((clientsData.search.showAdvancedFilter && !isMobileSize) ||
          !clientsData.search.showAdvancedFilter) && (
          <HelpLink path="/Configure/Clients-List-Screen" label="Help" />
        )}
      </StyledScreenHelpDiv>
      <StyledHeaderRowDiv>
        <h1>Clients</h1>
        <StyledHeaderRowButtonDiv>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/client")}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="material-icons">add</span>
            {"  "}Add Client
          </button>
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search Client Id or Name"
        search={clientsData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <DuplicateRecordDialog
        title="Duplicate Client"
        instructions={`Enter a new id for the client to be copied from '${clientsData.search.oldClientId}'.`}
        value={clientsData.search.newClientId || ""}
        showModal={showCopyModal}
        onCancel={handleCancelModal}
        onCopy={handleCopy}
      />
      <ConfirmDialog
        title="Remove Client"
        question={`Are you sure you wish to deactivate the client '${altDeleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={performDelete}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              clientsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={clientsData.search}
            setSearch={setSearchChanges}
            helpLink="/Configure/Clients-List-Screen&anchor=filters"
          >
            <ClientSearchForm
              errors={errors}
              search={clientsData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onActiveChange={handleActiveChange}
            />
          </GridAdvancedFilter>
        </div>

        {clientsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || deleting || copying ? (
              <Spinner />
            ) : (
              <ClientsList
                clients={clients}
                onDelete={handleDelete}
                search={clientsData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={clientsData.count}
                onCopy={startCopy}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default Clients;
