import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyGroup, emptyGroupSearch } from "../viewmodels/groupsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadGroups:
      return {
        ...state,
        groups: action.payload.groups,
        count: action.payload.count,
      };

    case ContextProviderActions.saveGroupSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadGroup:
      return {
        ...state,
        group: action.payload.group,
      };

    case ContextProviderActions.setGroupListCache:
      return {
        ...state,
        groupListCache: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in GroupsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  groups: [],
  group: emptyGroup,
  search: emptyGroupSearch,
  count: 0,
  groupListCache: [],
};

const Groups = createContext({
  groupsData: initialState,
  setGroupsData: () => null,
});

// eslint-disable-next-line react/prop-types
const GroupsProvider = ({ children }) => {
  const [groupsData, setGroupsData] = useReducer(reducer, initialState);

  return (
    <Groups.Provider value={{ groupsData, setGroupsData }}>
      {children}
    </Groups.Provider>
  );
};

export default GroupsProvider;
export const useGroups = () => useContext(Groups);
