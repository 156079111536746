import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createViewModel,
  fromViewModel,
  emptyTest,
  emptyTestStepSearch,
  emptyTestStep,
  getExpectedResponseJsonFromString,
  emptyTestDataFileErrorsSearch,
} from "../../../viewmodels/testsVm";
import {
  formatDateTimeUtcZoneForDisplay,
  generateUUID,
  getTimeframeFromMilliseconds,
  handleCollapseExpandAll,
  handleCollapseExpandSection,
} from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import CheckboxInput from "../../common/input/CheckboxInput";
import { useTests } from "../../../contexts/TestsContext";
import { useAuth } from "../../../contexts/AuthContext";
import {
  apiAddTest,
  apiGenerateTestData,
  apiLoadTest,
  apiRunTest,
  apiUpdateTest,
} from "../../../api/TestApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import ReadOnly from "../../common/input/ReadOnly";
import StatusPill from "../../common/ui/StatusPill";
import TestStepList from "./TestStepList";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import TestStepDialog from "./TestStepDialog";
import TestDataFileSection from "./TestDataFileSection";
import HelpLink from "../../common/ui/HelpLink";
import TestsWebSocketHubConnections from "./TestsWebSocketHubConnections";

function Test() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { testsData, setTestsData } = useTests();
  const { loading, api: apiLoad } = useApi(apiLoadTest);
  const { loading: adding, api: apiAdd } = useApi(apiAddTest);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateTest);
  const { loading: running, api: apiRun } = useApi(apiRunTest);
  const { loading: startingDataGeneration, api: apiGenerateData } =
    useApi(apiGenerateTestData);
  const [editItem, setEditItem] = useState(emptyTestStep);
  const [showStepDialog, setShowStepDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [lastSavedChanges, setLastSavedChanges] = useState(emptyTest);
  const [changes, setChanges] = useState(emptyTest);
  const [stepSearch, setStepSearch] = useState(emptyTestStepSearch);
  const [validationFailures, setValidationFailures] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [searchData, setSearchData] = useState({
    ...emptyTestDataFileErrorsSearch,
    pageSize: 10,
  });
  const [generationIsWaitingToStart, setGenerationIsWaitingToStart] =
    useState(false);
  const [generationInProgress, setGenerationInProgress] = useState(false);
  const [generationIsEnding, setGenerationIsEnding] = useState(false);

  const [collapsedState, setCollapsedState] = useState([
    { name: "Test", collapsed: false },
    { name: "Last Run Info", collapsed: false },
    { name: "Test Data", collapsed: false },
    { name: "Steps", collapsed: false },
  ]);

  const resId = params && params.id;
  const autoRun = params && params.autorun === "true";

  useEffect(() => {
    if (
      testsData.dataGenerationUpdateState &&
      testsData.dataGenerationUpdateState.testIdQueue
    ) {
      // Look for testid in state's queue to determine if this one is running
      const isThisTestGenenerating =
        testsData.dataGenerationUpdateState.testIdQueue.findIndex(
          (t) => t.testId === resId
        ) >= 0;

      if (isThisTestGenenerating) {
        setGenerationIsWaitingToStart(false);
      } else if (generationInProgress && !isThisTestGenenerating) {
        setGenerationIsEnding(true);

        // Wait a few seconds and then hide the progress
        window.setTimeout(() => {
          setGenerationInProgress(false);
          setGenerationIsEnding(false);

          // Load the test again to show the updated test data.
          loadTest(false);
          setSearchData({
            ...searchData,
            colPageSize: 4,
            colPageNumber: 1,
          });
        }, 4000);
      }
    }
  }, [testsData.dataGenerationUpdateState]);

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setTestsData({
        type: ContextProviderActions.loadTest,
        payload: emptyTest,
      });

      loadTest();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (testsData.test) {
      setChanges(testsData.test);
    } else {
      setChanges(emptyTest);
    }
  }, [testsData.test]);

  useEffect(() => {
    setHasChanges(
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
        JSON.stringify(_.cloneDeep(changes))
    );
  }, [lastSavedChanges, changes]);

  async function loadTest(collapseTestData = true) {
    if (!resId) {
      setTestsData({
        type: ContextProviderActions.loadTest,
        payload: emptyTest,
      });
      setChanges(emptyTest);
      return;
    }

    apiLoad.call(resId, async (result) => {
      let vm = emptyTest;
      if (!result) {
        notifyError("Test does not exist");
      } else {
        vm = createViewModel(result);
      }

      setTestsData({
        type: ContextProviderActions.loadTest,
        payload: vm,
      });
      setChanges(vm);
      setLastSavedChanges(vm);

      // If this test has test data, collapse the data file section so test data will not show by default
      if (collapseTestData && vm.testVariableCsv && vm.testVariableCsv !== "") {
        handleCollapseExpandSection(
          "Test Data",
          true,
          collapsedState,
          setCollapsedState
        );
      }

      if (autoRun === true) {
        await handleRunTest();
      }
    });
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    if (changes.description.trim() === "")
      _errors.description = "Description is required";

    if (changes.isDataAutoPopulated === true) {
      if (_.trim(changes.autoPopulateTestDataType) === "") {
        _errors.autoPopulateTestDataType = "A value is required";
      }
      if (_.trim(changes.autoPopulateGroupId) === "") {
        _errors.autoPopulateGroupId = "A value is required";
      }
      if (_.trim(changes.autoPopulateRecordCount) === "") {
        _errors.autoPopulateRecordCount = "A value is required";
      }
      if (
        changes.autoPopulateTestDataTypeVm.value ===
          "DataFromHighFrequencyClaims" ||
        changes.autoPopulateTestDataTypeVm.value ===
          "DataFromLowFrequencyClaims"
      ) {
        if (_.trim(changes.autoPopulateClaimThreshold) === "") {
          _errors.autoPopulateClaimThreshold = "A value is required";
        }
      }
      if (
        changes.autoPopulateDataStartDate !== null &&
        changes.autoPopulateDataEndDate !== null
      ) {
        if (
          changes.autoPopulateDataStartDate > changes.autoPopulateDataEndDate
        ) {
          _errors.autoPopulateDataStartDate =
            "Start Date must be less than or equal to End Date";
        }
      }
    }

    if ((changes.testSteps || []).length < 1)
      _errors.testSteps = "Test must have at least one step.";

    // Make sure step expected resonses parse to valid json
    if ((changes.testSteps || []).length > 0) {
      let stepErrors = "";
      (changes.testSteps || []).forEach((step) => {
        const json = getExpectedResponseJsonFromString(
          step.ordinal,
          step.expectedResponseVm
        );
        if (json === null)
          stepErrors += `Step ${step.ordinal}'s Expected Response field has an invalid format. `;
      });
      if (stepErrors !== "") _errors.testSteps = stepErrors;
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleAutoCleanupChange({ target }) {
    let changed = { ...changes };
    changed.autoCleanup = target.checked;
    setChanges(changed);
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };

    if (!resId) {
      addTest(newVm);
    } else updateTest(newVm.id, newVm);
  }

  async function addTest(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      const retModel = createViewModel(result);
      setChanges(retModel);
      setLastSavedChanges(retModel);

      if ((result.importValidationFailures || []).length > 0) {
        setValidationFailures(result.importValidationFailures);
        notifyWarn(
          "Please correct the data file validation warnings before saving."
        );
      } else {
        // Success
        setValidationFailures([]);
        notifySuccess("Test '" + vm.description + "' saved successfully");

        // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
        navigate("/test/" + result.id, { replace: true });
      }
    });
  }

  async function updateTest(testId, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id: testId, model }, (result) => {
      const retModel = createViewModel(result);
      setChanges(retModel);
      setLastSavedChanges(retModel);

      if ((result.importValidationFailures || []).length > 0) {
        setValidationFailures(result.importValidationFailures);
        notifyWarn(
          "Test '" +
            vm.description +
            "' saved successfully but the data file has validation warnings."
        );
      } else {
        // Success
        setValidationFailures([]);
        notifySuccess("Test '" + vm.description + "' saved successfully");
      }
    });
  }

  async function handleRunTest() {
    // Check for unsaved changes
    if (
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
      JSON.stringify(_.cloneDeep(changes))
    ) {
      notifyWarn("Please save your changes before running the test.");
      return;
    }

    apiRun.call({ testId: resId }, (result) => {
      const retModel = createViewModel(result);
      setChanges(retModel);
      setLastSavedChanges(retModel);
      notifySuccess("Test run complete.");

      // If this was an auto run, route back to normal test edit page so refresh does not cause autorun parameter to run the test again.
      if (autoRun) {
        navigate("/test/" + resId);
      }
    });
  }

  function handleInstantSearchChange(value) {
    setStepSearch({ ...stepSearch, highlightText: value });
  }

  function handleSaveStep() {
    errors.testSteps = "";
    const editMode = editItem && editItem.id !== "";
    const newVm = _.cloneDeep(changes);
    const array = newVm.testSteps;

    if (editMode) {
      // Save in edit mode
      const index = array.findIndex((i) => i.id === editItem.id);
      if (index < 0) {
        notifyError(`A step with id ${editItem.id} was not found to edit!`);
        return;
      }

      array[index] = { ...array[index], ...editItem };
    } else {
      // Save in insert mode
      const newItem = { ...emptyTestStep, ...editItem };
      newItem.id = generateUUID();

      if (newVm.testSteps.length > 0) {
        newItem.ordinal = newVm.testSteps.length + 1;
      }

      array.push(newItem);
    }

    setChanges(newVm);
    setShowStepDialog(false);
  }

  function handleDeleteStep(id) {
    const newVm = _.cloneDeep(changes);
    const array = newVm.testSteps;

    const index = changes.testSteps.findIndex((i) => i.id === id);
    if (index < 0) {
      notifyError(`A step with id ${editItem.id} was not found to remove!`);
      return;
    }

    // Remove item reset ordinals
    array.splice(index, 1);
    for (let i = 0; i < array.length; i++) {
      array[i].ordinal = i + 1;
    }

    setChanges(newVm);
  }

  function handleCopyStep(id) {
    const newVm = _.cloneDeep(changes);
    const array = newVm.testSteps;

    const index = changes.testSteps.findIndex((i) => i.id === id);
    if (index < 0) {
      notifyError(`A step with id ${editItem.id} was not found to copy!`);
      return;
    }

    // Add the new item onto the end
    const newItem = { ...emptyTestStep, ...array[index] };
    newItem.id = generateUUID();

    if (newVm.testSteps.length > 0) {
      newItem.ordinal = newVm.testSteps.length + 1;
      newItem.description = "Copy of " + newItem.description;
    }

    array.push(newItem);

    setChanges(newVm);
  }

  function handleAddStep() {
    setEditItem({ ...emptyTestStep });
    setShowStepDialog(true);
  }
  function handleEditStep(id) {
    const index = changes.testSteps.findIndex((i) => i.id === id);
    if (index < 0) {
      notifyError(`A step with id ${editItem.id} was not found to edit!`);
      return;
    }

    setEditItem({ ...changes.testSteps[index] });
    setShowStepDialog(true);
  }

  function handleMoveStep(id, isUp) {
    const newVm = _.cloneDeep(changes);
    const array = newVm.testSteps;

    const index = array.findIndex((i) => i.id === id);
    if (index < 0) {
      notifyError(`A step with id ${id} was not found to move!`);
      return;
    }

    // Check boundaries
    if (isUp && index === 0) return;
    if (!isUp && index >= array.length) return;

    const item = array[index];
    const swapItem = isUp ? array[index - 1] : array[index + 1];

    const tempOrdinal = item.ordinal;
    item.ordinal = swapItem.ordinal;
    swapItem.ordinal = tempOrdinal;

    setChanges(newVm);
  }

  function handleGenerateData() {
    const model = { ...changes };
    model.lastRunBy = auth.userName; // This does not get saved, but it is used to set the lastGeneratedBy user in the signal.

    apiGenerateData.call({ model: model }, () => {
      setGenerationIsWaitingToStart(true);
      setGenerationInProgress(true);
      notifySuccess(
        "The data population request has been sent. It will begin momentarily..."
      );
    });
  }

  const working = loading || adding || updating || running;

  return (
    <Authorize>
      <TestsWebSocketHubConnections />
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return to previous screen"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Testing/Test-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>Test {changes.description ? ` | ${changes.description}` : ""}</h1>
          <StyledHeaderRowButtonDiv>
            {resId && !working && (
              <button
                type="button"
                className="btn btn-secondary btn-with-icon"
                onClick={handleRunTest}
                style={{
                  marginRight: "24px",
                }}
                disabled={hasChanges}
              >
                {hasChanges ? (
                  <>Save changes before running</>
                ) : (
                  <>
                    <span className="material-icons">play_circle_outline</span>
                    {"  "}Run Now
                  </>
                )}
              </button>
            )}
            {!working && (
              <>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "86px",
                  }}
                >
                  <span className="material-icons">check</span>
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => navigate(-1)}
                  style={{ marginLeft: "12px" }}
                >
                  Cancel
                </button>
              </>
            )}
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {working ? (
          <Spinner message={running ? "Running test..." : ""} />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin && typeof resId !== "undefined",
                  url: `/auditrecord/test/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Test"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Testing/Test-Screen&anchor=test-section"
              >
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6">
                    <TextInput
                      id="description"
                      label="Description"
                      onChange={handleChange}
                      placeholder="Description"
                      name="description"
                      value={changes.description || ""}
                      error={errors.description}
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <CheckboxInput
                      id="autoCleanup"
                      label="Automatically clean up any setup data when done"
                      onChange={handleAutoCleanupChange}
                      name="autoCleanup"
                      checked={changes.autoCleanup}
                      error={errors.autoCleanup}
                    />
                  </div>
                </StyledRowDiv>
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Used by Test Group</label>
                      <div className="field">
                        {changes.testGroupId === "" ? (
                          <i>none</i>
                        ) : (
                          <button
                            className="btn btn-link link-underline"
                            title="View test group"
                            onClick={() =>
                              navigate(`/testgroup/${changes.testGroupId}`)
                            }
                          >
                            {changes.testGroupName}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              {resId && (
                <ExpandCollapseDetailSection
                  sectionTitle="Last Run Info"
                  collapsedState={collapsedState}
                  setCollapsedState={setCollapsedState}
                  helpLink="/Testing/Test-Screen&anchor=last-run-info-section"
                >
                  <StyledRowDiv className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Result</label>
                        <div className="field">
                          <StatusPill status={changes.lastRunResultStatus} />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <ReadOnly
                        id="lastRunDate"
                        label="Run Date"
                        name="lastRunDate"
                        value={formatDateTimeUtcZoneForDisplay(
                          changes.lastRunDate
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <ReadOnly
                        id="lastRunDurationMilliseconds"
                        label="Duration"
                        name="lastRunDurationMilliseconds"
                        value={getTimeframeFromMilliseconds(
                          changes.lastRunDurationMilliseconds
                        )}
                      />
                    </div>
                    <div className="col-6">
                      <ReadOnly
                        id="lastRunBy"
                        label="Run By"
                        name="lastRunBy"
                        value={changes.lastRunBy}
                      />
                    </div>
                  </StyledRowDiv>
                  {changes.lastRunResultId !== "" && (
                    <StyledRowDiv style={{ marginTop: "10px" }}>
                      <div className="col-12">
                        <button
                          className="btn btn-link link-underline"
                          onClick={() =>
                            navigate(
                              `/testresults/${resId}/${changes.lastRunResultId}`
                            )
                          }
                        >
                          View full results of last test run
                        </button>
                      </div>
                    </StyledRowDiv>
                  )}
                </ExpandCollapseDetailSection>
              )}

              <TestDataFileSection
                testId={resId}
                changes={changes}
                setChanges={setChanges}
                hasChanges={hasChanges}
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                searchData={searchData}
                setSearchData={setSearchData}
                onGenerateData={handleGenerateData}
                generationIsWaitingToStart={
                  startingDataGeneration || generationIsWaitingToStart
                }
                generationInProgress={generationInProgress}
                generationIsEnding={generationIsEnding}
                uploading={adding || updating}
                validationFailures={validationFailures}
                errors={errors}
              />

              <ExpandCollapseDetailSection
                sectionTitle="Steps"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Testing/Test-Screen&anchor=steps-section"
              >
                <StyledRowDiv className="row">
                  <div className="col-12">
                    <StyledHeaderRowDiv
                      style={{ marginTop: "5px", marginBottom: "10px" }}
                    >
                      <InstantSearchInput
                        id="screenSearchInput"
                        onChange={handleInstantSearchChange}
                        value={stepSearch.highlightText}
                      />
                      <StyledHeaderRowButtonDiv>
                        <button
                          type="button"
                          className="btn btn-secondary btn-with-icon"
                          onClick={handleAddStep}
                        >
                          <span className="material-icons">add</span>
                          {"  "}Add Step
                        </button>
                      </StyledHeaderRowButtonDiv>
                    </StyledHeaderRowDiv>
                    <TestStepDialog
                      isAdmin={auth.isAdmin}
                      editItem={editItem}
                      setEditItem={setEditItem}
                      showDialog={showStepDialog}
                      onCloseDialog={() => setShowStepDialog(false)}
                      onSaveDialog={handleSaveStep}
                    />
                    {errors.testSteps && (
                      <div className="alert alert-danger">
                        {errors.testSteps}
                      </div>
                    )}
                    <TestStepList
                      testSteps={changes.testSteps}
                      testStepIterationCounts={changes?.testVariables || []}
                      totalRecords={changes.testSteps?.length || 0}
                      search={stepSearch}
                      setSearch={setStepSearch}
                      editItem={editItem}
                      setEditItem={setEditItem}
                      onDelete={handleDeleteStep}
                      onCopy={handleCopyStep}
                      onEdit={handleEditStep}
                      onMoveUp={(id) => handleMoveStep(id, true)}
                      onMoveDown={(id) => handleMoveStep(id, false)}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default Test;
