import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import {
  getHighlightStyle,
  getRowStyle,
  getTextStyle,
  handleIndexedAdd,
  handleIndexedChange,
  handleIndexedRemove,
  handleRowDown,
  handleRowUp,
  handleStagingChange,
} from "./RuleCommonFunctions";
import RuleEditorInput from "../../common/input/RuleEditorInput";

function RuleAreaIncludedDataValues({
  isSmallSize,
  isGroupRule,
  includedDataValues,
  ruleChanges,
  setRuleChanges,
  handleOpenDates,
  handleIndexedCopy,
}) {
  const navigate = useNavigate();
  const isFiltered = (ruleChanges.textSearch || "").length > 0;

  return (
    <>
      <StyledRowDiv className="row">
        <div className="col-12 overflow-x-scroll">
          <table
            className={`table rule-grid ${isSmallSize ? "not-fixed" : ""}`}
          >
            <colgroup>
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "2%" }} />
              {!isGroupRule && (
                <>
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "2%" }} />
                </>
              )}
            </colgroup>
            <thead>
              <tr>
                <th id="attachedArtifact">Artifact/Flag</th>
                <th id="valueProcessTiming">Timing</th>
                <th id="key">Key</th>
                <th id="valueType">Value Type</th>
                <th id="name">Name</th>
                <th id="value">Value</th>
                <th style={{ width: "3%" }} id="stagingOnly">
                  Staging
                </th>
                {!isGroupRule ? (
                  <>
                    <th> </th>
                    <th> </th>
                    <th> </th>
                    <th>
                      <i
                        className="fa fa-plus-circle pointer"
                        onClick={(e) =>
                          handleIndexedAdd(
                            "includedDataValues",
                            ruleChanges,
                            setRuleChanges
                          )
                        }
                      ></i>
                    </th>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody>
              {includedDataValues.map((idv, idvIndex) => {
                return (
                  <Fragment key={idvIndex}>
                    <tr id={"idvtr" + idvIndex} style={getRowStyle(idv)}>
                      <td>
                        <input
                          id="idvAttachedArtifact"
                          type="text"
                          name="attachedArtifact"
                          className="form-control"
                          style={getHighlightStyle(idv, true)}
                          placeholder="Artifact/Flag"
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedDataValues",
                              e,
                              idv.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value={idv.attachedArtifact || ""}
                        ></input>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          style={getTextStyle(idv)}
                          id="valueProcessTiming"
                          key={idvIndex}
                          name="valueProcessTiming"
                          value={idv.valueProcessTiming}
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedDataValues",
                              e,
                              idv.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                        >
                          <option value="BeforeArtifact">
                            Before Artifact
                          </option>
                          <option value="AfterArtifact">After Artifact</option>
                        </select>
                      </td>
                      <td>
                        <input
                          id="idvIdKey"
                          key={idvIndex}
                          type="text"
                          name="idKey"
                          className="form-control"
                          style={getHighlightStyle(idv, false)}
                          placeholder="Key"
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedDataValues",
                              e,
                              idv.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value={idv.idKey || ""}
                        ></input>
                      </td>
                      <td>
                        <select
                          className="form-select"
                          style={getTextStyle(idv)}
                          id="valueType"
                          key={idvIndex}
                          name="valueType"
                          value={idv.valueType}
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedDataValues",
                              e,
                              idv.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                        >
                          <option value="RequestFieldValue">
                            Request Field Value
                          </option>
                          <option value="ResponseFieldValue">
                            Response Field Value
                          </option>
                          <option value="InternalVariable">
                            Internal Variable
                          </option>
                          <option value="TransmissionMessage">
                            Transmission Message
                          </option>
                          <option value="TransactionMessage">
                            Transaction Message
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          id="idvKey"
                          key={idvIndex}
                          type="text"
                          name="key"
                          className="form-control"
                          style={getTextStyle(idv)}
                          placeholder="Name"
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedDataValues",
                              e,
                              idv.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value={idv.key || ""}
                        ></input>
                      </td>
                      <td>
                        <RuleEditorInput
                          id="idvValue"
                          key={idvIndex}
                          label=""
                          labelStyle={{ display: "none" }}
                          onChange={(e) =>
                            handleIndexedChange(
                              "includedDataValues",
                              e,
                              idv.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          placeholder="Value"
                          name="value"
                          value={idv.value || ""}
                          inputStyle={getTextStyle(idv)}
                        />
                      </td>
                      <td>
                        <input
                          id="stagingOnly"
                          type="checkbox"
                          name="stagingOnly"
                          className="form-check-input"
                          placeholder="Staging Only"
                          onChange={(e) =>
                            handleStagingChange(
                              "includedDataValues",
                              e,
                              idv.id,
                              ruleChanges,
                              setRuleChanges
                            )
                          }
                          value="Staging Only"
                          checked={idv.stagingOnly}
                        ></input>
                      </td>
                      {!isGroupRule ? (
                        <>
                          <td>
                            <i
                              className="fa fa-calendar pointer"
                              onClick={() =>
                                handleOpenDates(
                                  idv,
                                  "includedDataValues",
                                  idv.id
                                )
                              }
                            ></i>
                          </td>
                          <td>
                            <i
                              className="fa fa-clone pointer"
                              onClick={() =>
                                handleIndexedCopy(
                                  "includedDataValues",
                                  idvIndex
                                )
                              }
                            ></i>
                          </td>
                          <td>
                            {isFiltered ? (
                              <>&nbsp;</>
                            ) : (
                              <>
                                <i
                                  className="fa fa-arrow-up pointer"
                                  onClick={() =>
                                    handleRowUp(
                                      "includedDataValues",
                                      idv.id,
                                      ruleChanges,
                                      setRuleChanges
                                    )
                                  }
                                ></i>
                                <i
                                  className="fa fa-arrow-down pointer"
                                  onClick={() =>
                                    handleRowDown(
                                      "includedDataValues",
                                      idv.id,
                                      ruleChanges,
                                      setRuleChanges
                                    )
                                  }
                                ></i>
                              </>
                            )}
                          </td>
                          <td>
                            <i
                              className="fa fa-times pointer"
                              onClick={() =>
                                handleIndexedRemove(
                                  "includedDataValues",
                                  idv.id,
                                  ruleChanges,
                                  setRuleChanges
                                )
                              }
                            ></i>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                    {isGroupRule ? (
                      <tr key={"idvtrs" + idvIndex} style={getRowStyle(idv)}>
                        <td
                          colSpan="2"
                          style={{
                            fontSize: "0.7em",
                            padding: "0px",
                            textAlign: "left",
                          }}
                        >
                          Artifact: {idv.artifact}
                        </td>
                        <td
                          colSpan="20"
                          style={{
                            fontSize: "0.7em",
                            padding: "0px",
                            textAlign: "right",
                          }}
                        >
                          Rule:{" "}
                          <button
                            className="btn-link"
                            style={{ border: "0px" }}
                            onClick={() =>
                              navigate("/rule/" + idv.sourceRuleId)
                            }
                          >
                            {idv.sourceRule}
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </StyledRowDiv>
    </>
  );
}

export default RuleAreaIncludedDataValues;
