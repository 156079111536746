import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyTestGroup,
  emptyTestGroupSearch,
} from "../viewmodels/testGroupsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadTestGroups:
      return {
        ...state,
        testGroups: action.payload.testGroups,
        count: action.payload.count,
      };

    case ContextProviderActions.saveTestGroupSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadTestGroup:
      return {
        ...state,
        testGroup: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in TestGroupsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  testGroups: [],
  testGroup: emptyTestGroup,
  search: emptyTestGroupSearch,
  count: 0,
};

const TestGroups = createContext({
  testGroupsData: initialState,
  setTestGroupsData: () => null,
});

// eslint-disable-next-line react/prop-types
const TestGroupsProvider = ({ children }) => {
  const [testGroupsData, setTestGroupsData] = useReducer(reducer, initialState);

  return (
    <TestGroups.Provider value={{ testGroupsData, setTestGroupsData }}>
      {children}
    </TestGroups.Provider>
  );
};

export default TestGroupsProvider;
export const useTestGroups = () => useContext(TestGroups);
