import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useAuth } from "../../../contexts/AuthContext";
import Authorize from "../../common/layout/Authorize";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import useApi from "../../../hooks/useApi";
import { useSocketServerConfig } from "../../../contexts/SocketServerConfigContext";
import {
  apiLoadSocketServerConfig,
  apiUpdateSocketServerConfig,
} from "../../../api/SocketServerConfigApi";
import {
  createViewModel,
  emptySocketServerConfig,
  emptySocketServerConsumer,
  emptySocketServerRoutingPlan,
  emptySocketServerRoutingPlanConsumer,
  fromViewModel,
} from "../../../viewmodels/socketserverconfigVm";
import Spinner from "../../common/ui/Spinner";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import {
  generateUUID,
  handleCollapseExpandAll,
} from "../../../services/General";
import styled from "styled-components";
import StatusPill from "../../common/ui/StatusPill";
import TextInput from "../../common/input/TextInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import TextAreaInput from "../../common/input/TextAreaInput";
import SelectInput from "../../common/input/SingleSelect";
import { usePacketTraffic } from "../../../contexts/PacketTrafficContext";

function SocketServerConfig() {
  const { auth } = useAuth();
  const { socketServerConfigData, setSocketServerConfigData } =
    useSocketServerConfig();
  const { loading, api: apiLoad } = useApi(apiLoadSocketServerConfig);
  const { loading: updating, api: apiUpdate } = useApi(
    apiUpdateSocketServerConfig
  );
  const [changes, setChanges] = useState(emptySocketServerConfig);
  const [errors, setErrors] = useState({});
  const { packetsTraffic } = usePacketTraffic();
  const [traffic, setTraffic] = useState({});

  const [collapsedState, setCollapsedState] = useState([
    { name: "General", collapsed: false },
    { name: "Routing Plans", collapsed: false },
    { name: "Consumers", collapsed: false },
  ]);

  useEffect(() => {
    if (auth.authenticated) {
      loadSocketServerConfig();
    }
  }, [auth.authenticated]);

  useEffect(() => {
    if (packetsTraffic) {
      setTraffic(packetsTraffic.packetsTraffic);
    }
  }, [packetsTraffic]);

  useEffect(() => {
    if (socketServerConfigData.socketServerConfig) {
      const changed = _.cloneDeep(socketServerConfigData.socketServerConfig);
      // Id is used only for editing but we need each consumer and routing plan to have one and api does not assign one
      changed.consumers.forEach((s) => (s.id = generateUUID()));
      changed.routingPlans.forEach((s) => {
        s.id = generateUUID();
        s.consumers.forEach((c) => (c.id = generateUUID()));
      });
      setChanges(changed);
    } else {
      setChanges(emptySocketServerConfig);
    }
  }, [socketServerConfigData.socketServerConfig]);

  async function loadSocketServerConfig() {
    await apiLoad.call(null, async (result) => {
      const vm = createViewModel(result);
      setSocketServerConfigData({
        type: ContextProviderActions.loadSocketServerConfig,
        payload: vm,
      });
    });
  }

  function isValidJson(text) {
    try {
      JSON.parse(text);
      return true;
    } catch (ex) {
      console.log("Invalid JSON: " + text);
      return false;
    }
  }

  function doesConsumerExist(name) {
    const exists =
      changes.consumers.filter(
        (c) => _.toLower(_.trim(c.name)) === _.toLower(_.trim(name))
      ).length > 0;
    return exists;
  }

  function formIsValid() {
    const _errors = {};

    // General required fields: Server Mode, State Manager, Local Server IP Address, Local Server Port, Cache Max Age Seconds, Connection Refresh
    if (_.trim(changes.serverMode) === "")
      _errors.serverMode = "Server Mode must be entered";
    if (_.trim(changes.stateManager) === "")
      _errors.stateManager = "State Manager must be entered";
    if (_.trim(changes.properties.localServerIpAddress) === "")
      _errors.localServerIpAddress = "Local Server IP Address must be entered";
    if (_.trim(changes.properties.localServerPort) === "")
      _errors.localServerPort = "Local Server Port must be entered";
    if (_.trim(changes.configCacheMaxAgeSeconds) === "")
      _errors.configCacheMaxAgeSeconds =
        "Cache Max Age Seconds must be entered";
    if (_.trim(changes.connectionRefreshMilliseconds) === "")
      _errors.connectionRefreshMilliseconds =
        "Connection Refresh (ms) must be entered";

    // Routing Plan required fields: Name, Recognizer, Handler, at least one consumer and exactly one primary consumer.
    let item, id;
    for (let i = 0; i < changes.routingPlans.length; i++) {
      item = changes.routingPlans[i];
      id = item.id;

      if (_.trim(item.name) === "")
        _errors[`routeplan-name-${id}`] = "Name must be entered";
      if (_.trim(item.recognizer) === "")
        _errors[`routeplan-recognizer-${id}`] = "Recognizer must be entered";
      if (_.trim(item.handler) === "")
        _errors[`routeplan-handler-${id}`] = "Handler must be entered";

      // Make sure properties json is valid
      if (_.isEmpty(_.trim(item.properties))) item.properties = "{}";
      if (!isValidJson(item.properties)) {
        _errors[`routeplan-properties-${id}`] = "Properties must be valid JSON";
      }

      if (item.consumers.length === 0)
        _errors[`routeplan-consumers-${id}`] =
          "Routing plan must have at least one consumer";
      if (
        item.consumers.length > 0 &&
        item.consumers.filter((c) => c.isPrimary === true).length !== 1
      )
        _errors[`routeplan-consumers-${id}`] =
          "Routing plan must have exactly one primary consumer";

      // Routing Plan Consumer required fields: Consumer(Name), Timeout
      // Each routing plan consumer must exist in the consumers array
      let citem, cid;
      for (let i = 0; i < item.consumers.length; i++) {
        citem = item.consumers[i];
        cid = citem.id;

        if (_.trim(citem.name) === "")
          _errors[`rpconsumer-name-${cid}`] = "Consumer must be selected";
        if (!doesConsumerExist(citem.name))
          _errors[`rpconsumer-name-${cid}`] =
            "Consumer must exist in Consumers section";
        if (_.trim(citem.timeout) === "")
          _errors[`rpconsumer-timeout-${cid}`] = "Timeout must be entered";
      }
    }

    // Consumer required fields: Name, Timeout
    for (let i = 0; i < changes.consumers.length; i++) {
      item = changes.consumers[i];
      id = item.id;

      if (_.trim(item.name) === "")
        _errors[`consumer-name-${id}`] = "Name must be entered";
      if (_.trim(item.timeout) === "")
        _errors[`consumer-timeout-${id}`] = "Timeout must be entered";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    updateSocketServerConfig({ ...changes });
  }

  async function updateSocketServerConfig(vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ model }, (result) => {
      const newVm = { ...model, ...result };
      const retModel = createViewModel(newVm);
      setChanges(retModel);
      setSocketServerConfigData({
        type: ContextProviderActions.loadSocketServerConfig,
        payload: retModel,
      });

      notifySuccess("Configuration saved successfully");
    });
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handlePropertyChange({ target }) {
    let changed = { ...changes };
    changed.properties[target.name] = target.value;
    setChanges(changed);
  }

  function handleConsumerChange(id, target) {
    let changed = _.cloneDeep(changes);
    const changedItem = changed.consumers.find((t) => t.id === id);
    changedItem[target.name] = target.value;
    setChanges(changed);
  }

  function handleConsumerCheckboxChange(id, target) {
    let changed = _.cloneDeep(changes);
    const changedItem = changed.consumers.find((t) => t.id === id);
    changedItem[target.name] = target.checked;
    setChanges(changed);
  }

  function handleRoutingPlanChange(id, target) {
    let changed = _.cloneDeep(changes);
    const changedItem = changed.routingPlans.find((t) => t.id === id);
    changedItem[target.name] = target.value;
    setChanges(changed);
  }

  function handleRoutingPlanConsumerChange(routingPlanId, id, target) {
    let changed = _.cloneDeep(changes);
    const routingPlan = changed.routingPlans.find(
      (t) => t.id === routingPlanId
    );

    const changedItem = routingPlan.consumers.find((t) => t.id === id);
    changedItem[target.name] = target.value;

    setChanges(changed);
  }

  function handleRoutingPlanConsumerDropdownChange(routingPlanId, id, option) {
    let changed = _.cloneDeep(changes);
    const routingPlan = changed.routingPlans.find(
      (t) => t.id === routingPlanId
    );

    const changedItem = routingPlan.consumers.find((t) => t.id === id);
    changedItem.name = option.value;

    setChanges(changed);
  }

  function handleRoutingPlanConsumerCheckboxChange(routingPlanId, id, target) {
    let changed = _.cloneDeep(changes);
    const routingPlan = changed.routingPlans.find(
      (t) => t.id === routingPlanId
    );

    const changedItem = routingPlan.consumers.find((t) => t.id === id);
    changedItem[target.name] = target.checked;

    setChanges(changed);
  }

  function handleAddNewRoutingPlan() {
    let changed = _.cloneDeep(changes);

    changed.routingPlans.push({
      ...emptySocketServerRoutingPlan,
      id: generateUUID(),
    });
    setChanges(changed);
  }

  function removeRoutingPlan(id) {
    let changed = _.cloneDeep(changes);

    const index = changed.routingPlans.findIndex((s) => s.id === id);
    if (index >= 0) {
      changed.routingPlans.splice(index, 1);
    }

    setChanges(changed);
  }

  function addRoutingPlanConsumer(routingPlanId) {
    let changed = _.cloneDeep(changes);

    const index = changed.routingPlans.findIndex((s) => s.id === routingPlanId);
    if (index >= 0) {
      changed.routingPlans[index].consumers.push({
        ...emptySocketServerRoutingPlanConsumer,
        id: generateUUID(),
      });
    }

    setChanges(changed);
  }

  function removeRoutingPlanConsumer(routingPlanId, id) {
    let changed = _.cloneDeep(changes);

    const index = changed.routingPlans.findIndex((s) => s.id === routingPlanId);
    if (index >= 0) {
      const index2 = changed.routingPlans[index].consumers.findIndex(
        (s) => s.id === id
      );
      if (index2 >= 0) {
        changed.routingPlans[index].consumers.splice(index2, 1);
      }
    }

    setChanges(changed);
  }

  function handleAddNewConsumer() {
    let changed = _.cloneDeep(changes);

    changed.consumers.push({
      ...emptySocketServerConsumer,
      id: generateUUID(),
    });
    setChanges(changed);
  }

  function removeConsumer(id) {
    let changed = _.cloneDeep(changes);

    const index = changed.consumers.findIndex((s) => s.id === id);
    if (index >= 0) {
      changed.consumers.splice(index, 1);
    }

    setChanges(changed);
  }

  const isStarted = (traffic?.connections || []).length > 0;

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledScreenHelpDiv>
          <HelpLink
            path="/SocketServer/Socket-Server-Config-Screen"
            label="Help"
          />
        </StyledScreenHelpDiv>
        <StyledHeaderRowDiv>
          <div className="flex-row-without-wrap">
            <h1 style={{ display: "inline-block", paddingTop: "5px" }}>
              Socket Server Configuration
            </h1>

            {!loading && (
              <StatusPill
                pillStyle={{
                  display: "inline-block",
                  marginLeft: "10px",
                  minWidth: "50px",
                  textAlign: "center",
                }}
                status={isStarted ? "Started" : "Stopped"}
              />
            )}
          </div>
          {!loading && (
            <StyledHeaderRowButtonDiv
              style={{ flexWrap: "wrap", rowGap: "10px" }}
            >
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "86px",
                  marginLeft: "24px",
                }}
                disabled={false}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  document.location.reload();
                }}
                style={{ marginLeft: "12px" }}
                disabled={false}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          )}
        </StyledHeaderRowDiv>
        {loading || updating ? (
          <Spinner spinnerStyle={{ lineHeight: "25vh" }} />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <ExpandCollapseDetailSection
              sectionTitle="General"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/SocketServer/Socket-Server-Config-Screen&anchor=general-section"
            >
              <StyledRowDiv className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <TextInput
                    id="serverMode"
                    label="Server Mode"
                    onChange={handleChange}
                    placeholder="Server Mode"
                    name="serverMode"
                    value={changes.serverMode || ""}
                    error={errors.serverMode}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <TextInput
                    id="stateManager"
                    label="State Manager"
                    onChange={handleChange}
                    placeholder="State Manager"
                    name="stateManager"
                    value={changes.stateManager || ""}
                    error={errors.stateManager}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <TextInput
                    id="stateContainer"
                    label="State Container"
                    onChange={handleChange}
                    placeholder="State Container"
                    name="stateContainer"
                    value={changes.stateContainer || ""}
                    error={errors.stateContainer}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-6 col-lg-4">
                  <div style={{ maxWidth: "180px" }}>
                    <TextInput
                      id="localServerIpAddress"
                      label="Local Server IP Address"
                      onChange={handlePropertyChange}
                      placeholder="Local Server IP Address"
                      name="localServerIpAddress"
                      value={changes.properties.localServerIpAddress || ""}
                      error={errors.localServerIpAddress}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <TextInput
                    id="localServerPort"
                    label="Local Server Port"
                    onChange={handlePropertyChange}
                    placeholder="Local Server Port"
                    name="localServerPort"
                    value={changes.properties.localServerPort}
                    error={errors.localServerPort}
                    isNumber={true}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                <div className="col-12 col-md-6 col-lg-4">
                  <TextInput
                    id="configCacheMaxAgeSeconds"
                    label="Cache Max Age Seconds"
                    onChange={handleChange}
                    placeholder="Cache Max Age Seconds"
                    name="configCacheMaxAgeSeconds"
                    value={changes.configCacheMaxAgeSeconds}
                    error={errors.configCacheMaxAgeSeconds}
                    isNumber={true}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <TextInput
                    id="connectionRefreshMilliseconds"
                    label="Connection Refresh (ms)"
                    onChange={handleChange}
                    placeholder="Connection Refresh (ms)"
                    name="connectionRefreshMilliseconds"
                    value={changes.connectionRefreshMilliseconds}
                    error={errors.connectionRefreshMilliseconds}
                    isNumber={true}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Routing Plans"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/SocketServer/Socket-Server-Config-Screen&anchor=routing-plans-section"
            >
              {(changes.routingPlans || []).length === 0 && (
                <div>
                  <i>No routing plans found</i>
                </div>
              )}
              {(changes.routingPlans || []).map((c, idx) => (
                <StyledTypeContainer key={`routeplan-${idx}`} id={c.id}>
                  <StyledHeaderRowDiv
                    style={{ marginTop: "5px", marginBottom: "10px" }}
                  >
                    <h1>{c.name || ""}</h1>
                    <StyledHeaderRowButtonDiv>
                      <button
                        type="button"
                        className="btn btn-secondary btn-with-icon"
                        onClick={() => {
                          removeRoutingPlan(c.id);
                        }}
                      >
                        <span className="material-icons">close</span>
                        Remove
                      </button>
                    </StyledHeaderRowButtonDiv>
                  </StyledHeaderRowDiv>
                  <StyledRowDiv className="row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-12">
                          <TextInput
                            id={`routeplan-${idx}-name`}
                            label="Name"
                            onChange={({ target }) =>
                              handleRoutingPlanChange(c.id, target)
                            }
                            placeholder="Name"
                            name="name"
                            value={c.name || ""}
                            error={errors[`routeplan-name-${c.id}`]}
                          />
                        </div>
                        <div className="col-12">
                          <TextInput
                            id={`routeplan-${idx}-recognizer`}
                            label="Recognizer"
                            onChange={({ target }) =>
                              handleRoutingPlanChange(c.id, target)
                            }
                            placeholder="Recognizer"
                            name="recognizer"
                            value={c.recognizer || ""}
                            error={errors[`routeplan-recognizer-${c.id}`]}
                          />
                        </div>
                        <div className="col-12">
                          <TextInput
                            id={`routeplan-${idx}-handler`}
                            label="Handler"
                            onChange={({ target }) =>
                              handleRoutingPlanChange(c.id, target)
                            }
                            placeholder="Handler"
                            name="handler"
                            value={c.handler || ""}
                            error={errors[`routeplan-handler-${c.id}`]}
                          />
                        </div>
                        <div className="col-12">
                          <TextInput
                            id={`routeplan-${idx}-stateManager`}
                            label="State Manager"
                            onChange={({ target }) =>
                              handleRoutingPlanChange(c.id, target)
                            }
                            placeholder="State Manager"
                            name="stateManager"
                            value={c.stateManager || ""}
                            error={errors[`routeplan-${c.stateManager}`]}
                          />
                        </div>
                        <div className="col-12">
                          <TextInput
                            id={`routeplan-${idx}-errorConsumer`}
                            label="Error Consumer"
                            onChange={({ target }) =>
                              handleRoutingPlanChange(c.id, target)
                            }
                            placeholder="Error Consumer"
                            name="errorConsumer"
                            value={c.errorConsumer || ""}
                            error={errors[`routeplan-${c.errorConsumer}`]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 properties">
                      <TextAreaInput
                        id={`routeplan-${idx}-properties`}
                        rows="10"
                        label="Properties"
                        onChange={({ target }) =>
                          handleRoutingPlanChange(c.id, target)
                        }
                        placeholder="Properties"
                        name="properties"
                        value={c.properties}
                        error={errors[`routeplan-properties-${c.id}`]}
                      />
                    </div>
                  </StyledRowDiv>
                  <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                    <div className="col-12">
                      <StyledHeaderRowDiv
                        style={{ marginTop: "5px", marginBottom: "10px" }}
                      >
                        <h2 style={{ fontSize: "18px" }}>
                          {c.name || ""} Consumers
                        </h2>
                        <StyledHeaderRowButtonDiv>
                          <button
                            type="button"
                            className="btn btn-link btn-with-icon"
                            onClick={() => {
                              addRoutingPlanConsumer(c.id);
                            }}
                          >
                            <span className="material-icons">add</span>
                            Add consumer
                          </button>
                        </StyledHeaderRowButtonDiv>
                      </StyledHeaderRowDiv>
                      {errors[`routeplan-consumers-${c.id}`] && (
                        <div className="alert alert-danger">
                          {errors[`routeplan-consumers-${c.id}`]}
                        </div>
                      )}
                      {(c.consumers || []).length === 0 && (
                        <div>
                          <i>No consumers found</i>
                        </div>
                      )}
                      {(c.consumers || []).map((t, tidx) => (
                        <StyledTypeContainer
                          key={`rpconsumer-${tidx}`}
                          id={t.id}
                          className="no-border"
                        >
                          <StyledRowDiv className="row">
                            <div className="col-12 col-md-1">
                              <div className="form-group">
                                <label>Remove</label>
                                <br />
                                <button
                                  type="button"
                                  className="btn btn-link"
                                  onClick={() => {
                                    removeRoutingPlanConsumer(c.id, t.id);
                                  }}
                                  style={{
                                    marginTop: "18px",
                                  }}
                                >
                                  <span
                                    className="material-icons"
                                    style={{
                                      width: "50px",
                                      textAlign: "center",
                                    }}
                                  >
                                    close
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div className="col-12 col-md-4">
                              <SelectInput
                                id={`rpconsumer-${tidx}-name`}
                                name="name"
                                label="Consumer"
                                options={(changes.consumers || [])
                                  .filter(
                                    (consumer) => !_.isEmpty(consumer.name)
                                  )
                                  .map((consumer) => {
                                    return {
                                      label: consumer.name,
                                      value: consumer.name,
                                    };
                                  })}
                                value={
                                  _.isEmpty(t.name)
                                    ? {
                                        label: "(Select a consumer)",
                                        value: "-1",
                                      }
                                    : { label: t.name, value: t.name }
                                }
                                onChange={(option) =>
                                  handleRoutingPlanConsumerDropdownChange(
                                    c.id,
                                    t.id,
                                    option
                                  )
                                }
                                placeholder="(Select a consumer)"
                                error={errors[`rpconsumer-name-${t.id}`]}
                              />
                            </div>
                            <div className="col-12 col-md-3">
                              <CheckboxInput
                                id={`rpconsumer-${tidx}-isPrimary`}
                                label="Primary"
                                onChange={({ target }) =>
                                  handleRoutingPlanConsumerCheckboxChange(
                                    c.id,
                                    t.id,
                                    target
                                  )
                                }
                                placeholder=""
                                name="isPrimary"
                                showLabelInline={false}
                                checked={t.isPrimary}
                                error={errors[`rpconsumer-${t.isPrimary}`]}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <TextInput
                                id={`rpconsumer-${tidx}-timeout`}
                                label="Timeout"
                                onChange={({ target }) =>
                                  handleRoutingPlanConsumerChange(
                                    c.id,
                                    t.id,
                                    target
                                  )
                                }
                                placeholder="Timeout"
                                name="timeout"
                                value={t.timeout}
                                error={errors[`rpconsumer-timeout-${t.id}`]}
                                isNumber={true}
                              />
                            </div>
                          </StyledRowDiv>
                        </StyledTypeContainer>
                      ))}
                    </div>
                  </StyledRowDiv>
                </StyledTypeContainer>
              ))}
              <StyledNewTypeContainer onClick={handleAddNewRoutingPlan}>
                <p>
                  <span className="material-icons">add</span> Add new routing
                  plan
                </p>
              </StyledNewTypeContainer>
            </ExpandCollapseDetailSection>

            <ExpandCollapseDetailSection
              sectionTitle="Consumers"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/SocketServer/Socket-Server-Config-Screen&anchor=consumers-section"
            >
              {(changes.consumers || []).length === 0 && (
                <div>
                  <i>No consumers found</i>
                </div>
              )}
              {(changes.consumers || []).map((c, idx) => (
                <StyledTypeContainer key={`consumer-${idx}`} id={c.id}>
                  <StyledHeaderRowDiv
                    style={{ marginTop: "5px", marginBottom: "10px" }}
                  >
                    <h1>{c.name || ""}</h1>
                    <StyledHeaderRowButtonDiv>
                      <button
                        type="button"
                        className="btn btn-secondary btn-with-icon"
                        onClick={() => {
                          removeConsumer(c.id);
                        }}
                      >
                        <span className="material-icons">close</span>
                        Remove
                      </button>
                    </StyledHeaderRowButtonDiv>
                  </StyledHeaderRowDiv>
                  <StyledRowDiv className="row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-12">
                          <TextInput
                            id={`consumer-${idx}-name`}
                            label="Name"
                            onChange={({ target }) =>
                              handleConsumerChange(c.id, target)
                            }
                            placeholder="Name"
                            name="name"
                            value={c.name || ""}
                            error={errors[`consumer-name-${c.id}`]}
                          />
                        </div>
                        <div className="col-12">
                          <CheckboxInput
                            id={`consumer-${idx}-singleton`}
                            label="Keep in memory"
                            onChange={({ target }) =>
                              handleConsumerCheckboxChange(c.id, target)
                            }
                            placeholder=""
                            name="singleton"
                            showLabelInline={false}
                            checked={c.singleton}
                            error={errors[`consumer-${c.singleton}`]}
                          />
                        </div>
                        <div className="col-12">
                          <TextInput
                            id={`consumer-${idx}-timeout`}
                            label="Timeout"
                            onChange={({ target }) =>
                              handleConsumerChange(c.id, target)
                            }
                            placeholder="Timeout"
                            name="timeout"
                            value={c.timeout}
                            error={errors[`consumer-timeout-${c.id}`]}
                            isNumber={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 properties">
                      <TextAreaInput
                        id={`consumer-${idx}-properties`}
                        rows="10"
                        label="Properties"
                        onChange={({ target }) =>
                          handleConsumerChange(c.id, target)
                        }
                        placeholder="Properties"
                        name="properties"
                        value={c.properties}
                        error={errors[`consumer-${c.properties}`]}
                      />
                    </div>
                  </StyledRowDiv>
                </StyledTypeContainer>
              ))}
              <StyledNewTypeContainer onClick={handleAddNewConsumer}>
                <p>
                  <span className="material-icons">add</span> Add new consumer
                </p>
              </StyledNewTypeContainer>
            </ExpandCollapseDetailSection>
          </>
        )}
      </form>
    </Authorize>
  );
}

const StyledTypeContainer = styled.div`
  padding: 10px;
  border: 1px solid var(--elevated-border);

  .no-border {
    border: none;
  }

  h3 {
    font-size: 18px;
  }

  div.properties textarea {
    font-size: 15px;
    font-family: "Courier New", Courier, monospace;
  }
`;

const StyledNewTypeContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  min-height: 50px;
  background: var(--drag-chip-placeholder-bg);
  border: 1px dashed var(--drag-chip-placeholder-border);
  cursor: pointer;

    &:hover {
      background-color: var(--drag-chip-placeholder-bg-active);
      border: 1px dashed var(--drag-chip-placeholder-border-active);

      p,
      span[class="material-icons"] {
        color: var(--drag-chip-placeholder-text-active);
      }
    }

    &:active {
      background-color: var(--drag-chip-placeholder-border-active);

      p,
      span[class="material-icons"] {
        color: #FFF;
      }
    }

  }

  span[class="material-icons"] {
    font-size: 24px;
    line-height: 24px;
    color: var(--drag-chip-placeholder-border);
  }

  p {
    width: 320px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 0;
    margin: 0 auto;
    font-size: 24px;
    line-height: 28px;
    color: var(--drag-chip-placeholder-text);
  }
`;

export default SocketServerConfig;
