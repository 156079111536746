import React from "react";
import styled from "styled-components";
// import { notifyWarn } from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";

function RulesSearchBar({
  onChange,
  search,
  setSearch,
  onSubmitSearch,
  errors,
  showGroupRule,
}) {
  const { isMobileSize } = useMobile();

  // function formIsValid() {
  //   if (search.groupId.trim() === "" && search.textSearch.trim() === "") {
  //     notifyWarn("Please enter search terms.");
  //     return false;
  //   }

  //   return true;
  // }

  async function handleSearch(event) {
    if (event) event.preventDefault();
    // if (!formIsValid()) return;

    onSubmitSearch();
  }

  async function onSubmit(event) {
    var updatedSearch = { ...search, pageNumber: 1 };
    setSearch(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  return (
    <>
      <StyledSearchBar>
        <form onSubmit={onSubmit}>
          <TextInput
            id="groupId"
            label="Group Id"
            labelStyle={{ display: "none" }}
            onChange={onChange}
            placeholder="Group Id"
            name="groupId"
            value={search.groupId}
            error={errors.groupId}
            isSearch={true}
          />
          <TextInput
            id="highlightText"
            label="Text Search"
            labelStyle={{ display: "none" }}
            onChange={onChange}
            placeholder="Text Search"
            name="highlightText"
            value={search.highlightText}
            error={errors.highlightText}
            isSearch={true}
          />
          <button
            type="submit"
            className="btn btn-primary btn-with-icon"
            onClick={onSubmit}
            style={{ width: "100px", minWidth: "100px", marginLeft: "10px" }}
          >
            <span className="material-icons">search</span>
            {"  "}Search
          </button>
          <button
            type="button"
            onClick={showGroupRule}
            className="btn btn-secondary"
            style={{ marginLeft: isMobileSize ? "10px" : "auto" }}
          >
            View Group Summary
          </button>
        </form>
      </StyledSearchBar>
    </>
  );
}

const StyledSearchBar = styled.div`
  margin: 14px 0 10px 0;
  padding: 0 20px;
  min-height: 60px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;

  form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    width: 100%;
    margin: 10px 0;
  }

  div.form-group {
    margin-top: 0;
  }

  input {
    width: 200px;
    min-width: 200px;
    max-width: 320px;
    margin-left: 13px;
    font-size: 16px;
    line-height: 20px;
    color: var(-text-light);
    outline: none !important;
    padding: 6px 0;
    border: 0;
    border-bottom: 1px solid var(--text-light);
  }
`;

export default RulesSearchBar;
