import React from "react";
import _ from "lodash";
import { notifyError } from "../../../services/NotificationService";

export function getFilterColor(name) {
  if (name === "Coverage") return "var(--text-highlight)";
  else if (name === "NoCoverage") return "var(--text-highlight)";
  else if (name === "Function") return "var(--notify-info)";
  else if (name === "Event") return "var(--notify-success)";
  else if (name === "Loop") return "var(--notify-warning)";
  else if (name === "RepoGet") return "var(--pill-purple)";
  else if (name === "RepoSave") return "var(--notify-danger)";
  else if (name === "Headless") return "var(--text-highlight)";
}

export function getIconForEntry(entry) {
  if (entry.entryType === "Coverage") {
    return (
      <span
        className="material-symbols-outlined"
        style={{ color: getFilterColor("Coverage") }}
      >
        troubleshoot
      </span>
    );
  } else if (entry.entryType === "NoCoverage") {
    return (
      <span
        className="material-symbols-outlined"
        style={{ color: getFilterColor("NoCoverage") }}
      >
        search
      </span>
    );
  } else if (entry.entryType === "FunctionRegistration") {
    return (
      <span
        className="material-symbols-outlined"
        style={{ color: getFilterColor("Function") }}
      >
        function
      </span>
    );
  } else if (entry.entryType === "TestableEventRegistration") {
    return (
      <span
        className="material-symbols-outlined"
        style={{ color: getFilterColor("Event") }}
      >
        science
      </span>
    );
  } else if (entry.entryType === "LoopRegistration") {
    return (
      <span
        className="material-symbols-outlined"
        style={{ color: getFilterColor("Loop") }}
      >
        laps
      </span>
    );
  } else if (entry.entryType === "RepositoryGetRegistration") {
    return (
      <span
        className="material-symbols-outlined"
        style={{ color: getFilterColor("RepoGet") }}
      >
        cloud_download
      </span>
    );
  } else if (entry.entryType === "RepositorySaveRegistration") {
    return (
      <span
        className="material-symbols-outlined"
        style={{ color: getFilterColor("RepoSave") }}
      >
        save
      </span>
    );
  } else if (entry.entryType === "Headless") {
    return (
      <span
        className="material-symbols-outlined"
        style={{ color: getFilterColor("Headless") }}
      >
        no_accounts
      </span>
    );
  }
  return null;
}

export function getFilterNameFromEntryType(entryType) {
  if (entryType === "FunctionRegistration") {
    return "Function";
  } else if (entryType === "TestableEventRegistration") {
    return "Event";
  } else if (entryType === "LoopRegistration") {
    return "Loop";
  } else if (entryType === "RepositoryGetRegistration") {
    return "RepoGet";
  } else if (entryType === "RepositorySaveRegistration") {
    return "RepoSave";
  }
  return "";
}

export function getEntryName(name) {
  let retVal = name;

  const pos = (name || "").lastIndexOf(".");
  if (pos >= 0) {
    retVal = name.substring(pos + 1);
  }

  return retVal;
}

export function getEntryNamespace(name) {
  let retVal = "";

  const pos = (name || "").lastIndexOf(".");
  if (pos >= 0) {
    retVal = name.substring(0, pos);
  }

  return retVal;
}

export function getEntryDescription(desc) {
  let retVal = "";

  if (desc !== "" && desc !== null) {
    retVal = desc;
  }

  return retVal;
}

export function getApiCoveragePercent(result, hideDetails) {
  const entries = result.metadata.entries || [];
  const _coveredEntries = result.metadata.coveredEntries || [];
  const _nonCoveredEntries = result.metadata.nonCoveredEntries || [];
  let entry = null;

  const coveredEntries = [];
  for (let i = 0; i < _coveredEntries.length; i++) {
    // We need to look up the detail flag in the entry array since coveredEntries does not have it
    entry = entries.find((e) => e.id === _coveredEntries[i].id);

    if (!entry) {
      const msg = `Id not found in entries while building coveredEntries. id=${_coveredEntries[i].id}, key=${_coveredEntries[i].key}`;
      notifyError(msg);
      console.log(msg);
      continue;
    }

    if (!hideDetails || (hideDetails && entry.detail !== true)) {
      coveredEntries.push(_coveredEntries[i]);
    }
  }

  const nonCoveredEntries = [];
  for (let i = 0; i < _nonCoveredEntries.length; i++) {
    // We need to look up the detail flag in the entry array since nonCoveredEntries does not have it
    entry = entries.find((e) => e.id === _nonCoveredEntries[i].id);

    if (!entry) {
      const msg = `Id not found in entries while building nonCoveredEntries. id=${_nonCoveredEntries[i].id}, key=${_nonCoveredEntries[i].key}`;
      notifyError(msg);
      console.log(msg);
      continue;
    }

    if (!hideDetails || (hideDetails && entry.detail !== true)) {
      nonCoveredEntries.push(_nonCoveredEntries[i]);
    }
  }

  const totalEntityCount =
    (coveredEntries.length || 0) + (nonCoveredEntries.length || 0);
  const percent =
    totalEntityCount === 0
      ? 0
      : ((coveredEntries.length || 0) / totalEntityCount) * 100;
  return percent;
}

export function mergeApiMetadata(existing, newResult) {
  existing.apiDate = newResult.apiDate;
  existing.numCalls += 1;

  // Merge coveredEntries from new result into existing one
  if ((newResult.metadata.coveredEntries || []).length > 0) {
    const _coveredEntries = _.cloneDeep(existing.metadata.coveredEntries);

    for (let i = 0; i < newResult.metadata.coveredEntries.length; i++) {
      // If an entry with the same id does not already exist, add it to the array
      if (
        _coveredEntries.findIndex(
          (e) => e.id === newResult.metadata.coveredEntries[i].id
        ) < 0
      ) {
        _coveredEntries.push({ ...newResult.metadata.coveredEntries[i] });
      }
    }

    existing.metadata.coveredEntries = _coveredEntries;
  }

  // Merge nonCoveredEntries from new result into existing one
  if ((newResult.metadata.nonCoveredEntries || []).length > 0) {
    const _nonCoveredEntries = _.cloneDeep(existing.metadata.nonCoveredEntries);

    for (let i = 0; i < newResult.metadata.nonCoveredEntries.length; i++) {
      // If an entry with the same id does not already exist, add it to the array
      if (
        _nonCoveredEntries.findIndex(
          (e) => e.id === newResult.metadata.nonCoveredEntries[i].id
        ) < 0
      ) {
        _nonCoveredEntries.push({ ...newResult.metadata.nonCoveredEntries[i] });
      }
    }

    existing.metadata.nonCoveredEntries = _nonCoveredEntries;
  }

  // Remove any nonCoveredEntries in existing that are now in coveredEntries due to the new result
  if ((existing.metadata.coveredEntries || []).length > 0) {
    const _nonCoveredEntries = _.cloneDeep(existing.metadata.nonCoveredEntries);

    for (let i = 0; i < existing.metadata.coveredEntries.length; i++) {
      // If an entry with the same id exists in nonCovered, remove it since it is now covered.
      const pos = _nonCoveredEntries.findIndex(
        (e) => e.id === existing.metadata.coveredEntries[i].id
      );
      if (pos >= 0) {
        _nonCoveredEntries.splice(pos, 1);
      }
    }

    existing.metadata.nonCoveredEntries = _nonCoveredEntries;
  }

  // Since the API finds ALL possible coverage for each API call, all entries should already exist. Therefore,
  //   locate and update each existing entry to see if it is now covered.
  // Actually, the dashboard call on the home page is called twice and have different coverage each time, so we
  //   need to find those and add them to the end since they are orphans in the data.
  const _orphans = [];

  if ((newResult.metadata.entries || []).length > 0) {
    const _entries = _.cloneDeep(existing.metadata.entries);

    for (let i = 0; i < newResult.metadata.entries.length; i++) {
      // Find the existing entry and update its coverage if new one is covered
      const _entry = _entries.find(
        (e) => e.id === newResult.metadata.entries[i].id
      );
      if (_entry) {
        _entry.covered =
          _entry.covered || newResult.metadata.entries[i].covered;
        _entry.count += newResult.metadata.entries[i].count;
      } else {
        _orphans.push({ ...newResult.metadata.entries[i], count: 0 });
      }
    }

    existing.metadata.entries = _.concat(_entries, _orphans);
  }

  existing.coveragePercent = getApiCoveragePercent(existing, false);
  existing.coveragePercentHideDetails = getApiCoveragePercent(existing, true);
}
