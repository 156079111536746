import React from "react";
import { formatDateOnly } from "../../../services/General";
import ClaimSectionRow from "./ClaimSectionRow";
import _ from "lodash";

export default function MemberData({ data }) {
  const member = data;
  const memberInfo = member.memberInfo;
  const address =
    !_.isEmpty(member) && member.addresses.length > 0
      ? member.addresses[0]
      : null;
  const phone =
    !_.isEmpty(member) && member.phoneNumbers.length > 0
      ? member.phoneNumbers[0]
      : null;
  const emailAddress =
    !_.isEmpty(memberInfo) && memberInfo.emailAddresses.length > 0
      ? memberInfo.emailAddresses[0]
      : null;

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-6 col-xxl-4">
        <ClaimSectionRow label="Member Id" value={member.memberId} />
        <ClaimSectionRow
          label="DOB"
          value={formatDateOnly(member.dateOfBirth)}
        />
        <ClaimSectionRow label="Person Code" value={member.personCode} />
        <ClaimSectionRow
          label="Relationship Code"
          value={member.relationshipCode}
        />
        <ClaimSectionRow
          label="Effective Date"
          value={formatDateOnly(member.effectiveDate)}
        />
        <ClaimSectionRow
          label="Termination Date"
          value={formatDateOnly(member.terminationDate)}
        />
      </div>
      <div className="col-xs-12 col-lg-6 col-xxl-3">
        {!_.isEmpty(address) ? (
          <>
            <ClaimSectionRow label="Address Type" value={address.addressType} />
            <ClaimSectionRow label="Address 1" value={address.address1} />
            <ClaimSectionRow label="Address 2" value={address.address2} />
            <ClaimSectionRow label="City" value={address.city} />
            <ClaimSectionRow label="State" value={address.state} />
            <ClaimSectionRow label="Zip" value={address.zipCode} />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="col-xs-12 col-lg-6 col-xxl-3">
        {!_.isEmpty(phone) ? (
          <>
            <ClaimSectionRow label="Phone Type" value={phone.phoneType} />
            <ClaimSectionRow label="Area Code" value={phone.areaCode} />
            <ClaimSectionRow label="Number" value={phone.number} />
          </>
        ) : (
          <></>
        )}
        {!_.isEmpty(memberInfo) ? (
          <>
            <ClaimSectionRow label="First Name" value={memberInfo.firstName} />
            <ClaimSectionRow
              label="Middle Name"
              value={memberInfo.middleName}
            />
            <ClaimSectionRow label="Last Name" value={memberInfo.lastName} />
            <ClaimSectionRow
              label="Email Address"
              value={emailAddress && emailAddress.emailAddress}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
