import React from "react";
import styled from "styled-components";

function TabControl({
  activeTab,
  setActiveTab,
  showTabHeader,
  sticky = true,
  tab1Title,
  tab2Title,
  tab3Title = null,
  tab1Children,
  tab2Children,
  tab3Children = null,
}) {
  return (
    <StyledTabControl>
      {showTabHeader && (
        <StyledTabHeader style={{ position: sticky ? "sticky" : "inherit" }}>
          <h1
            className={activeTab === tab1Title ? "active-tab" : ""}
            onClick={() => setActiveTab(tab1Title)}
          >
            {tab1Title}
          </h1>
          <h1
            className={activeTab === tab2Title ? "active-tab" : ""}
            onClick={() => setActiveTab(tab2Title)}
          >
            {tab2Title}
          </h1>
          {tab3Title !== null && (
            <h1
              className={activeTab === tab3Title ? "active-tab" : ""}
              onClick={() => setActiveTab(tab3Title)}
            >
              {tab3Title}
            </h1>
          )}
        </StyledTabHeader>
      )}
      <StyledTabContent>
        {activeTab === tab1Title ? (
          tab1Children
        ) : activeTab === tab2Title ? (
          tab2Children
        ) : tab3Children !== null ? (
          tab3Children
        ) : (
          <>{activeTab} not found!</>
        )}
      </StyledTabContent>
    </StyledTabControl>
  );
}

const StyledTabControl = styled.div`
  margin-top: 0;
`;

const StyledTabHeader = styled.div`
  top: 88px;
  z-index: 1;
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-flat);

  @media only screen and (max-width: 767px) {
    margin-left: -5%;
    margin-right: -5%;
  }

  h1 {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--tab-control-header);
    padding: 0 0 5px 0;
    margin: 0 0 2px 0;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    text-align: center;

    &:hover {
      font-weight: 600;
      color: var(--tab-control-header-active);
    }

    &.active-tab {
      font-weight: 600;
      color: var(--tab-control-header-active);
      border-bottom: 3px solid var(--tab-control-header-active);
    }
  }
`;

const StyledTabContent = styled.div`
  padding-top: 0px;
`;

export default TabControl;
