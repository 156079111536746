import React, { useEffect, useState } from "react";
import _ from "lodash";
import { DefaultNodeSize } from "./RuleMapNode";
import Dagre from "@dagrejs/dagre";
import RuleMapFlowDiagramProvider from "./RuleMapFlowDiagram";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";

function RuleMapLayouter({ isGroupRuleMap = false }) {
  const { ruleMapsData, setRuleMapsData } = useRuleMaps();
  const [lastNodes, setLastNodes] = useState([]);
  const [lastEdges, setLastEdges] = useState([]);

  useEffect(() => {
    const serLastNodes = JSON.stringify(lastNodes);
    const serLastEdges = JSON.stringify(lastEdges);
    const serNodes = JSON.stringify(ruleMapsData.nodes);
    const serEdges = JSON.stringify(ruleMapsData.edges);

    if (serLastNodes !== serNodes || serLastEdges !== serEdges) {
      // Debounce to keep from this layout from running on both nodes and edges. We want it after both are set.
      if (
        debouncedGetBuildLayoutedNodesAndEdges &&
        debouncedGetBuildLayoutedNodesAndEdges.cancel
      ) {
        debouncedGetBuildLayoutedNodesAndEdges.cancel();
      }
      debouncedGetBuildLayoutedNodesAndEdges(
        ruleMapsData.nodes,
        ruleMapsData.edges
      );
    }
  }, [ruleMapsData.nodes, ruleMapsData.edges]);

  const getLayoutedElements = (nodes, edges) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({
      rankdir: ruleMapsData.layoutDirection,
      nodesep: 200,
      ranksep: 200,
      edgesep: 100,
    });

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) =>
      g.setNode(node.id, {
        ...node,
        width: node.measured?.width ?? DefaultNodeSize.width,
        height: node.measured?.height ?? DefaultNodeSize.height,
      })
    );

    Dagre.layout(g);

    return {
      nodes: nodes.map((node) => {
        const position = g.node(node.id);
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        const x = position.x - (node.measured?.width ?? 0) / 2;
        const y = position.y - (node.measured?.height ?? 0) / 2;

        return {
          ...node,
          position: { x, y },
          data: {
            ...node.data,
          },
        };
      }),
      edges,
    };
  };

  function getBuildLayoutedNodesAndEdges(newNodes, newEdges) {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      newNodes,
      newEdges
    );

    return { layoutedNodes, layoutedEdges };
  }

  const debouncedGetBuildLayoutedNodesAndEdges = React.useRef(
    _.debounce((newNodes, newEdges) => {
      const newNodesAndEdges = getBuildLayoutedNodesAndEdges(
        newNodes,
        newEdges
      );
      setLastNodes(newNodesAndEdges.layoutedNodes);
      setLastEdges(newNodesAndEdges.layoutedEdges);

      setRuleMapsData({
        type: ContextProviderActions.setRuleMapLayoutedNodesAndEdges,
        payload: newNodesAndEdges,
      });
    }, 100)
  ).current;

  function handleOnRelayoutNodes() {
    const newNodesAndEdges = getBuildLayoutedNodesAndEdges(
      ruleMapsData.nodes,
      ruleMapsData.edges
    );
    setLastNodes(newNodesAndEdges.layoutedNodes);
    setLastEdges(newNodesAndEdges.layoutedEdges);

    setRuleMapsData({
      type: ContextProviderActions.setRuleMapFinalLayoutedNodesAndEdges,
      payload: newNodesAndEdges,
    });
  }

  return (
    <>
      <RuleMapFlowDiagramProvider
        onRelayoutNodes={handleOnRelayoutNodes}
        isGroupRuleMap={isGroupRuleMap}
      />
    </>
  );
}

export default RuleMapLayouter;
