import React, { useState, useRef } from "react";
import { notifyError } from "../../../services/NotificationService";

function FileUploadNoSaveButton({
  fileTypes,
  uploading,
  uploadFileContents,
  onPreClick = () => {
    return true;
  },
  style = {},
}) {
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const inputRef = useRef(null);

  const handleUpload = () => {
    if (!onPreClick()) return;
    inputRef.current?.click();
  };

  const uploadFile = () => {
    var f = inputRef.current?.files && inputRef.current?.files[0];
    if (!f) {
      notifyError("Failed to upload file");
      return;
    }

    // Show the file name to the user
    setUploadedFileName(f.name);

    // Read the contents of the file and hand back to caller
    var r = new FileReader();
    r.onload = function (e) {
      var contents = e.target.result;
      uploadFileContents(contents);
      inputRef.current.value = null;
    };
    r.readAsText(f);
  };

  return (
    <div style={style}>
      <input
        ref={inputRef}
        onChange={uploadFile}
        className="d-none"
        type="file"
        accept={fileTypes && fileTypes !== "" ? fileTypes : ".csv"}
      />
      <button
        type="button"
        className="btn btn-secondary btn-with-icon"
        onClick={handleUpload}
        disabled={uploading}
      >
        {uploadedFileName ? (
          <>{`${uploadedFileName} (click to change)`}</>
        ) : uploading ? (
          <>
            <span className="material-icons">hourglass_empty</span>
            {`Uploading ${uploadedFileName}...`}
          </>
        ) : (
          <>
            {" "}
            <span className="material-icons">upload</span>
            Upload Data (CSV)...
          </>
        )}
      </button>
    </div>
  );
}

export default FileUploadNoSaveButton;
