import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyChain, emptyChainSearch } from "../viewmodels/chainsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadChains:
      return {
        ...state,
        chains: action.payload.chains,
        count: action.payload.count,
      };

    case ContextProviderActions.saveChainSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadChain:
      return {
        ...state,
        chain: action.payload,
      };

    case ContextProviderActions.setChainListCache:
      return {
        ...state,
        chainListCache: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in ChainsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  chains: [],
  chain: emptyChain,
  search: emptyChainSearch,
  count: 0,
  chainListCache: [],
};

const Chains = createContext({
  chainsData: initialState,
  setChainsData: () => null,
});

// eslint-disable-next-line react/prop-types
const ChainsProvider = ({ children }) => {
  const [chainsData, setChainsData] = useReducer(reducer, initialState);

  return (
    <Chains.Provider value={{ chainsData, setChainsData }}>
      {children}
    </Chains.Provider>
  );
};

export default ChainsProvider;
export const useChains = () => useContext(Chains);
