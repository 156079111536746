import React, { Fragment, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
} from "../layout/CommonStyledControls";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import GridActionButton from "../grid/GridActionButton";
import InstantSearchInput from "../input/InstantSearchInput";
import { getHighlightedText } from "../../../services/General";
import { Modal } from "react-bootstrap";
import TextInput from "../input/TextInput";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import HelpLink from "./HelpLink";

function SettingsSection({ settingsArrayName, changes, setChanges, helpLink }) {
  const [search, setSearch] = useState({ highlightText: "" });
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editItem, setEditItem] = useState({
    originalKey: "",
    key: "",
    value: "",
  });
  const [errors, setErrors] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  function handleAddSetting() {
    setEditItem({ originalKey: "", key: "", value: "" });
    setEditMode(false);
    setShowModal(true);
  }

  function handleEditSetting(key, value) {
    setEditItem({ originalKey: key, key: key, value: value });
    setEditMode(true);
    setShowModal(true);
  }

  function handleRemoveSetting() {
    const newVm = _.cloneDeep(changes);
    const array = _.get(newVm, settingsArrayName) || [];
    const index = array.findIndex((i) => i.key === deleteId);
    if (index < 0) {
      notifyError(`A setting with key ${deleteId} was not found to remove!`);
      return;
    }

    array.splice(index, 1);
    setChanges(newVm);
    notifySuccess(`Setting '${deleteId}' removed successfully.`);
    setShowDeleteModal(false);
  }

  function handleChange({ target }) {
    let newItem = { ...editItem, [target.name]: target.value };
    setEditItem(newItem);
  }

  function formIsValid() {
    const _errors = {};

    if (editItem.key.trim() === "") _errors.key = "Key is required";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleSave() {
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    const newVm = _.cloneDeep(changes);
    const array = _.get(newVm, settingsArrayName) || [];

    if (editMode) {
      // Save in edit mode
      const index = array.findIndex((i) => i.key === editItem.originalKey);
      if (index < 0) {
        notifyError(
          `A setting with key ${editItem.originalKey} was not found to edit!`
        );
        return;
      }

      const item = array[index];
      if (_.isNull(editItem.value)) item.valuesVm = "";
      else item.valuesVm = editItem.value;
      item.key = editItem.key;
    } else {
      // Save in insert mode
      array.push({
        key: editItem.key,
        valuesVm: editItem.value,
      });
    }

    setChanges(newVm);
    handleCloseEditDialog();
    notifySuccess(`Setting ${editMode ? "updated" : "added"} successfully.`);
  }

  function submitOnEnter(e) {
    if (e && e.key && e.key === "Enter") {
      handleSave();
    }
  }

  function handleCloseEditDialog() {
    setEditItem({ originalKey: "", key: "", value: "" });
    setErrors({});
    setShowModal(false);
  }

  function getActionButton(key, value) {
    const actions = [
      {
        name: "Edit Setting",
        onClick: () => handleEditSetting(key, value),
      },
      {
        name: "Remove Setting",
        onClick: () => {
          setDeleteId(key);
          setShowDeleteModal(true);
        },
      },
    ];

    return <GridActionButton key={key} actions={actions}></GridActionButton>;
  }

  function handleInstantSearchChange(value) {
    setSearch({ ...search, highlightText: value });
  }

  const hl = search.highlightText;
  let keyHlResult = {};
  let valueHlResult = {};
  const settingsArray = _.get(changes, settingsArrayName) || [];

  return (
    <>
      <ConfirmDialog
        title="Remove Setting"
        question={`Are you sure you wish to remove the setting '${deleteId}'?`}
        showModal={showDeleteModal}
        onNo={() => setShowDeleteModal(false)}
        onYes={handleRemoveSetting}
      />
      <Modal show={showModal} onHide={handleCloseEditDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Setting" : "Add Setting"}
            <HelpLink path={helpLink} label="" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            id="key"
            label="Key"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="key"
            value={editItem.key}
            error={errors.key}
            autoFocus={true}
          />
          <TextInput
            id="value"
            label="Value"
            onChange={handleChange}
            onKeyDown={submitOnEnter}
            placeholder=""
            name="value"
            value={editItem.value}
            error={errors.value}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSave}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseEditDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <StyledContainer>
        <StyledHeaderRowDiv>
          <InstantSearchInput
            id="screenSearchInput"
            onChange={handleInstantSearchChange}
            value={search.highlightText}
          />
          <StyledHeaderRowButtonDiv>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleAddSetting}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="material-icons">add</span>
              {"  "}Add Setting
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        <table className="table table-clickable-rows">
          <thead>
            <tr>
              <th id="key" style={{ width: "40%" }}>
                Key
              </th>
              <th
                id="values"
                style={{ width: "60%" }}
                className=" d-none d-sm-table-cell"
              >
                Value(s)
              </th>
              <th style={{ width: "60px", textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {settingsArray
              .sort((a, b) => (a.key > b.key ? 1 : -1))
              .map((i, idx) => {
                keyHlResult = getHighlightedText(i.key, hl);
                valueHlResult = getHighlightedText(i.valuesVm, hl);

                return _.trim(hl).length > 0 &&
                  keyHlResult.count === 0 &&
                  valueHlResult.count === 0 ? (
                  <></>
                ) : (
                  <Fragment key={idx}>
                    <tr key={"setting" + idx}>
                      <td className="force-wrap">
                        <button
                          type="button"
                          className="btn btn-link link-underline"
                          onClick={() => handleEditSetting(i.key, i.valuesVm)}
                        >
                          {keyHlResult.html}
                        </button>
                      </td>
                      <td className="force-wrap d-none d-sm-table-cell">
                        {valueHlResult.html}
                      </td>
                      <td className="table-action-btn">
                        {getActionButton(i.key, i.valuesVm)}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </StyledContainer>
    </>
  );
}

const StyledContainer = styled.div`
  padding-top: 10px;

  table.table {
    margin-top: 12px;
  }

  button.btn.btn-secondary {
    margin-top: 4px;
  }
`;

export default SettingsSection;
