import React, { useEffect } from "react";
import {
  StyledNoResultsDiv,
  StyledRowDiv,
} from "../../common/layout/CommonStyledControls";
import { useMobile } from "../../../hooks/useMobile";
import { StyledArtifact, StyledWrapper } from "./RuleGroupSummaryCommonStyles";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import RuleGroupSummaryProperty from "./RuleGroupSummaryProperty";

function RuleGroupSummaryProperties({
  items,
  artifacts,
  isAdmin,
  sort,
  setSort,
  displayValue,
  includeAllClaimsRule,
  setSortedFilteredItems,
  linkedProperty,
  onClickReturn,
}) {
  const { isMobileSize, isTabletSize } = useMobile();

  useEffect(() => {
    if (displayValue !== "advanced" && sort === "artifact") {
      setSort("valueTypeDisplay");
      setSortedFilteredItems(includeAllClaimsRule, "valueTypeDisplay");
    }
  }, [displayValue]);

  const sortOptions = [
    { value: "valueTypeDisplay", label: "Type" },
    { value: "name", label: "Name" },
    { value: "value", label: "Value" },
  ];
  if (displayValue === "advanced")
    sortOptions.push({ value: "artifact", label: "Artifact" });

  const isAdminView = displayValue === "admin" || displayValue === "advanced";

  return (
    <>
      {items.length === 0 && (
        <StyledRowDiv className="row" style={{ marginTop: "5px" }}>
          <StyledNoResultsDiv>
            <p>
              <i className="material-icons">search_off</i>
            </p>
            <p>No properties found for this group</p>
          </StyledNoResultsDiv>
        </StyledRowDiv>
      )}
      {items.length > 0 && (
        <StyledRowDiv className="row">
          <p style={{ marginBottom: 0, fontStyle: "italic" }}>
            The following properties are used by this group.
          </p>
          <div className="flex-row-with-wrap-and-justify">
            <ToggleButtonGroupInput
              id="sort"
              name="sort"
              label="Sort by"
              labelStyle={{ display: isMobileSize ? "none" : "inline" }}
              showLabelInline={true}
              options={[...sortOptions]}
              value={sort}
              onChange={(value) => {
                setSort(value);
                setSortedFilteredItems(includeAllClaimsRule, value);
              }}
            />
          </div>
          {sort === "artifact" ? (
            <div style={{ marginTop: "20px" }}>
              {artifacts.map((artifact) => {
                return (
                  <div key={`${artifact.sequence}-${artifact.name}`}>
                    <StyledArtifact>
                      {artifact.name !== "None" && (
                        <span className="list-number">
                          {artifact.sequence}.
                        </span>
                      )}
                      {artifact.name === "None" ? (
                        <i>Not Associated with an Artifact:</i>
                      ) : (
                        artifact.name
                      )}
                      {artifact.name !== "None" && (
                        <span className="summary-field">
                          {artifact.artifactType === "CodeArtifact"
                            ? "code"
                            : "rule"}
                        </span>
                      )}
                    </StyledArtifact>
                    <StyledWrapper
                      className="col-12 col-md-6"
                      isSmallScreen={isMobileSize || isTabletSize}
                      style={{ marginTop: "10px" }}
                    >
                      {items
                        .filter(
                          (r) =>
                            (r.artifact === artifact.name &&
                              artifact.name !== "None") ||
                            (r.artifact === "" && artifact.name === "None")
                        )
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((r, idx) => {
                          return (
                            <RuleGroupSummaryProperty
                              key={`${r.name}-${idx}`}
                              item={r}
                              showValue={displayValue !== "basic"}
                              showRuleLink={isAdminView}
                              linkedProperty={linkedProperty}
                              onClickReturn={onClickReturn}
                              isAdmin={isAdmin}
                            />
                          );
                        })}
                    </StyledWrapper>
                  </div>
                );
              })}
            </div>
          ) : (
            <StyledWrapper
              className="col-12 col-md-6"
              isSmallScreen={isMobileSize || isTabletSize}
              style={{ marginTop: "10px" }}
            >
              {items.map((r, idx) => {
                return (
                  <RuleGroupSummaryProperty
                    key={`${r.name}-${idx}`}
                    item={r}
                    showValue={displayValue !== "basic"}
                    showRuleLink={isAdminView}
                    linkedProperty={linkedProperty}
                    onClickReturn={onClickReturn}
                    isAdmin={isAdmin}
                  />
                );
              })}
            </StyledWrapper>
          )}
        </StyledRowDiv>
      )}
    </>
  );
}

export default RuleGroupSummaryProperties;
