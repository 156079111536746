import React from "react";
import _ from "lodash";
import styled from "styled-components";
import DatePickerInput from "../../common/input/DatePickerInput";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";

function CacheStatusSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onStartDateChange,
  onEndDateChange,
  onClearCacheKeys,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <StyledSearchForm>
          <DatePickerInput
            id="startDate"
            name="startDate"
            label="Start Time"
            value={search.startDate}
            placeholder="Start Time"
            onChange={onStartDateChange}
            error={errors.startDate}
          />
          <DatePickerInput
            id="endDate"
            label="End Time"
            name="endDate"
            value={search.endDate}
            placeholder="End Time"
            onChange={onEndDateChange}
            error={errors.endDate}
          />
          <TextInput
            id="keyMatch"
            label="Key Search"
            onChange={onChange}
            placeholder="Partial key name"
            name="keyMatch"
            value={search.keyMatch}
            error={errors.keyMatch}
          />
          <div>
            <input type="submit" value={"Update"} className="btn btn-primary" />
            <input
              type="reset"
              value="Reset"
              className="btn btn-secondary"
              style={{ marginLeft: "10px" }}
            />
          </div>
          <input
            type="button"
            value="Clear matching keys from cache"
            className="btn btn-secondary"
            onClick={() => onClearCacheKeys(search.keyMatch)}
            style={{ marginLeft: isMobileSize ? "0" : "auto", width: "240px" }}
            disabled={_.trim(search.keyMatch) === ""}
          />
        </StyledSearchForm>
      </form>
    </>
  );
}

const StyledSearchForm = styled.div`
  display: flex;
  column-gap: 24px;
  row-gap: 14px;
  align-items: flex-end;
  flex-wrap: wrap;

  label {
    color: var(--text-medium);
  }

  input.btn.btn-primary,
  input.btn.btn-secondary {
    height: 42px;
    width: 106px;
    min-width: 86px;
  }
`;

export default CacheStatusSearchForm;
