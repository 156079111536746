import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyDatabaseSync } from "../viewmodels/dbDatabaseSyncVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadDbDatabaseSync:
      return {
        ...state,
        dbDatabaseSync: action.payload,
      };

    case ContextProviderActions.loadDbDatabaseSyncUpdateState:
      return {
        ...state,
        trafficDbSyncStateUpdates: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in DbDatabaseSyncContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  dbDatabaseSync: emptyDatabaseSync,
  trafficDbSyncStateUpdates: null,
};

const DbDatabaseSync = createContext({
  dbDatabaseSyncData: initialState,
  setDbDatabaseSyncData: () => null,
});

// eslint-disable-next-line react/prop-types
const DbDatabaseSyncProvider = ({ children }) => {
  const [dbDatabaseSyncData, setDbDatabaseSyncData] = useReducer(
    reducer,
    initialState
  );

  return (
    <DbDatabaseSync.Provider
      value={{ dbDatabaseSyncData, setDbDatabaseSyncData }}
    >
      {children}
    </DbDatabaseSync.Provider>
  );
};

export default DbDatabaseSyncProvider;
export const useDbDatabaseSync = () => useContext(DbDatabaseSync);
