import React from "react";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";

export default function ClaimDataSection({
  data,
  sectionTitle,
  SubSection,
  collapsedState,
  setCollapsedState,
  helpLink = null,
}) {
  return (
    <>
      <ExpandCollapseDetailSection
        sectionTitle={sectionTitle}
        collapsedState={collapsedState}
        setCollapsedState={setCollapsedState}
        helpLink={helpLink}
      >
        <div data-testid={`subsection-${sectionTitle}`}>
          <SubSection data={data} />
        </div>
      </ExpandCollapseDetailSection>
    </>
  );
}
