import React, { useState } from "react";
import _ from "lodash";
import Authorize from "../../../common/layout/Authorize";
import { StyledWizardPanel } from "../../../common/layout/CommonStyledWizardComponents";
import useApi from "../../../../hooks/useApi";
import { apiUpdateMember } from "../../../../api/MemberApi";
import { MMDDyyyy, yyyy_mm_dd } from "../../../../services/General";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  notifySuccess,
  notifyWarn,
} from "../../../../services/NotificationService";
import { parseISO, isValid } from "date-fns";
import PanelGetGroup from "../common/PanelGetGroup";
import PanelGetMember from "../common/PanelGetMember";
import PanelSubmitForm from "../common/PanelSubmitForm";
import PanelGetDateOnly from "../common/PanelGetDateOnly";
import PanelWizardHeader from "../common/PanelWizardHeader";

function WizardSetMemberDOB() {
  const { auth } = useAuth();
  const { loading: updating, api: apiUpdate } = useApi(apiUpdateMember);

  const [currentStep, setCurrentStep] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState({
    value: "",
    label: "(Select a group)",
  });
  const [member, setMember] = useState(null);
  const [oldDateOfBirth, setOldDateOfBirth] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [errors, setErrors] = useState({});

  const STEP_ORDER = ["groupId", "member", "dateOfBirth", "submit"];

  function getStepNumber(stepName) {
    const step = STEP_ORDER.findIndex((s) => s === stepName) || 0;
    return step;
  }

  function setNextStep(stepName) {
    const step = getStepNumber(stepName);

    // Only go up in steps so fields don't disappear when user goes back.
    if (step > currentStep) {
      setCurrentStep(step);
    }
  }

  function resetForm() {
    setSelectedGroup({
      value: "",
      label: "(Select a group)",
    });
    setMember(null);
    setOldDateOfBirth(null);
    setDateOfBirth(null);
    setNextStep("groupId");
  }

  function setGroup(groupId) {
    if (_.isEmpty(groupId?.value)) {
      resetForm();
      return;
    }

    setNextStep("member");
    handleMemberIdOnChange(null);
    setSelectedGroup(groupId);
  }

  function handleMemberIdOnChange(selectedItem) {
    setMember(selectedItem);
    if (!selectedItem) {
      setOldDateOfBirth(null);
      setDateOfBirth(null);
      setNextStep("member");
      return;
    }

    const dob = selectedItem.dateOfBirth;
    const date = new Date(dob).toISOString();
    setOldDateOfBirth(date);
    setDateOfBirth(date);
    setNextStep("dateOfBirth");
  }

  function handleMemberDateOfBirth(date) {
    const dob = date === null ? null : new Date(date).toISOString();
    setDateOfBirth(dob);
    setNextStep("submit");
  }

  function formIsValid() {
    const _errors = {};

    if (_.isEmpty(selectedGroup?.value)) _errors.groupId = "Group is required";

    if (_.isEmpty(dateOfBirth) || !isValidDate(dateOfBirth))
      _errors.dateOfBirth = "Date of Birth is required";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function isValidDate(date) {
    const parsedDate = parseISO(date);
    const valid = isValid(parsedDate);
    return valid;
  }

  async function updateMember() {
    const model = member.selectedMember;
    const id = model.id;
    model.dateOfBirth = yyyy_mm_dd(parseISO(dateOfBirth));

    apiUpdate.call({ id, model }, (result) => {
      const parsedDob = parseISO(result.dateOfBirth);
      const msg = `DOB for member ${member.memberId} in group ${
        selectedGroup.value
      } was updated to ${MMDDyyyy(parsedDob)}.`;
      notifySuccess(msg);
      resetForm();
    });
  }

  async function handleSubmit(e) {
    if (e) e.preventDefault();

    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    await updateMember();
  }

  return (
    <Authorize>
      <PanelWizardHeader
        helpLink={"Change-Member-DOB"}
        symbolIcon={"event"}
        title={"Change Member DOB"}
      />
      <form>
        <StyledWizardPanel>
          <ol>
            <PanelGetGroup
              isAuthenticated={auth.authenticated}
              stepNumber={getStepNumber("groupId")}
              currentStep={currentStep}
              name={"groupId"}
              title={"Select the Group ID for the member:"}
              selectedGroup={selectedGroup}
              setSelectedGroup={setGroup}
              errors={errors}
              showPanel={auth.authenticated}
            />
            <PanelGetMember
              stepNumber={getStepNumber("member")}
              currentStep={currentStep}
              name={"member"}
              title={"Enter the Member ID to locate the member:"}
              member={member}
              selectedGroup={selectedGroup}
              onMemberChange={handleMemberIdOnChange}
              errors={errors}
            />
            <PanelGetDateOnly
              stepNumber={getStepNumber("dateOfBirth")}
              currentStep={currentStep}
              name={"dateOfBirth"}
              title={"Enter the new DOB for the member:"}
              footerText={`Current: ${
                oldDateOfBirth === null
                  ? ""
                  : MMDDyyyy(parseISO(oldDateOfBirth))
              }`}
              value={dateOfBirth === null ? null : new Date(dateOfBirth)}
              onDateChange={handleMemberDateOfBirth}
              errors={errors}
            />
            <PanelSubmitForm
              showPanel={
                member !== null &&
                dateOfBirth !== null &&
                !_.isEmpty(dateOfBirth) &&
                isValidDate(dateOfBirth) &&
                oldDateOfBirth !== dateOfBirth
              }
              stepNumber={getStepNumber("submit")}
              currentStep={currentStep}
              name={"submit"}
              title={"Save the member to update the DOB."}
              label={"Save Member"}
              disabled={
                updating ||
                selectedGroup === null ||
                member === null ||
                oldDateOfBirth === dateOfBirth ||
                (oldDateOfBirth === null && dateOfBirth === null)
              }
              submitting={updating}
              onSubmit={handleSubmit}
            />
          </ol>
        </StyledWizardPanel>
      </form>
    </Authorize>
  );
}

export default WizardSetMemberDOB;
