import React, { useState, useEffect } from "react";
import AuditRecordList from "./AuditRecordList";
import AuditRecordsSearchForm from "./AuditRecordsSearchForm";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  createViewModel,
  emptyAuditRecordsSearch,
} from "../../../viewmodels/auditVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useAudit } from "../../../contexts/AuditContext";
import {
  apiLoadAuditRecords,
  apiRollbackSingleChange,
  apiRollbackBulkChange,
} from "../../../api/AuditApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import useApi from "../../../hooks/useApi";
import { notifySuccess } from "../../../services/NotificationService";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import {
  StyledHeaderRowDiv,
  StyledScreenHelpDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";

function AuditRecords() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { auditData, setAuditData } = useAudit();
  const { loading, api: apiLoad } = useApi(apiLoadAuditRecords);
  const { loading: rollingBack, api: apiRollbackSingle } = useApi(
    apiRollbackSingleChange
  );
  const { loading: rollingBackBulk, api: apiRollbackBulk } = useApi(
    apiRollbackBulkChange
  );
  const [loadData, setLoadData] = useState(true);
  const { isMobileSize } = useMobile();
  const [rollbackData, setRollbackData] = useState({});
  const [showRollbackModal, setShowRollbackModal] = useState(false);

  let auditRecords = [];
  if (auditData && auditData.auditRecords) {
    auditRecords = auditData.auditRecords;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setChainsData must usually
  //   happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //   useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadAuditRecords();
    }
  }, [auth.authenticated, loadData]);

  async function loadAuditRecords() {
    apiLoad.call(
      auditData.search,
      (result) => {
        setLoadData(false);
        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setAuditData({
          type: ContextProviderActions.loadAuditRecords,
          payload: {
            auditRecords: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setAuditData({
      type: ContextProviderActions.saveAuditSearch,
      payload: search,
    });
  }

  async function handleReset() {
    let clearedSearch = emptyAuditRecordsSearch;
    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    clearedSearch.startDate = startDate;
    clearedSearch.endDate = endDate;
    setSearchChanges({
      ...clearedSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : auditData.search.showAdvancedFilter,
    });

    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    if (
      _.has(auditData.search, "startDate") &&
      !_.isDate(auditData.search.startDate)
    )
      _errors.startDate = "Effective Date must be a valid date";
    if (
      _.has(auditData.search, "endDate") &&
      !_.isDate(auditData.search.endDate)
    )
      _errors.endDate = "Termination Date must be a valid date";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = auditData.search.orderBy.indexOf(`${event.target.id}+`);
    var indexDesc = auditData.search.orderBy.indexOf(`${event.target.id}-`);

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...auditData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...auditData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1) return updateSort({ ...auditData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = { ...auditData.search, ...newSearch, pageNumber: 1 };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleDateRangeChange(dateRange, startDate, endDate) {
    setSearchChanges({
      ...auditData.search,
      dateRange: dateRange,
      startDate: startDate,
      endDate: endDate,
    });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...auditData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...auditData.search, endDate: date });
  }

  function handleSearchChange({ target }) {
    setSearchChanges({ ...auditData.search, [target.name]: target.value });
  }

  function handleCheckboxChange({ target }) {
    let changed = { ...auditData.search, [target.name]: target.checked };
    setSearchChanges(changed);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (auditData.search.id !== "") numFilters++;
    if (auditData.search.userName !== "") numFilters++;
    if (auditData.search.documentId !== "") numFilters++;
    if (auditData.search.entityType !== "") numFilters++;
    if (auditData.search.entityKey !== "") numFilters++;
    if (auditData.search.bulkChangeOnly === true) numFilters++;

    return numFilters;
  }

  async function rollbackBulkChanges(id) {
    apiRollbackBulk.call(id, (result) => {
      notifySuccess("Changes for bulk operation rolled back successfully");
      setLoadData(true);
    });
  }

  async function rollbackChangesSingleId(entityType, documentId, id) {
    apiRollbackSingle.call(
      { entityType, id: documentId, transactionId: id },
      (result) => {
        notifySuccess(`Changes to single document rolled back successfully`);
        setLoadData(true);
      }
    );
  }

  async function performRollbackChanges() {
    setShowRollbackModal(false);

    if (rollbackData.entityType === "") {
      await rollbackBulkChanges(rollbackData.id);
    } else {
      await rollbackChangesSingleId(
        rollbackData.entityType,
        rollbackData.documentId,
        rollbackData.id
      );
    }
  }

  async function performRollbackSingleChange(entityType, documentId, id) {
    setRollbackData({ entityType, documentId, id });
    setShowRollbackModal(true);
  }

  async function performRollbackBulkChange(id) {
    setRollbackData({ entityType: "", documentId: "", id });
    setShowRollbackModal(true);
  }

  return (
    <Authorize>
      <StyledHeaderRowDiv>
        <h1>Audit Records</h1>
        <StyledScreenHelpDiv>
          {((auditData.search.showAdvancedFilter && !isMobileSize) ||
            !auditData.search.showAdvancedFilter) && (
            <HelpLink path="/Tools/Audit-Records-List-Screen" label="Help" />
          )}
        </StyledScreenHelpDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search by Doc Id, User, or Entity"
        search={auditData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              auditData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={auditData.search}
            setSearch={setSearchChanges}
            helpLink="/Tools/Audit-Records-List-Screen&anchor=filters"
          >
            <AuditRecordsSearchForm
              errors={errors}
              search={auditData.search}
              onCheckboxChange={handleCheckboxChange}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              onDateRangeChange={handleDateRangeChange}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
            />
          </GridAdvancedFilter>
        </div>
        <ConfirmDialog
          title="Rollback Changes"
          question={`${
            rollbackData.entityType === ""
              ? "Are you sure you want to undo all of the changes from this bulk operation"
              : `Are you sure you want to undo the changes from ${rollbackData.entityType} ${rollbackData.documentId}`
          }?`}
          showModal={showRollbackModal}
          onNo={() => setShowRollbackModal(false)}
          onYes={performRollbackChanges}
        />
        {auditData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData || rollingBack || rollingBackBulk ? (
              <Spinner />
            ) : (
              <AuditRecordList
                auditRecords={auditRecords}
                search={auditData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={auditData.count}
                onRollbackSingleChange={(entityType, documentId, id) =>
                  performRollbackSingleChange(entityType, documentId, id)
                }
                onRollbackBulkChange={(id) => performRollbackBulkChange(id)}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default AuditRecords;
