import React, { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Spinner from "../../common/ui/Spinner";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import { notifySuccess } from "../../../services/NotificationService";
import {
  decodeBase64Stream,
  download,
  formatDateTimeUtcZoneForDisplay,
  getExtensionFromFilename,
  getTimeframeFromMilliseconds,
  handleCollapseExpandAll,
} from "../../../services/General";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import ReadOnly from "../../common/input/ReadOnly";
import { useProcessHistory } from "../../../contexts/ProcessHistoryContext";
import {
  apiDownloadProcessFile,
  apiLoadProcessHistoryRecord,
} from "../../../api/ProcessHistoryApi";
import ReportProcessLastRunSavedData from "./ReportProcessLastRunSavedData";
import { emptyProcessHistory } from "../../../viewmodels/processHistoryVm";
import ReportProcessReportParametersGrid from "./ReportProcessReportParametersGrid";
import HelpLink from "../../common/ui/HelpLink";

function ProcessRunHistoryRecord() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { processHistoryData, setProcessHistoryData } = useProcessHistory();
  const { loading, api: apiLoad } = useApi(apiLoadProcessHistoryRecord);
  const { loading: downloading, api: apiDownload } = useApi(
    apiDownloadProcessFile
  );

  const [collapsedState, setCollapsedState] = useState([
    { name: "Process Run History", collapsed: false },
    { name: "Last Run Info", collapsed: false },
  ]);

  const processHistoryRecord =
    (processHistoryData && processHistoryData.processHistoryRecord) ||
    emptyProcessHistory;
  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      loadProcessHistoryRecord();
    }
  }, [auth.authenticated, params?.id]);

  async function loadProcessHistoryRecord() {
    apiLoad.call(resId, async (result) => {
      setProcessHistoryData({
        type: ContextProviderActions.loadProcessHistoryRecord,
        payload: result,
      });
    });
  }

  async function handleDownloadProcessFile(id) {
    apiDownload.call(id, async (result) => {
      const filename = result.FileName;
      const ext = getExtensionFromFilename(filename);
      const contents = await decodeBase64Stream(result.FileContents);
      download(contents, filename, ext);
      notifySuccess("File downloaded successfully");
    });
  }

  const isLoadingStuff = loading || downloading;

  return (
    <Authorize>
      <StyledBackButtonDiv>
        <button
          title="Return to previous screen"
          type="button"
          className="btn btn-link"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink
            path="/Reports/View-Process-Run-History-Record-Screen"
            label="Help"
          />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1>
          View Process Run History - {processHistoryRecord.description} -
          {formatDateTimeUtcZoneForDisplay(processHistoryRecord.runDate)}
        </h1>
      </StyledHeaderRowDiv>

      {isLoadingStuff ? (
        <Spinner />
      ) : (
        <>
          <ActionMenu
            title="Actions"
            items={[
              { value: "ExpandAll", label: "Expand All" },
              { value: "CollapseAll", label: "Collapse All" },
            ]}
            onSelectAction={(value, label) =>
              handleCollapseExpandAll(
                value === "CollapseAll",
                collapsedState,
                setCollapsedState
              )
            }
          />
          <div className="container-fluid" style={{ marginTop: "5px" }}>
            <ExpandCollapseDetailSection
              sectionTitle="Process Run History"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Reports/View-Process-Run-History-Record-Screen&anchor=process-run-history"
            >
              <StyledRowDiv className="row">
                <div className="col-12 col-md-6">
                  <ReadOnly
                    label="Name"
                    value={processHistoryRecord.description || ""}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <ReadOnly
                    label="Run Type"
                    value={processHistoryRecord.runType || ""}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Report</label>
                    <div className="field">
                      <button
                        className="btn btn-link link-underline"
                        onClick={() =>
                          navigate(
                            "/report/" + processHistoryRecord.reportDefinitionId
                          )
                        }
                      >
                        {processHistoryRecord.reportName}
                      </button>
                    </div>
                  </div>
                </div>
                {processHistoryRecord.reportTemplateId && (
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Template</label>
                      <div className="field">
                        <button
                          className="btn btn-link link-underline"
                          onClick={() =>
                            navigate(
                              "/reporttemplate/" +
                                processHistoryRecord.reportTemplateId
                            )
                          }
                        >
                          {processHistoryRecord.reportTemplateName}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
            <ExpandCollapseDetailSection
              sectionTitle="Last Run Info"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Reports/View-Process-Run-History-Record-Screen&anchor=last-run-info"
            >
              <StyledRowDiv className="row">
                <div className="col-6">
                  <ReadOnly
                    label="Run Date"
                    value={formatDateTimeUtcZoneForDisplay(
                      processHistoryRecord.runDate
                    )}
                  />
                </div>
                <div className="col-6">
                  <ReadOnly
                    label="Duration"
                    value={getTimeframeFromMilliseconds(
                      processHistoryRecord.runDuration
                    )}
                  />
                </div>
                <div className="col-6">
                  <ReadOnly label="Run By" value={processHistoryRecord.runBy} />
                </div>
                <div className="col-6">
                  <ReadOnly
                    label="Run Number"
                    value={processHistoryRecord.runNumber}
                  />
                </div>
              </StyledRowDiv>
              <StyledRowDiv style={{ marginTop: "20px" }}>
                <div className="col-12 col-sm-6">
                  <button
                    className="btn btn-link link-underline"
                    onClick={() =>
                      handleDownloadProcessFile(processHistoryRecord.id)
                    }
                  >
                    Download file(s) from this run
                  </button>
                </div>
              </StyledRowDiv>
              {processHistoryRecord &&
                processHistoryRecord.runType === "Detail" && (
                  <ReportProcessLastRunSavedData
                    title="Saved Data"
                    vmLastSavedValues={
                      processHistoryRecord.lastRunValuesUIFriendly
                    }
                    allowEdits={false}
                  />
                )}
              {processHistoryRecord &&
                processHistoryRecord.runType === "Summary" && (
                  <ReportProcessReportParametersGrid
                    vmFilters={processHistoryRecord.dataFilterValues}
                  />
                )}
            </ExpandCollapseDetailSection>
          </div>
        </>
      )}
    </Authorize>
  );
}

export default ProcessRunHistoryRecord;
