import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import InfoDialog from "../../dialogs/InfoDialog";
import TestStepDialog from "../tests/TestStepDialog";
import { emptyTestStep } from "../../../viewmodels/testsVm";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";
import StatusPill from "../../common/ui/StatusPill";
import { useHref } from "react-router-dom";
import { notifyError } from "../../../services/NotificationService";
import {
  convertLocalDateToUTC,
  formatDateTime,
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
} from "../../../services/General";

function TestStepResultsList({
  isAdmin,
  testResultId,
  testSteps,
  totalRecords,
  search,
  setSearch,
  onCollapseExpandJson,
}) {
  const hrefTestProc = useHref("/testprocessor");
  const [messageText, setMessageText] = useState("");
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [editItem, setEditItem] = useState(emptyTestStep);
  const [showStepDialog, setShowStepDialog] = useState(false);

  function handleShowMessage(message) {
    if (message !== "") {
      setMessageText(message);
      setShowMessageDialog(true);
    }
  }

  function handleViewStep(step) {
    setEditItem({ ...step });
    setShowStepDialog(true);
  }

  function getJsonFromContents(content) {
    const contentJson = "" + content;
    let json = {};

    try {
      json = JSON.parse(contentJson.replaceAll("'", '"'));
    } catch (ex) {
      notifyError("Error parsing JSON content: " + ex);
    }

    return json;
  }

  function getPrettyJson(content) {
    if (!content || content === "") return "";
    const result = JSON.stringify(content, null, "  ");
    return result;
  }

  function getExpectedStatusCode(row) {
    const expectedStatusCode =
      row.expectedStatusCode === 0 ? "" : "" + row.expectedStatusCode;

    return expectedStatusCode;
  }

  function getExpectedResponseJson(row) {
    const expectedResponseIsJson =
      row.expectedResponseJson &&
      (row.expectedResponseJson.startsWith("[") ||
        row.expectedResponseJson.startsWith("{"));
    const expectedResponseJson = expectedResponseIsJson
      ? getJsonFromContents(row.expectedResponseJson)
      : [];

    return expectedResponseJson;
  }

  function isExpectedResponseJson(row) {
    const expectedResponseIsJson =
      row.expectedResponseJson &&
      (row.expectedResponseJson.startsWith("[") ||
        row.expectedResponseJson.startsWith("{"));

    return expectedResponseIsJson;
  }

  function getExpectedResponseString(row) {
    const expectedResponseIsJson = isExpectedResponseJson(row);
    const expectedResponseJson = getExpectedResponseJson(row);
    const expectedResponseString = expectedResponseIsJson
      ? getPrettyJson(expectedResponseJson)
      : row.expectedResponseJson;

    return expectedResponseString;
  }

  function getExpectedFieldStatus(row) {
    const expectedStatusCode = getExpectedStatusCode(row);

    if (expectedStatusCode !== "") {
      return (
        <>
          <span>Status: {expectedStatusCode}</span>
          <br />
        </>
      );
    }
    return <></>;
  }

  function getExpectedFieldContents(row) {
    const expectedResponseIsJson = isExpectedResponseJson(row);
    const expectedResponseString = getExpectedResponseString(row);
    const expectedStatusCode = getExpectedStatusCode(row);

    // If we have no response but we did have a status code, show nothing here.
    if (_.trim(expectedResponseString) === "" && expectedStatusCode !== "") {
      return <></>;
    }

    // If we have no response and do not have a status code either, show No Response.
    if (_.trim(expectedResponseString) === "") {
      return (
        <StyledDocumentDisplay>
          <i>No response</i>
        </StyledDocumentDisplay>
      );
    }

    // If response is plain text, show as text - actual will show differences
    if (!expectedResponseIsJson && _.trim(expectedResponseString) !== "") {
      return (
        <StyledDocumentDisplay>{expectedResponseString}</StyledDocumentDisplay>
      );
    }

    // Otherwise, show and compare as JSON
    return (
      <StyledDocumentDisplay
        dangerouslySetInnerHTML={{
          __html: getHighlightedHtmlFromExpectedJson(row),
        }}
      ></StyledDocumentDisplay>
    );
  }

  function getActualStatusCode(row) {
    const actualStatusCode =
      row.lastStatusCode === 0 ? "" : "" + row.lastStatusCode;

    return actualStatusCode;
  }

  function getActualResponseJson(row) {
    const actualResponseIsJson =
      row.lastResponse &&
      (row.lastResponse.startsWith("[") || row.lastResponse.startsWith("{"));
    const actualResponseJson = actualResponseIsJson
      ? getJsonFromContents(row.lastResponse)
      : [];

    return actualResponseJson;
  }

  function isActualResponseJson(row) {
    const actualResponseIsJson =
      row.lastResponse &&
      (row.lastResponse.startsWith("[") || row.lastResponse.startsWith("{"));

    return actualResponseIsJson;
  }

  function getActualResponseString(row) {
    const actualResponseIsJson = isActualResponseJson(row);
    const actualResponseJson = getActualResponseJson(row);
    const actualResponseString = actualResponseIsJson
      ? getPrettyJson(actualResponseJson)
      : row.lastResponse;

    return actualResponseString;
  }

  function getActualFieldStatus(row) {
    const actualStatusCode = getActualStatusCode(row);
    const expectedStatusCode = getExpectedStatusCode(row);

    if (actualStatusCode !== "") {
      return (
        <>
          <span className={expectedStatusCode !== actualStatusCode ? "hl" : ""}>
            Status: {actualStatusCode}
          </span>
          <br />
        </>
      );
    }

    if (actualStatusCode === "" && expectedStatusCode !== "") {
      return (
        <>
          <span className="hl">
            <i>No status</i>
          </span>
          <br />
        </>
      );
    }

    return <></>;
  }

  function getActualFieldContents(row) {
    const expectedStatusCode = getExpectedStatusCode(row);
    const actualResponseIsJson = isActualResponseJson(row);
    const actualResponseString = getActualResponseString(row);
    const expectedResponseString = getExpectedResponseString(row);

    // If we have no response but we did have a status code, show nothing here.
    if (_.trim(actualResponseString) === "" && expectedStatusCode !== "") {
      return <></>;
    }

    // If we have an expected response but no actual response, show No Response.
    if (
      _.trim(expectedResponseString) !== "" &&
      _.trim(actualResponseString) === ""
    ) {
      return (
        <StyledDocumentDisplay className="hl">
          <i>No response</i>
        </StyledDocumentDisplay>
      );
    }

    // If response is plain text, show and compare as text
    if (
      !actualResponseIsJson &&
      _.trim(expectedResponseString) !== "" &&
      _.trim(actualResponseString) !== ""
    ) {
      return (
        <StyledDocumentDisplay
          className={
            expectedResponseString.toLowerCase() !==
            actualResponseString.toLowerCase()
              ? "hl"
              : ""
          }
        >
          {actualResponseString}
        </StyledDocumentDisplay>
      );
    }

    // Otherwise, show and compare as JSON
    return (
      <StyledDocumentDisplay
        dangerouslySetInnerHTML={{
          __html: getHighlightedHtmlFromActualJson(row),
        }}
      ></StyledDocumentDisplay>
    );
  }

  function getExpectedFieldValue(row, fieldName) {
    const expectedResponseJson = getExpectedResponseJson(row);

    const f = expectedResponseJson.find(
      (f) => f.field === fieldName || f.Field === fieldName
    );
    if (f && (f.value || f.Value)) return f.value ?? f.Value;
    return "";
  }

  function getActualFieldValue(row, fieldName) {
    const actualResponseJson = getActualResponseJson(row);

    if (!actualResponseJson || !actualResponseJson.length) return "";
    const f = actualResponseJson.find(
      (f) => f.field === fieldName || f.Field === fieldName
    );
    if (f && (f.value || f.Value)) return f.value ?? f.Value;
    return "";
  }

  function getValueAsString(value) {
    // Always return the value as a string with quotes around it
    return `"${value}"`;
  }

  function valuesAreEqual(value1, value2) {
    // Always compare values as strings
    const v1 = "" + value1;
    const v2 = "" + value2;
    return v1 === v2;
  }

  function shouldHighlightDifference(
    field,
    expectedValue,
    actualValue,
    isExpectedCol
  ) {
    let highlight = false;
    const isParamField = isExpectedCol
      ? expectedValue.startsWith("@")
      : field.startsWith("@");

    // jon, 4/24/24: If this is the FQ message field and we have no expected value, do not highlight as a failed result. This message is for info only.
    if (field === "FQ" && _.isEmpty(expectedValue)) highlight = false;
    else if (isExpectedCol) {
      highlight = !isParamField && actualValue === "" && expectedValue !== "";
    } else {
      highlight = !isParamField && !valuesAreEqual(actualValue, expectedValue);
    }

    return highlight;
  }

  function getHighlightedHtmlFromExpectedJson(row) {
    const expectedResponseJson = getExpectedResponseJson(row);
    const array = expectedResponseJson;
    let result = "";
    let field;
    let valueStr;
    let actualValue;

    for (let i = 0; i < array.length; i++) {
      field = array[i].field ?? array[i].Field;
      valueStr = array[i].value ?? array[i].Value;
      // console.log(`Field: ${field}, Value: ${valueStr}`);

      // Only show highlight if this expected value does not appear in the actual list - otherwise actual will show difference
      actualValue = getActualFieldValue(row, field);

      result += `<span><span class="propname ${
        shouldHighlightDifference(field, valueStr, actualValue, true)
          ? "hl"
          : ""
      }">${field}:</span></span>`;
      result += `<span> <span class="${
        shouldHighlightDifference(field, valueStr, actualValue, true)
          ? "hl"
          : ""
      }">${getValueAsString(valueStr)}</span></span><br />`;
    }

    return result;
  }

  function getHighlightedHtmlFromActualJson(row) {
    const actualResponseJson = getActualResponseJson(row);
    const array = actualResponseJson || [];
    let result = "";
    let field;
    let valueStr;
    let expectedValue;

    for (let i = 0; i < array.length; i++) {
      field = array[i].field ?? array[i].Field;
      valueStr = array[i].value ?? array[i].Value;
      // console.log(`Field: ${field}, Value: ${valueStr}`);

      expectedValue = getExpectedFieldValue(row, field);

      result += `<span><span class="propname ${
        shouldHighlightDifference(field, expectedValue, valueStr, false)
          ? "hl"
          : ""
      }">${field}</span></span>:`;
      result += `<span> <span class="${
        shouldHighlightDifference(field, expectedValue, valueStr, false)
          ? "hl"
          : ""
      }">${getValueAsString(valueStr)}</span></span><br />`;
    }

    return result;
  }

  function handleClickTestLink(row) {
    // Set up the test packet and processed date for the test processor screen
    //  We must convert this date to UTC because it gets converted back to local on the test processor screen.
    const processedDateStr = row.lastProcessedDate;
    const processedDateLocal = new Date(processedDateStr);
    const processedDate = convertLocalDateToUTC(processedDateLocal);

    const expectedFields = getExpectedResultAsJson(row);
    const newRequest = {
      claimId: row.lastClaimId,
      processedDate: formatDateTime(processedDate, true),
      requestContents: row.lastRequestBody,
      responseContents: row.lastResponseBody,
      responseJson: JSON.stringify(expectedFields),
    };

    // Save request in local storage so we can access it in the other tab
    localStorage.setItem("TEST_PROCESSOR_REQUEST", JSON.stringify(newRequest));

    window.open(hrefTestProc, "TestProcessor");
  }

  function getExpectedResultAsJson(row) {
    const expectedResponseJson = getExpectedResponseJson(row);
    const array = expectedResponseJson;
    let result = [];
    let field;
    let valueStr;

    for (let i = 0; i < array.length; i++) {
      field = array[i].field ?? array[i].Field;
      valueStr = array[i].value ?? array[i].Value;

      result.push({
        segmentDescription: "Pricing Segment (Response)",
        segmentCode: "23",
        hasValue: true,
        value: { data: valueStr, metadata: {} },
        fieldId: field,
        valueAsString: valueStr,
        transactionIndex: 0,
      });
    }

    // If we have an old message in the step data, set that as well
    var responseMessage = row.lastResponseMessage;
    if (!_.isEmpty(responseMessage)) {
      result.push({
        segmentDescription: "Pricing Segment (Response)",
        segmentCode: "23",
        hasValue: true,
        value: { data: responseMessage, metadata: {} },
        fieldId: "OldMessage",
        valueAsString: responseMessage,
        transactionIndex: 0,
      });
    }

    return result;
  }

  return (
    <>
      <InfoDialog
        title={"Message"}
        text={messageText}
        showModal={showMessageDialog}
        onClose={() => {
          setMessageText("");
          setShowMessageDialog(false);
        }}
      />
      <TestStepDialog
        isAdmin={isAdmin}
        isReadOnly={true}
        editItem={editItem}
        setEditItem={setEditItem}
        showDialog={showStepDialog}
        onCloseDialog={() => setShowStepDialog(false)}
        onSaveDialog={() => setShowStepDialog(false)}
      />
      <ResponsiveGrid
        gridId="TestSteps"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        dataRows={testSteps.sort((a, b) =>
          a.ordinal > b.ordinal || a.iteration < b.iteration ? 1 : -1
        )}
        disablePaging={true}
        alwaysShowDetailsInMobileView={true}
        columnDefs={[
          {
            name: "ordinal",
            label: "Step",
            noForceWrap: true,
            style: { width: "65px" },
          },
          {
            name: "iteration",
            label: "Iteration",
            noForceWrap: true,
            style: { width: "65px" },
          },
          {
            name: "description",
            label: "Description",
            style: { width: "30%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                title="View this step's settings"
                onClick={(e) => {
                  e.preventDefault();
                  handleViewStep(row);
                }}
              >
                {row.description}
              </button>
            ),
          },
          {
            name: "testStepType",
            label: "Type",
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
          },
          {
            name: "lastRunDate",
            label: "Last Run",
            className: "d-none d-xl-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              row.lastRunDate === ""
                ? ""
                : formatDateTimeUtcZoneForDisplay(row.lastRunDate),
          },
          {
            name: "lastRunDurationMilliseconds",
            label: "Duration",
            className: "d-none d-xl-table-cell",
            style: { width: "10%" },
            getValue: (row) =>
              row.lastRunDate === ""
                ? ""
                : getTimeframeFromMilliseconds(row.lastRunDurationMilliseconds),
          },
          {
            name: "runResultStatus",
            label: "Result",
            noForceWrap: true,
            className: "d-none d-md-table-cell",
            style: { width: "10%" },
            getValue: (row) => (
              <StatusPill
                status={row.runResultStatus}
                pillStyle={{
                  cursor:
                    row.runResultStatus === "Error" ? "pointer" : "default",
                }}
                message={(row.message || "").substring(0, 50)}
                onClick={() => handleShowMessage(row.message)}
              />
            ),
          },
          {
            name: "expectedResponseJson",
            label: "Expected",
            style: { width: "25%" },
            getValue: (row) => (
              <>
                <div>
                  <button
                    type="button"
                    className="btn btn-link link-underline"
                    style={{
                      fontSize: "15px",
                      lineHeight: "15px",
                      marginTop: "-3px",
                    }}
                    onClick={() =>
                      onCollapseExpandJson(row.id, row.iteration, !row.showJson)
                    }
                  >
                    {row.showJson ? "hide" : "show"}
                  </button>
                </div>
                {!row.showJson ? (
                  <></>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    {getExpectedFieldStatus(row)}
                    {getExpectedFieldContents(row)}
                  </div>
                )}
              </>
            ),
          },
          {
            name: "lastResponse",
            label: "Actual",
            style: { width: "25%" },
            getValue: (row) => (
              <>
                <div>
                  <button
                    type="button"
                    className="btn btn-link link-underline"
                    style={{
                      fontSize: "15px",
                      lineHeight: "15px",
                      marginTop: "-3px",
                    }}
                    onClick={() =>
                      onCollapseExpandJson(row.id, row.iteration, !row.showJson)
                    }
                  >
                    {row.showJson ? "hide" : "show"}
                  </button>
                </div>
                {!row.showJson ? (
                  <></>
                ) : (
                  <div style={{ marginTop: "10px" }}>
                    {getActualFieldStatus(row)}
                    {getActualFieldContents(row)}
                  </div>
                )}
              </>
            ),
          },
          {
            name: "test",
            label: "Test",
            style: {
              width: "56px",
              textAlign: "center",
              verticalAlign: "middle",
            },
            getValue: (row) =>
              row.testStepType !== "API" ? (
                <StyledTestLinkButton
                  type="button"
                  className="btn btn-with-icon"
                  onClick={() => handleClickTestLink(row)}
                >
                  <span className="material-icons">play_arrow</span>
                </StyledTestLinkButton>
              ) : (
                <></>
              ),
          },
        ]}
      />
    </>
  );
}

const StyledTestLinkButton = styled.button`
  padding: 0;

  span.material-icons {
    font-size: 30px;
    margin: 0;
    color: var(--link);
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
`;

const StyledDocumentDisplay = styled.span`
  font-family: courier;
  font-size: 14px;
  line-height: 18px;
`;

export default TestStepResultsList;
