import { pageSize } from "../constants/Environments";

export const importModeTypes = [
  {
    label: "Overwrite existing data when keys match",
    value: "overwritechanges",
  },
  // {
  //   label: "Clear table data before importing",
  //   value: "tableoverwrite",
  // },
  {
    label: "Terminate existing data and insert new records",
    value: "terminatechanges",
  },
];

const today = new Date();
today.setHours(0, 0, 0, 0);

export const emptyImport = {
  dataSource: "Claim",
  importMode: "overwritechanges",
  terminationDate: today,
  fileContents: "",
};

export const emptyImportErrorsSearch = {
  active: true,
  highlightText: "",
  pageSize: pageSize || 25,
  pageNumber: 1,
  colPageCount: 1,
  colPageNumber: 1,
  colPageSize: 5,
};

export function getSelectedImportModeFromValue(value) {
  const v = importModeTypes.find((c) => c.value === value);
  if (!v) return importModeTypes[0];

  return v;
}

export function getSelectedDataSourceFromValue(sources, value) {
  const v = (sources || []).find((c) => c.value === value);
  if (!v) return "";

  return v;
}
