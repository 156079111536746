/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadRuleMapForClaim(claimId, token, signal) {
  let uri = API_URL + "/claimrulemap/" + claimId + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

apiLoadRuleMapForClaim.funcName = "apiLoadRuleMapForClaim";
