/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";
import _ from "lodash";
import { format } from "date-fns";

export function apiLoadDiagnostics(search, token, signal) {
  const uri = API_URL + "/diagnostics";
  let sliceSeconds = 600;
  if (
    search &&
    search.sliceSeconds &&
    search.sliceSeconds.length > 0 &&
    _.isInteger(parseInt(search.sliceSeconds))
  )
    sliceSeconds = search.sliceSeconds;

  let startDate = new Date();
  let endDate = new Date();
  startDate.setHours(startDate.getHours() - 1);

  // jon, 8/26/22: strip off milliseconds because GetDate in StringExtensions in the frontend checks for the . and returns null if it sees it. Not used anyway.
  if (search) startDate = format(search.startDate, "yyyy-MM-dd'T'HH:mm:ssxxx");
  if (search) endDate = format(search.endDate, "yyyy-MM-dd'T'HH:mm:ssxxx");
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(
    uri +
      `/${sliceSeconds}/${startDate}/${endDate}` +
      getQueryString() +
      `&page=${page}&pageSize=${pageSize}`,
    {
      method: "GET",
      mode: "cors",
      headers: { ...headersToAdd },
      signal: signal,
    }
  ).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadDiagnostics.funcName = "apiLoadDiagnostics";
