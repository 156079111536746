import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
} from "../../../services/NotificationService";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useAudit } from "../../../contexts/AuditContext";
import { useMobile } from "../../../hooks/useMobile";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowDiv,
  StyledNoResultsDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import {
  createDocumentChangeViewModel,
  emptyAuditDocument,
} from "../../../viewmodels/auditVm";
import {
  apiLoadAuditDocument,
  apiRollbackSingleChange,
} from "../../../api/AuditApi";
import {
  formatDateTimeUtcZoneForDisplay,
  getHighlightedText,
} from "../../../services/General";
import ActionMenu from "../../common/ui/ActionMenu";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import GridActionButton from "../../common/grid/GridActionButton";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import useApi from "../../../hooks/useApi";
import InfoDialog from "../../dialogs/InfoDialog";
import ToggleSwitchInput from "../../common/input/ToggleSwitchInput";
import HelpLink from "../../common/ui/HelpLink";

function AuditRecord() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { auditData, setAuditData } = useAudit();
  const { loading, api: apiLoad } = useApi(apiLoadAuditDocument);
  const { loading: rollingBack, api: apiRollback } = useApi(
    apiRollbackSingleChange
  );
  const { isMobileSize } = useMobile();
  const [loadData, setLoadData] = useState(true);
  const [showRollbackModal, setShowRollbackModal] = useState(false);
  const [rollbackSingleId, setRollbackSingleId] = useState("");
  const [changesDialogTitle, setChangesDialogTitle] = useState("");
  const [changesDialogText, setChangesDialogText] = useState("");
  const [showChangesDialog, setShowChangesDialog] = useState(false);
  const [showChangesOnly, setShowChangesOnly] = useState(false);

  const resId = params && params.id;
  const entityType = params && params.entity;
  const transactionId = params && params.transactionId;

  let changes = [];
  if (
    auditData &&
    auditData.auditDocRecord &&
    auditData.auditDocRecord.transactionHistory
  ) {
    changes = auditData.auditDocRecord.transactionHistory;
  }

  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadAuditRecord();
    }
  }, [auth.authenticated, loadData, params?.id]);

  async function loadAuditRecord() {
    apiLoad.call(
      { entityType, id: resId, transactionId },
      (result) => {
        setLoadData(false);
        let vm = emptyAuditDocument;
        if (!result) {
          notifyError("Audit document does not exist");
        } else {
          vm = createDocumentChangeViewModel(result);
        }
        setAuditData({
          type: ContextProviderActions.loadAuditRecord,
          payload: vm,
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function getPrettyJson(data, changedKeys, propChanges) {
    const result = JSON.stringify(data, null, "    ");
    let highlights = changedKeys.map((key) => `"${key}"`);
    if (_.trim(auditData.auditDocSearch.highlightText) !== "") {
      highlights = [...changedKeys, auditData.auditDocSearch.highlightText];
    }

    let changedKeysResult = getTextWithMarkedHighlightsForArray(
      result,
      highlights || [],
      _.trim(auditData.auditDocSearch.highlightText),
      propChanges || []
    );

    return changedKeysResult;
  }

  function getTextWithMarkedHighlightsForArray(
    textToSearch,
    searchTermsArray,
    hlSearchText,
    propChanges
  ) {
    let hlCount = 0;

    let text =
      textToSearch === null || typeof textToSearch === "undefined"
        ? ""
        : `${textToSearch}`;
    if (text.length === 0) return { count: 0, html: text };

    const highlights = searchTermsArray || [];
    if (highlights.length === 0) return { count: 0, html: text };
    let fullText = text;
    let isFound = false;

    // Split on highlight term and include term into parts, ignore case
    highlights.forEach((highlight) => {
      const parts = fullText.split(new RegExp(`(${highlight}.*)\\n`, "gi"));

      let hlText = "";

      parts.forEach((part) => {
        isFound = part.toLowerCase().indexOf(highlight.toLowerCase()) >= 0;
        if (isFound) {
          hlText += `~@~${part}~@~\n`;
        } else {
          hlText += part;
        }
      });
      fullText = hlText;
    });

    const hlParts = fullText.split(new RegExp(`(~@~.*~@~)`, "gi"));
    isFound = false;
    let isKeyFound = false;

    const html = (
      <span>
        {hlParts.map((part, idx) => {
          isFound =
            _.trim(hlSearchText) !== "" &&
            part.toLowerCase().indexOf(hlSearchText.toLowerCase()) >= 0;
          isKeyFound =
            !isFound &&
            (searchTermsArray || []).find(
              (st) => part.toLowerCase().indexOf(st.toLowerCase()) >= 0
            ) !== undefined;

          if (isFound) hlCount++;
          return (
            <React.Fragment key={`spn-${idx}`}>
              <span className={isKeyFound ? "hl-key" : isFound ? "hl" : ""}>
                {isKeyFound
                  ? getKeyHighlight(part.replace(/~@~/g, ""), propChanges)
                  : (showChangesOnly && isFound) || !showChangesOnly
                  ? part.replace(/~@~/g, "")
                  : ""}
              </span>
              {showChangesOnly && (isKeyFound || isFound) && <br />}
            </React.Fragment>
          );
        })}
      </span>
    );

    return { count: hlCount, html: html };
  }

  function getKeyHighlight(key, propChanges) {
    const _propChanges = propChanges.filter(
      (p) => key.toLowerCase().indexOf(`"${p.objectName.toLowerCase()}"`) >= 0
    );
    if (_propChanges.length === 0) return <>{key}</>;

    return (
      <>
        {key} (
        <button
          className="btn btn-link link-underline"
          style={{ fontSize: "12px" }}
          onClick={() => handleKeyChangesClick(key, _propChanges)}
        >
          {_propChanges.length}{" "}
          {_propChanges.length === 1 ? "change" : "changes"}
        </button>
        )
      </>
    );
  }

  function handleKeyChangesClick(key, propChanges) {
    const text = propChanges.map((p, idx) => (
      <p key={`pc-${idx}`}>
        <strong>{p.propertyName}:</strong>
        <br />
        <span style={{ paddingLeft: "20px" }}>
          {getPropertyChangeString(p.oldValue)}
          &nbsp;&nbsp;=&gt;&nbsp;&nbsp;
          {getPropertyChangeString(p.newValue)}
        </span>
      </p>
    ));
    setChangesDialogTitle(`Changes to ${key}`);
    setChangesDialogText(text);
    setShowChangesDialog(true);
  }

  function getPropertyChangeString(value) {
    let result = value;

    if (result === "") result = "null";
    else if (result === true) result = "true";
    else if (result === false) result = "false";

    return result;
  }

  function collapseExpandJson(changeId, expand) {
    const _doc = { ...auditData.auditDocRecord };
    const _changes = [..._doc.transactionHistory];
    const change = _changes.find((c) => c.transactionId === changeId);

    if (change) change.showJson = expand;
    _doc.transactionHistory = _changes;

    setAuditData({
      type: ContextProviderActions.loadAuditRecord,
      payload: _doc,
    });
  }

  function handleCollapseExpandAll(collapse) {
    const _doc = { ...auditData.auditDocRecord };
    const _changes = [];
    _doc.transactionHistory.forEach((c) =>
      _changes.push({ ...c, showJson: !collapse })
    );
    _doc.transactionHistory = _changes;

    setAuditData({
      type: ContextProviderActions.loadAuditRecord,
      payload: _doc,
    });
  }

  function setAuditDocSearchInput(text) {
    const newSearch = { ...auditData.auditDocSearch };
    newSearch.highlightText = text;

    setAuditData({
      type: ContextProviderActions.saveAuditRecordSearch,
      payload: newSearch,
    });

    // When user is searching, expand all by default
    handleCollapseExpandAll(false);
  }

  async function rollbackChangesSingleId(id) {
    apiRollback.call({ entityType, id: resId, transactionId: id }, (result) => {
      notifySuccess(`Changes rolled back successfully`);
      setRollbackSingleId("");
      setLoadData(true);
    });
  }

  function handleRollbackChange(id) {
    setRollbackSingleId(id);
    setShowRollbackModal(true);
  }

  async function performRollbackChanges() {
    setShowRollbackModal(false);
    await rollbackChangesSingleId(rollbackSingleId);
  }

  function getActionButton(id, index) {
    const actions = [];

    actions.push({
      name:
        index === 0
          ? "Record creation cannot be rolled back"
          : "Rollback this change",
      disabled: index === 0,
      onClick: () => handleRollbackChange(id),
    });

    return <GridActionButton key={id} actions={actions}></GridActionButton>;
  }

  const hl = auditData.auditDocSearch.highlightText;
  let datelHlResult = {};
  let userHlResult = {};
  let jsonHlResult = {};

  return (
    <Authorize>
      <StyledBackButtonDiv>
        <button
          title="Return to previous screen"
          className="btn btn-link"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink path="/Tools/Audit-Record-Screen" label="Help" />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1>
          {`Audit Changes`}{" "}
          {!loading && auditData.auditDocRecord && (
            <>{` | ${auditData.auditDocRecord.entityKey} (${auditData.auditDocRecord.entityType})`}</>
          )}
        </h1>
        <InstantSearchInput
          id="screenSearchInput"
          onChange={setAuditDocSearchInput}
          value={auditData.auditDocSearch.highlightText}
        />
      </StyledHeaderRowDiv>
      <ConfirmDialog
        title="Rollback Change"
        question="Are you sure you want to undo this change?"
        showModal={showRollbackModal}
        onNo={() => setShowRollbackModal(false)}
        onYes={performRollbackChanges}
      />
      <InfoDialog
        title={changesDialogTitle}
        text={changesDialogText}
        showModal={showChangesDialog}
        onClose={() => setShowChangesDialog(false)}
      />

      {loading || loadData || rollingBack ? (
        <Spinner />
      ) : (
        <>
          <StyledHeaderRowDiv style={{ marginTop: "10px" }}>
            <StyledDocumentDisplay>
              <span className="hl-key" style={{ padding: "3px 4px" }}>
                Changed Properties
              </span>
            </StyledDocumentDisplay>
            <div style={{ marginLeft: "20px", marginBottom: "-10px" }}>
              <ToggleSwitchInput
                id="showChangesOnly"
                name="showChangesOnly"
                label="Show changes only"
                onChange={() => setShowChangesOnly(!showChangesOnly)}
                checked={showChangesOnly}
              />
            </div>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(value === "CollapseAll")
              }
            />
          </StyledHeaderRowDiv>
          <div className="container-fluid" style={{ marginTop: "5px" }}>
            {changes.length === 0 ? (
              <StyledNoResultsDiv>
                <p>
                  <i className="material-icons">search_off</i>
                </p>
                <p>No changes found for this document</p>
              </StyledNoResultsDiv>
            ) : (
              <>
                <table className="table" cellSpacing={0} cellPadding={0}>
                  <thead>
                    <tr>
                      <th
                        id="transactionDate"
                        style={{ width: "140px", maxWidth: "20%" }}
                      >
                        Date
                      </th>
                      <th
                        id="userName"
                        style={{ width: "135px", maxWidth: "20%" }}
                        className="d-none d-md-table-cell"
                      >
                        User
                      </th>
                      <th id="doc" style={{ width: "70%" }}>
                        Document
                      </th>
                      <th
                        style={{
                          width: "60px",
                          maxWidth: "60px",
                          textAlign: "center",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {changes.map((change, idx) => {
                      datelHlResult = getHighlightedText(
                        formatDateTimeUtcZoneForDisplay(change.transactionDate),
                        hl
                      );
                      userHlResult = getHighlightedText(
                        change.transactionUser,
                        hl
                      );
                      jsonHlResult = getPrettyJson(
                        change.model,
                        change.propertiesChanged,
                        change.detailedPropertiesChanged
                      );

                      return (
                        <Fragment key={idx}>
                          {_.trim(hl).length > 0 &&
                          datelHlResult.count === 0 &&
                          userHlResult.count === 0 &&
                          jsonHlResult.count === 0 ? (
                            <></>
                          ) : (
                            <>
                              <tr key={"tr0" + idx}>
                                <td>{datelHlResult.html}</td>
                                <td className="d-none d-md-table-cell">
                                  {userHlResult.html}
                                </td>
                                <td className="force-wrap">
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-link link-underline"
                                      style={{
                                        fontSize: "15px",
                                        lineHeight: "15px",
                                        marginTop: "-3px",
                                      }}
                                      onClick={() =>
                                        collapseExpandJson(
                                          change.transactionId,
                                          !change.showJson
                                        )
                                      }
                                    >
                                      {change.showJson ? "hide" : "show"}
                                    </button>
                                  </div>
                                  {isMobileSize || !change.showJson ? (
                                    <></>
                                  ) : (
                                    <div style={{ marginTop: "10px" }}>
                                      {idx === 0 && (
                                        <>
                                          <StyledDocumentDisplay>
                                            <span className="hl-key">
                                              Record Created
                                            </span>
                                          </StyledDocumentDisplay>
                                        </>
                                      )}
                                      <StyledDocumentDisplay>
                                        {jsonHlResult.html}
                                      </StyledDocumentDisplay>
                                    </div>
                                  )}
                                </td>
                                <td className="table-action-btn">
                                  {getActionButton(change.transactionId, idx)}
                                </td>
                              </tr>
                              {isMobileSize && change.showJson && (
                                <StyledMobileMessageTableRow key={"tr1" + idx}>
                                  <td colSpan="4" className="force-wrap">
                                    <div style={{ marginTop: "10px" }}>
                                      <StyledDocumentDisplay>
                                        {jsonHlResult.html}
                                      </StyledDocumentDisplay>
                                    </div>
                                  </td>
                                </StyledMobileMessageTableRow>
                              )}
                            </>
                          )}
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </>
      )}
    </Authorize>
  );
}

const StyledDocumentDisplay = styled.div`
  font-family: courier;
  font-size: 14px;
  line-height: 18px;
`;

const StyledMobileMessageTableRow = styled.tr`
  background-color: transparent;

  td {
    margin-left: 40px;
  }
`;

export default AuditRecord;
