import React from "react";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function PriorAuthorizationsList({
  priorAuthorizations,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const priorAuthorizationNumber = dataRow.priorAuthorizationNumber;

    const actions = [
      {
        name: "View Details",
        onClick: () => navigate("/priorauthorization/" + id),
      },
      {
        name: "Remove",
        onClick: () => onDelete(id, priorAuthorizationNumber),
      },
    ];

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () =>
          navigate("/auditrecord/claimpriorauthorization/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="PriorAuthorizations"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={priorAuthorizations}
        columnDefs={[
          {
            name: "priorAuthorizationNumber",
            label: "PA",
            style: { width: "40%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/priorauthorization/" + row.id)}
              >
                {row.priorAuthorizationNumber}
              </button>
            ),
          },
          {
            name: "serviceProviderId",
            label: "Pharmacy",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
            getValue: (row) => row.pharmacy,
          },
          {
            name: "prescriptionNumber",
            label: "Rx",
            className: "d-none d-md-table-cell",
            style: { width: "25%" },
            getValue: (row) => row.prescriptionNumber,
          },
          {
            name: "groupId",
            label: "Group",
            className: "d-none d-xl-table-cell",
            style: { width: "20%" },
            getValue: (row) => row.groupId,
          },
          {
            name: "excludedRejections",
            label: "Overrides",
            style: { width: "30%" },
            getValue: (row) => row.authorizedRejectionsVm,
          },
          {
            name: "usages",
            label: "Usages",
            className: "d-none d-xl-table-cell",
            style: { width: "15%" },
            getValue: (row) => row.usages,
          },
          {
            name: "lastModifiedDate",
            label: "Created/Modified",
            className: "d-none d-xl-table-cell",
            style: { width: "20%" },
            getValue: (row) =>
              formatDateTimeUtcZoneForDisplay(row.lastModifiedDate),
          },
          {
            name: "active",
            label: "Active",
            className: "d-none d-xl-table-cell",
            style: { width: "6%" },
            getValue: (row) =>
              row.active ? <span className="material-icons">check</span> : "",
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default PriorAuthorizationsList;
