/* eslint-disable no-undef */
import { formatDateTime } from "../services/General";
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadTests(search, token, signal) {
  const uri = API_URL + "/tests" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "id", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "description",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "lastRunBy",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "lastRunResultStatus",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.description && search.description.length > 0)
    where.push({
      name: "description",
      operator: "=",
      values: [search.description],
    });
  if (search && search.lastRunBy && search.lastRunBy.length > 0)
    where.push({
      name: "lastRunBy",
      operator: "=",
      values: [search.lastRunBy],
    });

  if (search && search.includeTestsInGroup === false) {
    where.push({
      name: "testGroupId",
      operator: "isempty",
    });
  }

  if (search && search.showOnlyAutoPopulatedTests === true) {
    where.push({
      name: "isDataAutoPopulated",
      operator: "true",
    });
  }

  if (
    search &&
    search.startDate &&
    _.isDate(search.startDate) &&
    !formatDateTime(search.startDate).startsWith("1/01/2000")
  ) {
    where.push({
      name: "lastRunDate",
      operator: ">=",
      values: [search.startDate],
    });
  }
  if (
    search &&
    search.endDate &&
    _.isDate(search.endDate) &&
    !formatDateTime(search.endDate).startsWith("1/01/3000")
  ) {
    where.push({
      name: "lastRunDate",
      operator: "<",
      values: [search.endDate],
    });
  }

  // Search by Run Status support
  if (
    search &&
    search.lastRunResultStatus &&
    search.lastRunResultStatus.length > 0
  ) {
    search.lastRunResultStatus.forEach((status, i) => {
      where.push({
        operand: "OR",
        name: "lastRunResultStatus",
        operator: "=",
        values: [status.value],
      });
    });
  }

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadTest(testId, token, signal) {
  let uri = API_URL + "/test/" + testId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiGetTestDataForDownload(params, token, signal) {
  const id = params.id;

  let uri = API_URL + "/testdataexport/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadTestSteps(testId, token, signal) {
  let uri = API_URL + "/teststeps/" + testId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiGetAvailableTests(dummy, token, signal) {
  let uri = API_URL + "/testsavailablefortestgroups" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadTestStep(testStepId, token, signal) {
  let uri = API_URL + "/teststep/" + testStepId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadTestGroups(search, token, signal) {
  const uri = API_URL + "/testgroups" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "id", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "description",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "lastRunBy",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "lastRunResultStatus",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.description && search.description.length > 0)
    where.push({
      name: "description",
      operator: "=",
      values: [search.description],
    });
  if (search && search.lastRunBy && search.lastRunBy.length > 0)
    where.push({
      name: "lastRunBy",
      operator: "=",
      values: [search.lastRunBy],
    });

  if (
    search &&
    search.startDate &&
    _.isDate(search.startDate) &&
    !formatDateTime(search.startDate).startsWith("1/01/2000")
  ) {
    where.push({
      name: "lastRunDate",
      operator: ">=",
      values: [search.startDate],
    });
  }
  if (
    search &&
    search.endDate &&
    _.isDate(search.endDate) &&
    !formatDateTime(search.endDate).startsWith("1/01/3000")
  ) {
    where.push({
      name: "lastRunDate",
      operator: "<",
      values: [search.endDate],
    });
  }

  // Search by Run Status support
  if (
    search &&
    search.lastRunResultStatus &&
    search.lastRunResultStatus.length > 0
  ) {
    search.lastRunResultStatus.forEach((status, i) => {
      where.push({
        operand: "OR",
        name: "lastRunResultStatus",
        operator: "=",
        values: [status.value],
      });
    });
  }

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadTestGroup(testGroupId, token, signal) {
  let uri = API_URL + "/testgroup/" + testGroupId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadTestGroupTests(testGroupId, token, signal) {
  let uri = API_URL + "/tests/" + testGroupId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadTestRunResults(search, token, signal) {
  const uri = API_URL + "/testresults" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({ name: "id", operator: "notnull" });
    where[where.length - 1].subQueryItems = [];
    where[where.length - 1].subQueryItems.push({
      name: "entityDescription",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "runBy",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where[where.length - 1].subQueryItems.push({
      operand: "OR",
      name: "runResultStatus",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.description && search.description.length > 0)
    where.push({
      name: "entityDescription",
      operator: "=",
      values: [search.description],
    });

  if (search && search.runBy && search.runBy.length > 0)
    where.push({
      name: "runBy",
      operator: "=",
      values: [search.runBy],
    });

  if (search && search.startDate && _.isDate(search.startDate))
    where.push({
      name: "runDate",
      operator: ">=",
      values: [search.startDate],
    });
  if (search && search.endDate && _.isDate(search.endDate))
    where.push({
      name: "runDate",
      operator: "<",
      values: [search.endDate],
    });

  // Search by Result Type support
  if (search && search.resultType && search.resultType.length > 0) {
    search.resultType.forEach((status, i) => {
      where.push({
        operand: "OR",
        name: "resultType",
        operator: "=",
        values: [status.value],
      });
    });
  }

  // Search by Run Status support
  if (search && search.runResultStatus && search.runResultStatus.length > 0) {
    search.runResultStatus.forEach((status, i) => {
      where.push({
        operand: "OR",
        name: "runResultStatus",
        operator: "=",
        values: [status.value],
      });
    });
  }

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadTestGroupResults(params, token, signal) {
  const testGroupId = params.testGroupId;
  const testResultId = params.testResultId;

  let uri =
    API_URL +
    "/testgroupwithresults/" +
    testGroupId +
    "/" +
    testResultId +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadSingleTestResults(params, token, signal) {
  const testId = params.testId;
  const testResultId = params.testResultId;

  let uri =
    API_URL +
    "/testwithresults/" +
    testId +
    "/" +
    testResultId +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddTest(m, token, signal) {
  let uri = API_URL + "/test" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateTest(params, token, signal) {
  const id = params.id;
  const m = params.model;

  let uri = API_URL + "/test/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteTest(id, token, signal) {
  let uri = API_URL + "/test/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCopyTest(params, token, signal) {
  const id = params.id;
  const newId = params.newId;

  let uri =
    API_URL +
    "/copytest/" +
    id +
    "/" +
    encodeURIComponent(newId) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddTestGroup(m, token, signal) {
  let uri = API_URL + "/testgroup" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateTestGroup(params, token, signal) {
  const id = params.id;
  const m = params.model;

  let uri = API_URL + "/testgroup/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteTestGroup(id, token, signal) {
  let uri = API_URL + "/testgroup/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCopyTestGroup(params, token, signal) {
  const id = params.id;
  const newId = params.newId;

  let uri =
    API_URL +
    "/copytestgroup/" +
    id +
    "/" +
    encodeURIComponent(newId) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddTestStep(m, token, signal) {
  let uri = API_URL + "/teststep" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateTestStep(params, token, signal) {
  const id = params.id;
  const m = params.model;

  let uri = API_URL + "/teststep/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteTestStep(id, token, signal) {
  let uri = API_URL + "/teststep/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCopyTestStep(params, token, signal) {
  const id = params.id;
  const newId = params.newId;

  let uri =
    API_URL +
    "/copyteststep/" +
    encodeURIComponent(id) +
    "/" +
    encodeURIComponent(newId) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiStartRunForTestOrGroup(params, token, signal) {
  const model = params.model;

  let uri = API_URL + "/testrunforlist/" + getQueryString();

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCancelRunForTestOrGroup(params, token, signal) {
  const model = params.model;

  let uri = API_URL + "/testcancelrunforlist/" + getQueryString();

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRunTest(params, token, signal) {
  const testId = params.testId;

  let uri = API_URL + "/testrun/" + testId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  headersToAdd["x-no-logs"] = "true";

  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRunTestGroup(params, token, signal) {
  const testGroupId = params.testGroupId;

  let uri = API_URL + "/testgrouprun/" + testGroupId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateTestGroupResult(params, token, signal) {
  const testGroupId = params.testGroupId;
  const uniqueId = params.uniqueId;

  let uri =
    API_URL +
    "/testgroupresult/" +
    testGroupId +
    "/" +
    uniqueId +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiGenerateTestData(params, token, signal) {
  const test = params.model;

  let uri = API_URL + "/testgeneratedata" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(test),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiGenerateTestDataForList(params, token, signal) {
  const tests = params.model;

  let uri = API_URL + "/testgeneratedataforlist" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(tests),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCancelGenerateTestDataForList(params, token, signal) {
  const tests = params.model;

  let uri = API_URL + "/testcancelgeneratedataforlist" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(tests),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadTests.funcName = "apiLoadTests";
apiLoadTest.funcName = "apiLoadTest";
apiLoadTestSteps.funcName = "apiLoadTestSteps";
apiGetAvailableTests.funcName = "apiGetAvailableTests";
apiLoadTestStep.funcName = "apiLoadTestStep";
apiLoadTestGroups.funcName = "apiLoadTestGroups";
apiLoadTestGroup.funcName = "apiLoadTestGroup";
apiLoadTestGroupTests.funcName = "apiLoadTestGroupTests";
apiLoadTestRunResults.funcName = "apiLoadTestRunResults";
apiLoadTestGroupResults.funcName = "apiLoadTestGroupResults";
apiLoadSingleTestResults.funcName = "apiLoadSingleTestResults";
apiAddTest.funcName = "apiAddTest";
apiUpdateTest.funcName = "apiUpdateTest";
apiDeleteTest.funcName = "apiDeleteTest";
apiCopyTest.funcName = "apiCopyTest";
apiAddTestGroup.funcName = "apiAddTestGroup";
apiUpdateTestGroup.funcName = "apiUpdateTestGroup";
apiDeleteTestGroup.funcName = "apiDeleteTestGroup";
apiCopyTestGroup.funcName = "apiCopyTestGroup";
apiAddTestStep.funcName = "apiAddTestStep";
apiUpdateTestStep.funcName = "apiUpdateTestStep";
apiDeleteTestStep.funcName = "apiDeleteTestStep";
apiCopyTestStep.funcName = "apiCopyTestStep";
apiStartRunForTestOrGroup.funcName = "apiStartRunForTestOrGroup";
apiCancelRunForTestOrGroup.funcName = "apiCancelRunForTestOrGroup";
apiRunTest.funcName = "apiRunTest";
apiRunTestGroup.funcName = "apiRunTestGroup";
apiUpdateTestGroupResult.funcName = "apiUpdateTestGroupResult";
apiGenerateTestData.funcName = "apiGenerateTestData";
apiGenerateTestDataForList.funcName = "apiGenerateTestDataForList";
apiCancelGenerateTestDataForList.funcName = "apiCancelGenerateTestDataForList";
