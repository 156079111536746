import React from "react";
import _ from "lodash";
import { StyledStepHeaderDiv } from "../../../common/layout/CommonStyledWizardComponents";

function PanelGrouping({ title = "", showPanel = true, children }) {
  if (!showPanel) {
    return <></>;
  }

  return (
    <>
      {!_.isEmpty(title) && (
        <>
          <br />
          <br />
          <StyledStepHeaderDiv>{title}</StyledStepHeaderDiv>
        </>
      )}
      {children}
    </>
  );
}

export default PanelGrouping;
