import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
} from "../../common/layout/CommonStyledControls";
import StatusPill from "../../common/ui/StatusPill";

function DbLegacyDbSyncContinuousType({
  type,
  isRunning,
  isError,
  lastMessage,
  lastDate,
  messages,
  showAllMessages,
}) {
  const [showMessages, setShowMessages] = useState(showAllMessages);

  useEffect(() => {
    if (showAllMessages && !showMessages) {
      setShowMessages(true);
    } else if (!showAllMessages && showMessages) {
      setShowMessages(false);
    }
  }, [showAllMessages]);

  function handleClickShowMessages(event) {
    if (event) event.preventDefault();
    setShowMessages(!showMessages);
  }

  return (
    <StyledTypeContainer
      className={`col-12 col-lg-6 ${isRunning ? "running" : "not-running"}`}
    >
      <StyledHeaderRowDiv>
        <h3>{type}</h3>
        <StyledHeaderRowButtonDiv>
          {isRunning ? (
            <i className="material-symbols-outlined rotating">rotate_right</i>
          ) : (
            <StatusPill status={isError ? "Fail" : "Success"} />
          )}
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {isRunning || isError ? (
          <p>{lastMessage}</p>
        ) : (
          <p>
            {lastDate === null ? (
              <i>Never run</i>
            ) : (
              formatDateTimeUtcZoneForDisplay(lastDate)
            )}
          </p>
        )}
        <StyledMessageLinkDiv>
          <button
            className="btn btn-link btn-with-icon"
            onClick={handleClickShowMessages}
          >
            Latest Messages ({(messages || []).length}){"  "}
            <span className="material-symbols-outlined">
              {showMessages ? "expand_more" : "chevron_right"}
            </span>
          </button>
        </StyledMessageLinkDiv>
      </div>
      {showMessages && (
        <StyledMessageContainerDiv>
          {(messages || []).length === 0 && <i>No recent messages</i>}
          {(messages || []).map((msg, mIdx) => (
            <p key={`msg-${mIdx}`}>
              <span>
                {formatDateTimeUtcZoneForDisplay(msg.syncDate)}&nbsp;&nbsp;
              </span>
              {msg.message}
            </p>
          ))}
        </StyledMessageContainerDiv>
      )}
    </StyledTypeContainer>
  );
}

const StyledTypeContainer = styled.div`
  padding: 20px;

  &.not-running {
    border: 1px solid var(--elevated-border);
    background-color: inherit;
    transition: all 500ms ease-in-out;
  }

  &.running {
    border: 1px solid var(--selected-border);
    background-color: var(--selected-bg);
    transition: all 500ms ease-in-out;
  }

  h3 {
    font-size: 18px;
  }

  p {
    margin-bottom: 3px;
  }
`;

const StyledMessageLinkDiv = styled.div`
  margin-right: -15px;

  button {
    font-size: 14px;
  }
`;

const StyledMessageContainerDiv = styled.div`
  margin-top: 20px;
  min-height: 30px;
  max-height: 150px;
  overflow-y: auto;
  border-left: 2px solid var(--text-dark);
  padding-left: 10px;

  p {
    font-size: 14px;
    color: var(--text-dark);

    span {
      color: var(--text-medium);
      display: inline-block;
      width: 150px;
      font-size: 13px;
      font-family: "Courier New", Courier, monospace;
    }
  }
`;

export default DbLegacyDbSyncContinuousType;
