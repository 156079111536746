import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyClient, emptyClientSearch } from "../viewmodels/clientsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadClients:
      return {
        ...state,
        clients: action.payload.clients,
        count: action.payload.count,
      };

    case ContextProviderActions.saveClientSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadClient:
      return {
        ...state,
        client: action.payload,
      };

    case ContextProviderActions.setClientListCache:
      return {
        ...state,
        clientListCache: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in ClientsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  clients: [],
  client: emptyClient,
  search: emptyClientSearch,
  count: 0,
  clientListCache: [],
};

const Clients = createContext({
  clientsData: initialState,
  setClientsData: () => null,
});

// eslint-disable-next-line react/prop-types
const ClientsProvider = ({ children }) => {
  const [clientsData, setClientsData] = useReducer(reducer, initialState);

  return (
    <Clients.Provider value={{ clientsData, setClientsData }}>
      {children}
    </Clients.Provider>
  );
};

export default ClientsProvider;
export const useClients = () => useContext(Clients);
