import _ from "lodash";

// Calculate today's date at midnight for effective date
const minDate = new Date("01/01/2000");
minDate.setHours(0, 0, 0, 0);
const maxDate = new Date("01/01/3000");
maxDate.setHours(0, 0, 0, 0);

export const emptyLegacyDbSync = {
  id: "",
  remoteNewProcessorAddress: "",
  remoteNewProcessorPort: 0,
  numberOfClientsToEmulate: 1,
  sourceConnectionString: "",
  sourceCoreConnectionString: "",
  destConnectionString: "",
  destDatabaseId: "",
  destEnvironment: "",
  pollFrequencySeconds: 5,
  bulkRecordPageSize: 25,
  bulkRecordWaitBetweenPages: 300,
  active: true,
  typesToSync: [], // emptyLegacyDbSyncType
};

export const emptyLegacyDbSyncType = {
  type: "",
  startDate: minDate,
  endDate: maxDate,
  continuous: false,
  continuousTestMode: false,
  bulkRecordPageSize: 25,
  bulkRecordWaitBetweenPages: 300,
  active: true,
};

export const emptyLegacyDbSyncStatus = {
  typesSynced: [], // emptyLegacyDbSyncTypeStatus
};

export const emptyLegacyDbSyncTypeStatus = {
  type: "",
  isRunInProgress: false,
  inProgressRecordsProcessed: 0,
  totalRecordsToProcess: 0,
  totalRecordsProcessed: 0,
  totalManualRecords: 0,
  lastUpdateMessage: "",
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  return vm;
}
