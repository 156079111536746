import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyDiagnosticsSearch } from "../viewmodels/diagnosticsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadDiagnostics:
      return {
        ...state,
        apiBuildVersion: action.payload.apiBuildVersion,
        apiEnv: action.payload.apiEnv,
        diagnostics: action.payload.diagnostics,
        count: action.payload.count,
      };

    case ContextProviderActions.saveDiagnosticSearch:
      return {
        ...state,
        search: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in DiagnosticsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  apiBuildVersion: "",
  apiEnv: "",
  diagnostics: [],
  search: emptyDiagnosticsSearch,
  count: 0,
};

const Diagnostics = createContext({
  diagnosticData: initialState,
  setDiagnosticData: () => null,
});

// eslint-disable-next-line react/prop-types
const DiagnosticsProvider = ({ children }) => {
  const [diagnosticData, setDiagnosticData] = useReducer(reducer, initialState);

  return (
    <Diagnostics.Provider value={{ diagnosticData, setDiagnosticData }}>
      {children}
    </Diagnostics.Provider>
  );
};

export default DiagnosticsProvider;
export const useDiagnostics = () => useContext(Diagnostics);
