import { generateUUID } from "../../../services/General";

export const MAX_INDENT_LEVEL = 100;

export const NodeType = {
  Container: 1,
  Node: 2,
};

export function getEmptyContainerNode(operand, indentLevel, parent) {
  const node = {
    id: generateUUID(),
    type: NodeType.Container,
    operand: operand,
    indentLevel: indentLevel,
    children: [],
    parent: parent,
  };
  return node;
}

function getConditionTreeNode(parentNode, node, operand) {
  let newNode = {
    ...node,
    operand: operand,
    type: NodeType.Node,
    children: [],
    parent: parentNode,
  };
  return newNode;
}

export function buildConditionTree(
  stack,
  parentNode,
  indentLevel,
  operandMode
) {
  // Is the stack empty? If so, we are done!
  if ((stack || []).length === 0) return;

  // Get the top item off the stack to process
  // const _stack = [...stack];
  const node = stack.pop();

  // Operand at this level is determined from the indent level and operand type - AndOrAnd or OrAndOr
  const divByTwo = indentLevel % 2 === 0;
  let operand = "And";
  if (
    (operandMode === "And" && !divByTwo) ||
    (operandMode === "Or" && divByTwo)
  ) {
    operand = "Or";
  }

  // If we are at the level indicated by the node, add it to the tree and continue bulding any more at this level.
  if (node.indentLevel === indentLevel) {
    // If we aren't starting a new group, just add the node. Or if there is no grandparent to which to add a new container.
    if (
      !node.groupBreak ||
      node.groupBreakHandled ||
      parentNode.parent === null
    ) {
      const newNode = getConditionTreeNode(parentNode, node, operand);
      parentNode.children.push(newNode);
      buildConditionTree(stack, parentNode, node.indentLevel, operandMode);
    } else {
      // Add a new group here by adding a new container child to the parent - leaf nodes always have a parent
      const newContainer = getEmptyContainerNode(
        parentNode.operand,
        parentNode.indentLevel,
        parentNode.parent
      );
      parentNode.parent.children.push(newContainer);
      // Put this node back on the stack for processing in next call but turn group break off so we don't keep adding new containers!
      stack.push({ ...node, groupBreakHandled: true });
      buildConditionTree(stack, newContainer, indentLevel, operandMode);
    }
  }
  // If the node we are trying to add is at a higher level than where we are, add a container node and continue on to the next level
  //   with this container as parent.
  else if (node.indentLevel > indentLevel) {
    // Flip the operand of this container
    const newContainer = getEmptyContainerNode(
      operand === "And" ? "Or" : "And",
      indentLevel,
      parentNode
    );
    parentNode.children.push(newContainer);
    // Put this node back on the stack for processing in next call
    stack.push(node);
    buildConditionTree(stack, newContainer, indentLevel + 1, operandMode);
  }
  // If this condition is on a lower level, back up to that last parent at that level and start building tree again
  else if (node.indentLevel < indentLevel) {
    if (parentNode.parent !== null) {
      // Put this node back on the stack for processing in next call
      stack.push(node);
      buildConditionTree(
        stack,
        parentNode.parent,
        indentLevel - 1,
        operandMode
      );
    } else {
      console.log(
        "WARNING: prevParent is unexpectedly null in buildConditionTree"
      );
    }
  } else {
    console.log(
      "WARNING: This should never happen! Should not be possible to get here."
    );
  }
}
