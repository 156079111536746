import React, { useState } from "react";
import styled from "styled-components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { StyledBlockLetter } from "../layout/CommonStyledControls";

export const ChipMode = {
  View: 1,
  Readonly: 2,
  Edit: 3,
};

function DragChip({
  id,
  index,
  label,
  chipMode,
  onClickRemove,
  onClickEdit,
  showSortDirection,
  sortDirection,
  isCustomField = false,
  icon = <></>,
}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });
  const [removing, setRemoving] = useState(false);

  // Remove scaling because it was warping the chips in a weird way.
  const _transform = { ...transform, scaleX: 1, scaleY: 1 };
  const style = {
    transform: CSS.Transform.toString(_transform),
    transition,
    opacity: isDragging ? ".5" : 1,
  };

  function onRemove() {
    // Allow user a small amount of time to see the chip disappear and the others slide over before actually removing.
    setRemoving(true);
    window.setTimeout(() => {
      onClickRemove(id);
      setRemoving(false);
    }, 500);
  }

  return (
    <>
      {chipMode === ChipMode.Readonly ? (
        <StyledReadonlyChip name={id}>
          <p>
            {isCustomField && (
              <StyledBlockLetter style={{ marginRight: "3px" }}>
                C
              </StyledBlockLetter>
            )}
            {label}
          </p>
          <div>{icon}</div>
          {showSortDirection && (
            <div>
              {sortDirection === "Ascending" ? (
                <i className="fa fa-sort-up" />
              ) : (
                <i className="fa fa-sort-down" />
              )}
            </div>
          )}
        </StyledReadonlyChip>
      ) : (
        <div ref={setNodeRef} style={style} name={id}>
          <StyledDraggableChip name={id} className={removing ? "removing" : ""}>
            <span className="material-icons" {...attributes} {...listeners}>
              drag_indicator
            </span>
            <p>
              {isCustomField && (
                <StyledBlockLetter style={{ marginRight: "3px" }}>
                  C
                </StyledBlockLetter>
              )}
              {label}
            </p>
            <div>{icon}</div>
            {showSortDirection && (
              <div>
                {sortDirection === "Ascending" ? (
                  <i className="fa fa-sort-up" />
                ) : (
                  <i className="fa fa-sort-down" />
                )}
              </div>
            )}
            <div
              className="flex-row-with-wrap"
              style={{
                justifyContent: "flex-end",
                position: "absolute",
                right: "9px",
                width: "80px",
              }}
            >
              <span
                className="material-icons icon-with-hover pointer"
                onClick={() => onClickEdit(id)}
              >
                edit
              </span>
              <span
                className="material-icons icon-with-hover pointer"
                style={{ marginLeft: "12px", fontSize: "18px" }}
                onClick={onRemove}
              >
                close
              </span>
            </div>
          </StyledDraggableChip>
        </div>
      )}
    </>
  );
}

const StyledReadonlyChip = styled.div`
  height: 36px;
  padding: 10px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 18px;
  display: flex;
  align-items: center;
  column-gap: 5px;

  > p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 16px;
    color: var(--text-disabled);

    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 14px;
    }
  }

  i {
    font-size: 20px;
    margin-left: 8px;
    color: var(--text-disabled);
  }
`;

const StyledDraggableChip = styled.div`
  height: 36px;
  padding: 10px 50px 10px 10px;
  margin-right: 30px !important;
  background: var(--drag-chip-bg);
  border: 1px solid var(--drag-chip-border);
  border-radius: 18px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  touch-action: none;
  transition: all 0.5s ease-in-out;

    &.removing {
      opacity: 0;
    }    
  }

  span[class="material-icons"] {
    font-size: 20px;
    line-height: 20px;
    color: var(--drag-chip-border);

    &:hover {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

  span[class="material-icons icon-subtle"] {
    color: var(--drag-chip-placeholder-text);
  }

  p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 16px;
    color: var(--drag-chip-text);

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 14px;
      }
  }

  i {
    font-size: 20px;
    margin-left: 8px;
  }
`;

export default DragChip;
