import _ from "lodash";
import { pageSize } from "../constants/Environments";
import {
  ruleTableDefinitionSecurityTypes,
  ruleTableDefinitionColumnValueTypes,
  ruleTableDefinitionColumnControlTypes,
  ruleTableDefinitionColumnListSourceTypes,
} from "../services/General";

export const emptyRuleTableDefinition = {
  id: "",
  name: "",
  description: "",
  securityLevel: { value: "Public", label: "Public" }, // see ruleTableDefinitionSecurityTypes in General.js
  securityLevelVm: "",
  associatedRuleId: "",
  columns: [],
  newRuleTableDefinitionName: "",
  oldRuleTableDefinitionName: "",
  usedOnRules: [], // TODO: update to name used in api when added
};

export const emptyRuleTableDefinitionColumn = {
  id: "",
  name: "",
  valueType: { value: "Text", label: "Text" }, // see ruleTableDefinitionColumnValueTypes in General.js
  valueTypeVm: "",
  isKey: false,
  controlType: { value: "Input", label: "Input" }, // see ruleTableDefinitionColumnControlTypes in General.js
  controlTypeVm: "",
  listSource: { label: "List of values", value: "ValidValues" }, // see ruleTableDefinitionColumnListSourceTypes in General.js
  listSourceVm: "",
  ordinal: 0,
  maxLength: "",
  minValue: "",
  maxValue: "",
  decimalPlaces: "",
  validationExpression: "",
  validValues: [],
  validValuesVm: "",
  ruleTableDefinitionId: "",
  ruleTableDefinitionColumnId: "",
};

export const emptyRuleTableDefinitionSearch = {
  name: "",
  description: "",
  securityLevel: [],
  orderBy: ["name+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
  freeFormSearch: "",
  showAdvancedFilter: false,
};

export const emptyRuleTableDefinitionRuleTableSearch = {
  orderBy: ["key+"],
  pageSize: pageSize || 25,
  pageNumber: 1,
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  // Dropdowns
  vm.securityLevel = (vm.securityLevelVm || { value: "" }).value;

  // Columns
  const _cols = [];
  vm.columns.forEach((col) => {
    // Column dropdown values
    col.valueType =
      col.valueTypeVm === ""
        ? col.valueType.value
        : (col.valueTypeVm || { value: "" }).value;
    col.controlType =
      col.controlTypeVm === ""
        ? col.controlType.value
        : (col.controlTypeVm || { value: "" }).value;
    col.listSource =
      col.listSourceVm === ""
        ? col.listSource.value
        : (col.listSourceVm || { value: "" }).value;

    // Convert valid values from comma-separated to an array of strings
    col.validValues =
      _.trim(col.validValuesVm) === "" ? "" : col.validValuesVm.split(",");

    // Trim each valid value to remove any spaces after commas
    if (col.validValuesVm !== "" && (col.validValues || []).length > 0) {
      const vv = [];
      col.validValues.forEach((v) => vv.push(_.trim(v)));
      col.validValues = vv;
    }

    // Convert empty numbers to null for saving
    col.maxLength = col.maxLength === "" ? null : col.maxLength;
    col.minValue = col.minValue === "" ? null : col.minValue;
    col.maxValue = col.maxValue === "" ? null : col.maxValue;
    col.decimalPlaces = col.decimalPlaces === "" ? null : col.decimalPlaces;

    _cols.push(col);
  });
  vm.columns = _cols;

  // On save, we want to reorder the columns so that all keys are at the top, but otherwise retain the user's order.
  let keyCols = vm.columns.filter((c) => c.isKey === true);
  let otherCols = vm.columns.filter((c) => c.isKey !== true);

  // Sort each array by their user-defined ordinals
  keyCols = keyCols.map((c, i) => {
    return { ...c, ordinal: i };
  });
  otherCols = otherCols.map((c, i) => {
    return { ...c, ordinal: i + keyCols.length };
  });

  // Concatenate the two arrays back together
  vm.columns = [...keyCols, ...otherCols];

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  // Dropdown values
  vm.securityLevelVm =
    (ruleTableDefinitionSecurityTypes || []).find(
      (c) => c.value === vm.securityLevel
    ) || emptyRuleTableDefinition.securityLevel;

  // Columns
  const _cols = [];
  vm.columns.forEach((col) => {
    const _col = { ...emptyRuleTableDefinitionColumn, ...col };

    // Column dropdown values
    _col.valueTypeVm =
      (ruleTableDefinitionColumnValueTypes || []).find(
        (c) => c.value === _col.valueType
      ) || emptyRuleTableDefinitionColumn.valueType;

    _col.controlTypeVm =
      (ruleTableDefinitionColumnControlTypes || []).find(
        (c) => c.value === _col.controlType
      ) || emptyRuleTableDefinitionColumn.controlType;

    _col.listSourceVm =
      (ruleTableDefinitionColumnListSourceTypes || []).find(
        (c) => c.value === _col.listSource
      ) || emptyRuleTableDefinitionColumn.listSource;

    // Convert valid values from a string array to comma-separated
    _col.validValuesVm =
      (_col.validValues || []).length === 0 ? "" : _col.validValues.join(",");

    // Convert null numbers to empty string for display
    _col.maxLength = _col.maxLength === null ? "" : _col.maxLength;
    _col.minValue = _col.minValue === null ? "" : _col.minValue;
    _col.maxValue = _col.maxValue === null ? "" : _col.maxValue;
    _col.decimalPlaces = _col.decimalPlaces === null ? "" : _col.decimalPlaces;

    _cols.push(_col);
  });
  vm.columns = _cols;

  return vm;
}

export function getDropDownValue(model, name) {
  const value =
    model[`${name}Vm`] === ""
      ? model[`${name}`].value
      : model[`${name}Vm`].value;
  return value;
}
