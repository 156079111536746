import React, { useState } from "react";
import styled from "styled-components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { getDropDownValue } from "../../../viewmodels/ruleTableDefinitionsVm";
import { StyledKeyIndicator } from "../../common/layout/CommonStyledControls";

function DragDropRuleTableDefinitionColumn({
  id,
  column,
  definitionsList,
  onClickRemove,
  onClickEdit,
  disabled,
}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });
  const [removing, setRemoving] = useState(false);

  // Remove scaling because it was warping the conditions in a weird way.
  const _transform = { ...transform, scaleX: 1, scaleY: 1 };
  const style = {
    transform: CSS.Transform.toString(_transform),
    transition,
    opacity: isDragging ? ".5" : 1,
  };

  function onRemove() {
    // Allow user a small amount of time to see the condition disappear and the others slide over before actually removing.
    setRemoving(true);
    window.setTimeout(() => {
      onClickRemove(id);
      setRemoving(false);
    }, 500);
  }

  function getDefinitionNameFromId(defId) {
    if (!defId || !definitionsList || definitionsList.length === 0)
      return "UNKNOWN";

    const name = definitionsList.find((d) => d.id === defId)?.name;
    if (!name) return "NOT FOUND";

    return name;
  }

  function getDefinitionColumnNameFromId(defId, colId) {
    if (!defId || !colId || !definitionsList || definitionsList.length === 0)
      return "UNKNOWN";

    const columns = definitionsList.find((d) => d.id === defId)?.columns;
    if (!columns) return "NOT FOUND";

    const name = columns.find((d) => d.id === colId)?.name;
    if (!name) return "NOT FOUND";

    return name;
  }

  function getTextFieldDescription() {
    if (getDropDownValue(column, "controlType") === "List") {
      return (
        <>
          <div>Text Dropdown</div>
          <div className="flex-row-with-wrap">
            {getDropDownValue(column, "listSource") === "ValidValues" ? (
              <>
                <i>Values:&nbsp;&nbsp;</i>
                {column.validValuesVm.substring(0, 25) + "..."}
              </>
            ) : (
              <>
                <i>Source:&nbsp;&nbsp;</i>{" "}
                {getDefinitionNameFromId(column.ruleTableDefinitionId)}/
                {getDefinitionColumnNameFromId(
                  column.ruleTableDefinitionId,
                  column.ruleTableDefinitionColumnId
                )}
              </>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            Text Input&nbsp;&nbsp;
            {column.maxLength && column.maxLength > 0 && (
              <>({column.maxLength} max)</>
            )}
          </div>
          <div className="flex-row-with-wrap">
            {column.validationExpression !== "" && (
              <>
                <i>Validation:&nbsp;&nbsp;</i> {column.validationExpression}
              </>
            )}
          </div>
        </>
      );
    }
  }

  function isNumber(num) {
    return !isNaN(num);
  }

  function getNumberFieldDescription() {
    if (getDropDownValue(column, "controlType") === "List") {
      return (
        <>
          <div>Number Dropdown</div>
          <div className="flex-row-with-wrap">
            {getDropDownValue(column, "listSource") === "ValidValues" ? (
              <>
                <i>Values:&nbsp;&nbsp;</i>
                {column.validValuesVm.length > 25
                  ? column.validValuesVm.substring(0, 25) + "..."
                  : column.validValuesVm}
              </>
            ) : (
              <>
                <i>Source:&nbsp;&nbsp;</i>{" "}
                {getDefinitionNameFromId(column.ruleTableDefinitionId)}/
                {getDefinitionColumnNameFromId(
                  column.ruleTableDefinitionId,
                  column.ruleTableDefinitionColumnId
                )}
              </>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            Number Input&nbsp;&nbsp;
            {isNumber(column.minValue) && isNumber(column.maxValue) ? (
              <>
                ({column.minValue}&nbsp;&ndash;&nbsp;{column.maxValue})
              </>
            ) : isNumber(column.minValue) ? (
              <>({column.minValue}&nbsp;&ndash;&nbsp;&infin;)</>
            ) : isNumber(column.maxValue) ? (
              <>(-&infin;&nbsp;&ndash;&nbsp;{column.maxValue})</>
            ) : (
              <></>
            )}
          </div>
          <div className="flex-row-with-wrap">
            {isNumber(column.decimalPlaces) && (
              <>
                <i># decimal places:&nbsp;&nbsp;</i> {column.decimalPlaces}
              </>
            )}
          </div>
        </>
      );
    }
  }

  function getDatetimeFieldDescription() {
    return (
      <>
        <div>Date Input</div>
      </>
    );
  }

  function getBooleanFieldDescription() {
    return (
      <>
        <div>Boolean Input</div>
      </>
    );
  }

  return (
    <>
      <div ref={setNodeRef} style={style} name={id}>
        <StyledDraggableChip
          name={id}
          className={`${
            disabled ? "chip-disabled" : removing ? "removing" : ""
          }`}
        >
          {!disabled && (
            <span
              className="material-icons"
              {...attributes}
              {...listeners}
              style={{
                marginRight: "10px",
              }}
            >
              drag_indicator
            </span>
          )}
          <StyledChipContent>
            <StyledColumn>
              <StyledKeyIndicator className="material-icons">
                {column.isKey && "key"}
              </StyledKeyIndicator>
              <StyledFieldSpan>{column.name}</StyledFieldSpan>
            </StyledColumn>
            {getDropDownValue(column, "valueType") === "Date"
              ? getDatetimeFieldDescription()
              : getDropDownValue(column, "valueType") === "Numeric"
              ? getNumberFieldDescription()
              : getDropDownValue(column, "valueType") === "Boolean"
              ? getBooleanFieldDescription()
              : getTextFieldDescription()}
          </StyledChipContent>
          {!disabled && (
            <div
              className="flex-row-with-wrap"
              style={{
                justifyContent: "flex-end",
                position: "absolute",
                right: "-24px",
                width: "80px",
              }}
            >
              <span className="material-icons pointer" onClick={onClickEdit}>
                edit
              </span>
              <span
                className="material-icons pointer"
                style={{ marginLeft: "15px", fontSize: "18px" }}
                onClick={onRemove}
              >
                close
              </span>
            </div>
          )}
        </StyledDraggableChip>
      </div>
    </>
  );
}

const StyledColumn = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFieldSpan = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 35px;
  font-weight: bold;
  color: var(--text-normal);
  margin: 0 5px;
`;

const StyledChipContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: left;
  margin-left: 20px;
`;

const StyledDraggableChip = styled.div`
  position: relative;
  min-height: 72px;
  max-width: 600px;
  padding: 3px 10px 10px 10px;
  background: var(--drag-chip-bg);
  border: 1px solid var(--drag-chip-border);
  border-radius: 18px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  transition: all 0.5s ease-in-out;
  margin-right: 20px;

  &.removing {
    opacity: 0;
  }

  &.chip-disabled {
    opacity: 0.5;
    background: var(--elevated-bg);
    border: 1px solid var(--elevated-border);

    &.join-or {
      background: var(--elevated-bg);
      border: 1px solid var(--elevated-border);
    }
  }

  span[class="material-icons"] {
    font-size: 20px;
    line-height: 20px;
    color: var(--drag-chip-border);
    touch-action: none;

    &:hover {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
`;

export default DragDropRuleTableDefinitionColumn;
