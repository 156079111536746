import React, { Fragment, useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  StyledHeaderRowDiv,
  StyledNoResultsDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import Badge from "../../common/ui/Badge";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import HomePacketsFilter from "./HomePacketsFilter";
import { useMobile } from "../../../hooks/useMobile";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import HomePacketsTrafficBlock from "./HomePacketsTrafficBlock";
import ToggleSwitchInput from "../../common/input/ToggleSwitchInput";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Modal } from "react-bootstrap";
import SelectInput from "../../common/input/SingleSelect";
import { testPacketTypes } from "../../../services/General";
import { apiSendTestPacketTraffic } from "../../../api/HomeApi";
import useApi from "../../../hooks/useApi";
import TextInput from "../../common/input/TextInput";
import TextAreaInput from "../../common/input/TextAreaInput";
import {
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import { emptyTestConfig } from "../../../viewmodels/trafficVm";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useUserPreferences } from "../../../contexts/UserPreferencesContext";
import { usePacketTraffic } from "../../../contexts/PacketTrafficContext";

export const PACKET_TRAFFIC_DONE_STATUSES = [
  "ReadyToRespond",
  "Error",
  "TimedOut",
];

function HomePacketsTraffic({
  isLoading,
  packetBlockPositions,
  setPacketBlockPositions,
}) {
  const { isMobileSize } = useMobile();
  const { userPrefs, setUserPrefs } = useUserPreferences();
  const { loading: sendingPacket, api: apiSendTestPacket } = useApi(
    apiSendTestPacketTraffic
  );
  const { packetsTraffic } = usePacketTraffic();
  const [traffic, setTraffic] = useState({});

  const emptyFilter = {
    connections: [],
    recognizers: [],
    consumers: [],
  };

  // State of filter select all checkboxes
  const emptyFiltersSelectAll = {
    connections: true,
    recognizers: true,
    consumers: true,
  };

  const [search, setSearch] = useState({
    freeFormSearch: "",
    showAdvancedFilter: true,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [numBadPackets, setNumBadPackets] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [showTestSettingsDialog, setShowTestSettingsDialog] = useState(false);
  const [testPacketConfig, setTestPacketConfig] = useState({
    ...userPrefs.trafficPacketTestData,
  });
  const [testConfigDialog, setTestConfigDialog] = useState({
    ...userPrefs.trafficPacketTestData,
  });
  const [filteredQueuedPackets, setFilteredQueuedPackets] = useState([]);
  const [filteredProcessedPackets, setFilteredProcessedPackets] = useState([]);
  const [filters, setFilters] = useState({
    ...emptyFilter,
  });
  const [filtersSelectAll, setFiltersSelectAll] = useState({
    ...emptyFiltersSelectAll,
  });
  const [isFiltering, setIsFiltering] = useState(false);
  const [hoveredFilter, setHoveredFilter] = useState("");

  const parentRef = useRef(null);

  useEffect(() => {
    if (isMobileSize) {
      setSearch({ ...search, showAdvancedFilter: false });
    }
  }, [isMobileSize]);

  useEffect(() => {
    if (packetsTraffic) {
      setTraffic(packetsTraffic.packetsTraffic);
    }
  }, [packetsTraffic]);

  useEffect(() => {
    let badPacketCount = 0;

    if (traffic && (traffic.queuedPackets || []).length > 0) {
      setFilteredQueuedPackets(
        getFilteredArray(traffic.queuedPackets, filters)
      );
      badPacketCount += getBadPacketCountFromArray(traffic.queuedPackets);
    } else {
      setFilteredQueuedPackets([]);
    }
    if (traffic && (traffic.processedPackets || []).length > 0) {
      setFilteredProcessedPackets(
        getFilteredArray(traffic.processedPackets, filters)
      );
      badPacketCount += getBadPacketCountFromArray(traffic.processedPackets);
    } else {
      setFilteredProcessedPackets([]);
    }

    setNumBadPackets(badPacketCount);
  }, [traffic?.queuedPackets, traffic?.processedPackets]);

  useEffect(() => {
    if (isFirstLoad && traffic && (traffic.recognizers || []).length > 0) {
      setIsFirstLoad(false);

      // Initialize all filters to checked
      const _filters = { ...emptyFilter };
      _filters.connections = (traffic.connections || []).map((c) => {
        return { id: c.id, checked: true };
      });
      _filters.recognizers = (traffic.recognizers || []).map((c) => {
        return { id: c.id, checked: true };
      });
      _filters.consumers = (traffic.consumers || []).map((c) => {
        return { id: c.id, checked: true };
      });
      setFilters(_filters);
    } else if (!isFirstLoad && (traffic.connections || []).length > 0) {
      // After first load: monitor all new connections and default them to the state of the select all checkbox when they appear
      const _filters = _.cloneDeep(filters);
      for (let i = 0; i < traffic.connections.length; i++) {
        const _check = _filters.connections.find(
          (c) => c.id === traffic.connections[i].id
        );
        if (!_check) {
          _filters.connections.push({
            id: traffic.connections[i].id,
            checked: filtersSelectAll.connections,
          });
        }
      }

      setFilters(_filters);
    }
  }, [isFirstLoad, traffic?.recognizers, traffic?.connections]);

  function getBadPacketCountFromArray(array) {
    const count = array.filter((r) => r.recognizerName === "Unmatched").length;

    return count;
  }

  function getFilteredArray(array, pFilters) {
    let _array = _.cloneDeep(array);

    // Get list of checkboxes from sidebar filters
    const _filteredConnections = (pFilters.connections || [])
      .filter((c) => c.checked)
      .map((c) => c.id);
    const _filteredRecognizers = (pFilters.recognizers || [])
      .filter((c) => c.checked)
      .map((c) => c.id);
    const _filteredConsumers = (pFilters.consumers || [])
      .filter((c) => c.checked)
      .map((c) => c.id);

    // Use checked filters to remove any items from the arry that do not match
    _array = _array.filter(
      (r) => _filteredConnections.indexOf("Connection:" + r.connectionName) >= 0
    );
    _array = _array.filter(
      (r) => _filteredRecognizers.indexOf(r.recognizerName) >= 0
    );
    _array = _array.filter(
      (r) =>
        r.consumers.findIndex((c) => _filteredConsumers.indexOf(c.name) >= 0) >=
        0
    );

    return _array;
  }

  function handleFilterCheckboxChange(filterName, target) {
    setIsFiltering(true);

    const _filters = _.cloneDeep(filters);
    let newState = true;
    const _check = _filters[filterName].find((c) => c.id === target.name);
    if (_check) {
      newState = !_check.checked;
      _check.checked = newState;
    } else {
      _filters[filterName].push({ id: target.name, checked: true });
    }
    setFilters(_filters);

    // Toggle the select all checkbox for this grouping
    const _filtersSelectAll = { ...filtersSelectAll };
    if (newState === false) {
      // this checkbox is unchecked, so uncheck the select all for the group
      _filtersSelectAll[filterName] = false;
    } else {
      // this checkbox is checked, so if all checkboxes are checked for the group, check the select all checkbox.
      if (
        _filters[filterName].length ===
        _filters[filterName].filter((c) => c.checked === true).length
      ) {
        _filtersSelectAll[filterName] = true;
      }
    }
    setFiltersSelectAll(_filtersSelectAll);

    setFilteredQueuedPackets(getFilteredArray(traffic.queuedPackets, _filters));
    setFilteredProcessedPackets(
      getFilteredArray(traffic.processedPackets, _filters)
    );

    // Turn off filtering flag so animations can continue after waiting one second
    window.setTimeout(() => setIsFiltering(false), 1000);
  }

  function handleFilterSelectAll(name, target) {
    const _filtersSelectAll = { ...filtersSelectAll };
    const _checked = !_filtersSelectAll[name];

    _filtersSelectAll[name] = _checked;
    setFiltersSelectAll(_filtersSelectAll);

    // Set all checkboxes for this filter to the new state
    setIsFiltering(true);

    const _filters = _.cloneDeep(filters);
    for (let i = 0; i < _filters[name].length; i++) {
      _filters[name][i].checked = _checked;
    }
    setFilters(_filters);

    setFilteredQueuedPackets(getFilteredArray(traffic.queuedPackets, _filters));
    setFilteredProcessedPackets(
      getFilteredArray(traffic.processedPackets, _filters)
    );

    // Turn off filtering flag so animations can continue after waiting one second
    window.setTimeout(() => setIsFiltering(false), 1000);
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    numFilters += (filters.connections || []).filter((c) => !c.checked).length;
    numFilters += (filters.recognizers || []).filter((c) => !c.checked).length;
    numFilters += (filters.consumers || []).filter((c) => !c.checked).length;

    return numFilters;
  }

  function handleBlockExit(id, listName, index) {
    const _blockPositions = [...packetBlockPositions];
    const pos = _blockPositions.findIndex((b) => b.id === id);

    if (pos >= 0) {
      _blockPositions[pos].index = index;
    } else {
      _blockPositions.push({ id: id, index: index });
    }

    setPacketBlockPositions(_blockPositions);
  }

  function handleBlockRemove(id, listName) {
    const _blockPositions = [...packetBlockPositions];
    const pos = _blockPositions.findIndex((b) => b.id === id);
    if (pos >= 0) {
      _blockPositions.splice(pos, 1);
    }

    setPacketBlockPositions(_blockPositions);
  }

  function getQueueBlockPosition(id) {
    const block = (packetBlockPositions || []).find((b) => b.id === id);
    if (block) {
      // console.log("Queue block position found!", block);
      return block.index;
    } else {
      // console.log("Queue block position not found for id!", id);
      return 0;
    }
  }

  function handleOpenTestSettingsDialog() {
    const selectedOption = testPacketTypes.find(
      (t) => t.value === testPacketConfig.packetType
    );
    setTestConfigDialog({
      packetType: selectedOption,
      address: testPacketConfig.address,
      port: testPacketConfig.port,
      body: testPacketConfig.body,
    });
    setShowTestSettingsDialog(true);
  }

  function handleCancelTestSettingsDialog() {
    setShowTestSettingsDialog(false);
  }

  function handleTestPacketTypeChange(value) {
    const config = { ...testConfigDialog, packetType: value };
    setTestConfigDialog(config);
  }

  function handleTestPacketInputChange({ target }) {
    const config = { ...testConfigDialog, [target.name]: target.value };
    setTestConfigDialog(config);
  }

  function handleSaveTestSettings() {
    const config = {
      ...emptyTestConfig,
      packetType: testConfigDialog.packetType.value,
      address: testConfigDialog.address,
      port: testConfigDialog.port,
      body: testConfigDialog.body,
    };

    setTestPacketConfig(config);
    setShowTestSettingsDialog(false);

    // Save data in local storage so it remains for this user
    setUserPrefs({
      type: ContextProviderActions.setTrafficPacketTestData,
      payload: config,
    });
  }

  function sendTestPacket() {
    // Validate we have all the parameters we need.
    if (_.isEmpty(testPacketConfig.packetType)) {
      notifyWarn(
        "You must select a Packet Type in the Test Packet Settings dialog before sending a test packet."
      );
      return;
    }
    if (_.isEmpty(testPacketConfig.address)) {
      notifyWarn(
        "You must configure the IP Address in the Test Packet Settings dialog before sending a test packet."
      );
      return;
    }
    if (_.isEmpty(testPacketConfig.port)) {
      notifyWarn(
        "You must configure the Port in the Test Packet Settings dialog before sending a test packet."
      );
      return;
    }

    apiSendTestPacket.call(
      {
        packetType: testPacketConfig.packetType,
        address: testPacketConfig.address,
        port: testPacketConfig.port,
        body: testPacketConfig.body,
      },
      () => {
        notifySuccess(
          `${testPacketConfig.packetType === "error" ? "An" : "A"} ${
            testPacketConfig.packetType
          } packet was sent to ${testPacketConfig.address}:${
            testPacketConfig.port
          }.`
        );
      }
    );
  }

  function shouldHighlightPacket(packet) {
    if (hoveredFilter === "") return true;

    return (
      packet.connectionName === hoveredFilter ||
      packet.recognizerName === hoveredFilter ||
      packet.consumers.findIndex((c) => c.name === hoveredFilter) >= 0
    );
  }

  function getPacketCount(packetList, filteredList) {
    const packetArray = packetList || [];
    const filterArray = filteredList || [];

    const list = packetArray.filter(
      (p) =>
        shouldHighlightPacket(p) &&
        filterArray.findIndex((f) => f.id === p.id) >= 0
    );
    return list.length;
  }

  let lastGroupingKey = "";

  return (
    <div data-testid="home-packets-traffic-section">
      <StyledHeaderRowDiv style={{ marginTop: "35px", marginBottom: "-4px" }}>
        <h2
          style={{
            fontSize: "18px",
            padding: "0",
            margin: "0",
            display: "flex",
            alignItems: "center",
            columnGap: "5px",
          }}
        >
          <span>
            Real-time Packets Traffic{" "}
            <Badge>
              {(filteredQueuedPackets || []).length +
                (filteredProcessedPackets || []).length}
            </Badge>{" "}
          </span>
          <HelpLink path="/Home#real-time-packets-traffic-section" />
        </h2>
        <div style={{ marginBottom: "-10px" }}>
          <ToggleSwitchInput
            id="showDetails"
            name="showDetails"
            label="Show details"
            onChange={() => setShowDetails(!showDetails)}
            checked={showDetails}
          />
        </div>
        <div className="flex-row-with-wrap">
          <span
            className="material-icons button-icon"
            onClick={handleOpenTestSettingsDialog}
          >
            settings
          </span>
          <button
            type="button"
            className="btn btn-secondary btn-with-icon"
            onClick={sendTestPacket}
            style={{ marginLeft: "10px" }}
            disabled={sendingPacket}
          >
            Send {testPacketConfig.packetType} packet &nbsp;&nbsp;
            {sendingPacket ? (
              <i className="material-symbols-outlined rotating">rotate_right</i>
            ) : (
              <i className="material-icons">send</i>
            )}
          </button>
        </div>
      </StyledHeaderRowDiv>

      <div ref={parentRef}>
        <>
          <GridFreeFormSearchBar
            placeholderText="Search packets"
            search={search}
            setSearch={setSearch}
            numSetFilters={getNumberOfSetFilters()}
            alwaysShowMobileFilterLink={!search.showAdvancedFilter}
            onSubmitSearch={() => {
              return;
            }}
          />
          <div style={{ display: "flex" }}>
            <div
              style={{
                width:
                  search.showAdvancedFilter && isMobileSize ? "100%" : "auto",
              }}
            >
              <GridAdvancedFilter
                search={search}
                setSearch={setSearch}
                marginTop="-145px"
              >
                <HomePacketsFilter
                  traffic={traffic}
                  filters={filters}
                  isLoading={isLoading}
                  onFilterCheckboxChange={handleFilterCheckboxChange}
                  selectAll={filtersSelectAll}
                  onSelectAll={handleFilterSelectAll}
                  hoveredFilter={hoveredFilter}
                  setHoveredFilter={setHoveredFilter}
                />
              </GridAdvancedFilter>
            </div>
            {search.showAdvancedFilter && isMobileSize ? (
              <></>
            ) : (
              <div style={{ flex: "1 1 auto" }}>
                {numBadPackets > 0 && (
                  <div
                    className="flex-row-with-wrap"
                    style={{
                      marginBottom: "10px",
                      justifyContent: "space-around",
                    }}
                  >
                    <div
                      className="flex-row-with-wrap"
                      style={{
                        color: "var(--notify-danger)",
                        fontSize: "18px",
                      }}
                    >
                      <i
                        className="material-symbols-outlined"
                        style={{ fontSize: "28px" }}
                      >
                        warning
                      </i>
                      <span>
                        &nbsp;&nbsp;
                        {`${numBadPackets} bad ${
                          numBadPackets === 1 ? "packet" : "packets"
                        } received!`}
                      </span>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-6" style={{ position: "relative" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "var(--text-dark)",
                      }}
                    >
                      Queued (
                      {getPacketCount(
                        traffic.queuedPackets,
                        filteredQueuedPackets
                      )}
                      )
                    </p>

                    <TransitionGroup>
                      {(traffic.queuedPackets || []).map((t, idx) => {
                        const packetBlock = (
                          <HomePacketsTrafficBlock
                            key={`tqueued-${t.id}`}
                            listName="Queued"
                            packet={t}
                            isPacketInFilter={
                              filteredQueuedPackets.findIndex(
                                (p) => p.id === t.id
                              ) >= 0
                            }
                            searchTerm={search.freeFormSearch}
                            showDetails={showDetails}
                            blockOriginPosition={idx}
                            myPosition={idx}
                            blockTimeout={{ enter: 500, exit: 10 }}
                            blockClassNames={
                              isFiltering ? "donotanimate" : "animatequeueitem"
                            }
                            onBlockExit={handleBlockExit}
                            onBlockRemove={() => {}}
                            isHighlighted={shouldHighlightPacket(t)}
                            showGroupName={
                              t.groupingKey !== "" &&
                              t.groupingKey !== lastGroupingKey
                            }
                            groupPacket={
                              t.groupingKey !== "" &&
                              t.groupingKey === lastGroupingKey
                            }
                          />
                        );

                        lastGroupingKey = t.groupingKey || t.friendlyId;
                        return packetBlock;
                      })}
                    </TransitionGroup>
                    <TransitionGroup>
                      {(traffic.queuedPackets || []).length === 0 && (
                        <CSSTransition
                          timeout={{ enter: 2000, exit: 0 }}
                          classNames="animatewaiting"
                        >
                          <StyledNoResultsDiv
                            style={{
                              height: "60px",
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                            }}
                            data-testid="home-packets-traffic-no-results"
                          >
                            <div
                              className="flex-row-without-wrap"
                              style={{
                                columnGap: "5px",
                                color: "var(--text-dark)",
                              }}
                            >
                              <i className="material-symbols-outlined rotating">
                                rotate_right
                              </i>
                              <span>Waiting for packets...</span>
                            </div>
                          </StyledNoResultsDiv>
                        </CSSTransition>
                      )}
                    </TransitionGroup>
                  </div>
                  <div className="col-6" style={{ position: "relative" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "var(--text-dark)",
                      }}
                    >
                      Processed (
                      {getPacketCount(
                        traffic.processedPackets,
                        filteredProcessedPackets
                      )}
                      )
                    </p>
                    <TransitionGroup delay={500}>
                      {(traffic.processedPackets || []).map((t, idx) => {
                        const packetBlock = (
                          <HomePacketsTrafficBlock
                            key={`tprocessed-${t.id}`}
                            listName="Processed"
                            packet={t}
                            isPacketInFilter={
                              filteredProcessedPackets.findIndex(
                                (p) => p.id === t.id
                              ) >= 0
                            }
                            searchTerm={search.freeFormSearch}
                            showDetails={showDetails}
                            blockOriginPosition={getQueueBlockPosition(t.id)}
                            myPosition={idx}
                            blockTimeout={{ enter: 500, exit: 500 }}
                            blockClassNames={
                              isFiltering
                                ? "donotanimate"
                                : "animatecompleteitem"
                            }
                            onBlockExit={() => {}}
                            onBlockRemove={handleBlockRemove}
                            isHighlighted={shouldHighlightPacket(t)}
                            showGroupName={
                              t.groupingKey !== "" &&
                              t.groupingKey !== lastGroupingKey
                            }
                            groupPacket={
                              t.groupingKey !== "" &&
                              t.groupingKey === lastGroupingKey
                            }
                          />
                        );

                        lastGroupingKey = t.groupingKey || t.friendlyId;
                        return packetBlock;
                      })}
                    </TransitionGroup>
                    <TransitionGroup>
                      {(traffic.processedPackets || []).length === 0 && (
                        <CSSTransition
                          timeout={{ enter: 2000, exit: 0 }}
                          classNames="animatewaiting"
                        >
                          <StyledNoResultsDiv
                            style={{
                              height: "60px",
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                            }}
                            data-testid="home-packets-traffic-no-results"
                          >
                            <div
                              className="flex-row-without-wrap"
                              style={{
                                columnGap: "5px",
                                color: "var(--text-dark)",
                              }}
                            >
                              <i className="material-symbols-outlined">
                                hourglass
                              </i>
                              <span>No recent packets</span>
                            </div>
                          </StyledNoResultsDiv>
                        </CSSTransition>
                      )}
                    </TransitionGroup>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      </div>
      <Modal
        show={showTestSettingsDialog}
        onHide={handleCancelTestSettingsDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>Test Packet Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectInput
            id="testPacketType"
            name="testPacketType"
            label="Packet Type"
            options={testPacketTypes}
            value={testConfigDialog.packetType}
            onChange={handleTestPacketTypeChange}
            placeholder="Select a packet type"
          />
          <TextInput
            id="address"
            label="IP Address"
            onChange={handleTestPacketInputChange}
            placeholder="0.0.0.0"
            name="address"
            value={testConfigDialog.address}
          />
          <TextInput
            id="port"
            label="Port"
            onChange={handleTestPacketInputChange}
            placeholder="55555"
            name="port"
            value={testConfigDialog.port}
          />
          <TextAreaInput
            id="body"
            label="Packet Body (optional)"
            onChange={handleTestPacketInputChange}
            placeholder="Packet contents"
            cols="40"
            rows="5"
            name="body"
            value={testConfigDialog.body}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-with-icon"
            onClick={handleSaveTestSettings}
            style={{
              minWidth: "86px",
            }}
          >
            <span className="material-icons">check</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancelTestSettingsDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default HomePacketsTraffic;
