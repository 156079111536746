import React, { useEffect } from "react";
import {
  StyledNoResultsDiv,
  StyledRowDiv,
} from "../../common/layout/CommonStyledControls";
import { useMobile } from "../../../hooks/useMobile";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import { StyledArtifact, StyledWrapper } from "./RuleGroupSummaryCommonStyles";
import RuleGroupSummaryExcludedRejection from "./RuleGroupSummaryExcludedRejection";

function RuleGroupSummaryExcludedRejections({
  items,
  artifacts,
  sort,
  setSort,
  displayValue,
  includeAllClaimsRule,
  setSortedFilteredItems,
  getFormattedCondition,
}) {
  const { isMobileSize, isTabletSize } = useMobile();

  useEffect(() => {
    if (displayValue !== "advanced" && sort === "artifact") {
      setSort("message");
      setSortedFilteredItems(includeAllClaimsRule, "message");
    }
  }, [displayValue]);

  const sortOptions = [
    { value: "message", label: "Message" },
    { value: "code", label: "Code" },
  ];
  if (displayValue === "advanced")
    sortOptions.push({ value: "artifact", label: "Artifact" });

  const isAdminView = displayValue === "admin" || displayValue === "advanced";

  return (
    <>
      {items.length === 0 && (
        <StyledRowDiv className="row" style={{ marginTop: "5px" }}>
          <StyledNoResultsDiv>
            <p>
              <i className="material-icons">search_off</i>
            </p>
            <p>No excluded rejections found for this group</p>
          </StyledNoResultsDiv>
        </StyledRowDiv>
      )}
      {items.length > 0 && (
        <StyledRowDiv className="row">
          <p style={{ marginBottom: 0, fontStyle: "italic" }}>
            The following rejections are excluded for this group.
          </p>
          <ToggleButtonGroupInput
            id="sort"
            name="sort"
            label="Sort by"
            labelStyle={{ display: isMobileSize ? "none" : "inline" }}
            showLabelInline={true}
            options={[...sortOptions]}
            value={sort}
            onChange={(value) => {
              setSort(value);
              setSortedFilteredItems(includeAllClaimsRule, value);
            }}
          />
          {sort === "artifact" ? (
            <div style={{ marginTop: "20px" }}>
              {artifacts.map((artifact) => {
                return (
                  <div key={`${artifact.sequence}-${artifact.name}`}>
                    <StyledArtifact>
                      {artifact.name !== "None" && (
                        <span className="list-number">
                          {artifact.sequence}.
                        </span>
                      )}
                      {artifact.name === "None" ? (
                        <i>Not Associated with an Artifact:</i>
                      ) : (
                        artifact.name
                      )}
                      {artifact.name !== "None" && (
                        <span className="summary-field">
                          {artifact.artifactType === "CodeArtifact"
                            ? "code"
                            : "rule"}
                        </span>
                      )}
                    </StyledArtifact>
                    <StyledWrapper
                      className="col-12 col-md-6"
                      isSmallScreen={isMobileSize || isTabletSize}
                      style={{ marginTop: "10px" }}
                    >
                      {items
                        .filter(
                          (r) =>
                            (r.artifact === artifact.name &&
                              artifact.name !== "None") ||
                            (r.artifact === "" && artifact.name === "None")
                        )
                        .sort((a, b) =>
                          a.description > b.description ? 1 : -1
                        )
                        .map((r, idx) => {
                          return (
                            <RuleGroupSummaryExcludedRejection
                              key={`${r.name}-${idx}`}
                              item={r}
                              showFriendly={!isAdminView}
                              showCondition={displayValue !== "basic"}
                              showRuleLink={isAdminView}
                              getFormattedCondition={getFormattedCondition}
                            />
                          );
                        })}
                    </StyledWrapper>
                  </div>
                );
              })}
            </div>
          ) : (
            <StyledWrapper
              className="col-12 col-md-6"
              isSmallScreen={isMobileSize || isTabletSize}
              style={{ marginTop: "10px" }}
            >
              {items.map((r, idx) => {
                return (
                  <RuleGroupSummaryExcludedRejection
                    key={`${r.name}-${idx}`}
                    item={r}
                    showFriendly={!isAdminView}
                    showCondition={displayValue !== "basic"}
                    showRuleLink={isAdminView}
                    getFormattedCondition={getFormattedCondition}
                  />
                );
              })}
            </StyledWrapper>
          )}
        </StyledRowDiv>
      )}
    </>
  );
}

export default RuleGroupSummaryExcludedRejections;
