/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";
import _ from "lodash";

export function apiLoadRules(groupId, token, signal) {
  let uri = API_URL + "/rules";
  if (!_.isEmpty(groupId)) uri += "/" + groupId;
  uri += getQueryString();

  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadRule(id, token, signal) {
  let uri = API_URL + "/rule/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadGroupRule(id, token, signal) {
  let uri = API_URL + "/grouprule/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);

  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddRule(rule, token, signal) {
  let uri = API_URL + "/rule" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(rule),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateRule(params, token, signal) {
  const id = params.id;
  const rule = params.model;

  let uri = API_URL + "/rule/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(rule),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteRule(id, token, signal) {
  let uri = API_URL + "/rule/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCopyRule(id, token, signal) {
  let uri = API_URL + "/rulecopy/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadGroupSummary(groupId, token, signal) {
  let uri = PROCESSOR_URL + "/summarizerule/" + groupId + getQueryString();

  const processingDate = new Date().toISOString();
  const headersToAdd = getCommonHeaders(token);
  headersToAdd["x-processing-date"] = processingDate;

  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadRules.funcName = "apiLoadRules";
apiLoadRule.funcName = "apiLoadRule";
apiLoadGroupRule.funcName = "apiLoadGroupRule";
apiAddRule.funcName = "apiAddRule";
apiUpdateRule.funcName = "apiUpdateRule";
apiDeleteRule.funcName = "apiDeleteRule";
apiCopyRule.funcName = "apiCopyRule";
apiLoadGroupSummary.funcName = "apiLoadGroupSummary";
