/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadReportTemplates(search, token, signal) {
  const uri = API_URL + "/reporttemplates" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({
      operand: "OR",
      name: "name",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "entityType",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "fileNameTemplate",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "externalDataRuleTableDefinitionName",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.name && search.name.length > 0)
    where.push({ name: "name", operator: "=", values: [search.name] });

  if (search && search.entityType && search.entityType.value)
    where.push({
      name: "entityType",
      operator: "=",
      values: [search.entityType.value],
    });

  if (search && search.fileNameTemplate && search.fileNameTemplate.length > 0)
    where.push({
      name: "fileNameTemplate",
      operator: "=",
      values: [search.fileNameTemplate],
    });

  if (
    search &&
    search.externalDataRuleTableDefinitionName &&
    search.externalDataRuleTableDefinitionName.length > 0
  )
    where.push({
      name: "externalDataRuleTableDefinitionName",
      operator: "=",
      values: [search.externalDataRuleTableDefinitionName],
    });

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy || '["name+"]' };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadReportTemplatesAll(params, token, signal) {
  const uri = API_URL + "/reporttemplatesall" + getQueryString();
  let where = [];
  where.push({ name: "id", operator: "notnull", values: [] });

  const data = { where, orderBy: ["name+"] };

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadReportTemplate(id, token, signal) {
  let uri = API_URL + "/reporttemplate/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddReportTemplate(m, token, signal) {
  let uri = API_URL + "/reporttemplate" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateReportTemplate(params, token, signal) {
  const id = params.id;
  const model = params.model;

  let uri = API_URL + "/reporttemplate/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteReportTemplate(id, token, signal) {
  let uri = API_URL + "/reporttemplate/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCopyReportTemplate(params, token, signal) {
  const id = params.id;
  const newId = params.newId;

  let uri =
    API_URL +
    "/reporttemplatecopy/" +
    encodeURIComponent(id) +
    "/" +
    encodeURIComponent(newId) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadReportTemplates.funcName = "apiLoadReportTemplates";
apiLoadReportTemplatesAll.funcName = "apiLoadReportTemplatesAll";
apiLoadReportTemplate.funcName = "apiLoadReportTemplate";
apiAddReportTemplate.funcName = "apiAddReportTemplate";
apiUpdateReportTemplate.funcName = "apiUpdateReportTemplate";
apiDeleteReportTemplate.funcName = "apiDeleteReportTemplate";
apiCopyReportTemplate.funcName = "apiCopyReportTemplate";
