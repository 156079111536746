import _ from "lodash";
import { pageSize } from "../constants/Environments";

var oneHourAgo = new Date();
oneHourAgo.setHours(oneHourAgo.getHours() - 1);

export const emptyCacheStatus = {
  cacheEnabled: false,
  keys: [],
};

export const emptyCacheKeyRecord = {
  keyName: "",
  keyHits: 0,
  keyMisses: 0,
  keyTotal: 0,
  keyHitPercentage: 0,
  keyLastHit: "",
  keyAverageGetTimeMilliseconds: 0,
  keyTimeToLiveMinutes: 0,
  keyIdleMinutes: 0,
  keyInfoSource: "cache",
};

export const emptyCacheSearch = {
  pageSize: pageSize || 25,
  pageNumber: 1,
  orderBy: "-keyLastHit",
  startDate: oneHourAgo,
  endDate: new Date("1/1/3000"),
  keyMatch: "",
};

export function createCacheKeyViewModel(m) {
  let vm = _.cloneDeep(m);

  vm.keyTotal = vm.keyHits + vm.keyMisses;

  return vm;
}
