import React from "react";
import _ from "lodash";
import { formatDateTimeUtcZoneForDisplay } from "../../../services/General";
import RuleDataStatusPill from "../../common/ui/RuleDataStatusPill";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { StyledKeyHeader } from "../../common/layout/CommonStyledControls";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function RuleTableDataGrid({
  ruleTableId,
  totalRecords,
  columns,
  records,
  colCount,
  search,
  setSearch,
  onDeleteDataRow,
  onEditDataRow,
  onScheduleDataRowChange,
  onViewDataRowHistory,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function handleEditDataRow(e, id) {
    if (e) e.preventDefault();
    onEditDataRow(id);
  }

  function getActions(dataRow) {
    const id = dataRow.id;
    const inheritedRuleTableId = dataRow.ruleTableId;
    const isInherited = dataRow.isInherited;

    const actions = [];

    actions.push({
      name: "Edit row data",
      onClick: () => onEditDataRow(id),
    });

    if (isInherited === true) {
      actions.push({
        name: "View inherited rule table",
        onClick: () => navigate("/ruletable/" + inheritedRuleTableId),
      });
    }

    actions.push({
      name: "Schedule change",
      onClick: () => onScheduleDataRowChange(dataRow),
    });

    actions.push({
      name: isInherited === true ? "Terminate inherited row" : "Remove",
      onClick: () => onDeleteDataRow(id, ruleTableId, isInherited),
    });

    actions.push({
      name: "View key history",
      onClick: () => onViewDataRowHistory(dataRow.keyHash),
    });

    if (auth.isAdmin) {
      actions.push({
        name: "View change history",
        onClick: () => navigate("/auditrecord/ruletablerow/" + id + "/0"),
      });
    }

    return actions;
  }

  function getColumnDefs() {
    const cols = [];

    // Always-visible edit column
    cols.push({
      name: "edit",
      label: "",
      disableSort: true,
      isSticky: true,
      style: { width: "65px" },
      getValue: (row) => (
        <button
          type="button"
          className="btn btn-link link-underline"
          onClick={(e) => handleEditDataRow(e, row.id)}
          style={{ marginTop: "-4px" }}
        >
          edit
        </button>
      ),
    });

    // Status column
    cols.push({
      name: "status",
      label: "Status",
      disableSort: true,
      style: { width: "120px" },
      getValue: (row) => (
        <RuleDataStatusPill
          effectiveDate={row.effectiveDate}
          terminationDate={row.terminationDate}
        />
      ),
    });

    // Rule table columns
    let col;
    for (let i = 0; i < columns.length; i++) {
      col = columns[i];

      cols.push({
        name: col.name,
        label: col.isKey ? (
          <StyledKeyHeader>
            <span className="material-icons">key</span>
            <div>{col.name}</div>
          </StyledKeyHeader>
        ) : (
          <>{col.name}</>
        ),
        disableSort: true,
        freeTextSearch: true,
        getRawValue: (row) => {
          const val = row.values.find(
            (r) => r.ruleTableColumnDefinitionId === columns[i].id
          );
          let value = val?.value ?? "";

          if (columns[i]?.valueType === "Date") {
            value = formatDateTimeUtcZoneForDisplay(value);
          }

          return value;
        },
        getValue: (row, index, searchResultHtml) => searchResultHtml,
      });
    }

    // Inherited column
    cols.push({
      name: "inherited",
      label: "Inherited",
      disableSort: true,
      style: { width: "80px" },
      getValue: (row) =>
        row.isInherited ? (
          <span
            data-tip={`Inherited from rule '${row.sourceRuleName}'`}
            className="material-icons"
          >
            check
          </span>
        ) : (
          <>&nbsp;</>
        ),
    });

    // Always-visible action column
    cols.push({
      name: "action",
      label: "Action",
      isSticky: true,
      isActionBtn: true,
      disableSort: true,
      noForceWrap: true,
      style: { width: "60px", maxWidth: "25%", textAlign: "center" },
      getActions: (row) => getActions(row),
    });

    return cols;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="RuleTableData"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        allowFreeTextSearch={true}
        dataRows={records}
        enableClientColumnPager={true}
        enableClientRowPager={true}
        totalColumnCount={colCount + 2}
        disablePaging={_.trim(search.highlightText) !== ""}
        columnDefs={getColumnDefs()}
      />
    </>
  );
}

export default RuleTableDataGrid;
