import React, { useState } from "react";
import { StyledHeaderRowDiv } from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import WizardTaskPanel from "./WizardTaskPanel";
import { notifyWarn } from "../../../services/NotificationService";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import { useNavigate } from "react-router-dom";
import Authorize from "../../common/layout/Authorize";

function WizardHome() {
  const navigate = useNavigate();

  const [screenSearchInput, setScreenSearchInput] = useState("");

  function handleStartTask(taskId) {
    let route = "";

    switch (taskId) {
      case "SetMemberDOB":
        route = "setmemberdob/SetMemberDOBWizard";
        break;
      case "CreateTest":
        route = "createtest/WizardCreateTest";
        break;
      default:
        notifyWarn("Wizard not yet implemented: " + taskId);
        return;
    }

    navigate(route);
  }

  return (
    <Authorize>
      <StyledHeaderRowDiv style={{ marginTop: "0", marginBottom: "-4px" }}>
        <h2
          style={{
            fontSize: "24px",
          }}
        >
          <span>Perform Common Tasks</span>
        </h2>
        <HelpLink path="/Wizard" label="Help" />
      </StyledHeaderRowDiv>
      <p>
        Select or search for a task below and then click Start to begin that
        task.
      </p>
      <div style={{ maxWidth: "300px" }}>
        <InstantSearchInput
          id="screenSearchInput"
          onChange={setScreenSearchInput}
          value={screenSearchInput}
        />
      </div>
      <div className="row">
        <WizardTaskPanel
          taskId="SetMemberDOB"
          taskTitle="Change Member DOB"
          taskDesc="Provide a Member ID to view the current date of birth for a
                member and then update it."
          symbolIconName="event"
          searchText={screenSearchInput}
          onStartTask={handleStartTask}
        />
        <WizardTaskPanel
          taskId="CreateTest"
          taskTitle="Create a Test"
          taskDesc="Create a test for a group that verifies the correct processing of claims in the group."
          symbolIconName="experiment"
          searchText={screenSearchInput}
          onStartTask={handleStartTask}
        />
        <WizardTaskPanel
          taskId="TerminateDiscount"
          taskTitle="Terminate a Discount"
          taskDesc="Provide a Member ID to view the current effective and
                termination dates for the discount and then terminate it."
          symbolIconName="event_busy"
          searchText={screenSearchInput}
          onStartTask={handleStartTask}
        />
        <WizardTaskPanel
          taskId="CheckDiscountBalance"
          taskTitle="Check the Balance of a Discount"
          taskDesc="Provide a Voucher # to view the redemption history and current balance for a discount."
          symbolIconName="request_quote"
          searchText={screenSearchInput}
          onStartTask={handleStartTask}
        />
      </div>
    </Authorize>
  );
}

export default WizardHome;
