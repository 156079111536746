import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { Modal } from "react-bootstrap";
import {
  arrayOperators,
  booleanOperators,
  datetimeOperators,
  disableAnimations,
  generateUUID,
  numberOperators,
  operators,
  stringOperators,
} from "../../../services/General";
import { emptyReportCondition } from "../../../viewmodels/reportsVm";
import { StyledBlockLetter } from "../../common/layout/CommonStyledControls";
import PlaceholderChip from "../../common/ui/PlaceholderChip";
import SelectInput from "../../common/input/SingleSelect";
import FilterValueFieldByDataType from "../../common/ui/FilterValueFieldByDataType";
import CheckboxInput from "../../common/input/CheckboxInput";
import TextInput from "../../common/input/TextInput";
import HelpLink from "../../common/ui/HelpLink";
import ReportFilterConditionContainer from "./ReportFilterConditionContainer";
import { useReports } from "../../../contexts/ReportsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import {
  MAX_INDENT_LEVEL,
  buildConditionTree,
  getEmptyContainerNode,
} from "./ReportFilterConditionCommon";

function ReportFilterSection({
  title,
  fields,
  conditions,
  setConditions,
  error,
  disabled,
  selectFields,
}) {
  const { reportsData, setReportsData } = useReports();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [showModal, setShowModal] = useState(false);
  const [editCondition, setEditCondition] = useState(null);
  const [filterFields, setFilterFields] = useState([]);
  const [errors, setErrors] = useState({});

  const report = (reportsData && reportsData.report) || {};

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(event) {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id === over.id) {
      return;
    }

    const _conditions = reorder(
      conditions,
      conditions.findIndex((c) => c.id === active.id),
      conditions.findIndex((c) => c.id === over.id)
    );

    setConditions(_conditions);
  }

  function closeAddConditionDialog() {
    setShowModal(false);
  }

  function handleFieldChange(option) {
    // Get list of operators for this new field so we can default the operator to the first in the list
    const opList = getOperatorListByFieldType(option.value);
    setEditCondition({
      ...editCondition,
      fieldId: option.fieldId,
      fieldUniqueId: option.value,
      fieldLabel: option.label,
      fieldDisplayName: option.fieldDisplayName,
      entityType: option.entityType,
      operator: opList[0].value,
      value: "",
    });
  }

  function operatorRequiresValue(opValue) {
    const operator = operators.find((o) => o.value === opValue);
    if (!operator) return true;

    return operator.requiresValue === true;
  }

  function handleOperatorChange(option) {
    let changes = { ...editCondition, operator: option.value, value: "" };

    if (!operatorRequiresValue(changes.operator)) {
      changes.value = "";
    }

    setEditCondition(changes);
  }

  function handleParameterChange(value) {
    let changes = { ...editCondition, isParameter: value };

    if (!changes.isParameter) {
      changes.parameterLabel = "";
    }

    setEditCondition(changes);
  }

  function handleChange({ target }) {
    let changes = { ...editCondition, [target.name]: target.value };
    setEditCondition(changes);
  }

  function formIsValid() {
    const _errors = {};

    // Field must be selected
    if (editCondition.fieldId === "") {
      _errors.selectfield = "Field must be selected";
    }

    if (
      operatorRequiresValue(editCondition.operator) &&
      editCondition.value === ""
    ) {
      _errors.value = "Value must be entered";
    }

    // If parameter is selected, then label must not be empty
    if (
      editCondition.isParameter === true &&
      editCondition.parameterLabel === ""
    ) {
      _errors.parameterLabel = "Parameter Label must be entered";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function saveCondition() {
    if (!formIsValid()) return;

    closeAddConditionDialog();
    const condition = { ...editCondition };
    const _conditions = [...conditions];

    // If condition has an id, then we are editing, otherwise adding
    const pos = _conditions.findIndex((c) => c.id === condition.id);

    if (pos >= 0) {
      _conditions.splice(pos, 1, condition);
    } else {
      // New condition, so give it an id
      condition.id = generateUUID();
      _conditions.push(condition);
    }

    // Save
    setConditions(_conditions);
  }

  function handleAddNewCondition() {
    setShowModal(true);
    setFilterFields(getFieldsForFilterSection());

    // Default new condition's operand and level to the last condition's values
    let newCondition = { ...emptyReportCondition };
    if (conditions.length > 0) {
      const lastCondition = conditions[conditions.length - 1];
      newCondition.operand = lastCondition.operand;
      newCondition.indentLevel = lastCondition.indentLevel;
    }
    setEditCondition(newCondition);
  }

  function handleRemoveCondition(id) {
    const _conditions = [...conditions];
    const index = _conditions.findIndex((c) => c.id === id);
    _conditions.splice(index, 1);
    setConditions(_conditions);
  }

  function handleSetConditionIndentLevel(id, level) {
    const _conditions = [...conditions];
    const index = _conditions.findIndex((c) => c.id === id);
    if (index < 0) {
      console.log(
        `A condition with id ${id} was not found in handleSetConditionIndentLevel!`
      );
      return;
    }
    const _condition = _conditions[index];

    const currentIndentLevel = _condition.indentLevel;
    _condition.indentLevel =
      level < 0 ? 0 : level > MAX_INDENT_LEVEL ? MAX_INDENT_LEVEL : level;

    // When changing indent level, also reset group break
    _condition.groupBreak = false;
    _condition.groupBreakHandled = false;

    // If this condition is being outdented, check the next condition. If it is at the same level as this one and it starts a
    //   a new group, this will cause an empty node to get added between the two. So remove the next condition's group break.
    const nextCondition =
      index + 1 < _conditions.length ? _conditions[index + 1] : null;
    if (
      _condition.indentLevel < currentIndentLevel &&
      nextCondition !== null &&
      nextCondition.groupBreak
    ) {
      nextCondition.groupBreak = false;
      nextCondition.groupBreakHandled = false;
    }

    setConditions(_conditions);
  }

  function handleChangeGroupBreak(id, groupBreak) {
    const _conditions = [...conditions];
    const _condition = _conditions.find((c) => c.id === id);
    _condition.groupBreak = groupBreak;
    setConditions(_conditions);
  }

  function handleChangeOperandMode(operandMode) {
    setReportsData({
      type: ContextProviderActions.loadReport,
      payload: { ...report, operandMode: operandMode },
    });
  }

  function handleEditCondition(id) {
    const condition = conditions.find((c) => c.id === id);
    setEditCondition(condition);
    setFilterFields(getFieldsForFilterSection());
    setShowModal(true);
  }

  function getCustomDataTypeString(dt) {
    let dataType = "string";

    switch (dt) {
      case "string":
        dataType = "Text";
        break;
      case "date":
        dataType = "DateTime";
        break;
      case "numeric":
        dataType = "Decimal";
        break;
      case "boolean":
        dataType = "Boolean";
        break;
      default:
        dataType = "Text";
        break;
    }

    return dataType;
  }

  function getFieldsForFilterSection() {
    if (!fields || fields.length === 0) return [];

    // Determine which fields are filterable
    const filterableFields = fields.filter((f) => f.allowFiltering !== false);

    // Get fields from the select list that are filterable
    const fieldList = [];
    let ff;
    for (let i = 0; i < selectFields.length; i++) {
      // Handle custom fields
      const isCustomField = selectFields[i].isCustom;

      ff = filterableFields.find(
        (field) => field.value === selectFields[i].fieldId
      );
      if (ff || isCustomField) {
        // We need datatype for the conditions dropdown to filter properly in the dialog
        fieldList.push({
          aggregateFunction: selectFields[i].aggregateFunction,
          fieldId: selectFields[i].fieldId,
          value:
            selectFields[i].fieldId + "#" + selectFields[i].fieldDisplayName,
          fieldDisplayName: selectFields[i].fieldDisplayName,
          label: selectFields[i].fieldDisplayName,
          entityType: isCustomField ? "" : ff.entityType,
          datatype: isCustomField
            ? getCustomDataTypeString(selectFields[i].customDataType)
            : ff.datatype,
        });
      }
    }

    // Add filterable fields to the end of the list that do not appear in the field list already (i.e., select fields),
    //   but only do this for non-aggregates. It is ok to have aggregates added since that would be the underlying field.
    for (let i = 0; i < filterableFields.length; i++) {
      if (
        fieldList.findIndex(
          (f) =>
            f.fieldId === filterableFields[i].value &&
            f.aggregateFunction === "None"
        ) < 0
      ) {
        fieldList.push({
          aggregateFunction: "None",
          fieldId: filterableFields[i].value,
          value:
            filterableFields[i].value + "#" + filterableFields[i].propertyLabel,
          fieldDisplayName: filterableFields[i].propertyLabel,
          label: filterableFields[i].label,
          entityType: filterableFields[i].entityType,
          datatype: filterableFields[i].datatype,
        });
      }
    }

    return fieldList;
  }

  function getOperatorListByFieldType(fieldId) {
    let opsList = operators.map((o) => {
      return { value: o.value, label: o.label };
    });
    if (!fieldId) return opsList;

    const field = filterFields.find((field) => field.value === fieldId);
    if (!field) return opsList;

    // If the field value type is "Array", then this overrides the datatype operator.
    if (field.valueType === "Array") {
      opsList = opsList.filter((o) => arrayOperators.indexOf(o.value) >= 0);
      return opsList;
    }

    switch (field.datatype) {
      case "Int32":
      case "Decimal":
      case "Byte":
        opsList = opsList.filter((o) => numberOperators.indexOf(o.value) >= 0);
        break;
      case "DateTime":
      case "DateOnly":
        opsList = opsList.filter(
          (o) => datetimeOperators.indexOf(o.value) >= 0
        );
        break;
      case "Boolean":
        opsList = opsList.filter((o) => booleanOperators.indexOf(o.value) >= 0);
        break;
      default:
        // Default to string types for stuff like Guid, Byte, etc.
        opsList = opsList.filter((o) => stringOperators.indexOf(o.value) >= 0);
        break;
    }

    return opsList;
  }

  function getConditionTree() {
    if ((conditions || []).length === 0) return null;

    //const _conditions = getPreProcessedConditionsArray(conditions);

    // Reverse the conditions array to build the tree in the correct order.
    const stack = _.reverse([...conditions]);
    const indentLevel = -1;
    const operandMode = report.operandMode || "And";
    const rootNode = getEmptyContainerNode(operandMode, indentLevel, null);

    buildConditionTree(stack, rootNode, indentLevel + 1, operandMode);
    return rootNode;
  }

  const conditionTree = getConditionTree();

  return (
    <>
      <StyledSectionHeader>
        <h3>
          {title}{" "}
          <i style={{ fontSize: "14px", paddingLeft: "10px" }}>
            (Double-click a condition to add/remove group break)
          </i>
        </h3>
        <p className="d-none d-md-block">
          <StyledBlockLetter>P</StyledBlockLetter> = Parameter
        </p>
      </StyledSectionHeader>
      <p className="d-block d-md-none">
        <StyledBlockLetter>P</StyledBlockLetter> = Parameter
      </p>
      {disabled ? (
        <StyledChipContainer>
          {conditionTree && (
            <ReportFilterConditionContainer
              operandMode={report.operandMode}
              conditionNode={conditionTree}
              parentNode={null}
              disabled={disabled}
              selectFields={selectFields}
            />
          )}
        </StyledChipContainer>
      ) : (
        <DndContext
          autoScroll={false}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => onDragEnd(event)}
        >
          <SortableContext items={conditions} strategy={rectSortingStrategy}>
            <StyledChipContainer>
              {conditionTree && (
                <ReportFilterConditionContainer
                  operandMode={report.operandMode}
                  conditionNode={conditionTree}
                  parentNode={null}
                  disabled={disabled}
                  selectFields={selectFields}
                  onRemoveCondition={handleRemoveCondition}
                  onEditCondition={handleEditCondition}
                  onSetConditionIndentLevel={handleSetConditionIndentLevel}
                  onChangeGroupBreak={handleChangeGroupBreak}
                  onChangeOperandMode={handleChangeOperandMode}
                />
              )}

              <PlaceholderChip
                label="Add condition"
                onClick={handleAddNewCondition}
              ></PlaceholderChip>
            </StyledChipContainer>
          </SortableContext>
        </DndContext>
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <Modal
        show={showModal}
        onHide={closeAddConditionDialog}
        animation={!disableAnimations()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editCondition && editCondition.id
              ? "Edit Condition"
              : "Add Condition"}
            <HelpLink
              path="/Reports/Report-Screen&anchor=condition-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectInput
            id="selectfield"
            name="selectfield"
            label="Field"
            options={filterFields}
            value={
              editCondition?.fieldUniqueId && {
                value: editCondition.fieldUniqueId,
                label: filterFields.find(
                  (f) => f.value === editCondition.fieldUniqueId
                )?.label,
              }
            }
            onChange={handleFieldChange}
            placeholder=""
            error={errors.selectfield}
          />
          <SelectInput
            id="operator"
            name="operator"
            label="Operator"
            options={getOperatorListByFieldType(editCondition?.fieldId)}
            value={
              editCondition?.operator
                ? {
                    value: editCondition.operator,
                    label: operators.find(
                      (o) => o.value === editCondition.operator
                    )?.label,
                  }
                : { value: "0", label: "is equal to" }
            }
            onChange={handleOperatorChange}
            placeholder=""
            error={errors.operator}
          />
          <FilterValueFieldByDataType
            id="value"
            label="Value"
            onChange={handleChange}
            placeholder=""
            name="value"
            value={editCondition?.value}
            displayAsTextControl={true}
            error={errors.value}
            disabled={!operatorRequiresValue(editCondition?.operator)}
            field={filterFields.find(
              (f) => f.value === editCondition?.fieldUniqueId
            )}
            operator={editCondition?.operator}
          />
          <CheckboxInput
            id="isParameter"
            label="Parameter"
            onChange={() =>
              handleParameterChange(
                editCondition?.isParameter === true ? false : true
              )
            }
            placeholder="Parameter"
            name="isParameter"
            checked={editCondition?.isParameter ?? false}
            error={errors.isParameter}
          />
          <TextInput
            id="parameterLabel"
            label="Parameter Label"
            onChange={handleChange}
            placeholder=""
            name="parameterLabel"
            value={editCondition?.parameterLabel || ""}
            error={errors.parameterLabel}
            disabled={editCondition?.isParameter !== true}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={saveCondition}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            <span className="material-icons">done</span>
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={closeAddConditionDialog}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const StyledSectionHeader = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;

  p,
  h3 {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  p {
    margin-left: auto;
    text-align: right;
    font-style: italic;

    button {
      line-height: 31px;
    }
  }
`;

const StyledChipContainer = styled.div`
  padding: 15px;
  min-height: 58px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  display: flex;
  align-items: left;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 8px;
`;

export default ReportFilterSection;
