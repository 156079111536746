import React, { useState, useEffect } from "react";
import Spinner from "../../common/ui/Spinner";
import { notifyError } from "../../../services/NotificationService";
import {
  createViewModel,
  emptyTestGroupResult,
  emptyTestGroupResultSearch,
} from "../../../viewmodels/testResultsVm";
import {
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
  handleCollapseExpandAll,
} from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import { useTestResults } from "../../../contexts/TestResultsContext";
import { useAuth } from "../../../contexts/AuthContext";
import { apiLoadTestGroupResults } from "../../../api/TestApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import ReadOnly from "../../common/input/ReadOnly";
import StatusPill from "../../common/ui/StatusPill";
import TestGroupResultsList from "./TestGroupResultsList";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import HelpLink from "../../common/ui/HelpLink";

function TestGroupResults() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { testResultsData, setTestResultsData } = useTestResults();
  const { loading, api: apiLoad } = useApi(apiLoadTestGroupResults);
  const [testSearch, setTestSearch] = useState(emptyTestGroupResultSearch);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Test Group", collapsed: false },
    { name: "Run Info", collapsed: false },
    { name: "Test Results", collapsed: false },
  ]);

  const groupId = params && params.testGroupId;
  const resultId = params && params.testResultId;
  const groupResult = testResultsData && testResultsData.testGroupResult;

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setTestResultsData({
        type: ContextProviderActions.loadTestGroupResult,
        payload: emptyTestGroupResult,
      });

      loadTestGroup();
    }
  }, [auth.authenticated, params?.testGroupId, params?.testResultId]);

  async function loadTestGroup() {
    if (!(groupId && resultId)) {
      notifyError(
        "You must provide both a testGroupId and testResultId to view this page."
      );
      return;
    }

    apiLoad.call(
      { testGroupId: groupId, testResultId: resultId },
      async (result) => {
        let vm = emptyTestGroupResult;
        if (!result) {
          notifyError("Test Group Result does not exist");
        } else {
          vm = createViewModel(result);
        }

        setTestResultsData({
          type: ContextProviderActions.loadTestGroupResult,
          payload: vm,
        });
      }
    );
  }

  async function handleRunTestGroup() {
    navigate("/testgroup/" + groupId + "/true");
  }

  function handleInstantSearchChange(value) {
    setTestSearch({ ...testSearch, highlightText: value });
  }

  const working = loading;

  return (
    <Authorize>
      <StyledBackButtonDiv>
        <button
          type="button"
          title="Return to previous screen"
          className="btn btn-link btn-with-icon"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink
            path="/Testing/Test-Group-Results-List-Screen"
            label="Help"
          />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1>
          Test Group Results{" "}
          {groupResult.description ? ` | ${groupResult.description}` : ""}
        </h1>
        <StyledHeaderRowButtonDiv>
          {!working && (
            <button
              type="button"
              className="btn btn-secondary btn-with-icon"
              onClick={handleRunTestGroup}
            >
              <>
                <span className="material-icons">play_circle_outline</span>
                {"  "}Run Again
              </>
            </button>
          )}
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>

      {working ? (
        <Spinner />
      ) : (
        <>
          <ActionMenu
            title="Actions"
            items={[
              { value: "ExpandAll", label: "Expand All" },
              { value: "CollapseAll", label: "Collapse All" },
            ]}
            onSelectAction={(value, label) =>
              handleCollapseExpandAll(
                value === "CollapseAll",
                collapsedState,
                setCollapsedState
              )
            }
          />
          <div className="container-fluid" style={{ marginTop: "5px" }}>
            <ExpandCollapseDetailSection
              sectionTitle="Test Group"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Testing/Test-Group-Results-List-Screen&anchor=test-group-section"
            >
              <StyledRowDiv className="row">
                <div className="col-12 col-md-6">
                  <button
                    type="button"
                    title="Edit test group"
                    className="btn btn-link"
                    onClick={() => navigate("/testgroup/" + groupResult.id)}
                  >
                    {groupResult.description}
                  </button>
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
            <ExpandCollapseDetailSection
              sectionTitle="Run Info"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Testing/Test-Group-Results-List-Screen&anchor=run-info-section"
            >
              <StyledRowDiv className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Result</label>
                    <div className="field">
                      <StatusPill status={groupResult.lastRunResultStatus} />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <ReadOnly
                    id="lastRunDate"
                    label="Run Date"
                    name="lastRunDate"
                    value={formatDateTimeUtcZoneForDisplay(
                      groupResult.lastRunDate
                    )}
                  />
                </div>
                <div className="col-6">
                  <ReadOnly
                    id="lastRunDurationMilliseconds"
                    label="Duration"
                    name="lastRunDurationMilliseconds"
                    value={getTimeframeFromMilliseconds(
                      groupResult.lastRunDurationMilliseconds
                    )}
                  />
                </div>
                <div className="col-6">
                  <ReadOnly
                    id="lastRunBy"
                    label="Run By"
                    name="lastRunBy"
                    value={groupResult.lastRunBy}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
            <ExpandCollapseDetailSection
              sectionTitle="Test Results"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Testing/Test-Group-Results-List-Screen&anchor=test-results-section"
            >
              <StyledRowDiv className="row">
                <div className="col-12">
                  <StyledHeaderRowDiv
                    style={{ marginTop: "5px", marginBottom: "10px" }}
                  >
                    <InstantSearchInput
                      id="screenSearchInput"
                      onChange={handleInstantSearchChange}
                      value={testSearch.highlightText}
                    />
                  </StyledHeaderRowDiv>
                  <TestGroupResultsList
                    testResultId={resultId}
                    tests={groupResult.tests}
                    totalRecords={groupResult.tests?.length || 0}
                    search={testSearch}
                    setSearch={setTestSearch}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
          </div>
        </>
      )}
    </Authorize>
  );
}

export default TestGroupResults;
