import React from "react";
import _ from "lodash";
import { StyledCard, StyledDescription } from "./RuleGroupSummaryCommonStyles";
import { useAuth } from "../../../contexts/AuthContext";

function RuleGroupSummaryRule({ item, priority }) {
  const { auth } = useAuth();
  const id = _.lowerCase(`rule-${item.key}`);

  return (
    <StyledCard id={id}>
      <StyledDescription>
        <span className="charblock">{priority}</span>
        <span style={{ paddingLeft: "10px" }}>
          {auth.isAdmin ? (
            <a target="rule" className="link" href={`/rule/${item.id}`}>
              {item.description}
            </a>
          ) : (
            <>{item.description}</>
          )}
        </span>
      </StyledDescription>
    </StyledCard>
  );
}

export default RuleGroupSummaryRule;
