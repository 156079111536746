import React, { useEffect, useState } from "react";
import _ from "lodash";
import { notifyWarn } from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import { Modal } from "react-bootstrap";
import {
  disableAnimations,
  rejectionLevelTypes,
} from "../../../services/General";
import HelpLink from "../../common/ui/HelpLink";
import { useGroupRuleMaps } from "../../../contexts/GroupRuleMapsContext";
import ReadOnly from "../../common/input/ReadOnly";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
  NodeTypes,
} from "../rulemaps/RuleMapDataCommon";
import { StyledCharblockDiv } from "../rulemaps/RuleMapCommonStyles";
import DatePickerInput from "../../common/input/DatePickerInput";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import SelectInput from "../../common/input/SingleSelect";
import CheckboxInput from "../../common/input/CheckboxInput";
import RuleEditorInput from "../../common/input/RuleEditorInput";
import ToggleButtonGroupInput from "../../common/input/ToggleButtonGroupInput";
import CreatableSelectInput from "../../common/input/CreatableSelectInput";

function GroupRuleMapEditFieldDialog({ showModal, onSaveItem, onCancelItem }) {
  const { groupRuleMapsData } = useGroupRuleMaps();
  const { ruleMapsData } = useRuleMaps();
  const [errors, setErrors] = useState({});
  const [editItem, setEditItem] = useState(null);
  const [changes, setChanges] = useState(null);
  const [attachType, setAttachType] = useState("Flag");
  const [ruleOptions, setRuleOptions] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);

  const isAdd = editItem === null ? true : editItem.isNew;

  useEffect(() => {
    if (groupRuleMapsData && groupRuleMapsData.editField) {
      // On dialog open, set changes to the current edit field so we can detect when we have actual changes
      setEditItem(groupRuleMapsData.editField);
      setChanges(groupRuleMapsData.editField);
      setAttachType(
        _.isEmpty(groupRuleMapsData.editField.flagName) ? "Module" : "Flag"
      );
    } else {
      setEditItem(null);
      setChanges(null);
    }
  }, [groupRuleMapsData?.editField]);

  useEffect(() => {
    if (ruleMapsData && ruleMapsData.ruleMap) {
      // Get rule options for dropdown - keep in hierarchy order
      const rules = ruleMapsData.ruleMap.ruleMetadata.map((r) => {
        return { value: r.id, label: r.name };
      });
      setRuleOptions(rules);

      // Get module options for dropdown - order by name
      const modules = ruleMapsData.ruleMap.modules
        .map((r) => {
          return { value: r.name, label: r.name };
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1));
      setModuleOptions(modules);
    }
  }, [ruleMapsData?.ruleMap]);

  function formIsValid() {
    const _errors = {};

    if (!_.has(changes, "identifier") || changes.identifier.trim() === "")
      _errors.identifier = "Name must be entered";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSubmit(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };
    onSaveItem(newVm);
  }

  function getIncludedDataValueTypes() {
    const valueTypes = [];
    valueTypes.push({
      value: NodeTypes.RequestFieldValue.description,
      label: "Request Field Value",
    });
    valueTypes.push({
      value: NodeTypes.ResponseFieldValue.description,
      label: "Response Field Value",
    });
    valueTypes.push({
      value: NodeTypes.InternalVariable.description,
      label: "Internal Variable",
    });
    valueTypes.push({
      value: NodeTypes.TransmissionMessage.description,
      label: "Transmission Message",
    });
    valueTypes.push({
      value: NodeTypes.TransactionMessage.description,
      label: "Transaction Message",
    });
    return valueTypes;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleEffectiveDateChange(date) {
    setChanges({ ...changes, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  function handleRuleChange(option) {
    setChanges({ ...changes, ruleId: option.value });
  }

  function handleStagingChange({ target }) {
    let changed = { ...changes };
    changed.staging = target.checked;
    setChanges(changed);
  }

  function handleRejectionLevelChange(option) {
    setChanges({
      ...changes,
      level: option.value,
    });
  }

  function handleValueTypeChange(option) {
    setChanges({
      ...changes,
      valueType: option.value,
    });
  }

  function handleModuleChange(option) {
    const value = option === null ? "" : option.value;
    let changed = { ...changes };
    if ((changed.modules || []).length < 1) {
      changed.modules = [];
      changed.modules.push({ name: value, moduleType: "Primary" });
    } else {
      changes.modules[0].name = value;
    }
    setChanges(changed);
  }

  function handleAttachTypeChange(value) {
    setAttachType(value);
    if (value === "Flag") {
      setChanges({
        ...changes,
        modules: [],
      });
    } else {
      setChanges({
        ...changes,
        flagName: "",
      });
    }
  }

  function shouldDisableRuleSelection() {
    let disable = false;

    if (
      changes.type === NodeTypes.CodeArtifact ||
      changes.type === NodeTypes.CodeArtifactInput ||
      changes.type === NodeTypes.CodeArtifactOutput
    ) {
      disable = true;
    }

    return disable;
  }

  function getSelectedRule() {
    const selected = {
      value: "-1",
      label: "(No rule associated with this field)",
    };

    if (!_.isEmpty(changes.ruleId) && changes.ruleId !== "-1") {
      selected.value = changes.ruleId;
      selected.label =
        ruleOptions.find((r) => r.value === changes.ruleId)?.label ??
        "Rule not found";
    }

    return selected;
  }

  function getSelectedModule() {
    const selected = {
      value: "-1",
      label: "(Select or enter a module...)",
    };

    if ((changes.modules || []).length > 0) {
      selected.value = changes.modules[0].name;
      selected.label = selected.value;
    }

    return selected;
  }

  function getSelectedRejectionLevel() {
    const selected = {
      value: "-1",
      label: "(Select a Level)",
    };

    if (!_.isEmpty(changes.level) && changes.level !== "-1") {
      selected.value = changes.level;
      selected.label =
        rejectionLevelTypes.find((r) => r.value === changes.level)?.label ??
        "Level not found";
    }

    return selected;
  }

  function getSelectedIncludedDataValueType() {
    const selected = {
      value: "-1",
      label: "(Select a Value Type)",
    };
    const valueTypes = getIncludedDataValueTypes();

    if (!_.isEmpty(changes.valueType) && changes.valueType !== "-1") {
      selected.value = changes.valueType;
      selected.label =
        valueTypes.find((r) => r.value === changes.valueType)?.label ??
        "Value Type not found";
    }

    return selected;
  }

  if (!editItem || editItem === null) return <></>;

  return (
    <>
      <Modal
        show={showModal}
        onHide={onCancelItem}
        animation={!disableAnimations()}
        dialogClassName="modal-dialog-large-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isAdd ? "Add" : "Edit"} Field
            <HelpLink
              path="/Group-Rule-Map/Group-Rule-Map-Screen&anchor=field-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ReadOnly
              id="displayKey"
              label=""
              labelStyle={{ display: "none" }}
              name="displayKey"
              value={`${changes.identifier || ""}/${changes.itemKey || ""}`}
            />
            <ReadOnly
              id="type"
              label=""
              labelStyle={{ display: "none" }}
              name="type"
              value={
                <>
                  <StyledCharblockDiv
                    className="charblock"
                    colors={getBadgeColorsFromType(changes.type)}
                    style={{ marginRight: "8px" }}
                  >
                    {getNodeTypeAbbreviation(changes.type)}
                  </StyledCharblockDiv>
                  {changes.type.description}
                </>
              }
            />
            <br />
            <TextInput
              id="identifier"
              label="Identifier"
              onChange={handleChange}
              placeholder="Identifier"
              name="identifier"
              autoFocus={true}
              value={changes.identifier}
              error={errors.identifier}
            />
            <TextInput
              id="itemKey"
              label="Item Key"
              onChange={handleChange}
              placeholder="Item Key"
              name="itemKey"
              value={changes.itemKey}
              error={errors.itemKey}
            />
            <br />
            <ToggleButtonGroupInput
              id="isFlagType"
              name="isFlagType"
              label="Attach Type"
              options={[
                { value: "Flag", label: "Flag" },
                { value: "Module", label: "Module" },
              ]}
              value={attachType}
              onChange={handleAttachTypeChange}
            />
            {attachType === "Flag" && (
              <TextInput
                id="flagName"
                label="Flag"
                onChange={handleChange}
                placeholder="Flag"
                name="flagName"
                value={changes.flagName || ""}
                error={errors.flagName}
              />
            )}
            {attachType === "Module" && (
              <>
                <CreatableSelectInput
                  id="module"
                  name="module"
                  label="Module"
                  options={moduleOptions}
                  value={getSelectedModule()}
                  onChange={handleModuleChange}
                  placeholder="Select or enter a module..."
                  error={errors.module}
                />
              </>
            )}
            <br />
            {changes.type === NodeTypes.InternalVariable ? (
              <>
                <SelectInput
                  id="valueType"
                  name="valueType"
                  label="Value Type"
                  options={getIncludedDataValueTypes()}
                  value={getSelectedIncludedDataValueType()}
                  onChange={handleValueTypeChange}
                  placeholder=""
                  error={errors.valueType}
                />
                <RuleEditorInput
                  id="itemValue"
                  label="Item Value"
                  onChange={handleChange}
                  placeholder="Item Value"
                  name="itemValue"
                  value={changes.itemValue}
                  error={errors.itemValue}
                />
              </>
            ) : (
              <>
                <TextInput
                  id="itemValue"
                  label="Item Value"
                  onChange={handleChange}
                  placeholder="Item Value"
                  name="itemValue"
                  value={changes.itemValue}
                  error={errors.itemValue}
                />
              </>
            )}
            {changes.type === NodeTypes.Rejection && (
              <SelectInput
                id="level"
                name="level"
                label="Level"
                options={[...rejectionLevelTypes]}
                value={getSelectedRejectionLevel()}
                onChange={handleRejectionLevelChange}
                placeholder=""
                error={errors.level}
              />
            )}
            <br />
            <SelectInput
              id="ruleId"
              name="ruleId"
              label="Rule"
              options={ruleOptions}
              value={getSelectedRule()}
              onChange={handleRuleChange}
              placeholder="Select a Rule"
              disabled={shouldDisableRuleSelection()}
              error={errors.ruleId}
            />
            <br />

            <DatePickerInput
              id="effectiveDate"
              name="effectiveDate"
              label="Effective Date"
              value={changes.effectiveDate}
              placeholder="Effective Date"
              onChange={handleEffectiveDateChange}
              showTimeInput={false}
              error={errors.effectiveDate}
            />
            <DatePickerInput
              id="terminationDate"
              label="Termination Date"
              name="terminationDate"
              value={changes.terminationDate}
              placeholder="Termination Date"
              onChange={handleTerminationDateChange}
              showTimeInput={false}
              error={errors.terminationDate}
            />
            <CheckboxInput
              id="staging"
              label="Staging"
              onChange={handleStagingChange}
              placeholder="Staging"
              name="staging"
              checked={changes.staging}
              error={errors.staging}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "86px",
            }}
          >
            {isAdd ? "Add" : "Save"} Field
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancelItem}
            style={{ marginLeft: "12px" }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GroupRuleMapEditFieldDialog;
