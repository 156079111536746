import React from "react";
import TextInput from "../../common/input/TextInput";
import { useMobile } from "../../../hooks/useMobile";
import MultiSelectInput from "../../common/input/MultiSelectInput";
import { testResultTypes, testRunResultTypes } from "../../../services/General";
import DatePickerInput from "../../common/input/DatePickerInput";

function TestRunResultsSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onResultTypeChanged,
  onRunResultStatusChanged,
  onStartDateChange,
  onEndDateChange,
}) {
  const { isMobileSize } = useMobile();
  const runResultTypes = [
    ...testRunResultTypes.filter((t) => t.value !== "NotRun"),
  ];

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="description"
            label="Description"
            onChange={onChange}
            placeholder="Description"
            name="description"
            value={search.description}
            error={errors.description}
          />
          <TextInput
            id="runBy"
            label="Run By"
            onChange={onChange}
            placeholder="Run by"
            name="runBy"
            value={search.runBy}
            error={errors.runBy}
          />
          <MultiSelectInput
            id="resultType"
            name="resultType"
            label="Result Type"
            options={[...testResultTypes]}
            value={search.resultType}
            onChange={onResultTypeChanged}
            placeholder="All"
            error={errors.resultType}
          />
          <MultiSelectInput
            id="runResultStatus"
            name="runResultStatus"
            label="Run Result Status"
            options={runResultTypes}
            value={search.runResultStatus}
            onChange={onRunResultStatusChanged}
            placeholder="All"
            error={errors.runResultStatus}
          />
          <DatePickerInput
            id="startDate"
            name="startDate"
            label="Run Start Date"
            value={search.startDate}
            placeholder=""
            onChange={onStartDateChange}
            error={errors.startDate}
          />
          <DatePickerInput
            id="endDate"
            label="Run End Date"
            name="endDate"
            value={search.endDate}
            placeholder=""
            onChange={onEndDateChange}
            error={errors.endDate}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default TestRunResultsSearchForm;
