import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function RuleTableDefinitionsList({
  isAdmin,
  ruleTableDefinitions,
  search,
  setSearch,
  onSort,
  totalRecords,
  onDelete,
  onCopy,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const name = dataRow.name;
    const isUsedOnRule = (dataRow.ruleTables || []).length > 0;
    const securityLevel = dataRow.securityLevelVm;

    const actions = [];

    actions.push({
      name: "Edit",
      onClick: () => navigate("/ruletabledefinition/" + id),
    });

    actions.push({
      name: "Duplicate",
      onClick: () => onCopy(name),
    });

    // Cannot delete this definition if it is used on a rule.
    //   Also cannot delete if it is a System definition (unless user is admin)
    if (
      isUsedOnRule !== true &&
      (securityLevel !== "System" || (securityLevel === "System" && isAdmin))
    ) {
      actions.push({
        name: "Remove",
        onClick: () => onDelete(id, name),
      });
    }

    if (auth.isAdmin) {
      actions.push({
        name: "View Change History",
        onClick: () =>
          navigate("/auditrecord/ruletabledefinition/" + id + "/0"),
      });
    }

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="RuleTableDefinitions"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={ruleTableDefinitions}
        columnDefs={[
          {
            name: "name",
            label: "Name",
            style: { width: "25%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                onClick={() => navigate("/ruletabledefinition/" + row.id)}
              >
                {row.name}
              </button>
            ),
          },
          {
            name: "description",
            label: "Description",
            style: { width: "40%" },
          },
          {
            name: "numColumns",
            label: "# of Columns",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) => (row.columns || []).length,
          },
          {
            name: "numRuleTablesUsed",
            label: "# of Rule Tables",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) => (row.ruleTables || []).length,
          },
          {
            name: "securityLevel",
            label: "Security",
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) => row.securityLevelVm?.label,
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default RuleTableDefinitionsList;
