import React from "react";
import PropTypes from "prop-types";

function TextInput({
  name,
  id,
  value,
  label,
  error,
  disabled,
  onChange,
  placeholder,
  labelStyle,
  highlightColor,
  onKeyDown,
  onBlur,
  autoFocus,
  isNumber,
  isSearch,
}) {
  // if (value === null) debugger;
  let wrapperClass = "form-group";
  if (error.length > 0) {
    wrapperClass += " has-error";
  }
  return (
    <div className={wrapperClass}>
      <label htmlFor={id} style={labelStyle || {}}>
        {label}
      </label>
      <div
        className="field"
        style={{ width: isNumber === true ? "120px" : "inherit" }}
      >
        <input
          autoFocus={autoFocus || false}
          id={id}
          type={
            isNumber === true ? "number" : isSearch === true ? "search" : "text"
          }
          name={name}
          className="form-control"
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          style={{
            outline: "none !important",
            borderColor: highlightColor,
            boxShadow: "0 0 10px " + highlightColor,
          }}
        ></input>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  highlightColor: PropTypes.string,
};

TextInput.defaultProps = {
  error: "",
};

export default TextInput;
