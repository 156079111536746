import React from "react";
import _ from "lodash";
import {
  StyledCard,
  StyledDescription,
  StyledRuleLink,
} from "./RuleGroupSummaryCommonStyles";

function RuleGroupSummaryResponseValue({
  item,
  showFriendly,
  showValue,
  showRuleLink,
  getFormattedCondition,
}) {
  const id = _.lowerCase(`prop-${item.key}`);
  let desc = showFriendly ? item.friendlyCondition : item.condition;

  return (
    <StyledCard id={id}>
      <StyledDescription>
        <span className="charblock">{item.key}</span>
        <span style={{ paddingLeft: "10px" }}>{item.description}</span>
      </StyledDescription>
      {showValue && (
        <>
          {showRuleLink && (
            <StyledRuleLink>
              <a target="rule" className="link" href={`/rule/${item.ruleId}`}>
                {item.ruleName}
              </a>
            </StyledRuleLink>
          )}
          <div style={{ padding: "20px 20px 0 0" }} className="rule-summary">
            {getFormattedCondition(id, `_=_ ${desc}`)}
          </div>
        </>
      )}
    </StyledCard>
  );
}

export default RuleGroupSummaryResponseValue;
