import _ from "lodash";

export const emptyHbDetectorConfig = {
  id: "",
  storageConnectionString: "",
  logLocation: "",
  appSettings: {}, // emptyHbDetectorAppSettings
  apiSettings: {}, // emptyHbDetectorApiSettings
  mailSettings: {}, // emptyHbDetectorMailSettings
  smsSettings: {}, // emptyHbDetectorSmsSettings
};

export const emptyHbDetectorAppSettings = {
  socketServerAddress: "",
  socketServerPort: 0,
  connectionTimeoutSeconds: 10,
  sendTestPacketEveryXSeconds: 30,
  notifyThresholdMinutes: 5,
};

export const emptyHbDetectorApiSettings = {
  apiAddress: "",
  clientId: "",
  tenantId: "",
  clientSecret: "",
};

export const emptyHbDetectorMailSettings = {
  host: "",
  port: 0,
  username: "",
  password: "",
  emailSubject: "",
  recipientCSVList: "",
};

export const emptyHbDetectorSmsSettings = {
  connectionString: "",
  fromPhoneNumber: "",
  toPhoneNumbersCSV: "",
};

export function fromViewModel(vm) {
  vm = _.cloneDeep(vm);

  let m = vm;
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);

  return vm;
}
