import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import Spinner from "../../common/ui/Spinner";
import { useMobile } from "../../../hooks/useMobile";
import HelpLink from "../../common/ui/HelpLink";

function CacheKeyContentsDialog({
  keyName,
  contents,
  showDialog,
  loading,
  onCloseDialog,
}) {
  const { isMobileSize, isTabletSize } = useMobile();

  const isDesktopSize = !(isMobileSize || isTabletSize);

  const jsonData =
    contents === "" ? "" : JSON.stringify(contents, null, "    ");

  return (
    <>
      <Modal
        dialogClassName="modal-dialog-full-width"
        show={showDialog}
        onHide={onCloseDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Cache Contents for Key
            <HelpLink
              path="/Tools/Cache-Status-Screen&anchor=cache-contents-for-key-dialog"
              label=""
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              padding: "10px 20px",
              overflowY: isDesktopSize ? "scroll" : "inherit",
              maxHeight: isDesktopSize ? "80vh" : "inherit",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                <h4>{keyName}</h4>
                <StyledDocumentDisplay>
                  <pre>{jsonData}</pre>
                </StyledDocumentDisplay>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCloseDialog}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const StyledDocumentDisplay = styled.div`
  font-family: courier;
  font-size: 14px;
  line-height: 18px;
`;

export default CacheKeyContentsDialog;
