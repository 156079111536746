import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import { useReportTemplates } from "../../../contexts/ReportTemplatesContext";
import { useReportProcesses } from "../../../contexts/ReportProcessesContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";

import {
  emptyReportProcess,
  createViewModel,
  fromViewModel,
} from "../../../viewmodels/reportProcessesVm";

import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import {
  apiLoadReportProcess,
  apiAddReportProcess,
  apiUpdateReportProcess,
  apiRunReportProcess,
  apiClearProcessHistory,
} from "../../../api/ReportProcessesApi";
import {
  apiLoadFieldsForDataSource,
  apiLoadReportsAll,
} from "../../../api/ReportsApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Spinner from "../../common/ui/Spinner";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import SelectInput from "../../common/input/SingleSelect";
import TextInput from "../../common/input/TextInput";
import {
  notifySuccess,
  notifyWarn,
  notifyError,
} from "../../../services/NotificationService";
import {
  REPORT_TEMPLATES_RULE_ID,
  decodeBase64Stream,
  download,
  formatDateTimeUtcZoneForDisplay,
  getExtensionFromFilename,
  getTimeframeFromMilliseconds,
  handleCollapseExpandAll,
} from "../../../services/General";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import { apiLoadAllRuleTablesForRule } from "../../../api/RuleTableApi";
import ReadOnly from "../../common/input/ReadOnly";
import FilterValueFieldByDataType from "../../common/ui/FilterValueFieldByDataType";
import { useProcessHistory } from "../../../contexts/ProcessHistoryContext";
import { emptyProcessHistorySearch } from "../../../viewmodels/processHistoryVm";
import { apiDownloadProcessFile } from "../../../api/ProcessHistoryApi";
import ReportProcessLastRunSavedData from "./ReportProcessLastRunSavedData";
import CheckboxInput from "../../common/input/CheckboxInput";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import HelpLink from "../../common/ui/HelpLink";

function ReportProcess() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { reportProcessesData, setReportProcessesData } = useReportProcesses();
  const { setProcessHistoryData } = useProcessHistory();
  const { reportTemplatesData, setReportTemplatesData } = useReportTemplates();
  const { loading, api: apiLoad } = useApi(apiLoadReportProcess);
  const { loading: loadingReports, api: apiLoadReports } =
    useApi(apiLoadReportsAll);
  const { loading: loadingRuleTables, api: apiLoadTemplateRuleTables } = useApi(
    apiLoadAllRuleTablesForRule
  );
  const { loading: loadingFields, api: apiLoadFields } = useApi(
    apiLoadFieldsForDataSource
  );
  const { loading: addingProcess, api: apiAdd } = useApi(apiAddReportProcess);
  const { loading: updatingProcess, api: apiUpdate } = useApi(
    apiUpdateReportProcess
  );
  const { loading: running, api: apiRun } = useApi(apiRunReportProcess);
  const { loading: downloading, api: apiDownload } = useApi(
    apiDownloadProcessFile
  );
  const { loading: resettingHistory, api: apiResetHistory } = useApi(
    apiClearProcessHistory
  );
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyReportProcess);
  const [lastSavedChanges, setLastSavedChanges] = useState(emptyReportProcess);
  const [hasChanges, setHasChanges] = useState(false);
  const [fields, setFields] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportParameters, setReportParameters] = useState([]);
  const [showResetModal, setShowResetModal] = useState(false);

  const [collapsedState, setCollapsedState] = useState([
    { name: "Report Process", collapsed: false },
    { name: "Process Data", collapsed: false },
    { name: "Last Run Info", collapsed: false },
  ]);

  const reportProcess =
    (reportProcessesData && reportProcessesData.process) || {};
  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      loadReportProcessAndDataLists();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (reportProcessesData.process) {
      setChanges(reportProcessesData.process);
    } else {
      setChanges(emptyReportProcess);
    }
  }, [reportProcessesData.process]);

  useEffect(() => {
    setHasChanges(
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
        JSON.stringify(_.cloneDeep(changes))
    );
  }, [lastSavedChanges, changes]);

  async function loadReportProcessAndDataLists() {
    let reportList = reportProcessesData.reportListCache || [];
    let ruleTableList = reportTemplatesData.ruleTableListCache || [];

    if ((reportList || []).length === 0) {
      reportList = await loadReportsList();

      // Save the list of data sources in the cache
      setReportProcessesData({
        type: ContextProviderActions.setReportCache,
        payload: reportList,
      });
    }

    if ((ruleTableList || []).length === 0) {
      ruleTableList = await loadTemplateRuleTableList();

      // Save the list of rule tables in the cache
      setReportTemplatesData({
        type: ContextProviderActions.setReportRuleTableCache,
        payload: ruleTableList,
      });
    }

    await loadReportProcess(reportList, ruleTableList);
  }

  async function loadReportsList() {
    const reportsList = await apiLoadReports.call(null, async (result) => {
      let dsResult = ([...result.resources] || []).sort((a, b) =>
        a.name > b.name ? 1 : -1
      );

      let dsList = [
        {
          value: "-1",
          label: "(Select a report)",
          reportTemplateId: "",
          externalDataRuleTableId: "",
        },
      ];

      for (let i = 0; i < dsResult.length; i++) {
        dsList.push({
          value: dsResult[i].id,
          label: dsResult[i].name,
          entityType: dsResult[i].entityType,
          reportTemplateId: dsResult[i].reportTemplateId,
          externalDataRuleTableId: dsResult[i].externalDataRuleTableId,
          parameters: dsResult[i].filters.filter((f) => f.isParameter === true),
        });
      }

      return dsList;
    });

    return reportsList || [];
  }

  async function loadTemplateRuleTableList() {
    const ruleTables = await apiLoadTemplateRuleTables.call(
      REPORT_TEMPLATES_RULE_ID,
      async (result) => {
        let dsResult = ([...result.resources] || []).sort((a, b) =>
          a.name > b.name ? 1 : -1
        );

        let dsList = [
          { value: "-1", label: "(Select a rule table)", entityType: "" },
        ];

        for (let i = 0; i < dsResult.length; i++) {
          dsList.push({
            value: dsResult[i].id,
            label: dsResult[i].key,
            ruleTableDefinitionId: dsResult[i].ruleTableDefinitionId,
          });
        }

        return dsList;
      }
    );

    return ruleTables || [];
  }

  async function loadFieldsForDataSource(entityType) {
    const fieldList = apiLoadFields.call(entityType, async (result) => {
      const list = (result.properties || [])
        .map((c) => {
          return {
            value: c.id,
            label: c.propertyLabel,
            datatype: c.propertyType,
            validValues: [...c.validValues],
          };
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1));

      return list;
    });

    return fieldList || [];
  }

  async function loadReportProcess(reportList, ruleTableList) {
    if (!resId) {
      const vm = createViewModel(emptyReportProcess, reportList, ruleTableList);
      setReportProcessesData({
        type: ContextProviderActions.loadReportProcess,
        payload: vm,
      });
      setChanges(vm);
      setLastSavedChanges(vm);
      return;
    }

    apiLoad.call(resId, async (result) => {
      await postLoadReportProcessAndLists(result, reportList, ruleTableList);
    });
  }

  async function postLoadReportProcessAndLists(
    reportProcessResult,
    reportList,
    ruleTableList
  ) {
    let vm = emptyReportProcess;
    if (!reportProcessResult) {
      notifyError("Process does not exist");
    } else {
      vm = createViewModel(
        { ...emptyReportProcess, ...reportProcessResult },
        reportList,
        ruleTableList
      );
    }

    // Handle saved report populatation of fields and parameters
    if (vm.reportId !== "-1") {
      const _report = reportList.find((r) => r.value === vm.reportId);
      if (_report) {
        setSelectedReport(_report);
        vm.reportName = _report.label;
        const _fields = await loadFieldsForDataSource(_report.entityType);
        setFields(_fields);

        const _parameters = [..._report.parameters];
        for (let i = 0; i < _parameters.length; i++) {
          _parameters[i].value =
            vm.reportParameters.find((p) => p.id === _parameters[i].id)
              ?.value || "";
        }
        setReportParameters(_parameters);

        if (vm.ruleTableId === null) {
          vm.ruleTableVm = {
            value: "-1",
            label: "(Select a rule table)",
          };
          vm.ruleTableId = "-1";
          vm.ruleTableName = "";
        } else {
          vm.ruleTableName =
            ruleTableList.find((rt) => rt.value === vm.ruleTableId)?.label ||
            "";
        }
      }
    }

    setChanges(vm);
    setLastSavedChanges(vm);
    setReportProcessesData({
      type: ContextProviderActions.loadReportProcess,
      payload: vm,
    });
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleCheckboxChange({ target }) {
    let changed = {
      ...changes,
      [target.name]: target.checked,
    };
    setChanges(changed);
  }

  function handleReportChange(option) {
    const _changes = {
      ...changes,
      reportVm: option,
      reportName: "",
      ruleTableVm: {
        value: "-1",
        label: "(Select a rule table)",
      },
      ruleTableId: "-1",
      ruleTableName: "",
    };

    // Reset the report parameter values
    setReportParameters([]);

    if (option.value !== "-1") {
      const _report = reportProcessesData.reportListCache.find(
        (r) => r.value === option.value
      );
      _changes.reportName = _report.label;
      setSelectedReport(_report);
      setReportParameters([..._report.parameters]);

      // Does this report have a default rule table set? If so, set that here.
      if (
        _report.externalDataRuleTableId !== null &&
        _report.externalDataRuleTableId !== "-1"
      ) {
        const _ruleTableName =
          reportTemplatesData.ruleTableListCache.find(
            (rt) => rt.value === _report.externalDataRuleTableId
          )?.label || "";
        _changes.ruleTableId = _report.externalDataRuleTableId;
        _changes.ruleTableVm = {
          value: _report.externalDataRuleTableId,
          label: _ruleTableName,
        };
        _changes.ruleTableName = _ruleTableName;
      }
    } else {
      setSelectedReport(null);
    }

    setChanges(_changes);
  }

  function handleRuleTableChange(option) {
    setChanges({
      ...changes,
      ruleTableVm: option,
      ruleTableName: option.label,
    });
  }

  function handleParameterChange({ target }) {
    const _changes = { ...changes };
    const _parameter = reportParameters.find((f) => f.id === target.name);
    if (!_parameter) {
      console.log(`A report parameter with id "${target.name}" was not found.`);
      return;
    }

    _parameter.value = target.value;
    setChanges(_changes);
  }

  function formIsValid() {
    const _errors = {};

    if (_.trim(changes.description) === "")
      _errors.description = "Description must be entered";

    if ((changes.reportVm || { value: "-1" }).value === "-1") {
      _errors.reportId = "Report must be selected.";
    } else {
      // Validate parameters - each one must contain a value, but let each control take care of
      //   validating its own format.
      reportParameters
        .filter(
          (p) =>
            fields.find((f) => f.value === p.fieldId)?.datatype !== "Boolean"
        )
        .forEach((p) => {
          if (!p.value || !p.value || _.trim(p.value) === "")
            _errors["PARAM-" + p.id] = `${p.parameterLabel} is required`;
        });

      // If report has a template with a rule table, make sure one is selected here
      const _report = reportProcessesData.reportListCache.find(
        (r) => r.value === changes.reportVm.value
      );
      if (
        _report &&
        _report.externalDataRuleTableId !== "-1" &&
        (changes.ruleTableVm || { value: "-1" }).value === "-1"
      ) {
        _errors.ruleTableId = "Rule Table must be selected.";
      }
    }

    if (_.trim(changes.runNumber) === "")
      _errors.runNumber = "Run Number must be entered";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleRunProcess() {
    // Check for unsaved changes
    if (
      JSON.stringify(_.cloneDeep(lastSavedChanges)) !==
      JSON.stringify(_.cloneDeep(changes))
    ) {
      notifyWarn("Please save your changes before running the process.");
      return;
    }

    apiRun.call(resId, async (result) => {
      await loadReportProcess(
        reportProcessesData.reportListCache,
        reportTemplatesData.ruleTableListCache
      );
      notifySuccess("Process run complete.");
    });
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    const newVm = { ...changes };

    // Save report parameters
    newVm.reportParameters = [...reportParameters];

    if (!resId) {
      addReportProcess(newVm);
    } else {
      updateReportProcess(newVm.id, newVm);
    }
  }

  async function addReportProcess(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      const retModel = createViewModel(
        result,
        reportProcessesData.reportListCache,
        reportTemplatesData.ruleTableListCache
      );
      setChanges(retModel);
      setLastSavedChanges(retModel);
      notifySuccess("Process '" + vm.description + "' saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/reportprocess/" + result.id, { replace: true });
    });
  }

  async function updateReportProcess(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      const newVm = { ...model, ...result };
      const retModel = createViewModel(
        newVm,
        reportProcessesData.reportListCache,
        reportTemplatesData.ruleTableListCache
      );
      setChanges(retModel);
      setLastSavedChanges(retModel);

      notifySuccess("Process '" + vm.description + "' saved successfully");
    });
  }

  function handleClickViewProcessHistory(id) {
    const newSearch = {
      ...emptyProcessHistorySearch,
      freeFormSearch: id,
      description: "",
      runType: "",
      runBy: "",
      startDate: new Date("1/1/2000"),
      endDate: new Date("1/1/3000"),
    };
    setProcessHistoryData({
      type: ContextProviderActions.saveProcessHistorySearch,
      payload: newSearch,
    });
    navigate("/processhistory");
  }

  async function handleDownloadProcessFile(id) {
    apiDownload.call(id, async (result) => {
      const filename = result.FileName;
      const ext = getExtensionFromFilename(filename);
      const contents = await decodeBase64Stream(result.FileContents);
      download(contents, filename, ext);
      notifySuccess("File downloaded successfully");
    });
  }

  async function handleResetHistory() {
    setShowResetModal(true);
  }

  async function performResetHistory() {
    setShowResetModal(false);

    apiResetHistory.call(resId, async (result) => {
      await loadReportProcess(
        reportProcessesData.reportListCache,
        reportTemplatesData.ruleTableListCache
      );
      notifySuccess("Process history was reset successfully.");
    });
  }

  const isLoadingStuff =
    loading ||
    loadingReports ||
    loadingRuleTables ||
    loadingFields ||
    addingProcess ||
    updatingProcess ||
    running ||
    downloading ||
    resettingHistory;

  return (
    <Authorize>
      <ConfirmDialog
        title="Reset Process History"
        question={`This will reset the process to a state where it has never been run. All run history and saved values will be reset. Are you sure you want to continue?`}
        showModal={showResetModal}
        onNo={() => setShowResetModal(false)}
        onYes={performResetHistory}
      />
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            title="Return to previous screen"
            type="button"
            className="btn btn-link"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Reports/Report-Process-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>
            {resId ? "Edit Report Process" : "Create Report Process"}{" "}
            {resId ? ` | ${reportProcess.description}` : ""}
          </h1>
          {!isLoadingStuff && (
            <StyledHeaderRowButtonDiv
              style={{ flexWrap: "wrap", rowGap: "10px" }}
            >
              {resId && (
                <button
                  type="button"
                  className="btn btn-secondary btn-with-icon"
                  onClick={handleResetHistory}
                  style={{ borderColor: "var(--notify-danger)" }}
                >
                  <span className="material-icons">update_disabled</span>
                  {"  "}Reset History
                </button>
              )}
              {resId && (
                <button
                  type="button"
                  className="btn btn-secondary btn-with-icon"
                  onClick={handleRunProcess}
                  style={{
                    marginLeft: "14px",
                    borderColor: "var(--notify-success)",
                  }}
                  disabled={hasChanges}
                >
                  {hasChanges ? (
                    <>Save changes before running</>
                  ) : (
                    <>
                      <span className="material-icons">
                        play_circle_outline
                      </span>
                      {"  "}Run Now
                    </>
                  )}
                </button>
              )}
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "86px",
                  marginLeft: "24px",
                }}
              >
                <span className="material-icons">check</span>
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                style={{ marginLeft: "12px" }}
              >
                Cancel
              </button>
            </StyledHeaderRowButtonDiv>
          )}
        </StyledHeaderRowDiv>

        {isLoadingStuff ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin && typeof resId !== "undefined",
                  url: `/auditrecord/reportprocess/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Report Process"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Reports/Report-Process-Screen&anchor=report-process-section"
              >
                <StyledRowDiv className="row">
                  <div className="col-12 col-md-6">
                    <TextInput
                      id="Description"
                      label="Description"
                      onChange={handleChange}
                      placeholder="Description"
                      name="description"
                      value={changes.description || ""}
                      error={errors.description}
                      autoFocus={true}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <SelectInput
                      id="reportId"
                      name="reportId"
                      label="Report"
                      options={[...reportProcessesData.reportListCache]}
                      value={changes.reportVm}
                      onChange={handleReportChange}
                      placeholder=""
                      error={errors.reportId}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <CheckboxInput
                      id="noRepeatData"
                      label="Prevent process from running on already-run data rows"
                      onChange={handleCheckboxChange}
                      placeholder=""
                      name="noRepeatData"
                      showLabelInline={false}
                      checked={changes.noRepeatData}
                      error={errors.noRepeatData}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              {selectedReport !== null &&
                (loadingFields ? (
                  <Spinner
                    spinnerStyle={{ height: "200px", lineHeight: "200px" }}
                  />
                ) : (
                  <ExpandCollapseDetailSection
                    sectionTitle="Process Data"
                    collapsedState={collapsedState}
                    setCollapsedState={setCollapsedState}
                    helpLink="/Reports/Report-Process-Screen&anchor=process-data-section"
                  >
                    <StyledRowDiv className="row">
                      {(fields || []).length > 0 &&
                        reportParameters.map((p) => {
                          return (
                            <div key={p.id} className="col col-12 col-lg-6">
                              <FilterValueFieldByDataType
                                key={p.id}
                                id={p.id}
                                label={p.parameterLabel}
                                onChange={handleParameterChange}
                                placeholder=""
                                name={p.id}
                                value={p.value}
                                error={errors["PARAM-" + p.id]}
                                field={fields.find(
                                  (f) => f.value === p.fieldId
                                )}
                                operator={p.operator}
                                displayAsTextControl={true}
                              />
                            </div>
                          );
                        })}
                      <div className="col col-12 col-lg-6">
                        <TextInput
                          id="runNumber"
                          label="Run Number"
                          onChange={handleChange}
                          placeholder="Run Number"
                          name="runNumber"
                          value={changes.runNumber || ""}
                          error={errors.runNumber}
                        />
                      </div>
                      <div className="col col-12 col-lg-6">
                        <TextInput
                          id="runDateInput"
                          name="runDateInput"
                          label="Run Date Override"
                          value={changes.runDateInput || ""}
                          placeholder=""
                          onChange={handleChange}
                          error={errors.runDateInput}
                        />
                      </div>
                    </StyledRowDiv>
                    {selectedReport &&
                      selectedReport.externalDataRuleTableId !== "-1" && (
                        <>
                          <StyledRowDiv
                            className="row"
                            style={{ marginTop: "30px" }}
                          >
                            <p>
                              Select a Rule Table to provide data to the
                              process. The selected report will run once for
                              each row in the rule table.
                            </p>
                          </StyledRowDiv>
                          <StyledRowDiv className="row">
                            <div className="col-12 col-md-6 col-lg-4">
                              <SelectInput
                                id="ruleTableId"
                                name="ruleTableId"
                                label="Rule Table"
                                options={[
                                  ...reportTemplatesData.ruleTableListCache,
                                ]}
                                value={changes.ruleTableVm}
                                onChange={handleRuleTableChange}
                                placeholder=""
                                error={errors.ruleTableId}
                              />
                            </div>
                          </StyledRowDiv>
                        </>
                      )}
                  </ExpandCollapseDetailSection>
                ))}

              <ExpandCollapseDetailSection
                sectionTitle="Last Run Info"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Reports/Report-Process-Screen&anchor=last-run-info-section"
              >
                {!changes.lastProcessHistoryId ||
                changes.lastProcessHistoryId === "" ? (
                  <p>This process has never been run.</p>
                ) : (
                  <>
                    <StyledRowDiv className="row">
                      <div className="col-6">
                        <ReadOnly
                          id="lastRunDate"
                          label="Run Date"
                          name="lastRunDate"
                          value={formatDateTimeUtcZoneForDisplay(
                            changes.lastRunDate
                          )}
                        />
                      </div>
                      <div className="col-6">
                        <ReadOnly
                          id="lastRunDuration"
                          label="Duration"
                          name="lastRunDuration"
                          value={getTimeframeFromMilliseconds(
                            changes.lastRunDuration
                          )}
                        />
                      </div>
                      <div className="col-6">
                        <ReadOnly
                          id="lastRunBy"
                          label="Run By"
                          name="lastRunBy"
                          value={changes.lastRunBy}
                        />
                      </div>
                    </StyledRowDiv>
                    <StyledRowDiv className="row" style={{ marginTop: "20px" }}>
                      <div className="col-12 col-sm-6">
                        <button
                          className="btn btn-link link-underline"
                          onClick={() =>
                            handleClickViewProcessHistory(
                              changes.lastProcessHistoryId
                            )
                          }
                        >
                          View process history for this run
                        </button>
                      </div>
                      <div className="col-12 col-sm-6">
                        <button
                          className="btn btn-link link-underline"
                          onClick={() =>
                            handleDownloadProcessFile(
                              changes.lastProcessHistoryId
                            )
                          }
                        >
                          Download file(s) from last run
                        </button>
                      </div>
                    </StyledRowDiv>
                    <ReportProcessLastRunSavedData
                      title="Last Run Saved Data"
                      vmLastSavedValues={changes.lastRunValues}
                      allowEdits={true}
                    />
                  </>
                )}
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default ReportProcess;
