import React from "react";
import PropTypes from "prop-types";

function TextAreaInput({
  name,
  id,
  value,
  label,
  error,
  onChange,
  placeholder,
  cols,
  rows,
  labelStyle,
  inputStyle,
  autoFocus = false,
}) {
  let wrapperClass = "form-group";
  if (error.length > 0) {
    wrapperClass += " has-error";
  }

  function handleBlur(e) {
    // Reset field height
    window.setTimeout(() => {
      e.target.style.height = "inherit";
    }, 500);
  }

  function handleFocus(e) {
    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    let height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      parseInt(computed.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 10) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);

    if (height > 10) height -= 10;

    // jon, 5/26/23: Enforce a max height
    if (height > 400) height = 400;

    e.target.style.height = `${height}px`;
  }

  function handleKeyDown(e) {
    // Reset field height
    e.target.style.height = "inherit";
    handleFocus(e);
  }

  return (
    <div className={wrapperClass}>
      <label htmlFor={id} style={labelStyle || {}}>
        {label}
      </label>
      <div className="field">
        <textarea
          id={id}
          name={name}
          autoFocus={autoFocus || false}
          className="form-control"
          placeholder={placeholder}
          cols={cols}
          rows={rows}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={onChange}
          value={value}
          style={inputStyle || {}}
        ></textarea>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

TextAreaInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  cols: PropTypes.string,
  rows: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
};

TextAreaInput.defaultProps = {
  error: "",
  cols: "40",
  rows: "5",
};

export default TextAreaInput;
