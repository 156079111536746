import React from "react";
import _ from "lodash";

export const REPORT_TEMPLATES_RULE_ID = "a5eb675e-abf4-4711-aaf3-253705d45f63";

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (day.length < 2) day = "0" + day;

  if (_.isNaN(month) || _.isNaN(day) || _.isNaN(year)) return null;
  var testDate = [month, day, year].join("/");
  if (testDate === "12/31/1969") return null;
  return testDate;
}

export function formatDateTime(
  date,
  includeSeconds = false,
  includeMilliseconds = false
) {
  var d;

  if (Object.prototype.toString.call(date) === "[object Date]") {
    d = date;
  } else {
    d = new Date(date);
  }

  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes(),
    second = "" + d.getSeconds(),
    ms = "" + d.getMilliseconds();

  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  if (second.length < 2) second = "0" + second;

  if (
    _.isNaN(month) ||
    _.isNaN(day) ||
    _.isNaN(year) ||
    _.isNaN(hour) ||
    _.isNaN(minute) ||
    _.isNaN(second) ||
    _.isNaN(ms)
  )
    return null;

  var testDate = `${month}/${day}/${year} ${hour}:${minute}${
    includeSeconds ? ":" + second : ""
  }${includeMilliseconds ? "." + ms : ""}`;

  if (testDate.startsWith("12/31/1969")) return null;
  return testDate;
}

export function convertLocalDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

export function convertUTCDateToLocal(utcdate) {
  // Normal date constructor converts ISO-formatted date to local, but only if it has a Z on the end.
  if (!_.endsWith(utcdate, "Z")) utcdate = utcdate + "Z";
  const dt = new Date(utcdate);
  return dt;
}

export function convertUTCDateStringToUTCDate(utcdate) {
  // Normal date constructor converts ISO-formatted date to local, but only if it has a Z on the end, so remove the Z first.
  if (_.endsWith(utcdate, "Z"))
    utcdate = utcdate.substring(0, utcdate.length - 1);
  const dt = new Date(utcdate);
  return dt;
}

export function formatDateTimeUtcZone(date) {
  if (!_.endsWith(date, "Z")) date = date + "Z";
  let dt = new Date(date).toLocaleString();
  return dt;
}

export function formatDateTimeUtcZoneForDisplay(
  date,
  removeTimeIfMidnight = false
) {
  if (date === null) return "";
  if (!_.endsWith(date, "Z")) date = date + "Z";
  let dt = getLocalDateFormatForDisplay(date);

  if (removeTimeIfMidnight === true && dt.endsWith(" 12:00:00a")) {
    dt = dt.substring(0, dt.indexOf(" 12:00:00a"));
  }

  if (dt === "Invalid Date") {
    dt = "";
  }

  return dt;
}

export function formatDateUtcZoneForDisplay(date) {
  let dt = new Date(date).toLocaleString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });
  return dt;
}

export function formatDateOnly(date) {
  // Expected format is YYYY-MM-DD and might have time portion that will get removed.
  if (!date || date === undefined || date === null || date.length < 10)
    return "";

  var dParts = date.substring(0, 10).split("-");
  var month = "" + dParts[1],
    day = "" + dParts[2],
    year = "" + dParts[0];

  return [month, day, year].join("/");
}

export function formatDateTimeUtc(date) {
  //if (!_.endsWith(date, "Z")) date = date + "Z";
  return new Date(date).toLocaleString();
}

export function formatDateTimeUtcForDisplay(date) {
  const dt = getLocalDateFormatForDisplay(date);
  return dt;
}

function getLocalDateFormatForDisplay(date) {
  let dt = new Date(date).toLocaleString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
  });

  // Remove comma separating date and time
  dt = dt.replaceAll(",", "");

  // Replace PM and AM with p and a
  dt = dt.replaceAll(/.AM/gi, "a");
  dt = dt.replaceAll(/.PM/gi, "p");

  return dt;
}

export function formatDateTimeUtcWithMilliseconds(date) {
  var dt = new Date(date);
  var options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: "3",
  };
  var dateTimePart = dt.toLocaleString("en-US", options);

  // Remove comma separating date and time and AM/PM
  dateTimePart = dateTimePart
    .replaceAll(",", "")
    .replaceAll(" AM", "")
    .replaceAll(" PM", "");

  return dateTimePart;
}

// export function convertJsonDateStructureToDate(jsonDate) {
//   const dt = new Date(jsonDate.Year, jsonDate.Month, jsonDate.Day);
//   return dt;
// }

export function ticksToDate(ticks) {
  return Number.isInteger(ticks)
    ? new Date(
        ticks / 1e4 + new Date("0001-01-01T00:00:00Z").getTime()
      ).toLocaleString()
    : null;
}

export function formatDecimal(num, places, placeholderWhenEmpty = "") {
  if (!_.isNumber(num)) return placeholderWhenEmpty;
  return num.toFixed(places);
}

export function unZero(val) {
  var numval = _.toInteger(val);
  return _.toString(numval);
}

export function yyyymmdd(dt) {
  var isDate = Object.prototype.toString.call(dt) === "[object Date]";
  if (!isDate) dt = new Date(dt);
  var y = dt.getFullYear().toString();
  var m = (dt.getMonth() + 1).toString();
  var d = dt.getDate().toString();
  d.length === 1 && (d = "0" + d);
  m.length === 1 && (m = "0" + m);
  var yyyymmdd = y + m + d;
  return yyyymmdd;
}

export function yyyy_mm_dd(dt) {
  var isDate = Object.prototype.toString.call(dt) === "[object Date]";
  if (!isDate) dt = new Date(dt);
  var y = dt.getFullYear().toString();
  var m = (dt.getMonth() + 1).toString();
  var d = dt.getDate().toString();
  d.length === 1 && (d = "0" + d);
  m.length === 1 && (m = "0" + m);
  var yyyymmdd = y + "-" + m + "-" + d;
  return yyyymmdd;
}

export function fromyyyymmdd(dts) {
  var year = dts.substring(0, 4);
  var month = dts.substring(4, 6);
  var day = dts.substring(6, 8);

  return new Date(year, month - 1, day);
}

export function MMDDyyyy(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

export function formatNumberForDisplay(num) {
  if (num && num.toLocaleString) return num.toLocaleString();
  else return num;
}

export function parseJwt(token) {
  if (!token || _.isEmpty(token)) {
    console.log("WARNING: token is NULL in parseJwt!");
    return null;
  }
  if (token.indexOf(".") < 0) {
    console.log("WARNING: token is not valid in parseJwt: " + token);
    return null;
  }

  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getUserIdFromToken(token) {
  if (!token || _.isEmpty(token)) return "";
  const tokenObj = parseJwt(token);
  return tokenObj?.oid;
}

function hasRole(token, role) {
  const tokenObj = parseJwt(token);
  return (
    tokenObj &&
    tokenObj.roles &&
    tokenObj.roles.length > 0 &&
    tokenObj.roles.includes(role)
  );
}

export function isAdmin(token) {
  if (!token) return false;
  return hasRole(token, "Task.Admin");
}

export function isBasicUser(token) {
  if (!token) return false;
  return hasRole(token, "Task.User");
}

export function isParserUser(token) {
  if (!token) return false;
  return hasRole(token, "Task.Parser");
}

export const decodeBase64Stream = async (
  base64,
  type = "application/octet-stream"
) => await fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

export function getExtensionFromFilename(filename) {
  if (_.trim(filename) === "") return "";
  let extension = "";

  const pos = _.lastIndexOf(filename, ".");
  if (pos >= 0) {
    extension = filename.substring(pos);
  }

  return extension;
}

export function download(data, filename, type) {
  var file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export function readSingleFile(evt) {
  var f = evt.target.files[0];
  if (f) {
    var r = new FileReader();
    r.onload = function (e) {
      var contents = e.target.result;

      var lines = contents.split("\n"),
        output = [];
      for (var i = 0; i < lines.length; i++) {
        output.push(
          "<tr><td>" + lines[i].split(",").join("</td><td>") + "</td></tr>"
        );
      }
      output = "<table>" + output.join("") + "</table>";
      document.write(output);
    };
    r.readAsText(f);
  } else {
    alert("Failed to load file");
  }
}

export function isNumber(str) {
  if (typeof str === "number") return true;
  if (typeof str != "string") return false; // we only process strings!
  // could also coerce to string: str = ""+str
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export function isBoolean(str) {
  if (typeof str === "boolean") return true;
  if (typeof str != "string") return false; // we only process strings!

  return str.toLowerCase() === "true" || str.toLowerCase() === "false";
}

export function isPositiveInteger(n) {
  let strVer = ("" + n).replace(/^0+/, "");
  let num = Math.floor(Number(n));
  let isPos =
    num !== Infinity && (String(num) === strVer || strVer === n) && num >= 0;
  return isPos;
}

export const states = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District Of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const genders = [
  { label: "Unspecified", value: "Unspecified" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

// Returns a random number between min (inclusive) and max (exclusive)
export function getRandomNumberInRange(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function getRandomDecimalInRange(min, max) {
  return Math.random() * (max - min) + min;
}

export function jsonToArray(json) {
  if (!json || json === "") return [];
  return JSON.parse(json);
}

export const logInitial = [
  { label: "Info", value: "Info" },
  { label: "Warn", value: "Warn" },
  { label: "Error", value: "Error" },
  { label: "Fatal", value: "Fatal" },
];

export function escapeRegularExpressionSpecialCharacters(expression) {
  let ret = expression;
  ret = ret.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  return ret;
}

export function getHighlightedText(textToSearch, searchTerms) {
  let hlCount = 0;

  let text =
    textToSearch === null || typeof textToSearch === "undefined"
      ? ""
      : `${textToSearch}`;
  if (text.length === 0) return { count: 0, html: text };

  const highlight = escapeRegularExpressionSpecialCharacters(
    _.trim(searchTerms)
  );
  if (highlight === "") return { count: 0, html: text };

  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  let isFound = false;

  const retVal = (
    <span>
      {" "}
      {parts.map((part, i) => {
        isFound = part.toLowerCase() === highlight.toLowerCase();
        if (isFound) hlCount++;
        return (
          <span key={i} className={isFound ? "hl" : ""}>
            {part}
          </span>
        );
      })}{" "}
    </span>
  );

  return { count: hlCount, html: retVal };
}

export function getFormattedJson(str) {
  let value = str;

  if (
    value &&
    typeof value === "string" &&
    value !== "" &&
    (value.indexOf("{") >= 0 || value.indexOf("[") >= 0)
  ) {
    // Determine which potential starting json character to use first
    const posCurlyBrace = value.indexOf("{");
    const posBracket = value.indexOf("[");
    let pos = posCurlyBrace;

    if (posBracket >= 0 && posBracket < posCurlyBrace) {
      pos = posBracket;
    }
    const extractedJson = value.substring(pos);

    let parsedJson = "";
    try {
      parsedJson = JSON.parse(extractedJson);
    } catch (ex) {
      // console.log(`Json cannot be parsed. Error: ${ex}`.extractedJson);
      return str;
    }

    value =
      value.substring(0, value.indexOf("{")) +
      " " +
      JSON.stringify(parsedJson, null, "    ");
  }

  return value;
}

export function getHighlightedTextForPropertiesArray(
  arrayToSearch,
  searchTerms
) {
  if ((arrayToSearch || []).length === 0) {
    return { count: 0, htmlArray: [] };
  }

  const hlArray = [];
  let hlCount = 0;
  let propNameResult = {};
  let propValueResult = {};

  arrayToSearch.forEach((property) => {
    propNameResult = getHighlightedText(property.name, searchTerms);
    propValueResult = getHighlightedText(
      getFormattedJson(property.value),
      searchTerms
    );

    hlCount += propNameResult.count;
    hlCount += propValueResult.count;
    hlArray.push({
      countName: propNameResult.count,
      htmlName: propNameResult.html,
      countValue: propValueResult.count,
      htmlValue: propValueResult.html,
    });
  });

  return { count: hlCount, htmlArray: hlArray };
}

export function suppressEnterKey(e) {
  if (e && e.key && e.key === "Enter") {
    e.preventDefault();
  }
}

export const joinOperators = [
  { label: "AND", value: "And" },
  { label: "OR", value: "Or" },
];

export const operators = [
  { label: "is", value: "Equals", requiresValue: true },
  { label: "is not", value: "NotEqual", requiresValue: true },
  { label: "contains", value: "Contains", requiresValue: true },
  {
    label: "does not contain",
    value: "DoesNotContain",
    requiresValue: true,
  },
  { label: ">", value: "GreaterThan", requiresValue: true },
  {
    label: ">=",
    value: "GreaterThanOrEqual",
    requiresValue: true,
  },
  { label: "<", value: "LessThan", requiresValue: true },
  {
    label: "<=",
    value: "LessThanOrEqual",
    requiresValue: true,
  },
  { label: "is one of", value: "In", requiresValue: true },
  { label: "is not one of", value: "NotIn", requiresValue: true },
  { label: "is true", value: "IsTrue", requiresValue: false },
  { label: "is false", value: "IsFalse", requiresValue: false },
  { label: "is empty", value: "IsNull", requiresValue: false },
  { label: "is not empty", value: "IsNotNull", requiresValue: false },
  { label: "list contains", value: "ArrayContains", requiresValue: true },
  {
    label: "list does not contain",
    value: "NotArrayContains",
    requiresValue: true,
  },
];

// export const operators = [
//   { label: "is equal to", value: "Equals", requiresValue: true },
//   { label: "is not equal to", value: "NotEqual", requiresValue: true },
//   { label: "contains", value: "Contains", requiresValue: true },
//   {
//     label: "does not contain",
//     value: "DoesNotContain",
//     requiresValue: true,
//   },
//   { label: "is greater than", value: "GreaterThan", requiresValue: true },
//   {
//     label: "is greater than or equal to",
//     value: "GreaterThanOrEqual",
//     requiresValue: true,
//   },
//   { label: "is less than", value: "LessThan", requiresValue: true },
//   {
//     label: "is less than or equal to",
//     value: "LessThanOrEqual",
//     requiresValue: true,
//   },
//   { label: "is one of", value: "In", requiresValue: true },
//   { label: "is not one of", value: "NotIn", requiresValue: true },
//   { label: "is true", value: "IsTrue", requiresValue: false },
//   { label: "is false", value: "IsFalse", requiresValue: false },
//   { label: "is empty", value: "IsNull", requiresValue: false },
//   { label: "is not empty", value: "IsNotNull", requiresValue: false },
//   { label: "list contains", value: "ArrayContains", requiresValue: true },
//   {
//     label: "list does not contain",
//     value: "NotArrayContains",
//     requiresValue: true,
//   },
// ];

export const datetimeOperators = [
  "Equals",
  "NotEqual",
  "GreaterThan",
  "GreaterThanOrEqual",
  "LessThan",
  "LessThanOrEqual",
  "IsNull",
  "IsNotNull",
];

export const stringOperators = [
  "Equals",
  "NotEqual",
  "Contains",
  "DoesNotContain",
  "In",
  "NotIn",
  "IsNull",
  "IsNotNull",
];

export const numberOperators = [
  "Equals",
  "NotEqual",
  "GreaterThan",
  "GreaterThanOrEqual",
  "LessThan",
  "LessThanOrEqual",
  "IsNull",
  "IsNotNull",
];

export const booleanOperators = ["IsTrue", "IsFalse", "IsNull", "IsNotNull"];

export const arrayOperators = ["ArrayContains", "NotArrayContains"];

export const ruleConditionalOperators = [
  { label: "Equal", value: "=", requiresValue: true },
  { label: "Not Equal", value: "<>", requiresValue: true },
  { label: "Is Empty", value: "isempty", requiresValue: false },
  { label: "Is Not Empty", value: "isnotempty", requiresValue: false },
  { label: "In", value: "in", requiresValue: true },
  { label: "Is Not In", value: "notin", requiresValue: true },
  { label: "Greater Than", value: ">", requiresValue: true },
  { label: "Less Than", value: "<", requiresValue: true },
  { label: "Greater Than or Equal", value: ">=", requiresValue: true },
  { label: "Less Than or Equal", value: "<=", requiresValue: true },
  { label: "Starts With", value: "startsWith", requiresValue: true },
  { label: "Ends With", value: "endsWith", requiresValue: true },
  { label: "Is Null", value: "isnull", requiresValue: false },
  { label: "Is Not Null", value: "notnull", requiresValue: false },
  { label: "Is True", value: "istrue", requiresValue: false },
  { label: "Is False", value: "isfalse", requiresValue: false },
  { label: "Multiple Of", value: "multipleof", requiresValue: true },
  { label: "Not Multiple Of", value: "notmultipleof", requiresValue: true },
  { label: "Is Zero", value: "iszero", requiresValue: false },
  { label: "Is Not Zero", value: "isnotzero", requiresValue: false },
  { label: "Matches", value: "matches", requiresValue: true },
  { label: "Not Matches", value: "nmatches", requiresValue: true },
];

export function getOperatorDesc(operator) {
  let desc = "";
  const op = operators.find((o) => o.value === operator);
  if (op) {
    desc = op.label;
  }
  return desc;
}

export function getJoinOperatorDesc(operator) {
  let desc = "";
  const op = joinOperators.find((o) => o.value === operator);
  if (op) {
    desc = op.label;
  }
  return desc;
}

export const shareModeTypes = [
  { label: "Not Shared", value: "ZNotShared" },
  { label: "Shared", value: "Shared" },
  { label: "Featured", value: "Featured" },
];

export const shareModeSearchTypes = [
  { label: "Owned By Me", value: "ZNotShared" },
  { label: "Featured", value: "Featured" },
];

export const ruleTableDefinitionSecurityTypes = [
  { label: "Public", value: "Public" },
  { label: "Report Template", value: "ReportTemplate" },
  { label: "Rule", value: "Rule" },
  { label: "System", value: "System" },
];

export const ruleTableDefinitionColumnValueTypes = [
  { label: "Text", value: "Text" },
  { label: "Numeric", value: "Numeric" },
  { label: "Date", value: "Date" },
  { label: "Boolean", value: "Boolean" },
];

export const ruleTableDefinitionColumnControlTypes = [
  { label: "Input", value: "Input" },
  { label: "Dropdown", value: "List" },
];

export const ruleTableDefinitionColumnListSourceTypes = [
  { label: "List of values", value: "ValidValues" },
  { label: "Rule Table Definition column", value: "RuleTableDefinition" },
];

export const ruleTableImportModeTypes = [
  {
    label: "Overwrite existing data when keys match",
    value: "overwritechanges",
  },
  { label: "Clear rule table data before importing", value: "tableoverwrite" },
  {
    label: "Terminate existing data and insert new records",
    value: "terminatechanges",
  },
];

export const testResultTypes = [
  { label: "Test", value: "Test" },
  { label: "Group", value: "Group" },
];

export const testRunResultTypes = [
  { label: "Not Run", value: "NotRun" },
  { label: "Pass", value: "Pass" },
  { label: "Fail", value: "Fail" },
  { label: "Error", value: "Error" },
];

export const testStepModeTypes = [
  { label: "API", value: "API" },
  { label: "Processor", value: "Processor" },
  { label: "SocketServer", value: "SocketServer" },
];

export const testStepMethodTypes = [
  { label: "Get", value: "Get" },
  { label: "Post", value: "Post" },
  { label: "Put", value: "Put" },
  { label: "Delete", value: "Delete" },
];

export const reportAggregateFunctions = [
  { label: "(None)", value: "None" },
  { label: "Average", value: "Average" },
  { label: "Count", value: "Count" },
  { label: "Max", value: "Max" },
  { label: "Min", value: "Min" },
  { label: "Sum", value: "Sum" },
];

export const reportDisplayFormats = [
  { label: "(Default)", value: "" },
  { label: "Absolute Value", value: "{{absValue val}}" },
  { label: "Add Days to Date", value: '{{addDays val 7 "yyyy-MM-dd"}}' },
  { label: "Calculate", value: '{{calculate val "+" num 2}}' },
  { label: "Currency", value: "{{toCurrency val}}" },
  { label: "Date", value: '{{toDateFormat val "MM/dd/yyyy"}}' },
  { label: "From NCPDP Field", value: "{{fromNcpdpField val fieldCode}}" },
  { label: "Number to String", value: "{{numToString val}}" },
  { label: "Number with Precision", value: "{{toPrecision val 2}}" },
  { label: "Pad Left", value: '{{padLeft val 10 "0"}}' },
  { label: "Pad Right", value: '{{padRight val 10 " "}}' },
  { label: "Short Date", value: "{{toShortDate val}}" },
];

export const reportCustomFieldDataTypes = [
  { label: "Boolean", value: "boolean" },
  { label: "Date", value: "date" },
  { label: "Numeric", value: "numeric" },
  { label: "String", value: "string" },
];

export const processHistoryRunTypes = [
  { label: "(All Types)", value: "" },
  { label: "Detail", value: "Detail" },
  { label: "Summary", value: "Summary" },
];

export const testPacketTypes = [
  { label: "Heartbeat", value: "heartbeat" },
  { label: "Test", value: "test" },
  { label: "Error", value: "error" },
  { label: "Timeout", value: "timeout" },
];

export const ruleSummaryDisplayTypes = [
  { label: "Basic View", value: "basic" },
  { label: "Basic with Details", value: "details" },
  { label: "Admin View", value: "admin" },
  { label: "Admin Advanced", value: "advanced" },
];

export const packetParserDisplayTypes = [
  { label: "Condensed View", value: "condensed" },
  { label: "Expanded View", value: "expanded" },
  { label: "Detail View", value: "detail" },
];

export const autoPopulateTestDataTypes = [
  {
    label: "Existing claims for frequently-seen data",
    value: "DataFromHighFrequencyClaims",
  },
  {
    label: "Existing claims for infrequently-seen data",
    value: "DataFromLowFrequencyClaims",
  },
  {
    label: "Discount pricing from data not seen on existing claims",
    value: "DataFromNonClaimDiscountPricing",
  },
  {
    label: "Non-discount pricing from data not seen on existing claims",
    value: "DataFromNonClaimNoDiscountPricing",
  },
];

export const rejectionLevelTypes = [
  { label: "Transaction", value: "Transaction" },
  { label: "Transmission", value: "Transmission" },
];

export function handleCollapseExpandAll(
  collapse,
  collapsedState,
  setCollapsedState
) {
  const newCollapsedState = [...collapsedState];
  newCollapsedState.forEach((section) => (section.collapsed = collapse));
  setCollapsedState(newCollapsedState);
}

export function handleCollapseExpandSection(
  sectionName,
  collapse,
  collapsedState,
  setCollapsedState
) {
  const newCollapsedState = [...collapsedState];
  newCollapsedState.find((section) => section.name === sectionName).collapsed =
    collapse;
  setCollapsedState(newCollapsedState);
}

export function disableAnimations() {
  // In addition to trying to honor the user's browser/system settings for preferring reduced motion, the react bootstrap
  //   modal has a known issue where it will sometimes leave the user's browser screen non-reactive after closing a the dialog.
  // The workaround is to explicitly disable animation on the dialog when this setting is true on the user's system.
  const isReduced =
    window.matchMedia(`(prefers-reduced-motion: reduce)`)?.matches || false;
  // console.log("User prefers reduced motion?", isReduced);
  return isReduced;
}

export function getParameterValueFromNumber(n) {
  // Prepended n: to number parameter
  const value = `n:${n}`;
  return value;
}

// Is this needed?
// export function getParameterValueFromBoolean(b) {
//   const v = b ?? false;
//   // Prepended b: to boolean parameter
//   const value = `b:${v}`;
//   return value;
// }

export function getNumberFromParameterValue(v) {
  // If number has n: prepended to it, remove that for display/editing to the user.
  let val = "" + v;

  if (("" + v).indexOf("n:") === 0) {
    return val.substring(2);
  }

  return v;
}

// export function getBooleanFromParameterValue(v) {
//   // If boolean has b: prepended to it, remove that for display/editing to the user.
//   let val = "" + v;

//   if ((val).indexOf("b:") === 0) {
//     return val.substring(2);
//   }

//   return v;
// }

export function getTimeAgoFromIntervalSeconds(refreshSeconds, interval) {
  let unit = "sec";
  let ago = refreshSeconds * interval;

  // If time is 1 minute, use minutes. Unless going over 1 hr, in which case use hour.
  //  jon, 3/7/23: To support cache Last Hit, add days. So use days if time is over 1 day.
  if (ago >= 60 && ago < 3600) {
    unit = "min";
    ago = Math.round((ago / 60) * 10) / 10; // Round to nearest 1 decimal place
  } else if (ago >= 3600 && ago < 86400) {
    unit = "hr";
    ago = Math.round((ago / 3600) * 10) / 10; // Round to nearest 1 decimal place
  } else if (ago >= 86400) {
    unit = "day";
    ago = Math.round((ago / 86400) * 10) / 10; // Round to nearest 1 decimal place
  } else {
    unit = "sec";
    ago = Math.round(ago); // Round seconds to integer only
  }

  return { unit: unit, ago: ago };
}

export function getUserSizeFromBytes(numBytes) {
  let unit = "B";
  let qty = numBytes;

  // If 0, return empty string
  if (numBytes === 0) return "";

  if (qty < 1000) {
    // If bytes is < 1000, use B (bytes).
    // If bytes is over 1000 but less than 1 million, use K (kilobytes) and show 1 decimal
    // If bytes is over 1 million, use M (megabytes) and show 1 decimal
    unit = "B";
  } else if (qty >= 1000 && qty < 1000000) {
    unit = "K";
    qty = Math.round((qty / 1000) * 10) / 10; // Round to nearest 1 decimal place
  } else {
    unit = "M";
    qty = Math.round((qty / 1000000) * 10) / 10; // Round to nearest 1 decimal place
  }

  return `${qty}${unit}`;
}

export function getTimeframeFromMilliseconds(ms) {
  let unit = "ms";
  let qty = ms || 0;

  // If 0, return empty string
  if (qty === 0) return "";

  if (qty < 1000) {
    // If bytes is < 1000, use ms (milliseconds).
    // If bytes is over 1000, use s (seconds)
    unit = "ms";
  } else {
    unit = "s";
    qty = Math.round((qty / 1000) * 10) / 10; // Round to nearest 1 decimal place
  }

  return `${qty}${unit}`;
}

export function getIconFromAggregate(aggregateFunc) {
  let icon = <></>;
  const aggregate = aggregateFunc?.value ? aggregateFunc.value : aggregateFunc;

  switch (aggregate) {
    case "Average":
      icon = (
        <span
          style={{
            textDecoration: "overline",
            display: "inline-block",
            marginTop: "6px",
          }}
          className="material-icons icon-subtle"
        >
          close
        </span>
      );
      break;
    case "Count":
      icon = <span className="material-icons icon-subtle">numbers</span>;
      break;
    case "Max":
      icon = <span className="material-icons icon-subtle">add</span>;
      break;
    case "Min":
      icon = <span className="material-icons icon-subtle">remove</span>;
      break;
    case "Sum":
      icon = <span className="material-icons icon-subtle">functions</span>;
      break;
    default:
      icon = <></>;
  }

  return icon;
}

export const calculateBoundingBoxes = (children) => {
  const boundingBoxes = [];
  if (!children || children === null || (children || []).length === 0) {
    console.log("Children is empty in calculateBoundingBoxes!");
    return boundingBoxes;
  }

  let child = null;
  for (let i = 0; i < (children || []).length; i++) {
    child = children[i];
    if ((child.ref || null) === null || !child.ref.current) {
      console.log(`Child ${child.key} has no ref in calculateBoundingBoxes!`);
      continue;
    }

    const domNode = child.ref.current;
    const nodeBoundingBox = domNode?.getBoundingClientRect() ?? {
      x: 0,
      y: 0,
      height: 0,
      width: 0,
    };

    boundingBoxes[child.key] = nodeBoundingBox;
  }

  return boundingBoxes;
};
