import React, { useState, useEffect } from "react";
import Spinner from "../../common/ui/Spinner";
import { notifyError } from "../../../services/NotificationService";
import {
  createViewModel,
  emptyTestResult,
  emptyTestStepResultSearch,
} from "../../../viewmodels/testResultsVm";
import {
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
  handleCollapseExpandAll,
} from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import { useTestResults } from "../../../contexts/TestResultsContext";
import { useAuth } from "../../../contexts/AuthContext";
import { apiLoadSingleTestResults } from "../../../api/TestApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import ReadOnly from "../../common/input/ReadOnly";
import StatusPill from "../../common/ui/StatusPill";
import TestStepResultsList from "./TestStepResultsList";
import HelpLink from "../../common/ui/HelpLink";

function TestResults() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { testResultsData, setTestResultsData } = useTestResults();
  const { loading, api: apiLoad } = useApi(apiLoadSingleTestResults);
  const [testSearch, setTestSearch] = useState(emptyTestStepResultSearch);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Test", collapsed: false },
    { name: "Run Info", collapsed: false },
    { name: "Step Results", collapsed: false },
  ]);

  const testId = params && params.testid;
  const resultId = params && params.testResultId;
  const testResult = testResultsData && testResultsData.testResult;

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setTestResultsData({
        type: ContextProviderActions.loadTestResult,
        payload: emptyTestResult,
      });

      loadTest();
    }
  }, [auth.authenticated, params?.testGroupId, params?.testResultId]);

  async function loadTest() {
    if (!(testId && resultId)) {
      notifyError(
        "You must provide both a testId and testResultId to view this page."
      );
      return;
    }

    apiLoad.call({ testId: testId, testResultId: resultId }, async (result) => {
      let vm = emptyTestResult;
      if (!result) {
        notifyError("Test Result does not exist");
      } else {
        vm = createViewModel(result);
      }

      setTestResultsData({
        type: ContextProviderActions.loadTestResult,
        payload: vm,
      });
    });
  }

  async function handleRunTest() {
    navigate("/test/" + testId + "/true");
  }

  function handleCollapseExpandJson(changeId, iteration, expand) {
    const _doc = { ...testResultsData.testResult };
    const _changes = [..._doc.testSteps];
    const change = _changes.find(
      (c) => c.id === changeId && c.iteration === iteration
    );

    if (change) change.showJson = expand;
    _doc.testSteps = _changes;

    setTestResultsData({
      type: ContextProviderActions.loadTestResult,
      payload: _doc,
    });
  }

  function handleCollapseExpandAllSteps(collapse) {
    const _doc = { ...testResultsData.testResult };
    const _changes = [];
    _doc.testSteps.forEach((c) => _changes.push({ ...c, showJson: !collapse }));
    _doc.testSteps = _changes;

    setTestResultsData({
      type: ContextProviderActions.loadTestResult,
      payload: _doc,
    });
  }

  const working = loading;

  return (
    <Authorize>
      <StyledBackButtonDiv>
        <button
          type="button"
          title="Return to previous screen"
          className="btn btn-link btn-with-icon"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink path="/Testing/Test-Run-Result-Screen" label="Help" />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1>
          Test Results{" "}
          {testResult.description ? ` | ${testResult.description}` : ""}
        </h1>
        <StyledHeaderRowButtonDiv>
          {!working && (
            <button
              type="button"
              className="btn btn-secondary btn-with-icon"
              onClick={handleRunTest}
            >
              <>
                <span className="material-icons">play_circle_outline</span>
                {"  "}Run Again
              </>
            </button>
          )}
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>

      {working ? (
        <Spinner />
      ) : (
        <>
          <ActionMenu
            title="Actions"
            items={[
              { value: "ExpandAll", label: "Expand All" },
              { value: "CollapseAll", label: "Collapse All" },
              { value: "ExpandAllSteps", label: "Expand All Step Results" },
              { value: "CollapseAllSteps", label: "Collapse All Step Results" },
            ]}
            onSelectAction={(value, label) =>
              value.endsWith("Steps")
                ? handleCollapseExpandAllSteps(
                    value === "CollapseAllSteps",
                    collapsedState,
                    setCollapsedState
                  )
                : handleCollapseExpandAll(
                    value === "CollapseAll",
                    collapsedState,
                    setCollapsedState
                  )
            }
          />
          <div className="container-fluid" style={{ marginTop: "5px" }}>
            <ExpandCollapseDetailSection
              sectionTitle="Test"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Testing/Test-Run-Result-Screen&anchor=test-section"
            >
              <StyledRowDiv className="row">
                <div className="col-12 col-md-6">
                  <button
                    type="button"
                    title="Edit test"
                    className="btn btn-link"
                    onClick={() => navigate("/test/" + testResult.id)}
                  >
                    {testResult.description}
                  </button>
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
            <ExpandCollapseDetailSection
              sectionTitle="Run Info"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Testing/Test-Run-Result-Screen&anchor=run-info-section"
            >
              <StyledRowDiv className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Result</label>
                    <div className="field">
                      <StatusPill status={testResult.lastRunResultStatus} />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <ReadOnly
                    id="lastRunDate"
                    label="Run Date"
                    name="lastRunDate"
                    value={formatDateTimeUtcZoneForDisplay(
                      testResult.lastRunDate
                    )}
                  />
                </div>
                <div className="col-6">
                  <ReadOnly
                    id="lastRunDurationMilliseconds"
                    label="Duration"
                    name="lastRunDurationMilliseconds"
                    value={getTimeframeFromMilliseconds(
                      testResult.lastRunDurationMilliseconds
                    )}
                  />
                </div>
                <div className="col-6">
                  <ReadOnly
                    id="lastRunBy"
                    label="Run By"
                    name="lastRunBy"
                    value={testResult.lastRunBy}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
            <ExpandCollapseDetailSection
              sectionTitle="Step Results"
              collapsedState={collapsedState}
              setCollapsedState={setCollapsedState}
              helpLink="/Testing/Test-Run-Result-Screen&anchor=step-results-section"
            >
              <StyledRowDiv className="row">
                <div className="col-12">
                  <TestStepResultsList
                    isAdmin={auth.isAdmin}
                    testResultId={resultId}
                    testSteps={testResult.testSteps || []}
                    totalRecords={testResult.testSteps?.length || 0}
                    search={testSearch}
                    setSearch={setTestSearch}
                    onCollapseExpandJson={handleCollapseExpandJson}
                  />
                </div>
              </StyledRowDiv>
            </ExpandCollapseDetailSection>
          </div>
        </>
      )}
    </Authorize>
  );
}

export default TestResults;
