import React, { useState, useEffect } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
} from "../../../services/NotificationService";
import TextInput from "../../common/input/TextInput";
import {
  createViewModel,
  fromViewModel,
  emptyPrescriber,
} from "../../../viewmodels/prescribersVm";
import DatePickerInput from "../../common/input/DatePickerInput";
import {
  isPositiveInteger,
  states,
  genders,
  handleCollapseExpandAll,
} from "../../../services/General";
import { useNavigate, useParams } from "react-router-dom";
import SelectInput from "../../common/input/SingleSelect";
import { usePrescribers } from "../../../contexts/PrescribersContext";
import { useAuth } from "../../../contexts/AuthContext";
import {
  apiAddPrescriber,
  apiLoadPrescriber,
  apiUpdatePrescriber,
} from "../../../api/PrescriberApi";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import Authorize from "../../common/layout/Authorize";
import {
  StyledBackButtonDiv,
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
  StyledRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import ActionMenu from "../../common/ui/ActionMenu";
import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function Prescriber() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { prescribersData, setPrescribersData } = usePrescribers();
  const [errors, setErrors] = useState({});
  const [changes, setChanges] = useState(emptyPrescriber);
  const { loading, api: apiLoad } = useApi(apiLoadPrescriber);
  const { loading: adding, api: apiAdd } = useApi(apiAddPrescriber);
  const { loading: updating, api: apiUpdate } = useApi(apiUpdatePrescriber);
  const [collapsedState, setCollapsedState] = useState([
    { name: "Prescriber", collapsed: false },
    { name: "Effective Range", collapsed: true },
    { name: "Contact", collapsed: true },
  ]);

  const resId = params && params.id;

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setPrescribersData({
        type: ContextProviderActions.loadPrescriber,
        payload: emptyPrescriber,
      });

      loadPrescriber();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (prescribersData.prescriber) {
      setChanges(prescribersData.prescriber);
    } else {
      setChanges(emptyPrescriber);
    }
  }, [prescribersData.prescriber]);

  async function loadPrescriber() {
    if (!resId) {
      setPrescribersData({
        type: ContextProviderActions.loadPrescriber,
        payload: emptyPrescriber,
      });
      setChanges(emptyPrescriber);
      return;
    }

    apiLoad.call(resId, (result) => {
      let vm = emptyPrescriber;
      if (!result) {
        notifyError("Prescriber does not exist");
      } else {
        vm = createViewModel(result);
      }

      setPrescribersData({
        type: ContextProviderActions.loadPrescriber,
        payload: vm,
      });
      setChanges(vm);
    });
  }

  async function handleSubmit(event) {
    await handleSave(event, changes);
  }

  function formIsValid() {
    const _errors = {};
    if (_.has(changes, "lastName") && changes.lastName.trim() === "")
      _errors.lastName = "Last Name must be entered";
    if (
      _.has(changes, "npi") &&
      ((changes.npi || "").trim().length !== 10 ||
        !isPositiveInteger(changes.npi))
    )
      _errors.npi = "NPI must be a valid number of 10 digits";

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function handleChange({ target }) {
    let changed = { ...changes, [target.name]: target.value };
    setChanges(changed);
  }

  function handleStateChange(value) {
    setChanges({ ...changes, stateVm: value });
  }

  function handleGenderChange(value) {
    setChanges({ ...changes, genderVm: value });
  }

  function handleEffectiveDateChange(date) {
    setChanges({ ...changes, effectiveDate: date });
  }

  function handleTerminationDateChange(date) {
    setChanges({ ...changes, terminationDate: date });
  }

  async function handleSave(event, updated) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }
    const newVm = updated ? updated : { ...changes };

    if (!resId) {
      addPrescriber(newVm);
    } else updatePrescriber(newVm.id, newVm);
  }

  async function addPrescriber(vm) {
    var model = fromViewModel(vm);

    apiAdd.call(model, (result) => {
      setChanges(createViewModel(result));
      notifySuccess("Prescriber " + vm.npi + " saved successfully");

      // Navigate to the edit URL so user can save repeatedly. Replace the old create route in history so back works.
      navigate("/prescriber/" + result.id, { replace: true });
    });
  }

  async function updatePrescriber(id, vm) {
    var model = fromViewModel(vm);

    apiUpdate.call({ id, model }, (result) => {
      setChanges(createViewModel(result));
      notifySuccess("Prescriber " + vm.npi + " saved successfully");
    });
  }

  return (
    <Authorize>
      <form onSubmit={handleSubmit}>
        <StyledBackButtonDiv>
          <button
            type="button"
            title="Return To Prescribers"
            className="btn btn-link btn-with-icon"
            onClick={() => navigate("/configureprescribers")}
          >
            <i className="fa fa-angle-left"></i> Back
          </button>
          <StyledScreenHelpWithBackDiv>
            <HelpLink path="/Configure/Prescriber-Screen" label="Help" />
          </StyledScreenHelpWithBackDiv>
        </StyledBackButtonDiv>
        <StyledHeaderRowDiv>
          <h1>Prescriber Details {changes.npi ? ` | ${changes.npi}` : ""}</h1>
          <StyledHeaderRowButtonDiv>
            <button
              type="submit"
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/configureprescribers")}
              style={{ marginLeft: "12px" }}
            >
              Cancel
            </button>
          </StyledHeaderRowButtonDiv>
        </StyledHeaderRowDiv>

        {loading || adding || updating ? (
          <Spinner />
        ) : (
          <>
            <ActionMenu
              title="Actions"
              items={[
                { value: "ExpandAll", label: "Expand All" },
                { value: "CollapseAll", label: "Collapse All" },
                {
                  value: "ViewChangeHistory",
                  label: "View Change History",
                  isLink: true,
                  show: auth.isAdmin,
                  url: `/auditrecord/prescriber/${resId}/0`,
                },
              ]}
              onSelectAction={(value, label) =>
                handleCollapseExpandAll(
                  value === "CollapseAll",
                  collapsedState,
                  setCollapsedState
                )
              }
            />
            <div className="container-fluid" style={{ marginTop: "5px" }}>
              <ExpandCollapseDetailSection
                sectionTitle="Prescriber"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Prescriber-Screen&anchor=prescriber"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="npi"
                      label="Prescriber NPI"
                      onChange={handleChange}
                      placeholder="Prescriber NPI"
                      name="npi"
                      value={changes.npi || ""}
                      error={errors.npi}
                    />
                  </div>
                  <div className="col-6 col-lg-3 col-xl-3">
                    <TextInput
                      id="deaNumber"
                      label="Prescriber DEA Number"
                      onChange={handleChange}
                      placeholder="Prescriber DEA Number"
                      name="deaNumber"
                      value={changes.deaNumber || ""}
                      error={errors.deaNumber}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Effective Range"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Prescriber-Screen&anchor=effective-range"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="effectiveDate"
                      name="effectiveDate"
                      label="Effective Date"
                      value={changes.effectiveDate}
                      placeholder="Effective Date"
                      onChange={handleEffectiveDateChange}
                      error={errors.effectiveDate}
                      showTimeInput={false}
                    />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3">
                    <DatePickerInput
                      id="terminationDate"
                      label="Termination Date"
                      name="terminationDate"
                      value={changes.terminationDate}
                      placeholder="Termination Date"
                      onChange={handleTerminationDateChange}
                      error={errors.terminationDate}
                      showTimeInput={false}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
              <ExpandCollapseDetailSection
                sectionTitle="Contact"
                collapsedState={collapsedState}
                setCollapsedState={setCollapsedState}
                helpLink="/Configure/Prescriber-Screen&anchor=contact"
              >
                <StyledRowDiv className="row">
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="lastName"
                      label="Last Name"
                      onChange={handleChange}
                      placeholder="Last Name"
                      name="lastName"
                      value={changes.lastName || ""}
                      error={errors.lastName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="firstName"
                      label="First Name"
                      onChange={handleChange}
                      placeholder="First Name"
                      name="firstName"
                      value={changes.firstName || ""}
                      error={errors.firstName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="middleName"
                      label="Middle Name"
                      onChange={handleChange}
                      placeholder="Middle Name"
                      name="middleName"
                      value={changes.middleName || ""}
                      error={errors.middleName}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="title"
                      label="Title"
                      onChange={handleChange}
                      placeholder="Title"
                      name="title"
                      value={changes.title || ""}
                      error={errors.title}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="subtitle"
                      label="Subtitle"
                      onChange={handleChange}
                      placeholder="Subtitle"
                      name="subtitle"
                      value={changes.subtitle || ""}
                      error={errors.subtitle}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="suffix"
                      label="Suffix"
                      onChange={handleChange}
                      placeholder="Suffix"
                      name="suffix"
                      value={changes.suffix || ""}
                      error={errors.suffix}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <SelectInput
                      id="gender"
                      name="gender"
                      label="Gender"
                      options={genders}
                      value={changes.genderVm || ""}
                      onChange={handleGenderChange}
                      placeholder="Select a Gender"
                      error={errors.gender}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="emailAddresses"
                      label="Email Addresses"
                      onChange={handleChange}
                      placeholder="Email Addresses"
                      name="emailAddressesVm"
                      value={changes.emailAddressesVm}
                      error={errors.emailAddresses}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="address"
                      label="Address"
                      onChange={handleChange}
                      placeholder="Address"
                      name="address"
                      value={changes.address || ""}
                      error={errors.address}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="city"
                      label="City"
                      onChange={handleChange}
                      placeholder="City"
                      name="city"
                      value={changes.city || ""}
                      error={errors.city}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <SelectInput
                      id="state"
                      name="state"
                      label="State"
                      options={states}
                      value={changes.stateVm || ""}
                      onChange={handleStateChange}
                      placeholder="Select a State"
                      error={errors.state}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="zipCode"
                      label="Zip Code"
                      onChange={handleChange}
                      placeholder="Zip Code"
                      name="zipCode"
                      value={changes.zipCode || ""}
                      error={errors.zipCode}
                    />
                  </div>
                  <div className="col-6 col-lg-4 col-xl-3">
                    <TextInput
                      id="number"
                      label="Phone Number"
                      onChange={handleChange}
                      placeholder="Phone Number"
                      name="number"
                      value={changes.number || ""}
                      error={errors.number}
                    />
                  </div>
                </StyledRowDiv>
              </ExpandCollapseDetailSection>
            </div>
          </>
        )}
      </form>
    </Authorize>
  );
}

export default Prescriber;
