import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyClaim, emptyClaimSearch } from "../viewmodels/claimsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadClaims:
      return {
        ...state,
        claims: action.payload.claims,
        count: action.payload.count,
      };

    case ContextProviderActions.saveClaimSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadClaim:
      return {
        ...state,
        claim: action.payload,
      };

    case ContextProviderActions.loadClaimsTraffic:
      return {
        ...state,
        claimsTraffic: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in ClaimsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  claims: [],
  claim: emptyClaim,
  search: emptyClaimSearch,
  count: 0,
  claimsTraffic: {},
};

const Claims = createContext({
  claimsData: initialState,
  setClaimsData: () => null,
});

// eslint-disable-next-line react/prop-types
const ClaimsProvider = ({ children }) => {
  const [claimsData, setClaimsData] = useReducer(reducer, initialState);

  return (
    <Claims.Provider value={{ claimsData, setClaimsData }}>
      {children}
    </Claims.Provider>
  );
};

export default ClaimsProvider;
export const useClaims = () => useContext(Claims);
