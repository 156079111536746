import React from "react";
import TextInput from "../../common/input/TextInput";
import PropTypes from "prop-types";
import DatePickerInput from "../../common/input/DatePickerInput";
import DateRangeInput from "../../common/input/DateRangeInput";
import { useMobile } from "../../../hooks/useMobile";
import MultiSelectInput from "../../common/input/MultiSelectInput";

function LogSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onStartDateChange,
  onEndDateChange,
  onDateRangeChange,
  onLogTypesChanged,
}) {
  const { isMobileSize } = useMobile();

  const logTypes = [
    { label: "Debug", value: "Debug" },
    { label: "Error", value: "Error" },
    { label: "Fatal", value: "Fatal" },
    { label: "Info", value: "Info" },
    { label: "Trace", value: "Trace" },
    { label: "Warn", value: "Warn" },
  ];

  return (
    <form onSubmit={onSearch} onReset={onReset}>
      <div className="filter-search-form">
        <TextInput
          id="traceId"
          label="Trace Id"
          onChange={onChange}
          placeholder=""
          name="traceId"
          value={search.traceId}
          error={errors.traceId}
        />
        <TextInput
          id="environment"
          label="Environment"
          onChange={onChange}
          placeholder=""
          name="environment"
          value={search.environment}
          error={errors.environment}
        />
        <TextInput
          id="logHeaderSearch"
          label="Text Search"
          onChange={onChange}
          placeholder=""
          name="logHeaderSearch"
          value={search.logHeaderSearch}
          error={errors.logHeaderSearch}
        />
        <TextInput
          id="userSearch"
          label="User"
          onChange={onChange}
          placeholder=""
          name="userSearch"
          value={search.userSearch}
          error={errors.userSearch}
        />
        <MultiSelectInput
          id="logTypes"
          name="logTypes"
          label="Log Type"
          options={logTypes}
          value={search.logTypes}
          onChange={onLogTypesChanged}
          placeholder="All"
          error={errors.logTypes}
        />
        <DateRangeInput
          id="dateRange"
          name="dateRange"
          label="Date Range"
          value={search.dateRange}
          onChange={onDateRangeChange}
          error={errors.dateRange}
        />
        <DatePickerInput
          id="startDate"
          name="startDate"
          label="Effective Date"
          value={search.startDate}
          placeholder=""
          onChange={onStartDateChange}
          error={errors.startDate}
        />
        <DatePickerInput
          id="endDate"
          label="Termination Date"
          name="endDate"
          value={search.endDate}
          placeholder=""
          onChange={onEndDateChange}
          error={errors.endDate}
        />
      </div>
      <div className="grid-search-form-action-row">
        <input
          type="submit"
          value={isMobileSize ? "Apply" : "Search"}
          className="btn btn-primary"
        />
        <input type="reset" value="Clear" className="btn btn-secondary" />
      </div>
    </form>
  );
}

LogSearchForm.propTypes = {
  search: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default LogSearchForm;
