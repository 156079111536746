import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { emptyTestDataPopulatorConfig } from "../viewmodels/testDataPopulatorConfigVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadTestDataPopulatorConfig:
      return {
        ...state,
        testDataPopulatorConfig: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in TestDataPopulatorConfigContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  testDataPopulatorConfig: emptyTestDataPopulatorConfig,
};

const TestDataPopulatorConfig = createContext({
  testDataPopulatorConfigData: initialState,
  setTestDataPopulatorConfigData: () => null,
});

// eslint-disable-next-line react/prop-types
const TestDataPopulatorConfigProvider = ({ children }) => {
  const [testDataPopulatorConfigData, setTestDataPopulatorConfigData] =
    useReducer(reducer, initialState);

  return (
    <TestDataPopulatorConfig.Provider
      value={{ testDataPopulatorConfigData, setTestDataPopulatorConfigData }}
    >
      {children}
    </TestDataPopulatorConfig.Provider>
  );
};

export default TestDataPopulatorConfigProvider;
export const useTestDataPopulatorConfig = () =>
  useContext(TestDataPopulatorConfig);
