import React from "react";
import { useNavigate } from "react-router-dom";
import {
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
} from "../../../services/General";
import StatusPill from "../../common/ui/StatusPill";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function TestRunResultsList({
  testResults,
  search,
  setSearch,
  onSort,
  totalRecords,
}) {
  const navigate = useNavigate();

  function getActions(dataRow) {
    const id = dataRow.id;
    const entityId = dataRow.entityId;
    const isGroup = dataRow.resultType === "Group";

    const actions = [];

    actions.push({
      name: isGroup ? "View test group results" : "View test results",
      onClick: () =>
        navigate(
          (isGroup ? "/testgroupresults/" : "/testresults/") +
            entityId +
            "/" +
            id
        ),
    });

    // Passing autorun parameter set to true to test and testgroup screens to indicate to run immediately
    actions.push({
      name: isGroup ? "Run test group again" : "Run test again",
      onClick: () =>
        navigate((isGroup ? "/testgroup/" : "/test/") + entityId + "/true"),
    });

    actions.push({
      name: isGroup ? "Edit test group" : "Edit test",
      onClick: () => navigate((isGroup ? "/testgroup/" : "/test/") + entityId),
    });

    return actions;
  }

  return (
    <>
      <ResponsiveGrid
        gridId="TestRunResults"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        onSort={onSort}
        dataRows={testResults}
        columnDefs={[
          {
            name: "results",
            label: "Results",
            disableSort: true,
            style: { width: "70px", maxWidth: "15%", textAlign: "left" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                title="View the results of this test run"
                onClick={() =>
                  navigate(
                    row.resultType === "Group"
                      ? `/testgroupresults/${row.entityId}/${row.id}`
                      : `/testresults/${row.entityId}/${row.id}`
                  )
                }
              >
                view
              </button>
            ),
          },
          {
            name: "runDate",
            label: "Run Date",
            disableSort: true,
            style: { width: "25%" },
            className: "d-none d-md-table-cell",
            getValue: (row) => formatDateTimeUtcZoneForDisplay(row.runDate),
          },
          {
            name: "resultType",
            label: "Type",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
          },
          {
            name: "entityDescription",
            label: "Description",
            disableSort: true,
            style: { width: "30%" },
            getValue: (row) => (
              <button
                className="btn btn-link link-underline"
                title="View the results of this test run"
                onClick={() =>
                  navigate(
                    row.resultType === "Group"
                      ? `/testgroup/${row.entityId}`
                      : `/test/${row.entityId}`
                  )
                }
              >
                {row.entityDescription}
              </button>
            ),
          },
          {
            name: "runBy",
            label: "Run By",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
          },
          {
            name: "runDurationMilliseconds",
            label: "Duration",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              getTimeframeFromMilliseconds(row.runDurationMilliseconds),
          },
          {
            name: "runResultStatus",
            label: "Result",
            disableSort: true,
            noForceWrap: true,
            style: { width: "15%" },
            getValue: (row) => <StatusPill status={row.runResultStatus} />,
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default TestRunResultsList;
