import _ from "lodash";
import { MMDDyyyy } from "../services/General";
import { parseISO } from "date-fns";
import { pageSize } from "../constants/Environments";

export const discountTypes = [
  { label: "Per Unit", value: "perunit" },
  { label: "Percent", value: "percent" },
  { label: "Flat Value", value: "flat" },
];

export const discountModes = [
  { label: "Normal", value: "normal" },
  { label: "Non-Stackable", value: "NonStackable" },
  { label: "Always Apply", value: "AlwaysApply" },
  { label: "Priority", value: "Priority" },
];

var today = new Date();
var tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
var oneHourAgo = new Date();
oneHourAgo.setHours(oneHourAgo.getHours() - 1);

export const emptyDiscount = {
  groupId: "",
  ndcs: [],
  pharmacies: [],
  prescribers: [],
  discountPlan: "",
  discountAmount: 0,
  discountType: "",
  maxCount: 0,
  maximumQuantity: 0,
  memberId: "",
  maximumPaid: 0,
  discountMode: "",
  discountTypeVm: "",
  discountModeVm: "",
  pharmaciesVm: "",
  ndcsVm: "",
  prescribersVm: "",
  metricStrengthPerUnit: 0,
  currentCount: 0,
  currentPaid: 0,
  currentQty: 0,
  remainingBenefit: 0,
  remainingUses: 0,
  remainingQty: 0,
  initialPrice: 0,
  effectiveDate: today,
  terminationDate: new Date("1/1/3000"),
  conditionals: [],
  authorizedRejections: [],
  authorizedRejectionsVm: "",
  effTermVm: "",
  memberFirstName: "",
  memberLastName: "",
  memberDateOfBirth: "",
  memberPersonCode: 0,
  active: false,
  addedMemberIds: [],
  generateMemberIds: 0,
};

export const emptyDiscountSearch = {
  id: "",
  groupId: "",
  pharmacy: "",
  prescriber: "",
  ndc: "",
  memberId: "",
  discountPlan: "",
  selectedDiscountId: "",
  prescriptionNumber: "",
  active: true,
  initialPrice: 0,
  unreversed: false,
  processedDate: null,
  lookupQty: 0,
  drOrderBy: ["redemptionDate+"],
  orderBy: ["lastModifiedDate-"],
  redemptionPageSize: pageSize || 25,
  pageSize: pageSize || 25,
  redemptionPageNumber: 1,
  discountRedemptionsCount: 0,
  pageNumber: 1,
  discountsCount: 0,
  freeFormSearch: "",
  showAdvancedFilter: false,
  showColumnNames: false,
  startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  endDate: new Date("1/1/3000"),
  dateRange: "Past24hours",
};

export const emptyDiscountRedemption = {
  authorizationNumber: "",
  claimId: "",
  claimMemberFirstName: "",
  claimMemberLastName: "",
  discountAmount: 0,
  discountPlan: "",
  discountType: "",
  effectiveDate: "",
  terminationDate: "",
  fillDate: "",
  thirdPartyPayAmount: 0,
  groupId: "",
  memberId: "",
  message: "",
  ndc: "",
  pharmacy: "",
  prescriber: "",
  priceFromProcessor: 0,
  reversalClaimId: "",
  reversed: false,
  selectedDiscountId: "",
  redemptionDate: "",
  discountMode: "",
  maximumPaid: 0,
  patientPayAmount: 0,
  remainingBenefit: 0,
  remainingUses: 0,
  remainingQty: 0,
  memberFirstName: "",
  memberLastName: "",
  prescriptionNumber: "",
  quantity: 0,
  maximumQuantity: 0,
  orderBy: ["lastModifiedDate-"],
  pageSize: pageSize || 20,
  pageNumber: 1,
};

export function fromViewModel(vm) {
  let m = _.cloneDeep(vm);
  if (_.isEmpty(m.pharmaciesVm)) m.pharmacies = [];
  else m.pharmacies = m.pharmaciesVm.split(",").trimArray();
  if (_.isEmpty(m.ndcsVm)) m.ndcs = [];
  else m.ndcs = m.ndcsVm.split(",").trimArray();
  if (_.isEmpty(m.prescribersVm)) m.prescribers = [];
  else m.prescribers = m.prescribersVm.split(",").trimArray();
  m.discountType = vm.discountTypeVm.value || "";
  m.discountMode = vm.discountModeVm.value || "";
  return m;
}

export function createViewModel(m) {
  let vm = _.cloneDeep(m);
  if (_.isNull(vm.effectiveDate)) {
    vm.effectiveDate = emptyDiscount.effectiveDate;
  } else {
    vm.effectiveDate = parseISO(vm.effectiveDate);
  }
  if (_.isNull(vm.terminationDate))
    vm.terminationDate = emptyDiscount.terminationDate;
  else {
    vm.terminationDate = parseISO(vm.terminationDate);
  }
  vm.processedDate = parseISO(vm.processedDate);
  vm.pharmaciesVm = vm.pharmacies.sort().join(", ") || "";
  vm.ndcsVm = vm.ndcs.sort().join(", ") || "";
  vm.prescribersVm = vm.prescribers.sort().join(", ") || "";
  vm.discountTypeVm = discountTypes.find((d) => d.value === m.discountType) || {
    label: "Per Unit",
    value: "perunit",
  };
  vm.discountModeVm = discountModes.find((d) => d.value === m.discountMode) || {
    label: "Normal",
    value: "normal",
  };
  let term = MMDDyyyy(vm.terminationDate);
  if (term === "01/01/3000") term = "None";
  vm.effTermVm = MMDDyyyy(vm.effectiveDate) + " to " + term;

  if (vm.generateMemberIds === null) vm.generateMemberIds = 0;
  if (vm.memberId === null) vm.memberId = "";
  if (vm.memberFirstName === null) vm.memberFirstName = "";
  if (vm.memberLastName === null) vm.memberLastName = "";
  if (vm.memberDateOfBirth === null) vm.memberDateOfBirth = "";
  if (vm.maxCount === null) vm.maxCount = 0;
  if (vm.maximumQuantity === null) vm.maximumQuantity = 0;
  if (vm.maximumPaid === null) vm.maximumPaid = 0;
  if (vm.currentCount === null) vm.currentCount = 0;
  if (vm.currentPaid === null) vm.currentPaid = 0;
  if (vm.currentQty === null) vm.currentQty = 0;

  return vm;
}

export function createDrViewModel(m) {
  let vm = _.cloneDeep(m);
  if (_.isNull(vm.effectiveDate)) {
    vm.effectiveDate = emptyDiscount.effectiveDate;
  } else {
    vm.effectiveDate = parseISO(vm.effectiveDate);
  }
  if (_.isNull(vm.terminationDate))
    vm.terminationDate = emptyDiscount.terminationDate;
  else {
    vm.terminationDate = parseISO(vm.terminationDate);
  }
  return vm;
}
