import React from "react";
import { disableAnimations } from "../../../services/General";
import { Modal } from "react-bootstrap";
import HelpLink from "../../common/ui/HelpLink";
import { StyledCardLogList, StyledDescription } from "./RuleMapCommonStyles";

function RuleMapLogsDialog({ showDialog, title, logs, onCloseDialog }) {
  const logCount = (logs || []).length;

  function getLogColors() {
    let bgColor = "var(--ruleparser-blue)";
    let color = "#fff";
    let bgShadow = "transparent";

    return { color, bgColor, bgShadow };
  }

  return (
    <Modal
      show={showDialog}
      onHide={onCloseDialog}
      animation={!disableAnimations()}
      dialogClassName="modal-dialog-large-width"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
          <HelpLink
            path="/RuleMaps/RuleMap-Screen&anchor=logs-dialog"
            label=""
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{`${logCount} log ${logCount === 1 ? "entry" : "entries"}`}</p>

        {logCount > 0 && (
          <>
            <StyledCardLogList>
              {logs.map((log, idx) => (
                <li
                  key={`log-${idx}`}
                  style={{
                    borderLeft: "2px solid var(--text-dark)",
                    paddingLeft: "8px",
                    marginBottom: "10px",
                  }}
                >
                  <StyledDescription
                    colors={getLogColors()}
                    style={{
                      columnGap: "10px",
                      marginBottom: "5px",
                      display: "block",
                    }}
                  >
                    <span
                      className={`option-member-name full-width`}
                      style={{ fontSize: "13px" }}
                    >
                      {log.logDate}
                    </span>
                    <span
                      className="full-width"
                      style={{ color: "var(--text-norma)" }}
                    >
                      {log.message}
                    </span>
                  </StyledDescription>
                </li>
              ))}
            </StyledCardLogList>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onCloseDialog}
          style={{ marginLeft: "12px" }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default RuleMapLogsDialog;
