import React from "react";
import TextInput from "../../common/input/TextInput";
import CheckboxInput from "../../common/input/CheckboxInput";
import { useMobile } from "../../../hooks/useMobile";

function RuleTableSearchForm({
  search,
  errors,
  onChange,
  onSearch,
  onReset,
  onIncludeInactiveChange,
}) {
  const { isMobileSize } = useMobile();

  return (
    <>
      <form onSubmit={onSearch} onReset={onReset}>
        <div className="filter-search-form">
          <TextInput
            id="key"
            label="Key"
            onChange={onChange}
            placeholder="Key"
            name="key"
            value={search.key}
            error={errors.key}
          />
          <TextInput
            id="description"
            label="Description"
            onChange={onChange}
            placeholder="Description"
            name="description"
            value={search.description}
            error={errors.description}
          />
          <TextInput
            id="sourceRuleName"
            label="Rule"
            onChange={onChange}
            placeholder="Rule"
            name="sourceRuleName"
            value={search.sourceRuleName}
            error={errors.sourceRuleName}
          />
          {/* <TextInput
            id="ruleTableDefinitionName"
            label="Rule Table Definition"
            onChange={onChange}
            placeholder="Rule Table Definition"
            name="ruleTableDefinitionName"
            value={search.ruleTableDefinitionName}
            error={errors.ruleTableDefinitionName}
          /> */}
          <CheckboxInput
            id="includeInactive"
            label="Include inactive rule tables"
            onChange={onIncludeInactiveChange}
            placeholder="Include Inactive"
            name="includeInactive"
            checked={search.includeInactive}
            error={errors.includeInactive}
          />
        </div>
        <div className="grid-search-form-action-row">
          <input
            type="submit"
            value={isMobileSize ? "Apply" : "Search"}
            className="btn btn-primary"
          />
          <input type="reset" value="Clear" className="btn btn-secondary" />
        </div>
      </form>
    </>
  );
}

export default RuleTableSearchForm;
