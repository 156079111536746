import { useState } from "react";
import _ from "lodash";

export default function useRuleEditParser() {
  const [positionBeforeEnter, setPositionBeforeEnter] = useState(null);

  const ParseState = {
    Default: 0,
    StartOperator: 10,
    Operator: 11,
    EndOperator: 12,
    FormatSpecifier: 20,
    FormatSpecifierValue: 21,
    EndFormatSpecifier: 22,
    StartCondition: 30,
    ConditionType: 31,
    ConditionVariable: 32,
    ConditionOperator: 33,
    StartConditionValue: 34,
    ConditionValue: 35,
    EndConditionValue: 36,
    EndCondition: 37,
    ConditionSeparator: 38,
    StartSymbolLookup: 40,
    SymbolLookup: 41,
    EndSymbolLookup: 42,
    StartFunction: 50,
    Function: 51,
    EndFunction: 52,
    StartRuleTableLookup: 60,
    StartRuleTablePart: 61,
    EndRuleTablePart: 62,
    RuleTablePartDelimiter: 63,
  };

  const ConditionTypes = ["iv", "req", "rsp", "ed", "gs"];
  const OperatorTypes = [
    "eq",
    "neq",
    "in",
    "nin",
    "gt",
    "ge",
    "lt",
    "le",
    "sw",
    "ew",
    "ltlen",
    "gtlen",
    "len",
    "mult",
    "nmult",
    "matches",
    "nmatches",
    "empty",
    "nempty",
    "false",
    "true",
    "null",
    "notnull",
    "zero",
    "nzero",
  ];
  const Operators = ["and", "or", "then", "else", ...OperatorTypes];
  const Functions = [
    "first",
    "first-n",
    "last",
    "last-n",
    "last-n-where",
    "last-sum-n-where",
    "sum",
    "sum-n",
    "sum-n-where",
  ];

  function getTokensFromParseString(text) {
    // Split source into an array of tokens - splitting on whitespace, and all possible symbols, but preserve the symbols
    //  Symbols to split on: <spc> <newlines> <tab> { } | : [ ] /
    const tokens = [];
    if (_.trim(text) === "") return tokens;

    // jon, 4/20/23: Changed from regular expression to loop since iOS does not support lookbehind (?<=) and causes a blank screen in app.
    const TokenChars = " \r\n\t{}|:/[]";
    let s = "";
    for (let i = 0; i < text.length; i++) {
      // Do we have a token?
      if (TokenChars.indexOf(text[i]) >= 0) {
        // Add previous text token (if any) and reset for next one. Also, add this token to the array.
        if (s !== "") {
          tokens.push(s);
          s = "";
        }
        tokens.push(text[i]);
      } else {
        // Keep building text string
        s += text[i];
      }
    }

    // Add the final string token if there is one
    if (s !== "") {
      tokens.push(s);
    }

    return tokens;
  }

  function getLookaheadToken(tokens, position) {
    let token = "";

    if (position + 1 < tokens.length) {
      token = tokens[position + 1];
    }

    return "" + token;
  }

  function parseTextContentIntoHtml(srcText) {
    // Split source into an array of tokens - splitting on whitespace, and all possible symbols, but preserve the symbols
    const tokens = getTokensFromParseString(srcText);

    let stateStack = []; // Using an array to simulate a stack here of the current state.
    let isError = false;
    let pos = 0;
    let state;
    let s = "";
    let token = "";
    let lookahead = "";

    // Initial state
    stateStack.push(ParseState.Default);

    while (!isError && pos < tokens.length && stateStack.length > 0) {
      // Pop the latest stack from top of the stack
      state = stateStack.pop();
      token = tokens[pos];
      lookahead = getLookaheadToken(tokens, pos).toLowerCase();

      switch (state) {
        case ParseState.Default: {
          // In default state, check for all possible tokens to see if we need to enter a different state.
          if (
            Functions.indexOf(token.toLowerCase()) >= 0 &&
            lookahead === ":"
          ) {
            stateStack.push(ParseState.StartFunction);
          } else if (
            token === ":" &&
            Operators.indexOf(lookahead.toLowerCase()) >= 0
          ) {
            stateStack.push(ParseState.StartOperator);
          } else if (
            (token === "n" || token === "b" || token === "today") &&
            lookahead === ":"
          ) {
            stateStack.push(ParseState.FormatSpecifier);
          } else if (
            token === "{" &&
            ConditionTypes.indexOf(lookahead.toLowerCase()) >= 0
          ) {
            stateStack.push(ParseState.StartCondition);
          } else if (token === "/" && lookahead === "/") {
            stateStack.push(ParseState.StartRuleTableLookup);
          } else if (token === "/" && lookahead !== "/") {
            stateStack.push(ParseState.RuleTablePartDelimiter);
          } else if (token === "[") {
            stateStack.push(ParseState.StartSymbolLookup);
          } else {
            // Stay in default state and add this token to the output
            pos++;
            s += token;

            // If we already have a state on the stack, just use that. Otherwise, set back to default
            if (stateStack.length === 0) {
              stateStack.push(ParseState.Default);
            }
          }
          break;
        }

        case ParseState.StartCondition: {
          s += `<span class='condition'>`;
          s += `<span class='delim'>${token}</span>`;
          pos++;
          stateStack.push(ParseState.ConditionType);
          break;
        }

        case ParseState.ConditionType: {
          s += `<span class='condtype'>${token}</span>`;
          pos++;

          if (lookahead === "|") {
            stateStack.push(ParseState.ConditionVariable);
            stateStack.push(ParseState.ConditionSeparator);
          } else if (lookahead === "}") {
            stateStack.push(ParseState.EndCondition);
          } else {
            stateStack.push(ParseState.Default);
          }

          break;
        }

        case ParseState.ConditionVariable: {
          if (lookahead === "|") {
            s += `${token}`;
            pos++;
            stateStack.push(ParseState.ConditionOperator);
            stateStack.push(ParseState.ConditionSeparator);
          } else if (lookahead === "}") {
            s += `${token}`;
            pos++;
            stateStack.push(ParseState.EndCondition);
          } else {
            stateStack.push(ParseState.ConditionVariable);
            stateStack.push(ParseState.Default);
          }

          break;
        }

        case ParseState.ConditionOperator: {
          if (OperatorTypes.indexOf(token.toLowerCase()) >= 0) {
            s += `<span class='operatortype'>${token}</span>`;
            pos++;

            if (lookahead === "|") {
              stateStack.push(ParseState.StartConditionValue);
              stateStack.push(ParseState.ConditionSeparator);
            } else if (lookahead === "}") {
              stateStack.push(ParseState.EndCondition);
            } else {
              stateStack.push(ParseState.Default);
            }
          } else {
            stateStack.push(ParseState.StartConditionValue);
          }

          break;
        }

        case ParseState.StartConditionValue: {
          s += `<span class='condvalue'>`;
          stateStack.push(ParseState.ConditionValue);
          break;
        }

        case ParseState.ConditionValue: {
          if (lookahead === "}") {
            s += `${token}`;
            pos++;
            stateStack.push(ParseState.EndCondition);
            stateStack.push(ParseState.EndConditionValue);
          } else {
            stateStack.push(ParseState.EndCondition);
            stateStack.push(ParseState.EndConditionValue);
            stateStack.push(ParseState.Default);
          }

          break;
        }

        case ParseState.EndConditionValue: {
          s += "</span>";

          // If we already have a state on the stack, just use that. Otherwise, set back to default
          if (stateStack.length === 0) {
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.ConditionSeparator: {
          s += "<span class='delim'>|</span>";
          pos++;
          break;
        }

        case ParseState.EndCondition: {
          s += `<span class='delim'>${token}</span>`;
          s += "</span>";
          pos++;

          // If we already have a state on the stack, just use that. Otherwise, set back to default
          if (stateStack.length === 0) {
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.StartOperator: {
          s += `<span class='joinop'>`;
          s += `<span class='delim'>${token}</span>`;
          pos++;
          stateStack.push(ParseState.Operator);
          break;
        }

        case ParseState.Operator: {
          s += token;

          // Look ahead to see if this operator has a closing :
          if (lookahead === ":") {
            stateStack.push(ParseState.EndOperator);
          } else {
            stateStack.push(ParseState.Default);
          }
          pos++;

          break;
        }

        case ParseState.EndOperator: {
          s += `<span class='delim'>${token}</span>`;
          s += "</span>";
          pos++;

          // If we already have a state on the stack, just use that. Otherwise, set back to default
          if (stateStack.length === 0) {
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.FormatSpecifier: {
          s += `<span class='typespec'>${token}</span>`;
          pos++;
          s += "<span class='delim'>:</span>";
          pos++;
          stateStack.push(ParseState.FormatSpecifierValue);
          break;
        }

        case ParseState.FormatSpecifierValue: {
          s += `<span class='typespecvalue'>`;
          stateStack.push(ParseState.EndFormatSpecifier);
          stateStack.push(ParseState.Default);
          break;
        }

        case ParseState.EndFormatSpecifier: {
          s += "</span>";

          // If we already have a state on the stack, just use that. Otherwise, set back to default
          if (stateStack.length === 0) {
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.StartSymbolLookup: {
          s += `<span class='symbol'>`;
          s += `<span class='delim'>${token}</span>`;
          pos++;
          stateStack.push(ParseState.SymbolLookup);
          break;
        }

        case ParseState.SymbolLookup: {
          stateStack.push(ParseState.EndSymbolLookup);
          // Just let default state handle anything that could be put in here
          stateStack.push(ParseState.Default);

          break;
        }

        case ParseState.EndSymbolLookup: {
          if (token !== "]") {
            // Keep consuming until we hit the end of the symbol lookup
            stateStack.push(ParseState.EndSymbolLookup);
            stateStack.push(ParseState.Default);
          } else {
            s += `<span class='delim'>${token}</span>`;
            s += "</span>";
            pos++;

            // If we already have a state on the stack, just use that. Otherwise, set back to default
            if (stateStack.length === 0) {
              stateStack.push(ParseState.Default);
            }
          }
          break;
        }

        case ParseState.StartFunction: {
          s += `<span class='function'>`;
          stateStack.push(ParseState.Function);
          break;
        }

        case ParseState.Function: {
          s += `${token}`;
          pos++;

          if (lookahead === ":") {
            stateStack.push(ParseState.EndFunction);
          } else {
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.EndFunction: {
          s += "</span>";
          s += `<span class='delim'>${token}</span>`;
          pos++;

          // If we already have a state on the stack, just use that. Otherwise, set back to default
          if (stateStack.length === 0) {
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.StartRuleTableLookup: {
          s += `<span class='delim'>//</span>`;
          pos += 2;
          stateStack.push(ParseState.StartRuleTablePart);
          break;
        }

        case ParseState.StartRuleTablePart: {
          s += `<span class='ruletable'>`;
          stateStack.push(ParseState.EndRuleTablePart);
          stateStack.push(ParseState.Default);
          break;
        }

        case ParseState.EndRuleTablePart: {
          s += "</span>";

          // If we already have a state on the stack, just use that. Otherwise, set back to default
          if (stateStack.length === 0) {
            stateStack.push(ParseState.Default);
          }
          break;
        }

        case ParseState.RuleTablePartDelimiter: {
          s += `<span class='delim'>/</span>`;
          pos++;
          stateStack.push(ParseState.StartRuleTablePart);
          break;
        }

        default: {
          console.log(`Parse Error. Unknown parsing state: ${state}`);
          isError = true;
        }
      }
    }

    if (isError) {
      console.log(
        "Stopped parsing due to error - in the future, add red squiggly under token."
      );
    }

    return s;
  }

  function isNodeRuleEditorDiv(node) {
    if (!node || node === null) return false;

    const ret = node.nodeName === "DIV" && node.className === "rule-editor";
    return ret;
  }

  function getNodeSize(node) {
    let size = 0;
    if (!node || node === null) return size;

    if (node.nodeName === "BR") {
      size = 1;
    } else {
      size = node.textContent.length;
    }

    return size;
  }

  function getCurrentCursorPosition(parentElement) {
    var selection = window.getSelection(),
      charCount = -1,
      enterKey = false,
      node;

    if (selection.focusNode) {
      if (isNodeRuleEditorDiv(selection.focusNode)) {
        // If the surrounding elements are <br />, the focus node will be returned as the contenteditable div. In this case,
        //   the focusOffset will be how many child nodes to skip to where the cursor is. But this messes up our algorithm since
        //   the number will be way lower than the total number of characters before the cursor position.  So go through childNodes
        //   up to the offset and compute size of each one.
        charCount = 0;

        if (positionBeforeEnter !== null) {
          // If we are processing an ENTER key on the main div, add an extra character for it.
          // console.log("just hit ENTER at position: " + positionBeforeEnter);
          enterKey = true;
          charCount = 1;
          setPositionBeforeEnter(null);
        }

        let i = 0;
        for (
          i = 0;
          i < selection.focusOffset &&
          i < selection.focusNode.childNodes.length;
          i++
        ) {
          node = selection.focusNode.childNodes[i];
          charCount += getNodeSize(node);
        }

        // If hitting enter and the next child node is also a BR tag, we overcalculated the position by 1, so subtract it out.
        if (
          enterKey === true &&
          i < selection.focusNode.childNodes.length &&
          selection.focusNode.childNodes[i].nodeName === "BR"
        ) {
          // console.log(
          //   `Hit enter and next child is BR so subtracting one character from ${charCount} to ${
          //     charCount - 1
          //   }`
          // );
          charCount -= 1;
        }

        // console.log(
        //   `Computing cursor position on main DIV using offset ${selection.focusOffset}: ${charCount}`
        // );
      } else if (_isChildOf(selection.focusNode, parentElement)) {
        node = selection.focusNode;

        if (positionBeforeEnter !== null) {
          // console.log("just hit ENTER at position: " + positionBeforeEnter);
          charCount = positionBeforeEnter + 1;
          setPositionBeforeEnter(null);
        } else {
          charCount = selection.focusOffset;

          while (node) {
            if (node === parentElement) {
              break;
            }

            if (node.previousSibling) {
              node = node.previousSibling;
              charCount += getNodeSize(node);
            } else {
              node = node.parentNode;
              if (node === null) {
                break;
              }
            }
          }
        }
      }
    }

    return charCount;
  }

  function _isChildOf(node, parentElement) {
    while (node !== null) {
      if (node === parentElement) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  }

  // function _getLookAheadChar(contents, pos) {
  //   if (pos + 1 >= contents.length) return "";
  //   else return contents[pos + 1];
  // }

  // function _lookAheadUntilTokens(contents, pos, tokens) {
  //   let s = "";
  //   let i = pos;

  //   while (i < contents.length) {
  //     if (tokens.indexOf(contents[i]) >= 0) {
  //       break;
  //     } else {
  //       s += contents[i];
  //       i++;
  //     }
  //   }

  //   return s;
  // }

  // function _isTokenChar(c) {
  //   return Tokens.indexOf(c) >= 0;
  // }

  // function _isInterruptTokenChar(c) {
  //   return InterruptTokens.indexOf(c) >= 0;
  // }

  // function _convertSpecialChars(c) {
  //   let s = c;

  //   switch (c.charCodeAt(0)) {
  //     case 10: // LF
  //       s = "<br />";
  //       break;
  //     case 13: // CR
  //       // ignore - use LF instead
  //       break;
  //     default:
  //       s = c;
  //   }

  //   return s;
  // }

  // function _isWhiteSpace(c) {
  //   const whiteSpaceCharCodes = [13, 10, 9, 32, 160]; // CR, LF, TAB, SPC, NBSPC
  //   return whiteSpaceCharCodes.indexOf(c.charCodeAt(0)) >= 0;
  // }

  function handleTabKeyPress(editor) {
    var doc = editor.ownerDocument.defaultView;
    var sel = doc.getSelection();
    var range = sel.getRangeAt(0);

    var newNode = document.createTextNode("\u00a0\u00a0\u00a0\u00a0");
    range.insertNode(newNode);

    range.setStartAfter(newNode);
    range.setEndAfter(newNode);
    sel.removeAllRanges();
    sel.addRange(range);
  }

  function handleEnterKeyPress(position) {
    setPositionBeforeEnter(position);
  }

  return {
    getCurrentCursorPosition,
    handleTabKeyPress,
    handleEnterKeyPress,
    parseTextContentIntoHtml,
  };
}
