import React, { Fragment, useState } from "react";
import _ from "lodash";
import Spinner from "../../common/ui/Spinner";
import { apiLoadRules } from "../../../api/RuleApi";
import { StyledRowDiv } from "../../common/layout/CommonStyledControls";
import ExpandCollapseDetailSection from "../../common/layout/ExpandCollapseDetailSection";
import useApi from "../../../hooks/useApi";

function DestinationRules({
  ruleId,
  ruleChanges,
  setRuleChanges,
  collapsedState,
  setCollapsedState,
}) {
  const { loading: loadingDestRules, api: apiLoadDestRules } =
    useApi(apiLoadRules);
  const [rulesLoaded, setRulesLoaded] = useState(false);

  function loadDestinationRules() {
    const loadedRuleId = ruleId;

    apiLoadDestRules.call("", (result) => {
      var filteredRules = [...result];
      filteredRules = filteredRules
        .filter((r) => r.id !== loadedRuleId)
        .map((r) => {
          return { ...r, selected: false };
        });

      let newRule = _.cloneDeep(ruleChanges);
      newRule.destinationRules = filteredRules;
      setRuleChanges(newRule);
    });
  }

  function handleDestinationRuleChange(event, index) {
    let newRule = _.cloneDeep(ruleChanges);
    let selectedRule = newRule.destinationRules[index];
    if (!_.isNull(selectedRule)) {
      if (event.target.checked) {
        selectedRule.selected = true;
      } else {
        selectedRule.selected = false;
      }
      setRuleChanges(newRule);
    }
  }

  function getGroupList(includedGroups) {
    const groups = (includedGroups || []).join(",");
    return groups;
  }

  return (
    <ExpandCollapseDetailSection
      sectionTitle="Destination Rules for Copy"
      collapsedState={collapsedState}
      setCollapsedState={(newState) => {
        if (!rulesLoaded) {
          setRulesLoaded(true);
          loadDestinationRules();
        }
        setCollapsedState(newState);
      }}
      helpLink="/Processing-Rules/Rule-Screen&anchor=destination-rules-for-copy"
    >
      {loadingDestRules ? (
        <Spinner spinnerStyle={{ height: "200px", lineHeight: "200px" }} />
      ) : (
        <StyledRowDiv className="row">
          <div className="col-12">
            <table className="table table-hover">
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "95%" }} />
              </colgroup>
              <tbody>
                {(ruleChanges.destinationRules || []).map((dr, dridx) => {
                  return (
                    <Fragment key={dridx}>
                      <tr>
                        <td>
                          <input
                            id={"dr" + dridx}
                            name={"dr" + dridx}
                            type="checkbox"
                            checked={dr.selected}
                            onChange={(e) =>
                              handleDestinationRuleChange(e, dridx)
                            }
                          ></input>
                        </td>
                        <td>
                          {dr.description} &nbsp;&nbsp;
                          <span className="option-member-name">
                            {getGroupList(dr.includedGroups)}
                          </span>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </StyledRowDiv>
      )}
    </ExpandCollapseDetailSection>
  );
}

export default DestinationRules;
