import _ from "lodash";
import { COVERAGE_TRACKING_ITEM } from "../contexts/UserPreferencesContext";

export async function handleResponse(response) {
  if (response.ok) {
    if (_.startsWith(response.status.toString(), "204")) return {};

    const json = response
      .json()
      .then((j) => {
        return j;
      })
      .catch(() => {
        return "";
      });
    return json;
  }
  if (
    _.startsWith(response.status.toString(), "4") ||
    _.startsWith(response.status.toString(), "5")
  ) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.

    const message = await response.text();

    // const error = `(${response.status}) ${message}`;

    const error = new Error(message);
    error.statusCode = response.status;
    throw error;
  }
  throw new Error("Unrecognized response");
}

export function handleSpecificFailures(error) {
  return handleConnectionFailure(error);
}

function handleConnectionFailure(message) {
  if (message && message.indexOf("Failed to fetch") > -1) {
    return "Unable to retrieve data. Possible connection failure.";
  } else return message;
}

export function getCommonHeaders(token) {
  // jon, 3/7/2023: Add timezone offset header so the server knows how to convert concepts like "today"
  //   for the reports.
  const offset = new Date().getTimezoneOffset();
  const header = {
    "Content-Type": "application/json",
    Accept: "application/vnd.xclaim.hateoas+json",
    Authorization: "Bearer " + token,
    "x-xclaim-timeoffset": offset,
  };

  // jon, 8/16/23: Add a header to enable test coverage tracking if user has enabled it.
  if (localStorage[COVERAGE_TRACKING_ITEM] === "true") {
    header["x-document-metadata"] = "1";
  }
  return header;
}

export function getQueryString() {
  // var env = store.getState().environment.environment.value;
  // if (_.endsWith(env, "n")) env = env.substring(0, env.length - 1);
  // return "?env=" + env;
  /* eslint-disable no-undef */
  if (API_ENV && API_ENV !== "") return "?env=" + API_ENV;
  return "?x=0";
}

export function getTestProcEndpoint(saveHistory) {
  //Removed use of ProcessClaim for the moment. Will add back in as separate option
  //since manually switching environments is being removed
  //var env = store.getState().environment.environment.value;
  //if (_.endsWith(env, "n")) return "ProcessClaim";
  return saveHistory ? "ProcessClaim" : "StageClaim";
}
