import React, { useEffect, useState } from "react";
import { StyledNodeEditLink } from "./RuleMapCommonStyles";
import { useGroupRuleMaps } from "../../../contexts/GroupRuleMapsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { getNodeForGroupRuleMapById } from "./RuleMapDataCommon";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";

function RuleMapNodeEditLink({ show, id }) {
  const { ruleMapsData } = useRuleMaps();
  const { groupRuleMapsData, setGroupRuleMapsData } = useGroupRuleMaps();
  const [node, setNode] = useState(null);

  useEffect(() => {
    if (node === null) {
      // Load the field from rule map using the id (it is deconstructed into key and type to look up value in rule map arrays)
      let theNode = getNodeForGroupRuleMapById(ruleMapsData.ruleMap, id);
      if (theNode !== null) {
        theNode.isNew = false;
      }
      setNode(theNode);
    }
  }, [id]);

  function handleEditNode(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setGroupRuleMapsData({
      type: ContextProviderActions.setGroupRuleMapEditNode,
      payload: node,
    });
  }

  if (node === null || show !== true || groupRuleMapsData?.editField !== null) {
    return <></>;
  }

  return (
    <StyledNodeEditLink>
      <button
        type="button"
        className="btn btn-link btn-secondary"
        style={{ cursor: "pointer" }}
        onClick={handleEditNode}
        title="Edit field"
      >
        <span className="material-icons">edit</span>
      </button>
    </StyledNodeEditLink>
  );
}

export default RuleMapNodeEditLink;
