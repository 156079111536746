import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledBackButtonDiv,
  StyledHeaderRowDiv,
  StyledNoResultsDiv,
} from "../layout/CommonStyledControls";
import Badge from "./Badge";
import CoverageTrackingRecordProperties from "./CoverageTrackingRecordProperties";
import InstantSearchInput from "../input/InstantSearchInput";
import { getHighlightedText } from "../../../services/General";
import ToggleSwitchInput from "../input/ToggleSwitchInput";
import {
  getEntryDescription,
  getEntryName,
  getEntryNamespace,
  getFilterNameFromEntryType,
  getIconForEntry,
} from "./CoverageTrackingCommon";
import { useTestCoverageTracking } from "../../../contexts/TestCoverageTrackingContext";

function CoverageTrackingRecordEntryPager({
  metadata,
  hideDetails,
  setHideDetails,
}) {
  const { testCoverageTrackingData } = useTestCoverageTracking();
  const { isMobileSize, isTabletSize } = useMobile();
  const [showProperties, setShowProperties] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [level, setLevel] = useState(1);
  const [currentParent, setCurrentParent] = useState(
    (metadata?.entries || []).length > 0 ? metadata.entries[0] : null
  );
  const [searchText, setSearchText] = useState("");
  const [hideFiltered, setHideFiltered] = useState(false);
  const [filters, setFilters] = useState([
    "Function",
    "Event",
    "Loop",
    "RepoGet",
    "RepoSave",
  ]);

  const entries = metadata?.entries || [];
  const TAB_WIDTH = isMobileSize ? 20 : isTabletSize ? 40 : 80;
  let MAX_LEVELS = isMobileSize ? 2 : isTabletSize ? 4 : 10;
  if (isTabletSize && showProperties) MAX_LEVELS -= 1;

  const filterButtons = [
    {
      name: "Function",
      label: "Function",
      entryType: "FunctionRegistration",
    },
    {
      name: "Event",
      label: "Event",
      entryType: "TestableEventRegistration",
    },
    {
      name: "Loop",
      label: "Loop",
      entryType: "LoopRegistration",
    },
    {
      name: "RepoGet",
      label: "Repo Get",
      entryType: "RepositoryGetRegistration",
    },
    {
      name: "RepoSave",
      label: "Repo Save",
      entryType: "RepositorySaveRegistration",
    },
    {
      name: "Coverage",
      label: "Coverage Only",
      entryType: "Coverage",
    },
    {
      name: "NoCoverage",
      label: "No Coverage",
      entryType: "NoCoverage",
    },
    {
      name: "Headless",
      label: "Headless",
      entryType: "Headless",
    },
  ];

  useEffect(() => {
    if (currentParent === null) {
      scrollToTop();
    } else {
      scrollToEntry(currentParent);
    }
  }, [currentParent]);

  useEffect(() => {
    if (selectedEntry !== null) {
      // When entry is selected, scoll to top to view the properties
      scrollToTop();
    }
  }, [selectedEntry]);

  function isCovered(entry) {
    return entry.covered || entry.parentFunction === null;
  }

  function getStyleForEntry(entry) {
    let style = {
      marginLeft: `${TAB_WIDTH * (entry.callLevel - level)}px`,
    };

    // Do not indent on mobile since we are only showing the parent and a single level anyway.
    if (isMobileSize) {
      style = { ...style, marginLeft: "0" };
    }

    if (entry.entryType === "FunctionRegistration") {
      style = { ...style, borderColor: "var(--notify-info)" };
    } else if (entry.entryType === "TestableEventRegistration") {
      style = { ...style, borderColor: "var(--notify-success)" };
    } else if (entry.entryType === "LoopRegistration") {
      style = { ...style, borderColor: "var(--notify-warning)" };
    } else if (entry.entryType === "RepositoryGetRegistration") {
      style = { ...style, borderColor: "var(--pill-black)" };
    } else if (entry.entryType === "RepositorySaveRegistration") {
      style = { ...style, borderColor: "var(--notify-danger)" };
    }

    // If this entry is not in the current filter, show it as a ghost to make levels work correctly
    // filters.indexOf("Coverage") < 0 &&
    if (!isEntryInFilter(entry)) {
      style = { ...style, opacity: "0.25" };
    }

    return style;
  }

  function getClassForEntry(entry, idx) {
    let className = "";
    // Was this entry covered? If so, highlight it
    if (isCovered(entry)) {
      className += " covered-entry";
    }

    // Highlight the first entry since this is the current parent
    if (entry.localId === currentParent.localId) {
      className += " first-entry";
    }
    return className;
  }

  function getAssociatedValuesBadge(entity) {
    const count = (entity.associatedValues || []).length;
    // Hide properties in group mode
    if (count > 0 && testCoverageTrackingData.search.groupMode !== true) {
      return (
        <Badge
          badgeStyle={{
            marginLeft: "8px",
            fontSize: "13px",
            lineHeight: "20px",
          }}
        >{`${count} ${count === 1 ? "prop" : "props"}`}</Badge>
      );
    } else {
      return <></>;
    }
  }

  function getFilterButtonClass(name) {
    let className = "btn-toggle";
    const isEnabled = filters.indexOf(name) >= 0;
    if (isEnabled) {
      className += " btn-selected";
    }
    return className;
  }

  function handleClickFilter(e, name) {
    if (e) e.preventDefault();

    let _filters = [...filters];
    const pos = _filters.indexOf(name);

    // Remove filter if already there, otherwise add it
    if (pos >= 0) {
      _filters.splice(pos, 1);
    } else {
      // If Coverage Only or No Coverage was just enabled, enable all the other buttons too
      if (name === "Coverage" || name === "NoCoverage" || name === "Headless") {
        _filters = [
          name === "Coverage"
            ? "Coverage"
            : name === "NoCoverage"
            ? "NoCoverage"
            : "Headless",
          "Function",
          "Event",
          "Loop",
          "RepoGet",
          "RepoSave",
        ];
      } else {
        _filters.push(name);
      }
    }
    setFilters(_filters);

    scrollToTop();
  }

  function handleChangeLevel(event, entry, addLevel) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    // If going back, set parent to this node's parent. Otherwise, this node is the parent.
    let newParent = null;
    if (addLevel === 1) {
      // Going forward - set this entry as the parent and use its call level
      setLevel(entry.callLevel);
      newParent = { ...entry };
    } else {
      // Going backward - set the new level to be one less than this entry's call level and locate parent
      setLevel(entry.callLevel - 1);
      const parent = getParentNode(entry);
      newParent = { ...parent };
    }

    setCurrentParent(newParent);
  }

  function getFilterCount(filterName) {
    const filteredEntries = getFilteredEntries();

    if (filterName === "Coverage") {
      return filteredEntries.filter((e) => e.covered === true)?.length ?? 0;
    } else if (filterName === "NoCoverage") {
      return filteredEntries.filter((e) => e.covered === false)?.length ?? 0;
    } else if (filterName === "Headless") {
      return filteredEntries.filter((e) => e.orphaned === true)?.length ?? 0;
    } else if (filterName === "Function") {
      return (
        filteredEntries.filter((e) => e.entryType === "FunctionRegistration")
          ?.length ?? 0
      );
    } else if (filterName === "Event") {
      return (
        filteredEntries.filter(
          (e) => e.entryType === "TestableEventRegistration"
        )?.length ?? 0
      );
    } else if (filterName === "Loop") {
      return (
        filteredEntries.filter((e) => e.entryType === "LoopRegistration")
          ?.length ?? 0
      );
    } else if (filterName === "RepoGet") {
      return (
        filteredEntries.filter(
          (e) => e.entryType === "RepositoryGetRegistration"
        )?.length ?? 0
      );
    } else if (filterName === "RepoSave") {
      return (
        filteredEntries.filter(
          (e) => e.entryType === "RepositorySaveRegistration"
        )?.length ?? 0
      );
    }

    return 0;
  }

  function isEntryInFilter(entry) {
    // Is entry visible according to current filter?
    const entryFilterName = getFilterNameFromEntryType(entry.entryType);
    const covered = isCovered(entry);
    const headless = entry.orphaned === true;
    const detail = entry.detail === true;

    if (
      filters.indexOf(entryFilterName) < 0 ||
      (filters.indexOf("Coverage") >= 0 && !covered) ||
      (filters.indexOf("NoCoverage") >= 0 && covered) ||
      (filters.indexOf("Headless") >= 0 && !headless) ||
      (hideDetails && detail)
    ) {
      return false;
    }

    // If we have search text, make sure entry matches the typed in text
    const st = _.toLower(searchText);
    const entryName = _.toLower(getEntryName(entry.name));
    const entryNamespace = _.toLower(getEntryNamespace(entry.name));
    const entryDesc = _.toLower(getEntryDescription(entry.description));

    if (_.trim(st) !== "") {
      if (
        entryName.indexOf(st) < 0 &&
        entryNamespace.indexOf(st) < 0 &&
        entryDesc.indexOf(st) < 0
      ) {
        return false;
      }
    }

    return true;
  }

  function isEntryVisible(entry) {
    // We now show filtered-out entries as ghosts - except when the Hide filtered items checkbox is checked
    if (hideFiltered && !isEntryInFilter(entry)) return false;

    // If we are hiding details and this is a detailed entry, always hide it - don't even show it faded out.
    if (hideDetails && entry.detail === true) return false;

    // Always show parent node if it isn't filtered out
    if (entry.localId === currentParent.localId) {
      return true;
    }

    const entryLevel = entry.callLevel;
    if (entryLevel < level || entryLevel >= level + MAX_LEVELS) {
      // Is entry in the visible levels being displayed?
      return false;
    }

    // Always show orphans at or below the current level - regardless of being a descendant since orphans have no parents :(
    if (entry.orphaned === true && entryLevel >= level) {
      return true;
    }

    // Is entry the direct descendant of (or same as) the current parent?
    let isDescendant = false;
    let node = { ...entry };
    let l = level;

    while (node !== null && l < level + MAX_LEVELS) {
      if (node.parentId === currentParent.id) {
        isDescendant = true;
        break;
      }
      node = getParentNode(node);
      l += 1;
    }

    if (!isDescendant && entry.localId !== currentParent.localId) return false;

    return true;
  }

  function getParentNode(entry) {
    const parent = entries.find((e) => e.id === entry.parentId);
    return parent || null;
  }

  function getFilteredEntries() {
    const _entries = [];

    for (let i = 0; i < entries.length; i++) {
      if (isEntryInFilter(entries[i])) {
        _entries.push(entries[i]);
      }
    }

    return _entries;
  }

  function doesEntryHaveChildren(entry) {
    let result = false;
    const filteredEntries = getFilteredEntries();

    // Does any entry have this entry's id as its parentId? If so, this entry has children.
    if (filteredEntries.findIndex((e) => e.parentId === entry.id) >= 0) {
      result = true;
    }

    return result;
  }

  function getVisibleEntries() {
    const _entries = [];

    for (let i = 0; i < entries.length; i++) {
      if (isEntryVisible(entries[i])) {
        _entries.push(entries[i]);
      }
    }

    return _entries;
  }

  function scrollIntoView(element) {
    window.setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 1);
  }

  function scrollToTop() {
    const element = document.getElementById("top-of-page");
    if (element) scrollIntoView(element);
  }

  function scrollToEntry(entry) {
    const element = document.getElementById(entry.id);
    if (element) {
      scrollIntoView(element);
      return;
    } else {
      scrollToTop();
    }
  }

  function handleClickEntry(entry) {
    setSelectedEntry(entry);
    setShowProperties(true);
  }

  function shouldShowLeftArrowOnEntry(entry) {
    let result = false;

    // Show back arrow for levels beyond 1st if this entry is the current parent and not an orphan.
    //   Orphaned records never show arrows now because there is no way to tell when paging should occur
    //   and there will never be more than two levels of orphans.
    if (
      level > 1 &&
      entry.localId === currentParent.localId &&
      !entry.orphaned
    ) {
      result = true;
    }

    return result;
  }

  function shouldShowRightArrowOnEntry(entry) {
    let result = false;

    // Show forward arrow if this isn't the current parent and it has children, but is not an orphan.
    //   Orphaned records never show arrows now because there is no way to tell when paging should occur
    //   and there will never be more than two levels of orphans.
    if (
      doesEntryHaveChildren(entry) &&
      !entry.orphaned &&
      entry.localId !== currentParent.localId
    ) {
      result = true;
    }

    return result;
  }

  const filteredEntries = getVisibleEntries();
  const hl = _.toLower(searchText);
  let namespace = "";
  let description = "";
  let nameHlResult = {};
  let namespaceHlResult = {};
  let descHlResult = {};

  return (
    <>
      <StyledHeaderRowDiv id="top-of-page">
        <InstantSearchInput
          id="searchText"
          onChange={setSearchText}
          value={searchText}
        />
        <ToggleSwitchInput
          id="hideFiltered"
          name="hideFiltered"
          label="Hide filtered items"
          onChange={() => setHideFiltered(!hideFiltered)}
          checked={hideFiltered}
        />
        <ToggleSwitchInput
          id="hideDetails"
          name="hideDetails"
          label="Hide Details"
          onChange={() => setHideDetails(!hideDetails)}
          checked={hideDetails}
        />
      </StyledHeaderRowDiv>
      <StyledFilters>
        {filterButtons.map((b) => (
          <button
            key={b.name}
            type="button"
            className={`btn ${getFilterButtonClass(b.name)}`}
            style={{
              position: "relative",
              fontSize: isMobileSize ? "18px" : "24px",
              justifyContent:
                isMobileSize || isTabletSize ? "space-evenly" : "flex-start",
            }}
            value={b.name}
            onClick={(e) => handleClickFilter(e, b.name)}
          >
            {getIconForEntry({ entryType: b.entryType })}
            <i className="d-none d-lg-block">{b.label}</i>
            <StyledFilterCount
              style={{
                fontSize: isMobileSize ? "11px" : "13px",
                top: isMobileSize ? "0" : "2px",
                right: isMobileSize ? "-2px" : "0",
              }}
            >
              {getFilterCount(b.name)}
            </StyledFilterCount>
          </button>
        ))}
      </StyledFilters>
      <StyledEntryContainer>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: showProperties && isMobileSize ? "100%" : "auto",
            }}
          >
            <CoverageTrackingRecordProperties
              show={showProperties}
              setShow={setShowProperties}
              entry={selectedEntry}
            />
          </div>
          {showProperties && isMobileSize ? (
            <></>
          ) : (
            <div style={{ flex: "1 1 auto", padding: "20px" }}>
              {filteredEntries.length === 0 ? (
                <StyledNoResultsDiv>
                  <p>
                    <i className="material-icons">search_off</i>
                  </p>
                  <p>The current filter has no results</p>
                </StyledNoResultsDiv>
              ) : (
                <>
                  <StyledBackButtonDiv>
                    <button
                      title="Back to top level"
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        setLevel(1);
                        setCurrentParent(metadata.entries[0]);
                      }}
                    >
                      <i className="fa fa-angle-left"></i> Back to top level
                    </button>
                  </StyledBackButtonDiv>
                  {filteredEntries.map((e, idx) => {
                    namespace = getEntryNamespace(e.name);
                    description = getEntryDescription(e.description);
                    nameHlResult = getHighlightedText(getEntryName(e.name), hl);
                    namespaceHlResult = getHighlightedText(namespace, hl);
                    descHlResult = getHighlightedText(description, hl);

                    return (
                      <StyledEntry
                        key={`${idx}-${e.localId}`}
                        id={e.id}
                        style={getStyleForEntry(e)}
                        className={getClassForEntry(e, idx)}
                        onClick={() => handleClickEntry(e)}
                      >
                        {e.count > 1 && (
                          <StyledCallCount>{`x${e.count}`}</StyledCallCount>
                        )}
                        {shouldShowLeftArrowOnEntry(e) && (
                          <span
                            className="material-symbols-outlined"
                            style={{ marginLeft: "-5px" }}
                            onClick={(event) => handleChangeLevel(event, e, -1)}
                          >
                            chevron_left
                          </span>
                        )}
                        {getIconForEntry(e)}
                        <div style={{ flex: "1 1 auto" }}>
                          {namespace.length > 0 && (
                            <i>
                              {namespaceHlResult.html}
                              <br />
                            </i>
                          )}
                          <p>
                            {nameHlResult.html}
                            {getAssociatedValuesBadge(e)}
                          </p>
                          {(e.ParamTypes || "").length > 0 && (
                            <i>
                              <span
                                style={{
                                  fontStyle: "normal",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                ({e.ParamTypes.replaceAll(",", ", ")})
                              </span>
                            </i>
                          )}
                          {description.length > 0 && (
                            <i style={{ fontStyle: "normal" }}>
                              {descHlResult.html}
                            </i>
                          )}
                        </div>
                        {shouldShowRightArrowOnEntry(e) && (
                          <span
                            className="material-symbols-outlined"
                            style={{
                              marginLeft: "auto",
                              marginRight: "-5px",
                            }}
                            onClick={(event) => handleChangeLevel(event, e, 1)}
                          >
                            chevron_right
                          </span>
                        )}
                      </StyledEntry>
                    );
                  })}
                </>
              )}
            </div>
          )}
        </div>
      </StyledEntryContainer>
    </>
  );
}

const StyledEntryContainer = styled.div`
  background-color: var(--bg-flat);
`;

const StyledFilters = styled.div`
  position: sticky;
  z-index: 99;
  top: 82px;
  display: flex;
  padding: 4px 0;
  background-color: var(--bg-flat);

  button {
    display: flex;
    min-width: 40px !important;
    align-items: center;
    flex: auto;
    color: var(--text-medium);
    background-color: transparent;

    &.btn-toggle {
      border: 1px solid var(--button-secondary-border);
      border-radius: 0;
    }

    &.btn-selected {
      color: var(--text-highlight);
      background-color: var(--pill-blue);
    }

    i {
      margin-left: 8px;
      font-size: 16px;
    }
  }
`;

const StyledEntry = styled.div`
  position: relative;
  min-height: 36px;
  max-width: 450px;
  padding: 10px;
  margin-bottom: 5px;
  color: var(--drag-chip-text);
  background-color: var(--table-hover-row-bg);
  border: 1px solid var(--drag-chip-border);
  border-radius: 8px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: var(--elevated-bg);
  }

  &.first-entry {
    border-color: #000 !important;
    border-width: 4px;
    margin-bottom: 20px;

    &:hover,
    &:active {
      background-color: var(--drag-chip-bg-drag);
    }
  }

  &.covered-entry {
    color: #fff;
    background-color: var(--pill-lavender);

    &:hover,
    &:active {
      background-color: var(--drag-chip-bg-drag);
    }
  }

  div {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    i {
      font-size: 11px;
      line-height: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      padding: 0;
      margin: 0;
      color: inherit;
      font-size: 16px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  span[class="material-symbols-outlined"] {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;
  }
`;

const StyledCallCount = styled.span`
  position: absolute;
  top: 2px;
  right: 0;
  border-radius: 24px;
  padding: 0 5px 1px 4px;
  font-size: 13px;
  line-height: 13px;
  color: var(--text-dark);
  background-color: transparent;
`;

const StyledFilterCount = styled.span`
  position: absolute;
  top: 2px;
  right: 0;
  border-radius: 24px;
  padding: 0 5px 1px 4px;
  font-size: 13px;
  line-height: 13px;
  color: var(--text-dark);
  background-color: transparent;
`;

export default CoverageTrackingRecordEntryPager;
