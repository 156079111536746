import React from "react";
import _ from "lodash";
import {
  StyledCard,
  StyledDescription,
  StyledReturnButton,
} from "./RuleGroupSummaryCommonStyles";

function RuleGroupSummaryGroupSetting({
  item,
  showValue,
  linkedProperty,
  onClickReturn,
}) {
  const id = _.lowerCase(`gs-${item.name}`);
  const matchLink = !_.isEmpty(linkedProperty) && id === linkedProperty;

  return (
    <StyledCard
      className={matchLink ? "selected pulse-fade-subtle" : ""}
      id={id}
    >
      {matchLink && (
        <StyledReturnButton title="Return" onClick={onClickReturn}>
          <i className="fa fa-angle-left"></i>
        </StyledReturnButton>
      )}
      <StyledDescription>
        <span>{item.name}</span>
      </StyledDescription>
      {showValue && (
        <>
          <div
            style={{
              padding: "20px 20px 0 0",
              color: "var(--text-dark)",
              fontWeight: "bold",
            }}
          >
            {_.isEmpty(item.value) ? (
              <i style={{ color: "var(--text-normal)" }}>No value</i>
            ) : (
              item.value
            )}
          </div>
        </>
      )}
    </StyledCard>
  );
}

export default RuleGroupSummaryGroupSetting;
