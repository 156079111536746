import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import {
  emptyRuleTableDefinition,
  emptyRuleTableDefinitionSearch,
} from "../viewmodels/ruleTableDefinitionsVm";

function reducer(state, action) {
  switch (action.type) {
    case ContextProviderActions.loadRuleTableDefinitions:
      return {
        ...state,
        ruleTableDefinitions: action.payload.ruleTableDefinitions,
        count: action.payload.count,
      };

    case ContextProviderActions.saveRuleTableDefinitionSearch:
      return {
        ...state,
        search: action.payload,
      };

    case ContextProviderActions.loadRuleTableDefinition:
      return {
        ...state,
        ruleTableDefinition: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in RuleTableDefinitionsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  ruleTableDefinitions: [],
  ruleTableDefinition: emptyRuleTableDefinition,
  search: emptyRuleTableDefinitionSearch,
  count: 0,
};

const RuleTableDefinitions = createContext({
  ruleTableDefinitionsData: initialState,
  setRuleTableDefinitionsData: () => null,
});

// eslint-disable-next-line react/prop-types
const RuleTableDefinitionsProvider = ({ children }) => {
  const [ruleTableDefinitionsData, setRuleTableDefinitionsData] = useReducer(
    reducer,
    initialState
  );

  return (
    <RuleTableDefinitions.Provider
      value={{ ruleTableDefinitionsData, setRuleTableDefinitionsData }}
    >
      {children}
    </RuleTableDefinitions.Provider>
  );
};

export default RuleTableDefinitionsProvider;
export const useRuleTableDefinitions = () => useContext(RuleTableDefinitions);
