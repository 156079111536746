/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

export function apiLoadReports(search, token, signal) {
  const uri = API_URL + "/reportdefinitions" + getQueryString();
  let where = [];

  // Free Form Search support
  if (search && search.freeFormSearch && search.freeFormSearch.length > 0) {
    where.push({
      operand: "OR",
      name: "shareMode",
      operator: "=",
      values: ["Shared"],
    });
    where.push({
      operand: "OR",
      name: "name",
      operator: "=",
      values: [search.freeFormSearch],
    });
    where.push({
      operand: "OR",
      name: "description",
      operator: "=",
      values: [search.freeFormSearch],
    });
  }

  if (search && search.name && search.name.length > 0)
    where.push({ name: "name", operator: "=", values: [search.name] });
  if (search && search.description && search.description.length > 0)
    where.push({
      name: "description",
      operator: "=",
      values: [search.description],
    });

  // Search by Share Mode
  if (search && search.shareMode && search.shareMode.length > 0) {
    where.push({ name: "shareMode", operator: "notnull" });
    search.shareMode.forEach((sectype, i) => {
      where.push({
        operand: "OR",
        name: "shareMode",
        operator: "=",
        values: [sectype.value],
      });
    });
  }

  if (
    search &&
    search.reportTemplateId &&
    search.reportTemplateId.value &&
    search.reportTemplateId.value !== "-1"
  )
    where.push({
      name: "reportTemplateId",
      operator: "=",
      values: [search.reportTemplateId.value],
    });

  if (search && search.showOnHomePage) {
    where.push({
      name: "showOnHomePage",
      operator: "true",
      values: [],
    });
  }
  if (search && search.showAsDashboardPanel) {
    where.push({
      name: "showAsDashboardPanel",
      operator: "true",
      values: [],
    });
  }
  if (search && search.dashboardPanelIsAlertPanel) {
    where.push({
      name: "dashboardPanelIsAlertPanel",
      operator: "true",
      values: [],
    });
  }

  if (where.length === 0) {
    where.push({ name: "id", operator: "notnull", values: [] });
  }

  const data = { where, orderBy: search.orderBy || '["name+"]' };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadReportsAll(params, token, signal) {
  const uri =
    API_URL + "/reportdefinitions" + getQueryString() + "&disablepaging=true";
  let where = [];

  // Show report of all share levels
  where.push({
    operand: "OR",
    name: "shareMode",
    operator: "=",
    values: ["ZNotShared"],
  });
  where.push({
    operand: "OR",
    name: "shareMode",
    operator: "=",
    values: ["Shared"],
  });
  where.push({
    operand: "OR",
    name: "shareMode",
    operator: "=",
    values: ["Featured"],
  });

  const data = { where, orderBy: ["name+"] };

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadReport(id, token, signal) {
  let uri = API_URL + "/reportdefinition/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRunReport(params, token, signal) {
  const id = params.id;
  const search = params.search;
  const parameters = params.parameters;

  let uri = API_URL + "/runreport/" + id + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  const data = { parameters };
  const pageSize = search.pageSize || 10;
  const page = search.pageNumber || 1;

  const reportUrl = uri + `&page=${page}&pageSize=${pageSize}`;
  console.log("Run report:", reportUrl);

  return fetch(reportUrl, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiRunReportForDownload(params, token, signal) {
  const id = params.id;
  const parameters = params.parameters;
  const importReadyMode = params.importReadyMode || false;
  const isTemplate = params.isTemplate || false;

  let uri = API_URL + "/reportcsv/" + id + getQueryString();

  if (isTemplate) {
    uri += `&template=true`;
  }

  const headersToAdd = getCommonHeaders(token);
  const data = { parameters, importReadyMode };
  const pageSize = 100000; // Get all data for download
  const page = 1; // Start at page 1 for download

  return fetch(uri + `&page=${page}&pageSize=${pageSize}`, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiAddReport(m, token, signal) {
  let uri = API_URL + "/reportdefinition" + getQueryString();
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(m),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiUpdateReport(params, token, signal) {
  const id = params.id;
  const model = params.model;

  let uri = API_URL + "/reportdefinition/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiDeleteReport(id, token, signal) {
  let uri = API_URL + "/reportdefinition/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiCopyReport(params, token, signal) {
  const id = params.id;
  const newId = params.newId;

  let uri =
    API_URL +
    "/reportcopy/" +
    encodeURIComponent(id) +
    "/" +
    encodeURIComponent(newId) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadDataSourcesAll(params, token, signal) {
  const uri = API_URL + "/reportmodels" + getQueryString();
  let where = [];
  where.push({ name: "TEMPORALITEMMATCH", operator: "=", values: [] });

  const data = { where, orderBy: ["label+"] };

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiLoadFieldsForDataSource(entityType, token, signal) {
  const uri =
    API_URL +
    "/reportmodels/" +
    encodeURIComponent(entityType) +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadReports.funcName = "apiLoadReports";
apiLoadReportsAll.funcName = "apiLoadReportsAll";
apiLoadReport.funcName = "apiLoadReport";
apiRunReport.funcName = "apiRunReport";
apiRunReportForDownload.funcName = "apiRunReportForDownload";
apiAddReport.funcName = "apiAddReport";
apiUpdateReport.funcName = "apiUpdateReport";
apiDeleteReport.funcName = "apiDeleteReport";
apiCopyReport.funcName = "apiCopyReport";
apiLoadDataSourcesAll.funcName = "apiLoadDataSourcesAll";
apiLoadFieldsForDataSource.funcName = "apiLoadFieldsForDataSource";
