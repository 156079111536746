import React from "react";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";

function CreatableSelectInput({
  name,
  id,
  value,
  label,
  disabled,
  error,
  onChange,
  options,
  labelStyle,
  selectStyle,
  controlStyle,
  autoFocus,
}) {
  let wrapperClass = "form-group";
  if (error.length > 0) {
    wrapperClass += " has-error";
  }

  const styles = {
    valueContainer: (provided) => ({
      ...provided,
      ...selectStyle,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--input-text)",
    }),
    control: (provided) => ({ ...provided, ...controlStyle }),
  };

  function formatCreateLabel(input) {
    return `Use custom value: ${input}`;
  }

  return (
    <div className={wrapperClass}>
      <label htmlFor={id} style={labelStyle || {}}>
        {label}
      </label>
      <div className="field">
        <StyledSelect
          isDisabled={disabled}
          id={id}
          name={name}
          options={options}
          styles={styles}
          value={value}
          onChange={onChange}
          blurInputOnSelect={true}
          isClearable={true}
          formatCreateLabel={formatCreateLabel}
          autoFocus={autoFocus || false}
        />
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

const StyledSelect = styled(CreatableSelect)`
  & > div {
    color: var(--input-text);
    background-color: var(--input-bg);
    border: 1px solid var(--input-border);
    border-radius: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 3px 0 2px 0;
    transition: none;

    &:hover {
      border: 1px solid var(--input-border);
    }

    svg {
      color: var(--input-dark);
    }

    input {
      color: var(--input-text) !important;
    }
  }
`;

CreatableSelectInput.defaultProps = {
  error: "",
};

export default CreatableSelectInput;
