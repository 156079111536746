import { MarkerType } from "@xyflow/react";
import {
  getBadgeColorsFromType,
  getFieldFromFieldListById,
  getItemFromPropertyByDisplayKey,
  NodeTypes,
} from "./RuleMapDataCommon";
import _ from "lodash";

const MAX_LEVELS = 20;
const MAX_EDGE_LABEL_LENGTH = 20;

let nodeList = [];
let edgeList = [];
let isSelectionMode = false;

function getRuleMapNodeTypeFromNodeType(nodeType) {
  let ruleMapNodeType = "ruleMapNode";

  switch (nodeType) {
    case NodeTypes.CodeArtifact:
      ruleMapNodeType = "ruleMapCodeArtifactNode";
      break;
    case NodeTypes.CodeArtifactInput:
    case NodeTypes.CodeArtifactOutput:
      ruleMapNodeType = "ruleMapArtifactPreReqsNode";
      break;
    case NodeTypes.RequestFieldValue:
      ruleMapNodeType = "ruleMapSimpleNode";
      break;
    case NodeTypes.ResponseFieldValue:
      ruleMapNodeType = "ruleMapSimpleNode";
      break;
    case NodeTypes.TransactionMessage:
    case NodeTypes.TransmissionMessage:
    case NodeTypes.RootMessage:
      ruleMapNodeType = "ruleMapNode";
      break;
    case NodeTypes.Rejection:
    case NodeTypes.ExcludedRejection:
      ruleMapNodeType = "ruleMapNode";
      break;
    case NodeTypes.ModuleNode:
    case NodeTypes.ModuleInputNode:
      ruleMapNodeType = "ruleMapModuleNode";
      break;
    default:
      break;
  }

  return ruleMapNodeType;
}

function getExistingNodeById(id) {
  const array = nodeList || [];
  if (array.length < 1 || _.isEmpty(id)) return null;

  const item = array.find((i) => i.id === id);
  return item || null;
}

function filterArrayByProcessedFlag(array, includePossiblePaths) {
  // Do not filter if we are showing all possible paths
  if (includePossiblePaths === true) return array;

  const filteredArray = array.filter((a) => a.isProcessed === true);
  return filteredArray;
}

function buildLeafNodes(leafNodes) {
  let retLeafNodes = [];
  let leaf;
  for (let i = 0; i < (leafNodes || []).length; i++) {
    leaf = {
      itemKey: leafNodes[i].key,
      value: leafNodes[i].value,
      description: leafNodes[i].description,
      type: leafNodes[i].type.description,
      links: leafNodes[i].links,
      ruleTableData: leafNodes[i].ruleTableData,
    };
    retLeafNodes.push(leaf);
  }

  return retLeafNodes;
}

function buildNode(field) {
  const id = field.id;
  const ruleMapNodeType = getRuleMapNodeTypeFromNodeType(field.type);

  // if (field.type === NodeTypes.ConditionalNode) {
  //   console.log(`Field ${field.key} is conditional`);
  // }

  const subnodes =
    field.type === NodeTypes.ModuleNode ||
    field.type === NodeTypes.ModuleInputNode
      ? field.subnodes
      : [];

  const node = {
    id: id,
    position: { x: 0, y: 0 },
    data: {
      displayKey: field.key,
      itemKey: field.name,
      id: id,
      value: field.value,
      description: field.description,
      type: field.type,
      links: field.links,
      ruleTableData: field.ruleTableData,
      logs: field.logs,
      layoutDirection: "TB",
      translatedValues: field.translatedValues,
      isActualNodeLink: isSelectionMode ? false : field.isActualNodeLink,
      isProcessed: field.isProcessed,
      module: field.module,
      isRootNode: field.isRoot,
      isLeafNode: field.isLeaf,
      leafNodes: buildLeafNodes(field.leafNodes),
      runValues: field.runValues,
      conditionals: field.conditionals,
      subnodes: subnodes,
    },
    type: ruleMapNodeType,
  };

  return node;
}

function addNodeIfNotExist(node) {
  if ((nodeList || []).length < 1) {
    nodeList.push(node);
    return;
  }

  // const existing = nodeList.find(
  //   (e) =>
  //     e.data.displayKey === node.data.displayKey &&
  //     e.data.type === node.data.type
  // );

  const existing = nodeList.find((e) => e.id === node.id);

  if (!existing) {
    nodeList.push(node);
  }
}

function buildEdge(
  type,
  parentNode,
  childNode,
  label = "",
  isActualNodeLink = true,
  isProcessed = true
) {
  const color = getBadgeColorsFromType(type, isActualNodeLink, isProcessed);

  // If we are showing modules, change all edges that would otherwise connect a module's subnodes to connect to the module itself instead.
  let childId = childNode.id;
  let parentId = parentNode.id;

  const edge = {
    id: `edge-${childId}-${parentId}`,
    source: childId,
    target: parentId,
    type: isActualNodeLink ? "smoothstep" : "default",
    animated: isActualNodeLink,
    style: {
      stroke: color.bgColor,
      strokeWidth: isActualNodeLink ? "2px" : "1px",
    },
    data: {
      isActualNodeLink: isActualNodeLink,
    },
  };

  if (isActualNodeLink) {
    edge.type = "ruleMapNormalEdge";
    edge.markerEnd = {
      type: MarkerType.ArrowClosed,
    };
  } else {
    edge.type = "ruleMapPossiblePathEdge";
  }

  if (!_.isEmpty(label)) {
    edge.data.childNode = getExistingNodeById(childId);
    edge.data.parentNode = getExistingNodeById(parentId);
    edge.data.fullLabel = label;
    edge.label =
      label.length > MAX_EDGE_LABEL_LENGTH
        ? _.truncate(label, { length: MAX_EDGE_LABEL_LENGTH })
        : label;
    edge.labelStyle = {
      fontSize: "16px",
    };
    edge.labelBgStyle = {
      fill: color.bgColor,
      fillOpacity: 0.3,
      stroke: color.bgColor,
      strokeWidth: "1px",
    };
    edge.labelShowBg = true;
    edge.labelBgPadding = [10, 5];
    edge.labelBgBorderRadius = 5;
  }

  if (_.isEmpty(edge.source) || edge.source === null) {
    console.log(`***Edge SOURCE null for edge ${edge.id}`);
  }

  if (_.isEmpty(edge.target || edge.target === null)) {
    console.log(`***Edge TARGET null for edge ${edge.id}`);
  }

  return edge;
}

function addEdgeIfNotExist(edge) {
  if ((edgeList || []).length < 1) {
    edgeList.push(edge);
    return;
  }

  const existing = edgeList.find(
    (e) => e.source === edge.source && e.target === edge.target
  );
  if (!existing) {
    edgeList.push(edge);
  }
  // else {
  //   console.log("Edge already exists so skipping add: ", edge);
  // }
}

function getEdgeLabel(node, parent) {
  let edgeLabel = node?.data.value || "";
  if (edgeLabel === "NULL") {
    edgeLabel = "";
  }
  if (_.isEmpty(edgeLabel) && !_.isEmpty(parent?.value)) {
    edgeLabel = parent.value;
  }
  return edgeLabel;
}

function traverseTreeAndBuildNodesAndEdges(
  fieldList,
  level,
  includePossiblePaths,
  field,
  parent,
  maxLevel = MAX_LEVELS
) {
  if (level > maxLevel) return;

  let thisNode = null;

  // Does this node already appear in the list? Only check this if this isn't a root node.
  if (!field.isRoot) {
    thisNode = getExistingNodeById(field.id);
  }

  // If this node already exists, draw the edge but stop traversing since we would already have traced this path in another branch.
  if (thisNode !== null && parent !== null) {
    const edgeLabel = getEdgeLabel(thisNode, parent);
    const edge = buildEdge(
      field.type,
      parent,
      thisNode,
      edgeLabel,
      isSelectionMode ? false : field.isActualNodeLink,
      thisNode.data.isProcessed
    );
    addEdgeIfNotExist(edge);
    return;
  }

  // We have a new node, so add it to the graph and traverse its dependency tree
  thisNode = buildNode(field);
  addNodeIfNotExist(thisNode);

  // Only build an edge if we have a parent
  if (parent !== null) {
    const edgeLabel = getEdgeLabel(thisNode, parent);

    const parentEdge = buildEdge(
      field.type,
      parent,
      thisNode,
      edgeLabel,
      isSelectionMode ? false : field.isActualNodeLink,
      thisNode.data.isProcessed
    );
    addEdgeIfNotExist(parentEdge);
  }

  // If the node has children, traverse the tree for each one
  let children = field.children || [];
  let child, shouldContinueTraversal;

  for (let i = 0; i < children.length; i++) {
    child = getFieldFromFieldListById(fieldList, children[i].id);
    if (child === null) continue;

    // Only continue if we are showing all paths OR we aren't showing all paths but the node is not a ghost
    shouldContinueTraversal =
      includePossiblePaths ||
      (!includePossiblePaths && child.isActualNodeLink === true);

    if (shouldContinueTraversal) {
      traverseTreeAndBuildNodesAndEdges(
        fieldList,
        level + 1,
        includePossiblePaths,
        child,
        field,
        maxLevel
      );
    }
  }
}

function getInitialNodeAndEdgeListForArrayField(
  fieldList,
  includePossiblePaths,
  array,
  fieldName
) {
  const field = getItemFromPropertyByDisplayKey(array, fieldName);
  if (!field) {
    console.log(
      `Field with key ${fieldName} not found in array in getInitialNodeAndEdgeListForArrayField`,
      array
    );
    return;
  }

  traverseTreeAndBuildNodesAndEdges(
    fieldList,
    1,
    includePossiblePaths,
    field,
    null // No parent
  );
}

export function getReactFlowNodesAndEdgesForClaim(
  includePossiblePaths = false,
  fieldList,
  selectedModuleId = "-1",
  selectedField
) {
  nodeList = [];
  edgeList = [];
  isSelectionMode = false;

  // If we have a selected module, just render everything in the field list - we have already reduced it in the module extractor to the selected module.
  if (selectedModuleId !== "-1") {
    let rootFields = fieldList.filter((f) => f.isRoot === true);
    rootFields = filterArrayByProcessedFlag(rootFields, true);
    for (let i = 0; i < rootFields.length; i++) {
      getInitialNodeAndEdgeListForArrayField(
        fieldList,
        includePossiblePaths,
        rootFields,
        rootFields[i].key
      );
    }

    return {
      nodeList: nodeList,
      edgeList: edgeList,
      rejections: [],
      responseFields: [],
    };
  }

  const selectedResponseField =
    selectedField.type === NodeTypes.ResponseFieldValue
      ? selectedField.value
      : "-1";
  const selectedRejection =
    selectedField.type === NodeTypes.Rejection ||
    selectedField.type === NodeTypes.ExcludedRejection
      ? selectedField.value
      : "-1";

  const hasSelectedResponseField = selectedResponseField !== "-1";
  const hasSelectedRejection = selectedRejection !== "-1";

  // Gather any rejections - for a claim, never show excluded rejections since these are already removed in included rejections.
  let rejections = fieldList.filter(
    (f) => f.type === NodeTypes.Rejection && f.isRoot === true
  );
  rejections = filterArrayByProcessedFlag(rejections, false);

  if (hasSelectedRejection) {
    if (rejections.filter((r) => r.id === selectedRejection).length < 1) {
      isSelectionMode = true;
    }

    // Filter to show only the selected rejection
    rejections = fieldList.filter(
      (r) => r.type === NodeTypes.Rejection && r.id === selectedRejection
    );
  }

  const hasRejections = (rejections || []).length > 0;

  if (hasRejections && !hasSelectedResponseField) {
    for (let i = 0; i < rejections.length; i++) {
      getInitialNodeAndEdgeListForArrayField(
        fieldList,
        includePossiblePaths,
        rejections,
        rejections[i].key
      );
    }
  }

  // Show response fields if there were no errors above.
  let responseFields = fieldList.filter(
    (t) => t.type === NodeTypes.ResponseFieldValue
  );
  responseFields = filterArrayByProcessedFlag(
    responseFields,
    includePossiblePaths
  );

  if (hasSelectedResponseField) {
    // Show only the selected response field
    responseFields = responseFields.filter(
      (r) => r.id === selectedResponseField
    );
  }
  const hasResponseFields = (responseFields || []).length > 0;

  // Don't show response fields if this is a rejection, unless a specific response field has been selected.
  const shouldShowResponseFields =
    hasResponseFields &&
    !hasSelectedRejection &&
    (!hasRejections || hasSelectedResponseField);

  if (shouldShowResponseFields) {
    for (let i = 0; i < responseFields.length; i++) {
      getInitialNodeAndEdgeListForArrayField(
        fieldList,
        includePossiblePaths,
        responseFields,
        responseFields[i].key
      );
    }
  }

  // Always show message fields if there were no errors above.
  let messageFields = fieldList.filter((t) => t.type === NodeTypes.RootMessage);
  messageFields = filterArrayByProcessedFlag(
    messageFields,
    includePossiblePaths
  );

  const hasMessages = (messageFields || []).length > 0;

  if (hasMessages && !hasSelectedResponseField && !hasSelectedRejection) {
    for (let i = 0; i < messageFields.length; i++) {
      getInitialNodeAndEdgeListForArrayField(
        fieldList,
        includePossiblePaths,
        messageFields,
        messageFields[i].key
      );
    }
  }

  // Remove any modules (and their inputs) that have no parent nodes since this means they were not used in the rule map.
  const finalNodeList = nodeList;
  const finalEdgeList = edgeList;

  // console.log("Nodes", finalNodeList);
  // console.log("Edges", finalEdgeList);

  // const tempNodes = nodeList.filter(
  //   (f) => f.data.itemKey === "currentRejectionCount"
  // );
  // console.log("RENDER: currentRejectionCount nodes:", tempNodes);

  // const tempEdges = edgeList.filter(
  //   (f) =>
  //     f.source.indexOf("currentRejectionCount") >= 0 ||
  //     f.target.indexOf("currentRejectionCount") >= 0
  // );
  // console.log("RENDER: currentRejectionCount edges:", tempEdges);

  return {
    nodeList: finalNodeList,
    edgeList: finalEdgeList,
    rejections: [],
    responseFields: [],
  };
}

// export function getReactFlowNodesAndEdgesForGroupOld(
//   fieldList,
//   selectedModuleId = "-1",
//   selectedField
// ) {
//   nodeList = [];
//   edgeList = [];
//   isSelectionMode = false;

//   const includePossiblePaths = true;
//   let hasSelectedModule = false;

//   // If we have a selected module, just render everything in the field list - we have already reduced it in the module extractor to the selected module.
//   if (selectedModuleId !== "-1") {
//     hasSelectedModule = true;
//     for (let i = 0; i < fieldList.length; i++) {
//       getInitialNodeAndEdgeListForArrayField(
//         fieldList,
//         includePossiblePaths,
//         fieldList,
//         fieldList[i].key
//       );
//     }
//   }

//   const selectedResponseField =
//     selectedField.type === NodeTypes.ResponseFieldValue
//       ? selectedField.value
//       : "-1";
//   const selectedRejection =
//     selectedField.type === NodeTypes.Rejection ? selectedField.value : "-1";

//   const hasSelectedResponseField = selectedResponseField !== "-1";
//   const hasSelectedRejection = selectedRejection !== "-1";

//   // Gather any rejections
//   let rejections = fieldList.filter(
//     (f) => f.type === NodeTypes.Rejection && f.isRoot === true
//   );

//   if (hasSelectedRejection) {
//     if (rejections.filter((r) => r.id === selectedRejection).length < 1) {
//       isSelectionMode = true;
//     }

//     // Filter to show only the selected rejection
//     rejections = fieldList.filter(
//       (r) => r.type === NodeTypes.Rejection && r.id === selectedRejection
//     );
//   }

//   const hasRejections = (rejections || []).length > 0;

//   if (hasRejections && !hasSelectedResponseField && !hasSelectedModule) {
//     for (let i = 0; i < rejections.length; i++) {
//       getInitialNodeAndEdgeListForArrayField(
//         fieldList,
//         includePossiblePaths,
//         rejections,
//         rejections[i].key
//       );
//     }
//   }

//   // Show response fields if there were no errors above.
//   let responseFields = fieldList.filter(
//     (t) => t.type === NodeTypes.ResponseFieldValue
//   );

//   if (hasSelectedResponseField) {
//     // Show only the selected response field
//     responseFields = responseFields.filter(
//       (r) => r.id === selectedResponseField
//     );
//   }
//   const hasResponseFields = (responseFields || []).length > 0;

//   // Don't show response fields if a specific rejection field has been selected.
//   const shouldShowResponseFields =
//     hasResponseFields && !hasSelectedRejection && !hasSelectedModule;

//   if (shouldShowResponseFields) {
//     for (let i = 0; i < responseFields.length; i++) {
//       getInitialNodeAndEdgeListForArrayField(
//         fieldList,
//         includePossiblePaths,
//         responseFields,
//         responseFields[i].key
//       );
//     }
//   }

//   // Always show message fields if there were no errors above.
//   let messageFields = fieldList.filter(
//     (t) =>
//       t.type === NodeTypes.RootMessage ||
//       (t.isRoot === true &&
//         (t.type === NodeTypes.TransactionMessage ||
//           t.type === NodeTypes.TransmissionMessage))
//   );
//   const hasMessages = (messageFields || []).length > 0;

//   if (
//     hasMessages &&
//     !hasSelectedResponseField &&
//     !hasSelectedRejection &&
//     !hasSelectedModule
//   ) {
//     for (let i = 0; i < messageFields.length; i++) {
//       getInitialNodeAndEdgeListForArrayField(
//         fieldList,
//         includePossiblePaths,
//         messageFields,
//         messageFields[i].key
//       );
//     }
//   }

//   // Remove any modules (and their inputs) that have no parent nodes since this means they were not used in the rule map.
//   const finalNodeList = nodeList;
//   const finalEdgeList = edgeList;

//   // console.log("Nodes", finalNodeList);
//   // console.log("Edges", finalEdgeList);

//   return {
//     nodeList: finalNodeList,
//     edgeList: finalEdgeList,
//     rejections: [],
//     responseFields: [],
//   };
// }

export function getReactFlowNodesAndEdgesForGroup(
  fieldList,
  selectedModuleId = "-1",
  selectedField
) {
  nodeList = [];
  edgeList = [];
  isSelectionMode = false;

  const includePossiblePaths = true;
  // let hasSelectedModule = false;

  // If we have a selected module, just render everything in the field list - we have already reduced it in the module extractor to the selected module.
  if (selectedModuleId !== "-1") {
    // hasSelectedModule = true;
    for (let i = 0; i < fieldList.length; i++) {
      getInitialNodeAndEdgeListForArrayField(
        fieldList,
        includePossiblePaths,
        fieldList,
        fieldList[i].key
      );
    }
  }

  // Only support selected field
  if (selectedField.value !== "-1") {
    getInitialNodeAndEdgeListForArrayField(
      fieldList,
      includePossiblePaths,
      fieldList,
      selectedField.value
    );
  }

  // Remove any modules (and their inputs) that have no parent nodes since this means they were not used in the rule map.
  const finalNodeList = nodeList;
  const finalEdgeList = edgeList;

  // console.log("Nodes", finalNodeList);
  // console.log("Edges", finalEdgeList);

  return {
    nodeList: finalNodeList,
    edgeList: finalEdgeList,
    rejections: [],
    responseFields: [],
  };
}
