import React, { useState } from "react";
import {
  formatDateTimeUtcZoneForDisplay,
  getTimeframeFromMilliseconds,
} from "../../../services/General";
import StatusPill from "../../common/ui/StatusPill";
import { useMobile } from "../../../hooks/useMobile";
import InfoDialog from "../../dialogs/InfoDialog";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";

function TestStepList({
  testSteps,
  testStepIterationCounts,
  totalRecords,
  search,
  setSearch,
  onDelete,
  onCopy,
  onEdit,
  onMoveUp,
  onMoveDown,
}) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { isMobileSize } = useMobile();
  const [messageText, setMessageText] = useState("");
  const [showMessageDialog, setShowMessageDialog] = useState(false);

  function getActions(dataRow) {
    const id = dataRow.id;

    const actions = [];

    actions.push({
      name: "Edit step",
      onClick: () => onEdit(id),
    });

    actions.push({
      name: "Duplicate step",
      onClick: () => onCopy(id),
    });

    actions.push({
      name: "Remove step",
      onClick: () => onDelete(id),
    });

    if (auth.isAdmin) {
      actions.push({
        name: "View Step Change History",
        onClick: () => navigate(`/auditrecord/teststep/${id}/0`),
      });
    }

    if (isMobileSize) {
      actions.push({
        name: "Move step up",
        onClick: () => onMoveUp(id),
      });
      actions.push({
        name: "Move step down",
        onClick: () => onMoveDown(id),
      });
    }

    return actions;
  }

  function handleShowMessage(message) {
    if (message !== "") {
      setMessageText(message);
      setShowMessageDialog(true);
    }
  }

  function getStepIterationCount(ordinal) {
    if ((testStepIterationCounts || []).length === 0) {
      return "1";
    }

    // Get default count for all data columns that don't specify a step #
    const defaultValues =
      testStepIterationCounts.find(
        (c) => c.name === "iterationCount" && c.step === 0
      )?.values || [];
    let count = defaultValues.length > 0 ? defaultValues[0] : 1;

    const values =
      testStepIterationCounts.find(
        (c) => c.name === "iterationCount" && c.step === ordinal
      )?.values || [];
    if (values.length > 0) {
      count = values[0];
    }

    return count;
  }

  return (
    <>
      <InfoDialog
        title={"Message"}
        text={messageText}
        showModal={showMessageDialog}
        onClose={() => {
          setMessageText("");
          setShowMessageDialog(false);
        }}
      />
      <ResponsiveGrid
        gridId="TestSteps"
        totalRecords={totalRecords}
        search={search}
        setSearch={setSearch}
        allowFreeTextSearch={true}
        dataRows={testSteps.sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))}
        columnDefs={[
          {
            name: "edit",
            label: "Edit",
            disableSort: true,
            style: {
              width: "60px",
              maxWidth: "25%",
              textAlign: "center",
            },
            getValue: (row, index) => (
              <button
                className="btn btn-link link-underline"
                title="Edit this step"
                onClick={(e) => {
                  e.preventDefault();
                  onEdit(row.id);
                }}
              >
                edit
              </button>
            ),
          },
          {
            name: "ordinal",
            label: "Order",
            disableSort: true,
            style: { width: "15%" },
          },
          {
            name: "description",
            label: "Step Description",
            disableSort: true,
            freeTextSearch: true,
            style: { width: "40%" },
            getValue: (row, index, searchResultHtml) => searchResultHtml,
          },
          {
            name: "testStepType",
            label: "Type",
            disableSort: true,
            className: "d-none d-md-table-cell",
            style: { width: "15%" },
            getValue: (row, index, searchResultHtml) => searchResultHtml,
          },
          {
            name: "testStepIterationCount",
            label: "Iterations",
            disableSort: true,
            className: "d-none d-xl-table-cell",
            style: { width: "15%" },
            getValue: (row) => getStepIterationCount(row.ordinal),
          },
          {
            name: "lastRunDate",
            label: "Last Run",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
            getValue: (row) =>
              row.lastRunDate === ""
                ? ""
                : formatDateTimeUtcZoneForDisplay(row.lastRunDate),
          },
          {
            name: "lastRunBy",
            label: "Run By",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "25%" },
          },
          {
            name: "lastRunDurationMilliseconds",
            label: "Duration",
            disableSort: true,
            className: "d-none d-lg-table-cell",
            style: { width: "15%" },
            getValue: (row) =>
              getTimeframeFromMilliseconds(row.lastRunDurationMilliseconds),
          },
          {
            name: "runResultStatus",
            label: "Last Result",
            freeTextSearch: true,
            className: "d-none d-md-table-cell",
            style: { width: "15%" },
            noForceWrap: true,
            getValue: (row, index, searchResultHtml) => (
              <StatusPill
                status={searchResultHtml}
                pillStyle={{
                  cursor:
                    row.runResultStatus === "Error" ? "pointer" : "default",
                }}
                message={(row.message || "").substring(0, 50)}
                onClick={() => handleShowMessage(row.message)}
              />
            ),
          },
          {
            name: "move",
            label: "Move",
            disableSort: true,
            noForceWrap: true,
            className: "d-none d-md-table-cell",
            style: { width: "80px", maxWidth: "15%", textAlign: "left" },
            getValue: (row, index) => {
              const isFirstStep = index === 0;
              const isLastStep = index === testSteps.length - 1;

              return (
                <>
                  <span
                    className={
                      isLastStep ? "material-icons disabled" : "material-icons"
                    }
                    style={{
                      cursor: isLastStep ? "default" : "pointer",
                    }}
                    title="Move down"
                    onClick={() => {
                      if (index === testSteps.length - 1) return;
                      onMoveDown(row.id);
                    }}
                  >
                    south
                  </span>
                  <span
                    className={
                      isFirstStep ? "material-icons disabled" : "material-icons"
                    }
                    style={{
                      cursor: isFirstStep ? "default" : "pointer",
                    }}
                    title="Move up"
                    onClick={() => {
                      if (index === 0) return;
                      onMoveUp(row.id);
                    }}
                  >
                    north
                  </span>
                </>
              );
            },
          },
          {
            name: "action",
            label: "Action",
            isActionBtn: true,
            disableSort: true,
            noForceWrap: true,
            style: { width: "60px", maxWidth: "25%", textAlign: "center" },
            getActions: (row) => getActions(row),
          },
        ]}
      />
    </>
  );
}

export default TestStepList;
