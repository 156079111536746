import React from "react";
import styled from "styled-components";
import { useMobile } from "../../../hooks/useMobile";
import SliderInput from "../input/SliderInput";
import ToggleSwitchInput from "../input/ToggleSwitchInput";

function GridPageSizeAndInfo({
  count,
  search,
  setSearch,
  showNameLink = false,
  showDetailsLink = false,
  showColumnNames,
  setShowColumnNames,
  showDetails,
  setShowDetails,
  colCount = 1,
  showColumnPager = false,
  disablePaging = false,
  disableRecordCount = false,
}) {
  const { isMobileSize } = useMobile();

  let firstRecord = 0;
  let lastRecord = 0;
  let firstColumn = 0;
  let lastColumn = 0;

  if (count > 0) {
    firstRecord = 1 + search.pageSize * (search.pageNumber - 1);
    lastRecord = firstRecord + search.pageSize - 1;
    if (lastRecord > count) lastRecord = count;

    if (showColumnPager) {
      firstColumn = 1 + search.colPageSize * (search.colPageNumber - 1);
      lastColumn = firstColumn + search.colPageSize - 1;
      if (lastColumn > colCount) lastColumn = colCount;
    }
  }

  function setNumColsPerPage(value) {
    // Recalculate number of pages
    const numPages = Math.ceil(colCount / value);

    // Always go back to first page when changing number of visible columns
    setSearch({
      ...search,
      colPageSize: value,
      colPageCount: numPages,
      colPageNumber: 1,
      isClientOpOnly: true,
    });
  }

  return (
    <>
      {isMobileSize ? (
        <StyledWrapper className="row" data-testid="grid-pagesize-control">
          <div className="flex-row-with-wrap" style={{ columnGap: "10px" }}>
            {showNameLink && (
              <span>
                <ToggleSwitchInput
                  id="shownamesswitch"
                  name="shownamesswitch"
                  label="Names"
                  onChange={() => setShowColumnNames(!showColumnNames)}
                  checked={showColumnNames}
                />
              </span>
            )}
            {showDetailsLink && (
              <span>
                <ToggleSwitchInput
                  id="showdetailsswitch"
                  name="showdetailsswitch"
                  label="Details"
                  onChange={() => setShowDetails(!showDetails)}
                  checked={showDetails}
                />
              </span>
            )}
          </div>
        </StyledWrapper>
      ) : (
        <StyledWrapper className="row" data-testid="grid-pagesize-control">
          <div className="col">
            <StyledColumnWrapper>
              {!disablePaging && (
                <StyledPageSizeTable>
                  <tbody>
                    <tr>
                      <td
                        className={
                          search.pageSize === 10 ? "selected-value" : ""
                        }
                        onClick={() =>
                          setSearch({ ...search, pageSize: 10, pageNumber: 1 })
                        }
                      >
                        10
                      </td>
                      <td
                        className={
                          search.pageSize === 25 ? "selected-value" : ""
                        }
                        onClick={() =>
                          setSearch({ ...search, pageSize: 25, pageNumber: 1 })
                        }
                      >
                        25
                      </td>
                      <td
                        className={
                          search.pageSize === 50 ? "selected-value" : ""
                        }
                        onClick={() =>
                          setSearch({ ...search, pageSize: 50, pageNumber: 1 })
                        }
                      >
                        50
                      </td>
                      <td
                        className={
                          search.pageSize === 100 ? "selected-value" : ""
                        }
                        onClick={() =>
                          setSearch({ ...search, pageSize: 100, pageNumber: 1 })
                        }
                      >
                        100
                      </td>
                      <td
                        className={
                          search.pageSize === 250 ? "selected-value" : ""
                        }
                        onClick={() =>
                          setSearch({ ...search, pageSize: 250, pageNumber: 1 })
                        }
                      >
                        250
                      </td>
                    </tr>
                  </tbody>
                </StyledPageSizeTable>
              )}

              {showNameLink && (
                <div className="d-block" style={{ marginLeft: "20px" }}>
                  <ToggleSwitchInput
                    id="shownamesswitch"
                    name="shownamesswitch"
                    label="Show names"
                    onChange={() => setShowColumnNames(!showColumnNames)}
                    checked={showColumnNames}
                  />
                </div>
              )}
              {showDetailsLink && (
                <div style={{ marginLeft: "20px" }}>
                  <ToggleSwitchInput
                    id="showdetailsswitch"
                    name="showdetailsswitch"
                    label="Show details"
                    onChange={() => setShowDetails(!showDetails)}
                    checked={showDetails}
                  />
                </div>
              )}
            </StyledColumnWrapper>
          </div>
          <div className="col">
            {!disableRecordCount && (
              <StyledPageInfo>
                {disablePaging ? (
                  <>
                    {count?.toLocaleString() || "0"}{" "}
                    {count === 1 ? "record" : "records"}
                  </>
                ) : (
                  <>
                    Showing {firstRecord?.toLocaleString() || "0"} &ndash;{" "}
                    {lastRecord?.toLocaleString() || "0"} of{" "}
                    {count?.toLocaleString() || "0"}{" "}
                    {count === 1 ? "record" : "records"}
                  </>
                )}
              </StyledPageInfo>
            )}
          </div>
        </StyledWrapper>
      )}
      {showColumnPager && colCount > 2 && (
        <StyledWrapper className="row">
          <div className="col-12 col-md-6" style={{ marginTop: "-10px" }}>
            <SliderInput
              id="numCols"
              name="numCols"
              label="Columns"
              minValue={2}
              maxValue={colCount}
              value={search.colPageSize}
              onChange={(e) => setNumColsPerPage(parseInt(e.target.value, 10))}
            />
          </div>
          <div className="col-12 col-md-6">
            <StyledPageInfo
              className={"d-none d-md-block"}
              style={{
                textAlign: isMobileSize ? "left" : "right",
                lineHeight: "14px",
              }}
            >
              Showing {firstColumn?.toLocaleString() || "0"} &ndash;{" "}
              {lastColumn?.toLocaleString() || "0"} of{" "}
              {colCount?.toLocaleString() || "0"} columns
            </StyledPageInfo>
          </div>
        </StyledWrapper>
      )}
    </>
  );
}

const StyledWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 10px;
`;

const StyledColumnWrapper = styled.div`
  display: flex;
  align-items: center;

  button.btn.btn-link {
    margin: 0 0 8px 10px;
  }
`;

const StyledPageSizeTable = styled.table`
  background: var(--table-bg);
  border: 1px solid var(--table-border);
  border-radius: 3px;
  height: 26px;

  tbody tr td {
    cursor: pointer;
    width: 16px;
    height: 19px;
    font-size: 15px;
    line-height: 19px;
    color: var(--text-medium);
    text-align: center;
    vertical-align: middle;
    padding: 3px 9px 4px 9px;
    border: 1px solid var(--table-border);

    &:hover,
    &.selected-value {
      color: var(--button-primary-text);
      background-color: var(--button-primary-active-bg);
    }
  }
`;

const StyledPageInfo = styled.div`
  text-align: right;
  font-size: 15px;
  line-height: 28px;
  color: var(--text-medium);
`;

export default GridPageSizeAndInfo;
