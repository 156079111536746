import React from "react";

function BlankPage() {
  return (
    <>
      <p>&nbsp;</p>
    </>
  );
}

export default BlankPage;
