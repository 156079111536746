/* eslint-disable no-undef */
import { handleResponse, getCommonHeaders, getQueryString } from "./apiUtils";

// Loads dashboard data for panels that give latest data.
export function apiLoadDashboardData(noRefresh, token, signal) {
  let uri = API_URL + "/dashboard" + getQueryString();

  if (noRefresh === true) {
    uri += `&norefresh=true`;
  }
  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Loads all reports for current user that either show on dashboard as panels or in report list.
export function apiLoadDashboardReports(params, token, signal) {
  const uri =
    API_URL + "/reportdefinitions" + getQueryString() + "&homePage=true";
  let where = [];
  where.push({ name: "showOnHomePage", operator: "true", values: [] });

  const data = { where, orderBy: ["name+"] };

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(data),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiClearDashboardPanelAlert(reportId, token, signal) {
  const uri = API_URL + "/dashboardpanelack/" + reportId + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiSaveDashboardPanelProperties(model, token, signal) {
  const uri = API_URL + "/dashboardpanels/" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiSaveDashboardProperties(params, token, signal) {
  const id = params.id;
  const model = params.model;

  const uri = API_URL + "/dashboard/" + id + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(model),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Get current claims traffic.
export function apiGetClaimsTraffic(params, token, signal) {
  let uri = API_URL + "/trafficclaims" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Get current packet traffic.
export function apiGetPacketsTraffic(params, token, signal) {
  let uri = API_URL + "/trafficpackets" + getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: { ...headersToAdd },
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

export function apiSendTestPacketTraffic(params, token, signal) {
  const address = encodeURIComponent(params.address);
  const port = encodeURIComponent(params.port);
  const packetType = encodeURIComponent(params.packetType);
  const body = params.body;

  const uri =
    API_URL +
    `/tcpiprequest/${address}/${port}/${packetType}` +
    getQueryString();

  const headersToAdd = getCommonHeaders(token);
  return fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: { ...headersToAdd },
    body: JSON.stringify(body || ""),
    signal: signal,
  }).then((response) => {
    return handleResponse(response);
  });
}

// Function names for use in useApi to show in coverage tracking since obsfucation messes up function.name.
apiLoadDashboardData.funcName = "apiLoadDashboardData";
apiLoadDashboardReports.funcName = "apiLoadDashboardReports";
apiClearDashboardPanelAlert.funcName = "apiClearDashboardPanelAlert";
apiSaveDashboardPanelProperties.funcName = "apiSaveDashboardPanelProperties";
apiSaveDashboardProperties.funcName = "apiSaveDashboardProperties";
apiGetClaimsTraffic.funcName = "apiGetClaimsTraffic";
apiSendTestPacketTraffic.funcName = "apiSendTestPacketTraffic";
