import React, { useState, useEffect } from "react";
import TestRunResultsList from "./TestRunResultsList";
import TestRunResultSearchForm from "./TestRunResultsSearchForm";
import Spinner from "../../common/ui/Spinner";
import {
  createViewModel,
  emptyTestResultSearch,
} from "../../../viewmodels/testResultsVm";
import { useAuth } from "../../../contexts/AuthContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { useTestResults } from "../../../contexts/TestResultsContext";
import { apiLoadTestRunResults } from "../../../api/TestApi";
import Authorize from "../../common/layout/Authorize";
import GridFreeFormSearchBar from "../../common/grid/GridFreeFormSearchBar";
import GridAdvancedFilter from "../../common/grid/GridAdvancedFilter";
import { useMobile } from "../../../hooks/useMobile";
import {
  StyledHeaderRowButtonDiv,
  StyledHeaderRowDiv,
} from "../../common/layout/CommonStyledControls";

import useApi from "../../../hooks/useApi";
import HelpLink from "../../common/ui/HelpLink";

function TestResults() {
  const { auth } = useAuth();
  const [errors, setErrors] = useState({});
  const { testResultsData, setTestResultsData } = useTestResults();
  const { loading, api: apiLoad } = useApi(apiLoadTestRunResults);
  const [loadData, setLoadData] = useState(true);
  const { isMobileSize } = useMobile();

  let testResults = [];
  if (testResultsData && testResultsData.testResults) {
    testResults = testResultsData.testResults;
  }

  // The API call to load data is actually a side effect in most cases since a dispatch to setTestResultsData must usually
  //  happen first to set the search/sort parameters.  And these parameters are used by the load data call, so this
  //  useEffect ensures that happens first.
  useEffect(() => {
    if (auth.authenticated && loadData) {
      loadTestResults();
    }
  }, [auth.authenticated, loadData]);

  async function loadTestResults() {
    apiLoad.call(
      testResultsData.search,
      (result) => {
        setLoadData(false);

        const vms = result.resources.map((r) => createViewModel(r));
        const count = result.count || 0;

        setTestResultsData({
          type: ContextProviderActions.loadTestResults,
          payload: {
            testResults: vms,
            count,
          },
        });
      },
      () => {
        setLoadData(false);
        return true;
      }
    );
  }

  function setSearchChanges(search) {
    setTestResultsData({
      type: ContextProviderActions.saveTestResultSearch,
      payload: search,
    });
  }

  async function handleReset() {
    setSearchChanges({
      ...emptyTestResultSearch,
      showAdvancedFilter: isMobileSize
        ? false
        : testResultsData.search.showAdvancedFilter,
    });
    if (!loading) {
      setLoadData(true);
    }
  }

  function formIsValid() {
    const _errors = {};

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  async function handleSearch(event, newSearch) {
    if (event) event.preventDefault();
    if (!formIsValid()) return;

    if (!loading) {
      // If using mobile full screen filter, close that on search.
      if (newSearch.showAdvancedFilter && isMobileSize) {
        setSearchChanges({
          ...newSearch,
          showAdvancedFilter: false,
        });
      }
      setLoadData(true);
    }
  }

  async function handleSort(event) {
    var indexAsc = testResultsData.search.orderBy.indexOf(
      `${event.target.id}+`
    );
    var indexDesc = testResultsData.search.orderBy.indexOf(
      `${event.target.id}-`
    );

    if (indexAsc === -1 && indexDesc === -1)
      return updateSort({
        ...testResultsData.search,
        orderBy: [`${event.target.id}+`],
      });
    if (indexAsc > -1)
      return updateSort({
        ...testResultsData.search,
        orderBy: [`${event.target.id}-`],
      });
    if (indexDesc > -1)
      return updateSort({ ...testResultsData.search, orderBy: [] });

    async function updateSort(updatedSearch) {
      setSearchChanges(updatedSearch);
      await handleSearch(event, updatedSearch);
    }
  }

  async function onSubmit(event, newSearch) {
    var updatedSearch = {
      ...testResultsData.search,
      ...newSearch,
      pageNumber: 1,
    };
    setSearchChanges(updatedSearch);
    await handleSearch(event, updatedSearch);
  }

  function handleSearchChange({ target }) {
    setSearchChanges({
      ...testResultsData.search,
      [target.name]: target.value,
    });
  }

  function handleResultTypeChanged(types) {
    setSearchChanges({
      ...testResultsData.search,
      resultType: types,
    });
  }

  function handleRunResultStatusChanged(statuses) {
    setSearchChanges({
      ...testResultsData.search,
      runResultStatus: statuses,
    });
  }

  function handleStartDateChange(date) {
    setSearchChanges({ ...testResultsData.search, startDate: date });
  }

  function handleEndDateChange(date) {
    setSearchChanges({ ...testResultsData.search, endDate: date });
  }

  function getNumberOfSetFilters() {
    let numFilters = 0;

    if (testResultsData.search.description !== "") numFilters++;
    if (testResultsData.search.runBy !== "") numFilters++;
    if ((testResultsData.search.resultType || []).length > 0) numFilters++;
    if ((testResultsData.search.runResultStatus || []).length > 0) numFilters++;

    return numFilters;
  }

  return (
    <Authorize>
      <StyledHeaderRowDiv>
        <h1>Test Run Results History</h1>
        <StyledHeaderRowButtonDiv>
          <HelpLink path="/Testing/Test-Run-Results-List-Screen" label="Help" />
        </StyledHeaderRowButtonDiv>
      </StyledHeaderRowDiv>
      <GridFreeFormSearchBar
        placeholderText="Search Test/Group Desc, Run by, or Result"
        search={testResultsData.search}
        setSearch={setSearchChanges}
        numSetFilters={getNumberOfSetFilters()}
        onSubmitSearch={onSubmit}
      />
      <div style={{ display: "flex" }}>
        <div
          style={{
            width:
              testResultsData.search.showAdvancedFilter && isMobileSize
                ? "100%"
                : "auto",
          }}
        >
          <GridAdvancedFilter
            search={testResultsData.search}
            setSearch={setSearchChanges}
            helpLink="/Testing/Test-Run-Results-List-Screen&anchor=filters"
          >
            <TestRunResultSearchForm
              errors={errors}
              search={testResultsData.search}
              onSearch={onSubmit}
              onReset={handleReset}
              onChange={handleSearchChange}
              onResultTypeChanged={handleResultTypeChanged}
              onRunResultStatusChanged={handleRunResultStatusChanged}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
            />
          </GridAdvancedFilter>
        </div>

        {testResultsData.search.showAdvancedFilter && isMobileSize ? (
          <></>
        ) : (
          <div style={{ flex: "1 1 auto" }}>
            {loading || loadData ? (
              <Spinner />
            ) : (
              <TestRunResultsList
                testResults={testResults}
                search={testResultsData.search}
                setSearch={async (search) => {
                  setSearchChanges(search);
                  // Only do server-side search if the user didn't just perform a client op only
                  if (!search.isClientOpOnly) {
                    await handleSearch(undefined, search);
                  }
                }}
                onSort={handleSort}
                totalRecords={testResultsData.count}
              />
            )}
          </div>
        )}
      </div>
    </Authorize>
  );
}

export default TestResults;
